import React, { Component } from 'react'
import forgot_logo from "./../../Assets/images/forgot_logo.svg";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { toast } from 'react-toastify';
import './Forgot_2.css';
class Forgot_2 extends Component {

    state = {
       pincode:"",
       codeerror:"",
       isLoading: false
    }

    async componentWillMount(){
      if(this.props.location.state===undefined){
            this.props.history.push('/forgot');
           }else{
        return null;
      }
    }

    componentDidMount(){
        document.body.classList.add('forgot_bg')
      }
      componentWillUnmount(){
        document.body.classList.remove('forgot_bg');
      }

      inputHandler = (event) => {
        this.setState({
          [event.target.name] : event.target.value
        })
      }

      CheckCode=()=>{
        if(this.state.pincode !== ''){
          this.props.history.push({
                        pathname: '/forgot_4',
                        state: { authenticatepin: this.state.pincode, email: this.props.location.state.email, user_guid :  this.props.location.state.user_guid }
                      });
          }else{
            this.setState({
                codeerror:'This Field Is Required'
            });
          }
      }

      resendCode = async () =>{
        this.setState({
          isLoading: true
        });
        await Auth.forgotPassword(this.props.location.state.email)
        .then(data => 
          console.log("Code resended to " + data.CodeDeliveryDetails.Destination))
        .catch(err => toast.error(err.message));
        this.setState({
          isLoading: false
        })
      }

  render() {
    return (
        <div className="col-sm-12">
          {this.state.isLoading ?
            <div className="se-pre-con"></div>
          : ''}
        <div className="container p0">
        
        <div className="forgot_box1">
          <div className="forgot_box">
                
          <div className="col-sm-12 reset_password p0"> <img src={forgot_logo} alt="" />  Reset Password</div>
           
       
                
                <div className="reset_under_box2">
                
                 
              Enter the PIN Code
    
    
    
    
    
                   <div className="clear20"></div>
       
                  
                        <input name="pincode" onChange={this.inputHandler} className="frogot_input" type="text" placeholder="PIN Code" />
                        <div className="clearfix"></div>
                        <div className="col-sm-12 p0 text-right resend_code_label cursorPointer"><a href={null} onClick={this.resendCode}>Resend code</a></div>
                    
                   
    
                          <div className="clearfix"></div>
           
    
                    <Link to="/forgot" className="btn_frogot_back">Back</Link>
                     <a href={null} className="btn_frogot_blue_res cursorPointer" onClick={this.CheckCode}>Proceed</a>
    
    
     
     
     
       <div className="clearfix"></div>     
            </div>
               <div className="clearfix"></div>     
                
    </div>
    </div>
               <div className="clearfix"></div>   
    
             
             
          </div>
           <div className="clearfix"></div>   
        </div>
    )
  }
}
export default Forgot_2;
