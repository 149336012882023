import React, { Component } from 'react';
import HeaderMob from '../Common/HeaderMob/HeaderMob';
import aviva from './../../Assets/images/aviva.png';
import './Aviva.css';
class Aviva extends Component {
  render() {
    return (
      <div>
        <HeaderMob />

        <div className="col-md-12 col-xs-12 analytics_wrapper timecrad_coments_display" >




          <img src={aviva} className="img-responsive" alt="aviva-pic" />




          <div className="clear50"></div>










        </div>
      </div>
    )
  }
}
export default Aviva;
