import React, { Component } from "react";
import { Link } from "react-router-dom";
import forgot_logo from "./../../Assets/images/forgot_logo.svg";
import check_cricle from "./../../Assets/images/check_cricle.svg";
import { API } from "aws-amplify";
import { toast } from 'react-toastify';
import { Auth } from "aws-amplify";
import md5 from 'md5';
import "./Forgot_4.css";
class Forgot_4 extends Component {
  state = {
    isLoading: false,
    oldpass: '',
    newpass: '',
    confirmnewpass: '',
    confirmingcode: ''
  }

  async componentWillMount() {
    if (this.props.location.state === undefined) {
      this.props.history.push('/forgot');
    } else {
      this.setState({
        confirmingcode: this.props.location.state.authenticatepin
      });
    }
  }

  componentDidMount() {
    document.body.classList.add("forgot_bg");
  }
  componentWillUnmount() {
    document.body.classList.remove("forgot_bg");
  }

  ChangePassword = async () => {
    /*    if(this.state.oldpass === ''){
          toast.error('Please enter old password')
          return false;
        }*/
    if (this.state.newpass === '') {
      toast.error('Please enter new password');
      return false;
    }
    if (this.state.confirmnewpass !== this.state.newpass) {
      toast.error('Password not matched');
      return false;
    }

    this.setState({
      isLoading: true
    })

    await Auth.forgotPasswordSubmit(this.props.location.state.email, this.state.confirmingcode, this.state.newpass)
      .then(data => {
       // toast.success('Password updated successfully')
        API.post("updatepassword", "/updatepassword", {
          body: {
            Guid: this.props.location.state.user_guid,
            Password: md5(this.state.newpass)
          }
        }).then(result => {
         // toast.success('Password has been changed, Please login with new Password');
          if (result.status) {

          }
        }).catch(err => {
          toast.error(err.message, { autoClose: 5000 });
        });

        API.post("updatelastpasswordchanged", "/updatelastpasswordchanged", {
          body: {
            "userEmail": this.props.location.state.email,
            "PWLastChanged": Date.now()
          }
        }).then(data => {
          //toast.success('Password Changed Updated Successfully For User.');
        }).catch(err => {
          toast.error(err.message);
        });

        API.post("passwordchangedonce", "/passwordchangedonce", {
          body: {
            Guid: this.props.location.state.user_guid
          }
        }).then(result => {
          if (result.status) {
            this.props.history.push({
              pathname: '/login'
            });
          }
        }).catch(err => {
          toast.error(err.message, { autoClose: 5000 });
        });
      })
      .catch(err => {
        console.log(err);
        toast.error(err.message, { autoClose: 5000 });
      });


    this.setState({
      isLoading: false
    })
  }

  inputHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render() {
    return (
      <div>
        {this.state.isLoading ?
          <div className="se-pre-con"></div>
          : ''}
        <div className="col-sm-12">
          <div className="container p0">
            <div className="forgot_box1">
              <div className="forgot_box">
                <div className="col-sm-12 reset_password p0">
                  {" "}
                  <img src={forgot_logo} alt="" /> Reset Password
                </div>



                <div className="reset_under_box2">
                  Enter the password and confirm password
                  <div className="clear20" />
                  {/*  <input
                    name="oldpass"
                    className="frogot_input"
                    type="password"
                    placeholder="Old Password"
                    onChange={this.inputHandler}
                  />*/}
                  <input
                    name="newpass"
                    className="frogot_input"
                    type="password"
                    placeholder="New Password"
                    onChange={this.inputHandler}
                  />
                  <input
                    name="confirmnewpass"
                    className="frogot_input"
                    type="password"
                    placeholder="Confirm Password"
                    onChange={this.inputHandler}
                  />
                  <div className="clear30" />
                  <Link to="/forgot" className="btn_frogot_back">
                    Back
                  </Link>
                  <a
                    href={null}
                    className="btn_frogot_blue cursorPointer"
                    onClick={this.ChangePassword}
                  >
                    Proceed
                  </a>
                  <div className="clearfix" />
                </div>
                <div className="clearfix" />
              </div>
            </div>
            <div className="clearfix" />
          </div>
          <div className="clearfix" />
        </div>

      </div>
    );
  }
}
export default Forgot_4;
