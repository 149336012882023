import React, { Component } from "react";
import Header1 from "./../Common/Header1/Header1";
import CKEditor from "react-ckeditor-component";
import attach_icon_user from "./../../Assets/images/attach_icon_user.png";
import "./Email_Templates_Add.css";
import Dropzone from 'react-dropzone';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import Amplify, { Storage } from 'aws-amplify';
import { toast } from 'react-toastify';
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import $ from "jquery";

class Email_Templates_Add extends Component {
  constructor(props) {
    super(props);
    this.quillRef = null;
    this.reactQuillRef = null;
    this.attachQuillRefs = this.attachQuillRefs.bind(this);

    this.updateContent = this.updateContent.bind(this);
    this.state = {
      isLoading: true,
      emailTempAd_name: "",
      placeHolderFormTableData: [],
      emailTempAd_typeOptions: [
        { id: "New Contract", Name: "New Contract" },
        { id: "user Invite", Name: "user Invite" },
        { id: "Timecard Reminder", Name: "Timecard Reminder" }
      ],
      emailTempAd_typeValue: "",
      emailTempAd_type: false,
      emailTempAd_defaultCheck: false,
      emailTempAd_privateCheck: false,
      emailTempAd_attachment: "",
      emailTempAd_subject: "",
      emailTempAd_content: "",
      emailTempAd_nameValid: false,
      emailTempAd_typeValid: false,
      emailTempAd_subjectValid: false,
      emailTempAd_contentValid: false,
      emailTempAdErrors: {
        emailTempAd_nameErr: "",
        emailTempAd_typeErr: "",
        emailTempAd_subjectErr: "",
        emailTempAd_contentErr: ""
      },
      emailTempAdFormValid: false,
      tmp_attach: [],
      tmp_Guid: "",

    };
  }
  emailTempAd_inputHandler = e => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState(
      {
        [name]: value
      },
      () => {
        this.emailTempAd_validateField(name, value);
      }
    );
  };
  emailTempAd_selectHandler = e => {
    let name = e.target.name;
    let value = e.target.value;

    if (e.target.selectedIndex > 0) {
      this.setState(
        {
          emailTempAd_typeValue: e.target.value,
          emailTempAd_type: true,
          emailTempAd_typeValid: true
        },
        () => {
          this.emailTempAd_validateField(name, value);
        }
      );
    } else if (e.target.selectedIndex === 0) {
      this.setState(
        {
          emailTempAd_typeValue: "",
          emailTempAd_type: false,
          emailTempAd_typeValid: false
        },
        () => {
          this.emailTempAd_validateField(name, value);
        }
      );
    }
  };
  emailTempAd_validateField(fieldName, value) {
    let emailTempAdErrors = this.state.emailTempAdErrors;
    let emailTempAd_nameValid = this.state.emailTempAd_nameValid;
    let emailTempAd_typeValid = this.state.emailTempAd_typeValid;
    let emailTempAd_subjectValid = this.state.emailTempAd_subjectValid;
    let emailTempAd_contentValid = this.state.emailTempAd_contentValid;

    switch (fieldName) {
      case "emailTempAd_name":
        emailTempAd_nameValid = value.length > 0;
        emailTempAdErrors.emailTempAd_nameErr = emailTempAd_nameValid
          ? ""
          : "This field is required";
        break;

      case "emailTempAd_type":
        emailTempAd_typeValid = this.state.emailTempAd_typeValid;
        emailTempAdErrors.emailTempAd_typeErr = emailTempAd_typeValid
          ? ""
          : "Please Select Option";
        break;
      case "emailTempAd_subject":
        emailTempAd_subjectValid = value.length > 0;
        emailTempAdErrors.emailTempAd_subjectErr = emailTempAd_subjectValid
          ? ""
          : "This field is required";
        break;
      case "emailTempAd_content":
        emailTempAd_contentValid = value.length > 0;
        emailTempAdErrors.emailTempAd_contentErr = emailTempAd_contentValid
          ? ""
          : "This field is required";
        break;
      default:
        break;
    }

    this.setState(
      {
        emailTempAdErrors: emailTempAdErrors,
        emailTempAd_nameValid: emailTempAd_nameValid,
        emailTempAd_typeValid: emailTempAd_typeValid,
        emailTempAd_subjectValid: emailTempAd_subjectValid,
        emailTempAd_contentValid: emailTempAd_contentValid
      },
      this.emailTempAd_validateForm
    );
  }
  emailTempAd_validateForm = () => {
    this.setState({
      emailTempAdFormValid:
        this.state.emailTempAd_nameValid &&
        this.state.emailTempAd_typeValid &&
        this.state.emailTempAd_subjectValid &&
        this.state.emailTempAd_contentValid
    });
  };
  emailTempAd_submitHandler = async () => {

    let emailTempAdErrors = this.state.emailTempAdErrors;

    if (!this.state.emailTempAd_nameValid) {
      emailTempAdErrors.emailTempAd_nameErr = "This field is required";
    }
    if (!this.state.emailTempAd_typeValid) {
      emailTempAdErrors.emailTempAd_typeErr = "Please Select Option";
    }
    if (!this.state.emailTempAd_subjectValid) {
      emailTempAdErrors.emailTempAd_subjectErr = "This field is required";
    }
    if (!this.state.emailTempAd_contentValid) {
      emailTempAdErrors.emailTempAd_contentErr = "This field is required";
    }
    await this.setState({
      emailTempAdErrors: emailTempAdErrors
    });
    await this.setState({
      isLoading: true
    });
    if (this.state.emailTempAdFormValid) {
      if (this.state.tmp_Guid === '') {

        await API.post('createemailtemplate', '/createemailtemplate', {
          body: {
            "name": this.state.emailTempAd_name,
            "type": this.state.emailTempAd_typeValue,
            "default": this.state.emailTempAd_defaultCheck,
            "private": this.state.emailTempAd_privateCheck,
            "subject": this.state.emailTempAd_subject,
            "body": this.state.emailTempAd_content,
            "attach": this.state.tmp_attach,
            "tenant": localStorage.getItem('useableGuid')

          }
        }).then(data => {
         // toast.success("Template Added Successfully.");
          this.props.history.push('/email_template');
        }).catch(error => {
          toast.error('Error in adding template');
        });
      } else {

        await API.post('createemailtemplate', '/createemailtemplate', {
          body: {
            "Guid": this.state.tmp_Guid,
            "name": this.state.emailTempAd_name,
            "type": this.state.emailTempAd_typeValue,
            "default": this.state.emailTempAd_defaultCheck,
            "private": this.state.emailTempAd_privateCheck,
            "subject": this.state.emailTempAd_subject,
            "body": this.state.emailTempAd_content,
            "attach": this.state.tmp_attach,
            "tenant": localStorage.getItem('useableGuid')

          }
        }).then(data => {
        //  toast.success("Template Updated Successfully.");
          this.props.history.push('/email_template');
        }).catch(error => {
          toast.error('Error in Updating template');
        });
      }
    }
    await this.setState({
      isLoading: false
    });
  };
  updateContent(newContent) {
    this.setState({
      emailTempAd_content: newContent
    });
  }
  uploadattachments = async (files) => {
    await this.setState({
      isLoading: true
    });

    var test = [];

    await Promise.all(files.map(async file => {
      var tmp_name_dynamo = new Date().getTime() + '-' + file.name;
      var file_name = 'Email-Attachments/' + tmp_name_dynamo;
      await Storage.put(file_name, file)
        .then(result => {
          var uploadedObj = {
            "ContentType": "UTF-8",
            "DocDesc": "Email Attachment",
            "DoumentType": file.type,
            "filename": tmp_name_dynamo,
            "Timestamp": new Date().getTime(),
            "url": 'https://'+process.env.REACT_APP_S3_BUCKET+'.s3-'+process.env.REACT_APP_S3_REGION+'.amazonaws.com/public/' + result.key
          }
          test.push(uploadedObj);
        }).catch(err => console.log(err));

    }));
    this.setState({
      tmp_attach: test,
      isLoading: false
    });
  }


  onChange = async (evt) => {

    // var newContent = evt.editor.getData();

    var HTMLText = evt
    await this.setState({
      emailTempAd_content: HTMLText
    },
      () => {
        this.emailTempAd_validateField("emailTempAd_content", HTMLText);
      })
  }



  attachQuillRefs() {
    // Ensure React-Quill reference is available:
    if (typeof this.reactQuillRef.getEditor !== 'function') return;
    // Skip if Quill reference is defined:
    if (this.quillRef != null) return;

    const quillRef = this.reactQuillRef.getEditor();
    if (quillRef != null) this.quillRef = quillRef;
  }
  getplaceholdersall = async () => {
    await API.post("getplaceholdersall", "/getplaceholdersall"
    ).then(data => {

      this.setState({
        placeHolderFormTableData: data,

      })

    }).catch(err => {
      toast.error(err.message)
    });
  }
  componentDidMount = async () => {
    await this.props.childProps.isAuthentication();
    if (!this.props.childProps.isAuthenticated) {
      this.props.history.push('/login');
    } else {
      await this.getplaceholdersall();
      if (this.props.location.state !== undefined && this.props.location.state.data) {
        var editdata = this.props.location.state.data
        this.setState({

          tmp_Guid: editdata.Guid,
          emailTempAd_name: editdata.Name,
          emailTempAd_typeValue: editdata.Type,
          emailTempAd_defaultCheck: editdata.Default,
          emailTempAd_privateCheck: editdata.Private,
          emailTempAd_subject: editdata.Subject,
          emailTempAd_content: editdata.BodyHTML,
          tmp_attach: editdata.Attachments,
          emailTempAd_nameValid: true,
          emailTempAd_typeValid: true,
          emailTempAd_subjectValid: true,
          emailTempAd_contentValid: true,
          emailTempAdFormValid: true,
        })
      }
      this.setState({
        isLoading: false
      })
      await this.attachQuillRefs();
    }
  }
  componentDidUpdate = () => {
    this.attachQuillRefs();
  }
  removeAttachment = async (file) => {
    await this.setState({
      isLoading: true
    });
    let attach = this.state.tmp_attach;
    await Storage.remove('Email-Attachments/' + file)
      .then(result => {
        //toast.success('deleted successfully')
        attach = attach.filter(function (obj) {
          return obj.filename !== file;
        });

      })
      .catch(err => { toast.error('error in deleting') });
    await this.setState({
      tmp_attach: attach
    })
    await this.setState({
      isLoading: false
    });
  }
  showdrop = () => {
    $('#place').show();
  }
  hidedrop = () => {
    $('#place').hide();
  }
  insertPlaceholders = (e, text, url, name, Link) => {

    e.preventDefault();

    var textt;


    if (Link === true) {
      textt = "<a href='" + url + "'>" + text + "</a>";
    }
    else {
      textt = "{{" + name + "}}";
    }

    this.quillRef.focus();
    var range = this.quillRef.getSelection();

    let position = range ? range.index : 0;
    this.quillRef.insertText(position, textt)


  }
  render() {
    const that = this;
    return (
      <div>
        {this.state.isLoading ?
          <div className="se-pre-con"></div>
          : ''}
        <Header1
          link='/email_template'
          pageHeader="Email Template"
          childProps={this.props.childProps}

        />
        <div className="col-sm-12 p0 top_mrg_general top_mrg_general_custom top_mrgg_mbl_screeen">
          <div className="">
            <div className="col-sm-12 col-md-12 col-lg-10 col-lg-offset-1 general_setting_hd">
              <div className="col-xs-12 col-sm-12 col-md-12 p0">
                <div className="clear30" />

                <div className="clear10" />
                <div className="clearfix" />
                <div className="email_temp_box">
                  <div className="col-sm-8">
                    <div className="emailadd_left_boxarea">
                      <div className="col-sm-3 mrg_top5">Template:</div>
                      <div className="col-sm-3 col-md-4">
                        <input
                          id="emailTempAd_name"
                          name="emailTempAd_name"
                          value={this.state.emailTempAd_name}
                          onChange={this.emailTempAd_inputHandler}
                          className="name_w_email"
                          type="text"
                        />
                        <p
                          className={
                            "error validation " +
                            (this.state.emailTempAdErrors.emailTempAd_nameErr
                              ? ""
                              : "displayNone")
                          }
                        >
                          {this.state.emailTempAdErrors.emailTempAd_nameErr}
                        </p>
                      </div>
                      <div className="clear10" />

                      <div className="col-sm-3 mrg_top5">Template Type:</div>
                      <div className="col-sm-3 col-md-4 name_drop_down_lissst">
                        <select
                          id="emailTempAd_type"
                          name="emailTempAd_type"
                          onChange={this.emailTempAd_selectHandler}
                          className="name_w_email_select"
                          value={this.state.emailTempAd_typeValue}
                        >
                          <option>Please Select One Option</option>
                          {this.state.emailTempAd_typeOptions.map(function (
                            item,
                            key
                          ) {
                            return (
                              <option value={item.id} key={key}>
                                {item.Name}
                              </option>
                            );
                          })}
                        </select>
                        <p className={"error validation " + (this.state.emailTempAdErrors.emailTempAd_typeErr ? '' : 'displayNone')}>{this.state.emailTempAdErrors.emailTempAd_typeErr}</p>
                      </div>
                      <div className="mrg_top5">
                        <div className="col-xs-7 col-sm-2 col-md-1">
                          Default:
                        </div>
                        <div className="col-xs-5 col-sm-1">
                          <div className="checkbox checkbox_mrg_emailtop email_mrg_top_chkboxx">
                            <input
                              id="checkbox26"
                              name="emailTempAd_defaultCheck"
                              checked={this.state.emailTempAd_defaultCheck}
                              onChange={this.emailTempAd_inputHandler}
                              type="checkbox"
                            />
                            <label htmlFor="checkbox26" />
                          </div>
                        </div>
                      </div>
                      <div className="mrg_top5">
                        <div className="col-xs-7 col-sm-2 col-md-1">
                          Private:
                        </div>
                        <div className="col-xs-5 col-sm-1">
                          <div className="checkbox checkbox_mrg_emailtop email_mrg_top_chkboxx">
                            <input
                              id="checkbox27"
                              name="emailTempAd_privateCheck"
                              checked={this.state.emailTempAd_privateCheck}
                              onChange={this.emailTempAd_inputHandler}
                              type="checkbox"
                            />
                            <label htmlFor="checkbox27" />
                          </div>
                        </div>
                      </div>
                      <div className="clear10" />
                      <div className="col-sm-12 p0">
                        {
                          this.state.tmp_attach.map(value => {
                            return (<button className="btn btn-default" key={value.filename}>
                              <svg onClick={() => that.removeAttachment(value.filename)} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7b7b7b" />
                              </svg> <span data-toggle="modal" data-target="#send_message_popus">{value.filename}</span> </button>)
                          })
                        }
                        <div className="clear10" />
                        <div className="col-sm-3 mrg_top10">Attachment:</div>
                        <div className="col-sm-9 col-md-9 text-left cursorPointer ad_template_picker">
                          <Dropzone onDrop={this.uploadattachments.bind(this)}>
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />

                                <img className="add_template_filepick"
                                  src={attach_icon_user}
                                  width="23"
                                  height="43"
                                  alt=""
                                />
                                <p className="add_template_text">Drag and drop files.</p>

                              </div>
                            )}
                          </Dropzone>



                        </div>

                        <div className="clearfix" />
                      </div>

                      <div className="clearfix" />
                      <hr />
                      <div className="clearfix" />

                      <div className="email_temp_add_box3">
                        <div className="col-xs-3 col-sm-3 col-md-3 mrg_top5">
                          Subject:{" "}
                          <span className="email_temp_add_box3_spn">*</span>
                        </div>
                        <div className="col-xs-9 col-sm-9 col-md-9">
                          <div className="dropdown">
                            <button onMouseOver={this.showdrop}
                              className="email_btn_insert_place pull-right"
                              type="button"
                              data-toggle="dropdown"
                            >
                              Insert Placeholders
                            </button>

                            <ul id="place" className="dropdown-menu mrg_top_emiltep dropdown-menu_emiltemp_place">
                              <a href={null} onClick={this.hidedrop} className="close_mrg_email">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                >
                                  <path
                                    id="ic_clear_24px"
                                    d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                                    transform="translate(-5 -5)"
                                    fill="#7B7B7B"
                                  />
                                </svg>
                              </a>
                              <h2>Insert Placeholders</h2>
                              Click to insert Placeholders in content which will
                              dynamically get resolved into the appropriate
                              data.
                              <div className="clear20" />
                              <div className="col-sm-12 p0">
                                <div className="insert_place_bg_w">
                                  {this.state.placeHolderFormTableData.map(item => {
                                    return <a
                                      key={item.Name}
                                      href={null}
                                      onClick={(e) => this.insertPlaceholders(e, item.Text, item.Url, item.Name, item.Link)}
                                      className="btn_email_temp_status"
                                    >
                                      {item.Name}
                                    </a>
                                  })}
                                  <div className="clearfix"></div>
                                </div>
                              </div>
                              <div className="clear40" />
                            </ul>
                          </div>
                        </div>
                        <div className="clear10" />
                        <div className="col-sm-12">
                          <input
                            name="emailTempAd_subject"
                            id="emailTempAd_subject"
                            type="text"
                            value={this.state.emailTempAd_subject}
                            onChange={this.emailTempAd_inputHandler}
                          />
                          <p
                            className={
                              "error validation " +
                              (this.state.emailTempAdErrors
                                .emailTempAd_subjectErr
                                ? ""
                                : "displayNone")
                            }
                          >
                            {
                              this.state.emailTempAdErrors
                                .emailTempAd_subjectErr
                            }
                          </p>
                        </div>
                        <div className="clear20" />

                        <div className="clear20" />
                        <div className="col-sm-7">
                          Message:{" "}
                          <span className="email_temp_add_box3_spn">*</span>
                        </div>

                        <div className="clear10" />
                        <div className="col-sm-12 editor_label_normal">

                          <ReactQuill
                            ref={(el) => { this.reactQuillRef = el }}
                            // theme={'snow'}
                            onChange={this.onChange}
                            modules={Email_Templates_Add.modules}
                            // formats={Editor.formats}
                            defaultValue={this.state.emailTempAd_content}
                            value={this.state.emailTempAd_content || ''}
                            placeholder={this.props.placeholder}
                          />
                          <p
                            className={
                              "error validation " +
                              (this.state.emailTempAdErrors
                                .emailTempAd_contentErr
                                ? ""
                                : "displayNone")
                            }
                          >
                            {
                              this.state.emailTempAdErrors
                                .emailTempAd_contentErr
                            }
                          </p>
                        </div>
                        <div className="clear20" />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4 p0">
                    <div className="email_add2_rightbox">
                      <div className="email_right_logo_label">

                        Instructions
                      </div>
                    </div>
                  </div>

                  <div className="clearfix" />
                </div>
                <div className="clear40" />
                <div className="col-sm-6">
                  <div className="btn_cance_save">
                    <a href={null}>
                      <button
                        type="button"
                        onClick={this.emailTempAd_submitHandler}
                        className="btn_save_pro"
                      >
                        {this.state.tmp_Guid !== '' ? 'Update' : 'Save'}
                      </button>
                    </a>
                    <Link to="/email_template">
                      <button type="button" className="btn_cancel_pro">
                        Cancel
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="clear20" />
              </div>
            </div>

            <div className="clear10" />
          </div>
        </div>
      </div>
    );
  }
}


Email_Templates_Add.modules = {
  toolbar: {
    container:
      [
        [{ 'placeholder': ['[GuestName]', '[HotelName]'] }], // my custom dropdown
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']                                    // remove formatting button

      ], handlers: {
        "placeholder": function (value) {
          if (value) {
            const cursorPosition = this.quill.getSelection().index;
            this.quill.insertText(cursorPosition, value);
            this.quill.setSelection(cursorPosition + value.length);
          }
        }
      }

  }
}
export default Email_Templates_Add;
