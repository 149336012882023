import React, { Component } from "react";
import Header1 from "../Common/Header1/Header1";
import "./PayrollBatches_Edit.css";
import SearchSvg from "../../Assets/images/ic_search_-1.svg";
import "react-datepicker/dist/react-datepicker.css";
import "moment-timezone";
import {getKeys} from './../tempkeys.js'
import Amplify, { Storage } from "aws-amplify";
import ic_search from "./../../Assets/images/ic_search_-1.svg";
import paid from "./../../Assets/images/paid.png";
import calculate from "./../../Assets/images/calculate.png";
import move from "./../../Assets/images/move.png";
import cross from "./../../Assets/images/cross.png";
import relese_icon2 from "./../../Assets/images/relese_icon2.png";
import send_year_report from "./../../Assets/images/send_year_report.png";
import DatePicker from "react-datepicker";
import moment from "moment";
import excelPng from "../../Assets/images/excel.png";
import selPastePng from "../../Assets/images/select_paste.png";
import dollar_icon from "./../../Assets/images/dollar_icon.png";

import { API } from "aws-amplify";
import { toast } from "react-toastify";
import $, { error } from "jquery";
import { getDate } from "date-fns";

export default class PayrollBatches_Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countingOtherThanDraft:0,
      freelanceuser_email:null,
freelanceuser_signature:null,
      timesheetImage:null,
      testallcheck: [],
      approver_group_prlemp: "",
      current_selected_group: [],
      editChartCode: "",
      stdtimes: [],
      editChartSort: "",
      editChequeNumber: "",
      editFlags: "",
      editInternalNotes: "",
      editPaymentFlags: "",
      editPayslipNote: "",
      editPayWeek: "",
      editWorkState: "",
      editADtimes: [],
      editPaytimes: [],
      payrollReport: "",
      batch_chk_mon: false,
      batch_chk_tue: false,
      batch_chk_wed: false,
      batch_chk_thu: false,
      batch_chk_fri: false,
      batch_chk_sat: false,
      batch_chk_sun: false,
      delete_to_payroll: [],
      batch_to_updtae: "",
      Batches: [],
      counting: "",
      test: "",
      Employee_old_Guid_tobenull: "",
      editPrltimes: "",
      edit_batch: "",
      employee_userGuid: "",
      employ_uid_new_prlemp: "",
      edit_card: false,
      editableguid: "",
      Employee_Agcode: "",
      Employee_AliasFirstName: "",
      Employee_AliasLastName: "",
      Employee_EmpBasis: "",
      Employee_EmpGuid: "",
      Employee_GlobalCode: "",
      Employee_Guid: "",
      Employee_HourlyRate: "",
      Employee_LastName: "",
      Employee_Mobile: "",
      Employee_PaymentFlags: [],
      Employee_TemplateCode: "",
      Employee_TenantGuid: "",
      Employee_Address: [],
      payroll_Edit_secondModal_Search: "",
      payrollBatches_edit_EmpSearch_TermChkbox: false,
      payrollBatchEdit_SearchTable: "",

      payrollEdit_sendApprovalTable_btnInput: "",

      payrollEdit_EntryDet_EmployeeSearchInput: "",
      payrollEdit_EntryDet_PeriodEnding: new Date(),
      payrollEdit_EntryDet_Department: "",
      payrollEdit_EntryDet_Position: "",
      payrollEdit_EntryDet_Email: "",
      payrollEdit_EntryDet_ApprovSelectedOption: "",
      payrollEdit_EntryDet_ApproverGroupOpt: [],
      payrollBatchEdit_SearchTable: "",
      PayrollBatchEditSelectedTab: "First Name",
      payrollBatchEdit_search: "",

      sort_firstToggle: false,
      sort_lastToggle: false,

      sort_departmentToggle: false,
      sort_positionToggle: false,
      sort_approveToggle: false,
      sort_statusToggle: false,
      sort_currentToggle: false,
      sort_approvedByToggle: false,
      sort_empCodeToggle: false,
      sort_groupToggle: false,
      sort_dateToggle: false,
      sort_grossToggle: false,
      sort_taxToggle: false,

      Adtimes2_ModalSearchName_Search: "",
      payrollEdit_EntryDet_StateTax: "",
      payrollEdit_EntryDet_Group: "",
      payrollEdit_EntryDet_StartDate: "",
      payrollEdit_EntryDet_EndDate: "",
      payrollEdit_EntryDet_TerminatedChkBox: false,

      payrollEdit_sendApprovalTable_btnInputValid: false,

      payrollEdit_EntryDet_EmployeeSearchInputValid: false,
      payrollEdit_EntryDet_PeriodEndingValid: true,
      payrollEdit_EntryDet_DepartmentValid: false,
      payrollEdit_EntryDet_PositionValid: false,
      payrollEdit_EntryDet_EmailValid: false,
      payrollEdit_EntrDet_ApprvGrpValid: false,
      payrollEdit_EntryDet_StateTaxValid: false,
      payrollEdit_EntryDet_GroupValid: false,
      payrollEdit_EntryDet_StartDateValid: false,
      payrollEdit_EntryDet_EndDateValid: false,

      payrollEdit_SendApprovalTableBtnErrors: {
        payrollEdit_sendApprovalTable_btnInput: ""
      },

      payrollEdit_EntryDetErrors: {
        payrollEdit_EntryDet_EmployeeSearchInput: "",
        payrollEdit_EntryDet_PeriodEnding: "",
        payrollEdit_EntryDet_Department: "",
        payrollEdit_EntryDet_Position: "",
        payrollEdit_EntryDet_Email: "",
        payrollEdit_EntryDet_ApprovSelectedOption: "",
        payrollEdit_EntryDet_StateTax: "",
        payrollEdit_EntryDet_Group: "",
        payrollEdit_EntryDet_StartDate: "",
        payrollEdit_EntryDet_EndDate: ""
      },

      IspayrollEntrDetFormValid: false,

      IsAllpayrollEditTableCheckBoxSelected: false,
      payrollEditTableData: [],
      payrollEditTableDataCloned: [],
      // Employees Section
      AdTimes2_NameSearchModal_SelectedTab: "FirstName",
      AdTimes2_NameModal_LastName_Toggle: false,
      AdTimes2_NameModal_Email_Toggle: false,
      AdTimes2_NameModal_Mobile_Toggle: false,
      AdTimes2_NameModal_Dept_Toggle: false,
      AdTimes2_NameModal_Position_Toggle: false,
      AdTimes2_NameModal_ApproverGroup_Toggle: false,
      adTimes_NonTerminateEmpSearch: [],
      toggleEmployeeCaret: false,
      toggleCaretState: false,
      AdTimes2SelectedTab: "FirstName",
      AdTimes_2_FirstName_Toggle: false,
      AdTimes_2_LastName_Toggle: false,
      AdTimes_2_Position_Toggle: false,
      AdTimes_2_Department_Toggle: false,
      AdTimes_2_TravelTo_Toggle: false,
      AdTimes_2_Start_Toggle: false,
      AdTimes_2_Meal_Toggle: false,
      AdTimes_2_Wrap_Toggle: false,
      AdTimes_2_TravelFrom_Toggle: false,
      AdTimes_2_PmtFlag_Toggle: false,
      sort_adTimes2_employees_toggle: false,
      checkedarray: [],
      adTimes_NonTerminateEmp: [],
      payrollBatches_edit_EmpSearch_TermChkbox: false,
      payrollAttachments: [],
      bacth_number: "",
      currentShownAttachment: "",
      everyOnePaid: "",
      everyone_batch_date: ""
    };

  }

  sort_payrollBatchEdit = async (AdSearchTab) => {
    
    var that = this;
    localStorage.setItem("PayrollBatchEdit_sortLastTab", AdSearchTab);
    if (AdSearchTab === "First Name") {
      $("#firstCaret").show();
      $("#lastCaret").hide();
      $("#departmentCaret").hide();
      $("#positionCaret").hide();
      $("#approveCaret").hide();
      $("#statusCaret").hide();
      $("#currentCaret").hide();
      $("#approvedByCaret").hide();
      $("#empCodeCaret").hide();
      $("#groupCaret").hide();
      $("#dateCaret").hide();
      $("#grossCaret").hide();
      $("#taxCaret").hide();

      this.setState({
        PayrollBatchEditSelectedTab: AdSearchTab,
        sort_lastToggle: false
      });

      if (this.state.sort_firstToggle) {
        await this.setState({
          sort_firstToggle: false
        });
      } else {
        await this.setState({
          sort_firstToggle: true
        });
      }
      if (this.state.sort_firstToggle) {
        await this.setState({
          payrollEditTableData: this.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            var nameA =
              a.Employee.length > 0
                ? a.Employee[0].FirstName.toUpperCase()
                : a.freelanceuser[0].FirstName.toUpperCase(); // ignore upper and lowercase
            var nameB =
              b.Employee.length > 0
                ? b.Employee[0].FirstName.toUpperCase()
                : b.freelanceuser[0].FirstName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        });
      } else {
        await this.setState({
          payrollEditTableData: this.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            var nameA =
              a.Employee.length > 0
                ? a.Employee[0].FirstName.toUpperCase()
                : a.freelanceuser[0].FirstName.toUpperCase(); // ignore upper and lowercase
            var nameB =
              b.Employee.length > 0
                ? b.Employee[0].FirstName.toUpperCase()
                : b.freelanceuser[0].FirstName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        });
      }
    }
    if (AdSearchTab === "Last Name") {
      $("#firstCaret").hide();
      $("#lastCaret").show();
      $("#departmentCaret").hide();
      $("#positionCaret").hide();
      $("#approveCaret").hide();
      $("#statusCaret").hide();
      $("#currentCaret").hide();
      $("#approvedByCaret").hide();
      $("#empCodeCaret").hide();
      $("#groupCaret").hide();
      $("#dateCaret").hide();
      $("#grossCaret").hide();
      $("#taxCaret").hide();

      this.setState({
        PayrollBatchEditSelectedTab: AdSearchTab,
        sort_firstToggle: false
      });

      if (this.state.sort_lastToggle) {
        await this.setState({
          sort_lastToggle: false
        });
      } else {
        await this.setState({
          sort_lastToggle: true
        });
      }
      if (this.state.sort_lastToggle) {
        await this.setState({
          payrollEditTableData: this.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            var nameA =
              a.Employee.length > 0
                ? a.Employee[0].LastName.toUpperCase()
                : a.freelanceuser[0].LastName.toUpperCase(); // ignore upper and lowercase
            var nameB =
              b.Employee.length > 0
                ? b.Employee[0].LastName.toUpperCase()
                : b.freelanceuser[0].LastName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        });
      } else {
        await this.setState({
          payrollEditTableData: this.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            var nameA =
              a.Employee.length > 0
                ? a.Employee[0].LastName.toUpperCase()
                : a.freelanceuser[0].LastName.toUpperCase(); // ignore upper and lowercase
            var nameB =
              b.Employee.length > 0
                ? b.Employee[0].LastName.toUpperCase()
                : b.freelanceuser[0].LastName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        });
      }
    }
    if (AdSearchTab === "Department") {
      $("#firstCaret").hide();
      $("#lastCaret").hide();
      $("#departmentCaret").show();
      $("#positionCaret").hide();
      $("#approveCaret").hide();
      $("#statusCaret").hide();
      $("#currentCaret").hide();
      $("#approvedByCaret").hide();
      $("#empCodeCaret").hide();
      $("#groupCaret").hide();
      $("#dateCaret").hide();
      $("#grossCaret").hide();
      $("#taxCaret").hide();

      this.setState({
        PayrollBatchEditSelectedTab: AdSearchTab,
        sort_departmentToggle: false
      });

      if (this.state.sort_departmentToggle) {
        await this.setState({
          sort_departmentToggle: false
        });
      } else {
        await this.setState({
          sort_departmentToggle: true
        });
      }
      if (this.state.sort_departmentToggle) {
        await this.setState({
          payrollEditTableData: this.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            var nameA =
              a.Employee.length > 0
                ? a.Employee[0].Department.toUpperCase()
                : "";
            var nameB =
              b.Employee.length > 0
                ? b.Employee[0].Department.toUpperCase()
                : "";
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        });
      } else {
        await this.setState({
          payrollEditTableData: this.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            var nameA =
              a.Employee.length > 0
                ? a.Employee[0].Department.toUpperCase()
                : "";
            var nameB =
              b.Employee.length > 0
                ? b.Employee[0].Department.toUpperCase()
                : "";
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        });
      }
    }
    if (AdSearchTab === "Position") {
      $("#firstCaret").hide();
      $("#lastCaret").hide();
      $("#departmentCaret").hide();
      $("#positionCaret").show();
      $("#approveCaret").hide();
      $("#statusCaret").hide();
      $("#currentCaret").hide();
      $("#approvedByCaret").hide();
      $("#empCodeCaret").hide();
      $("#groupCaret").hide();
      $("#dateCaret").hide();
      $("#grossCaret").hide();
      $("#taxCaret").hide();

      this.setState({
        PayrollBatchEditSelectedTab: AdSearchTab,
        sort_positionToggle: false
      });

      if (this.state.sort_positionToggle) {
        await this.setState({
          sort_positionToggle: false
        });
      } else {
        await this.setState({
          sort_positionToggle: true
        });
      }
      if (this.state.sort_positionToggle) {
        await this.setState({
          payrollEditTableData: this.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            var nameA =
              a.Employee.length > 0 ? a.Employee[0].Position.toUpperCase() : "";
            var nameB =
              b.Employee.length > 0 ? b.Employee[0].Position.toUpperCase() : "";

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        });
      } else {
        await this.setState({
          payrollEditTableData: this.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            var nameA =
              a.Employee.length > 0 ? a.Employee[0].Position.toUpperCase() : "";
            var nameB =
              b.Employee.length > 0 ? b.Employee[0].Position.toUpperCase() : "";
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        });
      }
    }
    if (AdSearchTab === "Approve") {

      $("#firstCaret").hide();
      $("#lastCaret").hide();
      $("#departmentCaret").hide();
      $("#positionCaret").hide();
      $("#approveCaret").show();
      $("#statusCaret").hide();
      $("#currentCaret").hide();
      $("#approvedByCaret").hide();
      $("#empCodeCaret").hide();
      $("#groupCaret").hide();
      $("#dateCaret").hide();
      $("#grossCaret").hide();
      $("#taxCaret").hide();

      that.setState({
        PayrollBatchEditSelectedTab: AdSearchTab,
        sort_approveToggle: false
      });

      if (that.state.sort_approveToggle) {
        await that.setState({
          sort_approveToggle: false
        });
      } else {
        await that.setState({
          sort_approveToggle: true
        });
      }

      if (that.state.sort_approveToggle) {
        await that.setState({
          payrollEditTableData: that.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            let nameA;
            let nameB;

            nameA = a.ApproverGroupName.toUpperCase();
            nameB = b.ApproverGroupName.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        });
      } else {
        await that.setState({
          payrollEditTableData: that.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            let nameA;
            let nameB;
            // _that.state.payrollEdit_EntryDet_ApproverGroupOpt &&
            //   _that.state.payrollEdit_EntryDet_ApproverGroupOpt.forEach(
            //     (p, i) => {
            //       if (p.Guid == a.Employee[0].ApproverGroup) {
            //         nameA = p.ApproverGroup[0].FirstName.toUpperCase();
            //       }
            //     }
            //   );

            // _that.state.payrollEdit_EntryDet_ApproverGroupOpt &&
            //   _that.state.payrollEdit_EntryDet_ApproverGroupOpt.forEach(
            //     (p, i) => {
            //       if (p.Guid == b.Employee[0].ApproverGroup) {
            //         nameB = p.ApproverGroup[0].FirstName.toUpperCase();
            //       }
            //     }
            //   );

            nameA = a.ApproverGroupName.toUpperCase();
            nameB = b.ApproverGroupName.toUpperCase();
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        });
      }
    }
    if (AdSearchTab === "Status") {
      $("#firstCaret").hide();
      $("#lastCaret").hide();
      $("#departmentCaret").hide();
      $("#positionCaret").hide();
      $("#approveCaret").hide();
      $("#statusCaret").show();
      $("#currentCaret").hide();
      $("#approvedByCaret").hide();
      $("#empCodeCaret").hide();
      $("#groupCaret").hide();
      $("#dateCaret").hide();
      $("#grossCaret").hide();
      $("#taxCaret").hide();

      this.setState({
        PayrollBatchEditSelectedTab: AdSearchTab,
        sort_statusToggle: false
      });

      if (this.state.sort_statusToggle) {
        await this.setState({
          sort_statusToggle: false
        });
      } else {
        await this.setState({
          sort_statusToggle: true
        });
      }
      if (this.state.sort_statusToggle) {
        await this.setState({
          payrollEditTableData: this.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            var nameA = a.approve_status.toUpperCase(); // ignore upper and lowercase
            var nameB = b.approve_status.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        });
      } else {
        await this.setState({
          payrollEditTableData: this.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            var nameA = a.approve_status.toUpperCase(); // ignore upper and lowercase
            var nameB = b.approve_status.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        });
      }
    }
    if (AdSearchTab === "Current Approve") {
      $("#firstCaret").hide();
      $("#lastCaret").hide();
      $("#departmentCaret").hide();
      $("#positionCaret").hide();
      $("#approveCaret").hide();
      $("#statusCaret").hide();
      $("#currentCaret").show();
      $("#approvedByCaret").hide();
      $("#empCodeCaret").hide();
      $("#groupCaret").hide();
      $("#dateCaret").hide();
      $("#grossCaret").hide();
      $("#taxCaret").hide();

      that.setState({
        PayrollBatchEditSelectedTab: AdSearchTab,
        sort_currentToggle: false
      });

      if (that.state.sort_currentToggle) {
        await that.setState({
          sort_currentToggle: false
        });
      } else {
        await that.setState({
          sort_currentToggle: true
        });
      }

      if (that.state.sort_currentToggle) {
        await that.setState({
          payrollEditTableData: that.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            let nameA;
            let nameB;

            nameA = a.currentname?a.currentname.toUpperCase():'';
            nameB = b.currentname?b.currentname.toUpperCase():'';
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        });
      } else {
        await that.setState({
          payrollEditTableData: that.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            let nameA;
            let nameB;
            
            nameA = a.currentname?a.currentname.toUpperCase():'';
      nameB = b.currentname?b.currentname.toUpperCase():'';
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        });
      }
    }
    if (AdSearchTab === "Approved By") {
      $("#firstCaret").hide();
      $("#lastCaret").hide();
      $("#departmentCaret").hide();
      $("#positionCaret").hide();
      $("#approveCaret").hide();
      $("#statusCaret").hide();
      $("#currentCaret").hide();
      $("#approvedByCaret").show();
      $("#empCodeCaret").hide();
      $("#groupCaret").hide();
      $("#dateCaret").hide();
      $("#grossCaret").hide();
      $("#taxCaret").hide();

      that.setState({
        PayrollBatchEditSelectedTab: AdSearchTab,
        sort_approvedByToggle: false
      });

      if (that.state.sort_approvedByToggle) {
        await that.setState({
          sort_approvedByToggle: false
        });
      } else {
        await that.setState({
          sort_approvedByToggle: true
        });
      }

      if (that.state.sort_approvedByToggle) {
        await that.setState({
          payrollEditTableData: that.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            let nameA;
            let nameB;

            nameA = a.lastapprover?a.lastapprover.toUpperCase():'';
            nameB = b.lastapprover? b.lastapprover.toUpperCase():'';

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        });
      } else {
        await that.setState({
          payrollEditTableData: that.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            let nameA;
            let nameB;

            nameA = a.lastapprover?a.lastapprover.toUpperCase():'';
            nameB = b.lastapprover? b.lastapprover.toUpperCase():'';

            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        });
      }
    }
    if (AdSearchTab === "Emp Code") {
      $("#firstCaret").hide();
      $("#lastCaret").hide();
      $("#departmentCaret").hide();
      $("#positionCaret").hide();
      $("#approveCaret").hide();
      $("#statusCaret").hide();
      $("#currentCaret").hide();
      $("#approvedByCaret").hide();
      $("#empCodeCaret").show();
      $("#groupCaret").hide();
      $("#dateCaret").hide();
      $("#grossCaret").hide();
      $("#taxCaret").hide();

      this.setState({
        PayrollBatchEditSelectedTab: AdSearchTab,
        sort_empCodeToggle: false
      });

      if (this.state.sort_empCodeToggle) {
        await this.setState({
          sort_empCodeToggle: false
        });
      } else {
        await this.setState({
          sort_empCodeToggle: true
        });
      }
      if (this.state.sort_empCodeToggle) {
        await this.setState({
          payrollEditTableData: this.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            var nameA = a.empCode; // ignore upper and lowercase
            var nameB = b.empCode; // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        });
      } else {
        await this.setState({
          payrollEditTableData: this.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            var nameA = a.empCode; // ignore upper and lowercase
            var nameB = b.empCode; // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        });
      }
    }
    if (AdSearchTab === "Group") {
      $("#firstCaret").hide();
      $("#lastCaret").hide();
      $("#departmentCaret").hide();
      $("#positionCaret").hide();
      $("#approveCaret").hide();
      $("#statusCaret").hide();
      $("#currentCaret").hide();
      $("#approvedByCaret").hide();
      $("#empCodeCaret").hide();
      $("#groupCaret").show();
      $("#dateCaret").hide();
      $("#grossCaret").hide();
      $("#taxCaret").hide();

      this.setState({
        PayrollBatchEditSelectedTab: AdSearchTab,
        sort_groupToggle: false
      });

      if (this.state.sort_groupToggle) {
        await this.setState({
          sort_groupToggle: false
        });
      } else {
        await this.setState({
          sort_groupToggle: true
        });
      }
      if (this.state.sort_groupToggle) {
        await this.setState({
          payrollEditTableData: this.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            var nameA = a.ApproverGroupName; // ignore upper and lowercase
            var nameB = b.ApproverGroupName; // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        });
      } else {
        await this.setState({
          payrollEditTableData: this.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            var nameA = a.ApproverGroupName; // ignore upper and lowercase
            var nameB = b.ApproverGroupName; // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        });
      }
    }
    if (AdSearchTab === "Date") {
      $("#firstCaret").hide();
      $("#lastCaret").hide();
      $("#departmentCaret").hide();
      $("#positionCaret").hide();
      $("#approveCaret").hide();
      $("#statusCaret").hide();
      $("#currentCaret").hide();
      $("#approvedByCaret").hide();
      $("#empCodeCaret").hide();
      $("#groupCaret").hide();
      $("#dateCaret").show();
      $("#grossCaret").hide();
      $("#taxCaret").hide();

      this.setState({
        PayrollBatchEditSelectedTab: AdSearchTab,
        sort_dateToggle: false
      });

      if (this.state.sort_dateToggle) {
        await this.setState({
          sort_dateToggle: false
        });
      } else {
        await this.setState({
          sort_dateToggle: true
        });
      }
      if (this.state.sort_dateToggle) {
        await this.setState({
          payrollEditTableData: this.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            var nameA = a.PeriodEndingDate; // ignore upper and lowercase
            var nameB = b.PeriodEndingDate; // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        });
      } else {
        await this.setState({
          payrollEditTableData: this.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            var nameA = a.PeriodEndingDate; // ignore upper and lowercase
            var nameB = b.PeriodEndingDate; // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        });
      }
    }
    if (AdSearchTab === "Gross") {
      $("#firstCaret").hide();
      $("#lastCaret").hide();
      $("#departmentCaret").hide();
      $("#positionCaret").hide();
      $("#approveCaret").hide();
      $("#statusCaret").hide();
      $("#currentCaret").hide();
      $("#approvedByCaret").hide();
      $("#empCodeCaret").hide();
      $("#groupCaret").hide();
      $("#dateCaret").hide();
      $("#grossCaret").show();
      $("#taxCaret").hide();

      this.setState({
        PayrollBatchEditSelectedTab: AdSearchTab,
        sort_grossToggle: false
      });

      if (this.state.sort_grossToggle) {
        await this.setState({
          sort_grossToggle: false
        });
      } else {
        await this.setState({
          sort_grossToggle: true
        });
      }
      if (this.state.sort_grossToggle) {
        await this.setState({
          payrollEditTableData: this.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            var nameA = a.Gross?a.Gross:''; // ignore upper and lowercase
            var nameB = b.Gross?b.Gross:''; // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        });
      } else {
        await this.setState({
          payrollEditTableData: this.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            var nameA = a.Gross?a.Gross:''; // ignore upper and lowercase
            var nameB = b.Gross?b.Gross:''; // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        });
      }
    }
    if (AdSearchTab === "Tax") {
      $("#firstCaret").hide();
      $("#lastCaret").hide();
      $("#departmentCaret").hide();
      $("#positionCaret").hide();
      $("#approveCaret").hide();
      $("#statusCaret").hide();
      $("#currentCaret").hide();
      $("#approvedByCaret").hide();
      $("#empCodeCaret").hide();
      $("#groupCaret").hide();
      $("#dateCaret").hide();
      $("#grossCaret").hide();
      $("#taxCaret").show();

      this.setState({
        PayrollBatchEditSelectedTab: AdSearchTab,
        sort_taxToggle: false
      });

      if (this.state.sort_taxToggle) {
        await this.setState({
          sort_taxToggle: false
        });
      } else {
        await this.setState({
          sort_taxToggle: true
        });
      }
      if (this.state.sort_taxToggle) {
        await this.setState({
          payrollEditTableData: this.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            var nameA = a.TaxDeducted?a.TaxDeducted:''; // ignore upper and lowercase
            var nameB = b.TaxDeducted?b.TaxDeducted:''; // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        });
      } else {
        await this.setState({
          payrollEditTableData: this.state.payrollEditTableData.sort(function (
            a,
            b
          ) {
            var nameA = a.TaxDeducted?a.TaxDeducted:''; // ignore upper and lowercase
            var nameB = b.TaxDeducted?b.TaxDeducted:''; // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        });
      }
    }
  };
  payrollBatchEdit_SearchHandler = async event => {
    let tab = this.state.PayrollBatchEditSelectedTab;
    let searchText = event.target.value;
    this.setState({
      payrollBatchEdit_search: searchText
    });
    if (!searchText) {
      await this.setState({
        payrollEditTableData: this.state.payrollEditTableDataCloned
      });
    } else {
      let payrollEditTablefilteredData = [];
      if (tab === "First Name") {
        payrollEditTablefilteredData = this.state.payrollEditTableDataCloned.filter(
          function (d) {
            return d.Employee[0].FirstName.toUpperCase().includes(
              searchText.toUpperCase()
            );
          }
        );
      }

      if (tab === "Last Name") {
        payrollEditTablefilteredData = this.state.payrollEditTableDataCloned.filter(
          function (d) {
            return d.Employee[0].LastName.toUpperCase().includes(
              searchText.toUpperCase()
            );
          }
        );
      }

      if (tab === "Department") {
        payrollEditTablefilteredData = this.state.payrollEditTableDataCloned.filter(
          function (d) {
            return d.Employee[0].Department.toUpperCase().includes(
              searchText.toUpperCase()
            );
          }
        );
      }

      if (tab === "Position") {
        payrollEditTablefilteredData = this.state.payrollEditTableDataCloned.filter(
          function (d) {
            return d.Employee[0].Position.toUpperCase().includes(
              searchText.toUpperCase()
            );
          }
        );
      }

      if (tab === "Approve") {
        payrollEditTablefilteredData = this.state.payrollEditTableDataCloned.filter(
          function (d) {
            return (
              d.ApproverGroupName &&
              d.ApproverGroupName.toUpperCase().includes(
                searchText.toUpperCase()
              )
            );
          }
        );
      }

      if (tab === "Status") {
        payrollEditTablefilteredData = this.state.payrollEditTableDataCloned.filter(
          function (d) {
            return d.approve_status
              .toUpperCase()
              .includes(searchText.toUpperCase());
          }
        );
      }

      if (tab === "Current Approve") {

        payrollEditTablefilteredData = this.state.payrollEditTableDataCloned.filter(
          function (d) {
            return (
              d.currentname &&
              d.currentname
                .toUpperCase()
                .includes(searchText.toUpperCase())
            );
          }
        );
      }

      if (tab === "Approved By") {

        payrollEditTablefilteredData = this.state.payrollEditTableDataCloned.filter(
          function (d) {

            return (
              d.lastapprover &&
              d.lastapprover.toUpperCase().includes(searchText.toUpperCase())
            );
          }
        );
      }

      if (tab === "Emp Code") {
        var text = event.target.value;
        this.setState({
          payrollBatchEdit_search: event.target.value
        });
        this.setState({
          payrollEditTableData: this.state.payrollBatchEdit_searchData.filter(
            function (setup) {
              return setup.empCode.toUpperCase().includes(text.toUpperCase());
            }
          )
        });
      }

      if (tab === "Group") {
        var text = event.target.value;
       
        this.setState({
          payrollBatchEdit_search: event.target.value
        });
      
          payrollEditTablefilteredData= this.state.payrollBatchEdit_searchData.filter(
            function (setup) {
              return setup.ApproverGroupName.toUpperCase().includes(text.toUpperCase());
            }
          )
    
      }

      if (tab === "Date") {
        var text = event.target.value;
        this.setState({
          payrollBatchEdit_search: event.target.value
        });
      
        payrollEditTablefilteredData= this.state.payrollEditTableDataCloned.filter(
            function (setup) {
              return setup.PeriodEndingDate.toUpperCase().includes(text.toUpperCase());
            }
          )
      
      }

      if (tab === "Gross") {
        var text = event.target.value;
        this.setState({
          payrollBatchEdit_search: event.target.value
        });
        payrollEditTablefilteredData= this.state.payrollEditTableDataCloned.filter(
            function (setup) {
              return setup.Gross&&setup.Gross.toString().toUpperCase().includes(text.toUpperCase());
            }
          )
       
      }

      if (tab === "Tax") {
        var text = event.target.value;
        this.setState({
          payrollBatchEdit_search: event.target.value
        });
        payrollEditTablefilteredData= this.state.payrollEditTableDataCloned.filter(
            function (setup) {
              return setup.TaxDeducted&&setup.TaxDeducted.toString().toUpperCase().includes(text.toUpperCase());
            }
          )
      
      }
      await this.setState({
        payrollEditTableData: payrollEditTablefilteredData
      });
    }
  };
  EmployeesInputsChngHandlers = event => {
    if (this.state.Adtimes2_Modal2SearchName_TerminatedChkbx) {
      let SelectedTab = this.state.AdTimes2_NameSearchModal_SelectedTab;

      var text = event.target.value === null ? "" : event.target.value;

      if (SelectedTab === "FirstName") {
        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          ad_times_all_employess: this.state.ad_times_all_employess_search.filter(
            function (usr) {
              return usr.FirstName.toUpperCase().includes(text.toUpperCase());
            }
          )
        });
      }
      if (SelectedTab === "LastName") {
        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          ad_times_all_employess: this.state.ad_times_all_employess_search.filter(
            function (usr) {
              return usr.LastName.toUpperCase().includes(text.toUpperCase());
            }
          )
        });
      }
      if (SelectedTab === "Email") {
        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          ad_times_all_employess: this.state.ad_times_all_employess_search.filter(
            function (usr) {
              return usr.NewEmail.toUpperCase().includes(text.toUpperCase());
            }
          )
        });
      }
      if (SelectedTab === "Mobile") {
        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          ad_times_all_employess: this.state.ad_times_all_employess_search.filter(
            function (usr) {
              return usr.NewMobile.toUpperCase().includes(text.toUpperCase());
            }
          )
        });
      }
      if (SelectedTab === "Department") {
        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          ad_times_all_employess: this.state.ad_times_all_employess_search.filter(
            function (usr) {
              return usr.Department.toUpperCase().includes(text.toUpperCase());
            }
          )
        });
      }
      if (SelectedTab === "Position") {
        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          ad_times_all_employess: this.state.ad_times_all_employess_search.filter(
            function (usr) {
              return usr.Position.toUpperCase().includes(text.toUpperCase());
            }
          )
        });
      }
      if (SelectedTab === "ApproverGroup") {
        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          ad_times_all_employess: this.state.ad_times_all_employess_search.filter(
            function (usr) {
              return usr.NewApproverGroup.toUpperCase().includes(
                text.toUpperCase()
              );
            }
          )
        });
      }
    } else {
      let SelectedTab = this.state.AdTimes2_NameSearchModal_SelectedTab;

      var text = event.target.value === null ? "" : event.target.value;
      if (SelectedTab === "FirstName") {
        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmpSearch.filter(
            function (usr) {
              return usr.FirstName.toUpperCase().includes(text.toUpperCase());
            }
          )
        });
      }
      if (SelectedTab === "LastName") {
        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmpSearch.filter(
            function (usr) {
              return usr.LastName.toUpperCase().includes(text.toUpperCase());
            }
          )
        });
      }
      if (SelectedTab === "Email") {
        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmpSearch.filter(
            function (usr) {
              return usr.NewEmail.toUpperCase().includes(text.toUpperCase());
            }
          )
        });
      }
      if (SelectedTab === "Mobile") {
        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmpSearch.filter(
            function (usr) {
              return usr.NewMobile.toUpperCase().includes(text.toUpperCase());
            }
          )
        });
      }
      if (SelectedTab === "Department") {
        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmpSearch.filter(
            function (usr) {
              return usr.Department.toUpperCase().includes(text.toUpperCase());
            }
          )
        });
      }
      if (SelectedTab === "Position") {
        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmpSearch.filter(
            function (usr) {
              return usr.Position.toUpperCase().includes(text.toUpperCase());
            }
          )
        });
      }
      if (SelectedTab === "ApproverGroup") {
        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmpSearch.filter(
            function (usr) {
              return usr.NewApproverGroup.toUpperCase().includes(
                text.toUpperCase()
              );
            }
          )
        });
      }
    }
  };

  componentDidMount = async () => {
    await this.props.childProps.isAuthentication();
    if (!this.props.childProps.isAuthenticated) {
      this.props.history.push('/login');
    } else {
      if (
        this.props.location.state === undefined ||
        this.props.location.state.data === ""
      ) {
        this.props.history.push("/payroll_batches");
      } else {
        this.setState({
          bacth_number: this.props.location.state.data
        });
        await this.get_Approvergroups();
        await this.getAllEmployees();
        /*await this.props.childProps.amplifyConfiguration('freelancer');
          API.get('submittedtimecards','/submittedtimecards').then(data=>{
              console.log(data.items);
        }).catch(err=>console.log(err));
       await this.props.childProps.amplifyConfiguration('digitaltimes');*/
        await this.get_prlemp();
        $(document).click(function (e) {
          if (
            e.target.className === "modal-backdrop fade in" &&
            $("#parolledit2_search_pop").css("display") === "block"
          ) {
            $("body").css("overflow", "hidden");
            $("#batched_edit_pop").css("overflow-y", "auto");
          } else if (
            e.target.className === "modal-backdrop fade in" &&
            $("#batched_edit_pop").css("display") === "block"
          ) {
            $("body").removeAttr("style");
          }
        });
        await API.post("getbatch", "/getbatch", {
          body: {
            tenant: localStorage.getItem("useableGuid")
          }
        }).then(data => {
          this.setState({ Batches: data });
        });
       

      }
    }
  };
  oldSorting=async()=>{
    let AdSearchTab =
    localStorage.getItem("PayrollBatchEdit_sortLastTab") === null
      ? "First Name"
      : localStorage.getItem("PayrollBatchEdit_sortLastTab");
  // alert(AdSearchTab);
  if (AdSearchTab === "First Name") {
    $("#firstCaret").show();
    $("#lastCaret").hide();
    $("#departmentCaret").hide();
    $("#positionCaret").hide();
    $("#approveCaret").hide();
    $("#statusCaret").hide();
    $("#currentCaret").hide();
    $("#approvedByCaret").hide();
    $("#empCodeCaret").hide();
    $("#groupCaret").hide();
    $("#dateCaret").hide();
    $("#grossCaret").hide();
    $("#taxCaret").hide();
    await this.setState({
      PayrollBatchEditSelectedTab: AdSearchTab,
      sort_firstToggle: true,
      sort_lastToggle: false,
      sort_departmentToggle: false,
      sort_positionToggle: false,
      sort_approveToggle: false,
      sort_statusToggle: false,
      sort_currentToggle: false,
      sort_approvedByToggle: false,
      sort_empCodeToggle: false,
      sort_groupToggle: false,
      sort_dateToggle: false,
      sort_grossToggle: false,
      sort_taxToggle: false,
      payrollEditTableData: this.state.payrollEditTableData.sort(function (
        a,
        b
      ) {
        var nameA =
          a.Employee.length > 0
            ? a.Employee[0].FirstName.toUpperCase()
            : a.freelanceuser[0].FirstName.toUpperCase(); // ignore upper and lowercase
        var nameB =
          b.Employee.length > 0
            ? b.Employee[0].FirstName.toUpperCase()
            : b.freelanceuser[0].FirstName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      })
    });
  } else if (AdSearchTab === "Last Name") {
    $("#firstCaret").hide();
    $("#lastCaret").show();
    $("#departmentCaret").hide();
    $("#positionCaret").hide();
    $("#approveCaret").hide();
    $("#statusCaret").hide();
    $("#currentCaret").hide();
    $("#approvedByCaret").hide();
    $("#empCodeCaret").hide();
    $("#groupCaret").hide();
    $("#dateCaret").hide();
    $("#grossCaret").hide();
    $("#taxCaret").hide();
    await this.setState({
      PayrollBatchEditSelectedTab: AdSearchTab,
      sort_firstToggle: false,
      sort_lastToggle: true,
      sort_departmentToggle: false,
      sort_positionToggle: false,
      sort_approveToggle: false,
      sort_statusToggle: false,
      sort_currentToggle: false,
      sort_approvedByToggle: false,
      sort_empCodeToggle: false,
      sort_groupToggle: false,
      sort_dateToggle: false,
      sort_grossToggle: false,
      sort_taxToggle: false,
      payrollEditTableData: this.state.payrollEditTableData.sort(function (
        a,
        b
      ) {
        var nameA =
          a.Employee.length > 0
            ? a.Employee[0].LastName.toUpperCase()
            : a.freelanceuser[0].LastName.toUpperCase(); // ignore upper and lowercase
        var nameB =
          b.Employee.length > 0
            ? b.Employee[0].LastName.toUpperCase()
            : b.freelanceuser[0].LastName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      })
    });
  } else if (AdSearchTab === "Department") {
    //  alert('AdSearchTab');
    $("#firstCaret").hide();
    $("#lastCaret").hide();
    $("#departmentCaret").show();
    $("#positionCaret").hide();
    $("#approveCaret").hide();
    $("#statusCaret").hide();
    $("#currentCaret").hide();
    $("#approvedByCaret").hide();
    $("#empCodeCaret").hide();
    $("#groupCaret").hide();
    $("#dateCaret").hide();
    $("#grossCaret").hide();
    $("#taxCaret").hide();
    await this.setState({
      PayrollBatchEditSelectedTab: AdSearchTab,
      sort_firstToggle: false,
      sort_lastToggle: false,
      sort_departmentToggle: true,
      sort_positionToggle: false,
      sort_approveToggle: false,
      sort_statusToggle: false,
      sort_currentToggle: false,
      sort_approvedByToggle: false,
      sort_empCodeToggle: false,
      sort_groupToggle: false,
      sort_dateToggle: false,
      sort_grossToggle: false,
      sort_taxToggle: false,
      payrollEditTableData: this.state.payrollEditTableData.sort(function (
        a,
        b
      ) {
        var nameA =
          a.Employee.length > 0
            ? a.Employee[0].Department.toUpperCase()
            : "";
        var nameB =
          b.Employee.length > 0
            ? b.Employee[0].Department.toUpperCase()
            : "";
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      })
    });
  } else if (AdSearchTab === "Position") {
    $("#firstCaret").hide();
    $("#lastCaret").hide();
    $("#departmentCaret").hide();
    $("#positionCaret").show();
    $("#approveCaret").hide();
    $("#statusCaret").hide();
    $("#currentCaret").hide();
    $("#approvedByCaret").hide();
    $("#empCodeCaret").hide();
    $("#groupCaret").hide();
    $("#dateCaret").hide();
    $("#grossCaret").hide();
    $("#taxCaret").hide();
    await this.setState({
      PayrollBatchEditSelectedTab: AdSearchTab,
      sort_firstToggle: false,
      sort_lastToggle: false,
      sort_departmentToggle: false,
      sort_positionToggle: true,
      sort_approveToggle: false,
      sort_statusToggle: false,
      sort_currentToggle: false,
      sort_approvedByToggle: false,
      sort_empCodeToggle: false,
      sort_groupToggle: false,
      sort_dateToggle: false,
      sort_grossToggle: false,
      sort_taxToggle: false,
      payrollEditTableData: this.state.payrollEditTableData.sort(function (
        a,
        b
      ) {
        var nameA =
          a.Employee.length > 0 ? a.Employee[0].Position.toUpperCase() : "";
        var nameB =
          b.Employee.length > 0 ? b.Employee[0].Position.toUpperCase() : "";

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      })
    });
  } else if (AdSearchTab === "Approve") {
    $("#firstCaret").hide();
    $("#lastCaret").hide();
    $("#departmentCaret").hide();
    $("#positionCaret").hide();
    $("#approveCaret").show();
    $("#statusCaret").hide();
    $("#currentCaret").hide();
    $("#approvedByCaret").hide();
    $("#empCodeCaret").hide();
    $("#groupCaret").hide();
    $("#dateCaret").hide();
    $("#grossCaret").hide();
    $("#taxCaret").hide();
    await this.setState({
      PayrollBatchEditSelectedTab: AdSearchTab,
      sort_firstToggle: false,
      sort_lastToggle: false,
      sort_departmentToggle: false,
      sort_positionToggle: false,
      sort_approveToggle: true,
      sort_statusToggle: false,
      sort_currentToggle: false,
      sort_approvedByToggle: false,
      sort_empCodeToggle: false,
      sort_groupToggle: false,
      sort_dateToggle: false,
      sort_grossToggle: false,
      sort_taxToggle: false,
      payrollEditTableData: this.state.payrollEditTableData.sort(function (
        a,
        b
      ) {
        let nameA;
        let nameB;

        nameA = a.ApproverGroupName && a.ApproverGroupName.toUpperCase();
        nameB = b.ApproverGroupName && b.ApproverGroupName.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      })
    });
  } else if (AdSearchTab === "Status") {
    $("#firstCaret").hide();
    $("#lastCaret").hide();
    $("#departmentCaret").hide();
    $("#positionCaret").hide();
    $("#approveCaret").hide();
    $("#statusCaret").show();
    $("#currentCaret").hide();
    $("#approvedByCaret").hide();
    $("#empCodeCaret").hide();
    $("#groupCaret").hide();
    $("#dateCaret").hide();
    $("#grossCaret").hide();
    $("#taxCaret").hide();
    await this.setState({
      PayrollBatchEditSelectedTab: AdSearchTab,
      sort_firstToggle: false,
      sort_lastToggle: false,
      sort_departmentToggle: false,
      sort_positionToggle: false,
      sort_approveToggle: false,
      sort_statusToggle: true,
      sort_currentToggle: false,
      sort_approvedByToggle: false,
      sort_empCodeToggle: false,
      sort_groupToggle: false,
      sort_dateToggle: false,
      sort_grossToggle: false,
      sort_taxToggle: false,
      payrollEditTableData: this.state.payrollEditTableData.sort(function (
        a,
        b
      ) {
        var nameA = a.approve_status.toUpperCase(); // ignore upper and lowercase
        var nameB = b.approve_status.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      })
    });
  } else if (AdSearchTab === "Current Approve") {
    $("#firstCaret").hide();
    $("#lastCaret").hide();
    $("#departmentCaret").hide();
    $("#positionCaret").hide();
    $("#approveCaret").hide();
    $("#statusCaret").hide();
    $("#currentCaret").show();
    $("#approvedByCaret").hide();
    $("#empCodeCaret").hide();
    $("#groupCaret").hide();
    $("#dateCaret").hide();
    $("#grossCaret").hide();
    $("#taxCaret").hide();
    await this.setState({
      PayrollBatchEditSelectedTab: AdSearchTab,
      sort_firstToggle: false,
      sort_lastToggle: false,
      sort_departmentToggle: false,
      sort_positionToggle: false,
      sort_approveToggle: false,
      sort_statusToggle: false,
      sort_currentToggle: true,
      sort_approvedByToggle: false,
      sort_empCodeToggle: false,
      sort_groupToggle: false,
      sort_dateToggle: false,
      sort_grossToggle: false,
      sort_taxToggle: false,
      payrollEditTableData: this.state.payrollEditTableData.sort(function (
        a,
        b
      ) {
        let nameA;
        let nameB;

        
      nameA = a.currentname?a.currentname.toUpperCase():'';
      nameB = b.currentname?b.currentname.toUpperCase():'';
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      })
    });
  } else if (AdSearchTab === "Approved By") {
    $("#firstCaret").hide();
    $("#lastCaret").hide();
    $("#departmentCaret").hide();
    $("#positionCaret").hide();
    $("#approveCaret").hide();
    $("#statusCaret").hide();
    $("#currentCaret").hide();
    $("#approvedByCaret").show();
    $("#empCodeCaret").hide();
    $("#groupCaret").hide();
    $("#dateCaret").hide();
    $("#grossCaret").hide();
    $("#taxCaret").hide();
    await this.setState({
      PayrollBatchEditSelectedTab: AdSearchTab,
      sort_firstToggle: false,
      sort_lastToggle: false,
      sort_departmentToggle: false,
      sort_positionToggle: false,
      sort_approveToggle: false,
      sort_statusToggle: false,
      sort_currentToggle: false,
      sort_approvedByToggle: true,
      sort_empCodeToggle: false,
      sort_groupToggle: false,
      sort_dateToggle: false,
      sort_grossToggle: false,
      sort_taxToggle: false,
      payrollEditTableData: this.state.payrollEditTableData.sort(function (
        a,
        b
      ) {
        let nameA;
        let nameB;
        nameA = a.lastapprover?a.lastapprover.toUpperCase():'';
        nameB = b.lastapprover? b.lastapprover.toUpperCase():'';

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      })
    });
  } else if (AdSearchTab === "Emp Code") {
    $("#firstCaret").hide();
    $("#lastCaret").hide();
    $("#departmentCaret").hide();
    $("#positionCaret").hide();
    $("#approveCaret").hide();
    $("#statusCaret").hide();
    $("#currentCaret").hide();
    $("#approvedByCaret").hide();
    $("#empCodeCaret").show();
    $("#groupCaret").hide();
    $("#dateCaret").hide();
    $("#grossCaret").hide();
    $("#taxCaret").hide();
    this.setState({
      PayrollBatchEditSelectedTab: AdSearchTab,
      sort_firstToggle: false,
      sort_lastToggle: false,
      sort_departmentToggle: false,
      sort_positionToggle: false,
      sort_approveToggle: false,
      sort_statusToggle: false,
      sort_currentToggle: false,
      sort_approvedByToggle: false,
      sort_empCodeToggle: true,
      sort_groupToggle: false,
      sort_dateToggle: false,
      sort_grossToggle: false,
      sort_taxToggle: false,
      payrollEditTableData: this.state.payrollEditTableData.sort(function (
        a,
        b
      ) {
        let nameA = a.empCode.toUpperCase();
        let nameB = b.empCode.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
      }),
      payrollBatchEdit_searchData: this.state.payrollEditTableData.sort(
        function (a, b) {
          let nameA = a.empCode.toUpperCase();
          let nameB = b.empCode.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
        }
      ),
      payrollEditTableData: this.state.payrollEditTableData.sort(function (
        a,
        b
      ) {
        let nameA = a.empCode.toUpperCase();
        let nameB = b.empCode.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
      }),
      payrollBatchEdit_searchData: this.state.payrollEditTableData.sort(
        function (a, b) {
          let nameA = a.empCode.toUpperCase();
          let nameB = b.empCode.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
        }
      )
    });
  } else if (AdSearchTab === "Group") {
    $("#firstCaret").hide();
    $("#lastCaret").hide();
    $("#departmentCaret").hide();
    $("#positionCaret").hide();
    $("#approveCaret").hide();
    $("#statusCaret").hide();
    $("#currentCaret").hide();
    $("#approvedByCaret").hide();
    $("#empCodeCaret").hide();
    $("#groupCaret").show();
    $("#dateCaret").hide();
    $("#grossCaret").hide();
    $("#taxCaret").hide();
    this.setState({
      PayrollBatchEditSelectedTab: AdSearchTab,
      sort_firstToggle: false,
      sort_lastToggle: false,
      sort_departmentToggle: false,
      sort_positionToggle: false,
      sort_approveToggle: false,
      sort_statusToggle: false,
      sort_currentToggle: false,
      sort_approvedByToggle: false,
      sort_empCodeToggle: false,
      sort_groupToggle: true,
      sort_dateToggle: false,
      sort_grossToggle: false,
      sort_taxToggle: false,
      payrollEditTableData: this.state.payrollEditTableData.sort(function (
        a,
        b
      ) {
        let nameA = a.ApproverGroupName.toUpperCase();
        let nameB = b.ApproverGroupName.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
      }),
      payrollBatchEdit_searchData: this.state.payrollEditTableData.sort(
        function (a, b) {
          let nameA = a.ApproverGroupName.toUpperCase();
          let nameB = b.ApproverGroupName.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
        }
      ),
      payrollEditTableData: this.state.payrollEditTableData.sort(function (
        a,
        b
      ) {
        let nameA =a.ApproverGroupName.toUpperCase();
        let nameB = b.ApproverGroupName.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
      }),
      payrollBatchEdit_searchData: this.state.payrollEditTableData.sort(
        function (a, b) {
          let nameA = a.ApproverGroupName.toUpperCase();
          let nameB = b.ApproverGroupName.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
        }
      )
    });
  } else if (AdSearchTab === "Date") {
    $("#firstCaret").hide();
    $("#lastCaret").hide();
    $("#departmentCaret").hide();
    $("#positionCaret").hide();
    $("#approveCaret").hide();
    $("#statusCaret").hide();
    $("#currentCaret").hide();
    $("#approvedByCaret").hide();
    $("#empCodeCaret").hide();
    $("#groupCaret").hide();
    $("#dateCaret").show();
    $("#grossCaret").hide();
    $("#taxCaret").hide();
    this.setState({
      PayrollBatchEditSelectedTab: AdSearchTab,
      sort_firstToggle: false,
      sort_lastToggle: false,
      sort_departmentToggle: false,
      sort_positionToggle: false,
      sort_approveToggle: false,
      sort_statusToggle: false,
      sort_currentToggle: false,
      sort_approvedByToggle: false,
      sort_empCodeToggle: false,
      sort_groupToggle: false,
      sort_dateToggle: true,
      sort_grossToggle: false,
      sort_taxToggle: false,
      payrollEditTableData: this.state.payrollEditTableData.sort(function (
        a,
        b
      ) {
        let nameA = a.PeriodEndingDate.toUpperCase();
        let nameB = b.PeriodEndingDate.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
      }),
      payrollBatchEdit_searchData: this.state.payrollEditTableData.sort(
        function (a, b) {
          let nameA = a.PeriodEndingDate.toUpperCase();
          let nameB = b.PeriodEndingDate.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
        }
      ),
      payrollEditTableData: this.state.payrollEditTableData.sort(function (
        a,
        b
      ) {
        let nameA = a.PeriodEndingDate.toUpperCase();
        let nameB = b.PeriodEndingDate.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
      }),
      payrollBatchEdit_searchData: this.state.payrollEditTableData.sort(
        function (a, b) {
          let nameA = a.PeriodEndingDate.toUpperCase();
          let nameB = b.PeriodEndingDate.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
        }
      )
    });
  } else if (AdSearchTab === "Gross") {
    $("#firstCaret").hide();
    $("#lastCaret").hide();
    $("#departmentCaret").hide();
    $("#positionCaret").hide();
    $("#approveCaret").hide();
    $("#statusCaret").hide();
    $("#currentCaret").hide();
    $("#approvedByCaret").hide();
    $("#empCodeCaret").hide();
    $("#groupCaret").hide();
    $("#dateCaret").hide();
    $("#grossCaret").show();
    $("#taxCaret").hide();
    this.setState({
      PayrollBatchEditSelectedTab: AdSearchTab,
      sort_firstToggle: false,
      sort_lastToggle: false,
      sort_departmentToggle: false,
      sort_positionToggle: false,
      sort_approveToggle: false,
      sort_statusToggle: false,
      sort_currentToggle: false,
      sort_approvedByToggle: false,
      sort_empCodeToggle: false,
      sort_groupToggle: false,
      sort_dateToggle: false,
      sort_grossToggle: true,
      sort_taxToggle: false,
      payrollEditTableData: this.state.payrollEditTableData.sort(function (
        a,
        b
      ) {
        let nameA = a.Gross?a.Gross.toString().toUpperCase():'';
        let nameB = b.Gross?b.Gross.toString().toUpperCase():'';
        if (nameA < nameB) {
          return -1;
        }
      }),
      payrollBatchEdit_searchData: this.state.payrollEditTableData.sort(
        function (a, b) {
          let nameA =  a.Gross?a.Gross.toString().toUpperCase():'';
          let nameB = b.Gross?b.Gross.toString().toUpperCase():'';
          if (nameA < nameB) {
            return -1;
          }
        }
      ),
      payrollEditTableData: this.state.payrollEditTableData.sort(function (
        a,
        b
      ) {
        let nameA =  a.Gross?a.Gross.toString().toUpperCase():'';
        let nameB = b.Gross?b.Gross.toString().toUpperCase():'';
        if (nameA < nameB) {
          return -1;
        }
      }),
      payrollBatchEdit_searchData: this.state.payrollEditTableData.sort(
        function (a, b) {
          let nameA =  a.Gross?a.Gross.toString().toUpperCase():'';
          let nameB = b.Gross?b.Gross.toString().toUpperCase():'';
          if (nameA < nameB) {
            return -1;
          }
        }
      )
    });
  } else if (AdSearchTab === "Tax") {
    $("#firstCaret").hide();
    $("#lastCaret").hide();
    $("#departmentCaret").hide();
    $("#positionCaret").hide();
    $("#approveCaret").hide();
    $("#statusCaret").hide();
    $("#currentCaret").hide();
    $("#approvedByCaret").hide();
    $("#empCodeCaret").hide();
    $("#groupCaret").hide();
    $("#dateCaret").hide();
    $("#grossCaret").hide();
    $("#taxCaret").show();
    this.setState({
      PayrollBatchEditSelectedTab: AdSearchTab,
      sort_firstToggle: false,
      sort_lastToggle: false,
      sort_departmentToggle: false,
      sort_positionToggle: false,
      sort_approveToggle: false,
      sort_statusToggle: false,
      sort_currentToggle: false,
      sort_approvedByToggle: false,
      sort_empCodeToggle: false,
      sort_groupToggle: false,
      sort_dateToggle: false,
      sort_grossToggle: false,
      sort_taxToggle: true,
      payrollEditTableData: this.state.payrollEditTableData.sort(function (
        a,
        b
      ) {
        
        let nameA = a.TaxDeducted?a.TaxDeducted.toString().toUpperCase():'';
        let nameB = b.TaxDeducted?b.TaxDeducted.toString().toUpperCase():'';
        if (nameA < nameB) {
          return -1;
        }
      }),
      payrollBatchEdit_searchData: this.state.payrollEditTableData.sort(
        function (a, b) {
          let nameA = a.TaxDeducted?a.TaxDeducted.toString().toUpperCase():'';
          let nameB = b.TaxDeducted?b.TaxDeducted.toString().toUpperCase():'';
          if (nameA < nameB) {
            return -1;
          }
        }
      ),
      payrollEditTableData: this.state.payrollEditTableData.sort(function (
        a,
        b
      ) {
        let nameA = a.TaxDeducted?a.TaxDeducted.toString().toUpperCase():'';
        let nameB = b.TaxDeducted?b.TaxDeducted.toString().toUpperCase():'';
        if (nameA < nameB) {
          return -1;
        }
      }),
      payrollBatchEdit_searchData: this.state.payrollEditTableData.sort(
        function (a, b) {
          let nameA = a.TaxDeducted?a.TaxDeducted.toString().toUpperCase():'';
        let nameB = b.TaxDeducted?b.TaxDeducted.toString().toUpperCase():'';
          if (nameA < nameB) {
            return -1;
          }
        }
      )
    });
  } else {
    $("#firstCaret").show();
    $("#lastCaret").hide();
    $("#departmentCaret").hide();
    $("#positionCaret").hide();
    $("#approveCaret").hide();
    $("#statusCaret").hide();
    $("#currentCaret").hide();
    $("#approvedByCaret").hide();
    $("#empCodeCaret").hide();
    $("#groupCaret").hide();
    $("#dateCaret").hide();
    $("#grossCaret").hide();
    $("#taxCaret").hide();
    await this.setState({
      PayrollBatchEditSelectedTab: "First Name",
      sort_firstToggle: true,
      sort_lastToggle: false,
      sort_departmentToggle: false,
      sort_positionToggle: false,
      sort_approveToggle: false,
      sort_statusToggle: false,
      sort_currentToggle: false,
      sort_approvedByToggle: false,
      sort_empCodeToggle: false,
      sort_groupToggle: false,
      sort_dateToggle: false,
      sort_grossToggle: false,
      sort_taxToggle: false,
      payrollEditTableData: this.state.payrollEditTableData.sort(function (
        a,
        b
      ) {
        var nameA =
          a.Employee.length > 0
            ? a.Employee[0].FirstName.toUpperCase()
            : a.freelanceuser[0].FirstName.toUpperCase(); // ignore upper and lowercase
        var nameB =
          b.Employee.length > 0
            ? b.Employee[0].FirstName.toUpperCase()
            : b.freelanceuser[0].FirstName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      })
    });
  }
  }
  generateReport = async () => {
    var array_temp = [];
    this.state.payrollEditTableData.map(abc => {
      if (abc.checked === true) {
        array_temp.push(abc);
      }
    });
    if (array_temp.length > 0) {
      //

      let arr = JSON.stringify(array_temp);

      localStorage.setItem("getreports", arr);
      if (this.state.payrollReport === "PayrollBatchReport") {
        var path =
          window.location.protocol +
          "//" +
          window.location.host +
          "/PayrollBatchReport";
        window.open(path, "_blank");
      } else if (this.state.payrollReport === "TimeCardReport") {
        var path =
          window.location.protocol +
          "//" +
          window.location.host +
          "/TimeCardReport";
        window.open(path, "_blank");
      }
    } else {
      toast.error("Please Select at Least One Record");
      $(".close").click();
      // $('#employee_reports').modal('hide');
      // $('body').removeClass('modal-open');
      // $('.modal-backdrop').remove();
    }
  };
  get_prlemp = async () => {
    $("#freshbtn").addClass("moving");
    this.setState({
      isLoading: true
    });
    var bodypost={
      tenant: localStorage.getItem("useableGuid"),
      Batch: this.state.bacth_number
    }
    var data=[];
var lastflag=false;
while(lastflag===false){   
   await API.post("getprlemp", "/getprlemp", {
      body:bodypost
    })
      .then(response => {
      response.Items.map(s=>{
        data.push(s);
      })
      if (response.LastEvaluatedKey) { 
        bodypost.LastEvaluatedKey = response.LastEvaluatedKey
        } else {
          lastflag = true
        }
      })
      .catch(err => console.log(err));
}

data.map(item => {
  item.checked = false;
  item.chkBoxName = item.Guid;
  });
  //get approver group name and put it to array
  data.map((d, i) => {
  d.ApproverGroupName = "-";
  d.ApproverGroupCompleteName = "-";
  if (d.Employee.length > 0) {
  this.state.payrollEdit_EntryDet_ApproverGroupOpt &&
  this.state.payrollEdit_EntryDet_ApproverGroupOpt.forEach((a, i) => {
  //selecting approver group
  if (a.Guid == d.Employee[0].ApproverGroup) {
  d.ApproverGroupName = (a.ApproverGroup[0].FirstName && a.ApproverGroup[0].FirstName !== null) ? a.ApproverGroup[0].FirstName : "-";
  d.ApproverGroupCompleteName = (a.GroupName && a.GroupName !== null) ? a.GroupName : "-";
  }
  
  //selecting current approver
  if (
  d.current_approver !== "No user" &&
  d.approve_status !== "Approved"
  ) {
  a.ApproverSetup &&
  a.ApproverSetup.forEach((s, i) => {
  if (s.approver_id == d.current_approver) {
  d.currentname = s.approver_name;
  }
  });
  } else {
  d.currentname = "-";
  }
  //selecting last approver approved by
  if (d.current_approver !== "No user") {
  let index = d.approver_chain.indexOf(d.current_approver);
  let cAppId;
  
  if (d.approve_status === "Approved") {
  cAppId = d.approver_chain[index];
  } else {
  cAppId = d.approver_chain[index - 1];
  }
  a.ApproverSetup &&
  a.ApproverSetup.forEach((s, i) => {
  if (s.approver_id === cAppId) {
  d.lastapprover = s.approver_name;
  }
  });
  if (!d.lastapprover) {
  d.lastapprover = "-";
  }
  } else {
  d.lastapprover = "-";
  }
  });
  } else {
  d.ApproverGroupName = "-"
  d.ApproverGroupCompleteName = "-"
  d.currentname = "-";
  d.lastapprover = "-";
  
  }
  });

 await this.setState({
    payrollEditTableData: data,
    payrollEditTableDataCloned: data // for searching, so by this it can't effect on orignal state
    });
    if (this.state.payrollEditTableData.length > 0) {
      this.setState({
        testallcheck: this.state.payrollEditTableData.filter(val => {
          if (val.Employee.length > 0) {
            return (
              val.Employee[0].ApproverGroup === null ||
              val.group_guid === null ||
              (val.Employee.length < 1 && val.freelanceuser.length > 0)
            );
          } else {
            return true;
          }
        })
      });
    }
   await this.oldSorting();
    this.setState({
      isLoading: false
    });
    $("#freshbtn").removeClass("moving");
  };

  issue_ScrollHandler = e => {
    $("body").css("overflow", "hidden");
    $("#batched_edit_pop").css("overflow-y", "auto");
    this.setState({
      batch_chk_mon: false,
      batch_chk_tue: false,
      batch_chk_wed: false,
      batch_chk_thu: false,
      batch_chk_fri: false,
      batch_chk_sat: false,
      batch_chk_sun: false,
      everyone_batch_date: ""
    });
  };

  after_issueScrollHandler = e => {
    $("body").removeAttr("style");
  };

  payrllbatches_EditInputHandler = e => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState(
      {
        [name]: value
      },
      this.validateField(name, value)
    );
  };

  get_Approvergroups = async () => {
    this.setState({
      isLoading: true
    });
   // toast.success("Fetching Groups list...");
    await API.post("getallusersag", "/getallusersag").then(data => {
      this.setState({ payrollEdit_EntryDet_ApproverGroupOpt: data });
    });
    this.setState({
      isLoading: false
    });
  };
  getAllEmployees = async () => {
    await API.post("getallemployees", "/getallemployees", {
      body: {
        tenant: localStorage.getItem("useableGuid")
      }
    })
      .then(data => {
        data.map(item => {
          item.hi = "1";
          item.NewApproverGroup =
            item.ApproverGroup === null || item.ApproverGroup === undefined
              ? "NA"
              : item.ApproverGroup;
          item.NewEmail =
            item.Email === null || item.Email === undefined ? "NA" : item.Email;
          item.NewMobile =
            item.Mobile === null || item.Mobile === undefined
              ? "NA"
              : item.Mobile;
        });

        let Tab = localStorage.getItem("payrollBatchesEdit");

        if (Tab === "FirstName") {
          $("#FirstNameCaret").show();
          $("#LastNameCaret").hide();
          $("#MobileCaret").hide();
          $("#EmailCaret").hide();
          $("#DepartmentCaret").hide();
          $("#PositionCaret").hide();
          $("#ApproverGroupCaret").hide();

          this.setState({
            AdTimes2_NameSearchModal_SelectedTab: Tab,
            AdTimes2_NameModal_FirstName_Toggle: true,
            AdTimes2_NameModal_LastName_Toggle: false,
            AdTimes2_NameModal_Email_Toggle: false,
            AdTimes2_NameModal_Mobile_Toggle: false,
            AdTimes2_NameModal_Dept_Toggle: false,
            AdTimes2_NameModal_Position_Toggle: false,
            AdTimes2_NameModal_ApproverGroup_Toggle: false,
            adTimes_NonTerminateEmp: data
              .filter(item => {
                return item.Terminated !== "T";
              })
              .sort(function (a, b) {
                var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
                var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
              }),
            adTimes_NonTerminateEmpSearch: data
              .filter(item => {
                return item.Terminated !== "T";
              })
              .sort(function (a, b) {
                var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
                var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
              }),
            ad_times_all_employess: data.sort(function (a, b) {
              var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
              var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            }),

            ad_times_all_employess_search: data.sort(function (a, b) {
              var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
              var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            })
          });
        } else if (Tab === "LastName") {
          $("#FirstNameCaret").hide();
          $("#LastNameCaret").show();
          $("#MobileCaret").hide();
          $("#EmailCaret").hide();
          $("#DepartmentCaret").hide();
          $("#PositionCaret").hide();
          $("#ApproverGroupCaret").hide();

          this.setState({
            AdTimes2_NameSearchModal_SelectedTab: Tab,
            AdTimes2_NameModal_FirstName_Toggle: false,
            AdTimes2_NameModal_LastName_Toggle: true,
            AdTimes2_NameModal_Email_Toggle: false,
            AdTimes2_NameModal_Mobile_Toggle: false,
            AdTimes2_NameModal_Dept_Toggle: false,
            AdTimes2_NameModal_Position_Toggle: false,
            AdTimes2_NameModal_ApproverGroup_Toggle: false,
            adTimes_NonTerminateEmp: data
              .filter(item => {
                return item.Terminated !== "T";
              })
              .sort(function (a, b) {
                var nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
                var nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
              }),
            adTimes_NonTerminateEmpSearch: data
              .filter(item => {
                return item.Terminated !== "T";
              })
              .sort(function (a, b) {
                var nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
                var nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
              }),
            ad_times_all_employess: data.sort(function (a, b) {
              var nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
              var nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            }),

            ad_times_all_employess_search: data.sort(function (a, b) {
              var nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
              var nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            })
          });
        } else if (Tab === "Email") {
          $("#FirstNameCaret").hide();
          $("#LastNameCaret").hide();
          $("#MobileCaret").hide();
          $("#EmailCaret").show();
          $("#DepartmentCaret").hide();
          $("#PositionCaret").hide();
          $("#ApproverGroupCaret").hide();

          this.setState({
            AdTimes2_NameSearchModal_SelectedTab: Tab,
            AdTimes2_NameModal_FirstName_Toggle: false,
            AdTimes2_NameModal_LastName_Toggle: false,
            AdTimes2_NameModal_Email_Toggle: true,
            AdTimes2_NameModal_Mobile_Toggle: false,
            AdTimes2_NameModal_Dept_Toggle: false,
            AdTimes2_NameModal_Position_Toggle: false,
            AdTimes2_NameModal_ApproverGroup_Toggle: false,
            adTimes_NonTerminateEmp: data
              .filter(item => {
                return item.Terminated !== "T";
              })
              .sort(function (a, b) {
                var nameA = a.NewEmail.toUpperCase(); // ignore upper and lowercase
                var nameB = b.NewEmail.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
              }),
            adTimes_NonTerminateEmpSearch: data
              .filter(item => {
                return item.Terminated !== "T";
              })
              .sort(function (a, b) {
                var nameA = a.NewEmail.toUpperCase(); // ignore upper and lowercase
                var nameB = b.NewEmail.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
              }),
            ad_times_all_employess: data.sort(function (a, b) {
              var nameA = a.NewEmail.toUpperCase(); // ignore upper and lowercase
              var nameB = b.NewEmail.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            }),

            ad_times_all_employess_search: data.sort(function (a, b) {
              var nameA = a.NewEmail.toUpperCase(); // ignore upper and lowercase
              var nameB = b.NewEmail.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            })
          });
        } else if (Tab === "Mobile") {
          $("#FirstNameCaret").hide();
          $("#LastNameCaret").hide();
          $("#MobileCaret").show();
          $("#EmailCaret").hide();
          $("#DepartmentCaret").hide();
          $("#PositionCaret").hide();
          $("#ApproverGroupCaret").hide();

          this.setState({
            AdTimes2_NameSearchModal_SelectedTab: Tab,
            AdTimes2_NameModal_FirstName_Toggle: false,
            AdTimes2_NameModal_LastName_Toggle: false,
            AdTimes2_NameModal_Email_Toggle: false,
            AdTimes2_NameModal_Mobile_Toggle: true,
            AdTimes2_NameModal_Dept_Toggle: false,
            AdTimes2_NameModal_Position_Toggle: false,
            AdTimes2_NameModal_ApproverGroup_Toggle: false,
            adTimes_NonTerminateEmp: data
              .filter(item => {
                return item.Terminated !== "T";
              })
              .sort(function (a, b) {
                var nameA = a.NewMobile.toUpperCase(); // ignore upper and lowercase
                var nameB = b.NewMobile.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
              }),
            adTimes_NonTerminateEmpSearch: data
              .filter(item => {
                return item.Terminated !== "T";
              })
              .sort(function (a, b) {
                var nameA = a.NewMobile.toUpperCase(); // ignore upper and lowercase
                var nameB = b.NewMobile.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
              }),
            ad_times_all_employess: data.sort(function (a, b) {
              var nameA = a.NewMobile.toUpperCase(); // ignore upper and lowercase
              var nameB = b.NewMobile.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            }),

            ad_times_all_employess_search: data.sort(function (a, b) {
              var nameA = a.NewMobile.toUpperCase(); // ignore upper and lowercase
              var nameB = b.NewMobile.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            })
          });
        } else if (Tab === "Department") {
          $("#FirstNameCaret").hide();
          $("#LastNameCaret").hide();
          $("#MobileCaret").hide();
          $("#EmailCaret").hide();
          $("#DepartmentCaret").show();
          $("#PositionCaret").hide();
          $("#ApproverGroupCaret").hide();

          this.setState({
            AdTimes2_NameSearchModal_SelectedTab: Tab,
            AdTimes2_NameModal_FirstName_Toggle: false,
            AdTimes2_NameModal_LastName_Toggle: false,
            AdTimes2_NameModal_Email_Toggle: false,
            AdTimes2_NameModal_Mobile_Toggle: false,
            AdTimes2_NameModal_Dept_Toggle: true,
            AdTimes2_NameModal_Position_Toggle: false,
            AdTimes2_NameModal_ApproverGroup_Toggle: false,
            adTimes_NonTerminateEmp: data
              .filter(item => {
                return item.Terminated !== "T";
              })
              .sort(function (a, b) {
                var nameA = a.Department.toUpperCase(); // ignore upper and lowercase
                var nameB = b.Department.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
              }),
            adTimes_NonTerminateEmpSearch: data
              .filter(item => {
                return item.Terminated !== "T";
              })
              .sort(function (a, b) {
                var nameA = a.Department.toUpperCase(); // ignore upper and lowercase
                var nameB = b.Department.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
              }),
            ad_times_all_employess: data.sort(function (a, b) {
              var nameA = a.Department.toUpperCase(); // ignore upper and lowercase
              var nameB = b.Department.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            }),

            ad_times_all_employess_search: data.sort(function (a, b) {
              var nameA = a.Department.toUpperCase(); // ignore upper and lowercase
              var nameB = b.Department.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            })
          });
        } else if (Tab === "Position") {
          $("#FirstNameCaret").hide();
          $("#LastNameCaret").hide();
          $("#MobileCaret").hide();
          $("#EmailCaret").hide();
          $("#DepartmentCaret").hide();
          $("#PositionCaret").show();
          $("#ApproverGroupCaret").hide();

          this.setState({
            AdTimes2_NameSearchModal_SelectedTab: Tab,
            AdTimes2_NameModal_FirstName_Toggle: false,
            AdTimes2_NameModal_LastName_Toggle: false,
            AdTimes2_NameModal_Email_Toggle: false,
            AdTimes2_NameModal_Mobile_Toggle: false,
            AdTimes2_NameModal_Dept_Toggle: false,
            AdTimes2_NameModal_Position_Toggle: true,
            AdTimes2_NameModal_ApproverGroup_Toggle: false,
            adTimes_NonTerminateEmp: data
              .filter(item => {
                return item.Terminated !== "T";
              })
              .sort(function (a, b) {
                var nameA = a.Position.toUpperCase(); // ignore upper and lowercase
                var nameB = b.Position.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
              }),
            adTimes_NonTerminateEmpSearch: data
              .filter(item => {
                return item.Terminated !== "T";
              })
              .sort(function (a, b) {
                var nameA = a.Position.toUpperCase(); // ignore upper and lowercase
                var nameB = b.Position.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
              }),
            ad_times_all_employess: data.sort(function (a, b) {
              var nameA = a.Position.toUpperCase(); // ignore upper and lowercase
              var nameB = b.Position.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            }),

            ad_times_all_employess_search: data.sort(function (a, b) {
              var nameA = a.Position.toUpperCase(); // ignore upper and lowercase
              var nameB = b.Position.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            })
          });
        } else if (Tab === "ApproverGroup") {
          $("#FirstNameCaret").hide();
          $("#LastNameCaret").hide();
          $("#MobileCaret").hide();
          $("#EmailCaret").hide();
          $("#DepartmentCaret").hide();
          $("#PositionCaret").hide();
          $("#ApproverGroupCaret").show();

          this.setState({
            AdTimes2_NameSearchModal_SelectedTab: Tab,
            AdTimes2_NameModal_FirstName_Toggle: false,
            AdTimes2_NameModal_LastName_Toggle: false,
            AdTimes2_NameModal_Email_Toggle: false,
            AdTimes2_NameModal_Mobile_Toggle: false,
            AdTimes2_NameModal_Dept_Toggle: false,
            AdTimes2_NameModal_Position_Toggle: false,
            AdTimes2_NameModal_ApproverGroup_Toggle: true,
            adTimes_NonTerminateEmp: data
              .filter(item => {
                return item.Terminated !== "T";
              })
              .sort(function (a, b) {
                var nameA = a.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
                var nameB = b.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
              }),
            adTimes_NonTerminateEmpSearch: data
              .filter(item => {
                return item.Terminated !== "T";
              })
              .sort(function (a, b) {
                var nameA = a.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
                var nameB = b.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
              }),
            ad_times_all_employess: data.sort(function (a, b) {
              var nameA = a.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
              var nameB = b.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            }),

            ad_times_all_employess_search: data.sort(function (a, b) {
              var nameA = a.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
              var nameB = b.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            })
          });
        } else {
          $("#FirstNameCaret").show();
          $("#LastNameCaret").hide();
          $("#MobileCaret").hide();
          $("#EmailCaret").hide();
          $("#DepartmentCaret").hide();
          $("#PositionCaret").hide();
          $("#ApproverGroupCaret").hide();
          this.setState({
            AdTimes2_NameSearchModal_SelectedTab: "FirstName",
            AdTimes2_NameModal_FirstName_Toggle: true,
            AdTimes2_NameModal_LastName_Toggle: false,
            AdTimes2_NameModal_Email_Toggle: false,
            AdTimes2_NameModal_Mobile_Toggle: false,
            AdTimes2_NameModal_Dept_Toggle: false,
            AdTimes2_NameModal_Position_Toggle: false,
            AdTimes2_NameModal_ApproverGroup_Toggle: false,
            adTimes_NonTerminateEmp: data
              .filter(item => {
                return item.Terminated !== "T";
              })
              .sort(function (a, b) {
                var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
                var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
              }),
            adTimes_NonTerminateEmpSearch: data
              .filter(item => {
                return item.Terminated !== "T";
              })
              .sort(function (a, b) {
                var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
                var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
              }),
            ad_times_all_employess: data.sort(function (a, b) {
              var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
              var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            }),

            ad_times_all_employess_search: data.sort(function (a, b) {
              var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
              var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            })
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
    await this.setState({
      isLoading: false
    });
  };

  sortEmployees = async (Tab, name) => {
    await this.setState({
      toggleEmployeeCaret: !this.state.toggleEmployeeCaret
    });
    if (this.state.toggleEmployeeCaret === false) {
      $("#" + name).removeAttr("style");
    }

    localStorage.setItem("payrollBatchesEdit", Tab);
    if (this.state.Adtimes2_Modal2SearchName_TerminatedChkbx) {
      if (Tab === "FirstName") {
        $("#FirstNameCaret").show();
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();

        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false
        });

        if (this.state.AdTimes2_NameModal_FirstName_Toggle) {
          await this.setState({
            AdTimes2_NameModal_FirstName_Toggle: false
          });
        } else {
          await this.setState({
            AdTimes2_NameModal_FirstName_Toggle: true
          });
        }
        if (this.state.AdTimes2_NameModal_FirstName_Toggle) {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(
              function (a, b) {
                var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
                var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        } else {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(
              function (a, b) {
                var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
                var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
                if (nameA > nameB) {
                  return -1;
                }
                if (nameA < nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        }
      }
      if (Tab === "LastName") {
        $("#FirstNameCaret").hide();
        $("#LastNameCaret").show();
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();

        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false
        });

        if (this.state.AdTimes2_NameModal_LastName_Toggle) {
          await this.setState({
            AdTimes2_NameModal_LastName_Toggle: false
          });
        } else {
          await this.setState({
            AdTimes2_NameModal_LastName_Toggle: true
          });
        }
        if (this.state.AdTimes2_NameModal_LastName_Toggle) {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(
              function (a, b) {
                var nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
                var nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        } else {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(
              function (a, b) {
                var nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
                var nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
                if (nameA > nameB) {
                  return -1;
                }
                if (nameA < nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        }
      }
      if (Tab === "Email") {
        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").show();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();

        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false
        });

        if (this.state.AdTimes2_NameModal_Email_Toggle) {
          await this.setState({
            AdTimes2_NameModal_Email_Toggle: false
          });
        } else {
          await this.setState({
            AdTimes2_NameModal_Email_Toggle: true
          });
        }
        if (this.state.AdTimes2_NameModal_Email_Toggle) {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(
              function (a, b) {
                var nameA = a.NewEmail.toUpperCase(); // ignore upper and lowercase
                var nameB = b.NewEmail.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        } else {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(
              function (a, b) {
                var nameA = a.NewEmail.toUpperCase(); // ignore upper and lowercase
                var nameB = b.NewEmail.toUpperCase(); // ignore upper and lowercase
                if (nameA > nameB) {
                  return -1;
                }
                if (nameA < nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        }
      }
      if (Tab === "Mobile") {
        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").show();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();

        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false
        });

        if (this.state.AdTimes2_NameModal_Mobile_Toggle) {
          await this.setState({
            AdTimes2_NameModal_Mobile_Toggle: false
          });
        } else {
          await this.setState({
            AdTimes2_NameModal_Mobile_Toggle: true
          });
        }
        if (this.state.AdTimes2_NameModal_Mobile_Toggle) {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(
              function (a, b) {
                var nameA = a.NewMobile.toUpperCase(); // ignore upper and lowercase
                var nameB = b.NewMobile.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        } else {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(
              function (a, b) {
                var nameA = a.NewMobile.toUpperCase(); // ignore upper and lowercase
                var nameB = b.NewMobile.toUpperCase(); // ignore upper and lowercase
                if (nameA > nameB) {
                  return -1;
                }
                if (nameA < nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        }
      }
      if (Tab === "Department") {
        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").show();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();

        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false
        });

        if (this.state.AdTimes2_NameModal_Dept_Toggle) {
          await this.setState({
            AdTimes2_NameModal_Dept_Toggle: false
          });
        } else {
          await this.setState({
            AdTimes2_NameModal_Dept_Toggle: true
          });
        }
        if (this.state.AdTimes2_NameModal_Dept_Toggle) {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(
              function (a, b) {
                var nameA = a.Department.toUpperCase(); // ignore upper and lowercase
                var nameB = b.Department.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        } else {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(
              function (a, b) {
                var nameA = a.Department.toUpperCase(); // ignore upper and lowercase
                var nameB = b.Department.toUpperCase(); // ignore upper and lowercase
                if (nameA > nameB) {
                  return -1;
                }
                if (nameA < nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        }
      }
      if (Tab === "Position") {
        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").show();
        $("#ApproverGroupCaret").hide();

        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false
        });

        if (this.state.AdTimes2_NameModal_Position_Toggle) {
          await this.setState({
            AdTimes2_NameModal_Position_Toggle: false
          });
        } else {
          await this.setState({
            AdTimes2_NameModal_Position_Toggle: true
          });
        }
        if (this.state.AdTimes2_NameModal_Position_Toggle) {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(
              function (a, b) {
                var nameA = a.Position.toUpperCase(); // ignore upper and lowercase
                var nameB = b.Position.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        } else {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(
              function (a, b) {
                var nameA = a.Position.toUpperCase(); // ignore upper and lowercase
                var nameB = b.Position.toUpperCase(); // ignore upper and lowercase
                if (nameA > nameB) {
                  return -1;
                }
                if (nameA < nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        }
      }
      if (Tab === "ApproverGroup") {
        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").show();

        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false
        });

        if (this.state.AdTimes2_NameModal_ApproverGroup_Toggle) {
          await this.setState({
            AdTimes2_NameModal_ApproverGroup_Toggle: false
          });
        } else {
          await this.setState({
            AdTimes2_NameModal_ApproverGroup_Toggle: true
          });
        }
        if (this.state.AdTimes2_NameModal_ApproverGroup_Toggle) {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(
              function (a, b) {
                var nameA = a.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
                var nameB = b.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        } else {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(
              function (a, b) {
                var nameA = a.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
                var nameB = b.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
                if (nameA > nameB) {
                  return -1;
                }
                if (nameA < nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        }
      }
    } else {
      if (Tab === "FirstName") {
        $("#FirstNameCaret").show();
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();

        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false
        });

        if (this.state.AdTimes2_NameModal_FirstName_Toggle) {
          await this.setState({
            AdTimes2_NameModal_FirstName_Toggle: false
          });
        } else {
          await this.setState({
            AdTimes2_NameModal_FirstName_Toggle: true
          });
        }
        if (this.state.AdTimes2_NameModal_FirstName_Toggle) {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(
              function (a, b) {
                var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
                var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        } else {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(
              function (a, b) {
                var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
                var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
                if (nameA > nameB) {
                  return -1;
                }
                if (nameA < nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        }
      }
      if (Tab === "LastName") {
        $("#FirstNameCaret").hide();
        $("#LastNameCaret").show();
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();

        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false
        });

        if (this.state.AdTimes2_NameModal_LastName_Toggle) {
          await this.setState({
            AdTimes2_NameModal_LastName_Toggle: false
          });
        } else {
          await this.setState({
            AdTimes2_NameModal_LastName_Toggle: true
          });
        }
        if (this.state.AdTimes2_NameModal_LastName_Toggle) {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(
              function (a, b) {
                var nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
                var nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        } else {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(
              function (a, b) {
                var nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
                var nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
                if (nameA > nameB) {
                  return -1;
                }
                if (nameA < nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        }
      }
      if (Tab === "Email") {
        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").show();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();

        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false
        });

        if (this.state.AdTimes2_NameModal_Email_Toggle) {
          await this.setState({
            AdTimes2_NameModal_Email_Toggle: false
          });
        } else {
          await this.setState({
            AdTimes2_NameModal_Email_Toggle: true
          });
        }
        if (this.state.AdTimes2_NameModal_Email_Toggle) {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(
              function (a, b) {
                var nameA = a.NewEmail.toUpperCase(); // ignore upper and lowercase
                var nameB = b.NewEmail.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        } else {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(
              function (a, b) {
                var nameA = a.NewEmail.toUpperCase(); // ignore upper and lowercase
                var nameB = b.NewEmail.toUpperCase(); // ignore upper and lowercase
                if (nameA > nameB) {
                  return -1;
                }
                if (nameA < nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        }
      }
      if (Tab === "Mobile") {
        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").show();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();

        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false
        });

        if (this.state.AdTimes2_NameModal_Mobile_Toggle) {
          await this.setState({
            AdTimes2_NameModal_Mobile_Toggle: false
          });
        } else {
          await this.setState({
            AdTimes2_NameModal_Mobile_Toggle: true
          });
        }
        if (this.state.AdTimes2_NameModal_Mobile_Toggle) {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(
              function (a, b) {
                var nameA = a.NewMobile.toUpperCase(); // ignore upper and lowercase
                var nameB = b.NewMobile.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        } else {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(
              function (a, b) {
                var nameA = a.NewMobile.toUpperCase(); // ignore upper and lowercase
                var nameB = b.NewMobile.toUpperCase(); // ignore upper and lowercase
                if (nameA > nameB) {
                  return -1;
                }
                if (nameA < nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        }
      }
      if (Tab === "Department") {
        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").show();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();

        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false
        });

        if (this.state.AdTimes2_NameModal_Dept_Toggle) {
          await this.setState({
            AdTimes2_NameModal_Dept_Toggle: false
          });
        } else {
          await this.setState({
            AdTimes2_NameModal_Dept_Toggle: true
          });
        }
        if (this.state.AdTimes2_NameModal_Dept_Toggle) {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(
              function (a, b) {
                var nameA = a.Department.toUpperCase(); // ignore upper and lowercase
                var nameB = b.Department.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        } else {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(
              function (a, b) {
                var nameA = a.Department.toUpperCase(); // ignore upper and lowercase
                var nameB = b.Department.toUpperCase(); // ignore upper and lowercase
                if (nameA > nameB) {
                  return -1;
                }
                if (nameA < nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        }
      }
      if (Tab === "Position") {
        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").show();
        $("#ApproverGroupCaret").hide();

        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false
        });

        if (this.state.AdTimes2_NameModal_Position_Toggle) {
          await this.setState({
            AdTimes2_NameModal_Position_Toggle: false
          });
        } else {
          await this.setState({
            AdTimes2_NameModal_Position_Toggle: true
          });
        }
        if (this.state.AdTimes2_NameModal_Position_Toggle) {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(
              function (a, b) {
                var nameA = a.Position.toUpperCase(); // ignore upper and lowercase
                var nameB = b.Position.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        } else {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(
              function (a, b) {
                var nameA = a.Position.toUpperCase(); // ignore upper and lowercase
                var nameB = b.Position.toUpperCase(); // ignore upper and lowercase
                if (nameA > nameB) {
                  return -1;
                }
                if (nameA < nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        }
      }
      if (Tab === "ApproverGroup") {
        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").show();

        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false
        });

        if (this.state.AdTimes2_NameModal_ApproverGroup_Toggle) {
          await this.setState({
            AdTimes2_NameModal_ApproverGroup_Toggle: false
          });
        } else {
          await this.setState({
            AdTimes2_NameModal_ApproverGroup_Toggle: true
          });
        }
        if (this.state.AdTimes2_NameModal_ApproverGroup_Toggle) {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(
              function (a, b) {
                var nameA = a.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
                var nameB = b.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        } else {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(
              function (a, b) {
                var nameA = a.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
                var nameB = b.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
                if (nameA > nameB) {
                  return -1;
                }
                if (nameA < nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              }
            )
          });
        }
      }
    }
  };

  PayrollBatchesSelectHandler = e => {
    if (e.target.name === "AdTimes2_EditModal_PayAs") {
      if (e.target.selectedIndex > 0) {
        this.setState({
          AdTimes2_EditModal_PayAs: e.target.value,
          IsAdTimes2_EditModal_PayAsValid: true
        });
      } else if (e.target.selectedIndex === 0) {
        this.setState({
          AdTimes2_EditModal_PayAs: "",
          IsAdTimes2_EditModal_PayAsValid: false
        });
      }
    }

    if (e.target.name === "AdTimes2_AdTimeForm_MealBreak") {
      if (e.target.selectedIndex > 0) {
        this.setState({
          AdTimes2_AdTimeForm_MealBreak: e.target.value,
          IsAdTimes2_AdTimeForm_MealBreakValid: true
        });
      } else if (e.target.selectedIndex === 0) {
        this.setState({
          AdTimes2_AdTimeForm_MealBreak: "",
          IsAdTimes2_AdTimeForm_MealBreakValid: false
        });
      }
    }
  };

  adTimes2_SearchHandler = event => {
    var AdSearchTab = this.state.AdTimes2SelectedTab;
    var text = event.target.value;

    if (AdSearchTab === "FirstName") {
      this.setState({
        adtimes2_tableSearchBox: event.target.value
      });
      this.setState({
        AdTimes2TableData: this.state.AdTimes2_searchedData.filter(function (
          setup
        ) {
          return setup.FirstName.toUpperCase().includes(text.toUpperCase());
        })
      });
    }
    if (AdSearchTab === "LastName") {
      this.setState({
        adtimes2_tableSearchBox: event.target.value
      });
      this.setState({
        AdTimes2TableData: this.state.AdTimes2_searchedData.filter(function (
          setup
        ) {
          return setup.LastName.toUpperCase().includes(text.toUpperCase());
        })
      });
    }
    if (AdSearchTab === "Position") {
      this.setState({
        adtimes2_tableSearchBox: event.target.value
      });
      this.setState({
        AdTimes2TableData: this.state.AdTimes2_searchedData.filter(function (
          setup
        ) {
          return setup.Position.toUpperCase().includes(text.toUpperCase());
        })
      });
    }

    if (AdSearchTab === "Department") {
      this.setState({
        adtimes2_tableSearchBox: event.target.value
      });
      this.setState({
        AdTimes2TableData: this.state.AdTimes2_searchedData.filter(function (
          setup
        ) {
          return setup.Dept.toUpperCase().includes(text.toUpperCase());
        })
      });
    }

    if (AdSearchTab === "TravelTo") {
      this.setState({
        adtimes2_tableSearchBox: event.target.value
      });
      this.setState({
        AdTimes2TableData: this.state.AdTimes2_searchedData.filter(function (
          setup
        ) {
          return setup.TravelTo.toUpperCase().includes(text.toUpperCase());
        })
      });
    }

    if (AdSearchTab === "Start") {
      this.setState({
        adtimes2_tableSearchBox: event.target.value
      });
      this.setState({
        AdTimes2TableData: this.state.AdTimes2_searchedData.filter(function (
          setup
        ) {
          return setup.Start.toUpperCase().includes(text.toUpperCase());
        })
      });
    }

    if (AdSearchTab === "Meal") {
      this.setState({
        adtimes2_tableSearchBox: event.target.value
      });
      this.setState({
        AdTimes2TableData: this.state.AdTimes2_searchedData.filter(function (
          setup
        ) {
          return setup.meals.toUpperCase().includes(text.toUpperCase());
        })
      });
    }

    if (AdSearchTab === "Wrap") {
      this.setState({
        adtimes2_tableSearchBox: event.target.value
      });
      this.setState({
        AdTimes2TableData: this.state.AdTimes2_searchedData.filter(function (
          setup
        ) {
          return setup.Finish.toUpperCase().includes(text.toUpperCase());
        })
      });
    }

    if (AdSearchTab === "TravelFrom") {
      this.setState({
        adtimes2_tableSearchBox: event.target.value
      });
      this.setState({
        AdTimes2TableData: this.state.AdTimes2_searchedData.filter(function (
          setup
        ) {
          return setup.TravelFrom.toUpperCase().includes(text.toUpperCase());
        })
      });
    }

    if (AdSearchTab === "PmtFlag") {
      this.setState({
        adtimes2_tableSearchBox: event.target.value
      });
      this.setState({
        AdTimes2TableData: this.state.AdTimes2_searchedData.filter(function (
          setup
        ) {
          return setup.NewPaymentFlag.toUpperCase().includes(
            text.toUpperCase()
          );
        })
      });
    }
  };

  validateField = (fieldName, value) => {
    let payrollEdit_EntryDetErrors = this.state.payrollEdit_EntryDetErrors;
    let payrollEdit_SendApprovalTableBtnErrors = this.state
      .payrollEdit_SendApprovalTableBtnErrors;
    let payrollEdit_EntryDet_EmployeeSearchInputValid = this.state
      .payrollEdit_EntryDet_EmployeeSearchInputValid;
    let payrollEdit_EntryDet_PeriodEndingValid = this.state
      .payrollEdit_EntryDet_PeriodEndingValid;
    let payrollEdit_EntryDet_DepartmentValid = this.state
      .payrollEdit_EntryDet_DepartmentValid;
    let payrollEdit_EntryDet_PositionValid = this.state
      .payrollEdit_EntryDet_PositionValid;
    let payrollEdit_EntryDet_EmailValid = this.state
      .payrollEdit_EntryDet_EmailValid;

    let payrollEdit_sendApprovalTable_btnInputValid = this.state
      .payrollEdit_sendApprovalTable_btnInputValid;

    switch (fieldName) {
      case "payrollEdit_EntryDet_EmployeeSearchInput":
        payrollEdit_EntryDet_EmployeeSearchInputValid = value.length > 0;
        payrollEdit_EntryDetErrors.payrollEdit_EntryDet_EmployeeSearchInput = payrollEdit_EntryDet_EmployeeSearchInputValid
          ? ""
          : "This field is required";
        break;
      case "payrollEdit_EntryDet_PeriodEnding":
        payrollEdit_EntryDet_PeriodEndingValid = value.length > 0;
        payrollEdit_EntryDetErrors.payrollEdit_EntryDet_PeriodEnding = payrollEdit_EntryDet_PeriodEndingValid
          ? ""
          : "This field is required";
        break;
      case "payrollEdit_EntryDet_Department":
        payrollEdit_EntryDet_DepartmentValid = value.length > 0;
        payrollEdit_EntryDetErrors.payrollEdit_EntryDet_Department = payrollEdit_EntryDet_DepartmentValid
          ? ""
          : "This field is required";
        break;
      case "payrollEdit_EntryDet_Position":
        payrollEdit_EntryDet_PositionValid = value.length > 0;
        payrollEdit_EntryDetErrors.payrollEdit_EntryDet_Position = payrollEdit_EntryDet_PositionValid
          ? ""
          : "This field is required";
        break;
      case "payrollEdit_EntryDet_Email":
        payrollEdit_EntryDet_EmailValid = value.match(
          /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
        );
        payrollEdit_EntryDetErrors.payrollEdit_EntryDet_Email = payrollEdit_EntryDet_EmailValid
          ? ""
          : "Email should be username@domain.com";
        break;

      case "payrollEdit_sendApprovalTable_btnInput":
        payrollEdit_sendApprovalTable_btnInputValid = value.length > 0;
        payrollEdit_SendApprovalTableBtnErrors.payrollEdit_sendApprovalTable_btnInput = payrollEdit_sendApprovalTable_btnInputValid
          ? ""
          : "This field is required";
        break;
      default:
        break;
    }

    this.setState(
      {
        payrollEdit_EntryDetErrors: payrollEdit_EntryDetErrors,
        payrollEdit_EntryDet_EmployeeSearchInputValid: payrollEdit_EntryDet_EmployeeSearchInputValid,
        payrollEdit_EntryDet_PeriodEndingValid: payrollEdit_EntryDet_PeriodEndingValid,
        payrollEdit_EntryDet_DepartmentValid: payrollEdit_EntryDet_DepartmentValid,
        payrollEdit_EntryDet_PositionValid: payrollEdit_EntryDet_PositionValid,
        payrollEdit_EntryDet_EmailValid: payrollEdit_EntryDet_EmailValid,

        payrollEdit_sendApprovalTable_btnInputValid: payrollEdit_sendApprovalTable_btnInputValid,
        payrollEdit_SendApprovalTableBtnErrors: payrollEdit_SendApprovalTableBtnErrors
      },
      this.validateFormPayrollEdit
    );
  };

  payrollEditEntryApprvGrpSelectHandler = async e => {
    let name = e.target.name;
    let value = e.target.value;
    if (e.target.selectedIndex > 0) {
      await this.setState(
        {
          payrollEdit_EntrDet_ApprvGrpValid: true,
          payrollEdit_EntryDet_ApprovSelectedOption: e.target.value
        },
        () => {
          this.validateField(name, value);
        }
      );

      await this.geting_approver(value);
      //await this.saving_approver();
    } else if (e.target.selectedIndex === 0) {
      await this.setState(
        {
          payrollEdit_EntryDet_ApprovSelectedOption: "",
          payrollEdit_EntrDet_ApprvGrpValid: false
        },
        () => {
          this.validateField(name, value);
        }
      );
      await this.geting_approver(value);
    }
  };

  AllCheckBoxPayrollEditHandler = async (CheckBoxName, isChecked) => {
    let isAllChecked =
      CheckBoxName === "IsAllpayrollEditTableCheckBoxSelected" && isChecked;
    let isAllUnChecked =
      CheckBoxName === "IsAllpayrollEditTableCheckBoxSelected" && !isChecked;
    const checked = isChecked;

    const checkList = this.state.payrollEditTableData.map((item, index) => {
      if (isAllChecked || item.chkBoxName === CheckBoxName) {
        return Object.assign({}, item, {
          checked
        });
      } else if (isAllUnChecked) {
        return Object.assign({}, item, {
          checked: false
        });
      }

      return item;
    });

    let isAllSelected =
      checkList.findIndex(item => item.checked === false) === -1 ||
      isAllChecked;

   await this.setState({
      IsAllpayrollEditTableCheckBoxSelected: isAllSelected,
      payrollEditTableData: checkList
    });
  await this.countrecords()
  };

  onPayrollEntryDetSaveHandler = async () => {
    let payrollEdit_EntryDetErrors = this.state.payrollEdit_EntryDetErrors;
    let payrollEdit_EntryDet_EmployeeSearchInputValid = this.state
      .payrollEdit_EntryDet_EmployeeSearchInputValid;
    let payrollEdit_EntryDet_PeriodEndingValid = this.state
      .payrollEdit_EntryDet_PeriodEndingValid;
    let payrollEdit_EntryDet_DepartmentValid = this.state
      .payrollEdit_EntryDet_DepartmentValid;
    let payrollEdit_EntryDet_PositionValid = this.state
      .payrollEdit_EntryDet_PositionValid;
    let payrollEdit_EntryDet_EmailValid = this.state
      .payrollEdit_EntryDet_EmailValid;

    if (!payrollEdit_EntryDet_EmployeeSearchInputValid) {
      payrollEdit_EntryDetErrors.payrollEdit_EntryDet_EmployeeSearchInput =
        "This field is required";
    }
    if (!payrollEdit_EntryDet_PeriodEndingValid) {
      payrollEdit_EntryDetErrors.payrollEdit_EntryDet_PeriodEnding =
        "This field is required";
    }
    if (!payrollEdit_EntryDet_DepartmentValid) {
      payrollEdit_EntryDetErrors.payrollEdit_EntryDet_Department =
        "This field is required";
    }
    if (!payrollEdit_EntryDet_PositionValid) {
      payrollEdit_EntryDetErrors.payrollEdit_EntryDet_Position =
        "This field is required";
    }
    if (!payrollEdit_EntryDet_EmailValid) {
      payrollEdit_EntryDetErrors.payrollEdit_EntryDet_Email =
        "This field is required";
    }

    let formValid =
      this.state.payrollEdit_EntryDet_EmployeeSearchInputValid &&
      this.state.payrollEdit_EntryDet_PeriodEndingValid &&
      this.state.payrollEdit_EntryDet_DepartmentValid &&
      this.state.payrollEdit_EntryDet_PositionValid &&
      this.state.payrollEdit_EntryDet_EmailValid;

    await this.setState({
      payrollEdit_EntryDetErrors: payrollEdit_EntryDetErrors,
      IspayrollEntrDetFormValid: formValid
    });

    if (this.state.IspayrollEntrDetFormValid) {
      await this.setState({
        isLoading: true
      });

      let object = [
        {
          stdtimes: this.state.stdtimes,
          Address: this.state.Employee_Address,
          AgentCode: this.state.Employee_Agcode,
          AliasFirstName: this.state.Employee_AliasFirstName,
          AliasLastName: this.state.Employee_AliasLastName,
          ApproverGroup:
            this.state.payrollEdit_EntryDet_ApprovSelectedOption === ""
              ? null
              : this.state.payrollEdit_EntryDet_ApprovSelectedOption,
          attachments: this.state.payrollAttachments,
          Department: this.state.payrollEdit_EntryDet_Department,
          Email: this.state.payrollEdit_EntryDet_Email,
          EmpBasis: this.state.Employee_EmpBasis,
          EmpGuid: this.state.Employee_EmpGuid,
          FirstName: this.state.payrollEdit_EntryDet_EmployeeSearchInput,
          GlobalCode: this.state.Employee_GlobalCode,
          Guid: this.state.Employee_Guid,
          HourlyRate: this.state.Employee_HourlyRate,
          LastName: this.state.Employee_LastName,
          Mobile: this.state.Employee_Mobile,
          PaymentFlags: this.state.Employee_PaymentFlags,
          Position: this.state.payrollEdit_EntryDet_Position,
          StartDate: this.state.payrollEdit_EntryDet_StartDate,
          StateTax:
            this.state.payrollEdit_EntryDet_StateTax === ""
              ? null
              : this.state.payrollEdit_EntryDet_StateTax,
          TemplateCode: this.state.Employee_TemplateCode,
          TenantGuid: this.state.Employee_TenantGuid,
          Terminated: this.state.payrollEdit_EntryDet_TerminatedChkBox
            ? "T"
            : "F",
          TerminationDate: this.state.payrollEdit_EntryDet_EndDate,
          TimecardGroup:
            this.state.payrollEdit_EntryDet_Group === ""
              ? null
              : this.state.payrollEdit_EntryDet_Group
        }
      ];

      if (this.state.editableguid === "") {
        await this.saving_approver();

        var date_matched = [];
        let array_to_send = [];
        for (var i = 0; i < 7; i++) {
          date_matched.push(
            moment(new Date(this.state.payrollEdit_EntryDet_PeriodEnding))
              .subtract(i, "days")
              .format("YYYY-MM-DD")
          );
        }

        //fetching adtimes for submit
        await API.post("getadlistbydate", "/getadlistbydate", {
          body: {
            tenant: localStorage.getItem("useableGuid"),
            dates: date_matched
          }
        })
          .then(data => {
            data.map(value => {
              array_to_send.push(value.Guid);
            });
          })
          .catch(error => {
            toast.error("fetching adlist error");
          });


        await API.post("getadtimesbyadlistguid", "/getadtimesbyadlistguid", {
          body: {
            adlistGuid: array_to_send,
            EmployeeGuid: object[0].Guid
          }
        })
          .then(data => {
            date_matched.map((value, index) => {

              var x = false;
              data.map((val, ind) => {

                if (value === moment(new Date(val.adlistdate)).format("YYYY-MM-DD").toString()) {

                  x = true;

                }

              });
              if (x === false) {



                var st = {
                  "adlistdate": moment(new Date(value)),
                  "adlistGuid": "static",
                  "ChartCode": null,
                  "ChartSort": null,
                  "Dept": this.state.payrollEdit_EntryDet_Department,
                  "EmployeeGuid": this.state.Employee_EmpGuid,
                  "EmployeeName": this.state.payrollEdit_EntryDet_EmployeeSearchInput + " " + this.state.Employee_LastName,
                  "Finish": "00:00",
                  "Guid": this.state.Employee_EmpGuid,
                  "HourlyRate": "00.00",
                  "mealBreak": [
                    {
                      "AD1Finish": "00:00",
                      "AD1Start": "00:00",
                      "Deductable": {
                        "AD": false,
                        "Time": false
                      },
                      "MB1Finish": "00:00",
                      "MB1Start": "00:00"
                    },
                    {
                      "AD2Finish": "00:00",
                      "AD2Start": "00:00",
                      "Deductable": {
                        "AD": false,
                        "Time": false
                      },
                      "MB2Finish": "00:00",
                      "MB2Start": "00:00"
                    },
                    {
                      "AD3Finish": "00:00",
                      "AD3Start": "00:00",
                      "Deductable": {
                        "AD": false,
                        "Time": false
                      },
                      "MB3Finish": "00:00",
                      "MB3Start": "00:00"
                    }
                  ],
                  "meals": "00:00",
                  "Note": null,
                  "Payas": "CON",
                  "PaymentFlag": null,
                  "Position": "PRODUCTION RUNNER",
                  "Start": "00:00",
                  "TenantGuid": "uuijjj",
                  "TotalHours": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
                  "TrackingCodes": JSON.parse(localStorage.getItem('completetenant')).TrackingFields,
                  "TravelFrom": "00:00",
                  "TravelTo": "00:00",
                  "WorkState": null
                };



                data.push(st);



              }

            })

            this.setState({
              editADtimes: data
            });
          })
          .catch(error => {
            toast.error("fetching adtimes error");
          });

        var tmp_app = [];
        if (this.state.current_selected_group[0]) {
          this.state.current_selected_group[0].Approvers.map(value => {
            tmp_app.push(value.approver_id);
          });
        }

        var finaldata = {
          approver_chain: tmp_app,
          group_guid: this.state.current_selected_group[0]
            ? this.state.current_selected_group[0].Guid
            : null,
          current_approver: "No user",
          approve_status: "Draft",
          Batch: this.state.bacth_number,
          Employee: object,
          freelanceuser: [],
          PRLTimes: null,
          TenantGuid: localStorage.getItem("useableGuid"),
          ChequeNumber: null,
          PayslipNote: null,
          WorkState: null,
          PayWeek: null,
          ChartSort: null,
          ChartCode: null,
          Flags: null,
          InternalNotes: null,
          PaymentFlags: null,
          ADTimes: this.state.editADtimes,
          PeriodEndingDate: this.state.payrollEdit_EntryDet_PeriodEnding,
          PayTimes:
            object[0].stdtimes.length > 0
              ? this.state.stdtimes
              : this.state.editADtimes,
          comments: [],
          changes: [],
          activities: [{
            date: moment(Date.now()).format("l h:mma"),
            msg: "Timecard created by DigitalTimes."
          }],
          senderid: null,
              timesheetImage:this.state.timesheetImage,
              freelanceuser_email:null,
freelanceuser_signature:null

        };

        await API.post("createprlemp", "/createprlemp", {
          body: finaldata
        })
          .then(data => {
           
            //toast.success("Timecard Created Successfully.");
            document.getElementById("closePayrollModal").click();
            document.getElementById("freshbtn").click();
          })
          .catch(error => {
            toast.error("Timecard creation error.");
          });
      } else {
        var date_matched = [];
        let array_to_send = [];

        //fetching adtimes for submit
        if (this.state.editPrltimes !== null) {
          this.state.editPrltimes.Times[0].DailyTimes.map(value => {
            date_matched.push(
              moment(new Date(value.Date * 1000)).format("YYYY-MM-DD")
            );
          });
        } else {
          for (var i = 0; i < 7; i++) {
            date_matched.push(
              moment(new Date(this.state.payrollEdit_EntryDet_PeriodEnding))
                .subtract(i, "days")
                .format("YYYY-MM-DD")
            );
          }
        }
        await API.post("getadlistbydate", "/getadlistbydate", {
          body: {
            tenant: localStorage.getItem("useableGuid"),
            dates: date_matched
          }
        })
          .then(data => {
            data.map(value => {
              array_to_send.push(value.Guid);
            });
          })
          .catch(error => {
            toast.error("fetching adlist error");
          });


        await API.post("getadtimesbyadlistguid", "/getadtimesbyadlistguid", {
          body: {
            adlistGuid: array_to_send,
            EmployeeGuid: object[0].Guid
          }
        })
          .then(data => {
            date_matched.map((value, index) => {

              var x = false;
              data.map((val, ind) => {

                if (value === moment(new Date(val.adlistdate)).format("YYYY-MM-DD").toString()) {

                  x = true;

                }

              });
              if (x === false) {



                var st = {
                  "adlistdate": moment(new Date(value)),
                  "adlistGuid": "static",
                  "ChartCode": null,
                  "ChartSort": null,
                  "Dept": this.state.payrollEdit_EntryDet_Department,
                  "EmployeeGuid": this.state.Employee_EmpGuid,
                  "EmployeeName": this.state.payrollEdit_EntryDet_EmployeeSearchInput + " " + this.state.Employee_LastName,
                  "Finish": "00:00",
                  "Guid": this.state.Employee_EmpGuid,
                  "HourlyRate": "00.00",
                  "mealBreak": [
                    {
                      "AD1Finish": "00:00",
                      "AD1Start": "00:00",
                      "Deductable": {
                        "AD": false,
                        "Time": false
                      },
                      "MB1Finish": "00:00",
                      "MB1Start": "00:00"
                    },
                    {
                      "AD2Finish": "00:00",
                      "AD2Start": "00:00",
                      "Deductable": {
                        "AD": false,
                        "Time": false
                      },
                      "MB2Finish": "00:00",
                      "MB2Start": "00:00"
                    },
                    {
                      "AD3Finish": "00:00",
                      "AD3Start": "00:00",
                      "Deductable": {
                        "AD": false,
                        "Time": false
                      },
                      "MB3Finish": "00:00",
                      "MB3Start": "00:00"
                    }
                  ],
                  "meals": "00:00",
                  "Note": null,
                  "Payas": "CON",
                  "PaymentFlag": null,
                  "Position": "PRODUCTION RUNNER",
                  "Start": "00:00",
                  "TenantGuid": "uuijjj",
                  "TotalHours": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
                  "TrackingCodes": JSON.parse(localStorage.getItem('completetenant')).TrackingFields,
                  "TravelFrom": "00:00",
                  "TravelTo": "00:00",
                  "WorkState": null
                };



                data.push(st);



              }

            })

            this.setState({
              editADtimes: data
            });
            if (this.state.editPrltimes === null) {
              this.setState({
                editPaytimes:
                  object[0].stdtimes.length > 0 ? this.state.stdtimes : data
              });
            }
          })
          .catch(error => {
            toast.error("fetching adtimes error");
          });
        await this.saving_approver();

        var tmp_app = [];
        if (this.state.current_selected_group[0]) {
          this.state.current_selected_group[0].Approvers.map(value => {
            tmp_app.push(value.approver_id);
          });
        }

        var finaldata = {
          approver_chain: tmp_app,
          group_guid: this.state.current_selected_group[0]
            ? this.state.current_selected_group[0].Guid
            : null,
          current_approver: "No user",
          approve_status: "Draft",
          Guid: this.state.editableguid,
          Batch: this.state.edit_batch,
          Employee: object,
          freelanceuser: [],
          PRLTimes: this.state.editPrltimes,
          TenantGuid: localStorage.getItem("useableGuid"),
          ChequeNumber: this.state.editChequeNumber,
          PayslipNote: this.state.editPayslipNote,
          WorkState: this.state.editWorkState,
          PayWeek: this.state.editPayWeek,
          ChartSort: this.state.editChartSort,
          ChartCode: this.state.editChartCode,
          Flags: this.state.editFlags,
          InternalNotes: this.state.editInternalNotes,
          PaymentFlags: this.state.editPaymentFlags,
          ADTimes: this.state.editADtimes,
          PeriodEndingDate: this.state.payrollEdit_EntryDet_PeriodEnding,
          PayTimes: this.state.editPaytimes,
          comments: [],
          senderid: null,
                 timesheetImage:this.state.timesheetImage,
                 freelanceuser_email:this.state.freelanceuser_email,
freelanceuser_signature:this.state.freelanceuser_signature,
          changes: [],
          activities: [{
            date: moment(Date.now()).format("l h:mma"),
            msg: "Timecard Updated by DigitalTimes."
          }]
        };

        var old_val = this.state.Employee_old_Guid_tobenull;
        await API.post("createprlemp", "/createprlemp", {
          body: finaldata
        })
          .then(data => {
         
           // toast.success("Timecard Updated Successfully.");
            document.getElementById("closePayrollModal").click();
            document.getElementById("freshbtn").click();
          })
          .catch(error => {
            toast.error("Timecard Updation error.");
          });
        if (this.state.editPrltimes !== null) {
          await this.updateUserguid_employee(null, old_val);
          await this.updateUserguid_employee(
            this.state.employee_userGuid,
            object[0].Guid
          );
        }
      }

      await this.setState({
        isLoading: false
      });
    } else {
      toast.error("Select Employee Please");
    }
   
    //this.setState(this.initialState);
    $("body").removeAttr("style");
  };

  // end onpayrollEntryDetSaveHandler
  //resetting state
  // get initialState(){
  //   return{

  //
  //       payrollEdit_EntryDet_PeriodEndingValid: true,
  //       payrollEdit_EntryDet_DepartmentValid: false,
  //       payrollEdit_EntryDet_PositionValid: false,
  //       payrollEdit_EntryDet_EmailValid: false,
  // payrollEdit_EntryDetErrors: {
  //   payrollEdit_EntryDet_EmployeeSearchInput: '',
  //   payrollEdit_EntryDet_PeriodEnding: '',
  //   payrollEdit_EntryDet_Department: '',
  //   payrollEdit_EntryDet_Position: '',
  //   payrollEdit_EntryDet_Email: '',
  //   payrollEdit_EntryDet_ApprovSelectedOption: '',
  //   payrollEdit_EntryDet_StateTax: '',
  //   payrollEdit_EntryDet_Group: '',
  //   payrollEdit_EntryDet_StartDate: '',
  //   payrollEdit_EntryDet_EndDate: '',
  // },

  // payrollEdit_EntryDet_EmployeeSearchInput: ''
  //

  //     }

  // }//ending resettin state
  saving_approver = async () => {
    if (this.state.approver_group_prlemp !== "") {
      await API.post("appgroupfortimecard", "/appgroupfortimecard", {
        body: {
          ApproverSetup: this.state.approver_group_prlemp.ApproverSetup,
          ApproverGroup: this.state.approver_group_prlemp.ApproverGroup,
          GroupName: this.state.approver_group_prlemp.GroupName,
          TenantGuid: localStorage.getItem("useableGuid")
        }
      })
        .then(data => {
          var arr = [];
          arr.push(data);
          this.setState({
            current_selected_group: arr
          });
        })
        .catch(error => {
          toast.error("Group not added");
        });
    } else {
      toast.error("selected Group not found");
    }
  };
  geting_approver = async groupname => {
    if (groupname !== "") {
      await this.setState({
        isLoading: true
      });
      await API.post("getgroupbyname", "/getgroupbyname", {
        body: {
          Guid: this.state.payrollEdit_EntryDet_ApprovSelectedOption
        }
      })
        .then(data => {
          if (data.Item) {
            this.setState({
              approver_group_prlemp: data.Item
            });
          } else {
            toast.error("No group found with your selected name");
          }
        })
        .catch(error => {
          toast.error("No group found");
        });
      await this.setState({
        isLoading: false
      });
    } else {
      await this.setState({
        approver_group_prlemp: ""
      });
    }
  };
  updateUserguid_employee = async (uid, guid) => {
    await API.post("updateemployeeuserguid", "/updateemployeeuserguid", {
      body: {
        Guid: guid,
        UserGuid: uid
      }
    })
      .then(data => {
       // toast.success("UserGuid  Updated  successfully in Employee");
      })
      .catch(error => {
        toast.error("UserGuid not Updated in Employee");
      });
  };
  onEmployeSelected = async Guid => {
    await this.setState({
      selectedEmployee: this.state.ad_times_all_employess.filter(
        item => item.EmpGuid === Guid
      ),
      isLoading: true
    });


    document.getElementById("closeEmployeeModal").click();

    await this.setState({
      payrollEdit_EntryDet_DepartmentValid: true,
      payrollEdit_EntryDet_EmailValid: true,
      payrollEdit_EntryDet_PositionValid: true,
      payrollEdit_EntryDet_EmployeeSearchInputValid: true,
      employ_uid_new_prlemp: this.state.selectedEmployee[0].UserGuid
        ? this.state.selectedEmployee[0].UserGuid
        : null,
      stdtimes: this.state.selectedEmployee[0].stdtimes
        ? this.state.selectedEmployee[0].stdtimes
        : [],
      Employee_Agcode: this.state.selectedEmployee[0].AgentCode,
      Employee_AliasFirstName: this.state.selectedEmployee[0].AliasFirstName,
      Employee_AliasLastName: this.state.selectedEmployee[0].AliasLastName,
      Employee_EmpBasis: this.state.selectedEmployee[0].EmpBasis,
      Employee_EmpGuid: this.state.selectedEmployee[0].EmpGuid,
      Employee_GlobalCode: this.state.selectedEmployee[0].GlobalCode,
      Employee_Guid: this.state.selectedEmployee[0].Guid,
      Employee_HourlyRate: this.state.selectedEmployee[0].HourlyRate,
      Employee_LastName: this.state.selectedEmployee[0].LastName,
      Employee_Mobile: this.state.selectedEmployee[0].Mobile,
      Employee_PaymentFlags: this.state.selectedEmployee[0].PaymentFlags,
      Employee_TemplateCode: this.state.selectedEmployee[0].TemplateCode,
      Employee_TenantGuid: this.state.selectedEmployee[0].TenantGuid,
      Employee_Address: this.state.selectedEmployee[0].Address,
      payrollEdit_EntryDetErrors: {
        payrollEdit_EntryDet_EmployeeSearchInput: "",

        payrollEdit_EntryDet_Department: "",
        payrollEdit_EntryDet_Position: "",
        payrollEdit_EntryDet_Email: ""
      },

      payrollEdit_EntryDet_EmployeeSearchInput: this.state.selectedEmployee[0]
        .FirstName,

      payrollEdit_EntryDet_Department: this.state.selectedEmployee[0]
        .Department,
      payrollEdit_EntryDet_Position: this.state.selectedEmployee[0].Position,
      payrollEdit_EntryDet_Email: this.state.selectedEmployee[0].Email,
      payrollEdit_EntryDet_ApprovSelectedOption:
        this.state.selectedEmployee[0].ApproverGroup === null
          ? ""
          : this.state.selectedEmployee[0].ApproverGroup,
      payrollEdit_EntryDet_StateTax:
        this.state.selectedEmployee[0].StateTax === null
          ? ""
          : this.state.selectedEmployee[0].StateTax,
      payrollEdit_EntryDet_Group:
        this.state.selectedEmployee[0].TimecardGroup === null
          ? ""
          : this.state.selectedEmployee[0].TimecardGroup,

      payrollEdit_EntryDet_StartDate: new Date(
        this.state.selectedEmployee[0].StartDate === null ||
          this.state.selectedEmployee[0].StartDate === undefined ||
          this.state.selectedEmployee[0].StartDate.trim() === "-  -"
          ? "01/01/1970"
          : this.state.selectedEmployee[0].StartDate
      ),

      payrollEdit_EntryDet_EndDate: new Date(
        this.state.selectedEmployee[0].TerminationDate === null ||
          this.state.selectedEmployee[0].TerminationDate === undefined ||
          this.state.selectedEmployee[0].TerminationDate.trim() === "-  -"
          ? "01/01/1970"
          : this.state.selectedEmployee[0].TerminationDate
      ),

      payrollEdit_EntryDet_TerminatedChkBox:
        this.state.selectedEmployee[0].Terminated === null ||
          this.state.selectedEmployee[0].Terminated === "" ||
          this.state.selectedEmployee[0].Terminated === "F"
          ? false
          : true,
      payrollAttachments: this.state.selectedEmployee[0].attachments
    });
    if (this.state.payrollEdit_EntryDet_ApprovSelectedOption !== "") {
      await this.geting_approver(
        this.state.payrollEdit_EntryDet_ApprovSelectedOption
      );
    }
    await this.setState({
      isLoading: false
    });
  };
  clear_payrollmodal_states = async () => {
    this.after_issueScrollHandler();
    await this.setState({
      freelanceuser_email:null,
freelanceuser_signature:null,
             timesheetImage:null,
      Employee_old_Guid_tobenull: "",
      payrollEdit_EntryDet_DepartmentValid: false,
      payrollEdit_EntryDet_EmailValid: false,
      payrollEdit_EntryDet_PositionValid: false,
      payrollEdit_EntryDet_EmployeeSearchInputValid: false,
      edit_card: false,
      Employee_Agcode: "",
      editableguid: "",
      Employee_AliasFirstName: "",
      Employee_AliasLastName: "",
      Employee_EmpBasis: "",
      Employee_EmpGuid: "",
      Employee_GlobalCode: "",
      Employee_Guid: "",
      Employee_HourlyRate: "",
      Employee_LastName: "",
      Employee_Mobile: "",
      Employee_PaymentFlags: [],
      Employee_TemplateCode: "",
      Employee_TenantGuid: "",
      Employee_Address: [],
      payrollEdit_EntryDetErrors: {
        payrollEdit_EntryDet_EmployeeSearchInput: "",

        payrollEdit_EntryDet_Department: "",
        payrollEdit_EntryDet_Position: "",
        payrollEdit_EntryDet_Email: ""
      },

      payrollEdit_EntryDet_EmployeeSearchInput: "",

      payrollEdit_EntryDet_Department: "",
      payrollEdit_EntryDet_Position: "",
      payrollEdit_EntryDet_Email: "",
      payrollEdit_EntryDet_ApprovSelectedOption: "",
      payrollEdit_EntryDet_StateTax: "",
      payrollEdit_EntryDet_Group: "",
      payrollEdit_EntryDet_StartDate: "",
      payrollEdit_EntryDet_EndDate: "",
      payrollEdit_EntryDet_TerminatedChkBox: false,
      payrollAttachments: [],
      approver_group_prlemp: "",
      current_selected_group: [],
      stdtimes: []
    });
  };

  openEmployeePopUp = () => {
    document.getElementById("search_employee_popup").click();
  };
  TerminateHandler = e => {
    this.setState({
      [e.target.name]: e.target.checked
    });
  };

  refreshEmplopyees = async () => {
    this.setState({
      isLoading: true
    });

    await this.getAllEmployees();

    this.setState({
      isLoading: false
    });
  };
  handleDateChange = async e => {
    //alert('sas');
    await this.setState({
      payrollEdit_EntryDet_PeriodEnding: e
    });
  };
  handleStartDateChange = async e => {
    await this.setState({
      payrollEdit_EntryDet_StartDate: e
    });
  };
  handleEndDateChange = async e => {
    await this.setState({
      payrollEdit_EntryDet_EndDate: e
    });
  };
  edit_timecard = async id => {
    await this.setState({
      isLoading: true
    });
    await API.post("prlempbyid", "/prlempbyid", {
      body: {
        id: id
      }
    })
      .then(data => {

        this.setState({
          freelanceuser_email:data.freelanceuser_email?data.freelanceuser_email:null,
freelanceuser_signature:data.freelanceuser_signature?data.freelanceuser_signature:null,
                 timesheetImage:data.timesheetImage?data.timesheetImage:null,
          edit_card: true,
          editableguid: data.Guid,
          editPrltimes: data.PRLTimes,
          edit_batch: data.Batch,
          employee_userGuid:
            data.PRLTimes !== null ? data.PRLTimes.UserGuid : null,
          editADtimes: data.ADTimes,
          editChartCode: data.ChartCode,
          editChartSort: data.ChartSort,
          editChequeNumber: data.ChequeNumber,
          editFlags: data.Flags,
          editInternalNotes: data.InternalNotes,
          editPaymentFlags: data.PaymentFlags,
          editPayslipNote: data.PayslipNote,
          editPayWeek: data.PayWeek,
          editWorkState: data.WorkState,
          editPaytimes: data.PayTimes,
          payrollEdit_EntryDet_PeriodEnding: data.PeriodEndingDate !== null ? new Date(data.PeriodEndingDate) : new Date(data.PayTimes.PeriodEnding)
        });
        if (data.Employee.length > 0) {
          var employee = data.Employee[0];
          this.setState({
            payrollEdit_EntryDet_DepartmentValid: true,
            payrollEdit_EntryDet_EmailValid: true,
            payrollEdit_EntryDet_PositionValid: true,
            payrollEdit_EntryDet_EmployeeSearchInputValid: true,

            Employee_Agcode: employee.AgentCode,
            Employee_AliasFirstName: employee.AliasFirstName,
            Employee_AliasLastName: employee.AliasLastName,
            Employee_EmpBasis: employee.EmpBasis,
            Employee_EmpGuid: employee.EmpGuid,
            Employee_GlobalCode: employee.GlobalCode,
            Employee_Guid: employee.Guid,
            Employee_old_Guid_tobenull: employee.Guid,
            Employee_HourlyRate: employee.HourlyRate,
            Employee_LastName: employee.LastName,
            Employee_Mobile: employee.Mobile,
            Employee_PaymentFlags: employee.PaymentFlags,
            Employee_TemplateCode: employee.TemplateCode,
            Employee_TenantGuid: employee.TenantGuid,
            Employee_Address: employee.Address,
            payrollEdit_EntryDetErrors: {
              payrollEdit_EntryDet_EmployeeSearchInput: "",

              payrollEdit_EntryDet_Department: "",
              payrollEdit_EntryDet_Position: "",
              payrollEdit_EntryDet_Email: ""
            },

            payrollEdit_EntryDet_EmployeeSearchInput: employee.FirstName,

            payrollEdit_EntryDet_Department: employee.Department,
            payrollEdit_EntryDet_Position: employee.Position,
            payrollEdit_EntryDet_Email: employee.Email,
            payrollEdit_EntryDet_ApprovSelectedOption:
              employee.ApproverGroup === null ? "" : employee.ApproverGroup,
            payrollEdit_EntryDet_StateTax:
              employee.StateTax === null ? "" : employee.StateTax,
            payrollEdit_EntryDet_Group:
              employee.TimecardGroup === null ? "" : employee.TimecardGroup,
            payrollEdit_EntryDet_StartDate: new Date(employee.StartDate),
            payrollEdit_EntryDet_EndDate: new Date(employee.TerminationDate),
            payrollEdit_EntryDet_TerminatedChkBox:
              employee.Terminated === null ||
                employee.Terminated === "" ||
                employee.Terminated === "F"
                ? false
                : true,
            payrollAttachments: employee.attachments
          });
        }
      })
      .catch(error => {
        console.log(error.message + "error getting timecard by id");
      });
    await this.geting_approver(
      this.state.payrollEdit_EntryDet_ApprovSelectedOption
    );
    await this.setState({
      isLoading: false
    });
  };
  get_checked_records = async () => {
    var Records = [];
    var emplist = [];
    Records = this.state.payrollEditTableData.filter(it => {
      return it.checked === true;
    });
    if (Records.length > 0) {
      await this.setState({
        isLoading: true
      });

      var completetenant = localStorage.getItem("completetenant");
      completetenant = JSON.parse(completetenant);
      var TphPayrollApi = completetenant.TphPayrollApi;
      var that = this;
      var qtimecard = [];

      await Promise.all(
        Records.map(async (value, index) => {

          if (value.PayTimes.Times) {
            value.PayTimes.Times[0].DailyTimes.map(val => {

              var obj = {

                guid: value.Guid,
                Date: moment(val.Date * 1000).format("YYYY-MM-DD"),
                Day: val.Day,
                Finish: val.Finish,
                FinishMeal1: val.FinishMeal1,
                FinishMeal2: val.FinishMeal2,
                FinishMeal3: val.FinishMeal3,
                MB1ND: val.MB1ND == 0 ? false : true,
                MB2ND: val.MB2ND == 0 ? false : true,
                MB3ND: val.MB3ND == 0 ? false : true,
                Note: val.Note,
                Payas: val.Payas,

                Start: val.Start,
                StartMeal1: val.StartMeal,
                StartMeal2: val.StartMeal2,
                StartMeal3: val.StartMeal3,
                TotalMBDed: val.TotalMBDed,
                Travel1: val.Travel1,
                Travel2: val.Travel2,
                PaymentFlag: value.PaymentFlags,
                ADPaymentFlag: value.Flags
              }
              qtimecard.push(obj);

            });
          } else {
            value.PayTimes.map(val => {
              var obj = {
                guid: value.Guid,

                Date: moment(val.adlistdate).format("YYYY-MM-DD"),
                Day: new Date(val.adlistdate).getDay(),
                Finish: val.Finish,
                FinishMeal1: val.mealBreak[0].AD1Finish,
                FinishMeal2: val.mealBreak[1].AD2Finish,
                FinishMeal3: val.mealBreak[2].AD3Finish,
                MB1ND: val.mealBreak[0].Deductable.Time,
                MB2ND: val.mealBreak[1].Deductable.Time,
                MB3ND: val.mealBreak[2].Deductable.Time,
                Note: val.Note,
                Payas: val.Payas,

                Start: val.Start,
                StartMeal1: val.mealBreak[0].AD1Start,
                StartMeal2: val.mealBreak[1].AD2Start,
                StartMeal3: val.mealBreak[2].AD3Start,
                TotalMBDed: val.meals,
                Travel1: val.TravelTo,
                Travel2: val.TravelFrom,
                PaymentFlag: val.PaymentFlag,
                ADPaymentFlag: value.PaymentFlags
              }
              qtimecard.push(obj);

            });
          }

          var objs = {
            guid: value.Guid,
            TenantGuid: value.TenantGuid,
            qtimecard: qtimecard,
            employeeguid: value.Employee[0].Guid,
            department: value.Employee[0].Department,
            position: value.Employee[0].Position,
            periodendingdate: new Date(value.PeriodEndingDate),
            batch: value.Batch,
            tran: 0
          }
          emplist.push(objs);



          return null;
        })
      );
var that=this;
      var keys=localStorage.getItem('tempkeys');
      var finalkeys = (keys&&keys!==null&&keys!==undefined)?JSON.parse(keys):undefined;
      if(finalkeys!==undefined&&finalkeys.tempUsername&&
        finalkeys.tempPassword){
      await $.ajax({
        url: TphPayrollApi.url + "/api/SendTCforApproval",
        type: "post",
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify({
          username: finalkeys.tempUsername,
          password: finalkeys.tempPassword,
          employeelist: emplist
        }),
        success:async function (res) {
          if(res.SendTCforApproval_response.ServiceErrors&&(res.SendTCforApproval_response.ServiceErrors[0].ERRORDESCRIPTION==="Userkey or Password invalid"||res.SendTCforApproval_response.ServiceErrors[0].ERRORDESCRIPTION==="temp key expired")){
            var confirm=await getKeys();
            if(confirm===true){
            that.get_checked_records();
           }
          }else{
          Records.map(async (value, index) => {
            that.updatestatus(value.approver_chain[0], value.Guid);
          });

          that.setState({
            test: res
          });}
        },
        error:async function (error){
          if(error.SendTCforApproval_response.ServiceErrors[0].ERRORDESCRIPTION==="Userkey or Password invalid"||error.SendTCforApproval_response.ServiceErrors[0].ERRORDESCRIPTION==="temp key expired"){
            var confirm=await getKeys();
            if(confirm===true){
            that.get_checked_records();
           }
          }
        }
      });
    }else{
      var confirm=await getKeys();
           if(confirm===true){
           that.get_checked_records();
          }}

      if (Records.length > 0) {
        setTimeout(function () {
          document.getElementById("freshbtn").click();
        }, 3000);
      }

     // toast.success("Generating payslips to post back via web hook");
    } else {
      toast.error("Please Select Timecards to send.");
    }
    document.getElementById("close_btn_tc").click();
    await this.setState({
      isLoading: false
    });
  };

  updatestatus = async (capprover, Guid) => {
    await API.post("updatetimecardstatus", "/updatetimecardstatus", {
      body: {
        Guid: Guid,
        approve_status: "Pending",
        current_approver: capprover,
        senderid: localStorage.getItem("userGuid")
      }
    })
      .then(data => {

      })
      .catch(error => {
        console.log("not updated");
      });
  };

  showEmployeeAttachment = async filename => {
    await Storage.get(
      "employee_attachments/" +
      localStorage.getItem("useableGuid") +
      "/attachments/" +
      filename
    ).then(result => {
      window.open(result);
    });
  };

  handleDateeveryone = async date => {
    await this.setState({
      everyOnePaid: date
    });
  };
  handleDateBatch = async date => {
    await this.setState({
      everyone_batch_date: date
    });
  };

  open_AdTimes_calender = () => {
    $("#ad_times_date_calender").click();
  };

  countrecords = async () => {
    var Records = [];
    Records = this.state.payrollEditTableData.filter(it => {
      return it.checked === true;
    });

   await this.setState({
      counting: Records.length,
      countingOtherThanDraft:Records.filter(timeCard=> timeCard.approve_status!=='Draft').length
    });
  };
  addBatch = e => {
    // $('#adtimes2_search_pop').hide();
    // $('#adbatch_search_pop').show();
    e.preventDefault();
    document.getElementById("close_every_modal").click();
  };
  selectBatch = e => {
    this.setState({ batch_to_updtae: e.target.value });
  };
  moverecords = async () => {
    if (this.state.batch_to_updtae === "") {
      toast.error("Please Select Batch");
    } else {
      await this.setState({
        isLoading: true
      });
      var Records = [];
      var array = [];
      Records = this.state.payrollEditTableData.filter(it => {
        return it.checked === true;
      });

      if (Records.length > 0 && Records.length < 20) {
        Records.map(val => {
          val.activities.push({
            date: moment(Date.now()).format("l h:mma"),
            msg: localStorage.getItem("UserFullName") + " has moved the timecard"
          });
          var Item = {
            Guid: val.Guid,
            ADTimes: val.ADTimes,
            Batch: this.state.batch_to_updtae,
            PRLTimes: val.PRLTimes,
            Employee: val.Employee,
            freelanceuser: val.freelanceuser,
            TenantGuid: val.TenantGuid,
            createdAt: val.createdAt,
            ChartCode: val.ChartCode,
            ChartSort: val.ChartSort,
            ChequeNumber: val.ChequeNumber,
            Employee: val.Employee,
            Flags: val.Flags,
            InternalNotes: val.InternalNotes,
            PayTimes: val.PayTimes,
            PayWeek: val.PayWeek,
            PaymentFlags: val.PaymentFlags,
            PayslipNote: val.PayslipNote,
            PeriodEndingDate: val.PeriodEndingDate,
            WorkState: val.WorkState,
            approve_status: val.approve_status,
            approver_chain: val.approver_chain,
            checked: val.checked,
            CheckBoxID: val.chkBoxName,
            group_guid: val.group_guid,
            current_approver: val.current_approver,
            comments: val.comments,
            senderid: val.senderid,
            changes: val.changes,
            activities: val.activities,
              timesheetImage:val.timesheetImage?val.timesheetImage:null,
              freelanceuser_email:val.freelanceuser_email?val.freelanceuser_email:null,
freelanceuser_signature:val.freelanceuser_signature?val.freelanceuser_signature:null
          };

          array.push(Item);
        });


        await API.post("updatebatchnumber", "/updatebatchnumber", {
          body: {
            finaldata: array
          }
        })
          .then(data => {
           // toast.success("Timecard moved");
            this.get_prlemp();
            document.getElementById("close_move").click();
          })
          .catch(error => {
            toast.error("Timecard not moved");
          });
      } else {
        toast.error("Please select valid number of records");
      }
      await this.setState({
        isLoading: false
      });
    }
  };
  deletePayroll = async () => {
    if (this.props.location.state.lock !== "Locked") {
      var Records = [];
      var array = [];
      Records = this.state.payrollEditTableData.filter(it => {
        return it.checked === true;
      });
      this.setState({
        delete_to_payroll: Records
      });
      if (Records.length > 0 && Records.length < 20) {
        this.setState({
          isLoading: true
        });
        Records.map(value => {
          array.push({ Guid: value.Guid });
          return null;
        });
        await API.post("deleteprlempbatch", "/deleteprlempbatch", {
          body: {
            finaldata: array
          }
        })
          .then(data => {
            //toast.success("Data Deleted");
            this.get_prlemp();
            document.getElementById("close_del_modal").click();
          })
          .catch(error => {
            toast.error("Data not Deleted");
          });
        this.setState({
          isLoading: false
        });
      } else {
        toast.error("select the valid number of records");
      }
    } else {
      toast.error(
        "You can't delete from this batch because this batch is locked "
      );
    }
  };
  payrollBatchReport = e => {
    this.setState({ payrollReport: e.target.value });
  };
  openDatepicker = () => this._calendar.setOpen(true);
  render() {
    const empMapArray = this.state.Adtimes2_Modal2SearchName_TerminatedChkbx
      ? this.state.ad_times_all_employess
      : this.state.adTimes_NonTerminateEmp;
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    var weekday = [
      "Sunday",
      "Monday",
      "Tueday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ][new Date(this.state.AdTimes2_AdTimeForm_Date).getDay()];

    return (
      <div>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}
        <Header1

          childProps={this.props.childProps}
          batchEditHeader={this.props.history.location.state.dec}
          link={"/payroll_batches"}
        />

        <div className="clearfix"></div>

        <div className="col-lg-10 col-lg-offset-1 mrg_top_payroll">
          <div className="col-sm-6 p0">
            <div className="search_area_payroll">
              <input
                name="payrollBatchEdit_search"
                id="payrollBatchEdit_search"
                type="text"
                className="search_payroll_bedit_popsearch"
                value={this.state.payrollBatchEdit_search}
                placeholder={
                  "Search by" + " " + this.state.PayrollBatchEditSelectedTab
                }
                onChange={this.payrollBatchEdit_SearchHandler}
              />
              <img src={SearchSvg} width="20" height="20" alt="" />
            </div>
          </div>

          <div className="col-sm-6 p0">
            <div className="dropdown menu_icon_payroll">
              <button
                className="dropdown-toggle btn_payroll_menu"
                type="button"
                data-toggle="dropdown"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="5.223"
                  height="20.893"
                  viewBox="0 0 5.223 20.893"
                >
                  <path
                    id="ic_more_horiz_24px"
                    d="M6.612,10a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,6.612,10Zm15.669,0a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,22.281,10Zm-7.835,0a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,14.446,10Z"
                    transform="translate(15.223 -4) rotate(90)"
                    fill="#7b7b7b"
                  />
                </svg>
              </button>

              <ul className="dropdown-menu dropleft dropdown_menu_users_down dropdown_menu_payroll_1 resend_top_dropp">
                <li>
                  <a
                    href={null}
                    data-toggle="modal"
                    className="cursorPointer"
                    data-target="#excel_pop_payroll"
                  >
                    <img
                      src={excelPng}
                      className="mrg_sendicon"
                      width="19"
                      height="13"
                      alt=""
                    />
                    <span> </span>
                    Excel
                  </a>
                </li>
                <li>
                  <a
                    href={null}
                    className="cursorPointer"
                    data-toggle="modal"
                    data-target="#select_paste_pop"
                  >
                    <img
                      src={selPastePng}
                      className="mrg_sendicon"
                      width="15"
                      height="16"
                      alt=""
                    />
                    <span> </span>
                    Select Paste
                  </a>
                </li>

                <li>
                  <a
                    href={null}
                    data-toggle="modal"
                    data-target="#payroll_reports"
                    className="lock_invoice_icon cursorPointer"
                  >
                    <img
                      src={excelPng}
                      className="mrg_sendicon"
                      width="18"
                      height="13"
                      alt=""
                    />
                    <span> </span>
                    Reports
                  </a>
                </li>

                <li>
                  <a
                    onClick={this.get_checked_records}
                    href={null}
                    data-toggle="modal"
                    data-target="#release_payroll"
                    className="create_ftp_icon cursorPointer"
                  >
                    <img
                      src={calculate}
                      className="mrg_sendicon"
                      width="16"
                      height="15"
                      alt=""
                    />
                    <span> </span>
                    Calculate
                  </a>
                </li>

                <li>
                  <a
                    href={null}
                    className="cursorPointer"
                    data-toggle="modal"
                    data-target="#adtimes2_search_pop"
                  >
                    <img
                      src={paid}
                      className="mrg_sendicon"
                      width="16"
                      height="15"
                      alt=""
                    />
                    <span> </span>
                    Everyone Paid
                  </a>
                </li>

                <li>
                  <a
                    href={null}
                    className="cursorPointer"
                    data-toggle="modal"
                    data-target="#payroll_move_2"
                  >
                    <img
                      src={move}
                      className="mrg_sendicon"
                      width="13"
                      height="15"
                      alt=""
                    />
                    <span> </span>
                    Move
                  </a>
                </li>
                <li>
                  <a
                    href={null}
                    data-toggle="modal"
                    onClick={this.countrecords}
                    data-target="#payroll_delete"
                    className="cursorPointer"
                  >
                    <img
                      src={cross}
                      className="icon_post"
                      width="8"
                      height="16"
                      alt=""
                    />
                    <span> </span>
                    Delete
                  </a>
                </li>
              </ul>
            </div>

            <a
              onClick={this.get_prlemp}
              id="freshbtn"
              href={null}
              className="refresh_icon_payroll cursorPointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24.224"
                height="17.617"
                viewBox="0 0 24.224 17.617"
              >
                <path
                  id="ic_cached_24px"
                  d="M20.819,8.4l-4.4,4.4h3.3a6.612,6.612,0,0,1-6.606,6.606,6.464,6.464,0,0,1-3.083-.771L8.421,20.252a8.793,8.793,0,0,0,13.5-7.443h3.3Zm-14.314,4.4A6.612,6.612,0,0,1,13.112,6.2a6.464,6.464,0,0,1,3.083.771L17.8,5.365A8.793,8.793,0,0,0,4.3,12.809H1l4.4,4.4,4.4-4.4Z"
                  transform="translate(-1 -4)"
                  fill="#7B7B7B"
                />
              </svg>
            </a>

            <a
              href={null}
              className="plus_icon_payroll cursorPointer"
              data-toggle="modal"
              data-target="#batched_edit_pop"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
              >
                <path
                  id="ic_add_24px"
                  d="M20,13.571H13.571V20H11.429V13.571H5V11.429h6.429V5h2.143v6.429H20Z"
                  transform="translate(-5 -5)"
                  fill="#7B7B7B"
                />
              </svg>
            </a>
          </div>

          <div className="clear10"></div>

          <div className="col-sm-12 p0">
            <div className="overflow_scroll_sm">
              <table
                width="100%"
                className="table table-bordered timecard_table payroll_batches_edit_table"
              >
                <thead>
                  <tr className="table_header_payroll">
                    <th width="3%" align="center" valign="middle">
                      <input
                        name="IsAllpayrollEditTableCheckBoxSelected"
                        type="checkbox"
                        checked={
                          this.state.IsAllpayrollEditTableCheckBoxSelected
                        }
                        onChange={e =>
                          this.AllCheckBoxPayrollEditHandler(
                            "IsAllpayrollEditTableCheckBoxSelected",
                            e.target.checked
                          )
                        }
                        disabled={
                          this.state.testallcheck.length > 0 ? true : false
                        }
                      />
                    </th>
                    <th align="center" valign="middle">
                      <div
                        className="pull-left"
                        onClick={() =>
                          this.sort_payrollBatchEdit("First Name", "firstCaret")
                        }
                      >
                        {" "}
                        First Name
                      </div>
                      <div className="batch_downarrow batch_droppp_aroww">
                        <svg
                          id="firstCaret"
                          className={
                            !this.state.sort_firstToggle
                              ? "toggleCaretClass"
                              : ""
                          }
                          xmlns="http://www.w3.org/2000/svg"
                          width="12.337"
                          height="6.887"
                          viewBox="0 0 12.337 6.887"
                        >
                          <path
                            id="ic_arrow_drop_down_24px"
                            d="M7,10l6.169,6.887L19.337,10Z"
                            transform="translate(-7 -10)"
                            fill="#30679a"
                          />
                        </svg>
                      </div>
                    </th>
                    <th align="center" valign="middle">
                      <div
                        className="pull-left"
                        onClick={() =>
                          this.sort_payrollBatchEdit("Last Name", "lastCaret")
                        }
                      >
                        {" "}
                        Last Name
                      </div>
                      <div className="batch_downarrow batch_droppp_aroww">
                        <svg
                          id="lastCaret"
                          className={
                            !this.state.sort_lastToggle
                              ? "toggleCaretClass"
                              : ""
                          }
                          xmlns="http://www.w3.org/2000/svg"
                          width="12.337"
                          height="6.887"
                          viewBox="0 0 12.337 6.887"
                        >
                          <path
                            id="ic_arrow_drop_down_24px"
                            d="M7,10l6.169,6.887L19.337,10Z"
                            transform="translate(-7 -10)"
                            fill="#30679a"
                          />
                        </svg>
                      </div>
                    </th>
                    <th align="center" valign="middle">
                      <div
                        className="pull-left"
                        onClick={() =>
                          this.sort_payrollBatchEdit(
                            "Department",
                            "departmentCaret"
                          )
                        }
                      >
                        Department
                      </div>
                      <div className="batch_downarrow batch_droppp_aroww">
                        <svg
                          id="departmentCaret"
                          className={
                            !this.state.sort_departmentToggle
                              ? "toggleCaretClass"
                              : ""
                          }
                          xmlns="http://www.w3.org/2000/svg"
                          width="12.337"
                          height="6.887"
                          viewBox="0 0 12.337 6.887"
                        >
                          <path
                            id="ic_arrow_drop_down_24px"
                            d="M7,10l6.169,6.887L19.337,10Z"
                            transform="translate(-7 -10)"
                            fill="#30679a"
                          />
                        </svg>
                      </div>
                    </th>
                    <th align="center" valign="middle">
                      <div
                        className="outerpositon_mrg"
                        onClick={() =>
                          this.sort_payrollBatchEdit(
                            "Position",
                            "positionCaret"
                          )
                        }
                      >
                        Position
                      </div>
                      <div className="batch_downarrow batch_droppp_aroww">
                        <svg
                          id="positionCaret"
                          className={
                            !this.state.sort_positionToggle
                              ? "toggleCaretClass"
                              : ""
                          }
                          xmlns="http://www.w3.org/2000/svg"
                          width="12.337"
                          height="6.887"
                          viewBox="0 0 12.337 6.887"
                        >
                          <path
                            id="ic_arrow_drop_down_24px"
                            d="M7,10l6.169,6.887L19.337,10Z"
                            transform="translate(-7 -10)"
                            fill="#30679a"
                          />
                        </svg>
                      </div>
                    </th>
                    <th width="10%" align="center" valign="middle">
                      <div
                        className="outerApprove_mrg"
                        onClick={() =>
                          this.sort_payrollBatchEdit("Approve", "approveCaret")
                        }
                      >
                        Approve
                      </div>
                      <div className="batch_downarrow batch_droppp_aroww">
                        <svg
                          id="approveCaret"
                          className={
                            !this.state.sort_approveToggle
                              ? "toggleCaretClass"
                              : ""
                          }
                          xmlns="http://www.w3.org/2000/svg"
                          width="12.337"
                          height="6.887"
                          viewBox="0 0 12.337 6.887"
                        >
                          <path
                            id="ic_arrow_drop_down_24px"
                            d="M7,10l6.169,6.887L19.337,10Z"
                            transform="translate(-7 -10)"
                            fill="#30679a"
                          />
                        </svg>
                      </div>
                    </th>
                    <th align="center" valign="middle">
                      <div
                        className="outerStatus_mrg"
                        onClick={() =>
                          this.sort_payrollBatchEdit("Status", "statusCaret")
                        }
                      >
                        Status
                      </div>
                      <div className="batch_downarrow batch_droppp_aroww">
                        <svg
                          id="statusCaret"
                          className={
                            !this.state.sort_statusToggle
                              ? "toggleCaretClass"
                              : ""
                          }
                          xmlns="http://www.w3.org/2000/svg"
                          width="12.337"
                          height="6.887"
                          viewBox="0 0 12.337 6.887"
                        >
                          <path
                            id="ic_arrow_drop_down_24px"
                            d="M7,10l6.169,6.887L19.337,10Z"
                            transform="translate(-7 -10)"
                            fill="#30679a"
                          />
                        </svg>
                      </div>
                    </th>
                    <th align="center" valign="middle">
                      <div
                        className="pull-left"
                        onClick={() =>
                          this.sort_payrollBatchEdit(
                            "Current Approve",
                            "currentCaret"
                          )
                        }
                      >
                        Current Approver
                      </div>
                      <div className="batch_downarrow batch_droppp_aroww">
                        <svg
                          id="currentCaret"
                          className={
                            !this.state.sort_currentToggle
                              ? "toggleCaretClass"
                              : ""
                          }
                          xmlns="http://www.w3.org/2000/svg"
                          width="12.337"
                          height="6.887"
                          viewBox="0 0 12.337 6.887"
                        >
                          <path
                            id="ic_arrow_drop_down_24px"
                            d="M7,10l6.169,6.887L19.337,10Z"
                            transform="translate(-7 -10)"
                            fill="#30679a"
                          />
                        </svg>
                      </div>
                    </th>
                    <th align="center" valign="middle">
                      <div
                        className="pull-left"
                        onClick={() =>
                          this.sort_payrollBatchEdit(
                            "Approved By",
                            "approvedByCaret"
                          )
                        }
                      >
                        Approved By
                      </div>
                      <div className="batch_downarrow batch_droppp_aroww">
                        <svg
                          id="approvedByCaret"
                          className={
                            !this.state.sort_approvedByToggle
                              ? "toggleCaretClass"
                              : ""
                          }
                          xmlns="http://www.w3.org/2000/svg"
                          width="12.337"
                          height="6.887"
                          viewBox="0 0 12.337 6.887"
                        >
                          <path
                            id="ic_arrow_drop_down_24px"
                            d="M7,10l6.169,6.887L19.337,10Z"
                            transform="translate(-7 -10)"
                            fill="#30679a"
                          />
                        </svg>
                      </div>
                    </th>
                    <th align="center" valign="middle">
                      <div
                        className="pull-left"
                        onClick={() =>
                          this.sort_payrollBatchEdit("Group", "groupCaret")
                        }
                      >
                        Group
                      </div>
                      <div className="batch_downarrow batch_droppp_aroww">
                        <svg
                          id="groupCaret"
                          className={
                            !this.state.sort_groupToggle
                              ? "toggleCaretClass"
                              : ""
                          }
                          xmlns="http://www.w3.org/2000/svg"
                          width="12.337"
                          height="6.887"
                          viewBox="0 0 12.337 6.887"
                        >
                          <path
                            id="ic_arrow_drop_down_24px"
                            d="M7,10l6.169,6.887L19.337,10Z"
                            transform="translate(-7 -10)"
                            fill="#30679a"
                          />
                        </svg>
                      </div>
                    </th>
                    <th align="center" valign="middle">
                      <div
                        className="pull-left"
                        onClick={() =>
                          this.sort_payrollBatchEdit("Date", "dateCaret")
                        }
                      >
                        Date
                      </div>
                      <div className="batch_downarrow batch_droppp_aroww">
                        <svg
                          id="dateCaret"
                          className={
                            !this.state.sort_dateToggle
                              ? "toggleCaretClass"
                              : ""
                          }
                          xmlns="http://www.w3.org/2000/svg"
                          width="12.337"
                          height="6.887"
                          viewBox="0 0 12.337 6.887"
                        >
                          <path
                            id="ic_arrow_drop_down_24px"
                            d="M7,10l6.169,6.887L19.337,10Z"
                            transform="translate(-7 -10)"
                            fill="#30679a"
                          />
                        </svg>
                      </div>
                    </th>
                    <th align="center" valign="middle">
                      <div
                        className="pull-left"
                        onClick={() =>
                          this.sort_payrollBatchEdit("Gross", "grossCaret")
                        }
                      >
                        Gross
                      </div>
                      <div className="batch_downarrow batch_droppp_aroww">
                        <svg
                          id="grossCaret"
                          className={
                            !this.state.sort_grossToggle
                              ? "toggleCaretClass"
                              : ""
                          }
                          xmlns="http://www.w3.org/2000/svg"
                          width="12.337"
                          height="6.887"
                          viewBox="0 0 12.337 6.887"
                        >
                          <path
                            id="ic_arrow_drop_down_24px"
                            d="M7,10l6.169,6.887L19.337,10Z"
                            transform="translate(-7 -10)"
                            fill="#30679a"
                          />
                        </svg>
                      </div>
                    </th>
                    <th align="center" valign="middle">
                      <div
                        className="pull-left"
                        onClick={() =>
                          this.sort_payrollBatchEdit("Tax", "taxCaret")
                        }
                      >
                        Tax
                      </div>
                      <div className="batch_downarrow batch_droppp_aroww">
                        <svg
                          id="taxCaret"
                          className={
                            !this.state.sort_taxToggle ? "toggleCaretClass" : ""
                          }
                          xmlns="http://www.w3.org/2000/svg"
                          width="12.337"
                          height="6.887"
                          viewBox="0 0 12.337 6.887"
                        >
                          <path
                            id="ic_arrow_drop_down_24px"
                            d="M7,10l6.169,6.887L19.337,10Z"
                            transform="translate(-7 -10)"
                            fill="#30679a"
                          />
                        </svg>
                      </div>
                    </th>
                    <th width="5%" align="center" valign="middle">
                      &nbsp;
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.payrollEditTableData.map(items => {
                    let approverName = "Null";
                    let currentApprover = "Null";
                    let approvedByname = "-";
                  

                    return (
                      <React.Fragment>
                        {items.Employee.length > 0 ? (
                          <tr key={items.Guid}>
                            {items.Employee[0].ApproverGroup === null ||
                              items.group_guid === null ? (
                                <td align="center" valign="middle">
                                  <input type="checkbox" disabled />
                                </td>
                              ) : (
                                <td align="center" valign="middle">
                                  <input
                                    name={items.chkBoxName}
                                    type="checkbox"
                                    checked={items.checked}
                                    onChange={e =>
                                      this.AllCheckBoxPayrollEditHandler(
                                        items.chkBoxName,
                                        e.target.checked
                                      )
                                    }
                                  />
                                </td>
                              )}
                            <td align="center" valign="middle">
                              {items.Employee[0].FirstName}
                            </td>
                            <td align="center" valign="middle">
                              {items.Employee[0].LastName}
                            </td>
                            <td align="center" valign="middle">
                              {items.Employee[0].Department}
                            </td>
                            <td align="center" valign="middle">
                              {items.Employee[0].Position}
                            </td>
                            <td align="center" valign="middle">
                              {items.ApproverGroupName == "Null" ? "-" : items.ApproverGroupName}
                            </td>
                            <td align="center" valign="middle">
                              {items.approve_status == "No user"
                                ? "-"
                                : items.approve_status}
                            </td>

                            <td align="center" valign="middle">
                              {items.approve_status === "Approved" ? '-' :
                                items.currentname
                              }
                            </td>
                            <td align="center" valign="middle">
                              {items.lastapprover}
                            </td>
                            <td align="center" valign="middle">
                              {items.ApproverGroupCompleteName}
                            </td>
                            <td align="center" valign="middle">
                            { items.PeriodEndingDate !== null ? moment (new Date(items.PeriodEndingDate).toLocaleDateString()).format("DD/MM/YYYY") : moment (new Date(items.PayTimes.PeriodEnding).toLocaleDateString()).format("DD/MM/YYYY")}
                            </td>
                            <td align="center" valign="middle">
                              {items.Gross}
                            </td>
                            <td align="center" valign="middle">
                              {items.TaxDeducted}
                            </td>

                            <td align="center" valign="middle">
                              <a
                                href={null}
                                onClick={this.edit_timecard.bind(
                                  this,
                                  items.Guid
                                )}
                                className="svg_pullleft_edit cursorPointer"
                                data-toggle="modal"
                                data-target="#batched_edit_pop"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    id="ic_create_24px"
                                    className="cls-1"
                                    d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z"
                                    transform="translate(-3 -2.998)"
                                  ></path>
                                </svg>
                              </a>
                            </td>
                          </tr>
                        ) : (
                            <tr key={items.Guid}>
                              <td align="center" valign="middle">
                                <input
                                  name={items.chkBoxName}
                                  type="checkbox"
                                  disabled
                                // onChange={(e) => this.AllCheckBoxPayrollEditHandler(items.chkBoxName, e.target.checked)}
                                />
                              </td>
                              <td align="center" valign="middle">
                                {items.freelanceuser[0].FirstName}
                              </td>
                              <td align="center" valign="middle">
                                {items.freelanceuser[0].LastName}
                              </td>
                              <td align="center" valign="middle">
                                {items.PRLTimes.Dept}
                              </td>
                              <td align="center" valign="middle">
                                {items.PRLTimes.Position}
                              </td>
                              <td align="center" valign="middle">
                                -
                            </td>
                              <td align="center" valign="middle">
                                -
                            </td>
                              <td align="center" valign="middle">
                                -
                            </td>
                              <td align="center" valign="middle">
                                -
                            </td>
                              <td align="center" valign="middle">
                                -
                            </td>
                              <td align="center" valign="middle">
                                -
                            </td>

                              <td align="center" valign="middle">
                                --
                            </td>
                              <td align="center" valign="middle">
                                -
                            </td>
                              <td align="center" valign="middle">
                                -
                            </td>

                              <td align="center" valign="middle">
                                <a
                                  href={null}
                                  onClick={this.edit_timecard.bind(
                                    this,
                                    items.Guid
                                  )}
                                  data-toggle="modal"
                                  className="cursorPointer"
                                  data-target="#batched_edit_pop"
                                  className="svg_pullleft_edit cursorPointer"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                  >
                                    <path
                                      id="ic_create_24px"
                                      className="cls-1"
                                      d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z"
                                      transform="translate(-3 -2.998)"
                                    ></path>
                                  </svg>
                                </a>
                              </td>
                            </tr>
                          )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="clearfix"> </div>
          {this.props.history.location.state.data === 1 ? null :this.state.countingOtherThanDraft!==0?null:(<div className="col-sm-12 send_for_aproval p0">
              <a
              
                href={null}
                data-toggle="modal"
                className="cursorPointer"
                onClick={this.countrecords}
                data-target="#send_approval_table_tbn"
              >
                Send for Approval{" "}
              </a>
            </div>)}
        
        </div>

        <div id="send_approval_table_tbn" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle">
            <div className="modal-content modal_middle_h_batchreport">
              <div className="modal-header">
                <button
                  type="button"
                  className="close close_modal_payroll"
                  data-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title payroll_modal_header">
                  Send for Approval
                </h4>
              </div>
              <div className="modal-body">
                <div className="col-sm-12 payroll_rpoerts_label">
                  <input
                    type="text"
                    name="payrollEdit_sendApprovalTable_btnInput"
                    value={this.state.payrollEdit_sendApprovalTable_btnInput}
                    className="form-control"
                    placeholder={
                      this.state.counting +
                      " Timecards selected to be sent for approval"
                    }
                    onChange={this.payrllbatches_EditInputHandler}
                  />
                  <p
                    className={
                      "error validation " +
                      (this.state.payrollEdit_SendApprovalTableBtnErrors
                        .payrollEdit_sendApprovalTable_btnInput
                        ? ""
                        : "displayNone")
                    }
                  >
                    {
                      this.state.payrollEdit_SendApprovalTableBtnErrors
                        .payrollEdit_sendApprovalTable_btnInput
                    }
                  </p>
                </div>

                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    <a
                      href={null}
                      className="btn_save_analytics cursorPointer"
                      onClick={this.get_checked_records}
                    >
                      Start
                    </a>
                    <a
                      href={null}
                      className="btn_cancel_analytics cursorPointer"
                      id="close_btn_tc"
                      data-dismiss="modal"
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10"></div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="batched_edit_pop"
          data-keyboard="false"
          data-backdrop="static"
          className="modal fade"
          role="dialog"
        >
          <div className="modal-dialog ">
            <div className="modal-content ">
              <div className="modal-header">
                <button
                  type="button"
                  className="close close_modal_payroll"
                  data-dismiss="modal"
                  onClick={this.clear_payrollmodal_states}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title payroll_modal_header">
                  Payroll Entry Details
                </h4>
              </div>
              <div className="modal-body ">
                <div className="col-sm-12 p0">
                  <div className="col-xs-5 col-sm-5 col-md-4 label_batched_editpop2">
                    Employee:
                  </div>

                  <div className="col-xs-7 col-sm-8  col-md-8 payrollbatches_edit2">
                    <input
                      name="payrollEdit_EntryDet_EmployeeSearchInput"
                      id="payrollEdit_EntryDet_EmployeeSearchInput"
                      value={
                        this.state.payrollEdit_EntryDet_EmployeeSearchInput +
                        " " +
                        this.state.Employee_LastName
                      }
                      readOnly
                      type="text"
                      onChange={this.payrllbatches_EditInputHandler}
                    />
                    <a
                      href={null}
                      className="payroll_edit_popsearc"
                      data-toggle="modal"
                      data-target="#parolledit2_search_pop"
                    >
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="ic_search_24px"
                          d="M17.294,15.579h-.9l-.32-.309a7.444,7.444,0,1,0-.8.8l.309.32v.9L21.3,23,23,21.3Zm-6.861,0a5.146,5.146,0,1,1,5.146-5.146A5.139,5.139,0,0,1,10.433,15.579Z"
                          transform="translate(-3 -3)"
                          fill="#c4c4c4"
                        />
                      </svg>
                    </a>
                    <p
                      className={
                        "error validation " +
                        (this.state.payrollEdit_EntryDetErrors
                          .payrollEdit_EntryDet_EmployeeSearchInput
                          ? ""
                          : "displayNone")
                      }
                    >
                      {
                        this.state.payrollEdit_EntryDetErrors
                          .payrollEdit_EntryDet_EmployeeSearchInput
                      }
                    </p>
                  </div>

                  <div className="col-xs-5 col-sm-5 col-md-4 label_batched_editpop2">
                    Period Ending:
                  </div>
                  <div className="col-xs-7 col-sm-8 col-md-5 payrollbatches_edit2">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      className="calender"
                      id="ad_times_date_calender"
                      value={this.state.payrollEdit_EntryDet_PeriodEnding}
                      selected={this.state.payrollEdit_EntryDet_PeriodEnding}
                      onChange={this.handleDateChange}
                      ref={c => (this._calendar = c)}
                    />
                    <a
                      onClick={this.openDatepicker}
                      href={null}
                      className="payroll_edit_pop_calend"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                      >
                        <path
                          id="ic_date_range_24px"
                          d="M9,11H7v2H9Zm4,0H11v2h2Zm4,0H15v2h2Zm2-7H18V2H16V4H8V2H6V4H5A1.991,1.991,0,0,0,3.01,6L3,20a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,19,4Zm0,16H5V9H19Z"
                          transform="translate(-3 -2)"
                          fill="#30679a"
                        />
                      </svg>
                    </a>
                    <p
                      className={
                        "error validation " +
                        (this.state.payrollEdit_EntryDetErrors
                          .payrollEdit_EntryDet_PeriodEnding
                          ? ""
                          : "displayNone")
                      }
                    >
                      {
                        this.state.payrollEdit_EntryDetErrors
                          .payrollEdit_EntryDet_PeriodEnding
                      }
                    </p>
                  </div>

                  <div className="clear10"></div>
                  <div className="col-xs-5 col-sm-5 col-md-4 label_batched_editpop2">
                    Department:
                  </div>

                  <div className="col-xs-7 col-sm-8  col-md-8 payrollbatches_edit2">
                    <input
                      readOnly
                      name="payrollEdit_EntryDet_Department"
                      id="payrollEdit_EntryDet_Department"
                      value={this.state.payrollEdit_EntryDet_Department}
                      onChange={this.payrllbatches_EditInputHandler}
                      type="text"
                    />
                    <p
                      className={
                        "error validation " +
                        (this.state.payrollEdit_EntryDetErrors
                          .payrollEdit_EntryDet_Department
                          ? ""
                          : "displayNone")
                      }
                    >
                      {
                        this.state.payrollEdit_EntryDetErrors
                          .payrollEdit_EntryDet_Department
                      }
                    </p>
                  </div>

                  <div className="clear10"></div>
                  <div className="col-xs-5 col-sm-5 col-md-4 label_batched_editpop2">
                    Position:
                  </div>

                  <div className="col-xs-7 col-sm-8  col-md-8 payrollbatches_edit2">
                    <input
                      readOnly
                      name="payrollEdit_EntryDet_Position"
                      id="payrollEdit_EntryDet_Position"
                      value={this.state.payrollEdit_EntryDet_Position}
                      type="text"
                      onChange={this.payrllbatches_EditInputHandler}
                    />
                    <p
                      className={
                        "error validation " +
                        (this.state.payrollEdit_EntryDetErrors
                          .payrollEdit_EntryDet_Position
                          ? ""
                          : "displayNone")
                      }
                    >
                      {
                        this.state.payrollEdit_EntryDetErrors
                          .payrollEdit_EntryDet_Position
                      }
                    </p>
                  </div>

                  <div className="clear10"></div>
                  <div className="col-xs-5 col-sm-5 col-md-4 label_batched_editpop2">
                    Email:
                  </div>

                  <div className="col-xs-7 col-sm-8  col-md-8 payrollbatches_edit2">
                    <input
                      name="payrollEdit_EntryDet_Email"
                      type="text"
                      id="payrollEdit_EntryDet_Email"
                      value={this.state.payrollEdit_EntryDet_Email}
                      onChange={this.payrllbatches_EditInputHandler}
                    />
                    <p
                      className={
                        "error validation " +
                        (this.state.payrollEdit_EntryDetErrors
                          .payrollEdit_EntryDet_Email
                          ? ""
                          : "displayNone")
                      }
                    >
                      {
                        this.state.payrollEdit_EntryDetErrors
                          .payrollEdit_EntryDet_Email
                      }
                    </p>
                  </div>

                  <div className="clear10"></div>
                  <div className="col-xs-5 col-sm-5 col-md-4 label_batched_editpop2">
                    Approver Group:
                  </div>

                  <div className="col-xs-7 col-sm-8  col-md-8 payrollbatches_edit2">
                    <select
                      value={
                        this.state.payrollEdit_EntryDet_ApprovSelectedOption
                      }
                      name="payrollEdit_EntryDet_ApprovSelectedOption"
                      id="payrollEdit_EntryDet_ApprovSelectedOption"
                      onChange={this.payrollEditEntryApprvGrpSelectHandler}
                    >
                      <option value="">Select</option>
                      {this.state.payrollEdit_EntryDet_ApproverGroupOpt.map(
                        value => {
                          return (
                            <option key={value.Guid} value={value.Guid}>
                              {value.GroupName}
                            </option>
                          );
                        }
                      )}
                    </select>
                    <p
                      className={
                        "error validation " +
                        (this.state.payrollEdit_EntryDetErrors
                          .payrollEdit_EntryDet_ApprovSelectedOption
                          ? ""
                          : "displayNone")
                      }
                    >
                      {
                        this.state.payrollEdit_EntryDetErrors
                          .payrollEdit_EntryDet_ApprovSelectedOption
                      }
                    </p>
                  </div>

                  <div className="clear10"></div>
                  <div className="col-xs-5 col-sm-5 col-md-4 label_batched_editpop2">
                    State Tax
                  </div>

                  <div className="col-xs-7 col-sm-8  col-md-8 payrollbatches_edit2">
                    <input
                      name="payrollEdit_EntryDet_StateTax"
                      type="text"
                      id="payrollEdit_EntryDet_StateTax"
                      value={this.state.payrollEdit_EntryDet_StateTax}
                      onChange={this.payrllbatches_EditInputHandler}
                    />
                    <p
                      className={
                        "error validation " +
                        (this.state.payrollEdit_EntryDetErrors
                          .payrollEdit_EntryDet_StateTax
                          ? ""
                          : "displayNone")
                      }
                    >
                      {
                        this.state.payrollEdit_EntryDetErrors
                          .payrollEdit_EntryDet_StateTax
                      }
                    </p>
                  </div>

                  <div className="clear10"></div>
                  <div className="col-xs-5 col-sm-5 col-md-4 label_batched_editpop2">
                    Group:
                  </div>

                  <div className="col-xs-7 col-sm-8  col-md-8 payrollbatches_edit2">
                    <input
                      name="payrollEdit_EntryDet_Group"
                      type="text"
                      id="payrollEdit_EntryDet_Group"
                      value={this.state.payrollEdit_EntryDet_Group}
                      onChange={this.payrllbatches_EditInputHandler}
                    />
                    <p
                      className={
                        "error validation " +
                        (this.state.payrollEdit_EntryDetErrors
                          .payrollEdit_EntryDet_Group
                          ? ""
                          : "displayNone")
                      }
                    >
                      {
                        this.state.payrollEdit_EntryDetErrors
                          .payrollEdit_EntryDet_Group
                      }
                    </p>
                  </div>

                  <div className="clear10"></div>
                  <div className="col-xs-5 col-sm-5 col-md-4 label_batched_editpop2">
                    Start Date:
                  </div>

                  <div className="col-xs-7 col-sm-8  col-md-5 payrollbatches_edit2">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      id="ad_times_date_calender"
                      value={this.state.payrollEdit_EntryDet_StartDate}
                      selected={this.state.payrollEdit_EntryDet_StartDate}
                      onChange={this.handleStartDateChange}
                    />

                    <a
                      href={null}
                      className="payroll_edit_pop_calend cursorPointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                      >
                        <path
                          id="ic_date_range_24px"
                          d="M9,11H7v2H9Zm4,0H11v2h2Zm4,0H15v2h2Zm2-7H18V2H16V4H8V2H6V4H5A1.991,1.991,0,0,0,3.01,6L3,20a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,19,4Zm0,16H5V9H19Z"
                          transform="translate(-3 -2)"
                          fill="#30679a"
                        />
                      </svg>
                    </a>
                    <p
                      className={
                        "error validation " +
                        (this.state.payrollEdit_EntryDetErrors
                          .payrollEdit_EntryDet_StartDate
                          ? ""
                          : "displayNone")
                      }
                    >
                      {
                        this.state.payrollEdit_EntryDetErrors
                          .payrollEdit_EntryDet_StartDate
                      }
                    </p>
                  </div>

                  <div className="clear10"></div>
                  <div className="col-xs-5 col-sm-5 col-md-4 label_batched_editpop2">
                    End Date:
                  </div>

                  <div className="col-xs-7 col-sm-8 col-md-5 payrollbatches_edit2">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      id="ad_times_date_calender"
                      value={this.state.payrollEdit_EntryDet_EndDate}
                      selected={this.state.payrollEdit_EntryDet_EndDate}
                      onChange={this.handleEndDateChange}
                    />

                    <a
                      href={null}
                      className="payroll_edit_pop_calend cursorPointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                      >
                        <path
                          id="ic_date_range_24px"
                          d="M9,11H7v2H9Zm4,0H11v2h2Zm4,0H15v2h2Zm2-7H18V2H16V4H8V2H6V4H5A1.991,1.991,0,0,0,3.01,6L3,20a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,19,4Zm0,16H5V9H19Z"
                          transform="translate(-3 -2)"
                          fill="#30679a"
                        />
                      </svg>
                    </a>
                    <p
                      className={
                        "error validation " +
                        (this.state.payrollEdit_EntryDetErrors
                          .payrollEdit_EntryDet_EndDate
                          ? ""
                          : "displayNone")
                      }
                    >
                      {
                        this.state.payrollEdit_EntryDetErrors
                          .payrollEdit_EntryDet_EndDate
                      }
                    </p>
                  </div>

                  <div className="clear10"></div>
                  <div className="col-xs-5 col-sm-5 col-md-4 label_batched_editpop2">
                    Terminated:
                  </div>

                  <div className="col-xs-7 col-sm-8  col-md-8 payroll_edit_chec">
                    <div className="checkbox checkbox_mrg_toppp">
                      <input
                        id="checkbox1"
                        type="checkbox"
                        name="payrollEdit_EntryDet_TerminatedChkBox"
                        checked={
                          this.state.payrollEdit_EntryDet_TerminatedChkBox
                        }
                        onChange={this.payrllbatches_EditInputHandler}
                      />

                      <label htmlFor="checkbox1"></label>
                    </div>
                  </div>

                  <div className="clear10"></div>

                  <div className="table_payroll_edit_2 paryroll_popedit_3 pop_overflow_email3">
                    <table
                      width="100%"
                      cellSpacing="0"
                      cellPadding="0"
                      className="brd_table_pay_edit"
                    >
                      <thead>
                        <tr>
                          <th width="20%" align="left" valign="middle">
                            Attachments
                          </th>
                          <th width="30%" align="left" valign="middle"></th>
                        </tr>
                      </thead>

                      <tbody>
                        {this.state.payrollAttachments.map(item => {
                          return (
                            <tr>
                              <td align="left" valign="middle">
                                {item.filename}
                              </td>
                              <td align="center">
                                <a
                                  href={null}
                                  className="icon_edit_edit_pop2 cursorPointer"
                                  onClick={this.showEmployeeAttachment.bind(
                                    this,
                                    item.filename
                                  )}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                  >
                                    <defs></defs>
                                    <path
                                      id="ic_description_24px"
                                      className="cls-1"
                                      d="M16.5,2H6.5A2.287,2.287,0,0,0,4.013,4L4,20a2.287,2.287,0,0,0,2.487,2H21.5A2.3,2.3,0,0,0,24,20V8ZM19,18H9V16H19Zm0-4H9V12H19ZM15.25,9V3.5L22.125,9Z"
                                      transform="translate(-4 -2)"
                                    />
                                  </svg>
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    {this.state.edit_card ? (
                      <a
                        href={null}
                        className="btn_save_analytics cursorPointer"
                        onClick={this.onPayrollEntryDetSaveHandler}
                      >
                        Update
                      </a>
                    ) : (
                        <a
                          href={null}
                          className="btn_save_analytics cursorPointer"
                          onClick={this.onPayrollEntryDetSaveHandler}
                        >
                          Save
                      </a>
                      )}
                    <a
                      href={null}
                      id="closePayrollModal"
                      className="btn_cancel_analytics cursorPointer"
                      data-dismiss="modal"
                      onClick={this.clear_payrollmodal_states}
                    >
                      Cancel
                    </a>
                  </div>
                </div>
                <div className="clear10"></div>
              </div>
              <div className="clear10"></div>
            </div>
            <div className="clear10"></div>
          </div>
        </div>

        <div id="parolledit2_search_pop" className="modal fade" role="dialog">
          <div className="modal-dialog payroll_edit2_pop_search">
            <div className="modal-content modal_content_parollb_pop3 mdl_mrggg  btncancel_setting">
              <div className="modal-header edit_detail_hdr">
                <button
                  type="button"
                  className="close close_adtime2_pop_edit"
                  data-dismiss="modal"
                  onClick={this.issue_ScrollHandler}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title"></h4>
              </div>
              <div className="modal-body">
                <div className="col-sm-12 payroll_b2_scroll_pop3">
                  <div className="col-sm-10 p0">
                    <div className="search_area_payroll_b2_pop2">
                      <div className="search_area_addtime2_pop2">
                        <input
                          name="Adtimes2_ModalSearchName_Search"
                          type="text"
                          className="search_adtimes2_popsearch"
                          id="Adtimes2_ModalSearchName_Search"
                          value={this.state.Adtimes2_ModalSearchName_Search}
                          onChange={this.EmployeesInputsChngHandlers}
                          placeholder={`Search by ${this.state.AdTimes2_NameSearchModal_SelectedTab}`}
                        />
                        <img
                          alt="no"
                          src={ic_search}
                          className="searchicon_adtimes2_pop"
                          width="20"
                          height="20"
                        />
                      </div>
                      <img
                        src={SearchSvg}
                        className="searchicon_batch_edit2_pop"
                        width="20"
                        height="20"
                        alt=""
                      />
                    </div>
                    <div className="search_area_payroll_bed2_pop3">
                      <div className="pull-left">
                        <div className="checkbox chkBoxMarginTop">
                          <input
                            name="Adtimes2_Modal2SearchName_TerminatedChkbx"
                            checked={
                              this.state
                                .Adtimes2_Modal2SearchName_TerminatedChkbx
                            }
                            type="checkbox"
                            id="Adtimes2_Modal2SearchName_TerminatedChkbx"
                            onChange={this.TerminateHandler}
                          />
                          <label htmlFor="Adtimes2_Modal2SearchName_TerminatedChkbx"></label>
                        </div>
                      </div>
                      <div className="pull-left">
                        {" "}
                        Show terminated employees
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-2 p0 mrg_topright2_bactedt">
                    <a
                      href={null}
                      onClick={this.refreshEmplopyees}
                      className="refresh_icon_payroll cursorPointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24.224"
                        height="17.617"
                        viewBox="0 0 24.224 17.617"
                      >
                        <path
                          id="ic_cached_24px"
                          d="M20.819,8.4l-4.4,4.4h3.3a6.612,6.612,0,0,1-6.606,6.606,6.464,6.464,0,0,1-3.083-.771L8.421,20.252a8.793,8.793,0,0,0,13.5-7.443h3.3Zm-14.314,4.4A6.612,6.612,0,0,1,13.112,6.2a6.464,6.464,0,0,1,3.083.771L17.8,5.365A8.793,8.793,0,0,0,4.3,12.809H1l4.4,4.4,4.4-4.4Z"
                          transform="translate(-1 -4)"
                          fill="#7B7B7B"
                        />
                      </svg>
                    </a>
                  </div>
                  <div className="clear10"></div>

                  <div className="col-sm-12 p0">
                    <div className="overflow_scroll_sm payrolledit2_pop3">
                      <table
                        width="100%"
                        className="table table-bordered timecard_table addtime_2_pop3"
                      >
                        <thead>
                          <tr className="table_header_pop3">
                            <th align="center"></th>
                            <th>
                              <div
                                className="Fn_mrgleft"
                                onClick={() =>
                                  this.sortEmployees(
                                    "FirstName",
                                    "FirstNameCaret"
                                  )
                                }
                              >
                                {" "}
                                First Name
                              </div>
                              <div className="batch_downarrow batch_droppp_aroww">
                                <svg
                                  id="FirstNameCaret"
                                  className={
                                    !this.state
                                      .AdTimes2_NameModal_FirstName_Toggle
                                      ? "toggleCaretClass"
                                      : ""
                                  }
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12.337"
                                  height="6.887"
                                  viewBox="0 0 12.337 6.887"
                                >
                                  <path
                                    id="ic_arrow_drop_down_24px"
                                    d="M7,10l6.169,6.887L19.337,10Z"
                                    transform="translate(-7 -10)"
                                    fill="#30679a"
                                  />
                                </svg>
                              </div>
                            </th>
                            <th>
                              <div
                                className="pull-left"
                                onClick={() =>
                                  this.sortEmployees(
                                    "LastName",
                                    "LastNameCaret"
                                  )
                                }
                              >
                                {" "}
                                Last Name
                              </div>
                              <div className="batch_downarrow batch_droppp_aroww">
                                <svg
                                  id="LastNameCaret"
                                  className={
                                    !this.state
                                      .AdTimes2_NameModal_LastName_Toggle
                                      ? "toggleCaretClass"
                                      : ""
                                  }
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12.337"
                                  height="6.887"
                                  viewBox="0 0 12.337 6.887"
                                >
                                  <path
                                    id="ic_arrow_drop_down_24px"
                                    d="M7,10l6.169,6.887L19.337,10Z"
                                    transform="translate(-7 -10)"
                                    fill="#30679a"
                                  />
                                </svg>
                              </div>
                            </th>
                            <th>
                              <div
                                className="email_mrgleft"
                                onClick={() =>
                                  this.sortEmployees("Email", "EmailCaret")
                                }
                              >
                                {" "}
                                Email
                              </div>
                              <div className="batch_downarrow batch_droppp_aroww">
                                <svg
                                  id="EmailCaret"
                                  className={
                                    !this.state.AdTimes2_NameModal_Email_Toggle
                                      ? "toggleCaretClass"
                                      : ""
                                  }
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12.337"
                                  height="6.887"
                                  viewBox="0 0 12.337 6.887"
                                >
                                  <path
                                    id="ic_arrow_drop_down_24px"
                                    d="M7,10l6.169,6.887L19.337,10Z"
                                    transform="translate(-7 -10)"
                                    fill="#30679a"
                                  />
                                </svg>
                              </div>
                            </th>
                            <th>
                              <div
                                className="mobile_mrgleft"
                                onClick={() =>
                                  this.sortEmployees("Mobile", "MobileCaret")
                                }
                              >
                                {" "}
                                Mobile
                              </div>
                              <div className="batch_downarrow batch_droppp_aroww">
                                <svg
                                  id="MobileCaret"
                                  className={
                                    !this.state.AdTimes2_NameModal_Mobile_Toggle
                                      ? "toggleCaretClass"
                                      : ""
                                  }
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12.337"
                                  height="6.887"
                                  viewBox="0 0 12.337 6.887"
                                >
                                  <path
                                    id="ic_arrow_drop_down_24px"
                                    d="M7,10l6.169,6.887L19.337,10Z"
                                    transform="translate(-7 -10)"
                                    fill="#30679a"
                                  />
                                </svg>
                              </div>
                            </th>
                            <th>
                              <div
                                className="Dep_mrgleft"
                                onClick={() =>
                                  this.sortEmployees(
                                    "Department",
                                    "DepartmentCaret"
                                  )
                                }
                              >
                                {" "}
                                Dept
                              </div>
                              <div className="batch_downarrow batch_droppp_aroww">
                                <svg
                                  id="DepartmentCaret"
                                  className={
                                    !this.state.AdTimes2_NameModal_Dept_Toggle
                                      ? "toggleCaretClass"
                                      : ""
                                  }
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12.337"
                                  height="6.887"
                                  viewBox="0 0 12.337 6.887"
                                >
                                  <path
                                    id="ic_arrow_drop_down_24px"
                                    d="M7,10l6.169,6.887L19.337,10Z"
                                    transform="translate(-7 -10)"
                                    fill="#30679a"
                                  />
                                </svg>
                              </div>
                            </th>
                            <th>
                              <div
                                className="position_mrgleft"
                                onClick={() =>
                                  this.sortEmployees(
                                    "Position",
                                    "PositionCaret"
                                  )
                                }
                              >
                                {" "}
                                Position
                              </div>
                              <div className="batch_downarrow batch_droppp_aroww">
                                <svg
                                  id="PositionCaret"
                                  className={
                                    !this.state
                                      .AdTimes2_NameModal_Position_Toggle
                                      ? "toggleCaretClass"
                                      : ""
                                  }
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12.337"
                                  height="6.887"
                                  viewBox="0 0 12.337 6.887"
                                >
                                  <path
                                    id="ic_arrow_drop_down_24px"
                                    d="M7,10l6.169,6.887L19.337,10Z"
                                    transform="translate(-7 -10)"
                                    fill="#30679a"
                                  />
                                </svg>
                              </div>
                            </th>
                            <th>
                              <div
                                className="approveGroup_mrgleft"
                                onClick={() =>
                                  this.sortEmployees(
                                    "ApproverGroup",
                                    "ApproverGroupCaret"
                                  )
                                }
                              >
                                {" "}
                                Approver Group
                              </div>
                              <div className="batch_downarrow batch_droppp_aroww">
                                <svg
                                  id="ApproverGroupCaret"
                                  className={
                                    !this.state
                                      .AdTimes2_NameModal_ApproverGroup_Toggle
                                      ? "toggleCaretClass"
                                      : ""
                                  }
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12.337"
                                  height="6.887"
                                  viewBox="0 0 12.337 6.887"
                                >
                                  <path
                                    id="ic_arrow_drop_down_24px"
                                    d="M7,10l6.169,6.887L19.337,10Z"
                                    transform="translate(-7 -10)"
                                    fill="#30679a"
                                  />
                                </svg>
                              </div>
                            </th>
                          </tr>
                        </thead>{" "}
                        <tbody>
                          {empMapArray.map(item => {
                            return (
                              <tr key={item.EmpGuid}>
                                <td align="center">
                                  <div
                                    className="addtime_pop4_svg"
                                    onClick={this.onEmployeSelected.bind(
                                      this,
                                      item.EmpGuid
                                    )}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="17.6"
                                      height="13.4"
                                      viewBox="0 0 17.6 13.4"
                                    >
                                      <path
                                        id="ic_done_24px"
                                        d="M9,16.2,4.8,12,3.4,13.4,9,19,21,7,19.6,5.6Z"
                                        transform="translate(-3.4 -5.6)"
                                      />
                                    </svg>
                                  </div>
                                </td>
                                <td align="center">{item.FirstName}</td>
                                <td align="center">{item.LastName}</td>
                                <td align="center">{item.Email}</td>
                                <td align="center">{item.Mobile}</td>
                                <td align="center">{item.Department}</td>
                                <td align="center">{item.Position}</td>
                                <td align="center">{item.ApproverGroup}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    <a
                      href={null}
                      id="closeEmployeeModal"
                      className="btn_cancel_analytics mrg_left0_addtime  cursorPointer"
                      data-dismiss="modal"
                      onClick={this.issue_ScrollHandler}
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>

                <div className="clear10"></div>
              </div>
            </div>
          </div>
        </div>

        <div id="excel_pop_payroll" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle_payroll modal-dialog-centered modal_no_scrol_employeee">
            <div className="modal-content modal_middle_h_payroll">
              <div className="modal-header">
                <button
                  type="button"
                  className="close close_modal_payroll"
                  data-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title payrolll_modal_header">Excel</h4>
              </div>
              <div className="modal-body">
                <div className="col-sm-8 payroll_input_pop1">
                  <select
                    id="payroll_selectExcel"
                    name="payroll_selectExcel"
                    onChange={this.payroll_selectHandler}
                  >
                    <option>Payroll Batches</option>
                  </select>
                </div>

                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    <a href={null} className="btn_save_analytics cursorPointer">
                      Export
                    </a>
                    <a
                      id="export_excel_cancel_btn"
                      href={null}
                      className="btn_cancel_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10" />
              </div>
            </div>
          </div>
        </div>

        <div id="select_paste_pop" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle_adtimes modal-dialog-centered modal_no_scrol_employeee">
            <div className="modal-content modal_middle_h_adtimes2">
              <div className="modal-header">
                <button
                  type="button"
                  className="close close_modal_adtimes"
                  data-dismiss="modal"
                  id="close_paste"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title adtimesl_modal_header">
                  Select Paste
                </h4>
              </div>
              <div className="modal-body">
                <div className="col-sm-8 payroll_input_pop1">
                  <select id="adTimes_selectPaste" name="adTimes_selectPaste">
                    <option>Payroll Batches</option>
                  </select>
                </div>
                <div className="col-sm-12 adtimes_input_pop1">
                  <textarea name="importdata" cols="" rows="" />
                </div>

                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    <a href={null} className="btn_save_analytics cursorPointer">
                      Start
                    </a>
                    <a
                      href={null}
                      className="btn_cancel_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10" />
              </div>
            </div>
          </div>
        </div>

        <div id="payroll_reports" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle_adtimes modal-dialog-centered modal_no_scrol_employeee">
            <div className="modal-content modal_middle_h_adtimes">
              <div className="modal-header">
                <button
                  type="button"
                  className="close close_modal_adtimes"
                  data-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title adtimesl_modal_header">Reports</h4>
              </div>
              <div className="modal-body">
                <div className="col-sm-8 adtimes_input_pop1">
                  <select
                    id="PayrollBatchReports"
                    name="PayrollBatchReport"
                    onChange={this.payrollBatchReport}
                  >
                    <option defaultValue disabled>
                      Select Report Type
                    </option>
                    <option value="PayrollBatchReport">
                      Payroll Batch Report
                    </option>

                    <option value="TimeCardReport">Time Card Report</option>
                  </select>
                </div>

                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    <a
                      href={null}
                      className="btn_save_analytics cursorPointer"
                      onClick={this.generateReport}
                    >
                      Save
                    </a>
                    <a
                      href={null}
                      className="btn_cancel_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10" />
              </div>
            </div>
          </div>
        </div>

        <div id="payroll_delete" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle_adtimes modal-dialog-centered modal_no_scrol_employeee">
            <div className="modal-content modal_middle_h_adtimes">
              <div className="modal-header">
                <button
                  type="button"
                  className="close close_modal_adtimes"
                  data-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title adtimesl_modal_header">
                  Delete from batch
                </h4>
              </div>
              <div className="modal-body">
                <div className="col-sm-8 adtimes_input_pop1">
                  {this.state.counting} Employees selected to be deleted from
                  batch.
                </div>

                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    <a
                      href={null}
                      className="btn_save_analytics cursorPointer"
                      onClick={this.deletePayroll}
                    >
                      Delete
                    </a>
                    <a
                      id="close_del_modal"
                      href={null}
                      className="btn_cancel_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10" />
              </div>
            </div>
          </div>
        </div>

        <div id="payroll_move_2" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle_adtimes modal-dialog-centered modal_no_scrol_employeee">
            <div className="modal-content modal_middle_h_adtimes">
              <div className="modal-header">
                <button
                  type="button"
                  className="close close_modal_adtimes"
                  data-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title adtimesl_modal_header">
                  Move to New Batch
                </h4>
              </div>
              <div className="modal-body">
                <div className="col-sm-8 adtimes_input_pop1">
                  <select
                    onChange={this.selectBatch}
                    value={this.state.batch_to_updtae}
                    id="adTimes_selectReports"
                    name="adTimes_selectReports"
                  >
                    <option value="">Select Batch</option>
                    {this.state.Batches.map(value => (
                      <option key={value.Batch} value={value.Batch}>
                        {value.Desc}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    <a
                      href={null}
                      className="btn_save_analytics cursorPointer"
                      onClick={this.moverecords}
                    >
                      Move
                    </a>
                    <a
                      id="close_move"
                      href={null}
                      className="btn_cancel_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10" />
              </div>
            </div>
          </div>
        </div>

        <div id="adtimes2_search_pop" className="modal fade" role="dialog">
          <div className="modal-dialog w_addtime2_pop_search">
            <div className="modal-content modal_content_addtime2_pop3 mdl_hgttt">
              <div className="modal-header edit_detail_hdr">
                <button
                  type="button"
                  className="close close_adtime2_pop_edit"
                  id="close_every_modal"
                  data-dismiss="modal"
                  onClick={this.issue_ScrollHandler}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title">Everyone Paid</h4>
              </div>
              <div className="modal-body">
                <div className="col-sm-12 addtime2_scroll_pop3 everyone_table">
                  <div className="col-sm-12 p0">
                    <div className="row">
                      <div className="col-sm-4 col-xs-12 everyone_margin">
                        <div className="search_area_addtime2_pop2">
                          <input
                            name="Adtimes2_ModalSearchName_Search"
                            type="text"
                            className="search_adtimes2_popsearch"
                            id="Adtimes2_ModalSearchName_Search"
                          />
                          <img
                            alt="no"
                            src={ic_search}
                            className="searchicon_adtimes2_pop everyone_search"
                            width="20"
                            height="20"
                          />
                        </div>
                      </div>
                      <div className="col-sm-2 col-xs-12 everyone_margin every_label">
                        <p>Period Ending Time:</p>
                      </div>
                      <div className="col-sm-2 col-xs-7 everyone_margin">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          id="ad_times_date_calender_everyone_paid"
                          className="search_adtimes2_popsearch everyone_input"
                          value={this.state.everyOnePaid}
                          selected={this.state.everyOnePaid}
                          onChange={this.handleDateeveryone}
                        />
                        <a
                          href={null}
                          className="calendar_icon_ad_times cursorPointer everyone_calendar"
                        >
                          <svg
                            onClick={this.open_AdTimes_calender}
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="20"
                            viewBox="0 0 18 20"
                          >
                            <path
                              id="ic_date_range_24px"
                              d="M9,11H7v2H9Zm4,0H11v2h2Zm4,0H15v2h2Zm2-7H18V2H16V4H8V2H6V4H5A1.991,1.991,0,0,0,3.01,6L3,20a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,19,4Zm0,16H5V9H19Z"
                              transform="translate(-3 -2)"
                              fill="#30679a"
                            />
                          </svg>
                        </a>
                      </div>
                      <div className="col-sm-2 col-xs-12 everyone_margin">
                        <a
                          href={null}
                          className="btn_save_analytics cursorPointer everyonebtn"
                        >
                          Start
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-2 p0 mrg_topright2"></div>

                  <div className="clear10"></div>

                  <div className="col-sm-12 p0">
                    <div className="overflow_scroll_sm">
                      <table
                        width="100%"
                        className="table table-bordered timecard_table addtime_2_pop3"
                      >
                        <thead>
                          <tr className="table_header_pop3">
                            <th align="center">
                              <div
                                className="cursorPointer"
                                onClick={() =>
                                  this.sortEmployees(
                                    "FirstName",
                                    "FirstNameCaret"
                                  )
                                }
                              >
                                <div className=""> First Name</div>
                                <div className="addtime2_pop_downarrow ad_tiimes__mrg_topp"></div>
                              </div>
                            </th>
                            <th align="center">
                              <div
                                className="cursorPointer"
                                onClick={() =>
                                  this.sortEmployees(
                                    "LastName",
                                    "LastNameCaret"
                                  )
                                }
                              >
                                <div className=""> Last Name</div>
                                <div className="addtime2_pop_downarrow ad_tiimes__mrg_topp">
                                  <svg
                                    id="LastNameCaret"
                                    className={
                                      !this.state
                                        .AdTimes2_NameModal_LastName_Toggle
                                        ? "toggleCaretClass"
                                        : ""
                                    }
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12.337"
                                    height="6.887"
                                    viewBox="0 0 12.337 6.887"
                                  >
                                    <path
                                      id="ic_arrow_drop_down_24px"
                                      d="M7,10l6.169,6.887L19.337,10Z"
                                      transform="translate(-7 -10)"
                                      fill="#30679a"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </th>
                            <th align="center">
                              <div
                                className="cursorPointer"
                                onClick={() =>
                                  this.sortEmployees("Email", "EmailCaret")
                                }
                              >
                                <div className=""> Email</div>
                                <div className="addtime2_pop_downarrow ad_tiimes__mrg_topp">
                                  <svg
                                    id="EmailCaret"
                                    className={
                                      !this.state
                                        .AdTimes2_NameModal_Email_Toggle
                                        ? "toggleCaretClass"
                                        : ""
                                    }
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12.337"
                                    height="6.887"
                                    viewBox="0 0 12.337 6.887"
                                  >
                                    <path
                                      id="ic_arrow_drop_down_24px"
                                      d="M7,10l6.169,6.887L19.337,10Z"
                                      transform="translate(-7 -10)"
                                      fill="#30679a"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </th>
                            <th align="center">
                              <div
                                className="cursorPointer"
                                onClick={() =>
                                  this.sortEmployees("Mobile", "MobileCaret")
                                }
                              >
                                <div className="">Mobile</div>
                                <div className="addtime2_pop_downarrow ad_tiimes__mrg_topp">
                                  <svg
                                    id="MobileCaret"
                                    className={
                                      !this.state
                                        .AdTimes2_NameModal_Mobile_Toggle
                                        ? "toggleCaretClass"
                                        : ""
                                    }
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12.337"
                                    height="6.887"
                                    viewBox="0 0 12.337 6.887"
                                  >
                                    <path
                                      id="ic_arrow_drop_down_24px"
                                      d="M7,10l6.169,6.887L19.337,10Z"
                                      transform="translate(-7 -10)"
                                      fill="#30679a"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </th>
                            <th align="center">
                              <div
                                className="cursorPointer"
                                onClick={() =>
                                  this.sortEmployees(
                                    "Department",
                                    "DepartmentCaret"
                                  )
                                }
                              >
                                <div className="">Dept</div>
                                <div className="addtime2_pop_downarrow ad_tiimes__mrg_topp">
                                  <svg
                                    id="DepartmentCaret"
                                    className={
                                      !this.state.AdTimes2_NameModal_Dept_Toggle
                                        ? "toggleCaretClass"
                                        : ""
                                    }
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12.337"
                                    height="6.887"
                                    viewBox="0 0 12.337 6.887"
                                  >
                                    <path
                                      id="ic_arrow_drop_down_24px"
                                      d="M7,10l6.169,6.887L19.337,10Z"
                                      transform="translate(-7 -10)"
                                      fill="#30679a"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </th>
                            <th align="center">
                              <div
                                className="cursorPointer"
                                onClick={() =>
                                  this.sortEmployees(
                                    "Position",
                                    "PositionCaret"
                                  )
                                }
                              >
                                <div className="">Position</div>
                                <div className="addtime2_pop_downarrow ad_tiimes__mrg_topp">
                                  <svg
                                    id="PositionCaret"
                                    className={
                                      !this.state
                                        .AdTimes2_NameModal_Position_Toggle
                                        ? "toggleCaretClass"
                                        : ""
                                    }
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12.337"
                                    height="6.887"
                                    viewBox="0 0 12.337 6.887"
                                  >
                                    <path
                                      id="ic_arrow_drop_down_24px"
                                      d="M7,10l6.169,6.887L19.337,10Z"
                                      transform="translate(-7 -10)"
                                      fill="#30679a"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </th>
                            <th align="center">
                              <div
                                className="cursorPointer"
                                onClick={() =>
                                  this.sortEmployees(
                                    "ApproverGroup",
                                    "ApproverGroupCaret"
                                  )
                                }
                              >
                                <div className=""> Approver Group</div>
                                <div className="addtime2_pop_downarrow ad_tiimes__mrg_topp">
                                  <svg
                                    id="ApproverGroupCaret"
                                    className={
                                      !this.state
                                        .AdTimes2_NameModal_ApproverGroup_Toggle
                                        ? "toggleCaretClass"
                                        : ""
                                    }
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12.337"
                                    height="6.887"
                                    viewBox="0 0 12.337 6.887"
                                  >
                                    <path
                                      id="ic_arrow_drop_down_24px"
                                      d="M7,10l6.169,6.887L19.337,10Z"
                                      transform="translate(-7 -10)"
                                      fill="#30679a"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* {(empMapArray.map(item => {
                            return ( */}
                          <tr>
                            <td align="center">FirstName</td>
                            <td align="center">LastName</td>
                            <td align="center">Email</td>
                            <td align="center">Mobile</td>
                            <td align="center">Department</td>
                            <td align="center">Position</td>
                            <td align="center">Approver Group</td>
                          </tr>
                          <tr>
                            <td align="center">FirstName</td>
                            <td align="center">LastName</td>
                            <td align="center">Email</td>
                            <td align="center">Mobile</td>
                            <td align="center">Department</td>
                            <td align="center">Position</td>
                            <td align="center">Approver Group</td>
                          </tr>
                          <tr>
                            <td align="center">FirstName</td>
                            <td align="center">LastName</td>
                            <td align="center">Email</td>
                            <td align="center">Mobile</td>
                            <td align="center">Department</td>
                            <td align="center">Position</td>
                            <td align="center">Approver Group</td>
                          </tr>
                          {/* )
                          }))} */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="clear10"></div>
                <div className="row">
                  <div className="col-sm-2 col-xs-6">
                    <a
                      href={null}
                      className="btn_save_analytics cursorPointer everyone_reminder"
                    >
                      Send Reminder
                    </a>
                  </div>
                  <div className="col-sm-2 col-xs-6">
                    <div className="analytics_btn_save">
                      <a
                        hreaf={null}
                        // data-dismiss="modal"
                        data-toggle="modal"
                        data-target="#adbatch_search_pop"
                        className="btn_cancel_analytics mrg_left0_addtime cursorPointer everyone_batch"
                        onClick={this.addBatch}
                      >
                        Add to Batch
                      </a>
                    </div>
                  </div>
                </div>

                <div className="clear10"></div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="adbatch_search_pop"
          className="modal fade payroll_batch_mm"
          role="dialog"
        >
          <div className="modal-dialog modal-md w_addtime2_pop_search">
            <div className="modal-content modal_content_batch mdl_hgttt">
              <div className="modal-header edit_detail_hdr batch_close payroll_batch_lock_invoice_header">
                <button
                  type="button"
                  className="close close_adtime2_pop_edit"
                  data-target="#adbatch_search_pop"
                  data-dismiss="modal"
                  onClick={this.issue_ScrollHandler}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title batch_hdr">Lock and Invoice</h4>
                <hr />
              </div>
              <div className="payroll_batch_lock_invoice">
                <div className="row">
                  <div className="col-sm-3 col-xs-12 every_label">
                    <p className="payroll_batch_period">Period Ending Time:</p>
                  </div>
                  <div className="col-sm-3 col-xs-9">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      id="ad_times_date_calender_everyone_paid"
                      className="search_adtimes2_popsearch everyone_input"
                      value={this.state.everyone_batch_date}
                      selected={this.state.everyone_batch_date}
                      onChange={this.handleDateBatch}
                    />
                    <a
                      href={null}
                      className="calendar_icon_ad_times cursorPointer payroll_batch everyone_calendar"
                    >
                      <svg
                        onClick={this.open_AdTimes_calender}
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                      >
                        <path
                          id="ic_date_range_24px"
                          d="M9,11H7v2H9Zm4,0H11v2h2Zm4,0H15v2h2Zm2-7H18V2H16V4H8V2H6V4H5A1.991,1.991,0,0,0,3.01,6L3,20a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,19,4Zm0,16H5V9H19Z"
                          transform="translate(-3 -2)"
                          fill="#30679a"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
                <div className="col-sm-12 every_label payroll_batch_incdays">
                  <p>Include days:</p>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <ul className="payroll_batch_mdaysList">
                      <li>
                        <p>Monday:</p>
                        <input
                          id="checkbox_mon"
                          type="checkbox"
                          name="batch_chk_mon"
                          checked={this.state.batch_chk_mon}
                          onChange={this.TerminateHandler}
                        />
                      </li>
                      <li>
                        <p>Tuesday:</p>
                        <input
                          id="checkbox_tue"
                          type="checkbox"
                          name="batch_chk_tue"
                          checked={this.state.batch_chk_tue}
                          onChange={this.TerminateHandler}
                        />
                      </li>
                      <li>
                        <p>Wednesday:</p>
                        <input
                          id="checkbox_wed"
                          type="checkbox"
                          name="batch_chk_wed"
                          checked={this.state.batch_chk_wed}
                          onChange={this.TerminateHandler}
                        />
                      </li>
                      <li>
                        <p>Thursday</p>
                        <input
                          id="checkbox_thu"
                          type="checkbox"
                          name="batch_chk_thu"
                          checked={this.state.batch_chk_thu}
                          onChange={this.TerminateHandler}
                        />
                      </li>
                      <li>
                        <p>Friday:</p>
                        <input
                          id="checkbox_fri"
                          type="checkbox"
                          name="batch_chk_fri"
                          checked={this.state.batch_chk_fri}
                          onChange={this.TerminateHandler}
                        />
                      </li>
                      <li>
                        <p>Saturday:</p>
                        <input
                          id="checkbox_sat"
                          type="checkbox"
                          name="batch_chk_sat"
                          checked={this.state.batch_chk_sat}
                          onChange={this.TerminateHandler}
                        />
                      </li>
                      <li>
                        <p>Sunday:</p>
                        <input
                          id="checkbox_sun"
                          type="checkbox"
                          name="batch_chk_sun"
                          checked={this.state.batch_chk_sun}
                          onChange={this.TerminateHandler}
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-2 col-xs-6">
                        <a
                          href={null}
                          className="btn_save_analytics cursorPointer everyone_reminder payroll_batch_continue"
                        >
                          Continue
                        </a>
                      </div>
                      <div className="col-sm-2 col-xs-6">
                        <div className="analytics_btn_save">
                          <a
                            hreaf={null}
                            // data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#adbatch_search_pop"
                            className="btn_cancel_analytics mrg_left0_addtime cursorPointer everyone_batch batch_cancel"
                          // onClick={this.issue_ScrollHandler}
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
