import React, { Component } from 'react';
import Login_logo from '../../Assets/images/login_logo.svg';
import Login_sm_logo from '../../Assets/images/login_sm_logo.png';
import login_down_arrow from '../../Assets/images/login_down_arrow.svg';
import { Link } from "react-router-dom";
import './Verifaction_Code2.css';
import { toast } from 'react-toastify';
import { Auth } from "aws-amplify";
class Verifaction_Code2 extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false }

  }
  onHref = (e) => {
    e.preventDefault()
  }
  checkCompanyWithUser = async (event) => {

    var userTenant = localStorage.getItem('tenantid');
    if (userTenant.indexOf(event.target.id) > -1) {
      this.setState({ isLoading: true });
      await localStorage.setItem('useableGuid', event.target.id);

      if (this.props.location.state.mustchange === 'Must Change') {
        Auth.signOut();
        this.props.history.push("/forgot");

      } else {
        this.props.history.push("/dashboard");
      }
    } else {
      toast.error("You don't belong with selected company");
    }
  }
  render() {
    return (
      <div className="col-sm-12 p0">
        {this.state.isLoading ? <div className="se-pre-con"></div> : ''}

        <div className="col-sm-6 p0 small_display_none">
          <div className="verify_left_area"></div>

        </div>


        <div className="col-sm-6 p0 verify_right_h">

          <div className="verify_2_right_middle">
            <div className="col-sm-12 verify_bg1_logo2"><Link to="#"><img src={Login_logo} alt="" /></Link></div>
            <div className="col-sm-12 sm_logo_login"><Link to="#"><img src={Login_sm_logo} alt="" /></Link></div>
            <div className="clear20"></div>


            <div className="verif_table_box">

              <table width="99%" className="table zeroMarginBottom">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Production</th>
                    <th align="center" scope="col">Approve</th>
                    <th align="center" scope="col">Pending</th>
                    <th align="center" scope="col">Approved</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.location.state.companies.map((value, key) => {
                    return (<tr key={key} onClick={this.checkCompanyWithUser} className="cursorPointer companyList">
                      <td id={value.TenantGuid}>{value.CompanyName}</td>
                      <td align="center" id={value.TenantGuid}>0</td>
                      <td align="center" id={value.TenantGuid}>0</td>
                      <td align="center" id={value.TenantGuid}>5</td>
                    </tr>);
                  })}


                </tbody>
              </table>
            </div>



            <div className="clear20"></div>
            <div className="col-md-12">
              <Link to="/" className="verify_btn_cancel">Cancel</Link>
            </div>
            <div className="clear20"></div>

            <div className="col-sm-12 text-center full_display_none accept_policy"> I accept the Terms of Use and Privacy Policy</div>
          </div>
          <div className="clearfix"></div>
          <div className="verify_policy_label small_display_none">Term of use. Privacy policy</div>
          <div className="clear20"></div>
          <div className="more_detail">
            <Link to="" onClick={this.onHref}>More Details
                     <div className="clear5"></div>
              <img src={login_down_arrow} alt="" />

            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Verifaction_Code2;