import React, { Component } from "react";
import Header1 from "../Common/Header1/Header1";
import "./Message.css";
import ic_dehaz from "../../Assets/images/ic_dehaze_-19@2x.png";
import loginLogo from "../../Assets/images/login_logo.svg";
import forgotLogoSvg from "../../Assets/images/forgot_logo.svg";
import logoutSvg from "../../Assets/images/logout.svg";
import notifactioSvg from "../../Assets/images/notifactio.svg";
import MagniGlassSvg from "../../Assets/images/magnifying-glass.svg";
import msgPng from "../../Assets/images/msg_open.png";
import sign1 from "../../Assets/images/sign1.jpg";
import msgOpenPng from "../../Assets/images/msg_open.png";
import timecard_2Png from "../../Assets/images/timecard_2.png";
import setting_3Png from "../../Assets/images/setting_3.png";
import pppPng from "../../Assets/images/ppp.png";
import sendMsg from "../../Assets/images/send_message.jpg";
import sendImagPng from "../../Assets/images/send_imggg.png";
import SendImgPng from "../../Assets/images/send_img.png";
import attachUserIcPng from "../../Assets/images/attach_icon_user.png";
import CKEditor from "react-ckeditor-component";
import Amplify, { Storage } from 'aws-amplify';
import Dropzone from 'react-dropzone';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { API, Auth } from "aws-amplify";
import SendIcon from "../../Assets/images/send_icon.png";
import moment from 'moment';
import 'moment-timezone';
import $ from "jquery";
var ip = require('ip');
let userGuId = localStorage.getItem('userGuid');
export default class Message extends Component {
  constructor(props) {
    super(props);
    this.updateContent = this.updateContent.bind(this);
    this.state = {
      messageFrom_filter: [],
      message_user_list: '',
      unreadMsgCount: 0,
      allMessages: [],
      currentTabMessages: [],
      tmp_img: [],
      mobile_img: [],
      isLoading: false,
      selectedMessage: {},
      sendMessage_searchData: [],
      sendMessage_showData: [],
      message_search: "",
      message_searched_data: [],
      isDropdownShow: false,
      sendMessage_SeacrchSidebar: "",

      sendMessageBoxName: "",
      sendMessageBoxNameText: "",
      sendMessageBoxSubject: "",
      sendMessageAttachment: "",
      sendMessageContent: "",
      ToInitials: "",
      FromInitials: "",
      IssendMessageBoxNameValid: false,
      IssendMessageBoxSubjectValid: false,
      IssendMessageAttachmentValid: false,
      IssendMessageContentValid: false,

      sendMessageErrors: {
        sendMessageBoxName: "",
        sendMessageBoxSubject: "",
        sendMessageAttachment: "",
        sendMessageContent: ""
      },
      IsSendMessageValid: false,
      sendMob_sentToOptions: [],
      sendMob_sentToValue: "",
      sendMob_sentTo: false,
      sendMob_Subject: "",
      sendMob_Message: "",
      sendMob_Attachment: "",
      total_Attachment: '',
      sendMobMessageErrors: {
        sendMob_sentTo: "",
        sendMob_Subject: "",
        sendMob_Message: "",

      },

      IssendMob_sentToValid: false,
      IssendMob_SubjectValid: false,
      IssendMob_MessageValid: false,
      IsSendMobMessageValid: false,
      mob_Send_Msg_To: ''
    };
  }
  componentDidMount = async () => {
    await this.props.childProps.isAuthentication();
    if (!this.props.childProps.isAuthenticated) {
      this.props.history.push('/login');
    } else {
      let userGuId = localStorage.getItem('userGuid');
      $("#sendMsgContainerDiv").hide();
      $("#sendMessageHeader").hide();
      $("#sendMobMessageBlock").hide();
      this.setState({
        isLoading: true
      })
      await this.myunreadmsgcount(localStorage.getItem('userGuid'));

      await API.post("getmessage", "/getmessage", {
        body: {
          "Guid": localStorage.getItem('userGuid'),
          "tenant": localStorage.getItem('useableGuid'),
        }
      }).then(data => {
        this.setState({
          allMessages: data,
          currentTabMessages: data
        })
       // toast.success('Messages Loaded Successfully.');
      }).catch(err => {
        toast.error(err.message)
      });

      await this.setState({
        allMessages: this.state.currentTabMessages.filter(function (message) {
          return message.deletedReceiver === 0 && message.Type !== "draft" && message.To === userGuId;
        })
      });
      await this.setState({
        message_searched_data: this.state.allMessages
      })
      let names = [];
      this.state.message_searched_data.map(val => {
        var x = names.filter(vals => { return val.messageFrom === vals })
        if (x.length === 0 && val.messageFrom !== null) {
          names.push(val.messageFrom)
        }

      })
      await this.setState({
        messageFrom_filter: names
      })
      this.setState({
        isLoading: false
      })
      this.get_users();
    }
  }



  componentWillUnmount = async () => {
    let sendMessageContent = this.state.sendMessageContent;
    let sendMessageBoxName = this.state.sendMessageBoxName;
    let sendMessageBoxSubject = this.state.sendMessageBoxSubject;
    if (sendMessageContent.length > 0 || sendMessageBoxName.length > 0 || sendMessageBoxSubject.length > 0) {
      await API.post('dynamomsgcreate', '/dynamomsgcreate', {
        body: {
          "body": (this.state.sendMessageContent !== "" ? this.state.sendMessageContent : "Draft"),
          "from": localStorage.getItem('userGuid'),
          "subject": (this.state.sendMessageBoxSubject !== "" ? this.state.sendMessageBoxSubject : "Draft"),
          "tenant": localStorage.getItem('useableGuid'),
          "to": (this.state.sendMessageBoxName !== "" ? this.state.sendMessageBoxName : "Draft"),
          "messageTo": (this.state.endMessageBoxNameText !== "" ? this.state.endMessageBoxNameText : "Draft"),
          "messageFrom": localStorage.getItem('UserFullName'),
          "type": "draft",
          "attachments": this.state.tmp_img,
          "userid": localStorage.getItem('userGuid'),
        }
      }).then(data => {
        toast.warn('Message Saved As Draft');
      });
    }
  }
  myunreadmsgcount = async (userGuid) => {
    await API.post('myunreadmsgcount', '/myunreadmsgcount', {
      body: {
        "myguid": userGuid
      }
    }).then(data => {
      this.setState({
        unreadMsgCount: data
      });
    }).catch(err => {
      toast.error('Unable To Fetch Unread MessageCounts');
    });
  }
  DownAttachments = (url) => {
    window.open(url);
  }
  get_users = async () => {

    this.setState({
      isLoading: true,
    });
    await API.post('getallusers', '/getallusers').then(data => {
      data.map(value => {
        value.checked = false;
        return null;
      });

      this.setState({ sendMessage_searchData: data, sendMob_sentToOptions: data });
    });
    this.setState({
      isLoading: false,
    });
  }

  deletemessage = async () => {
    const selectedMessage = this.state.selectedMessage;
    const guid = this.state.selectedMessage.Guid;
    let userGuId = localStorage.getItem('userGuid');
    this.setState({
      isLoading: true
    });
    await API.post('deletemessage', '/deletemessage', {
      body: {
        "Guid": guid,
        "deletedSender": (selectedMessage.From === userGuId ? 1 : selectedMessage.deletedSender),
        "deletedReceiver": (selectedMessage.To === userGuId ? 1 : selectedMessage.deletedReceiver)
      }
    }).then(data => {
     // toast.success("Message Status Deleted Successfully.");
    });

    await this.myunreadmsgcount(localStorage.getItem('userGuid'));
    await API.post("getmessage", "/getmessage", {
      body: {
        "Guid": localStorage.getItem('userGuid'),
        "tenant": localStorage.getItem('useableGuid'),
      }
    }).then(data => {
      this.setState({
        allMessages: data,
        currentTabMessages: data
      })
    //  toast.success('Messages Loaded Successfully.');
    }).catch(err => {
      toast.error(err.message)
    });

    this.setState({
      allMessages: this.state.currentTabMessages.filter(function (message) {
        return message.deletedReceiver === 0 && message.Type !== "draft" && message.To === userGuId;
      }),
      selectedMessage: {}


    });
    $('#inbox').addClass('activeBold');
    $('#drafts').removeClass('activeBold');
    $('#sent').removeClass('activeBold');
    $('#delete').removeClass('activeBold');
    $('#archives').removeClass('activeBold');
    this.setState({
      isLoading: false
    })
    this.setState({
      isLoading: false
    })
  }
  draftmessage = async () => {
    const guid = this.state.selectedMessage.Guid;
    this.setState({
      isLoading: true
    });
    await API.post('draftmessage ', '/draftmessage ', { body: { "Guid": guid } }).then(data => {
      //toast.success("Message Status Deleted Successfully.");
    });
    let userGuId = localStorage.getItem('userGuid');
    await this.myunreadmsgcount(localStorage.getItem('userGuid'));
    await API.post("getmessage", "/getmessage", {
      body: {
        "Guid": localStorage.getItem('userGuid'),
        "tenant": localStorage.getItem('useableGuid'),
      }
    }).then(data => {
      this.setState({
        allMessages: data,
        currentTabMessages: data
      })
    //  toast.success('Messages Loaded Successfully.');
    }).catch(err => {
      toast.error(err.message)
    });

    this.setState({
      allMessages: this.state.currentTabMessages.filter(function (message) {
        return message.To === userGuId;
      })
    });

    this.setState({
      isLoading: false
    })
    this.setState({
      isLoading: false
    })
  }

  markRead = async (guid) => {
    this.setState({
      isLoading: true
    });
    await API.post('markmessageread', '/markmessageread', { body: { "Guid": guid } }).then(data => {
      //toast.success("Message Status Updated Successfully.");
    });
    let userGuId = localStorage.getItem('userGuid');
    await this.myunreadmsgcount(localStorage.getItem('userGuid'));
    await API.post("getmessage", "/getmessage", {
      body: {
        "Guid": localStorage.getItem('userGuid'),
        "tenant": localStorage.getItem('useableGuid'),
      }
    }).then(data => {
      this.setState({
        allMessages: data,
        currentTabMessages: data
      })
     // toast.success('Messages Loaded Successfully.');
    }).catch(err => {
      toast.error(err.message)
    });

    this.setState({
      allMessages: this.state.currentTabMessages.filter(function (message) {
        return message.deletedReceiver === 0 && message.Type !== "draft" && message.To === userGuId;
      })
    });



    this.setState({
      isLoading: false
    })
    this.setState({
      isLoading: false
    })
  }
  handleMessageContent = async (id) => {
    $('#sendMsgContainerDiv').css({ "display": "none" });
    $('#messageContainerDiv').css({ "display": "block" });
    $('.grid_layout_content').removeClass('active');

    $('#' + id).addClass('active');

    const SelectedMessage = this.state.allMessages.filter(item => item.Guid === id);
    let FromInitials = SelectedMessage[0].messageFrom;
    let ToInitials = '';
    FromInitials = FromInitials.match(/\b\w/g) || [];
    FromInitials = ((FromInitials.shift() || '') + (FromInitials.pop() || '')).toUpperCase();
    if (SelectedMessage[0].messageTo && SelectedMessage[0].messageTo !== "" && SelectedMessage[0].messageTo !== "Draft") {
      ToInitials = SelectedMessage[0].messageTo;
      ToInitials = ToInitials.match(/\b\w/g) || [];
      ToInitials = ((ToInitials.shift() || '') + (ToInitials.pop() || '')).toUpperCase();
    }
    this.setState({
      ToInitials: ToInitials,
      FromInitials: FromInitials
    });
    let sendMessageContent = this.state.sendMessageContent;
    let sendMessageBoxName = this.state.sendMessageBoxName;
    let sendMessageBoxSubject = this.state.sendMessageBoxSubject;
    if (sendMessageContent.length > 0 || sendMessageBoxName.length > 0 || sendMessageBoxSubject.length > 0) {
      await this.setState({
        isLoading: true
      });
      await API.post('dynamomsgcreate', '/dynamomsgcreate', {
        body: {
          "body": (this.state.sendMessageContent !== "" ? this.state.sendMessageContent : "Draft"),
          "from": localStorage.getItem('userGuid'),
          "subject": (this.state.sendMessageBoxSubject !== "" ? this.state.sendMessageBoxSubject : "Draft"),
          "tenant": localStorage.getItem('useableGuid'),
          "to": (this.state.sendMessageBoxName !== "" ? this.state.sendMessageBoxName : "Draft"),
          "messageTo": (this.state.endMessageBoxNameText !== "" ? this.state.endMessageBoxNameText : "Draft"),
          "messageFrom": localStorage.getItem('UserFullName'),
          "type": "draft",
          "attachments": this.state.tmp_img,
          "userid": localStorage.getItem('userGuid'),
        }
      }).then(data => {
        toast.warn('Message Saved As Draft');
      });
      await this.setState({
        isLoading: false
      });
    }
    if (SelectedMessage[0].To === localStorage.getItem('userGuid')) {
      if (SelectedMessage[0].readstatus === 0) {
        await this.markRead(SelectedMessage[0].Guid);
      }
    }



    await this.setState({
      selectedMessage: SelectedMessage[0]
    });

  }
  AutoChangeHandlers = (event) => {
    var text = event.target.value;
    this.setState({
      sendMessageBoxNameText: event.target.value,
      isDropdownShow: true,
    });
    this.setState({
      sendMessage_showData: this.state.sendMessage_searchData.filter(function (item) {
        return item.FirstName.toUpperCase().includes(text.toUpperCase());
      })
    },
      this.validatFields('sendMessage_showData', text)
    );
  }

  messageSearchAllHandler = (e) => {
    var text = e.target.value;
    this.setState({
      message_search: text
    })

    let message_searched_data = [];
    if (!text) {
      message_searched_data = this.state.allMessages;
    } else {
      message_searched_data = this.state.allMessages.filter(function (setup) {
        return (
          setup.Body.toUpperCase().includes(
            text.toUpperCase()
          )
        );
      });
    }

    this.setState({ message_searched_data });
  }

  get_messageUserlist = (e) => {


    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: e.target.value
    });

    if (this.state.allMessages.length > 0) {
      if (value == "all") {
        this.setState({ message_searched_data: this.state.allMessages });
      } else {
        let userData = this.state.allMessages.filter(e => e.messageFrom == value);
        this.setState({ message_searched_data: userData });
      }
    }
  };


  replyMessage = async () => {
    const seletctedMsg = this.state.selectedMessage;

    await this.setState({
      sendMessageBoxNameText: seletctedMsg.messageFrom,
      sendMessageBoxName: seletctedMsg.From,
    }, this.validatFields('sendMessage_showData', seletctedMsg.messageFrom))
    await this.setState({

      sendMessageBoxSubject: seletctedMsg.Subject
    }, this.validatFields('sendMessageBoxSubject', seletctedMsg.Subject))
    this.sendMessageDiv();

  }


  AutoAddValue = (event, id) => {
    let text = $(event.target).text();
    let value = id
    console.log(">>>>>>DFDFd", text)
    this.setState({
      sendMessageBoxNameText: text,
      sendMessageBoxName: value,
      isDropdownShow: false,
    },
      this.validatFields('sendMessage_showData', value))
  }


  message_inputHandler = e => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({
      [name]: value
    });
  };
  toggle = async (event) => {

    let userGuid = localStorage.getItem('userGuid');
    if (event.target.id === "inbox") {
      $('#inbox').addClass('activeBold');
      $('#drafts').removeClass('activeBold');
      $('#sent').removeClass('activeBold');
      $('#delete').removeClass('activeBold');
      $('#archives').removeClass('activeBold');
      await this.setState({
        message_user_list: ''
      })
      await this.setState({
        allMessages: this.state.currentTabMessages.filter(function (message) {
          return message.deletedReceiver === 0 && message.To === userGuid && message.Type !== "draft";
        })
      });
      await this.setState({
        message_searched_data: this.state.allMessages
      })
      let names = [];
      this.state.message_searched_data.map(val => {
        var x = names.filter(vals => { return val.messageFrom === vals })
        if (x.length === 0 && val.messageFrom !== null) {
          names.push(val.messageFrom)
        }

      })
      await this.setState({
        messageFrom_filter: names
      })
    }
    else if (event.target.id === "drafts") {
      $('#inbox').removeClass('activeBold');
      $('#drafts').addClass('activeBold');
      $('#sent').removeClass('activeBold');
      $('#delete').removeClass('activeBold');
      $('#archives').removeClass('activeBold');
      await this.setState({
        message_user_list: ''
      })
      await this.setState({
        allMessages: this.state.currentTabMessages.filter(function (message) {
          return message.Type === "draft" && message.From === userGuid;
        })
      });
      await this.setState({
        message_searched_data: this.state.allMessages
      })
      let names = [];
      this.state.message_searched_data.map(val => {
        var x = names.filter(vals => { return val.messageFrom === vals })
        if (x.length === 0 && val.messageFrom !== null) {
          names.push(val.messageFrom)
        }

      })
      await this.setState({
        messageFrom_filter: names
      })
    }
    else if (event.target.id === "sent") {
      $('#inbox').removeClass('activeBold');
      $('#drafts').removeClass('activeBold');
      $('#sent').addClass('activeBold');
      $('#delete').removeClass('activeBold');
      $('#archives').removeClass('activeBold');
      await this.setState({
        message_user_list: ''
      })
      await this.setState({
        allMessages: this.state.currentTabMessages.filter(function (message) {
          return message.deletedSender === 0 && message.Type !== "draft" && message.From === userGuid && message.Type !== "draft";
        })
      });
      await this.setState({
        message_searched_data: this.state.allMessages
      })

      let names = [];
      this.state.message_searched_data.map(val => {
        var x = names.filter(vals => { return val.messageFrom === vals })
        if (x.length === 0 && val.messageFrom !== null) {
          names.push(val.messageFrom)
        }

      })
      await this.setState({
        messageFrom_filter: names
      })
    }
    else if (event.target.id === "delete") {
      $('#inbox').removeClass('activeBold');
      $('#drafts').removeClass('activeBold');
      $('#sent').removeClass('activeBold');
      $('#delete').addClass('activeBold');
      $('#archives').removeClass('activeBold');
      await this.setState({
        message_user_list: ''
      })
      await this.setState({
        allMessages: this.state.currentTabMessages.filter(function (message) {
          return ((message.deletedSender === 1 && message.From === userGuid) || (message.deletedReceiver === 1 && message.To === userGuid))
        })
      });
      await this.setState({
        message_searched_data: this.state.allMessages
      })
      let names = [];
      this.state.message_searched_data.map(val => {
        var x = names.filter(vals => { return val.messageFrom === vals })
        if (x.length === 0 && val.messageFrom !== null) {
          names.push(val.messageFrom)
        }

      })
      await this.setState({
        messageFrom_filter: names
      })
    }
    else if (event.target.id === "archives") {
      $('#inbox').removeClass('activeBold');
      $('#drafts').removeClass('activeBold');
      $('#sent').removeClass('activeBold');
      $('#delete').removeClass('activeBold');
      $('#archives').addClass('activeBold');
      await this.setState({
        message_user_list: ''
      })
      await this.setState({
        allMessages: this.state.currentTabMessages.filter(function (message) {
          return message.Type.toUpperCase().includes('Archives'.toUpperCase());
        })
      });
      await this.setState({
        message_searched_data: this.state.allMessages
      })
      let names = [];
      this.state.message_searched_data.map(val => {
        var x = names.filter(vals => { return val.messageFrom === vals })
        if (x.length === 0 && val.messageFrom !== null) {
          names.push(val.messageFrom)
        }

      })
      await this.setState({
        messageFrom_filter: names
      })
    }

  }
  sendMessageDiv = () => {
    // $("#MessageHeader").css("display", "none");
    $("#messageContainerDiv").css("display", "none");
    $("#sendMessageHeader").css("display", "none");
    $("#sendMsgContainerDiv").css("display", "block");
    $("#sendMobMessageBlock").css("display", "none");
    $(".grid_layout_content").removeClass("active");
  };
  showMobSendMessageDiv = () => {
    $("#sendMobMessageBlock").css("display", "block");
    $("#messageBlock").css("display", "none");
  };
  backFromMobSendMessage = () => {
    $("#sendMobMessageBlock").css("display", "none");
    $("#messageBlock").css("display", "block");
  };

  SenMessageInputChangeHandlers = e => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState(
      {
        [name]: value
      },
      this.validatFields(name, value)
    );
  };

  validatFields = (fieldName, value) => {
    let sendMessageErrors = this.state.sendMessageErrors;
    let IssendMessageBoxNameValid = this.state.IssendMessageBoxNameValid;
    let IssendMessageBoxSubjectValid = this.state.IssendMessageBoxSubjectValid;
    let IssendMessageContentValid = this.state.IssendMessageContentValid;
    switch (fieldName) {
      case "sendMessage_showData":
        IssendMessageBoxNameValid = value.length > 0;
        sendMessageErrors.sendMessageBoxName = IssendMessageBoxNameValid
          ? ""
          : "This field is required";
        break;
      case "sendMessageBoxSubject":
        IssendMessageBoxSubjectValid = value.length > 0;
        sendMessageErrors.sendMessageBoxSubject = IssendMessageBoxSubjectValid
          ? ""
          : "This field is required";
        break;
      case "sendMessageContent":
        IssendMessageContentValid = value.length > 0;
        sendMessageErrors.sendMessageContent = IssendMessageContentValid
          ? ""
          : "This field is required";
        break;
      default:
        break;
    }

    this.setState({
      sendMessageErrors: sendMessageErrors,
      IssendMessageBoxNameValid: IssendMessageBoxNameValid,
      IssendMessageBoxSubjectValid: IssendMessageBoxSubjectValid,
      IssendMessageContentValid: IssendMessageContentValid
    });
  };

  updateContent(newContent) {
    this.setState({
      sendMessageContent: newContent
    });
  }

  onChange = evt => {
    var newContent = evt.editor.getData();
    this.setState({
      sendMessageContent: newContent
    });
  };

  onBlur(evt) {
  }
  afterPaste(evt) {
  }
  onSendMessageSendHandler = async () => {
    let sendMessageErrors = this.state.sendMessageErrors;
    let IssendMessageBoxNameValid = this.state.IssendMessageBoxNameValid;
    let IssendMessageBoxSubjectValid = this.state.IssendMessageBoxSubjectValid;
    let IssendMessageContentValid = this.state.IssendMessageContentValid;

    if (!IssendMessageBoxNameValid) {
      sendMessageErrors.sendMessageBoxName = "This field is required";
    }
    if (!IssendMessageBoxSubjectValid) {
      sendMessageErrors.sendMessageBoxSubject = "This field is required";
    }
    if (!IssendMessageContentValid) {
      sendMessageErrors.sendMessageContent = "This field is required";
    }
    this.setState({
      sendMessageErrors: sendMessageErrors
    });
    if (this.state.sendMessageErrors) {
      await this.setState({
        isLoading: true
      })
      const {
        sendMessageBoxName,
        sendMessageBoxSubject,
        tmp_img,
        sendMessageContent, sendMessageBoxNameText
      } = this.state;


      await API.post('dynamomsgcreate', '/dynamomsgcreate', {
        body: {
          "body": sendMessageContent,
          "from": localStorage.getItem('userGuid'),
          "subject": sendMessageBoxSubject,
          "tenant": localStorage.getItem('useableGuid'),
          "to": sendMessageBoxName,
          "messageTo": sendMessageBoxNameText,
          "messageFrom": localStorage.getItem('UserFullName'),

          "type": "inbox",
          "attachments": tmp_img,
          "userid": localStorage.getItem('userGuid'),
        }
      }).then(async data => {
        //toast.success("Message Sent Successfully");
        $('#sendMsgContainerDiv').css('display', 'none');
        $('#messageContainerDiv').css('display', 'block');

        let userGuId = localStorage.getItem('userGuid');
        await this.myunreadmsgcount(localStorage.getItem('userGuid'));
        await API.post("getmessage", "/getmessage", {
          body: {
            "Guid": localStorage.getItem('userGuid'),
            "tenant": localStorage.getItem('useableGuid'),
          }
        }).then(data => {
          this.setState({
            allMessages: data,
            currentTabMessages: data
          })
         // toast.success('Messages Loaded Successfully.');
        }).catch(err => {
          toast.error(err.message)
        });

        this.setState({
          allMessages: this.state.currentTabMessages.filter(function (message) {
            return message.deletedReceiver === 0 && message.Type !== "draft" && message.To === userGuId;
          }),
          selectedMessage: {},
          sendMessageBoxName: '',
          sendMessageBoxSubject: '',
          tmp_img: [],
          sendMessageContent: '',
          sendMessageBoxNameText: ''
        });
        $('#inbox').addClass('activeBold');
        $('#drafts').removeClass('activeBold');
        $('#sent').removeClass('activeBold');
        $('#delete').removeClass('activeBold');
        $('#archives').removeClass('activeBold');
        this.setState({
          isLoading: false
        })





      }).catch(err => { toast.error(err.message) });

      await this.setState({
        isLoading: false
      })
    }
  };
  sendMobHandler = async (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let usr = this.state.sendMob_sentToOptions.find((u) => u.Guid == value)

    if(usr && usr.FirstName && usr.UserType){
      let name = usr.FirstName +' ' +  '('+usr.UserType + ')';
        this.setState({ mob_Send_Msg_To:  name})
    }
    await this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        this.sendMob_validateField(name, value);
      }
    );


  }
  sendMob_validateField = (fieldName, value) => {
    let sendMobMessageErrors = this.state.sendMobMessageErrors;
    let IsSendMobMessageValid = this.state.IsSendMobMessageValid;
    let IssendMob_sentToValid = this.state.IssendMob_sentToValid;
    let IssendMob_SubjectValid = this.state.IssendMob_SubjectValid;
    let IssendMob_MessageValid = this.state.IssendMob_MessageValid;

    switch (fieldName) {
      case "sendMob_sentTo":
        IssendMob_sentToValid = this.state.sendMob_sentTo !== '';

        sendMobMessageErrors.sendMob_sentTo = IssendMob_sentToValid ? "" : "Please Select Option";
        break;
      case 'sendMob_Subject':
        IssendMob_SubjectValid = value.length > 0;
        sendMobMessageErrors.sendMob_Subject = IssendMob_SubjectValid ? '' : "This field is required";
        break;
      case 'sendMob_Message':
        IssendMob_MessageValid = value.length > 0;
        sendMobMessageErrors.sendMob_Message = IssendMob_MessageValid ? '' : "This field is required";
        break;

      default:
        break;
    }

    this.setState(
      {
        sendMobMessageErrors: sendMobMessageErrors,
        IsSendMobMessageValid: IsSendMobMessageValid,
        IssendMob_sentToValid: IssendMob_sentToValid,
        IssendMob_MessageValid: IssendMob_MessageValid,
        IssendMob_SubjectValid: IssendMob_SubjectValid,
      },
      this.sendMob_validateForm
    );
  };
  sendMob_validateForm = () => {
    this.setState({
      IsSendMobMessageValid: this.state.IssendMob_sentToValid && this.state.IssendMob_SubjectValid && this.state.IssendMob_MessageValid
    });
  }
  sendMobMessageSave = async () => {
    let sendMobMessageErrors = this.state.sendMobMessageErrors;
    let IssendMob_sentToValid = this.state.IssendMob_sentToValid;
    let IssendMob_SubjectValid = this.state.IssendMob_SubjectValid;
    let IssendMob_MessageValid = this.state.IssendMob_MessageValid;

    if (!IssendMob_sentToValid) {
      sendMobMessageErrors.sendMob_sentTo = "This field is required";
    }
    if (!IssendMob_SubjectValid) {
      sendMobMessageErrors.sendMob_Subject = "This field is required";
    }
    if (!IssendMob_MessageValid) {
      sendMobMessageErrors.sendMob_Message = "This field is required";
    }
    this.setState({
      sendMobMessageErrors: sendMobMessageErrors
    });
    if (this.state.IsSendMobMessageValid) {
      await this.setState({
        isLoading: true,
      });

      const { sendMob_sentTo, sendMob_Subject, sendMob_Message, mobile_img, sendMessageBoxNameText } = this.state;
      
      await API.post('dynamomsgcreate', '/dynamomsgcreate', {
        body: {
          "body": sendMob_Message,
          "from": localStorage.getItem('userGuid'),
          "subject": sendMob_Subject,
          "attachments": mobile_img,
          "tenant": localStorage.getItem('useableGuid'),
          "to": sendMob_sentTo,
          "messageFrom": localStorage.getItem('UserFullName'),
          "messageTo": this.state.mob_Send_Msg_To,
          "type": "inbox",
          "userid": localStorage.getItem('userGuid'),
        }
      }).then(async data => {
       // toast.success("Message Sent Successfully");
        $("#sendMobMessageBlock").css("display", "none");
         $("#messageBlock").css("display", "block");
        await this.setState({
          sendMob_Subject: '',
          sendMob_Message:''
        })
      }).catch(err => { console.log(err) });
      await this.setState({
        isLoading: false,
      });
    }
    this.props.history.push('/messages');
  }

  mobileScreenShowMessage = (message) => {
    localStorage.setItem('message', JSON.stringify(message))
  }

  SendMessageFileUploadHandler = async files => {

    var test = [];
    await this.setState({
      sendMessageAttachment: files,
      isLoading: true
    });
    await Promise.all(files.map(async file => {
      var tmp_name_dynamo = new Date().getTime() + '-' + file.name;
      var file_name = 'Message-Attachments/' + tmp_name_dynamo;
      await Storage.put(file_name, file)
        .then(result => {
          var uploadedObj = {
            "ContentType": "UTF-8",
            "DocDesc": "Message document",
            "DoumentType": file.type,
            "filename": tmp_name_dynamo,
            "Timestamp": new Date().getTime(),
            "url": 'https://'+process.env.REACT_APP_S3_BUCKET+'.s3-'+process.env.REACT_APP_S3_REGION+'.amazonaws.com/public/' + result.key
          }
          test.push(uploadedObj);
        }).catch(err => console.log(err));

    }));
    this.setState({
      tmp_img: test,
      isLoading: false
    });
  };
  MobSendMessageFileUploadHandler = async files => {
    var num_of_images = files.length;
    this.setState({
      total_Attachment: num_of_images
    })


    await this.setState({
      sendMob_Attachment: files,
      isLoading: true
    });
    var temp_array = [];
    await Promise.all(files.map(async file => {
      var tmp_name_dynamo = new Date().getTime() + '-' + file.name;
      var file_name = 'Message-Attachments/' + tmp_name_dynamo;
      await Storage.put(file_name, file)
        .then(result => {
          var uploadedObj = {
            "ContentType": "UTF-8",
            "DocDesc": "Message document",
            "DoumentType": file.type,
            "filename": tmp_name_dynamo,
            "Timestamp": new Date().getTime(),
            "url": 'https://'+process.env.REACT_APP_S3_BUCKET+'.s3-'+process.env.REACT_APP_S3_REGION+'.amazonaws.com/public/' + result.key
          }
          temp_array.push(uploadedObj);
        }).catch(err => console.log(err));

    }));
    await this.setState({
      isLoading: false,
      mobile_img: temp_array,

    })
  }

  removeAttachment = async (file) => {
    await this.setState({
      isLoading: true
    });
    let attach = this.state.tmp_img;
    await Storage.remove('Message-Attachments/' + file)
      .then(result => {
       // toast.success('deleted successfully')
        attach = attach.filter(function (obj) {
          return obj.filename !== file;
        });

      })
      .catch(err => { toast.error('error in deleting') });
    await this.setState({
      tmp_img: attach
    })
    await this.setState({
      isLoading: false
    });
  }

  logout = async () => {
    this.setState({ isLoading: true });
    if(localStorage.getItem('userGuid')!==undefined&&localStorage.getItem('userGuid')!==""&&localStorage.getItem('EmailAddress')&&localStorage.getItem('EmailAddress')!==undefined&&localStorage.getItem('EmailAddress')!==""){
    await API.post("recordlastlogin", "/recordlastlogin", {
      body:
      {
        "Guid": localStorage.getItem('userGuid')
      }
    }).then(data => {
     // toast.success('Last Logout Recorded Successfully.');
    }
    ).catch(err => { toast.error(err.message); });
    await API.post("recordactivity", "/recordactivity", {
      body:
      {
        "Description": "logout",
        "UserName": localStorage.getItem('UserLogin'),
        "IPAddress": ip.address(),
        "TenantGuid": "UYGVUYGBIUYGHBIUYHGB",
        "Type": "Logout",
        "UserGuid": localStorage.getItem('userGuid')
      }
    }).then(data => { 
     // toast.success('Logout Activity Recorded Successfully.');
     }).catch(err => { toast.error(err.message); });

    await API.post("updatelastlogout", "/updatelastlogout", {
      body:
      {
        "Guid": localStorage.getItem('userGuid')
      }
    }).then(data => {
     // toast.success('User last logout updated successfully.');
    }
    ).catch(err => { toast.error(err.message); });

    await API.post("updatelastlogoutforuser", "/updatelastlogoutforuser", {
      body:
      {
        "userEmail": localStorage.getItem('EmailAddress'),
        "LastLogout": Date.now()
      }
    }).then(data => {
     // toast.success('last logout updated successfully for user.');
    }
    ).catch(err => { toast.error(err.message); });
  }
    localStorage.removeItem('EmailAddress');
    localStorage.removeItem('userGuid');
    localStorage.removeItem('UserLogin');
    localStorage.removeItem('UserFullName');
    await Auth.signOut();
    this.props.history.push('/login');
  }

  render() {
    const that = this;
    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    return (
      <div>
        {this.state.isLoading ?
          <div className="se-pre-con"></div>
          : ''}
        <div id="messageBlock">
          <div id="MessageHeader">
            <Header1
              pageHeader="Message"
              childProps={this.props.childProps}

            />
          </div>
          <div id="sendMessageHeader">
            <Header1
              pageHeader="Send Message"
              childProps={this.props.childProps}

            />
          </div>

          <div className="clearfix" />

          <div className="col-sm-12 msg_head_top p0 msg_display_no_mobile form_arow_down_show">
            <div className="msg_inbox_badge">
              <div className="col-sm-12 p0">
                <a
                  href={null}
                  onClick={this.toggle}
                  id="inbox"
                  className="activeBold cursorPointer"
                >
                  Inbox <span></span>
                  {this.state.unreadMsgCount > 0 ? <span className="badge">{this.state.unreadMsgCount}</span> : ''}
                </a>
              </div>
              <div className="clear10"> </div>
              <div className="col-sm-12 p0">
                <a href={null} className="cursorPointer" onClick={this.toggle} id="drafts">
                  Drafts{" "}
                </a>
              </div>
              <div className="clear10"> </div>
              <div className="col-sm-12 p0">
                <a href={null} onClick={this.toggle} className="cursorPointer" id="sent">
                  Sent items{" "}
                </a>
              </div>
              <div className="clear10"> </div>
              <div className="col-sm-12 p0">
                <a href={null} onClick={this.toggle} className="cursorPointer" id="delete">
                  Deleted Items{" "}
                </a>
              </div>
              <div className="clear10"> </div>
              <div className="col-sm-12 p0">
                <a href={null} onClick={this.toggle} className="cursorPointer" id="archives">
                  Archives{" "}
                </a>
              </div>
            </div>
            <div className="col-sm-4  p0 msg_boxx_scroll msg_boxx_width">
              <div id="message_box">
                <div className="grid_layout_message">
                  <div className="grid_layout_inner">
                    <select
                      name="message_user_list"
                      value={this.state.message_user_list}
                      onChange={this.get_messageUserlist}
                      className="form_detail_select grid_layout_select_ie "
                    >
                      <option value="all">Users</option>

                      {this.state.messageFrom_filter.map((item, key) => (
                        <option key={key} value={item}>{item}</option>
                      ))}
                    </select>
                  </div>
                  <div className="grid_layout_inner grid_layout_search_ie">
                    <form className="form searchInput_form">
                      <div className="form-group">
                        <button
                          type="button"
                          className="search_button"
                          value=""
                        >
                          <img
                            alt="message pic"
                            src={MagniGlassSvg}
                            width="20"
                            height="20"
                          />
                        </button>
                        <input
                          id="message_search"
                          name="message_search"
                          value={this.state.message_search}
                          onChange={this.messageSearchAllHandler}
                          type="text"
                          className="form-control search_input"
                          placeholder="What are you looking for"
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="clearfix" />

                {(this.state.message_searched_data ? this.state.message_searched_data.map(item => {

                  return <div id={item.Guid} key={item.Guid} onClick={() => this.handleMessageContent(item.Guid)} className="grid_layout_content">
                    <div className="grid_content">
                      <div className=" msg_box_1">
                        {
                          item.From === localStorage.getItem('userGuid') ? <img src={SendIcon} /> : item.readstatus === 0 ? <div><span class="unread">*</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
                              <path id="ic_mail_outline_24px" d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,14H4V8l8,5,8-5Zm-8-7L4,6H20Z" transform="translate(-2 -4)" fill="#7b7b7b" />
                            </svg></div>
                            : <img src={msgPng} />


                        }

                        <label> &nbsp; {item.From === localStorage.getItem('userGuid') ? item.messageTo : item.messageFrom} </label>
                      </div>
                      {renderHTML(item.Subject)}

                    </div>
                    <div className="grid_content">
                      <h3> &nbsp; &nbsp; &nbsp;</h3>
                      <p>{moment.tz(moment(item.Timestamp), "Australia/Canberra").format('DD-MM-YYYY HH:mm:ss')}</p>
                      <p> &nbsp; &nbsp; &nbsp;</p>

                    </div>
                    <div className="clearfix"></div>
                  </div>
                }) : 'no data to show')}
              </div>
            </div>

            <div id="messageContainerDiv">
              <div className="col-sm-6 p0 msg_box_width_6">
                <div className="msg_box_right_section msg_right_reply_pd_left">

                  {(Object.keys(this.state.selectedMessage).length !== 0 && (this.state.selectedMessage.deletedReceiver === 1 || this.state.selectedMessage.deletedSender === 1)) ?
                    <div className=" msg_right_reply cursorPointer">
                      <a href={null} onClick={this.sendMessageDiv}>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="12"
                          viewBox="0 0 15 15"
                        >
                          <path
                            id="ic_add_24px"
                            d="M20,13.571H13.571V20H11.429V13.571H5V11.429h6.429V5h2.143v6.429H20Z"
                            transform="translate(-5 -5)"
                          />
                        </svg>{" "}
                        New{" "}
                      </a>
                    </div>
                    :
                    (Object.keys(this.state.selectedMessage).length === 0 && this.state.selectedMessage.constructor === Object) ?

                      <div className=" msg_right_reply cursorPointer">
                        <a href={null} onClick={this.sendMessageDiv}>
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="12"
                            viewBox="0 0 15 15"
                          >
                            <path
                              id="ic_add_24px"
                              d="M20,13.571H13.571V20H11.429V13.571H5V11.429h6.429V5h2.143v6.429H20Z"
                              transform="translate(-5 -5)"
                            />
                          </svg>{" "}
                          New{" "}
                        </a>
                      </div> :
                      <React.Fragment>
                        <div className=" msg_right_reply cursorPointer">
                          <a href={null} onClick={this.replyMessage}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20.646"
                              height="9"
                              viewBox="0 0 20.646 9"
                            >
                              <path
                                id="ic_redo_24px"
                                d="M5.173,10.6a10.612,10.612,0,0,1,17.014,4.62L19.8,16A8.078,8.078,0,0,0,6.969,12.38L10.622,16H1.54V7Z"
                                transform="translate(-1.54 -7)"
                              />
                            </svg>{" "}
                            Reply{" "}
                          </a>
                        </div>
                        <div className=" msg_right_reply cursorPointer">
                          <a href={null} onClick={this.sendMessageDiv}>
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="12"
                              viewBox="0 0 15 15"
                            >
                              <path
                                id="ic_add_24px"
                                d="M20,13.571H13.571V20H11.429V13.571H5V11.429h6.429V5h2.143v6.429H20Z"
                                transform="translate(-5 -5)"
                              />
                            </svg>{" "}
                            New{" "}
                          </a>
                        </div>
                        <div className=" msg_right_reply cursorPointer">
                          <a href={null}
                            onClick={this.deletemessage}
                          >

                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10.111"
                              height="13"
                              viewBox="0 0 10.111 13"
                            >
                              <path
                                id="ic_delete_24px"
                                d="M5.722,14.556A1.449,1.449,0,0,0,7.167,16h5.778a1.449,1.449,0,0,0,1.444-1.444V5.889H5.722ZM15.111,3.722H12.583L11.861,3H8.25l-.722.722H5V5.167H15.111Z"
                                transform="translate(-5 -3)"
                              />
                            </svg>
                            &nbsp;Delete{" "}
                          </a>
                        </div>
                      </React.Fragment>
                  }

                  <div className="clearfix"> </div>
                </div>
              </div>

              <div className="col-sm-6">
                {(Object.keys(this.state.selectedMessage).length === 0 && this.state.selectedMessage.constructor === Object) ? 'No Message Selected' :
                  <div className="msg_box_attached_document">
                    <div className="clear10"> </div>
                    <div className="col-sm-12 p0">
                      <p></p>
                    </div>
                    <div className="col-sm-12 p0">
                      <div className="col-sm-2 msg_box_pp p0">
                        <p data-letters={(this.state.selectedMessage.From === userGuId ? this.state.FromInitials : (this.state.selectedMessage.To === userGuId ? this.state.FromInitials : ''))}></p>
                      </div>
                      <div className="col-sm-10 msg_box_pattric_heading p0 msg_car_insurance msgWidthDyn">
                        <h5>{this.state.selectedMessage.messageFrom} </h5>
                        <p> {moment.tz(moment(this.state.selectedMessage.Timestamp), "Australia/Canberra").format('ddd MMM DD YYYY  HH:mm:ss')}
                        </p>
                        <p>  {localStorage.getItem('UserLogin')}</p>
                        <div className="clearfix"> </div>
                        {this.state.selectedMessage.Attachments.map(item => {
                          return <button key={item.filename} className="btn btn-default" onClick={() => this.DownAttachments(item.url)} >
                            {item.filename} </button>
                        })}

                        <div className="clear30"> </div>
                        <span> Hi {localStorage.getItem('UserFullName')}</span>
                        <div className="clear50"> </div>
                        <span> {renderHTML(this.state.selectedMessage.Body)}</span>

                        <div className="clear50"> </div>
                      </div>

                    </div>
                    <div className="col-sm-10 col-sm-offset-2 msg_car_insurance">

                    </div>
                  </div>
                }
              </div>
            </div>
            <div id="sendMsgContainerDiv">
              <div className="col-sm-6 p0 msg_box_width_6">
                <div className="msg_box_right_section msg_right_reply_pd_left">
                  <div className=" msg_right_reply msg_send_className msg_send_img">
                    <a
                      href={null}
                      className="cursorPointer"
                      onClick={this.onSendMessageSendHandler}
                    >
                      <img alt="send_msg" src={sendImagPng} />
                      &nbsp;Send{" "}
                    </a>
                  </div>

                  <div className=" msg_right_reply ">

                  </div>
                  <div className="clearfix"> </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="msg_box_attached_document">
                  <div className="clear30"> </div>

                  <div className="form-group form_message_send">
                    <label htmlFor="email">Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="sendMessageBoxName"
                      name="sendMessageBoxName"
                      value={this.state.sendMessageBoxNameText}
                      onChange={this.AutoChangeHandlers}
                    />

                    {this.state.isDropdownShow ?

                      <ul className="suggestions">
                        {this.state.sendMessage_showData.length > 0 ? this.state.sendMessage_showData.map((suggestion, index) => {
                          if (localStorage.getItem('userGuid') !== suggestion.Guid && suggestion.TenantGuid.indexOf(localStorage.getItem('useableGuid')) > -1) {
                            return (
                              <li
                                value={suggestion.Guid}
                                key={suggestion.Guid}
                                onClick={(event) => this.AutoAddValue(event, suggestion.Guid)}
                              >
                                {suggestion.FirstName}{suggestion.LastName}  {'(' + suggestion.UserType + ')'}
                              </li>
                            );
                          }
                        }) : 'No Record Found'}
                      </ul> : null
                    }
                    <p
                      className={
                        "error validation" +
                        (this.state.sendMessageErrors.sendMessageBoxName
                          ? ""
                          : "displayNone")
                      }
                    >
                      {this.state.sendMessageErrors.sendMessageBoxName}
                    </p>
                  </div>

                  <div className="form-group form_message_send">
                    <label htmlFor="email">Subject:</label>
                    <input
                      type="text"
                      name="sendMessageBoxSubject"
                      className="form-control"
                      id="sendMessageBoxSubject"
                      value={this.state.sendMessageBoxSubject}
                      onChange={this.SenMessageInputChangeHandlers}
                    />
                    <p
                      className={
                        "error validation" +
                        (this.state.sendMessageErrors.sendMessageBoxSubject
                          ? ""
                          : "displayNone")
                      }
                    >
                      {this.state.sendMessageErrors.sendMessageBoxSubject}
                    </p>
                  </div>

                  <div className="form-group form_message_send">
                    <div className="col-sm-12 p0">
                      <label htmlFor="email">Attachment:</label>
                      {
                        this.state.tmp_img.map(value => {
                          return (<button className="btn btn-default">
                            <svg onClick={() => that.removeAttachment(value.filename)} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                              <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7b7b7b" />
                            </svg> <span data-toggle="modal" data-target="#send_message_popus">{value.filename}</span> </button>)
                        })
                      }

                      <div
                        className="form_upload_message">
                        <Dropzone onDrop={this.SendMessageFileUploadHandler.bind(this)}>
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />

                              <img
                                alt="send_msg"
                                src={attachUserIcPng}
                                width="23"
                                height="43"
                              />
                              <p>Drag and drop files.</p>
                            </div>
                          )}
                        </Dropzone>

                      </div>{" "}
                    </div>
                  </div>
                  <div className="clear10" />
                  <div className="form-group form_message_send">
                    <label htmlFor="email">Message:</label>
                    <CKEditor
                      activeClass="p10"
                      content={this.state.sendMessageContent}
                      events={{
                        blur: this.onBlur,
                        afterPaste: this.afterPaste,
                        change: this.onChange
                      }}
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="mobMessageContainer">
            <div id="mobile_msg_screeen">
              {(this.state.allMessages.map(item => {
                return <Link key={item.Guid} onClick={this.mobileScreenShowMessage.bind(this, item)} to="/attach_document"><div className="col-sm-12 p0 msg_boxx_width msg_mobile_brder_btm">
                  <div className="col-xs-10 col-xs-offset-2 msg_mobile_text">
                    <h5>{item.From} </h5>

                  </div>
                  <div className="col-xs-12 msg_mobile_text">
                    <div className="col-xs-2 p0 msg_close_svg">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
                        <path id="ic_mail_outline_24px" d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,14H4V8l8,5,8-5Zm-8-7L4,6H20Z" transform="translate(-2 -4)" fill="#7b7b7b"></path>
                      </svg>
                    </div>
                    <div className="col-xs-10 p0">
                      <label> {renderHTML(item.Body)}</label>
                    </div>
                  </div>
                  <div className="col-xs-10 col-xs-offset-2 msg_mobile_text">
                    <p>{item.Timestamp} </p>
                  </div>
                  <div className="clearfix"> </div>
                </div>
                </Link>
              }))}
            </div>
            <div className="clearfix"> </div>


            <div className="timecard_btm_menu">
            
            <div className="col-xs-12">
              <div className="edit_button">
                <a href={null} onClick={this.showMobSendMessageDiv}>
                  {" "}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <defs />
                    <path
                      id="ic_create_24px"
                      className="cls-1"
                      d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z"
                      transform="translate(-3 -2.998)"
                      fill="#fff"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div className="clearfix"> </div>

              <div className="col-sm-12 col-xs-12">
                <div className="col-xs-3 col-sm-3 timecard_mbl_tick_icon timecard_menu_mobilee text-center">
                  <Link to="/timecards">
                    <svg
                      className="timecard_svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="20"
                      viewBox="0 0 20 22.223"
                    >
                      <defs />
                      <path
                        id="ic_receipt_24px"
                        className="cls-1"
                        d="M19.667,18.667H6.333V16.444H19.667Zm0-4.444H6.333V12H19.667Zm0-4.444H6.333V7.556H19.667ZM3,24.222l1.667-1.667,1.667,1.667L8,22.556l1.667,1.667,1.667-1.667L13,24.222l1.667-1.667,1.667,1.667L18,22.556l1.667,1.667,1.667-1.667L23,24.222V2L21.333,3.667,19.667,2,18,3.667,16.333,2,14.667,3.667,13,2,11.333,3.667,9.667,2,8,3.667,6.333,2,4.667,3.667,3,2Z"
                        transform="translate(-3 -2)"
                        fill="#707070"
                      />
                    </svg>

                    <p>Timecard </p>
                  </Link>
                </div>
                <div className="col-xs-3 col-sm-3 timecard_mbl_edit_icon text-center timecard_menu_mobilee">
                  <Link to="settings">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="20"
                      viewBox="0 0 20 20.561"
                    >
                      <defs />
                      <path
                        id="ic_settings_24px"
                        className="cls-1"
                        d="M19.911,13.288a8.012,8.012,0,0,0,.072-1.007,8.012,8.012,0,0,0-.072-1.007l2.169-1.7a.519.519,0,0,0,.123-.658L20.148,5.362a.517.517,0,0,0-.627-.226l-2.56,1.028a7.511,7.511,0,0,0-1.737-1.007l-.391-2.724a.5.5,0,0,0-.5-.432H10.217a.5.5,0,0,0-.5.432L9.322,5.156A7.9,7.9,0,0,0,7.585,6.164L5.025,5.136a.5.5,0,0,0-.627.226L2.342,8.919a.507.507,0,0,0,.123.658l2.169,1.7a8.153,8.153,0,0,0-.072,1.007,8.153,8.153,0,0,0,.072,1.007l-2.169,1.7a.519.519,0,0,0-.123.658L4.4,19.2a.517.517,0,0,0,.627.226L7.585,18.4A7.511,7.511,0,0,0,9.322,19.4l.391,2.724a.5.5,0,0,0,.5.432h4.112a.5.5,0,0,0,.5-.432l.391-2.724A7.9,7.9,0,0,0,16.961,18.4l2.56,1.028a.5.5,0,0,0,.627-.226L22.2,15.642a.519.519,0,0,0-.123-.658Zm-7.638,2.591a3.6,3.6,0,1,1,3.6-3.6A3.6,3.6,0,0,1,12.273,15.879Z"
                        transform="translate(-2.271 -2)"
                        fill="#707070"
                      />
                    </svg>

                    <p>Settings</p>
                  </Link>
                </div>
                <div className="col-xs-3 col-sm-3 timecard_mbl_msg_icon timecard_mbl_decline_icon text-center timecard_menu_mobilee timecard_active">
                  <Link to="/messages">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="20"
                      viewBox="0 0 20 16"
                    >
                      <path
                        id="ic_mail_outline_24px"
                        d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,14H4V8l8,5,8-5Zm-8-7L4,6H20Z"
                        transform="translate(-2 -4)"
                      />
                    </svg>

                    <p>Messages </p>
                  </Link>
                </div>
                <div className="col-xs-3 col-sm-3 timecard_mbl_next_icon timecard_logout_icon text-center timecard_menu_mobilee timecard_active">
                  <div onClick={this.logout}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="20"
                      viewBox="0 0 23.814 20.094"
                    >
                      <g id="logout" transform="translate(-0.002 -25)">
                        <path
                          id="XMLID_7_"
                          d="M3.812,105.582H16.746a1.116,1.116,0,0,0,0-2.233H3.812l1.443-1.443a1.116,1.116,0,0,0-1.579-1.579L.328,103.676q-.039.039-.074.082c-.01.012-.019.026-.029.038s-.025.032-.036.049-.019.032-.029.048-.019.03-.027.046-.016.033-.024.05-.016.032-.023.049-.012.033-.018.049-.013.035-.019.054-.009.033-.013.05-.01.037-.014.056-.006.039-.009.058-.006.033-.007.05c0,.036-.005.072-.006.108v0q0,.054.006.109c0,.017,0,.033.007.049s.005.039.009.059.009.037.014.055.008.034.013.051.012.035.018.053.012.034.018.05.015.032.022.048.016.034.025.051.017.03.026.045.019.033.03.049.023.032.035.047.019.027.03.04.046.053.07.078l0,0L3.677,108.6a1.116,1.116,0,0,0,1.579-1.579Z"
                          transform="translate(0 -69.419)"
                          fill="#86878A"
                        />
                        <path
                          id="XMLID_8_"
                          d="M115.258,25h-14.14A1.116,1.116,0,0,0,100,26.116v4.465a1.116,1.116,0,0,0,2.233,0V27.233h11.907V42.861H102.235V39.512a1.116,1.116,0,1,0-2.233,0v4.465a1.116,1.116,0,0,0,1.116,1.116h14.14a1.116,1.116,0,0,0,1.116-1.116V26.116A1.116,1.116,0,0,0,115.258,25Z"
                          transform="translate(-92.559 0)"
                          fill="#86878A"
                        />
                      </g>
                    </svg>

                    <p>Logout </p>
                  </div>
                </div>

                <div className="clearfix"> </div>
              </div>
              <div className="clearfix"> </div>
            </div>
          </div>

          <div id="send_message_popus" className="modal fade" role="dialog">
            <div className="modal-dialog modal_middle_user">
              <div className="modal-content modal_middle_user_pop4 modal_aviva_pictureeee">
                <div className="modal-header modal_header_msg">
                  <button
                    type="button"
                    className="close close_modal_user"
                    data-dismiss="modal"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                    >
                      <path
                        id="ic_clear_24px"
                        d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                        transform="translate(-5 -5)"
                        fill="#7B7B7B"
                      />
                    </svg>
                  </button>
                </div>
                <div className="modal-body user_pop_inviteemail text-center">
                  <img
                    alt="message pic"
                    src={sendMsg}
                    className="img-responsive"
                  />
                  <div className="clear10" />
                  <div className="clearfix" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="sendMobMessageBlock">
          <div>
            <div className="col-sm-12 header_shadow_all">
              <a
                href="#menu-toggle"
                className="full_menu_toggle menu_clsssss"
                id="menu-toggle_mobile"
                onClick={(e) => console.log(e)}
              >
                <img
                  alt="headermob_pic"
                  src={ic_dehaz}
                  className="side_imggggggg"
                />
              </a>

              <div className="logo_header_all">
                <Link
                  to="/dashboard"
                  className="pull-left logo_header_all logo_headerall_none1"
                >
                  <img alt="headermob_pic" src={loginLogo} />{" "}
                </Link>

                <Link
                  to="/dashboard"
                  className="pull-left logo_headerall_none2"
                >
                  <img alt="headermob_pic" src={forgotLogoSvg} />{" "}
                </Link>
              </div>

              <div className="header_all_center_label">
                <a href="/dashboard" className="arrow_back_headerall">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="21.053"
                    viewBox="0 0 13 21.053"
                  >
                    <g
                      id="Group_1096"
                      data-name="Group 1096"
                      transform="translate(1480 539.053) rotate(180)"
                    >
                      <path
                        id="ic_chevron_right_24px"
                        d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                        transform="translate(1458.41 512)"
                      />
                    </g>
                  </svg>
                </a>
                Analitics
              </div>
              <div className="exit_top_right pull-right">
                <a hreaf="javascript:void(0);">
                  <div className="right_logout_label">Logout</div>
                  <img alt="headermob_pic" src={logoutSvg} />
                </a>
              </div>

              <div className="notifactio">
                <a hreaf="javascript:void(0);">
                  <img alt="headermob_pic" src={notifactioSvg} />
                </a>
                <span className="notif_label">12</span>
              </div>

              <div className="clearfix" />
            </div>

            <div className="clearfix" />
            <div className="header_analytics_block">
              <div className="col-sm-12 col-xs-12 p0 ">
                <a
                  href={null}
                  onClick={this.backFromMobSendMessage}
                  className="arrow_back_headerall arrow_back_header_white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="17.92"
                    viewBox="0 0 13 21.053"
                  >
                    <g
                      id="Group_1096"
                      data-name="Group 1096"
                      transform="translate(1480 539.053) rotate(180)"
                    >
                      <path
                        id="ic_chevron_right_24px"
                        d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                        transform="translate(1458.41 512)"
                      />
                    </g>
                  </svg>
                </a>
                <div className=" text-center analytics_digital_text digital_text_none  attach_timecard_headerrrrrr">
                  Digital Times
                </div>

                <div className="col-xs-1 p0 decline_gallery_icon">
                  <a className="Mobimage-upload" href={null}>



                    <Dropzone onDrop={this.MobSendMessageFileUploadHandler.bind(this)}>
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input  {...getInputProps()} />
                          <label htmlFor="Mobfile-input">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11.766"
                              height="21.511"
                              viewBox="0 0 11.766 21.511"

                            >
                              <path
                                id="ic_attach_file_24px"
                                d="M17.162,5.889V17.134a4.106,4.106,0,0,1-4.279,3.911A4.106,4.106,0,0,1,8.6,17.134V4.911a2.568,2.568,0,0,1,2.674-2.444,2.568,2.568,0,0,1,2.674,2.444V15.178a1.074,1.074,0,0,1-2.139,0V5.889h-1.6v9.289a2.568,2.568,0,0,0,2.674,2.444,2.568,2.568,0,0,0,2.674-2.444V4.911A4.106,4.106,0,0,0,11.279,1,4.106,4.106,0,0,0,7,4.911V17.134a5.644,5.644,0,0,0,5.883,5.378,5.644,5.644,0,0,0,5.883-5.378V5.889Z"
                                transform="translate(-7 -1)"
                                fill="#fff"
                              />
                            </svg>
                            <span className="badge badge-secondary attchbadge_setting">{this.state.total_Attachment}</span>
                          </label>
                        </div>
                      )}
                    </Dropzone>

                  </a>
                </div>
                <div className="col-xs-1 p0 decline_send_icon">
                  <a href={null} onClick={this.sendMobMessageSave}>
                    <img alt="headermob_pic" src={SendImgPng} />{" "}
                  </a>
                </div>
              </div>
              <div className="clearfix"> </div>
            </div>
            <div className="col-sm-12 p0 text-center payrol_timecard_mbl attach_timecard_none payroll_mbl_heading">
              &nbsp;
            </div>
          </div>

          <div className="col-sm-12 attach_timecard_margin_top">
            <select
              className="form-control"
              id="sel1"
              onChange={this.sendMobHandler}
              name="sendMob_sentTo"
            >
              <option value="">Send To</option>
              {this.state.sendMob_sentToOptions.length > 0 ? this.state.sendMob_sentToOptions.map((items, index) => {
                return (
                  <option
                    value={items.Guid}
                    key={items.Guid}
                    >
                    {items.FirstName}{items.LastName}  {'(' + items.UserType + ')'}
                  </option>
                );
              }) : 'No Record Found'}
            </select>
            <p
              className={
                "error validation " +
                (this.state.sendMobMessageErrors.sendMob_sentTo
                  ? ""
                  : "displayNone")
              }
            >
              {this.state.sendMobMessageErrors.sendMob_sentTo}
            </p>
          </div>
          <div className="clear5" />
          <div className="col-sm-12 timecard_subject">
            <input
              type="text"
              className="form-control"
              name="sendMob_Subject"
              onChange={this.sendMobHandler}
              value={this.state.sendMob_Subject}
            />
            <p
              className={
                "error validation " +
                (this.state.sendMobMessageErrors.sendMob_Subject
                  ? ""
                  : "displayNone")
              }
            >
              {this.state.sendMobMessageErrors.sendMob_Subject}
            </p>
          </div>
          <div className="clear5" />
          <div className="col-sm-12">
            <textarea
              className="add_timecar_textarea form-control"
              name="sendMob_Message"
              onChange={this.sendMobHandler}
              value={this.state.sendMob_Message}
            />
            <p
              className={
                "error validation " +
                (this.state.sendMobMessageErrors.sendMob_Message
                  ? ""
                  : "displayNone")
              }
            >
              {this.state.sendMobMessageErrors.sendMob_Message}
            </p>
          </div>
          <div className="clear5"> </div>
          <div className="tab-content tab-content_timecard">
            <div className="tab-pane" id="tab_default_1_timecard">
              <p />
              <p>
                <img src={sign1} alt="attach_timecardpic" />{" "}
              </p>
              <p />
            </div>

            <div className="clearfix" />
          </div>
          <div className="clear20"> </div>
        </div>
      </div>
    );
  }
}
