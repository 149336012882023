import React, { Component } from "react";
import ic_dehaze_192x from "./../../../Assets/images/ic_dehaze_-19@2x.png";
import login_logo from "./../../../Assets/images/login_logo.svg";
import forgot_logo from "./../../../Assets/images/forgot_logo.svg";
import logout from "./../../../Assets/images/logout.svg";
import notifactio from "./../../../Assets/images/notifactio.svg";
import "./Header.css";
import "../../Payroll_Batches/Payroll_Batches.css";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import { toast } from 'react-toastify';
var ip = require('ip');
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingHeader: false,
      unreadMsgCount: 0,
      companies: [],
      companyname: ''
    }
  }
  async componentDidMount() {
    let that = this;
    await API.post("getcompaniesbycoyid", "/getcompaniesbycoyid", {
      body: {
        "cID": localStorage.getItem('companyId')
      }
    }).then(result => {
      this.setState({
        companies: result
      })

    }).catch(err => { console.log(err); });
    setInterval(function () {
      if (that.state.unreadMsgCount !== 0) {
        if ($(".notifactio").hasClass('shake')) {
          $(".notifactio").removeClass('shake');
        }
        else {
          $(".notifactio").addClass('shake');
        }
      }
    }, 3000);
    $("#menu-toggle_mobile_close").css("display", "none");
    await this.myunreadmsgcount(localStorage.getItem('userGuid'));
    await this.setState({
      companyname: JSON.parse(localStorage.getItem('completetenant')).CompanyName
    })
  }
  myunreadmsgcount = async (userGuid) => {
    await API.post('myunreadmsgcount', '/myunreadmsgcount', {
      body: {
        "myguid": userGuid
      }
    }).then(data => {
      this.setState({
        unreadMsgCount: data
      });
    }).catch(err => {
      toast.error('Unable To Fetch Unread MessageCounts');
    });
  }
  onToggleUp = () => {
    $("#mobile_menu_sm").css("display", "block");
    $("#menu-toggle_mobile").css("display", "none");
    $("#menu-toggle_mobile_close").css("display", "block");
  };

  onToggleDown = () => {
    $("#mobile_menu_sm").css("display", "none");
    $("#menu-toggle_mobile").css("display", "block");
    $("#menu-toggle_mobile_close").css("display", "none");
  };
  onHref = e => {
    e.preventDefault();
  };

  logout = async () => {
    this.setState({ isLoadingHeader: true });

    if(localStorage.getItem('userGuid')!==undefined&&localStorage.getItem('userGuid')!==""&&localStorage.getItem('EmailAddress')&&localStorage.getItem('EmailAddress')!==undefined&&localStorage.getItem('EmailAddress')!==""){
    await API.post("recordlastlogin", "/recordlastlogin", {
      body:
      {
        "Guid": localStorage.getItem('userGuid')
      }
    }).then(data => {
     // toast.success('Last Logout Recorded Successfully.');
    }
    ).catch(err => { toast.error(err.message); });
    await API.post("recordactivity", "/recordactivity", {
      body:
      {
        "Description": "logout",
        "UserName": localStorage.getItem('UserLogin'),
        "IPAddress": ip.address(),
        "TenantGuid": "UYGVUYGBIUYGHBIUYHGB",
        "Type": "Logout",
        "UserGuid": localStorage.getItem('userGuid')
      }
    }).then(data => { console.log('Logout Activity Recorded Successfully.'); }).catch(err => { toast.error(err.message); });

    await API.post("updatelastlogout", "/updatelastlogout", {
      body:
      {
        "Guid": localStorage.getItem('userGuid')
      }
    }).then(data => {
      //toast.success('User last logout updated successfully.');
    }
    ).catch(err => { toast.error(err.message); });

    await API.post("updatelastlogoutforuser", "/updatelastlogoutforuser", {
      body:
      {
        "userEmail": localStorage.getItem('EmailAddress'),
        "LastLogout": Date.now()
      }
    }).then(data => {
     // toast.success('last logout updated successfully for user.');
    }
    ).catch(err => { toast.error(err.message); });
  }
    localStorage.removeItem('EmailAddress');
    localStorage.removeItem('userGuid');
    localStorage.removeItem('UserLogin');
    localStorage.removeItem('UserFullName');
    await Auth.signOut();
    this.props.history.push('/login');
  }
  checkCompanyWithUser = async (event) => {
    var userTenant = localStorage.getItem('tenantid');
    if (userTenant.indexOf(event.target.id) > -1) {
      $("#companiesModelclose").click();
      this.setState({ isLoading: true });
      await localStorage.setItem('useableGuid', event.target.id);
      await this.forceUpdate();
     // toast.success("Production switched Successfully.");

    } else {
      toast.error("You don't belong with selected company");
    }
    if (window.location.pathname !== '/dashboard') {
      this.props.history.push('/dashboard');
    }
    else {
      await API.post('gettenantbytenantid', '/gettenantbytenantid', {
        body: {
          id: localStorage.getItem('useableGuid')
        }
      }).then(data => {
        localStorage.setItem('completetenant', JSON.stringify(data[0]));
      }).catch(err => {
        toast.error('Unable To Fetch Users Activities');
      });
    }
  }
  render() {
    return (
      <div className="col-sm-12 dashboard_main_shadow dashboard_extra_mbl">
        {(this.state.isLoadingHeader || this.props.isLoading) ? <div className="se-pre-con"></div> : ''}
        <button data-toggle="modal" data-target="#companiesModel" id="companiesModelbtn" className="displayNone"></button>
        <div id="companiesModel" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" id="companiesModelclose" data-dismiss="modal">&times;</button>
                <h3 className="modal-title">Productions</h3>
              </div>
              <div className="modal-body">
                <div className="verif_table_box">

                  <table width="99%" className="table zeroMarginBottom">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Production</th>
                        <th align="center" scope="col">Approve</th>
                        <th align="center" scope="col">Pending</th>
                        <th align="center" scope="col">Approved</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.companies.map((value, key) => {
                        return (<tr key={key} onClick={this.checkCompanyWithUser} className="cursorPointer companyList">
                          <td id={value.TenantGuid}>{value.CompanyName}</td>
                          <td align="center" id={value.TenantGuid}>0</td>
                          <td align="center" id={value.TenantGuid}>0</td>
                          <td align="center" id={value.TenantGuid}>5</td>
                        </tr>);
                      })}


                    </tbody>
                  </table>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <Link
          to="/dashboard"
          className="menu_toggle_mrg display_none_sm_menu"
          onClick={this.onToggleUp}
          id="menu-toggle_mobile"
          style={{ float: "left", lineHeight: "normal" }}
        >
          <img
            className=""
            src={ic_dehaze_192x}
            style={{ width: "25px !important", height: "18px" }}
            alt=""
          />
        </Link>

        <a
          href="#menu-toggle"
          className="menu_toggle_mrg menu-toggle_mobile_close"
          onClick={this.onToggleDown}
          id="menu-toggle_mobile_close"
          style={{ float: "left", lineHeight: "normal" }}
        >
          <img
            src={ic_dehaze_192x}
            style={{ width: "25px !important", height: "18px" }}
            className="toggle-img"
            alt=""
          />
        </a>

        <div className="logo_dashboard">
          <Link
            to="/dashboard"
            className="pull-left logo_dashboard logo_dashboard_none1"
          >
            <img src={login_logo} alt="" />{" "}
          </Link>

          <Link to="/dashboard" className="pull-left logo_dashboard_none2">
            <img src={forgot_logo} alt="" />{" "}
          </Link>
        </div>

        <div className="col-xs-8 col-sm-4 col-md-4 text-left custome_header_label">
          {'"' + this.props.companyname + '"'}
        </div>

        <div className="exit_top_right pull-right">
          <a href={null} onClick={this.logout} className="cursorPointer">
            <div className="right_logout_label">Logout</div>
            <img src={logout} alt="" />
          </a>
        </div>
        {
          this.props.childProps.setting_SNCheck &&

          <div className="notifactio">
            <Link to="/messages">
              <img src={notifactio} alt="" />
            </Link>
            {this.state.unreadMsgCount > 0 ? <span className="notif_label">{this.state.unreadMsgCount}</span> : ''}
          </div>
        }

        <div className="clearfix" />
      </div>
    );
  }
}
export default withRouter(Header);
