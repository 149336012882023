import "isomorphic-fetch";
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Amplify from "aws-amplify";
import * as serviceWorker from './serviceWorker';

Amplify.configure({
    Auth: {
      mandatorySignIn: false,
      region:process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
      userPoolWebClientId:  process.env.REACT_APP_COGNITO_APP_CLIENT_ID
    },
    Storage: {
      region:process.env.REACT_APP_S3_REGION,
      bucket: process.env.REACT_APP_S3_BUCKET,
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID
    },
    API: {
      endpoints: [
        {
          name: "userget",
          endpoint: process.env.REACT_APP_API_URL,
          region: process.env.REACT_APP_API_REGION
        },
        {
          name: "updateprofileindynamo",
          endpoint: process.env.REACT_APP_API_URL,
          region: process.env.REACT_APP_API_REGION
        },
        {
          name: "updatepoolid",
          endpoint: process.env.REACT_APP_API_URL,
          region: process.env.REACT_APP_API_REGION
        },
        {
          name: "getuserbyguid",
          endpoint: process.env.REACT_APP_API_URL,
          region: process.env.REACT_APP_API_REGION
        },
        {
          name: "recordactivity",
          endpoint: process.env.REACT_APP_API_URL,
          region: process.env.REACT_APP_API_REGION
        },
        {
          name: "updateuserbyguid",
          endpoint: process.env.REACT_APP_API_URL,
          region: process.env.REACT_APP_API_REGION
        },
        {
          name: "recordlastlogin",
          endpoint: process.env.REACT_APP_API_URL,
          region: process.env.REACT_APP_API_REGION
        },
        {
          name: "listresources",
          endpoint: process.env.REACT_APP_API_URL,
          region: process.env.REACT_APP_API_REGION
        },
        {
        name: "updatepassword",
        endpoint: process.env.REACT_APP_API_URL,
        region: process.env.REACT_APP_API_REGION
        },
        {
        name: "sendsms",
        endpoint: process.env.REACT_APP_API_URL,
        region: process.env.REACT_APP_API_REGION
        },
        {
        name: "checkemailuser",
        endpoint: process.env.REACT_APP_API_URL,
        region: process.env.REACT_APP_API_REGION
        },
        {
        name: "countwpupdate",
        endpoint: process.env.REACT_APP_API_URL,
        region: process.env.REACT_APP_API_REGION
        },
        {
        name: "activedeactiveuser",
        endpoint: process.env.REACT_APP_API_URL,
        region: process.env.REACT_APP_API_REGION
        },
        {
        name: "resetcount",
        endpoint: process.env.REACT_APP_API_URL,
        region: process.env.REACT_APP_API_REGION
        },
        {
            name: "getcompaniesbycoyid",
            endpoint: process.env.REACT_APP_API_URL,
            region: process.env.REACT_APP_API_REGION
        },
        {
            name: "listtenant",
            endpoint: process.env.REACT_APP_API_URL,
            region: process.env.REACT_APP_API_REGION
        },
        {
            name: "gettenantbyguid",
            endpoint: process.env.REACT_APP_API_URL,
            region: process.env.REACT_APP_API_REGION
        },
        {
            name: "updatetenantbyguid",
            endpoint: process.env.REACT_APP_API_URL,
            region: process.env.REACT_APP_API_REGION
        },
        {
            name: "createtenant",
            endpoint: process.env.REACT_APP_API_URL,
            region: process.env.REACT_APP_API_REGION
        },
        {
            name: "deletetenant",
            endpoint: process.env.REACT_APP_API_URL,
            region: process.env.REACT_APP_API_REGION
        },
        {
            name: "getsettings",
            endpoint: process.env.REACT_APP_API_URL,
            region: process.env.REACT_APP_API_REGION
        },
        {
            name: "createsettings",
            endpoint: process.env.REACT_APP_API_URL,
            region: process.env.REACT_APP_API_REGION
        },
        {
            name: "createuser",
            endpoint: process.env.REACT_APP_API_URL,
            region: process.env.REACT_APP_API_REGION
        },
        {
            name: "updatelastlogout",
            endpoint: process.env.REACT_APP_API_URL,
            region: process.env.REACT_APP_API_REGION
        },
        {
            name: "getallusers",
            endpoint: process.env.REACT_APP_API_URL,
            region: process.env.REACT_APP_API_REGION
        },
        {
            name: "deleteuser",
            endpoint: process.env.REACT_APP_API_URL,
            region: process.env.REACT_APP_API_REGION
        }
        ,
        {
            name: "sendemail",
            endpoint: process.env.REACT_APP_API_URL,
            region: process.env.REACT_APP_API_REGION
        } ,
        {
            name: "getemailtemplate",
            endpoint: process.env.REACT_APP_API_URL,
            region: process.env.REACT_APP_API_REGION
        } ,
        {
            name: "getallusersag",
            endpoint: process.env.REACT_APP_API_URL,
            region: process.env.REACT_APP_API_REGION
        },
        {
            name: "getallusersapprover",
            endpoint: process.env.REACT_APP_API_URL,
            region: process.env.REACT_APP_API_REGION
        },
        {
            name: "updateapprovergroup",
            endpoint: process.env.REACT_APP_API_URL,
            region: process.env.REACT_APP_API_REGION
        }
          ,
          {
            name: "mastersettings",
            endpoint: process.env.REACT_APP_API_URL,
            region: process.env.REACT_APP_API_REGION
        }
        ,
        {
        name: "updateapproversetup",
        endpoint: process.env.REACT_APP_API_URL,
        region: process.env.REACT_APP_API_REGION
        },
        {
        name: "getallusersapprover",
        endpoint: process.env.REACT_APP_API_URL,
        region: process.env.REACT_APP_API_REGION
        },
        {
        name: "updateuserupdateinfo",
        endpoint: process.env.REACT_APP_API_URL,
        region: process.env.REACT_APP_API_REGION
        },
        {
        name: "updatelastlogoutforuser",
        endpoint: process.env.REACT_APP_API_URL,
        region: process.env.REACT_APP_API_REGION
        },{
        name: "timestampsync",
        endpoint: process.env.REACT_APP_API_URL,
        region: process.env.REACT_APP_API_REGION
        },{
        name: "getstamp",
        endpoint: process.env.REACT_APP_API_URL,
        region: process.env.REACT_APP_API_REGION
        },
        {
        name: "updatelastpasswordchanged",
        endpoint: process.env.REACT_APP_API_URL,
        region: process.env.REACT_APP_API_REGION
        },
          {
            name: "getalladlist",
            endpoint: process.env.REACT_APP_API_URL,
            region: process.env.REACT_APP_API_REGION
            },
            {
            name: "getadlistbyguid",
            endpoint: process.env.REACT_APP_API_URL,
            region: process.env.REACT_APP_API_REGION
            }
            ,
            {
            name: "deleteadlist",
            endpoint: process.env.REACT_APP_API_URL,
            region: process.env.REACT_APP_API_REGION
            },
            {
            name: "updateadlist",
            endpoint: process.env.REACT_APP_API_URL,
            region: process.env.REACT_APP_API_REGION
            },
          {
          name: "createadlist",
          endpoint: process.env.REACT_APP_API_URL,
          region: process.env.REACT_APP_API_REGION
        },{
            name: "getadtimes",
				endpoint: process.env.REACT_APP_API_URL,
				region: process.env.REACT_APP_API_REGION
				},{
            name: "deleteadtimes",
				endpoint: process.env.REACT_APP_API_URL,
				region: process.env.REACT_APP_API_REGION
				},
{
name: "getallemployees",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "createadtimescard",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "adtimesbyguid",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "updateadtimes2adcards",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "getmessage",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},{
name: "dynamomsgcreate",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "createemployees",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},{
name: "myunreadmsgcount",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "markmessageread",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "draftmessage",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
}
,
{
name: "deletemessage",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "gettenantbytenantid",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "createplaceholder",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "getplaceholdersall",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "deleteplaceholder",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "createemailtemplate",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "deletetemplate",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "templateforinvite",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "placeholdersbyname",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "getuserbyemail",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "createbatch",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "getbatch",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "deletebatchitems",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "getprlemp",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "createprlemp",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "prlempbyid",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "updateemployeeuserguid",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "deleteprlempbatch",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "updatebatchnumber",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
}
,
{
name: "getteams",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "deleteteam",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "passwordchangedonce",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},
{
name: "updatelastactivepassword",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},{
  name: "getallprlemp",
  endpoint: process.env.REACT_APP_API_URL,
  region: process.env.REACT_APP_API_REGION
},
{
  name: "prlempbyid",
  endpoint: process.env.REACT_APP_API_URL,
  region: process.env.REACT_APP_API_REGION
}  ,
{
  name: "getadlistbydate",
  endpoint: process.env.REACT_APP_API_URL,
  region: process.env.REACT_APP_API_REGION
}   
 ,
{
  name: "getadtimesbyadlistguid",
  endpoint: process.env.REACT_APP_API_URL,
  region: process.env.REACT_APP_API_REGION
}   
,
{
  name: "updatedatesadtimes",
  endpoint: process.env.REACT_APP_API_URL,
  region: process.env.REACT_APP_API_REGION
} ,
{
  name: "getgroupbyname",
  endpoint: process.env.REACT_APP_API_URL,
  region: process.env.REACT_APP_API_REGION
}  ,
{
  name: "appgroupfortimecard",
  endpoint: process.env.REACT_APP_API_URL,
  region: process.env.REACT_APP_API_REGION
} ,
{
  name: "setupsfortimecard",
  endpoint: process.env.REACT_APP_API_URL,
  region: process.env.REACT_APP_API_REGION
}   ,
{
  name: "updatetimecardstatus",
  endpoint: process.env.REACT_APP_API_URL,
  region: process.env.REACT_APP_API_REGION
}   ,
{
  name: "updatecomments",
  endpoint: process.env.REACT_APP_API_URL,
  region: process.env.REACT_APP_API_REGION
}  ,
          {
name: "getdeclinetemplate",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
},{
  name: "getprlempanalytics",
  endpoint: process.env.REACT_APP_API_URL,
  region: process.env.REACT_APP_API_REGION
  },{
name: "updateuserpasswordbyemail",
endpoint: process.env.REACT_APP_API_URL,
region: process.env.REACT_APP_API_REGION
}

      ]
    }
  });

ReactDOM.render(
    <Router>
        <App />
    </Router>
    ,
    document.getElementById('root'));


serviceWorker.unregister();
