import React, { Component } from "react";
import {getKeys} from './../tempkeys.js'
import ic_search from "./../../Assets/images/ic_search_-1.svg";
import send_icon from "./../../Assets/images/send_icon.png";
import reverse_icon from "./../../Assets/images/reverse_icon.png";
import send_reports from "./../../Assets/images/send_reports.png";
import relese_icon from "./../../Assets/images/relese_icon.png";
import relese_icon2 from "./../../Assets/images/relese_icon2.png";
import send_year_report from "./../../Assets/images/send_year_report.png";
import dollar_icon from "./../../Assets/images/dollar_icon.png";
import post_icon from "./../../Assets/images/post_icon.png";
import Header1 from "../Common/Header1/Header1";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import { toast } from 'react-toastify';
import "./Payroll_Batches.css";
import $ from 'jquery';
const uuidv1 = require('uuid/v1');
const https = require('https');
const agent = new https.Agent({
    rejectUnauthorized: false
});

let counter=0;
class Payroll_Batches extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showPayrollBatch: true,
      endyeardata: [],
      sort_StatusToggle: false,
      tmp_batch_nmb: '',
      sort_DescToggle: false,
      changeBatchDesc: '',
      isEditchangeBatchDesc: false,
      batchwrite: [],
      PayrollBatchesSelectedTab: 'Batch',
      isLoading: true,
      payrollBatches_search: '',
      payroll_BatchName_Name: '',
      payroll_sendApproval_BatchName_Name: '',
      paryRoll_resendStpFileSelectOpt: '',
      Is_paryRoll_resendStpFileOptSelected: false,
      Is_payRoll_resendStp_amendment: false,
      payrollBatches_resendStp_Verify: false,
      payroll_YearEndStp_amendment_Chkbx: false,
      payroll_YearEndStp_Verify_Chkbx: false,
      payroll_CreatSendEft_ChkBx: false,


      payroll_BatchName_NameValid: false,
      payroll_sendApproval_BatchName_NameValid: false,




      payrollBatchesBatchNameErrors: {
        payroll_BatchName_Name: ""
      }
      ,
      payrollSendApprovalBatchNameErrors: {
        payroll_sendApproval_Name: ""
      }
      ,

      paryRoll_resendStpFileOptions: [
        { _id: 1, name: 'A' },
        { _id: 2, name: 'B' },
        { _id: 3, name: 'C' },
      ],

      isAllPayrollTableChkBoxSelected: false,
      sort_batchToggle: false,
      sort_notesToggle: false,
      payrollBatchesTableData: [],
      payrollBatches_searchData: [],

    }

  }
  componentWillMount = async () => {
    await this.getUserSettings();
    if (this.state.showPayrollBatch === false) {
      this.props.history.push('/dashboard');
    }
  }
  getUserSettings = async () => {
    let userGuid = localStorage.getItem("userGuid");
    await API.post("getuserbyguid", "/getuserbyguid", {
      body: {
        user_id: userGuid
      }
    }).then(data => {
      this.setState({
        showPayrollBatch: data.access.PayrollBatch,
      });
    }).catch(err => {
      console.log('error in fetching user')
    });


  }
  componentDidMount = async () => {
    await this.props.childProps.isAuthentication();
    if (!this.props.childProps.isAuthenticated) {
      this.props.history.push('/login');
    } else {
      await this.batchclientapi();
    }
  }
  deleteallbatch = async () => {
    var Guidsdelete = [];
    await API.post('getbatch', '/getbatch', {
      body: {
        "tenant": localStorage.getItem('useableGuid')
      }
    }).then(data => {
      data.map(val => {
        Guidsdelete.push({ Guid: val.Guid });
      });
    }).catch(error => {
      toast.error('No Ids Found');
    });
    if (Guidsdelete.length > 0) {

      var length_of_data = Math.ceil(Guidsdelete.length / 20);
      var records = 0;
      for (var i = 1; i <= length_of_data; i++) {


        await API.post('deletebatchitems', '/deletebatchitems', {
          body: {
            finaldata: Guidsdelete.slice(records, records + 20)
          }
        }).then(data => {
          console.log('old Data Deleted');
        }).catch(error => {
          toast.error('old Data not Deleted');
        });

        records = records + 20;
      }
     // toast.success('Old Data Replacing...');
    } else {
     // toast.success('No Data to Delete');
    }
  }
  editBatch = async (currentBatch) => {
    await this.setState({
      changeBatchDesc: currentBatch,
      payroll_BatchName_Name: currentBatch.Desc,
      isEditchangeBatchDesc: true
    })
  }
  UpdatePayrollBatch = async () => {
    let payrollBatchesBatchNameErrors = this.state.payrollBatchesBatchNameErrors;
    if (this.state.payroll_BatchName_Name === '') {
      payrollBatchesBatchNameErrors.payroll_BatchName_Name = 'This field is required!...';

    }
    this.setState({
      payrollBatchesBatchNameErrors: payrollBatchesBatchNameErrors
    })
    const Name = this.state.payroll_BatchName_Name;
    const Batch = this.state.changeBatchDesc.Batch;

    var completetenant = localStorage.getItem('completetenant');
    completetenant = JSON.parse(completetenant);
    var TphPayrollApi = completetenant.TphPayrollApi;
    var keys=localStorage.getItem('tempkeys');
    var finalkeys = (keys&&keys!==null&&keys!==undefined)?JSON.parse(keys):undefined;
    if(finalkeys!==undefined&&finalkeys.tempUsername&&
    finalkeys.tempPassword){
    await fetch(TphPayrollApi.url + '/api/apiBATCH?username=' + finalkeys.tempUsername + '&password=' + finalkeys.tempPassword + '&batchnumber=' + Batch + '&description=' + Name)
    .then(response => response.json()).then(async(response) => {
        
        if(response.apiBatch_response.Batch_List){
          document.getElementById("batchaddmodal").click();
          await this.batchclientapi();
        }else if(response.apiBatch_response.ServiceErrors[0].ERRORDESCRIPTION==="Userkey or Password invalid"||response.apiBatch_response.ServiceErrors[0].ERRORDESCRIPTION==="temp key expired"){
          var confirm=await getKeys();
          if(confirm===true){
          this.UpdatePayrollBatch();
         }
        }

         }).catch(error => { toast.error('error in updating a data.') });



  }else{
    var confirm=await getKeys();
         if(confirm===true){
         this.UpdatePayrollBatch();
        }
  }
  }
  sort_payrollBatches = async (AdSearchTab, name) => {


    localStorage.setItem('PayrollBatches_sortLastTab', AdSearchTab)
    if (AdSearchTab === 'Batch') {
      $('#batchCaret').show();
      $('#notesCaret').hide();
      $('#DescCaret').hide();
      $('#StatusCaret').hide();


      this.setState({
        PayrollBatchesSelectedTab: AdSearchTab,
        sort_notesToggle: false,
        sort_DescToggle: false,
        sort_StatusToggle: false,
      })



      if (this.state.sort_batchToggle) {
        await this.setState({
          sort_batchToggle: false
        })
      } else {
        await this.setState({
          sort_batchToggle: true
        })
      }
      if (this.state.sort_batchToggle) {
        await this.setState({
          payrollBatchesTableData: this.state.payrollBatchesTableData.sort(function (a, b) {
            var nameA = a.Batch; // ignore upper and lowercase
            var nameB = b.Batch; // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          payrollBatchesTableData: this.state.payrollBatchesTableData.sort(function (a, b) {
            var nameA = a.Batch; // ignore upper and lowercase
            var nameB = b.Batch; // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        })
      }

    }
    if (AdSearchTab === 'Notes') {
      $('#batchCaret').hide();
      $('#notesCaret').show();
      $('#DescCaret').hide();
      $('#StatusCaret').hide();

      this.setState({
        PayrollBatchesSelectedTab: AdSearchTab,
        sort_batchToggle: false,
        sort_DescToggle: false,
        sort_StatusToggle: false,
      })



      if (this.state.sort_notesToggle) {
        await this.setState({
          sort_notesToggle: false
        })
      } else {
        await this.setState({
          sort_notesToggle: true
        })
      }
      if (this.state.sort_notesToggle) {
        await this.setState({
          payrollBatchesTableData: this.state.payrollBatchesTableData.sort(function (a, b) {
            var nameA = a.Notes.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Notes.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          payrollBatchesTableData: this.state.payrollBatchesTableData.sort(function (a, b) {
            var nameA = a.Notes.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Notes.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        })
      }

    }
    if (AdSearchTab === 'Description') {
      $('#batchCaret').hide();
      $('#notesCaret').hide();
      $('#DescCaret').show();
      $('#StatusCaret').hide();


      this.setState({
        PayrollBatchesSelectedTab: AdSearchTab,
        sort_batchToggle: false,
        sort_notesToggle: false,
        sort_StatusToggle: false,
      })



      if (this.state.sort_DescToggle) {
        await this.setState({
          sort_DescToggle: false
        })
      } else {
        await this.setState({
          sort_DescToggle: true
        })
      }
      if (this.state.sort_DescToggle) {
        await this.setState({
          payrollBatchesTableData: this.state.payrollBatchesTableData.sort(function (a, b) {
            var nameA = a.Desc.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Desc.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          payrollBatchesTableData: this.state.payrollBatchesTableData.sort(function (a, b) {
            var nameA = a.Desc.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Desc.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        })
      }

    }
    if (AdSearchTab === 'Status') {
      $('#batchCaret').hide();
      $('#notesCaret').hide();
      $('#DescCaret').hide();
      $('#StatusCaret').show();


      this.setState({
        PayrollBatchesSelectedTab: AdSearchTab,
        sort_batchToggle: false,
        sort_notesToggle: false,
        sort_DescToggle: false,
      })



      if (this.state.sort_StatusToggle) {
        await this.setState({
          sort_StatusToggle: false
        })
      } else {
        await this.setState({
          sort_StatusToggle: true
        })
      }
      if (this.state.sort_StatusToggle) {
        await this.setState({
          payrollBatchesTableData: this.state.payrollBatchesTableData.sort(function (a, b) {
            var nameA = a.Lock.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Lock.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          payrollBatchesTableData: this.state.payrollBatchesTableData.sort(function (a, b) {
            var nameA = a.Lock.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Lock.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        })
      }

    }
  }
  payrollBatches_SearchHandler = async (event) => {

    let tab = this.state.PayrollBatchesSelectedTab;
    if (tab === 'Batch') {
      var text = event.target.value;
      this.setState({
        payrollBatches_search: event.target.value
      });
      let payrollBatchesTableData = this.state.payrollBatches_searchData.filter(function (setup) {
        return setup.Batch.toString().toUpperCase().includes(text.toUpperCase());
      });

      await this.setState({
        payrollBatchesTableData
      })

    }

    if (tab === 'Notes') {
      var text = event.target.value;
      this.setState({
        payrollBatches_search: event.target.value
      });
      this.setState({
        payrollBatchesTableData: this.state.payrollBatches_searchData.filter(function (setup) {
          return setup.Notes.toUpperCase().includes(text.toUpperCase());
        })
      });
    }
    if (tab === 'Description') {
      var text = event.target.value;
      this.setState({
        payrollBatches_search: event.target.value
      });
      this.setState({
        payrollBatchesTableData: this.state.payrollBatches_searchData.filter(function (setup) {
          return setup.Desc.toUpperCase().includes(text.toUpperCase());
        })
      });
    }
    if (tab === 'Status') {
      var text = event.target.value;
      this.setState({
        payrollBatches_search: event.target.value
      });
      this.setState({
        payrollBatchesTableData: this.state.payrollBatches_searchData.filter(function (setup) {
          return setup.Lock.toUpperCase().includes(text.toUpperCase());
        })
      });
    }
  }
  getPayrollBatchesList = async () => {
    this.setState({
      isLoading: true,
    });

    await API.post('getbatch', '/getbatch', {
      body: {
        "tenant": localStorage.getItem('useableGuid')
      }
    }).then(data => {
      data.map(value => {
        value.checked = false;
        value.Notes = (value.Notes === null || value.Notes === undefined) ? 'pay17' : value.Notes;
        value.Lock = (value.Lock === null || value.Lock === undefined) ? 'Not Locked' : value.Lock;
        return null;
      });
      let AdSearchTab = localStorage.getItem('PayrollBatches_sortLastTab') === null ? 'Batch' : localStorage.getItem('PayrollBatches_sortLastTab');

      if (AdSearchTab === 'Batch') {
        $('#batchCaret').show();
        $('#notesCaret').hide();
        $('#DescCaret').hide();
        $('#StatusCaret').hide();
        this.setState({
          PayrollBatchesSelectedTab: AdSearchTab,
          sort_batchToggle: true,
          sort_notesToggle: false,
          sort_DescToggle: false,
          sort_StatusToggle: false,
          payrollBatchesTableData: data.sort(function (a, b) {
            let nameA = a.Batch;
            let nameB = b.Batch;
            if (nameA < nameB) {
              return -1
            }
          }),
          payrollBatches_searchData: data.sort(function (a, b) {
            let nameA = a.Batch;
            let nameB = b.Batch;
            if (nameA < nameB) {
              return -1
            }
          }),
          payrollBatchesTableData: data.sort(function (a, b) {
            let nameA = a.Batch;
            let nameB = b.Batch;
            if (nameA < nameB) {
              return -1
            }
          }),
          payrollBatches_searchData: data.sort(function (a, b) {
            let nameA = a.Batch;
            let nameB = b.Batch;
            if (nameA < nameB) {
              return -1
            }
          })
        })


      }
      else if (AdSearchTab === 'Notes') {
        $('#batchCaret').hide();
        $('#notesCaret').show();
        $('#DescCaret').hide();
        $('#StatusCaret').hide();
        this.setState({
          PayrollBatchesSelectedTab: AdSearchTab,
          sort_batchToggle: false,
          sort_notesToggle: true,
          sort_DescToggle: false,
          sort_StatusToggle: false,
          payrollBatchesTableData: data.sort(function (a, b) {
            let nameA = a.Notes.toUpperCase();
            let nameB = b.Notes.toUpperCase();
            if (nameA < nameB) {
              return -1
            }
          }),
          payrollBatches_searchData: data.sort(function (a, b) {
            let nameA = a.Notes.toUpperCase();
            let nameB = b.Notes.toUpperCase();
            if (nameA < nameB) {
              return -1
            }
          }),
          payrollBatchesTableData: data.sort(function (a, b) {
            let nameA = a.Notes.toUpperCase();
            let nameB = b.Notes.toUpperCase();
            if (nameA < nameB) {
              return -1
            }
          }),
          payrollBatches_searchData: data.sort(function (a, b) {
            let nameA = a.Notes.toUpperCase();
            let nameB = b.Notes.toUpperCase();
            if (nameA < nameB) {
              return -1
            }
          })

        })


      }
      else if (AdSearchTab === 'Description') {
        $('#batchCaret').hide();
        $('#notesCaret').hide();
        $('#DescCaret').show();
        $('#StatusCaret').hide();
        this.setState({
          PayrollBatchesSelectedTab: AdSearchTab,
          sort_batchToggle: false,
          sort_notesToggle: false,
          sort_DescToggle: true,
          sort_StatusToggle: false,

          payrollBatchesTableData: data.sort(function (a, b) {
            let nameA = a.Desc.toUpperCase();
            let nameB = b.Desc.toUpperCase();
            if (nameA < nameB) {
              return -1
            }
          }),
          payrollBatches_searchData: data.sort(function (a, b) {
            let nameA = a.Desc.toUpperCase();
            let nameB = b.Desc.toUpperCase();
            if (nameA < nameB) {
              return -1
            }
          }),
          payrollBatchesTableData: data.sort(function (a, b) {
            let nameA = a.Desc.toUpperCase();
            let nameB = b.Desc.toUpperCase();
            if (nameA < nameB) {
              return -1
            }
          }),
          payrollBatches_searchData: data.sort(function (a, b) {
            let nameA = a.Desc.toUpperCase();
            let nameB = b.Desc.toUpperCase();
            if (nameA < nameB) {
              return -1
            }
          })

        })


      }
      else if (AdSearchTab === 'Status') {
        $('#batchCaret').hide();
        $('#notesCaret').hide();
        $('#DescCaret').hide();
        $('#StatusCaret').show();
        this.setState({
          PayrollBatchesSelectedTab: AdSearchTab,
          sort_batchToggle: false,
          sort_notesToggle: false,
          sort_DescToggle: false,
          sort_StatusToggle: true,

          payrollBatchesTableData: data.sort(function (a, b) {
            let nameA = a.Lock.toUpperCase();
            let nameB = b.Lock.toUpperCase();
            if (nameA < nameB) {
              return -1
            }
          }),
          payrollBatches_searchData: data.sort(function (a, b) {
            let nameA = a.Lock.toUpperCase();
            let nameB = b.Lock.toUpperCase();
            if (nameA < nameB) {
              return -1
            }
          }),
          payrollBatchesTableData: data.sort(function (a, b) {
            let nameA = a.Lock.toUpperCase();
            let nameB = b.Lock.toUpperCase();
            if (nameA < nameB) {
              return -1
            }
          }),
          payrollBatches_searchData: data.sort(function (a, b) {
            let nameA = a.Lock.toUpperCase();
            let nameB = b.Lock.toUpperCase();
            if (nameA < nameB) {
              return -1
            }
          })

        })


      }
      else {
        $('#batchCaret').show();
        $('#notesCaret').hide();
        this.setState({
          PayrollBatchesSelectedTab: "Batch",
          sort_batchToggle: true,
          sort_notesToggle: false,
          payrollBatchesTableData: data.sort(function (a, b) {
            let nameA = a.Batch.toUpperCase();
            let nameB = b.Batch.toUpperCase();
            if (nameA < nameB) {
              return -1
            }
          }),
          payrollBatches_searchData: data.sort(function (a, b) {
            let nameA = a.Batch.toUpperCase();
            let nameB = b.Batch.toUpperCase();
            if (nameA < nameB) {
              return -1
            }
          }),
          payrollBatchesTableData: data.sort(function (a, b) {
            let nameA = a.Batch.toUpperCase();
            let nameB = b.Batch.toUpperCase();
            if (nameA < nameB) {
              return -1
            }
          }),
          payrollBatches_searchData: data.sort(function (a, b) {
            let nameA = a.Batch.toUpperCase();
            let nameB = b.Batch.toUpperCase();
            if (nameA < nameB) {
              return -1
            }
          })
        })



      }
    }).catch(err => {
      console.log(err);
    })

    this.setState({
      isLoading: false
    })
  }
  batchclientapi = async () => {
    this.setState({
      isLoading: true
    })
  
    $('#freshbtn').addClass('moving');
   

    var completetenant = localStorage.getItem('completetenant');
    
    completetenant = JSON.parse(completetenant);
    var TphPayrollApi = completetenant.TphPayrollApi;
    var keys=localStorage.getItem('tempkeys');
    var finalkeys = (keys&&keys!==null&&keys!==undefined)?JSON.parse(keys):undefined;
if(finalkeys!==undefined&&finalkeys.tempUsername&&
  finalkeys.tempPassword){
    await fetch(TphPayrollApi.url + '/api/apiBATCH?username=' + finalkeys.tempUsername + '&password=' +finalkeys.tempPassword + '')
      .then(response => response.json())
      .then(async(data) => {

        if(data.apiBatch_response.Batch_List){
        var temp_data = data.apiBatch_response.Batch_List;

        temp_data.map(item => {
          item.Guid = uuidv1();
          item.tenant = localStorage.getItem('useableGuid')
        })

        this.setState({
          batchwrite: temp_data
        })
        await this.synceithdynamo();
      }else if(data.apiBatch_response.ServiceErrors[0].ERRORDESCRIPTION==="Userkey or Password invalid"||data.apiBatch_response.ServiceErrors[0].ERRORDESCRIPTION==="temp key expired"){
 var confirm=await getKeys();
 if(confirm===true){
 this.batchclientapi();
}
      }
       

      })
      .catch(async(error) => {
        
      
        toast.error('Api Batch is Not Working please try again');
      });
    
  }else{
    var confirm=await getKeys();
    if(confirm===true){
    this.batchclientapi();
   }
    }
  
    $('#freshbtn').removeClass('moving');
    this.setState({
      isLoading: false
    })
  }
  synceithdynamo = async () => {
    var requesteddata = this.state.batchwrite;

    if (requesteddata.length > 0) {
      await this.deleteallbatch();


      var length_of_data = Math.ceil(this.state.batchwrite.length / 20);
      var records = 0;
      for (var i = 1; i <= length_of_data; i++) {
        await API.post('createbatch', '/createbatch', {
          body: {
            finaldata: requesteddata.slice(records, records + 20)
          }
        }).then(data => {
          console.log('Data Successfully Synced With Dynamo');
        }).catch(error => {
          toast.error('Data Not Synced With Dynamo');
        });
        records = records + 20;
      }
      //toast.success('Data Successfully Synced With Dynamo');
    } else {
      toast.error('No data to sync');
    }

    await this.getPayrollBatchesList();


  }
  handleInputsPayroll = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({
      [name]: value
    }, () => {
      this.validateForms(name, value)
    })

  }
  validateForms = (fieldName, value) => {

    let payrollBatchesBatchNameErrors = this.state.payrollBatchesBatchNameErrors;
    let payrollSendApprovalBatchNameErrors = this.state.payrollSendApprovalBatchNameErrors;
    let BatchNameValid = this.state.payroll_BatchName_NameValid;
    let SendApprovalBatchValid = this.state.payroll_sendApproval_BatchName_NameValid;
    switch (fieldName) {
      case 'payroll_BatchName_Name':
        BatchNameValid = value.length > 0;
        payrollBatchesBatchNameErrors.payroll_BatchName_Name = BatchNameValid ? '' : 'This field is required';
        break;
      case 'payroll_sendApproval_BatchName_Name':
        SendApprovalBatchValid = value.length > 0;
        payrollSendApprovalBatchNameErrors.payroll_sendApproval_Name = SendApprovalBatchValid ? '' : 'This field is required';
        break;
      default:
        break;

    }
    this.setState({
      payroll_BatchName_NameValid: BatchNameValid,
      payrollBatchesBatchNameErrors: payrollBatchesBatchNameErrors,
      payroll_sendApproval_BatchName_NameValid: SendApprovalBatchValid,
      payrollSendApprovalBatchNameErrors: payrollSendApprovalBatchNameErrors
    })





  }
  PayRollSelectHandler = (e) => {


    if (e.target.selectedIndex > 0) {
      this.setState({
        paryRoll_resendStpFileSelectOpt: e.target.value,
        Is_paryRoll_resendStpFileOptSelected: true

      })

    }
    else if (e.target.selectedIndex === 0) {

      this.setState({
        paryRoll_resendStpFileSelectOpt: '',
        Is_paryRoll_resendStpFileOptSelected: false

      })
    }


  }
  SavePayrollBatch = async () => {
    let payrollBatchesBatchNameErrors = this.state.payrollBatchesBatchNameErrors;
    let payrollBatchName = this.state.payroll_BatchName_Name;
    if (!(this.state.payroll_BatchName_NameValid)) {
      payrollBatchesBatchNameErrors.payroll_BatchName_Name = 'This field is required!...';

    }

    this.setState({
      isLoading: true
    })

    this.setState({
      payrollBatchesBatchNameErrors: payrollBatchesBatchNameErrors
    })
    if (this.state.payroll_BatchName_Name !== '') {
      var completetenant = localStorage.getItem('completetenant');
      completetenant = JSON.parse(completetenant);
      var TphPayrollApi = completetenant.TphPayrollApi;



      var keys=localStorage.getItem('tempkeys');
var finalkeys = (keys&&keys!==null&&keys!==undefined)?JSON.parse(keys):undefined;
if(finalkeys!==undefined&&finalkeys.tempUsername&&
finalkeys.tempPassword){
      await fetch(TphPayrollApi.url + '/api/apiBATCH?username=' + finalkeys.tempUsername + '&password=' + finalkeys.tempPassword + '&description=' + payrollBatchName)
      .then(response => response.json()).then(async(response) => {
        
        if(response.apiBatch_response.Batch_List){

          document.getElementById("batchaddmodal").click();
          await this.batchclientapi();
        }else if(response.apiBatch_response.ServiceErrors[0].ERRORDESCRIPTION==="Userkey or Password invalid"||response.apiBatch_response.ServiceErrors[0].ERRORDESCRIPTION==="temp key expired"){
          var confirm=await getKeys();
          if(confirm===true){
          this.SavePayrollBatch();
         }
        }
         // toast.success('Batch Created Successfully')
         }).catch(error => { toast.error('error in created') });



        }else{
          var confirm=await getKeys();
          if(confirm===true){
          this.SavePayrollBatch();
         }
        }

    }

    this.setState({
      isLoading: false
    })

  }

  payRollSendApprvCont = () => {

    let payrollSendApprovalBatchNameErrors = this.state.payrollSendApprovalBatchNameErrors;
    if (!(this.state.payroll_sendApproval_BatchName_NameValid)) {
      payrollSendApprovalBatchNameErrors.payroll_sendApproval_Name = 'This field is required';
    }
    this.setState({
      payrollSendApprovalBatchNameErrors: payrollSendApprovalBatchNameErrors
    })

  }

  clearbatchstate = async () => {
    await this.setState({
      changeBatchDesc: '',
      payroll_BatchName_Name: '',
      isEditchangeBatchDesc: false
    })
  }


  AllPayrollTableChkBoxHandler = (chkboxName, isChecked) => {

    let AllSelected = (chkboxName === 'all' && isChecked);
    let allUnselected = (chkboxName === 'all' && !isChecked);
    const checked = isChecked;
    const checkList = this.state.payrollBatchesTableData.map((item, index) => {
      if (AllSelected || item.Guid === chkboxName) {
        return Object.assign({}, item, {
          checked,
        })
      }
      else if (allUnselected) {
        return Object.assign({}, item, {
          checked: false
        })
      }

      return item;
    })


    let isAllChecked = (checkList.findIndex((item) => item.checked === false) === -1) || AllSelected;
    this.setState({
      payrollBatchesTableData: checkList,
      isAllPayrollTableChkBoxSelected: isAllChecked

    })
  }
  onHref = e => {
    e.preventDefault();
  };
  setbatchtosend = async (no) => {
    await this.setState({
      tmp_batch_nmb: no
    })
  }
  SubmitLock = async () => {
    await this.setState({
      isLoading: true,
    })
    var completetenant = localStorage.getItem('completetenant');
    completetenant = JSON.parse(completetenant);
    var TphPayrollApi = completetenant.TphPayrollApi;

    var keys=localStorage.getItem('tempkeys');
var finalkeys = (keys&&keys!==null&&keys!==undefined)?JSON.parse(keys):undefined;
if(finalkeys!==undefined&&finalkeys.tempUsername&&
finalkeys.tempPassword){
    await fetch(TphPayrollApi.url + '/api/LockInvoice?username=' + finalkeys.tempUsername + '&password=' + finalkeys.tempPassword + '&batchnumber=' + this.state.tmp_batch_nmb + '', {
      headers: {
        Accept: 'application/json',
      }
    })
      .then(response => { return response.json(); }).then(async data => {
      if(data.LockInvoice_response.batch){
        await this.setState({
          tmp_batch_nmb: '',
         // isLoading: false,
        })
          await this.batchclientapi();
      }else if(data.LockInvoice_response.ServiceErrors[0].ERRORDESCRIPTION==="Userkey or Password invalid"||data.LockInvoice_response.ServiceErrors[0].ERRORDESCRIPTION==="temp key expired"){
        var confirm=await getKeys();
        if(confirm===true){
        this.SubmitLock();
       }
      }
       // toast.success('Lock And Invoice Completed');
       // toast.success('Refreshing the list...');
   
      })
      .catch(err => {
        toast.error('Lock And Invoice Not Completed')
      })

    }else{
      var confirm=await getKeys();
      if(confirm===true){
      this.SubmitLock();
     }
    }
    await this.setState({
      //tmp_batch_nmb: '',
      isLoading: false,
    })
  }

  SendReportsSubmit = async () => {
    await this.setState({
      isLoading: true,
    })
    var completetenant = localStorage.getItem('completetenant');
    completetenant = JSON.parse(completetenant);
    var TphPayrollApi = completetenant.TphPayrollApi;

    var keys=localStorage.getItem('tempkeys');
    var finalkeys = (keys&&keys!==null&&keys!==undefined)?JSON.parse(keys):undefined;
    if(finalkeys!==undefined&&finalkeys.tempUsername&&
    finalkeys.tempPassword){

    await fetch(TphPayrollApi.url + '/api/SendReports?username=' + finalkeys.tempUsername + '&password=' + finalkeys.tempPassword + '&batchnumber=' + this.state.tmp_batch_nmb + '', {
      headers: {
        Accept: 'application/json',
      }
    })
      .then(response => { return response.json(); }).then(async data => {
       // toast.success('Report Send Successfully');
       // toast.success('Refreshing the list...');

       if(!data.SendReports_response.ServiceErrors){
        await this.setState({
          tmp_batch_nmb: '',
          //isLoading: false,
        })
          await this.batchclientapi();
       }else if(data.SendReports_response.ServiceErrors[0].ERRORDESCRIPTION==="Userkey or Password invalid"||data.SendReports_response.ServiceErrors[0].ERRORDESCRIPTION==="temp key expired"){
        var confirm=await getKeys();
        if(confirm===true){
        this.SendReportsSubmit();
       }
       }
       

      })
      .catch(err => {
        toast.error('Report Not Send Successfully')
      })


    }else{
      var confirm=await getKeys();
   if(confirm===true){
   this.SendReportsSubmit();
  }}
    await this.setState({
     // tmp_batch_nmb: '',
      isLoading: false,
    })
  }

  SendEFTSTP = async () => {
    if (this.state.payroll_CreatSendEft_ChkBx) {
      document.getElementById('closeEFt').click();

      await this.setState({
        isLoading: true,
      })
      var completetenant = localStorage.getItem('completetenant');
      completetenant = JSON.parse(completetenant);
      var TphPayrollApi = completetenant.TphPayrollApi;
      var keys=localStorage.getItem('tempkeys');
      var finalkeys = (keys&&keys!==null&&keys!==undefined)?JSON.parse(keys):undefined;
      if(finalkeys!==undefined&&finalkeys.tempUsername&&
      finalkeys.tempPassword){

      await fetch(TphPayrollApi.url + '/api/SendEFTSTP?username=' + finalkeys.tempUsername + '&password=' + finalkeys.tempPassword + '&batchnumber=' + this.state.tmp_batch_nmb + '', {
        headers: {
          Accept: 'application/json',
        }
      })
        .then(response => { return response.json(); }).then(async data => {
          
       if(!data.SendEFTSTP_response.ServiceErrors){
        await this.setState({
          tmp_batch_nmb: '',
          //isLoading: false,
        })
          await this.batchclientapi();
       }else if(data.SendEFTSTP_response.ServiceErrors[0].ERRORDESCRIPTION==="Userkey or Password invalid"||data.SendEFTSTP_response.ServiceErrors[0].ERRORDESCRIPTION==="temp key expired"){
        var confirm=await getKeys();
        if(confirm===true){
        this.SendEFTSTP();
       }
       }
         // toast.success('EFT/STP Send Successfully');
         // toast.success('Refreshing the list...');
         
        })
        .catch(err => {
          toast.error('EFT/STP Not Send Successfully')
        })


      }else{
        var confirm=await getKeys();
     if(confirm===true){
     this.SendEFTSTP();
    }}


      await this.setState({
       // tmp_batch_nmb: '',
        isLoading: false,
      })
    } else {
      toast.error('Please Authorized First');
    }
  }
  Sendpayslipsemp = async () => {



    await this.setState({
      isLoading: true,
    })
    var completetenant = localStorage.getItem('completetenant');
    completetenant = JSON.parse(completetenant);
    var TphPayrollApi = completetenant.TphPayrollApi;


    var keys=localStorage.getItem('tempkeys');
    var finalkeys = (keys&&keys!==null&&keys!==undefined)?JSON.parse(keys):undefined;
    if(finalkeys!==undefined&&finalkeys.tempUsername&&
    finalkeys.tempPassword){

    await fetch(TphPayrollApi.url + '/api/SendPayslips?username=' + finalkeys.tempUsername + '&password=' + finalkeys.tempPassword + '&batchnumber=' + this.state.tmp_batch_nmb + '', {
      headers: {
        Accept: 'application/json',
      }
    })
      .then(response => { return response.json(); }).then(async data => {
       // toast.success('payslips Send Successfully');
       // toast.success('Refreshing the list...');
       if(!data.SendPayslips_response.ServiceErrors){
        await this.setState({
          tmp_batch_nmb: '',
          //isLoading: false,
        })
          await this.batchclientapi();
       }else if(data.SendPayslips_response.ServiceErrors[0].ERRORDESCRIPTION==="Userkey or Password invalid"||data.SendPayslips_response.ServiceErrors[0].ERRORDESCRIPTION==="temp key expired"){
        var confirm=await getKeys();
        if(confirm===true){
        this.Sendpayslipsemp();
       }
       }
      })
      .catch(err => {
        toast.error('payslips Not Send Successfully')
      })





    }else{
      var confirm=await getKeys();
   if(confirm===true){
   this.Sendpayslipsemp();
  }}
    await this.setState({
    //  tmp_batch_nmb: '',
      isLoading: false,
    })


  }

  PostandUploadData = async () => {



    await this.setState({
      isLoading: true,
    })
    var completetenant = localStorage.getItem('completetenant');
    completetenant = JSON.parse(completetenant);
    var TphPayrollApi = completetenant.TphPayrollApi;
    var keys=localStorage.getItem('tempkeys');
var finalkeys = (keys&&keys!==null&&keys!==undefined)?JSON.parse(keys):undefined;
if(finalkeys!==undefined&&finalkeys.tempUsername&&
finalkeys.tempPassword){
    await fetch(TphPayrollApi.url + '/api/PostandUploadData?username=' + finalkeys.tempUsername + '&password=' + finalkeys.tempPassword + '&batchnumber=' + this.state.tmp_batch_nmb + '', {
      headers: {
        Accept: 'application/json',
      }
    })
      .then(response => { return response.json(); }).then(async data => {
      //  toast.success('Post And Upload Data Send Successfully');
      //  toast.success('Refreshing the list...');
      if(!data.PostandUploadData_response.ServiceErrors){
        await this.setState({
          tmp_batch_nmb: '',
          //isLoading: false,
        })
          await this.batchclientapi();
       }else if(data.PostandUploadData_response.ServiceErrors[0].ERRORDESCRIPTION==="Userkey or Password invalid"||data.PostandUploadData_response.ServiceErrors[0].ERRORDESCRIPTION==="temp key expired"){
        var confirm=await getKeys();
        if(confirm===true){
        this.PostandUploadData();
       }
       }
      })
      .catch(err => {
        toast.error('Post And Upload Data Not Send Successfully')
      })
    }else{
      var confirm=await getKeys();
   if(confirm===true){
   this.PostandUploadData();
  }}
    await this.setState({
     // tmp_batch_nmb: '',
      isLoading: false,
    })


  }
  ResendSTP = async () => {
    if (this.state.Is_paryRoll_resendStpFileOptSelected && this.state.payrollBatches_resendStp_Verify) {
      document.getElementById('closeEFtresend').click();

      await this.setState({
        isLoading: true,
      })
      var completetenant = localStorage.getItem('completetenant');
      completetenant = JSON.parse(completetenant);
      var TphPayrollApi = completetenant.TphPayrollApi;


      var keys=localStorage.getItem('tempkeys');
var finalkeys = (keys&&keys!==null&&keys!==undefined)?JSON.parse(keys):undefined;
if(finalkeys!==undefined&&finalkeys.tempUsername&&
finalkeys.tempPassword){
      await fetch(TphPayrollApi.url + '/api/ResendSTP?username=' + finalkeys.tempUsername + '&password=' + finalkeys.tempPassword+ '&batchnumber=' + this.state.paryRoll_resendStpFileSelectOpt + '', {
        headers: {
          Accept: 'application/json',
        }
      })
        .then(response => { return response.json(); }).then(async data => {
          if(!data.ResendSTP_response.ServiceErrors){
            await this.setState({
              Is_paryRoll_resendStpFileOptSelected: '',
               //isLoading: false,
             })
              await this.batchclientapi();
           }else if(data.ResendSTP_response.ServiceErrors[0].ERRORDESCRIPTION==="Userkey or Password invalid"||data.ResendSTP_response.ServiceErrors[0].ERRORDESCRIPTION==="temp key expired"){
            var confirm=await getKeys();
            if(confirm===true){
            this.ResendSTP();
           }
           }
        
        })
        .catch(err => {
          toast.error('EFT/STP Not ReSend Successfully')
        })


      }else{
        var confirm=await getKeys();
     if(confirm===true){
     this.ResendSTP();
    }}


      await this.setState({
       // Is_paryRoll_resendStpFileOptSelected: '',
        isLoading: false,
      })
    } else {
      toast.error('Please Complete Form First');
    }
  }
  ReverseSuper = async () => {


    await this.setState({
      isLoading: true,
    })
    var completetenant = localStorage.getItem('completetenant');
    completetenant = JSON.parse(completetenant);
    var TphPayrollApi = completetenant.TphPayrollApi;

    var keys=localStorage.getItem('tempkeys');
    var finalkeys = (keys&&keys!==null&&keys!==undefined)?JSON.parse(keys):undefined;
    if(finalkeys!==undefined&&finalkeys.tempUsername&&
    finalkeys.tempPassword){

    await fetch(TphPayrollApi.url + '/api/ReverseSuper?username=' + finalkeys.tempUsername + '&password=' + finalkeys.tempPassword + '', {
      headers: {
        Accept: 'application/json',
      }
    })
      .then(response => { return response.json(); }).then(async data => {
       // toast.success(data.ReverseSuper_response.Description);
       if(!data.ReverseSuper_response.ServiceErrors){
      
       }else
     if(data.ReverseSuper_response.ServiceErrors[0].ERRORDESCRIPTION==="Userkey or Password invalid"||data.ReverseSuper_response.ServiceErrors[0].ERRORDESCRIPTION==="temp key expired"){
        var confirm=await getKeys();
        if(confirm===true){
        this.ReverseSuper();
       }
       }

      })
      .catch(err => {
        toast.error('Reverse Super Not Successfully')
      })




    }else{
      var confirm=await getKeys();
   if(confirm===true){
   this.ReverseSuper();
  }}
    await this.setState({

      isLoading: false,
    })


  }

  SendMonthEndReports = async () => {


    await this.setState({
      isLoading: true,
    })
    var completetenant = localStorage.getItem('completetenant');
    completetenant = JSON.parse(completetenant);
    var TphPayrollApi = completetenant.TphPayrollApi;

    var keys=localStorage.getItem('tempkeys');
    var finalkeys = (keys&&keys!==null&&keys!==undefined)?JSON.parse(keys):undefined;
    if(finalkeys!==undefined&&finalkeys.tempUsername&&
    finalkeys.tempPassword){

    await fetch(TphPayrollApi.url + '/api/SendMonthEndReports?username=' + finalkeys.tempUsername + '&password=' + finalkeys.tempPassword + '', {
      headers: {
        Accept: 'application/json',
      }
    })
      .then(response => { return response.json(); }).then(async data => {
      //  toast.success(data.SendMonthEndReports_response.Description);
      if(!data.SendMonthEndReports_response.ServiceErrors){
      
    }else
    if(data.SendMonthEndReports_response.ServiceErrors[0].ERRORDESCRIPTION==="Userkey or Password invalid"||data.SendMonthEndReports_response.ServiceErrors[0].ERRORDESCRIPTION==="temp key expired"){
       var confirm=await getKeys();
       if(confirm===true){
       this.SendMonthEndReports();
      }
      }

      })
      .catch(err => {
        toast.error('Monthly Reports Not Send Successfully')
      })

    }else{
      var confirm=await getKeys();
   if(confirm===true){
   this.SendMonthEndReports();
  }}


    await this.setState({

      isLoading: false,
    })


  }

  ReleasePayrollTax = async () => {


    await this.setState({
      isLoading: true,
    })
    var completetenant = localStorage.getItem('completetenant');
    completetenant = JSON.parse(completetenant);
    var TphPayrollApi = completetenant.TphPayrollApi;

    var keys=localStorage.getItem('tempkeys');
    var finalkeys = (keys&&keys!==null&&keys!==undefined)?JSON.parse(keys):undefined;
    if(finalkeys!==undefined&&finalkeys.tempUsername&&
    finalkeys.tempPassword){

    await fetch(TphPayrollApi.url + '/api/ReleasePayrollTax?username=' + finalkeys.tempUsername + '&password=' + finalkeys.tempPassword + '', {
      headers: {
        Accept: 'application/json',
      }
    })
      .then(response => { return response.json(); }).then(async data => {
      //  toast.success(data.ReleasePayrollTax_response.Description);
      if(!data.ReleasePayrollTax_response.ServiceErrors){
      
      }else
    if(data.ReleasePayrollTax_response.ServiceErrors[0].ERRORDESCRIPTION==="Userkey or Password invalid"||data.ReleasePayrollTax_response.ServiceErrors[0].ERRORDESCRIPTION==="temp key expired"){
       var confirm=await getKeys();
       if(confirm===true){
       this.ReleasePayrollTax();
      }
      }

      })
      .catch(err => {
        toast.error('Payroll tax not release successfully')
      })



    }else{
      var confirm=await getKeys();
   if(confirm===true){
   this.ReleasePayrollTax();
  }}
    await this.setState({

      isLoading: false,
    })


  }

  ReleaseSuper = async () => {


    await this.setState({
      isLoading: true,
    })
    var completetenant = localStorage.getItem('completetenant');
    completetenant = JSON.parse(completetenant);
    var TphPayrollApi = completetenant.TphPayrollApi;


    var keys=localStorage.getItem('tempkeys');
var finalkeys = (keys&&keys!==null&&keys!==undefined)?JSON.parse(keys):undefined;
if(finalkeys!==undefined&&finalkeys.tempUsername&&
finalkeys.tempPassword){
    await fetch(TphPayrollApi.url + '/api/ReleaseSuper?username=' +finalkeys.tempUsername + '&password=' + finalkeys.tempPassword+ '', {
      headers: {
        Accept: 'application/json',
      }
    })
      .then(response => { return response.json(); }).then(async data => {
      //  toast.success(data.ReleaseSuper_response.Description);
      if(!data.ReleaseSuper_response.ServiceErrors){
      
      }else
    if(data.ReleaseSuper_response.ServiceErrors[0].ERRORDESCRIPTION==="Userkey or Password invalid"||data.ReleaseSuper_response.ServiceErrors[0].ERRORDESCRIPTION==="temp key expired"){
       var confirm=await getKeys();
       if(confirm===true){
       this.ReleaseSuper();
      }
      }

      })
      .catch(err => {
        toast.error('Release Super not successfully')
      })
    }else{
      var confirm=await getKeys();
   if(confirm===true){
   this.ReleaseSuper();
  }}
    await this.setState({

      isLoading: false,
    })


  }

  SendYearEndReports = async () => {


    await this.setState({
      isLoading: true,
    })
    var completetenant = localStorage.getItem('completetenant');
    completetenant = JSON.parse(completetenant);
    var TphPayrollApi = completetenant.TphPayrollApi;

    var keys=localStorage.getItem('tempkeys');
    var finalkeys = (keys&&keys!==null&&keys!==undefined)?JSON.parse(keys):undefined;
    if(finalkeys!==undefined&&finalkeys.tempUsername&&
    finalkeys.tempPassword){

    await fetch(TphPayrollApi.url + '/api/SendYearEndReports?username=' + finalkeys.tempUsername + '&password=' + finalkeys.tempPassword + '', {
      headers: {
        Accept: 'application/json',
      }
    })
      .then(response => { return response.json(); }).then(async data => {
       // toast.success(data.SendYearEndReports_response.Description);
       if(!data.SendYearEndReports_response.ServiceErrors){
      
      }else
    if(data.SendYearEndReports_response.ServiceErrors[0].ERRORDESCRIPTION==="Userkey or Password invalid"||data.SendYearEndReports_response.ServiceErrors[0].ERRORDESCRIPTION==="temp key expired"){
       var confirm=await getKeys();
       if(confirm===true){
       this.SendYearEndReports();
      }
      }

      })
      .catch(err => {
        toast.error('Year End Reports not send successfully')
      })


    }else{
      var confirm=await getKeys();
   if(confirm===true){
   this.SendYearEndReports();
  }}
      

    await this.setState({

      isLoading: false,
    })


  }
  yearendSTP = async () => {

    await this.setState({
      isLoading: true,
    })
    var completetenant = localStorage.getItem('completetenant');
    completetenant = JSON.parse(completetenant);
    var TphPayrollApi = completetenant.TphPayrollApi;

    var keys=localStorage.getItem('tempkeys');
    var finalkeys = (keys&&keys!==null&&keys!==undefined)?JSON.parse(keys):undefined;
    if(finalkeys!==undefined&&finalkeys.tempUsername&&
    finalkeys.tempPassword){

 
    await fetch(TphPayrollApi.url + '/api/CalculateYearEnd?username=' +finalkeys.tempUsername + '&password=' + finalkeys.tempPassword + '', {
      headers: {
        Accept: 'application/json',
      }
    })
      .then(response => { return response.json(); }).then(async(data) => {
       // toast.success('successfully calculated');
       

        if(!data.CalculateYearEnd_response.ServiceErrors){
          this.setState({
            endyeardata: data.CalculateYearEnd_response.CalcYearEnd
          })
        }else
      if(data.CalculateYearEnd_response.ServiceErrors[0].ERRORDESCRIPTION==="Userkey or Password invalid"||data.CalculateYearEnd_response.ServiceErrors[0].ERRORDESCRIPTION==="temp key expired"){
         var confirm=await getKeys();
         if(confirm===true){
         this.yearendSTP();
        }
        }
      })
      .catch(err => {
        toast.error('Year End Reports not send successfully')
      })


    }else{
      var confirm=await getKeys();
   if(confirm===true){
   this.yearendSTP();
  }}


    await this.setState({

      isLoading: false,
    })

  }
  yearendSTPsend = async () => {
    if (this.state.payroll_YearEndStp_Verify_Chkbx && this.state.payroll_YearEndStp_amendment_Chkbx) {
      await this.setState({
        isLoading: true,
      })
      var completetenant = localStorage.getItem('completetenant');
      completetenant = JSON.parse(completetenant);
      var TphPayrollApi = completetenant.TphPayrollApi;

      var keys=localStorage.getItem('tempkeys');
      var finalkeys = (keys&&keys!==null&&keys!==undefined)?JSON.parse(keys):undefined;
      if(finalkeys!==undefined&&finalkeys.tempUsername&&
      finalkeys.tempPassword){
      //api call


      await fetch(TphPayrollApi.url + '/api/SendYearEndSTP?username=' + finalkeys.tempUsername + '&password=' +   finalkeys.tempPassword+ '', {
        headers: {
          Accept: 'application/json',
        }
      })
        .then(response => { return response.json(); }).then(async data => {
         // toast.success(data.SendYearEndSTP_response.Description);
         if(!data.SendYearEndSTP_response.ServiceErrors){
      
        }else
      if(data.SendYearEndSTP_response.ServiceErrors[0].ERRORDESCRIPTION==="Userkey or Password invalid"||data.SendYearEndSTP_response.ServiceErrors[0].ERRORDESCRIPTION==="temp key expired"){
         var confirm=await getKeys();
         if(confirm===true){
         this.yearendSTPsend();
        }
        }
  

        })
        .catch(err => {
          toast.error('Year End STP not send successfully')
        })


      }else{
        var confirm=await getKeys();
     if(confirm===true){
     this.yearendSTPsend();
    }}

      await this.setState({

        isLoading: false,
      })


    } else {
      toast.error('Please select Checkbox first.');
    }
  }
  edit_batch = async (descr, id, lock) => {
    this.props.history.push('/payroll_batches_edit', { data: id, dec: descr, lock: lock });
  }
  get_records_send = () => {

  }
  render() {
    return (
      <div>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ''}

        <Header1
          pageHeader="Payroll Batches"
          childProps={this.props.childProps}
        />
        <div className="col-lg-10 col-lg-offset-1 mrg_top_payroll">
          <div className="col-sm-6 p0">
            <div className="search_area_payroll">
              <input
                id="payrollBatches_search"
                name="payrollBatches_search"
                type="text"
                className="search_payroll"
                value={this.state.payrollBatches_search}
                placeholder={"Search By " + this.state.PayrollBatchesSelectedTab}
                onChange={this.payrollBatches_SearchHandler}
              />

              <img src={ic_search} width="20" height="20" alt="" />
            </div>
          </div>

          <div className="col-sm-6 p0">
            <div className="dropdown menu_icon_payroll ">
              <button
                className="dropdown-toggle btn_payroll_menu"
                type="button"
                data-toggle="dropdown"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="5.223"
                  height="20.893"
                  viewBox="0 0 5.223 20.893"
                >
                  <path
                    id="ic_more_horiz_24px"
                    d="M6.612,10a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,6.612,10Zm15.669,0a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,22.281,10Zm-7.835,0a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,14.446,10Z"
                    transform="translate(15.223 -4) rotate(90)"
                    fill="#7b7b7b"
                  />
                </svg>
              </button>
              <ul className="dropdown-menu dropleft dropdown_menu_users_down dropdown_menu_payroll_1 resend_top_dropp">
                <li>
                  < a href={null} data-toggle="modal" className="cursorPointer" data-target="#resend_stp">
                    <img
                      src={send_icon}
                      className="mrg_sendicon"
                      width="19"
                      height="13"
                      alt=""
                    />
                    <span> </span>
                    Resend STP File
                  </a>
                </li>
                <li>
                  <a
                    href={null} className="cursorPointer"
                    data-toggle="modal"
                    data-target="#reverse_super_under"
                  >
                    <img
                      src={reverse_icon}
                      className="mrg_sendicon"
                      width="15"
                      height="16"
                      alt=""
                    />
                    <span> </span>
                    Reverse Super Under threshold
                  </a>
                </li>

                <li>
                  <a
                    href={null}
                    data-toggle="modal"
                    data-target="#send_month"
                    className="lock_invoice_icon cursorPointer"
                  >
                    <img
                      src={send_reports}
                      className="mrg_sendicon"
                      width="18"
                      height="13"
                      alt=""
                    />
                    <span> </span>
                    Send Month End Reports
                  </a>
                </li>

                <li>
                  <a
                    href={null}
                    data-toggle="modal"
                    data-target="#release_payroll"
                    className="create_ftp_icon cursorPointer"
                  >
                    <img
                      src={relese_icon}
                      className="mrg_sendicon"
                      width="16"
                      height="15"
                      alt=""
                    />
                    <span> </span>
                    Release Payroll Tax Payments
                  </a>
                </li>

                <li>
                  <a
                    href={null} className="cursorPointer"
                    data-toggle="modal"
                    data-target="#release_super_payment"
                  >
                    <img
                      src={relese_icon2}
                      className="mrg_sendicon"
                      width="16"
                      height="15"
                      alt=""
                    />
                    <span> </span>
                    Release Super Payments
                  </a>
                </li>

                <li>
                  <a
                    href={null} className="cursorPointer"
                    data-toggle="modal"
                    data-target="#year_end_report"
                  >
                    <img
                      src={send_year_report}
                      className="mrg_sendicon"
                      width="13"
                      height="15"
                      alt=""
                    />
                    <span> </span>
                    Send Year End Reports
                  </a>
                </li>
                <li>
                  < a href={null} onClick={this.yearendSTP} data-toggle="modal" data-target="#year_end_stp" className="cursorPointer">
                    <img
                      src={dollar_icon}
                      className="icon_post"
                      width="8"
                      height="16"
                      alt=""
                    />
                    <span> </span>
                    Send Year End STP File
                  </a>
                </li>
              </ul>
            </div>

            < a href={null} onClick={this.batchclientapi} id="freshbtn" className="refresh_icon_payroll cursorPointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24.224"
                height="17.617"
                viewBox="0 0 24.224 17.617"
              >
                <path
                  id="ic_cached_24px"
                  d="M20.819,8.4l-4.4,4.4h3.3a6.612,6.612,0,0,1-6.606,6.606,6.464,6.464,0,0,1-3.083-.771L8.421,20.252a8.793,8.793,0,0,0,13.5-7.443h3.3Zm-14.314,4.4A6.612,6.612,0,0,1,13.112,6.2a6.464,6.464,0,0,1,3.083.771L17.8,5.365A8.793,8.793,0,0,0,4.3,12.809H1l4.4,4.4,4.4-4.4Z"
                  transform="translate(-1 -4)"
                  fill="#7B7B7B"
                />
              </svg>
            </a>

            <a
              href={null}
              className="plus_icon_payroll cursorPointer"
              data-toggle="modal"
              data-target="#myModal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
              >
                <path
                  id="ic_add_24px"
                  d="M20,13.571H13.571V20H11.429V13.571H5V11.429h6.429V5h2.143v6.429H20Z"
                  transform="translate(-5 -5)"
                  fill="#7B7B7B"
                />
              </svg>
            </a>
          </div>

          <div className="clear10" />

          <div className="col-sm-12 p0">
            <div className="overflow_scroll_sm">
              <table
                width="100%"
                className="table table-bordered timecard_table payroll_batches_table"
              >
                <thead>
                  <tr className="table_header_payroll">
                    <th width="3%" align="left">
                      <input name="payRoll_AllItemsAllChkBox" type="checkbox" checked={this.state.isAllPayrollTableChkBoxSelected} onChange={(e) => this.AllPayrollTableChkBoxHandler('all', e.target.checked)} />
                    </th>
                    <th width="10%" align="left">
                      <div className="pull-left" onClick={() => this.sort_payrollBatches('Batch', 'batchCaret')}> Batch
                      <div className="batch_downarrow">
                          <svg id="batchCaret" className={!this.state.sort_batchToggle ? "toggleCaretClass" : ''}
                            xmlns="http://www.w3.org/2000/svg"
                            width="12.337"
                            height="6.887"
                            viewBox="0 0 12.337 6.887"
                          >
                            <path
                              id="ic_arrow_drop_down_24px"
                              d="M7,10l6.169,6.887L19.337,10Z"
                              transform="translate(-7 -10)"
                              fill="#30679a"
                            />
                          </svg>
                        </div>

                      </div>

                    </th>
                    <th width="15%" align="left">
                      <div className="pull-left" onClick={() => this.sort_payrollBatches('Status', 'StatusCaret')}> Status
<div className="batch_downarrow">
                          <svg id="StatusCaret" className={!this.state.sort_StatusToggle ? "toggleCaretClass" : ''}
                            xmlns="http://www.w3.org/2000/svg"
                            width="12.337"
                            height="6.887"
                            viewBox="0 0 12.337 6.887"
                          >
                            <path
                              id="ic_arrow_drop_down_24px"
                              d="M7,10l6.169,6.887L19.337,10Z"
                              transform="translate(-7 -10)"
                              fill="#30679a"
                            />
                          </svg>
                        </div>

                      </div>
                    </th>
                    <th width="25%" align="left">
                      <div className="pull-left" onClick={() => this.sort_payrollBatches('Description', 'DescCaret')}> Description
<div className="batch_downarrow">
                          <svg id="DescCaret" className={!this.state.sort_DescToggle ? "toggleCaretClass" : ''}
                            xmlns="http://www.w3.org/2000/svg"
                            width="12.337"
                            height="6.887"
                            viewBox="0 0 12.337 6.887"
                          >
                            <path
                              id="ic_arrow_drop_down_24px"
                              d="M7,10l6.169,6.887L19.337,10Z"
                              transform="translate(-7 -10)"
                              fill="#30679a"
                            />
                          </svg>
                        </div>

                      </div>


                    </th>
                    <th width="28%" align="left">
                      <div className="pull-left" onClick={() => this.sort_payrollBatches('Notes', 'notesCaret')}> Notes
                      <div className="batch_downarrow">
                          <svg id="notesCaret" className={!this.state.sort_notesToggle ? "toggleCaretClass" : ''}
                            xmlns="http://www.w3.org/2000/svg"
                            width="12.337"
                            height="6.887"
                            viewBox="0 0 12.337 6.887"
                          >
                            <path
                              id="ic_arrow_drop_down_24px"
                              d="M7,10l6.169,6.887L19.337,10Z"
                              transform="translate(-7 -10)"
                              fill="#30679a"
                            />
                          </svg>
                        </div>

                      </div>

                    </th>
                    <th width="10%" align="left">
                      &nbsp;
                    </th>
                  </tr>
                </thead>
                <tbody>


                  {this.state.payrollBatchesTableData.map(item => {

                    return (
                      <tr key={item.Guid}>
                        <td align="left">
                          <input name={item.chkbName} type="checkbox" checked={item.checked} onChange={(e) => this.AllPayrollTableChkBoxHandler(item.Guid, e.target.checked)} />
                        </td>
                        <td align="left">{item.Batch}</td>
                        <td align="left">{item.Lock}</td>
                        <td align="left">{item.Desc}</td>
                        <td align="left">{item.Notes == 'pay17' ? '' : item.Notes}</td>
                        <td align="left">
                          <div className="dropdown menu_icon_payroll dropdown_click_leftttttt">
                            <button
                              className="dropdown-toggle btn_payroll_menu"
                              type="button"
                              data-toggle="dropdown"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="4"
                                viewBox="0 0 16 4"
                              >
                                <path
                                  id="ic_more_horiz_24px"
                                  d="M6,10a2,2,0,1,0,2,2A2.006,2.006,0,0,0,6,10Zm12,0a2,2,0,1,0,2,2A2.006,2.006,0,0,0,18,10Zm-6,0a2,2,0,1,0,2,2A2.006,2.006,0,0,0,12,10Z"
                                  transform="translate(-4 -10)"
                                  fill="#7B7B7B"
                                />
                              </svg>
                            </button>
                            <ul className="dropdown-menu dropleft dropdown_menu_users_down dropdown_menu_payroll_1 chng_drpdwn_aisy">
                              <li>
                                <a
                                  href={null}
                                  onClick={this.editBatch.bind(this, item)}
                                  data-toggle="modal"
                                  data-target="#myModal"
                                  className="batch_icon cursorPointer"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                  >
                                    <path
                                      id="ic_create_24px"
                                      className="cls-1"
                                      d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z"
                                      transform="translate(-3 -2.998)"
                                    />
                                  </svg>
                                  <span> </span>
                                  Change Batch Description
          </a>
                              </li>

                              <li>
                                <a
                                  href={null} className="cursorPointer"
                                  data-toggle="modal"
                                  data-target="#send_approval"
                                >
                                  <img
                                    src={send_icon}
                                    className="mrg_sendicon"
                                    width="19"
                                    height="13"
                                    alt=""
                                  />
                                  <span> </span>
                                  Send for Approval{" "}
                                </a>
                              </li>
                              <li>
                                <a
                                  href={null} className="cursorPointer"
                                  onClick={this.setbatchtosend.bind(this, item.Batch)}
                                  data-toggle="modal"
                                  data-target="#batch_reports"
                                >
                                  <img
                                    src={send_reports}
                                    className="mrg_sendicon"
                                    width="17"
                                    height="11"
                                    alt=""
                                  />
                                  <span> </span>
                                  Send Reports
                                </a>
                              </li>

                              <li>
                                <a
                                  href={null}
                                  onClick={this.setbatchtosend.bind(this, item.Batch)}
                                  data-toggle="modal"
                                  data-target="#lock_invoice"
                                  className="lock_invoice_icon cursorPointer"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M18 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-3zm-10 0v-4c0-2.206 1.794-4 4-4s4 1.794 4 4v4h-8z" />
                                  </svg>
                                  <span> </span>
                                  Lock & Invoice
          </a>
                              </li>

                              <li>
                                <a
                                  href={null}
                                  onClick={this.setbatchtosend.bind(this, item.Batch)}
                                  data-toggle="modal"
                                  data-target="#creat_send"
                                  className="create_ftp_icon cursorPointer"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14.455"
                                    height="11.563"
                                    viewBox="0 0 14.455 11.563"
                                  >
                                    <path
                                      id="ic_folder_open_24px"
                                      d="M15.009,5.445H9.227L7.782,4H3.445A1.444,1.444,0,0,0,2.007,5.445L2,14.118a1.45,1.45,0,0,0,1.445,1.445H15.009a1.45,1.45,0,0,0,1.445-1.445V6.891A1.45,1.45,0,0,0,15.009,5.445Zm0,8.673H3.445V6.891H15.009Z"
                                      transform="translate(-2 -4)"
                                      fill="#86878a"
                                    />
                                  </svg>
                                  <span> </span>
                                  Create & Send EFT/STP Files
          </a>
                              </li>

                              <li>
                                <a
                                  href={null}
                                  onClick={this.setbatchtosend.bind(this, item.Batch)}
                                  data-toggle="modal"
                                  data-target="#send_payslip_emp "
                                  className="cursorPointer"
                                >
                                  <img
                                    src={dollar_icon}
                                    className="img_dollar_icon"
                                    width="9"
                                    height="18"
                                    alt=""
                                  />
                                  <span> </span>
                                  Send Payslip to Employees
          </a>
                              </li>

                              <li>
                                <a
                                  href={null}
                                  onClick={this.setbatchtosend.bind(this, item.Batch)}
                                  className="cursorPointer"
                                  data-toggle="modal"
                                  data-target="#post_upload"
                                >
                                  <img
                                    src={post_icon}
                                    className="icon_post"
                                    width="14"
                                    height="11"
                                    alt=""
                                  />
                                  <span> </span>
                                  Post & Upload Data
                                </a>
                              </li>
                            </ul>
                          </div>




                          <a
                            href={null}
                            onClick={this.edit_batch.bind(this, item.Desc, item.Batch, item.Lock)}

                            className="svg_pullleft_edit svgg_lefft_mrg"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path
                                id="ic_create_24px"
                                className="cls-1"
                                d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z"
                                transform="translate(-3 -2.998)"
                              />
                            </svg>
                            <span> </span>
                          </a>
                        </td>
                      </tr>
                    )





                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>


        <div id="myModal" className="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
          <div className="modal-dialog modal_middle  modal-dialog-centered ">

            <div className="modal-content modal_middle_h">
              <div className="modal-header">
                <button
                  onClick={this.clearbatchstate}
                  type="button"
                  className="close close_modal_payroll"
                  data-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title payroll_modal_header">Batch Name</h4>
              </div>
              <div className="modal-body">
                <div className="col-sm-12 payroll_input_pop1">
                  <input
                    id="payroll_BatchName_Name"
                    name="payroll_BatchName_Name"
                    type="text"
                    value={this.state.payroll_BatchName_Name}
                    placeholder="Name" onChange={this.handleInputsPayroll}
                  />
                  <p className={"error validation " + (this.state.payrollBatchesBatchNameErrors.payroll_BatchName_Name ? '' : 'displayNone')}>{this.state.payrollBatchesBatchNameErrors.payroll_BatchName_Name}</p>
                </div>

                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    {this.state.isEditchangeBatchDesc ? <a
                      href={null}
                      className="btn_save_analytics cursorPointer"
                      onClick={this.UpdatePayrollBatch}
                    >
                      Update
                    </a> : <a
                        href={null}
                        className="btn_save_analytics cursorPointer"
                        onClick={this.SavePayrollBatch}
                      >
                        Save
                    </a>}
                    <a
                      onClick={this.clearbatchstate}
                      href={null}
                      className="btn_cancel_analytics cursorPointer"
                      data-dismiss="modal"
                      id="batchaddmodal"
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10" />
              </div>
            </div>
          </div>
        </div>

        <div id="batch_reports" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle modal-dialog-centered">

            <div className="modal-content modal_middle_h_batchreport ">
              <div className="modal-header">
                <button
                  type="button"
                  className="close close_modal_payroll"
                  data-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title payroll_modal_header">
                  Batch Reports
                </h4>
              </div>
              <div className="modal-body">
                <div className="col-sm-12 payroll_rpoerts_label">
                  Please check your messages for the report.
                  <br />
                  This may take a few minutes.
                </div>

                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    <a
                      href={null}
                      onClick={this.SendReportsSubmit}
                      className="btn_save_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Continue
                    </a>
                    <a
                      href={null}
                      className="btn_cancel_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10" />
              </div>
            </div>
          </div>
        </div>

        <div id="send_approval" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle">


            <div className="modal-content modal_middle_h_batchreport">
              <div className="modal-header">
                <button type="button" className="close close_modal_payroll" data-dismiss="modal">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B" />
                  </svg>

                </button>
                <h4 className="modal-title payroll_modal_header">Send for Approval</h4>
              </div>
              <div className="modal-body">

                <div className="col-sm-12 payroll_rpoerts_label">
                  <input type="text" name="payrollEdit_sendApprovalTable_btnInput"
                    value="" className="form-control"
                    placeholder="10 Timecards selected to be sent for approval"
                  />




                </div>



                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    < a href={null} className="btn_save_analytics cursorPointer" onClick={this.get_records_send}>Start</a>
                    < a href={null} className="btn_cancel_analytics cursorPointer" id="close_btn_tc" data-dismiss="modal">Cancel</a>

                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10"></div>

              </div>

            </div>

          </div>
        </div>

        <div id="lock_invoice" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle modal-dialog-centered">

            <div className="modal-content modal_middle_h_lock_invoice">
              <div className="modal-header">
                <button
                  type="button"
                  className="close close_modal_payroll"
                  data-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title payroll_modal_header">
                  Lock and Invoice
                </h4>
              </div>
              <div className="modal-body">
                <div className="col-sm-12 payroll_rpoerts_label">
                  Please check your messages for the invoice. You will not be
                  able to create and send EFT/STP files until the invoice has
                  been paid in full.
                </div>

                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    <a
                      href={null}
                      onClick={this.SubmitLock}
                      className="btn_save_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Continue
                    </a>
                    <a
                      href={null}
                      className="btn_cancel_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10" />
              </div>
            </div>
          </div>
        </div>

        <div id="creat_send" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle">

            <div className="modal-content modal_middle_h_create_send payrol_pg_color">
              <div className="modal-header">
                <button
                  type="button"
                  className="close close_modal_payroll"
                  data-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title payroll_modal_header">
                  Create & Send EFT/STP Files
                </h4>
              </div>
              <div className="modal-body modal_send_scroll">
                <div className="col-sm-12 payroll_create_label_label">
                  <strong className="color_send_bold">
                    Once you authorize to send these files the process cannot be
                    stopped or reversed.
                  </strong>
                  <div className="clear20" />
                  <span className="tick_pg_colr">
                    {" "}
                    I am notifying the ATO that:
                  </span>{" "}
                  <br />{" "}
                  <span className="wf1 tick_pg_colr">
                    {" "}
                    -
                  </span>{" "}
                  <span className="wf2 tick_pg_colr">
                    {" "}
                    Threadgold Plummer Hood Pty Ltd provides my business with
                    transaction service, and{" "}
                  </span>{" "}
                  <br />{" "}
                  <span className="wf1 tick_pg_colr">
                    {" "}
                    -
                  </span>{" "}
                  <span className="wf2 tick_pg_colr">
                    {" "}
                    My business, for the purposes of its transactions with ATO
                    via the SBR channel, sends (and receives) those transactions
                    to (and from) the ATO via QVALENT PTY LTD.{" "}
                  </span>
                  <br />
                  <span className="tick_pg_colr">
                    {" "}
                    I declare the information transmitted in this payroll to be
                    true and correct and I am authorized to make this
                    declaration.
                  </span>
                  <div className="clear20" />
                  <div className="checkbox_send_pop">
                    <div className="checkbox">
                      <input id="checkbox34" type="checkbox" name="payroll_CreatSendEft_ChkBx" checked={this.state.payroll_CreatSendEft_ChkBx} onChange={this.handleInputsPayroll} />
                      <label htmlFor="checkbox34" />
                    </div>
                  </div>
                  <div className="pull-left col-xs-11 p0 color_bg_send_bot tick_pg">
                    <span className="tick_pg_colr">
                      Tick this box to sign the declaration with the credentials
                      used to login and to authorize lodgement with QVALENT PTY
                      LTD's AUSkey.
                    </span>
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    <a
                      href={null}
                      onClick={this.SendEFTSTP}
                      className="btn_save_analytics_setting cursorPointer"
                      disabled={!this.state.payroll_CreatSendEft_ChkBx}
                    >
                      Authorize
                    </a>
                    <a
                      href={null}
                      id='closeEFt'
                      className="btn_cancel_analytics_setting cursorPointer"
                      data-dismiss="modal"
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10" />
              </div>
            </div>
          </div>
        </div>
        <div id="send_payslip_emp" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle modal-dialog-centered">

            <div className="modal-content payroll_modal_payslip_emp">
              <div className="modal-header">
                <button
                  type="button"
                  className="close close_modal_payroll"
                  data-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title payroll_modal_header">
                  Send Payslips to employees
                </h4>
              </div>
              <div className="modal-body">
                <div className="col-sm-12 payroll_rpoerts_label">
                  Payslips are being sent out now.
                </div>

                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    <a
                      href={null}
                      onClick={this.Sendpayslipsemp}
                      className="btn_save_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Continue
                    </a>
                    <a
                      href={null}

                      className="btn_cancel_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10" />
              </div>
            </div>
          </div>
        </div>

        <div id="post_upload" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle modal-dialog-centered">

            <div className="modal-content payroll_modal_post_upload">
              <div className="modal-header">
                <button
                  type="button"
                  className="close close_modal_payroll"
                  data-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title payroll_modal_header">
                  Post and Upload Data
                </h4>
              </div>
              <div className="modal-body">
                <div className="col-sm-12 payroll_rpoerts_label">
                  The batch will be posted and the upload file sent to your
                  messages. This may take a few minutes.
                </div>

                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    <a
                      href={null}
                      onClick={this.PostandUploadData}
                      className="btn_save_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Continue
                    </a>
                    <a
                      href={null}
                      className="btn_cancel_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10" />
              </div>
            </div>
          </div>
        </div>

        <div id="resend_stp" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle">

            <div className="modal-content payroll_modal_resend_stp">
              <div className="modal-header">
                <button
                  type="button"
                  className="close close_modal_payroll"
                  data-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title payroll_modal_header">
                  Resend STP File
                </h4>
              </div>
              <div className="modal-body">
                <div className="col-sm-12 payroll_resend_stp tick_pg_colr">
                  <div className="col-xs-5 col-sm-3 text-left p0">Batch:</div>
                  <div className="col-xs-7 col-sm-9">
                    <select name="paryRoll_resendStpFile" id="paryRoll_resendStpFileSelectOpt" className="dropdown_send_stp dropdownHeight" onChange={this.PayRollSelectHandler}>
                      <option>Please Select one Option</option>
                      {this.state.payrollBatchesTableData.map(item => {
                        return (
                          <option key={item.Batch}>{item.Batch}</option>
                        )
                      })}

                    </select>
                  </div>
                  <div className="clear20" />
                  <div className="col-xs-5 col-sm-3 text-left p0">
                    Amendment:
                  </div>
                  <div className="col-xs-7 col-sm-9 text-left">
                    <div className="checkbox">
                      <input id="checkbox1777" type="checkbox" checked={this.state.Is_payRoll_resendStp_amendment} name="Is_payRoll_resendStp_amendment" onChange={this.handleInputsPayroll} />
                      <label htmlFor="checkbox1777" />
                    </div>
                  </div>
                  <div className="clear20" />
                  <strong className="color_send_bold">
                    Once you authorize to send these files the process cannot be
                    stopped or reversed.
                  </strong>
                  <div className="clear20" />
                  <span> I am notifying the ATO that:</span> <br />{" "}
                  <span className="wf1"> -</span>{" "}
                  <span className="wf2">
                    Threadgold Plummer Hood Pty Ltd provides my business with
                    transaction service, and{" "}
                  </span>{" "}
                  <br /> <span className="wf1"> -</span>{" "}
                  <span className="wf2">
                    {" "}
                    My business, for the purposes of its transactions with ATO
                    via the SBR channel, sends (and receives) those transactions
                    to (and from) the ATO via QVALENT PTY LTD.{" "}
                  </span>
                  <br /> I declare the information transmitted in this payroll
                  to be true and correct and I am authorized to make this
                  declaration.
                  <div className="clear10" />
                  <div className="checkbox_send_pop">
                    <div className="checkbox">
                      <input id="checkbox31" type="checkbox" name="payrollBatches_resendStp_Verify" checked={this.state.payrollBatches_resendStp_Verify} onChange={this.handleInputsPayroll} />
                      <label htmlFor="checkbox31" />
                    </div>
                  </div>
                  <div className="pull-left col-xs-11 p0 color_bg_send_bot tick_pg">
                    <span>
                      {" "}
                      Tick this box to sign the declaration with the credentials
                      used to login and to authorize lodgement with QVALENT PTY
                      LTD's AUSkey.
                    </span>
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    < a href={null}
                      className="btn_save_analytics_setting cursorPointer"
                      disabled={!this.state.Is_paryRoll_resendStpFileOptSelected || !this.state.payrollBatches_resendStp_Verify}
                      onClick={this.ResendSTP}>
                      Authorize
                    </a>
                    <a
                      href={null}
                      id='closeEFtresend'
                      className="btn_cancel_analytics_setting cursorPointer"
                      data-dismiss="modal"
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10" />
              </div>
            </div>
          </div>
        </div>

        <div id="reverse_super_under" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle modal-dialog-centered modal_no_scrollllll">

            <div className="modal-content payroll_modal_reverse_superunder modal_payroll_reverseee">
              <div className="modal-header">
                <button
                  type="button"
                  className="close close_modal_payroll"
                  data-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title payroll_modal_header">
                  Reverse Super Under the Threshold
                </h4>
              </div>
              <div className="modal-body overflow_scroll_sm_pop">
                <div className="col-sm-12 payroll_rpoerts_label">
                  A new batch will be created with all employee's under the
                  super threshold. This may take a few minutes and when it is
                  complete we will send you a message.
                </div>

                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    <a
                      href={null}
                      onClick={this.ReverseSuper}
                      className="btn_save_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Continue
                    </a>
                    <a
                      href={null}
                      className="btn_cancel_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10" />
              </div>
            </div>
          </div>
        </div>

        <div id="send_month" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle modal_no_scrolllll modal-dialog-centered">

            <div className="modal-content payroll_modal_reverse_superunder modal_payroll_reverseee">
              <div className="modal-header">
                <button
                  type="button"
                  className="close close_modal_payroll"
                  data-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title payroll_modal_header">
                  Send Month End Reports
                </h4>
              </div>
              <div className="modal-body">
                <div className="col-sm-12 payroll_rpoerts_label">
                  These reports will be sent to your messages. This will take a
                  few minutes to generate.
                </div>

                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    <a
                      href={null}
                      onClick={this.SendMonthEndReports}
                      className="btn_save_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Continue
                    </a>
                    <a
                      href={null}
                      className="btn_cancel_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10" />
              </div>
            </div>
          </div>
        </div>

        <div id="release_payroll" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle modal_no_scrolllll modal-dialog-centered">

            <div className="modal-content payroll_modal_reverse_superunder modal_payroll_reverseee">
              <div className="modal-header">
                <button
                  type="button"
                  className="close close_modal_payroll"
                  data-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title payroll_modal_header">
                  Release Payroll Tax Payments
                </h4>
              </div>
              <div className="modal-body">
                <div className="col-sm-12 payroll_rpoerts_label">
                  Release Payroll tax payment by the due date based on month end
                  reports amount.
                </div>

                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    <a
                      href={null}
                      onClick={this.ReleasePayrollTax}
                      className="btn_save_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Continue
                    </a>
                    <a
                      href={null}
                      className="btn_cancel_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10" />
              </div>
            </div>
          </div>
        </div>

        <div id="release_super_payment" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle modal_no_scrolllll modal-dialog-centered">

            <div className="modal-content payroll_modal_reverse_superunder modal_payroll_reverseee">
              <div className="modal-header">
                <button
                  type="button"
                  className="close close_modal_payroll"
                  data-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title payroll_modal_header">
                  Release Super Payments
                </h4>
              </div>
              <div className="modal-body">
                <div className="col-sm-12 payroll_rpoerts_label">
                  Release Super payments by the due date based on month end
                  reports amounts.
                </div>

                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    <a
                      onClick={this.ReleaseSuper}
                      href={null}
                      className="btn_save_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Continue
                    </a>
                    <a
                      href={null}
                      className="btn_cancel_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10" />
              </div>
            </div>
          </div>
        </div>

        <div id="year_end_report" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle modal_no_scrolllll modal-dialog-centered">

            <div className="modal-content payroll_modal_reverse_superunder modal_payroll_reverseee">
              <div className="modal-header">
                <button
                  type="button"
                  className="close close_modal_payroll"
                  data-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title payroll_modal_header">
                  Send Year End Reports
                </h4>
              </div>
              <div className="modal-body">
                <div className="col-sm-12 payroll_rpoerts_label">
                  These reports will be sent to your messages. This will take a
                  few minutes to generate.
                </div>

                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    <a
                      onClick={this.SendYearEndReports}
                      href={null}
                      className="btn_save_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Continue
                    </a>
                    <a
                      href={null}
                      className="btn_cancel_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10" />
              </div>
            </div>
          </div>
        </div>

        <div id="year_end_stp" className="modal fade" role="dialog">
          <div className="modal-dialog">

            <div className="modal-content ">
              <div className="modal-header">
                <button
                  type="button"
                  className="close close_modal_payroll"
                  data-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title payroll_modal_header">
                  Send Year End STP File
                </h4>
              </div>
              <div className="modal-body">
                <div className="col-sm-12 end_yearstp_pop1">
                  <div className="col-xs-5 col-sm-3 p0 text-left">
                    Year Ending:
                  </div>
                  <div className="col-xs-6  text-left">31/12/2018</div>
                  <div className="clear20" />
                  <div className="col-xs-5 col-sm-3 p0 text-left">
                    Amendment:
                  </div>
                  <div className="col-xs-6 text-left">
                    <div className="checkbox">
                      <input id="checkbox32" type="checkbox"
                        name="payroll_YearEndStp_amendment_Chkbx"
                        checked={this.state.payroll_YearEndStp_amendment_Chkbx}
                        onChange={this.handleInputsPayroll} />
                      <label htmlFor="checkbox32" />
                    </div>
                  </div>
                  <div className="clear20" />

                  {this.state.endyeardata.map(value => {
                    return (
                      <div>
                        <div className="col-xs-5 col-sm-3 p0 text-left">
                          {value.description}:
                  </div>
                        <div className="col-xs-6 text-left"> {value.value}</div>
                        <div className="clear20" />

                      </div>
                    )
                  })

                  }




                  <strong>
                    Once you authorize to send these files the process cannot be
                    stopped or reversed.
                  </strong>
                  <div className="clear20" />
                  <span> I am notifying the ATO that: </span> <br />{" "}
                  <span className="wf1"> -</span>{" "}
                  <span className="wf2">
                    {" "}
                    Threadgold Plummer Hood Pty Ltd provides my business with
                    transaction service, and{" "}
                  </span>
                  <span className="wf1"> -</span>{" "}
                  <span className="wf2">
                    {" "}
                    My business, for the purposes of its transactions with ATO
                    via the SBR channel, sends (and receives) those transactions
                    to (and from) the ATO via QVALENT PTY LTD.
                  </span>{" "}
                  <br />{" "}
                  <span>
                    {" "}
                    I declare the information transmitted in this payroll to be
                    true and correct and I am authorized to make this
                    declaration.{" "}
                  </span>
                  <div className="clear20" />
                  <div className="checkbox_send_pop">
                    <div className="checkbox">
                      <input id="checkbox33" type="checkbox" name="payroll_YearEndStp_Verify_Chkbx"
                        checked={this.state.payroll_YearEndStp_Verify_Chkbx} onChange={this.handleInputsPayroll} />
                      <label htmlFor="checkbox33" />
                    </div>
                  </div>
                  <div className="pull-left col-xs-11 p0 color_bg_send_bot tick_pg">
                    Tick this box to sign the declaration with the credentials
                    used to login and to authorize lodgement with QVALENT PTY
                    LTD's AUSkey.
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    <a
                      href={null}
                      className="btn_save_analytics cursorPointer"
                      disabled={!this.state.payroll_YearEndStp_Verify_Chkbx || !this.state.payroll_YearEndStp_amendment_Chkbx} onClick={this.yearendSTPsend}
                    >
                      Authorize
                    </a>
                    <a
                      href={null}
                      className="btn_cancel_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Payroll_Batches;
