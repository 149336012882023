import { API } from "aws-amplify";
import { toast } from 'react-toastify';
export async function getKeys(UN,PW){
let bodypost={"Guid":JSON.parse(localStorage.getItem('completetenant')).Guid}
    let keysresponse= await API.post("getallprlemp", "/getapikeys", {body: bodypost})
    if(keysresponse.tempKeys.tempUsername&&keysresponse.tempKeys.tempPassword){
        localStorage.setItem('tempkeys',JSON.stringify(keysresponse.tempKeys))
        return true;
        }else{
            
toast.error("keys not fetched");
return false;
        }}
export async function getkeysforsetup(UN,PW){
let bodypost={"UN":UN,"PW":PW}
        let keysresponse= await API.post("getallprlemp", "/getapikeys", {body: bodypost})
        if(keysresponse.tempKeys.tempUsername&&keysresponse.tempKeys.tempPassword){

        return keysresponse.tempKeys;
        }else{
                
toast.error("keys not fetched");
return false;
        }
}
