import React, { Component } from "react";
import Header from "../Common/Header/Header";
import amprove_icon from "./../../Assets/images/amprove_icon.png";
import file_img from "./../../Assets/images/file_img.png";
import small_loader from "./../../Assets/images/small_loader.gif";
import ic_lock1_outline_24px from "./../../Assets/images/ic_lock1_outline_24px.svg";
import ic_lock_outline_24px from "./../../Assets/images/ic_lock_outline_24px.svg";
import ic_vpn_key_24px from "./../../Assets/images/ic_vpn_key_24px.svg";
import ic_check_circle_24px from "./../../Assets/images/ic_check_circle_24px.svg";
import ic_mail_outline_24px from "./../../Assets/images/ic_mail_outline_24px.svg";
import placeholder from "./../../Assets/images/placeholder.png";
import Sidebar from "../Common/Sidebar/Sidebar";
import { Link } from "react-router-dom";
import "./Dashboard.css";
import md5 from 'md5';
import { toast } from 'react-toastify';
import { Auth } from "aws-amplify";
import { Storage, API } from 'aws-amplify';
import Clock from 'react-digital-clock';
import moment from 'moment';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;
var ip = require('ip');
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verified:false,
            count: 20,
            changePassw: '',
            isLoading: true,
            countryCode: '',
            activities: [],
            userData: '',
            changeFromDynamotoo: false,
            dashboardloader: false,
            ProfileModalopen: false,
            uploadS3: false,
            profile_pic: '',
            firstName: '',
            lastName: '',
            email: '',
            Mobile: '',
            formValid: false,
            emailvalid: false,
            mobileNumber: '',
            formErrors: {
                email: '',
                firstName: '',
                lastName: '',
                phone: '',
                countryCode: '',
                Mobile: '',
            },
            change_password_email: '',
            user_entered_code: '',
            sended_Code: '',
            prevPassword: '',
            newPassword: '',
            confirmPassword: '',
            recordactivity: false,
            companyname: ''
        }
    }
    pickfile = () => {
        document.getElementById("upload_profile").click();
    }
    getuser = async () => {
        this.setState({
            dashboardloader: true
        })
        await API.post('getuserbyguid', '/getuserbyguid', {
            body: {
                "user_id": localStorage.getItem('userGuid')
            }
        }).then(data => {
            this.setState({
                userData: data
            });
            if (data.profile_pic) {
                this.setState({
                    profile_pic: 'https://'+process.env.REACT_APP_S3_BUCKET+'.s3-'+process.env.REACT_APP_S3_REGION+'.amazonaws.com/private/' + data.Poolid + '/' + data.profile_pic
                });
            }

            var MobileNumber = '';
            if (data.MobileNumber.includes("+" + data.countryCode)) {
                MobileNumber = data.MobileNumber.replace("+" + data.countryCode, '')
            } else {
                MobileNumber = data.MobileNumber;
            }

            this.setState({
                firstName: data.FirstName,
                lastName: data.LastName,
                email: data.EmailAddress,
                countryCode: data.countryCode,
                Mobile: MobileNumber,
                changePassw: data.PasswordChange
            })
        }).catch(err => {
            console.log(err)
        });
        this.setState({
            dashboardloader: false
        })
    }
    handleCustomFieldsModalInputsCountry(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]: value
        },
            () => {
                this.validateField(name, value)
            });
        this.setState({
            Mobile: '+' + value
        });
    }
    uploadimage = async (event) => {
        let type = event.target.files[0].type
        if (type == 'image/jpg' || type == 'image/jpeg' || type == 'image/png') {
            this.setState({
                dashboardloader: true
            })
            var filename = event.target.files[0].name;

            await Storage.vault.put(filename, event.target.files[0])
                .then(result => {
                    this.setState({
                        uploadS3: true
                    })
                    // toast.success("Success! Uploaded in s3 bucket", {
                    //     autoClose: 5000
                    // });
                    this.setState({
                        recordactivity: true
                    })
                })
                .catch(err => {
                    toast.error(err, {
                        autoClose: 5000
                    });
                });

            if (this.state.uploadS3) {
                await this.updateprofile(filename);
            }

            if (this.state.recordactivity) {
                await API.post("recordactivity", "/recordactivity", {
                    body: {
                        "Description": "has updated profile photo",
                        "UserName": localStorage.getItem('UserLogin'),
                        "IPAddress": ip.address(),
                        "TenantGuid": "UYGVUYGBIUYGHBIUYHGB",
                        "Type": "Profile Photo Updated",
                        "UserGuid": localStorage.getItem('userGuid')
                    }
                }).then(data => {
                   // toast.success('Profile Photo Updation Activity Recorded Successfully.');
                }).catch(err => {
                    toast.error(err.message);
                });
                this.setState({
                    recordactivity: false
                })
            }

            await this.getuser();
            this.setState({
                dashboardloader: false
            })
        }
        else {
            toast.error("Please Select only Images of type: 'PNG, JPG, JPEG'", {
                autoClose: 5000
            });
        }
    }
    updateprofile = async (event) => {

        await API.post('updateprofileindynamo', '/updateprofileindynamo', {
            body: {
                "user_id": localStorage.getItem('userGuid'),
                "profile_pic": event
            }
        }).then(data => {
            // toast.success("Upload Successfully in Dynamodb"
            // , {
            //     autoClose: 5000
            // });

        }).catch(error => {
            toast.error("Not Uploaded to Dynamodb", {
                autoClose: 5000
            });
        });


    }
    updatelastactivedate = async () => {
        await API.post('updatelastactivepassword', '/updatelastactivepassword', {
            body: {
                "Guid": localStorage.getItem('userGuid'),
                "lastactivedate": Date.now()
            }
        }).then(data => { 
            //toast.success('Last active Date update!');
         }).catch(error => { toast.error('Last active Date not update!'); });
    }

    async componentDidMount() {

        await this.props.childProps.isAuthentication();
        if (!this.props.childProps.isAuthenticated) {
            this.props.history.push('/login');
        } else {

            document.body.classList.add('dashboard_body');
            if (this.props.history.location.state !== undefined) {
               // toast.success('User Login Succesfully');
            }
            await this.props.childProps.getUserSettings() //get Users Access settings
            await this.getuser();
            await this.getdatatenant();
            await this.updatelastactivedate();
            await this.props.getCounts() //get timecard counts to show on Dashboard
            await this.props.childProps.getSettings() //get general settings

            await API.post('listresources', '/listresources').then(data => {
                this.setState({
                    activities: data

                }, this.filteractivities);
            }).catch(err => {
                toast.error('Unable To Fetch Users Activities');
            });
            this.setState({

                isLoading: false
            });
        }

    }

    filteractivities = () => {
        var temp_activities = [];
        if (localStorage.getItem('role') === 'Admin') {
            this.state.activities.map(value => {
                if (value.TenantGuid === localStorage.getItem('useableGuid')) {
                    temp_activities.push(value);
                }
                return null;
            });
            this.setState({
                activities: temp_activities
            })
        }

    }
    getdatatenant = async () => {
        await API.post('gettenantbytenantid', '/gettenantbytenantid', {
            body: {
                id: localStorage.getItem('useableGuid')
            }
        }).then(data => {
            localStorage.setItem('completetenant', JSON.stringify(data[0]));
            this.setState({
                companyname: JSON.parse(localStorage.getItem('completetenant')).CompanyName,
            })
        }).catch(err => {
            toast.error('Unable To Fetch Users Activities');
        });
    }
    componentWillUnmount() {
        document.body.classList.remove('dashboard_body');
    }
    redirectFunc = (e) => {
        this.props.history.push("/timecards", {
            state: {
                tabindex: "approved"
            }
        });
    }
    redirectFuncApprove = (e) => {
        this.props.history.push("/timecards", {
            state: {
                tabindex: "approve"
            }
        });
    }
    redirectFuncPending = (e) => {
        this.props.history.push("/timecards", {
            state: {
                tabindex: "pending"
            }
        });
    }
    redirectFuncAll = (e) => {
        this.props.history.push("/timecards", {
            state: {
                tabindex: "all"
            }
        });
    }
    submitUpdation = async (event) => {

        event.preventDefault();
        var formErrors = this.state.formErrors;
        if (!this.state.firstName) {
            formErrors.firstName = 'This Field is Required.';
            this.setState({
                formErrors: formErrors,
            });
            return false;
        }
        if (!this.state.lastName) {
            formErrors.lastName = 'This Field is Required.';
            this.setState({
                formErrors: formErrors,
            });
            return false;
        }
        if (!this.state.Mobile) {
            formErrors.Mobile = 'Please Put Full Phone Number e.g. +123456789';
            this.setState({
                formErrors: formErrors,
            });
            return false;
        }
        if (!this.state.countryCode) {
            formErrors.countryCode = 'This Field is Required.';
            this.setState({
                formErrors: formErrors,
            });
            return false;
        }
        $("#closingEditModal").click();
        this.setState({
            isLoading: true
        })
        await API.post('updateuserbyguid', '/updateuserbyguid', {
            body: {
                "Guid": localStorage.getItem('userGuid'),
                "firstname": this.state.firstName,
                "lastname": this.state.lastName,
                "CountryCode": this.state.countryCode,
                "mobile": this.state.Mobile,

            }
        }).then(
            data => {
                // toast.success('Profile Successfully Updated In Dynamo DB.', {
                //     autoClose: 5000
                // });
                this.setState({
                    recordactivity: true
                })
            }
        ).catch(error => {
            toast.error(error.message, {
                autoClose: 5000
            });
        });
        if (this.state.recordactivity) {
            await API.post("recordactivity", "/recordactivity", {
                body: {
                    "Description": "has edited profile",
                    "UserName": localStorage.getItem('UserLogin'),
                    "IPAddress": ip.address(),
                    "TenantGuid": "UYGVUYGBIUYGHBIUYHGB",
                    "Type": "Profile Edit",
                    "UserGuid": localStorage.getItem('userGuid')
                }
            }).then(data => {
                //toast.success('Profile Edit Activity Recorded Successfully.');
            }).catch(err => {
                toast.error(err.message);
            });
            this.setState({
                recordactivity: false
            })
        }
        await this.getuser();
        this.setState({
            isLoading: false
        })
    }
    changeHandel = async (event) => {
        var name = event.target.name;
        var value = event.target.value;

        this.setState({
            [event.target.name]: event.target.value
        });
        this.validateField(name, value);
    }

    validateField = async (name, value) => {
        var email_pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
        var formErrors = this.state.formErrors;
        switch (name) {
            case 'email':
                if (value.length < 1) {
                    formErrors.email = 'This field is required.';
                    this.setState({
                        emailvalid: false
                    });
                } else if (!email_pattern.test(value)) {
                    formErrors.email = 'Please enter email like abc@def.com';
                    this.setState({
                        emailvalid: false
                    });
                } else {
                    formErrors.email = '';
                    this.setState({
                        emailvalid: true
                    });
                }
                break;
            case 'firstName':
                if (value.length < 1) {
                    formErrors.firstName = 'This Field is Required.';
                } else {
                    formErrors.firstName = '';
                }
                break;
            case 'lastName':
                if (value.length < 1) {
                    formErrors.lastName = 'This Field is Required.';
                } else {
                    formErrors.lastName = '';
                }
                break;
            case 'countryCode':
                if (value.length < 1) {
                    formErrors.countryCode = 'This Field is Required.';
                } else {
                    formErrors.countryCode = '';
                }
                break;
            case 'Mobile':
                if (value.length < 10) {
                    formErrors.Mobile = 'Please Put Full Phone Number e.g. +123456789';
                } else {
                    formErrors.Mobile = '';
                }
                break;
            default:
                break;
        }

        this.setState({
            formErrors: formErrors,
        });
    }

    handleUserInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    sendCode = async () => {
        //var reg='/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/';
        var emailValid = this.state.change_password_email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        if (this.state.change_password_email === '') {
            toast.error('Email is required');
            return false;
        }
        if (!emailValid) {
            toast.error('Please Write Email e.g abc@def.xyz');
            return false;
        }
        if (this.state.email === this.state.change_password_email) {
            $('#changePasswordModal').hide();
            $('#changePasswordPinCodeModal').show();

            if (this.state.Mobile.includes("+" + this.state.countryCode)) {
                this.setState({
                    Mobile: this.state.Mobile.replace("+" + this.state.countryCode, '')
                })
            }

            await API.post("sendsms", "/sendsms", {
                body: {
                    "Guid": this.state.userData.Guid,
                    "PhoneNumber": "+" + this.state.countryCode + this.state.Mobile
                }
            })
                .then(res =>
                     console.log("Verification code sended to you phone")
                     )
                .catch(err => toast.error("error : " + err))
        } else {
            toast.error('Please mention your account email address.');
        }
    }
    getautherization=async()=>{
        await API.post('sendsms','/checkauth',{
          body:{
            confirmation:this.state.user_entered_code.toString(),
            Guid:this.state.userguid,
          }
        }).then((result)=>{
          if(result.message==='Code Matched'){
          this.setState({
            verified:true
          })
        }
        }).catch(error=>{
          this.setState({
            verified:false
          })
        })
       
      }
    goto_enter_code_screen = () => {
        if (this.state.user_entered_code === '') {
            toast.error('Code is required');
            return false;
        }


        if (this.state.verified) {
            $('#changePasswordPinCodeModal').hide();
            $('#changePasswordFieldsCodeModal').show();
        } else {
            toast.error('Invalid code , Try again');
        }

    }

    changePassword = async () => {
        if (this.state.prevPassword === '' || this.state.newPassword === '' || this.state.confirmPassword === '') {
            toast.error('All fields are required');
            return false;
        }
        if (this.state.newPassword !== this.state.confirmPassword) {
            toast.error('Password not matched');
            return false;
        }
        this.setState({
            dashboardloader: true
        })
        await Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, this.state.prevPassword, this.state.newPassword);
            })
            .then(data => {
                if (data === 'SUCCESS') {
                    this.setState({
                        changeFromDynamotoo: true
                    })
                   // toast.success('Password has been updated in cognito successfully...');
                    $('#changePasswordFieldsCodeModal').hide();
                }
            })
            .catch(err => {
                toast.error(err.message);
                return false;
            });
        if (this.state.changeFromDynamotoo) {
            await API.post('updatepassword', '/updatepassword', {
                body: {
                    "Guid": localStorage.getItem('userGuid'),
                    "Password": md5(this.state.newPassword)
                }
            })
                .then(data => {
                   // toast.success('Password has been updated in dynamoDB successfully')
                    this.setState({
                        recordactivity: true
                    })
                })
                .catch(err => toast.error(err));
            this.setState({
                changeFromDynamotoo: false,
                change_password_email: '',
                user_entered_code: '',
                sended_Code: '',
                prevPassword: '',
                newPassword: '',
                confirmPassword: ''
            })
        }
        if (this.state.recordactivity) {
            await API.post("recordactivity", "/recordactivity", {
                body: {
                    "Description": "Changed Password Successfully",
                    "UserName": localStorage.getItem('UserLogin'),
                    "IPAddress": ip.address(),
                    "TenantGuid": "UYGVUYGBIUYGHBIUYHGB",
                    "Type": "Password Changed",
                    "UserGuid": localStorage.getItem('userGuid')
                }
            }).then(data => {
                //toast.success('Password Changed Activity Recorded Successfully.');
            }).catch(err => {
                toast.error(err.message);
            });

            await API.post("updatelastpasswordchanged", "/updatelastpasswordchanged", {
                body: {
                    "userEmail": localStorage.getItem('EmailAddress'),
                    "PWLastChanged": Date.now()
                }
            }).then(data => {
                //toast.success('Password Changed Updated Successfully For User.');
            }).catch(err => {
                toast.error(err.message);
            });

            this.setState({
                recordactivity: false
            })
        }
        this.setState({
            dashboardloader: false
        })
    }

    closeChangePasswordModal = () => {
        this.setState({
            change_password_email: ''
        });
        $('#changePasswordModal').hide();
    }

    closeChangePasswordPinCodeModal = () => {
        this.setState({
            user_entered_code: '',
            change_password_email: ''
        });
        $('#changePasswordPinCodeModal').hide();
        $('#changePasswordModal').show();
    }

    closeChangePasswordFieldsCodeModal = () => {
        this.setState({
            user_entered_code: '',
            change_password_email: '',
            prevPassword: '',
            newPassword: '',
            confirmPassword: ''
        });
        $('#changePasswordFieldsCodeModal').hide();
        $('#changePasswordPinCodeModal').hide();
        $('#changePasswordModal').hide();
    }

    ProfileModalClose = () => {
        this.setState({
            ProfileModalopen: false,
        });
    }

    resendCode = async () => {
      

        if (this.state.Mobile.includes("+" + this.state.countryCode)) {
            this.setState({
                Mobile: this.state.Mobile.replace("+" + this.state.countryCode, '')
            })
        }

        await API.post("sendsms", "/sendsms", {
            body: {
                "Guid": this.state.userData.Guid,
                "PhoneNumber": "+" + this.state.countryCode + this.state.Mobile
            }
        })
            .then(res => {
                this.setState({
                    verified:false
                })
                console.log("Verification code resended to you phone")})
            .catch(err => toast.error(err))
    }

    ProfileModalOpen = () => {
        this.setState({
            ProfileModalopen: true,
        });
    }
    addactivities = () => {
        this.setState({
            count: 20 + this.state.count
        })
    }
    lessactivities = () => {
        if (this.state.count > 20) {
            this.setState({
                count: 20
            });
        }

    }
    forceUpdate = async () => {
        await API.post("getcompaniesbycoyid", "/getcompaniesbycoyid", {
            body: {
                "cID": localStorage.getItem('companyId')
            }
        }).then(result => {
            console.log(result);
            this.setState({
                companies: result
            })

        }).catch(err => { console.log(err); });
        await this.props.childProps.isAuthentication();
        if (!this.props.childProps.isAuthenticated) {
            this.props.history.push('/login');
        } else {

            document.body.classList.add('dashboard_body');
            if (this.props.history.location.state !== undefined) {
                //toast.success('User Login Succesfully');
            }
            await this.getuser();
            await this.getdatatenant();
            await API.post('listresources', '/listresources').then(data => {
                this.setState({
                    activities: data

                })
            }).catch(err => {
                toast.error('Unable To Fetch Users Activities');
            });
            await this.setState({

                isLoading: false
            });
        }
    }
    text_truncate = (str, length, ending) => {
        if (length == null) {
            length = 100;
        }
        if (ending == null) {
            ending = '...';
        }
        if (str.length > length) {
            return str.substring(0, length - ending.length) + ending;
        } else {
            return str;
        }
    };
    render() {
        var activities = this.state.activities;
        var monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        var weekday = ['Sunday', 'Monday', 'Tueday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][new Date().getDay()];
        return (
            <div className="dashboard_body">
                <Header
                    companyname={this.state.companyname}
                    isLoading={this.state.isLoading}
                    childProps={this.props.childProps}
                />
                <div className="clearfix" />
                <div className="col-sm-12 p0 top_mrg_form_res">
                    <Sidebar childProps={this.props.childProps} />
                    <div className="dashboard_right dashboard_right_2">
                        <div id="stay-in-place">
                            <div className="dashboard_welcomebg">
                                Welcome, You are currently working on {this.state.companyname}
                            </div>
                            <div className="clear20" />
                            <div className="col-sm-12 p0">
                                <div className="col-xs-12 col-sm-12 col-md-6 p0_left_dash">
                                    <div className="dashboard_welcomebox">
                                        <div className="col-xs-12 col-sm-5 p0">
                                            <div className="col-sm-12 p0 text-center">
                                                <img
                                                    src={this.state.profile_pic !== '' ? this.state.profile_pic : placeholder}
                                                    className="profile_img"
                                                    alt=""
                                                />
                                                <div className="clear20" />
                                                <div className="col-sm-12 p0 text-center">
                                                    <a hreaf="javascript:void(0);" className="btn_edit_profile cursorPointer" data-toggle="modal" data-target="#mymodal_edit_profile">Edit Profile</a>
                                                </div>
                                                <div className="clear20" />
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-7 dahsboard_gray_box_label">
                                            <h2> Welcome, {(this.state.userData.FirstName ? this.state.userData.FirstName : 'Loading ...')} {this.state.userData.LastName}</h2>
                                            UserName: {(this.state.userData.UserLogin ? this.state.userData.UserLogin : 'Loading ...')}
                                            <br />
                                            Email: {(this.state.userData.EmailAddress ? this.state.userData.EmailAddress : 'Loading ...')}
                                            <br />
                                            Last Login: {(this.state.userData.LastLogin ? moment(this.state.userData.LastLogin).format('MMMM Do YYYY, h:mm:ss a') : 'Loading ...')}
                                        </div>
                                        <div className="clearfix" />
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 p0_right_dash mrg_dash_topbox2">
                                    <div className="dashboard_datebox">
                                        <Clock />
                                        {weekday}
                                        <br />
                                        {new Date().getDate()}{((new Date().getDate() === 1 || new Date().getDate() === 21 || new Date().getDate() === 31) ? 'st' : ((new Date().getDate() === 2 || new Date().getDate() === 22) ? 'nd' : ((new Date().getDate() === 3 || new Date().getDate() === 23) ? 'rd' : 'th')))} of {monthNames[new Date().getMonth()]}
                                        <div className="clearfix" />
                                    </div>
                                </div>
                            </div>
                            <div className="clear50" />
                            <div className="col-sm-12 col-md-12 col-lg-10 col-lg-offset-1">
                                <div className="col-sm-3">
                                    <a hreaf="javascript:void(0);" onClick={this.redirectFuncApprove}>
                                        <div className="aprove_box1 cursorPointer">
                                            <div className="amprove_underbox_1">
                                                <img
                                                    src={amprove_icon}
                                                    className="svg_amprove_icon"
                                                    width="45"
                                                    height="42"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="clear20" />
                                            <label>APPROVE</label>
                                            <div className="clear5" />{this.props.count.approveCount}{' '} Timecards new
   </div>
                                    </a>
                                </div>
                                <div className="col-sm-3">
                                    <a hreaf="javascript:void(0);" onClick={this.redirectFuncPending}>
                                        <div className="aprove_box1 cursorPointer">
                                            <div className="amprove_underbox_1">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24.224"
                                                    height="17.617"
                                                    viewBox="0 0 24.224 17.617"
                                                >
                                                    <path
                                                        id="ic_cached_24px"
                                                        d="M20.819,8.4l-4.4,4.4h3.3a6.612,6.612,0,0,1-6.606,6.606,6.464,6.464,0,0,1-3.083-.771L8.421,20.252a8.793,8.793,0,0,0,13.5-7.443h3.3Zm-14.314,4.4A6.612,6.612,0,0,1,13.112,6.2a6.464,6.464,0,0,1,3.083.771L17.8,5.365A8.793,8.793,0,0,0,4.3,12.809H1l4.4,4.4,4.4-4.4Z"
                                                        transform="translate(-1 -4)"
                                                        fill="#30679A"
                                                    />
                                                </svg>
                                            </div>
                                            <div className="clear20" />
                                            <label>PENDING</label>
                                            <div className="clear5" />{this.props.count.pendingCount} {' '} Timecards new
   </div>
                                    </a>
                                </div>
                                <div className="col-sm-3">
                                    <a hreaf="javascript:void(0);" onClick={this.redirectFunc}>
                                        <div className="aprove_box1 cursorPointer">
                                            <div className="amprove_underbox_1">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="17.6"
                                                    height="13.4"
                                                    viewBox="0 0 17.6 13.4"
                                                >
                                                    <path
                                                        id="ic_done_24px"
                                                        d="M9,16.2,4.8,12,3.4,13.4,9,19,21,7,19.6,5.6Z"
                                                        transform="translate(-3.4 -5.6)"
                                                        fill="#30679A"
                                                    />
                                                </svg>
                                            </div>
                                            <div className="clear20" />
                                            <label>APPROVED</label>
                                            <div className="clear5" />{this.props.count.approvedCount} {' '} Timecards new
      </div>
                                    </a>
                                </div>
                                <div className="col-sm-3">
                                    <a hreaf="javascript:void(0);" onClick={this.redirectFuncAll}>
                                        <div className="aprove_box1 cursorPointer">
                                            <div className="amprove_underbox_1">
                                                <img
                                                    src={file_img}
                                                    className="svg_w_allbox"
                                                    width="40"
                                                    height="50"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="clear20" />
                                            <label>ALL</label>
                                            <div className="clear5" />
                                            {this.props.count.allCount} {' '}Timecards new
         </div>
                                    </a>
                                </div>

                            </div>
                            <div className="clear40" /></div>
                        <div className="dashboard_right_activity" id="move-in-place">
                            <h2>ACTIVITY</h2>
                            <div className="clear10" />
                            <ul className="timeline">
                                {activities.slice(0, this.state.count).map((activities, key) => {
                                    return (
                                        <li key={key}>
                                            <div className="col-sm-2">
                                                <a target="_blank" hreaf="javascript:void(0);">
                                                    <strong>{this.text_truncate(`${activities.UserName}`, 15)}</strong>

                                                    {/* {console.log(this.text_truncate('We are doing JS string exercises.',19))} */}
                                                </a>
                                            </div>
                                            <div className="col-sm-6">
                                                <Link to="" className="float-right">
                                                    has {activities.Description} on Digital Timecard.
                                                </Link>
                                            </div>
                                            <div className="col-sm-4">
                                                Created Date: {moment(activities.Timestamp).format('MMMM Do YYYY, h:mm:ss a')}
                                            </div>
                                            <div className="clear10" />
                                        </li>
                                    )
                                })

                                }
                                {(activities.length < 20) ? '' :
                                    (this.state.count <= activities.length) ?
                                        <div className="textcenter cursorPointer" onClick={this.addactivities}>
                                            <svg className="arrows">
                                                <path className="a1" d="M0 0 L30 32 L60 0"></path></svg>
                                            <a className="btn_edit_profile cursorPointer fcWidth">Show More</a>
                                        </div>
                                        :
                                        <div className="textcenter cursorPointer" onClick={this.lessactivities}>
                                            <a className="btn_edit_profile cursorPointer fcWidth">Show Less</a>
                                        </div>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div id="mymodal_edit_profile" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modla_edit_profile popup_diaolg_top_mrg" role="document">
                        <div className="modal-content">
                            <div className="modal-header modal_header_register">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                        <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B"></path>
                                    </svg>
                                </button>
                            </div>
                            <div className="modal-body register_suc register_popup register_pd_apply_pop">
                                <div className="user_edting_hd">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="1585 5909 17.515 18.002">
                                        <path id="ic_create_24px" className="cls-1" d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z" transform="translate(1582 5906.002)" />
                                    </svg>
                                    User Settings
               </div>
                                <div className="clear50"></div>
                                <div className="col-sm-4 col-xs-12 p0">
                                    <div className="user_setting_box">
                                        <div className="col-sm-12 p0 text-center">
                                            {this.state.dashboardloader ?
                                                <div className="se-pre-con"></div>
                                                : ''} <img src={this.state.profile_pic !== '' ? this.state.profile_pic : placeholder} className="profile_img" alt="" />
                                        </div>
                                        <div className="clear20"></div>
                                        <input type='file' accept="image/jpeg, image/png, image/jpg" name='upload_profile' id='upload_profile' className='display_hidden' onChange={this.uploadimage} />
                                        <button type="button" hreaf="javascript:void(0);" onClick={this.pickfile} className="btn_changephoto">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-9284 -445 18 16.2">
                                                <g id="ic_photo_camera_24px" transform="translate(-9286 -447)">
                                                    <path id="Path_97" data-name="Path 97" className="cls-1" d="M2.88,0A2.88,2.88,0,1,1,0,2.88,2.88,2.88,0,0,1,2.88,0Z" transform="translate(8.12 8.12)" />
                                                    <path id="Path_92" data-name="Path 92" className="cls-1" d="M8.3,2,6.653,3.8H3.8A1.805,1.805,0,0,0,2,5.6V16.4a1.805,1.805,0,0,0,1.8,1.8H18.2A1.805,1.805,0,0,0,20,16.4V5.6a1.805,1.805,0,0,0-1.8-1.8H15.347L13.7,2ZM11,15.5A4.5,4.5,0,1,1,15.5,11,4.5,4.5,0,0,1,11,15.5Z" />
                                                </g>
                                            </svg>
                                            <span>Change photo</span>
                                        </button>
                                    </div>
                                    <div className="clear40"></div>
                                    <div className="btn_cance_save">
                                        <input name="" type="button" className="btn_save_pro" defaultValue="Save" onClick={this.submitUpdation} />
                                        <input name="" type="button" className="btn_cancel_pro" id="closingEditModal" data-dismiss="modal" aria-label="Close" defaultValue="Cancel" />
                                    </div>
                                </div>
                                <div className="col-sm-7 col-xs-12 profile_setting_pop pull-right p0">
                                    <form className="form-horizontal" >
                                        <div className="form-group text-left">
                                            <label className="control-label col-sm-4" htmlFor="First Name">First Name</label>
                                            <div className="col-sm-8">
                                                <input type="text" name="firstName" className="form-control pro_input_pop" value={this.state.firstName} id="First Name" placeholder="kevin" onChange={this.changeHandel} />
                                                <div className="danger">{this.state.formErrors.firstName !== '' ? this.state.formErrors.firstName : ''}</div>
                                            </div>
                                        </div>
                                        <div className="form-group text-left">
                                            <label className="control-label col-sm-4" htmlFor="Last Name">Last Name</label>
                                            <div className="col-sm-8">
                                                <input type="text" name="lastName" className="form-control pro_input_pop" value={this.state.lastName || ''} id="Last Name" placeholder="plummer" onChange={this.changeHandel} />
                                                <div className="danger">{this.state.formErrors.lastName !== '' ? this.state.formErrors.lastName : ''}</div>
                                            </div>
                                        </div>
                                        <div className="form-group text-left">
                                            <label className="control-label col-sm-4" htmlFor="Email">Email</label>
                                            <div className="col-sm-8">
                                                <input type="Email" className="form-control pro_input_pop" value={this.state.email} name="email" id="Email" placeholder="kevinplummer@gmail.com" onChange={this.changeHandel} readOnly="readOnly" />
                                                <div className="danger">{this.state.formErrors.email !== '' ? this.state.formErrors.email : ''}</div>
                                            </div>
                                        </div>
                                        <div className="form-group text-left">
                                            <label className="control-label col-sm-4" htmlFor="Mobile">Mobile</label>
                                            <div className="col-sm-8">
                                                <div className="col-sm-12 p0">
                                                    <div className="col-sm-6 p0">
                                                        <select className="form-control pro_input_pop" name="countryCode" value={this.state.countryCode} onChange={(event) =>
                                                            this.handleCustomFieldsModalInputsCountry(event)}>
                                                            <option value="">Country</option>
                                                            <option value="61">Australia (+61)</option>
                                                            <option value="44">UK (+44)</option>
                                                            <option value="1">USA (+1)</option>
                                                            <option value="213">Algeria (+213)</option>
                                                            <option value="379">Andorra (+376)</option>
                                                            <option value="244">Angola (+244)</option>
                                                            <option value="1264">Anguilla (+1264)</option>
                                                            <option value="1268">Antigua &amp;amp; Barbuda (+1268)</option>
                                                            <option value="54">Argentina (+54)</option>
                                                            <option value="374">Armenia (+374)</option>
                                                            <option value="297">Aruba (+297)</option>
                                                            <option value="43">Austria (+43)</option>
                                                            <option value="994">Azerbaijan (+994)</option>
                                                            <option value="1242">Bahamas (+1242)</option>
                                                            <option value="973">Bahrain (+973)</option>
                                                            <option value="880">Bangladesh (+880)</option>
                                                            <option value="1246">Barbados (+1246)</option>
                                                            <option value="375">Belarus (+375)</option>
                                                            <option value="32">Belgium (+32)</option>
                                                            <option value="501">Belize (+501)</option>
                                                            <option value="229">Benin (+229)</option>
                                                            <option value="1441">Bermuda (+1441)</option>
                                                            <option value="975">Bhutan (+975)</option>
                                                            <option value="591">Bolivia (+591)</option>
                                                            <option value="387">Bosnia Herzegovina (+387)</option>
                                                            <option value="267">Botswana (+267)</option>
                                                            <option value="55">Brazil (+55)</option>
                                                            <option value="673">Brunei (+673)</option>
                                                            <option value="359">Bulgaria (+359)</option>
                                                            <option value="226">Burkina Faso (+226)</option>
                                                            <option value="257">Burundi (+257)</option>
                                                            <option value="855">Cambodia (+855)</option>
                                                            <option value="237">Cameroon (+237)</option>
                                                            <option value="1">Canada (+1)</option>
                                                            <option value="238">Cape Verde Islands (+238)</option>
                                                            <option value="1345">Cayman Islands (+1345)</option>
                                                            <option value="236">Central African Republic (+236)</option>
                                                            <option value="56">Chile (+56)</option>
                                                            <option value="86">China (+86)</option>
                                                            <option value="57">Colombia (+57)</option>
                                                            <option value="269">Comoros (+269)</option>
                                                            <option value="242">Congo (+242)</option>
                                                            <option value="682">Cook Islands (+682)</option>
                                                            <option value="506">Costa Rica (+506)</option>
                                                            <option value="385">Croatia (+385)</option>
                                                            <option value="53">Cuba (+53)</option>
                                                            <option value="90392">Cyprus North (+90392)</option>
                                                            <option value="357">Cyprus South (+357)</option>
                                                            <option value="42">Czech Republic (+42)</option>
                                                            <option value="45">Denmark (+45)</option>
                                                            <option value="253">Djibouti (+253)</option>
                                                            <option value="1809">Dominica (+1809)</option>
                                                            <option value="1809">Dominican Republic (+1809)</option>
                                                            <option value="593">Ecuador (+593)</option>
                                                            <option value="20">Egypt (+20)</option>
                                                            <option value="503">El Salvador (+503)</option>
                                                            <option value="240">Equatorial Guinea (+240)</option>
                                                            <option value="291">Eritrea (+291)</option>
                                                            <option value="372">Estonia (+372)</option>
                                                            <option value="251">Ethiopia (+251)</option>
                                                            <option value="500">Falkland Islands (+500)</option>
                                                            <option value="298">Faroe Islands (+298)</option>
                                                            <option value="679">Fiji (+679)</option>
                                                            <option value="358">Finland (+358)</option>
                                                            <option value="33">France (+33)</option>
                                                            <option value="594">French Guiana (+594)</option>
                                                            <option value="689">French Polynesia (+689)</option>
                                                            <option value="241">Gabon (+241)</option>
                                                            <option value="220">Gambia (+220)</option>
                                                            <option value="7880">Georgia (+7880)</option>
                                                            <option value="49">Germany (+49)</option>
                                                            <option value="233">Ghana (+233)</option>
                                                            <option value="350">Gibraltar (+350)</option>
                                                            <option value="30">Greece (+30)</option>
                                                            <option value="299">Greenland (+299)</option>
                                                            <option value="1473">Grenada (+1473)</option>
                                                            <option value="590">Guadeloupe (+590)</option>
                                                            <option value="671">Guam (+671)</option>
                                                            <option value="502">Guatemala (+502)</option>
                                                            <option value="224">Guinea (+224)</option>
                                                            <option value="245">Guinea - Bissau (+245)</option>
                                                            <option value="592">Guyana (+592)</option>
                                                            <option value="509">Haiti (+509)</option>
                                                            <option value="504">Honduras (+504)</option>
                                                            <option value="852">Hong Kong (+852)</option>
                                                            <option value="36">Hungary (+36)</option>
                                                            <option value="354">Iceland (+354)</option>
                                                            <option value="91">India (+91)</option>
                                                            <option value="62">Indonesia (+62)</option>
                                                            <option value="98">Iran (+98)</option>
                                                            <option value="964">Iraq (+964)</option>
                                                            <option value="353">Ireland (+353)</option>
                                                            <option value="972">Israel (+972)</option>
                                                            <option value="39">Italy (+39)</option>
                                                            <option value="1876">Jamaica (+1876)</option>
                                                            <option value="81">Japan (+81)</option>
                                                            <option value="962">Jordan (+962)</option>
                                                            <option value="7">Kazakhstan (+7)</option>
                                                            <option value="254">Kenya (+254)</option>
                                                            <option value="686">Kiribati (+686)</option>
                                                            <option value="850">Korea North (+850)</option>
                                                            <option value="82">Korea South (+82)</option>
                                                            <option value="965">Kuwait (+965)</option>
                                                            <option value="996">Kyrgyzstan (+996)</option>
                                                            <option value="856">Laos (+856)</option>
                                                            <option value="371">Latvia (+371)</option>
                                                            <option value="961">Lebanon (+961)</option>
                                                            <option value="266">Lesotho (+266)</option>
                                                            <option value="231">Liberia (+231)</option>
                                                            <option value="218">Libya (+218)</option>
                                                            <option value="417">Liechtenstein (+417)</option>
                                                            <option value="370">Lithuania (+370)</option>
                                                            <option value="352">Luxembourg (+352)</option>
                                                            <option value="853">Macao (+853)</option>
                                                            <option value="389">Macedonia (+389)</option>
                                                            <option value="261">Madagascar (+261)</option>
                                                            <option value="265">Malawi (+265)</option>
                                                            <option value="60">Malaysia (+60)</option>
                                                            <option value="960">Maldives (+960)</option>
                                                            <option value="223">Mali (+223)</option>
                                                            <option value="356">Malta (+356)</option>
                                                            <option value="692">Marshall Islands (+692)</option>
                                                            <option value="596">Martinique (+596)</option>
                                                            <option value="222">Mauritania (+222)</option>
                                                            <option value="269">Mayotte (+269)</option>
                                                            <option value="52">Mexico (+52)</option>
                                                            <option value="691">Micronesia (+691)</option>
                                                            <option value="373">Moldova (+373)</option>
                                                            <option value="377">Monaco (+377)</option>
                                                            <option value="976">Mongolia (+976)</option>
                                                            <option value="1664">Montserrat (+1664)</option>
                                                            <option value="212">Morocco (+212)</option>
                                                            <option value="258">Mozambique (+258)</option>
                                                            <option value="95">Myanmar (+95)</option>
                                                            <option value="264">Namibia (+264)</option>
                                                            <option value="674">Nauru (+674)</option>
                                                            <option value="977">Nepal (+977)</option>
                                                            <option value="31">Netherlands (+31)</option>
                                                            <option value="687">New Caledonia (+687)</option>
                                                            <option value="64">New Zealand (+64)</option>
                                                            <option value="505">Nicaragua (+505)</option>
                                                            <option value="227">Niger (+227)</option>
                                                            <option value="234">Nigeria (+234)</option>
                                                            <option value="683">Niue (+683)</option>
                                                            <option value="672">Norfolk Islands (+672)</option>
                                                            <option value="670">Northern Marianas (+670)</option>
                                                            <option value="47">Norway (+47)</option>
                                                            <option value="968">Oman (+968)</option>
                                                            <option value="92">Pakistan (+92)</option>
                                                            <option value="680">Palau (+680)</option>
                                                            <option value="507">Panama (+507)</option>
                                                            <option value="675">Papua New Guinea (+675)</option>
                                                            <option value="595">Paraguay (+595)</option>
                                                            <option value="51">Peru (+51)</option>
                                                            <option value="63">Philippines (+63)</option>
                                                            <option value="48">Poland (+48)</option>
                                                            <option value="351">Portugal (+351)</option>
                                                            <option value="1787">Puerto Rico (+1787)</option>
                                                            <option value="974">Qatar (+974)</option>
                                                            <option value="262">Reunion (+262)</option>
                                                            <option value="40">Romania (+40)</option>
                                                            <option value="7">Russia (+7)</option>
                                                            <option value="250">Rwanda (+250)</option>
                                                            <option value="378">San Marino (+378)</option>
                                                            <option value="239">Sao Tome &amp;amp; Principe (+239)</option>
                                                            <option value="966">Saudi Arabia (+966)</option>
                                                            <option value="221">Senegal (+221)</option>
                                                            <option value="381">Serbia (+381)</option>
                                                            <option value="248">Seychelles (+248)</option>
                                                            <option value="232">Sierra Leone (+232)</option>
                                                            <option value="65">Singapore (+65)</option>
                                                            <option value="421">Slovak Republic (+421)</option>
                                                            <option value="386">Slovenia (+386)</option>
                                                            <option value="677">Solomon Islands (+677)</option>
                                                            <option value="252">Somalia (+252)</option>
                                                            <option value="27">South Africa (+27)</option>
                                                            <option value="34">Spain (+34)</option>
                                                            <option value="94">Sri Lanka (+94)</option>
                                                            <option value="290">St. Helena (+290)</option>
                                                            <option value="1869">St. Kitts (+1869)</option>
                                                            <option value="1758">St. Lucia (+1758)</option>
                                                            <option value="249">Sudan (+249)</option>
                                                            <option value="597">Suriname (+597)</option>
                                                            <option value="268">Swaziland (+268)</option>
                                                            <option value="46">Sweden (+46)</option>
                                                            <option value="41">Switzerland (+41)</option>
                                                            <option value="963">Syria (+963)</option>
                                                            <option value="886">Taiwan (+886)</option>
                                                            <option value="7">Tajikstan (+7)</option>
                                                            <option value="66">Thailand (+66)</option>
                                                            <option value="228">Togo (+228)</option>
                                                            <option value="676">Tonga (+676)</option>
                                                            <option value="1868">Trinidad &amp;amp; Tobago (+1868)</option>
                                                            <option value="216">Tunisia (+216)</option>
                                                            <option value="90">Turkey (+90)</option>
                                                            <option value="7">Turkmenistan (+7)</option>
                                                            <option value="993">Turkmenistan (+993)</option>
                                                            <option value="1649">Turks &amp;amp; Caicos Islands (+1649)</option>
                                                            <option value="688">Tuvalu (+688)</option>
                                                            <option value="256">Uganda (+256)</option>
                                                            <option value="44">UK (+44)</option>
                                                            <option value="380">Ukraine (+380)</option>
                                                            <option value="971">United Arab Emirates (+971)</option>
                                                            <option value="598">Uruguay (+598)</option>
                                                            <option value="1">USA (+1)</option>
                                                            <option value="7">Uzbekistan (+7)</option>
                                                            <option value="678">Vanuatu (+678)</option>
                                                            <option value="379">Vatican City (+379)</option>
                                                            <option value="58">Venezuela (+58)</option>
                                                            <option value="84">Vietnam (+84)</option>
                                                            <option value="1284">Virgin Islands - British (+1284)</option>
                                                            <option value="1340">Virgin Islands - US (+1340)</option>
                                                            <option value="681">Wallis &amp;amp; Futuna (+681)</option>
                                                            <option value="969">Yemen (North)(+969)</option>
                                                            <option value="967">Yemen (South)(+967)</option>
                                                            <option value="260">Zambia (+260)</option>
                                                            <option value="263">Zimbabwe (+263)</option>
                                                        </select>
                                                        <div className="danger">{this.state.formErrors.countryCode !== '' ? this.state.formErrors.countryCode : ''}</div>
                                                    </div>
                                                    <div className="col-sm-6 p0">
                                                        <input type="Mobile" value={this.state.Mobile} onChange={this.changeHandel} className="form-control pro_input_pop" placeholder="123456789" name="Mobile" />
                                                        <div className="danger">{this.state.formErrors.Mobile !== '' ? this.state.formErrors.Mobile : ''}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="clear20"></div>
                                        <div className="col-sm-8">
                                            {this.state.changePassw !== "Can't Change" ?
                                                <button type="button" hreaf="javascript:void(0);" className="btn_change_pass_pro " data-toggle="modal" data-target="#changePasswordModal">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-9022 -414 13 17.063">
                                                        <path id="ic_lock_24px" className="cls-1" d="M15.375,6.688h-.812V5.063a4.063,4.063,0,0,0-8.125,0V6.688H5.625A1.63,1.63,0,0,0,4,8.313v8.125a1.63,1.63,0,0,0,1.625,1.625h9.75A1.63,1.63,0,0,0,17,16.438V8.313A1.63,1.63,0,0,0,15.375,6.688ZM10.5,14a1.625,1.625,0,1,1,1.625-1.625A1.63,1.63,0,0,1,10.5,14Zm2.519-7.312H7.981V5.063a2.519,2.519,0,0,1,5.038,0Z" transform="translate(-9026 -415)" />
                                                    </svg>
                                                    <span>Change Password</span>
                                                </button>
                                                : ''}
                                        </div>
                                    </form>
                                    <div className="clear30"></div>
                                    <div className="btn_cance_save2">
                                        <input name="" type="button" className="btn_save_pro" defaultValue="Save" onClick={this.submitUpdation} />
                                        <input name="" type="button" data-dismiss="modal" aria-label="Close" className="btn_cancel_pro" defaultValue="Cancel" />
                                    </div>
                                </div>
                                <div className="clear10"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade  modal_res_forg in" id="changePasswordModal" tabIndex="-1" role="dialog" aria-labelledby="changePasswordModalTitle" aria-hidden="false">
                    <div className="modal-backdrop fade in"></div>
                    <div className="modal-dialog modal-dialog-centered modla_edit_profile modal-dialog-centered popup_diaolg_top_mrg" role="document" >
                        <div className="modal-content forgotPasswordModalContent">
                            <div className="modal-header modal_header_register">
                                <button type="button" onClick={this.closeChangePasswordModal} className="close" data-dismiss="modal" aria-label="Close" id="changePasswordModalClose">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                        <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B"></path>
                                    </svg>
                                </button>
                            </div>
                            <div className="modal-body register_suc register_popup forgotPasswordPopupBody register_pd_apply_pop">
                                <div className=" forgotPasswordBoxContainer">
                                    <div className="clear10"></div>
                                    <div className="forgot_circle"><img src={ic_lock1_outline_24px} alt="" /></div>
                                    <h2> Forgot Password?</h2>
                                    <div className="clear10"></div>
                                    <div className="clear10"></div>
                                    <div className="register_box_mid register_box_mid2">
                                        <form>
                                            <div className="col-sm-12 p0">
                                                <input name="change_password_email" value={this.state.change_password_email}
                                                    onChange={this.handleUserInput} id="username" className="frogot_input_dashboard forgotPasswordInput" type="text" placeholder="Email Address" /><img src={ic_mail_outline_24px} width="15px" height="15px" className="register_icon1 forgotPasswordEmailIcon" alt="" />
                                            </div>
                                            <div className="clear30"></div>
                                            <button type="button" onClick={this.sendCode} className="LoaderButton btn_forogot btn_forogot_new btn btn-sm btn-default btn-block btn_dashboard_pop_proc">Proceed</button>
                                        </form>
                                        <div className="clear10"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade  modal_res_forg in" id="changePasswordPinCodeModal" tabIndex="-1" role="dialog" aria-labelledby="changePasswordModalTitle" aria-hidden="false">
                    <div className="modal-backdrop fade in" ></div>
                    <div className="modal-dialog modal-dialog-centered modla_edit_profile modal-dialog-centered popup_diaolg_top_mrg" role="document" >
                        <div className="modal-content forgotPasswordModalContent">
                            <div className="modal-header modal_header_register">
                                <button type="button" onClick={this.closeChangePasswordPinCodeModal} className="close" data-dismiss="modal" aria-label="Close" id="changePasswordPinCodeModalClose">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                        <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B"></path>
                                    </svg>
                                </button>
                            </div>
                            <div className="modal-body register_suc register_popup forgotPasswordPopupBody register_pd_apply_pop">
                                <div className=" forgotPasswordBoxContainer">
                                    <div className="clear10"></div>
                                    <div className="forgot_circle"><img src={ic_lock1_outline_24px} alt="" /></div>
                                    <h2> Forgot Password?</h2>
                                    <div className="clear10"></div>
                                    If you didnt receive the PIN Code, click on "Resend",
                  <br />otherwise fill in the code and click on "Next".
                  <div className="register_box_mid register_box_mid2">
                                        <div className="clear20"></div>
                                        <form>
                                            <div className="col-sm-12 p0">
                                                <input name="user_entered_code" autoFocus value={this.state.user_entered_code} onChange={this.handleUserInput} id="user_code" className="frogot_input_dashboard forgotPasswordInput" type="text" placeholder="Pin Code" /><img src={ic_vpn_key_24px} width="15px" height="15px" className="regist er_icon1 forgotPasswordEmailIcon" alt="" />
                                            </div>
                                            <div className="clear10"></div>
                                            <div className="col-sm-12 text-right"> <a onClick={this.resendCode} hreaf="javascript:void(0);" className="resend_code dash_mrg_resend">Resend code</a></div>
                                            <div className="clear10"></div>
                                            <button onClick={this.goto_enter_code_screen} type="button" className="LoaderButton btn_forogot btn_forogot_new btn btn-sm btn-default btn-block btn_dashboard_pop_proc" >Proceed</button>
                                        </form>
                                        <div className="clear10"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade modal_res_forg in" id="changePasswordFieldsCodeModal" tabIndex="-1" role="dialog" aria-labelledby="changePasswordModalTitle" aria-hidden="false">
                    <div className="modal-backdrop fade in" ></div>
                    <div className="modal-dialog modal-dialog-centered modla_edit_profile modal-dialog-centered popup_diaolg_top_mrg" role="document">
                        <div className="modal-content forgotPasswordModalContent">
                            <div className="modal-header modal_header_register">
                                <button type="button" onClick={this.closeChangePasswordFieldsCodeModal} className="close" data-dismiss="modal" aria-label="Close" id="changePasswordFieldsCodeModalClose">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                        <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B"></path>
                                    </svg>
                                </button>
                            </div>
                            <div className="modal-body register_suc register_popup forgotPasswordPopupBody register_pd_apply_pop">
                                <div className=" forgotPasswordBoxContainer">
                                    <div className="clear10"></div>
                                    <div className="forgot_circle"><img src={ic_lock1_outline_24px} alt="" /></div>
                                    <h2> Reset Your Password</h2>
                                    <div className="clear10"></div>
                                    Please enter the new password and confirm password.
                  <div className="register_box_mid register_box_mid2">
                                        <div className="clear20"></div>
                                        <form>
                                            <div className="col-sm-12 p0">
                                                <input name="prevPassword" value={this.state.prevPassword} onChange={this.handleUserInput} id="prevPassword" className="frogot_input_dashboard forgotPasswordInput" type="password" placeholder="Previous Password" /><img src={ic_lock_outline_24px} width="15px" height="15px" className="register_icon1 forgotPasswordEmailIcon" alt="" />
                                            </div>
                                            <div className="clear10"></div>
                                            <div className="col-sm-12 p0">
                                                <input name="newPassword" value={this.state.newPassword} onChange={this.handleUserInput} id="newPassword" className="frogot_input_dashboard forgotPasswordInput" type="password" placeholder="New Password" /><img src={ic_lock_outline_24px} width="15px" height="15px" className="register_icon1 forgotPasswordEmailIcon" alt="" />
                                            </div>
                                            <div className="clear10"></div>
                                            <div className="col-sm-12 p0">
                                                <input name="confirmPassword" value={this.state.confirmPassword} onChange={this.handleUserInput} id="confirmpassword" className="frogot_input_dashboard forgotPasswordInput" type="password" placeholder="Confirm Password" /><img src={ic_lock_outline_24px} width="15px" height="15px" className="register_icon1 forgotPasswordEmailIcon" alt="" />
                                            </div>
                                            <div className="clear30"></div>
                                            <button type="button" onClick={this.changePassword} className="LoaderButton btn_forogot btn_forogot_new btn btn-sm btn-default btn-block btn_dashboard_pop_proc">Change Password</button>
                                        </form>
                                        <div className="clear10"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modla_register popup_diaolg_top_mrg" role="document">
                        <div className="modal-content">
                            <div className="modal-header modal_header_register">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                        <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B"></path>
                                    </svg>
                                </button>
                            </div>
                            <div className="modal-body register_suc register_popup register_pd_apply_pop">
                                <img src={ic_check_circle_24px} width="47" height="47" alt="" />
                                <div className="clearfix"></div>
                                <h2> You have changed password successfully</h2>
                                <div className="clearfix"></div>
                                <a href="/dashboard" className="btn_ok_reg">OK</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Dashboard;
