import React, { Component } from "react";
import magnifying_glass from "./../../Assets/images/magnifying-glass.svg";
import minus_3 from "./../../Assets/images/minus_3.png";
import {getKeys} from './../tempkeys.js'
import './DailyTimes.css';
import cross_all_3 from "./../../Assets/images/cross_all_3.png";
import img_left_right_icon from "./../../Assets/images/img_left_right_icon.png";
import charge_sheet from "./../../Assets/images/charge_sheet.png";
import DatePicker from "react-datepicker";
import next_icon from "./../../Assets/images/next_icon.png";
import sign1 from "./../../Assets/images/sign1.jpg";
import clock from "./../../Assets/images/clock.png";
import select_paste from "./../../Assets/images/select_paste.png";
import excel from "./../../Assets/images/excel.png";
import attach_icon_user from "./../../Assets/images/attach_icon_user.png";
import { Link } from "react-router-dom";
import "./TimeCards.css";
import TimePicker from "rc-time-picker";
import TimeCardHeader from "../Common/TimeCard_Header/TimeCard_Header";
import { toast } from "react-toastify";
import $ from "jquery";
import HeaderMob from "../../MobContainers/Common/HeaderMob/HeaderMob";
import { API, Auth } from "aws-amplify";
import Amplify, { Storage } from "aws-amplify";
import Dropzone from "react-dropzone";
import moment from "moment";
import "moment-timezone";
import { array } from "prop-types";
import htmlToImage from 'html-to-image';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

import { get } from "https";
import PayEndingFinal_Mob from "../../MobContainers/PayEndingFinal_Mob/PayEndingFinal_Mob";
var ip = require("ip");
//import Auth from '@aws-amplify/auth';
//import Lambda from 'aws-sdk/clients/lambda'; // npm install aws-sdk
const AWS = require("aws-sdk");
let width = window.screen.availWidth;
let height = window.screen.availHeight;
AWS.config.update({
  accessKeyId: "AKIAIJYISGDLZALHV4YA",
  secretAccessKey: "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",
  region: "us-east-1"
});
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: "us-east-1:83d16e3c-9a43-4f34-83e1-6bb3f5a7ce8b"
});
const images = [charge_sheet, charge_sheet];
var payas_selected;
var xx = "";
var pys = "";
let imageStyle = { width: 740, height: 370 };
class TimeCards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredBatches: [],
      mob_screen_flag: true,
      web_screen_flag: false,
      div_to_image:"",
      checkAllMobTimecard: false,
      isMobView: false,
      showTimecards: true,
      image_payslip_path: [],
      anflag: false,
      mobtype: "",
      simplemessage: false,
      payslips: [],

      clone_photoIndex: 0,
      flagcheck: false,
      exceptionlist: [
        {
          date: "2019-09-09",
          time: "9:11AM",
          exception: "Car Allowance paid last week for 75"
        },
        {
          date: "2019-09-09",
          time: "9:11AM",
          exception: "Bank details have changed from last week for 2"
        }
      ],

      batches_array: [],
      changes: [],
      approve_batch_number: "",
      pending_batch_number: "",
      approved_batch_number: "",
      all_batch_number: "",
      Batches: [],
      tmp_img: [],
      selected_approver_object: "",
      sendMessageAttachment: "",
      empGroupGuid: [],
      approver_object: [],
      notetmp: "",
      payflag: true,
      adflag: true,
      orgflag: true,
      adTimesPayas: "",
      //approve, pending, etc, counts show on header
      mtd: false,
      counts: {
        approveCount: 0,
        pendingCount: 0,
        approvedCount: 0,
        allCount: 0
      },
      prlObject: {},
      adTimesObject: {},
      active_mob_screen: false,
      setupEmpApp: [],
      login_app_index: "",
      disableMealbreak: false,
      setup_against_tab: [],
      trackingFields: [],
      customFields: [],
      imageWidth: 0,
      clone_current: "",
      payas: "",
      selectedIndex: "",
      selectedobj: [],
      obj: {},
      activeObject: {},
      activeObject: {},
      imageHeight: 0,
      timeCardChecked: [],
      isLoading: false,
      load: true,
      employeeData: [],
      empDetail: "",
      PayasIndex: "",
      PayasList: [
        { Flag: "CON", Value: "Worked" },
        { Flag: "TIL", Value: "Time in Lieu Day" },
        { Flag: "DNW", Value: "Did Not Work" },
        { Flag: "CON", Value: "Public Holiday Taken" },
        { Flag: "PUB", Value: "Public Holiday Worked" },
        {
          Flag: "SIC",
          Value: "Sick"
        },
        {
          Flag: "HOL",
          Value: "Annual Leave Taken"
        },
        {
          Flag: "OTH",
          Value: "Other Leave"
        },
        {
          Flag: "WCP",
          Value: "Workers Compensation"
        }
      ],

      showMobileHeader: false,

      timeCards_search: "",
      timeCards_searchApprove: "",
      timeCards_searchApproved: "",
      timeCards_MDdeclineReason: "",
      timeCards_declineReason: "",
      declineOptions: [],
      timeCards_selectDeclineToValue: "",
      timeCards_selectDecline: false,
      timeCards_comment: "",
      timeCards_mobile_comment: "",
      timeCards_NMsendToOptions: [
        { id: "1", Name: "Eclipse" },
        { id: "2", Name: "Eclipse Sky" },
        { id: "3", Name: "Plummer" }
      ],
      timeCards_NMselectSendToValue: "",
      timeCards_NMselectSendTo: false,
      timeCards_NMsubject: "",
      timeCards_NMmessage: "",
      timeCards_NMattachment: "",
      timeCards_modifyOptions: [
        { id: "1", Name: "Batch1" },
        { id: "2", Name: "Batch2" },
        { id: "3", Name: "Batch3" }
      ],
      timeCards_selectmodifyValue: null,
      timeCards_selectmodify: false,
      timeCards_editPayAs: "",
      timeCards_editTravelTo: "",
      timeCards_editStartWork: "",
      timeCards_editMealBreak: "",
      timeCards_editStartMB1: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      timeCards_editFinishMB1: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      timeCards_editStartMB2: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      timeCards_editFinishMB2: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      timeCards_editStartMB3: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      timeCards_editFinishMB3: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      AdTimes2_EditModal_MealBreak_total: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      selectedTab: "approve",

      timeCards_NonDeductibleMB1: {
        originalCheckMB1: false,
        adCheckMB1: false,
        payCheckMB1: false
      },
      timeCards_NonDeductibleMB2: {
        originalCheckMB2: false,
        adCheckMB2: false,
        payCheckMB2: false
      },
      timeCards_NonDeductibleMB3: {
        originalCheckMB3: false,
        adCheckMB3: false,
        payCheckMB3: false
      },

      timeCards_editFinishWork: "",
      timeCards_editTravelFrom: "",
      timeCards_editTotalHours: "",
      timeCards_editRate: "",
      timeCards_editNotes: "",
      timeCards_editTemplate: "",
      timeCards_editPaymentFlag: "",
      timeCards_editInternalNote: "",
      timeCards_editChartSort: "",
      timeCards_editChartCode: "",
      timeCards_editWorkState: "",
      timeCards_editTX: "",
      timeCards_editIN: "",
      timeCards_editF1: "",
      timeCards_editF2: "",
      timeCards_editF3: "",
      timeCards_editF4: "",
      timeCards_editF5: "",
      timeCards_editF6: "",
      timeCards_editF7: "",
      timeCards_editF8: "",

      timeCards_NMselectSendToValid: false,
      timeCards_NMsubjectValid: false,
      timeCards_NMmessageValid: false,
      timeCards_selectmodifyValid: false,

      newMessageErrors: {
        timeCards_NMselectSendToErr: "",
        timeCards_NMsubjectErr: "",
        timeCards_NMmessageErr: ""
      },
      modifyErrors: {
        timeCards_selectmodifyErr: ""
      },

      newMessage_formValid: false,
      modify_formValid: false,
      photoIndex: 0,
      isOpen: false,

      attachments: [],

      //comments
      comments: [],
      empGuid: "", //current active emp Guid
      editCheckboxesMob: false,
      isEdit: false,
      timeCards_searched_data: [],

      activities: [], //activities
      exceptions: [], //exceptions

      showHideChanges: false,
      showHideComments: false,
      showHideExceptions: false,
      showHideAttachments: false,
      showHideActivity: false
    };
  }
  componentWillMount = async () => {
    
    await this.getUserSettings();
    if (this.state.showTimecards === false) {
      this.props.history.push("/dashboard");
    }
  };
  getUserSettings = async () => {
    let userGuid = localStorage.getItem("userGuid");
    await API.post("getuserbyguid", "/getuserbyguid", {
      body: {
        user_id: userGuid
      }
    })
      .then(data => {
        this.setState({
          showTimecards: data.access.Timecards
        });
      })
      .catch(err => {
        console.log("error in fetching user");
      });
  };
  downloadPayslips = e => {
    if (this.state.image_payslip_path.length) {
       if (this.state.image_payslip_path[this.state.photoIndex].filename == 'static') 
       { 
        document.getElementById('image_download_a').click();
        }
        else{
            window.location = this.state.image_payslip_path[this.state.photoIndex].fileurl;
        }
    }
  };

  componentDidMount = async () => {
    var that = this;
    if (window.screen.availWidth < 768 && that.state.mob_screen_flag) {
      that.setState({
        mob_screen_flag: false,
        web_screen_flag: true
      });
    }
    $(window).bind("resize", function (e) {
      if (window.screen.availWidth > 767 && that.state.web_screen_flag) {
        that.setState({
          active_mob_screen: false,
          mob_screen_flag: true,
          web_screen_flag: false
        });
        that.get_emp_details(that.state.empDetail.Guid, that.state.selectedTab);
        // if (window.RT) clearTimeout(window.RT);
        // window.RT = setTimeout(function()
        // {
        // this.location.reload(false); / false to get page from cache /
        // }, 100);
      } else if (window.screen.availWidth < 768 && that.state.mob_screen_flag) {
        that.setState({
          active_mob_screen: true
        });
        that.get_emp_details(that.state.empDetail.Guid, that.state.selectedTab);
        that.setState({
          mob_screen_flag: false,
          web_screen_flag: true
        });
      }
    });

    await this.props.childProps.isAuthentication();
    if (!this.props.childProps.isAuthenticated) {
      this.props.history.push("/login");
    } else {
      await this.setState({
        isLoading: true
      });
      await this.props.childProps.getSettings();
      this.getScreenResolution();
      if (this.props.location.state) {
        if (this.props.location.state.state.tabindex === "approved") {
          $("#apprvd").addClass("active");
          $("#appr_ove").removeClass("active");
        }
      }

      $(document).click(function (e) {
        if (
          (e.target.className === "modal-backdrop fade in" ||
            e.target.className === "modal-backdrop fade in mdl_incrs") &&
          $("#name_details_pencil").css("display") === "block"
        ) {
          $("body").css("overflow", "hidden");
          $("#name_details").css("overflow-y", "auto");
        } else if (
          e.target.className === "modal-backdrop fade in" &&
          $("#name_details").css("display") === "block"
        ) {
          $("body").removeAttr("style");
        }
      });

      await this.get_prlemp(this.state.selectedTab);

      this.setState({
        isLoading: false,
        payflag: this.props.childProps.setting_SCTCheck,
        adflag: this.props.childProps.setting_SATCheck
      });
    }
  };
  getScreenResolution = () => {
    if (width < 768) {
      this.setState({
        active_mob_screen: true,
        isMobView: true
      });
    }
  };
  getDetailMob = () => {
    this.setState({
      active_mob_screen: false
    });
  };
  onClickBackArrowMob = () => {
    this.setState({
      active_mob_screen: true
    });
  };
  onClickBackArrowHeaderMob = () => {
    this.setState({
      editCheckboxesMob: false,
      isEdit: false
    });
  };
  loadimage = ({ target: img }) => { };
  zoomin = () => {
    if (imageStyle.width < 850) {
      imageStyle.width = imageStyle.width + 25;
      imageStyle.height = imageStyle.height + 80;
      this.setState({
        imageWidth: imageStyle.width,
        imageHeight: imageStyle.height
      });
    }
  };
  zoomout = () => {
    if (imageStyle.width > 730 && imageStyle.height > 290) {
      imageStyle.width = imageStyle.width - 25;
      imageStyle.height = imageStyle.height - 80;
      this.setState({
        imageWidth: imageStyle.width,
        imageHeight: imageStyle.height
      });
    }
  };
  changeImgSize = e => {
    let size = e.target.value;

    if (size == 100) {
      imageStyle.width = 865;
      imageStyle.height = 770;
      this.setState({
        imageWidth: imageStyle.width,
        imageHeight: imageStyle.height
      });
    } else if (size == 75) {
      imageStyle.width = 815;
      imageStyle.height = 610;
      this.setState({
        imageWidth: imageStyle.width,
        imageHeight: imageStyle.height
      });
    } else if (size == 50) {
      imageStyle.width = 765;
      imageStyle.height = 450;
      this.setState({
        imageWidth: imageStyle.width,
        imageHeight: imageStyle.height
      });
    } else {
      imageStyle.width = 730;
      imageStyle.height = 290;
      this.setState({
        imageWidth: imageStyle.width,
        imageHeight: imageStyle.height
      });
    }
  };
  leftkey = () => {
    let arry = this.state.employeeData.filter(val => {
      return val.Guid == this.state.empDetail.Guid;
    });

    let y = null;
    this.state.employeeData.map((data, index) => {
      if (data.Guid == arry[0].Guid) {
        y = index;
      }
    });

    if (y) {
      if (this.state.employeeData[y - 1]) {
        let x = this.state.employeeData[y - 1];
        this.get_emp_details(x.Guid, this.state.selectedTab);
      }
    }
  };
  rightkey = () => {
    let arry = this.state.employeeData.filter(val => {
      return val.Guid == this.state.empDetail.Guid;
    });

    let y = null;
    this.state.employeeData.map((data, index) => {
      if (data.Guid == arry[0].Guid) {
        y = index;
      }
    });

    if (y != null) {
      if (this.state.employeeData[y + 1]) {
        let x = this.state.employeeData[y + 1];
        this.get_emp_details(x.Guid, this.state.selectedTab);
      }
    }
  };

  get_setup_against_record = async array => {
    await API.post("setupsfortimecard", "/setupsfortimecard", {
      body: {
        appSetupGuid: array
      }
    })
      .then(data => {
        this.setState({
          setup_against_tab: data
        });
      })
      .catch(error => {
        console.log("Setup Not Fetched");
      });
  };

  get_prlemp = async (tab = this.state.selectedTab) => {
    $("#freshbtn").addClass("moving");
    $(".horizontal_list_tab li a").click(function () {
      var recent_li = $(this).attr("href");
      $(".carousel-inner > div.item").removeClass("active");
      $(recent_li + " .slider_pdf_viewer .carousel-inner > div.item")
        .first()
        .addClass("active");
    });

    this.setState({
      load: true
    });

    let userGuid = localStorage.getItem("userGuid");
    let filterData = [];
    var final_array = [];
    let originaldata_for_count = [];

    var data=[];
    var bodypost={
      tenant: localStorage.getItem("useableGuid")
      };

      var lastflag=false;
      while(lastflag===false){await API.post("getallprlemp", "/getallprlemp", {
          body: bodypost
      })
      .then(response => {
        response.Items.map(a=>{
          data.push(a);
        })
        if (response.LastEvaluatedKey) { 
          bodypost.LastEvaluatedKey = response.LastEvaluatedKey
          } else {
            lastflag = true
          }
      })
      .catch(err => console.log(err));
      }




      data.map(item => {
        item.checked = false;
        item.CheckBoxID = item.Guid;
      });
      originaldata_for_count = data;
      
      filterData = data.filter(item => item.approve_status !== "Draft");
      
      if (tab == "approve") {
        filterData = filterData.filter(o => {
          return (
            userGuid == o.current_approver && o.approve_status !== "Approved"
          );
        });
        filterData.map(item => {
          if (item.approver_chain.indexOf(userGuid) > -1) {
            final_array.push(item);
          }
        });
      }
      if (tab == "pending") {
        filterData = filterData.filter(
          item => item.approve_status === "Pending"
        );
      
        filterData.map(item => {
          var index = item.approver_chain.indexOf(userGuid);
          var cindex = item.approver_chain.indexOf(item.current_approver);
      
          if (
            item.approver_chain.indexOf(userGuid) > -1 ||
            userGuid === item.senderid
          ) {
            if (index < cindex || userGuid === item.senderid) {
              final_array.push(item);
            }
          }
        });
      }
      if (tab == "approved") {
        filterData = filterData.filter(
          item => item.approve_status === "Approved"
        );
      
        filterData.map(item => {
          if (
            item.approver_chain.indexOf(userGuid) > -1 ||
            userGuid === item.senderid
          ) {
            final_array.push(item);
          }
        });
      }
      if (tab == "all") {
        filterData.map(item => {
          var index = item.approver_chain.indexOf(userGuid);
          var cindex = item.approver_chain.indexOf(item.current_approver);
      
          if (
            item.approver_chain.indexOf(userGuid) > -1 ||
            userGuid === item.senderid
          ) {
            if (index <= cindex || userGuid === item.senderid) {
              final_array.push(item);
            }
          }
        });
      }



    const newArray = [];

    filterData.map(item => {
      newArray.push(item.group_guid);
    });

    if (newArray.length > 0) {
      await this.get_setup_against_record(newArray);
    } else {
      await this.setState({
        setup_against_tab: []
      });
    }

    await this.props.getCounts(originaldata_for_count);
    await this.setState({
      load: false,
      employeeData: final_array,
      timeCards_searched_data: final_array
    });
    if (this.state.employeeData.length > 0) {
      await this.get_emp_details(this.state.employeeData[0].Guid, tab);
    } else {
      await this.setState({
        empDetail: ""
      });
    }

    let finalBatch = [];
    this.state.employeeData.map(val => {
      var x = finalBatch.filter(vals => {
        return val.Batch === vals;
      });
      if (x.length === 0 && val.Batch !== null) {
        finalBatch.push(val.Batch);
      }
    });

    await this.setState({
      batches_array: finalBatch
    });

    // API call for to get the batches data
    await API.post("getbatch", "/getbatch", {
      body: {
        tenant: localStorage.getItem("useableGuid")
      }
    }).then(async data => {
      let btch = await data.filter((b) => {
        return finalBatch.includes(b.Batch)
      })
      this.setState({ Batches: data, filteredBatches: btch });
    });

    $("#freshbtn").removeClass("moving");
  };

  get_emp_details = async (empid, tab) => {
    this.setState({
      isLoading: true
    });

    

    $("#" + this.state.empDetail.Guid + tab).removeClass("active");
    if (this.state.empDetail === "") {
      $("#" + empid + tab).removeClass("active");
    }
    await API.post("prlempbyid", "/prlempbyid", {
      body: {
        id: empid
      }
    }).then(data => {
      data.customeGuid = data.Guid + tab;

      this.setState({
        payslips: data.Payslip,
        empDetail: data,
        photoIndex: 0,
        clone_photoIndex: 0,
        image_payslip_path: data.Payslip
          ? data.Payslip.length > 0
            ? data.Payslip
            : []
          : [],
        empGuid: data.Guid, //to find Guid which is active
        empGroupGuid: data.approver_chain, //to find Group Guid which is active
        comments: data.comments ? data.comments : [],
        changes: data.changes ? data.changes : [],
        activities: data.activities ? data.activities : [],
        exceptions: data.exceptions
          ? data.exceptions.length > 0
            ? data.exceptions
            : []
          : []
      });
    });
    
    if (this.state.empDetail !== "") {
      $("#" + empid + tab).addClass("active");
      var s = this.state.empDetail.PayTimes.Company
        ? this.state.empDetail.PayTimes.Company[0].Payas.filter(x => {
          return x.Flag === this.state.empDetail.PaymentFlags;
        })
        : "NA";
      this.setState({ payas: s });
    }
    let currentguid = this.state.empDetail.group_guid;
    var ss = this.state.setup_against_tab;

    await this.setState({
      setupEmpApp: ss.filter(item => currentguid === item.Guid)
     
    });
      
    if (this.state.empDetail.PayTimes
      && this.state.empDetail.PayTimes.Times
      && this.state.empDetail.PayTimes.Times[0].DailyTimes) {
  

     
        await this.setState({

          image_payslip_path:[...this.state.image_payslip_path,
                            {"fileurl":this.state.empDetail.timesheetImage,
                            "filename":"static",
                            "mimetype":"jpg",
                            "size":"12345"
                            }
                             ],
          
        });
       
        
    }
           
       
if(tab == "approve"){
  $(".pending_active").removeClass('active');
  $(".approved_active").removeClass('active');
  $(".all_active").removeClass('active');
  $(".approve_active").addClass('active');
}
if(tab == "pending"){
  $(".approved_active").removeClass('active');
  $(".all_active").removeClass('active');
  $(".approve_active").removeClass('active');
    $(".pending_active").addClass('active');
}
if(tab == "approved"){
  $(".pending_active").removeClass('active');
  $(".all_active").removeClass('active');
  $(".approve_active").removeClass('active');
    $(".approved_active").addClass('active');
}
if(tab == "all"){
  $(".pending_active").removeClass('active');
  $(".approved_active").removeClass('active');
  $(".approve_active").removeClass('active');
    $(".all_active").addClass('active');
}





  
  
           
  
  
    this.setState({
      isLoading: false
    })
  };

  // to check how much counts in Approve, pending, Approved and All in header
  /*  count = data => {
      let userGuid = localStorage.getItem("userGuid");
      let final_array = [];
      let final_array_pending = [];
      let final_array_approved = [];
      let final_array_all = [];
  
      data = data.filter(item => item.approve_status !== "Draft");
  
      let countObj = {};
      // for approve count
      let approveFilterData = data.filter(o => {
        return userGuid == o.current_approver && o.approve_status !== "Approved";
      });
      approveFilterData.map(item => {
        if (item.approver_chain.indexOf(userGuid) > -1) {
          final_array.push(item);
        }
      });
  
      countObj.approveCount = final_array.length;
  
      // for pending count
      let pendingFilterData = data.filter(
        item => item.approve_status === "Pending"
      );
      pendingFilterData.map(item => {
        var index = item.approver_chain.indexOf(userGuid);
        var cindex = item.approver_chain.indexOf(item.current_approver);
  
        if (
          item.approver_chain.indexOf(userGuid) > -1 ||
          userGuid === item.senderid
        ) {
          if (index < cindex || userGuid === item.senderid) {
            final_array_pending.push(item);
          }
        }
      });
  
      countObj.pendingCount = final_array_pending.length;
      // for approved count
      let approvedFilterData = data.filter(
        item => item.approve_status === "Approved"
      );
      approvedFilterData.map(item => {
        if (
          item.approver_chain.indexOf(userGuid) > -1 ||
          userGuid === item.senderid
        ) {
          final_array_approved.push(item);
        }
      });
      countObj.approvedCount = final_array_approved.length;
  
      data.map(item => {
        var index = item.approver_chain.indexOf(userGuid);
        var cindex = item.approver_chain.indexOf(item.current_approver);
  
        if (
          item.approver_chain.indexOf(userGuid) > -1 ||
          userGuid === item.senderid
        ) {
          if (index <= cindex || userGuid === item.senderid) {
            final_array_all.push(item);
          }
        }
      });
      //for all count
      countObj.allCount = final_array_all.length;
  
      this.setState({ counts: countObj });
    };*/

  timeCards_inputHandler = e => {
    if (
      e.target.name === " timeCards_editRate" ||
      e.target.name === "timeCards_editNotes" ||
      e.target.name === " timeCards_editPaymentFlag" ||
      e.target.name === "timeCards_editInternalNote"
    ) {
      this.setState({
        flagcheck: true
      });
    }

    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({
      [name]: value
    });
  };
  timeCards_checkBoxHandler = e => {
    let name = e.target.name;
    let isChecked = e.target.checked;
    if (
      name === "originalCheckMB1" ||
      name === "adCheckMB1" ||
      name === "payCheckMB1"
    ) {
      let timeCards_NonDeductibleMB1 = {
        ...this.state.timeCards_NonDeductibleMB1
      };
      timeCards_NonDeductibleMB1[name] = isChecked;
      this.setState({
        timeCards_NonDeductibleMB1: timeCards_NonDeductibleMB1
      });
    }

    if (
      name === "originalCheckMB2" ||
      name === "adCheckMB2" ||
      name === "payCheckMB2"
    ) {
      let timeCards_NonDeductibleMB2 = {
        ...this.state.timeCards_NonDeductibleMB2
      };
      timeCards_NonDeductibleMB2[name] = isChecked;
      this.setState({
        timeCards_NonDeductibleMB2: timeCards_NonDeductibleMB2
      });
    }

    if (
      name === "originalCheckMB3" ||
      name === "adCheckMB3" ||
      name === "payCheckMB3"
    ) {
      let timeCards_NonDeductibleMB3 = {
        ...this.state.timeCards_NonDeductibleMB3
      };
      timeCards_NonDeductibleMB3[name] = isChecked;
      this.setState({
        timeCards_NonDeductibleMB3: timeCards_NonDeductibleMB3
      });
    }

    this.find_diff();
  };

  newMessage_selectHandler = e => {
    let resultBox = document.getElementById("timeCards_NMselectSendTo");
    let newindex = resultBox.options["selectedIndex"];

    this.setState({
      selected_approver_object: this.state.approver_object
        ? this.state.approver_object[newindex - 1]
        : ""
    });
    let name = e.target.name;
    let value = e.target.value;

    if (e.target.selectedIndex > 0) {
      this.setState(
        {
          timeCards_NMselectSendToValue: e.target.value,
          timeCards_NMselectSendTo: true,
          timeCards_NMselectSendToValid: true
        },
        () => {
          this.newMessage_validateField(name, value);
        }
      );
    } else if (e.target.selectedIndex === 0) {
      this.setState(
        {
          timeCards_NMselectSendToValue: "",
          timeCards_NMselectSendTo: false,
          timeCards_NMselectSendToValid: false
        },
        () => {
          this.newMessage_validateField(name, value);
        }
      );
    }
  };
  newMessage_inputHandler = e => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState(
      {
        [name]: value
      },
      () => {
        this.newMessage_validateField(name, value);
      }
    );
  };
  newMessage_validateField(fieldName, value) {
    let newMessageErrors = this.state.newMessageErrors;
    let timeCards_NMselectSendToValid = this.state
      .timeCards_NMselectSendToValid;
    let timeCards_NMsubjectValid = this.state.timeCards_NMsubjectValid;
    let timeCards_NMmessageValid = this.state.timeCards_NMmessageValid;

    switch (fieldName) {
      case "timeCards_NMselectSendTo":
        timeCards_NMselectSendToValid = this.state
          .timeCards_NMselectSendToValid;
        newMessageErrors.timeCards_NMselectSendToErr = timeCards_NMselectSendToValid
          ? ""
          : "Please Select Option";
        break;
      case "timeCards_NMsubject":
        timeCards_NMsubjectValid = value.length > 0;
        newMessageErrors.timeCards_NMsubjectErr = timeCards_NMsubjectValid
          ? ""
          : "This field is required";
        break;
      case "timeCards_NMmessage":
        timeCards_NMmessageValid = value.length > 0;
        newMessageErrors.timeCards_NMmessageErr = timeCards_NMmessageValid
          ? ""
          : "This field is required";
        break;

      default:
        break;
    }

    this.setState(
      {
        newMessageErrors: newMessageErrors,
        timeCards_NMselectSendToValid: timeCards_NMselectSendToValid,
        timeCards_NMsubjectValid: timeCards_NMsubjectValid,
        timeCards_NMmessageValid: timeCards_NMmessageValid
      },
      this.newMessage_validateForm
    );
  }
  newMessage_validateForm = () => {
    this.setState({
      newMessage_formValid:
        this.state.timeCards_NMselectSendToValid &&
        this.state.timeCards_NMsubjectValid &&
        this.state.timeCards_NMmessageValid
    });
  };
  modify_selectHandler = async e => {
    let name = e.target.name;
    let value = e.target.value;

    if (e.target.selectedIndex > 0) {
      await this.setState(
        {
          timeCards_selectmodifyValue: e.target.value,
          timeCards_selectmodify: true,
          timeCards_selectmodifyValid: true
        },
        () => {
          this.modify_validateField(name, value);
        }
      );
    } else if (e.target.selectedIndex === 0) {
      this.setState(
        {
          timeCards_selectmodifyValue: null,
          timeCards_selectmodify: false,
          timeCards_selectmodifyValid: false
        },
        () => {
          this.modify_validateField(name, value);
        }
      );
    }
  };
  modify_validateField(fieldName, value) {
    let modifyErrors = this.state.modifyErrors;
    let timeCards_selectmodifyValid = this.state.timeCards_selectmodifyValid;

    switch (fieldName) {
      case "timeCards_selectmodify":
        timeCards_selectmodifyValid = this.state.timeCards_selectmodifyValid;
        modifyErrors.timeCards_selectmodifyErr = timeCards_selectmodifyValid
          ? ""
          : "Please Select Option";
        break;

      default:
        break;
    }

    this.setState(
      {
        modifyErrors: modifyErrors,
        timeCards_selectmodifyValid: timeCards_selectmodifyValid
      },
      this.modify_validateForm
    );
  }
  modify_validateForm = () => {
    this.setState({
      modify_formValid: this.state.timeCards_selectmodifyValid
    });
  };
  modify_submitHandler = async () => {
    if (this.state.modify_formValid) {
    }
    let modifyErrors = this.state.modifyErrors;

    if (!this.state.timeCards_selectmodifyValid) {
      modifyErrors.timeCards_selectmodifyErr = "Please Select Option";
    }
    this.setState({
      modifyErrors: modifyErrors
    });
    if (this.state.timeCards_selectmodifyValue !== null) {
      this.setState({
        isLoading: true
      });
      this.state.empDetail.activities.push({
        date: moment(Date.now()).format("l h:mma"),
        msg: localStorage.getItem("UserFullName") + " has modify the timecard"
      });
      var empDetailModify = {
        ADTimes: this.state.empDetail.ADTimes,
        Batch: parseInt(this.state.timeCards_selectmodifyValue),
        ChartCode: this.state.empDetail.ChartCode,
        ChartSort: this.state.empDetail.ChartSort,
        ChequeNumber: this.state.empDetail.ChequeNumber,
        Employee: this.state.empDetail.Employee,
        Flags: this.state.empDetail.Flags,

        InternalNotes:
          this.state.timeCards_editInternalNote == ""
            ? null
            : this.state.timeCards_editInternalNote,
        PRLTimes: this.state.empDetail.PRLTimes,
        PayTimes: this.state.empDetail.PayTimes,
        PayWeek: this.state.empDetail.PayWeek,
        PaymentFlags:
          this.state.empDetail.PaymentFlags == ""
            ? null
            : this.state.empDetail.PaymentFlags,
        PayslipNote:
          this.state.empDetail.PayslipNote === ""
            ? null
            : this.state.empDetail.PayslipNote,
        PeriodEndingDate: this.state.empDetail.PeriodEndingDate,
        TenantGuid: this.state.empDetail.TenantGuid,
        WorkState: this.state.empDetail.WorkState,
        approve_status: "Draft",
        approver_chain: this.state.empDetail.approver_chain,
        comments: this.state.empDetail.comments,
        changes: this.state.empDetail.changes,
        activities: this.state.empDetail.activities,
        current_approver: "No user",
        freelanceuser: this.state.empDetail.freelanceuser,
        group_guid: this.state.empDetail.group_guid,
        senderid: null,
           timesheetImage:this.state.empDetail.timesheetImage?this.state.empDetail.timesheetImage:null,
           freelanceuser_email:this.state.empDetail.freelanceuser_email?this.state.empDetail.freelanceuser_email:null,
freelanceuser_signature:this.state.empDetail.freelanceuser_signature?this.state.empDetail.freelanceuser_signature:null
      };
      document.getElementById("modifyCloseModel").click();

      await API.post("createprlemp", "/createprlemp", {
        body: empDetailModify
      })
        .then(obj => {
          console.log(obj);
        })
        .catch(err => {
          console.log(err);
        });

     // toast.success("your data has been modify successfully");
      this.setState({
        isLoading: false,
        timeCards_selectmodifyValue: null
      });
    } else {
      toast.error("please select a valid option");
    }
  };

  handleModal_Height = () => {
    if (!$("#btn_cllps").hasClass("collapsed")) {
      $(".modal-backdrop").addClass("mdl_incrs");
    } else if ($("#btn_cllps").hasClass("collapsed")) {
      $(".modal-backdrop").removeClass("mdl_incrs");
    }
  };

  issue_ScrollHandler = e => {
    $("body").css("overflow", "hidden");
    $("#name_details").css("overflow-y", "auto");

    this.setState({
      timeCards_editStartMB1: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      timeCards_editFinishMB1: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      timeCards_editStartMB2: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      timeCards_editFinishMB2: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      timeCards_editStartMB3: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      timeCards_editFinishMB3: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      edit_paytimes_date: "",
      edit_paytimes_day: "",
      edit_paytimes_department: "",
      customFields: [],
      trackingFields: [],
      edit_paytimes_position: "",
      edit_paytimes_payas: "",
      edit_paytimes_travelto: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      edit_paytimes_start: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      edit_paytimes_total_meal: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      edit_paytimes_finish: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      edit_paytimes_travelfrom: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      edit_paytimes_totalhours: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      timeCards_editChartCode: "",
      timeCards_editChartSort: "",
      timeCards_editWorkState: "",
      timeCards_editInternalNote: ""
    });
  };
  after_issueScrollHandler = e => {
    $("body").removeAttr("style");
  };

  MobileCommentsScreen = () => {
    $(".timecard_mrg_top_less").css("display", "none");
    $("#timecard_sidee_modal_mobile").css("display", "none");
    $(".commentsMobileScreen").css("display", "block");

    this.setState({
      showMobileHeader: true
    });
  };

  showExceptionsMobile = () => {
    $(".timecard_mrg_top_less").css("display", "none");
    $("#timecard_sidee_modal_mobile").css("display", "none");
    $(".exceptionMobileScreen").css("display", "block");
    this.setState({
      showMobileHeader: true
    });
  };

  showDeclinetimecardMob = () => {
    $(".timecard_mrg_top_less").css("display", "none");
    $(".declinetimecardMobileScreen").css("display", "block");
    this.diclineData();
    this.setState({
      showMobileHeader: true
    });
  };
  handleDeclineTimecardBack = () => {
    $(".declinetimecardMobileScreen").css("display", "none");
    $(".timecard_mrg_top_less").css("display", "block");

    this.setState({
      showMobileHeader: false
    });
  };

  handleImageSize = () => {
    this.setState({
      isOpen: true
    });
  };

  timeCards_searchHandler = event => {
    var text = event.target.value;
    this.setState({
      timeCards_search: event.target.value
    });

    let timeCards_searched_data = [];
    if (!text) {
      timeCards_searched_data = this.state.employeeData;
    } else {
      timeCards_searched_data = this.state.employeeData.filter(function (setup) {
        return (
          setup.Employee[0].FirstName.toUpperCase().includes(
            text.toUpperCase()
          ) ||
          setup.Employee[0].LastName.toUpperCase().includes(text.toUpperCase())
        );
      });
    }

    this.setState({ timeCards_searched_data });
  };
  edit_employeeData = async (index, x) => {
    var payas_selectedq;

    var convertedDate = new Date(x.Date * 1000).toLocaleDateString("en-GB");
    if (this.state.empDetail.PayTimes.Times) {
      var xy = x.Day;
      var day = xy.substring(0, 3);
      var s = this.state.empDetail.Employee[0].PaymentFlags[0][day];

      this.state.empDetail.ADTimes.map(value => {
        if (
          new Date(value.adlistdate).toLocaleDateString() ==
          new Date(x.Date * 1000).toLocaleDateString()
        ) {
          if (value.PaymentFlag !== null) {
            s = s + "," + value.PaymentFlag;
          }
        }
      });

      let t = "";
      if (x.Day === "Mon") {
        this.state.empDetail.PayTimes.Times[0].CustomTimeFields.map(
          (value, index) => {
            if (value.Flag !== null) {
              t = t + "," + value.Flag;
            }
          }
        );

        s = s + "" + t;
      }

      this.state.empDetail.PayTimes.Company[0].Payas.map(item => {
        if (item.Value === x.Payas) {
          payas_selectedq = item.Value;
        }
      });

      await this.setState({
        selectedobj: x,
        timeCards_editInternalNote:
          x.InternalNotes === null ? "" : x.InternalNotes,
        timeCards_editPaymentFlag: s === null ? "" : s,
        selectedIndex: index,
        edit_paytimes_date: convertedDate,
        edit_paytimes_day: x.Day,
        customFields: this.state.empDetail.PayTimes.Times[0].CustomTimeFields,
        edit_paytimes_department: this.state.empDetail.PayTimes.Dept,
        edit_paytimes_position: this.state.empDetail.PayTimes.Position,
        timeCards_editChartCode: this.state.empDetail.ChartCode,
        timeCards_editChartSort: this.state.empDetail.ChartSort,
        timeCards_editWorkState: this.state.empDetail.WorkState,
        edit_paytimes_payas: payas_selectedq,
        edit_paytimes_travelto: x.Travel1,
        timeCards_editStartMB1: x.StartMeal,
        timeCards_editStartMB2: x.StartMeal2,
        timeCards_editStartMB3: x.StartMeal3,
        timeCards_editFinishMB1: x.FinishMeal1,
        timeCards_editFinishMB2: x.FinishMeal2,
        timeCards_editFinishMB3: x.FinishMeal3,
        timeCards_editRate:
          !x.HourlyRate || x.HourlyRate === null ? "" : x.HourlyRate,
        timeCards_editNotes: x.Note === null ? "" : x.Note,
        notetmp: x.Note === "" ? null : x.Note,
        edit_paytimes_start: x.Start,
        edit_paytimes_total_meal: x.TotalMBDed,
        edit_paytimes_finish: x.Finish,
        edit_paytimes_travelfrom: x.Travel2,
        edit_paytimes_totalhours: x.TotalHours
      });
    } else {
      var days = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ];

      var d = days[new Date(x.adlistdate).getDay()];

      var xy = d;
      var day = xy.substring(0, 3);
      var s = this.state.empDetail.Employee[0].PaymentFlags[0][day];

      this.state.empDetail.ADTimes.map(value => {
        if (
          new Date(value.adlistdate).toLocaleDateString() ==
          new Date(x.adlistdate).toLocaleDateString()
        ) {
          if (value.PaymentFlag !== null) {
            s = s + "," + value.PaymentFlag;
          }
        }
      });

      this.setState({
        selectedobj: x,
        selectedIndex: index,
        timeCards_editInternalNote:
          x.InternalNotes === null ? "" : x.InternalNotes,
        timeCards_editPaymentFlag: x.PaymentFlag === null ? "" : x.PaymentFlag,
        trackingFields: x.TrackingCodes,

        edit_paytimes_date: new Date(x.adlistdate).toLocaleDateString("en-GB"),
        edit_paytimes_day: days[new Date(x.adlistdate).getDay()],

        edit_paytimes_department: x.Dept,
        edit_paytimes_position: x.Position,
        edit_paytimes_payas: x.Payas,
        edit_paytimes_travelto: x.TravelTo,
        timeCards_editChartCode: this.state.empDetail.ChartCode,
        timeCards_editChartSort: this.state.empDetail.ChartSort,
        timeCards_editWorkState: this.state.empDetail.WorkState,
        edit_paytimes_start: x.Start,
        edit_paytimes_total_meal: x.meals,
        edit_paytimes_finish: x.Finish,
        timeCards_editRate: x.HourlyRate === null ? "" : x.HourlyRate,
        timeCards_editNotes: x.Note === null ? "" : x.Note,
        notetmp: x.Note === "" ? null : x.Note,
        edit_paytimes_travelfrom: x.TravelFrom,
        edit_paytimes_totalhours: moment(x.TotalHours)
          .format("HH:mm")
          .toString(),
        timeCards_editStartMB1: x.mealBreak[0].MB1Start,
        timeCards_editStartMB2: x.mealBreak[1].MB2Start,
        timeCards_editStartMB3: x.mealBreak[2].MB3Start,
        timeCards_editFinishMB1: x.mealBreak[0].MB1Finish,
        timeCards_editFinishMB2: x.mealBreak[1].MB2Finish,
        timeCards_editFinishMB3: x.mealBreak[2].MB3Finish
      });
    }
    if (this.state.selectedobj) {
      if (
        (moment(x.StartMeal)
          .format("HH:mm")
          .toString() === "00:00" ||
          x.StartMeal === "00:00") &&
        (moment(x.FinishMeal1)
          .format("HH:mm")
          .toString() === "00:00" ||
          x.FinishMeal1 === "00:00") &&
        (moment(x.StartMeal2)
          .format("HH:mm")
          .toString() === "00:00" ||
          x.StartMeal2 === "00:00") &&
        (moment(x.FinishMeal2)
          .format("HH:mm")
          .toString() === "00:00" ||
          x.FinishMeal2 === "00:00") &&
        (moment(x.StartMeal3)
          .format("HH:mm")
          .toString() === "00:00" ||
          x.StartMeal3 === "00:00") &&
        (moment(x.FinishMeal3)
          .format("HH:mm")
          .toString() === "00:00" ||
          x.FinishMeal3 === "00:00")
      ) {
        await this.setState({
          disableMealbreak: false,
          AdTimes2_EditModal_MealBreak_total: this.state
            .edit_paytimes_total_meal
        });
      } else {
        await this.setState({
          disableMealbreak: true
        });
      }
    }

    let prlDate =
      this.state.empDetail.PRLTimes !== null
        ? this.state.empDetail.PRLTimes.Times
          ? this.state.empDetail.PRLTimes.Times[0].DailyTimes.find(val => {
            return val.Date * 1000 == this.state.selectedobj.Date * 1000;
          })
          : ""
        : "";

    this.setState({
      prlObject: prlDate
    });

    let adTimesDate =
      this.state.empDetail.ADTimes.length > 0
        ? this.state.empDetail.ADTimes.find(item => {
          return new Date(item.adlistdate).toLocaleDateString() ==
            this.state.selectedobj.Date
            ? new Date(
              this.state.selectedobj.Date * 1000
            ).toLocaleDateString()
            : new Date(
              this.state.selectedobj.adlistdate
            ).toLocaleDateString();
        })
        : {};

    this.setState({
      adTimesObject: adTimesDate
    });

    let adtimesPayasValue = this.state.PayasList.find(
      item => item.Flag == this.state.adTimesObject.Payas
    );

    this.setState({
      adTimesPayas: adtimesPayasValue ? adtimesPayasValue.Value : ""
    });

    //   this.setState(x => ({
    //     edit_payTimes: {
    //         ...x.edit_payTimes,
    //         edit_paytimes_payas: x.Payas,
    //         edit_paytimes_travelto: x.Travel2
    //     }
    // }))
  };

  trackinghandler = async event => {
    let tmp_field = this.state.trackingFields;

    tmp_field.map(val => {
      if (val.uuid === event.target.name) {
        val.defaultValue =
          event.target.value !== "" ? event.target.value : null;
      }
    });
    await this.setState({
      trackingFields: tmp_field
    });
  };
  trackinghandlerdate = async (name, date) => {
    var tmp_field = this.state.trackingFields;
    tmp_field.map(val => {
      if (val.uuid === name) {
        val.defaultValue = date;
      }
    });
    await this.setState({
      trackingFields: tmp_field
    });
  };

  customtrackinghandler = async event => {
    let tmp_field = this.state.customFields;

    tmp_field.map((val, index) => {
      if (event.target.name == index) {
        val.defaultValue = event.target.value !== "" ? event.target.value : "";
      }
    });
    await this.setState({
      customFields: tmp_field
    });
  };

  customtrackinghandlerdate = async (name, date) => {
    var tmp_field = this.state.customFields;
    tmp_field.map((val, index) => {
      if (index === name) {
        val.defaultValue = date;
      }
    });
    await this.setState({
      customFields: tmp_field
    });
  };
  customtrackingSelecthandler = async event => {
    let tmp_field = this.state.customFields;

    tmp_field.map((val, index) => {
      if (event.target.name == index) {
        val.Value = event.target.value !== "" ? event.target.value : "";
      }
    });
    await this.setState({
      customFields: tmp_field
    });
  };
  find_diff = async () => {
    if (
      (moment(this.state.timeCards_editStartMB1)
        .format("HH:mm")
        .toString() === "00:00" ||
        this.state.timeCards_editStartMB1 === "00:00") &&
      (moment(this.state.timeCards_editFinishMB1)
        .format("HH:mm")
        .toString() === "00:00" ||
        this.state.timeCards_editFinishMB1 === "00:00") &&
      (moment(this.state.timeCards_editStartMB2)
        .format("HH:mm")
        .toString() === "00:00" ||
        this.state.timeCards_editStartMB2 === "00:00") &&
      (moment(this.state.timeCards_editFinishMB2)
        .format("HH:mm")
        .toString() === "00:00" ||
        this.state.timeCards_editFinishMB2 === "00:00") &&
      (moment(this.state.timeCards_editStartMB3)
        .format("HH:mm")
        .toString() === "00:00" ||
        this.state.timeCards_editStartMB3 === "00:00") &&
      (moment(this.state.timeCards_editFinishMB3)
        .format("HH:mm")
        .toString() === "00:00" ||
        this.state.timeCards_editFinishMB3 === "00:00")
    ) {
      await this.setState({
        disableMealbreak: false,
        AdTimes2_EditModal_MealBreak_total: this.state.edit_paytimes_total_meal
      });
    } else {
      await this.setState({
        disableMealbreak: true
      });
      var mb1_diff = moment.duration(
        moment(this.state.timeCards_editFinishMB1, "HH:mm").diff(
          moment(this.state.timeCards_editStartMB1, "HH:mm")
        )
      );
      var mb2_diff = moment.duration(
        moment(this.state.timeCards_editFinishMB2, "HH:mm").diff(
          moment(this.state.timeCards_editStartMB2, "HH:mm")
        )
      );
      var mb3_diff = moment.duration(
        moment(this.state.timeCards_editFinishMB3, "HH:mm").diff(
          moment(this.state.timeCards_editStartMB3, "HH:mm")
        )
      );
      let diff_hour = 0;
      let diff_mins = 0;
      let diff_sec = 0;
      if (!this.state.timeCards_NonDeductibleMB1.payCheckMB1) {
        diff_mins += mb1_diff._data.minutes;
        diff_hour += mb1_diff._data.hours;
        diff_sec += mb1_diff._data.seconds;
      }
      if (!this.state.timeCards_NonDeductibleMB2.payCheckMB2) {
        diff_mins += mb2_diff._data.minutes;
        diff_hour += mb2_diff._data.hours;
        diff_sec += mb2_diff._data.seconds;
      }
      if (!this.state.timeCards_NonDeductibleMB3.payCheckMB3) {
        diff_mins += mb3_diff._data.minutes;
        diff_hour += mb3_diff._data.hours;
        diff_sec += mb3_diff._data.seconds;
      }

      var m = moment();
      //==================================================================
      let diff_hour_non_deducted = 0;
      let diff_mins_non_deducted = 0;
      let diff_sec_non_deducted = 0;

      diff_mins_non_deducted += mb1_diff._data.minutes;
      diff_hour_non_deducted += mb1_diff._data.hours;
      diff_sec_non_deducted += mb1_diff._data.seconds;
      diff_mins_non_deducted += mb2_diff._data.minutes;
      diff_hour_non_deducted += mb2_diff._data.hours;
      diff_sec_non_deducted += mb2_diff._data.seconds;
      diff_mins_non_deducted += mb3_diff._data.minutes;
      diff_hour_non_deducted += mb3_diff._data.hours;
      diff_sec_non_deducted += mb3_diff._data.seconds;

      var m_non_deducted = moment();

      this.setState({
        AdTimes2_EditModal_MealBreak_total: m.set({
          hour: diff_hour,
          minute: diff_mins,
          second: diff_sec,
          millisecond: 0
        }),
        edit_paytimes_total_meal: m_non_deducted.set({
          hour: diff_hour_non_deducted,
          minute: diff_mins_non_deducted,
          second: diff_sec_non_deducted,
          millisecond: 0
        })
      });
    }

    var tmins =
      moment(this.state.edit_paytimes_travelto, "HH:mm")._d.getMinutes() +
      moment(this.state.edit_paytimes_travelfrom, "HH:mm")._d.getMinutes();

    var thours =
      moment(this.state.edit_paytimes_travelto, "HH:mm")._d.getHours() +
      moment(this.state.edit_paytimes_travelfrom, "HH:mm")._d.getHours();
    var travel = moment().set({
      hour: thours,
      minute: tmins,
      second: 0,
      millisecond: 0
    });

    var work = moment.duration(
      moment(this.state.edit_paytimes_finish, "HH:mm").diff(
        moment(this.state.edit_paytimes_start, "HH:mm")
      )
    );
    var travel_h = 0;
    var travel_m = 0;
    var travel_s = 0;
    travel_h +=
      moment(travel, "HH:mm")._d.getHours() -
      moment(
        this.state.AdTimes2_EditModal_MealBreak_total,
        "HH:mm"
      )._d.getHours() +
      work._data.hours;
    travel_m +=
      moment(travel, "HH:mm")._d.getMinutes() -
      moment(
        this.state.AdTimes2_EditModal_MealBreak_total,
        "HH:mm"
      )._d.getMinutes() +
      work._data.minutes;
    travel_s +=
      moment(travel, "HH:mm")._d.getSeconds() -
      moment(
        this.state.AdTimes2_EditModal_MealBreak_total,
        "HH:mm"
      )._d.getSeconds() +
      work._data.seconds;

    this.setState({
      edit_paytimes_totalhours: moment().set({
        hour: travel_h,
        minute: travel_m,
        second: travel_s
      })
    });
  };

  timescard_times_inputHandler = async (name, value) => {
    if (value === null) {
      value = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    }

    await this.setState({
      [name]: value.format("HH:mm"),
      flagcheck: true
    });

    this.find_diff();
  };

  editPaytimesPayasHandler = e => {
    // let selectedindex = document.getElementById('timeCards_editPayAs');
    // this.setState({
    //   PayasIndex: selectedindex.options['selectedIndex']
    // })
    this.setState({
      edit_paytimes_payas: e.target.value,
      flagcheck: true
    });
    // var glo;
    // var i = this.state.selectedIndex;
    // glo = this.state.empDetail.PayTimes.Times[0].DailyTimes[i];
    // if (glo.Payas) {

    //   glo.Payas = e.target.value;

    // }
  };

  setStdHours = async () => {
    if (this.state.empDetail.PayTimes) {
      var guid = this.state.empDetail.Employee[0].Guid;
      await API.post("updatestdtimesemp", "/updatestdtimesemp", {
        body: {
          Guid: guid,
          stdtimes: this.state.empDetail.PayTimes
        }
      })
        .then(data => {
          this.closemodal();
        })
        .catch(error => {
          toast.error("std not saved successfully");
        });
    } else {
      toast.error("No times found");
    }
  };
  onTabClick = async tab => {
    await this.setState({
      isLoading: true
    });
    await this.setState({
      selectedTab: tab,
      all_batch_number: 'all',
      timeCards_search: ''
    });
    await this.get_prlemp(tab);
    await this.setState({
      isLoading: false
    });
  };

  sendtimecardforapproval = async () => {
    var qtimecard = [];
    if (this.state.empDetail.PayTimes.Times) {
      this.state.empDetail.PayTimes.Times[0].DailyTimes.map((val, index) => {
        var obj = {
          guid: this.state.empDetail.Guid,
          Date: moment(val.Date * 1000).format("YYYY-MM-DD"),
          Day: val.Day,
          Finish: val.Finish,
          FinishMeal1: val.FinishMeal1,
          FinishMeal2: val.FinishMeal2,
          FinishMeal3: val.FinishMeal3,
          MB1ND: val.MB1ND == 0 ? false : true,
          MB2ND: val.MB2ND == 0 ? false : true,
          MB3ND: val.MB3ND == 0 ? false : true,
          Note: val.Note,
          Payas: val.Payas,

          Start: val.Start,
          StartMeal1: val.StartMeal,
          StartMeal2: val.StartMeal2,
          StartMeal3: val.StartMeal3,
          TotalMBDed: val.TotalMBDed,
          Travel1: val.Travel1,
          Travel2: val.Travel2,
          PaymentFlag:
            this.state.empDetail.PaymentFlags === ""
              ? null
              : this.state.empDetail.PaymentFlags,
          ADPymentFlag: this.state.empDetail.Flags
        };

        qtimecard.push(obj);
      });
    } else {
      this.state.empDetail.PayTimes.map((val, index) => {
        var obj = {
          guid: this.state.empDetail.Guid,
          Date: moment(val.adlistdate).format("YYYY-MM-DD"),
          Day: new Date(val.adlistdate).getDay(),
          Finish: val.Finish,
          FinishMeal1: val.mealBreak[0].AD1Finish,
          FinishMeal2: val.mealBreak[1].AD2Finish,
          FinishMeal3: val.mealBreak[2].AD3Finish,
          MB1ND: val.mealBreak[0].Deductable.Time,
          MB2ND: val.mealBreak[1].Deductable.Time,
          MB3ND: val.mealBreak[2].Deductable.Time,
          Note: val.Note,
          Payas: val.Payas,

          Start: val.Start,
          StartMeal1: val.mealBreak[0].AD1Start,
          StartMeal2: val.mealBreak[1].AD2Start,
          StartMeal3: val.mealBreak[2].AD3Start,
          TotalMBDed: val.meals,
          Travel1: val.TravelTo,
          Travel2: val.TravelFrom,
          PaymentFlag: val.PaymentFlag,
          ADPymentFlag: this.state.empDetail.Flags
        };

        qtimecard.push(obj);
      });
    }
    var objs = {
      guid: this.state.empDetail.Guid,
      TenantGuid: localStorage.getItem("useableGuid"),
      qtimecard: qtimecard,
      employeeguid: this.state.empDetail.Employee[0].Guid,
      department: this.state.empDetail.Employee[0].Department,
      position: this.state.empDetail.Employee[0].Position,
      periodendingdate: new Date(this.state.empDetail.PeriodEndingDate),
      batch: this.state.empDetail.Batch,
      tran: 0
    };

 

    var that = this;
    if (this.state.flagcheck === true && this.state.anflag === true) {
      await this.setState({
        isLoading: true
      });
      let timout = 6000;
      await API.post("getdeclinetemplate", "/getdeclinetemplate", {
        body: {
          Guid: "RefreshTime"
        }
      })
        .then(data => {
          timout = data.Timeinmiliseconds;
        })
        .catch(error => {
          console.log("not updated");
        });
   await this.generating_payslip(objs);

      await setTimeout(() => {
        that.get_emp_details(that.state.empDetail.Guid, that.state.selectedTab);
        this.setState({
          isLoading: false
        });
      }, timout);
    }

    await this.setState({
      flagcheck: false,
      anflag: false
    });
  };
  generating_payslip=async(objs)=>{
    var that=this;
    var completetenant = localStorage.getItem("completetenant");
    completetenant = JSON.parse(completetenant);
    var TphPayrollApi = completetenant.TphPayrollApi;
    var keys=localStorage.getItem('tempkeys');
    var finalkeys = (keys&&keys!==null&&keys!==undefined)?JSON.parse(keys):undefined;
    if(finalkeys!==undefined&&finalkeys.tempUsername&&
      finalkeys.tempPassword){
    await $.ajax({
      url: TphPayrollApi.url + "/api/SendTCforApproval",
      type: "post",
      dataType: "json",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify({
        username: finalkeys.tempUsername,
          password: finalkeys.tempPassword,
        employeelist: objs
      }),
      success:async function (res) {
        if(res.SendTCforApproval_response.ServiceErrors&&(res.SendTCforApproval_response.ServiceErrors[0].ERRORDESCRIPTION==="Userkey or Password invalid"||res.SendTCforApproval_response.ServiceErrors[0].ERRORDESCRIPTION==="temp key expired")){
          var confirm=await getKeys();
          if(confirm===true){
            that.generating_payslip(objs);
         }
        }
      }
    });
  }else{
    var confirm=await getKeys();
         if(confirm===true){
         that.generating_payslip(objs);
        }}
 
 
  }
  closemodal = () => {
    this.after_issueScrollHandler();

    this.sendtimecardforapproval();
  };

  onSaveEditHandler = async () => {
    var data = [];
    $("body").css("overflow", "hidden");
    $("#name_details").css("overflow-y", "auto");

    var clo = this.state.selectedobj;

    if (this.state.empDetail.PayTimes.Times) {
      let arr = this.state.changes;

      if (this.state.edit_paytimes_start !== clo.Start) {
        arr.push({
          time: moment(Date.now()).format("l h:mma"),
          pname:
            this.state.empDetail.Employee[0].FirstName +
            " " +
            this.state.empDetail.Employee[0].LastName,
          name: "Start Time",
          beforechange: clo.Start,
          afterchange: this.state.edit_paytimes_start
        });
      }
      if (this.state.edit_paytimes_travelto !== clo.Travel1) {
        let today = new Date(),
          date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();

        arr.push({
          time: moment(Date.now()).format("l h:mma"),
          pname:
            this.state.empDetail.Employee[0].FirstName +
            " " +
            this.state.empDetail.Employee[0].LastName,
          name: "Travel To",
          beforechange: clo.Travel1,
          afterchange: this.state.edit_paytimes_travelto
        });
      }

      if (
        moment(this.state.edit_paytimes_total_meal, "HH:mm").format("HH:mm") !==
        clo.TotalMBDed
      ) {
        let today = new Date(),
          date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();

        arr.push({
          time: moment(Date.now()).format("l h:mma"),
          pname:
            this.state.empDetail.Employee[0].FirstName +
            " " +
            this.state.empDetail.Employee[0].LastName,
          name: "Total Meal",
          beforechange: clo.TotalMBDed,
          afterchange: moment(
            this.state.edit_paytimes_total_meal,
            "HH:mm"
          ).format("HH:mm")
        });
      }
      if (this.state.edit_paytimes_finish !== clo.Finish) {
        let today = new Date(),
          date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();

        arr.push({
          time: moment(Date.now()).format("l h:mma"),
          pname:
            this.state.empDetail.Employee[0].FirstName +
            " " +
            this.state.empDetail.Employee[0].LastName,
          name: "Finish Time",
          beforechange: clo.Finish,
          afterchange: this.state.edit_paytimes_finish
        });
      }

      if (this.state.edit_paytimes_travelfrom !== clo.Travel2) {
        let today = new Date(),
          date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();

        arr.push({
          time: moment(Date.now()).format("l h:mma"),
          pname:
            this.state.empDetail.Employee[0].FirstName +
            " " +
            this.state.empDetail.Employee[0].LastName,
          name: "Travel From",
          beforechange: clo.Travel2,
          afterchange: this.state.edit_paytimes_travelfrom
        });
      }

      await this.setState({ changes: arr });

      payas_selected = this.state.edit_paytimes_payas;
      clo.Payas = this.state.edit_paytimes_payas;
      clo.Travel1 = this.state.edit_paytimes_travelto;
      clo.Start = this.state.edit_paytimes_start;
      clo.TotalMBDed = moment(
        this.state.edit_paytimes_total_meal,
        "HH:mm"
      ).format("HH:mm");
      clo.StartMeal = this.state.timeCards_editStartMB1;
      clo.StartMeal2 = this.state.timeCards_editStartMB2;
      clo.StartMeal3 = this.state.timeCards_editStartMB3;
      clo.Finish = this.state.edit_paytimes_finish;
      clo.HourlyRate =
        this.state.timeCards_editRate === ""
          ? null
          : this.state.timeCards_editRate;
      clo.FinishMeal1 = this.state.timeCards_editFinishMB1;
      clo.FinishMeal2 = this.state.timeCards_editFinishMB2;
      clo.FinishMeal3 = this.state.timeCards_editFinishMB3;
      clo.Travel2 = this.state.edit_paytimes_travelfrom;
      clo.TotalHours = moment(
        this.state.edit_paytimes_totalhours,
        "HH:mm"
      ).format("HH:mm");
      clo.Note =
        this.state.timeCards_editNotes === ""
          ? null
          : this.state.timeCards_editNotes;
      clo.ChartSort = this.state.timeCards_editChartSort;
      clo.ChartCode = this.state.timeCards_editChartCode;
      clo.WorkState = this.state.timeCards_editWorkState;
      clo.CustomTimeFields = this.state.customFields;
      //clo.CustomTimeFields = obj;

      var outerclo = this.state.empDetail;
      //outerclo.PaymentFlags = paymentflage;
      clo.InternalNotes =
        this.state.timeCards_editInternalNote === ""
          ? null
          : this.state.timeCards_editInternalNote;
      // outerclo.Employee[0].PaymentFlags[0][clo.Day.substring(0,3)]=this.state.timeCards_editPaymentFlag===""?null:this.state.timeCards_editPaymentFlag;
    } else {
      if (this.state.edit_paytimes_start !== clo.Start) {
        let arr = this.state.changes;
        let today = new Date(),
          date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();

        arr.push({
          time: moment(Date.now()).format("l h:mma"),
          pname:
            this.state.empDetail.Employee[0].FirstName +
            " " +
            this.state.empDetail.Employee[0].LastName,
          name: "Start Time",
          beforechange: clo.Start,
          afterchange: this.state.edit_paytimes_start
        });

        this.setState({ changes: arr });
      }
      if (this.state.edit_paytimes_travelto !== clo.TravelTo) {
        let arr = this.state.changes;
        let today = new Date(),
          date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();

        arr.push({
          time: moment(Date.now()).format("l h:mma"),
          pname:
            this.state.empDetail.Employee[0].FirstName +
            " " +
            this.state.empDetail.Employee[0].LastName,
          name: "Travel To",
          beforechange: clo.TravelTo,
          afterchange: this.state.edit_paytimes_travelto
        });

        this.setState({ changes: arr });
      }

      if (
        moment(this.state.edit_paytimes_total_meal, "HHmm").format("HH:mm") !==
        clo.meals
      ) {
        let arr = this.state.changes;
        let today = new Date(),
          date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();

        arr.push({
          time: moment(Date.now()).format("l h:mma"),
          pname:
            this.state.empDetail.Employee[0].FirstName +
            " " +
            this.state.empDetail.Employee[0].LastName,
          name: "Total Meal",
          beforechange: clo.meals,
          afterchange: moment(
            this.state.edit_paytimes_total_meal,
            "HHmm"
          ).format("HH:mm")
        });

        this.setState({ changes: arr });
      }
      if (this.state.edit_paytimes_finish !== clo.Finish) {
        let arr = this.state.changes;
        let today = new Date(),
          date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();

        arr.push({
          time: moment(Date.now()).format("l h:mma"),
          pname:
            this.state.empDetail.Employee[0].FirstName +
            " " +
            this.state.empDetail.Employee[0].LastName,
          name: "Finish Time",
          beforechange: clo.Finish,
          afterchange: this.state.edit_paytimes_finish
        });

        this.setState({ changes: arr });
      }

      if (this.state.edit_paytimes_travelfrom !== clo.TravelFrom) {
        let arr = this.state.changes;
        let today = new Date(),
          date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();

        arr.push({
          time: moment(Date.now()).format("l h:mma"),
          pname:
            this.state.empDetail.Employee[0].FirstName +
            " " +
            this.state.empDetail.Employee[0].LastName,
          name: "Travel From",
          beforechange: clo.TravelFrom,
          afterchange: this.state.edit_paytimes_travelfrom
        });

        this.setState({ changes: arr });
      }
      clo.Payas = this.state.edit_paytimes_payas;
      clo.Start = this.state.edit_paytimes_start;
      clo.Finish = this.state.edit_paytimes_finish;
      clo.TotalHours = moment(this.state.edit_paytimes_totalhours, "HH:mm");
      clo.HourlyRate =
        this.state.timeCards_editRate === ""
          ? null
          : this.state.timeCards_editRate;
      clo.TravelTo = this.state.edit_paytimes_travelto;
      clo.TrackingCodes = this.state.trackingFields;
      clo.TravelFrom = this.state.edit_paytimes_travelfrom;
      clo.meals = moment(this.state.edit_paytimes_total_meal, "HHmm").format(
        "HH:mm"
      );
      // clo.PaymentFlag= this.state.timeCards_editPaymentFlag===""?null:this.state.timeCards_editPaymentFlag;
      clo.mealBreak[0].MB1Start = this.state.timeCards_editStartMB1;
      clo.mealBreak[1].MB2Start = this.state.timeCards_editStartMB2;
      clo.mealBreak[2].MB3Start = this.state.timeCards_editStartMB3;
      clo.mealBreak[0].MB1Finish = this.state.timeCards_editFinishMB1;
      clo.mealBreak[1].MB2Finish = this.state.timeCards_editFinishMB2;
      clo.mealBreak[2].MB3Finish = this.state.timeCards_editFinishMB3;
      clo.ChartSort = this.state.timeCards_editChartSort;
      clo.ChartCode = this.state.timeCards_editChartCode;
      clo.WorkState = this.state.timeCards_editWorkState;
      clo.Note =
        this.state.timeCards_editNotes === ""
          ? null
          : this.state.timeCards_editNotes;
      //    clo.TrackingCodes = obj;
      var outerclo = this.state.empDetail;
      //outerclo.PaymentFlags = this.state.timeCards_editPaymentFlag===""?null:this.state.timeCards_editPaymentFlag;
      clo.InternalNotes =
        this.state.timeCards_editInternalNote === ""
          ? null
          : this.state.timeCards_editInternalNote;
    }
    var finaldata = {
      group_guid: this.state.empDetail.group_guid,
      senderid: this.state.empDetail.senderid,
      approver_chain: this.state.empDetail.approver_chain,
      current_approver: this.state.empDetail.current_approver,
      approve_status: this.state.empDetail.approve_status,
      Batch: this.state.empDetail.Batch,
      Employee: this.state.empDetail.Employee,
      freelanceuser: this.state.empDetail.freelanceuser,
      PRLTimes: this.state.empDetail.PRLTimes,
      TenantGuid: this.state.empDetail.TenantGuid,
      ChequeNumber: this.state.empDetail.ChequeNumber,
      PayslipNote:
        this.state.empDetail.PayslipNote === ""
          ? null
          : this.state.empDetail.PayslipNote,
      WorkState: this.state.empDetail.WorkState,
      PayWeek: this.state.empDetail.PayWeek,
      Payslip: this.state.image_payslip_path,
      ChartSort: this.state.empDetail.ChartSort,
      ChartCode: this.state.empDetail.ChartCode,
      Flags: this.state.empDetail.Flags,
      Guid: this.state.empDetail.Guid,
      InternalNotes:
        this.state.timeCards_editInternalNote == ""
          ? null
          : this.state.timeCards_editInternalNote,
      PaymentFlags:
        this.state.empDetail.PaymentFlags === ""
          ? null
          : this.state.empDetail.PaymentFlags,
      ADTimes: this.state.empDetail.ADTimes,
      PeriodEndingDate: this.state.empDetail.PeriodEndingDate,
      PayTimes: this.state.empDetail.PayTimes,
      comments: this.state.empDetail.comments,
      changes: this.state.changes,
      activities: this.state.activities,
        timesheetImage:this.state.empDetail.timesheetImage?this.state.empDetail.timesheetImage:null,
        freelanceuser_email:this.state.empDetail.freelanceuser_email?this.state.empDetail.freelanceuser_email:null,
freelanceuser_signature:this.state.empDetail.freelanceuser_signature?this.state.empDetail.freelanceuser_signature:null
    };

    var anflag = false;
    await API.post("createprlemp", "/createprlemp", {
      body: finaldata
    })
      .then(obj => {
        anflag = true;
      })
      .catch(err => {
        console.log(err);
      });
    this.setState({ anflag });
    // document.getElementById("closed").click();

    await this.get_emp_details(
      this.state.empDetail.Guid,
      this.state.selectedTab
    );
   // toast.success("your data has been updated successfully");

    await this.setState({
      edit_paytimes_payas: ""
      // mob screen state
    });
    if (width < 768) {
      await this.sendtimecardforapproval();
      this.setState({
        active_mob_screen: true
      });
    }
  };

  approve = async () => {
    await this.setState({
      isLoading: true
    });

    let name = localStorage.getItem("UserFullName");
    let msg = name + " approved Timecard.";
    let date = moment(Date.now()).format("l h:mma");
    let activities = this.state.activities;
    if (name) {
      activities.push({ msg, date });
      await this.setState({ activities });
    }

    let userGuid = localStorage.getItem("userGuid");
    let currentguidindex = this.state.empDetail.approver_chain.indexOf(
      userGuid
    );
    let currenttimecardguid = this.state.empDetail.Guid;
    var sati;
    var gloin;
    var approverid = this.state.setupEmpApp[0].Approvers[currentguidindex]
      .approver_id;
    var status = null;

    if (this.state.setupEmpApp[0].Approvers[currentguidindex + 1]) {
      approverid = this.state.setupEmpApp[0].Approvers[currentguidindex + 1]
        .approver_id;
      status = "Pending";
    } else if (!this.state.setupEmpApp[0].Approvers[currentguidindex + 1]) {
      status = "Approved";
    }

    await API.post("updatetimecardstatus", "/updatetimecardstatus", {
      body: {
        Guid: currenttimecardguid,
        approve_status: status,
        current_approver: approverid,
        activities: this.state.activities
      }
    })
      .then(data => {
        console.log("updated");
      })
      .catch(error => {
        console.log("not updated");
      });

      await this.recreatetimesheet(currentguidindex);

    $("#" + this.state.empDetail.Guid + this.state.selectedTab).removeClass(
      "active"
    );
    await this.get_prlemp(this.state.selectedTab);
    await this.setState({
      isLoading: false
      // active_mob_screen:true
    });
    if (width < 768) {
      this.setState({
        active_mob_screen: true
      });
    }
  };

  recreatetimesheet=async(currentguidindex)=>{

    if(this.state.empDetail!==""&&this.state.setupEmpApp.length>0){
    var desired_approvers=this.state.empDetail.approver_chain.slice(0,currentguidindex+1);
var final_approvers=[{}];
if(desired_approvers!==undefined){


  var tmp=undefined


await Promise.all(

      desired_approvers.map((app,index)=>{
      tmp=this.state.setupEmpApp[0].Approvers.find(ab=>ab.approver_id===app);
      if(tmp!==undefined){


  API.post("updateuserpasswordbyemail",'/get_files',{
    body: {
      
      bucket:process.env.REACT_APP_S3_BUCKET,
      key:'public/'+tmp.approver_email+'_signature.jpg'
    }
  }).then(a=>{
    final_approvers[0]['AppSignature'+index]=a.item
  }).catch(e=>{
    final_approvers[0]['AppSignature'+index]=null
  })
  



      }
      })

)







}
var data={};

//call for logo
await API.post("updateuserpasswordbyemail",'/get_files',{
  body: {
    bucket:process.env.REACT_APP_S3_BUCKET,
    key:JSON.parse(localStorage.getItem("completetenant")).Logo
  }
}).then(a=>{
  data.Logo=a.item
}).catch(e=>{
  data.Logo=null
})
//call for freelance signature
await API.post("updateuserpasswordbyemail",'/get_files',{
  body: {
    
    bucket:process.env.REACT_APP_S3_BUCKET,
    key:this.state.empDetail.freelanceuser_signature?this.state.empDetail.freelanceuser_signature:null
  }
}).then(a=>{
  data.freelanceSignature=a.item
}).catch(e=>{
  data.freelanceSignature=null
})




    data.EmailAddress=this.state.empDetail.Employee[0].Email;
    data.FirstName=this.state.empDetail.Employee[0].FirstName;
    data.LastName=this.state.empDetail.Employee[0].LastName;
    data.mrtFile= JSON.parse(localStorage.getItem("completetenant")).Timesheet?JSON.parse(localStorage.getItem("completetenant")).Timesheet:null;
    data.Approvals=final_approvers;
    data.PayTimes=JSON.parse(JSON.stringify(this.state.empDetail.PayTimes));
    data.PRLTimes=JSON.parse(JSON.stringify(this.state.empDetail.PRLTimes));
    if(data.PRLTimes.Times&&data.PRLTimes.Times[0]&&data.PRLTimes.Times[0].DailyTimes){
    data.PRLTimes.Times[0].DailyTimes.map(s=>{
      s.Date=moment(s.Date*1000).format("DD/MM/YYYY").toString();
     
    })
  }
    if(data.PayTimes.Times&&data.PayTimes.Times[0]&&data.PayTimes.Times[0].DailyTimes){
    data.PayTimes.Times[0].DailyTimes.map(s=>{
      s.Date= moment(s.Date*1000).format("DD/MM/YYYY").toString();
      
    })
  }
  if(data.PRLTimes.PeriodEnding){
    data.PRLTimes.PeriodEnding=moment(data.PRLTimes.PeriodEnding).format("DD/MM/YYYY").toString();
  }
  if(data.PayTimes.PeriodEnding){
    data.PayTimes.PeriodEnding=moment(data.PayTimes.PeriodEnding).format("DD/MM/YYYY").toString();
  }


    var htmlforimage="";
    var imageforupload="";
 await API.post("updateuserpasswordbyemail",'/gethtml',{
    body:data
  }).then(result=>{
    htmlforimage=result.htmlData;
  }).catch(err=>{
    htmlforimage=""
    console.log(err);
  })

if(htmlforimage!==""){
  await API.post("updateuserpasswordbyemail",'/html-to-image',{
    body:{
      htmlString:htmlforimage
    }
  }).then(result=>{
    imageforupload=result.base64Image;
  }).catch(err=>{
    imageforupload="";
    console.log(err);
  })

if(imageforupload!==""){
  if(this.state.empDetail.timesheetImage&&this.state.empDetail.timesheetImage!==null&&this.state.empDetail.timesheetImage!==undefined){
  var resq = this.state.empDetail.timesheetImage.split("/");
  var res=resq[resq.length-1];
}
  await API.post("updateuserpasswordbyemail",'/reuploadtimesheet',{
    body:{
      pic:imageforupload,
      key:res
    }
  }).then(result=>{
    imageforupload=result;
  }).catch(err=>{
    imageforupload="";
    console.log(err);
  })
}


}
}

  }
  sendEmailOnDecline = async (to, body) => {
    if (to) {
      await API.post("sendemail", "/sendemail", {
        body: {
          TO: to,
          SUBJECT: "Declined",
          MSG: body
        }
      })
        .then(data => console.log("Declined Email sended successfully"))
        .catch(err => toast.error("Problem in sending email"));
    } else {
      toast.error("Email Address Not Found");
    }
  };

  decline = async () => {
    var x = this.state.timeCards_selectDeclineToValue;

    if (x !== "") {
      await this.setState({
        isLoading: true
      });

      let emailStatement;
      await API.post("getdeclinetemplate", "/getdeclinetemplate", {
        body: {
          Guid: "EmailTemplate"
        }
      })
        .then(data => {
          emailStatement = data;
        })
        .catch(error => {
          console.log("not updated");
        });

      let name = localStorage.getItem("UserFullName");
      let msg = name + " declined Timecard.";
      let date = moment(Date.now()).format("l h:mma");
      let activities = this.state.activities;
      if (name) {
        activities.push({ msg, date });
        await this.setState({ activities });
      }

      if (this.state.empDetail.Employee[0].Guid === x) {
        await API.post("updatetimecardstatus", "/updatetimecardstatus", {
          body: {
            Guid: this.state.empDetail.Guid,
            approve_status: "Draft",
            current_approver: "No user",
            activities: this.state.activities
          }
        })
          .then(data => {
            console.log("updated");
          })
          .catch(error => {
            console.log("not updated");
          });

        let name = localStorage.getItem("UserFullName");
        if (emailStatement && emailStatement.DeclineToEmployee) {
          let st = emailStatement.DeclineToEmployee;
          let str = st.replace("@currentapprover", name);
          let body = str.replace(
            "@declinemessage",
            this.state.timeCards_declineReason
          );
          let Email =
            this.state.empDetail.Employee[0] &&
            this.state.empDetail.Employee[0].Email;
          //send email
          await this.sendEmailOnDecline(Email, body);
        }
      } else {
        let EmailAddress;
        await API.post("getuserbyguid", "/getuserbyguid", {
          body: {
            user_id: x
          }
        })
          .then(data => {
            EmailAddress = data.EmailAddress;
          })
          .catch(error => {
            console.log("err getuserbyguid", error);
          });

        await API.post("updatetimecardstatus", "/updatetimecardstatus", {
          body: {
            Guid: this.state.empDetail.Guid,
            approve_status: "Pending",
            current_approver: x,
            activities: this.state.activities
          }
        })
          .then(data => {
            console.log("updated");
          })
          .catch(error => {
            console.log("not updated");
          });

        let name = localStorage.getItem("UserFullName");
        if (emailStatement && emailStatement.DeclineToApprover) {
          let st = emailStatement.DeclineToApprover;
          let str = st.replace("@currentapprover", name);
          let body = str.replace(
            "@declinemessage",
            this.state.timeCards_declineReason
          );
          //send email
          await this.sendEmailOnDecline(EmailAddress, body);
        }
      }
      $("#" + this.state.empDetail.Guid + this.state.selectedTab).removeClass(
        "active"
      );
      document.getElementById("declineCloseModel").click();

      await this.get_prlemp(this.state.selectedTab);

      await this.setState({
        isLoading: false,
        timeCards_declineReason: ""

        // for mobscreen
        // active_mob_screen:true
      });
      if (width < 768) {
        this.setState({
          active_mob_screen: true
        });
      }
    } else {
      toast.error("Please Select a Valid option.");
    }
  };

  diclineData = async () => {
    let userGuid = localStorage.getItem("userGuid");
    let gloin = null;
    var obj = [];
    if (this.state.setupEmpApp[0]) {
      this.state.setupEmpApp[0].Approvers.map((i, index) => {
        if (i.approver_id === userGuid) {
          gloin = index;
        }
      });
    }

    for (let i = 0; i < gloin; i++) {
      if (this.state.setupEmpApp[0].Approvers) {
        obj.push(this.state.setupEmpApp[0].Approvers[i]);
      }
    }

    await this.setState({ declineOptions: obj });
  };
  closedecline = async () => { };

  onMoreDetails = () => {
    this.state.setupEmpApp[0].Approvers.map((i, index) => {
      if (i.approver_id === this.state.empDetail.current_approver) {
        this.setState({
          login_app_index: index
        });
      }
    });
  };
  onCloseMoreDetail = () => {
    this.setState({
      login_app_index: ""
    });
  };
  dowonloadEmpAttach = name => {
    window.open(
      'https://'+process.env.REACT_APP_S3_BUCKET+'.s3-'+process.env.REACT_APP_S3_REGION+'.amazonaws.com/public/employee_attachments/' +
      localStorage.getItem("useableGuid") +
      "/attachments/" +
      name
    );
  };

  addComment = async () => {
    let comment =
      this.state.timeCards_comment || this.state.timeCards_mobile_comment;
    let name = localStorage.getItem("UserFullName");
    let date = moment(Date.now()).format("l h:mma");
    let allComments = this.state.comments;
    if (comment) {
      await this.setState({
        isLoading: true
      });
      allComments.push({ name, comment, date });
      this.setState({
        comments: allComments,
        timeCards_comment: "",
        timeCards_mobile_comment: ""
      });
      await API.post("updatecomments", "/updatecomments", {
        body: {
          Guid: this.state.empGuid,
          comments: allComments
        }
      })
        .then(data => {
          //toast.success("Comment Added");
        })
        .catch(error => {
          toast.error("Comment Not Added");
        });

      await this.setState({
        isLoading: false
      });
    }
  };
  mobtimesData = async () => {
    this.setState({ mtd: true, isEdit: false });
  };

  onClickBackArrowHeaderEditMob = async () => {
    this.setState({ isEdit: true, mtd: false });
  };

  listcheck = async (e, name) => {
    // var val = e.target.checked;

    if (name == "pay") {
      var x = !this.state.payflag;

      this.setState({ payflag: x });
    }
    if (name == "ad") {
      var xy = !this.state.adflag;

      this.setState({ adflag: xy });
    }
    if (name === "org") {
      var xy = !this.state.orgflag;

      this.setState({ orgflag: xy });
    }
  };

  newMessage_submitHandler = async () => {
    let newMessageErrors = this.state.newMessageErrors;

    if (!this.state.timeCards_NMselectSendToValid) {
      newMessageErrors.timeCards_NMselectSendToErr = "Please Select Option";
    }
    if (!this.state.timeCards_NMsubjectValid) {
      newMessageErrors.timeCards_NMsubjectErr = "This field is required";
    }
    if (!this.state.timeCards_NMmessageValid) {
      newMessageErrors.timeCards_NMmessageErr = "This field is required";
    }
    this.setState({
      newMessageErrors: newMessageErrors
    });

    await this.setState({
      isLoading: true
    });
    var tests = [...this.state.tmp_img];

    this.state.payslips.map((val, index) => {
      var uploadedObjs = {
        ContentType: "UTF-8",
        DocDesc: "Message document",
        DoumentType: val.mimetype,
        filename: val.filename,
        Timestamp: new Date().getTime(),
        url: val.fileurl
      };
      tests.push(uploadedObjs);
    });

    if (this.state.simplemessage === true) {
      tests = [];
    }
    if (this.state.newMessage_formValid) {
      await API.post("dynamomsgcreate", "/dynamomsgcreate", {
        body: {
          body: this.state.timeCards_NMmessage,
          from: localStorage.getItem("userGuid"),
          subject: this.state.timeCards_NMsubject,
          tenant: localStorage.getItem("useableGuid"),
          to: this.state.selected_approver_object.approver_id,
          messageTo: this.state.timeCards_NMselectSendToValue,
          messageFrom: localStorage.getItem("UserFullName"),

          type: "inbox",
          attachments: tests,
          userid: localStorage.getItem("userGuid")
        }
      })
        .then(async data => {
         // toast.success("Message Sent Successfully");
          $("#close_msg").click();
          test = [];

          await this.setState({
            timeCards_NMmessage: "",
            timeCards_NMsubject: "",
            timeCards_NMselectSendToValue: "",
            tmp_img: []
          });
        })
        .catch(err => {
          console.log(err);
          toast.error("Message did not Sent");
        });
    } else {
      toast.error("Message did not Sent");
    }
    await this.setState({
      isLoading: false
    });
  };
  onClickAddMessage = async e => {
    if (e.target.title === "simple") {
      this.setState({
        simplemessage: true
      });
    } else {
      this.setState({
        simplemessage: false
      });
    }

    let userGuid = localStorage.getItem("userGuid");
    var ss = this.state.setup_against_tab;
    var exactdata = ss.filter(
      item => this.state.empDetail.group_guid === item.Guid
    );

    let approvers_array = exactdata[0].Approvers.filter(item => {
      return this.state.empGroupGuid
        .filter(e => e !== userGuid)
        .includes(item.approver_id);
    });
    await this.setState({
      approver_object: approvers_array
    });
  };

  SendMessageFileUploadHandler = async files => {
    var test = [];
    await this.setState({
      sendMessageAttachment: files,
      isLoading: true
    });

    await Promise.all(
      files.map(async file => {
        var tmp_name_dynamo = new Date().getTime() + "-" + file.name;
        var file_name = "Message-Attachments/" + tmp_name_dynamo;
        await Storage.put(file_name, file)
          .then(result => {
            var uploadedObj = {
              ContentType: "UTF-8",
              DocDesc: "Message document",
              DoumentType: file.type,
              filename: tmp_name_dynamo,
              Timestamp: new Date().getTime(),
              url:
              'https://'+process.env.REACT_APP_S3_BUCKET+'.s3-'+process.env.REACT_APP_S3_REGION+'.amazonaws.com/public/' +
                result.key
            };
            test.push(uploadedObj);
          })
          .catch(err => console.log(err));
      })
    );
    this.setState({
      tmp_img: test,
      isLoading: false
    });
  };

  removeAttachment = async file => {
    await this.setState({
      isLoading: true
    });
    let attach = this.state.tmp_img;
    await Storage.remove("Message-Attachments/" + file)
      .then(result => {
       // toast.success("deleted successfully");
        attach = attach.filter(function (obj) {
          return obj.filename !== file;
        });
      })
      .catch(err => {
        toast.error("error in deleting");
      });
    await this.setState({
      tmp_img: attach
    });
    await this.setState({
      isLoading: false
    });
  };

  getBatches = e => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: e.target.value
    });

    if (this.state.employeeData.length > 0) {
      if (value == "all") {
        this.setState({ timeCards_searched_data: this.state.employeeData });
      } else {
        let batchData = this.state.employeeData.filter(e => e.Batch == value);
        this.setState({ timeCards_searched_data: batchData });
      }
    }
  };

  showHideInfo = name => {
    this.setState({ [name]: !this.state[name] });
  };
  getActiveIndex = () => {
    setTimeout(() => {
      let idStr = $(".slider_pdf_viewer .item.active").attr("id");

      let id = Number(idStr);
      console.log(id,'iiiiiiiiiiidddddddddddddddd')
      this.setState({ photoIndex: id, clone_photoIndex: id });
    }, 1000);
  };
  changeMobType = async e => {
    var x = e.target.value;
    await this.setState({ selectedTab: x });
    await this.onTabClick(x);

    // if(e.target.value==="Approve"){
    //   await this.approve();
    // }
    // if(e.target.value==="Decline"){
    //   await this.diclineData();
    // }

    // if(e.target.value==="Pending"){}
    // if(e.target.value==="Approved"){}
    // if(e.target.value==="All"){}
  };
  editmobarrow = async (guid, tab) => {
    await this.get_emp_details(guid, tab);
    await this.getDetailMob();
  };
  editmobdata = async (index, value) => {
    await this.edit_employeeData(index, value);
    this.mobtimesData();
  };
  onClickBackArrowMob = () => {
    this.setState({ active_mob_screen: true });
  };

  logout = async () => {
    this.setState({ isLoading: true });
    if(localStorage.getItem('userGuid')!==undefined&&localStorage.getItem('userGuid')!==""&&localStorage.getItem('EmailAddress')&&localStorage.getItem('EmailAddress')!==undefined&&localStorage.getItem('EmailAddress')!==""){
    await API.post("recordlastlogin", "/recordlastlogin", {
      body: {
        Guid: localStorage.getItem("userGuid")
      }
    })
      .then(data => {
       // toast.success("Last Logout Recorded Successfully.");
      })
      .catch(err => {
        toast.error(err.message);
      });
    await API.post("recordactivity", "/recordactivity", {
      body: {
        Description: "logout",
        UserName: localStorage.getItem("UserLogin"),
        IPAddress: ip.address(),
        TenantGuid: "UYGVUYGBIUYGHBIUYHGB",
        Type: "Logout",
        UserGuid: localStorage.getItem("userGuid")
      }
    })
      .then(data => {
       // toast.success("Logout Activity Recorded Successfully.");
      })
      .catch(err => {
        toast.error(err.message);
      });

    await API.post("updatelastlogout", "/updatelastlogout", {
      body: {
        Guid: localStorage.getItem("userGuid")
      }
    })
      .then(data => {
       // toast.success("User last logout updated successfully.");
      })
      .catch(err => {
        toast.error(err.message);
      });

    await API.post("updatelastlogoutforuser", "/updatelastlogoutforuser", {
      body: {
        userEmail: localStorage.getItem("EmailAddress"),
        LastLogout: Date.now()
      }
    })
      .then(data => {
       // toast.success("last logout updated successfully for user.");
      })
      .catch(err => {
        toast.error(err.message);
      });
    }
    localStorage.removeItem("EmailAddress");
    localStorage.removeItem("userGuid");
    localStorage.removeItem("UserLogin");
    localStorage.removeItem("UserFullName");
    await Auth.signOut();
    this.props.history.push("/login");
  };

  AllTimeCardChkBoxHandler = (chkboxName, isChecked) => {
    let AllSelected = chkboxName === "all" && isChecked;
    let allUnselected = chkboxName === "all" && !isChecked;
    const checked = isChecked;
    const checkList = this.state.timeCards_searched_data.map((item, index) => {
      if (AllSelected || item.Guid === chkboxName) {
        return Object.assign({}, item, {
          checked
        });
      } else if (allUnselected) {
        return Object.assign({}, item, {
          checked: false
        });
      }

      return item;
    });

    let isAllChecked =
      checkList.findIndex(item => item.checked === false) === -1 || AllSelected;
    this.setState({
      timeCards_searched_data: checkList,
      checkAllMobTimecard: isAllChecked
    });
  };

  render() {
    const that = this;
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    var days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ];
    var finalDay;
    var finalDate;
    var finalMonth;
    var finalYear;
    var hexaa;

    if (this.state.empDetail.PayTimes) {
      if (this.state.empDetail.PayTimes.Times) {
        finalDay = this.state.edit_paytimes_day;
      } else {
        finalDay = days[new Date(this.state.edit_paytimes_date).getDay()];
      }
    }

    finalDate = new Date(this.state.edit_paytimes_date).getDate();

    finalMonth = monthNames[new Date(this.state.edit_paytimes_date).getMonth()];

    finalYear = new Date(this.state.edit_paytimes_date).getFullYear();

    if (finalDate === 1 || finalDate === 21 || finalDate === 31) {
      hexaa = "st";
    } else if (finalDate === 2 || finalDate === 22) {
      hexaa = "nd";
    } else if (finalDate === 3) {
      hexaa = "rd";
    } else {
      hexaa = "th";
    }

    return (
      <div>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}

        {this.state.editCheckboxesMob ? (
          <div>
            <HeaderMob
              onClickBackArrowHeaderMob={this.onClickBackArrowHeaderMob}
              show={true}
            />

            <div className="col-sm-12 p0 text-center analytics_mbl_heading">
              {/* <!-- Decline --> */}
            </div>

            <div className="col-sm-12 p0 top_mrg_form_res">
              <div className="">
                <div id="mobile_menu_sm">
                  {/* <!-- Sidebar --> */}
                  <div id="sidebar-wrapper " className="analytics_sidebar">
                    <ul className="sidebar-nav">
                      <li className="sidebar_active">
                        <a href="dashboard.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="-5378 759 13.333 11.333"
                          >
                            <path
                              id="ic_home_24px"
                              className="cls-1"
                              d="M7.333,14.333v-4H10v4h3.333V9h2L8.667,3,2,9H4v5.333Z"
                              transform="translate(-5380 756)"
                            />
                          </svg>
                          <span> Dashboard </span>
                        </a>
                      </li>
                      <li>
                        <a href="payroll_batches.html">
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 1000 1000"
                            enableBackground=""
                            xmlSpace="preserve"
                          >
                            <g>
                              <path d="M755.1,658c0,55.8-18.1,103.8-54.4,144.1c-36.3,40.3-83.4,65.2-141.4,74.7v95.7c0,5.1-1.6,9.3-4.9,12.6c-3.3,3.3-7.5,4.9-12.6,4.9H468c-4.7,0-8.8-1.7-12.3-5.2c-3.5-3.5-5.2-7.6-5.2-12.3v-95.7c-24.1-3.3-47.3-8.9-69.7-16.9c-22.4-8-40.9-16.1-55.5-24.3c-14.6-8.2-28.1-17-40.5-26.3c-12.4-9.3-20.9-16.1-25.4-20.5c-4.6-4.4-7.8-7.7-9.6-9.8c-6.2-7.7-6.6-15.1-1.1-22.4l56.3-73.8c2.5-3.6,6.7-5.8,12.6-6.5c5.5-0.7,9.8,0.9,13.1,4.9l1.1,1.1c41.2,36.1,85.5,58.9,132.9,68.4c13.5,2.9,27,4.4,40.5,4.4c29.5,0,55.5-7.8,78-23.5c22.4-15.7,33.6-37.9,33.6-66.7c0-10.2-2.7-19.9-8.2-29c-5.5-9.1-11.6-16.8-18.3-23c-6.7-6.2-17.4-13-32-20.5c-14.6-7.5-26.6-13.3-36.1-17.5c-9.5-4.2-24.1-10.1-43.8-17.8c-14.2-5.8-25.4-10.4-33.6-13.7c-8.2-3.3-19.4-8.1-33.6-14.5c-14.2-6.4-25.6-12-34.2-16.9c-8.6-4.9-18.9-11.4-30.9-19.4c-12-8-21.8-15.8-29.3-23.2c-7.5-7.5-15.4-16.4-23.8-26.8c-8.4-10.4-14.9-21-19.4-31.7c-4.6-10.7-8.4-22.9-11.5-36.4c-3.1-13.5-4.6-27.7-4.7-42.7c0-50.3,17.9-94.4,53.6-132.3c35.7-37.9,82.2-62.4,139.5-73.3V27.5c0-4.7,1.7-8.8,5.2-12.3c3.5-3.5,7.6-5.2,12.3-5.2h73.8c5.1,0,9.3,1.6,12.6,4.9c3.3,3.3,4.9,7.5,4.9,12.6v96.3c20.8,2.2,40.9,6.4,60.5,12.6c19.5,6.2,35.4,12.3,47.6,18.3c12.2,6,23.8,12.9,34.7,20.5c10.9,7.7,18,12.9,21.3,15.9c3.3,2.9,6,5.5,8.2,7.7c6.2,6.6,7.1,13.5,2.7,20.8L690,299.3c-2.9,5.5-7.1,8.4-12.6,8.8c-5.1,1.1-10-0.2-14.8-3.8c-1.1-1.1-3.7-3.3-7.9-6.5c-4.2-3.3-11.3-8.1-21.3-14.5c-10-6.4-20.7-12.2-32-17.5s-24.9-10-40.7-14.2c-15.8-4.2-31.4-6.3-46.8-6.3c-34.6,0-62.9,7.8-84.8,23.5c-21.9,15.7-32.8,35.9-32.8,60.7c0,9.5,1.6,18.2,4.7,26.3c3.1,8,8.5,15.6,16.1,22.7c7.7,7.1,14.9,13.1,21.6,18.1s17,10.6,30.6,16.9c13.7,6.3,24.7,11.3,33.1,14.8c8.4,3.5,21.1,8.5,38.3,15.1c19.3,7.3,34.1,13,44.3,17.2c10.2,4.2,24.1,10.6,41.6,19.1s31.3,16.3,41.3,23.2c10,6.9,21.3,16,33.9,27.3c12.6,11.3,22.2,22.9,29,34.7c6.7,11.8,12.5,25.8,17.2,41.8C752.7,622.7,755.1,639.8,755.1,658L755.1,658z" />
                            </g>
                          </svg>
                          <span> Payroll Batches</span>{" "}
                        </a>
                      </li>
                      <li>
                        <a href="timecards.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 23.158"
                          >
                            <path
                              id="ic_content_copy_24px"
                              className="cls-1"
                              d="M16.737,1H4.105A2.111,2.111,0,0,0,2,3.105V17.842H4.105V3.105H16.737Zm3.158,4.211H8.316A2.111,2.111,0,0,0,6.211,7.316V22.053a2.111,2.111,0,0,0,2.105,2.105H19.895A2.111,2.111,0,0,0,22,22.053V7.316A2.111,2.111,0,0,0,19.895,5.211Zm0,16.842H8.316V7.316H19.895Z"
                              transform="translate(-2 -1)"
                            />
                          </svg>
                          <span> Timecards</span>{" "}
                        </a>
                      </li>
                      {/* <li> */}

                      <li>
                        <a href="timecards.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="18px"
                            y="12px"
                            viewBox="0 0 1000 1000"
                            enableBackground="new 0 0 1000 1000"
                            xmlSpace="preserve"
                          >
                            <g>
                              <path d="M620.6,214c-88.3,0-176.5,0-264.8,0c-87.9,0-175.7,0-263.6,0.1c-6.2,0-12.5,0.5-18.6,1.6c-35.9,6.3-63.5,38.2-63.6,73.7C10,429.7,10,570,10,710.3c0,12.6,3,24.6,9.5,35.4C35.9,773,60.5,785.9,91.9,786c176.3,0.1,352.5,0,528.8,0c45.9,0,83.6-37.7,83.6-83.5c0-135,0-270,0-405C704.3,251.7,666.5,214,620.6,214z" />
                              <path d="M985.6,295.6c-10.6,0-21.3-0.1-31.9,0.1c-2.5,0.1-5.3,0.8-7.4,2c-66,39.5-131.9,79-197.8,118.5c-2.5,1.5-3.3,3.1-3.3,6c0.1,51.8,0.1,103.6,0,155.3c0,3.1,1.1,4.7,3.6,6.2c64,38.3,128,76.7,191.9,115.2c6.2,3.7,12.2,5.7,19.4,5.4c9.9-0.5,19.9-0.1,30.1-0.1c0-136.4,0-272.3,0-408.7C988.4,295.6,987,295.6,985.6,295.6z" />
                            </g>
                          </svg>
                          <span> Productions</span>{" "}
                        </a>
                      </li>
                      <li>
                        <a hreaf="javascript:void(0);">
                          <strong> Admin</strong>{" "}
                        </a>
                      </li>

                      <li>
                        <a href="custom_field.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path
                              id="ic_description_24px"
                              className="cls-1"
                              d="M16.5,2H6.5A2.287,2.287,0,0,0,4.013,4L4,20a2.287,2.287,0,0,0,2.487,2H21.5A2.3,2.3,0,0,0,24,20V8ZM19,18H9V16H19Zm0-4H9V12H19ZM15.25,9V3.5L22.125,9Z"
                              transform="translate(-4 -2)"
                            />
                          </svg>
                          <span> Employee List</span>{" "}
                        </a>
                      </li>
                      <li>
                        <a href="custom_list_field.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path
                              id="ic_create_24px"
                              className="cls-1"
                              d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z"
                              transform="translate(-3 -2.998)"
                            />
                          </svg>
                          <span> AD Times</span>{" "}
                        </a>
                      </li>

                      <li>
                        <a href="custom_list_field.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path
                              id="ic_person_24px"
                              className="cls-1"
                              d="M14,14A5,5,0,1,0,9,9,5,5,0,0,0,14,14Zm0,2.5c-3.337,0-10,1.675-10,5V24H24V21.5C24,18.175,17.337,16.5,14,16.5Z"
                              transform="translate(-4 -4)"
                            />
                          </svg>
                          <span> Users</span>{" "}
                        </a>
                      </li>

                      <li>
                        <a href="custom_list_field.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 14"
                          >
                            <path
                              id="ic_supervisor_account_24px"
                              className="cls-1"
                              d="M16.5,12A2.5,2.5,0,1,0,14,9.5,2.492,2.492,0,0,0,16.5,12ZM9,11A3,3,0,1,0,6,8,2.987,2.987,0,0,0,9,11Zm7.5,3c-1.83,0-5.5.92-5.5,2.75V19H22V16.75C22,14.92,18.33,14,16.5,14ZM9,13c-2.33,0-7,1.17-7,3.5V19H9V16.75a3.941,3.941,0,0,1,2.37-3.47A12.283,12.283,0,0,0,9,13Z"
                              transform="translate(-2 -5)"
                            />
                          </svg>
                          <span> Approval Setup</span>{" "}
                        </a>
                      </li>

                      <li>
                        <a href="custom_list_field.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 16"
                          >
                            <path
                              id="ic_markunread_24px"
                              className="cls-1"
                              d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,4-8,5L4,8V6l8,5,8-5Z"
                              transform="translate(-2 -4)"
                            />
                          </svg>
                          <span> Email Template</span>{" "}
                        </a>
                      </li>

                      <li>
                        <a href="custom_list_field.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20.561"
                          >
                            <path
                              id="ic_settings_24px"
                              className="cls-1"
                              d="M19.911,13.288a8.012,8.012,0,0,0,.072-1.007,8.012,8.012,0,0,0-.072-1.007l2.169-1.7a.519.519,0,0,0,.123-.658L20.148,5.362a.517.517,0,0,0-.627-.226l-2.56,1.028a7.511,7.511,0,0,0-1.737-1.007l-.391-2.724a.5.5,0,0,0-.5-.432H10.217a.5.5,0,0,0-.5.432L9.322,5.156A7.9,7.9,0,0,0,7.585,6.164L5.025,5.136a.5.5,0,0,0-.627.226L2.342,8.919a.507.507,0,0,0,.123.658l2.169,1.7a8.153,8.153,0,0,0-.072,1.007,8.153,8.153,0,0,0,.072,1.007l-2.169,1.7a.519.519,0,0,0-.123.658L4.4,19.2a.517.517,0,0,0,.627.226L7.585,18.4A7.511,7.511,0,0,0,9.322,19.4l.391,2.724a.5.5,0,0,0,.5.432h4.112a.5.5,0,0,0,.5-.432l.391-2.724A7.9,7.9,0,0,0,16.961,18.4l2.56,1.028a.5.5,0,0,0,.627-.226L22.2,15.642a.519.519,0,0,0-.123-.658Zm-7.638,2.591a3.6,3.6,0,1,1,3.6-3.6A3.6,3.6,0,0,1,12.273,15.879Z"
                              transform="translate(-2.271 -2)"
                            />
                          </svg>
                          <span> Settings</span>{" "}
                        </a>
                      </li>

                      <li>
                        <a href="custom_list_field.html">
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="17px"
                            y="17px"
                            viewBox="0 0 1000 1000"
                            enableBackground="new 0 0 1000 1000"
                            xmlSpace="preserve"
                          >
                            <g>
                              <path d="M968.4,789.2L561.9,451.7c23.2-42.1,37.6-89.8,37.6-141.2c0-162.8-132-294.8-294.8-294.8c-34.8,0-67.8,7.1-98.8,18.2l99.1,94.9c49.1,47,50.8,125,3.8,174.1c-47,49.1-125,50.8-174.1,3.7L29.9,206.3C17.6,238.8,10,273.6,10,310.4c0,162.8,132,294.8,294.8,294.8c54.2,0,104.3-15.7,148-41.2l344.5,399.6c22.9,26.5,61.7,27.8,86.3,2.8l89.3-91C997.4,850.4,995.4,811.6,968.4,789.2z" />
                            </g>
                          </svg>
                          <span> Setup</span>{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="clearfix"></div>
              </div>

              <div className="clearfix"></div>
            </div>

            <div className="clearfix"></div>

            <div className="col-md-12 col-xs-12 analytics_wrapper timecrad_coments_display">
              {/* <!-- Modal content--> */}

              <div className="col-sm-12 table_attach_empl p0">
                <div className="table-responsive">
                  <table
                    width="100%"
                    className="table table-condensed table_attach_bdr_pay_ending"
                  >
                    <thead>
                      <tr>
                        <th width="10%" align="left">
                          {/* <div className="checkbox checkbox-circle radio_btm_marg"> */}
                          <input
                            name="checkAllMobTimecard"
                            checked={this.state.checkAllMobTimecard}
                            type="checkbox"
                            onChange={e =>
                              this.AllTimeCardChkBoxHandler(
                                "all",
                                e.target.checked
                              )
                            }
                          />
                          {/* <label htmlFor="checkbox02"></label>
                          </div> */}
                        </th>
                        <th width="30%" align="left">
                          Pay Ending
                        </th>
                        <th width="30%" align="left">
                          Name
                        </th>
                        <th width="30%" align="left">
                          Hours
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.timeCards_searched_data.length > 0
                        ? this.state.timeCards_searched_data.map(
                          (value, index) => (
                            <tr>
                              <td>
                                {" "}
                                {/* <div className="checkbox checkbox-circle radio_btm_marg"> */}
                                <input
                                  name={value.Guid}
                                  type="checkbox"
                                  checked={value.checked}
                                  onChange={e =>
                                    this.AllTimeCardChkBoxHandler(
                                      value.Guid,
                                      e.target.checked
                                    )
                                  }
                                />
                                {/* <label htmlFor="checkbox08"></label>
                    </div> */}
                              </td>
                              <td>
                                {moment(
                                  new Date(
                                    value.PeriodEndingDate
                                  ).toLocaleDateString()
                                ).format("DD/MM/YYYY")}
                              </td>
                              <td>
                                {value.Employee[0].FirstName +
                                  " " +
                                  value.Employee[0].LastName}
                              </td>
                              <td>
                                {" "}
                                {value.PRLTimes !== null
                                  ? value.PRLTimes.TotalHours
                                  : "empty prl times"}
                              </td>
                            </tr>
                          )
                        )
                        : ""}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="clear30"></div>
            </div>

            {/* <!-- Mobile Menu timecard --> */}
            <div className="timecard_btm_menu">
              <div className="col-sm-12 col-xs-12 pay_ending_bdr_btm">
                <div className="col-xs-6 col-sm-6 timecard_mbl_tick_icon timecard_menu_mobilee text-center">
                  <a href="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.6"
                      height="13.4"
                      viewBox="0 0 17.6 13.4"
                    >
                      <path
                        id="ic_done_24px"
                        d="M9,16.2,4.8,12,3.4,13.4,9,19,21,7,19.6,5.6Z"
                        transform="translate(-3.4 -5.6)"
                        fill="#7b7b7b"
                      />
                    </svg>
                    {/* <!-- <img src="images/timecard_2.png"> --> */}

                    <p>Approve </p>
                  </a>
                </div>
                <div className="col-xs-6 col-sm-6 timecard_mbl_edit_icon text-center timecard_menu_mobilee">
                  <a href="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                    >
                      <path
                        id="ic_clear_24px"
                        d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                        transform="translate(-5 -5)"
                        fill="#7b7b7b"
                      />
                    </svg>

                    {/* <!--   <img src="images/setting_3.png" className="setting_timecard_img">
                         --> */}
                    <p>Decline </p>
                  </a>
                </div>

                <div className="clearfix"> </div>
              </div>
              <div className="clearfix"> </div>
            </div>

            {/* <!-- End Mobile Menu timecard   --> */}
          </div>
        ) : this.state.active_mob_screen ? (
          <div>
            <HeaderMob show={false} />

            <div className="col-sm-12 p0 text-center payending_mbl_mrg analytics_mbl_heading">
              {/* <!-- Decline --> */}
            </div>

            <div className="col-sm-12 p0 top_mrg_form_res">
              <div className="">
                <div id="mobile_menu_sm">
                  {/* <!-- Sidebar --> */}
                  <div id="sidebar-wrapper " className="analytics_sidebar">
                    <ul className="sidebar-nav">
                      <li className="sidebar_active">
                        <Link to="/dashboard">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="-5378 759 13.333 11.333"
                          >
                            <path
                              id="ic_home_24px"
                              className="cls-1"
                              d="M7.333,14.333v-4H10v4h3.333V9h2L8.667,3,2,9H4v5.333Z"
                              transform="translate(-5380 756)"
                            />
                          </svg>
                          <span> Dashboard </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/payroll_batches">
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 1000 1000"
                            enableBackground=""
                            xmlSpace="preserve"
                          >
                            <g>
                              <path d="M755.1,658c0,55.8-18.1,103.8-54.4,144.1c-36.3,40.3-83.4,65.2-141.4,74.7v95.7c0,5.1-1.6,9.3-4.9,12.6c-3.3,3.3-7.5,4.9-12.6,4.9H468c-4.7,0-8.8-1.7-12.3-5.2c-3.5-3.5-5.2-7.6-5.2-12.3v-95.7c-24.1-3.3-47.3-8.9-69.7-16.9c-22.4-8-40.9-16.1-55.5-24.3c-14.6-8.2-28.1-17-40.5-26.3c-12.4-9.3-20.9-16.1-25.4-20.5c-4.6-4.4-7.8-7.7-9.6-9.8c-6.2-7.7-6.6-15.1-1.1-22.4l56.3-73.8c2.5-3.6,6.7-5.8,12.6-6.5c5.5-0.7,9.8,0.9,13.1,4.9l1.1,1.1c41.2,36.1,85.5,58.9,132.9,68.4c13.5,2.9,27,4.4,40.5,4.4c29.5,0,55.5-7.8,78-23.5c22.4-15.7,33.6-37.9,33.6-66.7c0-10.2-2.7-19.9-8.2-29c-5.5-9.1-11.6-16.8-18.3-23c-6.7-6.2-17.4-13-32-20.5c-14.6-7.5-26.6-13.3-36.1-17.5c-9.5-4.2-24.1-10.1-43.8-17.8c-14.2-5.8-25.4-10.4-33.6-13.7c-8.2-3.3-19.4-8.1-33.6-14.5c-14.2-6.4-25.6-12-34.2-16.9c-8.6-4.9-18.9-11.4-30.9-19.4c-12-8-21.8-15.8-29.3-23.2c-7.5-7.5-15.4-16.4-23.8-26.8c-8.4-10.4-14.9-21-19.4-31.7c-4.6-10.7-8.4-22.9-11.5-36.4c-3.1-13.5-4.6-27.7-4.7-42.7c0-50.3,17.9-94.4,53.6-132.3c35.7-37.9,82.2-62.4,139.5-73.3V27.5c0-4.7,1.7-8.8,5.2-12.3c3.5-3.5,7.6-5.2,12.3-5.2h73.8c5.1,0,9.3,1.6,12.6,4.9c3.3,3.3,4.9,7.5,4.9,12.6v96.3c20.8,2.2,40.9,6.4,60.5,12.6c19.5,6.2,35.4,12.3,47.6,18.3c12.2,6,23.8,12.9,34.7,20.5c10.9,7.7,18,12.9,21.3,15.9c3.3,2.9,6,5.5,8.2,7.7c6.2,6.6,7.1,13.5,2.7,20.8L690,299.3c-2.9,5.5-7.1,8.4-12.6,8.8c-5.1,1.1-10-0.2-14.8-3.8c-1.1-1.1-3.7-3.3-7.9-6.5c-4.2-3.3-11.3-8.1-21.3-14.5c-10-6.4-20.7-12.2-32-17.5s-24.9-10-40.7-14.2c-15.8-4.2-31.4-6.3-46.8-6.3c-34.6,0-62.9,7.8-84.8,23.5c-21.9,15.7-32.8,35.9-32.8,60.7c0,9.5,1.6,18.2,4.7,26.3c3.1,8,8.5,15.6,16.1,22.7c7.7,7.1,14.9,13.1,21.6,18.1s17,10.6,30.6,16.9c13.7,6.3,24.7,11.3,33.1,14.8c8.4,3.5,21.1,8.5,38.3,15.1c19.3,7.3,34.1,13,44.3,17.2c10.2,4.2,24.1,10.6,41.6,19.1s31.3,16.3,41.3,23.2c10,6.9,21.3,16,33.9,27.3c12.6,11.3,22.2,22.9,29,34.7c6.7,11.8,12.5,25.8,17.2,41.8C752.7,622.7,755.1,639.8,755.1,658L755.1,658z" />
                            </g>
                          </svg>
                          <span> Payroll Batches</span>{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/timecards">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 23.158"
                          >
                            <path
                              id="ic_content_copy_24px"
                              className="cls-1"
                              d="M16.737,1H4.105A2.111,2.111,0,0,0,2,3.105V17.842H4.105V3.105H16.737Zm3.158,4.211H8.316A2.111,2.111,0,0,0,6.211,7.316V22.053a2.111,2.111,0,0,0,2.105,2.105H19.895A2.111,2.111,0,0,0,22,22.053V7.316A2.111,2.111,0,0,0,19.895,5.211Zm0,16.842H8.316V7.316H19.895Z"
                              transform="translate(-2 -1)"
                            />
                          </svg>
                          <span> Timecards</span>{" "}
                        </Link>
                      </li>
                      {/* <li> */}

                      <li>
                        <Link to="/timecards">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="18px"
                            y="12px"
                            viewBox="0 0 1000 1000"
                            enableBackground="new 0 0 1000 1000"
                            xmlSpace="preserve"
                          >
                            <g>
                              <path d="M620.6,214c-88.3,0-176.5,0-264.8,0c-87.9,0-175.7,0-263.6,0.1c-6.2,0-12.5,0.5-18.6,1.6c-35.9,6.3-63.5,38.2-63.6,73.7C10,429.7,10,570,10,710.3c0,12.6,3,24.6,9.5,35.4C35.9,773,60.5,785.9,91.9,786c176.3,0.1,352.5,0,528.8,0c45.9,0,83.6-37.7,83.6-83.5c0-135,0-270,0-405C704.3,251.7,666.5,214,620.6,214z" />
                              <path d="M985.6,295.6c-10.6,0-21.3-0.1-31.9,0.1c-2.5,0.1-5.3,0.8-7.4,2c-66,39.5-131.9,79-197.8,118.5c-2.5,1.5-3.3,3.1-3.3,6c0.1,51.8,0.1,103.6,0,155.3c0,3.1,1.1,4.7,3.6,6.2c64,38.3,128,76.7,191.9,115.2c6.2,3.7,12.2,5.7,19.4,5.4c9.9-0.5,19.9-0.1,30.1-0.1c0-136.4,0-272.3,0-408.7C988.4,295.6,987,295.6,985.6,295.6z" />
                            </g>
                          </svg>
                          <span> Productions</span>{" "}
                        </Link>
                      </li>
                      <li>
                        <a hreaf="javascript:void(0);">
                          <strong> Admin</strong>{" "}
                        </a>
                      </li>

                      <li>
                        <Link to="/custom_field">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path
                              id="ic_description_24px"
                              className="cls-1"
                              d="M16.5,2H6.5A2.287,2.287,0,0,0,4.013,4L4,20a2.287,2.287,0,0,0,2.487,2H21.5A2.3,2.3,0,0,0,24,20V8ZM19,18H9V16H19Zm0-4H9V12H19ZM15.25,9V3.5L22.125,9Z"
                              transform="translate(-4 -2)"
                            />
                          </svg>
                          <span> Employee List</span>{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/custom_list_field">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path
                              id="ic_create_24px"
                              className="cls-1"
                              d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z"
                              transform="translate(-3 -2.998)"
                            />
                          </svg>
                          <span> AD Times</span>{" "}
                        </Link>
                      </li>

                      <li>
                        <Link to="/custom_list_field">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path
                              id="ic_person_24px"
                              className="cls-1"
                              d="M14,14A5,5,0,1,0,9,9,5,5,0,0,0,14,14Zm0,2.5c-3.337,0-10,1.675-10,5V24H24V21.5C24,18.175,17.337,16.5,14,16.5Z"
                              transform="translate(-4 -4)"
                            />
                          </svg>
                          <span> Users</span>{" "}
                        </Link>
                      </li>

                      <li>
                        <Link to="/custom_list_field">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 14"
                          >
                            <path
                              id="ic_supervisor_account_24px"
                              className="cls-1"
                              d="M16.5,12A2.5,2.5,0,1,0,14,9.5,2.492,2.492,0,0,0,16.5,12ZM9,11A3,3,0,1,0,6,8,2.987,2.987,0,0,0,9,11Zm7.5,3c-1.83,0-5.5.92-5.5,2.75V19H22V16.75C22,14.92,18.33,14,16.5,14ZM9,13c-2.33,0-7,1.17-7,3.5V19H9V16.75a3.941,3.941,0,0,1,2.37-3.47A12.283,12.283,0,0,0,9,13Z"
                              transform="translate(-2 -5)"
                            />
                          </svg>
                          <span> Approval Setup</span>{" "}
                        </Link>
                      </li>

                      <li>
                        <Link to="/custom_list_field">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 16"
                          >
                            <path
                              id="ic_markunread_24px"
                              className="cls-1"
                              d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,4-8,5L4,8V6l8,5,8-5Z"
                              transform="translate(-2 -4)"
                            />
                          </svg>
                          <span> Email Template</span>{" "}
                        </Link>
                      </li>

                      <li>
                        <Link to="/custom_list_field">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20.561"
                          >
                            <path
                              id="ic_settings_24px"
                              className="cls-1"
                              d="M19.911,13.288a8.012,8.012,0,0,0,.072-1.007,8.012,8.012,0,0,0-.072-1.007l2.169-1.7a.519.519,0,0,0,.123-.658L20.148,5.362a.517.517,0,0,0-.627-.226l-2.56,1.028a7.511,7.511,0,0,0-1.737-1.007l-.391-2.724a.5.5,0,0,0-.5-.432H10.217a.5.5,0,0,0-.5.432L9.322,5.156A7.9,7.9,0,0,0,7.585,6.164L5.025,5.136a.5.5,0,0,0-.627.226L2.342,8.919a.507.507,0,0,0,.123.658l2.169,1.7a8.153,8.153,0,0,0-.072,1.007,8.153,8.153,0,0,0,.072,1.007l-2.169,1.7a.519.519,0,0,0-.123.658L4.4,19.2a.517.517,0,0,0,.627.226L7.585,18.4A7.511,7.511,0,0,0,9.322,19.4l.391,2.724a.5.5,0,0,0,.5.432h4.112a.5.5,0,0,0,.5-.432l.391-2.724A7.9,7.9,0,0,0,16.961,18.4l2.56,1.028a.5.5,0,0,0,.627-.226L22.2,15.642a.519.519,0,0,0-.123-.658Zm-7.638,2.591a3.6,3.6,0,1,1,3.6-3.6A3.6,3.6,0,0,1,12.273,15.879Z"
                              transform="translate(-2.271 -2)"
                            />
                          </svg>
                          <span> Settings</span>{" "}
                        </Link>
                      </li>

                      <li>
                        <Link to="/custom_list_field">
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="17px"
                            y="17px"
                            viewBox="0 0 1000 1000"
                            enableBackground="new 0 0 1000 1000"
                            xmlSpace="preserve"
                          >
                            <g>
                              <path d="M968.4,789.2L561.9,451.7c23.2-42.1,37.6-89.8,37.6-141.2c0-162.8-132-294.8-294.8-294.8c-34.8,0-67.8,7.1-98.8,18.2l99.1,94.9c49.1,47,50.8,125,3.8,174.1c-47,49.1-125,50.8-174.1,3.7L29.9,206.3C17.6,238.8,10,273.6,10,310.4c0,162.8,132,294.8,294.8,294.8c54.2,0,104.3-15.7,148-41.2l344.5,399.6c22.9,26.5,61.7,27.8,86.3,2.8l89.3-91C997.4,850.4,995.4,811.6,968.4,789.2z" />
                            </g>
                          </svg>
                          <span> Setup</span>{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="clearfix"></div>
              </div>

              <div className="clearfix"></div>
            </div>

            <div className="clearfix"></div>

            <div className="col-md-12 col-xs-12 analytics_wrapper timecrad_coments_display payend_final">
              <div className="col-sm-12 col-xs-12 p0 payending_table">
                <div className="col-xs-2 p0">
                  <label> Type: </label>
                </div>
                <div className="col-xs-8 p0">
                  <select
                    className="form-control payending_control_dropdown"
                    value={this.state.selectedTab}
                    onChange={this.changeMobType}
                  >
                    <option value="approve">Approve</option>
                    <option value="pending">Pending</option>

                    <option value="approved">Approved</option>
                    <option value="all">All</option>
                  </select>
                </div>
              </div>
              <div className="clear10"></div>
              <div className="col-xs-12 p0">
                <div className="col-xs-3 p0">
                  <select
                    name="all_batch_number"
                    value={this.state.all_batch_number}
                    onChange={this.getBatches}
                    className="form-control payending_control_dropdown"
                  >
                    <option value="all">Batch#</option>
                    {this.state.batches_array
                      .sort((a, b) => {
                        return a - b;
                      })
                      .map(item => (
                        <option value={item}>{item}</option>
                      ))}
                  </select>
                </div>
                <div className="col-xs-7 pd_payend_final_right">
                  <div className="grid_layout_inner">
                    <div className="form searchInput_form">
                      <div className="form-group">
                        <button
                          type="button"
                          className="search_button searc_btn_ico_index"
                          value=""
                        >
                          <img
                            src={magnifying_glass}
                            width="20"
                            height="20"
                            alt="payending-mob"
                          />
                        </button>
                        <input
                          type="text"
                          className="form-control search_input search_btn_top_index"
                          placeholder="What are you looking for"
                          id="timeCards_search"
                          name="timeCards_search"
                          value={this.state.timeCards_search}
                          onChange={this.timeCards_searchHandler}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xs-2 col-sm-2 copy_payending_top p0 text-right">
                  {this.state.selectedTab === "approve" ? (
                    <a
                      onClick={() => this.setState({ editCheckboxesMob: true })}
                    >
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="17.984"
                        viewBox="0 0 20 17.984"
                      >
                        <g
                          id="list_4_"
                          data-name="list (4)"
                          transform="translate(0 -24.45)"
                        >
                          <g
                            id="Group_871"
                            data-name="Group 871"
                            transform="translate(0 24.45)"
                          >
                            <path
                              id="Path_105"
                              data-name="Path 105"
                              d="M192.911,46.25H180.449a.05.05,0,0,0-.049.049v1.167a.05.05,0,0,0,.049.049h12.462a.05.05,0,0,0,.049-.049V46.3A.048.048,0,0,0,192.911,46.25Z"
                              transform="translate(-172.961 -45.351)"
                              fill="#acacac"
                            />
                            <ellipse
                              id="Ellipse_46"
                              data-name="Ellipse 46"
                              cx="1.563"
                              cy="1.563"
                              rx="1.563"
                              ry="1.563"
                              transform="translate(1.386 7.876)"
                              fill="#acacac"
                            />
                            <ellipse
                              id="Ellipse_47"
                              data-name="Ellipse 47"
                              cx="1.571"
                              cy="1.571"
                              rx="1.571"
                              ry="1.571"
                              transform="translate(1.377 14.722)"
                              fill="#acacac"
                            />
                            <path
                              id="Path_106"
                              data-name="Path 106"
                              d="M180.449,98.716h5.889a.05.05,0,0,0,.049-.049V97.5a.05.05,0,0,0-.049-.049h-5.889a.05.05,0,0,0-.049.049v1.167A.053.053,0,0,0,180.449,98.716Z"
                              transform="translate(-172.961 -94.44)"
                              fill="#acacac"
                            />
                            <path
                              id="Path_107"
                              data-name="Path 107"
                              d="M192.911,212.45H180.449a.05.05,0,0,0-.049.049v1.167a.05.05,0,0,0,.049.049h12.462a.05.05,0,0,0,.049-.049V212.5A.048.048,0,0,0,192.911,212.45Z"
                              transform="translate(-172.961 -204.697)"
                              fill="#acacac"
                            />
                            <path
                              id="Path_108"
                              data-name="Path 108"
                              d="M180.449,264.916h5.889a.05.05,0,0,0,.049-.049V263.7a.05.05,0,0,0-.049-.049h-5.889a.05.05,0,0,0-.049.049v1.167A.057.057,0,0,0,180.449,264.916Z"
                              transform="translate(-172.961 -253.786)"
                              fill="#acacac"
                            />
                            <path
                              id="Path_109"
                              data-name="Path 109"
                              d="M192.911,378.55H180.449a.05.05,0,0,0-.049.049v1.167a.05.05,0,0,0,.049.049h12.462a.05.05,0,0,0,.049-.049V378.6A.048.048,0,0,0,192.911,378.55Z"
                              transform="translate(-172.961 -363.948)"
                              fill="#acacac"
                            />
                            <path
                              id="Path_110"
                              data-name="Path 110"
                              d="M186.338,429.85h-5.889a.05.05,0,0,0-.049.049v1.167a.05.05,0,0,0,.049.049h5.889a.05.05,0,0,0,.049-.049V429.9A.048.048,0,0,0,186.338,429.85Z"
                              transform="translate(-172.961 -413.132)"
                              fill="#acacac"
                            />
                            <path
                              id="Path_111"
                              data-name="Path 111"
                              d="M2.2,27.011,1.126,25.926,0,27.048l1.08,1.08L2.2,29.254,3.32,28.137,5.9,25.576,4.779,24.45Z"
                              transform="translate(0 -24.45)"
                              fill="#acacac"
                            />
                          </g>
                        </g>
                      </svg>{" "}
                    </a>
                  ) : (
                      ""
                    )}
                </div>
              </div>
              <div className="clearfix"> </div>

              <div className="col-sm-12 table_attach_empl p0 attach_mrg_btm_10">
                <div className="table-responsive">
                  <table
                    width="100%"
                    className="table table-condensed table_attach_bdr_pay_ending"
                  >
                    <thead>
                      <tr>
                        {/* <!-- <th width="10%" align="left"><div className="radio radio_btm_marg">
                <input id="checkbox02" type="checkbox">
                <label for="checkbox02">
                  
                </label>
            </div></th> --> */}
                        <th width="30%" align="left">
                          Pay Ending
                        </th>
                        <th width="30%" align="left">
                          Name
                        </th>
                        <th width="30%" align="left">
                          Hours
                        </th>
                        <th width="30%" align="left">
                          &nbsp;
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.timeCards_searched_data.length > 0
                        ? this.state.timeCards_searched_data.map(
                          (value, index) => (
                            <>
                              <tr>
                                <td>
                                  {" "}
                                  {moment(
                                    new Date(
                                      value.PeriodEndingDate
                                    ).toLocaleDateString()
                                  ).format("DD/MM/YYYY")}
                                </td>
                                <td>
                                  {value.Employee[0].FirstName +
                                    " " +
                                    value.Employee[0].LastName}
                                </td>
                                <td>
                                  {" "}
                                  {value.PRLTimes !== null
                                    ? value.PRLTimes.TotalHours
                                    : "empty prl times"}
                                </td>
                                <td className="right_icon_payending">
                                  <div
                                    onClick={() =>
                                      this.editmobarrow(
                                        value.Guid,
                                        this.state.selectedTab
                                      )
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="13"
                                      height="13.053"
                                      viewBox="0 0 13 21.053"
                                    >
                                      <path
                                        id="ic_chevron_right_24px"
                                        d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                                        transform="translate(-8.59 -6)"
                                        fill="#30679A"
                                      />
                                    </svg>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )
                        )
                        : "no list "}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="clear30"></div>
            </div>
            <div className="clearfix"> </div>

            {/* <!-- Mobile Menu timecard --> */}
            <div className="timecard_btm_menu payending_bdr_top">
              <div className="col-sm-12 col-xs-12">
                <div className="col-xs-3 col-sm-3 timecard_mbl_tick_icon timecard_menu_mobilee text-center timecard_active">
                  <Link to="/timecards">
                    {/* <!-- <svg xmlns="http://www.w3.org/2000/svg" width="17.6" height="13.4" viewBox="0 0 17.6 13.4">
                                        <path id="ic_done_24px" d="M9,16.2,4.8,12,3.4,13.4,9,19,21,7,19.6,5.6Z" transform="translate(-3.4 -5.6)" fill="#4ED94E" />
                                    </svg> --> */}

                    {/* <!--  <img src="images/timecard_2.png"> --> */}
                    <svg
                      className="timecard_svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="20"
                      viewBox="0 0 20 22.223"
                    >
                      <defs></defs>
                      <path
                        id="ic_receipt_24px"
                        className="cls-1"
                        d="M19.667,18.667H6.333V16.444H19.667Zm0-4.444H6.333V12H19.667Zm0-4.444H6.333V7.556H19.667ZM3,24.222l1.667-1.667,1.667,1.667L8,22.556l1.667,1.667,1.667-1.667L13,24.222l1.667-1.667,1.667,1.667L18,22.556l1.667,1.667,1.667-1.667L23,24.222V2L21.333,3.667,19.667,2,18,3.667,16.333,2,14.667,3.667,13,2,11.333,3.667,9.667,2,8,3.667,6.333,2,4.667,3.667,3,2Z"
                        transform="translate(-3 -2)"
                      />
                    </svg>

                    <p>Timecard </p>
                  </Link>
                </div>
                <div className="col-xs-3 col-sm-3 timecard_mbl_edit_icon text-center timecard_menu_mobilee">
                  <Link to="/settings">
                    {/* <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="15.77" height="15.77">
                                        <path id="ic_create_24px" className="cls-1" d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z" transform="translate(-3 -2.998)" fill="#FFA200" />
                                    </svg> --> */}

                    {/* <!--  <img src="images/setting_3.png" className="setting_timecard_img"> --> */}

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="20"
                      viewBox="0 0 20 20.561"
                    >
                      <defs></defs>
                      <path
                        id="ic_settings_24px"
                        className="cls-1"
                        d="M19.911,13.288a8.012,8.012,0,0,0,.072-1.007,8.012,8.012,0,0,0-.072-1.007l2.169-1.7a.519.519,0,0,0,.123-.658L20.148,5.362a.517.517,0,0,0-.627-.226l-2.56,1.028a7.511,7.511,0,0,0-1.737-1.007l-.391-2.724a.5.5,0,0,0-.5-.432H10.217a.5.5,0,0,0-.5.432L9.322,5.156A7.9,7.9,0,0,0,7.585,6.164L5.025,5.136a.5.5,0,0,0-.627.226L2.342,8.919a.507.507,0,0,0,.123.658l2.169,1.7a8.153,8.153,0,0,0-.072,1.007,8.153,8.153,0,0,0,.072,1.007l-2.169,1.7a.519.519,0,0,0-.123.658L4.4,19.2a.517.517,0,0,0,.627.226L7.585,18.4A7.511,7.511,0,0,0,9.322,19.4l.391,2.724a.5.5,0,0,0,.5.432h4.112a.5.5,0,0,0,.5-.432l.391-2.724A7.9,7.9,0,0,0,16.961,18.4l2.56,1.028a.5.5,0,0,0,.627-.226L22.2,15.642a.519.519,0,0,0-.123-.658Zm-7.638,2.591a3.6,3.6,0,1,1,3.6-3.6A3.6,3.6,0,0,1,12.273,15.879Z"
                        transform="translate(-2.271 -2)"
                        fill="#707070"
                      />
                    </svg>
                    <p>Settings </p>
                  </Link>
                </div>
                <div className="col-xs-3 col-sm-3 timecard_mbl_msg_icon timecard_mbl_decline_icon text-center timecard_menu_mobilee">
                  <Link to="/messages">
                    {/* <!-- <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                        <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#FF1E1E" />
                                    </svg> --> */}

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="20"
                      viewBox="0 0 20 16"
                    >
                      <path
                        id="ic_mail_outline_24px"
                        d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,14H4V8l8,5,8-5Zm-8-7L4,6H20Z"
                        transform="translate(-2 -4)"
                        fill="#7b7b7b"
                      ></path>
                    </svg>

                    <p>Messages </p>
                  </Link>
                </div>
                <div className="col-xs-3 col-sm-3 timecard_mbl_next_icon timecard_logout_icon text-center timecard_menu_mobilee">
                  {/* <!--  <svg xmlns="http://www.w3.org/2000/svg"width="20.46" height="9" viewBox="0 0 20.646 9">
<path id="ic_redo_24px" d="M5.173,10.6a10.612,10.612,0,0,1,17.014,4.62L19.8,16A8.078,8.078,0,0,0,6.969,12.38L10.622,16H1.54V7Z" transform="translate(-1.54 -7)" fill="#4093AB"/>
</svg>
                              </svg> --> */}

                  <div onClick={this.logout}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18.814"
                      height="20.094"
                      viewBox="0 0 23.814 20.094"
                    >
                      <g id="logout" transform="translate(-0.002 -25)">
                        <path
                          id="XMLID_7_"
                          d="M3.812,105.582H16.746a1.116,1.116,0,0,0,0-2.233H3.812l1.443-1.443a1.116,1.116,0,0,0-1.579-1.579L.328,103.676q-.039.039-.074.082c-.01.012-.019.026-.029.038s-.025.032-.036.049-.019.032-.029.048-.019.03-.027.046-.016.033-.024.05-.016.032-.023.049-.012.033-.018.049-.013.035-.019.054-.009.033-.013.05-.01.037-.014.056-.006.039-.009.058-.006.033-.007.05c0,.036-.005.072-.006.108v0q0,.054.006.109c0,.017,0,.033.007.049s.005.039.009.059.009.037.014.055.008.034.013.051.012.035.018.053.012.034.018.05.015.032.022.048.016.034.025.051.017.03.026.045.019.033.03.049.023.032.035.047.019.027.03.04.046.053.07.078l0,0L3.677,108.6a1.116,1.116,0,0,0,1.579-1.579Z"
                          transform="translate(0 -69.419)"
                          fill="#7b7b7b"
                        />
                        <path
                          id="XMLID_8_"
                          d="M115.258,25h-14.14A1.116,1.116,0,0,0,100,26.116v4.465a1.116,1.116,0,0,0,2.233,0V27.233h11.907V42.861H102.235V39.512a1.116,1.116,0,1,0-2.233,0v4.465a1.116,1.116,0,0,0,1.116,1.116h14.14a1.116,1.116,0,0,0,1.116-1.116V26.116A1.116,1.116,0,0,0,115.258,25Z"
                          transform="translate(-92.559 0)"
                          fill="#7b7b7b"
                        />
                      </g>
                    </svg>

                    <p>Logout </p>
                  </div>
                </div>

                <div className="clearfix"> </div>
              </div>
              <div className="clearfix"> </div>
            </div>

            {/* <!-- End Mobile Menu timecard   -->

              ```
              <!-- Attachment Modal --> */}

            {/* <!-- <div id="approve_modal" className="modal fade" role="dialog">
              <div className="modal-dialog modal_middle_employee modal_attachment_dialog">


              <div className="modal-content modal_middle_emp_attach">
              <div className="modal-header">
              <button type="button" className="close  close_modal_employee" data-dismiss="modal">
              Done
              </button>
              <h4 className="modal-title employeel_modal_header modal_attachmnet_heading">&nbsp;

              </h4>
              </div>
              <div className="modal-body">

              <div className="col-sm-12 table_attach_empl payending_hover text-center">
              Approve
              </div>
                <div className="col-sm-12 payending_hover table_attach_empl text-center">
              Pending
              </div>
                <div className="col-sm-12 payending_hover table_attach_empl text-center">
              Declined
              </div>
              <div className="col-sm-12 payending_hover table_attach_empl text-center">
              Approved
              </div>
              <div className="col-sm-12 payending_hover table_attach_empl text-center">
              All
              </div>




              <div className="clear10"></div>

              </div>

              </div>

              </div>
              </div> -->                           */}

            {/* <!-- save page modal -->
              <!-- Attachmen```t Modal --> */}

            <div id="save_modalll" className="modal fade" role="dialog">
              <div className="modal-dialog modal_middle_employee modal_attachment_dialog">
                {/* <!-- Modal content--> */}
                <div className="modal-content modal_middle_emp_attach">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close  close_modal_employee"
                      data-dismiss="modal"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                      >
                        <path
                          id="ic_clear_24px"
                          d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                          transform="translate(-5 -5)"
                          fill="#7B7B7B"
                        />
                      </svg>
                    </button>
                    <h4 className="modal-title employeel_modal_header modal_attachmnet_heading"></h4>
                  </div>
                  <div className="modal-body">
                    <div className="col-xs-12 text-center p0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="24"
                        viewBox="0 0 20 22.223"
                      >
                        <defs></defs>
                        <path
                          id="ic_receipt_24px"
                          className="cls-1"
                          d="M19.667,18.667H6.333V16.444H19.667Zm0-4.444H6.333V12H19.667Zm0-4.444H6.333V7.556H19.667ZM3,24.222l1.667-1.667,1.667,1.667L8,22.556l1.667,1.667,1.667-1.667L13,24.222l1.667-1.667,1.667,1.667L18,22.556l1.667,1.667,1.667-1.667L23,24.222V2L21.333,3.667,19.667,2,18,3.667,16.333,2,14.667,3.667,13,2,11.333,3.667,9.667,2,8,3.667,6.333,2,4.667,3.667,3,2Z"
                          transform="translate(-3 -2)"
                          fill="#30679A"
                        />
                      </svg>
                    </div>
                    <div className="clear20"> </div>
                    <div className="col-xs-12 p0 ">
                      <label>
                        You are about to leave this form without saving, do you
                        wish to save?
                      </label>
                    </div>
                    <div className="clear20"> </div>
                    <div className="col-xs-12">
                      <a
                        hreaf="javascript:void(0);"
                        className="pull-left Save_timecard"
                      >
                        Save
                      </a>
                      <a
                        hreaf="javascript:void(0);"
                        className="pull-right dont_save_timecard"
                      >
                        Don't Save
                      </a>
                    </div>

                    <div className="clear10"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : this.state.isEdit ? (
          <div>
            <HeaderMob
              show={true}
              onClickBackArrowHeaderMob={this.onClickBackArrowHeaderMob}
            />

            <div className="col-sm-12 p0 text-center kevin_plummer_page payending_mbl_mrg analytics_mbl_heading">
              {this.state.empDetail.Employee[0].FirstName}
            </div>

            <div className="col-sm-12 p0 top_mrg_form_res">
              <div className="">
                <div id="mobile_menu_sm">
                  {/* <!-- Sidebar --> */}
                  <div id="sidebar-wrapper " className="analytics_sidebar">
                    <ul className="sidebar-nav plum_sss">
                      <li className="sidebar_active">
                        <a href="dashboard.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="-5378 759 13.333 11.333"
                          >
                            <path
                              id="ic_home_24px"
                              className="cls-1"
                              d="M7.333,14.333v-4H10v4h3.333V9h2L8.667,3,2,9H4v5.333Z"
                              transform="translate(-5380 756)"
                            />
                          </svg>
                          <span> Dashboard </span>
                        </a>
                      </li>
                      <li>
                        <a href="payroll_batches.html">
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 1000 1000"
                            enableBackground=""
                            xmlSpace="preserve"
                          >
                            <g>
                              <path d="M755.1,658c0,55.8-18.1,103.8-54.4,144.1c-36.3,40.3-83.4,65.2-141.4,74.7v95.7c0,5.1-1.6,9.3-4.9,12.6c-3.3,3.3-7.5,4.9-12.6,4.9H468c-4.7,0-8.8-1.7-12.3-5.2c-3.5-3.5-5.2-7.6-5.2-12.3v-95.7c-24.1-3.3-47.3-8.9-69.7-16.9c-22.4-8-40.9-16.1-55.5-24.3c-14.6-8.2-28.1-17-40.5-26.3c-12.4-9.3-20.9-16.1-25.4-20.5c-4.6-4.4-7.8-7.7-9.6-9.8c-6.2-7.7-6.6-15.1-1.1-22.4l56.3-73.8c2.5-3.6,6.7-5.8,12.6-6.5c5.5-0.7,9.8,0.9,13.1,4.9l1.1,1.1c41.2,36.1,85.5,58.9,132.9,68.4c13.5,2.9,27,4.4,40.5,4.4c29.5,0,55.5-7.8,78-23.5c22.4-15.7,33.6-37.9,33.6-66.7c0-10.2-2.7-19.9-8.2-29c-5.5-9.1-11.6-16.8-18.3-23c-6.7-6.2-17.4-13-32-20.5c-14.6-7.5-26.6-13.3-36.1-17.5c-9.5-4.2-24.1-10.1-43.8-17.8c-14.2-5.8-25.4-10.4-33.6-13.7c-8.2-3.3-19.4-8.1-33.6-14.5c-14.2-6.4-25.6-12-34.2-16.9c-8.6-4.9-18.9-11.4-30.9-19.4c-12-8-21.8-15.8-29.3-23.2c-7.5-7.5-15.4-16.4-23.8-26.8c-8.4-10.4-14.9-21-19.4-31.7c-4.6-10.7-8.4-22.9-11.5-36.4c-3.1-13.5-4.6-27.7-4.7-42.7c0-50.3,17.9-94.4,53.6-132.3c35.7-37.9,82.2-62.4,139.5-73.3V27.5c0-4.7,1.7-8.8,5.2-12.3c3.5-3.5,7.6-5.2,12.3-5.2h73.8c5.1,0,9.3,1.6,12.6,4.9c3.3,3.3,4.9,7.5,4.9,12.6v96.3c20.8,2.2,40.9,6.4,60.5,12.6c19.5,6.2,35.4,12.3,47.6,18.3c12.2,6,23.8,12.9,34.7,20.5c10.9,7.7,18,12.9,21.3,15.9c3.3,2.9,6,5.5,8.2,7.7c6.2,6.6,7.1,13.5,2.7,20.8L690,299.3c-2.9,5.5-7.1,8.4-12.6,8.8c-5.1,1.1-10-0.2-14.8-3.8c-1.1-1.1-3.7-3.3-7.9-6.5c-4.2-3.3-11.3-8.1-21.3-14.5c-10-6.4-20.7-12.2-32-17.5s-24.9-10-40.7-14.2c-15.8-4.2-31.4-6.3-46.8-6.3c-34.6,0-62.9,7.8-84.8,23.5c-21.9,15.7-32.8,35.9-32.8,60.7c0,9.5,1.6,18.2,4.7,26.3c3.1,8,8.5,15.6,16.1,22.7c7.7,7.1,14.9,13.1,21.6,18.1s17,10.6,30.6,16.9c13.7,6.3,24.7,11.3,33.1,14.8c8.4,3.5,21.1,8.5,38.3,15.1c19.3,7.3,34.1,13,44.3,17.2c10.2,4.2,24.1,10.6,41.6,19.1s31.3,16.3,41.3,23.2c10,6.9,21.3,16,33.9,27.3c12.6,11.3,22.2,22.9,29,34.7c6.7,11.8,12.5,25.8,17.2,41.8C752.7,622.7,755.1,639.8,755.1,658L755.1,658z" />
                            </g>
                          </svg>
                          <span> Payroll Batches</span>{" "}
                        </a>
                      </li>
                      <li>
                        <a href="timecards.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 23.158"
                          >
                            <path
                              id="ic_content_copy_24px"
                              className="cls-1"
                              d="M16.737,1H4.105A2.111,2.111,0,0,0,2,3.105V17.842H4.105V3.105H16.737Zm3.158,4.211H8.316A2.111,2.111,0,0,0,6.211,7.316V22.053a2.111,2.111,0,0,0,2.105,2.105H19.895A2.111,2.111,0,0,0,22,22.053V7.316A2.111,2.111,0,0,0,19.895,5.211Zm0,16.842H8.316V7.316H19.895Z"
                              transform="translate(-2 -1)"
                            />
                          </svg>
                          <span> Timecards</span>{" "}
                        </a>
                      </li>
                      {/* <li> */}

                      <li>
                        <a href="timecards.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="18px"
                            y="12px"
                            viewBox="0 0 1000 1000"
                            enableBackground="new 0 0 1000 1000"
                            xmlSpace="preserve"
                          >
                            <g>
                              <path d="M620.6,214c-88.3,0-176.5,0-264.8,0c-87.9,0-175.7,0-263.6,0.1c-6.2,0-12.5,0.5-18.6,1.6c-35.9,6.3-63.5,38.2-63.6,73.7C10,429.7,10,570,10,710.3c0,12.6,3,24.6,9.5,35.4C35.9,773,60.5,785.9,91.9,786c176.3,0.1,352.5,0,528.8,0c45.9,0,83.6-37.7,83.6-83.5c0-135,0-270,0-405C704.3,251.7,666.5,214,620.6,214z" />
                              <path d="M985.6,295.6c-10.6,0-21.3-0.1-31.9,0.1c-2.5,0.1-5.3,0.8-7.4,2c-66,39.5-131.9,79-197.8,118.5c-2.5,1.5-3.3,3.1-3.3,6c0.1,51.8,0.1,103.6,0,155.3c0,3.1,1.1,4.7,3.6,6.2c64,38.3,128,76.7,191.9,115.2c6.2,3.7,12.2,5.7,19.4,5.4c9.9-0.5,19.9-0.1,30.1-0.1c0-136.4,0-272.3,0-408.7C988.4,295.6,987,295.6,985.6,295.6z" />
                            </g>
                          </svg>
                          <span> Productions</span>{" "}
                        </a>
                      </li>
                      <li>
                        <a hreaf="javascript:void(0);">
                          <strong> Admin</strong>{" "}
                        </a>
                      </li>

                      <li>
                        <a href="custom_field.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path
                              id="ic_description_24px"
                              className="cls-1"
                              d="M16.5,2H6.5A2.287,2.287,0,0,0,4.013,4L4,20a2.287,2.287,0,0,0,2.487,2H21.5A2.3,2.3,0,0,0,24,20V8ZM19,18H9V16H19Zm0-4H9V12H19ZM15.25,9V3.5L22.125,9Z"
                              transform="translate(-4 -2)"
                            />
                          </svg>
                          <span> Employee List</span>{" "}
                        </a>
                      </li>
                      <li>
                        <a href="custom_list_field.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path
                              id="ic_create_24px"
                              className="cls-1"
                              d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z"
                              transform="translate(-3 -2.998)"
                            />
                          </svg>
                          <span> AD Times</span>{" "}
                        </a>
                      </li>

                      <li>
                        <a href="custom_list_field.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path
                              id="ic_person_24px"
                              className="cls-1"
                              d="M14,14A5,5,0,1,0,9,9,5,5,0,0,0,14,14Zm0,2.5c-3.337,0-10,1.675-10,5V24H24V21.5C24,18.175,17.337,16.5,14,16.5Z"
                              transform="translate(-4 -4)"
                            />
                          </svg>
                          <span> Users</span>{" "}
                        </a>
                      </li>

                      <li>
                        <a href="custom_list_field.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 14"
                          >
                            <path
                              id="ic_supervisor_account_24px"
                              className="cls-1"
                              d="M16.5,12A2.5,2.5,0,1,0,14,9.5,2.492,2.492,0,0,0,16.5,12ZM9,11A3,3,0,1,0,6,8,2.987,2.987,0,0,0,9,11Zm7.5,3c-1.83,0-5.5.92-5.5,2.75V19H22V16.75C22,14.92,18.33,14,16.5,14ZM9,13c-2.33,0-7,1.17-7,3.5V19H9V16.75a3.941,3.941,0,0,1,2.37-3.47A12.283,12.283,0,0,0,9,13Z"
                              transform="translate(-2 -5)"
                            />
                          </svg>
                          <span> Approval Setup</span>{" "}
                        </a>
                      </li>

                      <li>
                        <a href="custom_list_field.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 16"
                          >
                            <path
                              id="ic_markunread_24px"
                              className="cls-1"
                              d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,4-8,5L4,8V6l8,5,8-5Z"
                              transform="translate(-2 -4)"
                            />
                          </svg>
                          <span> Email Template</span>{" "}
                        </a>
                      </li>

                      <li>
                        <a href="custom_list_field.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20.561"
                          >
                            <path
                              id="ic_settings_24px"
                              className="cls-1"
                              d="M19.911,13.288a8.012,8.012,0,0,0,.072-1.007,8.012,8.012,0,0,0-.072-1.007l2.169-1.7a.519.519,0,0,0,.123-.658L20.148,5.362a.517.517,0,0,0-.627-.226l-2.56,1.028a7.511,7.511,0,0,0-1.737-1.007l-.391-2.724a.5.5,0,0,0-.5-.432H10.217a.5.5,0,0,0-.5.432L9.322,5.156A7.9,7.9,0,0,0,7.585,6.164L5.025,5.136a.5.5,0,0,0-.627.226L2.342,8.919a.507.507,0,0,0,.123.658l2.169,1.7a8.153,8.153,0,0,0-.072,1.007,8.153,8.153,0,0,0,.072,1.007l-2.169,1.7a.519.519,0,0,0-.123.658L4.4,19.2a.517.517,0,0,0,.627.226L7.585,18.4A7.511,7.511,0,0,0,9.322,19.4l.391,2.724a.5.5,0,0,0,.5.432h4.112a.5.5,0,0,0,.5-.432l.391-2.724A7.9,7.9,0,0,0,16.961,18.4l2.56,1.028a.5.5,0,0,0,.627-.226L22.2,15.642a.519.519,0,0,0-.123-.658Zm-7.638,2.591a3.6,3.6,0,1,1,3.6-3.6A3.6,3.6,0,0,1,12.273,15.879Z"
                              transform="translate(-2.271 -2)"
                            />
                          </svg>
                          <span> Settings</span>{" "}
                        </a>
                      </li>

                      <li>
                        <a href="custom_list_field.html">
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="17px"
                            y="17px"
                            viewBox="0 0 1000 1000"
                            enableBackground="new 0 0 1000 1000"
                            xmlSpace="preserve"
                          >
                            <g>
                              <path d="M968.4,789.2L561.9,451.7c23.2-42.1,37.6-89.8,37.6-141.2c0-162.8-132-294.8-294.8-294.8c-34.8,0-67.8,7.1-98.8,18.2l99.1,94.9c49.1,47,50.8,125,3.8,174.1c-47,49.1-125,50.8-174.1,3.7L29.9,206.3C17.6,238.8,10,273.6,10,310.4c0,162.8,132,294.8,294.8,294.8c54.2,0,104.3-15.7,148-41.2l344.5,399.6c22.9,26.5,61.7,27.8,86.3,2.8l89.3-91C997.4,850.4,995.4,811.6,968.4,789.2z" />
                            </g>
                          </svg>
                          <span> Setup</span>{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="clearfix"></div>
              </div>

              <div className="clearfix"></div>
            </div>

            <div className="clearfix"></div>

            <div className="col-md-12 col-xs-12 analytics_wrapper timecrad_coments_display payend_final">
              {/* <!-- Modal content--> */}

              <div className="col-sm-12 table_attach_empl p0">
                <div className="table-responsive">
                  <table
                    width="100%"
                    className="table table-condensed table_attach_bdr_pay_ending_kevin"
                  >
                    <thead>
                      <tr>
                        <th width="30%" align="left">
                          Date
                        </th>
                        <th width="30%" align="left">
                          Day
                        </th>
                        <th width="30%" align="left">
                          Hours
                        </th>
                        <th width="30%" align="left">
                          &nbsp;
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.empDetail.PayTimes
                        ? this.state.empDetail.PayTimes.Times
                          ? this.state.empDetail.PayTimes.Times[0].DailyTimes.map(
                            (value, index) => (
                              <>
                                <tr>
                                  <td>
                                    {new Date(
                                      value.Date * 1000
                                    ).toLocaleDateString("en-GB")}
                                  </td>
                                  <td>{value.Day}</td>
                                  <td> {value.TotalHours}</td>
                                  <td className="right_icon_payending">
                                    <div
                                      onClick={() =>
                                        this.editmobdata(index, value)
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="13"
                                        height="13.053"
                                        viewBox="0 0 13 21.053"
                                      >
                                        <path
                                          id="ic_chevron_right_24px"
                                          d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                                          transform="translate(-8.59 -6)"
                                          fill="#30679A"
                                        />
                                      </svg>{" "}
                                    </div>{" "}
                                  </td>
                                </tr>
                              </>
                            )
                          )
                          : this.state.empDetail.PayTimes.map(
                            (value, index) => (
                              <>
                                <tr>
                                  <td>
                                    {" "}
                                    {new Date(
                                      value.adlistdate
                                    ).toLocaleDateString("en-GB")}
                                  </td>
                                  <td>
                                    {
                                      days[
                                      new Date(value.adlistdate).getDay()
                                      ]
                                    }
                                  </td>
                                  <td>
                                    {" "}
                                    {moment(value.TotalHours)
                                      .format("HH:mm")
                                      .toString()}
                                  </td>
                                  <td className="right_icon_payending">
                                    <div
                                      onClick={() =>
                                        this.editmobdata(index, value)
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="13"
                                        height="13.053"
                                        viewBox="0 0 13 21.053"
                                      >
                                        <path
                                          id="ic_chevron_right_24px"
                                          d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                                          transform="translate(-8.59 -6)"
                                          fill="#30679A"
                                        />
                                      </svg>{" "}
                                    </div>{" "}
                                  </td>
                                </tr>
                              </>
                            )
                          )
                        : "no paytimes"}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="clear30"></div>
            </div>
            <div className="clearfix"> </div>
          </div>
        ) : this.state.mtd ? (
          <div>
            <HeaderMob
              onClickBackArrowHeaderMob={this.onClickBackArrowHeaderEditMob}
              show={true}
            />

            <div className="col-sm-12 p0 top_mrg_form_res">
              <div className="">
                <div id="mobile_menu_sm">
                  {/* <!-- Sidebar --> */}
                  <div id="sidebar-wrapper " className="analytics_sidebar">
                    <ul className="sidebar-nav">
                      <li className="sidebar_active">
                        <a href="dashboard.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="-5378 759 13.333 11.333"
                          >
                            <path
                              id="ic_home_24px"
                              className="cls-1"
                              d="M7.333,14.333v-4H10v4h3.333V9h2L8.667,3,2,9H4v5.333Z"
                              transform="translate(-5380 756)"
                            />
                          </svg>
                          <span> Dashboard </span>
                        </a>
                      </li>
                      <li>
                        <a href="payroll_batches.html">
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 1000 1000"
                            enableBackground=""
                            xmlSpace="preserve"
                          >
                            <g>
                              <path d="M755.1,658c0,55.8-18.1,103.8-54.4,144.1c-36.3,40.3-83.4,65.2-141.4,74.7v95.7c0,5.1-1.6,9.3-4.9,12.6c-3.3,3.3-7.5,4.9-12.6,4.9H468c-4.7,0-8.8-1.7-12.3-5.2c-3.5-3.5-5.2-7.6-5.2-12.3v-95.7c-24.1-3.3-47.3-8.9-69.7-16.9c-22.4-8-40.9-16.1-55.5-24.3c-14.6-8.2-28.1-17-40.5-26.3c-12.4-9.3-20.9-16.1-25.4-20.5c-4.6-4.4-7.8-7.7-9.6-9.8c-6.2-7.7-6.6-15.1-1.1-22.4l56.3-73.8c2.5-3.6,6.7-5.8,12.6-6.5c5.5-0.7,9.8,0.9,13.1,4.9l1.1,1.1c41.2,36.1,85.5,58.9,132.9,68.4c13.5,2.9,27,4.4,40.5,4.4c29.5,0,55.5-7.8,78-23.5c22.4-15.7,33.6-37.9,33.6-66.7c0-10.2-2.7-19.9-8.2-29c-5.5-9.1-11.6-16.8-18.3-23c-6.7-6.2-17.4-13-32-20.5c-14.6-7.5-26.6-13.3-36.1-17.5c-9.5-4.2-24.1-10.1-43.8-17.8c-14.2-5.8-25.4-10.4-33.6-13.7c-8.2-3.3-19.4-8.1-33.6-14.5c-14.2-6.4-25.6-12-34.2-16.9c-8.6-4.9-18.9-11.4-30.9-19.4c-12-8-21.8-15.8-29.3-23.2c-7.5-7.5-15.4-16.4-23.8-26.8c-8.4-10.4-14.9-21-19.4-31.7c-4.6-10.7-8.4-22.9-11.5-36.4c-3.1-13.5-4.6-27.7-4.7-42.7c0-50.3,17.9-94.4,53.6-132.3c35.7-37.9,82.2-62.4,139.5-73.3V27.5c0-4.7,1.7-8.8,5.2-12.3c3.5-3.5,7.6-5.2,12.3-5.2h73.8c5.1,0,9.3,1.6,12.6,4.9c3.3,3.3,4.9,7.5,4.9,12.6v96.3c20.8,2.2,40.9,6.4,60.5,12.6c19.5,6.2,35.4,12.3,47.6,18.3c12.2,6,23.8,12.9,34.7,20.5c10.9,7.7,18,12.9,21.3,15.9c3.3,2.9,6,5.5,8.2,7.7c6.2,6.6,7.1,13.5,2.7,20.8L690,299.3c-2.9,5.5-7.1,8.4-12.6,8.8c-5.1,1.1-10-0.2-14.8-3.8c-1.1-1.1-3.7-3.3-7.9-6.5c-4.2-3.3-11.3-8.1-21.3-14.5c-10-6.4-20.7-12.2-32-17.5s-24.9-10-40.7-14.2c-15.8-4.2-31.4-6.3-46.8-6.3c-34.6,0-62.9,7.8-84.8,23.5c-21.9,15.7-32.8,35.9-32.8,60.7c0,9.5,1.6,18.2,4.7,26.3c3.1,8,8.5,15.6,16.1,22.7c7.7,7.1,14.9,13.1,21.6,18.1s17,10.6,30.6,16.9c13.7,6.3,24.7,11.3,33.1,14.8c8.4,3.5,21.1,8.5,38.3,15.1c19.3,7.3,34.1,13,44.3,17.2c10.2,4.2,24.1,10.6,41.6,19.1s31.3,16.3,41.3,23.2c10,6.9,21.3,16,33.9,27.3c12.6,11.3,22.2,22.9,29,34.7c6.7,11.8,12.5,25.8,17.2,41.8C752.7,622.7,755.1,639.8,755.1,658L755.1,658z" />
                            </g>
                          </svg>
                          <span> Payroll Batches</span>{" "}
                        </a>
                      </li>
                      <li>
                        <a href="timecards.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 23.158"
                          >
                            <path
                              id="ic_content_copy_24px"
                              className="cls-1"
                              d="M16.737,1H4.105A2.111,2.111,0,0,0,2,3.105V17.842H4.105V3.105H16.737Zm3.158,4.211H8.316A2.111,2.111,0,0,0,6.211,7.316V22.053a2.111,2.111,0,0,0,2.105,2.105H19.895A2.111,2.111,0,0,0,22,22.053V7.316A2.111,2.111,0,0,0,19.895,5.211Zm0,16.842H8.316V7.316H19.895Z"
                              transform="translate(-2 -1)"
                            />
                          </svg>
                          <span> Timecards</span>{" "}
                        </a>
                      </li>
                      {/* <li> */}

                      <li>
                        <a href="timecards.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="18px"
                            y="12px"
                            viewBox="0 0 1000 1000"
                            enableBackground="new 0 0 1000 1000"
                            xmlSpace="preserve"
                          >
                            <g>
                              <path d="M620.6,214c-88.3,0-176.5,0-264.8,0c-87.9,0-175.7,0-263.6,0.1c-6.2,0-12.5,0.5-18.6,1.6c-35.9,6.3-63.5,38.2-63.6,73.7C10,429.7,10,570,10,710.3c0,12.6,3,24.6,9.5,35.4C35.9,773,60.5,785.9,91.9,786c176.3,0.1,352.5,0,528.8,0c45.9,0,83.6-37.7,83.6-83.5c0-135,0-270,0-405C704.3,251.7,666.5,214,620.6,214z" />
                              <path d="M985.6,295.6c-10.6,0-21.3-0.1-31.9,0.1c-2.5,0.1-5.3,0.8-7.4,2c-66,39.5-131.9,79-197.8,118.5c-2.5,1.5-3.3,3.1-3.3,6c0.1,51.8,0.1,103.6,0,155.3c0,3.1,1.1,4.7,3.6,6.2c64,38.3,128,76.7,191.9,115.2c6.2,3.7,12.2,5.7,19.4,5.4c9.9-0.5,19.9-0.1,30.1-0.1c0-136.4,0-272.3,0-408.7C988.4,295.6,987,295.6,985.6,295.6z" />
                            </g>
                          </svg>
                          <span> Productions</span>{" "}
                        </a>
                      </li>
                      <li>
                        <a hreaf="javascript:void(0);">
                          <strong> Admin</strong>{" "}
                        </a>
                      </li>

                      <li>
                        <a href="custom_field.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path
                              id="ic_description_24px"
                              className="cls-1"
                              d="M16.5,2H6.5A2.287,2.287,0,0,0,4.013,4L4,20a2.287,2.287,0,0,0,2.487,2H21.5A2.3,2.3,0,0,0,24,20V8ZM19,18H9V16H19Zm0-4H9V12H19ZM15.25,9V3.5L22.125,9Z"
                              transform="translate(-4 -2)"
                            />
                          </svg>
                          <span> Employee List</span>{" "}
                        </a>
                      </li>
                      <li>
                        <a href="custom_list_field.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path
                              id="ic_create_24px"
                              className="cls-1"
                              d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z"
                              transform="translate(-3 -2.998)"
                            />
                          </svg>
                          <span> AD Times</span>{" "}
                        </a>
                      </li>

                      <li>
                        <a href="custom_list_field.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path
                              id="ic_person_24px"
                              className="cls-1"
                              d="M14,14A5,5,0,1,0,9,9,5,5,0,0,0,14,14Zm0,2.5c-3.337,0-10,1.675-10,5V24H24V21.5C24,18.175,17.337,16.5,14,16.5Z"
                              transform="translate(-4 -4)"
                            />
                          </svg>
                          <span> Users</span>{" "}
                        </a>
                      </li>

                      <li>
                        <a href="custom_list_field.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 14"
                          >
                            <path
                              id="ic_supervisor_account_24px"
                              className="cls-1"
                              d="M16.5,12A2.5,2.5,0,1,0,14,9.5,2.492,2.492,0,0,0,16.5,12ZM9,11A3,3,0,1,0,6,8,2.987,2.987,0,0,0,9,11Zm7.5,3c-1.83,0-5.5.92-5.5,2.75V19H22V16.75C22,14.92,18.33,14,16.5,14ZM9,13c-2.33,0-7,1.17-7,3.5V19H9V16.75a3.941,3.941,0,0,1,2.37-3.47A12.283,12.283,0,0,0,9,13Z"
                              transform="translate(-2 -5)"
                            />
                          </svg>
                          <span> Approval Setup</span>{" "}
                        </a>
                      </li>

                      <li>
                        <a href="custom_list_field.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 16"
                          >
                            <path
                              id="ic_markunread_24px"
                              className="cls-1"
                              d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,4-8,5L4,8V6l8,5,8-5Z"
                              transform="translate(-2 -4)"
                            />
                          </svg>
                          <span> Email Template</span>{" "}
                        </a>
                      </li>

                      <li>
                        <a href="custom_list_field.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20.561"
                          >
                            <path
                              id="ic_settings_24px"
                              className="cls-1"
                              d="M19.911,13.288a8.012,8.012,0,0,0,.072-1.007,8.012,8.012,0,0,0-.072-1.007l2.169-1.7a.519.519,0,0,0,.123-.658L20.148,5.362a.517.517,0,0,0-.627-.226l-2.56,1.028a7.511,7.511,0,0,0-1.737-1.007l-.391-2.724a.5.5,0,0,0-.5-.432H10.217a.5.5,0,0,0-.5.432L9.322,5.156A7.9,7.9,0,0,0,7.585,6.164L5.025,5.136a.5.5,0,0,0-.627.226L2.342,8.919a.507.507,0,0,0,.123.658l2.169,1.7a8.153,8.153,0,0,0-.072,1.007,8.153,8.153,0,0,0,.072,1.007l-2.169,1.7a.519.519,0,0,0-.123.658L4.4,19.2a.517.517,0,0,0,.627.226L7.585,18.4A7.511,7.511,0,0,0,9.322,19.4l.391,2.724a.5.5,0,0,0,.5.432h4.112a.5.5,0,0,0,.5-.432l.391-2.724A7.9,7.9,0,0,0,16.961,18.4l2.56,1.028a.5.5,0,0,0,.627-.226L22.2,15.642a.519.519,0,0,0-.123-.658Zm-7.638,2.591a3.6,3.6,0,1,1,3.6-3.6A3.6,3.6,0,0,1,12.273,15.879Z"
                              transform="translate(-2.271 -2)"
                            />
                          </svg>
                          <span> Settings</span>{" "}
                        </a>
                      </li>

                      <li>
                        <a href="custom_list_field.html">
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="17px"
                            y="17px"
                            viewBox="0 0 1000 1000"
                            enableBackground="new 0 0 1000 1000"
                            xmlSpace="preserve"
                          >
                            <g>
                              <path d="M968.4,789.2L561.9,451.7c23.2-42.1,37.6-89.8,37.6-141.2c0-162.8-132-294.8-294.8-294.8c-34.8,0-67.8,7.1-98.8,18.2l99.1,94.9c49.1,47,50.8,125,3.8,174.1c-47,49.1-125,50.8-174.1,3.7L29.9,206.3C17.6,238.8,10,273.6,10,310.4c0,162.8,132,294.8,294.8,294.8c54.2,0,104.3-15.7,148-41.2l344.5,399.6c22.9,26.5,61.7,27.8,86.3,2.8l89.3-91C997.4,850.4,995.4,811.6,968.4,789.2z" />
                            </g>
                          </svg>
                          <span> Setup</span>{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="clearfix"></div>
              </div>

              <div className="clearfix"></div>
            </div>

            <div className="clearfix"></div>

            <div className="clear20"> </div>
            {/* <!-- msg mobile Screen --> */}
            <div id="pay_as_display_none">
              <div className="col-xs-12 tiimecard_list_2">
                <div className="col-xs-4 p0">
                  <label> Pay As</label>
                </div>

                <div className="col-xs-8 p0">
                  <select
                    className="form-control"
                    id="timeCards_editPayAs"
                    name="timeCards_editPayAs"
                    value={this.state.edit_paytimes_payas}
                    onChange={this.editPaytimesPayasHandler}
                  >
                    <option value={"NaN"}>No Payas Selected</option>
                    {this.state.empDetail.PayTimes
                      ? this.state.empDetail.PayTimes.Company
                        ? this.state.empDetail.PayTimes.Company[0].Payas.map(
                          function (item) {
                            return (
                              <option value={item.Value}>{item.Value}</option>
                            );
                          }
                        )
                        : this.state.PayasList
                          ? this.state.PayasList.map(function (item) {
                            return (
                              <option value={item.Flag}>{item.Value}</option>
                            );
                          })
                          : ""
                      : "NA"}
                  </select>
                </div>
                <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label> Date </label>
                  </div>
                  <div className="col-xs-6 p0">
                    {/* <!--  <input type="date" name="" className="form-control"> --> */}
                    <p>
                      {this.state.edit_paytimes_day}{" "}
                      {this.state.edit_paytimes_date}{" "}
                    </p>
                  </div>
                </div>

                <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label> Travel to </label>
                  </div>
                  <div className="col-xs-3 p0">
                    <TimePicker
                      showSecond={false}
                      value={moment(this.state.edit_paytimes_travelto, "HH:mm")}
                      name="edit_paytimes_travelto"
                      onChange={this.timescard_times_inputHandler.bind(
                        this,
                        "edit_paytimes_travelto"
                      )}
                      placeholder="12:00"
                    />
                  </div>
                </div>

                <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label> Start Work </label>
                  </div>
                  <div className="col-xs-3 p0">
                    <TimePicker
                      showSecond={false}
                      value={moment(this.state.edit_paytimes_start, "HH:mm")}
                      name="start_work"
                      onChange={this.timescard_times_inputHandler.bind(
                        this,
                        "edit_paytimes_start"
                      )}
                      placeholder="12:00"
                    />
                  </div>
                </div>

                <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label> Meal Break </label>
                  </div>
                  {/* <div className="col-xs-4 p0 tiimecard_svg_absolute"> */}
                  <TimePicker
                    showSecond={false}
                    value={moment(this.state.edit_paytimes_total_meal, "HH:mm")}
                    name="mealbreak"
                    disabled={this.state.disableMealbreak ? true : false}
                    onChange={this.timescard_times_inputHandler.bind(
                      this,
                      "edit_paytimes_total_meal"
                    )}
                    placeholder="12:00"
                  />
                  <button
                    data-toggle="collapse"
                    data-target="#meal_content_collapse"
                    className="btn"
                    type="button"
                    id="btn_cllps"
                    onClick={this.handleModal_Height}
                  >
                    <i className="fa fa-angle-down" aria-expanded="true" />
                  </button>
                  <div
                    className="meal_collapse collapse"
                    id="meal_content_collapse"
                  >
                    <table
                      width="100%"
                      className="table tbl_pencil_info_collapse"
                    >
                      <thead>
                        <tr className="">
                          <th width="20%" className="text-left">
                            &nbsp;{" "}
                          </th>
                          <th width="20%" className="text-left">
                            &nbsp;
                          </th>
                          <th width="20%" className="text-left">
                            &nbsp;
                          </th>
                          <th width="20%" className="text-left">
                            &nbsp;{" "}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Start MB1</td>
                          <td>{this.state.prlObject.StartMeal}</td>
                          <td>
                            {this.state.adTimesObject &&
                              this.state.adTimesObject.mealBreak
                              ? this.state.adTimesObject.mealBreak[0].MB1Start
                              : "-"}
                          </td>
                          <td>
                            <TimePicker
                              showSecond={false}
                              value={moment(
                                this.state.timeCards_editStartMB1,
                                "HH:mm"
                              )}
                              name="mb1"
                              onChange={this.timescard_times_inputHandler.bind(
                                this,
                                "timeCards_editStartMB1"
                              )}
                              placeholder="12:00"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Finish MB1</td>
                          <td>{this.state.prlObject.FinishMeal1}</td>
                          <td>
                            {this.state.adTimesObject &&
                              this.state.adTimesObject.mealBreak
                              ? this.state.adTimesObject.mealBreak[0].MB1Finish
                              : "-"}
                          </td>
                          <td>
                            <TimePicker
                              showSecond={false}
                              value={moment(
                                this.state.timeCards_editFinishMB1,
                                "HH:mm"
                              )}
                              name="fmb1"
                              onChange={this.timescard_times_inputHandler.bind(
                                this,
                                "timeCards_editFinishMB1"
                              )}
                              placeholder="12:00"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Start MB2</td>
                          <td>{this.state.prlObject.StartMeal2}</td>
                          <td>
                            {this.state.adTimesObject &&
                              this.state.adTimesObject.mealBreak
                              ? this.state.adTimesObject.mealBreak[1].MB2Start
                              : "-"}
                          </td>
                          <td>
                            <TimePicker
                              showSecond={false}
                              value={moment(
                                this.state.timeCards_editStartMB2,
                                "HH:mm"
                              )}
                              name="mb2"
                              onChange={this.timescard_times_inputHandler.bind(
                                this,
                                "timeCards_editStartMB2"
                              )}
                              placeholder="12:00"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Finish MB2</td>
                          <td>{this.state.prlObject.FinishMeal2}</td>
                          <td>
                            {this.state.adTimesObject &&
                              this.state.adTimesObject.mealBreak
                              ? this.state.adTimesObject.mealBreak[1].MB2Finish
                              : "-"}
                          </td>
                          <td>
                            <TimePicker
                              showSecond={false}
                              value={moment(
                                this.state.timeCards_editFinishMB2,
                                "HH:mm"
                              )}
                              name="fmb2"
                              onChange={this.timescard_times_inputHandler.bind(
                                this,
                                "timeCards_editFinishMB2"
                              )}
                              placeholder="12:00"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Start MB3</td>
                          <td>{this.state.prlObject.StartMeal3}</td>
                          <td>
                            {this.state.adTimesObject &&
                              this.state.adTimesObject.mealBreak
                              ? this.state.adTimesObject.mealBreak[2].MB3Start
                              : "-"}
                          </td>
                          <td>
                            <TimePicker
                              showSecond={false}
                              value={moment(
                                this.state.timeCards_editStartMB3,
                                "HH:mm"
                              )}
                              name="mb3"
                              onChange={this.timescard_times_inputHandler.bind(
                                this,
                                "timeCards_editStartMB3"
                              )}
                              placeholder="12:00"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Finish MB3</td>
                          <td>{this.state.prlObject.FinishMeal3}</td>
                          <td>
                            {this.state.adTimesObject &&
                              this.state.adTimesObject.mealBreak
                              ? this.state.adTimesObject.mealBreak[2].MB3Finish
                              : "-"}
                          </td>
                          <td>
                            <TimePicker
                              showSecond={false}
                              value={moment(
                                this.state.timeCards_editFinishMB3,
                                "HH:mm"
                              )}
                              name="fmb3"
                              onChange={this.timescard_times_inputHandler.bind(
                                this,
                                "timeCards_editFinishMB3"
                              )}
                              placeholder="12:00"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div className="clear20" />
                    <table
                      width="100%"
                      className="table tbl_pencil_info_collapse"
                    >
                      <thead>
                        <tr className="">
                          <th width="20%" className="text-left">
                            &nbsp;{" "}
                          </th>
                          <th width="20%" className="text-left">
                            &nbsp;
                          </th>
                          <th width="20%" className="text-left">
                            &nbsp;
                          </th>
                          <th width="20%" className="text-left">
                            &nbsp;{" "}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Non Deductible MB1:</td>
                          <td>
                            <input
                              type="checkbox"
                              id="originalCheckMB1"
                              name="originalCheckMB1"
                              checked={
                                this.state.timeCards_NonDeductibleMB1
                                  .originalCheckMB1
                              }
                              onChange={this.timeCards_checkBoxHandler}
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              id="adCheckMB1"
                              name="adCheckMB1"
                              checked={
                                this.state.timeCards_NonDeductibleMB1.adCheckMB1
                              }
                              onChange={this.timeCards_checkBoxHandler}
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              id="payCheckMB1"
                              name="payCheckMB1"
                              checked={
                                this.state.timeCards_NonDeductibleMB1
                                  .payCheckMB1
                              }
                              onChange={this.timeCards_checkBoxHandler}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Non Deductible MB2:</td>
                          <td>
                            <input
                              type="checkbox"
                              id="originalCheckMB2"
                              name="originalCheckMB2"
                              checked={
                                this.state.timeCards_NonDeductibleMB2
                                  .originalCheckMB2
                              }
                              onChange={this.timeCards_checkBoxHandler}
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              id="adCheckMB2"
                              name="adCheckMB2"
                              checked={
                                this.state.timeCards_NonDeductibleMB2.adCheckMB2
                              }
                              onChange={this.timeCards_checkBoxHandler}
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              id="payCheckMB2"
                              name="payCheckMB2"
                              checked={
                                this.state.timeCards_NonDeductibleMB2
                                  .payCheckMB2
                              }
                              onChange={this.timeCards_checkBoxHandler}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Non Deductible MB3:</td>
                          <td>
                            <input
                              type="checkbox"
                              id="originalCheckMB3"
                              name="originalCheckMB3"
                              checked={
                                this.state.timeCards_NonDeductibleMB3
                                  .originalCheckMB3
                              }
                              onChange={this.timeCards_checkBoxHandler}
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              id="adCheckMB3"
                              name="adCheckMB3"
                              checked={
                                this.state.timeCards_NonDeductibleMB3.adCheckMB3
                              }
                              onChange={this.timeCards_checkBoxHandler}
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              id="payCheckMB3"
                              name="payCheckMB3"
                              checked={
                                this.state.timeCards_NonDeductibleMB3
                                  .payCheckMB3
                              }
                              onChange={this.timeCards_checkBoxHandler}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {/* </div> */}
                </div>

                <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label> Finish Work </label>
                  </div>
                  <div className="col-xs-3 p0">
                    <TimePicker
                      showSecond={false}
                      value={moment(this.state.edit_paytimes_finish, "HH:mm")}
                      name="finish_work"
                      onChange={this.timescard_times_inputHandler.bind(
                        this,
                        "edit_paytimes_finish"
                      )}
                      placeholder="12:00"
                    />
                  </div>
                </div>

                <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label> Travel from </label>
                  </div>
                  <div className="col-xs-3 p0">
                    <TimePicker
                      showSecond={false}
                      value={moment(
                        this.state.edit_paytimes_travelfrom,
                        "HH:mm"
                      )}
                      name="edit_paytimes_travelfrom"
                      onChange={this.timescard_times_inputHandler.bind(
                        this,
                        "edit_paytimes_travelfrom"
                      )}
                      placeholder="12:00"
                    />
                  </div>
                </div>

                <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label> Total Hours </label>
                  </div>
                  <div className="col-xs-3 p0">
                    <TimePicker
                      showSecond={false}
                      value={moment(
                        this.state.edit_paytimes_totalhours,
                        "HH:mm"
                      )}
                      name="edit_paytimes_totalhours"
                      onChange={this.timescard_times_inputHandler.bind(
                        this,
                        "edit_paytimes_totalhours"
                      )}
                      placeholder="12:00"
                    />
                  </div>
                </div>

                <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label> Rate </label>
                  </div>
                  <div className="col-xs-8 p0">
                    <input
                      id="timeCards_editRate"
                      name="timeCards_editRate"
                      value={this.state.timeCards_editRate}
                      onChange={this.timeCards_inputHandler}
                      className="form-control"
                      type="number"
                      step="0.01"
                    />
                  </div>
                </div>

                <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label> Note </label>
                  </div>
                  <div className="col-xs-8 p0">
                    <input
                      id="timeCards_editNotes"
                      name="timeCards_editNotes"
                      value={this.state.timeCards_editNotes}
                      onChange={this.timeCards_inputHandler}
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label> Template</label>
                  </div>

                  <div className="col-xs-8 p0">
                    <span className="form-control">
                      {this.state.empDetail &&
                        this.state.empDetail.Employee &&
                        this.state.empDetail.Employee[0].TemplateCode &&
                        this.state.empDetail.Employee[0].TemplateCode
                        ? this.state.empDetail.Employee[0].TemplateCode
                        : "-"}
                    </span>
                  </div>
                </div>

                <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label> Payment Flag </label>
                  </div>
                  <div className="col-xs-8 p0">
                    <input
                      id="timeCards_editPaymentFlag"
                      name="timeCards_editPaymentFlag"
                      value={this.state.timeCards_editPaymentFlag}
                      onChange={this.timeCards_inputHandler}
                      className="form-control"
                      placeholder=""
                      type="text"
                    />
                  </div>
                </div>

                <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label>Internal Notes </label>
                  </div>
                  <div className="col-xs-8 p0">
                    <input
                      id="timeCards_editInternalNote"
                      name="timeCards_editInternalNote"
                      value={this.state.timeCards_editInternalNote}
                      onChange={this.timeCards_inputHandler}
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>

                <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label> Chart Sort </label>
                  </div>
                  <div className="col-xs-8 p0">
                    <input
                      id="timeCards_editChartSort"
                      name="timeCards_editChartSort"
                      value={this.state.timeCards_editChartSort}
                      onChange={this.timeCards_inputHandler}
                      className="form-control"
                      placeholder="AU01000"
                      type="text"
                    />
                  </div>
                </div>

                <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label> Chart Code </label>
                  </div>
                  <div className="col-xs-8 p0">
                    <input
                      id="timeCards_editChartCode"
                      name="timeCards_editChartCode"
                      value={this.state.timeCards_editChartCode}
                      onChange={this.timeCards_inputHandler}
                      className="form-control"
                      placeholder="C02A"
                      type="text"
                    />
                  </div>
                </div>

                <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label> Work State </label>
                  </div>
                  <div className="col-xs-8 p0">
                    <input
                      id="timeCards_editWorkState"
                      name="timeCards_editWorkState"
                      value={this.state.timeCards_editWorkState}
                      onChange={this.timeCards_inputHandler}
                      className="form-control"
                      placeholder="VIC"
                      type="text"
                    />
                  </div>
                </div>

                {/* <!--  <div className="clear20"> </div>
            <div className="col-xs-12 p0">
            <div className="col-xs-4 p0"> 
                <label> Work State </label>
            </div>
            <div className="col-xs-8 p0">
                <input type="text" name="" className="form-control" placeholder="VIC">
            </div>
            </div> --> */}

                {/* <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label> IN </label>
                  </div>
                  <div className="col-xs-8 p0">
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="Q"
                    />
                  </div>
                </div>

                <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label> TX </label>
                  </div>
                  <div className="col-xs-8 p0">
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="V"
                    />
                  </div>
                </div>

                <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label> F1 </label>
                  </div>
                  <div className="col-xs-8 p0">
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="C02A"
                    />
                  </div>
                </div>

                <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label> F2 </label>
                  </div>
                  <div className="col-xs-8 p0">
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="VIC"
                    />
                  </div>
                </div>

                <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label> F3 </label>
                  </div>
                  <div className="col-xs-8 p0">
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="Q"
                    />
                  </div>
                </div>

                <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label> F4 </label>
                  </div>
                  <div className="col-xs-8 p0">
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="V"
                    />
                  </div>
                </div>

                <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label> F5 </label>
                  </div>
                  <div className="col-xs-8 p0">
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="C02A"
                    />
                  </div>
                </div>
                <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label> F6 </label>
                  </div>
                  <div className="col-xs-8 p0">
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="VIC"
                    />
                  </div>
                </div>

                <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label> F7 </label>
                  </div>
                  <div className="col-xs-8 p0">
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="Q"
                    />
                  </div>
                </div> */}
                {/* 
                <div className="clear20"> </div>
                <div className="col-xs-12 p0">
                  <div className="col-xs-4 p0">
                    <label> F8 </label>
                  </div>
                  <div className="col-xs-8 p0">
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="V"
                    />
                  </div>
                </div> */}

                <div className="col-md-12 col-xs-12 col-sm-12 p0">
                  <div className="col-md-4 col-lg-12 col-sm-6 p0">
                    <div className="analytics_btn_save_save save_tiimecard_btn">
                      <a
                        className="btn_cancel_analytics_timecard"
                        onClick={
                          this.state.flagcheck
                            ? this.onSaveEditHandler
                            : undefined
                        }
                      >
                        Save
                      </a>
                    </div>
                    <div className="col-md-3"> </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- msg mobile screen -->
        
        <!--right Modal --> */}
            <div
              id="orignial_adtimes"
              className="modal right fade"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myModalLabel2"
            >
              <div className="modal-dialog original_table" role="document">
                <div className="modal-content modal_table_content_height">
                  {/* <!--  <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title" id="myModalLabel2">Right Sidebar</h4>
                        </div>
         --> */}
                  <div className="modal-body">
                    <table className="table table-condensed">
                      <thead>
                        <tr>
                          <th>Original</th>
                          <th>AD Times</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>0:00</td>
                          <td>0:00</td>
                        </tr>
                        <tr>
                          <td>9:00</td>
                          <td>9:00</td>
                        </tr>
                        <tr>
                          <td>0:00</td>
                          <td>0:00</td>
                        </tr>
                        <tr>
                          <td>0:00</td>
                          <td>0:00</td>
                        </tr>
                        <tr>
                          <td>0:00</td>
                          <td>0:00</td>
                        </tr>
                        <tr>
                          <td>0:00</td>
                          <td>0:00</td>
                        </tr>
                        <tr>
                          <td>0:00</td>
                          <td>0:00</td>
                        </tr>
                        <tr>
                          <td>0:00</td>
                          <td>0:00</td>
                        </tr>
                        <tr>
                          <td>0:00</td>
                          <td>0:00</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <!-- modal-content --> */}
              </div>
              {/* <!-- modal-dialog --> */}
            </div>

            {/* <!-- modal -->
            
        
            <!-- Attachment Modal --> */}

            <div id="save_modalll" className="modal fade" role="dialog">
              <div className="modal-dialog modal_middle_employee modal_attachment_dialog modal_save_cancel_btnnn">
                {/* <!-- Modal content--> */}
                <div className="modal-content ">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close  close_modal_employee"
                      data-dismiss="modal"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                      >
                        <path
                          id="ic_clear_24px"
                          d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                          transform="translate(-5 -5)"
                          fill="#7B7B7B"
                        />
                      </svg>
                    </button>
                    <h4 className="modal-title employeel_modal_header modal_attachmnet_heading"></h4>
                  </div>
                  <div className="modal-body">
                    <div className="col-xs-12 text-center p0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="24"
                        viewBox="0 0 20 22.223"
                      >
                        <defs></defs>
                        <path
                          id="ic_receipt_24px"
                          className="cls-1"
                          d="M19.667,18.667H6.333V16.444H19.667Zm0-4.444H6.333V12H19.667Zm0-4.444H6.333V7.556H19.667ZM3,24.222l1.667-1.667,1.667,1.667L8,22.556l1.667,1.667,1.667-1.667L13,24.222l1.667-1.667,1.667,1.667L18,22.556l1.667,1.667,1.667-1.667L23,24.222V2L21.333,3.667,19.667,2,18,3.667,16.333,2,14.667,3.667,13,2,11.333,3.667,9.667,2,8,3.667,6.333,2,4.667,3.667,3,2Z"
                          transform="translate(-3 -2)"
                          fill="#30679A"
                        />
                      </svg>
                    </div>
                    <div className="clear20"> </div>
                    <div className="col-xs-12 p0 ">
                      <label>
                        You are about to leave this form without saving, do you
                        wish to save?
                      </label>
                    </div>
                    <div className="clear20"> </div>
                    <div className="col-xs-12">
                      <Link
                        to="/kevin_plummer"
                        className="pull-left Save_timecard"
                      >
                        Save
                      </Link>
                      <Link
                        to="/kevin_plummer"
                        className="pull-right dont_save_timecard"
                      >
                        Don't Save
                      </Link>
                    </div>

                    <div className="clear10"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
                  <React.Fragment>
                    <TimeCardHeader
                      onTabClick={this.onTabClick}
                      onClickBackArrowMob={this.onClickBackArrowMob}
                      counts={this.props.count}
                      childProps={this.props.childProps}
                      onClickBackArrow={this.onClickBackArrowMob}
                    />

                    <div className="col-sm-12 p0 text-center payrol_timecard_mbl payroll_mbl_heading">
                      &nbsp;
            </div>

                    <div className="clearfix" />
                    <div className="col-sm-12 p0 top_mrg_form_res">
                      <div className="">
                        <div className="clearfix" />
                      </div>

                      <div className="clearfix" />
                    </div>

                    <div className="clearfix" />
                    <div className="col-sm-12 p0 top_mrg_time_card timecard_mrg_top_less">
                      <div className="col-sm-12 col-md-12 col-lg-12 general_setting_hd p0">
                        <div className="col-sm-12 p0">
                          <div className="tabbable-panel df_tabs dfs_bordr_no">
                            <div className="tabbable-line tabbable-line_timecard">
                              <div className="tab-content tab_no_bdr_mrg ">
                                <div className="tab-pane active" id="tab_approve">
                                  <div id="approve_box">
                                    <div className="col-sm-3 sidebar_main sidebar_kevin_pp">
                                      <div className="clear20"></div>
                                      <div className="grid_layout">
                                        <div className="grid_layout_inner">
                                  {/* {  <button onClick={this.recreatetimesheet}>ffff</button> } */}
                                          <select
                                            name="approve_batch_number"
                                            value={this.state.approve_batch_number}
                                            onChange={this.getBatches}
                                            className="form_detail_select grid_layout_select_ie"
                                          >
                                            <option value="all">Batch#</option>
                                            {this.state.batches_array
                                              .sort((a, b) => {
                                                return a - b;
                                              })
                                              .map(item => (
                                                <option value={item}>{item}</option>
                                              ))}
                                          </select>
                                        </div>
                                        <div className="grid_layout_inner grid_layout_search_ie">
                                          <div className="form searchInput_form">
                                            <div className="form-group">
                                              <button
                                                type="button"
                                                className="search_button search_btn_z_indexx"
                                                value=""
                                              >
                                                <img
                                                  alt="timecardpic"
                                                  src={magnifying_glass}
                                                  width="20"
                                                  height="20"
                                                />
                                              </button>
                                              <input
                                                type="text"
                                                id="timeCards_search"
                                                name="timeCards_search"
                                                value={this.state.timeCards_search}
                                                onChange={this.timeCards_searchHandler}
                                                className="form-control search_input search_opacity_1"
                                                placeholder="What are you looking for"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="clearfix"></div>
                                      {this.state.timeCards_searched_data.length > 0
                                        ? this.state.timeCards_searched_data.map(
                                          (item, index) => (
                                            <div key={index}>
                                              {item.Employee.length > 0 ? (
                                                <div
                                                  id={item.Guid + "approve"}
                                                  className="grid_layout_content approve_labell grid_layout_top_mrgg"
                                                  onClick={() =>
                                                    this.get_emp_details(
                                                      item.Guid,
                                                      "approve"
                                                    )
                                                  }
                                                >
                                                  <div className="grid_content">
                                                    <label>
                                                      {item.Employee[0].FirstName}{" "}
                                                      {item.Employee[0].LastName}
                                                    </label>
                                                    <div>
                                                      {/* <input type="checkbox" name={item.CheckBoxID} onChange={(e) => this.checkedTimeCard(e, item)} / > */}
                                                      <label htmlFor="checkbox1"></label>
                                                    </div>

                                                    <p>
                                                      {item.Employee[0].Department}
                                                    </p>
                                                    <p style={{ fontSize: "14px" }}>
                                                      {item.Employee[0].Position}
                                                    </p>
                                                    <p>{'"' + JSON.parse(localStorage.getItem('completetenant')).CompanyName + '"'}</p>
                                                  </div>
                                                  <div className="grid_content">
                                                    <h3> &nbsp; &nbsp; &nbsp;</h3>

                                                    <p>
                                                      {moment(
                                                        new Date(
                                                          item.PeriodEndingDate
                                                        ).toLocaleDateString()
                                                      ).format("DD/MM/YYYY")}
                                                    </p>
                                                    <p>
                                                      {item.PRLTimes
                                                        ? item.PRLTimes.TotalHours
                                                        : ""}
                                                    </p>
                                                    <a
                                                      href="javascript:void(0);"
                                                      className="cursorPointer"
                                                      data-toggle="modal"
                                                      data-target="#myModal_more_detail"
                                                      onClick={() =>
                                                        this.onMoreDetails()
                                                      }
                                                    >
                                                      More Details
                                              </a>
                                                  </div>
                                                  <br />
                                                </div>
                                              ) : null}
                                            </div>
                                          )
                                        )
                                        : "No Timecards Found!"}
                                    </div>
                                    <div className="col-sm-1 p0 toggle_button_div togle_height_mdd slide-toggle">
                                      <a
                                        href="javascript:void(0);"
                                        className="cursorPointer"
                                      >
                                        <i className="fa fa-caret-left"></i>
                                      </a>
                                    </div>
                                    <div className="tab_content_data">
                                      <div className="col-sm-8 col-md-8 p0 timecard_8_colum">
                                        {this.state.empDetail ? (
                                          <React.Fragment>
                                            <div className="approve_nav_right">
                                              <div className="col-sm-12 col-md-5 col-xs-12 timecard_aprove_section_tab timecard_p0">
                                                <div className="col-sm-4 col-md-4 col-xs-4 timecard_pd_lft_right timecard_p0">
                                                  <div className="timecard_modify_left_aprove timecard_approve_sec">
                                                    <a
                                                      href="javascript:void(0);"
                                                      onClick={this.approve}
                                                      className="cursorPointer"
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="17.6"
                                                        height="13.4"
                                                        viewBox="0 0 17.6 13.4"
                                                      >
                                                        <path
                                                          id="ic_done_24px"
                                                          d="M9,16.2,4.8,12,3.4,13.4,9,19,21,7,19.6,5.6Z"
                                                          transform="translate(-3.4 -5.6)"
                                                          fill="#30679A"
                                                        />
                                                      </svg>
                                                      <span> Approve </span>{" "}
                                                    </a>
                                                  </div>
                                                </div>
                                                <div className="col-sm-4 col-md-4 col-xs-4 timecard_pd_lft_right">
                                                  <div className="timecard_modify_left_aprove timecard_decline_iconn">
                                                    <a
                                                      href="javascript:void(0);"
                                                      className="cursorPointer"
                                                      onClick={this.diclineData}
                                                      data-toggle="modal"
                                                      data-target="#timecard_decline_popup"
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 25 25"
                                                        width="17.4"
                                                        height="13.4"
                                                      >
                                                        <defs></defs>
                                                        <path
                                                          id="ic_not_interested_24px"
                                                          className="cls-1"
                                                          d="M14.5,2A12.5,12.5,0,1,0,27,14.5,12.5,12.5,0,0,0,14.5,2Zm0,22.5a10,10,0,0,1-10-10A9.878,9.878,0,0,1,6.613,8.375L20.625,22.387A9.878,9.878,0,0,1,14.5,24.5Zm7.887-3.875L8.375,6.613A9.878,9.878,0,0,1,14.5,4.5a10,10,0,0,1,10,10A9.878,9.878,0,0,1,22.387,20.625Z"
                                                          transform="translate(-2 -2)"
                                                        />
                                                      </svg>
                                                      <span> Decline </span>{" "}
                                                    </a>
                                                  </div>
                                                </div>
                                                <div className="col-sm-4 col-md-4 col-xs-4 timecard_pd_lft_right">
                                                  <div className="timecard_modify_left_aprove timecard_decline_iconn ">
                                                    <a
                                                      href="javascript:void(0);"
                                                      className="cursorPointer"
                                                      data-toggle="modal"
                                                      data-target="#name_details"
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                        width="17.4"
                                                        height="13.4"
                                                      >
                                                        <defs></defs>
                                                        <path
                                                          id="ic_create_24px"
                                                          className="cls-1"
                                                          d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z"
                                                          transform="translate(-3 -2.998)"
                                                        />
                                                      </svg>
                                                      <span> Edit </span>{" "}
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-sm-6 col-md-4 col-xs-12 timecard_p0">
                                                <div className="timecard_navbar_icon_approve">
                                                  {this.state.image_payslip_path
                                                    .length > 0 ? (
                                                      <div className="timecard_plus_minus_icon">
                                                        <div className="clear5"> </div>
                                                        <a
                                                          href="javascript:void(0);"
                                                          className="cursorPointer"
                                                          onClick={this.zoomout}
                                                        >
                                                          <img
                                                            alt="timecardpic"
                                                            src={minus_3}
                                                          />
                                                        </a>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  {this.state.image_payslip_path
                                                    .length > 0 ? (
                                                      <div className="timecard_plus_svg">
                                                        <div className="clear10"> </div>
                                                        <a
                                                          href="javascript:void(0);"
                                                          className="cursorPointer"
                                                          onClick={this.zoomin}
                                                        >
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="15"
                                                            height="12"
                                                            viewBox="0 0 15 15"
                                                          >
                                                            <path
                                                              id="ic_add_24px"
                                                              d="M20,13.571H13.571V20H11.429V13.571H5V11.429h6.429V5h2.143v6.429H20Z"
                                                              transform="translate(-5 -5)"
                                                              fill="#3c6f9f"
                                                            />
                                                          </svg>
                                                        </a>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  {this.state.image_payslip_path
                                                    .length > 0 ? (
                                                      <div className="timecard_75_text">
                                                        <select
                                                          className={"margin_0px"}
                                                          name=""
                                                          onChange={this.changeImgSize}
                                                        >
                                                          <option value="25">25%</option>
                                                          <option value="50">50%</option>
                                                          <option value="75">75%</option>
                                                          <option value="100">
                                                            100%
                                                </option>
                                                        </select>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  {/* <div className="timecard_cross_all_img">
<div className="clear10"> </div>
<a href="javascript:void(0);" className="cursorPointer"><img alt="timecardpic" src={cross_all_3} /></a>
</div> */}
                                                  {this.state.image_payslip_path
                                                    .length > 0 ? (
                                                      <div className="timecard_arow_left_right_img">
                                                        <a
                                                          href="javascript:void(0);"
                                                          className="cursorPointer"
                                                        >
                                                          {" "}
                                                          <img
                                                            alt="timecardpic"
                                                            src={img_left_right_icon}
                                                            onClick={this.handleImageSize}
                                                          />
                                                        </a>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  {this.state.isOpen && (
                                                    <Lightbox
                                                      mainSrc={
                                                        this.state.image_payslip_path &&
                                                        this.state.image_payslip_path[
                                                        this.state.photoIndex
                                                        ] &&
                                                        this.state.image_payslip_path[
                                                          this.state.photoIndex
                                                        ].fileurl+'?t='+new Date()
                                                      }
                                                      nextSrc={
                                                        this.state.image_payslip_path[
                                                        (this.state.photoIndex + 1) %
                                                        this.state
                                                          .image_payslip_path.length
                                                        ]
                                                      }
                                                      prevSrc={
                                                        this.state.image_payslip_path[
                                                        (this.state.photoIndex +
                                                          this.state
                                                            .image_payslip_path
                                                            .length -
                                                          1) %
                                                        this.state
                                                          .image_payslip_path.length
                                                        ]
                                                      }
                                                      onCloseRequest={() =>
                                                        this.setState({
                                                          isOpen: false,
                                                          photoIndex: this.state
                                                            .clone_photoIndex
                                                        })
                                                      }
                                                      onMovePrevRequest={() =>
                                                        this.setState({
                                                          photoIndex:
                                                            (this.state.photoIndex +
                                                              this.state
                                                                .image_payslip_path
                                                                .length -
                                                              1) %
                                                            this.state
                                                              .image_payslip_path.length
                                                        })
                                                      }
                                                      onMoveNextRequest={() =>
                                                        this.setState({
                                                          photoIndex:
                                                            (this.state.photoIndex +
                                                              1) %
                                                            this.state
                                                              .image_payslip_path.length
                                                        })
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                              <div className="col-sm-6 col-md-3">
                                                <div className="col-sm-6 col-md-6 timecard_group_170 text-center">
                                                  <div className="clear10"> </div>
                                                  <a
                                                    href="javascript:void(0);"
                                                    className="cursorPointer"
                                                    onClick={this.leftkey}
                                                  >
                                                    {" "}
                                                    <svg
                                                      width="24"
                                                      height="24"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fillRule="evenodd"
                                                      clipRule="evenodd"
                                                    >
                                                      <path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" />
                                                    </svg>
                                                  </a>
                                                  <a
                                                    href="javascript:void(0);"
                                                    className="cursorPointer"
                                                    onClick={this.rightkey}
                                                    style={{ marginLeft: 9 }}
                                                  >
                                                    <svg
                                                      width="24"
                                                      height="24"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fillRule="evenodd"
                                                      clipRule="evenodd"
                                                    >
                                                      <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                                                    </svg>
                                                  </a>
                                                </div>

                                                <div className="col-sm-6 col-md-6 text-center">
                                                  <div className="clear5"> </div>
                                                  <a
                                                    href="javascript:void(0);"
                                                    className="cursorPointer"
                                                    data-toggle="modal"
                                                    data-target="#timecard_sidee_modal"
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="5.223"
                                                      height="20.893"
                                                      viewBox="0 0 5.223 20.893"
                                                    >
                                                      <path
                                                        id="ic_more_horiz_24px"
                                                        d="M6.612,10a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,6.612,10Zm15.669,0a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,22.281,10Zm-7.835,0a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,14.446,10Z"
                                                        transform="translate(15.223 -4) rotate(90)"
                                                        fill="#30679a"
                                                      />
                                                    </svg>
                                                  </a>
                                                </div>
                                              </div>
                                              <div className="clearfix"> </div>
                                            </div>
                                          </React.Fragment>
                                        ) : (
                                            ""
                                          )}
                                      </div>


                                      <div className="col-sm-8 sidebar_main_right timecard_8_colum">
                                        <div className="col-sm-12 ipad_padd_zero">
                                          <div className="slider_pdf_viewer custom_slide_height">
                                            <div className="clear50 clear50_none">
                                              {" "}
                                            </div>
                                            {this.state.empDetail ? (
                                              this.state.image_payslip_path.length >
                                                0 &&(this.state.image_payslip_path[0].fileurl!==undefined&&this.state.image_payslip_path[0].fileurl!==null) ? (
                                                  <React.Fragment>
                                                    <div
                                                      id="myCarousel_timecard_1"
                                                      className="carousel slide"
                                                      data-interval="false"
                                                    >
                                                      <ol className="carousel-indicators timecard_indcators">
                                                        <li
                                                          data-target="#myCarousel_timecard_1"
                                                          data-slide-to="0"
                                                          className="active"
                                                        ></li>
                                                        <li
                                                          data-target="#myCarousel_timecard_1"
                                                          data-slide-to="1"
                                                        ></li>
                                                      </ol>
                                                      <div
                                                        className="carousel-inner"
                                                        style={{
                                                          width: this.state.isMobView
                                                            ? ""
                                                            : imageStyle.width + "px",
                                                          height:
                                                            imageStyle.height + "px",
                                                          minWidth: this.state.isMobView
                                                            ? ""
                                                            : 560,
                                                          minHeight: 340,
                                                          maxWidth: 1250,
                                                          maxHeight: 800,
                                                          align: "center ",
                                                          margin: "0 auto "
                                                        }}
                                                      >
                                                        <div
                                                          id={0}
                                                          className="approve_active item active"
                                                        >
                                                          <p>Payslip</p>
                                                          <img
                                                            src={
                                                              this.state
                                                                .image_payslip_path &&
                                                              this.state
                                                                .image_payslip_path
                                                                .length &&
                                                              this.state
                                                                .image_payslip_path[0]
                                                                .fileurl
                                                            }
                                                            alt="payslip"
                                                            className="timecards_carousel_img"
                                                            onLoad={this.loadimage}
                                                            style={{
                                                              height:
                                                                imageStyle.height + "px"
                                                            }}
                                                          />
                                                        </div>

                                                        {this.state.image_payslip_path &&
                                                          this.state.image_payslip_path
                                                            .length > 1 &&(this.state.image_payslip_path[1].fileurl!==undefined&&this.state.image_payslip_path[1].fileurl!==null) &&
                                                          this.state.image_payslip_path
                                                            .slice(1)
                                                            .map((p, i) => {
                                                              return (
                                                                <div
                                                                  id={i + 1}
                                                                  className="item"
                                                                >
                                                                  <p>Timesheet </p>
                                                                  <img
                                                                    src={p.fileurl+'?t='+new Date()}
                                                                    alt="payslip"
                                                                    className="timecards_carousel_img"
                                                                    onLoad={
                                                                      this.loadimage
                                                                    }
                                                                    style={{
                                                                      height:
                                                                        imageStyle.height +
                                                                        "px"
                                                                    }}
                                                                  />
                                                                </div>
                                                              );
                                                            })}
                                                      </div>
                                                      {this.state.image_payslip_path
                                                        .length > 1 && (this.state.image_payslip_path[1].fileurl!==undefined&&this.state.image_payslip_path[1].fileurl!==null) &&(
                                                          <>
                                                            <a
                                                              className="left carousel-control "
                                                              href="#myCarousel_timecard_1"
                                                              data-slide="prev"
                                                              onClick={this.getActiveIndex}
                                                            >
                                                              <span className="glyphicon glyphicon-chevron-left">
                                                                {" "}
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  width="13"
                                                                  height="21.053"
                                                                  viewBox="0 0 13 21.053"
                                                                >
                                                                  <path
                                                                    id="ic_chevron_right_24px"
                                                                    d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                                                                    transform="translate(21.59 27.053) rotate(180)"
                                                                    fill="#989898"
                                                                  />
                                                                </svg>
                                                              </span>
                                                            </a>
                                                            <a
                                                              className="right carousel-control"
                                                              href="#myCarousel_timecard_1"
                                                              data-slide="next"
                                                              onClick={this.getActiveIndex}
                                                            >
                                                              <span className="glyphicon glyphicon-chevron-right">
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  width="13"
                                                                  height="21.053"
                                                                  viewBox="0 0 13 21.053"
                                                                >
                                                                  <path
                                                                    id="ic_chevron_right_24px"
                                                                    d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                                                                    transform="translate(-8.59 -6)"
                                                                    fill="#989898"
                                                                  />
                                                                </svg>
                                                              </span>
                                                            </a>
                                                          </>
                                                        )}
                                                    </div>
                                                  </React.Fragment>
                                                ) : (
                                                  <div style={{ textAlign: "center" }}>
                                                    <h4>Payslip Not Found</h4>
                                                  </div>
                                                )
                                            ) : (
                                                <div style={{ textAlign: "center" }}>
                                                  <h4>No Time Card is Selected</h4>
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="tab-pane" id="tab_pending">
                                  <div id="approve_box">
                                    <div className="col-sm-3 sidebar_main sidebar_kevin_pp">
                                      <div className="clear20"></div>
                                      <div className="grid_layout">
                                        <div className="grid_layout_inner">
                                          {/* <button onClick={this.recreatetimesheet}>ffff</button> */}
                                          <select
                                            name="pending_batch_number"
                                            value={this.state.pending_batch_number}
                                            onChange={this.getBatches}
                                            className="form_detail_select grid_layout_select_ie"
                                          >
                                            <option value="all">Batch#</option>
                                            {this.state.batches_array
                                              .sort((a, b) => {
                                                return a - b;
                                              })
                                              .map(item => (
                                                <option value={item}>{item}</option>
                                              ))}
                                          </select>
                                        </div>
                                        <div className="grid_layout_inner grid_layout_search_ie">
                                          <div className="form searchInput_form">
                                            <div className="form-group">
                                              <button
                                                type="button"
                                                className="search_button search_btn_z_indexx"
                                                value=""
                                              >
                                                <img
                                                  alt="timecardpic"
                                                  src={magnifying_glass}
                                                  width="20"
                                                  height="20"
                                                />
                                              </button>
                                              <input
                                                type="text"
                                                id="timeCards_search"
                                                name="timeCards_search"
                                                value={this.state.timeCards_search}
                                                onChange={this.timeCards_searchHandler}
                                                className="form-control search_input search_opacity_1"
                                                placeholder="What are you looking for"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="clearfix"></div>
                                      {this.state.timeCards_searched_data.length > 0
                                        ? this.state.timeCards_searched_data.map(
                                          (item, i) => (
                                            <div key={i}>
                                              {item.Employee.length > 0 ? (
                                                <div
                                                  id={item.Guid + "pending"}
                                                  className="grid_layout_content approve_labell grid_layout_top_mrgg"
                                                  onClick={() =>
                                                    this.get_emp_details(
                                                      item.Guid,
                                                      "pending"
                                                    )
                                                  }
                                                >
                                                  <div className="grid_content">
                                                    <label>
                                                      {item.Employee[0].FirstName}{" "}
                                                      {item.Employee[0].LastName}
                                                    </label>
                                                    <div>
                                                      {/* <input type="checkbox" name={item.CheckBoxID} onChange={(e) => this.checkedTimeCard(e, item)} / > */}
                                                      <label htmlFor="checkbox1"></label>
                                                    </div>

                                                    <p>
                                                      {item.Employee[0].Department}
                                                    </p>
                                                    <p style={{ fontSize: "14px" }}>
                                                      {item.Employee[0].Position}
                                                    </p>
                                                    <p>{'"' + JSON.parse(localStorage.getItem('completetenant')).CompanyName + '"'}</p>
                                                  </div>
                                                  <div className="grid_content">
                                                    <h3> &nbsp; &nbsp; &nbsp;</h3>

                                                    <p>
                                                      {new Date(
                                                        item.PeriodEndingDate
                                                      ).toLocaleDateString()}
                                                    </p>
                                                    <p>
                                                      {item.PRLTimes
                                                        ? item.PRLTimes.TotalHours
                                                        : ""}
                                                    </p>
                                                    <a
                                                      href="javascript:void(0);"
                                                      className="cursorPointer"
                                                      data-toggle="modal"
                                                      data-target="#myModal_more_detail"
                                                      onClick={() =>
                                                        this.onMoreDetails()
                                                      }
                                                    >
                                                      More Details
                                              </a>
                                                  </div>
                                                  <br />
                                                </div>
                                              ) : null}
                                            </div>
                                          )
                                        )
                                        : "No TimeCards Found!"}
                                    </div>
                                    <div className="col-sm-1 p0 toggle_button_div togle_height_mdd slide-toggle">
                                      <a
                                        href="javascript:void(0);"
                                        className="cursorPointer"
                                      >
                                        <i className="fa fa-caret-left"></i>
                                      </a>
                                    </div>
                                    <div className="tab_content_data">
                                      <div className="col-sm-8 col-md-8 p0 timecard_8_colum ">
                                        {this.state.empDetail ? (
                                          <React.Fragment>
                                            <div className="approve_nav_right">
                                              <div className="col-sm-3 col-md-3"></div>
                                              <div className="col-sm-6 col-md-6">
                                                <div className="timecard_navbar_icon">
                                                  {this.state.image_payslip_path
                                                    .length > 0 ? (
                                                      <div className="timecard_plus_minus_icon">
                                                        <div className="clear5"> </div>
                                                        <a
                                                          href="javascript:void(0);"
                                                          className="cursorPointer"
                                                          onClick={this.zoomout}
                                                        >
                                                          <img
                                                            alt="timecardpic"
                                                            src={minus_3}
                                                          />
                                                        </a>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  {this.state.image_payslip_path
                                                    .length > 0 ? (
                                                      <div className="timecard_plus_svg">
                                                        <div className="clear10"> </div>
                                                        <a
                                                          href="javascript:void(0);"
                                                          className="cursorPointer"
                                                          onClick={this.zoomin}
                                                        >
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="15"
                                                            height="15"
                                                            viewBox="0 0 15 15"
                                                          >
                                                            <path
                                                              id="ic_add_24px"
                                                              d="M20,13.571H13.571V20H11.429V13.571H5V11.429h6.429V5h2.143v6.429H20Z"
                                                              transform="translate(-5 -5)"
                                                              fill="#3c6f9f"
                                                            />
                                                          </svg>
                                                        </a>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  {this.state.image_payslip_path
                                                    .length > 0 ? (
                                                      <div className="timecard_75_text">
                                                        <select
                                                          name=""
                                                          className={"margin_0px"}
                                                          onChange={this.changeImgSize}
                                                        >
                                                          <option value="25">25%</option>
                                                          <option value="50">50%</option>
                                                          <option value="75">75%</option>
                                                          <option value="100">
                                                            100%
                                                </option>
                                                        </select>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  {/* <div className="timecard_cross_all_img">
                                          <div className="clear10"> </div>
                                          <a
                                            href="javascript:void(0);"
                                            className="cursorPointer"
                                          >
                                            <img
                                              alt="timecardpic"
                                              src={cross_all_3}
                                            />
                                          </a>
                                        </div> */}
                                                  {this.state.image_payslip_path
                                                    .length > 0 ? (
                                                      <div className="timecard_arow_left_right_img">
                                                        <a
                                                          href="javascript:void(0);"
                                                          className="cursorPointer"
                                                        >
                                                          {" "}
                                                          <img
                                                            alt="timecardpic"
                                                            src={img_left_right_icon}
                                                            onClick={this.handleImageSize}
                                                          />
                                                        </a>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                </div>
                                              </div>
                                              <div className="col-sm-3">
                                                <div className="col-sm-6 timecard_group_170 text-center">
                                                  <div className="clear10"> </div>
                                                  <a
                                                    href="javascript:void(0);"
                                                    className="cursorPointer"
                                                    onClick={this.leftkey}
                                                  >
                                                    {" "}
                                                    <svg
                                                      width="24"
                                                      height="24"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fillRule="evenodd"
                                                      clipRule="evenodd"
                                                    >
                                                      <path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" />
                                                    </svg>
                                                  </a>
                                                  <a
                                                    href="javascript:void(0);"
                                                    className="cursorPointer"
                                                    onClick={this.rightkey}
                                                    style={{ marginLeft: 9 }}
                                                  >
                                                    <svg
                                                      width="24"
                                                      height="24"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fillRule="evenodd"
                                                      clipRule="evenodd"
                                                    >
                                                      <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                                                    </svg>
                                                  </a>
                                                </div>
                                                <div className="col-sm-6 text-center">
                                                  <div className="clear5"> </div>
                                                  <a
                                                    href="javascript:void(0);"
                                                    className="cursorPointer"
                                                    data-toggle="modal"
                                                    data-target="#timecard_sidee_modal"
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="5.223"
                                                      height="20.893"
                                                      viewBox="0 0 5.223 20.893"
                                                    >
                                                      <path
                                                        id="ic_more_horiz_24px"
                                                        d="M6.612,10a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,6.612,10Zm15.669,0a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,22.281,10Zm-7.835,0a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,14.446,10Z"
                                                        transform="translate(15.223 -4) rotate(90)"
                                                        fill="#30679a"
                                                      />
                                                    </svg>
                                                  </a>
                                                </div>
                                              </div>
                                              <div className="clearfix"> </div>
                                            </div>
                                          </React.Fragment>
                                        ) : (
                                            ""
                                          )}
                                      </div>

                                      <div className="col-sm-8 sidebar_main_right timecard_8_colum">
                                        <div className="col-sm-12 ipad_padd_zero">
                                          <div className="slider_pdf_viewer custom_slide_height">
                                            <div className="clear50 clear50_none">
                                              {" "}
                                            </div>
                                            {this.state.empDetail ? (
                                              this.state.image_payslip_path.length >
                                                0 &&(this.state.image_payslip_path[0].fileurl!==undefined&&this.state.image_payslip_path[0].fileurl!==null) ? (
                                                  <React.Fragment>
                                                    <div
                                                      id="myCarousel_timecard_2"
                                                      className="carousel slide"
                                                      data-interval="false"
                                                    >
                                                      <ol className="carousel-indicators timecard_indcators">
                                                        <li
                                                          data-target="#myCarousel_timecard_2"
                                                          data-slide-to="0"
                                                          className="active"
                                                        ></li>
                                                        <li
                                                          data-target="#myCarousel_timecard_2"
                                                          data-slide-to="1"
                                                        ></li>
                                                      </ol>
                                                      <div
                                                        className="carousel-inner"
                                                        style={{
                                                          width: this.state.isMobView
                                                            ? ""
                                                            : imageStyle.width + "px",
                                                          height:
                                                            imageStyle.height + "px",
                                                          minWidth: this.state.isMobView
                                                            ? ""
                                                            : 560,
                                                          minHeight: 340,
                                                          maxWidth: 1250,
                                                          maxHeight: 800,
                                                          align: "center ",
                                                          margin: "0 auto "
                                                        }}
                                                      >
                                                        <div
                                                          id={0}
                                                          className="pending_active item active"
                                                        >
                                                          <p>Payslip </p>
                                                          <img
                                                            src={
                                                              this.state
                                                                .image_payslip_path &&
                                                              this.state
                                                                .image_payslip_path
                                                                .length &&
                                                              this.state
                                                                .image_payslip_path[0]
                                                                .fileurl
                                                            }
                                                            alt="payslip"
                                                            className="timecards_carousel_img"
                                                            onLoad={this.loadimage}
                                                            style={{
                                                              height:
                                                                imageStyle.height + "px"
                                                            }}
                                                          />
                                                        </div>

                                                        {this.state.image_payslip_path &&
                                                          this.state.image_payslip_path
                                                            .length > 1 &&(this.state.image_payslip_path[1].fileurl!==undefined&&this.state.image_payslip_path[1].fileurl!==null) &&
                                                          this.state.image_payslip_path
                                                            .slice(1)
                                                            .map((p, i) => {
                                                              return (
                                                                <div
                                                                  id={i + 1}
                                                                  className="item"
                                                                >
                                                                  <p>Timesheet </p>
                                                                  <img
                                                                    src={p.fileurl+'?t='+new Date()}
                                                                    alt="payslip"
                                                                    className="timecards_carousel_img"
                                                                    onLoad={
                                                                      this.loadimage
                                                                    }
                                                                    style={{
                                                                      height:
                                                                        imageStyle.height +
                                                                        "px"
                                                                    }}
                                                                  />
                                                                </div>
                                                              );
                                                            })}
                                                      </div>
                                                      {this.state.image_payslip_path
                                                        .length > 1 &&(this.state.image_payslip_path[1].fileurl!==undefined&&this.state.image_payslip_path[1].fileurl!==null) && (
                                                          <>
                                                            <a
                                                              className="left carousel-control "
                                                              href="#myCarousel_timecard_2"
                                                              data-slide="prev"
                                                              onClick={this.getActiveIndex}
                                                            >
                                                              <span className="glyphicon glyphicon-chevron-left">
                                                                {" "}
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  width="13"
                                                                  height="21.053"
                                                                  viewBox="0 0 13 21.053"
                                                                >
                                                                  <path
                                                                    id="ic_chevron_right_24px"
                                                                    d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                                                                    transform="translate(21.59 27.053) rotate(180)"
                                                                    fill="#989898"
                                                                  />
                                                                </svg>
                                                              </span>
                                                            </a>
                                                            <a
                                                              className="right carousel-control"
                                                              href="#myCarousel_timecard_2"
                                                              data-slide="next"
                                                              onClick={this.getActiveIndex}
                                                            >
                                                              <span className="glyphicon glyphicon-chevron-right">
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  width="13"
                                                                  height="21.053"
                                                                  viewBox="0 0 13 21.053"
                                                                >
                                                                  <path
                                                                    id="ic_chevron_right_24px"
                                                                    d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                                                                    transform="translate(-8.59 -6)"
                                                                    fill="#989898"
                                                                  />
                                                                </svg>
                                                              </span>
                                                            </a>
                                                          </>
                                                        )}
                                                    </div>
                                                  </React.Fragment>
                                                ) : (
                                                  <div style={{ textAlign: "center" }}>
                                                    <h4>Payslip Not Found</h4>
                                                  </div>
                                                )
                                            ) : (
                                                <div style={{ textAlign: "center" }}>
                                                  <h4>No Time Card is Selected</h4>
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="tab-pane" id="tab_approved">
                                  <div id="approve_box">
                                    <div className="col-sm-3 sidebar_main sidebar_kevin_pp">
                                      <div className="clear20"></div>
                                      <div className="grid_layout">
                                        <div className="grid_layout_inner">
                                    
                                          <select
                                            name="approved_batch_number"
                                            value={this.state.approved_batch_number}
                                            onChange={this.getBatches}
                                            className="form_detail_select grid_layout_select_ie"
                                          >
                                            <option value="all">Batch#</option>
                                            {this.state.batches_array
                                              .sort((a, b) => {
                                                return a - b;
                                              })
                                              .map(item => (
                                                <option value={item}>{item}</option>
                                              ))}
                                          </select>
                                        </div>
                                        <div className="grid_layout_inner grid_layout_search_ie">
                                          <div className="form searchInput_form">
                                            <div className="form-group">
                                              <button
                                                type="button"
                                                className="search_button search_btn_z_indexx"
                                                value=""
                                              >
                                                <img
                                                  alt="timecardpic"
                                                  src={magnifying_glass}
                                                  width="20"
                                                  height="20"
                                                />
                                              </button>
                                              <input
                                                type="text"
                                                id="timeCards_search"
                                                name="timeCards_search"
                                                value={this.state.timeCards_search}
                                                onChange={this.timeCards_searchHandler}
                                                className="form-control search_input search_opacity_1"
                                                placeholder="What are you looking for"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="clearfix"></div>
                                      {this.state.timeCards_searched_data.length > 0
                                        ? this.state.timeCards_searched_data.map(
                                          (item, i) => (
                                            <div key={i}>
                                              {item.Employee.length > 0 ? (
                                                <div
                                                  id={item.Guid + "approved"}
                                                  className="grid_layout_content approve_labell grid_layout_top_mrgg"
                                                  onClick={() =>
                                                    this.get_emp_details(
                                                      item.Guid,
                                                      "approved"
                                                    )
                                                  }
                                                >
                                                  <div className="grid_content">
                                                    <label>
                                                      {item.Employee[0].FirstName}{" "}
                                                      {item.Employee[0].LastName}
                                                    </label>
                                                    <div>
                                                      {/* <input type="checkbox" name={item.CheckBoxID} onChange={(e) => this.checkedTimeCard(e, item)} / > */}
                                                      <label htmlFor="checkbox1"></label>
                                                    </div>

                                                    <p>
                                                      {item.Employee[0].Department}
                                                    </p>
                                                    <p style={{ fontSize: "14px" }}>
                                                      {item.Employee[0].Position}
                                                    </p>
                                                    <p>{'"' + JSON.parse(localStorage.getItem('completetenant')).CompanyName + '"'}</p>
                                                  </div>
                                                  <div className="grid_content">
                                                    <h3> &nbsp; &nbsp; &nbsp;</h3>

                                                    <p>
                                                      {new Date(
                                                        item.PeriodEndingDate
                                                      ).toLocaleDateString()}
                                                    </p>
                                                    <p>
                                                      {item.PRLTimes
                                                        ? item.PRLTimes.TotalHours
                                                        : ""}
                                                    </p>
                                                    <a
                                                      href="javascript:void(0);"
                                                      className="cursorPointer"
                                                      data-toggle="modal"
                                                      data-target="#myModal_more_detail"
                                                      onClick={() =>
                                                        this.onMoreDetails()
                                                      }
                                                    >
                                                      More Details
                                              </a>
                                                  </div>
                                                  <br />
                                                </div>
                                              ) : null}
                                            </div>
                                          )
                                        )
                                        : "No Timecards Found!"}
                                    </div>

                                    <div className="col-sm-1 p0 toggle_button_div togle_height_mdd slide-toggle">
                                      <a
                                        href="javascript:void(0);"
                                        className="cursorPointer"
                                      >
                                        <i className="fa fa-caret-left"></i>
                                      </a>
                                    </div>
                                    <div className="tab_content_data">
                                      <div className="col-sm-8 col-md-8 p0 timecard_8_colum ">
                                        {this.state.empDetail ? (
                                          <React.Fragment>
                                            <div className="approve_nav_right">
                                              <div className="col-sm-3 col-md-3">
                                                <div className="timecard_modify_left">
                                                  {this.state.empDetail &&
                                                    this.state.empDetail.senderid ==
                                                    localStorage.getItem("userGuid") ? (
                                                      <a
                                                        href="javascript:void(0);"
                                                        data-toggle="modal"
                                                        data-target="#timecard_modify_popup"
                                                        className="cursorPointer"
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="18.207"
                                                          height="12.901"
                                                          viewBox="0 0 18.207 12.901"
                                                        >
                                                          <g
                                                            id="Group_1707"
                                                            data-name="Group 1707"
                                                            transform="translate(-591 -112)"
                                                          >
                                                            <path
                                                              id="Path_301"
                                                              data-name="Path 301"
                                                              d="M-19762.445,5455.1s4.727,5.329-2.537,7.523a6.628,6.628,0,0,0,8.025-4.135h0a6.417,6.417,0,0,0-2.539-6.626l.047-.045,1.965-1.861h-7.287v7.313Z"
                                                              transform="translate(20366 -5337.954)"
                                                              fill="#30679a"
                                                            />
                                                            <rect
                                                              id="Rectangle_3950"
                                                              data-name="Rectangle 3950"
                                                              width="8"
                                                              height="2"
                                                              transform="translate(591 112)"
                                                              fill="#30679a"
                                                            />
                                                            <rect
                                                              id="Rectangle_3951"
                                                              data-name="Rectangle 3951"
                                                              width="8"
                                                              height="2"
                                                              transform="translate(591 117)"
                                                              fill="#30679a"
                                                            />
                                                            <rect
                                                              id="Rectangle_3952"
                                                              data-name="Rectangle 3952"
                                                              width="8"
                                                              height="2"
                                                              transform="translate(591 122)"
                                                              fill="#30679a"
                                                            />
                                                          </g>
                                                        </svg>{" "}
                                                        <span> Modify </span>{" "}
                                                      </a>
                                                    ) : (
                                                      ""
                                                    )}
                                                </div>
                                              </div>
                                              <div className="col-sm-6 col-md-6">
                                                <div className="timecard_navbar_icon">
                                                  {this.state.image_payslip_path
                                                    .length > 0 ? (
                                                      <div className="timecard_plus_minus_icon">
                                                        <div className="clear5"> </div>
                                                        <a
                                                          href="javascript:void(0);"
                                                          className="cursorPointer"
                                                          onClick={this.zoomout}
                                                        >
                                                          <img
                                                            alt="timecardpic"
                                                            src={minus_3}
                                                          />
                                                        </a>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  {this.state.image_payslip_path
                                                    .length > 0 ? (
                                                      <div className="timecard_plus_svg">
                                                        <div className="clear10"> </div>
                                                        <a
                                                          href="javascript:void(0);"
                                                          className="cursorPointer"
                                                          onClick={this.zoomin}
                                                        >
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="15"
                                                            height="15"
                                                            viewBox="0 0 15 15"
                                                          >
                                                            <path
                                                              id="ic_add_24px"
                                                              d="M20,13.571H13.571V20H11.429V13.571H5V11.429h6.429V5h2.143v6.429H20Z"
                                                              transform="translate(-5 -5)"
                                                              fill="#3c6f9f"
                                                            />
                                                          </svg>
                                                        </a>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  {this.state.image_payslip_path
                                                    .length > 0 ? (
                                                      <div className="timecard_75_text">
                                                        <select
                                                          name=""
                                                          className={"margin_0px"}
                                                          onChange={this.changeImgSize}
                                                        >
                                                          <option value="25">25%</option>
                                                          <option value="50">50%</option>
                                                          <option value="75">75%</option>
                                                          <option value="100">
                                                            100%
                                                </option>
                                                        </select>
                                                        
                                                
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  {/* <div className="timecard_cross_all_img">
                                          <div className="clear10"> </div>
                                          <a
                                            href="javascript:void(0);"
                                            className="cursorPointer"
                                          >
                                            <img
                                              alt="timecardpic"
                                              src={cross_all_3}
                                            />
                                          </a>
                                        </div> */}
                                                  {this.state.image_payslip_path
                                                    .length > 0 ? (
                                                      <div className="timecard_arow_left_right_img">
                                                        <a
                                                          href="javascript:void(0);"
                                                          className="cursorPointer"
                                                        >
                                                          {" "}
                                                          <img
                                                            alt="timecardpic"
                                                            src={img_left_right_icon}
                                                            onClick={this.handleImageSize}
                                                          />
                                                        </a>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                </div>
                                              </div>
                                              <div className="col-sm-3">
                                                <div className="col-sm-6 timecard_group_170 text-center">
                                                  <div className="clear10"> </div>
                                                  <a
                                                    href="javascript:void(0);"
                                                    className="cursorPointer"
                                                    onClick={this.leftkey}
                                                  >
                                                    {" "}
                                                    <svg
                                                      width="24"
                                                      height="24"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fillRule="evenodd"
                                                      clipRule="evenodd"
                                                    >
                                                      <path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" />
                                                    </svg>
                                                  </a>
                                                  <a
                                                    href="javascript:void(0);"
                                                    className="cursorPointer"
                                                    onClick={this.rightkey}
                                                    style={{ marginLeft: 9 }}
                                                  >
                                                    <svg
                                                      width="24"
                                                      height="24"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fillRule="evenodd"
                                                      clipRule="evenodd"
                                                    >
                                                      <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                                                    </svg>
                                                  </a>
                                                </div>
                                                <div className="col-sm-6 text-center">
                                                  <div className="clear5"> </div>
                                                  <a
                                                    href="javascript:void(0);"
                                                    className="cursorPointer"
                                                    data-toggle="modal"
                                                    data-target="#timecard_sidee_modal"
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="5.223"
                                                      height="20.893"
                                                      viewBox="0 0 5.223 20.893"
                                                    >
                                                      <path
                                                        id="ic_more_horiz_24px"
                                                        d="M6.612,10a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,6.612,10Zm15.669,0a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,22.281,10Zm-7.835,0a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,14.446,10Z"
                                                        transform="translate(15.223 -4) rotate(90)"
                                                        fill="#30679a"
                                                      />
                                                    </svg>
                                                  </a>
                                                </div>
                                              </div>
                                              <div className="clearfix"> </div>
                                            </div>
                                          </React.Fragment>
                                        ) : (
                                            ""
                                          )}
                                      </div>

                                      <div className="col-sm-8 sidebar_main_right timecard_8_colum">
                                        <div className="col-sm-12 ipad_padd_zero">
                                          <div className="slider_pdf_viewer custom_slide_height">
                                            <div className="clear50 clear50_none">
                                              {" "}
                                            </div>
                                            {this.state.empDetail ? (
                                              this.state.image_payslip_path.length >
                                                0 &&(this.state.image_payslip_path[0].fileurl!==undefined&&this.state.image_payslip_path[0].fileurl!==null) ? (
                                                  <React.Fragment>
                                                    <div
                                                      id="myCarousel_timecard_3"
                                                      className="carousel slide"
                                                      data-interval="false"
                                                    >
                                                      <ol className="carousel-indicators timecard_indcators">
                                                        <li
                                                          data-target="#myCarousel_timecard_3"
                                                          data-slide-to="0"
                                                          className="active"
                                                        ></li>
                                                        <li
                                                          data-target="#myCarousel_timecard_3"
                                                          data-slide-to="1"
                                                        ></li>
                                                      </ol>
                                                      <div
                                                        className="carousel-inner"
                                                        style={{
                                                          width: this.state.isMobView
                                                            ? ""
                                                            : imageStyle.width + "px",
                                                          height:
                                                            imageStyle.height + "px",
                                                          minWidth: this.state.isMobView
                                                            ? ""
                                                            : 560,
                                                          minHeight: 340,
                                                          maxWidth: 1250,
                                                          maxHeight: 800,
                                                          align: "center ",
                                                          margin: "0 auto "
                                                        }}
                                                      >
                                                        <div
                                                          id={0}
                                                          className="approved_active item active"
                                                        >
                                                          <p>Payslip </p>
                                                          <img
                                                            src={
                                                              this.state
                                                                .image_payslip_path &&
                                                              this.state
                                                                .image_payslip_path
                                                                .length &&
                                                              this.state
                                                                .image_payslip_path[0]
                                                                .fileurl
                                                            }
                                                            alt="payslip"
                                                            className="timecards_carousel_img"
                                                            onLoad={this.loadimage}
                                                            style={{
                                                              height:
                                                                imageStyle.height + "px"
                                                            }}
                                                          />
                                                        </div>

                                                        {this.state.image_payslip_path &&
                                                          this.state.image_payslip_path
                                                            .length > 1 &&(this.state.image_payslip_path[1].fileurl!==undefined&&this.state.image_payslip_path[1].fileurl!==null) &&
                                                          this.state.image_payslip_path
                                                            .slice(1)
                                                            .map((p, i) => {
                                                              return (
                                                                <div
                                                                  id={i + 1}
                                                                  className="item"
                                                                >
                                                                  <p>Timesheet </p>
                                                                  <img
                                                                    src={p.fileurl+'?t='+new Date()}
                                                                    alt="payslip"
                                                                    className="timecards_carousel_img"
                                                                    onLoad={
                                                                      this.loadimage
                                                                    }
                                                                    style={{
                                                                      height:
                                                                        imageStyle.height +
                                                                        "px"
                                                                    }}
                                                                  />
                                                                </div>
                                                              );
                                                            })}
                                                      </div>
                                                      {this.state.image_payslip_path
                                                        .length > 1 &&(this.state.image_payslip_path[1].fileurl!==undefined&&this.state.image_payslip_path[1].fileurl!==null)&& (
                                                          <>
                                                            <a
                                                              className="left carousel-control "
                                                              href="#myCarousel_timecard_3"
                                                              data-slide="prev"
                                                              onClick={this.getActiveIndex}
                                                            >
                                                              <span className="glyphicon glyphicon-chevron-left">
                                                                {" "}
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  width="13"
                                                                  height="21.053"
                                                                  viewBox="0 0 13 21.053"
                                                                >
                                                                  <path
                                                                    id="ic_chevron_right_24px"
                                                                    d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                                                                    transform="translate(21.59 27.053) rotate(180)"
                                                                    fill="#989898"
                                                                  />
                                                                </svg>
                                                              </span>
                                                            </a>
                                                            <a
                                                              className="right carousel-control"
                                                              href="#myCarousel_timecard_3"
                                                              data-slide="next"
                                                              onClick={this.getActiveIndex}
                                                            >
                                                              <span className="glyphicon glyphicon-chevron-right">
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  width="13"
                                                                  height="21.053"
                                                                  viewBox="0 0 13 21.053"
                                                                >
                                                                  <path
                                                                    id="ic_chevron_right_24px"
                                                                    d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                                                                    transform="translate(-8.59 -6)"
                                                                    fill="#989898"
                                                                  />
                                                                </svg>
                                                              </span>
                                                            </a>
                                                          </>
                                                        )}
                                                    </div>
                                                  </React.Fragment>
                                                ) : (
                                                  <div style={{ textAlign: "center" }}>
                                                    <h4>Payslip Not Found</h4>
                                                  </div>
                                                )
                                            ) : (
                                                <div style={{ textAlign: "center" }}>
                                                  <h4>No Time Card is Selected</h4>
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="tab-pane" id="tab_all">
                                  <div id="approve_box">
                                    <div className="col-sm-3 sidebar_main sidebar_kevin_pp">
                                      <div className="clear20"></div>
                                      <div className="grid_layout">
                                        <div className="grid_layout_inner">
                                          <select
                                            name="all_batch_number"
                                            value={this.state.all_batch_number}
                                            onChange={this.getBatches}
                                            className="form_detail_select grid_layout_select_ie"
                                          >
                                            <option value="all">Batch#</option>
                                            {this.state.batches_array
                                              .sort((a, b) => {
                                                return a - b;
                                              })
                                              .map(item => (
                                                <option value={item}>{item}</option>
                                              ))}
                                          </select>
                                        </div>
                                        <div className="grid_layout_inner grid_layout_search_ie">
                                          <div className="form searchInput_form">
                                            <div className="form-group">
                                              <button
                                                type="button"
                                                className="search_button search_btn_z_indexx"
                                                value=""
                                              >
                                                <img
                                                  alt="timecardpic"
                                                  src={magnifying_glass}
                                                  width="20"
                                                  height="20"
                                                />
                                              </button>
                                              <input
                                                type="text"
                                                id="timeCards_search"
                                                name="timeCards_search"
                                                value={this.state.timeCards_search}
                                                onChange={this.timeCards_searchHandler}
                                                className="form-control search_input search_opacity_1"
                                                placeholder="What are you looking for"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="clearfix"></div>
                                      {this.state.timeCards_searched_data.length > 0
                                        ? this.state.timeCards_searched_data.map(
                                          (item, i) => (
                                            <div key={i}>
                                              {item.Employee.length > 0 ? (
                                                <div
                                                  id={item.Guid + "all"}
                                                  className="grid_layout_content approve_labell grid_layout_top_mrgg"
                                                  onClick={() =>
                                                    this.get_emp_details(
                                                      item.Guid,
                                                      "all"
                                                    )
                                                  }
                                                >
                                                  <div className="grid_content">
                                                    <label>
                                                      {item.Employee[0].FirstName}{" "}
                                                      {item.Employee[0].LastName}
                                                    </label>
                                                    <div>
                                                      {/* <input type="checkbox" name={item.CheckBoxID} onChange={(e) => this.checkedTimeCard(e, item)} / > */}
                                                      <label htmlFor="checkbox1"></label>
                                                    </div>

                                                    <p>
                                                      {item.Employee[0].Department}
                                                    </p>
                                                    <p style={{ fontSize: "14px" }}>
                                                      {item.Employee[0].Position}
                                                    </p>
                                                    <p>{'"' + JSON.parse(localStorage.getItem('completetenant')).CompanyName + '"'}</p>
                                                  </div>
                                                  <div className="grid_content">
                                                    <h3> &nbsp; &nbsp; &nbsp;</h3>

                                                    <p>
                                                      {new Date(
                                                        item.PeriodEndingDate
                                                      ).toLocaleDateString()}
                                                    </p>
                                                    <p>
                                                      {item.PRLTimes
                                                        ? item.PRLTimes.TotalHours
                                                        : ""}
                                                    </p>
                                                    <a
                                                      href="javascript:void(0);"
                                                      className="cursorPointer"
                                                      data-toggle="modal"
                                                      data-target="#myModal_more_detail"
                                                      onClick={() =>
                                                        this.onMoreDetails()
                                                      }
                                                    >
                                                      More Details
                                              </a>
                                                  </div>
                                                  <br />
                                                </div>
                                              ) : null}
                                            </div>
                                          )
                                        )
                                        : "No Timecards Found!"}
                                    </div>
                                    <div className="col-sm-1 p0 toggle_button_div togle_height_mdd slide-toggle">
                                      <a
                                        href="javascript:void(0);"
                                        className="cursorPointer"
                                      >
                                        <i className="fa fa-caret-left"></i>
                                      </a>
                                    </div>
                                    <div className="tab_content_data">
                                      <div className="col-sm-8 col-md-8 p0 timecard_8_colum ">
                                        {this.state.empDetail ? (
                                          <React.Fragment>
                                            <div className="approve_nav_right">
                                              <div className="col-sm-3 col-md-3"></div>
                                              <div className="col-sm-6 col-md-6">
                                                <div className="timecard_navbar_icon">
                                                  {this.state.image_payslip_path
                                                    .length > 0 ? (
                                                      <div className="timecard_plus_minus_icon">
                                                        <div className="clear5"> </div>
                                                        <a
                                                          href="javascript:void(0);"
                                                          className="cursorPointer"
                                                          onClick={this.zoomout}
                                                        >
                                                          <img
                                                            alt="timecardpic"
                                                            src={minus_3}
                                                          />
                                                        </a>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  {this.state.image_payslip_path
                                                    .length > 0 ? (
                                                      <div className="timecard_plus_svg">
                                                        <div className="clear10"> </div>
                                                        <a
                                                          href="javascript:void(0);"
                                                          className="cursorPointer"
                                                          onClick={this.zoomin}
                                                        >
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="15"
                                                            height="15"
                                                            viewBox="0 0 15 15"
                                                          >
                                                            <path
                                                              id="ic_add_24px"
                                                              d="M20,13.571H13.571V20H11.429V13.571H5V11.429h6.429V5h2.143v6.429H20Z"
                                                              transform="translate(-5 -5)"
                                                              fill="#3c6f9f"
                                                            />
                                                          </svg>
                                                        </a>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  {this.state.image_payslip_path
                                                    .length > 0 ? (
                                                      <div className="timecard_75_text">
                                                        <select
                                                          name=""
                                                          className="margin_0px"
                                                          onChange={this.changeImgSize}
                                                        >
                                                          <option value="25">25%</option>
                                                          <option value="50">50%</option>
                                                          <option value="75">75%</option>
                                                          <option value="100">
                                                            100%
                                                </option>
                                                        </select>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  {/* <div className="timecard_cross_all_img">
                                          <div className="clear10"> </div>
                                          <a
                                            href="javascript:void(0);"
                                            className="cursorPointer"
                                          >
                                            <img
                                              alt="timecardpic"
                                              src={cross_all_3}
                                            />
                                          </a>
                                        </div> */}
                                                  {this.state.image_payslip_path
                                                    .length > 0 ? (
                                                      <div className="timecard_arow_left_right_img">
                                                        <a
                                                          href="javascript:void(0);"
                                                          className="cursorPointer"
                                                        >
                                                          {" "}
                                                          <img
                                                            alt="timecardpic"
                                                            src={img_left_right_icon}
                                                            onClick={this.handleImageSize}
                                                          />
                                                        </a>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                </div>
                                              </div>
                                              <div className="col-sm-3">
                                                <div className="col-sm-6 timecard_group_170 text-center">
                                                  <div className="clear10"> </div>
                                                  <a
                                                    href="javascript:void(0);"
                                                    className="cursorPointer"
                                                    onClick={this.leftkey}
                                                  >
                                                    {" "}
                                                    <svg
                                                      width="24"
                                                      height="24"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fillRule="evenodd"
                                                      clipRule="evenodd"
                                                    >
                                                      <path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" />
                                                    </svg>
                                                  </a>
                                                  <a
                                                    href="javascript:void(0);"
                                                    className="cursorPointer"
                                                    onClick={this.rightkey}
                                                    style={{ marginLeft: 9 }}
                                                  >
                                                    <svg
                                                      width="24"
                                                      height="24"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fillRule="evenodd"
                                                      clipRule="evenodd"
                                                    >
                                                      <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                                                    </svg>
                                                  </a>
                                                </div>
                                                <div className="col-sm-6 text-center">
                                                  <div className="clear5"> </div>
                                                  <a
                                                    href="javascript:void(0);"
                                                    className="cursorPointer"
                                                    data-toggle="modal"
                                                    data-target="#timecard_sidee_modal"
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="5.223"
                                                      height="20.893"
                                                      viewBox="0 0 5.223 20.893"
                                                    >
                                                      <path
                                                        id="ic_more_horiz_24px"
                                                        d="M6.612,10a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,6.612,10Zm15.669,0a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,22.281,10Zm-7.835,0a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,14.446,10Z"
                                                        transform="translate(15.223 -4) rotate(90)"
                                                        fill="#30679a"
                                                      />
                                                    </svg>
                                                  </a>
                                                </div>
                                              </div>
                                              <div className="clearfix"> </div>
                                            </div>
                                          </React.Fragment>
                                        ) : (
                                            ""
                                          )}
                                      </div>

                                      <div className="col-sm-8 sidebar_main_right timecard_8_colum">
                                        <div className="col-sm-12 ipad_padd_zero">
                                          <div className="slider_pdf_viewer custom_slide_height">
                                            <div className="clear50 clear50_none">
                                              {" "}
                                            </div>
                                            {this.state.empDetail ? (
                                              this.state.image_payslip_path.length >
                                                0 &&(this.state.image_payslip_path[0].fileurl!==undefined&&this.state.image_payslip_path[0].fileurl!==null)  ? (
                                                  <React.Fragment>
                                                    <div
                                                      id="myCarousel_timecard_4"
                                                      className="carousel slide"
                                                      data-interval="false"
                                                    >
                                                      <ol className="carousel-indicators timecard_indcators">
                                                        <li
                                                          data-target="#myCarousel_timecard_4"
                                                          data-slide-to="0"
                                                          className="active"
                                                        ></li>
                                                        <li
                                                          data-target="#myCarousel_timecard_4"
                                                          data-slide-to="1"
                                                        ></li>
                                                      </ol>
                                                      <div
                                                        className="carousel-inner"
                                                        style={{
                                                          width: this.state.isMobView
                                                            ? ""
                                                            : imageStyle.width + "px",
                                                          height:
                                                            imageStyle.height + "px",
                                                          minWidth: this.state.isMobView
                                                            ? ""
                                                            : 560,
                                                          minHeight: 340,
                                                          maxWidth: 1250,
                                                          maxHeight: 800,
                                                          align: "center ",
                                                          margin: "0 auto "
                                                        }}
                                                      >
                                                        <div
                                                          id={0}
                                                          className="all_active item active"
                                                        >
                                                          <p>Payslip </p>
                                                          <img
                                                            src={
                                                              this.state
                                                                .image_payslip_path &&
                                                              this.state
                                                                .image_payslip_path
                                                                .length>0 &&
                                                              this.state
                                                                .image_payslip_path[0]
                                                                .fileurl
                                                            }
                                                            alt="payslip"
                                                            className="timecards_carousel_img"
                                                            onLoad={this.loadimage}
                                                            style={{
                                                              height:
                                                                imageStyle.height + "px"
                                                            }}
                                                          />
                                                        </div>

                                                        {this.state.image_payslip_path &&
                                                          this.state.image_payslip_path
                                                            .length > 1 &&(this.state.image_payslip_path[1].fileurl!==undefined&&this.state.image_payslip_path[1].fileurl!==null) &&
                                                          this.state.image_payslip_path
                                                            .slice(1)
                                                            .map((p, i) => {
                                                              return (
                                                                <div
                                                                  id={i + 1}
                                                                  className="item"
                                                                >
                                                                  <p>Timesheet </p>
                                                                  <img
                                                                    src={p.fileurl+'?t='+new Date()}
                                                                    alt="payslip"
                                                                    className="timecards_carousel_img"
                                                                    onLoad={
                                                                      this.loadimage
                                                                    }
                                                                    style={{
                                                                      height:
                                                                        imageStyle.height +
                                                                        "px"
                                                                    }}
                                                                  />
                                                                </div>
                                                              );
                                                            })}
                                                      </div>
                                                      {this.state.image_payslip_path
                                                        .length > 1 && (this.state.image_payslip_path[1].fileurl!==undefined&&this.state.image_payslip_path[1].fileurl!==null) &&(
                                                          <>
                                                            <a
                                                              className="left carousel-control "
                                                              href="#myCarousel_timecard_4"
                                                              data-slide="prev"
                                                              onClick={this.getActiveIndex}
                                                            >
                                                              <span className="glyphicon glyphicon-chevron-left">
                                                                {" "}
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  width="13"
                                                                  height="21.053"
                                                                  viewBox="0 0 13 21.053"
                                                                >
                                                                  <path
                                                                    id="ic_chevron_right_24px"
                                                                    d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                                                                    transform="translate(21.59 27.053) rotate(180)"
                                                                    fill="#989898"
                                                                  />
                                                                </svg>
                                                              </span>
                                                            </a>
                                                            <a
                                                              className="right carousel-control"
                                                              href="#myCarousel_timecard_4"
                                                              data-slide="next"
                                                              onClick={this.getActiveIndex}
                                                            >
                                                              <span className="glyphicon glyphicon-chevron-right">
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  width="13"
                                                                  height="21.053"
                                                                  viewBox="0 0 13 21.053"
                                                                >
                                                                  <path
                                                                    id="ic_chevron_right_24px"
                                                                    d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                                                                    transform="translate(-8.59 -6)"
                                                                    fill="#989898"
                                                                  />
                                                                </svg>
                                                              </span>
                                                            </a>
                                                          </>
                                                        )}
                                                    </div>
                                                  </React.Fragment>
                                                ) : (
                                                  <div style={{ textAlign: "center" }}>
                                                    <h4>Payslip Not Found</h4>
                                                  </div>
                                                )
                                            ) : (
                                                <div style={{ textAlign: "center" }}>
                                                  <h4>No Time Card is Selected</h4>
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="timecard_btm_menu">
                                  <div className="col-sm-12 col-xs-12">
                                    <div className="col-xs-3 col-sm-3 timecard_mbl_tick_icon text-center">
                                      {this.state.selectedTab === "pending" ||
                                        this.state.selectedTab === "approved" ||
                                        this.state.selectedTab === "decline" ||
                                        this.state.selectedTab === "all" ? (
                                          ""
                                        ) : (
                                          <a
                                            href="javascript:void(0);"
                                            className="cursorPointer"
                                            onClick={this.approve}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="17.6"
                                              height="13.4"
                                              viewBox="0 0 17.6 13.4"
                                            >
                                              <path
                                                id="ic_done_24px"
                                                d="M9,16.2,4.8,12,3.4,13.4,9,19,21,7,19.6,5.6Z"
                                                transform="translate(-3.4 -5.6)"
                                                fill="#4ED94E"
                                              />
                                            </svg>
                                            <p>Approve </p>
                                          </a>
                                        )}
                                    </div>
                                    <div className="col-xs-3 col-sm-3 timecard_mbl_edit_icon text-center">
                                      {this.state.selectedTab === "pending" ||
                                        this.state.selectedTab === "approved" ||
                                        this.state.selectedTab === "decline" ||
                                        this.state.selectedTab === "all" ? (
                                          ""
                                        ) : (
                                          <a
                                            onClick={() =>
                                              this.setState({ isEdit: true })
                                            }
                                          >
                                            {" "}
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 20 20"
                                              width="15.77"
                                              height="15.77"
                                            >
                                              <path
                                                id="ic_create_24px"
                                                className="cls-1"
                                                d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z"
                                                transform="translate(-3 -2.998)"
                                                fill="#FFA200"
                                              />
                                            </svg>
                                            <p>Edit </p>
                                          </a>
                                        )}
                                    </div>
                                    {this.state.selectedTab === "pending" ||
                                      this.state.selectedTab === "approved" ||
                                      this.state.selectedTab === "decline" ||
                                      this.state.selectedTab === "all" ? (
                                        ""
                                      ) : (
                                        <div
                                          className="col-xs-3 col-sm-3 timecard_mbl_decline_icon text-center"
                                          onClick={this.showDeclinetimecardMob}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                          >
                                            <path
                                              id="ic_clear_24px"
                                              d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                                              transform="translate(-5 -5)"
                                              fill="#FF1E1E"
                                            />
                                          </svg>

                                          <p>Decline </p>
                                        </div>
                                      )}
                                    <div className="col-xs-3 col-sm-3 timecard_mbl_next_icon text-center">
                                      <a
                                        href="javascript:void(0);"
                                        className="cursorPointer"
                                        onClick={this.rightkey}
                                      >
                                        <img alt="timecardpic" src={next_icon} />
                                        <p>Next</p>
                                      </a>
                                    </div>

                                    <div className="clearfix"> </div>
                                  </div>
                                  <div className="clearfix"> </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="clearfix"></div>
                    </div>
                    <div
                      id="timecard_sidee_modal"
                      className="modal right fade"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="myModalLabel2"
                    >
                      <div className="modal-dialog modal-right-top" role="document">
                        <div className="modal-content">
                          <div className="modal-header timecard_modal_header">
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true" />
                            </button>
                            <Link to="/analytics">
                              <h4
                                className="modal-title timecard_modal_titile"
                                id="myModalLabel2"
                              >
                                Analytics
                      </h4>
                            </Link>
                          </div>

                          <div className="modal-body timecard_modal_body">
                            {this.state.image_payslip_path.map(a => {
                              return<>
                              {a.filename == 'static' ? 
                              //<a download={`${a.filename}`} href={`${a.fileurl}`}>bbbbb</a>
                              
                              <a
                              download={`${a.filename}`} 
                              href={`${a.fileurl}`}
                              className="col-sm-12 col-xs-12  timecard_bdr_btm" 
                              style={{display:'none'}}
                              id={'image_download_a'}
                              >
                              <div
                                className="col-sm-6 col-xs-6 p0 timecard_modal_dwnload"
                                style={{ cursor: "default" }}
                              >
                                Download
                              </div>
                              <div className="col-sm-6 col-xs-6 p0 text-right">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20.307"
                                  viewBox="0 0 20 20.307"
                                >
                                  <g
                                    id="Group_1699"
                                    data-name="Group 1699"
                                    transform="translate(-34.16 4.729)"
                                  >
                                    <g id="v" transform="translate(0 -4)">
                                      <rect
                                        id="Rectangle_3943"
                                        data-name="Rectangle 3943"
                                        width="20"
                                        height="6"
                                        transform="translate(34.16 13.578)"
                                        fill="#30679a"
                                      />
                                      <rect
                                        id="Rectangle_3944"
                                        data-name="Rectangle 3944"
                                        width="16"
                                        height="8"
                                        transform="translate(36.16 9.578)"
                                        fill="#fff"
                                      />
                                    </g>
                                    <path
                                      id="ic_add_24px"
                                      d="M20.589,10.738H5V8.51H20.589Z"
                                      transform="translate(34.649 15.861) rotate(-90)"
                                      fill="#30679a"
                                    />
                                    <path
                                      id="ic_arrow_drop_up_24px"
                                      d="M0,3.073,3.073,0,6.147,3.073Z"
                                      transform="translate(47.307 12.61) rotate(180)"
                                      fill="#30679a"
                                    />
                                  </g>
                                </svg>
                              </div>
                            </a>
                            
                            
                              
                              :

                              <div
                              onClick={this.downloadPayslips}
                              className="col-sm-12 col-xs-12  timecard_bdr_btm"
                            >
                              <div
                                className="col-sm-6 col-xs-6 p0 timecard_modal_dwnload"
                                style={{ cursor: "default" }}
                              >
                                Download
                      </div>
                              <div className="col-sm-6 col-xs-6 p0 text-right">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20.307"
                                  viewBox="0 0 20 20.307"
                                >
                                  <g
                                    id="Group_1699"
                                    data-name="Group 1699"
                                    transform="translate(-34.16 4.729)"
                                  >
                                    <g id="v" transform="translate(0 -4)">
                                      <rect
                                        id="Rectangle_3943"
                                        data-name="Rectangle 3943"
                                        width="20"
                                        height="6"
                                        transform="translate(34.16 13.578)"
                                        fill="#30679a"
                                      />
                                      <rect
                                        id="Rectangle_3944"
                                        data-name="Rectangle 3944"
                                        width="16"
                                        height="8"
                                        transform="translate(36.16 9.578)"
                                        fill="#fff"
                                      />
                                    </g>
                                    <path
                                      id="ic_add_24px"
                                      d="M20.589,10.738H5V8.51H20.589Z"
                                      transform="translate(34.649 15.861) rotate(-90)"
                                      fill="#30679a"
                                    />
                                    <path
                                      id="ic_arrow_drop_up_24px"
                                      d="M0,3.073,3.073,0,6.147,3.073Z"
                                      transform="translate(47.307 12.61) rotate(180)"
                                      fill="#30679a"
                                    />
                                  </g>
                                </svg>
                              </div>
                            </div>
                            
                            
                            }</>
                            })}
                            
                            <div className="col-sm-12 col-xs-12 timecard_bdr_btm">
                              <div
                                className={
                                  this.state.showHideChanges
                                    ? "col-sm-1 col-xs-1 p0 modal-right-mt-7"
                                    : "col-sm-1 col-xs-1 timecard_modal_changes p0"
                                }
                                onClick={() => this.showHideInfo("showHideChanges")}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="13"
                                  viewBox="0 0 13 8.027"
                                >
                                  <path
                                    id="ic_expand_more_24px"
                                    d="M17.473,8.59,12.5,13.552,7.528,8.59,6,10.118l6.5,6.5,6.5-6.5Z"
                                    transform="translate(-6 -8.59)"
                                    fill="#7B7B7B"
                                  />
                                </svg>
                              </div>
                              <div className="col-sm-6 col-xs-6 timecard_chng_text">
                                Changes{" "}
                              </div>
                              <div className="col-sm-3 col-xs-3 timecard_chng_text_1">
                                {this.state.changes.length}{" "}
                              </div>
                              <div className="col-sm-2 col-xs-2 timecard_chervon_right p0 text-right">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="13"
                                  viewBox="0 0 13 21.053"
                                >
                                  <path
                                    id="ic_chevron_right_24px"
                                    d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                                    transform="translate(-8.59 -6)"
                                  />
                                </svg>
                              </div>
                            </div>
                            {this.state.showHideChanges && (
                              <>
                                {this.state.changes.length > 0
                                  ? this.state.changes.map(value => (
                                    <React.Fragment>
                                      <div className="col-sm-12 col-xs-12 timecard_kevin_heading ">
                                        <h4> {value.pname}</h4>
                                      </div>
                                      <div className="col-sm-12 col-xs-12 timecard_kevin_date">
                                        <h4>
                                          {" "}
                                          <p>
                                            changed {value.name} from{" "}
                                            {value.beforechange} to{" "}
                                            {value.afterchange}{" "}
                                          </p>
                                        </h4>
                                      </div>
                                      <div className="col-sm-12 col-xs-12 timecard_kevin_datee_last ">
                                        <h4> {value.time} </h4>
                                      </div>
                                    </React.Fragment>
                                  ))
                                  : ""}
                              </>
                            )}

                            <div className="col-sm-12 col-xs-12 timecard_bdr_btm">
                              <div
                                className={
                                  this.state.showHideComments
                                    ? "col-sm-1 col-xs-1 p0 modal-right-mt-7"
                                    : "col-sm-1 col-xs-1 timecard_modal_changes p0"
                                }
                                onClick={() => this.showHideInfo("showHideComments")}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="13"
                                  viewBox="0 0 13 8.027"
                                >
                                  <path
                                    id="ic_expand_more_24px"
                                    d="M17.473,8.59,12.5,13.552,7.528,8.59,6,10.118l6.5,6.5,6.5-6.5Z"
                                    transform="translate(-6 -8.59)"
                                    fill="#7B7B7B"
                                  />
                                </svg>
                              </div>
                              <a
                                href="javascript:void(0);"
                                className="cursorPointer"
                                data-target="#user_document"
                                data-toggle="modal"
                              >
                                {" "}
                                <div className="col-sm-6 col-xs-6 timecard_chng_text">
                                  Comments{" "}
                                </div>{" "}
                              </a>
                              <div className="col-sm-3 col-xs-3 timecard_chng_text_1">
                                {this.state.comments.length}{" "}
                              </div>
                              <a
                                href="javascript:void(0);"
                                className="cursorPointer"
                                data-target="#user_document"
                                data-toggle="modal"
                              >
                                <div className="col-sm-2 col-xs-2 timecard_chervon_right p0 text-right">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="13"
                                    height="13"
                                    viewBox="0 0 13 21.053"
                                  >
                                    <path
                                      id="ic_chevron_right_24px"
                                      d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                                      transform="translate(-8.59 -6)"
                                    />
                                  </svg>
                                </div>
                              </a>
                            </div>
                            {this.state.showHideComments && (
                              <>
                                {this.state.comments.map((d, i) => {
                                  return (
                                    <>
                                      <div className="col-sm-12 col-xs-12 timecard_kevin_heading ">
                                        <h4>{d.name}</h4>
                                      </div>
                                      <div className="col-sm-12 col-xs-12 timecard_kevin_date">
                                        <h4>{d.comment}</h4>
                                      </div>
                                      <div className="col-sm-12 col-xs-12 timecard_kevin_datee_last ">
                                        <h4>{d.date}</h4>
                                      </div>
                                      <div className="clear10"> </div>
                                    </>
                                  );
                                })}
                              </>
                            )}

                            <div className="col-sm-12 col-xs-12 timecard_bdr_btm">
                              <div
                                className={
                                  this.state.showHideExceptions
                                    ? "col-sm-1 col-xs-1 p0 modal-right-mt-7"
                                    : "col-sm-1 col-xs-1 timecard_modal_changes p0"
                                }
                                onClick={() => this.showHideInfo("showHideExceptions")}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="13"
                                  viewBox="0 0 13 8.027"
                                >
                                  <path
                                    id="ic_expand_more_24px"
                                    d="M17.473,8.59,12.5,13.552,7.528,8.59,6,10.118l6.5,6.5,6.5-6.5Z"
                                    transform="translate(-6 -8.59)"
                                    fill="#7B7B7B"
                                  />
                                </svg>
                              </div>
                              <a
                                href="javascript:void(0);"
                                className="cursorPointer"
                                data-target="#exceptions_modal"
                                data-toggle="modal"
                              >
                                <div className="col-sm-6 col-xs-6 timecard_chng_text">
                                  Exceptions{" "}
                                </div>{" "}
                              </a>
                              <div className="col-sm-3 col-xs-3 timecard_chng_text_1">
                                {this.state.exceptions.length}{" "}
                              </div>
                              <div className="col-sm-2 col-xs-2 timecard_chervon_right p0 text-right">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="13"
                                  viewBox="0 0 13 21.053"
                                >
                                  <path
                                    id="ic_chevron_right_24px"
                                    d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                                    transform="translate(-8.59 -6)"
                                  />
                                </svg>
                              </div>
                            </div>
                            {this.state.showHideExceptions && (
                              <>
                                {this.state.exceptions.map((e, i) => {
                                  return (
                                    <>
                                      <div className="col-sm-12 col-xs-12 timecard_kevin_date">
                                        <h4>{e.exception}</h4>
                                      </div>
                                      <div className="col-sm-12 col-xs-12 timecard_kevin_datee_last ">
                                        <h4>
                                          {" "}
                                          {e.date} {e.time}
                                        </h4>
                                      </div>
                                      <div className="clear10"> </div>
                                    </>
                                  );
                                })}
                              </>
                            )}

                            <div className="col-sm-12 col-xs-12 timecard_bdr_btm">
                              <div
                                className={
                                  this.state.showHideAttachments
                                    ? "col-sm-1 col-xs-1 p0 modal-right-mt-7"
                                    : "col-sm-1 col-xs-1 timecard_modal_changes p0"
                                }
                                onClick={() => this.showHideInfo("showHideAttachments")}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="13"
                                  viewBox="0 0 13 8.027"
                                >
                                  <path
                                    id="ic_expand_more_24px"
                                    d="M17.473,8.59,12.5,13.552,7.528,8.59,6,10.118l6.5,6.5,6.5-6.5Z"
                                    transform="translate(-6 -8.59)"
                                    fill="#7B7B7B"
                                  />
                                </svg>
                              </div>
                              <a
                                href="javascript:void(0);"
                                className="cursorPointer"
                                data-toggle="modal"
                                data-target="#attachments_timecard"
                              >
                                <div className="col-sm-6 col-xs-6 timecard_chng_text">
                                  Attachments{" "}
                                </div>{" "}
                              </a>
                              <div className="col-sm-3 col-xs-3 timecard_chng_text_1">
                                {/* {this.state.attachments.length} */}
                                {this.state.empDetail &&
                                  this.state.empDetail.PayTimes &&
                                  this.state.empDetail.PayTimes.FileAttachments
                                  ? this.state.empDetail.PayTimes.FileAttachments.length
                                  : "0"}{" "}
                              </div>
                              <div className="col-sm-2 col-xs-2 timecard_chervon_right p0 text-right">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="13"
                                  viewBox="0 0 13 21.053"
                                >
                                  <path
                                    id="ic_chevron_right_24px"
                                    d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                                    transform="translate(-8.59 -6)"
                                    fill="#7B7B7B"
                                  />
                                </svg>
                              </div>
                            </div>
                            {this.state.showHideAttachments && (
                              <>
                                {this.state.empDetail &&
                                  this.state.empDetail.PayTimes &&
                                  this.state.empDetail.PayTimes.FileAttachments
                                  ? this.state.empDetail.PayTimes.FileAttachments.map(
                                    (a, index) => {
                                      return (
                                        <div className="col-sm-12 col-xs-12">
                                          <div className="col-sm-8 col-xs-8 timecard_car_insurance p0">
                                            <Link to="/aviva_full_page">
                                              {" "}
                                              <h4>{a.filename}</h4>
                                            </Link>
                                          </div>
                                          <div className="col-sm-4 col-xs-4 timecard_chervon_car p0 text-right">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="13"
                                              height="13"
                                              viewBox="0 0 13 21.053"
                                            >
                                              <path
                                                id="ic_chevron_right_24px"
                                                d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                                                transform="translate(-8.59 -6)"
                                              />
                                            </svg>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )
                                  : ""}
                              </>
                            )}

                            <div className="col-sm-12 col-xs-12 timecard_bdr_btm">
                              <div
                                className={
                                  this.state.showHideActivity
                                    ? "col-sm-1 col-xs-1 p0 modal-right-mt-7"
                                    : "col-sm-1 col-xs-1 timecard_modal_changes p0"
                                }
                                onClick={() => this.showHideInfo("showHideActivity")}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="13"
                                  viewBox="0 0 13 8.027"
                                >
                                  <path
                                    id="ic_expand_more_24px"
                                    d="M17.473,8.59,12.5,13.552,7.528,8.59,6,10.118l6.5,6.5,6.5-6.5Z"
                                    transform="translate(-6 -8.59)"
                                    fill="#7B7B7B"
                                  />
                                </svg>
                              </div>
                              <a
                                href="javascript:void(0);"
                                className="cursorPointer"
                                data-toggle="modal"
                                data-target="#activity_popup_2"
                              >
                                <div className="col-sm-6 col-xs-6 timecard_chng_text">
                                  Activity{" "}
                                </div>{" "}
                              </a>
                              <div className="col-sm-3 col-xs-3 timecard_chng_text_1">
                                {this.state.activities.length}

                              </div>
                              <div className="col-sm-2 col-xs-2 timecard_chervon_right p0 text-right">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="13"
                                  viewBox="0 0 13 21.053"
                                >
                                  <path
                                    id="ic_chevron_right_24px"
                                    d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                                    transform="translate(-8.59 -6)"
                                  />
                                </svg>
                              </div>
                            </div>
                            {/* <div className="col-sm-12 col-xs-12 timecard_kevin_date">
                      <h4>
                        Ian Gracie changed start time on Monday from 7:00 am to
                        7:30am.
                      </h4>
                    </div>
                    <div className="col-sm-12 col-xs-12 timecard_kevin_datee_last ">
                      <h4> 5/07/2018 1:10pm</h4>
                    </div> */}
                            {this.state.showHideActivity && (
                              <>
                                {this.state.activities.map((a, i) => {
                                  return (
                                    <>
                                      <div className="col-sm-12 col-xs-12 timecard_kevin_date">
                                        <h4>{a.msg}</h4>
                                      </div>
                                      <div className="col-sm-12 col-xs-12 timecard_kevin_datee_last ">
                                        <h4>{a.date}</h4>
                                      </div>
                                    </>
                                  );
                                })}
                                {/* {" "}
                        {this.state.changes
                          ? this.state.changes.map((data, index) => {
                              return (
                                <>
                                  <div className="col-sm-12 col-xs-12 timecard_kevin_date">
                                    <h4>
                                      {data.pname} changes time on {data.time}{" "}
                                    </h4>
                                  </div>
                                </>
                              );
                            })
                          : ""}
                         */}
                              </>
                            )}

                            <div className="clear30"> </div>
                            <div className="clear30"> </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="timecard_sidee_modal_mobile"
                      className="modal right fade"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="myModalLabel2"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content" style={{ paddingBottom: '80px' }}>
                          <div className="modal-header timecard_modal_header">
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true" />
                            </button>
                            <Link to="/analytics">
                              <h4
                                className="modal-title timecard_modal_titile"
                                id="myModalLabel2"
                              >
                                Analytics
                      </h4>{" "}
                            </Link>
                            <label className="pull-right analytics_right_iconn">
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="13"
                                viewBox="0 0 13 21.053"
                              >
                                <path
                                  id="ic_chevron_right_24px"
                                  d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                                  transform="translate(-8.59 -6)"
                                />
                              </svg>
                            </label>
                          </div>

                          <div className="modal-body timecard_modal_body">
                            <div className="col-sm-12 col-xs-12 timecard_bdr_btm">
                              <div
                                className={
                                  this.state.showHideChanges
                                    ? "col-sm-1 col-xs-1 p0 modal-right-mt-7"
                                    : "col-sm-1 col-xs-1 timecard_modal_changes p0"
                                }
                                onClick={() => this.showHideInfo("showHideChanges")}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="13"
                                  viewBox="0 0 13 8.027"
                                >
                                  <path
                                    id="ic_expand_more_24px"
                                    d="M17.473,8.59,12.5,13.552,7.528,8.59,6,10.118l6.5,6.5,6.5-6.5Z"
                                    transform="translate(-6 -8.59)"
                                    fill="#7B7B7B"
                                  />
                                </svg>
                              </div>
                              <div className="col-sm-6 col-xs-6 timecard_chng_text">
                                Changes{" "}
                              </div>
                              <div className="col-sm-3 col-xs-3 timecard_chng_text_1">
                                {this.state.changes.length}{" "}
                              </div>
                              <div className="col-sm-2 col-xs-2 timecard_chervon_right p0 text-right">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="13"
                                  viewBox="0 0 13 21.053"
                                >
                                  <path
                                    id="ic_chevron_right_24px"
                                    d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                                    transform="translate(-8.59 -6)"
                                  />
                                </svg>
                              </div>
                            </div>
                            {this.state.showHideChanges && (
                              <>
                                {this.state.changes.length > 0 ? (
                                  this.state.changes.map(value => (
                                    <React.Fragment>
                                      <div className="col-sm-12 col-xs-12 timecard_kevin_heading ">
                                        <h4> {value.pname}</h4>
                                      </div>
                                      <div className="col-sm-12 col-xs-12 timecard_kevin_date">
                                        <h4>
                                          {" "}
                                          <p>
                                            changed {value.name} from{" "}
                                            {value.beforechange} to {value.afterchange}{" "}
                                          </p>
                                        </h4>
                                      </div>
                                      <div className="col-sm-12 col-xs-12 timecard_kevin_datee_last ">
                                        <h4> {value.time} </h4>
                                      </div>
                                    </React.Fragment>
                                  ))
                                ) : (
                                    <h1>empty</h1>
                                  )}
                              </>
                            )}
                            <div className="col-sm-12 col-xs-12 timecard_bdr_btm">
                              <div
                                className={
                                  this.state.showHideComments
                                    ? "col-sm-1 col-xs-1 p0 modal-right-mt-7"
                                    : "col-sm-1 col-xs-1 timecard_modal_changes p0"
                                }
                                onClick={() => this.showHideInfo("showHideComments")}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="13"
                                  viewBox="0 0 13 8.027"
                                >
                                  <path
                                    id="ic_expand_more_24px"
                                    d="M17.473,8.59,12.5,13.552,7.528,8.59,6,10.118l6.5,6.5,6.5-6.5Z"
                                    transform="translate(-6 -8.59)"
                                    fill="#7B7B7B"
                                  />
                                </svg>
                              </div>{" "}
                              <a
                                href="javascript:void(0);"
                                className="cursorPointer"
                                data-target="#user_document"
                                data-toggle="modal"
                              >
                                {" "}
                                <div
                                  className="col-sm-6 col-xs-6 timecard_chng_text"
                                // onClick={this.MobileCommentsScreen}
                                >
                                  Comments{" "}
                                </div>{" "}
                              </a>
                              <div className="col-sm-3 col-xs-3 timecard_chng_text_1">
                                {this.state.comments.length}{" "}
                              </div>
                              <div className="col-sm-2 col-xs-2 timecard_chervon_right p0 text-right">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="13"
                                  viewBox="0 0 13 21.053"
                                >
                                  <path
                                    id="ic_chevron_right_24px"
                                    d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                                    transform="translate(-8.59 -6)"
                                  />
                                </svg>
                              </div>
                            </div>
                            {this.state.comments.map((d, i) => {
                              return (
                                <>
                                  <div className="col-sm-12 col-xs-12 timecard_kevin_heading ">
                                    <h4>{d.name}</h4>
                                  </div>
                                  <div className="col-sm-12 col-xs-12 timecard_kevin_date">
                                    <h4>{d.comment}</h4>
                                  </div>
                                  <div className="col-sm-12 col-xs-12 timecard_kevin_datee_last ">
                                    <h4>{d.date}</h4>
                                  </div>
                                  <div className="clear10"> </div>
                                </>
                              );
                            })}

                            <div className="col-sm-12 col-xs-12 timecard_bdr_btm">
                              <div
                                className={
                                  this.state.showHideExceptions
                                    ? "col-sm-1 col-xs-1 p0 modal-right-mt-7"
                                    : "col-sm-1 col-xs-1 timecard_modal_changes p0"
                                }
                                onClick={() => this.showHideInfo("showHideExceptions")}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="13"
                                  viewBox="0 0 13 8.027"
                                >
                                  <path
                                    id="ic_expand_more_24px"
                                    d="M17.473,8.59,12.5,13.552,7.528,8.59,6,10.118l6.5,6.5,6.5-6.5Z"
                                    transform="translate(-6 -8.59)"
                                    fill="#7B7B7B"
                                  />
                                </svg>
                              </div>

                              <a
                                href="javascript:void(0);"
                                className="cursorPointer"
                                data-target="#exceptions_modal"
                                data-toggle="modal"
                              >
                                <div
                                  className="col-sm-6 col-xs-6 timecard_chng_text"
                                // onClick={this.showExceptionsMobile}
                                >
                                  Exceptions{" "}
                                </div>{" "}
                              </a>

                              <div className="col-sm-3 col-xs-3 timecard_chng_text_1">
                                {this.state.exceptions.length}{" "}
                              </div>
                              <div className="col-sm-2 col-xs-2 timecard_chervon_right p0 text-right">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="13"
                                  viewBox="0 0 13 21.053"
                                >
                                  <path
                                    id="ic_chevron_right_24px"
                                    d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                                    transform="translate(-8.59 -6)"
                                  />
                                </svg>
                              </div>
                            </div>
                            {this.state.showHideExceptions && (
                              <>
                                {this.state.exceptionlist ? (
                                  <>
                                    {this.state.exceptions.map((data, index) => {
                                      return (
                                        <>
                                          <div className="col-sm-12 col-xs-12 timecard_kevin_date">
                                            <h4>{data.exception}</h4>
                                          </div>
                                          <div className="col-sm-12 col-xs-12 timecard_kevin_datee_last ">
                                            <h4>
                                              {" "}
                                              {data.date} {data.time}
                                            </h4>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </>
                                ) : (
                                    ""
                                  )}
                              </>
                            )}

                            <div className="col-sm-12 col-xs-12 timecard_bdr_btm">
                              <div
                                className={
                                  this.state.showHideAttachments
                                    ? "col-sm-1 col-xs-1 p0 modal-right-mt-7"
                                    : "col-sm-1 col-xs-1 timecard_modal_changes p0"
                                }
                                onClick={() => this.showHideInfo("showHideAttachments")}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="13"
                                  viewBox="0 0 13 8.027"
                                >
                                  <path
                                    id="ic_expand_more_24px"
                                    d="M17.473,8.59,12.5,13.552,7.528,8.59,6,10.118l6.5,6.5,6.5-6.5Z"
                                    transform="translate(-6 -8.59)"
                                    fill="#7B7B7B"
                                  />
                                </svg>
                              </div>
                              <a
                                href="javascript:void(0);"
                                className="cursorPointer"
                                data-toggle="modal"
                                data-target="#attachments_timecard"
                              >
                                <div
                                  className="col-sm-6 col-xs-6 timecard_chng_text"
                                // onClick={this.showExceptionsMobile}
                                >
                                  Attachments{" "}
                                </div>{" "}
                              </a>
                              <div className="col-sm-3 col-xs-3 timecard_chng_text_1">
                                {this.state.empDetail &&
                                  this.state.empDetail.PayTimes &&
                                  this.state.empDetail.PayTimes.FileAttachments
                                  ? this.state.empDetail.PayTimes.FileAttachments.length
                                  : "0"}{" "}
                              </div>
                              <div className="col-sm-2 col-xs-2 timecard_chervon_right p0 text-right">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="13"
                                  viewBox="0 0 13 21.053"
                                >
                                  <path
                                    id="ic_chevron_right_24px"
                                    d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                                    transform="translate(-8.59 -6)"
                                  />
                                </svg>
                              </div>
                            </div>
                            {this.state.showHideAttachments && (
                              <>
                                {this.state.empDetail &&
                                  this.state.empDetail.PayTimes &&
                                  this.state.empDetail.PayTimes.FileAttachments
                                  ? this.state.empDetail.PayTimes.FileAttachments.map(
                                    (a, index) => {
                                      return (
                                        <div className="col-sm-12 col-xs-12">
                                          <div className="col-sm-8 col-xs-8 timecard_car_insurance p0">
                                            <Link to="/aviva_full_page">
                                              {" "}
                                              <h4>{a.filename}</h4>
                                            </Link>
                                          </div>
                                          <div className="col-sm-4 col-xs-4 timecard_chervon_car p0 text-right">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="13"
                                              height="13"
                                              viewBox="0 0 13 21.053"
                                            >
                                              <path
                                                id="ic_chevron_right_24px"
                                                d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                                                transform="translate(-8.59 -6)"
                                              />
                                            </svg>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )
                                  : ""}
                              </>
                            )}

                            <div className="col-sm-12 col-xs-12 timecard_bdr_btm">
                              <div
                                className={
                                  this.state.showHideActivity
                                    ? "col-sm-1 col-xs-1 p0 modal-right-mt-7"
                                    : "col-sm-1 col-xs-1 timecard_modal_changes p0"
                                }
                                onClick={() => this.showHideInfo("showHideActivity")}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="13"
                                  viewBox="0 0 13 8.027"
                                >
                                  <path
                                    id="ic_expand_more_24px"
                                    d="M17.473,8.59,12.5,13.552,7.528,8.59,6,10.118l6.5,6.5,6.5-6.5Z"
                                    transform="translate(-6 -8.59)"
                                    fill="#7B7B7B"
                                  />
                                </svg>
                              </div>
                              <a
                                href="javascript:void(0);"
                                className="cursorPointer"
                                data-toggle="modal"
                                data-target="#activity_popup_2"
                              >
                                <div className="col-sm-6 col-xs-6 timecard_chng_text">
                                  Activity{" "}
                                </div>{" "}
                              </a>
                              <div className="col-sm-3 col-xs-3 timecard_chng_text_1">
                                {this.state.activities.length}
                              </div>
                              <div className="col-sm-2 col-xs-2 timecard_chervon_right p0 text-right">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="13"
                                  viewBox="0 0 13 21.053"
                                >
                                  <path
                                    id="ic_chevron_right_24px"
                                    d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                                    transform="translate(-8.59 -6)"
                                  />
                                </svg>
                              </div>
                            </div>
                            {this.state.showHideActivity && (
                              <>
                                {this.state.activities.map((a, i) => {
                                  return (
                                    <>
                                      <div className="col-sm-12 col-xs-12 timecard_kevin_date">
                                        <h4>{a.msg}</h4>
                                      </div>
                                      <div className="col-sm-12 col-xs-12 timecard_kevin_datee_last ">
                                        <h4>{a.date}</h4>
                                      </div>
                                    </>
                                  );
                                })}
                                {/* {" "}
                        {this.state.changes
                          ? this.state.changes.map((data, index) => {
                              return (
                                <>
                                  <div className="col-sm-12 col-xs-12 timecard_kevin_date">
                                    <h4>
                                      {data.pname} changes time on {data.time}{" "}
                                    </h4>
                                  </div>
                                </>
                              );
                            })
                          : ""} */}
                              </>
                            )}
                            <div className="clear30"> </div>
                            <div className="clear30"> </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="attachments_timecard" className="modal fade" role="dialog">
                      <div className="modal-dialog modal_attachment_dialog">
                        <div className="modal-content modal_middle_emp_attach">
                          <div className="modal-header">
                            <button
                              type="button"
                              className="close close_modal_employee"
                              data-dismiss="modal"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                              >
                                <path
                                  id="ic_clear_24px"
                                  d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                                  transform="translate(-5 -5)"
                                  fill="#7B7B7B"
                                />
                              </svg>
                            </button>
                            <h4 className="modal-title employeel_modal_header modal_attachmnet_heading">
                              Attachments
                    </h4>
                          </div>
                          <div className="modal-body">
                            <div className="col-sm-12 table_attach_empl">
                              <table width="100%" className="table table-condensed">
                                {this.state.empDetail &&
                                  this.state.empDetail.PayTimes &&
                                  this.state.empDetail.PayTimes.FileAttachments
                                  ? this.state.empDetail.PayTimes.FileAttachments
                                    .length > 0 && (
                                    <thead>
                                      <tr>
                                        <th colSpan="3" align="left">
                                          Description
                                  </th>
                                      </tr>
                                    </thead>
                                  )
                                  : ""}
                                {this.state.empDetail &&
                                  this.state.empDetail.PayTimes &&
                                  this.state.empDetail.PayTimes.FileAttachments ? (
                                    this.state.empDetail.PayTimes.FileAttachments.length >
                                      0 ? (
                                        <tbody>
                                          {this.state.empDetail &&
                                            this.state.empDetail.PayTimes &&
                                            this.state.empDetail.PayTimes.FileAttachments
                                            ? this.state.empDetail.PayTimes.FileAttachments.map(
                                              (a, index) => {
                                                return (
                                                  <tr key={index}>
                                                    <td width="90%">{a.filename}</td>
                                                    <td
                                                      colSpan="2"
                                                      align="center"
                                                      width="10%"
                                                    >
                                                      <a
                                                        href={null}
                                                        className="doc_img_emp cursorPointer"
                                                        onClick={() =>
                                                          this.dowonloadEmpAttach(a.url)
                                                        }
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 18 22.5"
                                                        >
                                                          <path
                                                            id="ic_description_24px"
                                                            className="cls-1"
                                                            d="M15.25,2h-9A2.247,2.247,0,0,0,4.011,4.25L4,22.25A2.247,2.247,0,0,0,6.239,24.5H19.75A2.257,2.257,0,0,0,22,22.25V8.75ZM17.5,20h-9V17.75h9Zm0-4.5h-9V13.25h9ZM14.125,9.875V3.688l6.187,6.188Z"
                                                            transform="translate(-4 -2)"
                                                          />
                                                        </svg>
                                                      </a>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )
                                            : ""}
                                        </tbody>
                                      ) : (
                                        <thead>
                                          <tr>
                                            <th colSpan="3" align="left">
                                              No Attachments Found
                                </th>
                                          </tr>
                                        </thead>
                                      )
                                  ) : (
                                    <thead>
                                      <tr>
                                        <th colSpan="3" align="left">
                                          No Attachments Found
                              </th>
                                      </tr>
                                    </thead>
                                  )}
                              </table>
                            </div>

                            <div className="clear10" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="myModal_activity" className="modal fade" role="dialog">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              data-dismiss="modal"
                              className="close timecards_outline cursorPointer"
                            ></button>
                            <h4 className="modal-title modal_customefield activity_pop1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 13.334"
                              >
                                <path
                                  id="ic_sort_24px"
                                  className="cls-1"
                                  d="M3,19.333H9.667V17.111H3ZM3,6V8.222H23V6Zm0,7.778H16.333V11.556H3Z"
                                  transform="translate(-3 -6)"
                                />
                              </svg>
                              Activity
                    </h4>
                          </div>
                          <div className="modal-body label_modal_custom">
                            <div className="clear20" />

                            <div className="col-sm-12 active_pop_label">
                              <strong>Patrick Plummer</strong> created new digital
                      contract{" "}
                            </div>
                            <div className="col-sm-12 active_pop_label">
                              <strong>Patrick Plummer</strong> signed the contract{" "}
                            </div>
                            <div className="col-sm-12 active_pop_label">
                              <strong>Patrick Plummer</strong> approve the contract{" "}
                            </div>
                            <div className="col-sm-12 active_pop_label">
                              <strong>Patrick Plummer</strong> fully approved contract{" "}
                            </div>
                            <div className="col-sm-12 active_pop_label">
                              <strong>Patrick Plummer</strong> fully approved contract{" "}
                            </div>
                            <div className="col-sm-12 active_pop_label">
                              <strong>Patrick Plummer</strong> fully approved contract{" "}
                            </div>
                            <div className="col-sm-12 active_pop_label">
                              <strong>Patrick Plummer</strong> fully approved contract{" "}
                            </div>
                            <div className="col-sm-12 active_pop_label">
                              <strong>Patrick Plummer</strong> fully approved contract{" "}
                            </div>
                            <div className="col-sm-12 active_pop_label">
                              <strong>Patrick Plummer</strong> fully approved contract{" "}
                            </div>

                            <div className="clear20" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="myModal_attachments" className="modal fade" role="dialog">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              data-dismiss="modal"
                              className="close timecards_outline cursorPointer"
                            ></button>
                            <h4 className="modal-title modal_customefield activity_pop1 attach_imgsvg">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 120 120"
                              >
                                <path
                                  id="ic_library_books_24px"
                                  className="cls-1"
                                  d="M14,26H2v84a12.035,12.035,0,0,0,12,12H98V110H14ZM110,2H38A12.035,12.035,0,0,0,26,14V86A12.035,12.035,0,0,0,38,98h72a12.035,12.035,0,0,0,12-12V14A12.035,12.035,0,0,0,110,2Zm-6,54H44V44h60ZM80,80H44V68H80Zm24-48H44V20h60Z"
                                  transform="translate(-2 -2)"
                                />
                              </svg>
                              Attachments
                    </h4>
                          </div>
                          <div className="modal-body label_modal_custom modal_digi_form_attach">
                            <div className="clear20" />

                            <table
                              width="100%"
                              border="0"
                              cellSpacing="2"
                              cellPadding="2"
                            >
                              <thead>
                                <tr>
                                  <th colSpan="2" align="left" valign="middle">
                                    Description
                          </th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td width="95%" align="left" valign="middle">
                                    Car Insurance
                          </td>
                                  <td width="5%" align="center"></td>
                                </tr>
                                <tr>
                                  <td align="left" valign="middle">
                                    Driver License
                          </td>
                                  <td align="center"></td>
                                </tr>
                                <tr>
                                  <td align="left" valign="middle">
                                    Passport
                          </td>
                                  <td align="center"></td>
                                </tr>
                              </tbody>
                            </table>

                            <div className="clear40" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="myModal_import_data" className="modal fade" role="dialog">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              data-dismiss="modal"
                              className="close timecards_outline"
                            ></button>
                            <h4 className="modal-title modal_customefield">
                              Import Excel Data
                    </h4>
                          </div>
                          <div className="modal-body label_modal_custom modal_digi_form_attach">
                            <div className="clear30" />

                            <div className="col-sm-12">
                              <textarea
                                name=""
                                className="textarea_imprt_data"
                                cols=""
                                rows=""
                                defaultValue=" Paste Excel Data Here."
                              />
                            </div>

                            <div className="clear20" />

                            <div className="col-sm-12">
                              <div className="btn_cance_save">
                                <button
                                  type="button"
                                  className="btn_save_pro"
                                  data-dismiss="modal"
                                >
                                  Save
                        </button>
                                <button
                                  type="button"
                                  className="btn_cancel_pro"
                                  data-dismiss="modal"
                                >
                                  Cancel
                        </button>
                              </div>
                            </div>
                            <div className="clear30" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      id="myModal_aprove_tabattach"
                      className="modal fade"
                      role="dialog"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              data-dismiss="modal"
                              className="close timecards_outline"
                            ></button>
                            <h4 className="modal-title modal_customefield">
                              Attachments
                    </h4>
                          </div>
                          <div className="modal-body label_modal_custom attchment_bottom_h">
                            <div className="clear10" />

                            <div className="col-sm-12 attchment_bottom_label">
                              <table
                                width="100%"
                                border="0"
                                cellSpacing="2"
                                cellPadding="2"
                                className="digi_attach_pop_area4"
                              >
                                <thead>
                                  <tr>
                                    <th width="95%" align="left">
                                      Description
                            </th>
                                    <th width="5%" align="left" />
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr className="custom_field_tab">
                                    <td>attachment_test11111111.pdf</td>
                                    <td></td>
                                  </tr>
                                  <tr className="custom_field_tab">
                                    <td>attachment_test.pdf</td>
                                    <td></td>
                                  </tr>
                                  <tr className="custom_field_tab">
                                    <td>attachment_test222222222222222.pdf</td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>

                              <div className="clear30" />

                              <div className="col-sm-12 col-md-12 label_attchment_form">
                                Add new attachment{" "}
                              </div>

                              <div className="clear5" />

                              <div className="col-sm-12 p0">
                                <div className="drag_drop_box">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="8655 7398 52 34.667"
                                  >
                                    <path
                                      id="ic_backup_24px"
                                      className="cls-1"
                                      d="M41.925,17.087a16.234,16.234,0,0,0-30.333-4.333A12.995,12.995,0,0,0,13,38.667H41.167a10.8,10.8,0,0,0,.758-21.58ZM30.333,23.5v8.667H21.667V23.5h-6.5L26,12.667,36.833,23.5Z"
                                      transform="translate(8655 7394)"
                                    />
                                  </svg>
                                  <div className="clear10" />
                                  <div> Drag files in or click to upload</div>
                                </div>
                                <div className="clear20" />
                              </div>
                            </div>

                            <div className="clear10" />

                            <div className="col-sm-12">
                              <div className="btn_cance_save">
                                <button
                                  type="button"
                                  className="btn_save_pro"
                                  data-dismiss="modal"
                                >
                                  Save
                        </button>
                                <button
                                  type="button"
                                  className="btn_cancel_pro"
                                  data-dismiss="modal"
                                >
                                  Cancel
                        </button>
                              </div>
                            </div>
                            <div className="clear20" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      id="myModal_more_detail"
                      className="modal fade"
                      role="dialog"
                      data-keyboard="false"
                      data-backdrop="static"
                    >
                      <div className="modal-dialog timecard_modal_tbaleee">
                        <div className="modal-content">
                          <div className="modal-header timecard_table_title">
                            <button
                              type="button"
                              data-dismiss="modal"
                              className="close timecards_outline timecards_modereeeeee_detail"
                              onClick={this.onCloseMoreDetail}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                              >
                                <path
                                  id="ic_clear_24px"
                                  d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                                  transform="translate(-5 -5)"
                                  fill="#7B7B7B"
                                />
                              </svg>
                            </button>
                            <h4 className="modal-title modal_customefield">
                              Timecard More Details
                    </h4>
                          </div>
                          <div className="modal-body label_modal_custom attchment_bottom_h">
                            <div className="col-sm-12 attchment_bottom_label">
                              <table
                                width="100%"
                                border="0"
                                cellSpacing="2"
                                cellPadding="2"
                                className="digi_kevin_plum_poptable"
                              >
                                {this.state.empDetail !== "" ? (
                                  <tbody>
                                    <tr>
                                      <td width="20%" align="left" valign="middle">
                                        Batch:
                                      </td>
                                      <td width="10%" align="left" valign="middle">
                                        {this.state.empDetail.Batch}
                                      </td>
                                      <td width="20%" align="left" valign="middle">
                                        Batch name:
                                       </td>
                                      <td width="30%" align="left" valign="middle">
                                        {
                                          this.state.filteredBatches &&
                                            this.state.filteredBatches.find((b) => b.Batch === this.state.empDetail.Batch) &&
                                            this.state.filteredBatches.find((b) => b.Batch === this.state.empDetail.Batch).Desc ?
                                            this.state.filteredBatches.find((b) => b.Batch === this.state.empDetail.Batch).Desc : '-'
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <td align="left" valign="middle">
                                        Decline Reason:
                              </td>
                                      <td align="left" valign="middle">
                                        <input
                                          id="timeCards_MDdeclineReason"
                                          name="timeCards_MDdeclineReason"
                                          value={this.state.timeCards_MDdeclineReason}
                                          onChange={this.timeCards_inputHandler}
                                          disabled={true}
                                          type="text"
                                          className="form-control"
                                          placeholder="N/A"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td align="left" valign="middle">
                                        Approvals:
                              </td>
                                    </tr>
                                  </tbody>
                                ) : null}
                              </table>

                              <div className="clear5" />

                              <div className="row">
                                <div className="col-sm-12">
                                  <table
                                    width="100%"
                                    border="0"
                                    cellSpacing="2"
                                    cellPadding="2"
                                    className="digi_kevin_plum_poptable5 tiimecard_table"
                                  >
                                    <thead>
                                      <tr>
                                        <th width="50%" align="center">
                                          Approved
                                </th>
                                        <th width="50%" align="center">
                                          Approver
                                </th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {this.state.setupEmpApp[0]
                                        ? this.state.setupEmpApp[0].Approvers
                                          ? this.state.setupEmpApp[0].Approvers.map(
                                            (item, index) => (
                                              <React.Fragment>
                                                <tr className="custom_field_tab">
                                                  <td>
                                                    {index <
                                                      this.state.login_app_index ||
                                                      this.state.empDetail
                                                        .approve_status ===
                                                      "Approved" ? (
                                                        // this.state.empDetail.approve_status === 'Approved' ?
                                                        <i
                                                          className="fa fa-check timecard_chk_table"
                                                          aria-hidden="true"
                                                        />
                                                      ) : (
                                                        ""
                                                      )}
                                                  </td>
                                                  <td>{item.approver_name}</td>
                                                </tr>
                                              </React.Fragment>
                                            )
                                          )
                                          : ""
                                        : ""}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>

                            <div className="clear20" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      id="myModal_comment_digi_form"
                      className="modal fade"
                      role="dialog"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              data-dismiss="modal"
                              className="close timecards_outline"
                            ></button>
                            <h4 className="modal-title modal_customefield">Comment</h4>
                          </div>
                          <div className="modal-body label_modal_custom modal_pop_digi_comment8">
                            <div className="clear20" />

                            <div className="col-sm-12">
                              <div className="comment_box_pop_digi6">
                                <div className="comment_box_labelarea6">
                                  <div className="col-sm-12 text-left label1">
                                    Patrick Plummer
                          </div>
                                  <div className="col-sm-12 text-right label2">
                                    5/07/2018 1:10pm
                          </div>
                                  <div className="col-sm-12 text-left label3">
                                    I 've attached document
                          </div>
                                  <div className="clearfix" />
                                </div>
                                <div className="comment_box_labelarea6">
                                  <div className="col-sm-12 text-left label1">
                                    Patrick Plummer
                          </div>
                                  <div className="col-sm-12 text-right label2">
                                    5/07/2018 1:10pm
                          </div>
                                  <div className="col-sm-12 text-left label3">
                                    I 've attached document
                          </div>
                                  <div className="clearfix" />
                                </div>
                                <div className="comment_box_labelarea6">
                                  <div className="col-sm-12 text-left label1">
                                    Patrick Plummer
                          </div>
                                  <div className="col-sm-12 text-right label2">
                                    5/07/2018 1:10pm
                          </div>
                                  <div className="col-sm-12 text-left label3">
                                    I 've attached document
                          </div>
                                  <div className="clearfix" />
                                </div>
                                <div className="comment_box_labelarea6">
                                  <div className="col-sm-12 text-left label1">
                                    Patrick Plummer
                          </div>
                                  <div className="col-sm-12 text-right label2">
                                    5/07/2018 1:10pm
                          </div>
                                  <div className="col-sm-12 text-left label3">
                                    I 've attached document
                          </div>
                                  <div className="clearfix" />
                                </div>
                                <div className="comment_box_labelarea6">
                                  <div className="col-sm-12 text-left label1">
                                    Patrick Plummer
                          </div>
                                  <div className="col-sm-12 text-right label2">
                                    5/07/2018 1:10pm
                          </div>
                                  <div className="col-sm-12 text-left label3">
                                    I 've attached document
                          </div>
                                  <div className="clearfix" />
                                </div>
                              </div>

                              <div className="clear40" />

                              <div>Add a Comment</div>

                              <div className="clear10" />

                              <div className="col-sm-12 p0">
                                <textarea
                                  name=""
                                  className="textarea_comment_add_pop7"
                                  cols=""
                                  rows=""
                                  defaultValue="This is demo text"
                                />
                              </div>
                            </div>

                            <div className="clear20" />

                            <div className="col-sm-12">
                              <div className="btn_cance_save">
                                <button
                                  type="button"
                                  className="btn_add_comment9"
                                  data-dismiss="modal"
                                >
                                  Add Comment
                        </button>
                              </div>
                            </div>
                            <div className="clear30" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      id="myModal_decline_digi_form"
                      className="modal fade"
                      role="dialog"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header p0 bdr_none_9">
                            <button
                              type="button"
                              data-dismiss="modal"
                              className="timecards_outline close"
                            ></button>
                            <h4 className="modal-title modal_decline_hdr ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  id="ic_not_interested_24px"
                                  className="cls-1"
                                  d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,0,1-8-8A7.9,7.9,0,0,1,5.69,7.1L16.9,18.31A7.9,7.9,0,0,1,12,20Zm6.31-3.1L7.1,5.69A7.9,7.9,0,0,1,12,4a8,8,0,0,1,8,8A7.9,7.9,0,0,1,18.31,16.9Z"
                                  transform="translate(-2 -2)"
                                />
                              </svg>
                            </h4>
                          </div>
                          <div className="modal-body label_modal_custom modal_digi_form_attach">
                            <div className="clear30" />

                            <div className="col-sm-12 p0">
                              <div className="col-sm-12 decline_img_pop">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="-10048 7722.143 16 16"
                                >
                                  <path
                                    id="ic_edit_24px"
                                    className="cls-1"
                                    d="M3,15.665V19H6.333l9.83-9.83L12.83,5.835ZM18.74,6.59a.885.885,0,0,0,0-1.253l-2.08-2.08a.885.885,0,0,0-1.253,0L13.781,4.884l3.333,3.333L18.74,6.59Z"
                                    transform="translate(-10051 7719.146)"
                                  />
                                </svg>

                                <textarea
                                  name=""
                                  className="form-control decline_textraea_contact"
                                  cols=""
                                  rows=""
                                  placeholder="Message"
                                />
                              </div>
                            </div>

                            <div className="clear20" />

                            <div className="col-sm-12">
                              <div className="btn_cance_save">
                                <div className="col-sm-6 p0">
                                  <button
                                    type="button"
                                    className="btn_cancel_pro pull-left"
                                    data-dismiss="modal"
                                  >
                                    Cancel
                          </button>
                                </div>

                                <div className="col-sm-6 p0">
                                  <button
                                    type="button"
                                    className="btn_save_pro pull-right"
                                    data-dismiss="modal"
                                  >
                                    Save
                          </button>
                                </div>
                              </div>
                            </div>
                            <div className="clear30" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      id="name_details"
                      className="modal fade"
                      data-backdrop="static"
                      data-keyboard="false"
                      role="dialog"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header adtime_pop_ad">
                            <button
                              type="button"
                              className="close close_modal_adtimes"
                              id="closed"
                              data-dismiss="modal"
                              onClick={this.closemodal}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                              >
                                <path
                                  id="ic_clear_24px"
                                  d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                                  transform="translate(-5 -5)"
                                  fill="#7B7B7B"
                                />
                              </svg>
                            </button>

                            {this.state.empDetail !== "" ? (
                              <h4 className="modal-title">
                                <i
                                  className="fa fa-angle-left"
                                  aria-expanded="true"
                                  data-dismiss="modal"
                                />{" "}
                                {this.state.empDetail.Employee[0].FirstName}{" "}
                                {this.state.empDetail.Employee[0].LastName}
                              </h4>
                            ) : null}
                          </div>
                          <div className="modal-body">
                            <div className="col-sm-12 p0">
                              <div className="">
                                <div className="dropdown menu_icon_adtimes">
                                  <button
                                    className="dropdown-toggle btn_adtimes_menu"
                                    type="button"
                                    data-toggle="dropdown"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="5.223"
                                      height="20.893"
                                      viewBox="0 0 5.223 20.893"
                                    >
                                      <path
                                        id="ic_more_horiz_24px"
                                        d="M6.612,10a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,6.612,10Zm15.669,0a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,22.281,10Zm-7.835,0a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,14.446,10Z"
                                        transform="translate(15.223 -4) rotate(90)"
                                        fill="#7b7b7b"
                                      />
                                    </svg>
                                  </button>
                                  <ul className="dropdown-menu dropleft dropdown_menu_users_down dropdown_menu_adtimes">
                                    {this.props.childProps.setting_SATCheck ? (
                                      <li>
                                        <span>
                                          {" "}
                                          <div className="checkbox timecard_chkbox_1 chkbox ad_times_chkbox">
                                            <input
                                              id="checkbox20001"
                                              type="checkbox"
                                              checked={this.state.adflag}
                                              onChange={e => this.listcheck(e, "ad")}
                                            />
                                            <label htmlFor="checkbox20001" />
                                          </div>{" "}
                                        </span>
                                        <a
                                          href="javascript:void(0);"
                                          className="cursorPointer"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="11.102"
                                            height="11.102"
                                            viewBox="0 0 11.102 11.102"
                                          >
                                            <path
                                              id="ic_announcement_24px"
                                              d="M11.991,2H3.11a1.109,1.109,0,0,0-1.1,1.11L2,13.1l2.22-2.22h7.771a1.113,1.113,0,0,0,1.11-1.11V3.11A1.113,1.113,0,0,0,11.991,2ZM8.106,7H7V3.665h1.11Zm0,2.22H7V8.106h1.11Z"
                                              transform="translate(-2 -2)"
                                              fill="#86878a"
                                            />
                                          </svg>
                                          AD Times
                                </a>
                                      </li>
                                    ) : (
                                        ""
                                      )}

                                    <li>
                                      <div className="checkbox timecard_chkbox_1 chkbox ad_times_chkbox_1">
                                        <input
                                          id="checkbox2000"
                                          type="checkbox"
                                          checked={this.state.orgflag}
                                          onChange={e => this.listcheck(e, "org")}
                                        />
                                        <label htmlFor="checkbox2000" />
                                      </div>

                                      <a
                                        href="javascript:void(0);"
                                        className="cursorPointer"
                                      >
                                        <img
                                          alt="timecardpic"
                                          src={select_paste}
                                          className="mrg_sendicon"
                                          width="12"
                                          height="15"
                                        />{" "}
                                        Original Times
                              </a>
                                    </li>
                                    {this.props.childProps.setting_SCTCheck ? (
                                      <li>
                                        <span>
                                          {" "}
                                          <div className="checkbox timecard_chkbox_1 chkbox ad_times_chkbox_2">
                                            <input
                                              id="checkbox20002"
                                              type="checkbox"
                                              checked={this.state.payflag}
                                              onChange={e => this.listcheck(e, "pay")}
                                            />
                                            <label htmlFor="checkbox20002" />
                                          </div>{" "}
                                        </span>
                                        <a
                                          href="javascript:void(0);"
                                          className="lock_invoice_icon cursorPointer"
                                        >
                                          <img
                                            src={excel}
                                            className="mrg_sendicon"
                                            width="11"
                                            height="14"
                                            alt="timecardpic"
                                          />{" "}
                                          Pay Times
                                </a>
                                      </li>
                                    ) : (
                                        ""
                                      )}
                                  </ul>
                                </div>
                              </div>

                              <div className="clear20" />
                              <div className="overflow_scroll_sm">
                                <table
                                  width="100%"
                                  className="table table-bordered timecard_table adtimes_list_table"
                                >
                                  <thead>
                                    <tr className="table_header_adtimes">
                                      <th width="5%" className="text-left">
                                        &nbsp;
                              </th>
                                      <th width="5%" className="text-left">
                                        Date
                              </th>
                                      <th width="8%" className="text-left">
                                        Day
                              </th>
                                      <th width="10%" className="text-left">
                                        Trv to
                              </th>
                                      <th width="5%" className="text-left">
                                        Start
                              </th>
                                      <th width="5%" className="text-left">
                                        Meal
                              </th>
                                      <th width="5%" className="text-left">
                                        Finish
                              </th>
                                      <th width="10%" className="text-left">
                                        Trv from
                              </th>
                                      <th width="5%" className="text-left">
                                        Hours{" "}
                                      </th>
                                      <th width="15%" className="text-left">
                                        Notes
                              </th>
                                      <th width="17%" className="text-left">
                                        PMT Flag
                              </th>
                                      <th width="5%" className="text-left">
                                        Pay as{" "}
                                      </th>
                                      <th width="5%" className="text-left">
                                        &nbsp;
                              </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.empDetail.PayTimes
                                      ? this.state.empDetail.PayTimes.Times
                                        ? this.state.empDetail.PayTimes.Times[0].DailyTimes.sort(
                                          function (a, b) {
                                            let nameA = new Date(
                                              a.Date * 1000
                                            ).toLocaleDateString();
                                            let nameB = new Date(
                                              b.Date * 1000
                                            ).toLocaleDateString();
                                            if (nameA > nameB) {
                                              return 1;
                                            }
                                            if (nameA < nameB) {
                                              return -1;
                                            }
                                            // names must be equal
                                            return 0;
                                          }
                                        ).map((x, index) => (
                                          <React.Fragment>
                                            <tr
                                              style={{
                                                display: this.state.payflag
                                                  ? ""
                                                  : "none"
                                              }}
                                            >
                                              <td align="left">&nbsp;</td>

                                              <td align="left">
                                                {new Date(
                                                  x.Date * 1000
                                                ).toLocaleDateString("en-GB")}
                                              </td>
                                              <td align="left">{x.Day}</td>
                                              <td align="left">{x.Travel1}</td>
                                              <td align="left">{x.Start}</td>
                                              <td align="left">{x.TotalMBDed}</td>
                                              <td align="left">{x.Finish}</td>
                                              <td align="left">{x.Travel2}</td>
                                              <td align="left">{x.TotalHours}</td>
                                              <td align="left">{x.Note}</td>
                                              <td align="left">
                                                {this.state.empDetail.Employee[0]
                                                  .PaymentFlags[0][
                                                  x.Day.substring(0, 3)
                                                ] === null
                                                  ? "No Payment Flag"
                                                  : this.state.empDetail.Employee[0]
                                                    .PaymentFlags[0][
                                                  x.Day.substring(0, 3)
                                                  ]}{" "}
                                              </td>
                                              <td align="left">
                                                {x.Payas === "Nan"
                                                  ? "No Payas"
                                                  : this.state.empDetail.PayTimes.Company[0].Payas.map(
                                                    y => (
                                                      <React.Fragment>
                                                        {y.Value === x.Payas
                                                          ? y.Value
                                                          : ""}
                                                      </React.Fragment>
                                                    )
                                                  )}
                                              </td>

                                              <td align="left">
                                                <a
                                                  href="javascript:void(0);"
                                                  className="adtimes_pullleft_edit cursorPointer"
                                                  data-toggle="modal"
                                                  data-target="#name_details_pencil"
                                                  onClick={this.edit_employeeData.bind(
                                                    this,
                                                    index,
                                                    x
                                                  )}
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                  >
                                                    <path
                                                      id="ic_create_24px"
                                                      className="cls-1"
                                                      d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z"
                                                      transform="translate(-3 -2.998)"
                                                    />
                                                  </svg>
                                                </a>
                                              </td>
                                            </tr>
                                            {this.state.empDetail.ADTimes.length > 1
                                              ? this.state.empDetail.ADTimes.map(y =>
                                                new Date(
                                                  y.adlistdate
                                                ).toLocaleDateString("en-GB") ===
                                                  new Date(
                                                    x.Date * 1000
                                                  ).toLocaleDateString("en-GB") ? (
                                                    <tr
                                                      style={{
                                                        display: this.state.adflag
                                                          ? ""
                                                          : "none"
                                                      }}
                                                    >
                                                      <td align="left">
                                                        <img
                                                          alt="timecardpic"
                                                          src={clock}
                                                        />
                                                      </td>
                                                      <td align="left">
                                                        {new Date(
                                                          y.adlistdate
                                                        ).toLocaleDateString("en-GB")}
                                                      </td>
                                                      <td align="left">
                                                        {
                                                          days[
                                                          new Date(
                                                            y.adlistdate
                                                          ).getDay()
                                                          ]
                                                        }
                                                      </td>
                                                      <td align="left">
                                                        {y.TravelTo}
                                                      </td>
                                                      <td align="left">{y.Start}</td>
                                                      <td align="left">{y.meals}</td>
                                                      <td align="left">{y.Finish}</td>
                                                      <td align="left">
                                                        {y.TravelFrom}
                                                      </td>
                                                      <td align="left">
                                                        {moment(y.TotalHours)
                                                          .format("HH:mm")
                                                          .toString() ===
                                                          "Invalid date"
                                                          ? "00:00:00"
                                                          : moment(y.TotalHours)
                                                            .format("HH:mm")
                                                            .toString()}
                                                      </td>
                                                      <td align="left">{y.Note}</td>
                                                      <td align="left">
                                                        {y.PaymentFlag === null
                                                          ? "No Payments Flag"
                                                          : y.PaymentFlag}
                                                      </td>
                                                      <td align="left">
                                                        {y.Payas === "NaN"
                                                          ? "No Payas"
                                                          : this.state.PayasList.map(
                                                            a => {
                                                              return a.Flag ===
                                                                y.Payas
                                                                ? a.Value
                                                                : "";
                                                            }
                                                          )}
                                                      </td>

                                                      <td align="left">
                                                        <Link
                                                          to="/ad_times_2"
                                                          className="adtimes_pullleft_edit"
                                                          data-toggle="modal"
                                                          data-target="#adtimes2_edit_pop"
                                                        ></Link>
                                                      </td>
                                                    </tr>
                                                  ) : (
                                                    ""
                                                  )
                                              )
                                              : ""}

                                            {this.state.empDetail.PRLTimes !==
                                              null ? (
                                                this.state.empDetail.PRLTimes.Times
                                                  .length > 0 ? (
                                                    this.state.empDetail.PRLTimes.Times[0].DailyTimes.map(
                                                      z =>
                                                        new Date(
                                                          z.Date * 1000
                                                        ).toLocaleDateString() ===
                                                          new Date(
                                                            x.Date * 1000
                                                          ).toLocaleDateString() &&
                                                          x.Day === z.Day ? (
                                                            <tr
                                                              style={{
                                                                display: this.state.orgflag
                                                                  ? ""
                                                                  : "none"
                                                              }}
                                                            >
                                                              <td align="left">&nbsp;</td>

                                                              <td align="left">
                                                                {new Date(
                                                                  z.Date * 1000
                                                                ).toLocaleDateString(
                                                                  "en-GB"
                                                                )}
                                                              </td>
                                                              <td align="left">{z.Day}</td>
                                                              <td align="left">
                                                                {z.Travel1}
                                                              </td>
                                                              <td align="left">
                                                                {z.Start}
                                                              </td>
                                                              <td align="left">
                                                                {z.TotalMBDed}
                                                              </td>
                                                              <td align="left">
                                                                {z.Finish}
                                                              </td>
                                                              <td align="left">
                                                                {z.Travel2}
                                                              </td>
                                                              <td align="left">
                                                                {z.TotalHours}
                                                              </td>
                                                              <td align="left">{z.Note}</td>
                                                              <td align="left">
                                                                No Payment Flag{" "}
                                                              </td>
                                                              <td align="left">
                                                                {this.state.empDetail.PayTimes.Company[0].Payas.map(
                                                                  y => (
                                                                    <React.Fragment>
                                                                      {y.Value === z.Payas
                                                                        ? y.Value
                                                                        : ""}
                                                                    </React.Fragment>
                                                                  )
                                                                )}
                                                              </td>
                                                            </tr>
                                                          ) : (
                                                            ""
                                                          )
                                                    )
                                                  ) : (
                                                    <tr>
                                                      {" "}
                                                      no original times related to this
                                                      time card uper
                                          </tr>
                                                  )
                                              ) : (
                                                ""
                                              )}
                                          </React.Fragment>
                                        ))
                                        : this.state.empDetail.PayTimes.sort(function (
                                          a,
                                          b
                                        ) {
                                          let nameA = new Date(
                                            a.adlistdate
                                          ).toLocaleDateString();
                                          let nameB = new Date(
                                            b.adlistdate
                                          ).toLocaleDateString();
                                          if (nameA > nameB) {
                                            return 1;
                                          }
                                          if (nameA < nameB) {
                                            return -1;
                                          }
                                          // names must be equal
                                          return 0;
                                        }).map((a, index) => (
                                          <React.Fragment>
                                            <tr
                                              style={{
                                                display: this.state.payflag
                                                  ? ""
                                                  : "none"
                                              }}
                                            >
                                              <td align="left">&nbsp;</td>

                                              <td align="left">
                                                {a.adlistdate
                                                  ? new Date(
                                                    a.adlistdate
                                                  ).toLocaleDateString("en-GB")
                                                  : new Date(
                                                    a.TotalHours
                                                  ).toLocaleDateString()}
                                              </td>
                                              <td align="left">
                                                {
                                                  days[
                                                  new Date(a.adlistdate).getDay()
                                                  ]
                                                }
                                              </td>
                                              <td align="left">{a.TravelTo}</td>
                                              <td align="left">{a.Start}</td>
                                              <td align="left">{a.meals}</td>
                                              <td align="left">{a.Finish}</td>
                                              <td align="left">{a.TravelFrom}</td>
                                              <td align="left">
                                                {moment(a.TotalHours)
                                                  .format("HH:mm")
                                                  .toString() === "Invalid date"
                                                  ? "00:00:00"
                                                  : moment(a.TotalHours)
                                                    .format("HH:mm")
                                                    .toString()}
                                              </td>
                                              <td align="left">{a.Note}</td>
                                              <td align="left">
                                                {a.PaymentFlag === null
                                                  ? "No Payments Flag"
                                                  : a.PaymentFlag}
                                              </td>
                                              <td align="left">
                                                {a.Payas === "NaN"
                                                  ? "No Payas"
                                                  : this.state.PayasList.map(x => {
                                                    return x.Flag === a.Payas
                                                      ? x.Value
                                                      : "";
                                                  })}
                                              </td>

                                              <td align="left">
                                                <a
                                                  href="javascript:void(0);"
                                                  className="adtimes_pullleft_edit cursorPointer"
                                                  data-toggle="modal"
                                                  data-target="#name_details_pencil"
                                                  onClick={this.edit_employeeData.bind(
                                                    this,
                                                    index,
                                                    a
                                                  )}
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                  >
                                                    <path
                                                      id="ic_create_24px"
                                                      className="cls-1"
                                                      d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z"
                                                      transform="translate(-3 -2.998)"
                                                    />
                                                  </svg>
                                                </a>
                                              </td>
                                            </tr>

                                            {this.state.empDetail.ADTimes.length >
                                              0 ? (
                                                this.state.empDetail.ADTimes.map(y =>
                                                  new Date(
                                                    y.adlistdate
                                                  ).toLocaleDateString() ===
                                                    new Date(
                                                      a.adlistdate
                                                    ).toLocaleDateString() ? (
                                                      <tr
                                                        style={{
                                                          display: this.state.adflag
                                                            ? ""
                                                            : "none"
                                                        }}
                                                      >
                                                        <td align="left">
                                                          <img
                                                            alt="timecardpic"
                                                            src={clock}
                                                          />
                                                        </td>
                                                        <td align="left">
                                                          {new Date(
                                                            y.adlistdate
                                                          ).toLocaleDateString("en-GB")}
                                                        </td>
                                                        <td align="left">
                                                          {
                                                            days[
                                                            new Date(
                                                              y.adlistdate
                                                            ).getDay()
                                                            ]
                                                          }
                                                        </td>
                                                        <td align="left">{y.TravelTo}</td>
                                                        <td align="left">{y.Start}</td>
                                                        <td align="left">{y.meals}</td>
                                                        <td align="left">{y.Finish}</td>
                                                        <td align="left">
                                                          {y.TravelFrom}
                                                        </td>
                                                        <td align="left">
                                                          {moment(y.TotalHours)
                                                            .format("HH:mm")
                                                            .toString() === "Invalid date"
                                                            ? "00:00:00"
                                                            : moment(y.TotalHours)
                                                              .format("HH:mm")
                                                              .toString()}
                                                        </td>
                                                        <td align="left">{y.Note}</td>
                                                        <td align="left">
                                                          {y.PaymentFlag === null
                                                            ? "No Payments Flag"
                                                            : y.PaymentFlag}
                                                        </td>
                                                        <td align="left">
                                                          {y.Payas === "NaN"
                                                            ? "No Payas"
                                                            : this.state.PayasList.map(
                                                              x => {
                                                                return x.Flag ===
                                                                  y.Payas
                                                                  ? x.Value
                                                                  : "";
                                                              }
                                                            )}
                                                        </td>

                                                        <td align="left">
                                                          <Link
                                                            to="/ad_times_2"
                                                            className="adtimes_pullleft_edit"
                                                            data-toggle="modal"
                                                            data-target="#adtimes2_edit_pop"
                                                          ></Link>
                                                        </td>
                                                      </tr>
                                                    ) : (
                                                      ""
                                                    )
                                                )
                                              ) : (
                                                <tr>
                                                  no ad time related to this timecard
                                        </tr>
                                              )}
                                          </React.Fragment>
                                        ))
                                      : "No Paytime Found"}
                                  </tbody>
                                </table>
                                <a
                                  href="javascript:void(0);"
                                  className="btn_save_analytics cursorPointer"
                                  data-dismiss="modal"
                                  onClick={this.setStdHours}
                                >
                                  Save as STD Hours
                        </a>
                              </div>
                            </div>
                            <div className="clear10" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="name_details_pencil" className="modal fade" role="dialog">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header adtime_pop_ad">
                            <button
                              type="button"
                              className="close close_modal_adtimes"
                              data-dismiss="modal"
                              onClick={this.issue_ScrollHandler}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                              >
                                <path
                                  id="ic_clear_24px"
                                  d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                                  transform="translate(-5 -5)"
                                  fill="#7B7B7B"
                                />
                              </svg>
                            </button>
                            {this.state.empDetail != "" ? (
                              <h4 className="modal-title">
                                {this.state.empDetail.Employee[0].FirstName}{" "}
                                {this.state.empDetail.Employee[0].LastName},{" "}
                                {this.state.edit_paytimes_department},{" "}
                                {this.state.edit_paytimes_position},{" "}
                                {finalDay +
                                  " " +
                                  finalDate +
                                  hexaa +
                                  " of " +
                                  finalMonth +
                                  "  " +
                                  finalYear +
                                  ""}
                              </h4>
                            ) : null}
                          </div>
                          {/*   */}
                          <div className="modal-body">
                            <div className="col-sm-12 p0">
                              <div className="panel-group d-accordion">
                                <div className="panel panel-default">
                                  <div
                                    className="panel-heading panel_heading_bg"
                                    data-toggle="collapse"
                                    data-parent=".d-accordion"
                                    href="#aboutus"
                                  >
                                    <h4 className="panel-title">
                                      Timecard{" "}
                                      <i className="fa fa-chevron-up pull-right" />
                                    </h4>
                                  </div>
                                  <div
                                    id="aboutus"
                                    className="panel-collapse collapse in"
                                  >
                                    <div className="panel-body">
                                      <table
                                        width="100%"
                                        className="table tbl_pencil_info"
                                      >
                                        <thead>
                                          <tr className="">
                                            <th width="20%" className="text-left">
                                              &nbsp;
                                    </th>
                                            <th width="20%" className="text-left">
                                              Original
                                    </th>
                                            <th width="20%" className="text-left">
                                              AD
                                    </th>
                                            <th width="20%" className="text-left">
                                              Pay
                                    </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>Pay As</td>
                                            <td>
                                              {this.state.empDetail.PRLTimes &&
                                                this.state.empDetail.PRLTimes.Company &&
                                                this.state.empDetail.PRLTimes.Company[0]
                                                  .Payas &&
                                                this.state.empDetail.PRLTimes.Company[0]
                                                  .Payas[this.state.prlObject.Payas] &&
                                                this.state.empDetail.PRLTimes.Company[0]
                                                  .Payas[this.state.prlObject.Payas]
                                                  .Value}
                                            </td>
                                            <td>
                                              {this.state.adTimesPayas
                                                ? this.state.adTimesPayas
                                                : "-"}
                                            </td>
                                            <td>
                                              <select
                                                className="form-control"
                                                id="timeCards_editPayAs"
                                                name="timeCards_editPayAs"
                                                value={this.state.edit_paytimes_payas}
                                                onChange={this.editPaytimesPayasHandler}
                                              >
                                                <option value={"NaN"}>
                                                  No Payas Selected
                                        </option>
                                                {this.state.empDetail.PayTimes
                                                  ? this.state.empDetail.PayTimes
                                                    .Company
                                                    ? this.state.empDetail.PayTimes.Company[0].Payas.map(
                                                      function (item) {
                                                        return (
                                                          <option value={item.Value}>
                                                            {item.Value}
                                                          </option>
                                                        );
                                                      }
                                                    )
                                                    : this.state.PayasList
                                                      ? this.state.PayasList.map(function (
                                                        item
                                                      ) {
                                                        return (
                                                          <option value={item.Flag}>
                                                            {item.Value}
                                                          </option>
                                                        );
                                                      })
                                                      : ""
                                                  : "NA"}
                                              </select>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Travel to</td>

                                            <td>{this.state.prlObject.Travel1}</td>
                                            <td>
                                              {this.state.adTimesObject &&
                                                this.state.adTimesObject.TravelTo
                                                ? this.state.adTimesObject.TravelTo
                                                : "-"}
                                            </td>
                                            <td>
                                              <TimePicker
                                                showSecond={false}
                                                value={moment(
                                                  this.state.edit_paytimes_travelto,
                                                  "HH:mm"
                                                )}
                                                name="edit_paytimes_travelto"
                                                onChange={this.timescard_times_inputHandler.bind(
                                                  this,
                                                  "edit_paytimes_travelto"
                                                )}
                                                placeholder="12:00"
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Start Work</td>
                                            <td>{this.state.prlObject.Start}</td>
                                            <td>
                                              {this.state.adTimesObject &&
                                                this.state.adTimesObject.Start
                                                ? this.state.adTimesObject.Start
                                                : "-"}
                                            </td>
                                            <td>
                                              <TimePicker
                                                showSecond={false}
                                                value={moment(
                                                  this.state.edit_paytimes_start,
                                                  "HH:mm"
                                                )}
                                                name="start_work"
                                                onChange={this.timescard_times_inputHandler.bind(
                                                  this,
                                                  "edit_paytimes_start"
                                                )}
                                                placeholder="12:00"
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Meal Break</td>
                                            <td>{this.state.prlObject.TotalMBDed}</td>
                                            <td>
                                              {this.state.adTimesObject &&
                                                this.state.adTimesObject.meals
                                                ? this.state.adTimesObject.meals
                                                : "-"}
                                            </td>
                                            <td className="d-flex">
                                              <TimePicker
                                                showSecond={false}
                                                value={moment(
                                                  this.state.edit_paytimes_total_meal,
                                                  "HH:mm"
                                                )}
                                                name="mealbreak"
                                                disabled={
                                                  this.state.disableMealbreak
                                                    ? true
                                                    : false
                                                }
                                                onChange={this.timescard_times_inputHandler.bind(
                                                  this,
                                                  "edit_paytimes_total_meal"
                                                )}
                                                placeholder="12:00"
                                              />
                                              <button
                                                data-toggle="collapse"
                                                data-target="#meal_content_collapse"
                                                className="btn"
                                                type="button"
                                                id="btn_cllps"
                                                onClick={this.handleModal_Height}
                                              >
                                                <i
                                                  className="fa fa-angle-down"
                                                  aria-expanded="true"
                                                />
                                              </button>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <div
                                        className="meal_collapse collapse"
                                        id="meal_content_collapse"
                                      >
                                        <table
                                          width="100%"
                                          className="table tbl_pencil_info_collapse"
                                        >
                                          <thead>
                                            <tr className="">
                                              <th width="20%" className="text-left">
                                                &nbsp;{" "}
                                              </th>
                                              <th width="20%" className="text-left">
                                                &nbsp;
                                      </th>
                                              <th width="20%" className="text-left">
                                                &nbsp;
                                      </th>
                                              <th width="20%" className="text-left">
                                                &nbsp;{" "}
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>Start MB1</td>
                                              <td>{this.state.prlObject.StartMeal}</td>
                                              <td>
                                                {this.state.adTimesObject &&
                                                  this.state.adTimesObject.mealBreak
                                                  ? this.state.adTimesObject
                                                    .mealBreak[0].MB1Start
                                                  : "-"}
                                              </td>
                                              <td>
                                                <TimePicker
                                                  showSecond={false}
                                                  value={moment(
                                                    this.state.timeCards_editStartMB1,
                                                    "HH:mm"
                                                  )}
                                                  name="mb1"
                                                  onChange={this.timescard_times_inputHandler.bind(
                                                    this,
                                                    "timeCards_editStartMB1"
                                                  )}
                                                  placeholder="12:00"
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Finish MB1</td>
                                              <td>
                                                {this.state.prlObject.FinishMeal1}
                                              </td>
                                              <td>
                                                {this.state.adTimesObject &&
                                                  this.state.adTimesObject.mealBreak
                                                  ? this.state.adTimesObject
                                                    .mealBreak[0].MB1Finish
                                                  : "-"}
                                              </td>
                                              <td>
                                                <TimePicker
                                                  showSecond={false}
                                                  value={moment(
                                                    this.state.timeCards_editFinishMB1,
                                                    "HH:mm"
                                                  )}
                                                  name="fmb1"
                                                  onChange={this.timescard_times_inputHandler.bind(
                                                    this,
                                                    "timeCards_editFinishMB1"
                                                  )}
                                                  placeholder="12:00"
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Start MB2</td>
                                              <td>{this.state.prlObject.StartMeal2}</td>
                                              <td>
                                                {this.state.adTimesObject &&
                                                  this.state.adTimesObject.mealBreak
                                                  ? this.state.adTimesObject
                                                    .mealBreak[1].MB2Start
                                                  : "-"}
                                              </td>
                                              <td>
                                                <TimePicker
                                                  showSecond={false}
                                                  value={moment(
                                                    this.state.timeCards_editStartMB2,
                                                    "HH:mm"
                                                  )}
                                                  name="mb2"
                                                  onChange={this.timescard_times_inputHandler.bind(
                                                    this,
                                                    "timeCards_editStartMB2"
                                                  )}
                                                  placeholder="12:00"
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Finish MB2</td>
                                              <td>
                                                {this.state.prlObject.FinishMeal2}
                                              </td>
                                              <td>
                                                {this.state.adTimesObject &&
                                                  this.state.adTimesObject.mealBreak
                                                  ? this.state.adTimesObject
                                                    .mealBreak[1].MB2Finish
                                                  : "-"}
                                              </td>
                                              <td>
                                                <TimePicker
                                                  showSecond={false}
                                                  value={moment(
                                                    this.state.timeCards_editFinishMB2,
                                                    "HH:mm"
                                                  )}
                                                  name="fmb2"
                                                  onChange={this.timescard_times_inputHandler.bind(
                                                    this,
                                                    "timeCards_editFinishMB2"
                                                  )}
                                                  placeholder="12:00"
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Start MB3</td>
                                              <td>{this.state.prlObject.StartMeal3}</td>
                                              <td>
                                                {this.state.adTimesObject &&
                                                  this.state.adTimesObject.mealBreak
                                                  ? this.state.adTimesObject
                                                    .mealBreak[2].MB3Start
                                                  : "-"}
                                              </td>
                                              <td>
                                                <TimePicker
                                                  showSecond={false}
                                                  value={moment(
                                                    this.state.timeCards_editStartMB3,
                                                    "HH:mm"
                                                  )}
                                                  name="mb3"
                                                  onChange={this.timescard_times_inputHandler.bind(
                                                    this,
                                                    "timeCards_editStartMB3"
                                                  )}
                                                  placeholder="12:00"
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Finish MB3</td>
                                              <td>
                                                {this.state.prlObject.FinishMeal3}
                                              </td>
                                              <td>
                                                {this.state.adTimesObject &&
                                                  this.state.adTimesObject.mealBreak
                                                  ? this.state.adTimesObject
                                                    .mealBreak[2].MB3Finish
                                                  : "-"}
                                              </td>
                                              <td>
                                                <TimePicker
                                                  showSecond={false}
                                                  value={moment(
                                                    this.state.timeCards_editFinishMB3,
                                                    "HH:mm"
                                                  )}
                                                  name="fmb3"
                                                  onChange={this.timescard_times_inputHandler.bind(
                                                    this,
                                                    "timeCards_editFinishMB3"
                                                  )}
                                                  placeholder="12:00"
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>

                                        <div className="clear20" />
                                        <table
                                          width="100%"
                                          className="table tbl_pencil_info_collapse"
                                        >
                                          <thead>
                                            <tr className="">
                                              <th width="20%" className="text-left">
                                                &nbsp;{" "}
                                              </th>
                                              <th width="20%" className="text-left">
                                                &nbsp;
                                      </th>
                                              <th width="20%" className="text-left">
                                                &nbsp;
                                      </th>
                                              <th width="20%" className="text-left">
                                                &nbsp;{" "}
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>Non Deductible MB1:</td>
                                              <td>
                                                <input
                                                  type="checkbox"
                                                  id="originalCheckMB1"
                                                  name="originalCheckMB1"
                                                  checked={
                                                    this.state
                                                      .timeCards_NonDeductibleMB1
                                                      .originalCheckMB1
                                                  }
                                                  onChange={
                                                    this.timeCards_checkBoxHandler
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type="checkbox"
                                                  id="adCheckMB1"
                                                  name="adCheckMB1"
                                                  checked={
                                                    this.state
                                                      .timeCards_NonDeductibleMB1
                                                      .adCheckMB1
                                                  }
                                                  onChange={
                                                    this.timeCards_checkBoxHandler
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type="checkbox"
                                                  id="payCheckMB1"
                                                  name="payCheckMB1"
                                                  checked={
                                                    this.state
                                                      .timeCards_NonDeductibleMB1
                                                      .payCheckMB1
                                                  }
                                                  onChange={
                                                    this.timeCards_checkBoxHandler
                                                  }
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Non Deductible MB2:</td>
                                              <td>
                                                <input
                                                  type="checkbox"
                                                  id="originalCheckMB2"
                                                  name="originalCheckMB2"
                                                  checked={
                                                    this.state
                                                      .timeCards_NonDeductibleMB2
                                                      .originalCheckMB2
                                                  }
                                                  onChange={
                                                    this.timeCards_checkBoxHandler
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type="checkbox"
                                                  id="adCheckMB2"
                                                  name="adCheckMB2"
                                                  checked={
                                                    this.state
                                                      .timeCards_NonDeductibleMB2
                                                      .adCheckMB2
                                                  }
                                                  onChange={
                                                    this.timeCards_checkBoxHandler
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type="checkbox"
                                                  id="payCheckMB2"
                                                  name="payCheckMB2"
                                                  checked={
                                                    this.state
                                                      .timeCards_NonDeductibleMB2
                                                      .payCheckMB2
                                                  }
                                                  onChange={
                                                    this.timeCards_checkBoxHandler
                                                  }
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Non Deductible MB3:</td>
                                              <td>
                                                <input
                                                  type="checkbox"
                                                  id="originalCheckMB3"
                                                  name="originalCheckMB3"
                                                  checked={
                                                    this.state
                                                      .timeCards_NonDeductibleMB3
                                                      .originalCheckMB3
                                                  }
                                                  onChange={
                                                    this.timeCards_checkBoxHandler
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type="checkbox"
                                                  id="adCheckMB3"
                                                  name="adCheckMB3"
                                                  checked={
                                                    this.state
                                                      .timeCards_NonDeductibleMB3
                                                      .adCheckMB3
                                                  }
                                                  onChange={
                                                    this.timeCards_checkBoxHandler
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type="checkbox"
                                                  id="payCheckMB3"
                                                  name="payCheckMB3"
                                                  checked={
                                                    this.state
                                                      .timeCards_NonDeductibleMB3
                                                      .payCheckMB3
                                                  }
                                                  onChange={
                                                    this.timeCards_checkBoxHandler
                                                  }
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>

                                      <table
                                        width="100%"
                                        className="table tbl_pencil_info_collapse"
                                      >
                                        <thead>
                                          <tr className="">
                                            <th width="20%" className="text-left">
                                              &nbsp;{" "}
                                            </th>
                                            <th width="20%" className="text-left">
                                              &nbsp;
                                    </th>
                                            <th width="20%" className="text-left">
                                              &nbsp;
                                    </th>
                                            <th width="20%" className="text-left">
                                              &nbsp;{" "}
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>Finish Work</td>
                                            <td>{this.state.prlObject.Finish}</td>
                                            <td>
                                              {this.state.adTimesObject &&
                                                this.state.adTimesObject.Finish
                                                ? this.state.adTimesObject.Finish
                                                : "-"}
                                            </td>
                                            <td>
                                              <TimePicker
                                                showSecond={false}
                                                value={moment(
                                                  this.state.edit_paytimes_finish,
                                                  "HH:mm"
                                                )}
                                                name="finish_work"
                                                onChange={this.timescard_times_inputHandler.bind(
                                                  this,
                                                  "edit_paytimes_finish"
                                                )}
                                                placeholder="12:00"
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Travel from</td>
                                            <td>{this.state.prlObject.Travel2}</td>
                                            <td>
                                              {this.state.adTimesObject &&
                                                this.state.adTimesObject.TravelFrom
                                                ? this.state.adTimesObject.TravelFrom
                                                : "-"}
                                            </td>
                                            <td>
                                              <TimePicker
                                                showSecond={false}
                                                value={moment(
                                                  this.state.edit_paytimes_travelfrom,
                                                  "HH:mm"
                                                )}
                                                name="edit_paytimes_travelfrom"
                                                onChange={this.timescard_times_inputHandler.bind(
                                                  this,
                                                  "edit_paytimes_travelfrom"
                                                )}
                                                placeholder="12:00"
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Total Hours</td>
                                            <td>{this.state.prlObject.TotalHours}</td>
                                            <td>
                                              {this.state.adTimesObject
                                                ? moment(
                                                  this.state.adTimesObject.TotalHours
                                                )
                                                  .format("HH:mm")
                                                  .toString() === "Invalid date"
                                                  ? "00:00:00"
                                                  : moment(
                                                    this.state.adTimesObject
                                                      .TotalHours
                                                  )
                                                    .format("HH:mm")
                                                    .toString()
                                                : "-"}
                                            </td>

                                            <td>
                                              <TimePicker
                                                showSecond={false}
                                                value={moment(
                                                  this.state.edit_paytimes_totalhours,
                                                  "HH:mm"
                                                )}
                                                name="edit_paytimes_totalhours"
                                                onChange={this.timescard_times_inputHandler.bind(
                                                  this,
                                                  "edit_paytimes_totalhours"
                                                )}
                                                placeholder="12:00"
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Rate</td>
                                            <td>
                                              {this.state.prlObject &&
                                                this.state.prlObject.HourlyRate
                                                ? this.state.prlObject.HourlyRate
                                                : "-"}
                                            </td>
                                            <td>
                                              {this.state.adTimesObject &&
                                                this.state.adTimesObject.HourlyRate
                                                ? this.state.adTimesObject.HourlyRate
                                                : "-"}
                                            </td>
                                            <td>
                                              <input
                                                id="timeCards_editRate"
                                                name="timeCards_editRate"
                                                value={this.state.timeCards_editRate}
                                                onChange={this.timeCards_inputHandler}
                                                className="form-control"
                                                type="number"
                                                step="0.01"
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Notes</td>
                                            <td>
                                              {this.state.prlObject.Note !== null &&
                                                this.state.prlObject.Note
                                                ? this.state.prlObject.Note
                                                : "-"}
                                            </td>
                                            <td>
                                              {this.state.adTimesObject.Note !== null &&
                                                this.state.adTimesObject.Note
                                                ? this.state.adTimesObject.Note
                                                : "-"}
                                            </td>
                                            <td>
                                              <input
                                                id="timeCards_editNotes"
                                                name="timeCards_editNotes"
                                                value={this.state.timeCards_editNotes}
                                                onChange={this.timeCards_inputHandler}
                                                className="form-control"
                                                type="text"
                                              />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <div className="col-sm-12 p0 template_form">
                                        <div className="col-sm-3 p0">
                                          <label>Template</label>
                                        </div>
                                        <div className="col-sm-3 p0">
                                          {/* <select
                                            className="form-control"
                                            id="timeCards_editTemplate"
                                            name="timeCards_editTemplate"
                                            onChange={this.timeCards_inputHandler}
                                          >
                                            <option value="1">MEAA40</option>
                                            <option value="2">MEAA41</option>
                                            <option value="3">MEAA42</option>
                                            <option value="4">MEAA43</option>
                                          </select> */}
                                          <span className="form-control">
                                            {this.state.empDetail &&
                                              this.state.empDetail.Employee &&
                                              this.state.empDetail.Employee[0]
                                                .TemplateCode &&
                                              this.state.empDetail.Employee[0]
                                                .TemplateCode
                                              ? this.state.empDetail.Employee[0]
                                                .TemplateCode
                                              : "-"}
                                          </span>
                                        </div>
                                        <div className="clear10" />
                                        <div className="col-sm-3 p0">
                                          <label>Payment Flag</label>
                                        </div>
                                        <div className="col-sm-9 p0">
                                          <input
                                            id="timeCards_editPaymentFlag"
                                            name="timeCards_editPaymentFlag"
                                            value={this.state.timeCards_editPaymentFlag}
                                            onChange={this.timeCards_inputHandler}
                                            className="form-control"
                                            placeholder=""
                                            type="text"
                                          />
                                        </div>
                                        <div className="clear10" />
                                        <div className="col-sm-3 p0">
                                          <label>Internal Note</label>
                                        </div>
                                        <div className="col-sm-9 p0">
                                          <input
                                            id="timeCards_editInternalNote"
                                            name="timeCards_editInternalNote"
                                            value={
                                              this.state.timeCards_editInternalNote
                                            }
                                            onChange={this.timeCards_inputHandler}
                                            className="form-control"
                                            type="text"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="panel panel-default">
                                  <div
                                    className="panel-heading panel_heading_bg"
                                    data-toggle="collapse"
                                    data-parent=".d-accordion"
                                    href="#whoweare"
                                  >
                                    <h4 className="panel-title">
                                      Coding and Tracking{" "}
                                      <i className="fa fa-chevron-up pull-right" />
                                    </h4>
                                  </div>
                                  <div
                                    id="whoweare"
                                    className="panel-collapse collapse"
                                  >
                                    <div className="panel-body">
                                      <div className="col-sm-12 p0 template_form">
                                        <div className="col-sm-3 p0">
                                          <label>Chart Sort</label>
                                        </div>
                                        <div className="col-sm-3 p0">
                                          <input
                                            id="timeCards_editChartSort"
                                            name="timeCards_editChartSort"
                                            value={this.state.timeCards_editChartSort}
                                            onChange={this.timeCards_inputHandler}
                                            className="form-control"
                                            placeholder="AU01000"
                                            type="text"
                                          />
                                        </div>
                                        <div className="clear10" />
                                        <div className="col-sm-3 p0">
                                          <label>Chart Code</label>
                                        </div>
                                        <div className="col-sm-3 p0">
                                          <input
                                            id="timeCards_editChartCode"
                                            name="timeCards_editChartCode"
                                            value={this.state.timeCards_editChartCode}
                                            onChange={this.timeCards_inputHandler}
                                            className="form-control"
                                            placeholder="C02A"
                                            type="text"
                                          />
                                        </div>
                                        <div className="clear10" />
                                        <div className="col-sm-3 p0">
                                          <label>Work State</label>
                                        </div>
                                        <div className="col-sm-3 p0">
                                          <input
                                            id="timeCards_editWorkState"
                                            name="timeCards_editWorkState"
                                            value={this.state.timeCards_editWorkState}
                                            onChange={this.timeCards_inputHandler}
                                            className="form-control"
                                            placeholder="VIC"
                                            type="text"
                                          />
                                        </div>
                                        <div className="clear10" />
                                        {this.state.selectedobj.TrackingCodes
                                          ? this.state.selectedobj.TrackingCodes.map(
                                            value => {
                                              if (value.type === "Numeric") {
                                                return (
                                                  <div>
                                                    <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime">
                                                      {value.Prompt}
                                                    </div>
                                                    <div className="col-xs-8 col-sm-8 col-md-9">
                                                      <input
                                                        name={value.uuid}
                                                        type="number"
                                                        value={
                                                          value.defaultValue !== null
                                                            ? value.defaultValue
                                                            : ""
                                                        }
                                                        placeholder={
                                                          value.defaultValue !== null
                                                            ? value.defaultValue
                                                            : ""
                                                        }
                                                        onChange={
                                                          this.trackinghandler
                                                        }
                                                      />
                                                    </div>
                                                    <div className="clearfix"></div>
                                                  </div>
                                                );
                                              }

                                              if (value.type === "String") {
                                                return (
                                                  <div>
                                                    <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime">
                                                      {value.Prompt}
                                                    </div>
                                                    <div className="col-xs-8 col-sm-8 col-md-9">
                                                      <input
                                                        name={value.uuid}
                                                        type="text"
                                                        placeholder={
                                                          value.defaultValue !== null
                                                            ? value.defaultValue
                                                            : ""
                                                        }
                                                        value={
                                                          value.defaultValue !== null
                                                            ? value.defaultValue
                                                            : ""
                                                        }
                                                        onChange={
                                                          this.trackinghandler
                                                        }
                                                      />
                                                    </div>
                                                    <div className="clearfix"></div>
                                                  </div>
                                                );
                                              }

                                              if (value.type === "Date") {
                                                return (
                                                  <div>
                                                    <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime">
                                                      {value.Prompt}
                                                    </div>
                                                    <div className="col-xs-8 col-sm-8 col-md-9">
                                                      <DatePicker
                                                        name={value.uuid}
                                                        dateFormat="dd/MM/yyyy"
                                                        selected={value.defaultValue}
                                                        onChange={this.trackinghandlerdate.bind(
                                                          this,
                                                          value.uuid
                                                        )}
                                                      />
                                                    </div>
                                                    <div className="clearfix"></div>
                                                  </div>
                                                );
                                              }
                                              if (value.type === "DropList") {
                                                return (
                                                  <div>
                                                    <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime">
                                                      {value.Prompt}
                                                    </div>
                                                    <div className="col-xs-8 col-sm-8 col-md-9">
                                                      <select
                                                        onChange={
                                                          this.trackinghandler
                                                        }
                                                        value={
                                                          value.defaultValue !== null
                                                            ? value.defaultValue
                                                            : ""
                                                        }
                                                        name={value.uuid}
                                                      >
                                                        {value.optionsValues.map(
                                                          opt => {
                                                            return (
                                                              <option
                                                                value={opt.value}
                                                                key={opt.key}
                                                              >
                                                                {opt.option}
                                                              </option>
                                                            );
                                                          }
                                                        )}
                                                      </select>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                  </div>
                                                );
                                              }
                                            }
                                          )
                                          : this.state.empDetail.PayTimes
                                            ? this.state.empDetail.PayTimes.Times
                                              ? this.state.empDetail.PayTimes.Times[0].CustomTimeFields.map(
                                                (value, index) => {
                                                  if (value.Type === "Text") {
                                                    return (
                                                      <div>
                                                        <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime">
                                                          {value.Prompt}
                                                        </div>
                                                        <div className="col-xs-8 col-sm-8 col-md-9">
                                                          <input
                                                            name={index}
                                                            type="text"
                                                            placeholder={
                                                              value.defaultValue !==
                                                                null
                                                                ? value.defaultValue
                                                                : "enter"
                                                            }
                                                            value={
                                                              value.defaultValue !==
                                                                null
                                                                ? value.defaultValue
                                                                : ""
                                                            }
                                                            onChange={
                                                              this.customtrackinghandler
                                                            }
                                                          />
                                                        </div>
                                                        <div className="clearfix"></div>
                                                      </div>
                                                    );
                                                  }
                                                  if (value.Type === "Number") {
                                                    return (
                                                      <div>
                                                        <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime">
                                                          {value.Prompt}
                                                        </div>
                                                        <div className="col-xs-8 col-sm-8 col-md-9">
                                                          <input
                                                            name={index}
                                                            type="number"
                                                            value={
                                                              value.defaultValue !==
                                                                null
                                                                ? value.defaultValue
                                                                : ""
                                                            }
                                                            placeholder={
                                                              value.defaultValue !==
                                                                null
                                                                ? value.defaultValue
                                                                : ""
                                                            }
                                                            onChange={
                                                              this.customtrackinghandler
                                                            }
                                                          />
                                                        </div>
                                                        <div className="clearfix"></div>
                                                      </div>
                                                    );
                                                  }
                                                  if (value.Type === "Date") {
                                                    return (
                                                      <div>
                                                        <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime">
                                                          {value.Prompt}
                                                        </div>
                                                        <div className="col-xs-8 col-sm-8 col-md-9">
                                                          <DatePicker
                                                            name={index}
                                                            dateFormat="dd/MM/yyyy"
                                                            selected={
                                                              value.defaultValue
                                                            }
                                                            onChange={this.customtrackinghandlerdate.bind(
                                                              this,
                                                              index
                                                            )}
                                                          />
                                                        </div>
                                                        <div className="clearfix"></div>
                                                      </div>
                                                    );
                                                  }
                                                  if (value.Type == "DropList") {
                                                    var st = value.defaultValue.split(
                                                      "|"
                                                    );

                                                    return (
                                                      <div>
                                                        <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime">
                                                          {value.Prompt}
                                                        </div>
                                                        <div className="col-xs-8 col-sm-8 col-md-9">
                                                          <select
                                                            onChange={this.customtrackingSelecthandler.bind(
                                                              this
                                                            )}
                                                            value={
                                                              value.Value !== null
                                                                ? value.Value
                                                                : ""
                                                            }
                                                            name={index}
                                                          >
                                                            {st.map(opt => {
                                                              return (
                                                                <option
                                                                  value={opt}
                                                                  key={opt.key}
                                                                >
                                                                  {opt}
                                                                </option>
                                                              );
                                                            })}
                                                          </select>
                                                        </div>
                                                        <div className="clearfix"></div>
                                                      </div>
                                                    );
                                                  }
                                                }
                                              )
                                              : ""
                                            : ""}

                                        <div className="clear10" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="analytics_btn_save">
                                  <a
                                    href="javascript:void(0);"
                                    className="btn_save_analytics cursorPointer"
                                    data-dismiss="modal"
                                    onClick={
                                      this.state.flagcheck
                                        ? this.onSaveEditHandler
                                        : undefined
                                    }
                                  >
                                    Save
                          </a>
                                  <a
                                    href="javascript:void(0);"
                                    className="btn_cancel_analytics cursorPointer"
                                    data-dismiss="modal"
                                    onClick={this.issue_ScrollHandler}
                                  >
                                    Cancel
                          </a>
                                </div>
                              </div>
                            </div>
                            <div className="clear10" />
                          </div>
                          <div className="clearfix" />
                        </div>
                        <div className="clearfix" />
                      </div>
                      <div className="clearfix" />
                    </div>

                    <div id="user_document" className="modal fade" role="dialog">
                      <div className="modal-dialog">
                        <div className="modal-content timecard_comennt_modal">
                          <div className="modal-header adtime_pop_ad">
                            <button
                              type="button"
                              className="close close_modal_adtimes"
                              data-dismiss="modal"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                              >
                                <path
                                  id="ic_clear_24px"
                                  d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                                  transform="translate(-5 -5)"
                                  fill="#7B7B7B"
                                />
                              </svg>
                            </button>
                            <h4 className="modal-title">Comment</h4>
                          </div>
                          <div className="modal-body">
                            <div className="user_document">
                              {this.state.comments.map((d, i) => {
                                return (
                                  <div className="col-sm-12">
                                    <div className="user_document_inner">
                                      <div className="col-sm-6 p0">
                                        <label>{d.name}</label>
                                        <p>{d.comment}</p>
                                      </div>
                                      <div className="col-sm-6 p0 text-right">
                                        <p>{d.date}</p>
                                      </div>
                                    </div>
                                    <div className="clearfix" />
                                  </div>
                                );
                              })}
                              <div className="clearfix" />
                            </div>
                            <div className="clear10" />
                            <div className="comment_area">
                              <label>Comment</label>
                              <textarea
                                className="form-control"
                                rows="5"
                                id="timeCards_comment"
                                name="timeCards_comment"
                                value={this.state.timeCards_comment}
                                onChange={this.timeCards_inputHandler}
                              />
                            </div>
                            <div className="comment_btn_area">
                              <a
                                href="javascript:void(0);"
                                className="btn_save_analytics pull-left cursorPointer"
                                data-dismiss="modal"
                                onClick={this.addComment}
                              >
                                Add Comment
                      </a>
                            </div>

                            <div className="dropdown comment_btn_area pull-right">
                              <button
                                className="btn btn-primary btn_save_analytics dropdown-toggle "
                                type="button"
                                data-toggle="dropdown"
                              >
                                Add Message
                        <span className="caret" />
                              </button>
                              <ul className="dropdown-menu down">
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="cursorPointer"
                                    data-toggle="modal"
                                    title="attach"
                                    data-target="#add_timecard"
                                    onClick={this.onClickAddMessage}
                                  >
                                    Attach Timecard
                          </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="cursorPointer"
                                    data-toggle="modal"
                                    title="simple"
                                    data-target="#add_timecard"
                                    onClick={this.onClickAddMessage}
                                  >
                                    Send Message
                          </a>
                                </li>
                              </ul>
                            </div>

                            <div className="clearfix" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="activity_popup_2" className="modal fade" role="dialog">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header adtime_pop_ad">
                            <button
                              type="button"
                              className="close close_modal_adtimes"
                              data-dismiss="modal"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                              >
                                <path
                                  id="ic_clear_24px"
                                  d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                                  transform="translate(-5 -5)"
                                  fill="#7B7B7B"
                                />
                              </svg>
                            </button>
                            <h4 className="modal-title">Activity</h4>
                          </div>
                          <div className="modal-body">
                            <div className="user_document">
                              {/* <div className="col-sm-12">
                        <div className="user_document_inner">
                          <div className="col-sm-7 p0">
                            <label>
                              Ian Gracie changed start time on monday from
                              07:00am to 07:30 am{" "}
                            </label>
                          </div>
                          <div className="col-sm-5 p0 text-right">
                            <p>5/07/2018 1:10pm</p>
                          </div>
                        </div>
                        <div className="clearfix" />
                      </div> */}

                              {this.state.activities.map((a, i) => {
                                return (
                                  <div className="col-sm-12">
                                    <div className="user_document_inner">
                                      <div className="col-sm-7 p0">
                                        <label>{a.msg} </label>
                                      </div>
                                      <div className="col-sm-5 p0 text-right">
                                        <p>{a.date}</p>
                                      </div>
                                    </div>
                                    <div className="clearfix" />
                                  </div>
                                );
                              })}
                              {this.state.activities.length == 0 && (
                                <div className="user_document_inner">
                                  <div className="col-sm-7 p0">
                                    <label>{"No Activities Found"}</label>
                                  </div>
                                </div>
                              )}
                              <div className="clearfix" />
                            </div>

                            <div className="clearfix" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="add_timecard" className="modal fade" role="dialog">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              id="close_msg"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                              >
                                <path
                                  id="ic_clear_24px"
                                  d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                                  transform="translate(-5 -5)"
                                  fill="#7B7B7B"
                                />
                              </svg>
                            </button>
                            <h4 className="modal-title modal_header_titile_new">
                              New Message
                    </h4>
                          </div>
                          <div className="modal-body">
                            <div className="col-sm-12">
                              <select
                                className="form-control"
                                id="timeCards_NMselectSendTo"
                                name="timeCards_NMselectSendTo"
                                value={this.state.timeCards_NMselectSendToValue}
                                onChange={this.newMessage_selectHandler}
                              >
                                <option>Send To</option>
                                {this.state.approver_object &&
                                  this.state.approver_object.map(item => (
                                    <option>{item.approver_name}</option>
                                  ))}
                              </select>
                              <p
                                className={
                                  "error validation " +
                                  (this.state.newMessageErrors
                                    .timeCards_NMselectSendToErr
                                    ? ""
                                    : "displayNone")
                                }
                              >
                                {
                                  this.state.newMessageErrors
                                    .timeCards_NMselectSendToErr
                                }
                              </p>
                            </div>
                            <div className="clear5" />
                            <div className="col-sm-12 timecard_subject">
                              <input
                                type="text"
                                className="form-control"
                                id="timeCards_NMsubject"
                                value={this.state.timeCards_NMsubject}
                                name="timeCards_NMsubject"
                                onChange={this.newMessage_inputHandler}
                                placeholder="Subject"
                              />
                              <p
                                className={
                                  "error validation " +
                                  (this.state.newMessageErrors.timeCards_NMsubjectErr
                                    ? ""
                                    : "displayNone")
                                }
                              >
                                {this.state.newMessageErrors.timeCards_NMsubjectErr}
                              </p>
                            </div>
                            <div className="clear5" />
                            <div className="col-sm-12">
                              <textarea
                                className="add_timecar_textarea form-control"
                                id="timeCards_NMmessage"
                                name="timeCards_NMmessage"
                                value={this.state.timeCards_NMmessage}
                                onChange={this.newMessage_inputHandler}
                                placeholder="Message"
                              />
                              <p
                                className={
                                  "error validation " +
                                  (this.state.newMessageErrors.timeCards_NMmessageErr
                                    ? ""
                                    : "displayNone")
                                }
                              >
                                {this.state.newMessageErrors.timeCards_NMmessageErr}
                              </p>
                            </div>
                            <div className="clear5"> </div>

                            {this.state.simplemessage === true ? (
                              ""
                            ) : (
                                <>
                                  <div className="tab-content tab-content_timecard">
                                    <div className="tab-pane" id="tab_default_1_timecard">
                                      <p />
                                      <p>
                                        <img src={sign1} alt="" />{" "}
                                      </p>
                                      <p />
                                    </div>
                                    <div
                                      className="tab-pane pdg_drag_drop_user_tmecard active"
                                      id="tab_default_2_timecard"
                                    >
                                      <div className="col-md-12 p0">
                                        <label htmlFor="email">Attachment:</label>
                                        {this.state.payslips ? (
                                          this.state.payslips.map((value, index) => {
                                            return (
                                              <>
                                                <span>{value.filename}</span>
                                              </>
                                            );
                                          })
                                        ) : (
                                            <p> no recent payslips</p>
                                          )}
                                        {this.state.tmp_img.map(value => {
                                          return (
                                            <button className="btn btn-default">
                                              <svg
                                                onClick={() =>
                                                  that.removeAttachment(value.filename)
                                                }
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                              >
                                                <path
                                                  id="ic_clear_24px"
                                                  d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                                                  transform="translate(-5 -5)"
                                                  fill="#7b7b7b"
                                                />
                                              </svg>{" "}
                                              <span
                                                data-toggle="modal"
                                                data-target="#send_message_popus"
                                              >
                                                {value.filename}
                                              </span>{" "}
                                            </button>
                                          );
                                        })}
                                        <Dropzone
                                          onDrop={this.SendMessageFileUploadHandler.bind(
                                            this
                                          )}
                                        >
                                          {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()}>
                                              <input {...getInputProps()} />

                                              <img
                                                src={attach_icon_user}
                                                width="23"
                                                height="43"
                                                alt=""
                                              />
                                              <p>Drag and drop files.</p>
                                            </div>
                                          )}
                                        </Dropzone>
                                      </div>
                                    </div>

                                    <div className="clearfix" />
                                  </div>
                                </>
                              )}
                          </div>
                          <div className="modal-footer add_timecard_popup">
                            <div className="col-md-12 col-xs-12 col-sm-12 analytics_footer_btn_mbl_none">
                              <div className="col-md-4 col-lg-6 col-sm-6 p0">
                                <div className="analytics_btn_save">
                                  <a
                                    href="javascript:void(0);"
                                    className="btn_save_analytics_timecard cursorPointer"
                                    onClick={this.newMessage_submitHandler}
                                  >
                                    Send
                          </a>
                                  <Link
                                    to="#"
                                    className="btn_cancel_analytics_timecard"
                                    data-dismiss="modal"
                                  >
                                    Cancel
                          </Link>
                                </div>
                                <div className="col-md-3"> </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="timecard_modify_popup"
                      className="modal fade"
                      role="dialog"
                    >
                      <div className="modal-dialog modify_timcard_popup">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              id="modifyCloseModel"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                              >
                                <path
                                  id="ic_clear_24px"
                                  d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                                  transform="translate(-5 -5)"
                                  fill="#7B7B7B"
                                />
                              </svg>
                            </button>
                            <h4 className="modal-title modal_header_titile_new">
                              Modify
                    </h4>
                          </div>
                          <div className="modal-body">
                            <div className="col-sm-10">
                              <select
                                className="form-control"
                                id="timeCards_selectmodify"
                                name="timeCards_selectmodify"
                                onChange={this.modify_selectHandler}
                                value={this.state.timeCards_selectmodifyValue}
                              >
                                <option value={null}>Please Select One Option</option>
                                {this.state.Batches.map(function (item) {
                                  return item.Lock !== "Locked" ? (
                                    <option value={item.Batch}>{item.Desc}</option>
                                  ) : (
                                      ""
                                    );
                                })}
                              </select>
                              <p
                                className={
                                  "error validation " +
                                  (this.state.modifyErrors.timeCards_selectmodifyErr
                                    ? ""
                                    : "displayNone")
                                }
                              >
                                {this.state.modifyErrors.timeCards_selectmodifyErr}
                              </p>
                            </div>
                          </div>
                          <div className="clear20"> </div>
                          <div className="modal-footer add_timecard_popup">
                            <div className="col-md-12 col-xs-12 col-sm-12 analytics_footer_btn_mbl_none">
                              <div className="col-md-4 col-lg-12 col-sm-6 p0">
                                <div className="analytics_btn_save">
                                  <a
                                    href="#"
                                    className="btn_save_analytics_timecard"
                                    onClick={this.modify_submitHandler}
                                  >
                                    Modify
                          </a>
                                  <Link
                                    to="#"
                                    className="btn_cancel_analytics_timecard"
                                    data-dismiss="modal"
                                  >
                                    Cancel
                          </Link>
                                </div>
                                <div className="col-md-3"> </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="timecard_decline_popup"
                      className="modal fade"
                      role="dialog"
                      data-keyboard="false"
                      data-backdrop="static"
                    >
                      <div className="modal-dialog decline_timcard_popup">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              onClick={this.closedecline}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              id="declineCloseModel"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                              >
                                <path
                                  id="ic_clear_24px"
                                  d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                                  transform="translate(-5 -5)"
                                  fill="#7B7B7B"
                                />
                              </svg>
                            </button>
                            <h4 className="modal-title modal_header_titile_new">
                              Decline
                    </h4>
                          </div>
                          <div className="modal-body">
                            <div className="col-sm-12">
                              <textarea
                                className="decline_textarea_timecard form-control"
                                id="timeCards_declineReason"
                                name="timeCards_declineReason"
                                value={this.state.timeCards_declineReason}
                                onChange={this.timeCards_inputHandler}
                                placeholder="Reason for Decline"
                              />
                            </div>
                            <div className="clear10"> </div>
                            <div className="col-sm-12">
                              <select
                                className="form-control"
                                id="timeCards_selectDeclinToValue"
                                name="timeCards_selectDeclineToValue"
                                value={this.state.timeCards_selectDeclineToValue}
                                onChange={this.timeCards_inputHandler}
                              >
                                <option value="">Decline To</option>
                                {/* <option value={this.state.empDetail && this.state.empDetail.Guid}>{this.state.empDetail && this.state.empDetail.Guid}</option> */}
                                <option
                                  value={
                                    this.state.empDetail.Employee
                                      ? this.state.empDetail.Employee[0].Guid
                                      : ""
                                  }
                                >
                                  {this.state.empDetail.Employee
                                    ? this.state.empDetail.Employee[0].FirstName
                                    : ""}
                                </option>

                                {this.state.declineOptions.map(function (item, key) {
                                  return (
                                    <option value={item.approver_id} key={key}>
                                      {item.approver_name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="clear20"> </div>
                          <div className="modal-footer add_timecard_popup">
                            <div className="col-md-12 col-xs-12 col-sm-12 analytics_footer_btn_mbl_none">
                              <div className="col-md-4 col-lg-12 col-sm-6 p0">
                                <div className="analytics_btn_save">
                                  <a
                                    href="javascript:void(0);"
                                    className="btn_save_analytics_timecard cursorPointer"
                                    onClick={this.decline}
                                  >
                                    Decline
                          </a>
                                  <a
                                    onClick={this.closedecline}
                                    href="javascript:void(0);"
                                    className="btn_cancel_analytics_timecard cursorPointer"
                                    data-dismiss="modal"
                                  >
                                    Cancel
                          </a>
                                </div>
                                <div className="col-md-3"> </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="exceptions_modal" className="modal fade" role="dialog">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header adtime_pop_ad">
                            <button
                              type="button"
                              className="close close_modal_adtimes"
                              data-dismiss="modal"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                              >
                                <path
                                  id="ic_clear_24px"
                                  d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                                  transform="translate(-5 -5)"
                                  fill="#7B7B7B"
                                />
                              </svg>
                            </button>
                            <h4 className="modal-title">Exceptions</h4>
                          </div>
                          <div className="modal-body">
                            <div className="user_document">
                              <div className="col-sm-12">
                                <div className="user_document_inner">
                                  {this.state.exceptions.map((e, i) => {
                                    return (
                                      <>
                                        <div className="col-sm-6 p0">
                                          <label>{e.exception}</label>
                                        </div>
                                        <div className="col-sm-6 p0 text-right">
                                          <p>
                                            {" "}
                                            {e.date} {e.time}
                                          </p>
                                        </div>
                                        <div className="clearfix" />
                                      </>
                                    );
                                  })}
                                </div>
                                <div className="clearfix" />
                              </div>
                              <div className="clearfix" />
                            </div>
                            <div className="clearfix" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="commentsMobileScreen">
                      <div className="col-sm-12 p0 text-center analytics_mbl_heading">
                        Comments
              </div>
                      <div className="col-md-12 col-xs-12 analytics_wrapper timecrad_coments_display">
                        <div className="user_document">
                          {this.state.comments.map((d, i) => {
                            return (
                              <div className="col-sm-12 col-xs-12 cments_timecard_bdr_btm">
                                <div className="user_document_inner_comnt">
                                  <div className="col-sm-6 p0 col-xs-6">
                                    <label>{d.name}</label>
                                  </div>
                                  <div className="col-sm-6 col-xs-6 p0 text-right">
                                    <p>{d.date}</p>
                                  </div>
                                  <div className="clearfix"> </div>
                                  <div className="col-xs-12 p0">
                                    <p>{d.comment}</p>
                                  </div>
                                </div>
                                <div className="clearfix" />
                              </div>
                            );
                          })}
                          <div className="clearfix"></div>
                        </div>
                        <div className="clear10"></div>
                        <div className="comment_area comment_area_cmnts">
                          <label>Add a Comment</label>
                          <textarea
                            className="form-control"
                            rows="3"
                            id="comment"
                            name="timeCards_mobile_comment"
                            value={this.state.timeCards_mobile_comment}
                            onChange={this.timeCards_inputHandler}
                          ></textarea>
                        </div>
                        <div className="comment_btn_area">
                          <a
                            href="javascript:void(0);"
                            className="btn_save_analytics btn_cmnts_save_cncl btn_comnts_ad_mssgs pull-left"
                            data-dismiss="modal"
                            onClick={this.addComment}
                          >
                            Add a Comment
                  </a>
                        </div>

                        <div className="dropdown comment_btn_area coments_add_msg_btn pull-right">
                          <button
                            className="btn  btn_cmnts_save_cncl btn_save_analytics dropdown-toggle "
                            type="button"
                            data-toggle="dropdown"
                          >
                            Add Message
                  </button>
                          <ul className="dropdown-menu down_coments">
                            <li>
                              <Link to="/attach_timecard">Attach TimeCard</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="clear50"></div>
                      </div>
                    </div>

                    <div className="exceptionMobileScreen">
                      <div className="col-sm-12 p0 text-center analytics_mbl_heading">
                        Exceptions
              </div>

                      <div className="col-sm-12 p0 top_mrg_form_res">
                        <div className="">
                          <div id="mobile_menu_sm">
                            {/* <!-- Sidebar --> */}
                            <div id="sidebar-wrapper " className="analytics_sidebar">
                              <ul className="sidebar-nav sid_clsssssss">
                                <li className="sidebar_active">
                                  <a href="dashboard.html">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="-5378 759 13.333 11.333"
                                    >
                                      <path
                                        id="ic_home_24px"
                                        className="cls-1"
                                        d="M7.333,14.333v-4H10v4h3.333V9h2L8.667,3,2,9H4v5.333Z"
                                        transform="translate(-5380 756)"
                                      />
                                    </svg>
                                    <span> Dashboard </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="payroll_batches.html">
                                    <svg
                                      version="1.1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      x="0px"
                                      y="0px"
                                      viewBox="0 0 1000 1000"
                                      enableBackground=""
                                      xmlSpace="preserve"
                                    >
                                      <g>
                                        <path d="M755.1,658c0,55.8-18.1,103.8-54.4,144.1c-36.3,40.3-83.4,65.2-141.4,74.7v95.7c0,5.1-1.6,9.3-4.9,12.6c-3.3,3.3-7.5,4.9-12.6,4.9H468c-4.7,0-8.8-1.7-12.3-5.2c-3.5-3.5-5.2-7.6-5.2-12.3v-95.7c-24.1-3.3-47.3-8.9-69.7-16.9c-22.4-8-40.9-16.1-55.5-24.3c-14.6-8.2-28.1-17-40.5-26.3c-12.4-9.3-20.9-16.1-25.4-20.5c-4.6-4.4-7.8-7.7-9.6-9.8c-6.2-7.7-6.6-15.1-1.1-22.4l56.3-73.8c2.5-3.6,6.7-5.8,12.6-6.5c5.5-0.7,9.8,0.9,13.1,4.9l1.1,1.1c41.2,36.1,85.5,58.9,132.9,68.4c13.5,2.9,27,4.4,40.5,4.4c29.5,0,55.5-7.8,78-23.5c22.4-15.7,33.6-37.9,33.6-66.7c0-10.2-2.7-19.9-8.2-29c-5.5-9.1-11.6-16.8-18.3-23c-6.7-6.2-17.4-13-32-20.5c-14.6-7.5-26.6-13.3-36.1-17.5c-9.5-4.2-24.1-10.1-43.8-17.8c-14.2-5.8-25.4-10.4-33.6-13.7c-8.2-3.3-19.4-8.1-33.6-14.5c-14.2-6.4-25.6-12-34.2-16.9c-8.6-4.9-18.9-11.4-30.9-19.4c-12-8-21.8-15.8-29.3-23.2c-7.5-7.5-15.4-16.4-23.8-26.8c-8.4-10.4-14.9-21-19.4-31.7c-4.6-10.7-8.4-22.9-11.5-36.4c-3.1-13.5-4.6-27.7-4.7-42.7c0-50.3,17.9-94.4,53.6-132.3c35.7-37.9,82.2-62.4,139.5-73.3V27.5c0-4.7,1.7-8.8,5.2-12.3c3.5-3.5,7.6-5.2,12.3-5.2h73.8c5.1,0,9.3,1.6,12.6,4.9c3.3,3.3,4.9,7.5,4.9,12.6v96.3c20.8,2.2,40.9,6.4,60.5,12.6c19.5,6.2,35.4,12.3,47.6,18.3c12.2,6,23.8,12.9,34.7,20.5c10.9,7.7,18,12.9,21.3,15.9c3.3,2.9,6,5.5,8.2,7.7c6.2,6.6,7.1,13.5,2.7,20.8L690,299.3c-2.9,5.5-7.1,8.4-12.6,8.8c-5.1,1.1-10-0.2-14.8-3.8c-1.1-1.1-3.7-3.3-7.9-6.5c-4.2-3.3-11.3-8.1-21.3-14.5c-10-6.4-20.7-12.2-32-17.5s-24.9-10-40.7-14.2c-15.8-4.2-31.4-6.3-46.8-6.3c-34.6,0-62.9,7.8-84.8,23.5c-21.9,15.7-32.8,35.9-32.8,60.7c0,9.5,1.6,18.2,4.7,26.3c3.1,8,8.5,15.6,16.1,22.7c7.7,7.1,14.9,13.1,21.6,18.1s17,10.6,30.6,16.9c13.7,6.3,24.7,11.3,33.1,14.8c8.4,3.5,21.1,8.5,38.3,15.1c19.3,7.3,34.1,13,44.3,17.2c10.2,4.2,24.1,10.6,41.6,19.1s31.3,16.3,41.3,23.2c10,6.9,21.3,16,33.9,27.3c12.6,11.3,22.2,22.9,29,34.7c6.7,11.8,12.5,25.8,17.2,41.8C752.7,622.7,755.1,639.8,755.1,658L755.1,658z" />
                                      </g>
                                    </svg>
                                    <span> Payroll Batches</span>{" "}
                                  </a>
                                </li>
                                <li>
                                  <a href="timecards.html">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 23.158"
                                    >
                                      <path
                                        id="ic_content_copy_24px"
                                        className="cls-1"
                                        d="M16.737,1H4.105A2.111,2.111,0,0,0,2,3.105V17.842H4.105V3.105H16.737Zm3.158,4.211H8.316A2.111,2.111,0,0,0,6.211,7.316V22.053a2.111,2.111,0,0,0,2.105,2.105H19.895A2.111,2.111,0,0,0,22,22.053V7.316A2.111,2.111,0,0,0,19.895,5.211Zm0,16.842H8.316V7.316H19.895Z"
                                        transform="translate(-2 -1)"
                                      />
                                    </svg>
                                    <span> Timecards</span>{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="timecards.html">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      x="18px"
                                      y="12px"
                                      viewBox="0 0 1000 1000"
                                      enableBackground="new 0 0 1000 1000"
                                      xmlSpace="preserve"
                                    >
                                      <g>
                                        <path d="M620.6,214c-88.3,0-176.5,0-264.8,0c-87.9,0-175.7,0-263.6,0.1c-6.2,0-12.5,0.5-18.6,1.6c-35.9,6.3-63.5,38.2-63.6,73.7C10,429.7,10,570,10,710.3c0,12.6,3,24.6,9.5,35.4C35.9,773,60.5,785.9,91.9,786c176.3,0.1,352.5,0,528.8,0c45.9,0,83.6-37.7,83.6-83.5c0-135,0-270,0-405C704.3,251.7,666.5,214,620.6,214z" />
                                        <path d="M985.6,295.6c-10.6,0-21.3-0.1-31.9,0.1c-2.5,0.1-5.3,0.8-7.4,2c-66,39.5-131.9,79-197.8,118.5c-2.5,1.5-3.3,3.1-3.3,6c0.1,51.8,0.1,103.6,0,155.3c0,3.1,1.1,4.7,3.6,6.2c64,38.3,128,76.7,191.9,115.2c6.2,3.7,12.2,5.7,19.4,5.4c9.9-0.5,19.9-0.1,30.1-0.1c0-136.4,0-272.3,0-408.7C988.4,295.6,987,295.6,985.6,295.6z" />
                                      </g>
                                    </svg>
                                    <span> Productions</span>{" "}
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <strong> Admin</strong>{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="custom_field.html">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path
                                        id="ic_description_24px"
                                        className="cls-1"
                                        d="M16.5,2H6.5A2.287,2.287,0,0,0,4.013,4L4,20a2.287,2.287,0,0,0,2.487,2H21.5A2.3,2.3,0,0,0,24,20V8ZM19,18H9V16H19Zm0-4H9V12H19ZM15.25,9V3.5L22.125,9Z"
                                        transform="translate(-4 -2)"
                                      />
                                    </svg>
                                    <span> Employee List</span>{" "}
                                  </a>
                                </li>
                                <li>
                                  <a href="custom_list_field.html">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path
                                        id="ic_create_24px"
                                        className="cls-1"
                                        d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z"
                                        transform="translate(-3 -2.998)"
                                      />
                                    </svg>
                                    <span> AD Times</span>{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="custom_list_field.html">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path
                                        id="ic_person_24px"
                                        className="cls-1"
                                        d="M14,14A5,5,0,1,0,9,9,5,5,0,0,0,14,14Zm0,2.5c-3.337,0-10,1.675-10,5V24H24V21.5C24,18.175,17.337,16.5,14,16.5Z"
                                        transform="translate(-4 -4)"
                                      />
                                    </svg>
                                    <span> Users</span>{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="custom_list_field.html">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 14"
                                    >
                                      <path
                                        id="ic_supervisor_account_24px"
                                        className="cls-1"
                                        d="M16.5,12A2.5,2.5,0,1,0,14,9.5,2.492,2.492,0,0,0,16.5,12ZM9,11A3,3,0,1,0,6,8,2.987,2.987,0,0,0,9,11Zm7.5,3c-1.83,0-5.5.92-5.5,2.75V19H22V16.75C22,14.92,18.33,14,16.5,14ZM9,13c-2.33,0-7,1.17-7,3.5V19H9V16.75a3.941,3.941,0,0,1,2.37-3.47A12.283,12.283,0,0,0,9,13Z"
                                        transform="translate(-2 -5)"
                                      />
                                    </svg>
                                    <span> Approval Setup</span>{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="custom_list_field.html">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 16"
                                    >
                                      <path
                                        id="ic_markunread_24px"
                                        className="cls-1"
                                        d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,4-8,5L4,8V6l8,5,8-5Z"
                                        transform="translate(-2 -4)"
                                      />
                                    </svg>
                                    <span> Email Template</span>{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="custom_list_field.html">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20.561"
                                    >
                                      <path
                                        id="ic_settings_24px"
                                        className="cls-1"
                                        d="M19.911,13.288a8.012,8.012,0,0,0,.072-1.007,8.012,8.012,0,0,0-.072-1.007l2.169-1.7a.519.519,0,0,0,.123-.658L20.148,5.362a.517.517,0,0,0-.627-.226l-2.56,1.028a7.511,7.511,0,0,0-1.737-1.007l-.391-2.724a.5.5,0,0,0-.5-.432H10.217a.5.5,0,0,0-.5.432L9.322,5.156A7.9,7.9,0,0,0,7.585,6.164L5.025,5.136a.5.5,0,0,0-.627.226L2.342,8.919a.507.507,0,0,0,.123.658l2.169,1.7a8.153,8.153,0,0,0-.072,1.007,8.153,8.153,0,0,0,.072,1.007l-2.169,1.7a.519.519,0,0,0-.123.658L4.4,19.2a.517.517,0,0,0,.627.226L7.585,18.4A7.511,7.511,0,0,0,9.322,19.4l.391,2.724a.5.5,0,0,0,.5.432h4.112a.5.5,0,0,0,.5-.432l.391-2.724A7.9,7.9,0,0,0,16.961,18.4l2.56,1.028a.5.5,0,0,0,.627-.226L22.2,15.642a.519.519,0,0,0-.123-.658Zm-7.638,2.591a3.6,3.6,0,1,1,3.6-3.6A3.6,3.6,0,0,1,12.273,15.879Z"
                                        transform="translate(-2.271 -2)"
                                      />
                                    </svg>
                                    <span> Settings</span>{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="custom_list_field.html">
                                    <svg
                                      version="1.1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      x="17px"
                                      y="17px"
                                      viewBox="0 0 1000 1000"
                                      enableBackground="new 0 0 1000 1000"
                                      xmlSpace="preserve"
                                    >
                                      <g>
                                        <path d="M968.4,789.2L561.9,451.7c23.2-42.1,37.6-89.8,37.6-141.2c0-162.8-132-294.8-294.8-294.8c-34.8,0-67.8,7.1-98.8,18.2l99.1,94.9c49.1,47,50.8,125,3.8,174.1c-47,49.1-125,50.8-174.1,3.7L29.9,206.3C17.6,238.8,10,273.6,10,310.4c0,162.8,132,294.8,294.8,294.8c54.2,0,104.3-15.7,148-41.2l344.5,399.6c22.9,26.5,61.7,27.8,86.3,2.8l89.3-91C997.4,850.4,995.4,811.6,968.4,789.2z" />
                                      </g>
                                    </svg>
                                    <span> Setup</span>{" "}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className="clearfix"></div>
                        </div>

                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                      <div className="col-md-12 col-xs-12 timecrad_coments_display">
                        <div className="user_document exception_mobole_noscroll">
                          <div className="col-sm-12 exception_bdr_btm">
                            <div className="user_document_inner_excption">
                              <div className="col-xs-6 p0">
                                <label> Patric Plummer</label>
                              </div>
                              <div className="col-xs-6  p0 text-right">
                                <span>5/07/2018 1:10pm</span>
                              </div>
                              <div className="col-xs-9 p0">
                                <p>Car allowence payed last week but not this week</p>
                              </div>
                            </div>
                            <div className="clearfix"></div>
                          </div>

                          <div className="col-sm-12 exception_bdr_btm">
                            <div className="user_document_inner_excption">
                              <div className="col-xs-6 p0">
                                <label> Patric Plummer</label>
                              </div>
                              <div className="col-xs-6  p0 text-right">
                                <span>5/07/2018 1:10pm</span>
                              </div>
                              <div className="col-xs-9 p0">
                                <p> I've attached document</p>
                              </div>
                            </div>
                            <div className="clearfix"></div>
                          </div>

                          <div className="col-sm-12 exception_bdr_btm exception_bdr_last">
                            <div className="user_document_inner_excption">
                              <div className="col-xs-6 p0">
                                <label> Patric Plummer</label>
                              </div>
                              <div className="col-xs-6  p0 text-right">
                                <span>5/07/2018 1:10pm</span>
                              </div>
                              <div className="col-xs-9 p0">
                                <p>Sunday payed as travel</p>
                              </div>
                            </div>
                            <div className="clearfix"></div>
                          </div>

                          <div className="clear30"></div>
                        </div>

                        <div className="clearfix"></div>
                      </div>
                    </div>

                    <div className="declinetimecardMobileScreen">
                      <div className="col-sm-12 p0 text-center analytics_mbl_heading">
                        Decline
              </div>

                      <div className="col-sm-12 p0 top_mrg_form_res">
                        <div className="">
                          <div id="mobile_menu_sm">
                            <div id="sidebar-wrapper " className="analytics_sidebar">
                              <ul className="sidebar-nav dec_sddd">
                                <li className="sidebar_active">
                                  <a href="dashboard.html">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="-5378 759 13.333 11.333"
                                    >
                                      <path
                                        id="ic_home_24px"
                                        className="cls-1"
                                        d="M7.333,14.333v-4H10v4h3.333V9h2L8.667,3,2,9H4v5.333Z"
                                        transform="translate(-5380 756)"
                                      />
                                    </svg>
                                    <span> Dashboard </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="payroll_batches.html">
                                    <svg
                                      version="1.1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      x="0px"
                                      y="0px"
                                      viewBox="0 0 1000 1000"
                                      enableBackground=""
                                      xmlSpace="preserve"
                                    >
                                      <g>
                                        <path d="M755.1,658c0,55.8-18.1,103.8-54.4,144.1c-36.3,40.3-83.4,65.2-141.4,74.7v95.7c0,5.1-1.6,9.3-4.9,12.6c-3.3,3.3-7.5,4.9-12.6,4.9H468c-4.7,0-8.8-1.7-12.3-5.2c-3.5-3.5-5.2-7.6-5.2-12.3v-95.7c-24.1-3.3-47.3-8.9-69.7-16.9c-22.4-8-40.9-16.1-55.5-24.3c-14.6-8.2-28.1-17-40.5-26.3c-12.4-9.3-20.9-16.1-25.4-20.5c-4.6-4.4-7.8-7.7-9.6-9.8c-6.2-7.7-6.6-15.1-1.1-22.4l56.3-73.8c2.5-3.6,6.7-5.8,12.6-6.5c5.5-0.7,9.8,0.9,13.1,4.9l1.1,1.1c41.2,36.1,85.5,58.9,132.9,68.4c13.5,2.9,27,4.4,40.5,4.4c29.5,0,55.5-7.8,78-23.5c22.4-15.7,33.6-37.9,33.6-66.7c0-10.2-2.7-19.9-8.2-29c-5.5-9.1-11.6-16.8-18.3-23c-6.7-6.2-17.4-13-32-20.5c-14.6-7.5-26.6-13.3-36.1-17.5c-9.5-4.2-24.1-10.1-43.8-17.8c-14.2-5.8-25.4-10.4-33.6-13.7c-8.2-3.3-19.4-8.1-33.6-14.5c-14.2-6.4-25.6-12-34.2-16.9c-8.6-4.9-18.9-11.4-30.9-19.4c-12-8-21.8-15.8-29.3-23.2c-7.5-7.5-15.4-16.4-23.8-26.8c-8.4-10.4-14.9-21-19.4-31.7c-4.6-10.7-8.4-22.9-11.5-36.4c-3.1-13.5-4.6-27.7-4.7-42.7c0-50.3,17.9-94.4,53.6-132.3c35.7-37.9,82.2-62.4,139.5-73.3V27.5c0-4.7,1.7-8.8,5.2-12.3c3.5-3.5,7.6-5.2,12.3-5.2h73.8c5.1,0,9.3,1.6,12.6,4.9c3.3,3.3,4.9,7.5,4.9,12.6v96.3c20.8,2.2,40.9,6.4,60.5,12.6c19.5,6.2,35.4,12.3,47.6,18.3c12.2,6,23.8,12.9,34.7,20.5c10.9,7.7,18,12.9,21.3,15.9c3.3,2.9,6,5.5,8.2,7.7c6.2,6.6,7.1,13.5,2.7,20.8L690,299.3c-2.9,5.5-7.1,8.4-12.6,8.8c-5.1,1.1-10-0.2-14.8-3.8c-1.1-1.1-3.7-3.3-7.9-6.5c-4.2-3.3-11.3-8.1-21.3-14.5c-10-6.4-20.7-12.2-32-17.5s-24.9-10-40.7-14.2c-15.8-4.2-31.4-6.3-46.8-6.3c-34.6,0-62.9,7.8-84.8,23.5c-21.9,15.7-32.8,35.9-32.8,60.7c0,9.5,1.6,18.2,4.7,26.3c3.1,8,8.5,15.6,16.1,22.7c7.7,7.1,14.9,13.1,21.6,18.1s17,10.6,30.6,16.9c13.7,6.3,24.7,11.3,33.1,14.8c8.4,3.5,21.1,8.5,38.3,15.1c19.3,7.3,34.1,13,44.3,17.2c10.2,4.2,24.1,10.6,41.6,19.1s31.3,16.3,41.3,23.2c10,6.9,21.3,16,33.9,27.3c12.6,11.3,22.2,22.9,29,34.7c6.7,11.8,12.5,25.8,17.2,41.8C752.7,622.7,755.1,639.8,755.1,658L755.1,658z" />
                                      </g>
                                    </svg>
                                    <span> Payroll Batches</span>{" "}
                                  </a>
                                </li>
                                <li>
                                  <a href="timecards.html">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 23.158"
                                    >
                                      <path
                                        id="ic_content_copy_24px"
                                        className="cls-1"
                                        d="M16.737,1H4.105A2.111,2.111,0,0,0,2,3.105V17.842H4.105V3.105H16.737Zm3.158,4.211H8.316A2.111,2.111,0,0,0,6.211,7.316V22.053a2.111,2.111,0,0,0,2.105,2.105H19.895A2.111,2.111,0,0,0,22,22.053V7.316A2.111,2.111,0,0,0,19.895,5.211Zm0,16.842H8.316V7.316H19.895Z"
                                        transform="translate(-2 -1)"
                                      />
                                    </svg>
                                    <span> Timecards</span>{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="timecards.html">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      x="18px"
                                      y="12px"
                                      viewBox="0 0 1000 1000"
                                      enableBackground="new 0 0 1000 1000"
                                      xmlSpace="preserve"
                                    >
                                      <g>
                                        <path d="M620.6,214c-88.3,0-176.5,0-264.8,0c-87.9,0-175.7,0-263.6,0.1c-6.2,0-12.5,0.5-18.6,1.6c-35.9,6.3-63.5,38.2-63.6,73.7C10,429.7,10,570,10,710.3c0,12.6,3,24.6,9.5,35.4C35.9,773,60.5,785.9,91.9,786c176.3,0.1,352.5,0,528.8,0c45.9,0,83.6-37.7,83.6-83.5c0-135,0-270,0-405C704.3,251.7,666.5,214,620.6,214z" />
                                        <path d="M985.6,295.6c-10.6,0-21.3-0.1-31.9,0.1c-2.5,0.1-5.3,0.8-7.4,2c-66,39.5-131.9,79-197.8,118.5c-2.5,1.5-3.3,3.1-3.3,6c0.1,51.8,0.1,103.6,0,155.3c0,3.1,1.1,4.7,3.6,6.2c64,38.3,128,76.7,191.9,115.2c6.2,3.7,12.2,5.7,19.4,5.4c9.9-0.5,19.9-0.1,30.1-0.1c0-136.4,0-272.3,0-408.7C988.4,295.6,987,295.6,985.6,295.6z" />
                                      </g>
                                    </svg>
                                    <span> Productions</span>{" "}
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <strong> Admin</strong>{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="custom_field.html">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path
                                        id="ic_description_24px"
                                        className="cls-1"
                                        d="M16.5,2H6.5A2.287,2.287,0,0,0,4.013,4L4,20a2.287,2.287,0,0,0,2.487,2H21.5A2.3,2.3,0,0,0,24,20V8ZM19,18H9V16H19Zm0-4H9V12H19ZM15.25,9V3.5L22.125,9Z"
                                        transform="translate(-4 -2)"
                                      />
                                    </svg>
                                    <span> Employee List</span>{" "}
                                  </a>
                                </li>
                                <li>
                                  <a href="custom_list_field.html">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path
                                        id="ic_create_24px"
                                        className="cls-1"
                                        d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z"
                                        transform="translate(-3 -2.998)"
                                      />
                                    </svg>
                                    <span> AD Times</span>{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="custom_list_field.html">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path
                                        id="ic_person_24px"
                                        className="cls-1"
                                        d="M14,14A5,5,0,1,0,9,9,5,5,0,0,0,14,14Zm0,2.5c-3.337,0-10,1.675-10,5V24H24V21.5C24,18.175,17.337,16.5,14,16.5Z"
                                        transform="translate(-4 -4)"
                                      />
                                    </svg>
                                    <span> Users</span>{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="custom_list_field.html">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 14"
                                    >
                                      <path
                                        id="ic_supervisor_account_24px"
                                        className="cls-1"
                                        d="M16.5,12A2.5,2.5,0,1,0,14,9.5,2.492,2.492,0,0,0,16.5,12ZM9,11A3,3,0,1,0,6,8,2.987,2.987,0,0,0,9,11Zm7.5,3c-1.83,0-5.5.92-5.5,2.75V19H22V16.75C22,14.92,18.33,14,16.5,14ZM9,13c-2.33,0-7,1.17-7,3.5V19H9V16.75a3.941,3.941,0,0,1,2.37-3.47A12.283,12.283,0,0,0,9,13Z"
                                        transform="translate(-2 -5)"
                                      />
                                    </svg>
                                    <span> Approval Setup</span>{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="custom_list_field.html">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 16"
                                    >
                                      <path
                                        id="ic_markunread_24px"
                                        className="cls-1"
                                        d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,4-8,5L4,8V6l8,5,8-5Z"
                                        transform="translate(-2 -4)"
                                      />
                                    </svg>
                                    <span> Email Template</span>{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="custom_list_field.html">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20.561"
                                    >
                                      <path
                                        id="ic_settings_24px"
                                        className="cls-1"
                                        d="M19.911,13.288a8.012,8.012,0,0,0,.072-1.007,8.012,8.012,0,0,0-.072-1.007l2.169-1.7a.519.519,0,0,0,.123-.658L20.148,5.362a.517.517,0,0,0-.627-.226l-2.56,1.028a7.511,7.511,0,0,0-1.737-1.007l-.391-2.724a.5.5,0,0,0-.5-.432H10.217a.5.5,0,0,0-.5.432L9.322,5.156A7.9,7.9,0,0,0,7.585,6.164L5.025,5.136a.5.5,0,0,0-.627.226L2.342,8.919a.507.507,0,0,0,.123.658l2.169,1.7a8.153,8.153,0,0,0-.072,1.007,8.153,8.153,0,0,0,.072,1.007l-2.169,1.7a.519.519,0,0,0-.123.658L4.4,19.2a.517.517,0,0,0,.627.226L7.585,18.4A7.511,7.511,0,0,0,9.322,19.4l.391,2.724a.5.5,0,0,0,.5.432h4.112a.5.5,0,0,0,.5-.432l.391-2.724A7.9,7.9,0,0,0,16.961,18.4l2.56,1.028a.5.5,0,0,0,.627-.226L22.2,15.642a.519.519,0,0,0-.123-.658Zm-7.638,2.591a3.6,3.6,0,1,1,3.6-3.6A3.6,3.6,0,0,1,12.273,15.879Z"
                                        transform="translate(-2.271 -2)"
                                      />
                                    </svg>
                                    <span> Settings</span>{" "}
                                  </a>
                                </li>

                                <li>
                                  <a href="custom_list_field.html">
                                    <svg
                                      version="1.1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      x="17px"
                                      y="17px"
                                      viewBox="0 0 1000 1000"
                                      enableBackground="new 0 0 1000 1000"
                                      xmlSpace="preserve"
                                    >
                                      <g>
                                        <path d="M968.4,789.2L561.9,451.7c23.2-42.1,37.6-89.8,37.6-141.2c0-162.8-132-294.8-294.8-294.8c-34.8,0-67.8,7.1-98.8,18.2l99.1,94.9c49.1,47,50.8,125,3.8,174.1c-47,49.1-125,50.8-174.1,3.7L29.9,206.3C17.6,238.8,10,273.6,10,310.4c0,162.8,132,294.8,294.8,294.8c54.2,0,104.3-15.7,148-41.2l344.5,399.6c22.9,26.5,61.7,27.8,86.3,2.8l89.3-91C997.4,850.4,995.4,811.6,968.4,789.2z" />
                                      </g>
                                    </svg>
                                    <span> Setup</span>{" "}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className="clearfix"></div>
                        </div>

                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                      <div className="col-md-12 col-xs-12 analytics_wrapper timecrad_coments_display">
                        {/* <!-- Modal content-->
			<!-- Decline Popup -->


	<!-- Modal content--> */}

                        <div className="col-sm-12">
                          <textarea
                            className="decline_textarea_timecard form-control"
                            id="timeCards_declineReason"
                            name="timeCards_declineReason"
                            value={this.state.timeCards_declineReason}
                            onChange={this.timeCards_inputHandler}
                            placeholder="Reason for Decline"
                          />
                        </div>
                        <div className="clear20"> </div>
                        <div className="col-sm-12">
                          <select
                            className="form-control"
                            id="timeCards_selectDeclinToValue"
                            name="timeCards_selectDeclineToValue"
                            value={this.state.timeCards_selectDeclineToValue}
                            onChange={this.timeCards_inputHandler}
                          >
                            <option value="">Decline To</option>
                            {/* <option value={this.state.empDetail && this.state.empDetail.Guid}>{this.state.empDetail && this.state.empDetail.Guid}</option> */}
                            <option
                              value={
                                this.state.empDetail.Employee
                                  ? this.state.empDetail.Employee[0].Guid
                                  : ""
                              }
                            >
                              {this.state.empDetail.Employee
                                ? this.state.empDetail.Employee[0].FirstName
                                : ""}
                            </option>

                            {this.state.declineOptions.map(function (item, key) {
                              return (
                                <option value={item.approver_id} key={key}>
                                  {item.approver_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        <div className="clear40"> </div>

                        {/* <!--  <button type="button" className="btn btn-default" data-dismiss="modal">Close</button> --> */}
                        <div className="col-md-12 col-xs-12 col-sm-12 ">
                          <div className="col-md-4 col-lg-12 col-sm-6 p0">
                            <div className="analytics_btn_save_decline">
                              <div>
                                <a
                                  className="btn_cancel_analytics_timecard"
                                  onClick={this.decline}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="12"
                                    viewBox="0 0 14 14"
                                  >
                                    <path
                                      id="ic_clear_24px"
                                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                                      transform="translate(-5 -5)"
                                      fill="#30679A"
                                    />
                                  </svg>
                                  Decline
                        </a>
                              </div>
                            </div>
                            <div className="col-md-3"> </div>
                          </div>
                        </div>

                        {/* <!-- Decline Popup --> */}

                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
               
      </div>
    );
  }
}
export default TimeCards;
