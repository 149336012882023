import React, { Component } from 'react';
import HeaderMob from '../Common/HeaderMob/HeaderMob';
import './Attach_Document.css';

export default class Attach_Document extends Component {
  constructor() {
    super();
    this.state = {
      message: JSON.parse(localStorage.getItem('message'))
    }
  }
  render() {
    return (
      <div>


        <HeaderMob />



        <div className="col-sm-12">

          <div className="msg_box_attached_document">
            <div className="clear10"> </div>
            <div className="col-sm-12 p0">
              <p> {this.state.message.Subject}</p>
            </div>
            <div className="col-sm-12 p0">

              <div className="col-sm-10 msg_box_pattric_heading p0 msg_car_insurance">
                <h5>{this.state.message.From}</h5>
                <p> {this.state.message.Timestamp}</p>
                <p> {this.state.message.To}</p>
                <div className="clearfix"> </div>
                {/* <!--    <button className="btn btn-default">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                  <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7b7b7b"/>
                </svg> Car Insurance.pdf</button> --> */}
                <div className="clear30"> </div>
                <span> Hi {this.state.message.To}</span>
                <div className="clear50"> </div>
                <span> {this.state.message.Body}</span>
                <div className="clear50"> </div>
              </div>
            </div>
            <div className="col-sm-10 col-sm-offset-2 msg_car_insurance">

            </div>
          </div>

        </div>


        <div className="clear20"> </div>


      </div>
    )
  }
}
