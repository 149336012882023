import React, { Component } from "react";
import Header1 from "../Common/Header1/Header1";
import CKEditor from "react-ckeditor-component";
import "./EmailTemplate.css";
import SearchSvg from "../../Assets/images/ic_search_-1.svg";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import { toast } from 'react-toastify';
export default class EmailTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEmailTamplate: true,
      isLoading: true,
      selectedEmailDropdown: "",
      emailTemp_placeholderSearch: "",
      emailTemp_pfName: "",
      emailTemp_pfDbValue: "",
      emailTemp_pfLinkCheck: "",

      emailTemp_pffName: "",
      emailTemp_pffDbValue: "",
      emailTemp_pffLinkCheck: false,
      emailTemp_pffUrl: '',
      emailTemp_pffText: '',

      emailTemp_pfNameValid: false,
      emailTemp_pfDbValueValid: false,
      emailTemp_pffNameValid: false,
      emailTemp_pffDbValueValid: false,
      emailTemp_pffUrlValid: false,
      emailTemp_pffTextValid: false,

      pfErrors: {
        emailTemp_pfNameErr: "",
        emailTemp_pfDbValueErr: ""
      },
      pffErrors: {
        emailTemp_pffNameErr: "",
        emailTemp_pffDbValueErr: "",
        emailTemp_pffUrlErr: "",
        emailTemp_pffTextErr: "",
      },

      pfFormValid: false,
      pffFormValid: false,
      selectedEmailTemplate: [],
      emailTemplateList: [],
      placeHolderFormTableData: [],
      placeHolderFormTableDataSearch: [],
      currentSelectedPlaceholder: '',
      tmp_editable_guid: "",
      selectedvalue: ""
    };
  }
  componentWillMount = async () => {
    await this.getUserSettings();
    if (this.state.showEmailTamplate === false) {
      this.props.history.push('/dashboard');
    }
  }
  getUserSettings = async () => {
    let userGuid = localStorage.getItem("userGuid");
    await API.post("getuserbyguid", "/getuserbyguid", {
      body: {
        user_id: userGuid
      }
    }).then(data => {
      this.setState({
        showEmailTamplate: data.access.EmailTamplate,
      });
    }).catch(err => {
      console.log('error in fetching user')
    });


  }
  handleEmailDropdown = (e) => {
    let selectedEmailTemplate = [];
    const guid = e.target.value;
    this.setState({
      selectedvalue: e.target.value
    })
    if (guid === '') {
      this.setState({
        selectedEmailTemplate: []
      })
    } else {
      selectedEmailTemplate = this.state.emailTemplateList.filter(item => item.Guid === guid);
      this.setState({
        selectedEmailTemplate: selectedEmailTemplate[0]
      })
    }
  }
  pf_inputHandler = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({
      [name]: value
    },
      () => {
        this.pf_validateField(name, value)
      }
    );
  }
  pf_validateField(fieldName, value) {
    let pfErrors = this.state.pfErrors;
    let emailTemp_pfNameValid = this.state.emailTemp_pfNameValid;
    let emailTemp_pfDbValueValid = this.state.emailTemp_pfDbValueValid;


    switch (fieldName) {

      case 'emailTemp_pfName':
        emailTemp_pfNameValid = value.length > 0;
        pfErrors.emailTemp_pfNameErr = emailTemp_pfNameValid ? '' : "This field is required";
        break;
      case 'emailTemp_pfDbValue':
        emailTemp_pfDbValueValid = value.length > 0;
        pfErrors.emailTemp_pfDbValueErr = emailTemp_pfDbValueValid ? '' : 'This field is required';
        break;
      default:
        break;
    }

    this.setState({
      pfErrors: pfErrors,
      emailTemp_pfNameValid: emailTemp_pfNameValid,
      emailTemp_pfDbValueValid: emailTemp_pfDbValueValid
    }, this.pf_validateForm);
  }
  pf_validateForm = () => {
    this.setState({
      pfFormValid: this.state.emailTemp_pfNameValid && this.state.emailTemp_pfDbValueValid
    });
  }
  componentDidMount = async () => {
    await this.props.childProps.isAuthentication();
    if (!this.props.childProps.isAuthenticated) {
      this.props.history.push('/login');
    } else {
      await this.getplaceholdersall();
      await this.getAllEmailTemplate();
      this.setState({

        isLoading: false
      });
    }
  }
  getAllEmailTemplate = async () => {
    this.setState({
      isLoading: true
    });
    await API.post('getemailtemplate', '/getemailtemplate',
      {
        body: { "tenant": localStorage.getItem('useableGuid') }
      }
    ).then(data => {
      this.setState({
        emailTemplateList: data
      })


    }).catch(err => {
      toast.error(err.message)
    });
    this.setState({
      isLoading: false
    });
  }

  deleteplaceholder = async (guid) => {
    this.setState({
      isLoading: true
    });
    await API.post('deleteplaceholder', '/deleteplaceholder', {
      body: {
        "Guid": guid
      }
    }).then(data => {

      toast.error('Placeholder Deleted Successfully.');
      this.getplaceholdersall();

    }).catch(err => {
      toast.error(err.message)
    });
    this.setState({
      isLoading: false
    });
  }
  pf_SearchHandler = (event) => {
    let value = event.target.value;
    this.setState({
      emailTemp_placeholderSearch: value,
      placeHolderFormTableData: this.state.placeHolderFormTableDataSearch.filter(function (item) {
        return item.Name.toUpperCase().includes(value.toUpperCase());
      })


    })

  }
  getplaceholdersall = async () => {
    await API.post("getplaceholdersall", "/getplaceholdersall"
    ).then(data => {

      this.setState({
        placeHolderFormTableData: data,
        placeHolderFormTableDataSearch: data
      })

    }).catch(err => {
      toast.error(err.message)
    });
  }
  pff_inputHandler = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({
      [name]: value
    },
      () => {
        this.pff_validateField(name, value)
      }
    );
  }
  pff_validateField(fieldName, value) {
    let pffErrors = this.state.pffErrors;
    let emailTemp_pffNameValid = this.state.emailTemp_pffNameValid;
    let emailTemp_pffDbValueValid = this.state.emailTemp_pffDbValueValid;


    switch (fieldName) {
      case 'emailTemp_pffName':
        emailTemp_pffNameValid = value.length > 0;
        pffErrors.emailTemp_pffNameErr = emailTemp_pffNameValid ? '' : "This field is required";
        break;


      default:
        break;
    }

    this.setState({
      pffErrors: pffErrors,
      emailTemp_pffNameValid: emailTemp_pffNameValid,
      emailTemp_pffDbValueValid: emailTemp_pffDbValueValid,


    }, this.pff_validateForm);
  }
  pff_validateForm = () => {
    this.setState({
      pfFormValid: this.state.emailTemp_pffNameValid
    });
  }

  clearStateplaceholder = async () => {
    await this.setState({
      tmp_editable_guid: "",
      emailTemp_pffName: "",
      emailTemp_pffDbValue: "",
      emailTemp_pffLinkCheck: false,
      emailTemp_pffUrl: '',
      emailTemp_pffText: '',
      emailTemp_pffNameValid: false,
      emailTemp_pffDbValueValid: false,
      emailTemp_pffUrlValid: false,
      emailTemp_pffTextValid: false,
      pfFormValid: false,
      pffErrors: {
        emailTemp_pffNameErr: "",
        emailTemp_pffDbValueErr: "",
        emailTemp_pffUrlErr: "",
        emailTemp_pffTextErr: "",
      }
    });
  }
  pff_submitHandler = async () => {




    let pffErrors = this.state.pffErrors;

    if (!this.state.emailTemp_pffNameValid) {
      pffErrors.emailTemp_pffNameErr = "This field is required";
    }


    await this.setState({
      pffErrors: pffErrors,
      isLoading: true
    });

    await this.pff_validateForm();
    if (this.state.pfFormValid) {
      if (this.state.tmp_editable_guid === '') {

        await API.post("createplaceholder", "/createplaceholder", {
          body: {
            "dbvalue": this.state.emailTemp_pffDbValue,
            "link": this.state.emailTemp_pffLinkCheck,
            "name": this.state.emailTemp_pffName,
            "text": this.state.emailTemp_pffText,
            "url": this.state.emailTemp_pffUrl,
          }
        }).then(data => {

        //  toast.success('Placeholder Created Successfully.');
          this.getplaceholdersall();
        }).catch(err => {
          toast.error(err.message)
        });
        document.getElementById('close-modalplaceholder').click();

      } else {

        await API.post("createplaceholder", "/createplaceholder", {
          body: {
            "Guid": this.state.tmp_editable_guid,
            "dbvalue": this.state.emailTemp_pffDbValue,
            "link": this.state.emailTemp_pffLinkCheck,
            "name": this.state.emailTemp_pffName,
            "text": this.state.emailTemp_pffText,
            "url": this.state.emailTemp_pffUrl,
          }
        }).then(data => {

         // toast.success('Placeholder Updated Successfully.');
          this.getplaceholdersall();
        }).catch(err => {
          toast.error(err.message)
        });
        document.getElementById('close-modalplaceholder').click();
      }

    }
    this.setState({
      isLoading: false
    });
  }
  editPlaceHolderData = async (guid) => {

    await this.setState({
      currentSelectedPlaceholder: this.state.placeHolderFormTableData.filter(function (items) {
        return items.Guid === guid;
      }),
      tmp_editable_guid: guid
    })


    await this.setState({
      emailTemp_pffName: this.state.currentSelectedPlaceholder[0].Name !== 'NA' ? this.state.currentSelectedPlaceholder[0].Name : '',
      emailTemp_pffDbValue: this.state.currentSelectedPlaceholder[0].DBValue !== 'NA' ? this.state.currentSelectedPlaceholder[0].DBValue : '',
      emailTemp_pffUrl: this.state.currentSelectedPlaceholder[0].Url !== 'NA' ? this.state.currentSelectedPlaceholder[0].Url : '',
      emailTemp_pffText: this.state.currentSelectedPlaceholder[0].Text !== 'NA' ? this.state.currentSelectedPlaceholder[0].Text : '',
      emailTemp_pffLinkCheck: this.state.currentSelectedPlaceholder[0].Link,
      emailTemp_pffNameValid: this.state.currentSelectedPlaceholder[0].Name !== 'NA' ? true : false,
      emailTemp_pffDbValueValid: this.state.currentSelectedPlaceholder[0].DBValue !== 'NA' ? true : false,

    })

  }
  editTemplate = async () => {
    if (this.state.selectedEmailTemplate.Guid) {


      this.props.history.push('/email_templates_add', {
        data: this.state.selectedEmailTemplate
      });
    } else {
      toast.error('Select Template to Edit');
    }
  }
  deleteTemplate = async () => {
    await this.setState({
      isLoading: true
    })
    if (this.state.selectedEmailTemplate.Guid) {


      await API.post("deletetemplate", "/deletetemplate", {
        body: {
          "Guid": this.state.selectedEmailTemplate.Guid
        }
      }).then(data => {
        this.getAllEmailTemplate();
        this.setState({
          selectedEmailTemplate: [],
          selectedvalue: ''
        })
       // toast.success('Template Deleted Successfully.');

      }).catch(err => {
        toast.error(err.message)
      });

    } else {
      toast.error('Select Template to Delete');
    }
    await this.setState({
      isLoading: false
    })
  }
  render() {
    return (
      <div>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ''}
        <Header1
          pageHeader="Email Template"
          childProps={this.props.childProps}

        />
        <br />
        <br />
        <div className="clearfix" />

        <div className="col-sm-12 p0 top_mrg_general top_mrg_general_custom top_mrgg_mbl_screeen">
          <div className="">
            <div className="col-sm-12 col-md-12 col-lg-10 col-lg-offset-1 general_setting_hd">
              <div className="col-xs-12 col-sm-12 col-md-12">
                <div className="clear10" />
                <div className="clearfix" />
                <div className="email_temp_box">
                  <div className="email_temp_hdr">
                    <div className="col-xs-12 col-sm-6 text-left mrg_top3">
                      <label>Template:</label>

                      <div className="email_hdr_select">
                        <select id="selectedEmailDropdown" value={this.state.selectedvalue} name="selectedEmailDropdown" onChange={this.handleEmailDropdown}>
                          <option value=''>Select Email Template</option>
                          {this.state.emailTemplateList.map((val, key) => {
                            return (

                              <option id={val.Guid} value={val.Guid} key={key} tenantguid={val.TenantGuid} type={val.Type} >{val.Name}</option>
                            )
                          })}
                        </select>
                      </div>
                      <label>Template Type:{this.state.selectedEmailTemplate.length !== 0 ? this.state.selectedEmailTemplate.Type : ''}</label>

                    </div>

                    <div className="col-xs-12 col-sm-6 text-right email_temp_right">
                      <a href={null} onClick={this.deleteTemplate} className="pull-right">
                        <button className="pull-right plus_icon_table btn_plus_cusotm btn_custom_list bt_em_temp">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="1700 296 15 19.286"
                          >
                            <path
                              id="ic_delete_24px"
                              className="cls-1"
                              d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z"
                              transform="translate(1695 293)"
                            />
                          </svg>
                        </button>
                      </a>
                      <a href={null} onClick={this.editTemplate} className="pull-right" >
                        <button
                          type="button"
                          className=" pull-right plus_icon_table btn_plus_cusotm btn_custom_list bt_em_temp"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="1656.776 299 17.515 18.003"
                          >
                            <path
                              id="ic_create_24px"
                              className="cls-1"
                              d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                              transform="translate(1653.776 296.002)"
                            />
                          </svg>
                        </button>
                      </a>

                      <Link to="/email_templates_add" className="pull-right">
                        <button
                          type="button"
                          className="pull-right plus_icon_table btn_plus_cusotm btn_custom_list bt_em_temp"
                        >
                          +
                        </button>
                      </Link>

                      <button
                        type="button"
                        className="pull-right edit_place_email"
                        data-toggle="modal"
                        data-target="#myModal_edit_placeholder"
                      >
                        Edit Placeholders
                      </button>
                    </div>

                    {this.state.selectedEmailTemplate.length === 0 ?
                      <div className="mrg_top_email_temp">Email Template Preview</div>
                      : ""}
                    {this.state.selectedEmailTemplate.length !== 0 ?
                      <div className="mrg_top_email_temp preview_body emailTemp_mrgTop">

                        <div className="row">
                          <div className="col-sm-8">
                            <div className="emailadd_left_boxarea">
                              <div className="col-sm-3 mrg_top5">Template Name:</div>
                              <div className="col-sm-3 col-md-4">
                                <input name="templateName" className="name_w_email" value={this.state.selectedEmailTemplate.Name} type="text" readOnly={true} />
                              </div>
                              <div className="clear10"></div>
                              <div className="col-sm-3 mrg_top5">Template Type:</div>
                              <div className="col-sm-3 col-md-4">
                                <select className="name_w_email_select" value={this.state.selectedEmailTemplate.Type} name="templateType" readOnly={true} disabled={true} >
                                  <option>{this.state.selectedEmailTemplate.Type}</option>
                                </select>
                              </div>
                              {this.state.selectedEmailTemplate.Default === true ? <div className="mrg_top5">
                                <div className="col-xs-7 col-sm-2 col-md-1">Default:</div>
                                <div className="col-xs-5 col-sm-1">
                                  <label className="check_box_pop">
                                    <input type="checkbox" name="default" readOnly={true} checked={true} />
                                    <span className="checkmark left0_check"></span>
                                  </label>
                                </div>

                              </div> : ""}
                              {this.state.selectedEmailTemplate.Private === true ? <div className="mrg_top5">
                                <div className="col-xs-7 col-sm-2 col-md-1">Private:</div>
                                <div className="col-xs-5 col-sm-1">
                                  <label className="check_box_pop">
                                    <input type="checkbox" name="private" readOnly={true} checked={true} />
                                    <span className="checkmark left0_check"></span>
                                  </label>
                                </div>
                              </div> : ''}
                              <div className="clear20"></div>
                              <div className="col-sm-12 p0">
                                <div className="col-sm-3 mrg_top10">Attachment:</div>
                                <div className="col-sm-9 col-md-4 text-left">
                                  {(this.state.selectedEmailTemplate.Attachments.length > 0) ? this.state.selectedEmailTemplate.Attachments.map((val, key) => {
                                    return (
                                      <a href={val.url} key={key} download>
                                        <img src={val.url} key={key} className="images" alt="Img" />

                                      </a>
                                    )
                                  }) : <p>No Attachments</p>}
                                  <div>
                                  </div>
                                </div>
                                <div className="clear20"></div>
                              </div>
                              <div className="clear20"></div>
                              <hr />
                              <div className="clear20"></div>
                              <div className="email_temp_add_box3">
                                <div className="col-xs-5 col-sm-5 col-md-7 mrg_top5">Subject: <span className="email_temp_add_box3_spn">*</span></div>
                                <div className="col-xs-7 col-sm-7 col-md-5">

                                </div>
                                <div className="clear10"></div>
                                <div className="col-sm-12"><input name="subject" value={this.state.selectedEmailTemplate.Subject} readOnly={true} type="text" />
                                </div>
                                <div className="clear20"></div>
                                <div className="col-sm-7">Message: <span className="email_temp_add_box3_spn">*</span></div>
                                <div className="clear10"></div>
                                <div className="col-sm-12">
                                  <div className="form-group">
                                    <CKEditor
                                      id="emailTempAd_content"
                                      name="emailTempAd_content"
                                      config={{ readOnly: true }}
                                      activeClass="p10"
                                      content={this.state.selectedEmailTemplate.BodyHTML}

                                    />


                                  </div>
                                </div>
                                <div className="clear20"></div>
                              </div>
                            </div>
                          </div>
                        </div>



                      </div>
                      : ''}


                  </div>


                  <div className="clear10" />
                </div>
              </div>
            </div>

            <div className="clear10" />
          </div>
        </div>

        <div id="myModal_edit_placeholder" className="modal fade" role="dialog">
          <div className="modal-dialog modal_width_email_pop1 no_scroll_poppppp modal-dialog-centered">

            <div className="modal-content popups_email_1_middle posation_placehoder">
              <div className="modal-header modal_close_email">
                <button
                  type="button"
                  className="close close_modal_approval"
                  data-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title modal_customefield">Placeholders</h4>
              </div>
              <div className="modal-body modal_palce_scroll">
                <div className="col-sm-12 p0">
                  <div className="col-sm-10 p0">
                    <a href={null} className="pull-left">
                      <div className="search_area_approval">
                        <input onChange={(event) => this.pf_SearchHandler(event)}
                          id="emailTemp_placeholderSearch"
                          name="emailTemp_placeholderSearch"
                          type="text"
                          className="search_email_temp"
                          value={this.state.emailTemp_placeholderSearch}

                        />
                        <img
                          src={SearchSvg}
                          className="search_img"
                          width="20"
                          height="20"
                          alt=""
                        />
                      </div>
                    </a>
                  </div>
                  <div className="col-sm-2">
                    <a
                      href={null}
                      className="pull-right email_temp_pop1_plus cursorPointer"
                      data-toggle="modal"
                      data-target="#placeholder_form3"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                      >
                        <path
                          id="ic_add_24px"
                          d="M20,13.571H13.571V20H11.429V13.571H5V11.429h6.429V5h2.143v6.429H20Z"
                          transform="translate(-5 -5)"
                          fill="#989898"
                        />
                      </svg>
                    </a>
                  </div>

                  <div className="clear5" />
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="table_formpck_pop_1 company_pop pop_overflow_email3 icons_setting table-responsive">
                    <table className="table table_res_placehoder_w"
                      width="100%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <thead>
                        <tr>
                          <th width="20%" align="left" valign="middle">
                            Name
                          </th>
                          <th width="20%" align="left" valign="middle">
                            DB Value
                          </th>
                          <th width="30%" align="left" valign="middle">
                            URL
                          </th>
                          <th width="20%" align="left" valign="middle">
                            Text
                          </th>
                          <th width="30%" align="left" valign="middle" />
                        </tr>
                      </thead>


                      <tbody>
                        {this.state.placeHolderFormTableData.map(items => {
                          return (
                            <tr key={items.Guid}>
                              <td align="left" valign="middle">
                                {items.Name}
                              </td>
                              <td align="left" valign="middle">
                                {items.DBValue}
                              </td>
                              <td align="left" valign="middle">
                                {items.Url}
                              </td>
                              <td align="left" valign="middle">
                                {items.Text}
                              </td>
                              <td align="center">
                                <a href={null} onClick={this.deleteplaceholder.bind(this, items.Guid)} className="icon_edit_email_temp">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="1700 296 15 19.286"
                                  >
                                    <path
                                      id="ic_delete_24px"
                                      className="cls-1"
                                      d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z"
                                      transform="translate(1695 293)"
                                    />
                                  </svg>
                                </a>

                                <a href={null} onClick={() => this.editPlaceHolderData(items.Guid)} data-toggle="modal" data-target="#placeholder_form3" className="icon_del_email_temp icon_template_setting_pencill">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="1656.776 299 17.515 18.003"
                                  >
                                    <path
                                      id="ic_create_24px"
                                      className="cls-1"
                                      d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                      transform="translate(1653.776 296.002)"
                                    />
                                  </svg>
                                </a>
                              </td>
                            </tr>
                          )
                        })}


                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="clear20" />
              </div>
            </div>
          </div>
        </div>



        <div id="placeholder_form3" className="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
          <div className="modal-dialog modal_width_email_pop2 modal-dialog-centered no_scroll_poppppp">

            <div className='modal-content  PlaceHolderForm3FullExtHeight' >
              <div className="modal-header modal_close_email">
                <button
                  type="button"
                  className="close close_modal_approval"
                  data-dismiss="modal"
                  onClick={this.clearStateplaceholder}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title modal_customefield">
                  Placeholders Form
                </h4>
              </div>
              <div className="modal-body  placeholder_from_pop4">
                <div className="col-sm-12 p0">
                  <div className="col-sm-4 col-md-3 email_pop2_label_input">
                    Name:
                  </div>
                  <div className="col-sm-4 col-md-8 emailpop2_plc_form">
                    <input type="text" id="emailTemp_pffName" name="emailTemp_pffName" value={this.state.emailTemp_pffName} onChange={this.pff_inputHandler} />
                    <p className={"error validation " + (this.state.pffErrors.emailTemp_pffNameErr ? '' : 'displayNone')}>{this.state.pffErrors.emailTemp_pffNameErr}</p>
                  </div>
                  <div className="clear20" />
                  <div className="col-sm-4 col-md-3 email_pop2_label_input">
                    DB Value:
                  </div>
                  <div className="col-sm-4 col-md-8 emailpop2_plc_form">
                    <input type="text" id="emailTemp_pffDbValue" name="emailTemp_pffDbValue" value={this.state.emailTemp_pffDbValue} onChange={this.pff_inputHandler} />
                    <p className={"error validation " + (this.state.pffErrors.emailTemp_pffDbValueErr ? '' : 'displayNone')}>{this.state.pffErrors.emailTemp_pffDbValueErr}</p>
                  </div>
                  <div className="clear20" />
                  <div className="col-xs-2 col-sm-4 col-md-3 email_pop2_label_input">
                    Link:
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-8 emailpop2_plc_form">
                    <div className="checkbox email_checkbox chkbox_mrg_top_email_tmpppp">
                      <input id="checkbox25" type="checkbox" name="emailTemp_pffLinkCheck" checked={this.state.emailTemp_pffLinkCheck} onChange={this.pff_inputHandler} />
                      <label htmlFor="checkbox25" />
                    </div>
                  </div>

                  <div className="clear10" />

                  <div className="col-sm-12 p0">
                    <div className='Show_MainContent' >
                      <div className="col-sm-4 col-md-3 email_pop2_label_input">
                        URL:
                    </div>
                      <div className="col-sm-4 col-md-8 emailpop2_plc_form">
                        <input type="text" id="emailTemp_pffUrl" name="emailTemp_pffUrl" value={this.state.emailTemp_pffUrl} onChange={this.pff_inputHandler} />
                        <p className={"error validation " + (this.state.pffErrors.emailTemp_pffUrlErr ? '' : 'displayNone')}>{this.state.pffErrors.emailTemp_pffUrlErr}</p>
                      </div>
                      <div className="clear20" />
                      <div className="col-sm-4 col-md-3 email_pop2_label_input">
                        Text:
                    </div>
                      <div className="col-sm-4 col-md-8 emailpop2_plc_form">
                        <input type="text" id="emailTemp_pffText" name="emailTemp_pffText" value={this.state.emailTemp_pffText} onChange={this.pff_inputHandler} />
                        <p className={"error validation " + (this.state.pffErrors.emailTemp_pffTextErr ? '' : 'displayNone')}>{this.state.pffErrors.emailTemp_pffTextErr}</p>
                      </div>

                    </div>
                    <div className="clear5" />

                    <div className="col-sm-12">
                      <div className="email_btn_save">
                        {(this.state.tmp_editable_guid === "") ?
                          <a
                            href={null}
                            className="btn_save_employee cursorPointer"
                            onClick={this.pff_submitHandler}
                          >
                            Save
                        </a> :
                          <a
                            href={null}
                            className="btn_save_employee cursorPointer"
                            onClick={this.pff_submitHandler}
                          >
                            Update
                        </a>}
                        <a
                          href={null}
                          id="close-modalplaceholder"
                          onClick={this.clearStateplaceholder}
                          className="btn_cancel_employee cursorPointer"
                          data-dismiss="modal"
                        >
                          Cancel
                        </a>
                      </div>
                      <div className="col-md-3"> </div>
                    </div>
                  </div>

                  <div className="clear20" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
