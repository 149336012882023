import React, { Component } from 'react';
import "../../Assets/stimulsoft/stimulsoft.viewer.office2013.whiteblue.css";
class PayrollBatchReports extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount = async () => {
    await this.props.childProps.isAuthentication();
    if (!this.props.childProps.isAuthenticated) {
      this.props.history.push('/login');
    } else {

      var previewData = localStorage.getItem('getreports');

      var viewer = new window.Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);


      var report = new window.Stimulsoft.Report.StiReport();


      report.loadFile('/reports/PayrollBatchReport.mrt');

      report.dictionary.databases.clear();
      report.regData("Batch", "Batch", previewData);

      viewer.report = report;


      viewer.renderHtml('viewer');

    }
  }
  render() {

    return (
      <div id="viewer"></div>
    );
  }
}

export default PayrollBatchReports;
