import React, { Component } from 'react';
import Login_logo from '../../Assets/images/login_logo.svg';
import Login_sm_logo from '../../Assets/images/login_sm_logo.png';
import login_down_arrow from '../../Assets/images/login_down_arrow.svg';
import { Link } from "react-router-dom";
import './Verifaction_Code.css';
import md5 from 'md5';
import { toast } from 'react-toastify';
import { Auth } from "aws-amplify";
import { API } from "aws-amplify";

var ip = require('ip');

class Verifaction_code extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      confirmation_code: '',
      email: '',
      password: '',
      checkUserLogin: false,
      identityPoolUserId: "",
      checkUserFromDynamo: false,
      UpdateidentityPoolUserId: false,
      recordactivity: false,
      companies: [],
      phoneNumber: ''
    }
  }

  componentDidMount = () => {
    if (this.props.history.location.state !== undefined) {
      toast.warn('Please Enter Verification Code Received On Your Mobile.');
      this.setState({
        email: this.props.history.location.state.state.email,
        password: this.props.history.location.state.state.password,
        companies: this.props.history.location.state.state.resultCompanies

      })
    }
    const fullNumber = this.props.history.location.state.state.MobileNumber;
    const lastDigits = fullNumber.substr(fullNumber.length - 3);
    this.setState({
      phoneNumber: lastDigits
    })
  }

  onClickHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  confirmation = async () => {
    this.setState({ isLoading: true });
    await Auth.confirmSignUp(this.state.email, this.state.confirmation_code)
      .then(res => {
       // toast.success('User Confirmed Successfully , Please Login And Select Your Company.');
        this.setState({
          checkUserLogin: true
        });
        // this.props.history.push('/login');
      })
      .catch(err => {
        toast.error(err.message);
        this.setState({ isLoading: false });
      });

    if (this.state.checkUserLogin) {
      await Auth.signIn(this.state.email, this.state.password)
        .then(data => {
          this.setState({
            identityPoolUserId: data.storage['aws.cognito.identity-id.' + process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID],
          });
        //  toast.success('User Successfully logged in Cognito.');
          API.post("userget", "/userget", { body: { "user_email": this.state.email, "user_password": md5(this.state.password) } })
            .then(result => {
              if (result.length > 0) {
               // toast.success('User Successfully logged in Dynamo DB.');
                localStorage.setItem('userGuid', result[0].Guid);
                localStorage.setItem('companylist', JSON.stringify(this.state.companies));
                localStorage.setItem('UserFullName', result[0].FirstName + (result[0].LastName ? ' ' + result[0].LastName : ''));
                localStorage.setItem('UserLogin', result[0].UserLogin);
                localStorage.setItem('EmailAddress', result[0].EmailAddress);
                localStorage.setItem('role', result[0].UserType);
                localStorage.setItem('tenantid', result[0].TenantGuid);
                API.post("updatepoolid", "/updatepoolid", {
                  body: {
                    "Guid": localStorage.getItem('userGuid'),
                    "Poolid": this.state.identityPoolUserId
                  }
                })
                  .then(data => {
                   // toast.success('User Origin UpdatedSuccessfully.');

                    this.props.history.push("/company-select",
                      {
                        companies: this.state.companies,
                        mustchange: result[0].PasswordChange
                      });
                  }).catch(err => {
                    toast.error(err.message);
                  });
              } else {
                this.setState({ isLoading: false });
                toast.error("Invalid Username or password");
                this.setState({
                  LoginText: 'Login'
                })
                Auth.signOut();
                this.props.history.push('/login');
              }
            })
            .catch(error => {
              toast.error(error.message);
            });

        })
        .catch(err => {
          if (err.message === 'User is not confirmed.') {
            this.props.history.push("/verification_code", {
              state: {
                confirmed: false,
                email: this.state.email,
                password: this.state.password,
                resultCompanies: this.state.companies
              }
            });
          }
          else {
            toast.error(err.message);
          }
          this.setState({
            LoginText: 'Login'
          })
        });
    }




  }

  handleClick(e) {
    e.preventDefault();
  }
  render() {
    return (
      <div className="col-sm-12 p0">
        {this.state.isLoading ? <div className="se-pre-con"></div> : ''}

        <div className="col-sm-6 p0 small_display_none">
          <div className="verify_left_area"></div>

        </div>


        <div className="col-sm-6 p0 verify_right_h">

          <div className="verify_right_middle">
            <div className="col-sm-12 verify_bg1"><Link to=""><img src={Login_logo} alt="" /></Link></div>
            <div className="col-sm-12 sm_logo_login"><Link to=""><img src={Login_sm_logo} alt="" /></Link></div>

            <div className="col-sm-12 verify_h2 small_display_none">
              <h2>In a few moments a 6-digit verification code will be sent to XXXX-XXX-{this.state.phoneNumber}.</h2>
            </div>

            <div className="col-sm-12 verify_code_box">
              <input name="confirmation_code" onChange={this.onClickHandler} value={this.state.confirmation_code} type="text" placeholder="Verification code" />


            </div>


            <div className="clearfix"></div>
            <div className="col-sm-6 p0_left"><a hreaf="javascript:void(0);" onClick={this.confirmation} className="verify_btn_login">Login</a></div>
            <div className="col-sm-6 p0_right"><Link to="/login" className="verify_btn_cancel">Cancel</Link></div>
            <div className="clear20"></div>

            <div className="col-sm-12 text-center full_display_none accept_policy"> I accept the Terms of Use and Privacy Policy</div>








          </div>











          <div className="clearfix"></div>
          <div className="verify_policy_label small_display_none">Term of use. Privacy policy</div>
          <div className="clear20"></div>
          <div className="more_detail">
            <Link to="" onClick={this.onHref}>More Details
					 <div className="clear5"></div>
              <img src={login_down_arrow} alt="" />

            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Verifaction_code;