import React, { Component } from 'react';
import HeaderMob from '../Common/HeaderMob/HeaderMob';
import './Comments.css';
import { Link } from 'react-router-dom';

class Comments extends Component {
    render() {
        return (
            <div>
                <HeaderMob />

                <div className="col-sm-12 p0 text-center analytics_mbl_heading">
                    Comments
        </div>
                <div className="col-md-12 col-xs-12 analytics_wrapper timecrad_coments_display" >



                    {/* <!-- Modal content--> */}


                    <div className="user_document">
                        <div className="col-sm-12 col-xs-12 cments_timecard_bdr_btm">
                            <div className="user_document_inner_comnt">
                                <div className="col-sm-6 p0 col-xs-6">
                                    <label>Patrick Plummer</label>

                                </div>
                                <div className="col-sm-6 col-xs-6 p0 text-right">
                                    <p>5/07/2018 1:10pm</p>
                                </div>
                                <div className="clearfix"> </div>
                                <div className="col-xs-12 p0">
                                    <p>I've attached document</p>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="col-sm-12 col-xs-12 cments_timecard_bdr_btm">
                            <div className="user_document_inner_comnt">
                                <div className="col-sm-6 p0 col-xs-6">
                                    <label>Patrick Plummer</label>

                                </div>
                                <div className="col-sm-6 col-xs-6 p0 text-right">
                                    <p>5/07/2018 1:10pm</p>
                                </div>
                                <div className="clearfix"> </div>
                                <div className="col-xs-12 p0">
                                    <p>I've attached document</p>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="col-sm-12 col-xs-12 cments_timecard_bdr_btm">
                            <div className="user_document_inner_comnt">
                                <div className="col-sm-6 p0 col-xs-6">
                                    <label>Patrick Plummer</label>

                                </div>
                                <div className="col-sm-6 col-xs-6 p0 text-right">
                                    <p>5/07/2018 1:10pm</p>
                                </div>
                                <div className="clearfix"> </div>
                                <div className="col-xs-12 p0">
                                    <p>I've attached document</p>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="col-sm-12 col-xs-12 cments_timecard_bdr_btm">
                            <div className="user_document_inner_comnt">
                                <div className="col-sm-6 p0 col-xs-6">
                                    <label>Patrick Plummer</label>

                                </div>
                                <div className="col-sm-6 col-xs-6 p0 text-right">
                                    <p>5/07/2018 1:10pm</p>
                                </div>
                                <div className="clearfix"> </div>
                                <div className="col-xs-12 p0 ">
                                    <p>I've attached document</p>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="col-sm-12 col-xs-12 cments_timecard_bdr_btm">
                            <div className="user_document_inner_comnt">
                                <div className="col-sm-6 p0 col-xs-6">
                                    <label>Patrick Plummer</label>

                                </div>
                                <div className="col-sm-6 col-xs-6 p0 text-right">
                                    <p>5/07/2018 1:10pm</p>
                                </div>
                                <div className="clearfix"> </div>
                                <div className="col-xs-12 p0 ">
                                    <p>I've attached document</p>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="col-sm-12 col-xs-12 cments_timecard_bdr_btm">
                            <div className="user_document_inner_comnt">
                                <div className="col-sm-6 p0 col-xs-6">
                                    <label>Patrick Plummer</label>

                                </div>
                                <div className="col-sm-6 col-xs-6 p0 text-right">
                                    <p>5/07/2018 1:10pm</p>
                                </div>
                                <div className="clearfix"> </div>
                                <div className="col-xs-12 p0">
                                    <p>I've attached document</p>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="col-sm-12 col-xs-12 cments_timecard_bdr_btm">
                            <div className="user_document_inner_comnt">
                                <div className="col-sm-6 p0 col-xs-6">
                                    <label>Patrick Plummer</label>

                                </div>
                                <div className="col-sm-6 col-xs-6 p0 text-right">
                                    <p>5/07/2018 1:10pm</p>
                                </div>
                                <div className="clearfix"> </div>
                                <div className="col-xs-12 p0">
                                    <p>I've attached document</p>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="col-sm-12 col-xs-12 cments_timecard_bdr_btm">
                            <div className="user_document_inner_comnt">
                                <div className="col-sm-6 p0 col-xs-6">
                                    <label>Patrick Plummer</label>

                                </div>
                                <div className="col-sm-6 col-xs-6 p0 text-right">
                                    <p>5/07/2018 1:10pm</p>
                                </div>
                                <div className="clearfix"> </div>
                                <div className="col-xs-12 p0">
                                    <p>I've attached document</p>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                    <div className="clear10"></div>
                    <div className="comment_area comment_area_cmnts">
                        <label>Add a Comment</label>
                        <textarea className="form-control" rows="3" id="comment"></textarea>
                    </div>
                    <div className="comment_btn_area">
                        <a hreaf="javascript:void(0);" className="btn_save_analytics btn_cmnts_save_cncl btn_comnts_ad_mssgs pull-left" data-dismiss="modal">Add a Comment</a>
                        {/* <!--  < a hreaf="javascript:void(0);" className="btn_save_analytics btn_add_msg_timecard pull-right">Add Message <i className="fa fa-angle-down" aria-expanded="true"></i></a> --> */}


                    </div>

                    <div className="dropdown comment_btn_area coments_add_msg_btn pull-right">
                        <button className="btn  btn_cmnts_save_cncl btn_save_analytics dropdown-toggle " type="button" data-toggle="dropdown">Add Message
{/* <!--   <span className="caret"></span> --> */}
                        </button>
                        <ul className="dropdown-menu down_coments">
                            <li><Link to="/attach_timecard">Attach TimeCard</Link></li>

                        </ul>
                    </div>


                    <div className="clear50"></div>










                </div>
            </div>
        )
    }
}
export default Comments;
