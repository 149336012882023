import React, { Component } from "react";
import ic_search from "./../../Assets/images/ic_search_-1.svg";
import select_paste from "./../../Assets/images/select_paste.png";
import excel from "./../../Assets/images/excel.png";
import "./Add_Times_2.css";
import Header1 from "./../Common/Header1/Header1";
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import TimePicker from 'rc-time-picker';
import { API } from "aws-amplify";
import moment from 'moment';
import 'moment-timezone';
import 'rc-time-picker/assets/index.css';
import "react-datepicker/dist/react-datepicker.css";
import InputMask from 'react-input-mask';
import $ from 'jquery';
import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var ip = require('ip');
class Add_Times_2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      guidsofadtimes: [],
      mask_chartSort: '',
      mask_chartCode: '',
      AdTimes2_NameSearchModal_SelectedTab: 'FirstName',
      AdTimes2_NameModal_FirstName_Toggle: false,
      AdTimes2_NameModal_LastName_Toggle: false,
      AdTimes2_NameModal_Email_Toggle: false,
      AdTimes2_NameModal_Mobile_Toggle: false,
      AdTimes2_NameModal_Dept_Toggle: false,
      AdTimes2_NameModal_Position_Toggle: false,
      AdTimes2_NameModal_ApproverGroup_Toggle: false,
      adTimes_NonTerminateEmpSearch: [],
      toggleEmployeeCaret: false,
      toggleCaretState: false,
      AdTimes2SelectedTab: 'FirstName',
      AdTimes_2_FirstName_Toggle: false,
      AdTimes_2_LastName_Toggle: false,
      AdTimes_2_Position_Toggle: false,
      AdTimes_2_Department_Toggle: false,
      AdTimes_2_TravelTo_Toggle: false,
      AdTimes_2_Start_Toggle: false,
      AdTimes_2_Meal_Toggle: false,
      AdTimes_2_Wrap_Toggle: false,
      AdTimes_2_TravelFrom_Toggle: false,
      AdTimes_2_PmtFlag_Toggle: false,
      sort_adTimes2_employees_toggle: false,
      checkedarray: [],
      adTimes_NonTerminateEmp: [],
      excelData: [],
      detailed_data: "",
      IsAllAdTimes2CheckBoxSelected: false,
      edit_adtimes2: false,
      isLoading: true,
      tmp_Guid: '',
      guid_edit: '',
      adtimes2_tableSearchBox: '',
      AdTimes2_EditModal_Name: '',
      AdTimes2_EditModal_PayAs: '',
      AdTimes2_EditModal_TravelTo: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      AdTimes2_EditModal_StartWork: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      AdTimes2_EditModal_MealBreak: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      AdTimes2_EditModal_MealBreak_total: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      AdTimes2_EditModal_FinishWork: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      AdTimes2_EditModal_TravelFrom: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      AdTimes2_EditModal_TotalHours: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),

      AdTimes2_EditModal_HourlyRate: '',
      AdTimes2_EditModal_PmtFlag: '',
      AdTimes2_EditModal_Notes: '',
      AdTimes2_EditModal_PaymentFlag: '',
      AdTimes2_EditModal_ChartSort: '',
      AdTimes2_EditModal_ChartCode: '',
      AdTimes2_EditModal_WorkState: '',


      Adtimes2_ModalSearchName_Search: '',
      Adtimes2_Modal2SearchName_TerminatedChkbx: false,


      AdTimes2_AdTimeForm_Date: new Date(),
      AdTimes2_AdTimeForm_Unit: '',
      AdTimes2_AdTimeForm_TravelTo: '',
      AdTimes2_AdTimeForm_CrewCall: '',
      AdTimes2_AdTimeForm_MealBreak: '',
      AdTimes2_AdTimeForm_CrewWrap: '',
      AdTimes2_AdTimeForm_TravelFrom: '',
      AdTimes2_AdTimeForm_Notes: '',
      AdTimes2_editStartMB1: '01:00:04',
      AdTimes2_editFinishMB1: '02:00:03',
      AdTimes2_editStartMB2: '03:00:02',
      AdTimes2_editFinishMB2: '04:00:01',
      AdTimes2_editStartMB3: '05:00:05',
      AdTimes2_editFinishMB3: '07:00:13',





      AdTimes2_ExcelSelect: '',
      IsAdTimes2_ExcelSelectValid: false,



      AdTimes2_SelectPaste_SelectType: '',
      IsAdTimes2_SelectPaste_SelectTypeValid: false,
      AdTimes2_SelectPaste_PastHere: '',


      AdTimes2_Reports_Dropdown: '',
      IsAdTimes2_Reports_DropdownValid: false,




      IsAdTimes2_AdTimeForm_DateValid: false,
      IsAdTimes2_AdTimeForm_UnitValid: false,
      IsAdTimes2_AdTimeForm_TravelToValid: false,
      IsAdTimes2_AdTimeForm_CrewCallValid: false,
      IsAdTimes2_AdTimeForm_MealBreakValid: false,
      IsAdTimes2_AdTimeForm_CrewWrapValid: false,
      IsAdTimes2_AdTimeForm_TravelFromValid: false,
      IsAdTimes2_AdTimeForm_NotesValid: false,










      IsAdTimes2_EditModal_NameValid: false,
      IsAdTimes2_EditModal_PayAsValid: false,
      IsAdTimes2_EditModal_TravelToValid: false,
      IsAdTimes2_EditModal_StartWorkValid: false,
      IsAdTimes2_EditModal_MealBreakValid: false,
      IsAdTimes2_EditModal_FinishWorkValid: false,
      IsAdTimes2_EditModal_TravelFromValid: false,
      IsAdTimes2_EditModal_HourlyRateValid: false,
      IsAdTimes2_EditModal_PmtFlagValid: false,
      IsAdTimes2_EditModal_PaymentFlagValid: false,
      IsAdTimes2_EditModal_ChartSortValid: false,
      IsAdTimes2_EditModal_ChartCodeValid: false,
      IsAdTimes2_EditModal_WorkStateValid: false,
      IsAdTimes2_EditModal_CTXValid: false,
      IsAdTimes2_EditModal_INValid: false,







      AdTimes2_EditModalErrors: {

        AdTimes2_EditModal_Name: '',
        AdTimes2_EditModal_PayAs: '',
        AdTimes2_EditModal_TravelTo: '',
        AdTimes2_EditModal_StartWork: '',
        AdTimes2_EditModal_MealBreak: '',
        AdTimes2_EditModal_FinishWork: '',
        AdTimes2_EditModal_TravelFrom: '',
        AdTimes2_EditModal_HourlyRate: '',
        AdTimes2_EditModal_PmtFlag: '',
        AdTimes2_EditModal_Notes: '',
        AdTimes2_EditModal_PaymentFlag: '',
        AdTimes2_EditModal_ChartSort: '',
        AdTimes2_EditModal_ChartCode: '',
        AdTimes2_EditModal_WorkState: '',


      },



      AdTimes2_AdTimesFormErrors: {
        AdTimes2_AdTimeForm_Date: '',
        AdTimes2_AdTimeForm_Unit: '',
        AdTimes2_AdTimeForm_TravelTo: '',
        AdTimes2_AdTimeForm_CrewCall: '',
        AdTimes2_AdTimeForm_MealBreak: '',
        AdTimes2_AdTimeForm_CrewWrap: '',
        AdTimes2_AdTimeForm_TravelFrom: '',
        AdTimes2_AdTimeForm_Notes: '',
        AdTimes2_editStartMB1: '',
        AdTimes2_editFinishMB1: '',
        AdTimes2_editStartMB2: '',
        AdTimes2_editFinishMB2: '',
        AdTimes2_editStartMB3: '',

        AdTimes2_editFinishMB3: '',
      },


      IsAddTimes2_EditModalFormValid: false,
      IsDetailFormValid: false,






      isAllAdTimes2TableChkbxSelected: false,
      AdTimes2TableData: [],

      adtimes2_non_detuctable_mb1_1: false,
      adtimes2_non_detuctable_mb1_2: false,
      adtimes2_non_detuctable_mb1_3: false,
      adtimes2_non_detuctable_mb2_1: false,
      adtimes2_non_detuctable_mb2_2: false,
      adtimes2_non_detuctable_mb2_3: false,
      adtimes2_non_detuctable_mb3_1: false,
      adtimes2_non_detuctable_mb3_2: false,
      adtimes2_non_detuctable_mb3_3: false,

      PayasList: [
        { Flag: "CON", Value: "Worked" },
        { Flag: "TIL", Value: "Time in Lieu Day" },
        { Flag: "DNW", Value: "Did Not Work" },
        { Flag: "CON", Value: "Public Holiday Taken" },
        { Flag: "PUB", Value: "Public Holiday Worked" },
        {
          Flag: "SIC",
          Value: "Sick"
        },
        {
          Flag: "HOL",
          Value: "Annual Leave Taken"
        },
        {
          Flag: "OTH",
          Value: "Other Leave"
        },
        {
          Flag: "WCP",
          Value: "Workers Compensation"
        }
      ],
      AdTimes2_editModalStartMB1: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      AdTimes2_editModalFinishMB1: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      AdTimes2_editModalStartMB2: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      AdTimes2_editModalFinishMB2: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      AdTimes2_editModalStartMB3: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      AdTimes2_editModalFinishMB3: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      ad_times2_non_deductible_mb1_1: false,
      ad_times2_non_deductible_mb1_2: false,
      ad_times2_non_deductible_mb1_3: true,
      ad_times2_non_deductible_mb2_1: false,
      ad_times2_non_deductible_mb2_2: false,
      ad_times2_non_deductible_mb2_3: true,
      ad_times2_non_deductible_mb3_1: false,
      ad_times2_non_deductible_mb3_2: false,
      ad_times2_non_deductible_mb3_3: true,
      ad_times_all_employess: [],
      ad_times_all_employess_search: [],
      selectedEmployee: null,
      detail_mealdisable: false,
      AdTimes2_searchedData: [],
      sort_adTimes2_toggle: false,
      employee_meal: false,
      emp_position: '',
      emp_department: '',
      emp_EmpGuid: '',
      trackingfields: []
    }
  }

  EmployeeToggleCaret = async (name) => {

    await this.setState({
      toggleEmployeeCaret: !this.state.toggleEmployeeCaret
    })
    if (this.state.toggleEmployeeCaret === false) {
      $("#" + name).removeAttr("style")
    }
  }
  sortEmployees = async (Tab, name) => {



    await this.setState({
      toggleEmployeeCaret: !this.state.toggleEmployeeCaret
    })
    if (this.state.toggleEmployeeCaret === false) {
      $("#" + name).removeAttr("style")
    }

    localStorage.setItem('Ad2EmpSelTab', Tab);
    if (this.state.Adtimes2_Modal2SearchName_TerminatedChkbx) {



      if (Tab === 'FirstName') {

        $("#FirstNameCaret").show()
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();

        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false,
        })

        if (this.state.AdTimes2_NameModal_FirstName_Toggle) {
          await this.setState({
            AdTimes2_NameModal_FirstName_Toggle: false
          })
        } else {
          await this.setState({
            AdTimes2_NameModal_FirstName_Toggle: true
          })
        }
        if (this.state.AdTimes2_NameModal_FirstName_Toggle) {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(function (a, b) {
              var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
              var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        } else {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(function (a, b) {
              var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
              var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
              if (nameA > nameB) {
                return -1;
              }
              if (nameA < nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        }


      }
      if (Tab === 'LastName') {

        $("#FirstNameCaret").hide();
        $("#LastNameCaret").show()
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();

        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false,
        })

        if (this.state.AdTimes2_NameModal_LastName_Toggle) {
          await this.setState({
            AdTimes2_NameModal_LastName_Toggle: false
          })
        } else {
          await this.setState({
            AdTimes2_NameModal_LastName_Toggle: true
          })
        }
        if (this.state.AdTimes2_NameModal_LastName_Toggle) {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(function (a, b) {
              var nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
              var nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        } else {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(function (a, b) {
              var nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
              var nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
              if (nameA > nameB) {
                return -1;
              }
              if (nameA < nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        }

      }
      if (Tab === 'Email') {


        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").show()
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();


        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false,
        })

        if (this.state.AdTimes2_NameModal_Email_Toggle) {
          await this.setState({
            AdTimes2_NameModal_Email_Toggle: false
          })
        } else {
          await this.setState({
            AdTimes2_NameModal_Email_Toggle: true
          })
        }
        if (this.state.AdTimes2_NameModal_Email_Toggle) {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(function (a, b) {
              var nameA = a.NewEmail.toUpperCase(); // ignore upper and lowercase
              var nameB = b.NewEmail.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        } else {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(function (a, b) {
              var nameA = a.NewEmail.toUpperCase(); // ignore upper and lowercase
              var nameB = b.NewEmail.toUpperCase(); // ignore upper and lowercase
              if (nameA > nameB) {
                return -1;
              }
              if (nameA < nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        }

      }
      if (Tab === 'Mobile') {


        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").show()
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();


        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false,
        })

        if (this.state.AdTimes2_NameModal_Mobile_Toggle) {
          await this.setState({
            AdTimes2_NameModal_Mobile_Toggle: false
          })
        } else {
          await this.setState({
            AdTimes2_NameModal_Mobile_Toggle: true
          })
        }
        if (this.state.AdTimes2_NameModal_Mobile_Toggle) {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(function (a, b) {
              var nameA = a.NewMobile.toUpperCase(); // ignore upper and lowercase
              var nameB = b.NewMobile.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        } else {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(function (a, b) {
              var nameA = a.NewMobile.toUpperCase(); // ignore upper and lowercase
              var nameB = b.NewMobile.toUpperCase(); // ignore upper and lowercase
              if (nameA > nameB) {
                return -1;
              }
              if (nameA < nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        }

      }
      if (Tab === 'Department') {


        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").show()
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();

        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false,
        })

        if (this.state.AdTimes2_NameModal_Dept_Toggle) {
          await this.setState({
            AdTimes2_NameModal_Dept_Toggle: false
          })
        } else {
          await this.setState({
            AdTimes2_NameModal_Dept_Toggle: true
          })
        }
        if (this.state.AdTimes2_NameModal_Dept_Toggle) {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(function (a, b) {
              var nameA = a.Department.toUpperCase(); // ignore upper and lowercase
              var nameB = b.Department.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        } else {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(function (a, b) {
              var nameA = a.Department.toUpperCase(); // ignore upper and lowercase
              var nameB = b.Department.toUpperCase(); // ignore upper and lowercase
              if (nameA > nameB) {
                return -1;
              }
              if (nameA < nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        }

      }
      if (Tab === 'Position') {

        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").show()
        $("#ApproverGroupCaret").hide();

        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false,

        })

        if (this.state.AdTimes2_NameModal_Position_Toggle) {
          await this.setState({
            AdTimes2_NameModal_Position_Toggle: false
          })
        } else {
          await this.setState({
            AdTimes2_NameModal_Position_Toggle: true
          })
        }
        if (this.state.AdTimes2_NameModal_Position_Toggle) {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(function (a, b) {
              var nameA = a.Position.toUpperCase(); // ignore upper and lowercase
              var nameB = b.Position.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        } else {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(function (a, b) {
              var nameA = a.Position.toUpperCase(); // ignore upper and lowercase
              var nameB = b.Position.toUpperCase(); // ignore upper and lowercase
              if (nameA > nameB) {
                return -1;
              }
              if (nameA < nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        }

      }
      if (Tab === 'ApproverGroup') {

        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").show()


        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
        })

        if (this.state.AdTimes2_NameModal_ApproverGroup_Toggle) {
          await this.setState({
            AdTimes2_NameModal_ApproverGroup_Toggle: false
          })
        } else {
          await this.setState({
            AdTimes2_NameModal_ApproverGroup_Toggle: true
          })
        }
        if (this.state.AdTimes2_NameModal_ApproverGroup_Toggle) {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(function (a, b) {
              var nameA = a.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
              var nameB = b.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {



                return -1;


              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        } else {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(function (a, b) {
              var nameA = a.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
              var nameB = b.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
              if (nameA > nameB) {
                return -1;
              }
              if (nameA < nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        }

      }

    }
    else {


      if (Tab === 'FirstName') {

        $("#FirstNameCaret").show()
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();

        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false,
        })

        if (this.state.AdTimes2_NameModal_FirstName_Toggle) {
          await this.setState({
            AdTimes2_NameModal_FirstName_Toggle: false
          })
        } else {
          await this.setState({
            AdTimes2_NameModal_FirstName_Toggle: true
          })
        }
        if (this.state.AdTimes2_NameModal_FirstName_Toggle) {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(function (a, b) {
              var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
              var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        } else {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(function (a, b) {
              var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
              var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
              if (nameA > nameB) {
                return -1;
              }
              if (nameA < nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        }


      }
      if (Tab === 'LastName') {

        $("#FirstNameCaret").hide();
        $("#LastNameCaret").show()
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();

        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false,
        })

        if (this.state.AdTimes2_NameModal_LastName_Toggle) {
          await this.setState({
            AdTimes2_NameModal_LastName_Toggle: false
          })
        } else {
          await this.setState({
            AdTimes2_NameModal_LastName_Toggle: true
          })
        }
        if (this.state.AdTimes2_NameModal_LastName_Toggle) {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(function (a, b) {
              var nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
              var nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        } else {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(function (a, b) {
              var nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
              var nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
              if (nameA > nameB) {
                return -1;
              }
              if (nameA < nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        }

      }
      if (Tab === 'Email') {


        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").show()
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();


        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false,
        })

        if (this.state.AdTimes2_NameModal_Email_Toggle) {
          await this.setState({
            AdTimes2_NameModal_Email_Toggle: false
          })
        } else {
          await this.setState({
            AdTimes2_NameModal_Email_Toggle: true
          })
        }
        if (this.state.AdTimes2_NameModal_Email_Toggle) {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(function (a, b) {
              var nameA = a.NewEmail.toUpperCase(); // ignore upper and lowercase
              var nameB = b.NewEmail.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        } else {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(function (a, b) {
              var nameA = a.NewEmail.toUpperCase(); // ignore upper and lowercase
              var nameB = b.NewEmail.toUpperCase(); // ignore upper and lowercase
              if (nameA > nameB) {
                return -1;
              }
              if (nameA < nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        }

      }
      if (Tab === 'Mobile') {


        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").show()
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();


        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false,
        })

        if (this.state.AdTimes2_NameModal_Mobile_Toggle) {
          await this.setState({
            AdTimes2_NameModal_Mobile_Toggle: false
          })
        } else {
          await this.setState({
            AdTimes2_NameModal_Mobile_Toggle: true
          })
        }
        if (this.state.AdTimes2_NameModal_Mobile_Toggle) {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(function (a, b) {
              var nameA = a.NewMobile.toUpperCase(); // ignore upper and lowercase
              var nameB = b.NewMobile.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        } else {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(function (a, b) {
              var nameA = a.NewMobile.toUpperCase(); // ignore upper and lowercase
              var nameB = b.NewMobile.toUpperCase(); // ignore upper and lowercase
              if (nameA > nameB) {
                return -1;
              }
              if (nameA < nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        }

      }
      if (Tab === 'Department') {


        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").show()
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();

        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false,
        })

        if (this.state.AdTimes2_NameModal_Dept_Toggle) {
          await this.setState({
            AdTimes2_NameModal_Dept_Toggle: false
          })
        } else {
          await this.setState({
            AdTimes2_NameModal_Dept_Toggle: true
          })
        }
        if (this.state.AdTimes2_NameModal_Dept_Toggle) {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(function (a, b) {
              var nameA = a.Department.toUpperCase(); // ignore upper and lowercase
              var nameB = b.Department.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        } else {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(function (a, b) {
              var nameA = a.Department.toUpperCase(); // ignore upper and lowercase
              var nameB = b.Department.toUpperCase(); // ignore upper and lowercase
              if (nameA > nameB) {
                return -1;
              }
              if (nameA < nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        }

      }
      if (Tab === 'Position') {

        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").show()
        $("#ApproverGroupCaret").hide();

        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false,

        })

        if (this.state.AdTimes2_NameModal_Position_Toggle) {
          await this.setState({
            AdTimes2_NameModal_Position_Toggle: false
          })
        } else {
          await this.setState({
            AdTimes2_NameModal_Position_Toggle: true
          })
        }
        if (this.state.AdTimes2_NameModal_Position_Toggle) {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(function (a, b) {
              var nameA = a.Position.toUpperCase(); // ignore upper and lowercase
              var nameB = b.Position.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        } else {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(function (a, b) {
              var nameA = a.Position.toUpperCase(); // ignore upper and lowercase
              var nameB = b.Position.toUpperCase(); // ignore upper and lowercase
              if (nameA > nameB) {
                return -1;
              }
              if (nameA < nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        }

      }
      if (Tab === 'ApproverGroup') {

        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").show()


        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
        })

        if (this.state.AdTimes2_NameModal_ApproverGroup_Toggle) {
          await this.setState({
            AdTimes2_NameModal_ApproverGroup_Toggle: false
          })
        } else {
          await this.setState({
            AdTimes2_NameModal_ApproverGroup_Toggle: true
          })
        }
        if (this.state.AdTimes2_NameModal_ApproverGroup_Toggle) {
          await this.setState({
            adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmp.sort(function (a, b) {
              var nameA = a.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
              var nameB = b.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {



                return -1;


              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        } else {
          await this.setState({
            ad_times_all_employess: this.state.ad_times_all_employess.sort(function (a, b) {
              var nameA = a.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
              var nameB = b.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
              if (nameA > nameB) {
                return -1;
              }
              if (nameA < nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
          })
        }

      }



    }







  }
  async validateFields(fieldName, value) {
    let AdTimes2_EditModalErrors = this.state.AdTimes2_EditModalErrors;

    let IsAdTimes2_EditModal_NameValid = this.state.IsAdTimes2_EditModal_NameValid;
    let IsAdTimes2_EditModal_PayAsValid = this.state.IsAdTimes2_EditModal_PayAsValid;
    let IsAdTimes2_EditModal_HourlyRateValid = this.state.IsAdTimes2_EditModal_PmtFlagValid;



    let AdTimes2_AdTimesFormErrors = this.state.AdTimes2_AdTimesFormErrors;
    let IsAdTimes2_AdTimeForm_DateValid = this.state.IsAdTimes2_AdTimeForm_DateValid;
    let IsAdTimes2_AdTimeForm_UnitValid = this.state.IsAdTimes2_AdTimeForm_UnitValid;
    let IsAdTimes2_AdTimeForm_TravelToValid = this.state.IsAdTimes2_AdTimeForm_TravelToValid;
    let IsAdTimes2_AdTimeForm_CrewCallValid = this.state.IsAdTimes2_AdTimeForm_CrewCallValid;
    let IsAdTimes2_AdTimeForm_MealBreakValid = this.state.IsAdTimes2_AdTimeForm_MealBreakValid;
    let IsAdTimes2_AdTimeForm_CrewWrapValid = this.state.IsAdTimes2_AdTimeForm_CrewWrapValid;
    let IsAdTimes2_AdTimeForm_TravelFromValid = this.state.IsAdTimes2_AdTimeForm_TravelFromValid;
    let AdTimes2_editStartMB1 = this.state.AdTimes2_editStartMB1
    let AdTimes2_editFinishMB1 = this.state.AdTimes2_editFinishMB1

    let AdTimes2_editStartMB2 = this.state.AdTimes2_editStartMB2
    let AdTimes2_editFinishMB2 = this.state.AdTimes2_editFinishMB2
    let AdTimes2_editStartMB3 = this.state.AdTimes2_editStartMB3
    let AdTimes2_editFinishMB3 = this.state.AdTimes2_editFinishMB3

    switch (fieldName) {
      case 'AdTimes2_EditModal_StartWork':

        AdTimes2_EditModalErrors.AdTimes2_EditModal_StartWork = value !== '00:00' ? '' : "This field is required";
        break;
      case 'AdTimes2_EditModal_FinishWork':

        AdTimes2_EditModalErrors.AdTimes2_EditModal_FinishWork = value !== '00:00' ? '' : "This field is required";
        break;
      case 'AdTimes2_EditModal_PayAs':
        IsAdTimes2_EditModal_PayAsValid = value !== '';
        AdTimes2_EditModalErrors.AdTimes2_EditModal_PayAs = IsAdTimes2_EditModal_PayAsValid ? '' : "This field is required";
        break;
      case 'AdTimes2_EditModal_HourlyRate':
        IsAdTimes2_EditModal_HourlyRateValid = value.length > 0;
        AdTimes2_EditModalErrors.AdTimes2_EditModal_HourlyRate = IsAdTimes2_EditModal_HourlyRateValid ? '' : "This field is required";
        break;

      case 'AdTimes2_AdTimeForm_Date':
        IsAdTimes2_AdTimeForm_DateValid = value !== null;
        AdTimes2_AdTimesFormErrors.AdTimes2_AdTimeForm_Date = IsAdTimes2_AdTimeForm_DateValid ? '' : "This field is required";
        break;
      case 'AdTimes2_AdTimeForm_Unit':
        IsAdTimes2_AdTimeForm_UnitValid = value.length > 0;
        AdTimes2_AdTimesFormErrors.AdTimes2_AdTimeForm_Unit = IsAdTimes2_AdTimeForm_UnitValid ? '' : "This field is required";
        break;



      default:
        break;
    }
    await this.setState({
      AdTimes2_EditModalErrors: AdTimes2_EditModalErrors,
      IsAdTimes2_EditModal_NameValid: true,
      IsAdTimes2_EditModal_PayAsValid: IsAdTimes2_EditModal_PayAsValid,
      IsAdTimes2_EditModal_TravelToValid: true,
      IsAdTimes2_EditModal_StartWorkValid: true,
      IsAdTimes2_EditModal_MealBreakValid: true,
      IsAdTimes2_EditModal_FinishWorkValid: true,
      IsAdTimes2_EditModal_TravelFromValid: true,
      IsAdTimes2_EditModal_HourlyRateValid: IsAdTimes2_EditModal_HourlyRateValid,

      IsAdTimes2_AdTimeForm_DateValid: IsAdTimes2_AdTimeForm_DateValid,
      IsAdTimes2_AdTimeForm_UnitValid: IsAdTimes2_AdTimeForm_UnitValid,
      IsAdTimes2_AdTimeForm_TravelToValid: IsAdTimes2_AdTimeForm_TravelToValid,
      IsAdTimes2_AdTimeForm_CrewCallValid: IsAdTimes2_AdTimeForm_CrewCallValid,
      IsAdTimes2_AdTimeForm_MealBreakValid: IsAdTimes2_AdTimeForm_MealBreakValid,
      IsAdTimes2_AdTimeForm_CrewWrapValid: IsAdTimes2_AdTimeForm_CrewWrapValid,
      IsAdTimes2_AdTimeForm_TravelFromValid: IsAdTimes2_AdTimeForm_TravelFromValid,
      AdTimes2_AdTimesFormErrors: AdTimes2_AdTimesFormErrors
    });

  }
  validateform = () => {
    const { AdTimes2_editStartMB1, AdTimes2_editFinishMB1, AdTimes2_editStartMB2, AdTimes2_editFinishMB2, AdTimes2_editStartMB3,
      AdTimes2_editFinishMB3, IsAdTimes2_AdTimeForm_DateValid, IsAdTimes2_AdTimeForm_UnitValid, IsAdTimes2_AdTimeForm_TravelToValid, IsAdTimes2_AdTimeForm_CrewCallValid, IsAdTimes2_AdTimeForm_MealBreakValid, IsAdTimes2_AdTimeForm_CrewWrapValid, IsAdTimes2_AdTimeForm_TravelFromValid, IsAdTimes2_AdTimeForm_NotesValid } = this.state;
    this.setState({
      IsDetailFormValid: IsAdTimes2_AdTimeForm_DateValid && IsAdTimes2_AdTimeForm_UnitValid && IsAdTimes2_AdTimeForm_TravelToValid && IsAdTimes2_AdTimeForm_CrewCallValid && IsAdTimes2_AdTimeForm_MealBreakValid && IsAdTimes2_AdTimeForm_CrewWrapValid && IsAdTimes2_AdTimeForm_TravelFromValid && IsAdTimes2_AdTimeForm_NotesValid
    })
  }
  AdTimes2SelectHandler = (e) => {


    if (e.target.name === 'AdTimes2_EditModal_PayAs') {

      if (e.target.selectedIndex > 0) {
        this.setState({
          AdTimes2_EditModal_PayAs: e.target.value,
          IsAdTimes2_EditModal_PayAsValid: true,
        })
      }
      else if (e.target.selectedIndex === 0) {

        this.setState({

          AdTimes2_EditModal_PayAs: '',
          IsAdTimes2_EditModal_PayAsValid: false,
        })
      }
    }

    if (e.target.name === 'AdTimes2_AdTimeForm_MealBreak') {

      if (e.target.selectedIndex > 0) {
        this.setState({
          AdTimes2_AdTimeForm_MealBreak: e.target.value,
          IsAdTimes2_AdTimeForm_MealBreakValid: true,
        })
      }
      else if (e.target.selectedIndex === 0) {
        this.setState({
          AdTimes2_AdTimeForm_MealBreak: '',
          IsAdTimes2_AdTimeForm_MealBreakValid: false,
        })
      }
    }

  }
  adTimes2_SearchHandler = (event) => {








    var AdSearchTab = this.state.AdTimes2SelectedTab;
    var text = event.target.value;


    if (AdSearchTab === 'FirstName') {


      this.setState({
        adtimes2_tableSearchBox: event.target.value
      });
      this.setState({
        AdTimes2TableData: this.state.AdTimes2_searchedData.filter(function (setup) {
          return setup.FirstName.toUpperCase().includes(text.toUpperCase());
        })
      });

    }
    if (AdSearchTab === 'LastName') {


      this.setState({
        adtimes2_tableSearchBox: event.target.value
      });
      this.setState({
        AdTimes2TableData: this.state.AdTimes2_searchedData.filter(function (setup) {
          return setup.LastName.toUpperCase().includes(text.toUpperCase());
        })
      });

    }
    if (AdSearchTab === 'Position') {


      this.setState({
        adtimes2_tableSearchBox: event.target.value
      });
      this.setState({
        AdTimes2TableData: this.state.AdTimes2_searchedData.filter(function (setup) {
          return setup.Position.toUpperCase().includes(text.toUpperCase());
        })
      });

    }

    if (AdSearchTab === 'Department') {


      this.setState({
        adtimes2_tableSearchBox: event.target.value
      });
      this.setState({
        AdTimes2TableData: this.state.AdTimes2_searchedData.filter(function (setup) {
          return setup.Dept.toUpperCase().includes(text.toUpperCase());
        })
      });

    }

    if (AdSearchTab === 'TravelTo') {


      this.setState({
        adtimes2_tableSearchBox: event.target.value
      });
      this.setState({
        AdTimes2TableData: this.state.AdTimes2_searchedData.filter(function (setup) {
          return setup.TravelTo.toUpperCase().includes(text.toUpperCase());
        })
      });

    }

    if (AdSearchTab === 'Start') {


      this.setState({
        adtimes2_tableSearchBox: event.target.value
      });
      this.setState({
        AdTimes2TableData: this.state.AdTimes2_searchedData.filter(function (setup) {
          return setup.Start.toUpperCase().includes(text.toUpperCase());
        })
      });

    }

    if (AdSearchTab === 'Meal') {


      this.setState({
        adtimes2_tableSearchBox: event.target.value
      });
      this.setState({
        AdTimes2TableData: this.state.AdTimes2_searchedData.filter(function (setup) {
          return setup.meals.toUpperCase().includes(text.toUpperCase());
        })
      });

    }

    if (AdSearchTab === 'Wrap') {


      this.setState({
        adtimes2_tableSearchBox: event.target.value
      });
      this.setState({
        AdTimes2TableData: this.state.AdTimes2_searchedData.filter(function (setup) {
          return setup.Finish.toUpperCase().includes(text.toUpperCase());
        })
      });

    }

    if (AdSearchTab === 'TravelFrom') {


      this.setState({
        adtimes2_tableSearchBox: event.target.value
      });
      this.setState({
        AdTimes2TableData: this.state.AdTimes2_searchedData.filter(function (setup) {
          return setup.TravelFrom.toUpperCase().includes(text.toUpperCase());
        })
      });

    }



    if (AdSearchTab === 'PmtFlag') {


      this.setState({
        adtimes2_tableSearchBox: event.target.value
      });
      this.setState({
        AdTimes2TableData: this.state.AdTimes2_searchedData.filter(function (setup) {
          return setup.NewPaymentFlag.toUpperCase().includes(text.toUpperCase());
        })
      });

    }







  }
  AdTimes2SelectPastPastHandler = async () => {
    const pastedExcelData = this.state.AdTimes2_SelectPaste_PastHere;
    var res = pastedExcelData.split(/[\n\t]/);


    const newExcelData = [];

    var excel_columns = [];
    var excel_data = [];
    for (var i = 0; i < Math.round(res.length / 41); i++) { // i == 0 ; i < 3 
      for (var j = i * 41; j < (1 + i) * 41; j++) { // j == 1 ; j < 47 
        if (i === 0) {
          excel_columns.push(res[j]);
        } else {
          excel_data.push(res[j]);
        }
      }
    }
    var final_excel_data = [];
    for (var i = 0; i < excel_data.length / 41; i++) {
      var temp = [];
      for (var j = i * 41; j < (1 + i) * 41; j++) {
        temp.push(excel_data[j]);
      }
      final_excel_data.push(temp);
    }

    this.setState({
      isLoading: true
    });

    final_excel_data.map((item, index) => {

      let flag = false; //to check if excel data is valid then call api, otherwise not
      if (item.length > 0) {
        for (let i = 0; i < item.length; i++) {
          if (i == 8 || i == 13 || i == 16 || i == 17 || i == 30 || (i >= 20 && i <= 32)) {
            if (item[i] === null || item[i] === "" || !item[i]) {
              //time is empty, it should not be empty
              toast.error("Time Can't Contain Null value");
              flag = true;
              break;
            } else {
              //validate fuc
              let strArray = item[i].split(":", 2); //output ['00', '09']
              let partOneOfStr = Number(strArray[0]);
              let partSecondOfStr = Number(strArray[1]);
              //first convert string to number then check for isNaN
              //if isNaN returns true it means it contins some alphabetic
              if (isNaN(partOneOfStr) || isNaN(partSecondOfStr)) {
                toast.error("Time is not valid, it must be like \'01:00\'");
                flag = true;
                break;
              }
              //after checking that it is number then check that numbers length is exact 2, not low or higher than 2 like '000:00' it should be '00:09'
              else if (
                strArray[0].length < 2 ||
                strArray[0].length > 2 ||
                strArray[1].length < 2 ||
                strArray[1].length > 2
              ) {
                toast.error("Time must be in format '00:00'.");
                flag = true;
                break;
              }
            }

          }
        }
      }
      if (flag === false) {
        var mealBreak = [
          {
            "Deductable": {
              "AD": item[33],
              "Time": item[34]
            },
            "MB1Finish": item[21],
            "MB1Start": item[22],
            "AD1Finish": item[23],
            "AD1Start": item[24],
          },
          {
            "Deductable": {

              "AD": item[35],
              "Time": item[36]
            },
            "MB2Finish": item[25],
            "MB2Start": item[26],
            "AD2Finish": item[27],
            "AD2Start": item[28],
          },
          {
            "Deductable": {

              "AD": item[37],
              "Time": item[38]
            },
            "MB3Finish": item[29],
            "MB3Start": item[30],

            "AD3Finish": item[31],
            "AD3Start": item[32],
          }
        ];
        API.post("createadtimescard", "/createadtimescard", {
          body: {
            "Guid": item[0],
            "ChartCode": item[6],
            "ChartSort": item[7],
            "Dept": item[4],
            "EmployeeName": item[2] + " " + item[3],
            "EmployeeGuid": item[1],
            "Start": item[13],
            "Finish": item[8],
            "adlistGuid": this.state.tmp_Guid,
            "HourlyRate": item[9],
            "Note": item[10],
            "Payas": item[11],
            "PaymentFlag": item[12],
            "Position": item[5],
            "TenantGuid": localStorage.getItem('useableGuid'),
            "TotalHours": item[15],
            "TravelTo": item[17],
            "TravelFrom": item[16],
            "WorkState": item[18],
            "mealBreak": mealBreak,
            "meals": item[20],
            "adlistDate": item[40] === "NA" ? null : item[40],
            // "PmtFlag": item[12],
            "TrackingCodes": [],
            // "CTX": item[21],
            // "IN": item[30],
            // "F1": item[22],
            // "F2": item[23],
            // "F3": item[24],
            // "F4": item[25],
            // "F5": item[26],
            // "F6": item[27],
            // "F7": item[28],
            // "F8": item[29]
          }
        }).then(data => {

          //toast.success('Data Created Successfully.');

          this.getAdTimesList();
        }).catch(err => {
          toast.error(err.message)
        });
      }
    })

    document.getElementById('btn_cancel_excel').click();


    this.setState({
      isLoading: false
    })


  }

  //to check excel data is valid
  isExcelDataValid = async data => {
    let strArray = data.split(":", 2); //output ['00', '09']
    let partOneOfStr = Number(strArray[0]);
    let partSecondOfStr = Number(strArray[1]);
    //first convert string to number then check for isNaN
    //if isNaN returns true it means it contins some alphabetic
    if (isNaN(partOneOfStr) || isNaN(partSecondOfStr)) {
      toast.error("Time is not valid, it must be like \'01:00\'");
      return true;
    }
    //after checking that it is number then check that numbers length is exact 2, not low or higher than 2 like '000:00' it should be '00:09'
    else if (
      strArray[0].length < 2 ||
      strArray[0].length > 2 ||
      strArray[1].length < 2 ||
      strArray[1].length > 2
    ) {
      toast.error("Time must be in format '00:00'.");
      return true;
    } else {
      return false;
    }
  };
  EmployeesInputsChngHandlers = (event) => {

    if (this.state.Adtimes2_Modal2SearchName_TerminatedChkbx) {


      let SelectedTab = this.state.AdTimes2_NameSearchModal_SelectedTab

      var text = event.target.value === null ? '' : event.target.value

      if (SelectedTab === 'FirstName') {

        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          ad_times_all_employess: this.state.ad_times_all_employess_search.filter(function (usr) {

            return usr.FirstName.toUpperCase().includes(text.toUpperCase());
          })
        });

      }
      if (SelectedTab === 'LastName') {

        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          ad_times_all_employess: this.state.ad_times_all_employess_search.filter(function (usr) {
            return usr.LastName.toUpperCase().includes(text.toUpperCase());
          })
        });

      }
      if (SelectedTab === 'Email') {

        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          ad_times_all_employess: this.state.ad_times_all_employess_search.filter(function (usr) {
            return usr.NewEmail.toUpperCase().includes(text.toUpperCase());
          })
        });

      }
      if (SelectedTab === 'Mobile') {

        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          ad_times_all_employess: this.state.ad_times_all_employess_search.filter(function (usr) {
            return usr.NewMobile.toUpperCase().includes(text.toUpperCase());
          })
        });

      }
      if (SelectedTab === 'Department') {

        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          ad_times_all_employess: this.state.ad_times_all_employess_search.filter(function (usr) {
            return usr.Department.toUpperCase().includes(text.toUpperCase());
          })
        });

      }
      if (SelectedTab === 'Position') {

        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          ad_times_all_employess: this.state.ad_times_all_employess_search.filter(function (usr) {
            return usr.Position.toUpperCase().includes(text.toUpperCase());
          })
        });

      }
      if (SelectedTab === 'ApproverGroup') {

        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          ad_times_all_employess: this.state.ad_times_all_employess_search.filter(function (usr) {
            return usr.NewApproverGroup.toUpperCase().includes(text.toUpperCase());
          })
        });

      }

    }
    else {
      let SelectedTab = this.state.AdTimes2_NameSearchModal_SelectedTab

      var text = event.target.value === null ? '' : event.target.value
      if (SelectedTab === 'FirstName') {

        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmpSearch.filter(function (usr) {

            return usr.FirstName.toUpperCase().includes(text.toUpperCase());
          })
        });

      }
      if (SelectedTab === 'LastName') {

        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmpSearch.filter(function (usr) {
            return usr.LastName.toUpperCase().includes(text.toUpperCase());
          })
        });

      }
      if (SelectedTab === 'Email') {

        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmpSearch.filter(function (usr) {
            return usr.NewEmail.toUpperCase().includes(text.toUpperCase());
          })
        });

      }
      if (SelectedTab === 'Mobile') {

        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmpSearch.filter(function (usr) {
            return usr.NewMobile.toUpperCase().includes(text.toUpperCase());
          })
        });

      }
      if (SelectedTab === 'Department') {

        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmpSearch.filter(function (usr) {
            return usr.Department.toUpperCase().includes(text.toUpperCase());
          })
        });

      }
      if (SelectedTab === 'Position') {

        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmpSearch.filter(function (usr) {
            return usr.Position.toUpperCase().includes(text.toUpperCase());
          })
        });

      }
      if (SelectedTab === 'ApproverGroup') {

        this.setState({
          Adtimes2_ModalSearchName_Search: event.target.value
        });
        this.setState({
          adTimes_NonTerminateEmp: this.state.adTimes_NonTerminateEmpSearch.filter(function (usr) {
            return usr.NewApproverGroup.toUpperCase().includes(text.toUpperCase());
          })
        });

      }

    }







  }
  getDetails = async () => {
    await API.post('getadlistbyguid', '/getadlistbyguid', {
      body: {
        "Adlist_Guid": this.props.location.state.data
      }
    }).then(data => {
      console.log(data);
      this.setState({
        detailed_data: data
      })
    }).catch(err => { console.log(err) });
  }
  componentWillMount = async () => {

    let mask_chartSort = JSON.parse(localStorage.getItem('completetenant')).Codes.chart_sort;
    mask_chartSort = mask_chartSort.replace(/[A-Za-z]/g, '*');


    let mask_chartCode = JSON.parse(localStorage.getItem('completetenant')).Codes.chart_code;
    mask_chartCode = mask_chartCode.replace(/[A-Za-z]/g, '*');


    await this.setState({
      trackingfields: JSON.parse(localStorage.getItem('completetenant')).TrackingFields,
      mask_chartCode: mask_chartCode,
      mask_chartSort: mask_chartSort
    })

//console.log(moment.tz(moment(new Date(this.state.trackingfields.find(a=>a.type==="Date").defaultValue)), "Australia/Canberra").format(),'sssssssssssssssssssssssssssssssssssssssss');
    await this.getAllEmployees();


  }
  getAdTimesList = async () => {
    await this.setState({
      isLoading: true,
    });
    var guidsofadtimes = [];
    await API.post('getadtimes', '/getadtimes', {
      body: {
        "tenant": localStorage.getItem('useableGuid'),
        "adlistGuid": this.state.tmp_Guid,
      }
    }).then(data => {
      console.log(data);
      data.map(value => {
        value.checked = false;
        value.NewPaymentFlag = value.PaymentFlag === null ? '1' : value.PaymentFlag;
        let k = value.EmployeeName.split(" ");
        value.FirstName = k[0];
        value.LastName = k[1];
        guidsofadtimes.push(value.Guid);
        return null;
      });



      let AdSearchTab = localStorage.getItem('adTimes2_sortLastTab');



      if (AdSearchTab === 'FirstName') {
        $("#FnCaret").show();
        $("#LnCaret").hide();
        $("#postionCaret").hide();
        $("#depCaret").hide();
        $("#travelToCaret").hide();
        $("#startCaret").hide();
        $("#mealCaret").hide();
        $("#wrapCaret").hide();
        $("#travelFromCaret").hide();
        $("#pmtCaret").hide();


        this.setState({
          AdTimes2SelectedTab: AdSearchTab,
          AdTimes_2_FirstName_Toggle: true,
          AdTimes_2_LastName_Toggle: false,
          AdTimes_2_Position_Toggle: false,
          AdTimes_2_Department_Toggle: false,
          AdTimes_2_TravelTo_Toggle: false,
          AdTimes_2_Start_Toggle: false,
          AdTimes_2_Meal_Toggle: false,
          AdTimes_2_Wrap_Toggle: false,
          AdTimes_2_TravelFrom_Toggle: false,
          AdTimes_2_PmtFlag_Toggle: false,
          AdTimes2_searchedData: data.sort(function (a, b) {
            var nameA = a.FirstName.toUpperCase();
            var nameB = b.FirstName.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          }),
          AdTimes2TableData: data.sort(function (a, b) {
            var nameA = a.FirstName.toUpperCase();
            var nameB = b.FirstName.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          }),
        });



      }
      else if (AdSearchTab === 'LastName') {
        $("#FnCaret").hide();
        $("#LnCaret").show();
        $("#postionCaret").hide();
        $("#depCaret").hide();
        $("#travelToCaret").hide();
        $("#startCaret").hide();
        $("#mealCaret").hide();
        $("#wrapCaret").hide();
        $("#travelFromCaret").hide();
        $("#pmtCaret").hide();




        this.setState({
          AdTimes2SelectedTab: AdSearchTab,
          AdTimes_2_FirstName_Toggle: false,
          AdTimes_2_LastName_Toggle: true,
          AdTimes_2_Position_Toggle: false,
          AdTimes_2_Department_Toggle: false,
          AdTimes_2_TravelTo_Toggle: false,
          AdTimes_2_Start_Toggle: false,
          AdTimes_2_Meal_Toggle: false,
          AdTimes_2_Wrap_Toggle: false,
          AdTimes_2_TravelFrom_Toggle: false,
          AdTimes_2_PmtFlag_Toggle: false,
          AdTimes2_searchedData: data.sort(function (a, b) {
            var nameA = a.LastName.toUpperCase();
            var nameB = b.LastName.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          }),
          AdTimes2TableData: data.sort(function (a, b) {
            var nameA = a.LastName.toUpperCase();
            var nameB = b.LastName.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          }),
        });

      }
      else if (AdSearchTab === 'Position') {
        $("#FnCaret").hide();
        $("#LnCaret").hide();
        $("#postionCaret").show();
        $("#depCaret").hide();
        $("#travelToCaret").hide();
        $("#startCaret").hide();
        $("#mealCaret").hide();
        $("#wrapCaret").hide();
        $("#travelFromCaret").hide();
        $("#pmtCaret").hide();



        this.setState({
          AdTimes2SelectedTab: AdSearchTab,
          AdTimes_2_FirstName_Toggle: false,
          AdTimes_2_LastName_Toggle: false,
          AdTimes_2_Position_Toggle: true,
          AdTimes_2_Department_Toggle: false,
          AdTimes_2_TravelTo_Toggle: false,
          AdTimes_2_Start_Toggle: false,
          AdTimes_2_Meal_Toggle: false,
          AdTimes_2_Wrap_Toggle: false,
          AdTimes_2_TravelFrom_Toggle: false,
          AdTimes_2_PmtFlag_Toggle: false,
          AdTimes2_searchedData: data.sort(function (a, b) {
            var nameA = a.Position.toUpperCase();
            var nameB = b.Position.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          }),
          AdTimes2TableData: data.sort(function (a, b) {
            var nameA = a.Position.toUpperCase();
            var nameB = b.Position.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          }),
        });


      }
      else if (AdSearchTab === 'Department') {
        $("#FnCaret").hide();
        $("#LnCaret").hide();
        $("#postionCaret").hide();
        $("#depCaret").show();
        $("#travelToCaret").hide();
        $("#startCaret").hide();
        $("#mealCaret").hide();
        $("#wrapCaret").hide();
        $("#travelFromCaret").hide();
        $("#pmtCaret").hide();


        this.setState({
          AdTimes2SelectedTab: AdSearchTab,
          AdTimes_2_FirstName_Toggle: false,
          AdTimes_2_LastName_Toggle: false,
          AdTimes_2_Position_Toggle: false,
          AdTimes_2_Department_Toggle: true,
          AdTimes_2_TravelTo_Toggle: false,
          AdTimes_2_Start_Toggle: false,
          AdTimes_2_Meal_Toggle: false,
          AdTimes_2_Wrap_Toggle: false,
          AdTimes_2_TravelFrom_Toggle: false,
          AdTimes_2_PmtFlag_Toggle: false,
          AdTimes2_searchedData: data.sort(function (a, b) {
            var nameA = a.Dept.toUpperCase();
            var nameB = b.Dept.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          }),
          AdTimes2TableData: data.sort(function (a, b) {
            var nameA = a.Dept.toUpperCase();
            var nameB = b.Dept.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          }),
        });


      }
      else if (AdSearchTab === 'TravelTo') {
        $("#FnCaret").hide();
        $("#LnCaret").hide();
        $("#postionCaret").hide();
        $("#depCaret").hide();
        $("#travelToCaret").show();
        $("#startCaret").hide();
        $("#mealCaret").hide();
        $("#wrapCaret").hide();
        $("#travelFromCaret").hide();
        $("#pmtCaret").hide();



        this.setState({
          AdTimes2SelectedTab: AdSearchTab,
          AdTimes_2_FirstName_Toggle: false,
          AdTimes_2_LastName_Toggle: false,
          AdTimes_2_Position_Toggle: false,
          AdTimes_2_Department_Toggle: false,
          AdTimes_2_TravelTo_Toggle: true,
          AdTimes_2_Start_Toggle: false,
          AdTimes_2_Meal_Toggle: false,
          AdTimes_2_Wrap_Toggle: false,
          AdTimes_2_TravelFrom_Toggle: false,
          AdTimes_2_PmtFlag_Toggle: false,
          AdTimes2_searchedData: data.sort(function (a, b) {
            var nameA = a.TravelTo.toUpperCase();
            var nameB = b.TravelTo.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          }),
          AdTimes2TableData: data.sort(function (a, b) {
            var nameA = a.TravelTo.toUpperCase();
            var nameB = b.TravelTo.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          }),
        });



      }

      else if (AdSearchTab === 'Start') {
        $("#FnCaret").hide();
        $("#LnCaret").hide();
        $("#postionCaret").hide();
        $("#depCaret").hide();
        $("#travelToCaret").hide();
        $("#startCaret").show();
        $("#mealCaret").hide();
        $("#wrapCaret").hide();
        $("#travelFromCaret").hide();
        $("#pmtCaret").hide();



        this.setState({
          AdTimes2SelectedTab: AdSearchTab,
          AdTimes_2_FirstName_Toggle: false,
          AdTimes_2_LastName_Toggle: false,
          AdTimes_2_Position_Toggle: false,
          AdTimes_2_Department_Toggle: false,
          AdTimes_2_TravelTo_Toggle: false,
          AdTimes_2_Start_Toggle: true,
          AdTimes_2_Meal_Toggle: false,
          AdTimes_2_Wrap_Toggle: false,
          AdTimes_2_TravelFrom_Toggle: false,
          AdTimes_2_PmtFlag_Toggle: false,
          AdTimes2_searchedData: data.sort(function (a, b) {
            var nameA = a.Start.toUpperCase();
            var nameB = b.Start.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          }),
          AdTimes2TableData: data.sort(function (a, b) {
            var nameA = a.Start.toUpperCase();
            var nameB = b.Start.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          }),
        });


      }

      else if (AdSearchTab === 'Meal') {
        $("#FnCaret").hide();
        $("#LnCaret").hide();
        $("#postionCaret").hide();
        $("#depCaret").hide();
        $("#travelToCaret").hide();
        $("#startCaret").hide();
        $("#mealCaret").show();
        $("#wrapCaret").hide();
        $("#travelFromCaret").hide();
        $("#pmtCaret").hide();


        this.setState({
          AdTimes2SelectedTab: AdSearchTab,
          AdTimes_2_FirstName_Toggle: false,
          AdTimes_2_LastName_Toggle: false,
          AdTimes_2_Position_Toggle: false,
          AdTimes_2_Department_Toggle: false,
          AdTimes_2_TravelTo_Toggle: false,
          AdTimes_2_Start_Toggle: false,
          AdTimes_2_Meal_Toggle: true,
          AdTimes_2_Wrap_Toggle: false,
          AdTimes_2_TravelFrom_Toggle: false,
          AdTimes_2_PmtFlag_Toggle: false,
          AdTimes2_searchedData: data.sort(function (a, b) {
            var nameA = a.meals.toUpperCase();
            var nameB = b.meals.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          }),
          AdTimes2TableData: data.sort(function (a, b) {
            var nameA = a.meals.toUpperCase();
            var nameB = b.meals.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          }),
        });



      }



      else if (AdSearchTab === 'Wrap') {
        $("#FnCaret").hide();
        $("#LnCaret").hide();
        $("#postionCaret").hide();
        $("#depCaret").hide();
        $("#travelToCaret").hide();
        $("#startCaret").hide();
        $("#mealCaret").hide();
        $("#wrapCaret").show();
        $("#travelFromCaret").hide();
        $("#pmtCaret").hide();


        this.setState({
          AdTimes2SelectedTab: AdSearchTab,
          AdTimes_2_FirstName_Toggle: false,
          AdTimes_2_LastName_Toggle: false,
          AdTimes_2_Position_Toggle: false,
          AdTimes_2_Department_Toggle: false,
          AdTimes_2_TravelTo_Toggle: false,
          AdTimes_2_Start_Toggle: false,
          AdTimes_2_Meal_Toggle: false,
          AdTimes_2_Wrap_Toggle: true,
          AdTimes_2_TravelFrom_Toggle: false,
          AdTimes_2_PmtFlag_Toggle: false,
          AdTimes2_searchedData: data.sort(function (a, b) {
            var nameA = a.Finish.toUpperCase();
            var nameB = b.Finish.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          }),
          AdTimes2TableData: data.sort(function (a, b) {
            var nameA = a.Finish.toUpperCase();
            var nameB = b.Finish.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          }),
        });



      }


      else if (AdSearchTab === 'TravelFrom') {
        $("#FnCaret").hide();
        $("#LnCaret").hide();
        $("#postionCaret").hide();
        $("#depCaret").hide();
        $("#travelToCaret").hide();
        $("#startCaret").hide();
        $("#mealCaret").hide();
        $("#wrapCaret").hide();
        $("#travelFromCaret").show();
        $("#pmtCaret").hide();



        this.setState({
          AdTimes2SelectedTab: AdSearchTab,
          AdTimes_2_FirstName_Toggle: false,
          AdTimes_2_LastName_Toggle: false,
          AdTimes_2_Position_Toggle: false,
          AdTimes_2_Department_Toggle: false,
          AdTimes_2_TravelTo_Toggle: false,
          AdTimes_2_Start_Toggle: false,
          AdTimes_2_Meal_Toggle: false,
          AdTimes_2_Wrap_Toggle: false,
          AdTimes_2_TravelFrom_Toggle: true,
          AdTimes_2_PmtFlag_Toggle: false,
          AdTimes2_searchedData: data.sort(function (a, b) {
            var nameA = a.TravelFrom.toUpperCase();
            var nameB = b.TravelFrom.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          }),
          AdTimes2TableData: data.sort(function (a, b) {
            var nameA = a.TravelFrom.toUpperCase();
            var nameB = b.TravelFrom.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          }),
        });



      }



      else if (AdSearchTab === 'PmtFlag') {
        $("#FnCaret").hide();
        $("#LnCaret").hide();
        $("#postionCaret").hide();
        $("#depCaret").hide();
        $("#travelToCaret").hide();
        $("#startCaret").hide();
        $("#mealCaret").hide();
        $("#wrapCaret").hide();
        $("#travelFromCaret").hide();
        $("#pmtCaret").show();


        this.setState({
          AdTimes2SelectedTab: AdSearchTab,
          AdTimes_2_FirstName_Toggle: false,
          AdTimes_2_LastName_Toggle: false,
          AdTimes_2_Position_Toggle: false,
          AdTimes_2_Department_Toggle: false,
          AdTimes_2_TravelTo_Toggle: false,
          AdTimes_2_Start_Toggle: false,
          AdTimes_2_Meal_Toggle: false,
          AdTimes_2_Wrap_Toggle: false,
          AdTimes_2_TravelFrom_Toggle: false,
          AdTimes_2_PmtFlag_Toggle: true,
          AdTimes2_searchedData: data.sort(function (a, b) {
            var nameA = a.NewPaymentFlag.toUpperCase();
            var nameB = b.NewPaymentFlag.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          }),
          AdTimes2TableData: data.sort(function (a, b) {
            var nameA = a.NewPaymentFlag.toUpperCase();
            var nameB = b.NewPaymentFlag.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          }),
        });


      }
      else {



        $("#FnCaret").show();
        $("#LnCaret").hide();
        $("#postionCaret").hide();
        $("#depCaret").hide();
        $("#travelToCaret").hide();
        $("#startCaret").hide();
        $("#mealCaret").hide();
        $("#wrapCaret").hide();
        $("#travelFromCaret").hide();
        $("#pmtCaret").hide();
        this.setState({

          AdTimes_2_FirstName_Toggle: true,
          AdTimes_2_LastName_Toggle: false,
          AdTimes_2_Position_Toggle: false,
          AdTimes_2_Department_Toggle: false,
          AdTimes_2_TravelTo_Toggle: false,
          AdTimes_2_Start_Toggle: false,
          AdTimes_2_Meal_Toggle: false,
          AdTimes_2_Wrap_Toggle: false,
          AdTimes_2_TravelFrom_Toggle: false,
          AdTimes_2_PmtFlag_Toggle: false,
          AdTimes2_searchedData: data.sort(function (a, b) {
            var nameA = a.FirstName.toUpperCase();
            var nameB = b.FirstName.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          }),
          AdTimes2TableData: data.sort(function (a, b) {
            var nameA = a.FirstName.toUpperCase();
            var nameB = b.FirstName.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          }),
        });





      }
    }).catch(err => { console.log(err) });
    await this.setState({
      isLoading: false,
      guidsofadtimes: guidsofadtimes
    });
  }
  getAllEmployees = async () => {
    this.setState({
      isLoading: true
    })
    $('#freshbtn').addClass('moving');
    await API.post('getallemployees', '/getallemployees', {
      body: {
        "tenant": localStorage.getItem('useableGuid')
      }
    }).then(data => {


      data.map(item => {
        item.hi = '1'
        item.NewApproverGroup = (item.ApproverGroup === null || item.ApproverGroup === undefined) ? 'NA' : item.ApproverGroup;
        item.NewEmail = (item.Email === null || item.Email === undefined) ? 'NA' : item.Email;
        item.NewMobile = (item.Mobile === null || item.Mobile === undefined) ? 'NA' : item.Mobile;
        item.Position = (item.Position === null || item.Position === undefined) ? 'NA' : item.Position;
        item.ApproverGroup = (item.ApproverGroup === null || item.ApproverGroup === undefined) ? 'NA' : item.ApproverGroup;
      })


      $('#freshbtn').removeClass('moving');


      let Tab = localStorage.getItem('Ad2EmpSelTab');



      if (Tab === 'FirstName') {

        $("#FirstNameCaret").show()
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();


        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: true,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false,
          adTimes_NonTerminateEmp: data.filter(item => { return item.Terminated !== 'T' })
            .sort(function (a, b) {
              var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
              var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            }),
          adTimes_NonTerminateEmpSearch: data.filter(item => { return item.Terminated !== 'T' })
            .sort(function (a, b) {
              var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
              var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            }),
          ad_times_all_employess: data.sort(function (a, b) {
            var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),

          ad_times_all_employess_search: data.sort(function (a, b) {
            var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),
        })

      }
      else if (Tab === 'LastName') {

        $("#FirstNameCaret").hide();
        $("#LastNameCaret").show()
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();

        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: true,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false,
          adTimes_NonTerminateEmp: data.filter(item => { return item.Terminated !== 'T' })
            .sort(function (a, b) {
              var nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
              var nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            }),
          adTimes_NonTerminateEmpSearch: data.filter(item => { return item.Terminated !== 'T' })
            .sort(function (a, b) {
              var nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
              var nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            }),
          ad_times_all_employess: data.sort(function (a, b) {
            var nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),

          ad_times_all_employess_search: data.sort(function (a, b) {
            var nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),
        })

      }
      else if (Tab === 'Email') {


        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").show()
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();



        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: true,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false,
          adTimes_NonTerminateEmp: data.filter(item => { return item.Terminated !== 'T' })
            .sort(function (a, b) {
              var nameA = a.NewEmail.toUpperCase(); // ignore upper and lowercase
              var nameB = b.NewEmail.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            }),
          adTimes_NonTerminateEmpSearch: data.filter(item => { return item.Terminated !== 'T' })
            .sort(function (a, b) {
              var nameA = a.NewEmail.toUpperCase(); // ignore upper and lowercase
              var nameB = b.NewEmail.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            }),
          ad_times_all_employess: data.sort(function (a, b) {
            var nameA = a.NewEmail.toUpperCase(); // ignore upper and lowercase
            var nameB = b.NewEmail.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),

          ad_times_all_employess_search: data.sort(function (a, b) {
            var nameA = a.NewEmail.toUpperCase(); // ignore upper and lowercase
            var nameB = b.NewEmail.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),
        })

      }
      else if (Tab === 'Mobile') {


        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").show()
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();


        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: true,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false,
          adTimes_NonTerminateEmp: data.filter(item => { return item.Terminated !== 'T' })
            .sort(function (a, b) {
              var nameA = a.NewMobile.toUpperCase(); // ignore upper and lowercase
              var nameB = b.NewMobile.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            }),
          adTimes_NonTerminateEmpSearch: data.filter(item => { return item.Terminated !== 'T' })
            .sort(function (a, b) {
              var nameA = a.NewMobile.toUpperCase(); // ignore upper and lowercase
              var nameB = b.NewMobile.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            }),
          ad_times_all_employess: data.sort(function (a, b) {
            var nameA = a.NewMobile.toUpperCase(); // ignore upper and lowercase
            var nameB = b.NewMobile.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),

          ad_times_all_employess_search: data.sort(function (a, b) {
            var nameA = a.NewMobile.toUpperCase(); // ignore upper and lowercase
            var nameB = b.NewMobile.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),
        })

      }
      else if (Tab === 'Department') {


        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").show()
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();


        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: true,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false,
          adTimes_NonTerminateEmp: data.filter(item => { return item.Terminated !== 'T' })
            .sort(function (a, b) {
              var nameA = a.Department.toUpperCase(); // ignore upper and lowercase
              var nameB = b.Department.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            }),
          adTimes_NonTerminateEmpSearch: data.filter(item => { return item.Terminated !== 'T' })
            .sort(function (a, b) {
              var nameA = a.Department.toUpperCase(); // ignore upper and lowercase
              var nameB = b.Department.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            }),
          ad_times_all_employess: data.sort(function (a, b) {
            var nameA = a.Department.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Department.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),

          ad_times_all_employess_search: data.sort(function (a, b) {
            var nameA = a.Department.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Department.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),
        })

      }
      else if (Tab === 'Position') {

        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").show()
        $("#ApproverGroupCaret").hide();

        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: true,
          AdTimes2_NameModal_ApproverGroup_Toggle: false,
          adTimes_NonTerminateEmp: data.filter(item => { return item.Terminated !== 'T' })
            .sort(function (a, b) {
              var nameA = a.Position.toUpperCase(); // ignore upper and lowercase
              var nameB = b.Position.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            }),
          adTimes_NonTerminateEmpSearch: data.filter(item => { return item.Terminated !== 'T' })
            .sort(function (a, b) {
              var nameA = a.Position.toUpperCase(); // ignore upper and lowercase
              var nameB = b.Position.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            }),
          ad_times_all_employess: data.sort(function (a, b) {
            var nameA = a.Position.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Position.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),

          ad_times_all_employess_search: data.sort(function (a, b) {
            var nameA = a.Position.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Position.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),
        })


      }
      else if (Tab === 'ApproverGroup') {

        $("#FirstNameCaret").hide();
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").show()


        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: Tab,
          AdTimes2_NameModal_FirstName_Toggle: false,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: true,
          adTimes_NonTerminateEmp: data.filter(item => { return item.Terminated !== 'T' })
            .sort(function (a, b) {
              var nameA = a.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
              var nameB = b.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            }),
          adTimes_NonTerminateEmpSearch: data.filter(item => { return item.Terminated !== 'T' })
            .sort(function (a, b) {
              var nameA = a.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
              var nameB = b.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            }),
          ad_times_all_employess: data.sort(function (a, b) {
            var nameA = a.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
            var nameB = b.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),

          ad_times_all_employess_search: data.sort(function (a, b) {
            var nameA = a.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
            var nameB = b.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),
        })



      }
      else {


        $("#FirstNameCaret").show()
        $("#LastNameCaret").hide();
        $("#MobileCaret").hide();
        $("#EmailCaret").hide();
        $("#DepartmentCaret").hide();
        $("#PositionCaret").hide();
        $("#ApproverGroupCaret").hide();
        this.setState({
          AdTimes2_NameSearchModal_SelectedTab: 'FirstName',
          AdTimes2_NameModal_FirstName_Toggle: true,
          AdTimes2_NameModal_LastName_Toggle: false,
          AdTimes2_NameModal_Email_Toggle: false,
          AdTimes2_NameModal_Mobile_Toggle: false,
          AdTimes2_NameModal_Dept_Toggle: false,
          AdTimes2_NameModal_Position_Toggle: false,
          AdTimes2_NameModal_ApproverGroup_Toggle: false,
          adTimes_NonTerminateEmp: data.filter(item => { return item.Terminated !== 'T' })
            .sort(function (a, b) {
              var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
              var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            }),
          adTimes_NonTerminateEmpSearch: data.filter(item => { return item.Terminated !== 'T' })
            .sort(function (a, b) {
              var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
              var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
            }),
          ad_times_all_employess: data.sort(function (a, b) {
            var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),

          ad_times_all_employess_search: data.sort(function (a, b) {
            var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),
        })


      }

      ;
    }).catch(err => { console.log(err) });
    await this.setState({
      isLoading: false,
    });
  }

  componentDidMount = async () => {

    await this.getDetails();
    $(":contains('Download')").closest('button').hide();
    await this.props.childProps.isAuthentication();
    if (!this.props.childProps.isAuthenticated) {
      this.props.history.push('/login');
    } else {
      if (this.props.location.state === undefined || !this.props.location.state.data) {

        this.props.history.push('/ad_times');
      } else {
        $('#AdTimes2_AdTimeForm_Date').attr('readonly', 'readonly');
        this.setState({
          isLoading: false
        });
        $(document).click(function (e) {
          if ((e.target.className === 'modal-backdrop fade in'
            && $('#adtimes2_search_pop').css("display") === 'block'
          )) {
            $('body').css("overflow", "hidden");
            $('#adtimes2_edit_pop').css('overflow-y', 'auto');
          }
          else if (e.target.className === 'modal-backdrop fade in'
            && $('#adtimes2_edit_pop').css("display") === 'block'
          ) {

            $('body').removeAttr('style');
          }
        })
        await this.editAdTimes2();
        await this.getAdTimesList();

      }
    }
  }
  clearSelectPaste = () => {

    this.setState({
      AdTimes2_SelectPaste_PastHere: ''
    })

  }
  deleteAdTimesRecord = async (id) => {
    await this.setState({
      isLoading: true,
    });
    await API.post('deleteadtimes', '/deleteadtimes', {
      body: {
        "Guid": id
      }
    }).then(data => {
      console.log(data);
      this.getAdTimesList();
      API.post("recordactivity", "/recordactivity", {
        body: {
          "Description": "Adtimes Delete Activity",
          "UserName": localStorage.getItem('UserLogin'),
          "IPAddress": ip.address(),
          "TenantGuid": localStorage.getItem('useableGuid'),
          "Type": "Adtimes Delete ",
          "UserGuid": localStorage.getItem('userGuid')
        }
      }).then(data => {
        //toast.success('Adtimes Delete Activity Note');
      }).catch(err => {
        toast.error(err.message);
      });
      //toast.success('Record Deleted Successfully');

    }).catch(err => {
      console.log(err);
      toast.error('Problem in Record Deleting');
    });
    await this.setState({
      isLoading: false,
    });
  }
  adTimes2DateClickOpen = () => {
    $("#AdTimes2_AdTimeForm_Date").click();
  }
  handleModal_Height = () => {

    if (!$('#btn_cllps').hasClass('collapsed')) {

      $('.modal-backdrop').addClass('mdl_incrs');
    }
    else if ($('#btn_cllps').hasClass('collapsed')) {
      $('.modal-backdrop').removeClass('mdl_incrs');
    }
  }
  AdTimes2TimePicker = async (name, value) => {
    if (value === null) {
      value = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    }
    await this.setState({
      [name]: value
    });

    if (((moment(this.state.AdTimes2_editStartMB1).format('HH:mm').toString() === '00:00') || (this.state.AdTimes2_editStartMB1 === '00:00')) &&
      ((moment(this.state.AdTimes2_editFinishMB1).format('HH:mm').toString() === '00:00') || (this.state.AdTimes2_editFinishMB1 === '00:00')) &&
      ((moment(this.state.AdTimes2_editStartMB2).format('HH:mm').toString() === '00:00') || (this.state.AdTimes2_editStartMB2 === '00:00')) &&
      ((moment(this.state.AdTimes2_editFinishMB2).format('HH:mm').toString() === '00:00') || (this.state.AdTimes2_editFinishMB2 === '00:00')) &&
      ((moment(this.state.AdTimes2_editStartMB3).format('HH:mm').toString() === '00:00') || (this.state.AdTimes2_editStartMB3 === '00:00')) &&
      ((moment(this.state.AdTimes2_editFinishMB3).format('HH:mm').toString() === '00:00') || (this.state.AdTimes2_editFinishMB3 === '00:00'))) {

      await this.setState({
        detail_mealdisable: false

      })

    } else {

      await this.setState({
        detail_mealdisable: true
      })
      var mb1_diff = moment.duration(moment(this.state.AdTimes2_editFinishMB1, 'HH:mm').diff(moment(this.state.AdTimes2_editStartMB1, 'HH:mm')));
      var mb2_diff = moment.duration(moment(this.state.AdTimes2_editFinishMB2, 'HH:mm').diff(moment(this.state.AdTimes2_editStartMB2, 'HH:mm')));
      var mb3_diff = moment.duration(moment(this.state.AdTimes2_editFinishMB3, 'HH:mm').diff(moment(this.state.AdTimes2_editStartMB3, 'HH:mm')));
      let diff_hour = 0;
      let diff_mins = 0;
      let diff_sec = 0;
      diff_mins += mb1_diff._data.minutes + mb2_diff._data.minutes + mb3_diff._data.minutes;
      diff_hour += mb1_diff._data.hours + mb2_diff._data.hours + mb3_diff._data.hours;
      diff_sec += mb1_diff._data.seconds + mb2_diff._data.seconds + mb3_diff._data.seconds;
      var m = moment();

      await this.setState({
        AdTimes2_AdTimeForm_MealBreak: m.set({ hour: diff_hour, minute: diff_mins, second: diff_sec, millisecond: 0 })
      });


    }
    this.validateFields(name, value);

  }
  editAdTimes2 = async () => {

    let data = this.state.detailed_data;


    await this.setState({
      AdTimes2_AdTimeForm_Date: new Date(data.adDate),
      AdTimes2_AdTimeForm_Unit: data.Unit,
      AdTimes2_AdTimeForm_TravelTo: data.TravelTo,
      AdTimes2_AdTimeForm_CrewCall: data.CrewCall,
      AdTimes2_AdTimeForm_MealBreak: data.MealBreak,
      AdTimes2_AdTimeForm_CrewWrap: data.CrewWrap,
      tmp_Guid: data.Guid,
      AdTimes2_AdTimeForm_TravelFrom: data.TravelFrom,
      AdTimes2_AdTimeForm_Notes: data.Notes,
      IsAdTimes2_AdTimeForm_DateValid: true,
      IsAdTimes2_AdTimeForm_UnitValid: true,
      IsAdTimes2_AdTimeForm_TravelToValid: true,
      IsAdTimes2_AdTimeForm_CrewCallValid: true,
      IsAdTimes2_AdTimeForm_MealBreakValid: true,
      IsAdTimes2_AdTimeForm_CrewWrapValid: true,
      IsAdTimes2_AdTimeForm_TravelFromValid: true,
      IsAdTimes2_AdTimeForm_NotesValid: true,
      AdTimes2_editStartMB1: data.Meals[0].MB1Start,
      AdTimes2_editFinishMB1: data.Meals[0].MB1Finish,
      AdTimes2_editStartMB2: data.Meals[1].MB2Start,
      AdTimes2_editFinishMB2: data.Meals[1].MB2Finish,
      AdTimes2_editStartMB3: data.Meals[2].MB3Start,
      AdTimes2_editFinishMB3: data.Meals[2].MB3Finish,

      adtimes2_non_detuctable_mb1_2: data.Meals[0].Deductable.AD,
      adtimes2_non_detuctable_mb1_3: data.Meals[0].Deductable.Time,

      adtimes2_non_detuctable_mb2_2: data.Meals[1].Deductable.AD,
      adtimes2_non_detuctable_mb2_3: data.Meals[1].Deductable.Time,

      adtimes2_non_detuctable_mb3_2: data.Meals[2].Deductable.AD,
      adtimes2_non_detuctable_mb3_3: data.Meals[2].Deductable.Time,

    })
    if (((moment(this.state.AdTimes2_editStartMB1).format('HH:mm').toString() === '00:00') || (this.state.AdTimes2_editStartMB1 === '00:00')) &&
      ((moment(this.state.AdTimes2_editFinishMB1).format('HH:mm').toString() === '00:00') || (this.state.AdTimes2_editFinishMB1 === '00:00')) &&
      ((moment(this.state.AdTimes2_editStartMB2).format('HH:mm').toString() === '00:00') || (this.state.AdTimes2_editStartMB2 === '00:00')) &&
      ((moment(this.state.AdTimes2_editFinishMB2).format('HH:mm').toString() === '00:00') || (this.state.AdTimes2_editFinishMB2 === '00:00')) &&
      ((moment(this.state.AdTimes2_editStartMB3).format('HH:mm').toString() === '00:00') || (this.state.AdTimes2_editStartMB3 === '00:00')) &&
      ((moment(this.state.AdTimes2_editFinishMB3).format('HH:mm').toString() === '00:00') || (this.state.AdTimes2_editFinishMB3 === '00:00'))) {


      await this.setState({
        detail_mealdisable: false

      })

    } else {

      await this.setState({
        detail_mealdisable: true

      })
    }


  }
  handleDateChange = async (date) => {
    await this.setState({
      AdTimes2_AdTimeForm_Date: date
    });
    this.validateFields('AdTimes2_AdTimeForm_Date', date);
  }

  issue_ScrollHandler = (e) => {
    $('body').css("overflow", "hidden");
    $('#adtimes2_edit_pop').css('overflow-y', 'auto');
  }

  after_issueScrollHandler = (e) => {
    $('body').removeAttr('style');
  }


  AdTimes2InputsHandler = async (e) => {

    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    await this.setState({
      [name]: value
    })
    await this.validateFields(name, value);
    this.validateform();
    if (name === 'ad_times2_non_deductible_mb1_3' || name === 'ad_times2_non_deductible_mb2_3' || name === 'ad_times2_non_deductible_mb3_3') {

    }
    this.find_diff();

  }

  AdTimesDetailsSaveHandler = async () => {
    let AdTimes2_AdTimesFormErrors = this.state.AdTimes2_AdTimesFormErrors;
    let IsAdTimes2_AdTimeForm_DateValid = this.state.IsAdTimes2_AdTimeForm_DateValid;
    let IsAdTimes2_AdTimeForm_UnitValid = this.state.IsAdTimes2_AdTimeForm_UnitValid;
    let IsAdTimes2_AdTimeForm_TravelToValid = this.state.IsAdTimes2_AdTimeForm_TravelToValid;
    let IsAdTimes2_AdTimeForm_CrewCallValid = this.state.IsAdTimes2_AdTimeForm_CrewCallValid;
    let IsAdTimes2_AdTimeForm_MealBreakValid = this.state.IsAdTimes2_AdTimeForm_MealBreakValid;
    let IsAdTimes2_AdTimeForm_CrewWrapValid = this.state.IsAdTimes2_AdTimeForm_CrewWrapValid;
    let IsAdTimes2_AdTimeForm_TravelFromValid = this.state.IsAdTimes2_AdTimeForm_TravelFromValid;
    let IsAdTimes2_AdTimeForm_NotesValid = this.state.IsAdTimes2_AdTimeForm_NotesValid;

    if (!IsAdTimes2_AdTimeForm_DateValid) {
      AdTimes2_AdTimesFormErrors.AdTimes2_AdTimeForm_Date = "This field is required";

    }
    if (!IsAdTimes2_AdTimeForm_UnitValid) {
      AdTimes2_AdTimesFormErrors.AdTimes2_AdTimeForm_Unit = "This field is required";

    }


    this.setState({
      AdTimes2_AdTimesFormErrors: AdTimes2_AdTimesFormErrors,

    })
    await this.validateform();
    if (this.state.IsDetailFormValid) {
      this.setState({
        isLoading: true
      })

      var completeDate = moment.tz(moment(this.state.AdTimes2_AdTimeForm_Date), "Australia/Canberra").format();
      var onlyDate = completeDate.substr(0, completeDate.indexOf('T'));

      await API.post('updateadlist', '/updateadlist', {
        body: {
          "Guid": this.state.tmp_Guid,
          "CrewCall": moment(this.state.AdTimes2_AdTimeForm_CrewCall, 'HH:mm').format('HH:mm'),
          "CrewWrap": moment(this.state.AdTimes2_AdTimeForm_CrewWrap, 'HH:mm').format('HH:mm'),
          "onlyDate": onlyDate,
          "adDate": moment.tz(moment(this.state.AdTimes2_AdTimeForm_Date), "Australia/Canberra").format(),
          "MealBreak": moment(this.state.AdTimes2_AdTimeForm_MealBreak, 'HH:mm').format('HH:mm'),
          "Meals": [{
            "Deductable": {
              "AD": this.state.adtimes2_non_detuctable_mb1_2,

              "Time": this.state.adtimes2_non_detuctable_mb1_3
            },
            "MB1Finish": moment(this.state.AdTimes2_editFinishMB1, 'HH:mm').format('HH:mm'),
            "MB1Start": moment(this.state.AdTimes2_editStartMB1, 'HH:mm').format('HH:mm'),
            "AD1Start": moment(this.state.AdTimes2_editStartMB1, 'HH:mm').format('HH:mm'),
            "AD2Finish": moment(this.state.AdTimes2_editFinishMB1, 'HH:mm').format('HH:mm'),

          },
          {
            "Deductable": {
              "AD": this.state.adtimes2_non_detuctable_mb2_2,

              "Time": this.state.adtimes2_non_detuctable_mb2_3
            },
            "MB2Finish": moment(this.state.AdTimes2_editFinishMB2, 'HH:mm').format('HH:mm'),
            "MB2Start": moment(this.state.AdTimes2_editStartMB2, 'HH:mm').format('HH:mm'),
            "AD2Start": moment(this.state.AdTimes2_editStartMB2, 'HH:mm').format('HH:mm'),
            "AD2Finish": moment(this.state.AdTimes2_editFinishMB2, 'HH:mm').format('HH:mm'),

          },
          {
            "Deductable": {
              "AD": this.state.adtimes2_non_detuctable_mb3_2,

              "Time": this.state.adtimes2_non_detuctable_mb3_3
            },
            "MB3Finish": moment(this.state.AdTimes2_editFinishMB3, 'HH:mm').format('HH:mm'),
            "MB3Start": moment(this.state.AdTimes2_editStartMB3, 'HH:mm').format('HH:mm'),
            "AD3Start": moment(this.state.AdTimes2_editStartMB3, 'HH:mm').format('HH:mm'),
            "AD3Finish": moment(this.state.AdTimes2_editFinishMB3, 'HH:mm').format('HH:mm'),
          }],
          "TravelFrom": moment(this.state.AdTimes2_AdTimeForm_TravelFrom, 'HH:mm').format('HH:mm'),
          "TravelTo": moment(this.state.AdTimes2_AdTimeForm_TravelTo, 'HH:mm').format('HH:mm'),
          "Unit": this.state.AdTimes2_AdTimeForm_Unit,
          "Notes": this.state.AdTimes2_AdTimeForm_Notes
        }
      }).then(data => {
        this.updateadlistdateinadtimes(moment.tz(moment(this.state.AdTimes2_AdTimeForm_Date), "Australia/Canberra").format());
        API.post("recordactivity", "/recordactivity", {
          body: {
            "Description": "update adlist Activity",
            "UserName": localStorage.getItem('UserLogin'),
            "IPAddress": ip.address(),
            "TenantGuid": localStorage.getItem('useableGuid'),
            "Type": "Adlist update ",
            "UserGuid": localStorage.getItem('userGuid')
          }
        }).then(data => {
          //toast.success('update adlist Activity');
        }).catch(err => {
          toast.error(err.message);
        });


        //toast.success('Data successfully updated');

        $('#close_detail_modal').click();

      }).catch(err => { toast.error('Data Not successfully updated'); });
      this.setState({
        isLoading: false
      })

    }
  }
  updateadlistdateinadtimes = async (date) => {
    await API.post("updatedatesadtimes", "/updatedatesadtimes", {
      body: {
        "adlistGuid": this.state.guidsofadtimes,
        "adlistdate": date
      }
    }).then(data => {
      this.getAdTimesList();
      console.log('Adtimes dates also update with Adlist update');
    }).catch(err => {
      toast.error(err.message);
    });
  }

  adTimes2_times_inputHandler = async (name, value) => {
    if (value === null) {
      value = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    }
    await this.setState({
      [name]: value
    },
      () => {
        this.validateFields(name, value.format('HH:mm'))
      }
    );
    await this.find_diff();
  }
  find_diff = async () => {
    if ((moment(this.state.AdTimes2_editModalStartMB1).format('HH:mm').toString() === '00:00' || this.state.AdTimes2_editModalStartMB1 === '00:00') &&
      (moment(this.state.AdTimes2_editModalFinishMB1).format('HH:mm').toString() === '00:00' || this.state.AdTimes2_editModalFinishMB1 === '00:00') &&
      (moment(this.state.AdTimes2_editModalStartMB2).format('HH:mm').toString() === '00:00' || this.state.AdTimes2_editModalStartMB2 === '00:00') &&
      (moment(this.state.AdTimes2_editModalFinishMB2).format('HH:mm').toString() === '00:00' || this.state.AdTimes2_editModalFinishMB2 === '00:00') &&
      (moment(this.state.AdTimes2_editModalStartMB3).format('HH:mm').toString() === '00:00' || this.state.AdTimes2_editModalStartMB3 === '00:00') &&
      (moment(this.state.AdTimes2_editModalFinishMB3).format('HH:mm').toString() === '00:00' || this.state.AdTimes2_editModalFinishMB3 === '00:00')) {
      await this.setState({
        employee_meal: false,
        AdTimes2_EditModal_MealBreak_total: this.state.AdTimes2_EditModal_MealBreak

      })
    } else {
      await this.setState({
        employee_meal: true

      })
      var mb1_diff = moment.duration(moment(this.state.AdTimes2_editModalFinishMB1, 'HH:mm').diff(moment(this.state.AdTimes2_editModalStartMB1, 'HH:mm')));
      var mb2_diff = moment.duration(moment(this.state.AdTimes2_editModalFinishMB2, 'HH:mm').diff(moment(this.state.AdTimes2_editModalStartMB2, 'HH:mm')));
      var mb3_diff = moment.duration(moment(this.state.AdTimes2_editModalFinishMB3, 'HH:mm').diff(moment(this.state.AdTimes2_editModalStartMB3, 'HH:mm')));
      let diff_hour = 0;
      let diff_mins = 0;
      let diff_sec = 0;
      if (!this.state.ad_times2_non_deductible_mb1_3) {
        diff_mins += mb1_diff._data.minutes;
        diff_hour += mb1_diff._data.hours;
        diff_sec += mb1_diff._data.seconds;
      }
      if (!this.state.ad_times2_non_deductible_mb2_3) {
        diff_mins += mb2_diff._data.minutes;
        diff_hour += mb2_diff._data.hours;
        diff_sec += mb2_diff._data.seconds
      }
      if (!this.state.ad_times2_non_deductible_mb3_3) {
        diff_mins += mb3_diff._data.minutes;
        diff_hour += mb3_diff._data.hours;
        diff_sec += mb3_diff._data.seconds
      }

      var m = moment();
      //==================================================================
      let diff_hour_non_deducted = 0;
      let diff_mins_non_deducted = 0;
      let diff_sec_non_deducted = 0;

      diff_mins_non_deducted += mb1_diff._data.minutes;
      diff_hour_non_deducted += mb1_diff._data.hours;
      diff_sec_non_deducted += mb1_diff._data.seconds;
      diff_mins_non_deducted += mb2_diff._data.minutes;
      diff_hour_non_deducted += mb2_diff._data.hours;
      diff_sec_non_deducted += mb2_diff._data.seconds;
      diff_mins_non_deducted += mb3_diff._data.minutes;
      diff_hour_non_deducted += mb3_diff._data.hours;
      diff_sec_non_deducted += mb3_diff._data.seconds;

      var m_non_deducted = moment();

      this.setState({
        AdTimes2_EditModal_MealBreak_total: m.set({ hour: diff_hour, minute: diff_mins, second: diff_sec, millisecond: 0 }),
        AdTimes2_EditModal_MealBreak: m_non_deducted.set({ hour: diff_hour_non_deducted, minute: diff_mins_non_deducted, second: diff_sec_non_deducted, millisecond: 0 })
      });
    }





    var tmins = moment(this.state.AdTimes2_EditModal_TravelTo, 'HH:mm')._d.getMinutes() + moment(this.state.AdTimes2_EditModal_TravelFrom, 'HH:mm')._d.getMinutes();


    var thours = moment(this.state.AdTimes2_EditModal_TravelTo, 'HH:mm')._d.getHours() + moment(this.state.AdTimes2_EditModal_TravelFrom, 'HH:mm')._d.getHours();
    var travel = moment().set({ hour: thours, minute: tmins, second: 0, millisecond: 0 })
    var work = moment.duration(moment(this.state.AdTimes2_EditModal_FinishWork, 'HH:mm').diff(moment(this.state.AdTimes2_EditModal_StartWork, 'HH:mm')));
    var travel_h = 0;
    var travel_m = 0;
    var travel_s = 0;
    travel_h += moment(travel, 'HH:mm')._d.getHours() - moment(this.state.AdTimes2_EditModal_MealBreak_total, 'HH:mm')._d.getHours() + work._data.hours;
    travel_m += moment(travel, 'HH:mm')._d.getMinutes() - moment(this.state.AdTimes2_EditModal_MealBreak_total, 'HH:mm')._d.getMinutes() + work._data.minutes;
    travel_s += moment(travel, 'HH:mm')._d.getSeconds() - moment(this.state.AdTimes2_EditModal_MealBreak_total, 'HH:mm')._d.getSeconds() + work._data.seconds;

    this.setState({
      AdTimes2_EditModal_TotalHours: moment().set({ hour: travel_h, minute: travel_m, second: travel_s }),

    })


  }
  editClicked = async (id) => {

    await this.setState({
      edit_adtimes2: true
    });
    await this.setState({
      isLoading: true,
    });
    await API.post('adtimesbyguid', '/adtimesbyguid', {
      body: {
        "Adtime_Guid": id
      }
    }).then(data => {

      this.setState({
        guid_edit: data.Guid,
        emp_position: data.Position === null ? '' : data.Position,
        emp_department: data.Dept === null ? '' : data.Dept,
        emp_EmpGuid: data.EmployeeGuid === null ? '' : data.EmployeeGuid,
        AdTimes2_EditModal_Name: data.EmployeeName === null ? '' : data.EmployeeName,
        AdTimes2_EditModal_PayAs: data.Payas === null ? '' : data.Payas,
        AdTimes2_EditModal_TravelFrom: data.TravelFrom === null ? '' : data.TravelFrom,
        AdTimes2_EditModal_TravelTo: data.TravelTo === null ? '' : data.TravelTo,
        AdTimes2_EditModal_StartWork: data.Start === null ? '' : data.Start,
        AdTimes2_EditModal_FinishWork: data.Finish === null ? '' : data.Finish,

        AdTimes2_EditModal_MealBreak: data.meals === null ? '' : data.meals,
        AdTimes2_editModalStartMB1: data.mealBreak[0].MB1Start,
        AdTimes2_editModalFinishMB1: data.mealBreak[0].MB1Finish,
        AdTimes2_editModalStartMB2: data.mealBreak[1].MB2Start,
        AdTimes2_editModalFinishMB2: data.mealBreak[1].MB2Finish,
        AdTimes2_editModalStartMB3: data.mealBreak[2].MB3Start,
        AdTimes2_editModalFinishMB3: data.mealBreak[2].MB3Finish,
        ad_times2_non_deductible_mb1_2: data.mealBreak[0].Deductable.AD,


        ad_times2_non_deductible_mb1_3: data.mealBreak[0].Deductable.Time,
        ad_times2_non_deductible_mb2_2: data.mealBreak[1].Deductable.AD,

        ad_times2_non_deductible_mb2_3: data.mealBreak[1].Deductable.Time,
        ad_times2_non_deductible_mb3_2: data.mealBreak[2].Deductable.AD,

        ad_times2_non_deductible_mb3_3: data.mealBreak[2].Deductable.Time,
        AdTimes2_EditModal_TotalHours: data.TotalHours === null ? '' : data.TotalHours,
        AdTimes2_EditModal_HourlyRate: data.HourlyRate === null ? '' : data.HourlyRate,

        AdTimes2_EditModal_Notes: data.Note === null ? '' : data.Note,
        AdTimes2_EditModal_PaymentFlag: data.PaymentFlag === null ? '' : data.PaymentFlag,
        AdTimes2_EditModal_ChartSort: data.ChartSort === null ? '' : data.ChartSort,
        AdTimes2_EditModal_ChartCode: data.ChartCode === null ? '' : data.ChartCode,
        AdTimes2_EditModal_WorkState: data.WorkState === null ? '' : data.WorkState,
        trackingfields: data.TrackingCodes,
        IsAdTimes2_EditModal_NameValid: true,
        IsAdTimes2_EditModal_TravelToValid: true,
        IsAdTimes2_EditModal_StartWorkValid: true,
        IsAdTimes2_EditModal_MealBreakValid: true,
        IsAdTimes2_EditModal_FinishWorkValid: true,
        IsAdTimes2_EditModal_TravelFromValid: true,
        IsAdTimes2_EditModal_HourlyRateValid: true,
        IsAdTimes2_EditModal_PmtFlagValid: true,
        IsAdTimes2_EditModal_PaymentFlagValid: true,
        IsAdTimes2_EditModal_ChartSortValid: true,
        IsAdTimes2_EditModal_ChartCodeValid: true,
        IsAdTimes2_EditModal_WorkStateValid: true,
        IsAdTimes2_EditModal_CTXValid: true,
        IsAdTimes2_EditModal_INValid: true,
      })
    }).catch(err => { console.log(err) });
    await this.setState({
      isLoading: false,

    });

    if (this.state.AdTimes2_editModalStartMB1 === '00:00' &&
      this.state.AdTimes2_editModalFinishMB1 === '00:00' &&
      this.state.AdTimes2_editModalStartMB2 === '00:00' &&
      this.state.AdTimes2_editModalFinishMB2 === '00:00' &&
      this.state.AdTimes2_editModalStartMB3 === '00:00' &&
      this.state.AdTimes2_editModalFinishMB3 === '00:00') {
      await this.setState({
        employee_meal: false
      })
    } else {
      await this.setState({
        employee_meal: true
      })
    }


  }

  add_adTimes_clicked = async () => {



    await this.setState({
      edit_adtimes2: false,


      IsAdTimes2_EditModal_TravelToValid: true,
      IsAdTimes2_EditModal_TravelFromValid: true,
      AdTimes2_EditModal_TravelTo: moment(this.state.AdTimes2_AdTimeForm_TravelTo, 'HH:mm').format('HH:mm'),
      AdTimes2_EditModal_TravelFrom: moment(this.state.AdTimes2_AdTimeForm_TravelFrom, 'HH:mm').format('HH:mm'),
      AdTimes2_EditModal_StartWork: moment(this.state.AdTimes2_AdTimeForm_CrewCall, 'HH:mm').format('HH:mm'),
      AdTimes2_EditModal_FinishWork: moment(this.state.AdTimes2_AdTimeForm_CrewWrap, 'HH:mm').format('HH:mm'),
      AdTimes2_EditModal_MealBreak: moment(this.state.AdTimes2_AdTimeForm_MealBreak, 'HH:mm').format('HH:mm'),
      AdTimes2_editModalStartMB1: moment(this.state.AdTimes2_editStartMB1, 'HH:mm').format('HH:mm'),
      AdTimes2_editModalFinishMB1: moment(this.state.AdTimes2_editFinishMB1, 'HH:mm').format('HH:mm'),
      AdTimes2_editModalStartMB2: moment(this.state.AdTimes2_editStartMB2, 'HH:mm').format('HH:mm'),
      AdTimes2_editModalFinishMB2: moment(this.state.AdTimes2_editFinishMB2, 'HH:mm').format('HH:mm'),
      AdTimes2_editModalStartMB3: moment(this.state.AdTimes2_editStartMB3, 'HH:mm').format('HH:mm'),
      AdTimes2_editModalFinishMB3: moment(this.state.AdTimes2_editFinishMB3, 'HH:mm').format('HH:mm'),
      ad_times2_non_deductible_mb1_2: this.state.adtimes2_non_detuctable_mb1_2,


      ad_times2_non_deductible_mb1_3: this.state.adtimes2_non_detuctable_mb1_3,
      ad_times2_non_deductible_mb2_2: this.state.adtimes2_non_detuctable_mb2_2,

      ad_times2_non_deductible_mb2_3: this.state.adtimes2_non_detuctable_mb2_3,
      ad_times2_non_deductible_mb3_2: this.state.adtimes2_non_detuctable_mb3_2,

      ad_times2_non_deductible_mb3_3: this.state.adtimes2_non_detuctable_mb3_3,

    })


    if (this.state.AdTimes2_editModalStartMB1 === '00:00' &&
      this.state.AdTimes2_editModalFinishMB1 === '00:00' &&
      this.state.AdTimes2_editModalStartMB2 === '00:00' &&
      this.state.AdTimes2_editModalFinishMB2 === '00:00' &&
      this.state.AdTimes2_editModalStartMB3 === '00:00' &&
      this.state.AdTimes2_editModalFinishMB3 === '00:00') {
      await this.setState({
        employee_meal: false
      })
    } else {
      await this.setState({
        employee_meal: true
      })
    }
    await this.find_diff();
  }
  AdTimesEditModalSaveHandler = async (editOrSave) => {
    let AdTimes2_EditModalErrors = this.state.AdTimes2_EditModalErrors;
    let IsAddTimes2_EditModalFormValid = this.state.IsAddTimes2_EditModalFormValid;



    if (this.state.AdTimes2_EditModal_StartWork === '00:00') {
      AdTimes2_EditModalErrors.AdTimes2_EditModal_StartWork = "This field is required";
    }
    if (this.state.AdTimes2_EditModal_HourlyRate === '') {
      AdTimes2_EditModalErrors.AdTimes2_EditModal_HourlyRate = "This field is required";
    }
    if (this.state.AdTimes2_EditModal_PayAs === '') {
      AdTimes2_EditModalErrors.AdTimes2_EditModal_PayAs = "This field is required";
    }

    IsAddTimes2_EditModalFormValid = this.state.AdTimes2_EditModal_HourlyRate !== '' &&
      this.state.AdTimes2_EditModal_PayAs !== '' && this.state.AdTimes2_EditModal_StartWork !== '00:00';
    await this.setState({
      IsAddTimes2_EditModalFormValid: IsAddTimes2_EditModalFormValid,
      AdTimes2_EditModalErrors: AdTimes2_EditModalErrors
    });
    if (this.state.AdTimes2_EditModal_Name !== '') {
      if (this.state.IsAddTimes2_EditModalFormValid) {
        this.after_issueScrollHandler();

        if (editOrSave === 'save') {
          this.setState({
            isLoading: true
          })
          var mealBreak = [
            {
              "Deductable": {

                "AD": this.state.ad_times2_non_deductible_mb1_2,
                "Time": this.state.ad_times2_non_deductible_mb1_3
              },
              "MB1Finish": moment(this.state.AdTimes2_editModalFinishMB1, 'HH:mm').format('HH:mm'),
              "MB1Start": moment(this.state.AdTimes2_editModalStartMB1, 'HH:mm').format('HH:mm'),
              "AD1Start": moment(this.state.AdTimes2_editModalStartMB1, 'HH:mm').format('HH:mm'),
              "AD1Finish": moment(this.state.AdTimes2_editModalFinishMB1, 'HH:mm').format('HH:mm'),




            },
            {
              "Deductable": {

                "AD": this.state.ad_times2_non_deductible_mb2_2,
                "Time": this.state.ad_times2_non_deductible_mb2_3
              },
              "MB2Finish": moment(this.state.AdTimes2_editModalFinishMB2, 'HH:mm').format('HH:mm'),
              "MB2Start": moment(this.state.AdTimes2_editModalStartMB2, 'HH:mm').format('HH:mm'),
              "AD2Start": moment(this.state.AdTimes2_editModalStartMB2, 'HH:mm').format('HH:mm'),
              "AD2Finish": moment(this.state.AdTimes2_editModalFinishMB2, 'HH:mm').format('HH:mm'),


            },
            {
              "Deductable": {

                "AD": this.state.ad_times2_non_deductible_mb3_2,
                "Time": this.state.ad_times2_non_deductible_mb3_3
              },
              "MB3Finish": moment(this.state.AdTimes2_editModalFinishMB3, 'HH:mm').format('HH:mm'),
              "MB3Start": moment(this.state.AdTimes2_editModalStartMB3, 'HH:mm').format('HH:mm'),
              "AD3Start": moment(this.state.AdTimes2_editModalStartMB3, 'HH:mm').format('HH:mm'),
              "AD3Finish": moment(this.state.AdTimes2_editModalFinishMB3, 'HH:mm').format('HH:mm'),
            }
          ];
          await API.post("createadtimescard", "/createadtimescard", {
            body: {
              "adlistDate": this.state.AdTimes2_AdTimeForm_Date,
              "ChartCode": this.state.AdTimes2_EditModal_ChartCode,
              "ChartSort": this.state.AdTimes2_EditModal_ChartSort,
              "Dept": this.state.emp_department,
              "EmployeeName": this.state.AdTimes2_EditModal_Name,
              "EmployeeGuid": this.state.emp_EmpGuid,
              "Start": moment(this.state.AdTimes2_EditModal_StartWork, 'HH:mm').format('HH:mm'),
              "Finish": moment(this.state.AdTimes2_EditModal_FinishWork, 'HH:mm').format('HH:mm'),
              "adlistGuid": this.state.tmp_Guid,
              "HourlyRate": this.state.AdTimes2_EditModal_HourlyRate,
              "Note": this.state.AdTimes2_EditModal_Notes,
              "Payas": this.state.AdTimes2_EditModal_PayAs,
              "PaymentFlag": this.state.AdTimes2_EditModal_PaymentFlag,
              "Position": this.state.emp_position,
              "TenantGuid": localStorage.getItem('useableGuid'),
              "TotalHours": this.state.AdTimes2_EditModal_TotalHours,
              "TravelTo": moment(this.state.AdTimes2_EditModal_TravelTo, 'HH:mm').format('HH:mm'),
              "TravelFrom": moment(this.state.AdTimes2_EditModal_TravelFrom, 'HH:mm').format('HH:mm'),
              "WorkState": this.state.AdTimes2_EditModal_WorkState,
              "mealBreak": mealBreak,
              "meals": moment(this.state.AdTimes2_EditModal_MealBreak, 'HH:mm').format('HH:mm'),

              "TrackingCodes": this.state.trackingfields
            }
          }).then(data => {
            this.getAdTimesList();
            API.post("recordactivity", "/recordactivity", {
              body: {
                "Description": "Adtimes create Activity",
                "UserName": localStorage.getItem('UserLogin'),
                "IPAddress": ip.address(),
                "TenantGuid": localStorage.getItem('useableGuid'),
                "Type": "Adtimes create ",
                "UserGuid": localStorage.getItem('userGuid')
              }
            }).then(data => {
              //toast.success('Adtimes create Activity Note');
            }).catch(err => {
              toast.error(err.message);
            });
            //toast.success('Data Created Successfully.');
          }).catch(err => {
            toast.error(err.message)
          });
          document.getElementById('close_employee').click();
          this.setState({
            isLoading: false
          });
        }
        else {
          await this.setState({
            isLoading: true
          })
          var mealBreak = [
            {
              "Deductable": {

                "AD": this.state.ad_times2_non_deductible_mb1_2,
                "Time": this.state.ad_times2_non_deductible_mb1_3
              },
              "MB1Finish": moment(this.state.AdTimes2_editModalFinishMB1, 'HH:mm').format('HH:mm'),
              "MB1Start": moment(this.state.AdTimes2_editModalStartMB1, 'HH:mm').format('HH:mm'),
              "AD1Start": moment(this.state.AdTimes2_editModalStartMB1, 'HH:mm').format('HH:mm'),
              "AD1Finish": moment(this.state.AdTimes2_editModalFinishMB1, 'HH:mm').format('HH:mm'),
            },
            {
              "Deductable": {

                "AD": this.state.ad_times2_non_deductible_mb2_2,
                "Time": this.state.ad_times2_non_deductible_mb2_3
              },
              "MB2Finish": moment(this.state.AdTimes2_editModalFinishMB2, 'HH:mm').format('HH:mm'),
              "MB2Start": moment(this.state.AdTimes2_editModalStartMB2, 'HH:mm').format('HH:mm'),
              "AD2Start": moment(this.state.AdTimes2_editModalStartMB2, 'HH:mm').format('HH:mm'),
              "AD2Finish": moment(this.state.AdTimes2_editModalFinishMB2, 'HH:mm').format('HH:mm'),
            },
            {
              "Deductable": {

                "AD": this.state.ad_times2_non_deductible_mb3_2,
                "Time": this.state.ad_times2_non_deductible_mb3_3
              },
              "MB3Finish": moment(this.state.AdTimes2_editModalFinishMB3, 'HH:mm').format('HH:mm'),
              "MB3Start": moment(this.state.AdTimes2_editModalStartMB3, 'HH:mm').format('HH:mm'),
              "AD3Start": moment(this.state.AdTimes2_editModalStartMB3, 'HH:mm').format('HH:mm'),
              "AD3Finish": moment(this.state.AdTimes2_editModalFinishMB3, 'HH:mm').format('HH:mm'),

            }
          ];
          await API.post("updateadtimes2adcards", "/updateadtimes2adcards", {
            body: {
              "Guid": this.state.guid_edit,
              "adlistDate": this.state.AdTimes2_AdTimeForm_Date,
              "ChartCode": this.state.AdTimes2_EditModal_ChartCode,
              "ChartSort": this.state.AdTimes2_EditModal_ChartSort,
              "Dept": this.state.emp_department,
              "EmployeeName": this.state.AdTimes2_EditModal_Name,
              "EmployeeGuid": this.state.emp_EmpGuid,
              "Start": moment(this.state.AdTimes2_EditModal_StartWork, 'HH:mm').format('HH:mm'),
              "Finish": moment(this.state.AdTimes2_EditModal_FinishWork, 'HH:mm').format('HH:mm'),
              "adlistGuid": this.state.tmp_Guid,
              "HourlyRate": this.state.AdTimes2_EditModal_HourlyRate,
              "Note": this.state.AdTimes2_EditModal_Notes,
              "Payas": this.state.AdTimes2_EditModal_PayAs,
              "PaymentFlag": this.state.AdTimes2_EditModal_PaymentFlag,
              "Position": this.state.emp_position,
              "TenantGuid": localStorage.getItem('useableGuid'),
              "TotalHours": this.state.AdTimes2_EditModal_TotalHours,
              "TravelTo": moment(this.state.AdTimes2_EditModal_TravelTo, 'HH:mm').format('HH:mm'),
              "TravelFrom": moment(this.state.AdTimes2_EditModal_TravelFrom, 'HH:mm').format('HH:mm'),
              "WorkState": this.state.AdTimes2_EditModal_WorkState,
              "mealBreak": mealBreak,
              "meals": moment(this.state.AdTimes2_EditModal_MealBreak, 'HH:mm').format('HH:mm'),

              "TrackingCodes": this.state.trackingfields
            }
          }).then(data => {
            this.getAdTimesList();
            API.post("recordactivity", "/recordactivity", {
              body: {
                "Description": "Adtimes update Activity",
                "UserName": localStorage.getItem('UserLogin'),
                "IPAddress": ip.address(),
                "TenantGuid": localStorage.getItem('useableGuid'),
                "Type": "Adtimes update ",
                "UserGuid": localStorage.getItem('userGuid')
              }
            }).then(data => {
              //toast.success('Adtimes update Activity Note');
            }).catch(err => {
              toast.error(err.message);
            });
            //toast.success('Data Updated Successfully.');
          }).catch(err => {
            toast.error(err.message)
          });
          document.getElementById('close_employee').click();
          await this.setState({
            isLoading: false
          })
        }
      }
    }
    else {
      toast.error('Please Select Employee First');
    }
  }
  onEmployeSelected = async (Guid) => {
    await this.setState({
      selectedEmployee: this.state.ad_times_all_employess.filter(item => item.EmpGuid === Guid),

    });

    await this.setState({
      AdTimes2_EditModal_Name: this.state.selectedEmployee[0].FirstName + ' ' + this.state.selectedEmployee[0].LastName,
      emp_position: this.state.selectedEmployee[0].Position,
      emp_department: this.state.selectedEmployee[0].Department,
      emp_EmpGuid: this.state.selectedEmployee[0].EmpGuid,
      AdTimes2_EditModal_HourlyRate: this.state.selectedEmployee[0].HourlyRate
    })
    document.getElementById('selectEmploye_pop_cancel').click();
  }
  adtimes2_sort = async (AdSearchTab, name) => {


    await this.setState({
      toggleCaretState: !this.state.toggleCaretState
    })
    if (this.state.toggleCaretState === false) {
      $("#" + name).removeAttr("style")
    }

    localStorage.setItem('adTimes2_sortLastTab', AdSearchTab)
    if (AdSearchTab === 'FirstName') {
      $("#FnCaret").show();
      $("#LnCaret").hide();
      $("#postionCaret").hide();
      $("#depCaret").hide();
      $("#travelToCaret").hide();
      $("#startCaret").hide();
      $("#mealCaret").hide();
      $("#wrapCaret").hide();
      $("#travelFromCaret").hide();
      $("#pmtCaret").hide();

      this.setState({
        AdTimes2SelectedTab: AdSearchTab,
        AdTimes_2_LastName_Toggle: false,
        AdTimes_2_Position_Toggle: false,
        AdTimes_2_Department_Toggle: false,
        AdTimes_2_TravelTo_Toggle: false,
        AdTimes_2_Start_Toggle: false,
        AdTimes_2_Meal_Toggle: false,
        AdTimes_2_Wrap_Toggle: false,
        AdTimes_2_TravelFrom_Toggle: false,
        AdTimes_2_PmtFlag_Toggle: false,
      })



      if (this.state.AdTimes_2_FirstName_Toggle) {
        await this.setState({
          AdTimes_2_FirstName_Toggle: false
        })
      } else {
        await this.setState({
          AdTimes_2_FirstName_Toggle: true
        })
      }
      if (this.state.AdTimes_2_FirstName_Toggle) {
        await this.setState({
          AdTimes2TableData: this.state.AdTimes2TableData.sort(function (a, b) {
            var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          AdTimes2TableData: this.state.AdTimes2TableData.sort(function (a, b) {
            var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        })
      }

    }
    if (AdSearchTab === 'LastName') {
      $("#FnCaret").hide();
      $("#LnCaret").show();
      $("#postionCaret").hide();
      $("#depCaret").hide();
      $("#travelToCaret").hide();
      $("#startCaret").hide();
      $("#mealCaret").hide();
      $("#wrapCaret").hide();
      $("#travelFromCaret").hide();
      $("#pmtCaret").hide();
      this.setState({
        AdTimes2SelectedTab: AdSearchTab,
        AdTimes_2_FirstName_Toggle: false,
        AdTimes_2_Position_Toggle: false,
        AdTimes_2_Department_Toggle: false,
        AdTimes_2_TravelTo_Toggle: false,
        AdTimes_2_Start_Toggle: false,
        AdTimes_2_Meal_Toggle: false,
        AdTimes_2_Wrap_Toggle: false,
        AdTimes_2_TravelFrom_Toggle: false,
        AdTimes_2_PmtFlag_Toggle: false,
      })



      if (this.state.AdTimes_2_LastName_Toggle) {
        await this.setState({
          AdTimes_2_LastName_Toggle: false
        })
      } else {
        await this.setState({
          AdTimes_2_LastName_Toggle: true
        })
      }
      if (this.state.AdTimes_2_LastName_Toggle) {
        await this.setState({
          AdTimes2TableData: this.state.AdTimes2TableData.sort(function (a, b) {
            var nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          AdTimes2TableData: this.state.AdTimes2TableData.sort(function (a, b) {
            var nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        })
      }


    }
    if (AdSearchTab === 'Position') {
      $("#FnCaret").hide();
      $("#LnCaret").hide();
      $("#postionCaret").show();
      $("#depCaret").hide();
      $("#travelToCaret").hide();
      $("#startCaret").hide();
      $("#mealCaret").hide();
      $("#wrapCaret").hide();
      $("#travelFromCaret").hide();
      $("#pmtCaret").hide();
      this.setState({
        AdTimes2SelectedTab: AdSearchTab,
        AdTimes_2_FirstName_Toggle: false,
        AdTimes_2_LastName_Toggle: false,
        AdTimes_2_Department_Toggle: false,
        AdTimes_2_TravelTo_Toggle: false,
        AdTimes_2_Start_Toggle: false,
        AdTimes_2_Meal_Toggle: false,
        AdTimes_2_Wrap_Toggle: false,
        AdTimes_2_TravelFrom_Toggle: false,
        AdTimes_2_PmtFlag_Toggle: false,
      })



      if (this.state.AdTimes_2_Position_Toggle) {
        await this.setState({
          AdTimes_2_Position_Toggle: false
        })
      } else {
        await this.setState({
          AdTimes_2_Position_Toggle: true
        })
      }
      if (this.state.AdTimes_2_Position_Toggle) {
        await this.setState({
          AdTimes2TableData: this.state.AdTimes2TableData.sort(function (a, b) {
            var nameA = a.Position.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Position.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          AdTimes2TableData: this.state.AdTimes2TableData.sort(function (a, b) {
            var nameA = a.Position.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Position.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        })
      }

    }
    if (AdSearchTab === 'Department') {
      $("#FnCaret").hide();
      $("#LnCaret").hide();
      $("#postionCaret").hide();
      $("#depCaret").show();
      $("#travelToCaret").hide();
      $("#startCaret").hide();
      $("#mealCaret").hide();
      $("#wrapCaret").hide();
      $("#travelFromCaret").hide();
      $("#pmtCaret").hide();
      this.setState({
        AdTimes2SelectedTab: AdSearchTab,
        AdTimes_2_FirstName_Toggle: false,
        AdTimes_2_LastName_Toggle: false,
        AdTimes_2_Position_Toggle: false,
        AdTimes_2_TravelTo_Toggle: false,
        AdTimes_2_Start_Toggle: false,
        AdTimes_2_Meal_Toggle: false,
        AdTimes_2_Wrap_Toggle: false,
        AdTimes_2_TravelFrom_Toggle: false,
        AdTimes_2_PmtFlag_Toggle: false,
      })



      if (this.state.AdTimes_2_Department_Toggle) {
        await this.setState({
          AdTimes_2_Department_Toggle: false
        })
      } else {
        await this.setState({
          AdTimes_2_Department_Toggle: true
        })
      }
      if (this.state.AdTimes_2_Department_Toggle) {
        await this.setState({
          AdTimes2TableData: this.state.AdTimes2TableData.sort(function (a, b) {
            var nameA = a.Dept.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Dept.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          AdTimes2TableData: this.state.AdTimes2TableData.sort(function (a, b) {
            var nameA = a.Dept.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Dept.toUpperCase(); // ignore upper and lowercase
            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      }


    }
    if (AdSearchTab === 'TravelTo') {
      $("#FnCaret").hide();
      $("#LnCaret").hide();
      $("#postionCaret").hide();
      $("#depCaret").hide();
      $("#travelToCaret").show();
      $("#startCaret").hide();
      $("#mealCaret").hide();
      $("#wrapCaret").hide();
      $("#travelFromCaret").hide();
      $("#pmtCaret").hide();
      this.setState({
        AdTimes2SelectedTab: AdSearchTab,
        AdTimes_2_FirstName_Toggle: false,
        AdTimes_2_LastName_Toggle: false,
        AdTimes_2_Position_Toggle: false,
        AdTimes_2_Department_Toggle: false,
        AdTimes_2_Start_Toggle: false,
        AdTimes_2_Meal_Toggle: false,
        AdTimes_2_Wrap_Toggle: false,
        AdTimes_2_TravelFrom_Toggle: false,
        AdTimes_2_PmtFlag_Toggle: false,
      })



      if (this.state.AdTimes_2_TravelTo_Toggle) {
        await this.setState({
          AdTimes_2_TravelTo_Toggle: false
        })
      } else {
        await this.setState({
          AdTimes_2_TravelTo_Toggle: true
        })
      }
      if (this.state.AdTimes_2_TravelTo_Toggle) {
        await this.setState({
          AdTimes2TableData: this.state.AdTimes2TableData.sort(function (a, b) {
            var nameA = a.TravelTo.toUpperCase(); // ignore upper and lowercase
            var nameB = b.TravelTo.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          AdTimes2TableData: this.state.AdTimes2TableData.sort(function (a, b) {
            var nameA = a.TravelTo.toUpperCase(); // ignore upper and lowercase
            var nameB = b.TravelTo.toUpperCase(); // ignore upper and lowercase
            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      }


    }

    if (AdSearchTab === 'Start') {
      $("#FnCaret").hide();
      $("#LnCaret").hide();
      $("#postionCaret").hide();
      $("#depCaret").hide();
      $("#travelToCaret").hide();
      $("#startCaret").show();
      $("#mealCaret").hide();
      $("#wrapCaret").hide();
      $("#travelFromCaret").hide();
      $("#pmtCaret").hide();
      this.setState({
        AdTimes2SelectedTab: AdSearchTab,
        AdTimes_2_FirstName_Toggle: false,
        AdTimes_2_LastName_Toggle: false,
        AdTimes_2_Position_Toggle: false,
        AdTimes_2_Department_Toggle: false,
        AdTimes_2_TravelTo_Toggle: false,
        AdTimes_2_Meal_Toggle: false,
        AdTimes_2_Wrap_Toggle: false,
        AdTimes_2_TravelFrom_Toggle: false,
        AdTimes_2_PmtFlag_Toggle: false,
      })



      if (this.state.AdTimes_2_Start_Toggle) {
        await this.setState({
          AdTimes_2_Start_Toggle: false
        })
      } else {
        await this.setState({
          AdTimes_2_Start_Toggle: true
        })
      }
      if (this.state.AdTimes_2_Start_Toggle) {
        await this.setState({
          AdTimes2TableData: this.state.AdTimes2TableData.sort(function (a, b) {
            var nameA = a.Start.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Start.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          AdTimes2TableData: this.state.AdTimes2TableData.sort(function (a, b) {
            var nameA = a.Start.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Start.toUpperCase(); // ignore upper and lowercase
            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      }


    }

    if (AdSearchTab === 'Meal') {
      $("#FnCaret").hide();
      $("#LnCaret").hide();
      $("#postionCaret").hide();
      $("#depCaret").hide();
      $("#travelToCaret").hide();
      $("#startCaret").hide();
      $("#mealCaret").show();
      $("#wrapCaret").hide();
      $("#travelFromCaret").hide();
      $("#pmtCaret").hide();
      this.setState({
        AdTimes2SelectedTab: AdSearchTab,
        AdTimes_2_FirstName_Toggle: false,
        AdTimes_2_LastName_Toggle: false,
        AdTimes_2_Position_Toggle: false,
        AdTimes_2_Department_Toggle: false,
        AdTimes_2_TravelTo_Toggle: false,
        AdTimes_2_Start_Toggle: false,
        AdTimes_2_Wrap_Toggle: false,
        AdTimes_2_TravelFrom_Toggle: false,
        AdTimes_2_PmtFlag_Toggle: false,
      })



      if (this.state.AdTimes_2_Meal_Toggle) {
        await this.setState({
          AdTimes_2_Meal_Toggle: false
        })
      } else {
        await this.setState({
          AdTimes_2_Meal_Toggle: true
        })
      }
      if (this.state.AdTimes_2_Meal_Toggle) {
        await this.setState({
          AdTimes2TableData: this.state.AdTimes2TableData.sort(function (a, b) {
            var nameA = a.meals.toUpperCase(); // ignore upper and lowercase
            var nameB = b.meals.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          AdTimes2TableData: this.state.AdTimes2TableData.sort(function (a, b) {
            var nameA = a.meals.toUpperCase(); // ignore upper and lowercase
            var nameB = b.meals.toUpperCase(); // ignore upper and lowercase
            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      }


    }




    if (AdSearchTab === 'Wrap') {


      $("#FnCaret").hide();
      $("#LnCaret").hide();
      $("#postionCaret").hide();
      $("#depCaret").hide();
      $("#travelToCaret").hide();
      $("#startCaret").hide();
      $("#mealCaret").hide();
      $("#wrapCaret").show();
      $("#travelFromCaret").hide();
      $("#pmtCaret").hide();
      this.setState({
        AdTimes2SelectedTab: AdSearchTab,
        AdTimes_2_FirstName_Toggle: false,
        AdTimes_2_LastName_Toggle: false,
        AdTimes_2_Position_Toggle: false,
        AdTimes_2_Department_Toggle: false,
        AdTimes_2_TravelTo_Toggle: false,
        AdTimes_2_Start_Toggle: false,
        AdTimes_2_Meal_Toggle: false,
        AdTimes_2_TravelFrom_Toggle: false,
        AdTimes_2_PmtFlag_Toggle: false,
      })



      if (this.state.AdTimes_2_Wrap_Toggle) {
        await this.setState({
          AdTimes_2_Wrap_Toggle: false
        })
      } else {
        await this.setState({
          AdTimes_2_Wrap_Toggle: true
        })
      }
      if (this.state.AdTimes_2_Wrap_Toggle) {
        await this.setState({
          AdTimes2TableData: this.state.AdTimes2TableData.sort(function (a, b) {
            var nameA = a.Finish.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Finish.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          AdTimes2TableData: this.state.AdTimes2TableData.sort(function (a, b) {
            var nameA = a.Finish.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Finish.toUpperCase(); // ignore upper and lowercase
            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      }


    }



    if (AdSearchTab === 'TravelFrom') {
      $("#FnCaret").hide();
      $("#LnCaret").hide();
      $("#postionCaret").hide();
      $("#depCaret").hide();
      $("#travelToCaret").hide();
      $("#startCaret").hide();
      $("#mealCaret").hide();
      $("#wrapCaret").hide();
      $("#travelFromCaret").show();
      $("#pmtCaret").hide();
      this.setState({
        AdTimes2SelectedTab: AdSearchTab,
        AdTimes_2_FirstName_Toggle: false,
        AdTimes_2_LastName_Toggle: false,
        AdTimes_2_Position_Toggle: false,
        AdTimes_2_Department_Toggle: false,
        AdTimes_2_TravelTo_Toggle: false,
        AdTimes_2_Start_Toggle: false,
        AdTimes_2_Meal_Toggle: false,
        AdTimes_2_Wrap_Toggle: false,
        AdTimes_2_PmtFlag_Toggle: false,
      })



      if (this.state.AdTimes_2_TravelFrom_Toggle) {
        await this.setState({
          AdTimes_2_TravelFrom_Toggle: false
        })
      } else {
        await this.setState({
          AdTimes_2_TravelFrom_Toggle: true
        })
      }
      if (this.state.AdTimes_2_TravelFrom_Toggle) {
        await this.setState({
          AdTimes2TableData: this.state.AdTimes2TableData.sort(function (a, b) {
            var nameA = a.TravelFrom.toUpperCase(); // ignore upper and lowercase
            var nameB = b.TravelFrom.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          AdTimes2TableData: this.state.AdTimes2TableData.sort(function (a, b) {
            var nameA = a.TravelFrom.toUpperCase(); // ignore upper and lowercase
            var nameB = b.TravelFrom.toUpperCase(); // ignore upper and lowercase
            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      }


    }



    if (AdSearchTab === 'PmtFlag') {
      $("#FnCaret").hide();
      $("#LnCaret").hide();
      $("#postionCaret").hide();
      $("#depCaret").hide();
      $("#travelToCaret").hide();
      $("#startCaret").hide();
      $("#mealCaret").hide();
      $("#wrapCaret").hide();
      $("#travelFromCaret").hide();
      $("#pmtCaret").show();
      this.setState({
        AdTimes2SelectedTab: AdSearchTab,
        AdTimes_2_FirstName_Toggle: false,
        AdTimes_2_LastName_Toggle: false,
        AdTimes_2_Position_Toggle: false,
        AdTimes_2_Department_Toggle: false,
        AdTimes_2_TravelTo_Toggle: false,
        AdTimes_2_Start_Toggle: false,
        AdTimes_2_Meal_Toggle: false,
        AdTimes_2_Wrap_Toggle: false,
        AdTimes_2_TravelFrom_Toggle: false,
      })



      if (this.state.AdTimes_2_PmtFlag_Toggle) {
        await this.setState({
          AdTimes_2_PmtFlag_Toggle: false
        })
      } else {
        await this.setState({
          AdTimes_2_PmtFlag_Toggle: true
        })
      }
      if (this.state.AdTimes_2_PmtFlag_Toggle) {
        await this.setState({
          AdTimes2TableData: this.state.AdTimes2TableData.sort(function (a, b) {
            var nameA = a.NewPaymentFlag.toUpperCase(); // ignore upper and lowercase
            var nameB = b.NewPaymentFlag.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          AdTimes2TableData: this.state.AdTimes2TableData.sort(function (a, b) {
            var nameA = a.NewPaymentFlag.toUpperCase(); // ignore upper and lowercase
            var nameB = b.NewPaymentFlag.toUpperCase(); // ignore upper and lowercase
            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      }

    }



  }
  clearstates_addpopup = () => {
    this.after_issueScrollHandler();
    this.setState({
      guid_edit: '',
      emp_EmpGuid: '',
      emp_position: '',
      emp_department: '',
      AdTimes2_EditModal_Name: '',
      AdTimes2_EditModal_PayAs: '',
      AdTimes2_EditModal_TravelTo: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      AdTimes2_EditModal_StartWork: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      AdTimes2_EditModal_MealBreak: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      AdTimes2_EditModal_FinishWork: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      AdTimes2_EditModal_TravelFrom: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      AdTimes2_EditModal_TotalHours: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      AdTimes2_EditModal_HourlyRate: '',
      AdTimes2_EditModal_PmtFlag: '',
      AdTimes2_EditModal_Notes: '',
      AdTimes2_EditModal_PaymentFlag: '',
      AdTimes2_EditModal_ChartSort: '',
      AdTimes2_EditModal_ChartCode: '',
      AdTimes2_EditModal_WorkState: '',
      trackingfields: JSON.parse(localStorage.getItem('completetenant')).TrackingFields,
      IsAdTimes2_EditModal_NameValid: false,
      IsAdTimes2_EditModal_PayAsValid: false,
      IsAdTimes2_EditModal_TravelToValid: false,
      IsAdTimes2_EditModal_StartWorkValid: false,
      IsAdTimes2_EditModal_MealBreakValid: false,
      IsAdTimes2_EditModal_FinishWorkValid: false,
      IsAdTimes2_EditModal_TravelFromValid: false,
      IsAdTimes2_EditModal_HourlyRateValid: false,
      IsAdTimes2_EditModal_PmtFlagValid: false,
      IsAdTimes2_EditModal_PaymentFlagValid: false,
      IsAdTimes2_EditModal_ChartSortValid: false,
      IsAdTimes2_EditModal_ChartCodeValid: false,
      IsAdTimes2_EditModal_WorkStateValid: false,
      IsAdTimes2_EditModal_CTXValid: false,
      IsAdTimes2_EditModal_INValid: false,
      AdTimes2_EditModalErrors: {

        AdTimes2_EditModal_Name: '',
        AdTimes2_EditModal_PayAs: '',
        AdTimes2_EditModal_TravelTo: '',
        AdTimes2_EditModal_StartWork: '',
        AdTimes2_EditModal_MealBreak: '',
        AdTimes2_EditModal_FinishWork: '',
        AdTimes2_EditModal_TravelFrom: '',
        AdTimes2_EditModal_HourlyRate: '',
        AdTimes2_EditModal_PmtFlag: '',
        AdTimes2_EditModal_Notes: '',
        AdTimes2_EditModal_PaymentFlag: '',
        AdTimes2_EditModal_ChartSort: '',
        AdTimes2_EditModal_ChartCode: '',
        AdTimes2_EditModal_WorkState: '',
        AdTimes2_EditModal_CTX: '',
        AdTimes2_EditModal_IN: ''

      },
      selectedEmployee: null,
      AdTimes2_editModalStartMB1: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      AdTimes2_editModalFinishMB1: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      AdTimes2_editModalStartMB2: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      AdTimes2_editModalFinishMB2: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      AdTimes2_editModalStartMB3: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      AdTimes2_editModalFinishMB3: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      ad_times2_non_deductible_mb1_1: false,
      ad_times2_non_deductible_mb1_2: false,
      ad_times2_non_deductible_mb1_3: true,
      ad_times2_non_deductible_mb2_1: false,
      ad_times2_non_deductible_mb2_2: false,
      ad_times2_non_deductible_mb2_3: true,
      ad_times2_non_deductible_mb3_1: false,
      ad_times2_non_deductible_mb3_2: false,
      ad_times2_non_deductible_mb3_3: true,
      employee_meal: false,

    })
  }
  onExcelExport = async () => {
    this.setState({
      isLoading: true
    })
    const excelData = [];
    this.state.AdTimes2TableData.map(item => {
      if (item.checked === true) {
        excelData.push({
          adlistdate: item.adlistdate,
          ChartCode: item.ChartCode,
          ChartSort: item.ChartSort,
          Dept: item.Dept,
          EmployeeGuid: item.EmployeeGuid,
          EmployeeName: item.EmployeeName.split(" ")[0],
          LastName: item.EmployeeName.split(" ")[1],
          Finish: item.Finish,
          Guid: item.Guid,
          HourlyRate: item.HourlyRate,
          Note: item.Note,
          Payas: item.Payas,
          PaymentFlag: item.PaymentFlag,
          Position: item.Position,
          Start: item.Start,
          TenantGuid: item.TenantGuid,
          TotalHours: item.TotalHours,
          // CTX: item.TrackingCodes.CTX,
          // F1: item.TrackingCodes.F1,
          // F2: item.TrackingCodes.F2,
          // F3: item.TrackingCodes.F3,
          // F4: item.TrackingCodes.F4,
          // F5: item.TrackingCodes.F5,
          // F6: item.TrackingCodes.F6,
          // F7: item.TrackingCodes.F7,
          // F8: item.TrackingCodes.F8,
          // IN: item.TrackingCodes.IN,
          TravelFrom: item.TravelFrom,
          TravelTo: item.TravelTo,
          WorkState: item.WorkState,
          adlistGuid: item.adlistGuid,
          meals: item.meals,
          startmb1_detuctable_AD: (item.mealBreak[0].Deductable.AD === null ? false : true),

          startmb1_detuctable_Time: (item.mealBreak[0].Deductable.Time === null ? false : true),
          mb1Start: item.mealBreak[0].MB1Start,
          mb1Finish: item.mealBreak[0].MB1Finish,

          ad1Start: item.mealBreak[0].AD1Start,
          ad1Finish: item.mealBreak[0].AD1Finish,
          startmb2_detuctable_AD: (item.mealBreak[1].Deductable.AD === null ? false : true),

          startmb2_detuctable_Time: (item.mealBreak[1].Deductable.Time === null ? false : true),
          mb2Start: item.mealBreak[1].MB2Start,
          mb2Finish: item.mealBreak[1].MB2Start,

          ad2Start: item.mealBreak[1].AD2Start,
          ad2Finish: item.mealBreak[1].AD2Start,
          startmb3_detuctable_AD: (item.mealBreak[2].Deductable.AD === null ? false : true),

          startmb3_detuctable_Time: (item.mealBreak[2].Deductable.Time === null ? false : true),
          mb3Start: item.mealBreak[2].MB3Start,
          mb3Finish: item.mealBreak[2].MB3Start,

          ad3Start: item.mealBreak[2].AD3Start,
          ad3Finish: item.mealBreak[2].AD3Start,
        })
      }
    })
    await this.setState({
      excelData: excelData
    })

    // return;
    this.setState({
      isLoading: false
    })
  }
  onStartExportingExcel = () => {
    if (this.state.excelData.length > 0) {
      $(":contains('Download')").closest('button').click();
    } else {
      toast.error('Please select data to export');
      document.getElementById('export_excel_btn').click();
    }

  }
  Adtimes2AllTableCheckBoxHandler = (Guid, isChecked) => {
    let isAllChecked = (Guid === 'IsAllAdTimes2CheckBoxSelected' && isChecked);
    let isAllUnChecked = (Guid === 'IsAllAdTimes2CheckBoxSelected' && !isChecked);
    const checked = isChecked;

    const checkList = this.state.AdTimes2TableData.map((item, index) => {
      if (isAllChecked || item.Guid === Guid) {
        return Object.assign({}, item, {
          checked,
        })

      }
      else if (isAllUnChecked) {
        return Object.assign({}, item, {
          checked: false
        })
      }

      return item;

    })


    let isAllSelected = (checkList.findIndex(item => item.checked === false) === -1) || isAllChecked;
    if (isAllChecked) {
      let arr = [];
      checkList.map(items => {
        arr.push(items.Guid);
      });
      this.setState({
        checkedarray: arr
      });
    }
    else if (isAllUnChecked) {
      let arr = [];
      checkList.map(items => {
        const index = arr.indexOf(items.Guid);
        arr.splice(index, 1);
      });
      this.setState({
        checkedarray: arr
      });
    } else if (isChecked) {
      this.setState({ checkedarray: [...this.state.checkedarray, Guid] });
    } else if (!isChecked) {
      let arr = [];
      arr = [...this.state.checkedarray];
      const index = arr.indexOf(Guid);
      arr.splice(index, 1);
      this.setState({
        checkedarray: arr
      });
    }

    this.setState({
      IsAllAdTimes2CheckBoxSelected: isAllSelected,
      AdTimes2TableData: checkList
    })



  }
  openEmployeePopUp = () => {
    document.getElementById('search_employee_popup').click();
  }
  TerminateHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.checked
    })
  }
  trackinghandler = async (event) => {
    var tmp_field = this.state.trackingfields;
    tmp_field.map(val => {
      if (val.uuid === event.target.name) {
        val.defaultValue = event.target.value !== "" ? event.target.value : null;
      }

    })
    await this.setState({
      trackingfields: tmp_field
    })
  }


  trackinghandlerdate = async (name, date) => {


    var tmp_field = this.state.trackingfields;
    tmp_field.map(val => {
      if (val.uuid === name) {
        val.defaultValue = date;
      }

    })
    await this.setState({
      trackingfields: tmp_field
    })
  }
  render() {
    const empMapArray = this.state.Adtimes2_Modal2SearchName_TerminatedChkbx ? this.state.ad_times_all_employess : this.state.adTimes_NonTerminateEmp;
    var monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    var weekday = ['Sunday', 'Monday', 'Tueday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][new Date(this.state.AdTimes2_AdTimeForm_Date).getDay()];
    return (
      <div>
        {this.state.isLoading ?
          <div className="se-pre-con"></div>
          : ''}
        <Header1
          link='/ad_times' pageHeader='AD Times'
          childProps={this.props.childProps}

        />
        <div className="col-lg-10 col-lg-offset-1 mrg_top_adtimes">
          <div className="col-sm-6 p0">
            <div className="search_area_adtimes">
              <input
                name="adtimes2_tableSearchBox"
                type="text"
                id="adtimes2_tableSearchBox"
                className="search_adtimes"
                placeholder={`Search By ${this.state.AdTimes2SelectedTab}`}
                value={this.state.adtimes2_tableSearchBox}
                onChange={this.adTimes2_SearchHandler}
              />
              <img
                src={ic_search}
                width="20"
                height="20"
                alt=""
              />
            </div>
          </div>


          <div className="col-sm-6 p0 mrg_topright2">
            <div className="dropdown menu_icon_adtimes">
              <button
                className="dropdown-toggle btn_adtimes_menu"
                type="button"
                data-toggle="dropdown"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="5.223"
                  height="20.893"
                  viewBox="0 0 5.223 20.893"
                >
                  <path
                    id="ic_more_horiz_24px"
                    d="M6.612,10a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,6.612,10Zm15.669,0a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,22.281,10Zm-7.835,0a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,14.446,10Z"
                    transform="translate(15.223 -4) rotate(90)"
                    fill="#7b7b7b"
                  />
                </svg>
              </button>
              <ul className="dropdown-menu dropleft dropdown_menu_users_down dropdown_menu_adtimes drop_down_ad_time">
                <li>
                  <a
                    hreaf={null}
                    data-toggle="modal"
                    data-target="#adtimes_plus_pop"
                    className="cursorPointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11.102"
                      height="11.102"
                      viewBox="0 0 11.102 11.102"
                    >
                      <path
                        id="ic_announcement_24px"
                        d="M11.991,2H3.11a1.109,1.109,0,0,0-1.1,1.11L2,13.1l2.22-2.22h7.771a1.113,1.113,0,0,0,1.11-1.11V3.11A1.113,1.113,0,0,0,11.991,2ZM8.106,7H7V3.665h1.11Zm0,2.22H7V8.106h1.11Z"
                        transform="translate(-2 -2)"
                        fill="#86878a"
                      />
                    </svg>
                    Details
                  </a>
                </li>

                <li>
                  <a
                    hreaf={null}
                    data-toggle="modal"
                    data-target="#select_paste_pop"
                    className="cursorPointer"
                    onClick={this.clearSelectPaste}
                  >
                    <img
                      src={select_paste}
                      className="mrg_sendicon"
                      width="12"
                      height="15"
                      alt=""
                    />
                    Select Paste
                  </a>
                </li>

                <li>
                  <a
                    hreaf={null}
                    data-toggle="modal"
                    data-target="#adtimes_reports"
                    className="lock_invoice_icon cursorPointer"
                  >
                    <img
                      src={excel}
                      className="mrg_sendicon"
                      width="11"
                      height="14"
                      alt=""
                    />
                    Reports
                  </a>
                </li>

                <li>
                  <a
                    hreaf={null}
                    data-toggle="modal"
                    data-target="#excel_pop_adtimes"
                    className="cursorPointer"
                    onClick={this.onExcelExport}
                  >
                    <img
                      src={excel}
                      className="mrg_sendicon"
                      width="11"
                      height="14"
                      alt=""
                    />
                    Excel
                  </a>
                </li>
              </ul>
            </div>

            <a href={null} className="plus_icon_adtimes cursorPointer"
              data-toggle="modal"
              data-target="#adtimes2_edit_pop"
              onClick={this.add_adTimes_clicked}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
              >
                <path
                  id="ic_add_24px"
                  d="M20,13.571H13.571V20H11.429V13.571H5V11.429h6.429V5h2.143v6.429H20Z"
                  transform="translate(-5 -5)"
                  fill="#7B7B7B"
                />
              </svg>
            </a>
          </div>

          <div className="clear10" />

          <ExcelFile filename={"Ad_Times2_" + new Date()}>
            <ExcelSheet data={this.state.excelData} name="Ad_Times2">
              <ExcelColumn label="Guid" value="Guid" />
              <ExcelColumn label="EmployeeGuid" value="EmployeeGuid" />
              <ExcelColumn label="EmployeeName" value="EmployeeName" />
              <ExcelColumn label="LastName" value="LastName" />
              <ExcelColumn label="Dept" value="Dept" />
              <ExcelColumn label="Position" value="Position" />
              <ExcelColumn label="ChartCode" value="ChartCode" />
              <ExcelColumn label="ChartSort" value="ChartSort" />
              <ExcelColumn label="Finish" value="Finish" />
              <ExcelColumn label="HourlyRate" value="HourlyRate" />
              <ExcelColumn label="Note" value="Note" />
              <ExcelColumn label="Payas" value="Payas" />
              <ExcelColumn label="PaymentFlag" value="PaymentFlag" />
              <ExcelColumn label="Start" value="Start" />
              <ExcelColumn label="TenantGuid" value="TenantGuid" />
              <ExcelColumn label="TotalHours" value="TotalHours" />
              <ExcelColumn label="TravelFrom" value="TravelFrom" />
              <ExcelColumn label="TravelTo" value="TravelTo" />
              <ExcelColumn label="WorkState" value="WorkState" />
              <ExcelColumn label="adlistGuid" value="adlistGuid" />
              <ExcelColumn label="meals" value="meals" />
              <ExcelColumn label="mb1Finish" value="mb1Finish" />
              <ExcelColumn label="mb1Start" value="mb1Start" />

              <ExcelColumn label="ad1Finish" value="ad1Finish" />
              <ExcelColumn label="ad1Start" value="ad1Start" />

              <ExcelColumn label="mb2Finish" value="mb2Finish" />
              <ExcelColumn label="mb2Start" value="mb2Start" />

              <ExcelColumn label="ad2Finish" value="ad2Finish" />
              <ExcelColumn label="ad2Start" value="ad2Start" />

              <ExcelColumn label="mb3Finish" value="mb3Finish" />
              <ExcelColumn label="mb3Start" value="mb3Start" />

              <ExcelColumn label="ad3Finish" value="ad3Finish" />
              <ExcelColumn label="ad3Start" value="ad3Start" />

              <ExcelColumn label="startmb1_detuctable_AD" value="startmb1_detuctable_AD" />

              <ExcelColumn label="startmb1_detuctable_Time" value="startmb1_detuctable_Time" />
              <ExcelColumn label="startmb2_detuctable_AD" value="startmb2_detuctable_AD" />

              <ExcelColumn label="startmb2_detuctable_Time" value="startmb2_detuctable_Time" />
              <ExcelColumn label="startmb3_detuctable_AD" value="startmb3_detuctable_AD" />

              <ExcelColumn label="startmb3_detuctable_Time" value="startmb3_detuctable_Time" />
              <ExcelColumn label="mb3Start" value="mb3Start" />
              <ExcelColumn label="adlistdate" value="adlistdate" />
            </ExcelSheet>
          </ExcelFile>

          <div className="col-sm-12 p0">
            <div className="overflow_scroll_sm">
              <table
                width="100%"
                className="table table-bordered timecard_table adtimes_list_table"
              >

                <thead>
                  <tr className="table_header_adtimes">
                    <th width="3%" align="center">
                      <input name='IsAllAdTimes2CheckBoxSelected'
                        type="checkbox"
                        checked={this.state.IsAllAdTimes2CheckBoxSelected}
                        onChange={(e) => this.Adtimes2AllTableCheckBoxHandler('IsAllAdTimes2CheckBoxSelected', e.target.checked)} />
                    </th>
                    <th align="center">
                      <div className="cursorPointer" onClick={() => this.adtimes2_sort('FirstName', 'FnCaret')} >
                        <div className="date_mrg_left p-new"> First Name
<div className="adtimes_downarrow new1">
                            <svg id="FnCaret" className={!this.state.AdTimes_2_FirstName_Toggle ? "toggleCaretClass" : ''}
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.337"
                              height="6.887"
                              viewBox="0 0 12.337 6.887"
                            >
                              <path
                                id="ic_arrow_drop_down_24px"
                                d="M7,10l6.169,6.887L19.337,10Z"
                                transform="translate(-7 -10)"
                                fill="#30679a"
                              />
                            </svg>
                          </div>
                        </div>

                      </div>
                    </th>
                    <th align="center">
                      <div className="cursorPointer" onClick={() => this.adtimes2_sort('LastName', 'LnCaret')} >
                        <div className="date_mrg_left p-new"> Last Name
<div className="adtimes_downarrow new1">
                            <svg
                              id="LnCaret" className={!this.state.AdTimes_2_LastName_Toggle ? "toggleCaretClass" : ''}
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.337"
                              height="6.887"
                              viewBox="0 0 12.337 6.887"
                            >
                              <path
                                id="ic_arrow_drop_down_24px"
                                d="M7,10l6.169,6.887L19.337,10Z"
                                transform="translate(-7 -10)"
                                fill="#30679a"
                              />
                            </svg>
                          </div>
                        </div>

                      </div>
                    </th>
                    <th align="center">
                      <div className="cursorPointer" onClick={() => this.adtimes2_sort('Position', 'postionCaret')} >
                        <div className="date_mrg_left p-new"> Position
<div className="adtimes_downarrow new1">
                            <svg
                              id="postionCaret" className={!this.state.AdTimes_2_Position_Toggle ? "toggleCaretClass" : ''}
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.337"
                              height="6.887"
                              viewBox="0 0 12.337 6.887"
                            >
                              <path
                                id="ic_arrow_drop_down_24px"
                                d="M7,10l6.169,6.887L19.337,10Z"
                                transform="translate(-7 -10)"
                                fill="#30679a"
                              />
                            </svg>
                          </div>
                        </div>

                      </div>
                    </th>
                    <th align="center">
                      <div className="cursorPointer" onClick={() => this.adtimes2_sort('Department', 'depCaret')} >
                        <div className="date_mrg_left p-new"> Department
<div className="adtimes_downarrow new1">
                            <svg
                              id="depCaret" className={!this.state.AdTimes_2_Department_Toggle ? "toggleCaretClass" : ''}
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.337"
                              height="6.887"
                              viewBox="0 0 12.337 6.887"
                            >
                              <path
                                id="ic_arrow_drop_down_24px"
                                d="M7,10l6.169,6.887L19.337,10Z"
                                transform="translate(-7 -10)"
                                fill="#30679a"
                              />
                            </svg>
                          </div>
                        </div>

                      </div>
                    </th>
                    <th align="center">
                      <div className="cursorPointer" onClick={() => this.adtimes2_sort('TravelTo', 'travelToCaret')} >
                        <div className="date_mrg_left p-new"> Travel To
<div className="adtimes_downarrow new1">
                            <svg
                              id="travelToCaret" className={!this.state.AdTimes_2_TravelTo_Toggle ? "toggleCaretClass" : ''}
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.337"
                              height="6.887"
                              viewBox="0 0 12.337 6.887"
                            >
                              <path
                                id="ic_arrow_drop_down_24px"
                                d="M7,10l6.169,6.887L19.337,10Z"
                                transform="translate(-7 -10)"
                                fill="#30679a"
                              />
                            </svg>
                          </div>
                        </div>

                      </div>
                    </th>
                    <th align="center">
                      <div className="cursorPointer" onClick={() => this.adtimes2_sort('Start', 'startCaret')} >
                        <div className="date_mrg_left p-new"> Start
<div className="adtimes_downarrow new1">
                            <svg
                              id="startCaret" className={!this.state.AdTimes_2_Start_Toggle ? "toggleCaretClass" : ''}
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.337"
                              height="6.887"
                              viewBox="0 0 12.337 6.887"
                            >
                              <path
                                id="ic_arrow_drop_down_24px"
                                d="M7,10l6.169,6.887L19.337,10Z"
                                transform="translate(-7 -10)"
                                fill="#30679a"
                              />
                            </svg>
                          </div>
                        </div>

                      </div>
                    </th>
                    <th align="center">


                      <div className="cursorPointer" onClick={() => this.adtimes2_sort('Meal', 'mealCaret')} >
                        <div className="date_mrg_left p-new"> Meal{" "}
                          <div className="adtimes_downarrow new1">
                            <svg
                              id="mealCaret" className={!this.state.AdTimes_2_Meal_Toggle ? "toggleCaretClass" : ''}
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.337"
                              height="6.887"
                              viewBox="0 0 12.337 6.887"
                            >
                              <path
                                id="ic_arrow_drop_down_24px"
                                d="M7,10l6.169,6.887L19.337,10Z"
                                transform="translate(-7 -10)"
                                fill="#30679a"
                              />
                            </svg>
                          </div>
                        </div>

                      </div>
                    </th>
                    <th align="center">
                      <div className="cursorPointer" onClick={() => this.adtimes2_sort('Wrap', 'wrapCaret')} >
                        <div className="date_mrg_left p-new"> Wrap
<div className="adtimes_downarrow new1">
                            <svg
                              id="wrapCaret" className={!this.state.AdTimes_2_Wrap_Toggle ? "toggleCaretClass" : ''}
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.337"
                              height="6.887"
                              viewBox="0 0 12.337 6.887"
                            >
                              <path
                                id="ic_arrow_drop_down_24px"
                                d="M7,10l6.169,6.887L19.337,10Z"
                                transform="translate(-7 -10)"
                                fill="#30679a"
                              />
                            </svg>
                          </div>
                        </div>

                      </div>
                    </th>
                    <th align="center">
                      <div className="cursorPointer" onClick={() => this.adtimes2_sort('TravelFrom', 'travelFromCaret')} >
                        <div className="date_mrg_left p-new"> TravelFrom
<div className="adtimes_downarrow new1">
                            <svg
                              id="travelFromCaret" className={!this.state.AdTimes_2_TravelFrom_Toggle ? "toggleCaretClass" : ''}
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.337"
                              height="6.887"
                              viewBox="0 0 12.337 6.887"
                            >
                              <path
                                id="ic_arrow_drop_down_24px"
                                d="M7,10l6.169,6.887L19.337,10Z"
                                transform="translate(-7 -10)"
                                fill="#30679a"
                              />
                            </svg>
                          </div>
                        </div>

                      </div>
                    </th>
                    <th align="center">
                      <div className="cursorPointer" onClick={() => this.adtimes2_sort('PmtFlag', 'pmtCaret')} >
                        <div className="date_mrg_left p-new"> PMT Flag{" "}
                          <div className="adtimes_downarrow new1">
                            <svg
                              id="pmtCaret" className={!this.state.AdTimes_2_PmtFlag_Toggle ? "toggleCaretClass" : ''}
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.337"
                              height="6.887"
                              viewBox="0 0 12.337 6.887"
                            >
                              <path
                                id="ic_arrow_drop_down_24px"
                                d="M7,10l6.169,6.887L19.337,10Z"
                                transform="translate(-7 -10)"
                                fill="#30679a"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th align="center">
                      &nbsp;
</th>
                  </tr>
                </thead>
                <tbody>

                  {this.state.AdTimes2TableData.length > 0 ? this.state.AdTimes2TableData.map(items => {
                    var tmp_employee = items.EmployeeName.split(' ');
                    return (
                      <tr key={items.Guid}>
                        <td align="center">
                          <input
                            type="checkbox"
                            name={items.Guid}
                            checked={items.checked}
                            onChange={(e) => this.Adtimes2AllTableCheckBoxHandler(items.Guid, e.target.checked)} />
                        </td>
                        <td align="center">{tmp_employee[0]}</td>
                        <td align="center">{tmp_employee[1]}</td>
                        <td align="center">{items.Position}</td>
                        <td align="center">{items.Dept}</td>
                        <td align="center">{items.TravelTo}</td>
                        <td align="center">{items.Start}</td>
                        <td align="center">
                          {items.meals}
                        </td>
                        <td align="center">{items.Finish}</td>
                        <td align="center">{items.TravelFrom}</td>
                        <td align="center">{items.PaymentFlag}</td>
                        <td align="center">
                          <a href={null} onClick={this.deleteAdTimesRecord.bind(this, items.Guid)} className="adtimes_attachment pull-right cursorPointer">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10.111"
                              height="13"
                              viewBox="0 0 10.111 13"
                            >
                              <path
                                id="ic_delete_24px"
                                d="M5.722,14.556A1.449,1.449,0,0,0,7.167,16h5.778a1.449,1.449,0,0,0,1.444-1.444V5.889H5.722ZM15.111,3.722H12.583L11.861,3H8.25l-.722.722H5V5.167H15.111Z"
                                transform="translate(-5 -3)"
                                fill="#7b7b7b"
                              />
                            </svg>
                          </a>
                          <a onClick={this.editClicked.bind(this, items.Guid)}
                            href={null}
                            className="adtimes_pullleft_edit pull-right ad_tiimes_mrg_toppp cursorPointer"
                            data-toggle="modal"
                            data-target="#adtimes2_edit_pop"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path
                                id="ic_create_24px"
                                className="cls-1"
                                d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z"
                                transform="translate(-3 -2.998)"
                              />
                            </svg>
                          </a>
                        </td>
                      </tr>
                    )
                  }) :
                    <tr></tr>
                  }
                </tbody>
              </table>
              {this.state.AdTimes2TableData.length < 1 ?

                <div className="col-md-12"> <center>Data Not Found....</center></div> : ''}
            </div>
          </div>
        </div>
        <div id="excel_pop_adtimes" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle_adtimes modal-dialog-centered modal_no_scrol_employeee">


            <div className="modal-content modal_middle_h_adtimes">
              <div className="modal-header">
                <button type="button" className="close close_modal_adtimes" data-dismiss="modal">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B" />
                  </svg>

                </button>
                <h4 className="modal-title adtimesl_modal_header">Excel</h4>
              </div>
              <div className="modal-body">

                <div className="col-sm-8 adtimes_input_pop1">

                  <select name="AdTimes2_ExcelSelect" id="AdTimes2_ExcelSelect" onChange={this.AdTimes2SelectHandler}>
                    <option>AD Times</option>
                  </select>

                </div>



                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    <a hreaf={null} className="btn_save_analytics cursorPointer" onClick={this.onStartExportingExcel} onChange={this.AdTimes2ExcelStartHandler}>Start</a>
                    <a hreaf={null} id="export_excel_btn" className="btn_cancel_analytics cursorPointer" data-dismiss="modal">Cancel</a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10"></div>

              </div>

            </div>

          </div>
        </div>


        <div id="select_paste_pop" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle_adtimes modal-dialog-centered modal_no_scrol_employeee">

            <div className="modal-content modal_middle_h_adtimes2">
              <div className="modal-header">
                <button type="button" className="close close_modal_adtimes" data-dismiss="modal">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B" />
                  </svg>

                </button>
                <h4 className="modal-title adtimesl_modal_header">Select Paste</h4>
              </div>
              <div className="modal-body">

                <div className="col-sm-8 adtimes_input_pop1">
                  <select name="AdTimes2_SelectPaste_SelectType"
                    id="AdTimes2_SelectPaste_SelectType" onChange={this.AdTimes2SelectHandler}>
                    <option>AD Times</option>
                  </select>
                </div>
                <div className="col-sm-12 adtimes_input_pop1">
                  <textarea name="AdTimes2_SelectPaste_PastHere" value={this.state.AdTimes2_SelectPaste_PastHere} cols="" rows="" onChange={this.AdTimes2InputsHandler}></textarea>
                </div>



                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    < a hreaf={null} className="btn_save_analytics cursorPointer" onClick={this.AdTimes2SelectPastPastHandler}>Paste</a>
                    < a hreaf={null} className="btn_cancel_analytics cursorPointer" id="btn_cancel_excel" data-dismiss="modal">Cancel</a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10"></div>

              </div>

            </div>

          </div>
        </div>




        <div id="adtimes_reports" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle_adtimes modal-dialog-centered modal_no_scrol_employeee">


            <div className="modal-content modal_middle_h_adtimes">
              <div className="modal-header">
                <button type="button" className="close close_modal_adtimes" data-dismiss="modal">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B" />
                  </svg>

                </button>
                <h4 className="modal-title adtimesl_modal_header">Reports</h4>
              </div>
              <div className="modal-body">

                <div className="col-sm-8 adtimes_input_pop1">

                  <select name="AdTimes2_Reports_Dropdown" id="AdTimes2_Reports_Dropdown" onChange={this.AdTimes2SelectHandler}>
                    <option>Webbanklist</option>
                  </select>

                </div>



                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    < a hreaf={null} className="btn_save_analytics cursorPointer" onClick={this.AdTimes2ReportStartHandler}>Save</a>
                    < a hreaf={null} className="btn_cancel_analytics cursorPointer" data-dismiss="modal">Cancel</a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10"></div>

              </div>

            </div>

          </div>
        </div>



        <div id="adtimes_attachment" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle_adtimes">

            <div className="modal-content modal_middle_emp_attach">
              <div className="modal-header attach_header_pop">
                <button type="button" className="close close_modal_adtimes" data-dismiss="modal">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B" />
                  </svg>

                </button>
                <h4 className="modal-title adtimesl_modal_header">Attachments

</h4>
              </div>
              <div className="modal-body">

                <div className="col-sm-12 table_attach_empl">

                  <table width="100%" className="table table-condensed">
                    <thead>
                      <tr>
                        <th colSpan="3" align="left">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td width="90%">Car Insurance

</td>
                        <td colSpan="2" align="center" width="10%">
                          < a hreaf={null} className="doc_img_emp cursorPointer">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 22.5">
                              <path id="ic_description_24px" className="cls-1" d="M15.25,2h-9A2.247,2.247,0,0,0,4.011,4.25L4,22.25A2.247,2.247,0,0,0,6.239,24.5H19.75A2.257,2.257,0,0,0,22,22.25V8.75ZM17.5,20h-9V17.75h9Zm0-4.5h-9V13.25h9ZM14.125,9.875V3.688l6.187,6.188Z" transform="translate(-4 -2)" />
                            </svg>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>Driver License

</td>
                        <td colSpan="2" align="center">< a hreaf={null} className="doc_img_emp cursorPointer">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 22.5">
                            <path id="ic_description_24px4" className="cls-1" d="M15.25,2h-9A2.247,2.247,0,0,0,4.011,4.25L4,22.25A2.247,2.247,0,0,0,6.239,24.5H19.75A2.257,2.257,0,0,0,22,22.25V8.75ZM17.5,20h-9V17.75h9Zm0-4.5h-9V13.25h9ZM14.125,9.875V3.688l6.187,6.188Z" transform="translate(-4 -2)" />
                          </svg>
                        </a></td>
                      </tr>
                      <tr>
                        <td>Passport

</td>
                        <td colSpan="2" align="center">< a hreaf={null} className="doc_img_emp cursorPointer">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 22.5">
                            <path id="ic_description_24px5" className="cls-1" d="M15.25,2h-9A2.247,2.247,0,0,0,4.011,4.25L4,22.25A2.247,2.247,0,0,0,6.239,24.5H19.75A2.257,2.257,0,0,0,22,22.25V8.75ZM17.5,20h-9V17.75h9Zm0-4.5h-9V13.25h9ZM14.125,9.875V3.688l6.187,6.188Z" transform="translate(-4 -2)" />
                          </svg>
                        </a></td>
                      </tr>
                    </tbody>
                  </table>



                </div>




                <div className="clear10"></div>

              </div>

            </div>

          </div>
        </div>



        <div id="adtimes_plus_pop" className="modal fade" role="dialog">
          <div className="modal-dialog">

            <div className="modal-content">
              <div className="modal-header adtime_pop_ad">
                <button type="button" id="close_detail_modal" className="close close_modal_adtimes" data-dismiss="modal">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B" />
                  </svg>

                </button>
                <h4 className="modal-title">AD Times

</h4>
              </div>
              <div className="modal-body">

                <div className="col-sm-12 table_attach_empl">
                  <div className="clear20"></div>


                  <div className="col-md-12 p0 employe_edit_pop_input">




                    <div className="col-sm-6 col-md-4 label_ad_times_pop"> Date:</div>
                    <div className="col-sm-6 col-md-8">
                      <DatePicker dateFormat="dd/MM/yyyy"
                        id="AdTimes2_AdTimeForm_Date"
                        selected={this.state.AdTimes2_AdTimeForm_Date}
                        onChange={this.handleDateChange}
                      />

                      <p
                        className={
                          "error validation" +
                          (this.state.AdTimes2_AdTimesFormErrors.AdTimes2_AdTimeForm_Date
                            ? ""
                            : "displayNone")
                        }
                      >
                        {this.state.AdTimes2_AdTimesFormErrors.AdTimes2_AdTimeForm_Date}
                      </p>

                      < a hreaf={null} className="calendar_icon_ad_times cursorPointer" onClick={this.adTimes2DateClickOpen}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
                          <path id="ic_date_range_24px" d="M9,11H7v2H9Zm4,0H11v2h2Zm4,0H15v2h2Zm2-7H18V2H16V4H8V2H6V4H5A1.991,1.991,0,0,0,3.01,6L3,20a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,19,4Zm0,16H5V9H19Z" transform="translate(-3 -2)" fill="#30679a" />
                        </svg>
                      </a>


                    </div>



                    <div className="clearfix"></div>

                    <div className="col-sm-6 col-md-4 label_ad_times_pop"> Unit:</div>
                    <div className="col-sm-6 col-md-8">
                      <input name="AdTimes2_AdTimeForm_Unit" type="text"
                        id="AdTimes2_AdTimeForm_Unit" value={this.state.AdTimes2_AdTimeForm_Unit}
                        onChange={this.AdTimes2InputsHandler}
                      />
                      <p
                        className={
                          "error validation" +
                          (this.state.AdTimes2_AdTimesFormErrors.AdTimes2_AdTimeForm_Unit
                            ? ""
                            : "displayNone")
                        }
                      >
                        {this.state.AdTimes2_AdTimesFormErrors.AdTimes2_AdTimeForm_Unit}
                      </p>

                    </div>

                    <div className="clearfix"></div>

                    <div className="col-sm-6 col-md-4 label_ad_times_pop">Travel To:</div>
                    <div className="col-sm-6 col-md-8">

                      <TimePicker showSecond={false}
                        id="AdTimes2_AdTimeForm_TravelTo" value={moment(this.state.AdTimes2_AdTimeForm_TravelTo, 'HH:mm')}

                        onChange={this.AdTimes2TimePicker.bind(this, 'AdTimes2_AdTimeForm_TravelTo')} />

                      <p
                        className={
                          "error validation" +
                          (this.state.AdTimes2_AdTimesFormErrors.AdTimes2_AdTimeForm_TravelTo
                            ? ""
                            : "displayNone")
                        }
                      >
                        {this.state.AdTimes2_AdTimesFormErrors.AdTimes2_AdTimeForm_TravelTo}
                      </p>
                    </div>

                    <div className="clearfix"></div>

                    <div className="col-sm-6 col-md-4 label_ad_times_pop">Crew Call:

</div>
                    <div className="col-sm-6 col-md-8">
                      <TimePicker showSecond={false}
                        id="AdTimes2_AdTimeForm_CrewCall" value={moment(this.state.AdTimes2_AdTimeForm_CrewCall, 'HH:mm')}

                        onChange={this.AdTimes2TimePicker.bind(this, 'AdTimes2_AdTimeForm_CrewCall')} />
                      <p
                        className={
                          "error validation" +
                          (this.state.AdTimes2_AdTimesFormErrors.AdTimes2_AdTimeForm_CrewCall
                            ? ""
                            : "displayNone")
                        }
                      >
                        {this.state.AdTimes2_AdTimesFormErrors.AdTimes2_AdTimeForm_CrewCall}
                      </p>
                    </div>

                    <div className="clearfix"></div>

                    <div className="col-sm-6 col-md-4 label_ad_times_pop">
                      Meal Break:
										</div>

                    <div className="col-sm-6 col-md-7">
                      {" "}

                      <TimePicker showSecond={false}
                        id="AdTimes2_AdTimeForm_MealBreak" value={moment(this.state.AdTimes2_AdTimeForm_MealBreak, 'HH:mm')}

                        onChange={this.AdTimes2TimePicker.bind(this, 'AdTimes2_AdTimeForm_MealBreak')} disabled={this.state.detail_mealdisable ? true : false} />
                      <a href={null}
                        data-toggle="collapse"
                        data-target="#meal_content_collapse"
                        className="decor cursorPointer"
                        id="btn_cllps"
                        onClick={this.handleModal_Height}
                      >
                        &nbsp;&nbsp;&nbsp;
                               <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8.027" viewBox="0 0 13 8.027">
                          <path id="ic_expand_more_24px" d="M17.473,8.59,12.5,13.552,7.528,8.59,6,10.118l6.5,6.5,6.5-6.5Z" transform="translate(-6 -8.59)" fill="#C4C4C4" />
                        </svg>
                      </a>
                      <p className={"error validation " + (this.state.AdTimes2_AdTimesFormErrors.AdTimes2_AdTimeForm_MealBreakErr ? '' : 'displayNone')}>{this.state.AdTimes2_AdTimesFormErrors.AdTimes2_AdTimeForm_MealBreakErr}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div
                      className="meal_collapse collapse"
                      id="meal_content_collapse"
                    >
                      <table className="table-responsive " id="table_meal_ad_time">
                        <thead>
                          <tr>
                            <th align="center"> &nbsp;</th>

                            <th align="center" className="style_th_center"> AD</th>

                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Start MB1</td>


                            <td><TimePicker showSecond={false}
                              id="AdTimes2_editStartMB1" value={moment(this.state.AdTimes2_editStartMB1, 'HH:mm')}

                              onChange={this.AdTimes2TimePicker.bind(this, 'AdTimes2_editStartMB1')} />
                              <p
                                className={
                                  "error validation" +
                                  (this.state.AdTimes2_AdTimesFormErrors.AdTimes2_editStartMB1
                                    ? ""
                                    : "displayNone")
                                }
                              >
                                {this.state.AdTimes2_AdTimesFormErrors.AdTimes2_editStartMB1}
                              </p>
                            </td>

                          </tr>
                          <tr>
                            <td>Finish MB1</td>


                            <td><TimePicker showSecond={false}
                              id="AdTimes2_editFinishMB1" value={moment(this.state.AdTimes2_editFinishMB1, 'HH:mm')}

                              onChange={this.AdTimes2TimePicker.bind(this, 'AdTimes2_editFinishMB1')} />
                              <p
                                className={
                                  "error validation" +
                                  (this.state.AdTimes2_AdTimesFormErrors.AdTimes2_editFinishMB1
                                    ? ""
                                    : "displayNone")
                                }
                              >
                                {this.state.AdTimes2_AdTimesFormErrors.AdTimes2_editFinishMB1}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>Start MB2</td>


                            <td><TimePicker showSecond={false}
                              id="AdTimes2_editStartMB2" value={moment(this.state.AdTimes2_editStartMB2, 'HH:mm')}

                              onChange={this.AdTimes2TimePicker.bind(this, 'AdTimes2_editStartMB2')} />
                              <p
                                className={
                                  "error validation" +
                                  (this.state.AdTimes2_AdTimesFormErrors.AdTimes2_editStartMB2
                                    ? ""
                                    : "displayNone")
                                }
                              >
                                {this.state.AdTimes2_AdTimesFormErrors.AdTimes2_editStartMB2}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>Finish MB2</td>


                            <td><TimePicker showSecond={false}
                              id="AdTimes2_editFinishMB2" value={moment(this.state.AdTimes2_editFinishMB2, 'HH:mm')}

                              onChange={this.AdTimes2TimePicker.bind(this, 'AdTimes2_editFinishMB2')} />
                              <p
                                className={
                                  "error validation" +
                                  (this.state.AdTimes2_AdTimesFormErrors.AdTimes2_editFinishMB2
                                    ? ""
                                    : "displayNone")
                                }
                              >
                                {this.state.AdTimes2_AdTimesFormErrors.AdTimes2_editFinishMB2}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>Start MB3</td>


                            <td><TimePicker showSecond={false}
                              id="AdTimes2_editStartMB3" value={moment(this.state.AdTimes2_editStartMB3, 'HH:mm')}

                              onChange={this.AdTimes2TimePicker.bind(this, 'AdTimes2_editStartMB3')} />
                              <p
                                className={
                                  "error validation" +
                                  (this.state.AdTimes2_AdTimesFormErrors.AdTimes2_editStartMB3
                                    ? ""
                                    : "displayNone")
                                }
                              >
                                {this.state.AdTimes2_AdTimesFormErrors.AdTimes2_editStartMB3}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>Finish MB3</td>


                            <td><TimePicker showSecond={false}
                              id="AdTimes2_editFinishMB3" value={moment(this.state.AdTimes2_editFinishMB3, 'HH:mm')}

                              onChange={this.AdTimes2TimePicker.bind(this, 'AdTimes2_editFinishMB3')} />
                              <p
                                className={
                                  "error validation" +
                                  (this.state.AdTimes2_AdTimesFormErrors.AdTimes2_editFinishMB3
                                    ? ""
                                    : "displayNone")
                                }
                              >
                                {this.state.AdTimes2_AdTimesFormErrors.AdTimes2_editFinishMB3}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div className="clear20"></div>
                      <table width="100%" className="table tbl_pencil_info_collapse tbl_pencill_td">
                        <thead>
                          <tr className="">
                            <th width="20%" className="text-left">&nbsp; </th>

                            <th width="20%" className="text-left">&nbsp;

</th>

                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Non Deductible MB1:</td>


                            <td><input className="" name="adtimes2_non_detuctable_mb1_3" onChange={this.AdTimes2InputsHandler} type="checkbox" checked={(this.state.adtimes2_non_detuctable_mb1_3) ? true : false} /></td>
                          </tr>
                          <tr>
                            <td>Non Deductible MB2:</td>

                            <td><input className="" name="adtimes2_non_detuctable_mb2_3" onChange={this.AdTimes2InputsHandler} type="checkbox" checked={(this.state.adtimes2_non_detuctable_mb2_3) ? true : false} /></td>
                          </tr>
                          <tr>
                            <td>Non Deductible MB3:</td>

                            <td><input className="" name="adtimes2_non_detuctable_mb3_3" onChange={this.AdTimes2InputsHandler} type="checkbox" checked={(this.state.adtimes2_non_detuctable_mb3_3) ? true : false} /></td>
                          </tr>
                        </tbody>
                      </table>

                    </div>


                    <div className="clearfix"></div>
                    <div className="clear10" />

                    <div className="col-sm-6 col-md-4 label_ad_times_pop">Crew Wrap:</div>
                    <div className="col-sm-6 col-md-8">
                      <TimePicker showSecond={false}
                        id="AdTimes2_AdTimeForm_CrewWrap" value={moment(this.state.AdTimes2_AdTimeForm_CrewWrap, 'HH:mm')}

                        onChange={this.AdTimes2TimePicker.bind(this, 'AdTimes2_AdTimeForm_CrewWrap')} />
                      <p
                        className={
                          "error validation" +
                          (this.state.AdTimes2_AdTimesFormErrors.AdTimes2_AdTimeForm_CrewWrap
                            ? ""
                            : "displayNone")
                        }
                      >
                        {this.state.AdTimes2_AdTimesFormErrors.AdTimes2_AdTimeForm_CrewWrap}
                      </p>
                    </div>

                    <div className="clearfix"></div>

                    <div className="col-sm-6 col-md-4 label_ad_times_pop">Travel From:</div>
                    <div className="col-sm-6 col-md-8">
                      <TimePicker showSecond={false}
                        id="AdTimes2_AdTimeForm_TravelFrom" value={moment(this.state.AdTimes2_AdTimeForm_TravelFrom, 'HH:mm')}

                        onChange={this.AdTimes2TimePicker.bind(this, 'AdTimes2_AdTimeForm_TravelFrom')} />
                      <p
                        className={
                          "error validation" +
                          (this.state.AdTimes2_AdTimesFormErrors.AdTimes2_AdTimeForm_TravelFrom
                            ? ""
                            : "displayNone")
                        }
                      >
                        {this.state.AdTimes2_AdTimesFormErrors.AdTimes2_AdTimeForm_TravelFrom}
                      </p>
                    </div>
                    <div className="clearfix"></div>

                    <div className="col-sm-6 col-md-4 label_ad_times_pop">  Notes:</div>
                    <div className="col-sm-6 col-md-8">
                      <textarea name="AdTimes2_AdTimeForm_Notes" cols="" className="textarea_ad_times" rows=""
                        id="AdTimes2_AdTimeForm_Notes" value={this.state.AdTimes2_AdTimeForm_Notes === 'empty' ? '' : this.state.AdTimes2_AdTimeForm_Notes}
                        onChange={this.AdTimes2InputsHandler}
                      ></textarea>
                    </div>

                    <div className="clear10"></div>

                    <div className="col-sm-12">
                      <div className="analytics_btn_save">
                        < a hreaf={null} className="btn_save_analytics cursorPointer" onClick={this.AdTimesDetailsSaveHandler}>Save</a>
                        < a hreaf={null} className="btn_cancel_analytics cursorPointer" data-dismiss="modal">Cancel</a>
                      </div>
                      <div className="col-md-3"> </div>
                    </div>
                    <div className="clear20"></div>


                  </div>






                </div>




                <div className="clear10"></div>

              </div>

            </div>

          </div>
        </div>





        <div id="adtimes2_edit_pop" className="modal fade" data-keyboard="false" data-backdrop="static" role="dialog">
          <div className="modal-dialog w_addtime2_pop_main">
            <div className="modal-content">
              <div className="modal-header edit_detail_hdr">
                <button id="close_employee" type="button" className="close close_adtime2_pop_edit" data-dismiss="modal" onClick={this.clearstates_addpopup}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B" />
                  </svg>
                </button>
                {(this.state.edit_adtimes2) ?
                  <h4 className="modal-title">{this.state.AdTimes2_EditModal_Name}, {this.state.emp_department}, {this.state.emp_position}, {weekday}
                    <br />
                    {new Date(this.state.AdTimes2_AdTimeForm_Date).getDate()}{((new Date(this.state.AdTimes2_AdTimeForm_Date).getDate() === 1 || new Date(this.state.AdTimes2_AdTimeForm_Date).getDate() === 21 || new Date(this.state.AdTimes2_AdTimeForm_Date).getDate() === 31) ? 'st' : ((new Date(this.state.AdTimes2_AdTimeForm_Date).getDate() === 2 || new Date(this.state.AdTimes2_AdTimeForm_Date).getDate() === 22) ? 'nd' : ((new Date(this.state.AdTimes2_AdTimeForm_Date).getDate() === 3 || new Date(this.state.AdTimes2_AdTimeForm_Date).getDate() === 23) ? 'rd' : 'th')))} of {monthNames[new Date(this.state.AdTimes2_AdTimeForm_Date).getMonth()]}
                    {'  ' + new Date(this.state.AdTimes2_AdTimeForm_Date).getFullYear()}
                  </h4>
                  : ""}
              </div>
              <div className="modal-body">
                <div className="col-sm-12 table_attach_empl">
                  <div className="clearfix"></div>
                  <div className="col-xs-12 p0 hdg_edit_detail_1">
                    <div className="col-xs-6 p0">
                      <h4>Timecard</h4>
                    </div>
                    <div className="col-xs-6 p0 text-right svg_icon_addtime2_pop" data-toggle="collapse" data-target="#TimecardCollapse">
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7.41" viewBox="0 0 12 7.41">
                        <path id="ic_expand_less_24px" d="M12,8,6,14l1.41,1.41L12,10.83l4.59,4.58L18,14Z" transform="translate(-6 -8)" fill="#86878A" />
                      </svg>
                    </div>
                  </div>
                  <div className="clear10"></div>
                  <div className="col-md-12 p0 employe_edit_pop_input collapse in" id="TimecardCollapse" >
                    <div className="col-sm-4 col-xs-4 col-md-3 label_add2_poptime"> Name:</div>
                    <div className="col-xs-8 col-sm-8 col-md-9">
                      <input name="AdTimes2_EditModal_Name" className="cursorPointer" onClick={this.openEmployeePopUp} type="text" placeholder="Click here to select employee..."
                        id="AdTimes2_EditModal_Name"
                        value={this.state.AdTimes2_EditModal_Name}
                        onChange={this.AdTimes2InputsHandler}
                        readOnly />
                      <p className={"error validation " + (this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_Name ? '' : 'displayNone')}>{this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_Name}</p>
                      < a hreaf={null} className="search_icon_addtime2 cursorPointer" data-toggle="modal" id="search_employee_popup" data-target="#adtimes2_search_pop">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <path id="ic_search_24px" d="M17.294,15.579h-.9l-.32-.309a7.444,7.444,0,1,0-.8.8l.309.32v.9L21.3,23,23,21.3Zm-6.861,0a5.146,5.146,0,1,1,5.146-5.146A5.139,5.139,0,0,1,10.433,15.579Z" transform="translate(-3 -3)" fill="#c4c4c4" />
                        </svg>
                      </a>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime"> Department:</div>
                    <div className="col-xs-8 col-sm-8 col-md-9">
                      {(this.state.emp_department === '') ? 'Search above...' : this.state.emp_department}
                      <p className={"error validation " + (this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_Name ? '' : 'displayNone')}>{this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_Name}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-4 col-xs-4 col-md-3 label_add2_poptime"> Position:</div>
                    <div className="col-xs-8 col-sm-8 col-md-9">
                      {(this.state.emp_position === '') ? 'Search above...' : this.state.emp_position}
                      <p className={"error validation " + (this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_Name ? '' : 'displayNone')}>{this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_Name}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime">Pay As
                     </div>
                    <div className="col-xs-8 col-sm-8 col-md-9">
                      <select value={this.state.AdTimes2_EditModal_PayAs} name="AdTimes2_EditModal_PayAs" id="AdTimes2_EditModal_PayAs"
                        onChange={this.AdTimes2InputsHandler}>
                        <option value="">Select Pay As</option>
                        {this.state.PayasList.map(function (item, key) {
                          return (
                            <option value={item.Flag} key={key}>
                              {item.Value}
                            </option>
                          );
                        })}
                      </select>
                      <p className={"error validation " + (this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_PayAs ? '' : 'displayNone')}>{this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_PayAs}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime">Travel to</div>
                    <div className="col-xs-8 col-sm-8 col-md-3 col-lg-4">
                      <TimePicker showSecond={false}
                        value={moment(this.state.AdTimes2_EditModal_TravelTo, 'HH:mm')}
                        onChange={this.adTimes2_times_inputHandler.bind(this, "AdTimes2_EditModal_TravelTo")}
                        placeholder="12:00"

                      />
                      <p className={"error validation " + (this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_TravelTo ? '' : 'displayNone')}>{this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_TravelTo}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime">Start Work</div>
                    <div className="col-xs-8 col-sm-8 col-md-3 col-lg-4">
                      <TimePicker showSecond={false}
                        value={moment(this.state.AdTimes2_EditModal_StartWork, 'HH:mm')}
                        onChange={this.adTimes2_times_inputHandler.bind(this, "AdTimes2_EditModal_StartWork")}
                        placeholder="12:00"

                      />
                      <p className={"error validation " + (this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_StartWork ? '' : 'displayNone')}>{this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_StartWork}</p>
                    </div>
                    <div className="clearfix"></div>


                    <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime">Meal Break</div>
                    <div className="col-xs-8 col-sm-8 col-md-3 col-lg-4">
                      {" "}

                      <TimePicker showSecond={false}
                        id="AdTimes2_EditModal_MealBreak" value={moment(this.state.AdTimes2_EditModal_MealBreak, 'HH:mm')}

                        onChange={this.adTimes2_times_inputHandler.bind(this, 'AdTimes2_EditModal_MealBreak')} disabled={this.state.employee_meal ? true : false} />
                      <a href={null}
                        data-toggle="collapse"
                        data-target="#mealEdit_content_collapse"
                        className="decor cursorPointer"
                        id="btn_cllps"
                        onClick={this.handleModal_Height}
                      >
                        <svg className="svgadtime2" xmlns="http://www.w3.org/2000/svg" width="13" height="8.027" viewBox="0 0 13 8.027">
                          <path id="ic_expand_more_24px" d="M17.473,8.59,12.5,13.552,7.528,8.59,6,10.118l6.5,6.5,6.5-6.5Z" transform="translate(-6 -8.59)" fill="#C4C4C4" /></svg>
                      </a>
                      <p className={"error validation " + (this.state.AdTimes2_AdTimesFormErrors.AdTimes2_AdTimeForm_MealBreakErr ? '' : 'displayNone')}>{this.state.AdTimes2_AdTimesFormErrors.AdTimes2_AdTimeForm_MealBreakErr}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div
                      className="meal_collapse collapse"
                      id="mealEdit_content_collapse"
                    >
                      <table className="table-responsive " id="table_meal_ad_time">
                        <thead>
                          <tr>
                            <th align="center"> &nbsp;</th>

                            <th align="center" className="style_th_center"> AD</th>

                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Start MB1</td>


                            <td><TimePicker showSecond={false}
                              id="AdTimes2_editModalStartMB1" value={moment(this.state.AdTimes2_editModalStartMB1, 'HH:mm')}

                              onChange={this.adTimes2_times_inputHandler.bind(this, 'AdTimes2_editModalStartMB1')} />
                            </td>

                          </tr>
                          <tr>
                            <td>Finish MB1</td>


                            <td><TimePicker showSecond={false}
                              id="AdTimes2_editModalFinishMB1" value={moment(this.state.AdTimes2_editModalFinishMB1, 'HH:mm')}

                              onChange={this.adTimes2_times_inputHandler.bind(this, 'AdTimes2_editModalFinishMB1')} />
                            </td>
                          </tr>
                          <tr>
                            <td>Start MB2</td>


                            <td><TimePicker showSecond={false}
                              id="AdTimes2_editModalStartMB2" value={moment(this.state.AdTimes2_editModalStartMB2, 'HH:mm')}

                              onChange={this.adTimes2_times_inputHandler.bind(this, 'AdTimes2_editModalStartMB2')} />

                            </td>
                          </tr>
                          <tr>
                            <td>Finish MB2</td>


                            <td><TimePicker showSecond={false}
                              id="AdTimes2_editModalFinishMB2" value={moment(this.state.AdTimes2_editModalFinishMB2, 'HH:mm')}

                              onChange={this.adTimes2_times_inputHandler.bind(this, 'AdTimes2_editModalFinishMB2')} />
                            </td>
                          </tr>
                          <tr>
                            <td>Start MB3</td>


                            <td><TimePicker showSecond={false}
                              id="AdTimes2_editModalStartMB3" value={moment(this.state.AdTimes2_editModalStartMB3, 'HH:mm')}

                              onChange={this.adTimes2_times_inputHandler.bind(this, 'AdTimes2_editModalStartMB3')} />
                            </td>
                          </tr>
                          <tr>
                            <td>Finish MB3</td>

                            <td><TimePicker showSecond={false}
                              id="AdTimes2_editModalFinishMB3" value={moment(this.state.AdTimes2_editModalFinishMB3, 'HH:mm')}

                              onChange={this.adTimes2_times_inputHandler.bind(this, 'AdTimes2_editModalFinishMB3')} />
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div className="clear20"></div>
                      <table width="100%" className="table tbl_pencil_info_collapse tbl_pencill_td">
                        <thead>
                          <tr className="">

                            <th width="20%" className="text-left">&nbsp;

</th>
                            <th width="20%" className="text-left">&nbsp;

</th>

                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Non Deductible MB1:</td>

                            <td><input className="" name="ad_times2_non_deductible_mb1_3" onChange={this.AdTimes2InputsHandler} type="checkbox" checked={(this.state.ad_times2_non_deductible_mb1_3) ? true : false} /></td>
                          </tr>
                          <tr>
                            <td>Non Deductible MB2:</td>

                            <td><input className="" name="ad_times2_non_deductible_mb2_3" onChange={this.AdTimes2InputsHandler} type="checkbox" checked={(this.state.ad_times2_non_deductible_mb2_3) ? true : false} /></td>
                          </tr>
                          <tr>
                            <td>Non Deductible MB3:</td>

                            <td><input className="" name="ad_times2_non_deductible_mb3_3" onChange={this.AdTimes2InputsHandler} type="checkbox" checked={(this.state.ad_times2_non_deductible_mb3_3) ? true : false} /></td>
                          </tr>
                        </tbody>
                      </table>

                    </div>


                    <div className="clearfix"></div>

                    <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime">Finish Work</div>
                    <div className="col-xs-8 col-sm-8 col-md-3 col-lg-4">
                      <TimePicker showSecond={false}
                        value={moment(this.state.AdTimes2_EditModal_FinishWork, 'HH:mm')}
                        onChange={this.adTimes2_times_inputHandler.bind(this, "AdTimes2_EditModal_FinishWork")}
                        placeholder="12:00"

                      />
                      <p className={"error validation " + (this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_FinishWork ? '' : 'displayNone')}>{this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_FinishWork}</p>
                    </div>
                    <div className="clearfix"></div>

                    <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime">Travel from</div>
                    <div className="col-xs-8 col-sm-8 col-md-3 col-lg-4">

                      <TimePicker showSecond={false}
                        value={moment(this.state.AdTimes2_EditModal_TravelFrom, 'HH:mm')}
                        onChange={this.adTimes2_times_inputHandler.bind(this, "AdTimes2_EditModal_TravelFrom")}
                        placeholder="12:00"

                      />

                      <p className={"error validation " + (this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_TravelFrom ? '' : 'displayNone')}>{this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_TravelFrom}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="clear10" />

                    <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime">Total Hours</div>
                    <div className="col-xs-8 col-sm-8 col-md-3 col-lg-2 label_add2_poptime_right">  {(moment(this.state.AdTimes2_EditModal_TotalHours).format('HH:mm').toString() === "Invalid date") ? "00:00:00" : moment(this.state.AdTimes2_EditModal_TotalHours).format('HH:mm').toString()}</div>
                    <div className="clearfix"></div>
                    <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime">Hourly Rate:</div>
                    <div className="col-xs-8 col-sm-8 col-md-9">
                      <input name="AdTimes2_EditModal_HourlyRate" placeholder="250000" type="Number"
                        id="AdTimes2_EditModal_HourlyRate"
                        value={this.state.AdTimes2_EditModal_HourlyRate}
                        onChange={this.AdTimes2InputsHandler}
                      />

                      <p className={"error validation " + (this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_HourlyRate ? '' : 'displayNone')}>{this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_HourlyRate}</p>
                    </div>
                    <div className="clearfix"></div>


                    <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime">Notes</div>
                    <div className="col-xs-8 col-sm-8 col-md-9">
                      <textarea name="AdTimes2_EditModal_Notes" id="AdTimes2_EditModal_Notes"
                        value={this.state.AdTimes2_EditModal_Notes}
                        onChange={this.AdTimes2InputsHandler} cols=""
                        className="add_time2_textareanote" rows=""></textarea>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime">Payment Flag</div>
                    <div className="col-xs-8 col-sm-8 col-md-9">
                      <input name="AdTimes2_EditModal_PaymentFlag" placeholder="" type="text"
                        id="AdTimes2_EditModal_PaymentFlag"
                        value={this.state.AdTimes2_EditModal_PaymentFlag}
                        onChange={this.AdTimes2InputsHandler}
                      />
                      <p className={"error validation " + (this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_PaymentFlag ? '' : 'displayNone')}>{this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_PaymentFlag}</p>
                    </div>
                    <div className="clear40"></div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="col-xs-12 p0 hdg_edit_detail_1">
                    <div className="col-xs-10 p0">
                      <h4>Coding and Payment Flags</h4>
                    </div>
                    <div className="col-xs-2 p0 text-right svg_icon_addtime2_pop" data-toggle="collapse" data-target="#CodeandFlagCollapse">
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7.41" viewBox="0 0 12 7.41">
                        <path id="ic_expand_less_24px" d="M12,8,6,14l1.41,1.41L12,10.83l4.59,4.58L18,14Z" transform="translate(-6 -8)" fill="#86878A" />
                      </svg>
                    </div>
                  </div>
                  <div className="clear10"></div>
                  <div className="col-md-12 p0 employe_edit_pop_input collapse" id="CodeandFlagCollapse">
                    <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime"> Chart Sort</div>
                    <div className="col-xs-8 col-sm-8 col-md-9">
                      <InputMask mask={this.state.mask_chartSort} maskChar={null} name="AdTimes2_EditModal_ChartSort" type="text" placeholder="xx-xx-xxx"
                        id="AdTimes2_EditModal_ChartSort"
                        value={this.state.AdTimes2_EditModal_ChartSort}
                        onChange={this.AdTimes2InputsHandler} />
                      <p className={"error validation " + (this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_ChartSort ? '' : 'displayNone')}>{this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_ChartSort}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime"> Chart Code</div>
                    <div className="col-xs-8 col-sm-8 col-md-9">
                      <InputMask mask={this.state.mask_chartCode} maskChar={null} name="AdTimes2_EditModal_ChartCode" type="text" placeholder="xxxx-xxxx-xxxx"
                        id="AdTimes2_EditModal_ChartCode"
                        value={this.state.AdTimes2_EditModal_ChartCode}
                        onChange={this.AdTimes2InputsHandler}
                      />
                      <p className={"error validation " + (this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_ChartCode ? '' : 'displayNone')}>{this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_ChartCode}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime"> Work State</div>
                    <div className="col-xs-8 col-sm-8 col-md-9">
                      <input name="AdTimes2_EditModal_WorkState" type="text" placeholder="VIC"
                        id="AdTimes2_EditModal_WorkState"
                        value={this.state.AdTimes2_EditModal_WorkState}
                        onChange={this.AdTimes2InputsHandler}
                      />
                      <p className={"error validation " + (this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_WorkState ? '' : 'displayNone')}>{this.state.AdTimes2_EditModalErrors.AdTimes2_EditModal_WorkState}</p>
                    </div>
                    <div className="clearfix"></div>

                    {this.state.trackingfields.length > 0 ? this.state.trackingfields.map(value => {
                      if (value.type === 'Numeric') {
                        return (
                          <div>
                            <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime">{value.Prompt}</div>
                            <div className="col-xs-8 col-sm-8 col-md-9">
                              <input name={value.uuid} type="number" value={value.defaultValue !== null ? value.defaultValue : ''} placeholder={value.defaultValue !== null ? value.defaultValue : ''}
                                onChange={this.trackinghandler}

                              />
                            </div>
                            <div className="clearfix"></div>
                          </div>
                        )
                      }

                      if (value.type === 'String') {

                        return (
                          <div>
                            <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime">{value.Prompt}</div>
                            <div className="col-xs-8 col-sm-8 col-md-9">
                              <input name={value.uuid} type="text" placeholder={value.defaultValue !== null ? value.defaultValue : ''}
                                value={value.defaultValue !== null ? value.defaultValue : ''}
                                onChange={this.trackinghandler}
                              />
                            </div>
                            <div className="clearfix"></div>
                          </div>)
                      }


                      if (value.type === 'Date') {

                        return (
                          <div>
                            <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime">{value.Prompt}</div>
                            <div className="col-xs-8 col-sm-8 col-md-9">
                              <DatePicker name={value.uuid} dateFormat="dd/MM/yyyy"
                                selected={new Date(value.defaultValue)}
                                
                                
                                onChange={this.trackinghandlerdate.bind(this, value.uuid)}
                              />
                            </div>
                            <div className="clearfix"></div>
                          </div>
                        )
                      }
                      if (value.type === 'DropList') {
                        return (
                          <div>
                            <div className="col-xs-4 col-sm-4 col-md-3 label_add2_poptime">{value.Prompt}</div>
                            <div className="col-xs-8 col-sm-8 col-md-9">
                              <select onChange={this.trackinghandler} value={value.defaultValue !== null ? value.defaultValue : ''} name={value.uuid}>

                                {
                                  value.optionsValues.map(opt => {
                                    return (
                                      <option value={opt.value} key={opt.key}>{opt.option}</option>
                                    )
                                  })

                                }
                              </select>
                            </div>
                            <div className="clearfix"></div>
                          </div>
                        )
                      }
                    })
                      : ''

                    }
                    <div className="clearfix"></div>

                    <div className="clearfix"></div>

                    <div className="clear10"></div>
                  </div>
                  <div className="col-sm-12">
                    <div className="analytics_btn_save">
                      <a hreaf={null} className="btn_save_analytics cursorPointer" onClick={(this.state.edit_adtimes2) ? this.AdTimesEditModalSaveHandler.bind(this, 'edit') : this.AdTimesEditModalSaveHandler.bind(this, 'save')}>{(this.state.edit_adtimes2) ? 'Update' : 'Save'}</a>
                      < a hreaf={null} className="btn_cancel_analytics cursorPointer" data-dismiss="modal" onClick={this.clearstates_addpopup}>Cancel</a>
                    </div>
                    <div className="col-md-3"> </div>
                  </div>
                </div>
                <div className="clear10"></div>
              </div>
            </div>
          </div>
        </div>

        <div id="adtimes2_search_pop" className="modal fade" role="dialog">
          <div className="modal-dialog w_addtime2_pop_search">

            <div className="modal-content modal_content_addtime2_pop3 mdl_hgttt">
              <div className="modal-header edit_detail_hdr">
                <button type="button" className="close close_adtime2_pop_edit" data-dismiss="modal" onClick={this.issue_ScrollHandler}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B" />
                  </svg>

                </button>
                <h4 className="modal-title">

                </h4>
              </div>
              <div className="modal-body">

                <div className="col-sm-12 addtime2_scroll_pop3">

                  <div className="col-sm-10 p0">
                    <div className="search_area_addtime2_pop2">
                      <input name="Adtimes2_ModalSearchName_Search" type="text" className="search_adtimes2_popsearch"
                        id="Adtimes2_ModalSearchName_Search"
                        value={this.state.Adtimes2_ModalSearchName_Search}
                        onChange={this.EmployeesInputsChngHandlers}
                        placeholder={`Search by ${this.state.AdTimes2_NameSearchModal_SelectedTab}`} />
                      <img alt="no" src={ic_search} className="searchicon_adtimes2_pop" width="20" height="20" /></div>
                    <div className="search_area_addtime2_pop3">
                      <div className="pull-left"><div className="checkbox checked_Add_Times_2">
                        <input name="Adtimes2_Modal2SearchName_TerminatedChkbx"
                          checked={this.state.Adtimes2_Modal2SearchName_TerminatedChkbx}
                          type="checkbox"
                          id="Adtimes2_Modal2SearchName_TerminatedChkbx"
                          onChange={this.TerminateHandler} />
                        <label htmlFor="Adtimes2_Modal2SearchName_TerminatedChkbx">

                        </label>
                      </div></div>
                      <div className="pull-left">   Show terminated employees</div>


                    </div>



                  </div>

                  <div className="col-sm-2 p0 mrg_topright2">




                    <div className="dropdown menu_icon_employee">
                      <button id='freshbtn' className="dropdown-toggle btn_addtime2_pop2" onClick={this.getAllEmployees}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24.224" height="17.617" viewBox="0 0 24.224 17.617">
                          <path id="ic_cached_24px" d="M20.819,8.4l-4.4,4.4h3.3a6.612,6.612,0,0,1-6.606,6.606,6.464,6.464,0,0,1-3.083-.771L8.421,20.252a8.793,8.793,0,0,0,13.5-7.443h3.3Zm-14.314,4.4A6.612,6.612,0,0,1,13.112,6.2a6.464,6.464,0,0,1,3.083.771L17.8,5.365A8.793,8.793,0,0,0,4.3,12.809H1l4.4,4.4,4.4-4.4Z" transform="translate(-1 -4)" fill="#95989A" />
                        </svg>


                      </button>

                    </div>








                  </div>



                  <div className="clear10"></div>

                  <div className="col-sm-12 p0">


                    <div className="overflow_scroll_sm">
                      <table width="100%" className="table table-bordered timecard_table addtime_2_pop3">

                        <thead>
                          <tr className="table_header_pop3">
                            <th align="center"></th>
                            <th align="center">
                              <div className="cursorPointer" onClick={() => this.sortEmployees('FirstName', 'FirstNameCaret')}>
                                <div className=""> First Name

</div>
                                <div className="addtime2_pop_downarrow ad_tiimes__mrg_topp"><svg
                                  id="FirstNameCaret" className={!this.state.AdTimes2_NameModal_FirstName_Toggle ? "toggleCaretClass" : ''}
                                  xmlns="http://www.w3.org/2000/svg" width="12.337" height="6.887" viewBox="0 0 12.337 6.887">
                                  <path id="ic_arrow_drop_down_24px" d="M7,10l6.169,6.887L19.337,10Z" transform="translate(-7 -10)" fill="#30679a" />
                                </svg>
                                </div>
                              </div>

                            </th>
                            <th align="center">

                              <div className="cursorPointer" onClick={() => this.sortEmployees('LastName', 'LastNameCaret')}>
                                <div className=""> Last Name

</div>
                                <div className="addtime2_pop_downarrow ad_tiimes__mrg_topp"><svg
                                  id="LastNameCaret" className={!this.state.AdTimes2_NameModal_LastName_Toggle ? "toggleCaretClass" : ''}
                                  xmlns="http://www.w3.org/2000/svg" width="12.337" height="6.887" viewBox="0 0 12.337 6.887">
                                  <path id="ic_arrow_drop_down_24px" d="M7,10l6.169,6.887L19.337,10Z" transform="translate(-7 -10)" fill="#30679a" />
                                </svg>
                                </div>
                              </div>

                            </th>
                            <th align="center">

                              <div className="cursorPointer" onClick={() => this.sortEmployees('Email', 'EmailCaret')}>
                                <div className=""> Email

</div>
                                <div className="addtime2_pop_downarrow ad_tiimes__mrg_topp"><svg
                                  id="EmailCaret" className={!this.state.AdTimes2_NameModal_Email_Toggle ? "toggleCaretClass" : ''}

                                  xmlns="http://www.w3.org/2000/svg" width="12.337" height="6.887" viewBox="0 0 12.337 6.887">
                                  <path id="ic_arrow_drop_down_24px" d="M7,10l6.169,6.887L19.337,10Z" transform="translate(-7 -10)" fill="#30679a" />
                                </svg>
                                </div>
                              </div>
                            </th>
                            <th align="center">
                              <div className="cursorPointer" onClick={() => this.sortEmployees('Mobile', 'MobileCaret')}>
                                <div className="">Mobile

</div>
                                <div className="addtime2_pop_downarrow ad_tiimes__mrg_topp">
                                  <svg
                                    id="MobileCaret" className={!this.state.AdTimes2_NameModal_Mobile_Toggle ? "toggleCaretClass" : ''}

                                    xmlns="http://www.w3.org/2000/svg" width="12.337" height="6.887" viewBox="0 0 12.337 6.887">
                                    <path id="ic_arrow_drop_down_24px" d="M7,10l6.169,6.887L19.337,10Z" transform="translate(-7 -10)" fill="#30679a" />
                                  </svg>
                                </div>
                              </div>

                            </th>
                            <th align="center">
                              <div className="cursorPointer" onClick={() => this.sortEmployees('Department', 'DepartmentCaret')}>
                                <div className="">Dept

</div>
                                <div className="addtime2_pop_downarrow ad_tiimes__mrg_topp" >
                                  <svg
                                    id="DepartmentCaret" className={!this.state.AdTimes2_NameModal_Dept_Toggle ? "toggleCaretClass" : ''}

                                    xmlns="http://www.w3.org/2000/svg" width="12.337" height="6.887" viewBox="0 0 12.337 6.887">
                                    <path id="ic_arrow_drop_down_24px" d="M7,10l6.169,6.887L19.337,10Z" transform="translate(-7 -10)" fill="#30679a" />
                                  </svg>
                                </div>
                              </div>
                            </th>
                            <th align="center">
                              <div className="cursorPointer" onClick={() => this.sortEmployees('Position', 'PositionCaret')}>
                                <div className="">Position

</div>
                                <div className="addtime2_pop_downarrow ad_tiimes__mrg_topp">
                                  <svg
                                    id="PositionCaret" className={!this.state.AdTimes2_NameModal_Position_Toggle ? "toggleCaretClass" : ''}

                                    xmlns="http://www.w3.org/2000/svg" width="12.337" height="6.887" viewBox="0 0 12.337 6.887">
                                    <path id="ic_arrow_drop_down_24px" d="M7,10l6.169,6.887L19.337,10Z" transform="translate(-7 -10)" fill="#30679a" />
                                  </svg>
                                </div>
                              </div>
                            </th>
                            <th align="center">
                              <div className="cursorPointer" onClick={() => this.sortEmployees('ApproverGroup', 'ApproverGroupCaret')}>
                                <div className=""> Approver Group

</div>
                                <div className="addtime2_pop_downarrow ad_tiimes__mrg_topp" ><svg
                                  id="ApproverGroupCaret" className={!this.state.AdTimes2_NameModal_ApproverGroup_Toggle ? "toggleCaretClass" : ''}

                                  xmlns="http://www.w3.org/2000/svg" width="12.337" height="6.887" viewBox="0 0 12.337 6.887">
                                  <path id="ic_arrow_drop_down_24px" d="M7,10l6.169,6.887L19.337,10Z" transform="translate(-7 -10)" fill="#30679a" />
                                </svg>
                                </div>
                              </div>

                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {(empMapArray.map(item => {
                            return (
                              <tr key={item.EmpGuid}>
                                <td align="center">

                                  <div className="addtime_pop4_svg" onClick={this.onEmployeSelected.bind(this, item.EmpGuid)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.6" height="13.4" viewBox="0 0 17.6 13.4">
                                      <path id="ic_done_24px" d="M9,16.2,4.8,12,3.4,13.4,9,19,21,7,19.6,5.6Z" transform="translate(-3.4 -5.6)" />
                                    </svg>

                                  </div>

                                </td>
                                <td align="center">{item.FirstName}

                                </td>
                                <td align="center">{item.LastName}

                                </td>
                                <td align="center">{item.Email}

                                </td>
                                <td align="center">{item.Mobile}

                                </td>
                                <td align="center">{item.Department}

                                </td>
                                <td align="center">{item.Position}

                                </td>
                                <td align="center">{item.ApproverGroup}</td>
                              </tr>
                            )
                          }))}


                        </tbody>
                      </table>
                    </div>
                  </div>






                </div>
                <div className="clear10"></div>
                <div className="col-sm-12">
                  <div className="analytics_btn_save">

                    < a hreaf={null} id="selectEmploye_pop_cancel" className="btn_cancel_analytics mrg_left0_addtime  btn_analytics_can cursorPointer" data-dismiss="modal" onClick={this.issue_ScrollHandler}>Cancel</a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>


                <div className="clear10"></div>

              </div>

            </div>

          </div>
        </div>

      </div>
    );
  }
}
export default Add_Times_2;


