/**
 * @Date:   2019-02-18T13:00:53+05:00
 * @Last modified time: 2019-02-18T17:04:37+05:00
 */



import React, { Component } from 'react'
import './Login.css';
import login_logo from './../../Assets/images/login_logo.svg';
import login_sm_logo from './../../Assets/images/login_sm_logo.png';
import login_down_arrow from './../../Assets/images/login_down_arrow.svg';
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import md5 from 'md5';
import { API } from "aws-amplify";
import { toast } from 'react-toastify';
import moment from 'moment';
import 'moment-timezone';
import cookie from 'react-cookies';
import $ from 'jquery';
var ip = require('ip');
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mustchange_state: '',
            isLoading: false,
            email: "",
            UpdateidentityPoolUserId: false,
            companyID: '',
            companyIDValid: false,
            identityPoolUserId: "",
            password: "",
            emailValid: false,
            checkUserFromDynamo: false,
            passwordValid: false,
            recordactivity: false,
            formErrors: {
                email: '',
                password: ''
            },
            formValid: "",
            isActive: '',
            LoginText: 'Login',
            rememberme: false,
            useractive: '',
            countExceed: false,
            tmpguid: '',
            userloginnametmp: '',
            checkCompanies: false,
            companyfound: false,
            resultCompanies: [],
            phoneno: '',
            factorAuth: null,
            userpoolidfromuser:null,
            countryCode: ''
        }
    }
    async componentWillMount() {
        var ckmail = cookie.load('useremail');
        var ckpass = cookie.load('userpassword');
        var companyID = cookie.load('companyID');
        if (ckmail !== undefined && ckpass !== undefined && companyID !== undefined) {
            await this.setState({
                email: ckmail,
                password: ckpass,
                companyID: companyID,
                companyIDValid: true,
                emailValid: true,
                passwordValid: true,
                isActive: true,
                rememberme: true,
            })
            $('#chekemember').prop('checked', true);
        } else {
            $('#chekemember').prop('checked', false);
        }
        await this.props.childProps.isAuthentication();
        if (this.props.childProps.isAuthenticated) {
            if (window.screen.availWidth < 768) {
                this.props.history.push("/timecards");
            }
            else {
                this.props.history.push("/dashboard");
            }
        }
    }
    componentDidMount = async () => {
        document.body.classList.add('login_bg');
    }
    componentWillUnmount() {
        document.body.classList.remove('login_bg');
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;
        let passwordValid = this.state.passwordValid;
        switch (fieldName) {
            case 'email':
                emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrors.email = emailValid ? '' : 'Email should be username@domain.com';
                break;
            case 'password':
                //passwordValid = value.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}/i);
                passwordValid = value.length > 4;
                fieldValidationErrors.password = passwordValid ? '' : 'Password must contain 8 characters, at least 1 letter, 1 special character and 1 number';
                break;
            case 'companyID':
                if (value.length > 0) {
                    this.setState({
                        companyIDValid: true
                    })
                } else {
                    this.setState({
                        companyIDValid: false
                    })
                }
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            emailValid: emailValid,
            passwordValid: passwordValid
        }, this.validateForm);
    }
    validateForm() {
        this.setState({
            formValid: this.state.emailValid && this.state.passwordValid && this.state.companyIDValid,
            isActive: this.state.emailValid && this.state.passwordValid && this.state.companyIDValid
        });
    }
    checkboxChange = async (event) => {
        if ($('#chekemember').is(":checked")) {
            await this.setState({
                rememberme: true
            })
        } else {
            await this.setState({
                rememberme: false
            })
        }
    }
    async handleUserInput(event) {
        let name = event.target.name;
        let value = event.target.value;
        if (name === 'email') {
            await this.setState({
                [name]: value.toLowerCase()
            },
                () => {
                    this.validateField(name, value)
                });
        } else {
            await this.setState({
                [name]: value
            },
                () => {
                    this.validateField(name, value)
                });
        }
    }
    updatelockedStatus = async (guid) => {
        await API.post('updatelastactivepassword', '/updatelastactivepassword', {
            body: {
                "Guid": guid,
                "acctLocked": true
            }
        }).then(data => { 
           // toast.success('Last active Date update!');
         }).catch(error => { toast.error('Last active Date not update!'); });
    }
    handleSubmit = async event => {
        event.preventDefault();
        var emailEvent = {
            target: {
                name: 'email',
                value: this.state.email
            }
        };
        var passwordEvent = {
            target: {
                name: 'password',
                value: this.state.password
            }
        };
        var companyIDEvent = {
            target: {
                name: 'companyID',
                value: this.state.companyID
            }
        };
        this.handleUserInput(emailEvent);
        this.handleUserInput(passwordEvent);
        this.handleUserInput(companyIDEvent);
        if (this.state.emailValid === false) {
            toast.error("Email Address Is Required");
            return false;
        }
        if (this.state.passwordValid === false) {
            toast.error("Password Is Required");
            return false;
        }
        if (this.state.companyIDValid === false) {
            toast.error("Company Id Is Required");
            return false;
        }
        this.setState({
            isLoading: true,
            LoginText: 'Logging In ...',
            isActive: false
        });
        //==================== User Pool Request ========================================================
        var flag = false;
        try {
            await API.post("checkemailuser", "/checkemailuser", {
                body: {
                    "user_email": this.state.email
                }
            }).then(result => {
                this.setState({
                    factorAuth: result[0].FactorAuthenticate,
                    countryCode: result[0].countryCode
                });
                if (result[0].PWExpireDays !== null) {
                    if (moment() > moment(result[0].PWLastChanged).add(parseInt(result[0].PWExpireDays), 'days')) {

                        flag = true;
                        toast.error('your password has been Expired Please Reset Before Login');
                        return false;
                    }
                }

                if (result[0].InactivityLockoutDays !== null) {
                    if (moment() > moment(result[0].lastactivedate).add(parseInt(result[0].InactivityLockoutDays), 'days')) {

                        flag = true;
                        toast.error('Your Account Has Been Locked');
                        this.updatelockedStatus(result[0].Guid);
                        return false;
                    }
                }



                if (result.length === 0) {

                    toast.error("User Not Exist");
                    this.setState({
                        isLoading: true,
                        LoginText: 'Log In',
                        isActive: false
                    });
                } else {



                    if (result[0].UserActive === 1 && result[0].AcctDisabled !== true && result[0].AcctLockedout !== true) {
                        this.setState({
                            useractive: 'active',
                            checkCompanies: true,
                        });
                    } else {
                        this.setState({
                            useractive: 'deactive',
                            checkCompanies: false,
                        });
                    }
                    this.setState({
                        tmpguid: result[0].Guid,
                        userloginnametmp: result[0].UserLogin,
                        phoneno: result[0].MobileNumber,
                        userpoolidfromuser:result[0].Poolid
                    });
                }
            }).catch(err => {
                console.log(err)
            });
            if (this.state.useractive === 'active' && this.state.checkCompanies === true) {
                await API.post("getcompaniesbycoyid", "/getcompaniesbycoyid", {
                    body: {
                        "cID": this.state.companyID
                    }
                }).then(result => {
                    console.log(result);
                    if (result.length === 0) {
                        this.setState({
                            companyfound: false
                        })
                    } else {
                        this.setState({
                            companyfound: true,
                            resultCompanies: result
                        })
                    }
                }).catch(err => { console.log(err); });

            }

            if (this.state.useractive === 'active' && this.state.companyfound === true) {

                if (this.state.factorAuth !== null&&this.state.userpoolidfromuser!==null) {
                    var code = Math.round(Math.random() * 100000);
                    await API.post('sendsms', "/sendsms", {
                        body: {
                            "Guid": this.state.tmpguid,
                            "PhoneNumber": this.state.phoneno
                        }
                    }).then(res => console.log("Verification code sended to you phone"))
                        .catch(err => toast.error(err));

                    this.props.history.push("/Two_Factor_Verify", {

                        confirmed: false,
                        email: this.state.email,
                        password: this.state.password,
                        resultCompanies: this.state.resultCompanies,
                        MobileNumber: this.state.phoneno,
                        user_guid: this.state.tmpguid

                    });
                    return false;
                }

                await Auth.signIn(this.state.email, this.state.password)
                    .then(data => {
                        //toast.success('User Successfully logged in User Pool.');
                        this.setState({
                            identityPoolUserId: data.storage['aws.cognito.identity-id.' + process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID],
                            checkUserFromDynamo: true,
                        })
                    })
                    .catch(err => {
                        if (err.message === 'User is not confirmed.') {
                             //Auth.resendSignUp(this.state.email);
                            this.props.history.push("/verification_code", {
                                state: {
                                    confirmed: false,
                                    email: this.state.email,
                                    password: this.state.password,
                                    resultCompanies: this.state.resultCompanies,
                                    MobileNumber: this.state.phoneno
                                }
                            });
                        } else if (err.message === 'Incorrect username or password.') {
                            toast.error(err.message);
                            this.updatecount();
                        } else if (err.message === 'User does not exist.') {
                           // toast.success('user cognito in process');
                            this.CreateinCognito();
                        }

                        else {
                            toast.error(err.message);
                        }
                        this.setState({
                            LoginText: 'Login'
                        })
                    });



            } else if (this.state.useractive === 'deactive') {
                this.setState({
                    isLoading: true,
                    LoginText: 'Log In',
                    isActive: false
                });
                toast.error("User Disabled Please Contact Admin");
            } else {
                this.setState({
                    isLoading: true,
                    LoginText: 'Log In',
                    isActive: false
                });
                if (flag === false) {
                    toast.error("Comnpany Not Found or Credentials Error");
                }
            }
        } catch (e) {
            toast.error(e);
            this.setState({
                LoginText: 'Login'
            })
        }
        //==================== User Pool Request End========================================================
        if (this.state.checkUserFromDynamo) {
            await API.post("userget", "/userget", {
                body: {
                    "user_email": this.state.email,
                    "user_password": md5(this.state.password)
                }
            })
                .then(result => {
                    if (result.length > 0) {
                        //toast.success('User Successfully logged in Dynamo DB.');
                        localStorage.setItem('userGuid', result[0].Guid);
                        localStorage.setItem('UserLogin', result[0].UserLogin);
                        localStorage.setItem('UserFullName', result[0].FirstName + (result[0].LastName ? ' ' + result[0].LastName : ''));
                        localStorage.setItem('tenantid', result[0].TenantGuid);
                        localStorage.setItem('companylist', JSON.stringify(this.state.resultCompanies));
                        localStorage.setItem('EmailAddress', result[0].EmailAddress);
                        localStorage.setItem('companyId', this.state.companyID);
                        localStorage.setItem('role', result[0].UserType);

                        this.setState({
                            UpdateidentityPoolUserId: true,
                            mustchange_state: result[0].PasswordChange
                        });

                    } else {
                        this.setState({
                            isLoading: false
                        });
                        toast.error("Invalid Username or password");
                        this.signouttmp();
                        this.updatecount();
                        this.setState({
                            LoginText: 'Login'
                        })
                    }
                })
                .catch(error => {
                    toast.error(error.message);
                });
        }
        if (this.state.UpdateidentityPoolUserId) {
            await API.post("updatepoolid", "/updatepoolid", {
                body: {
                    "Guid": localStorage.getItem('userGuid'),
                    "Poolid": this.state.identityPoolUserId
                }
            })
                .then(data => {
                   // toast.success('User Origin UpdatedSuccessfully.');
                    this.setState({
                        recordactivity: true,
                    });
                    if (this.state.rememberme) {
                        cookie.save('useremail', this.state.email, {
                            path: '/'
                        });
                        cookie.save('userpassword', this.state.password, {
                            path: '/'
                        });
                        cookie.save('companyID', this.state.companyID, {
                            path: '/'
                        });
                    } else {
                        cookie.remove('useremail', {
                            path: '/'
                        });
                        cookie.remove('userpassword', {
                            path: '/'
                        });
                        cookie.remove('companyID', {
                            path: '/'
                        });
                    }
                    if ($(window).width() < 700) {
                        document.getElementById('opencompany').click();
                    } else {


                        this.resetcount();
                        this.props.history.push("/company-select",
                            {
                                companies: this.state.resultCompanies,
                                mustchange: this.state.mustchange_state
                            });
                    }
                }).catch(err => {
                    toast.error(err.message);
                });
        }
        if (this.state.recordactivity) {
            await API.post("recordactivity", "/recordactivity", {
                body: {
                    "Description": "login",
                    "UserName": localStorage.getItem('UserLogin'),
                    "IPAddress": ip.address(),
                    "TenantGuid": "UYGVUYGBIUYGHBIUYHGB",
                    "Type": "Login",
                    "UserGuid": localStorage.getItem('userGuid')
                }
            }).then(data => {
               // toast.success('Login Activity Recorded Successfully.');
            }).catch(err => {
                toast.error(err.message);
            });
            await API.post("recordlastlogin", "/recordlastlogin", {
                body: {
                    "Guid": localStorage.getItem('userGuid')
                }
            }).then(data => {
                //toast.success('Last Login Recorded Successfully.');
            }).catch(err => {
                toast.error(err.message);
            });
            await API.post("updateuserupdateinfo", "/updateuserupdateinfo", {
                body: {
                    "userEmail": localStorage.getItem('EmailAddress'),
                    "LastLogin": Date.now()
                }
            }).then(data => {
               // toast.success('Last Login And Login Count In User Recorded Successfully.');
            }).catch(err => {
                toast.error(err.message);
            });
        }
        this.setState({
            isLoading: false
        })
    }
    CreateinCognito = async () => {

        await Auth.signUp({
            username: this.state.email,
            password: this.state.password,
            attributes: {
                email: this.state.email,
                phone_number: this.state.phoneno
            }
        })
            .then(data => {


               // toast.success('user created in cognito');
            })
            .catch(err => {


                toast.error('user not created in cognito');
            });

        await API.post("updatelastpasswordchanged", "/updatelastpasswordchanged", {
            body: {
                "userEmail": this.state.email,
                "PWLastChanged": Date.now()
            }
        }).then(data => {
            document.getElementById('sub').click();
        }).catch(err => {
            toast.error(err.message);
        });


    }
    resetcount = async () => {
        await API.post("resetcount", "/resetcount", {
            body: {
                "Guid": this.state.tmpguid
            }
        }).then(data => {
            console.log('User Active');
        }).catch(err => {
            console.log('User Not Active')
        });
    }
    signouttmp = async () => {
        await Auth.signOut();
    }
    updatecount = async () => {
        await API.post("countwpupdate", "/countwpupdate", {
            body: {
                "userEmail": this.state.email
            }
        }).then(data => {
            if (data.count === 3 || data.count > 3) {
                this.setState({
                    countExceed: true
                });
            }
        }).catch(err => {
            console.log(err);
        });
        await API.post("recordactivity", "/recordactivity", {
            body: {
                "Description": "Wrong Password Attempt",
                "UserName": this.state.userloginnametmp,
                "IPAddress": ip.address(),
                "TenantGuid": "UYGVUYGBIUYGHBIUYHGB",
                "Type": "Wrong Password",
                "UserGuid": this.state.tmpguid
            }
        }).then(data => {
            //toast.success('Wrong password activity recorded');
        }).catch(err => {
            toast.error(err.message);
        });
        if (this.state.countExceed) {
            await API.post("activedeactiveuser", "/activedeactiveuser", {
                body: {
                    "Guid": this.state.tmpguid,
                    "value": 0
                }
            }).then(data => {
                console.log('User Deactive');
            }).catch(err => {
                console.log('something got wrong')
            });
        }
    }
    onHref = (e) => {
        e.preventDefault()
    }

    // checkCompanyWithUser = async (event) => {

    //     var userTenant = localStorage.getItem('tenantid');
    //     if (userTenant.indexOf(event.target.id) > -1) {
    //         this.setState({ isLoading: true });
    //         localStorage.setItem('useableGuid', event.target.id);
    //         if (window.screen.availWidth < 768) {
    //             this.props.history.push("/timecards");
    //         } else {
    //             this.props.history.push("/dashboard");
    //         }
    //     } else {
    //         toast.error("You don't belong with selected company");
    //     }
    // }
    checkCompanyWithUser = async (event) => {
        var userTenant = localStorage.getItem('tenantid');
        if (userTenant.indexOf(event.target.id) > -1) {
            this.setState({ isLoading: true });
            localStorage.setItem('useableGuid', event.target.id);
            if (window.screen.availWidth < 768) {
                this.props.history.push("/timecards");
            } else {
                this.props.history.push("/dashboard");
            }
        } else {
            toast.error("You don't belong with selected company");
        }
    }




    render() {
        return (
            <React.Fragment>
                <div className="col-sm-12 p0">
                    {this.state.isLoading ?
                        <div className="se-pre-con"></div>
                        : ''}
                    <div className="col-sm-6 p0 small_display_none">
                        <div className="login_left_area"></div>
                    </div>
                    <div className="col-sm-6 p0 login_right_h">
                        <div className="login_right_middle">
                            <div className="col-sm-12 logo_login">
                                <Link to="">
                                    <img src={login_logo} alt="" /></Link>
                            </div>
                            <div className="col-sm-12 sm_logo_login">
                                <Link to="">
                                    <img src={login_sm_logo} alt="" /></Link>
                            </div>
                            <div className="col-sm-12 login_h2 small_display_none welcome_txtttt">
                                <h2>Welcome back! Please login to your account.</h2>
                            </div>
                            <form onSubmit={this.handleSubmit} autoComplete='off'>
                                <div className="col-sm-12 login_box lgn_bxxx">
                                    <input name="email" value={this.state.email} onChange={(event) => this.handleUserInput(event)} id="email" type="email" placeholder="User Name" />
                                    <input name="companyID" value={this.state.companyID} type="text" placeholder="Company ID" onChange={(event) => this.handleUserInput(event)} />
                                    <input name="password" value={this.state.password} onChange={(event) => this.handleUserInput(event)} type="password" placeholder="Password" />
                                </div>
                                <div className="col-sm-6 p0 text-left small_display_none">
                                    <div className="check_box_remember">
                                        <label>Remember me</label>
                                        <input name="remember_me" type="checkbox" id="chekemember" onChange={this.checkboxChange} />
                                    </div>
                                </div>
                                <div className="col-sm-6 p0 text-right login_forgot">
                                    <Link to='/forgot'>
                                        Forgot Password?</Link>
                                </div>
                                <div className="clearfix"></div>
                                <button id='sub' type="submit" className={"btn_login login_show_webbb login_CursorPointer login_width100Percent" + (this.state.isActive ? '' : ' login_notActive')}>{this.state.LoginText}</button>
                                <a href="javascript:void(0);" onClick={this.handleSubmit} className={(this.state.isActive ? 'btn_login login_show_mobiiile' : 'btn_login login_show_mobiiile login_notActive')}>Login</a>
                                <div className="clear20"></div>
                            </form>
                            <div className="col-sm-12 text-center full_display_none accept_policy"> I accept the Terms of Use and Privacy Policy</div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="policy_label small_display_none">Term of use. Privacy policy</div>
                        <div className="clear20"></div>
                        <div className="more_detail">
                            <Link to="">
                                More Details
            <div className="clear5"></div>
                                <img src={login_down_arrow} alt="" />
                            </Link>
                        </div>
                    </div>
                </div>
                <a href={null} id="opencompany" className="search_user_pop2 cursorPointer displayNone" data-toggle="modal" data-target="#login_show_tableeeee">
                    open
                        </a>
                <div id="login_show_tableeeee" className="modal_tableee_showww modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog modal_bottom_dialog mod_custtt" role="document">
                        <div >
                            <div className="modal-body ">
                                <div id="mobile_show_table">
                                    <div className="verif_table_box">
                                        <table width="99%" className="table">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th scope="col">Production</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.resultCompanies.map(value => {
                                                    return (
                                                        <tr key={value.Guid} onClick={this.checkCompanyWithUser}>
                                                            <td id={value.TenantGuid}>{value.CompanyName}</td>
                                                        </tr>
                                                    );
                                                })}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Login;
