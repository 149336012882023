import React, { Component } from 'react';
import Login_logo from '../../Assets/images/login_logo.svg';
import Login_sm_logo from '../../Assets/images/login_sm_logo.png';
import login_down_arrow from '../../Assets/images/login_down_arrow.svg';
import { Link } from "react-router-dom";
import './Two_Factor_Verify.css';
import { toast } from 'react-toastify';
import { Auth } from "aws-amplify";
import md5 from 'md5';
import { API } from "aws-amplify";

var ip = require('ip');

class Two_Factor_Verify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      confirmation_code: '',
      email: '',
      password: '',
      checkUserLogin: false,
      identityPoolUserId: "",
      checkUserFromDynamo: false,
      UpdateidentityPoolUserId: false,
      recordactivity: false,
      companies: [],
      phoneNumber: '',
      verified: false,
      fullPhoneNumer: '',
      userguid:''
    }
  }

  componentDidMount = async () => {
    if (this.props.location.state !== undefined) {
      toast.warn('Please Enter Verification Code Received On Your Mobile.');
      var prop_email = this.props.location.state.email;
      var prop_password = this.props.location.state.password;
      var prop_companies = this.props.location.state.resultCompanies;
      var prop_userguid = this.props.location.state.user_guid;
      var prop_MobileNumber = this.props.location.state.MobileNumber;

      await this.setState({
        email: prop_email,
        password: prop_password,
        companies: prop_companies,
        userguid:prop_userguid,
        fullPhoneNumer: prop_MobileNumber
      })
    } else {
      this.props.history.push("/login");
    }
    const fullNumber = this.props.location.state.MobileNumber;
    const lastDigits = fullNumber.substr(fullNumber.length - 3);
    this.setState({
      phoneNumber: lastDigits
    })
  }

  onClickHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  confirmation = async () => {
    await this.getautherization();
    if (this.state.verified) {
      this.setState({
        isLoading: true
      })
    } else {
      toast.error('Incorrect Pin Code')
      return false;
    }
    await Auth.signIn(this.state.email, this.state.password)
      .then(data => {
        this.setState({
          identityPoolUserId: data.storage['aws.cognito.identity-id.' + process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID],
        });
       // toast.success('User Successfully logged in Cognito.');
        API.post("userget", "/userget", { body: { "user_email": this.state.email, "user_password": md5(this.state.password )} })
          .then(result => {
            if (result.length > 0) {
             // toast.success('User Successfully logged in Dynamo DB.');
              localStorage.setItem('userGuid', result[0].Guid);
              localStorage.setItem('companylist', JSON.stringify(this.state.companies));
              localStorage.setItem('UserFullName', result[0].FirstName + (result[0].LastName ? ' ' + result[0].LastName : ''));
              localStorage.setItem('UserLogin', result[0].UserLogin);
              localStorage.setItem('EmailAddress', result[0].EmailAddress);
              localStorage.setItem('role', result[0].UserType);
              localStorage.setItem('tenantid', result[0].TenantGuid);
              API.post("updatepoolid", "/updatepoolid", {
                body: {
                  "Guid": localStorage.getItem('userGuid'),
                  "Poolid": this.state.identityPoolUserId
                }
              })
                .then(data => {
                  //toast.success('User Origin UpdatedSuccessfully.');

                  this.props.history.push("/company-select",
                    {
                      companies: this.state.companies,
                      mustchange: result[0].PasswordChange
                    });
                }).catch(err => {
                  toast.error(err.message);
                });
            } else {
              this.setState({ isLoading: false });
              toast.error("Invalid Username or password");
              this.setState({
                LoginText: 'Login'
              })
              Auth.signOut();
              this.props.history.push('/login');
            }
          })
          .catch(error => {
            toast.error(error.message);
          });

      })
      .catch(err => {
        if (err.message === 'User is not confirmed.') {
          this.props.history.push("/verification_code", {
            state: {
              confirmed: false,
              email: this.state.email,
              password: this.state.password,
              resultCompanies: this.state.companies
            }
          });
        }
        else {
          toast.error(err.message);
        }
        this.setState({
          LoginText: 'Login'
        })
      });
  }
getautherization=async()=>{
  await API.post('sendsms','/checkauth',{
    body:{
      confirmation:this.state.confirmation_code.toString(),
      Guid:this.state.userguid,
    }
  }).then((result)=>{
    if(result.message==='Code Matched'){
    this.setState({
      verified:true
    })
  }
  }).catch(error=>{
    this.setState({
      verified:false
    })
  })
 
}
  resendCode = async () => {
    this.setState({
      isLoading: true
    })
    await API.post('sendsms', "/sendsms", {
      body: {
        "Guid": this.state.userguid,
        "PhoneNumber": this.state.fullPhoneNumer
      }
    }).then(res => {
      this.setState({
        verified:false,
        isLoading: false
      })
    })
      .catch(err => toast.error(err));
  }

  handleClick(e) {
    e.preventDefault();
  }
  render() {
    return (
      <div className="col-sm-12 p0">
        {this.state.isLoading ? <div className="se-pre-con"></div> : ''}

        <div className="col-sm-6 p0 small_display_none">
          <div className="verify_left_area"></div>

        </div>


        <div className="col-sm-6 p0 verify_right_h">

          <div className="verify_right_middle">
            <div className="col-sm-12 verify_bg1"><Link to=""><img src={Login_logo} alt="" /></Link></div>
            <div className="col-sm-12 sm_logo_login"><Link to=""><img src={Login_sm_logo} alt="" /></Link></div>

            <div className="col-sm-12 verify_h2 small_display_none">
              <h2>In a few moments a 6-digit verification code will be sent to XXXX-XXX-{this.state.phoneNumber}.</h2>
            </div>

            <div className="col-sm-12 verify_code_box">
              <input name="confirmation_code" onChange={this.onClickHandler} value={this.state.confirmation_code} type="text" placeholder="Verification code" />


            </div>

            <div className="col-sm-6 p0 text-right verify_forgot pull-right cursorPointer" onClick={this.resendCode}><a>Resend Code</a></div>
            <div className="clearfix"></div>
            <div className="col-sm-6 p0_left"><a hreaf="javascript:void(0);" onClick={this.confirmation} className="verify_btn_login cursorPointer">Login</a></div>
            <div className="col-sm-6 p0_right"><Link to="/login" className="verify_btn_cancel">Cancel</Link></div>
            <div className="clear20"></div>

            <div className="col-sm-12 text-center full_display_none accept_policy"> I accept the Terms of Use and Privacy Policy</div>








          </div>











          <div className="clearfix"></div>
          <div className="verify_policy_label small_display_none">Term of use. Privacy policy</div>
          <div className="clear20"></div>
          <div className="more_detail">
            <Link to="" onClick={this.onHref}>More Details
					 <div className="clear5"></div>
              <img src={login_down_arrow} alt="" />

            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Two_Factor_Verify;