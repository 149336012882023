import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Routes from './Routes/Routes';
import './App.css';
import {Auth} from "aws-amplify";
//import $ from 'jquery';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Amplify from "aws-amplify";
import { API } from "aws-amplify";

class App extends Component {
  
 
  constructor(props) {
    super(props);
      
    this.state = {
      //users settings
    showApprovalSetup: false,
    showEmailTamplate: false,
    showEmployeeList: false,
    showPayrollBatch: false,
    showTimecards: false,
    showUsers: false,
        //general settings
setting_selectDataUploadValue: "",
setting_selectDataUpload: false,
setting_SATCheck: false, //Show AD times on timecard:
setting_SCTCheck: false, //Show changed times on timecard:
setting_SNCheck: false, //Show Notifications:
previousSettings:false,
previousGuid:'',
      isAuthenticated: false,
        counts: {
approveCount: 0,
pendingCount: 0,
approvedCount: 0,
allCount: 0
}
    }
        
  }
async componentWillMount() {
       await this.isAuthentication();
}
  isAuthentication = async () =>{
      const useableGuid=localStorage.getItem('useableGuid');
    
      if (typeof useableGuid !== 'undefined' && useableGuid !== null ){
    await Auth.currentSession().then(
        data=>{
            this.setState({isAuthenticated:true});
        }
    ).catch(err => {
        this.setState({isAuthenticated:false});
         localStorage.removeItem('userGuid');
         localStorage.removeItem('UserLogin');
         localStorage.removeItem('useableGuid');
         localStorage.removeItem('companyId');
         localStorage.removeItem('role');
         localStorage.removeItem('tenantid');
         localStorage.removeItem('companylist');
    });
     }else{
          localStorage.removeItem('userGuid');
          localStorage.removeItem('UserLogin');
          localStorage.removeItem('useableGuid');
          localStorage.removeItem('companyId');
          localStorage.removeItem('role');
          localStorage.removeItem('tenantid');
          localStorage.removeItem('companylist');
          await  Auth.signOut();
     }
}
async componentDidMount() {
  await this.getUserSettings()
  await this.getSettings()
}

getUserSettings = async() => {
  let userGuid = localStorage.getItem("userGuid");

  await API.post("getuserbyguid", "/getuserbyguid", {
    body: {
       user_id: userGuid
    }
 }).then(data => {
    this.setState({
        showApprovalSetup:  data.access.ApprovalSetup,
        showEmailTamplate:  data.access.EmailTamplate,
        showEmployeeList:  data.access.EmployeeList,
        showPayrollBatch:  data.access.PayrollBatch,
        showTimecards:  data.access.Timecards,
        showUsers:  data.access.Users,
    });

 }).catch(err => {
    console.log('error in fetching user')
 });
}
//get general settings
getSettings = async () => {
var tenantguid = localStorage.getItem('tenantid');
var userguid = localStorage.getItem('userGuid');

await API.post("getsettings", "/getsettings",{
body: {
"TenantGuid": tenantguid,
"UserGuid": userguid
}
}).then(data => {
if(data.length > 0){
this.setState({
previousSettings:true,
previousGuid:data[0].Guid,
setting_SATCheck:data[0] && data[0].Settings[0] && data[0].Settings[0].setting_SATCheck,
setting_SCTCheck:data[0].Settings[1].setting_SCTCheck,
setting_SNCheck:data[0].Settings[2].setting_SNCheck,
setting_selectDataUploadValue:data[0].Settings[3].setting_selectDataUploadValue,
});
}else{
this.setState({
previousGuid:'',
previousSettings:false,
setting_SATCheck:false,
setting_SCTCheck:false,
setting_SNCheck:false,
setting_selectDataUploadValue:0,
});
}
})
.catch(err => console.log(err));

}
getCounts = async () => {
//get counts of time card
let originaldata_for_count = [];
var data=[];
var bodypost={
  tenant: localStorage.getItem("useableGuid")
  };
var lastflag=false;
while(lastflag===false){await API.post("getallprlemp", "/getallprlemp", {
    body: bodypost
})
.then(response => {
  response.Items.map(a=>{
    data.push(a);
  })
  if (response.LastEvaluatedKey) { 
    bodypost.LastEvaluatedKey = response.LastEvaluatedKey
    } else {
      lastflag = true
    }
})
.catch(err => console.log(err));
}
data.map(item => {
  item.checked = false;
  item.CheckBoxID = item.Guid;
  });
  originaldata_for_count = data;



        await this.count(originaldata_for_count);
}
// to check how much counts in Approve, pending, Approved and All 
count = async (data) => {
let userGuid = localStorage.getItem("userGuid");
let final_array = [];
let final_array_pending = [];
let final_array_approved = [];
let final_array_all = [];

data = data.filter(item => item.approve_status !== "Draft");

let countObj = {};
// for approve count
let approveFilterData = data.filter(o => {
return userGuid == o.current_approver && o.approve_status !== "Approved";
});
approveFilterData.map(item => {
if (item.approver_chain.indexOf(userGuid) > -1) {
final_array.push(item);
}
});

countObj.approveCount = final_array.length;

// for pending count
let pendingFilterData = data.filter(
item => item.approve_status === "Pending"
);
pendingFilterData.map(item => {
var index = item.approver_chain.indexOf(userGuid);
var cindex = item.approver_chain.indexOf(item.current_approver);

if (
item.approver_chain.indexOf(userGuid) > -1 ||
userGuid === item.senderid
) {
if (index < cindex || userGuid === item.senderid) {
final_array_pending.push(item);
}
}
});

countObj.pendingCount = final_array_pending.length;
// for approved count
let approvedFilterData = data.filter(
item => item.approve_status === "Approved"
);
approvedFilterData.map(item => {
if (
item.approver_chain.indexOf(userGuid) > -1 ||
userGuid === item.senderid
) {
final_array_approved.push(item);
}
});
countObj.approvedCount = final_array_approved.length;

data.map(item => {
var index = item.approver_chain.indexOf(userGuid);
var cindex = item.approver_chain.indexOf(item.current_approver);

if (
item.approver_chain.indexOf(userGuid) > -1 ||
userGuid === item.senderid
) {
if (index <= cindex || userGuid === item.senderid) {
final_array_all.push(item);
}
}
});

//for all count
countObj.allCount = final_array_all.length;
await this.setState({ counts: countObj });
};
  render() {
    const childProps={
        isAuthenticated:this.state.isAuthenticated,
        isAuthentication:this.isAuthentication,
        setting_SATCheck: this.state.setting_SATCheck, //Show AD times on timecard:
        setting_SCTCheck: this.state.setting_SCTCheck, //Show changed times on timecard:
        setting_SNCheck: this.state.setting_SNCheck, //Show Notifications:
        getSettings: this.getSettings, //get settings function
        //users settings
        showApprovalSetup: this.state.showApprovalSetup,
        showEmailTamplate: this.state.showEmailTamplate,
        showEmployeeList: this.state.showEmployeeList,
        showPayrollBatch: this.state.showPayrollBatch,
        showTimecards: this.state.showTimecards,
        showUsers: this.state.showUsers,
        getUserSettings: this.getUserSettings
    }
    return (
      <div className="App">
        <Routes getCounts={this.getCounts} count={this.state.counts} childProps={childProps} />
        <ToastContainer />
      </div>
    );
  }
}

export default withRouter(App);
