import React, { Component } from 'react';
import AvivaPng from  '../../Assets/images/aviva.png';
import './Aviva_Full_Page.css';
export default class Aviva_Full_Page extends Component {
  componentDidMount(){
    document.body.classList.remove('modal-open');
  }
  componentWillUnmount(){
    document.body.classList.add('modal-open');
  }
  render() {
    return (
      <div>

          
            <div className="col-md-12 col-xs-12 analytics_wrapper aviva_full_page_imggg" >
 
  


 <img src={AvivaPng} className="img-responsive" alt="avivapic"/>
l

  

         <div className="clear50"></div>










</div>
      </div>
    )
  }
}
