import React, { Component } from "react";
import searchSvg from "../../Assets/images/ic_search_-1.svg";
import excelPng from "../../Assets/images/excel.png";
import selPastePng from "../../Assets/images/select_paste.png";
import Header1 from "../Common/Header1/Header1";
import "./Ad_Times.css";
import { toast } from 'react-toastify';
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import DatePicker from "react-datepicker";
import moment from 'moment';
import 'moment-timezone';
import $ from "jquery";
import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var ip = require('ip');
class Ad_Times extends Component {
  constructor() {
    super();
    this.state = {
      toggleCaretState: false,
      AdTimesSelectedTab: 'Date',
      sort_AdTimes_Unit_Toggle: false,
      sort_AdTimes_Date_Toggle: false,
      sort_AdTimes_CrewCall_Toggle: false,
      sort_AdTimes_Break_Toggle: false,
      sort_AdTimes_CrewWrap_Toggle: false,
      isLoading: false,
      adTimes_ExcelOptions: [],
      importdata: '',
      adTimes_ExcelValue: "",
      adTimes_selectExcel: false,

      adTimes_selectPasteOptions: [],
      adTimes_selectPasteValue: "",
      adTimes_selectPaste: false,

      adTimes_ReportsOptions: [],
      adTimes_ReportsValue: "",
      adTimes_selectReports: false,

      adTimes_date: new Date(),
      adTimes_unit: "",
      adTimes_travelTo: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      adTimes_crewCall: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      adTimes_mealBreakOptions: [],
      adTimes_mealBreakValue: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      adTimes_selectmealBreak: false,
      adTimes_crewWrap: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      adTimes_travelFrom: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),

      excelErrors: {
        adTimes_selectExcelErr: ""
      },
      selectPasteErrors: {
        adTimes_selectPasteErr: ""
      },
      reportsErrors: {
        adTimes_selectReportsErr: ""
      },
      adTimesErrors: {
        adTimes_dateErr: "",
        adTimes_unitErr: "",
        adTimes_travelToErr: "",
        adTimes_crewCallErr: "",
        adTimes_selectmealBreakErr: "",
        adTimes_crewWrapErr: "",
        adTimes_travelFromErr: ""
      },

      adTimes_selectExcelValid: false,
      adTimes_selectPasteValid: false,
      adTimes_selectReportsValid: false,
      adTimes_dateValid: true,
      adTimes_unitValid: false,
      adTimes_travelToValid: true,
      adTimes_crewCallValid: true,
      adTimes_crewWrapValid: true,
      adTimes_travelFromValid: true,

      excel_formValid: false,
      selectPaste_formValid: false,
      reports_formValid: false,
      adTimes_formValid: false,


      IsAllAdTimesCheckBoxSelected: false,
      AdTimesTableData: [],
      "ad_times_start_mb1": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      "ad_times_finish_mb1": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      "ad_times_start_mb2": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      "ad_times_finish_mb2": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      "ad_times_start_mb3": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      "ad_times_finish_mb3": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),

      "ad_times_start_mb1_err": '',
      "ad_times_finish_mb1_err": '',
      "ad_times_start_mb2_err": '',
      "ad_times_finish_mb2_err": '',
      "ad_times_start_mb3_err": '',
      "ad_times_finish_mb3_err": '',

      "ad_tiems_notes_textarea": '',
      "ad_times_non_deductible_mb1_1": false,
      "ad_times_non_deductible_mb1_2": false,
      "ad_times_non_deductible_mb1_3": false,
      "ad_times_non_deductible_mb2_1": false,
      "ad_times_non_deductible_mb2_2": false,
      "ad_times_non_deductible_mb2_3": false,
      "ad_times_non_deductible_mb3_1": false,
      "ad_times_non_deductible_mb3_2": false,
      "ad_times_non_deductible_mb3_3": false,
      "mealsValid": false,
      "mealBreakErr": '',
      AdTimes_searchedData: [],
      mealdisable: false,
      sort_adTimes_toggle: false,
      checkedarray: [],
      AdTimesExcelData: []
    };
  }
  toggleCaret = async (name) => {

    await this.setState({
      toggleCaretState: !this.state.toggleCaretState
    })
    if (this.state.toggleCaretState === false) {
      $("#" + name).removeAttr("style")
    }
  }
  adTimes_selectHandler = e => {
    let name = e.target.name;
    let value = e.target.value;


    if (name === "adTimes_selectExcel") {
      if (e.target.selectedIndex > 0) {
        this.setState(
          {
            adTimes_ExcelValue: e.target.value,
            adTimes_selectExcel: true,
            adTimes_selectExcelValid: true
          },
          () => {
            this.excel_validateField(name, value);
          }
        );
      } else if (e.target.selectedIndex === 0) {
        this.setState(
          {
            adTimes_ExcelValue: "",
            adTimes_selectExcel: false,
            adTimes_selectExcelValid: false
          },
          () => {
            this.excel_validateField(name, value);
          }
        );
      }
    }
    if (name === "adTimes_selectPaste") {
      if (e.target.selectedIndex > 0) {
        this.setState(
          {
            adTimes_selectPasteValue: e.target.value,
            adTimes_selectPaste: true,
            adTimes_selectPasteValid: true
          },
          () => {
            this.selectPaste_validateField(name, value);
          }
        );
      } else if (e.target.selectedIndex === 0) {
        this.setState(
          {
            adTimes_selectPasteValue: "",
            adTimes_selectPaste: false,
            adTimes_selectPasteValid: false
          },
          () => {
            this.selectPaste_validateField(name, value);
          }
        );
      }
    }
    if (name === "adTimes_selectReports") {
      if (e.target.selectedIndex > 0) {
        this.setState(
          {
            adTimes_ReportsValue: e.target.value,
            adTimes_selectReports: true,
            adTimes_selectReportsValid: true
          },
          () => {
            this.reports_validateField(name, value);
          }
        );
      } else if (e.target.selectedIndex === 0) {
        this.setState(
          {
            adTimes_ReportsValue: "",
            adTimes_selectReports: false,
            adTimes_selectReportsValid: false
          },
          () => {
            this.reports_validateField(name, value);
          }
        );
      }
    }
  };
  excel_validateField(fieldName, value) {
    let excelErrors = this.state.excelErrors;
    let adTimes_selectExcelValid = this.state.adTimes_selectExcelValid;

    switch (fieldName) {
      case "adTimes_selectExcel":
        adTimes_selectExcelValid = this.state.adTimes_selectExcelValid;
        excelErrors.adTimes_selectExcelErr = adTimes_selectExcelValid
          ? ""
          : "Please Select Option";
        break;

      default:
        break;
    }

    this.setState(
      {
        excelErrors: excelErrors
      },
      this.excel_validateForm
    );
  }
  excel_validateForm = () => {
    this.setState({
      excel_formValid: this.state.adTimes_selectExcelValid
    });
  };
  excel_submitHandler = () => {
    if (this.state.AdTimesExcelData.length > 0) {
      $(":contains('Download')").closest('button').click();
    } else {
      toast.error('Please select data to export');
      document.getElementById('export_excel_cancel_btn').click();
    }

    // if (this.state.excel_formValid) {
    // console.log("success");
    // }
    // let excelErrors = this.state.excelErrors;

    // if (!this.state.adTimes_selectExcelValid) {
    // excelErrors.adTimes_selectExcelErr = "Please Select Option";
    // }
    // this.setState({
    // excelErrors: excelErrors
    // });
  }
  selectPaste_validateField(fieldName, value) {
    let selectPasteErrors = this.state.selectPasteErrors;
    let adTimes_selectPasteValid = this.state.adTimes_selectPasteValid;

    switch (fieldName) {
      case "adTimes_selectPaste":
        adTimes_selectPasteValid = this.state.adTimes_selectPasteValid;
        selectPasteErrors.adTimes_selectPasteErr = adTimes_selectPasteValid
          ? ""
          : "Please Select Option";
        break;
      default:
        break;
    }

    this.setState(
      {
        selectPasteErrors: selectPasteErrors
      },
      this.selectPaste_validateForm
    );
  }
  selectPaste_validateForm = () => {
    this.setState({
      selectPaste_formValid: this.state.adTimes_selectPasteValid
    });
  };
  selectPaste_submitHandler = async () => {


    const pastedExcelData = this.state.importdata;
    var res = pastedExcelData.split(/[\n\t]/);


    const newExcelData = [];

    var excel_columns = [];
    var excel_data = [];
    for (var i = 0; i < Math.round(res.length / 15); i++) { // i == 0 ; i < 3 
      for (var j = i * 15; j < (1 + i) * 15; j++) { // j == 1 ; j < 47 
        if (i === 0) {
          excel_columns.push(res[j]);
        } else {
          excel_data.push(res[j]);
        }
      }
    }
    var final_excel_data = [];
    for (var i = 0; i < excel_data.length / 15; i++) {
      var temp = [];
      for (var j = i * 15; j < (1 + i) * 15; j++) {
        temp.push(excel_data[j]);
      }
      final_excel_data.push(temp);
    }

    this.setState({
      isLoading: true
    });

    final_excel_data.map((item, index) => {

      let flag = false; //to check if excel data is valid then call api, otherwise not
      if (item.length > 0) {
        for (let i = 0; i < item.length; i++) {
          if (i > 1 && i < 12) {
            if (item[i] === null || item[i] === "" || !item[i]) {
              //time is empty, it should not be empty
              toast.error("Time Can't Contain Null value");
              flag = true;
              break;
            } else {
              let strArray = item[i].split(":", 2); //output ['00', '09']
              let partOneOfStr = Number(strArray[0]);
              let partSecondOfStr = Number(strArray[1]);
              //first convert string to number then check for isNaN
              //if isNaN returns true it means it contins some alphabetic
              if (isNaN(Number(partOneOfStr)) || isNaN(Number(partSecondOfStr))) {
                toast.error("Time is not valid, it must be like \'01:00\'");
                flag = true;
                break;
              }
              //after checking that it is number then check that numbers length is exact 2, not low or higher than 2 like '000:00' it should be '00:09'
              else if (
                strArray[0].length < 2 ||
                strArray[0].length > 2 ||
                strArray[1].length < 2 ||
                strArray[1].length > 2
              ) {
                toast.error("Time must be in format '00:00'.");
                flag = true;
                break;
              }


            }

          }
        }
      }
      if (!flag) {
        var mealBreak = [
          {
            "Deductable": {

              "AD": null,
              "Time": null
            },
            "MB1Finish": item[5],
            "MB1Start": item[4],
            "AD1Finish": null,
            "AD1Start": null,
          },
          {
            "Deductable": {

              "AD": null,
              "Time": null
            },
            "MB2Finish": item[8],
            "MB2Start": item[7],
            "AD2Finish": null,
            "AD2Start": null
          },
          {
            "Deductable": {

              "AD": null,
              "Time": null
            },
            "MB3Finish": item[10],
            "MB3Start": item[9],
            "AD3Finish": null,
            "AD3Start": null,
          }
        ];

        var completeDate = moment.tz(moment(item[0]), "Australia/Canberra").format();
        var onlyDate = completeDate.substr(0, completeDate.indexOf('T'));
        API.post("createadlist", "/createadlist", {
          body: {
            "Guid": item[14],
            "CrewCall": item[3],
            "CrewWrap": item[11],
            "Date": item[0],
            "MealBreak": item[4],
            "Meals": mealBreak,
            "TenantGuid": localStorage.getItem('useableGuid'),
            "TravelFrom": item[12],
            "TravelTo": item[2],
            "Unit": item[1],
            "Notes": item[13],
            "onlyDate": onlyDate
          }
        }).then(data => {
          this.AdList();

         // toast.success('Data Created Successfully.');
        }).catch(err => {
          toast.error(err.message)
        });


      }
    })

    document.getElementById('close_paste').click();
    this.setState({
      isLoading: false
    })

  }
  reports_validateField(fieldName, value) {
    let reportsErrors = this.state.reportsErrors;
    let adTimes_selectReportsValid = this.state.adTimes_selectReportsValid;

    switch (fieldName) {
      case "adTimes_selectReports":
        adTimes_selectReportsValid = this.state.adTimes_selectReportsValid;
        reportsErrors.adTimes_selectReportsErr = adTimes_selectReportsValid
          ? ""
          : "Please Select Option";
        break;

      default:
        break;
    }

    this.setState(
      {
        reportsErrors: reportsErrors
      },
      this.reports_validateForm
    );
  }
  reports_validateForm = () => {
    this.setState({
      reports_formValid: this.state.adTimes_selectReportsValid
    });
  };
  reports_submitHandler = () => {
    if (this.state.reports_formValid) {

    }
    let reportsErrors = this.state.reportsErrors;

    if (!this.state.adTimes_selectReportsValid) {
      reportsErrors.adTimes_selectReportsErr = "Please Select Option";
    }
    this.setState({
      reportsErrors: reportsErrors
    });
  };



  adTimes_inputHandler = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({
      [name]: value
    },
      () => {
        this.adTimes_validateField(name, value)
      }
    );
  }

  adTimes_times_inputHandler = async (name, value) => {
    if (value === null) {
      value = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    }
    await this.setState({
      [name]: value
    },
      () => {
        this.adTimes_validateField(name, value.format('HH:mm'))
      }
    );
    if (((moment(this.state.ad_times_start_mb1).format('HH:mm').toString() === '00:00') || (this.state.ad_times_start_mb1 === '00:00')) &&
      ((moment(this.state.ad_times_finish_mb1).format('HH:mm').toString() === '00:00') || (this.state.ad_times_finish_mb1 === '00:00')) &&
      ((moment(this.state.ad_times_start_mb2).format('HH:mm').toString() === '00:00') || (this.state.ad_times_start_mb2 === '00:00')) &&
      ((moment(this.state.ad_times_finish_mb2).format('HH:mm').toString() === '00:00') || (this.state.ad_times_finish_mb2 === '00:00')) &&
      ((moment(this.state.ad_times_start_mb3).format('HH:mm').toString() === '00:00') || (this.state.ad_times_start_mb3 === '00:00')) &&
      ((moment(this.state.ad_times_finish_mb3).format('HH:mm').toString() === '00:00') || (this.state.ad_times_finish_mb3 === '00:00'))) {

      await this.setState({
        mealdisable: false

      })

    } else {
      await this.setState({
        mealdisable: true
      })
      var mb1_diff = moment.duration(moment(this.state.ad_times_finish_mb1).diff(moment(this.state.ad_times_start_mb1)));
      var mb2_diff = moment.duration(moment(this.state.ad_times_finish_mb2).diff(moment(this.state.ad_times_start_mb2)));
      var mb3_diff = moment.duration(moment(this.state.ad_times_finish_mb3).diff(moment(this.state.ad_times_start_mb3)));
      let diff_hour = 0;
      let diff_mins = 0;
      let diff_sec = 0;
      diff_mins += mb1_diff._data.minutes + mb2_diff._data.minutes + mb3_diff._data.minutes;
      diff_hour += mb1_diff._data.hours + mb2_diff._data.hours + mb3_diff._data.hours;
      diff_sec += mb1_diff._data.seconds + mb2_diff._data.seconds + mb3_diff._data.seconds;
      var m = moment();

      this.setState({
        adTimes_mealBreakValue: m.set({ hour: diff_hour, minute: diff_mins, second: diff_sec, millisecond: 0 })
      });


    }

  }
  adTimes_validateField(fieldName, value) {
    let adTimesErrors = this.state.adTimesErrors;
    let adTimes_dateValid = this.state.adTimes_dateValid;
    let adTimes_unitValid = this.state.adTimes_unitValid;


    switch (fieldName) {
      case 'adTimes_date':
        adTimes_dateValid = (this.state.adTimes_date !== '') ? true : false;
        adTimesErrors.adTimes_dateErr = adTimes_dateValid ? '' : "This field is required";
        break;
      case 'adTimes_unit':
        adTimes_unitValid = (value !== '') ? true : false;
        adTimesErrors.adTimes_unitErr = adTimes_unitValid ? '' : "This field is required";
        break;
      default:
        break;
    }

    this.setState({
      adTimesErrors: adTimesErrors,
      adTimes_dateValid: adTimes_dateValid,
      adTimes_unitValid: adTimes_unitValid,
      adTimes_travelToValid: true,
      adTimes_crewCallValid: true,
      adTimes_crewWrapValid: true,
      adTimes_travelFromValid: true,

    }, this.adTimes_validateForm);
  }
  adTimes_validateForm = () => {
    this.setState({
      adTimes_formValid: this.state.adTimes_dateValid && this.state.adTimes_unitValid && this.state.adTimes_travelToValid && this.state.adTimes_crewCallValid && this.state.adTimes_crewWrapValid && this.state.adTimes_travelFromValid
    });
  }

  async componentDidMount() {

    $(":contains('Download')").closest('button').hide();
    await this.props.childProps.isAuthentication();
    if (!this.props.childProps.isAuthenticated) {
      this.props.history.push('/login');
    } else {
      this.AdList();
    }
  }

  AdList = async () => {
    await this.setState({
      isLoading: true,
    });
    $('#freshbtn').addClass('moving');
    await API.post('getalladlist', '/getalladlist', {
      body: {
        "tenant": localStorage.getItem('useableGuid')
      }
    }).then(data => {
      console.log(data);
      data.map(value => {
        value.checked = false;
        value.dateForm = moment(value.adDate).format('DD/MM/YYYY');
        return null;
      });

      let tab = localStorage.getItem('AdTimesSelcTab');


      if (tab === 'Date') {

        $("#dateCaret").show();
        $("#unitCaret").hide();
        $("#crewCallCaret").hide();
        $("#mealBreakCaret").hide();
        $("#crewWrapCaret").hide();


        this.setState({
          AdTimesSelectedTab: tab,
          sort_AdTimes_Unit_Toggle: false,
          sort_AdTimes_Date_Toggle: true,
          sort_AdTimes_CrewCall_Toggle: false,
          sort_AdTimes_Break_Toggle: false,
          sort_AdTimes_CrewWrap_Toggle: false,

          AdTimes_searchedData: data.sort(function (a, b) {
            let nameA = a.adDate.toUpperCase();
            let nameB = b.adDate.toUpperCase();
            if (nameA < nameB) {
              return 1;
            }
          })
          , AdTimesTableData: data.sort(function (a, b) {
            let nameA = a.adDate.toUpperCase();
            let nameB = b.adDate.toUpperCase();
            if (nameA < nameB) {
              return 1;
            }
          })
          ,
        })

      }
      else if (tab === 'Unit') {

        $("#dateCaret").hide();
        $("#unitCaret").show();
        $("#crewCallCaret").hide();
        $("#mealBreakCaret").hide();
        $("#crewWrapCaret").hide();



        this.setState({
          AdTimesSelectedTab: tab,
          sort_AdTimes_Unit_Toggle: true,
          sort_AdTimes_Date_Toggle: false,
          sort_AdTimes_CrewCall_Toggle: false,
          sort_AdTimes_Break_Toggle: false,
          sort_AdTimes_CrewWrap_Toggle: false,
          AdTimes_searchedData: data.sort(function (a, b) {
            let nameA = a.Unit.toUpperCase();
            let nameB = b.Unit.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          })
          , AdTimesTableData: data.sort(function (a, b) {
            let nameA = a.Unit.toUpperCase();
            let nameB = b.Unit.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          })
          ,
        })

      }
      else if (tab === 'CrewCall') {
        $("#dateCaret").hide();
        $("#unitCaret").hide();
        $("#crewCallCaret").show();
        $("#mealBreakCaret").hide();
        $("#crewWrapCaret").hide();

        this.setState({
          AdTimesSelectedTab: tab,
          sort_AdTimes_Unit_Toggle: false,
          sort_AdTimes_Date_Toggle: false,
          sort_AdTimes_CrewCall_Toggle: true,
          sort_AdTimes_Break_Toggle: false,
          sort_AdTimes_CrewWrap_Toggle: false,

          AdTimes_searchedData: data.sort(function (a, b) {
            let nameA = a.CrewCall.toUpperCase();
            let nameB = b.CrewCall.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          })
          , AdTimesTableData: data.sort(function (a, b) {
            let nameA = a.CrewCall.toUpperCase();
            let nameB = b.CrewCall.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          })
          ,
        })

      }
      else if (tab === 'MealBreak') {
        $("#dateCaret").hide();
        $("#unitCaret").hide();
        $("#crewCallCaret").hide();
        $("#mealBreakCaret").show();
        $("#crewWrapCaret").hide();

        this.setState({
          AdTimesSelectedTab: tab,
          sort_AdTimes_Unit_Toggle: false,
          sort_AdTimes_Date_Toggle: false,
          sort_AdTimes_CrewCall_Toggle: false,
          sort_AdTimes_Break_Toggle: true,
          sort_AdTimes_CrewWrap_Toggle: false,

          AdTimes_searchedData: data.sort(function (a, b) {
            let nameA = a.MealBreak.toUpperCase();
            let nameB = b.MealBreak.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          })
          , AdTimesTableData: data.sort(function (a, b) {
            let nameA = a.MealBreak.toUpperCase();
            let nameB = b.MealBreak.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          })
          ,
        })


      }
      else if (tab === 'CrewWrap') {
        $("#dateCaret").hide();
        $("#unitCaret").hide();
        $("#crewCallCaret").hide();
        $("#mealBreakCaret").hide();
        $("#crewWrapCaret").show();

        this.setState({
          AdTimesSelectedTab: tab,
          sort_AdTimes_Unit_Toggle: false,
          sort_AdTimes_Date_Toggle: false,
          sort_AdTimes_CrewCall_Toggle: false,
          sort_AdTimes_Break_Toggle: false,
          sort_AdTimes_CrewWrap_Toggle: true,

          AdTimes_searchedData: data.sort(function (a, b) {
            let nameA = a.CrewWrap.toUpperCase();
            let nameB = b.CrewWrap.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          })
          , AdTimesTableData: data.sort(function (a, b) {
            let nameA = a.CrewWrap.toUpperCase();
            let nameB = b.CrewWrap.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          })
          ,
        })


      }


      else {


        this.setState({
          AdTimesSelectedTab: 'Date',
          sort_AdTimes_Unit_Toggle: false,
          sort_AdTimes_Date_Toggle: true,
          sort_AdTimes_CrewCall_Toggle: false,
          sort_AdTimes_Break_Toggle: false,
          sort_AdTimes_CrewWrap_Toggle: false,

          AdTimes_searchedData: data.sort(function (a, b) {
            let nameA = a.adDate.toUpperCase();
            let nameB = b.adDate.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          })
          , AdTimesTableData: data.sort(function (a, b) {
            let nameA = a.adDate.toUpperCase();
            let nameB = b.adDate.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
          })
          ,
        })

      }






      ;
    }).catch(err => { console.log(err) });
    $('#freshbtn').removeClass('moving');
    await this.setState({
      isLoading: false,
    });
  }

  clearSelectPaste = () => {

    this.setState({
      importdata: ''
    });
  }
  setup_SearchHandler = (event) => {
    var AdSearchTab = this.state.AdTimesSelectedTab;
    var text = event.target.value;


    if (AdSearchTab === 'Date') {

      this.setState({
        adTimes_search: event.target.value
      });
      this.setState({
        AdTimesTableData: this.state.AdTimes_searchedData.filter(function (setup) {
          return setup.dateForm.toUpperCase().includes(text.toUpperCase());
        })
      });

    }
    if (AdSearchTab === 'Unit') {


      this.setState({
        adTimes_search: event.target.value
      });
      this.setState({
        AdTimesTableData: this.state.AdTimes_searchedData.filter(function (setup) {
          return setup.Unit.toUpperCase().includes(text.toUpperCase());
        })
      });

    }
    if (AdSearchTab === 'CrewCall') {


      this.setState({
        adTimes_search: event.target.value
      });
      this.setState({
        AdTimesTableData: this.state.AdTimes_searchedData.filter(function (setup) {
          return setup.CrewCall.toUpperCase().includes(text.toUpperCase());
        })
      });
    }
    if (AdSearchTab === 'MealBreak') {


      this.setState({
        adTimes_search: event.target.value
      });
      this.setState({
        AdTimesTableData: this.state.AdTimes_searchedData.filter(function (setup) {
          return setup.MealBreak.toUpperCase().includes(text.toUpperCase());
        })
      });
    }
    if (AdSearchTab === 'CrewWrap') {


      this.setState({
        adTimes_search: event.target.value
      });
      this.setState({
        AdTimesTableData: this.state.AdTimes_searchedData.filter(function (setup) {
          return setup.CrewWrap.toUpperCase().includes(text.toUpperCase());
        })
      });
    }




  }

  editAdTimes = async (id) => {
    await this.setState({
      isLoading: true,
    });
    this.props.history.push('/ad_times_2', { data: id });

    await this.setState({
      isLoading: false,
    });
  }
  deleteAdTimes = async (id) => {
    await this.setState({
      isLoading: true,
    });
    await API.post('deleteadlist', '/deleteadlist', {
      body: {
        "Guid": id
      }
    }).then(data => {
      console.log(data);
      this.AdList();
      API.post("recordactivity", "/recordactivity", {
        body: {
          "Description": "Adlist Delete Activity",
          "UserName": localStorage.getItem('UserLogin'),
          "IPAddress": ip.address(),
          "TenantGuid": localStorage.getItem('useableGuid'),
          "Type": "Adlist Delete ",
          "UserGuid": localStorage.getItem('userGuid')
        }
      }).then(data => {
       // toast.success('Adlist Delete Activity Note');
      }).catch(err => {
        toast.error(err.message);
      });
    //  toast.success('Record Deleted Successfully');

    }).catch(err => {
      console.log(err);
      toast.error('Problem in Record Deleting');
    });
    await this.setState({
      isLoading: false,
    });
  }

  clearstate = async () => {

    await this.setState({
      adTimes_date: new Date(),
      adTimes_unit: "",
      adTimes_travelTo: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      adTimes_crewCall: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      adTimes_mealBreakValue: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      adTimes_selectmealBreak: false,
      adTimes_crewWrap: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      adTimes_travelFrom: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),


      adTimes_selectExcelValid: false,
      adTimes_selectPasteValid: false,
      adTimes_selectReportsValid: false,
      adTimes_dateValid: true,
      adTimes_unitValid: false,
      adTimes_travelToValid: true,
      adTimes_crewCallValid: true,
      adTimes_crewWrapValid: true,
      adTimes_travelFromValid: true,
      adTimes_formValid: false,
      "ad_times_start_mb1": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      "ad_times_finish_mb1": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      "ad_times_start_mb2": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      "ad_times_finish_mb2": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      "ad_times_start_mb3": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      "ad_times_finish_mb3": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),

      "ad_times_start_mb1_err": '',
      "ad_times_finish_mb1_err": '',
      "ad_times_start_mb2_err": '',
      "ad_times_finish_mb2_err": '',
      "ad_times_start_mb3_err": '',
      "ad_times_finish_mb3_err": '',

      "ad_tiems_notes_textarea": '',
      "ad_times_non_deductible_mb1_1": false,
      "ad_times_non_deductible_mb1_2": false,
      "ad_times_non_deductible_mb1_3": false,
      "ad_times_non_deductible_mb2_1": false,
      "ad_times_non_deductible_mb2_2": false,
      "ad_times_non_deductible_mb2_3": false,
      "ad_times_non_deductible_mb3_1": false,
      "ad_times_non_deductible_mb3_2": false,
      "ad_times_non_deductible_mb3_3": false,
      "mealsValid": false,
      "mealBreakErr": '',
      adTimesErrors: {
        adTimes_dateErr: "",
        adTimes_unitErr: "",
        adTimes_travelToErr: "",
        adTimes_crewCallErr: "",
        adTimes_selectmealBreakErr: "",
        adTimes_crewWrapErr: "",
        adTimes_travelFromErr: ""
      },
    })
  }
  adTimes_submitHandler = async () => {
    if (this.state.adTimes_date === '' || this.state.adTimes_date === null) {
      this.setState({
        adTimes_dateValid: false
      })
      return;
    } else {
      this.setState({
        adTimes_dateValid: true
      })
    }
    if (this.state.adTimes_formValid) {


      this.setState({
        isLoading: true
      })

      var mealBreak = [
        {
          "Deductable": {

            "AD": this.state.ad_times_non_deductible_mb1_2,
            "Time": this.state.ad_times_non_deductible_mb1_3
          },
          "MB1Finish": moment(this.state.ad_times_finish_mb1).format('HH:mm'),
          "MB1Start": moment(this.state.ad_times_start_mb1).format('HH:mm'),
          "AD1Start": moment(this.state.ad_times_start_mb1).format('HH:mm'),
          "AD1Finish": moment(this.state.ad_times_finish_mb1).format('HH:mm'),
        },
        {
          "Deductable": {

            "AD": this.state.ad_times_non_deductible_mb2_2,
            "Time": this.state.ad_times_non_deductible_mb2_3
          },
          "MB2Finish": moment(this.state.ad_times_finish_mb2).format('HH:mm'),
          "MB2Start": moment(this.state.ad_times_start_mb2).format('HH:mm'),
          "AD2Start": moment(this.state.ad_times_start_mb2).format('HH:mm'),
          "AD2Finish": moment(this.state.ad_times_finish_mb2).format('HH:mm'),
        },
        {
          "Deductable": {

            "AD": this.state.ad_times_non_deductible_mb3_2,
            "Time": this.state.ad_times_non_deductible_mb3_3
          },
          "MB3Finish": moment(this.state.ad_times_finish_mb3).format('HH:mm'),
          "MB3Start": moment(this.state.ad_times_start_mb3).format('HH:mm'),
          "AD3Start": moment(this.state.ad_times_start_mb3).format('HH:mm'),
          "AD3Finish": moment(this.state.ad_times_finish_mb3).format('HH:mm'),
        }
      ];
      var guid_to_redirect = '';
      var completeDate = moment.tz(moment(this.state.adTimes_date), "Australia/Canberra").format();
      var onlyDate = completeDate.substr(0, completeDate.indexOf('T'));
      // console.log("Getting Only Date => ",onlyDate);
      await API.post("createadlist", "/createadlist", {
        body: {
          "CrewCall": moment(this.state.adTimes_crewCall).format('HH:mm'),
          "CrewWrap": moment(this.state.adTimes_crewWrap).format('HH:mm'),
          "Date": moment.tz(moment(this.state.adTimes_date), "Australia/Canberra").format(),
          "MealBreak": moment(this.state.adTimes_mealBreakValue).format('HH:mm'),
          "Meals": mealBreak,
          "TenantGuid": localStorage.getItem('useableGuid'),
          "TravelFrom": moment(this.state.adTimes_travelFrom).format('HH:mm'),
          "TravelTo": moment(this.state.adTimes_travelTo).format('HH:mm'),
          "Unit": this.state.adTimes_unit,
          "Notes": this.state.ad_tiems_notes_textarea,
          "onlyDate": onlyDate
        }
      }).then(data => {

        this.AdList();
        guid_to_redirect = data.Guid
        API.post("recordactivity", "/recordactivity", {
          body: {
            "Description": "Adlist Create Activity",
            "UserName": localStorage.getItem('UserLogin'),
            "IPAddress": ip.address(),
            "TenantGuid": localStorage.getItem('useableGuid'),
            "Type": "Adlist Create ",
            "UserGuid": localStorage.getItem('userGuid')
          }
        }).then(data => {

         // toast.success('Adlist Create Activity Note');
        }).catch(err => {
          toast.error(err.message);
        });
        //toast.success('Data Created Successfully.');
      }).catch(err => {
        toast.error(err.message)
      });

      document.getElementById('addTimesModalClose').click();
      if (guid_to_redirect !== '') {
        this.editAdTimes(guid_to_redirect);
      }

    }
    let adTimesErrors = this.state.adTimesErrors;

    if (!this.state.adTimes_dateValid) {
      adTimesErrors.adTimes_dateErr = "This field is required"
    }
    if (!this.state.adTimes_unitValid) {
      adTimesErrors.adTimes_unitErr = "This field is required"
    }
    if (!this.state.adTimes_travelToValid) {
      adTimesErrors.adTimes_travelToErr = "This field is required"
    }
    if (!this.state.adTimes_crewCallValid) {
      adTimesErrors.adTimes_crewCallErr = "This field is required"
    }
    if (!this.state.adTimes_crewWrapValid) {
      adTimesErrors.adTimes_crewWrapErr = "This field is required"
    }
    if (!this.state.adTimes_travelFromValid) {
      adTimesErrors.adTimes_travelFromErr = "This field is required"
    }
    this.setState({
      adTimesErrors: adTimesErrors
    })

  }

  handleModal_Height = () => {

    if (this.state.mealsValid === false) {
      this.setState({
        mealsValid: true
      })
    } else {
      this.setState({
        mealsValid: false
      })
    }


    if (!$('#btn_cllps').hasClass('collapsed')) {

      $('.modal-backdrop').addClass('mdl_incrs');
    }
    else if ($('#btn_cllps').hasClass('collapsed')) {
      $('.modal-backdrop').removeClass('mdl_incrs');
    }
  }

  handleDateChange = (date) => {

    this.setState({
      adTimes_date: date
    },
      () => {
        this.adTimes_validateField("adTimes_date", date)
      }
    );
  }
  AdtimesAllTableCheckBoxHandler = (Guid, isChecked) => {
    let isAllChecked = (Guid === 'IsAllAdTimesCheckBoxSelected' && isChecked);
    let isAllUnChecked = (Guid === 'IsAllAdTimesCheckBoxSelected' && !isChecked);
    const checked = isChecked;

    const checkList = this.state.AdTimesTableData.map((item, index) => {
      if (isAllChecked || item.Guid === Guid) {
        return Object.assign({}, item, {
          checked,
        })

      }
      else if (isAllUnChecked) {
        return Object.assign({}, item, {
          checked: false
        })
      }

      return item;

    })


    let isAllSelected = (checkList.findIndex(item => item.checked === false) === -1) || isAllChecked;
    if (isAllChecked) {
      let arr = [];
      checkList.map(items => {
        arr.push(items.Guid);
      });
      this.setState({
        checkedarray: arr
      });
    }
    else if (isAllUnChecked) {
      let arr = [];
      checkList.map(items => {
        const index = arr.indexOf(items.Guid);
        arr.splice(index, 1);
      });
      this.setState({
        checkedarray: arr
      });
    } else if (isChecked) {
      this.setState({ checkedarray: [...this.state.checkedarray, Guid] });
    } else if (!isChecked) {
      let arr = [];
      arr = [...this.state.checkedarray];
      const index = arr.indexOf(Guid);
      arr.splice(index, 1);
      this.setState({
        checkedarray: arr
      });
    }

    this.setState({
      IsAllAdTimesCheckBoxSelected: isAllSelected,
      AdTimesTableData: checkList
    })



  }




  open_AdTimes_calender = () => {
    $('#ad_times_date_calender').click();
  }
  sort_adTimes = async (tab, name) => {


    await this.setState({
      toggleCaretState: !this.state.toggleCaretState
    })
    if (this.state.toggleCaretState === false) {
      $("#" + name).removeAttr("style")
    }

    localStorage.setItem('AdTimesSelcTab', tab);



    if (tab === 'Date') {

      $("#dateCaret").show();
      $("#unitCaret").hide();
      $("#crewCallCaret").hide();
      $("#mealBreakCaret").hide();
      $("#crewWrapCaret").hide();

      this.setState({
        AdTimesSelectedTab: tab,
        sort_AdTimes_Unit_Toggle: false,
        sort_AdTimes_CrewCall_Toggle: false,
        sort_AdTimes_Break_Toggle: false,
        sort_AdTimes_CrewWrap_Toggle: false,
      })



      if (this.state.sort_AdTimes_Date_Toggle) {
        await this.setState({
          sort_AdTimes_Date_Toggle: false
        })
      } else {
        await this.setState({
          sort_AdTimes_Date_Toggle: true
        })
      }
      if (this.state.sort_AdTimes_Date_Toggle) {
        await this.setState({
          AdTimesTableData: this.state.AdTimesTableData.sort(function (a, b) {
            var nameA = a.adDate // ignore upper and lowercase
            var nameB = b.adDate // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          AdTimesTableData: this.state.AdTimesTableData.sort(function (a, b) {
            var nameA = a.adDate // ignore upper and lowercase
            var nameB = b.adDate // ignore upper and lowercase
            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      }

    }
    if (tab === 'Unit') {
      $("#dateCaret").hide();
      $("#unitCaret").show();
      $("#crewCallCaret").hide();
      $("#mealBreakCaret").hide();
      $("#crewWrapCaret").hide();


      this.setState({
        AdTimesSelectedTab: tab,
        sort_AdTimes_Date_Toggle: false,
        sort_AdTimes_CrewCall_Toggle: false,
        sort_AdTimes_Break_Toggle: false,
        sort_AdTimes_CrewWrap_Toggle: false,
      })



      if (this.state.sort_AdTimes_Unit_Toggle) {
        await this.setState({
          sort_AdTimes_Unit_Toggle: false
        })
      } else {
        await this.setState({
          sort_AdTimes_Unit_Toggle: true
        })
      }
      if (this.state.sort_AdTimes_Unit_Toggle) {
        await this.setState({
          AdTimesTableData: this.state.AdTimesTableData.sort(function (a, b) {
            var nameA = a.Unit.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Unit.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          AdTimesTableData: this.state.AdTimesTableData.sort(function (a, b) {
            var nameA = a.Unit.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Unit.toUpperCase(); // ignore upper and lowercase
            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      }





    }
    if (tab === 'CrewCall') {
      $("#dateCaret").hide();
      $("#unitCaret").hide();
      $("#crewCallCaret").show();
      $("#mealBreakCaret").hide();
      $("#crewWrapCaret").hide();


      this.setState({
        AdTimesSelectedTab: tab,
        sort_AdTimes_Unit_Toggle: false,
        sort_AdTimes_Date_Toggle: false,
        sort_AdTimes_Break_Toggle: false,
        sort_AdTimes_CrewWrap_Toggle: false,
      })


      if (this.state.sort_AdTimes_CrewCall_Toggle) {
        await this.setState({
          sort_AdTimes_CrewCall_Toggle: false
        })
      } else {
        await this.setState({
          sort_AdTimes_CrewCall_Toggle: true
        })
      }
      if (this.state.sort_AdTimes_CrewCall_Toggle) {
        await this.setState({
          AdTimesTableData: this.state.AdTimesTableData.sort(function (a, b) {
            var nameA = a.CrewCall.toUpperCase(); // ignore upper and lowercase
            var nameB = b.CrewCall.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          AdTimesTableData: this.state.AdTimesTableData.sort(function (a, b) {
            var nameA = a.CrewCall.toUpperCase(); // ignore upper and lowercase
            var nameB = b.CrewCall.toUpperCase(); // ignore upper and lowercase
            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      }





    }
    if (tab === 'MealBreak') {
      $("#dateCaret").hide();
      $("#unitCaret").hide();
      $("#crewCallCaret").hide();
      $("#mealBreakCaret").show();
      $("#crewWrapCaret").hide();
      this.setState({
        AdTimesSelectedTab: tab,
        sort_AdTimes_Unit_Toggle: false,
        sort_AdTimes_Date_Toggle: false,
        sort_AdTimes_CrewCall_Toggle: false,
        sort_AdTimes_CrewWrap_Toggle: false,
      })



      if (this.state.sort_AdTimes_Break_Toggle) {
        await this.setState({
          sort_AdTimes_Break_Toggle: false
        })
      } else {
        await this.setState({
          sort_AdTimes_Break_Toggle: true
        })
      }
      if (this.state.sort_AdTimes_Break_Toggle) {
        await this.setState({
          AdTimesTableData: this.state.AdTimesTableData.sort(function (a, b) {
            var nameA = a.MealBreak.toUpperCase(); // ignore upper and lowercase
            var nameB = b.MealBreak.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          AdTimesTableData: this.state.AdTimesTableData.sort(function (a, b) {
            var nameA = a.MealBreak.toUpperCase(); // ignore upper and lowercase
            var nameB = b.MealBreak.toUpperCase(); // ignore upper and lowercase
            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      }



    }
    if (tab === 'CrewWrap') {
      $("#dateCaret").hide();
      $("#unitCaret").hide();
      $("#crewCallCaret").hide();
      $("#mealBreakCaret").hide();
      $("#crewWrapCaret").show();
      this.setState({
        AdTimesSelectedTab: tab,
        sort_AdTimes_Unit_Toggle: false,
        sort_AdTimes_Date_Toggle: false,
        sort_AdTimes_CrewCall_Toggle: false,
        sort_AdTimes_Break_Toggle: false,
      })



      if (this.state.sort_AdTimes_CrewWrap_Toggle) {
        await this.setState({
          sort_AdTimes_CrewWrap_Toggle: false
        })
      } else {
        await this.setState({
          sort_AdTimes_CrewWrap_Toggle: true
        })
      }
      if (this.state.sort_AdTimes_CrewWrap_Toggle) {
        await this.setState({
          AdTimesTableData: this.state.AdTimesTableData.sort(function (a, b) {
            var nameA = a.CrewWrap.toUpperCase(); // ignore upper and lowercase
            var nameB = b.CrewWrap.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          AdTimesTableData: this.state.AdTimesTableData.sort(function (a, b) {
            var nameA = a.CrewWrap.toUpperCase(); // ignore upper and lowercase
            var nameB = b.CrewWrap.toUpperCase(); // ignore upper and lowercase
            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      }



    }






  }

  exportExcel = async () => {
    const excelData = [];
    excelData.push({
      columns: [
        { title: "Date", width: { wpx: 210 } },//pixels width 
        { title: "Unit", width: { wpx: 130 } },//char width 
        { title: "Travel To", width: { wpx: 130 } },
        { title: "Crew Call", width: { wpx: 130 } },//pixels width 
        { title: "Meal Break", width: { wpx: 130 } },//char width 
        { title: "MB1 Start", width: { wpx: 130 } },
        { title: "MB1 Finish", width: { wpx: 130 } },//pixels width 
        { title: "MB2 Start", width: { wpx: 130 } },//char width 
        { title: "MB2 Finish", width: { wpx: 130 } },
        { title: "MB3 Start", width: { wpx: 130 } },//pixels width 
        { title: "MB3 Finish", width: { wpx: 130 } },//char width 
        { title: "Crew Wrap", width: { wpx: 130 } },
        { title: "Travel From", width: { wpx: 130 } },//pixels width 
        { title: "Notes", width: { wpx: 300 } },//pixels width 
        { title: "Guid", width: { wpx: 300 } },//char width 
      ]
    });
    // console.log(this.state.AdTimesTableData);

    excelData[0].data = [];


    this.state.AdTimesTableData.map(item => {
      if (item.checked === true) {
        excelData[0].data.push([
          { value: item.adDate === null || '' ? 'NA' : item.adDate, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.Unit === null || '' ? 'NA' : item.Unit, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.TravelTo === null || '' ? 'NA' : item.TravelTo, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.CrewCall === null || '' ? 'NA' : item.CrewCall, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.MealBreak === null || '' ? 'NA' : item.MealBreak, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.Meals[0].MB1Start === null || '' ? 'NA' : item.Meals[0].MB1Start, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.Meals[0].MB1Finish === null || '' ? 'NA' : item.Meals[0].MB1Finish, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.Meals[1].MB2Start === null || '' ? 'NA' : item.Meals[1].MB2Start, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.Meals[1].MB2Finish === null || '' ? 'NA' : item.Meals[1].MB2Finish, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.Meals[2].MB3Start === null || '' ? 'NA' : item.Meals[2].MB3Start, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.Meals[2].MB3Finish === null || '' ? 'NA' : item.Meals[2].MB3Finish, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.CrewWrap === null || '' ? 'NA' : item.CrewWrap, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.TravelFrom === null || '' ? 'NA' : item.TravelFrom, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.Notes === null || '' ? 'NA' : item.Notes, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.Guid === null || '' ? 'NA' : item.Guid, style: { alignment: { horizontal: "center", vertical: 'center' } } },
        ])
      }
    })
    await this.setState({
      AdTimesExcelData: excelData
    })
  }

  //to check excel data is valid
  isExcelDataValid = async data => {
    let strArray = data.split(":", 2); //output ['00', '09']
    let partOneOfStr = Number(strArray[0]);
    let partSecondOfStr = Number(strArray[1]);
    //first convert string to number then check for isNaN
    //if isNaN returns true it means it contins some alphabetic
    if (isNaN(Number(partOneOfStr)) || isNaN(Number(partSecondOfStr))) {
      toast.error("Time is not valid, it must be like \'01:00\'");
      return true;
    }
    //after checking that it is number then check that numbers length is exact 2, not low or higher than 2 like '000:00' it should be '00:09'
    else if (
      strArray[0].length < 2 ||
      strArray[0].length > 2 ||
      strArray[1].length < 2 ||
      strArray[1].length > 2
    ) {
      toast.error("Time must be in format '00:00'.");
      return true;
    } else {
      return false;
    }
  };

  generateReport = async () => {
    var array_temp = [];
    this.state.AdTimesTableData.map(abc => {
      if (abc.checked === true) {
        array_temp.push(abc);
      }
    })
    if (array_temp.length > 0) {
      let data = {
        "ADList": array_temp
      };
      data = JSON.stringify(data)
      localStorage.setItem('getreport', data);
      var path = window.location.protocol + '//' + window.location.host + '/AdlistReport';
      window.open(path, '_blank');
    } else {
      toast.error("Please Select at Least One Record");
      $('.close').click();
      // $('#employee_reports').modal('hide');
      // $('body').removeClass('modal-open'); 
      // $('.modal-backdrop').remove();
    }

  }
  render() {
    return (
      <div>{this.state.isLoading ? <div className="se-pre-con"></div> : ''}
        <Header1
          pageHeader="AD Times"
          childProps={this.props.childProps}

        />

        <div className="col-lg-10 col-lg-offset-1 mrg_top_adtimes">
          <div className="col-sm-6 p0">
            <div className="search_area_adtimes">
              <input
                name="adTimes_search"
                type="text"
                placeholder={`search by ${this.state.AdTimesSelectedTab}`}
                onChange={this.setup_SearchHandler}
                value={this.state.adTimes_search}
                className="search_adtimes"

              />
              <img src={searchSvg} width="20" height="20" alt="" />
            </div>
          </div>

          <div className="col-sm-6 p0 mrg_topright2">
            <div className="dropdown menu_icon_adtimes">
              <button
                className="dropdown-toggle btn_adtimes_menu"
                type="button"
                data-toggle="dropdown"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="5.223"
                  height="20.893"
                  viewBox="0 0 5.223 20.893"
                >
                  <path
                    id="ic_more_horiz_24px"
                    d="M6.612,10a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,6.612,10Zm15.669,0a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,22.281,10Zm-7.835,0a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,14.446,10Z"
                    transform="translate(15.223 -4) rotate(90)"
                    fill="#7b7b7b"
                  />
                </svg>
              </button>
              <ul className="dropdown-menu dropleft dropdown_menu_users_down dropdown_menu_adtimes ad_times_drp_dwn">
                <li>
                  <a
                    href={null}
                    data-toggle="modal"
                    data-target="#excel_pop_adtimes"
                    className="cursorPointer"
                    onClick={this.exportExcel}
                  >
                    <img
                      src={excelPng}
                      className="mrg_sendicon"
                      width="11"
                      height="14"
                      alt=""
                    />
                    Excel
                  </a>
                </li>
                <li>

                  <a
                    href={null}
                    data-toggle="modal"
                    data-target="#select_paste_pop"
                    className="cursorPointer"
                    onClick={this.clearSelectPaste}
                  >
                    <img
                      src={selPastePng}
                      className="mrg_sendicon"
                      width="12"
                      height="15"
                      alt=""
                    />
                    Select Paste
                  </a>
                </li>

                <li>
                  <a
                    href={null}
                    data-toggle="modal"
                    data-target="#adtimes_reports"
                    className="lock_invoice_icon cursorPointer"
                  >
                    <img
                      src={excelPng}
                      className="mrg_sendicon"
                      width="11"
                      height="14"
                      alt=""
                    />
                    Reports
                  </a>
                </li>
              </ul>
            </div>

            <a href={null} id="freshbtn" className="refresh_icon_adtimes cursorPointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24.224"
                height="17.617"
                viewBox="0 0 24.224 17.617"
                onClick={this.AdList}
              >
                <path
                  id="ic_cached_24px"
                  d="M20.819,8.4l-4.4,4.4h3.3a6.612,6.612,0,0,1-6.606,6.606,6.464,6.464,0,0,1-3.083-.771L8.421,20.252a8.793,8.793,0,0,0,13.5-7.443h3.3Zm-14.314,4.4A6.612,6.612,0,0,1,13.112,6.2a6.464,6.464,0,0,1,3.083.771L17.8,5.365A8.793,8.793,0,0,0,4.3,12.809H1l4.4,4.4,4.4-4.4Z"
                  transform="translate(-1 -4)"
                  fill="#7B7B7B"
                />
              </svg>
            </a>
            <a
              href={null}
              className="plus_icon_adtimes cursorPointer"
              data-toggle="modal"
              data-target="#adtimes_plus_pop"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
              >
                <path
                  id="ic_add_24px"
                  d="M20,13.571H13.571V20H11.429V13.571H5V11.429h6.429V5h2.143v6.429H20Z"
                  transform="translate(-5 -5)"
                  fill="#7B7B7B"
                />
              </svg>
            </a>
          </div>

          <div className="clear10" />

          <ExcelFile element={<button>Download Data With Styles</button>} filename={"AD_Times_" + new Date()}>
            <ExcelSheet dataSet={this.state.AdTimesExcelData} name="AD_TIMES" />
          </ExcelFile>

          <div className="col-sm-12 p0">
            <div className="overflow_scroll_sm">
              <table
                width="100%"
                className="table table-bordered timecard_table adtimes_list_table"
              >
                <thead>
                  <tr className="table_header_adtimes">
                    <th align="center">
                      <input name='IsAllAdTimesCheckBoxSelected'
                        type="checkbox"
                        checked={this.state.IsAllAdTimesCheckBoxSelected}
                        onChange={(e) => this.AdtimesAllTableCheckBoxHandler('IsAllAdTimesCheckBoxSelected', e.target.checked)} />
                    </th>
                    <th align="center">
                      <div onClick={() => this.sort_adTimes('Date', 'dateCaret')}>
                        <div className="pull-left date_mrg_left p-new cursorPointer"> Date
<div className="adtimes_downarrow new1">
                            <svg id="dateCaret" className={!this.state.sort_AdTimes_Date_Toggle ? "toggleCaretClass" : ''}
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.337"
                              height="6.887"
                              viewBox="0 0 12.337 6.887"
                            >
                              <path
                                id="ic_arrow_drop_down_24px"
                                d="M7,10l6.169,6.887L19.337,10Z"
                                transform="translate(-7 -10)"
                                fill="#30679a"
                              />
                            </svg>
                          </div>
                        </div>

                      </div>
                    </th>
                    <th align="center">
                      <div onClick={() => this.sort_adTimes('Unit', 'unitCaret')}>
                        <div className="date_mrg_left p-new cursorPointer"> Unit
<div className="adtimes_downarrow new1">
                            <svg id="unitCaret" className={!this.state.sort_AdTimes_Unit_Toggle ? "toggleCaretClass" : ''}
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.337"
                              height="6.887"
                              viewBox="0 0 12.337 6.887"
                            >
                              <path
                                id="ic_arrow_drop_down_24px"
                                d="M7,10l6.169,6.887L19.337,10Z"
                                transform="translate(-7 -10)"
                                fill="#30679a"
                              />
                            </svg>
                          </div>
                        </div>

                      </div>
                    </th>
                    <th align="center">
                      <div onClick={() => this.sort_adTimes('CrewCall', 'crewCallCaret')}>
                        <div className="date_mrg_left p-new cursorPointer">Crew Call
 <div className="adtimes_downarrow new">
                            <svg id="crewCallCaret" className={!this.state.sort_AdTimes_CrewCall_Toggle ? "toggleCaretClass" : ''}
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.337"
                              height="6.887"
                              viewBox="0 0 12.337 6.887"
                            >
                              <path
                                id="ic_arrow_drop_down_24px"
                                d="M7,10l6.169,6.887L19.337,10Z"
                                transform="translate(-7 -10)"
                                fill="#30679a"
                              />
                            </svg>
                          </div></div>

                      </div>
                    </th>
                    <th align="center">
                      <div onClick={() => this.sort_adTimes('MealBreak', 'mealBreakCaret')}>
                        <div className="date_mrg_left p-new cursorPointer"> Meal Break

<div className="adtimes_downarrow new">
                            <svg id="mealBreakCaret" className={!this.state.sort_AdTimes_Break_Toggle ? "toggleCaretClass" : ''}
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.337"
                              height="6.887"
                              viewBox="0 0 12.337 6.887"
                            >
                              <path
                                id="ic_arrow_drop_down_24px"
                                d="M7,10l6.169,6.887L19.337,10Z"
                                transform="translate(-7 -10)"
                                fill="#30679a"
                              />
                            </svg>
                          </div>

                        </div>
                      </div>
                    </th>
                    <th align="center">
                      <div onClick={() => this.sort_adTimes('CrewWrap', 'crewWrapCaret')}>
                        <div className="date_mrg_left p-new cursorPointer"> Crew Wrap
<div className="adtimes_downarrow new">
                            <svg id="crewWrapCaret" className={!this.state.sort_AdTimes_CrewWrap_Toggle ? "toggleCaretClass" : ''}
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.337"
                              height="6.887"
                              viewBox="0 0 12.337 6.887"
                            >
                              <path
                                id="ic_arrow_drop_down_24px"
                                d="M7,10l6.169,6.887L19.337,10Z"
                                transform="translate(-7 -10)"
                                fill="#30679a"
                              />
                            </svg>
                          </div>
                        </div>

                      </div>
                    </th>
                    <th align="center">
                      &nbsp;
</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.AdTimesTableData.length > 0 ?
                    this.state.AdTimesTableData.map(items => {
                      return (
                        <tr key={items.Guid}>
                          <td align="center">
                            <input
                              type="checkbox"
                              name={items.Guid}
                              checked={items.checked}
                              onChange={(e) => this.AdtimesAllTableCheckBoxHandler(items.Guid, e.target.checked)} />
                          </td>
                          <td align="left">{moment(items.adDate).format('DD/MM/YYYY')}</td>
                          <td align="center">{items.Unit}</td>
                          <td align="center">{items.CrewCall}</td>
                          <td align="center">{items.MealBreak}</td>
                          <td align="center">{items.CrewWrap}</td>
                          <td align="center">
                            <a
                              href={null}
                              className="adtimes_pullleft_edit ad_times_edit_top_mrg cursorPointer"
                              onClick={this.editAdTimes.bind(this, items.Guid)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  id="ic_create_24px"
                                  className="cls-1"
                                  d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z"
                                  transform="translate(-3 -2.998)"
                                />
                              </svg>
                            </a>
                            <a
                              href={null}
                              className="adtimes_attachment cursorPointer"
                              onClick={this.deleteAdTimes.bind(this, items.Guid)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10.111"
                                height="13"
                                viewBox="0 0 10.111 13"
                              >
                                <path
                                  id="ic_delete_24px"
                                  d="M5.722,14.556A1.449,1.449,0,0,0,7.167,16h5.778a1.449,1.449,0,0,0,1.444-1.444V5.889H5.722ZM15.111,3.722H12.583L11.861,3H8.25l-.722.722H5V5.167H15.111Z"
                                  transform="translate(-5 -3)"
                                  fill="#7b7b7b"
                                />
                              </svg>
                            </a>
                          </td>
                        </tr>
                      )
                    }) :
                    <tr>
                      <td align="left">&nbsp;</td>
                      <td align="center">&nbsp;</td>
                      <td align="center">&nbsp;</td>
                      <td>No data Found...</td>
                      <td align="left">&nbsp;</td>
                      <td align="center">&nbsp;</td>
                      <td align="center">&nbsp;</td></tr>}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div id="excel_pop_adtimes" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle_adtimes modal-dialog-centered modal_no_scrol_employeee">
            <div className="modal-content modal_middle_h_adtimes">
              <div className="modal-header">
                <button
                  type="button"
                  className="close close_modal_adtimes"
                  data-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title adtimesl_modal_header">Excel</h4>
              </div>
              <div className="modal-body">
                <div className="col-sm-8 adtimes_input_pop1">
                  <select
                    id="adTimes_selectExcel"
                    name="adTimes_selectExcel"
                    onChange={this.adTimes_selectHandler}
                  >
                    <option>AD List</option>
                    {this.state.adTimes_ExcelOptions.map(function (item, key) {
                      return (
                        <option value={item.id} key={key}>
                          {item.Name}
                        </option>
                      );
                    })}
                  </select>
                  <p
                    className={
                      "error validation " +
                      (this.state.excelErrors.adTimes_selectExcelErr
                        ? ""
                        : "displayNone")
                    }
                  >
                    {this.state.excelErrors.adTimes_selectExcelErr}
                  </p>
                </div>

                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    <a
                      href={null}
                      className="btn_save_analytics cursorPointer"
                      onClick={this.excel_submitHandler}
                    >
                      Export
                    </a>
                    <a
                      id="export_excel_cancel_btn"
                      href={null}
                      className="btn_cancel_analytics cursorPointer"
                      data-dismiss="modal"

                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10" />
              </div>
            </div>
          </div>
        </div>

        <div id="select_paste_pop" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle_adtimes modal-dialog-centered modal_no_scrol_employeee">
            <div className="modal-content modal_middle_h_adtimes2">
              <div className="modal-header">
                <button
                  type="button"
                  className="close close_modal_adtimes"
                  data-dismiss="modal"
                  id="close_paste"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title adtimesl_modal_header">
                  Select Paste
                </h4>
              </div>
              <div className="modal-body">
                <div className="col-sm-8 adtimes_input_pop1">
                  <select
                    id="adTimes_selectPaste"
                    name="adTimes_selectPaste"
                    onChange={this.adTimes_selectHandler}
                  >
                    <option>AD List</option>
                    {this.state.adTimes_ExcelOptions.map(function (item, key) {
                      return (
                        <option value={item.id} key={key}>
                          {item.Name}
                        </option>
                      );
                    })}
                  </select>
                  <p
                    className={
                      "error validation " +
                      (this.state.selectPasteErrors.adTimes_selectPasteErr
                        ? ""
                        : "displayNone")
                    }
                  >
                    {this.state.selectPasteErrors.adTimes_selectPasteErr}
                  </p>
                </div>
                <div className="col-sm-12 adtimes_input_pop1">
                  <textarea name="importdata" value={this.state.importdata} onChange={this.adTimes_inputHandler} cols="" rows="" />
                </div>

                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    <a
                      href={null}
                      className="btn_save_analytics cursorPointer"
                      onClick={this.selectPaste_submitHandler}
                    >
                      Start
                    </a>
                    <a
                      href={null}
                      className="btn_cancel_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10" />
              </div>
            </div>
          </div>
        </div>

        <div id="adtimes_reports" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle_adtimes modal-dialog-centered modal_no_scrol_employeee">
            <div className="modal-content modal_middle_h_adtimes">
              <div className="modal-header">
                <button
                  type="button"
                  className="close close_modal_adtimes"
                  data-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title adtimesl_modal_header">Reports</h4>
              </div>
              <div className="modal-body">
                <div className="col-sm-8 adtimes_input_pop1">
                  <select
                    id="adTimes_selectReports"
                    name="adTimes_selectReports"
                    onChange={this.adTimes_selectHandler}
                  >
                    <option>AD List</option>
                    {this.state.adTimes_ReportsOptions.map(function (item, key) {
                      return (
                        <option value={item.id} key={key}>
                          {item.Name}
                        </option>
                      );
                    })}
                  </select>
                  <p
                    className={
                      "error validation " +
                      (this.state.reportsErrors.adTimes_selectReportsErr
                        ? ""
                        : "displayNone")
                    }
                  >
                    {this.state.reportsErrors.adTimes_selectReportsErr}
                  </p>
                </div>

                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    <a
                      href={null}
                      className="btn_save_analytics cursorPointer"
                      onClick={this.generateReport}
                    >
                      Save
                    </a>
                    <a
                      href={null}
                      className="btn_cancel_analytics cursorPointer"
                      data-dismiss="modal"
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10" />
              </div>
            </div>
          </div>
        </div>

        <div id="adtimes_attachment" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle_adtimes">
            <div className="modal-content modal_middle_emp_attach">
              <div className="modal-header attach_header_pop">
                <button
                  type="button"
                  className="close close_modal_adtimes"
                  data-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title adtimesl_modal_header">
                  Attachments
                </h4>
              </div>
              <div className="modal-body">
                <div className="col-sm-12 table_attach_empl">
                  <table width="100%" className="table table-condensed">
                    <thead>
                      <tr>
                        <th colSpan="3" align="left">
                          Description
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td width="90%">Car Insurance</td>
                        <td colSpan="2" align="center" width="10%">
                          < a href={null} className="doc_img_emp cursorPointer">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 18 22.5"
                            >
                              <path
                                id="ic_description_24px"
                                className="cls-1"
                                d="M15.25,2h-9A2.247,2.247,0,0,0,4.011,4.25L4,22.25A2.247,2.247,0,0,0,6.239,24.5H19.75A2.257,2.257,0,0,0,22,22.25V8.75ZM17.5,20h-9V17.75h9Zm0-4.5h-9V13.25h9ZM14.125,9.875V3.688l6.187,6.188Z"
                                transform="translate(-4 -2)"
                              />
                            </svg>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>Driver License</td>
                        <td colSpan="2" align="center">
                          < a href={null} className="doc_img_emp cursorPointer">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 18 22.5"
                            >
                              <path
                                id="ic_description_24px4"
                                className="cls-1"
                                d="M15.25,2h-9A2.247,2.247,0,0,0,4.011,4.25L4,22.25A2.247,2.247,0,0,0,6.239,24.5H19.75A2.257,2.257,0,0,0,22,22.25V8.75ZM17.5,20h-9V17.75h9Zm0-4.5h-9V13.25h9ZM14.125,9.875V3.688l6.187,6.188Z"
                                transform="translate(-4 -2)"
                              />
                            </svg>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>Passport</td>
                        <td colSpan="2" align="center">
                          < a href={null} className="doc_img_emp cursorPointer">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 18 22.5"
                            >
                              <path
                                id="ic_description_24px5"
                                className="cls-1"
                                d="M15.25,2h-9A2.247,2.247,0,0,0,4.011,4.25L4,22.25A2.247,2.247,0,0,0,6.239,24.5H19.75A2.257,2.257,0,0,0,22,22.25V8.75ZM17.5,20h-9V17.75h9Zm0-4.5h-9V13.25h9ZM14.125,9.875V3.688l6.187,6.188Z"
                                transform="translate(-4 -2)"
                              />
                            </svg>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="clear10" />
              </div>
            </div>
          </div>
        </div>

        <div id="adtimes_plus_pop" className="modal fade " role="dialog" data-keyboard="false" data-backdrop="static">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header adtime_pop_ad">
                <button
                  type="button"
                  className="close close_modal_adtimes"
                  data-dismiss="modal"
                  onClick={this.clearstate}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="ic_clear_24px"
                      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                      transform="translate(-5 -5)"
                      fill="#7B7B7B"
                    />
                  </svg>
                </button>
                <h4 className="modal-title">AD Times</h4>
              </div>
              <div className="modal-body">
                <div className="col-sm-12 table_attach_empl">
                  <div className="clear20" />

                  <div className="col-md-12 p0 employe_edit_pop_input">
                    <div className="col-sm-6 col-md-4 label_ad_times_pop">
                      {" "}
                      Date:
                    </div>
                    <div className="col-sm-6 col-md-8">
                      {" "}
                      <DatePicker dateFormat="dd/MM/yyyy"
                        id="ad_times_date_calender"
                        value={this.state.adTimes_date}
                        selected={this.state.adTimes_date}
                        onChange={this.handleDateChange}
                      />
                      < a href={null} className="calendar_icon_ad_times cursorPointer">
                        <svg
                          onClick={this.open_AdTimes_calender}
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="20"
                          viewBox="0 0 18 20"
                        >
                          <path
                            id="ic_date_range_24px"
                            d="M9,11H7v2H9Zm4,0H11v2h2Zm4,0H15v2h2Zm2-7H18V2H16V4H8V2H6V4H5A1.991,1.991,0,0,0,3.01,6L3,20a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,19,4Zm0,16H5V9H19Z"
                            transform="translate(-3 -2)"
                            fill="#30679a"
                          />
                        </svg>
                      </a>
                      {(this.state.adTimes_dateValid === false) ? <p style={{ color: 'red' }}>This field is required</p> : ''}
                    </div>

                    <div className="clearfix" />

                    <div className="col-sm-6 col-md-4 label_ad_times_pop">
                      {" "}
                      Unit:
                    </div>
                    <div className="col-sm-6 col-md-8">
                      {" "}
                      <input value={this.state.adTimes_unit} name="adTimes_unit" onChange={this.adTimes_inputHandler} />
                      {" "}
                      <p className={"error validation " + (this.state.adTimesErrors.adTimes_unitErr ? '' : 'displayNone')}>{this.state.adTimesErrors.adTimes_unitErr}</p>
                    </div>

                    <div className="clearfix" />

                    <div className="col-sm-6 col-md-4 label_ad_times_pop">
                      Travel To:
                    </div>
                    <div className="col-sm-6 col-md-8">
                      {" "}

                      <TimePicker showSecond={false} value={moment(this.state.adTimes_travelTo, 'HH:mm')} onChange={this.adTimes_times_inputHandler.bind(this, "adTimes_travelTo")} placeholder="12:00" />
                      {" "}
                      <p className={"error validation " + (this.state.adTimesErrors.adTimes_travelToErr ? '' : 'displayNone')}>{this.state.adTimesErrors.adTimes_travelToErr}</p>
                    </div>

                    <div className="clearfix" />

                    <div className="col-sm-6 col-md-4 label_ad_times_pop">
                      Crew Call:
                    </div>
                    <div className="col-sm-6 col-md-8">
                      {" "}
                      <TimePicker showSecond={false} value={moment(this.state.adTimes_crewCall, 'HH:mm')} onChange={this.adTimes_times_inputHandler.bind(this, "adTimes_crewCall")} placeholder="12:00" />
                      {" "}
                      <p className={"error validation " + (this.state.adTimesErrors.adTimes_crewCallErr ? '' : 'displayNone')}>{this.state.adTimesErrors.adTimes_crewCallErr}</p>
                    </div>

                    <div className="clearfix" />

                    <div className="col-sm-6 col-md-4 label_ad_times_pop">
                      Meal Break:
                    </div>

                    <div className="col-sm-6 col-md-7">
                      {" "}
                      <TimePicker showSecond={false}
                        value={moment(this.state.adTimes_mealBreakValue, 'HH:mm')}
                        onChange={this.adTimes_times_inputHandler.bind(this, "adTimes_mealBreakValue")}
                        placeholder="0:30" disabled={this.state.mealdisable ? true : false} />
                      <a href={null}
                        data-toggle="collapse"
                        data-target="#meal_content_collapse"
                        className="decor cursorPointer"
                        id="btn_cllps"
                        onClick={this.handleModal_Height}
                      >&nbsp;&nbsp;&nbsp;
                               <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8.027" viewBox="0 0 13 8.027">
                          <path id="ic_expand_more_24px" d="M17.473,8.59,12.5,13.552,7.528,8.59,6,10.118l6.5,6.5,6.5-6.5Z" transform="translate(-6 -8.59)" fill="#C4C4C4" />
                        </svg>
                      </a>
                      {(this.state.mealsValid) ? '' : <p style={{ color: 'blue' }}> Open meals detail section below </p>}
                    </div>
                    <div className="clearfix"></div>
                    <div
                      className="meal_collapse collapse"
                      id="meal_content_collapse"
                    >
                      <table className="table-responsive " id="table_meal_ad_time">
                        <thead>
                          <tr>
                            <th align="center"> &nbsp;</th>
                            <th align="center" className="style_th_center" > AD</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Start MB1</td>
                            <td>
                              <TimePicker showSecond={false}
                                value={moment(this.state.ad_times_start_mb1, 'HH:mm')}
                                onChange={this.adTimes_times_inputHandler.bind(this, "ad_times_start_mb1")}
                                placeholder="12:00"
                              />
                            </td>
                          </tr>
                          {(this.state.ad_times_start_mb1_err === '') ? '' : <p style={{ color: 'red' }}>{this.state.ad_times_start_mb1_err}</p>}
                          <tr>
                            <td>Finish MB1</td>
                            <td>
                              <TimePicker showSecond={false}
                                value={moment(this.state.ad_times_finish_mb1, 'HH:mm')}
                                onChange={this.adTimes_times_inputHandler.bind(this, "ad_times_finish_mb1")}
                                placeholder="12:00"
                              />
                            </td>
                          </tr>
                          {(this.state.ad_times_finish_mb1_err === '') ? '' : <p style={{ color: 'red' }}>{this.state.ad_times_finish_mb1_err}</p>}
                          <tr>
                            <td>Start MB2</td>
                            <td>
                              <TimePicker showSecond={false}
                                value={moment(this.state.ad_times_start_mb2, 'HH:mm')}
                                onChange={this.adTimes_times_inputHandler.bind(this, "ad_times_start_mb2")}
                                placeholder="12:00"
                              />
                            </td>
                          </tr>
                          {(this.state.ad_times_start_mb2_err === '') ? '' : <p style={{ color: 'red' }}>{this.state.ad_times_start_mb2_err}</p>}
                          <tr>
                            <td>Finish MB2</td>
                            <td>
                              <TimePicker showSecond={false}
                                value={moment(this.state.ad_times_finish_mb2, 'HH:mm')}
                                onChange={this.adTimes_times_inputHandler.bind(this, "ad_times_finish_mb2")}
                                placeholder="12:00"

                              />
                            </td>
                          </tr>
                          {(this.state.ad_times_finish_mb2_err === '') ? '' : <p style={{ color: 'red' }}>{this.state.ad_times_finish_mb2_err}</p>}
                          <tr>
                            <td>Start MB3</td>
                            <td>
                              <TimePicker showSecond={false}
                                value={moment(this.state.ad_times_start_mb3, 'HH:mm')}
                                onChange={this.adTimes_times_inputHandler.bind(this, "ad_times_start_mb3")}
                                placeholder="12:00"
                              />
                            </td>
                          </tr>
                          {(this.state.ad_times_start_mb3_err === '') ? '' : <p style={{ color: 'red' }}>{this.state.ad_times_start_mb3_err}</p>}
                          <tr>
                            <td>Finish MB3</td>
                            <td>
                              <TimePicker showSecond={false}
                                value={moment(this.state.ad_times_finish_mb3, 'HH:mm')}
                                onChange={this.adTimes_times_inputHandler.bind(this, "ad_times_finish_mb3")}
                                placeholder="12:00"
                              />
                            </td>
                          </tr>
                          {(this.state.ad_times_finish_mb3_err === '') ? '' : <p style={{ color: 'red' }}>{this.state.ad_times_finish_mb3_err}</p>}
                        </tbody>
                      </table>

                      <div className="clear20"></div>
                      <table width="100%" className="table tbl_pencil_info_collapse tbl_pencill_td">
                        <thead>
                          <tr className="">
                            <th width="20%" className="text-left">&nbsp; </th>
                            <th width="20%" className="text-left">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Non Deductible MB1:</td>
                            <td><input className="checki" name="ad_times_non_deductible_mb1_3" checked={this.state.ad_times_non_deductible_mb1_3 ? true : false} onChange={this.adTimes_inputHandler} type="checkbox" /></td>
                          </tr>
                          <tr>
                            <td>Non Deductible MB2:</td>
                            <td><input className="checki" name="ad_times_non_deductible_mb2_3" checked={this.state.ad_times_non_deductible_mb2_3 ? true : false} onChange={this.adTimes_inputHandler} type="checkbox" /></td>
                          </tr>
                          <tr>
                            <td>Non Deductible MB3:</td>
                            <td><input className="checki" name="ad_times_non_deductible_mb3_3" checked={this.state.ad_times_non_deductible_mb3_3 ? true : false} onChange={this.adTimes_inputHandler} type="checkbox" /></td>
                          </tr>
                        </tbody>
                      </table>

                    </div>

                    <div className="clear10" />

                    <div className="col-sm-6 col-md-4 label_ad_times_pop">
                      Crew Wrap:
                    </div>
                    <div className="col-sm-6 col-md-8">
                      {" "}
                      <TimePicker showSecond={false}
                        value={moment(this.state.adTimes_crewWrap, 'HH:mm')}
                        onChange={this.adTimes_times_inputHandler.bind(this, "adTimes_crewWrap")} placeholder="12:30" />
                      {" "}
                      <p className={"error validation " + (this.state.adTimesErrors.adTimes_crewWrapErr ? '' : 'displayNone')}>{this.state.adTimesErrors.adTimes_crewWrapErr}</p>
                    </div>

                    <div className="clearfix" />

                    <div className="col-sm-6 col-md-4 label_ad_times_pop">
                      Travel From:
                    </div>
                    <div className="col-sm-6 col-md-8">
                      <TimePicker showSecond={false}
                        value={moment(this.state.adTimes_travelFrom, 'HH:mm')}
                        onChange={this.adTimes_times_inputHandler.bind(this, "adTimes_travelFrom")}
                        placeholder="12:00" />
                      <p className={"error validation " + (this.state.adTimesErrors.adTimes_travelFromErr ? '' : 'displayNone')}>{this.state.adTimesErrors.adTimes_travelFromErr}</p>
                    </div>
                    <div className="clearfix" />

                    <div className="col-sm-6 col-md-4 label_ad_times_pop">
                      {" "}
                      Notes:
                    </div>
                    <div className="col-sm-6 col-md-8">
                      <textarea
                        name="ad_tiems_notes_textarea"
                        cols=""
                        className="textarea_ad_times"
                        rows=""
                        value={this.state.ad_tiems_notes_textarea}
                        onChange={this.adTimes_inputHandler}
                      />
                    </div>

                    <div className="clear10" />

                    <div className="col-sm-12">
                      <div className="analytics_btn_save">
                        <a
                          href={null}
                          className="btn_save_analytics cursorPointer"
                          onClick={this.adTimes_submitHandler}
                        >
                          Save
                        </a>
                        <a
                          id="addTimesModalClose"
                          href={null}
                          className="btn_cancel_analytics cursorPointer"
                          data-dismiss="modal"
                          onClick={this.clearstate}
                        >
                          Cancel
                        </a>
                      </div>
                      <div className="col-md-3"> </div>
                    </div>
                    <div className="clear20" />
                  </div>
                </div>

                <div className="clear10" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Ad_Times;
