import React, { Component } from 'react'
import SearchSvg from '../../Assets/images/ic_search_-1.svg';
import Header1 from '../Common/Header1/Header1';
import { Storage, API } from 'aws-amplify';
import { toast } from 'react-toastify';
import './ApprovalSetup.css';
import $ from 'jquery';
const uuidv1 = require('uuid/v1');
var ip = require('ip');


class ApprovalSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_approver_email:"",
      showApprovalSetup: true,
      isLoading: true,
      toggleCaretState: false,
      approvalSetupSelectedTab: 'UserGroup',
      approvalSetup_search: '',
      getallusersag: [],
      getallusersag_search: [],
      approvalSetup_ApproverOptions: [],
      approvalSetup_selectApproverValue: '',
      approvalSetup_selectApprover: false,
      approvalSetup_Sequence: '',
      approvalSetup_Signature: '',
      approvalSetup_etcCheck: '',
      approvalSetup_PayslipsCheck: '',
      approvalSetup_CommentsCheck: '',
      approvalSetup_ExceptionsCheck: '',
      approvalSetup_AttachmentsCheck: '',
      approvalSetup_ActivityCheck: '',

      approvalSetup_selectApproverValid: false,
      approvalSetup_SequenceValid: false,
      approvalSetup_SignatureValid: false,
      formValid: false,
      checkedarray: [],


      ApprovalSetupErrors: {
        approvalSetup_selectApproverErr: '',
        approvalSetup_SequenceErr: '',
        approvalSetup_SignatureErr: '',
      },
      userGroupToggle: true,
      userNameToggle: false,
      DepToggle: false,

      isAllApprovalSetupTableChkbxSelected: false,
      UserToBeUpdated: '',
      UserIndex: '',
      present_before: false,
      signature_present_before: false,
      editUser: false,
      editedApproverID: '',
      approvel_present_before: false
    }

  }
  componentWillMount = async () => {
    await this.getUserSettings();
    if (this.state.showApprovalSetup === false) {
      this.props.history.push('/dashboard');
    }
  }
  getUserSettings = async () => {
    let userGuid = localStorage.getItem("userGuid");
    await API.post("getuserbyguid", "/getuserbyguid", {
      body: {
        user_id: userGuid
      }
    }).then(data => {
      this.setState({
        showApprovalSetup: data.access.ApprovalSetup,
      });
    }).catch(err => {
      console.log('error in fetching user')
    });


  }
  componentDidMount = async () => {
    $('#UGCaret').hide();
    $('#UserNameCaret').hide();
    $('#DepCaret').hide();

    await this.props.childProps.isAuthentication();
    if (!this.props.childProps.isAuthenticated) {
      this.props.history.push('/login');

    } else {
      this.setState({
        isLoading: true
      });
      await API.post('getallusersag', '/getallusersag').then(data => {
        data.map(value => {
          value.checked = false;
          return null;
        });
        data.map((value) => {

          value.ApproverGroup[0].FirstName = value.ApproverGroup[0].FirstName !== null ? value.ApproverGroup[0].FirstName.toUpperCase() : '-';
          // value.Initials = value.Initials!==null ? value.Initials.toUpperCase() : '12';
          value.ApproverGroup[0].Department = value.ApproverGroup[0].Department !== null ? value.ApproverGroup[0].Department.toUpperCase() : '-';

        })
        let AdSearchTab = localStorage.getItem('approvalSetup_sortLastTab');
        if (AdSearchTab === 'UserGroup') {


          $('#UGCaret').show();
          $('#UserNameCaret').hide();
          $('#DepCaret').hide();
          this.setState({
            approvalSetupSelectedTab: AdSearchTab,
            userGroupToggle: true,
            userNameToggle: false,
            DepToggle: false,
            getallusersag: data.sort(function (a, b) {
              let nameA = a.GroupName.toUpperCase();
              let nameB = b.GroupName.toUpperCase();
              if (nameA < nameB) {
                return -1
              }
            }),
            getallusersag_search: data.sort(function (a, b) {
              let nameA = a.GroupName.toUpperCase();
              let nameB = b.GroupName.toUpperCase();
              if (nameA < nameB) {
                return -1
              }
            })
          })


        } else if (AdSearchTab === 'UserName') {

          $('#UGCaret').hide();
          $('#UserNameCaret').show();
          $('#DepCaret').hide();
          this.setState({
            approvalSetupSelectedTab: AdSearchTab,
            userGroupToggle: false,
            userNameToggle: true,
            DepToggle: false,
            getallusersag: data.sort(function (a, b) {
              let nameA = a.ApproverGroup[0].FirstName.toUpperCase();
              let nameB = b.ApproverGroup[0].FirstName.toUpperCase();
              if (nameA < nameB) {
                return -1
              }
            }),
            getallusersag_search: data.sort(function (a, b) {
              let nameA = a.ApproverGroup[0].FirstName.toUpperCase();
              let nameB = b.ApproverGroup[0].FirstName.toUpperCase();
              if (nameA < nameB) {
                return -1
              }
            })
          })


        } else if (AdSearchTab === 'Department') {


          $('#UGCaret').hide();
          $('#UserNameCaret').hide();
          $('#DepCaret').show();
          this.setState({
            approvalSetupSelectedTab: AdSearchTab,
            userGroupToggle: false,
            userNameToggle: false,
            DepToggle: true,
            getallusersag: data.sort(function (a, b) {
              let nameA = a.ApproverGroup[0].Department.toUpperCase();
              let nameB = b.ApproverGroup[0].Department.toUpperCase();
              if (nameA < nameB) {
                return -1
              }
            }),
            getallusersag_search: data.sort(function (a, b) {
              let nameA = a.ApproverGroup[0].Department.toUpperCase();
              let nameB = b.ApproverGroup[0].Department.toUpperCase();
              if (nameA < nameB) {
                return -1
              }
            })
          })


        } else {

          $('#UGCaret').show();
          $('#UserNameCaret').hide();
          $('#DepCaret').hide();
          this.setState({
            approvalSetupSelectedTab: "UserGroup",
            userGroupToggle: true,
            userNameToggle: false,
            DepToggle: false,
            getallusersag: data.sort(function (a, b) {
              let nameA = a.GroupName.toUpperCase();
              let nameB = b.GroupName.toUpperCase();
              if (nameA < nameB) {
                return -1
              }
            }),
            getallusersag_search: data.sort(function (a, b) {
              let nameA = a.GroupName.toUpperCase();
              let nameB = b.GroupName.toUpperCase();
              if (nameA < nameB) {
                return -1
              }
            })
          })

        }

      }).catch(err => {
        toast.error('Unable To Fetch Users');
      });
      var approvalSetup_ApproverOptions=[];
      await API.post('getallusersapprover', '/getallusersapprover').then(data => {
        data.map((item, index) => {
        var currentApproverTenantGuid=item.TenantGuid;
        if(currentApproverTenantGuid.includes(localStorage.getItem('useableGuid'))){
        approvalSetup_ApproverOptions.push(item);
        }
        });
        this.setState({
          approvalSetup_ApproverOptions: approvalSetup_ApproverOptions
        });
      }).catch(err => {
        toast.error('Unable To get approve users');
      });



      this.setState({
        isLoading: false
      });
    }
  }
  approvalSetupSort = async (AdSearchTab, name) => {


    await this.setState({
      toggleCaretState: !this.state.toggleCaretState
    })
    if (this.state.toggleCaretState === false) {
      $("#" + name).removeAttr("style")
    }

    localStorage.setItem('approvalSetup_sortLastTab', AdSearchTab)
    if (AdSearchTab === 'UserGroup') {
      $('#UGCaret').show();
      $('#UserNameCaret').hide();
      $('#DepCaret').hide();

      this.setState({
        approvalSetupSelectedTab: AdSearchTab,
        userNameToggle: false,
        DepToggle: false
      })



      if (this.state.userGroupToggle) {
        await this.setState({
          userGroupToggle: false
        })
      } else {
        await this.setState({
          userGroupToggle: true
        })
      }
      if (this.state.userGroupToggle) {
        await this.setState({
          getallusersag: this.state.getallusersag.sort(function (a, b) {
            var nameA = a.GroupName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.GroupName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          getallusersag: this.state.getallusersag.sort(function (a, b) {
            var nameA = a.GroupName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.GroupName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        })
      }

    }
    if (AdSearchTab === 'UserName') {
      $('#UGCaret').hide();
      $('#UserNameCaret').show();
      $('#DepCaret').hide();

      this.setState({
        approvalSetupSelectedTab: AdSearchTab,
        userGroupToggle: false,
        DepToggle: false
      })



      if (this.state.userNameToggle) {
        await this.setState({
          userNameToggle: false
        })
      } else {
        await this.setState({
          userNameToggle: true
        })
      }
      if (this.state.userNameToggle) {
        await this.setState({
          getallusersag: this.state.getallusersag.sort(function (a, b) {
            var nameA = a.ApproverGroup[0].FirstName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.ApproverGroup[0].FirstName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          getallusersag: this.state.getallusersag.sort(function (a, b) {
            var nameA = a.ApproverGroup[0].FirstName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.ApproverGroup[0].FirstName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        })
      }

    }
    if (AdSearchTab === 'Department') {
      $('#UGCaret').hide();
      $('#UserNameCaret').hide();
      $('#DepCaret').show();

      this.setState({
        approvalSetupSelectedTab: AdSearchTab,
        userGroupToggle: false,
        userNameToggle: false
      })



      if (this.state.DepToggle) {
        await this.setState({
          DepToggle: false
        })
      } else {
        await this.setState({
          DepToggle: true
        })
      }
      if (this.state.DepToggle) {
        await this.setState({
          getallusersag: this.state.getallusersag.sort(function (a, b) {
            var nameA = a.ApproverGroup[0].Department.toUpperCase(); // ignore upper and lowercase
            var nameB = b.ApproverGroup[0].Department.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          getallusersag: this.state.getallusersag.sort(function (a, b) {
            var nameA = a.ApproverGroup[0].Department.toUpperCase(); // ignore upper and lowercase
            var nameB = b.ApproverGroup[0].Department.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        })
      }

    }
  }
  approvalSetup_SearchHandler = (event) => {
    var AdSearchTab = this.state.approvalSetupSelectedTab;
    var text = event.target.value;
    if (AdSearchTab === 'UserGroup') {
      this.setState({
        approvalSetup_search: event.target.value
      });

      this.setState({
        getallusersag: this.state.getallusersag_search.filter(function (usr) {
          return usr.GroupName.toUpperCase().includes(text.toUpperCase());
        })
      });

    }
    if (AdSearchTab === 'UserName') {
      this.setState({
        approvalSetup_search: event.target.value
      });

      this.setState({
        getallusersag: this.state.getallusersag_search.filter(function (usr) {
          return usr.ApproverGroup[0].FirstName.toUpperCase().includes(text.toUpperCase());
        })
      });

    }
    if (AdSearchTab === 'Department') {
      this.setState({
        approvalSetup_search: event.target.value
      });

      this.setState({
        getallusersag: this.state.getallusersag_search.filter(function (usr) {
          return usr.ApproverGroup[0].Department.toUpperCase().includes(text.toUpperCase());
        })
      });

    }
  }
  UserInputsChngHandlers = (event) => {
    var text = event.target.value;

    this.setState({
      approvalSetup_search: event.target.value
    });

    this.setState({
      getallusersag: this.state.getallusersag_search.filter(function (usr) {
        return usr.GroupName.toUpperCase().includes(text.toUpperCase());
      })
    });
  }


  isNumber = (evt) => {
    var iKeyCode = (evt.which) ? evt.which : evt.keyCode
    if (iKeyCode != 46 && iKeyCode > 31 && (iKeyCode < 48 || iKeyCode > 57))
      return false;

    return true;
  }

  approvalSetup_inputHandler = (e) => {
    //to only enter numbers (approvalSetup_Sequence)

    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    //approvalSetup_Sequence input field should only accept numbers
    if (name == 'approvalSetup_Sequence') {
      if (!isNaN(value)) {
        this.setState({
          [name]: value
        }, () => {
          this.approvalSetup_validateField(name, value)
        }
        );
      }
    } else {
      this.setState({
        [name]: value
      }, () => {
        this.approvalSetup_validateField(name, value)
      }
      );
    }




  }
  approvalSetup_validateField(fieldName, value) {
    let fieldValidationErrors = this.state.ApprovalSetupErrors;
    let approvalSetup_selectApproverValid = this.state.approvalSetup_selectApproverValid;
    let approvalSetup_SequenceValid = this.state.approvalSetup_SequenceValid;
    let approvalSetup_SignatureValid = this.state.approvalSetup_SignatureValid;


    switch (fieldName) {
      case 'approvalSetup_selectApprover':
        approvalSetup_selectApproverValid = this.state.approvalSetup_selectApproverValid;
        fieldValidationErrors.approvalSetup_selectApproverErr = approvalSetup_selectApproverValid ? '' : "Please Select Option";
        break;
      case 'approvalSetup_Sequence':
        approvalSetup_SequenceValid = value.length > 0;
        fieldValidationErrors.approvalSetup_SequenceErr = approvalSetup_SequenceValid ? '' : "This field is required";
        break;
      case 'approvalSetup_Signature':
        approvalSetup_SignatureValid = value.length > 0;
        fieldValidationErrors.approvalSetup_SignatureErr = approvalSetup_SignatureValid ? '' : 'This field is required';
        break;
      default:
        break;
    }

    this.setState({
      ApprovalSetupErrors: fieldValidationErrors,
      approvalSetup_selectApproverValid: approvalSetup_selectApproverValid,
      approvalSetup_SequenceValid: approvalSetup_SequenceValid,
      approvalSetup_SignatureValid: approvalSetup_SignatureValid
    }, this.validateForm);
  }
  validateForm = () => {
    this.setState({
      formValid: this.state.approvalSetup_selectApproverValid && this.state.approvalSetup_SequenceValid && this.state.approvalSetup_SignatureValid
    });
  }
  approvalSetup_selectHandler = (e) => {

    let name = e.target.name;
    let value = e.target.value;

    const ApproverGuid = $("#approvalSetup_selectApprover option:selected").attr('data-userGuid');

    this.setState({
      newApproverGuid: ApproverGuid
    })

    if (e.target.selectedIndex > 0) {
      var selectedmail=this.state.approvalSetup_ApproverOptions.find(user=>user.Guid===ApproverGuid).EmailAddress
      this.setState({
        approvalSetup_selectApproverValue: e.target.value,
        approvalSetup_selectApprover: true,
        approvalSetup_selectApproverValid: true,
        selected_approver_email:selectedmail

      },
        () => {
          this.approvalSetup_validateField(name, value)
        })
   


    }
    else if (e.target.selectedIndex === 0) {

      this.setState({

        approvalSetup_selectApproverValue: '',
        approvalSetup_selectApprover: false,
        approvalSetup_selectApproverValid: false,
        selected_approver_email:""


      },
        () => {
          this.approvalSetup_validateField(name, value)
        })
    }


  }

  approvalSetup_submitHandler = async () => {
    const userIndex = this.state.UserIndex;
    var checkingIfPresentBefore = this.state.getallusersag[userIndex].ApproverSetup.filter(item => {

      if (item.approver_sequence === this.state.approvalSetup_Sequence) {
        return true;
      }

    });
    if (checkingIfPresentBefore[0]) {
      this.setState({
        present_before: true
      })
      return;
    } else {
      this.setState({
        present_before: false
      })
      var checkingIfPresentBefore = this.state.getallusersag[userIndex].ApproverSetup.filter(item => {
        // console.log(item);
        if (item.approver_signature === this.state.approvalSetup_Signature) {
          return true;
        } else {
          return false;
        }
      });
      if (checkingIfPresentBefore[0]) {
        this.setState({
          signature_present_before: true
        })
        return;
      } else {
        this.setState({
          signature_present_before: false
        })

        if (this.state.formValid) {
          var checkingIfApproverPresentBefore = this.state.getallusersag[userIndex].ApproverSetup.filter(item => {

            if (item.approver_name === this.state.approvalSetup_selectApproverValue) {
              return true
            }

          });
          if (checkingIfApproverPresentBefore[0]) {
            this.setState({
              approvel_present_before: true
            })
            return;
          } else {
            this.setState({
              approvel_present_before: false
            })
            this.setState({
              isLoading: true
            });
            this.state.getallusersag.filter(item => {
              if (item.Guid === this.state.UserToBeUpdated.Guid) {
                item.ApproverSetup = [...item.ApproverSetup, {
                  approver_id: this.state.newApproverGuid,
                  approver_name: this.state.approvalSetup_selectApproverValue,
                  approver_sequence: this.state.approvalSetup_Sequence,
                  approver_signature: this.state.approvalSetup_Signature,
                  approver_email:this.state.selected_approver_email,
                  edit_time_card: this.state.approvalSetup_etcCheck ? this.state.approvalSetup_etcCheck : false,
                  Payslips: this.state.approvalSetup_PayslipsCheck ? this.state.approvalSetup_PayslipsCheck : false,
                  comments: this.state.approvalSetup_CommentsCheck ? this.state.approvalSetup_CommentsCheck : false,
                  exception: this.state.approvalSetup_ExceptionsCheck ? this.state.approvalSetup_ExceptionsCheck : false,
                  attachments: this.state.approvalSetup_AttachmentsCheck ? this.state.approvalSetup_AttachmentsCheck : false,
                  activity: this.state.approvalSetup_ActivityCheck ? this.state.approvalSetup_ActivityCheck : false,
                }];
                API.post("updateapproversetup", "/updateapproversetup", {
                  body: {
                    "Guid": item.Guid,
                    "ApproverSetup": item.ApproverSetup
                  }
                }).then(data => {
                  API.post("recordactivity", "/recordactivity", {
                    body: {
                      "Description": "Approval added Activity",
                      "UserName": localStorage.getItem('UserLogin'),
                      "IPAddress": ip.address(),
                      "TenantGuid": localStorage.getItem('useableGuid'),
                      "Type": "Approval added",
                      "UserGuid": localStorage.getItem('userGuid')
                    }
                  }).then(data => {
                    //toast.success('Approval added Activity Note');
                  }).catch(err => {
                    toast.error(err.message);
                  });
                  //toast.success('Approval added Successfully')
                })
                  .catch(err => {
                    this.state.getallusersag.filter(item => {
                      if (item.Guid === this.state.UserToBeUpdated.Guid) {
                        item.ApproverSetup = item.ApprovalSetup.splice(-1, 1)
                      }
                    });
                  });
              }
            });
            document.getElementById('close_modal_approval').click()
            this.setState({
              isLoading: false,
              approvalSetup_selectApproverValue: '',
              approvalSetup_Sequence: '',
              approvalSetup_Signature: '',
              selected_approver_email:'',
              approvalSetup_etcCheck: false,
              approvalSetup_PayslipsCheck: false,
              approvalSetup_CommentsCheck: false,
              approvalSetup_ExceptionsCheck: false,
              approvalSetup_AttachmentsCheck: false,
              approvalSetup_ActivityCheck: false,
              signature_present_before: false,
              present_before: false
            });
          }
        }

        let errors = this.state.ApprovalSetupErrors;

        if (!this.state.approvalSetup_selectApproverValid) {
          errors.approvalSetup_selectApproverErr = "Please Select Option"
        }
        if (!this.state.approvalSetup_SequenceValid) {
          errors.approvalSetup_SequenceErr = "This field is required"
        }
        if (!this.state.approvalSetup_SignatureValid) {
          errors.approvalSetup_SignatureErr = "This field is required"
        } this.setState({
          ApprovalSetupErrors: errors
        })
      }
    }
  }



  apporvalSetupTableChkboxHandler = (Guid, isChecked) => {
    let isAllChecked = (Guid === 'approvalSetupSelectAllChkbx' && isChecked);
    let isAllUnChecked = (Guid === 'approvalSetupSelectAllChkbx' && !isChecked);
    const checked = isChecked;

    const checkList = this.state.getallusersag.map((item, index) => {
      if (isAllChecked || item.Guid === Guid) {
        return Object.assign({}, item, {
          checked,
        })

      }
      else if (isAllUnChecked) {
        return Object.assign({}, item, {
          checked: false
        })
      }

      return item;

    })


    let isAllSelected = (checkList.findIndex(item => item.checked === false) === -1) || isAllChecked;
    if (isAllChecked) {
      let arr = [];
      checkList.map(items => {
        arr.push(items.Guid);
      });
      this.setState({
        checkedarray: arr
      });
    }
    else if (isAllUnChecked) {
      let arr = [];
      checkList.map(items => {
        const index = arr.indexOf(items.Guid);
        arr.splice(index, 1);
      });
      this.setState({
        checkedarray: arr
      });
    } else if (isChecked) {
      this.setState({ checkedarray: [...this.state.checkedarray, Guid] });
    } else if (!isChecked) {
      let arr = [];
      arr = [...this.state.checkedarray];
      const index = arr.indexOf(Guid);
      arr.splice(index, 1);
      this.setState({
        checkedarray: arr
      });
    }

    this.setState({
      isAllApprovalSetupTableChkbxSelected: isAllSelected,
      getallusersag: checkList
    })



  }

  customToggle(event) {
    var ID = event.currentTarget.id;
    ID = "#approval_setup_" + ID
    $(ID).toggle();
    $(event.currentTarget).find(".cursorPointer").toggleClass('rotate180');
  }


  addApprovalGroups = (UserToBeUpdated, key) => {
    this.setState({
      UserToBeUpdated: UserToBeUpdated,
      UserIndex: key,
      editUser: false,
      approvalSetup_selectApproverValue: '',
      approvalSetup_Sequence: '',
      approvalSetup_Signature: '',
      approvalSetup_etcCheck: false,
      approvalSetup_PayslipsCheck: false,
      approvalSetup_CommentsCheck: false,
      approvalSetup_ExceptionsCheck: false,
      approvalSetup_AttachmentsCheck: false,
      approvalSetup_ActivityCheck: false,
      signature_present_before: false,
      present_before: false,
      approvel_present_before: false
    })
  }

  deleteApproverSetup = async (approver_id, Guid, key) => {
    this.setState({
      isLoading: true
    })
    var updatedItems = this.state.getallusersag[key].ApproverSetup.filter(item => item.approver_id !== approver_id);
    await API.post("updateapproversetup", "/updateapproversetup", {
      body: {
        "Guid": Guid,
        "ApproverSetup": updatedItems
      }
    }).then(data => {
      API.post("recordactivity", "/recordactivity", {
        body: {
          "Description": "Approval delete Activity",
          "UserName": localStorage.getItem('UserLogin'),
          "IPAddress": ip.address(),
          "TenantGuid": localStorage.getItem('useableGuid'),
          "Type": "Approval delete",
          "UserGuid": localStorage.getItem('userGuid')
        }
      }).then(data => {
        //toast.success('Approval delete Activity Note');
      }).catch(err => {
        toast.error(err.message);
      });
      //toast.success('Approval deleted successfully')
    })
      .catch(err => console.log(err));

    await API.post('getallusersag', '/getallusersag').then(data => {
      this.setState({
        getallusersag: data
      })
    }).catch(err => {
      toast.error('Unable To Fetch Users');
    });
    this.setState({
      isLoading: false
    })
  }

  EditApproverSetup = async (approver_id, guid, key, UserToBeUpdated) => {
    this.setState({
      isLoading: false,
      editUser: true,
      UserIndex: key,
      UserToBeUpdated: UserToBeUpdated,
      editedApproverID: approver_id
    });
    // console.log("index " + key);
    const user = this.state.getallusersag.filter(user => user.Guid === guid);
    const approverUpdateInfo = user[0].ApproverSetup.filter(approver => approver.approver_id === approver_id);

    this.setState({
      approvalSetup_selectApproverValue: approverUpdateInfo[0].approver_name,
      approvalSetup_Sequence: approverUpdateInfo[0].approver_sequence,
      approvalSetup_Signature: approverUpdateInfo[0].approver_signature,
      approvalSetup_etcCheck: approverUpdateInfo[0].edit_time_card,
      approvalSetup_PayslipsCheck: approverUpdateInfo[0].Payslips,
      approvalSetup_CommentsCheck: approverUpdateInfo[0].comments,
      approvalSetup_ExceptionsCheck: approverUpdateInfo[0].exception,
      approvalSetup_AttachmentsCheck: approverUpdateInfo[0].attachments,
      approvalSetup_ActivityCheck: approverUpdateInfo[0].activity,
      newApproverGuid: approverUpdateInfo[0].approver_id,
      selected_approver_email:approverUpdateInfo[0].approver_email?approverUpdateInfo[0].approver_email:''
    });

  }

  updateApprovalSetup = async () => {
    const userIndex = this.state.UserIndex;
    var checkingIfPresentBefore = this.state.getallusersag[userIndex].ApproverSetup.filter(item => {


      if (item.approver_sequence === this.state.approvalSetup_Sequence && item.approver_id !== this.state.editedApproverID) {
        return true;
      }

    });

    if (checkingIfPresentBefore[0]) {
      this.setState({
        present_before: true
      })
      return;
    } else {
      this.setState({
        present_before: false
      })
      var checkingIfPresentBefore = this.state.getallusersag[userIndex].ApproverSetup.filter(item => {
        // console.log(item);
        if (item.approver_signature === this.state.approvalSetup_Signature && item.approver_id !== this.state.editedApproverID) {
          return true;
        } else {
          return false;
        }
      });
      if (checkingIfPresentBefore[0]) {
        this.setState({
          signature_present_before: true
        })
        return;
      } else {

        var checkingIfApproverPresentBefore = this.state.getallusersag[userIndex].ApproverSetup.filter(item => {

          if (item.approver_name === this.state.approvalSetup_selectApproverValue && item.approver_id !== this.state.editedApproverID) {
            return true
          }

        });
        if (checkingIfApproverPresentBefore[0]) {
          this.setState({
            approvel_present_before: true
          })
          return;
        } else {
          this.setState({
            approvel_present_before: false
          })

          this.setState({
            isLoading: true,
            signature_present_before: false
          });
          var UpdatedApproval = '';

          this.state.getallusersag.map(user => {
            if (user.Guid === this.state.UserToBeUpdated.Guid) {
              user.ApproverSetup.map(approver => {
                if (approver.approver_id === this.state.editedApproverID) {
                  approver.approver_id = this.state.newApproverGuid;
                  approver.approver_email=this.state.selected_approver_email===""?null:this.state.selected_approver_email;
                  approver.approver_name = this.state.approvalSetup_selectApproverValue;
                  approver.approver_sequence = this.state.approvalSetup_Sequence;
                  approver.approver_signature = this.state.approvalSetup_Signature;
                  approver.edit_time_card = this.state.approvalSetup_etcCheck;
                  approver.comments = this.state.approvalSetup_CommentsCheck;
                  approver.exception = this.state.approvalSetup_ExceptionsCheck;
                  approver.attachments = this.state.approvalSetup_AttachmentsCheck;
                  approver.activity = this.state.approvalSetup_ActivityCheck;
                  approver.Payslips = this.state.approvalSetup_PayslipsCheck;
                }
              });
              UpdatedApproval = user;
            }

          });


          await API.post("updateapproversetup", "/updateapproversetup", {
            body: {
              "Guid": this.state.UserToBeUpdated.Guid,
              "ApproverSetup": UpdatedApproval.ApproverSetup
            }
          }).then(data => console.log(data))
            .catch(err => console.log(err));
          await API.post('getallusersag', '/getallusersag').then(data => {
            this.setState({
              getallusersag: data
            });
            API.post("recordactivity", "/recordactivity", {
              body: {
                "Description": "Approval updated Activity",
                "UserName": localStorage.getItem('UserLogin'),
                "IPAddress": ip.address(),
                "TenantGuid": localStorage.getItem('useableGuid'),
                "Type": "Approval updated",
                "UserGuid": localStorage.getItem('userGuid')
              }
            }).then(data => {
              //toast.success('Approval updated Activity Note');
            }).catch(err => {
              toast.error(err.message);
            });
            //toast.success('Approval updated successfully');
          }).catch(err => {
            toast.error('Unable To Fetch Users');
          });
          document.getElementById('close_modal_approval').click()
          this.setState({
            isLoading: false,
            approvalSetup_selectApproverValue: '',
            approvalSetup_Sequence: '',
            approvalSetup_Signature: '',
            approvalSetup_etcCheck: false,
            approvalSetup_PayslipsCheck: false,
            approvalSetup_CommentsCheck: false,
            approvalSetup_ExceptionsCheck: false,
            approvalSetup_AttachmentsCheck: false,
            approvalSetup_ActivityCheck: false,
            signature_present_before: false,
            present_before: false,
            approvel_present_before: false,
            approvalSetup_selectApprover: false,
            editUser: false,
            editedApproverID: '',
            selected_approver_email:''
          });
        }
      }
    }
  }

  render() {
    return (
      <div>
        {this.state.isLoading ?
          <div className="se-pre-con"></div>
          : ''}

        <Header1
          pageHeader="Approval Setup"
          childProps={this.props.childProps}

        />
        <div className="clearfix"></div>
        <div className="col-lg-10 col-lg-offset-1 mrg_top_approval">

          <div className="col-sm-6 p0">
            <div className="search_area_approval">
              <input id="approvalSetup_search" name="approvalSetup_search" type="text" className="search_approval" placeholder={"Search by " + this.state.approvalSetupSelectedTab} value={this.state.approvalSetup_search} onChange={this.approvalSetup_SearchHandler} />
              <img src={SearchSvg} width="20" height="20" alt="" /></div>
          </div>




          <div className="clear10"></div>

          <div className="col-sm-12 p0">
            <div className="overflow_scroll_sm">
              <table width="100%" className="table table-bordered timecard_table approval_list_table">
                <thead>
                  <tr className="table_header_approval">
                    <th width="5%" align="center"><input id="approvalSetupSelectAllChkbx" name="approvalSetupSelectAllChkbx" type="checkbox" checked={this.state.isAllApprovalSetupTableChkbxSelected} onChange={(e) => this.apporvalSetupTableChkboxHandler('approvalSetupSelectAllChkbx', e.target.checked)} /></th>
                    <th width="10%" align="center">
                      <div className="pull-left approval_setup_mrgdetail mrg_table_th_approvalDetail"> Detail</div>
                    </th>
                    <th width="30%" align="center">
                      <div className="cursorPointer" onClick={() => this.approvalSetupSort('UserGroup', 'UGCaret')}>
                        <div className="pull-left approval_setup_mrgdetail">User Group</div>
                        <div className="approval_downarrow">
                          <svg
                            id="UGCaret" className={!this.state.userGroupToggle ? "toggleCaretClass" : ''}
                            xmlns="http://www.w3.org/2000/svg" width="12.337" height="6.887" viewBox="0 0 12.337 6.887">
                            <path id="ic_arrow_drop_down_24px" d="M7,10l6.169,6.887L19.337,10Z" transform="translate(-7 -10)" fill="#30679a" />
                          </svg>
                        </div>
                      </div>
                    </th>
                    <th width="25%" align="center">
                      <div className="cursorPointer" onClick={() => this.approvalSetupSort('UserName', 'UserNameCaret')}>
                        <div className="pull-left approval_setup_mrgdetail">User Name</div>
                        <div className="approval_downarrow">
                          <svg
                            id="UserNameCaret" className={!this.state.userNameToggle ? "toggleCaretClass" : ''}
                            xmlns="http://www.w3.org/2000/svg" width="12.337" height="6.887" viewBox="0 0 12.337 6.887">
                            <path id="ic_arrow_drop_down_24px" d="M7,10l6.169,6.887L19.337,10Z" transform="translate(-7 -10)" fill="#30679a" />
                          </svg>
                        </div>
                      </div>
                    </th>
                    <th width="20%" align="center">
                      <div className="cursorPointer" onClick={() => this.approvalSetupSort('Department', 'DepCaret')}>
                        <div className="pull-left approval_setup_mrgdetail">Department</div>
                        <div className="approval_downarrow">
                          <svg
                            id="DepCaret" className={!this.state.DepToggle ? "toggleCaretClass" : ''}
                            xmlns="http://www.w3.org/2000/svg" width="12.337" height="6.887" viewBox="0 0 12.337 6.887">
                            <path id="ic_arrow_drop_down_24px" d="M7,10l6.169,6.887L19.337,10Z" transform="translate(-7 -10)" fill="#30679a" />
                          </svg>
                        </div>
                      </div>
                    </th>
                    <th width="10%" align="center">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>

                  {this.state.getallusersag.map((allusers, key) => {
                    return (
                      <React.Fragment key={key}>
                        <tr>
                          <td align="center">
                            <input
                              type="checkbox"
                              name={allusers.Guid}
                              checked={allusers.checked}
                              onChange={(e) => this.apporvalSetupTableChkboxHandler(allusers.Guid, e.target.checked)} /></td>
                          <td align="center">< a href={null} id={key} onClick={(event) => this.customToggle(event)} className="cursorPointer">
                            <svg className="cursorPointer" xmlns="http://www.w3.org/2000/svg" width="12" height="7.41" viewBox="0 0 12 7.41">

                              <path id="ic_expand_more_24px" d="M16.59,8.59,12,13.17,7.41,8.59,6,10l6,6,6-6Z" transform="translate(-6 -8.59)" fill="#c4c4c4" />
                            </svg>
                          </a></td>
                          <td align="center">{allusers.GroupName}</td>
                          <td align="center">{allusers.ApproverGroup[0].FirstName}</td>
                          <td align="center">{allusers.ApproverGroup[0].Department}</td>
                          <td align="center">&nbsp;</td>
                        </tr>

                        <tr id={"approval_setup_" + key} className="approvel_new_open_table displayNone" >
                          <td colSpan="6">

                            <div>
                              < a href="# " onClick={this.addApprovalGroups.bind(this, allusers, key)} className="plus_approv_tb cursorPointer" data-toggle="modal" data-target="#approval_setup">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                                  <path id="ic_add_24px" d="M20,13.571H13.571V20H11.429V13.571H5V11.429h6.429V5h2.143v6.429H20Z" transform="translate(-5 -5)" fill="#7b7b7b" />
                                </svg>

                              </a>
                            </div>
                            <div className="clear5"></div>

                            <table width="100%" border="0" cellSpacing="2" cellPadding="2">
                              <thead>
                                <tr>
                                  <th width="15%" align="center">&nbsp;</th>
                                  <th width="30%" align="left">Name</th>
                                  <th width="25%" align="center" className="text_center_approvaltd">Sequence</th>
                                  <th width="20%" align="center" className="text_center_approvaltd">Signature</th>
                                  <th width="10%" align="center"></th>
                                </tr>
                              </thead>
                              <tbody>

                                {(allusers.ApproverSetup.map(item => {
                                  return (
                                    <tr key={item.approver_id}>
                                      <td align="center">&nbsp;</td>
                                      <td align="left"><div className="pdg_left_setp">{item.approver_name}</div></td>
                                      <td align="center">{item.approver_sequence}</td>
                                      <td align="center">{item.approver_signature}</td>
                                      <td align="center">

                                        <div className="col-sm-6  col-xs-6 p0 pull-right text-center timecard_dele pull-right">
                                          < a href="# " onClick={this.deleteApproverSetup.bind(this, item.approver_id, allusers.Guid, key)} className="cursorPointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="1700 296 15 19.286">

                                              <path id="ic_delete_24px" className="cls-1" d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z" transform="translate(1695 293)"></path>
                                            </svg>
                                          </a>
                                        </div>
                                        <div className="col-sm-6  col-xs-6 p0 text-center timecard_edit6 pull-right">
                                          < a href="# " className="cursorPointer" onClick={this.EditApproverSetup.bind(this, item.approver_id, allusers.Guid, key, allusers)} data-toggle="modal" data-target="#approval_setup">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="1656.776 299 17.515 18.003">
                                              <path id="ic_create_24px" className="cls-1" d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z" transform="translate(1653.776 296.002)"></path>
                                            </svg>
                                          </a>
                                        </div>



                                      </td>
                                    </tr>
                                  );
                                }))}
                              </tbody>
                            </table>

                          </td>

                        </tr>
                      </React.Fragment>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>






        </div>



        <div id="approval_setup" className="modal fade" role="dialog">
          <div className="modal-dialog pop_w_approval">


            <div className="modal-content pop_w_approval">
              <div className="modal-header approval_hdr_pop2">
                <button type="button" id='close_modal_approval' className="close close_modal_approval" data-dismiss="modal">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B" />
                  </svg>

                </button>
                <h4 className="modal-title">Approver Setup</h4>
              </div>
              <div className="modal-body">

                <div className="col-sm-12 table_attach_empl">
                  <div className="clear10"></div>
                  <div className="col-md-12 col-lg-12 p0 approval_edit_pop_input">


                    <div className="col-xs-5 col-sm-4 col-md-4 label_dataentry_approv"> Approver:</div>
                    <div className="col-xs-7 col-sm-6 col-md-6">
                      <select id="approvalSetup_selectApprover" value={this.state.approvalSetup_selectApproverValue} name="approvalSetup_selectApprover" onChange={this.approvalSetup_selectHandler}>
                        <option>Please Select One Option</option>
                        {
                          this.state.approvalSetup_ApproverOptions.map(function (item, key) {
                            return <option value={item.FirstName} data-userGuid={item.Guid} key={item.Guid}>{item.FirstName}</option>;
                          })
                        }
                      </select>
                      <p className={"error validation " + (this.state.ApprovalSetupErrors.approvalSetup_selectApproverErr ? '' : 'displayNone')}>{this.state.ApprovalSetupErrors.approvalSetup_selectApproverErr}</p>
                    </div>
                    {(this.state.approvel_present_before) ? <p style={{ color: 'red' }}>Approver already registered</p> : ''}


                    <div className="clearfix"></div>



                    <div className="col-xs-5 col-sm-4 col-md-4  label_dataentry_approv">Sequence:</div>
                    <div className="col-xs-7 col-sm-6 col-md-8"><input id="approvalSetup_Sequence" name="approvalSetup_Sequence" type="text" value={this.state.approvalSetup_Sequence} onChange={this.approvalSetup_inputHandler} />
                      {(this.state.present_before) ? <p style={{ color: 'red' }}>Sequence already exits, Try another</p> : ''}
                      <p className={"error validation " + (this.state.ApprovalSetupErrors.approvalSetup_SequenceErr ? '' : 'displayNone')}>{this.state.ApprovalSetupErrors.approvalSetup_SequenceErr}</p>
                    </div>

                    <div className="clearfix"></div>

                    <div className="col-xs-5 col-sm-4 col-md-4 label_dataentry_approv">Signature Position:</div>
                    <div className="col-xs-7 col-sm-6 col-md-8">  <input type="text" id="approvalSetup_Signature" name="approvalSetup_Signature" value={this.state.approvalSetup_Signature} onChange={this.approvalSetup_inputHandler} />
                      {(this.state.signature_present_before) ? <p style={{ color: 'red' }}>Signature already taken, Try another</p> : ''}
                      <p className={"error validation " + (this.state.ApprovalSetupErrors.approvalSetup_SignatureErr ? '' : 'displayNone')}>{this.state.ApprovalSetupErrors.approvalSetup_SignatureErr}</p>
                    </div>

                    <div className="clearfix"></div>

                    <div className="col-xs-5 col-sm-4 col-md-4  label_dataentry_approv">Edit Time Cards:</div>




                    <div className="col-xs-7 col-sm-4 col-md-8 checkbox_approval_access">
                      <div className="checkbox chkbox_issue">
                        <input id="checkbox16" name="approvalSetup_etcCheck" checked={this.state.approvalSetup_etcCheck} type="checkbox" onChange={this.approvalSetup_inputHandler} />
                        <label htmlFor="checkbox16">

                        </label>
                      </div>

                    </div>

                    <div className="clearfix"></div>


                    <div className="clear40"></div>





                  </div>










                  <div className="col-xs-12 p0 hdg_edit_detail_1">
                    <div className="col-xs-6 p0"><h4>View</h4></div>




                  </div>
                  <div className="clear10"></div>
                  <div className="col-md-12 col-lg-12 p0 approval_edit_pop_input">


                    <div className="col-xs-5 col-sm-4 label_approval_access">Payslips:</div>
                    <div className="col-xs-2 col-sm-1 checkbox_approval_access">
                      <div className="checkbox chkbox_issue">
                        <input id="checkbox1" name="approvalSetup_PayslipsCheck" checked={this.state.approvalSetup_PayslipsCheck} type="checkbox" onChange={this.approvalSetup_inputHandler} />
                        <label htmlFor="checkbox1">

                        </label>
                      </div>
                    </div>

                    <div className="clearfix"></div>

                    <div className="col-xs-5 col-sm-4 label_approval_access">Comments:</div>
                    <div className="col-xs-2 col-sm-1 checkbox_approval_access">
                      <div className="checkbox chkbox_issue">
                        <input id="checkbox2" name="approvalSetup_CommentsCheck" checked={this.state.approvalSetup_CommentsCheck} onChange={this.approvalSetup_inputHandler} type="checkbox" />
                        <label htmlFor="checkbox2">

                        </label>
                      </div>
                    </div>


                    <div className="clearfix"></div>


                    <div className="col-xs-5 col-sm-4 label_approval_access">Exceptions:</div>
                    <div className="col-xs-2 col-sm-1 checkbox_approval_access">
                      <div className="checkbox chkbox_issue">
                        <input id="checkbox3" name="approvalSetup_ExceptionsCheck" checked={this.state.approvalSetup_ExceptionsCheck} onChange={this.approvalSetup_inputHandler} type="checkbox" />
                        <label htmlFor="checkbox3">

                        </label>
                      </div>
                    </div>


                    <div className="clearfix"></div>


                    <div className="col-xs-5 col-sm-4 label_approval_access">Attachments:</div>
                    <div className="col-xs-2 col-sm-1 checkbox_approval_access">
                      <div className="checkbox chkbox_issue">
                        <input id="checkbox4" name="approvalSetup_AttachmentsCheck" checked={this.state.approvalSetup_AttachmentsCheck} onChange={this.approvalSetup_inputHandler} type="checkbox" />
                        <label htmlFor="checkbox4">

                        </label>
                      </div>
                    </div>


                    <div className="clearfix"></div>


                    <div className="col-xs-5 col-sm-4 label_approval_access">Activity:</div>
                    <div className="col-xs-2 col-sm-1 checkbox_approval_access">
                      <div className="checkbox chkbox_issue">
                        <input id="checkbox5" name="approvalSetup_ActivityCheck" checked={this.state.approvalSetup_ActivityCheck} onChange={this.approvalSetup_inputHandler} type="checkbox" />
                        <label htmlFor="checkbox5">

                        </label>
                      </div>
                    </div>


                    <div className="clearfix"></div>




                    <div className="clear10"></div>






                  </div>





                  <div className="approval_btn_save">
                    < a href="# " className="btn_save_approval cursorPointer" onClick={(this.state.editUser) ? this.updateApprovalSetup : this.approvalSetup_submitHandler}>{(this.state.editUser) ? 'Update' : 'Save'}</a>
                    < a href="# " className="btn_cancel_approval cursorPointer" data-dismiss="modal">Cancel</a>
                  </div>




                </div>




                <div className="clear10"></div>

              </div>

            </div>

          </div>
        </div>





      </div>
    )
  }
}
export default ApprovalSetup;