import React, { Component } from 'react';
import Signature from 'react-another-signature-pad';
import Header1 from '../Common/Header1/Header1';
import './User.css';
import searchSvg from '../../Assets/images/ic_search_-1.svg';
import SignatureJpg from '../../Assets/images/signture.jpg';
import Sign1Jpg from '../../Assets/images/sign1.jpg';
import { API } from "aws-amplify";
import { Auth, Storage } from "aws-amplify";
import { toast } from 'react-toastify';
import moment from 'moment';
import md5 from 'md5';
import 'moment-timezone';

import AttachIconPng from '../../Assets/images/attach_icon_user.png';
import $ from 'jquery';
const uuidv1 = require('uuid/v1');
var ip = require('ip');
export default class User extends Component {
   constructor(props) {
      super(props);
      this.state = {
         showUsers: true,
         signclear: false,
         Signa: "",
         SignatureText: "",
         lastactivedate: 0,
         clone_locked: '',
         companyids: [],
         check_user_exist: [],
         placeHolderArray: [],
         toggleCaretState: false,
         User_UserSelTab: 'UserName',
         User_UserUserName_Toggle: false,
         User_UserType_Toggle: false,
         User_UserDepartment_Toggle: false,
         User_UserEmail_Toggle: false,
         User_UserStatus_Toggle: false,
         team_values: [],

         User_AGSelTab: 'UserName',
         User_AGUserName_Toggle: false,
         User_AGGroupName_Toggle: false,
         User_AGInitials_Toggle: false,
         User_AGUserDepartment_Toggle: false,
         listTeamGroupsToView: [],






         checkedarray: [],
         isLoading: true,
         deletefromcognito: false,
         UserTableDataSearch: '',
         groupsearch: '',
         User_UserFormuserType: '',
         User_UserFormuserTypeValue: '',
         UserTable_Search: [],
         group_Search: [],
         approver_setup: [],
         present_before: false,
         signature_present_before: false,
         editApproverSetup: false,
         updatedAprrover: '',
         approversforsetup: [],
         userCreatedInCognito: false,
         userCreatedInDynamo: false,
         User_UserFormuserTypeData: [{
            _id: 1,
            userType: 'Approver'
         }, {
            _id: 2,
            userType: 'Approved'
         },],
         User_UserFormuserLogin: '',
         User_UserFormuserName: '',
         User_UserFormuserInitials: '',
         User_UserFormuserEmail: '',
         User_UserFormuserMobNo: '',
         User_UserFormuserDepartment: '',
         User_UserFormuserTeamgroup: '',
         User_UserFormuserTeamGroupToView: '',
         User_UserFromCountryCode: '',
         User_UserFormCountryCodeData: [{
            _id: 1,
            CountryCode: 'America (+93)'
         }, {
            _id: 2,
            CountryCode: 'UK (+94)'
         }, {
            _id: 3,
            CountryCode: 'America (+96)'
         },],
         User_ApproverSetup_Apporver: '',
         User_ApproverSetup_ApproverData: [],
         User_ApproverSetup_Sequence: '',
         User_ApproverSetup_SignaturePosition: '',
         User_ApproverSetup_EditTimeCard: false,
         User_ApproverSetup_Payslips: false,
         User_ApproverSetup_Comments: false,
         User_ApproverSetup_Exceptions: false,
         User_ApproverSetup_Attachments: false,
         User_ApproverSetup_Activity: false,
         User_InviteUserEmail_Cc: '',
         User_InviteUserEmail_Notes: '',
         IsUser_UserFormuserTimecardChkboxChecked: false,
         IsUser_UserFormuserEmployeeListChkboxChecked: false,
         IsUser_UserFormuserApprovalSetupChkboxChecked: false,
         IsUser_UserFormuserPayrollBatchesChkboxChecked: false,
         IsUser_UserFormuserUserChkboxChecked: false,
         User_UserFormPassword: "",
         IsUser_UserFormuserEmailTemplateChkboxChecked: false,
         User_UserFormSendReminderEveryDay: '0 Hr',

         User_UserFormSendReminderEveryDayData: [{
            _id: 1,
            day: 'Monday'
         }, {
            _id: 2,
            day: 'Tuesday'
         }, {
            _id: 3,
            day: 'Wednesday'
         }, {
            _id: 4,
            day: 'Thursday'
         }, {
            _id: 5,
            day: 'Friday'
         }, {
            _id: 6,
            day: 'Saturday'
         }, {
            _id: 7,
            day: 'Sunday'
         },],
         IsUser_UserFromAccessChkBoxChecked: false,
         User_UserFormPasswordStrength: '',
         User_UserFormPasswordStrengthData: [{
            _id: 1,
            Capable: 'Must Change'
         }, {
            _id: 2,
            Capable: 'Does Not change'
         },],
         User_UserFormuserPWDExpireDays: '',
         User_UserFormuserInActivityLockDays: '',
         User_UserFormuserPWDAccessDate: 'NA',
         User_UserFormuserPWDLockOutDate: 'NA',
         IsUserFormUserSecurityAccountDisabledChecked: false,
         IsUserFormUserSecurityAccountLockedOutChecked: false,
         IsUserFormUserSecurityAccountFactorAuthenticateChecked: false,
         IsUser_UserFormuserLoginValid: false,
         IsUser_UserFormuserNameValid: false,
         IsUser_UserFormuserInitialsValid: false,
         IsUser_UserFormuserEmailValid: false,
         IsUser_UserFormuserMobNoValid: false,
         IsUser_UserFormuserDepartmentValid: false,
         IsUser_UserFormuserTeamgroupValid: false,
         IsUser_UserFormuserTeamGroupToViewValid: false,
         IsUser_UserFormuserPWDExpireDaysValid: false,
         IsUser_UserFormuserInActivityLockDaysValid: false,
         IsUser_UserFormuserPWDAccessDateValid: true,
         IsUser_UserFormuserPWDLockOutDateValid: true,
         IsUser_UserFormuserTypeValid: false,
         IsUser_UserFormCountryCodeValid: false,
         IsUser_UserFormSendReminderEveryDayValid: false,
         IsUser_UserFormPasswordStrengthValid: false,
         IsUser_ApproverSetup_ApporverValid: false,
         IsUser_ApproverSetup_SequenceValid: false,
         IsUser_ApproverSetup_SignaturePositionValid: false,
         IsUser_InviteUserEmail_CcValid: '',
         IsUser_InviteUserEmail_NotesValid: '',
         User_UserFormErrors: {
            User_UserFormuserType: '',
            User_UserFormuserLogin: '',
            User_UserFormuserName: '',
            User_UserFormuserInitials: '',
            User_UserFormuserEmail: '',
            User_UserFormuserMobNo: '',
            User_UserFormuserDepartment: '',
            User_UserFormuserTeamgroup: '',
            User_UserFormuserTeamGroupToView: '',
            User_UserFormuserPWDExpireDays: '',
            User_UserFormuserInActivityLockDays: '',
            User_UserFormuserPWDAccessDate: '',
            User_UserFormuserPWDLockOutDate: '',
            User_UserFormCountryCode: '',
            User_UserFormSendReminderEveryDay: '',
            User_UserFormPasswordStrength: ''
         },
         User_InviteUSerEmailErros: {
            User_InviteUserEmail_Cc: '',
            User_InviteUserEmail_Notes: '',
         },
         isAllUserTableChkbxSelected: false,
         UserTableData: [],
         GroupTableData: [],
         User_ApproverSetupErrors: {
            User_ApproverSetup_Apporver: '',
            User_ApproverSetup_Sequence: '',
            User_ApproverSetup_SignaturePosition: '',
         },
         IsUser_ApprovalSetupFromValid: false,
         IsUser_UserFormValid: false,
         IsUser_InviteUserEmailFormValid: false,
         emailtemplates: [],
         AccessibleTemplates: [],
         AccessibleCompanies: [],
         Group_Name: '',
         Group_UName: '',
         Group_Initials: '',
         Group_Department: '',
         group_name_valid: false,
         group_name_error: "",
         group_form_valid: false,
         tmp_check: '',
         tmp_Guid: '',
         signature: '',
         alreadySignature: '',
         email_template_name: '',
         sendInviteTo: '',
         selectedTemplate: [],
         UPassword: '',
         lastlogin: '',
         logincount: '',
         badpwCount: '',
         pwlastchange: '',
         lastlogout: '',
         all_Tenants: [],
         dropdown_tenant_CoyID: [],
         search_Tenants: [],
         sort_UserTableData_toggle: false,
         sort_GroupTableData_toggle: false,

         cId: '' //set company(Tenant) Select Dropdown default value 
      }
   }
   componentWillMount = async () => {
      await this.getUserSettings();
      if (this.state.showUsers === false) {
         this.props.history.push('/dashboard');
      }
   }
   getUserSettings = async () => {
      let userGuid = localStorage.getItem("userGuid");
      await API.post("getuserbyguid", "/getuserbyguid", {
         body: {
            user_id: userGuid
         }
      }).then(data => {
         this.setState({
            showUsers: data.access.Users,
         });
      }).catch(err => {
         console.log('error in fetching user')
      });


   }
   async componentDidMount() {
      await this.props.childProps.isAuthentication();
      if (!this.props.childProps.isAuthenticated) {
         this.props.history.push('/login');

      }
      else {
         $("#tttt canvas").attr("width", "410");
         $("#tttt canvas").attr("height", "107");
         document.getElementById("aprover_details").style.display = 'none';

         document.getElementById("approver_group").style.display = 'none';
         $(document).click(function (e) {
            if ((e.target.className === 'modal-backdrop fade in' && $('#approval_setup').css("display") === 'block') || (e.target.className === 'modal-backdrop fade in' && $('#user_edit_signpop').css("display") === 'block') || (e.target.className === 'modal-backdrop fade in' && $('#user_search_team').css("display") === 'block')) {
               $('body').css("overflow", "hidden");
               $('#user_form').css('overflow-y', 'auto');
            } else if (e.target.className === 'modal-backdrop fade in' && $('#user_form').css("display") === 'block') {
               $('body').removeAttr('style');
            }
         });
         this.after_issueScrollHandler();
         this.get_template();
         this.listTeamGroupsToView();
         this.get_Approvergroups();
         this.get_getapprovers();
         await this.getdatatenant();
         this.get_users();
         if (localStorage.getItem('role') === 'Admin') {
            var text = localStorage.getItem('companyId');

            this.setState({
               all_Tenants: this.state.search_Tenants.filter(function (usr) {

                  return usr.CoyID.toUpperCase().includes(text.toUpperCase());
               })
            });

         }
      }

   }
   get_getapprovers = async () => {
      this.setState({
         isLoading: true,
      });
      var approvalSetup_ApproverOptions=[];
      await API.post('getallusersapprover', '/getallusersapprover').then(data => {
        data.map((item, index) => {
        var currentApproverTenantGuid=item.TenantGuid;
        if(currentApproverTenantGuid.includes(localStorage.getItem('useableGuid'))){
        approvalSetup_ApproverOptions.push(item);
        }
        }); 
          this.setState({ User_ApproverSetup_ApproverData: approvalSetup_ApproverOptions});
   });
      this.setState({
         isLoading: false,
      });
   }
   listTeamGroupsToView = async () => {
      this.setState({
         isLoading: true,
      });
      await API.post('getteams', '/getteams', {
         body: { "tenant": localStorage.getItem('useableGuid') }
      }).then(data => {
         this.setState({
            listTeamGroupsToView: data
         });
      }).catch(err => {
         toast.error(err.message);
      });
   }
   get_Approvergroups = async () => {

      this.setState({
         isLoading: true,
      });
      toast.success('Fetching Groups list...');
      await API.post('getallusersag', '/getallusersag').then(data => {
         // data.map(value => {
         //    value.FirstName ? value.FirstName.toUpperCase() : '11',
         //    value.Initials ? value.Initials.toUpperCase() : '12',
         //    value.Department ? value.Department.toUpperCase() : '13'
         // })

         data.map((value) => {

            value.ApproverGroup[0].FirstName = value.ApproverGroup[0].FirstName !== null ? value.ApproverGroup[0].FirstName.toUpperCase() : '-';
            value.ApproverGroup[0].Initials = value.ApproverGroup[0].Initials !== null ? value.ApproverGroup[0].Initials.toUpperCase() : '-';
            value.ApproverGroup[0].Department = value.ApproverGroup[0].Department !== null ? value.ApproverGroup[0].Department.toUpperCase() : '-';

         })

         let userTab = localStorage.getItem('AGTableSelTab');
         if (userTab === 'UserName') {


            $("#AGUserNameCaret").show()
            $("#AGGroupCaret").hide()
            $("#AGInitialsCaret").hide()
            $("#AGDeptCaret").hide()

            this.setState({
               User_AGSelTab: userTab,
               User_AGUserName_Toggle: true,
               User_AGGroupName_Toggle: false,
               User_AGInitials_Toggle: false,
               User_AGUserDepartment_Toggle: false,
               group_Search: data.sort(function (a, b) {
                  var nameA = a.ApproverGroup[0].FirstName.toUpperCase();// ignore upper and lowercase
                  var nameB = b.ApproverGroup[0].FirstName.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                     return -1;
                  }
               }),

               GroupTableData: data.sort(function (a, b) {
                  var nameA = a.ApproverGroup[0].FirstName.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.ApproverGroup[0].FirstName.toUpperCase();// ignore upper and lowercase
                  if (nameA < nameB) {
                     return -1;
                  }
               }),
            })


         }
         else if (userTab === 'GroupName') {


            $("#AGUserNameCaret").hide()
            $("#AGGroupCaret").show()
            $("#AGInitialsCaret").hide()
            $("#AGDeptCaret").hide()

            this.setState({
               User_AGSelTab: userTab,
               User_AGUserName_Toggle: false,
               User_AGGroupName_Toggle: true,
               User_AGInitials_Toggle: false,
               User_AGUserDepartment_Toggle: false,
               group_Search: data.sort(function (a, b) {
                  var nameA = a.GroupName.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.GroupName.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                     return -1;
                  }
               }),

               GroupTableData: data.sort(function (a, b) {
                  var nameA = a.GroupName.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.GroupName.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                     return -1;
                  }
               }),
            })


         }
         else if (userTab === 'Initials') {


            $("#AGUserNameCaret").hide()
            $("#AGGroupCaret").hide()
            $("#AGInitialsCaret").show()
            $("#AGDeptCaret").hide()

            this.setState({
               User_AGSelTab: userTab,
               User_AGUserName_Toggle: false,
               User_AGGroupName_Toggle: false,
               User_AGInitials_Toggle: true,
               User_AGUserDepartment_Toggle: false,
               group_Search: data.sort(function (a, b) {
                  var nameA = a.ApproverGroup[0].Initials.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.ApproverGroup[0].Initials.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                     return -1;
                  }
               }),

               GroupTableData: data.sort(function (a, b) {
                  var nameA = a.ApproverGroup[0].Initials.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.ApproverGroup[0].Initials.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                     return -1;
                  }
               }),
            })


         }
         else if (userTab === 'Department') {


            $("#AGUserNameCaret").hide()
            $("#AGGroupCaret").hide()
            $("#AGInitialsCaret").hide()
            $("#AGDeptCaret").show()

            this.setState({
               User_AGSelTab: userTab,
               User_AGUserName_Toggle: false,
               User_AGGroupName_Toggle: false,
               User_AGInitials_Toggle: false,
               User_AGUserDepartment_Toggle: true,
               group_Search: data.sort(function (a, b) {
                  var nameA = a.ApproverGroup[0].Department.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.ApproverGroup[0].Department.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                     return -1;
                  }
               }),

               GroupTableData: data.sort(function (a, b) {
                  var nameA = a.ApproverGroup[0].Department.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.ApproverGroup[0].Department.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                     return -1;
                  }
               }),
            })


         }
         else {


            $("#AGUserNameCaret").show()
            $("#AGGroupCaret").hide()
            $("#AGInitialsCaret").hide()
            $("#AGDeptCaret").hide()

            this.setState({
               User_AGSelTab: 'AGUserName',
               User_AGUserName_Toggle: true,
               User_AGGroupName_Toggle: false,
               User_AGInitials_Toggle: false,
               User_AGUserDepartment_Toggle: false,
               group_Search: data.sort(function (a, b) {
                  var nameA = a.ApproverGroup[0].FirstName.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.ApproverGroup[0].FirstName.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                     return -1;
                  }
               }),

               GroupTableData: data.sort(function (a, b) {
                  var nameA = a.ApproverGroup[0].FirstName.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.ApproverGroup[0].FirstName.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                     return -1;
                  }
               }),
            })

         }




      });
      this.setState({
         isLoading: false,
      });
   }

   getdatatenant = async () => {
      var unique_tenants = [];
      this.setState({
         isLoading: true
      })
      await API.post("listtenant", "/listtenant").then(data => {
         data.map(value => {
            if (unique_tenants.indexOf(value.CoyID) === -1) {
               unique_tenants.push(value.CoyID);
            }
         })
         this.setState({
            dropdown_tenant_CoyID: unique_tenants,
            all_Tenants: data,
            search_Tenants: data,
         });
      });
      this.setState({
         isLoading: false
      })

   }
   refreshtenant = async (event) => {
      var text = event.target.value;
      this.setState({
         cId: text,
         all_Tenants: this.state.search_Tenants.filter(function (usr) {
            return usr.CoyID.toUpperCase().includes(text.toUpperCase());
         })
      });
   }
   get_template = async () => {
      this.setState({
         isLoading: true,
      });

      await API.post('getemailtemplate', '/getemailtemplate', {
         body: { "tenant": localStorage.getItem('useableGuid') }
      }).then(data => {
         console.log(data);
         this.setState({
            emailtemplates: data,
         });

      }).catch(err => { console.log(err) });
      var tmp_data = [];
      this.state.emailtemplates.map(value => {
         if (value.Default) {
            tmp_data = [value];
         }
         return null;
      })
      this.setState({
         email_template_name: tmp_data.length > 0 ? tmp_data[0].Name : this.state.emailtemplates[0].Name,
         selectedTemplate: tmp_data,
         isLoading: false,
      });
   }

   get_users = async () => {

      this.setState({
         isLoading: true,
      });
      toast.success('Fetching Users list...');

      await API.post('getallusers', '/getallusers').then(data => {
         let coyid = JSON.parse(localStorage.getItem('companylist'));


         let companyList = [];
         coyid.map(val => {
            companyList.push(val.TenantGuid);
         })


         let Tempdata = [];
         data.map(item => {
            if (companyList.some(v => item.TenantGuid.indexOf(v) !== -1)) {
               Tempdata.push(item);
            }

         })
         data = Tempdata;

         data.map(value => {
            value.checked = false;
            value.NewStatus = value.UserActive !== 1 ? 'Locked Out' : value.AcctDisabled ? 'Account Disabled' : value.LoginCount === 0 ? 'Pending' : value.LoginCount > 0 ? 'Accepted' : '';
            return null;



         });



         let SortTab = localStorage.getItem('userUserTableSortTab');
         if (SortTab === 'UserName') {

            $("#UserUnCaret").show()
            $("#UserTypeCaret").hide()
            $("#UserDepartmentCaret").hide()
            $("#UserEmailCaret").hide()
            $("#UserStatusCaret").hide()

            this.setState({
               User_UserSelTab: SortTab,
               User_UserUserName_Toggle: true,
               User_UserType_Toggle: false,
               User_UserDepartment_Toggle: false,
               User_UserEmail_Toggle: false,
               User_UserStatus_Toggle: false,
               UserTable_Search: data.sort(function (a, b) {
                  let nameA = a.FirstName.toUpperCase();
                  let nameB = b.FirstName.toUpperCase();
                  if (nameA < nameB) {
                     return -1
                  }
               }),

               UserTableData: data.sort(function (a, b) {
                  let nameA = a.FirstName.toUpperCase();
                  let nameB = b.FirstName.toUpperCase();
                  if (nameA < nameB) {
                     return -1
                  }
               })

               ,
            });

         }

         else if (SortTab === 'Type') {

            $("#UserUnCaret").hide()
            $("#UserTypeCaret").show()
            $("#UserDepartmentCaret").hide()
            $("#UserEmailCaret").hide()
            $("#UserStatusCaret").hide()

            this.setState({
               User_UserSelTab: SortTab,
               User_UserUserName_Toggle: false,
               User_UserType_Toggle: true,
               User_UserDepartment_Toggle: false,
               User_UserEmail_Toggle: false,
               User_UserStatus_Toggle: false,
               UserTable_Search: data.sort(function (a, b) {
                  let nameA = a.UserType.toUpperCase();
                  let nameB = b.UserType.toUpperCase();
                  if (nameA < nameB) {
                     return -1
                  }
               }),

               UserTableData: data.sort(function (a, b) {
                  let nameA = a.UserType.toUpperCase();
                  let nameB = b.UserType.toUpperCase();
                  if (nameA < nameB) {
                     return -1
                  }
               })

               ,
            });


         }

         else if (SortTab === 'Department') {

            $("#UserUnCaret").hide()
            $("#UserTypeCaret").hide()
            $("#UserDepartmentCaret").show()
            $("#UserEmailCaret").hide()
            $("#UserStatusCaret").hide()

            this.setState({
               User_UserSelTab: SortTab,
               User_UserUserName_Toggle: false,
               User_UserType_Toggle: false,
               User_UserDepartment_Toggle: true,
               User_UserEmail_Toggle: false,
               User_UserStatus_Toggle: false,
               UserTable_Search: data.sort(function (a, b) {
                  let nameA = a.Department.toUpperCase();
                  let nameB = b.Department.toUpperCase();
                  if (nameA < nameB) {
                     return -1
                  }
               }),

               UserTableData: data.sort(function (a, b) {
                  let nameA = a.Department.toUpperCase();
                  let nameB = b.Department.toUpperCase();
                  if (nameA < nameB) {
                     return -1
                  }
               })

               ,
            });


         }
         else if (SortTab === 'Email') {

            $("#UserUnCaret").hide()
            $("#UserTypeCaret").hide()
            $("#UserDepartmentCaret").hide()
            $("#UserEmailCaret").show()
            $("#UserStatusCaret").hide()

            this.setState({
               User_UserSelTab: SortTab,
               User_UserUserName_Toggle: false,
               User_UserType_Toggle: false,
               User_UserDepartment_Toggle: false,
               User_UserEmail_Toggle: true,
               User_UserStatus_Toggle: false,
               UserTable_Search: data.sort(function (a, b) {
                  let nameA = a.EmailAddress.toUpperCase();
                  let nameB = b.EmailAddress.toUpperCase();
                  if (nameA < nameB) {
                     return -1
                  }
               }),

               UserTableData: data.sort(function (a, b) {
                  let nameA = a.EmailAddress.toUpperCase();
                  let nameB = b.EmailAddress.toUpperCase();
                  if (nameA < nameB) {
                     return -1
                  }
               })

               ,
            });


         }
         else if (SortTab === 'Status') {

            $("#UserUnCaret").hide()
            $("#UserTypeCaret").hide()
            $("#UserDepartmentCaret").hide()
            $("#UserEmailCaret").hide()
            $("#UserStatusCaret").show()

            this.setState({
               User_UserSelTab: SortTab,
               User_UserUserName_Toggle: false,
               User_UserType_Toggle: false,
               User_UserDepartment_Toggle: false,
               User_UserEmail_Toggle: false,
               User_UserStatus_Toggle: true,
               UserTable_Search: data.sort(function (a, b) {
                  let nameA = a.UserActive !== 1 ? 'Locked Out' : a.AcctDisabled ? 'Account Disabled' : a.LoginCount === 0 ? 'Pending' : a.LoginCount > 0 ? 'Accepted' : 'Pending'.toUpperCase(); // ignore upper and lowercase
                  let nameB = b.UserActive !== 1 ? 'Locked Out' : b.AcctDisabled ? 'Account Disabled' : b.LoginCount === 0 ? 'Pending' : b.LoginCount > 0 ? 'Accepted' : 'Pending'.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                     return -1
                  }
               }),

               UserTableData: data.sort(function (a, b) {
                  let nameA = a.UserActive !== 1 ? 'Locked Out' : a.AcctDisabled ? 'Account Disabled' : a.LoginCount === 0 ? 'Pending' : a.LoginCount > 0 ? 'Accepted' : 'Pending'.toUpperCase(); // ignore upper and lowercase
                  let nameB = b.UserActive !== 1 ? 'Locked Out' : b.AcctDisabled ? 'Account Disabled' : b.LoginCount === 0 ? 'Pending' : b.LoginCount > 0 ? 'Accepted' : 'Pending'.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                     return -1
                  }
               })

               ,
            });


         }
         else {
            $("#UserUnCaret").show()
            $("#UserTypeCaret").hide()
            $("#UserDepartmentCaret").hide()
            $("#UserEmailCaret").hide()
            $("#UserStatusCaret").hide()

            this.setState({
               User_UserSelTab: 'UserName',
               User_UserUserName_Toggle: true,
               User_UserType_Toggle: false,
               User_UserDepartment_Toggle: false,
               User_UserEmail_Toggle: false,
               User_UserStatus_Toggle: false,
               UserTable_Search: data.sort(function (a, b) {
                  let nameA = a.FirstName.toUpperCase();
                  let nameB = b.FirstName.toUpperCase();
                  if (nameA < nameB) {
                     return -1
                  }
               }),

               UserTableData: data.sort(function (a, b) {
                  let nameA = a.FirstName.toUpperCase();
                  let nameB = b.FirstName.toUpperCase();
                  if (nameA < nameB) {
                     return -1
                  }
               })

               ,
            });
         }

      });





      this.setState({
         isLoading: false,
      });
   }


   accessibleCompanies = async (event) => {
      var value1 = event.target.id;
      var value2 = event.target.value;
      if (event.target.checked) {

         await this.setState({
            AccessibleCompanies: [...this.state.AccessibleCompanies, event.target.id],
            companyids: [...this.state.companyids, event.target.value]
         });
      } else {



         var arr = this.state.AccessibleCompanies.filter(function (item) {
            return item !== value1
         })

         await this.setState({
            AccessibleCompanies: arr
         })


         var arr1 = this.state.companyids.filter(function (item) {
            return item !== value2
         })
         await this.setState({
            companyids: arr1
         })

      }
   }
   setSequenceValid = () => {

      this.setState({
         present_before: false,
         signature_present_before: false,
         editApproverSetup: false,
         User_ApproverSetup_Apporver: 'Select an option from below',
         User_ApproverSetup_Sequence: '',
         User_ApproverSetup_SignaturePosition: '',
         User_ApproverSetup_EditTimeCard: '',
         User_ApproverSetup_Payslips: '',
         User_ApproverSetup_Comments: '',
         User_ApproverSetup_Exceptions: '',
         User_ApproverSetup_Attachments: '',
         User_ApproverSetup_Activity: ''
      })
   }
   UserApproverlSetupUpdate = async () => {
      this.setState({
         IsUser_ApprovalSetupFromValid: true
      });
      if (this.state.IsUser_ApprovalSetupFromValid) {

         var checkingIfPresentBefore = this.state.approver_setup.map(item => {
            if (item.approver_sequence === this.state.User_ApproverSetup_Sequence) {
               return true;
            } else {
               return false;
            }
         });
         if (checkingIfPresentBefore[0]) {
            this.setState({
               present_before: true
            })
            return;
         } else {
            var checkingIfPresentBefore = this.state.approver_setup.map(item => {
               if (item.approver_signature === this.state.User_ApproverSetup_SignaturePosition) {
                  return true;
               } else {
                  return false;
               }
            });
            if (checkingIfPresentBefore[0]) {
               this.setState({
                  signature_present_before: true
               })
               return;
            } else {
               this.setState({
                  signature_present_before: false
               })
               const item = this.state.approver_setup.filter(item => {
                  if (item.approver_id === this.state.updatedAprrover.approver_id) {
                     item.approver_name = this.state.User_ApproverSetup_Apporver;
                     item.approver_sequence = this.state.User_ApproverSetup_Sequence;
                     item.approver_signature = this.state.User_ApproverSetup_SignaturePosition;
                     item.edit_time_card = this.state.User_ApproverSetup_EditTimeCard;
                     item.Payslips = this.state.User_ApproverSetup_Payslips;
                     item.comments = this.state.User_ApproverSetup_Comments;
                     item.exception = this.state.User_ApproverSetup_Exceptions;
                     item.attachments = this.state.User_ApproverSetup_Attachments;
                     item.activity = this.state.User_ApproverSetup_Activity;
                  };
                  return item;
               });
               this.state.approver_setup.map(item => {
                  if (item.approver_id === this.state.updatedAprrover.approver_id) {
                     this.setState({
                        approver_name: this.state.User_ApproverSetup_Apporver,
                        approver_sequence: this.state.User_ApproverSetup_Sequence,
                        approver_signature: this.state.User_ApproverSetup_SignaturePosition,
                        edit_time_card: this.state.User_ApproverSetup_EditTimeCard,
                        Payslips: this.state.User_ApproverSetup_Payslips,
                        comments: this.state.User_ApproverSetup_Comments,
                        exception: this.state.User_ApproverSetup_Exceptions,
                        attachments: this.state.User_ApproverSetup_Attachments,
                        activity: this.state.User_ApproverSetup_Activity
                     });
                  };
               });
               toast.success('Approval Setup Updated Successfully');
               this.issue_ScrollHandler();
               $('#approval_setup').hide();
            }
         }
      }

   }

   approverDeleteHandler = (sequence, signature) => {
      this.setState({
         approver_setup: this.state.approver_setup.filter(item => item.approver_sequence !== sequence)
      });
   }


   editClicked = (Updatedapprover) => {
      this.setState({
         editApproverSetup: true,
         updatedAprrover: Updatedapprover,
         User_ApproverSetup_Apporver: this.state.updatedAprrover.approver_name,
         User_ApproverSetup_Sequence: this.state.updatedAprrover.approver_sequence,
         User_ApproverSetup_SignaturePosition: this.state.updatedAprrover.approver_signature,
         User_ApproverSetup_EditTimeCard: this.state.updatedAprrover.edit_time_card,
         User_ApproverSetup_Payslips: this.state.updatedAprrover.Payslips,
         User_ApproverSetup_Comments: this.state.updatedAprrover.comments,
         User_ApproverSetup_Exceptions: this.state.updatedAprrover.exception,
         User_ApproverSetup_Attachments: this.state.updatedAprrover.attachments,
         User_ApproverSetup_Activity: this.state.updatedAprrover.activity
      });
   }
   UserInputsChngHandlers = (event) => {
      var text = event.target.value;

      let SelTab = this.state.User_UserSelTab;
      if (SelTab === 'UserName') {
         this.setState({
            UserTableDataSearch: event.target.value
         });
         this.setState({
            UserTableData: this.state.UserTable_Search.filter(function (usr) {

               return usr.FirstName.toUpperCase().includes(text.toUpperCase());
            })
         });
      }
      if (SelTab === 'Type') {
         this.setState({
            UserTableDataSearch: event.target.value
         });
         this.setState({
            UserTableData: this.state.UserTable_Search.filter(function (usr) {

               return usr.UserType.toUpperCase().includes(text.toUpperCase());
            })
         });
      }
      if (SelTab === 'Department') {
         this.setState({
            UserTableDataSearch: event.target.value
         });
         this.setState({
            UserTableData: this.state.UserTable_Search.filter(function (usr) {

               return usr.Department.toUpperCase().includes(text.toUpperCase());
            })
         });
      }
      if (SelTab === 'Email') {
         this.setState({
            UserTableDataSearch: event.target.value
         });
         this.setState({
            UserTableData: this.state.UserTable_Search.filter(function (usr) {

               return usr.EmailAddress.toUpperCase().includes(text.toUpperCase());
            })
         });
      }
      if (SelTab === 'Status') {
         this.setState({
            UserTableDataSearch: event.target.value
         });
         this.setState({
            UserTableData: this.state.UserTable_Search.filter(function (usr) {

               return usr.NewStatus.toUpperCase().includes(text.toUpperCase());
            })
         });
      }


   }
   GroupInputsChngHandlers = (event) => {
      let AGSelTab = this.state.User_AGSelTab;

      var text = event.target.value;
      if (AGSelTab === 'UserName') {
         this.setState({
            groupsearch: event.target.value
         });
         this.setState({
            GroupTableData: this.state.group_Search.filter(function (usr) {

               return usr.ApproverGroup[0].FirstName.toUpperCase().includes(text.toUpperCase());
            })
         });
      }

      if (AGSelTab === 'GroupName') {
         this.setState({
            groupsearch: event.target.value
         });
         this.setState({
            GroupTableData: this.state.group_Search.filter(function (usr) {

               return usr.GroupName.toUpperCase().includes(text.toUpperCase());
            })
         });
      }

      if (AGSelTab === 'Initials') {
         this.setState({
            groupsearch: event.target.value
         });
         this.setState({
            GroupTableData: this.state.group_Search.filter(function (usr) {

               return usr.ApproverGroup[0].Initials.toUpperCase().includes(text.toUpperCase());
            })
         });
      }
      if (AGSelTab === 'Department') {
         this.setState({
            groupsearch: event.target.value
         });
         this.setState({
            GroupTableData: this.state.group_Search.filter(function (usr) {

               return usr.ApproverGroup[0].Department.toUpperCase().includes(text.toUpperCase());
            })
         });
      }
   }
   issue_ScrollHandler = (e) => {
      $('body').css("overflow", "hidden");
      $('#user_form').css('overflow-y', 'auto');
   }
   after_issueScrollHandler = (e) => {
      $('body').removeAttr('style');
   }
   UserInputsChngHandler = (e) => {
      let name = e.target.name;

      let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
      this.setState({
         [name]: value
      }, this.validateFields(name, value))
   }
   validateFields(fieldName, value) {
      let User_UserFormErrors = this.state.User_UserFormErrors;
      let User_ApproverSetupErrors = this.state.User_ApproverSetupErrors;
      let User_InviteUSerEmailErros = this.state.User_InviteUSerEmailErros;
      let IsUser_UserFormuserLoginValid = this.state.IsUser_UserFormuserLoginValid;
      let IsUser_UserFormuserNameValid = this.state.IsUser_UserFormuserNameValid;
      let IsUser_UserFormuserInitialsValid = this.state.IsUser_UserFormuserInitialsValid;
      let IsUser_UserFormuserEmailValid = this.state.IsUser_UserFormuserEmailValid;
      let IsUser_UserFormuserMobNoValid = this.state.IsUser_UserFormuserMobNoValid;
      let IsUser_UserFormuserDepartmentValid = this.state.IsUser_UserFormuserDepartmentValid;
      let IsUser_UserFormuserTeamgroupValid = this.state.IsUser_UserFormuserTeamgroupValid;
      let IsUser_UserFormuserTeamGroupToViewValid = this.state.IsUser_UserFormuserTeamGroupToViewValid;
      let IsUser_UserFormuserPWDExpireDaysValid = this.state.IsUser_UserFormuserPWDExpireDaysValid;
      let IsUser_UserFormuserInActivityLockDaysValid = this.state.IsUser_UserFormuserInActivityLockDaysValid;
      let IsUser_UserFormuserPWDAccessDateValid = this.state.IsUser_UserFormuserPWDAccessDateValid;
      let IsUser_UserFormuserPWDLockOutDateValid = this.state.IsUser_UserFormuserPWDLockOutDateValid;
      let IsUser_UserFormuserTypeValid = this.state.IsUser_UserFormuserTypeValid;
      let IsUser_UserFormCountryCodeValid = this.state.IsUser_UserFormCountryCodeValid;
      let IsUser_UserFormSendReminderEveryDayValid = this.state.IsUser_UserFormSendReminderEveryDayValid;
      let IsUser_UserFormPasswordStrengthValid = this.state.IsUser_UserFormPasswordStrengthValid;
      let IsUser_ApproverSetup_ApporverValid = this.state.IsUser_ApproverSetup_ApporverValid;
      let IsUser_ApproverSetup_SequenceValid = this.state.IsUser_ApproverSetup_SequenceValid;
      let IsUser_ApproverSetup_SignaturePositionValid = this.state.IsUser_ApproverSetup_SignaturePositionValid;
      let IsUser_InviteUserEmail_CcValid = this.state.IsUser_InviteUserEmail_CcValid;
      let IsUser_InviteUserEmail_NotesValid = this.state.IsUser_InviteUserEmail_NotesValid;
      switch (fieldName) {
         case 'User_UserFormuserType':
            IsUser_UserFormuserTypeValid = this.state.IsUser_UserFormuserTypeValid;
            User_UserFormErrors.User_UserFormuserType = IsUser_UserFormuserTypeValid ? '' : "Please select one option";
            break;
         case 'User_UserFormuserLogin':
            IsUser_UserFormuserLoginValid = value.length > 0;
            User_UserFormErrors.User_UserFormuserLogin = IsUser_UserFormuserLoginValid ? '' : "This field is required";
            break;
         case 'User_UserFormuserName':
            IsUser_UserFormuserNameValid = value.length > 0;
            User_UserFormErrors.User_UserFormuserName = IsUser_UserFormuserNameValid ? '' : "This field is required";
            break;
         case 'User_UserFormuserInitials':
            IsUser_UserFormuserInitialsValid = value.length > 0;
            User_UserFormErrors.User_UserFormuserInitials = IsUser_UserFormuserInitialsValid ? '' : "This field is required";
            break;
         case 'User_UserFormuserEmail':
            IsUser_UserFormuserEmailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            User_UserFormErrors.User_UserFormuserEmail = IsUser_UserFormuserEmailValid ? '' : "Email Should be valid like user@mail.com";
            break;
         case 'User_UserFormuserMobNo':
            IsUser_UserFormuserMobNoValid = value.match(/^\+?\d+$/);
            User_UserFormErrors.User_UserFormuserMobNo = IsUser_UserFormuserMobNoValid ? '' : "Format should be (+)1234567890";
            break;
         case 'User_UserFormuserDepartment':
            IsUser_UserFormuserDepartmentValid = value.length > 0;
            User_UserFormErrors.User_UserFormuserDepartment = IsUser_UserFormuserDepartmentValid ? '' : "This field is required";
            break;
         case 'User_UserFormCountryCode':
            IsUser_UserFormCountryCodeValid = this.state.IsUser_UserFormCountryCodeValid;
            User_UserFormErrors.User_UserFormCountryCode = IsUser_UserFormCountryCodeValid ? '' : "Please select one option";
            break;
         case 'User_ApproverSetup_Apporver':
            IsUser_ApproverSetup_ApporverValid = this.state.IsUser_ApproverSetup_ApporverValid;
            User_ApproverSetupErrors.User_ApproverSetup_Apporver = IsUser_ApproverSetup_ApporverValid ? '' : "Please select one option";
            break;
         case 'User_ApproverSetup_Sequence':
            IsUser_ApproverSetup_SequenceValid = value.length > 0;
            User_ApproverSetupErrors.User_ApproverSetup_Sequence = IsUser_ApproverSetup_SequenceValid ? '' : "This field is required";
            break;
         case 'User_ApproverSetup_SignaturePosition':
            IsUser_ApproverSetup_SignaturePositionValid = value.length > 0;
            User_ApproverSetupErrors.User_ApproverSetup_SignaturePosition = IsUser_ApproverSetup_SignaturePositionValid ? '' : "This field is required";
            break;

         default:
            break;
      }
      this.setState({
         User_UserFormErrors: User_UserFormErrors,
         IsUser_UserFormuserLoginValid: IsUser_UserFormuserLoginValid,
         IsUser_UserFormuserNameValid: IsUser_UserFormuserNameValid,
         IsUser_UserFormuserInitialsValid: IsUser_UserFormuserInitialsValid,
         IsUser_UserFormuserEmailValid: IsUser_UserFormuserEmailValid,
         IsUser_UserFormuserMobNoValid: IsUser_UserFormuserMobNoValid,
         IsUser_UserFormuserDepartmentValid: IsUser_UserFormuserDepartmentValid,
         IsUser_UserFormuserTeamgroupValid: IsUser_UserFormuserTeamgroupValid,
         IsUser_UserFormuserTeamGroupToViewValid: IsUser_UserFormuserTeamGroupToViewValid,
         IsUser_UserFormuserPWDExpireDaysValid: IsUser_UserFormuserPWDExpireDaysValid,
         IsUser_UserFormuserInActivityLockDaysValid: IsUser_UserFormuserInActivityLockDaysValid,
         IsUser_UserFormuserPWDAccessDateValid: IsUser_UserFormuserPWDAccessDateValid,
         IsUser_UserFormuserPWDLockOutDateValid: IsUser_UserFormuserPWDLockOutDateValid,
         IsUser_UserFormuserTypeValid: IsUser_UserFormuserTypeValid,
         IsUser_UserFormCountryCodeValid: IsUser_UserFormCountryCodeValid,
         IsUser_UserFormSendReminderEveryDayValid: IsUser_UserFormSendReminderEveryDayValid,
         IsUser_UserFormPasswordStrengthValid: IsUser_UserFormPasswordStrengthValid,
         IsUser_ApproverSetup_SequenceValid: IsUser_ApproverSetup_SequenceValid,
         IsUser_ApproverSetup_ApporverValid: IsUser_ApproverSetup_ApporverValid,
         IsUser_ApproverSetup_SignaturePositionValid: IsUser_ApproverSetup_SignaturePositionValid,
         User_ApproverSetupErrors: User_ApproverSetupErrors,
         User_InviteUSerEmailErros: User_InviteUSerEmailErros,
         IsUser_InviteUserEmail_CcValid: IsUser_InviteUserEmail_CcValid,
         IsUser_InviteUserEmail_NotesValid: IsUser_InviteUserEmail_NotesValid
      })
   }
   handleCustomFieldsModalInputsCountry(event) {
      let name = event.target.name;
      let value = event.target.value;
      this.setState({
         IsUser_UserFormCountryCodeValid: true,
         User_UserFormuserMobNo: '+' + value
      });
      this.setState({
         [name]: value
      },
         () => {
            this.validateFields(name, value)
         });
   }
   UserUserFormSelectOptHandlers = async (e) => {
      let name = e.target.name;
      let value = e.target.value;
      var x = document.getElementById("aprover_details");
      var other_user = document.getElementById("other_user");
      var group = document.getElementById("approver_group");



      if (e.target.name === 'User_UserFormuserType') {
         if (value === 'Approver' || value === 'Operator/Approver') {
            x.style.display = "block";

         } else {
            x.style.display = "none";

         }


         if (value === 'Approver Group') {

            group.style.display = "block";
            other_user.style.display = "none";

         } else {
            group.style.display = "none";
            other_user.style.display = "block";

         }
         if (e.target.selectedIndex > 0) {
            this.setState({
               User_UserFormuserTypeValue: e.target.value,
               IsUser_UserFormuserTypeValid: true,
            }, () => {
               this.validateFields(name, value)
            })
         } else if (e.target.selectedIndex === 0) {
            this.setState({
               User_UserFormuserTypeValue: '',
               IsUser_UserFormuserTypeValid: false,
            }, () => {
               this.validateFields(name, value)
            })
         }
      }
      if (e.target.name === 'User_UserFormCountryCode') {
         if (e.target.selectedIndex > 0) {
            this.setState({
               User_UserFormCountryCode: e.target.value,
               IsUser_UserFormCountryCodeValid: true,
            },
               () => {
                  this.validateFields(name, value)
               })
         } else if (e.target.selectedIndex === 0) {
            this.setState({
               User_UserFormCountryCode: '',
               IsUser_UserFormCountryCodeValid: false,
            }, () => {
               this.validateFields(name, value)
            })
         }
      }
      if (e.target.name === 'User_UserFormSendReminderEveryDay') {
         if (e.target.selectedIndex > 0) {
            this.setState({
               User_UserFormSendReminderEveryDay: e.target.value,
               IsUser_UserFormSendReminderEveryDayValid: true,
            }, () => {
               this.validateFields(name, value)
            })
         } else if (e.target.selectedIndex === 0) {
            this.setState({
               User_UserFormSendReminderEveryDay: '',
               IsUser_UserFormSendReminderEveryDayValid: false,
            }, () => {
               this.validateFields(name, value)
            })
         }
      }
      if (e.target.name === 'User_UserFormPasswordStrength') {
         if (e.target.selectedIndex > 0) {
            this.setState({
               User_UserFormPasswordStrength: e.target.value,
               IsUser_UserFormPasswordStrengthValid: true,
            }, () => {
               this.validateFields(name, value)
            })
         } else if (e.target.selectedIndex === 0) {
            this.setState({
               User_UserFormPasswordStrength: '',
               IsUser_UserFormPasswordStrengthValid: false,
            }, () => {
               this.validateFields(name, value)
            })
         }
      }
      if (e.target.name === 'User_ApproverSetup_Apporver') {
         if (e.target.selectedIndex > 0) {
            this.setState({
               User_ApproverSetup_Apporver: e.target.value,
               IsUser_ApproverSetup_ApporverValid: true,
            }, () => {
               this.validateFields(name, value)
            })
         } else if (e.target.selectedIndex === 0) {
            this.setState({
               User_ApproverSetup_Apporver: '',
               IsUser_ApproverSetup_ApporverValid: false,
            }, () => {
               this.validateFields(name, value)
            })
         }
      }
      if (e.target.name === 'User_UserFormPassword') {
         if (e.target.selectedIndex > 0) {
            this.setState({
               User_UserFormPassword: e.target.value,
            })
         } else if (e.target.selectedIndex === 0) {
            this.setState({
               User_UserFormPassword: '',

            })
         }
      }

   }
   UserApprovalSetupSave = async () => {
      let ApproverFormvalid = this.state.IsUser_ApprovalSetupFromValid;
      let User_ApproverSetupErrors = this.state.User_ApproverSetupErrors;
      let IsUser_ApproverSetup_ApporverValid = this.state.IsUser_ApproverSetup_ApporverValid;
      let IsUser_ApproverSetup_SequenceValid = this.state.IsUser_ApproverSetup_SequenceValid;
      let IsUser_ApproverSetup_SignaturePositionValid = this.state.IsUser_ApproverSetup_SignaturePositionValid;
      if (!IsUser_ApproverSetup_ApporverValid) {
         User_ApproverSetupErrors.User_ApproverSetup_Apporver = 'Please select one option';
      }
      if (!IsUser_ApproverSetup_SequenceValid) {
         User_ApproverSetupErrors.User_ApproverSetup_Sequence = 'This field is required';
      }
      if (!IsUser_ApproverSetup_SignaturePositionValid) {
         User_ApproverSetupErrors.User_ApproverSetup_SignaturePosition = 'This field is required';
      }
      ApproverFormvalid = this.state.IsUser_ApproverSetup_ApporverValid && this.state.IsUser_ApproverSetup_SequenceValid && this.state.IsUser_ApproverSetup_SignaturePositionValid;
      await this.setState({
         IsUser_ApprovalSetupFromValid: ApproverFormvalid,
         User_ApproverSetupErrors: User_ApproverSetupErrors
      });
      if (this.state.IsUser_ApprovalSetupFromValid) {

         var checkingIfPresentBefore = this.state.approver_setup.map(item => {
            if (item.approver_sequence === this.state.User_ApproverSetup_Sequence) {
               return true;
            } else {
               return false;
            }
         });
         if (checkingIfPresentBefore[0]) {
            this.setState({
               present_before: true
            })
            return;
         } else {
            var checkingIfPresentBefore = this.state.approver_setup.map(item => {
               if (item.approver_signature === this.state.User_ApproverSetup_SignaturePosition) {
                  return true;
               } else {
                  return false;
               }
            });
            if (checkingIfPresentBefore[0]) {
               this.setState({
                  signature_present_before: true
               })
               return;
            } else {
               this.setState({
                  signature_present_before: false
               })
               this.setState({
                  isLoading: true
               });
               var approver_setup_local = {
                  approver_id: uuidv1(),
                  approver_name: this.state.User_ApproverSetup_Apporver,
                  approver_sequence: this.state.User_ApproverSetup_Sequence,
                  approver_signature: this.state.User_ApproverSetup_SignaturePosition,
                  edit_time_card: this.state.User_ApproverSetup_EditTimeCard,
                  Payslips: this.state.User_ApproverSetup_Payslips,
                  comments: this.state.User_ApproverSetup_Comments,
                  exception: this.state.User_ApproverSetup_Exceptions,
                  attachments: this.state.User_ApproverSetup_Attachments,
                  activity: this.state.User_ApproverSetup_Activity,
               };

               this.setState({
                  approver_setup: [...this.state.approver_setup, approver_setup_local]
               });

               toast.success('Approval Setup Added Successfully');
               this.setState({
                  isLoading: false,
                  IsUser_ApproverSetup_ApporverValid: false,
                  IsUser_ApproverSetup_SequenceValid: false,
                  IsUser_ApproverSetup_SignaturePositionValid: false,
                  User_ApproverSetup_Payslips: false,
                  User_ApproverSetup_Comments: false,
                  User_ApproverSetup_Activity: false,
                  User_ApproverSetup_Exceptions: false,
                  User_ApproverSetup_Attachments: false,
                  User_ApproverSetup_Sequence: '',
                  User_ApproverSetup_SignaturePosition: '',
                  User_ApproverSetup_Apporver: '',
                  User_ApproverSetup_EditTimeCard: false,

               });
               this.issue_ScrollHandler();
               $('#approval_setup').hide();
            }
         }
      }
   }
   randomNumber() {
      var generator = require('generate-password');
      return generator.generate({
         length: 22,
         numbers: true,
         symbols: true,
         strict: true,
         exclude: '"[$&+,:;=?#|<>.-^*()%!~`'
      });
   }
   onUserFormOpen = async () => {
      let companyId = localStorage.getItem('companyId') ? localStorage.getItem('companyId') : ''; //ESA etc
      let completetenant = localStorage.getItem('completetenant') //it contains productions against company id
      let completetenantJSON = JSON.parse(completetenant);
      let tenantGuid = completetenantJSON ? completetenantJSON.TenantGuid : ''; //TenantGuid of a production

      //set state when create user Modal open then Tenant is Selected by default whatever its value saved in localstorage
      //and also checked the Productions related to that company id
      await this.setState({
         cId: companyId,  //set company(Tenant) dropdown selects default value 
         all_Tenants: this.state.search_Tenants.filter(function (usr) { //getting all productions against company id
            return usr.CoyID.toUpperCase().includes(companyId.toUpperCase());
         }),
         AccessibleCompanies: [...this.state.AccessibleCompanies, tenantGuid],
         companyids: [...this.state.companyids, companyId]
      });
   }
   UserUserFormSaveHandler = async () => {
      if (this.state.tmp_Guid !== '') {

         this.setState({
            isLoading: true
         })
         var lastdat = this.state.lastactivedate;
         if (this.state.clone_locked === true && moment() > moment(this.state.lastactivedate).add(parseInt(this.state.User_UserFormuserInActivityLockDays), 'days')) {
            if (this.state.IsUserFormUserSecurityAccountLockedOutChecked !== true) {
               lastdat = Date.now();
            }
         } else {
            lastdat = this.state.lastactivedate;
         }


         await API.post("checkemailuser", "/checkemailuser", {
            body: {
               "user_email": this.state.User_UserFormuserEmail
            }
         }).then(result => {
            this.setState({
               check_user_exist: result
            });
         }).catch(error => {
            toast.error(error.message);
         });


         if (this.state.check_user_exist.length !== 0) {

            await API.post("createuser", "/createuser", {
               body: {
                  "CompanyID": this.state.companyids,
                  "Guid": this.state.tmp_Guid,
                  "AccessibleCompanies": this.state.AccessibleCompanies,
                  "lastactive": lastdat,
                  "LoginCount": this.state.logincount,
                  "LastLogin": this.state.lastlogin,
                  "LastLogout": this.state.lastlogout,
                  "Password": this.state.UPassword !== ''? this.state.UPassword : null,
                  "WrongPWCount": this.state.badpwCount,
                  "PWLastChanged": this.state.pwlastchange,
                  "countryCode": this.state.User_UserFormCountryCode,
                  "PWStrength": this.state.User_UserFormPasswordStrength !== '' ? this.state.User_UserFormPasswordStrength : null,
                  "User_UserFormPassword_change": this.state.User_UserFormPassword !== '' ? this.state.User_UserFormPassword : null,
                  "PWExpireDays": this.state.User_UserFormuserPWDExpireDays !== '' ? this.state.User_UserFormuserPWDExpireDays : null,
                  "AcctDisabled": this.state.IsUserFormUserSecurityAccountDisabledChecked,
                  "AcctLockedout": this.state.IsUserFormUserSecurityAccountLockedOutChecked,
                  "FactorAuthenticate": this.state.IsUserFormUserSecurityAccountFactorAuthenticateChecked,
                  "InactivityLockoutDays": this.state.User_UserFormuserInActivityLockDays !== '' ? this.state.User_UserFormuserInActivityLockDays : null,
                  "StartAccess": this.state.User_UserFormuserPWDAccessDate,
                  "EndAccess": this.state.User_UserFormuserPWDLockOutDate,
                  "access": {
                     "Timecards": this.state.IsUser_UserFormuserTimecardChkboxChecked,
                     "EmployeeList": this.state.IsUser_UserFormuserEmployeeListChkboxChecked,
                     "ApprovalSetup": this.state.IsUser_UserFormuserApprovalSetupChkboxChecked,
                     "PayrollBatch": this.state.IsUser_UserFormuserPayrollBatchesChkboxChecked,
                     "Users": this.state.IsUser_UserFormuserUserChkboxChecked,
                     "EmailTamplate": this.state.IsUser_UserFormuserEmailTemplateChkboxChecked,
                     "AccessibleTemplates": this.state.AccessibleTemplates
                  },
                  "ApproverDetails": { "UserFormSendReminderEveryDay": this.state.User_UserFormSendReminderEveryDay },
                  "UserType": this.state.User_UserFormuserTypeValue,
                  "UserLogin": this.state.User_UserFormuserLogin,
                  "FirstName": this.state.User_UserFormuserName,
                  "Initials": this.state.User_UserFormuserInitials,
                  "EmailAddress": this.state.User_UserFormuserEmail,

                  "MobileNumber": this.state.User_UserFormuserMobNo,
                  "Department": this.state.User_UserFormuserDepartment,
                  "TeamGroup": this.state.User_UserFormuserTeamgroup !== '' ? this.state.User_UserFormuserTeamgroup : null,
                  "TeamList": this.state.User_UserFormuserTeamGroupToView !== '' ? this.state.User_UserFormuserTeamGroupToView : null,
                  "ApproverSetup": this.state.approver_setup



               }
            }).then(data => {
               API.post("recordactivity", "/recordactivity", {
                  body: {
                     "Description": "User Update Activity",
                     "UserName": localStorage.getItem('UserLogin'),
                     "IPAddress": ip.address(),
                     "TenantGuid": localStorage.getItem('useableGuid'),
                     "Type": "User Update",
                     "UserGuid": localStorage.getItem('userGuid')
                  }
               }).then(data => {
                  toast.success('User Update Activity Note');
               }).catch(err => {
                  toast.error(err.message);
               });
               toast.success('User Updated in dynamo');
               $('#add_user_close').click();
            }).catch(err => {


               toast.error('User not Updated in dynamo');
            });
         }
         else {
            toast.error('User with given email doesnot exit');
         }
         await this.props.childProps.getUserSettings()
         this.setState({
            isLoading: false
         })
      } else {



         let User_UserFormErrors = this.state.User_UserFormErrors;
         let IsUser_UserFormValid = this.state.IsUser_UserFormValid;
         let IsUser_UserFormuserLoginValid = this.state.IsUser_UserFormuserLoginValid;
         let IsUser_UserFormuserNameValid = this.state.IsUser_UserFormuserNameValid;
         let IsUser_UserFormuserInitialsValid = this.state.IsUser_UserFormuserInitialsValid;
         let IsUser_UserFormuserEmailValid = this.state.IsUser_UserFormuserEmailValid;
         let IsUser_UserFormuserMobNoValid = this.state.IsUser_UserFormuserMobNoValid;
         let IsUser_UserFormuserDepartmentValid = this.state.IsUser_UserFormuserDepartmentValid;
         let IsUser_UserFormuserTeamgroupValid = this.state.IsUser_UserFormuserTeamgroupValid;
         let IsUser_UserFormuserTeamGroupToViewValid = this.state.IsUser_UserFormuserTeamGroupToViewValid;
         let IsUser_UserFormuserTypeValid = this.state.IsUser_UserFormuserTypeValid;
         let IsUser_UserFormCountryCodeValid = this.state.IsUser_UserFormCountryCodeValid;
         let IsUser_UserFormSendReminderEveryDayValid = this.state.IsUser_UserFormSendReminderEveryDayValid;
         let IsUser_UserFormPasswordStrengthValid = this.state.IsUser_UserFormPasswordStrengthValid;
         if (!IsUser_UserFormuserLoginValid) {
            User_UserFormErrors.User_UserFormuserLogin = 'This field is required';
         }
         if (!IsUser_UserFormuserNameValid) {
            User_UserFormErrors.User_UserFormuserName = 'This field is required';
         }
         if (!IsUser_UserFormuserInitialsValid) {
            User_UserFormErrors.User_UserFormuserInitials = 'This field is required';
         }
         if (!IsUser_UserFormuserEmailValid) {
            User_UserFormErrors.User_UserFormuserEmail = 'Email is not valid';
         }
         if (!IsUser_UserFormuserMobNoValid) {
            User_UserFormErrors.User_UserFormuserMobNo = "Format should be (+)1234567890";
         }
         if (!IsUser_UserFormuserDepartmentValid) {
            User_UserFormErrors.User_UserFormuserDepartment = 'This field is required';
         }
         if (!IsUser_UserFormuserTypeValid) {
            User_UserFormErrors.User_UserFormuserType = 'Please select one option';
         }
         if (!IsUser_UserFormCountryCodeValid) {
            User_UserFormErrors.User_UserFormCountryCode = 'Please select one option';
         }
         await this.setState({
            IsUser_UserFormValid: this.state.IsUser_UserFormuserLoginValid && this.state.IsUser_UserFormuserNameValid && this.state.IsUser_UserFormuserInitialsValid && this.state.IsUser_UserFormuserEmailValid && this.state.IsUser_UserFormuserMobNoValid && this.state.IsUser_UserFormuserDepartmentValid && this.state.IsUser_UserFormuserTypeValid && this.state.IsUser_UserFormCountryCodeValid,
            User_UserFormErrors: User_UserFormErrors
         })
         if (this.state.IsUser_UserFormValid) {

            await API.post("checkemailuser", "/checkemailuser", {
               body: {
                  "user_email": this.state.User_UserFormuserEmail
               }
            }).then(result => {
               this.setState({
                  check_user_exist: result
               });
            }).catch(error => {
               toast.error(error.message);
            });




            this.setState({
               isLoading: true,
            });
            if (this.state.check_user_exist.length === 0) {
               this.after_issueScrollHandler();
               $('user_from').hide();

               var username = this.state.User_UserFormuserEmail;
               let password = this.randomNumber();







               await API.post("createuser", "/createuser", {
                  body: {
                     "Avatar": "aws.s3.com/JGHJHGJGJ/kk.png",
                     "BadPWCount": 0,
                     "lastactive": Date.now(),
                     "CompanyID": this.state.companyids,
                     "EULA": 0,
                     "InviteDate": "07MAY2018",
                     "InviteStatus": "Pending",
                     "IPList": [
                        "204.567.876"
                     ],
                     "Guid": '',

                     "LastName": 'lll',
                     "LoginCount": 0,
                     "LastLogout": 0,
                     "MoileDeviceTokens": "Nil",
                     "Password": "",
                     "Poolid": "us-east-1:1c4c11b9-6f5e-4b0b-8943-985ac87ad578",
                     "profile_pic": "head-659651_960_720.png",
                     PWCannotChange: 0,
                     "PWHistory": [
                        "hgjugjugjuig",
                        "khiuhdiqwuhd"
                     ],
                     "PWLastChanged": 0,
                     "PWMustChange": 0,
                     "PWNeverExp": 0,
                     "Reminder": 1,
                     "Salt": "JVUYVGUVUYVBUBV",
                     "SignatureFile": "aws.s3.com/JGHJHGJGJ/kkruger.jpg",
                     "UserActive": 1,
                     "WrongPWCount": 0,
                     "countryCode": this.state.User_UserFormCountryCode,
                     "User_UserFormPassword_change": this.state.User_UserFormPassword !== '' ? this.state.User_UserFormPassword : null,
                     "PWStrength": this.state.User_UserFormPasswordStrength !== '' ? this.state.User_UserFormPasswordStrength : null,
                     "PWExpireDays": this.state.User_UserFormuserPWDExpireDays !== '' ? this.state.User_UserFormuserPWDExpireDays : null,
                     "AcctDisabled": this.state.IsUserFormUserSecurityAccountDisabledChecked,
                     "AcctLockedout": this.state.IsUserFormUserSecurityAccountLockedOutChecked,
                     "FactorAuthenticate": this.state.IsUserFormUserSecurityAccountFactorAuthenticateChecked,
                     "InactivityLockoutDays": this.state.User_UserFormuserInActivityLockDays !== '' ? this.state.User_UserFormuserInActivityLockDays : null,
                     "StartAccess": this.state.User_UserFormuserPWDAccessDate,
                     "EndAccess": this.state.User_UserFormuserPWDLockOutDate,
                     "access": {
                        "Timecards": this.state.IsUser_UserFormuserTimecardChkboxChecked,
                        "EmployeeList": this.state.IsUser_UserFormuserEmployeeListChkboxChecked,
                        "ApprovalSetup": this.state.IsUser_UserFormuserApprovalSetupChkboxChecked,
                        "PayrollBatch": this.state.IsUser_UserFormuserPayrollBatchesChkboxChecked,
                        "Users": this.state.IsUser_UserFormuserUserChkboxChecked,
                        "EmailTamplate": this.state.IsUser_UserFormuserEmailTemplateChkboxChecked,
                        "AccessibleTemplates": this.state.AccessibleTemplates
                     },
                     "AccessibleCompanies": this.state.AccessibleCompanies,
                     "ApproverDetails": { "UserFormSendReminderEveryDay": this.state.User_UserFormSendReminderEveryDay },
                     "UserType": this.state.User_UserFormuserTypeValue,
                     "UserLogin": this.state.User_UserFormuserLogin,
                     "FirstName": this.state.User_UserFormuserName,
                     "Initials": this.state.User_UserFormuserInitials,
                     "EmailAddress": this.state.User_UserFormuserEmail,

                     "MobileNumber": this.state.User_UserFormuserMobNo,
                     "Department": this.state.User_UserFormuserDepartment,
                     "TeamGroup": this.state.User_UserFormuserTeamgroup !== '' ? this.state.User_UserFormuserTeamgroup : null,
                     "TeamList": this.state.User_UserFormuserTeamGroupToView !== '' ? this.state.User_UserFormuserTeamGroupToView : null,
                     "ApproverSetup": this.state.approver_setup,



                  }
               }).then(data => {

                  this.setState({
                     userCreatedInDynamo: true
                  });
                  toast.success('User created in dynamo');

               }).catch(err => {
                  this.setState({
                     userCreatedInDynamo: false
                  });
                  toast.error('User not created in dynamo');
               });

               if (this.state.userCreatedInDynamo === false) {

               } else {
                  await API.post("recordactivity", "/recordactivity", {
                     body: {
                        "Description": "User Created Activity",
                        "UserName": localStorage.getItem('UserLogin'),
                        "IPAddress": ip.address(),
                        "TenantGuid": localStorage.getItem('useableGuid'),
                        "Type": "User Create",
                        "UserGuid": localStorage.getItem('userGuid')
                     }
                  }).then(data => {
                     toast.success('User Created Activity Note');
                  }).catch(err => {
                     toast.error(err.message);
                  });


               }
               await this.props.childProps.getUserSettings()
               $('#add_user_close').click();
            } else {
               toast.error('User With This Email already exist');
            }
         }
      }
      this.get_users();

      this.setState({
         isLoading: false,
      });
   }
   setEmailAddressToSendMail = async (item) => {
      await this.setState({
         sendInviteTo: item,

         User_InviteUserEmail_Cc: '',
         User_InviteUserEmail_Notes: ''
      });
   }
   email_template_name_change = async (e) => {
      this.setState({
         [e.target.name]: e.target.value
      });

      var template = this.state.emailtemplates.filter(item => item.Name === e.target.value);
      await this.setState({
         selectedTemplate: template
      });
   }

   replacePlaceHodler = async (message, to, password) => {
      let word = /\{{(.*?)\}}/g;
      let tempArray = [];
      let responceplaceHolder = "";
      let user = [];
      console.log(user)
      await API.post("getuserbyemail", "/getuserbyemail", {
         body: {
            "email": to,
         }
      }).then(data => { user = data; })
         .catch(err => toast.error('Problem in sending email'));
      user[0].Password = password;


      for (let m = word.exec(message); m; m = word.exec(message)) {
         this.state.placeHolderArray.push(m[1]);
      }
      await API.post("placeholdersbyname", "/placeholdersbyname", {
         body: {
            "placeholder": this.state.placeHolderArray,
         }
      }).then(data => { responceplaceHolder = data; console.log('responceplaceHolder', responceplaceHolder);
      console.log('placeholderarray: ', this.state.placeHolderArray)})
         .catch(err => toast.error('Problem in sending email'));



      responceplaceHolder.result.map(items => {
         if (items.Text !== 'NA') {

            message = message.replace('{{' + items.Name + '}}', items.Text);
         }
         if (items.DBValue === '{System.TempPassword}') {
            tempArray.push({ "dbname": "Password", "placeholdername": items.Name });
         }
         if (items.DBValue !== 'NA') {
            var tmp = items.DBValue.split('_');
            if (typeof tmp[1] !== 'undefined') {
               tempArray.push({ "dbname": tmp[1], "placeholdername": items.Name });
            }
         }
      })


      tempArray.map((tempItem, index) => {

         message = message.replace('{{' + tempItem.placeholdername + '}}', user[0][tempItem.dbname]);
      })
      return message;

   }
   UserInviteUserSendHandler = async () => {
      let User_InviteUSerEmailErros = this.state.User_InviteUSerEmailErros;
      let IsUser_InviteUserEmail_CcValid = this.state.IsUser_InviteUserEmail_CcValid;

      let IsUser_InviteUserEmail_NotesValid = this.state.IsUser_InviteUserEmail_NotesValid;
      let IsUser_InviteUserEmailFormValid = this.state.IsUser_InviteUserEmailFormValid;
      
      IsUser_InviteUserEmailFormValid = true;
      console.log('Email Form validdddd', this.state.IsUser_InviteUserEmailFormValid)

      await this.setState({
         IsUser_InviteUserEmailFormValid: IsUser_InviteUserEmailFormValid,
         User_InviteUSerEmailErros: User_InviteUSerEmailErros,
      });
      if (this.state.IsUser_InviteUserEmailFormValid) {
         if (this.state.selectedTemplate.length === 0) {
            toast.error("Please Select Email Template");
            return false;
         }
         this.setState({ isLoading: true });
         let password = this.randomNumber();
         await API.post("updateuserpasswordbyemail", "/updateuserpasswordbyemail", {
            body: {
               "userEmail": this.state.sendInviteTo,
               "userPassword": md5(password),
            }
         }).then(data => toast.success('Password updated successfully'))
            .catch(err => toast.error('Problem in updating password'));
         $('#invite_user_email').hide();
         var to = this.state.sendInviteTo;
         var message = this.state.selectedTemplate[0].BodyHTML;
         if (this.state.User_InviteUserEmail_Cc !== '') {
            to = to + ';' + this.state.User_InviteUserEmail_Cc;
         }
         if (this.state.User_InviteUserEmail_Notes !== '') {
            message = message + ' Additional Notes: ' + this.state.User_InviteUserEmail_Notes
         }
         message = await this.replacePlaceHodler(message, this.state.sendInviteTo, password);
         await API.post("sendemail", "/sendemail", {
            body: {
               "TO": to,
               "SUBJECT": "Invite from " + this.state.sendInviteTo,
               "MSG": message
            }
         }).then(data => toast.success('Invitation sent successfully'))
            .catch(err => toast.error('Problem in sending email'));
      }
      this.setState({
         isLoading: false
      });
   }
   UserpAllTableChkBoxHandler = (Guid, isChecked) => {
      let isAllChecked = (Guid === 'isAllUserTableChkbxSelected' && isChecked);
      let isAllUnchecked = (Guid === 'isAllUserTableChkbxSelected' && !isChecked);
      const checked = isChecked;
      const checkList = this.state.UserTableData.map(item => {
         if (isAllChecked || item.Guid === Guid) {
            return {
               ...item,
               checked,
            }
         } else if (isAllUnchecked) {
            return {
               ...item,
               checked: false,
            }
         }
         return item;
      });
      let isAllSelected = (checkList.findIndex(item => item.checked === false) === -1) || isAllChecked;
      if (isAllChecked) {
         let arr = [];
         checkList.map(items => {
            arr.push(items.Guid);
         });
         this.setState({
            checkedarray: arr
         });
      }
      else if (isAllUnchecked) {
         let arr = [];
         checkList.map(items => {
            const index = arr.indexOf(items.Guid);
            arr.splice(index, 1);
         });
         this.setState({
            checkedarray: arr
         });
      } else if (isChecked) {
         this.setState({ checkedarray: [...this.state.checkedarray, Guid] });
      } else if (!isChecked) {
         let arr = [];
         arr = [...this.state.checkedarray];
         const index = arr.indexOf(Guid);
         arr.splice(index, 1);
         this.setState({
            checkedarray: arr
         });
      }
      this.setState({
         UserTableData: checkList,
         isAllUserTableChkbxSelected: isAllSelected
      })
   }

   deleteuser = async (Guid, email) => {
      //    return false;
      this.setState({
         isLoading: true,
      });


      const Aws = require('aws-sdk');
      Aws.config.update({
         region: process.env.REACT_APP_COGNITO_REGION,
         accessKeyId: process.env.REACT_APP_accesskey,
         secretAccessKey: process.env.REACT_APP_secretkey
      });
      const cognitoIdentityService = new Aws.CognitoIdentityServiceProvider();
      var params = {
         UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
         Username: email,
      };
      var that = this;
      await cognitoIdentityService.adminDeleteUser(params, function (err, data) {
         if (err) {
            toast.error('Error In Deleting from Cognito.', {
               autoClose: 5000
            });
            return false;
         }
         else {
            that.setState({
               deletefromcognito: true
            });
            toast.success('Successfully Deleted from User Pool.', {
               autoClose: 5000
            });
         }
      });
      if (this.state.deletefromcognito === true) {
         await API.post('deleteuser', '/deleteuser', {
            body: {
               Guid: Guid,
            }
         }).then(data => {
            toast.success('User Delete From Dynamo');


         }).catch(error => { toast.error('Not Deleted From Dynamo'); });


      }
      this.setState({
         isLoading: false,
         deletefromcognito: false,
      });
   }
   async DeletelistTeamFromDynamo(guid) {
      return API.post("deleteteam", "/deleteteam", {
         body: {
            "Guid": guid,
         }
      });
   }
   handleDeletelistTeam = (Guid) => async event => {
      this.setState({
         isLoading: true
      });
      await this.DeletelistTeamFromDynamo(Guid).then(data => {
         toast.success('Deleted List Item From Dynamo.');
         this.listTeamGroupsToView();
      }).catch(err => {
         toast.error('List Item Not Deleted From Dynamo');
      });
      this.setState({
         isLoading: false
      });
   }
   handleDeleteUser = (Guid, Email) => async event => {
      var SessionGuid = localStorage.getItem('userGuid');

      this.setState({
         isLoading: true
      });
      if (typeof (Storage) !== "undefined") {
         if (SessionGuid === Guid) {
            toast.error("User cannot delete own account", {
               autoClose: 5000
            });
            this.setState({
               isLoading: false,
            });
            return false;
         }
         else {
            //========= Deleting Authenticated User ============================
            const Aws = require('aws-sdk');
            Aws.config.update({
               region: process.env.REACT_APP_COGNITO_REGION,
               accessKeyId: process.env.REACT_APP_accesskey,
               secretAccessKey: process.env.REACT_APP_secretkey
            });
            const cognitoIdentityService = new Aws.CognitoIdentityServiceProvider();
            var params = {
               UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
               Username: Email,
            };
            cognitoIdentityService.adminDeleteUser(params, function (err, data) {
               if (err) {
                  toast.error('Error In Deleting from Cognito.', {
                     autoClose: 5000
                  });
                  return false;
               }
               else {
                  toast.success('Successfully Deleted from User Pool.', {
                     autoClose: 5000
                  });
               }
            });

            //==================================================================

            //================ Deleting from Dynamo ===========================
            await this.deletFromDynamo(Guid);
            await API.post("recordactivity", "/recordactivity", {
               body: {
                  "Description": "User Delete Activity",
                  "UserName": localStorage.getItem('UserLogin'),
                  "IPAddress": ip.address(),
                  "TenantGuid": localStorage.getItem('useableGuid'),
                  "Type": "User Delete",
                  "UserGuid": localStorage.getItem('userGuid')
               }
            }).then(data => {
               toast.success('User Delete Activity Note');
            }).catch(err => {
               toast.error(err.message);
            });
            toast.success('Successfully Deleted from Dynamo Db.', {
               autoClose: 5000
            });
            //================ Deleting from Dynamo ==========================
            //============refreshing user list ================================
            toast.success('Refreshing user lists.', {
               autoClose: 5000
            });
            //============refreshing user list End =============================
            //========= Deleting Authenticated User End ========================
            await this.get_users();
         }
      }
      else {
         toast.success('Incomplete session data.', {
            autoClose: 5000
         });
      }
      this.setState({
         isLoading: false
      });
   }

   handleDeleteGroup = (Guid) => async event => {
      this.setState({
         isLoading: true
      });

      await this.deletFromDynamo(Guid).then(data => {
         toast.success('Deleted From Dynamo');
         this.get_Approvergroups();
      }).catch(err => {
         toast.error('Not Deleted From Dynamo');
      });




      this.setState({
         isLoading: false
      });
   }
   async deletFromDynamo(guid) {
      return API.post("deleteuser", "/deleteuser", {
         body: {
            "Guid": guid,
         }
      });
   }

   Groupchnghandler = async (event) => {
      var name = event.target.name;
      var value = event.target.value;
      await this.setState({
         [name]: value
      });
      this.Group_Fields(name, value);
      this.Group_Form_check();
   }
   Group_Form_check = async () => {
      await this.setState({
         group_form_valid: this.state.group_name_valid && this.state.group_name_error === ''
      });

   }
   Group_Fields = async (name, value) => {

      switch (name) {
         case 'Group_Name':
            if (value === '') {
               await this.setState({
                  group_name_valid: false,
                  group_name_error: 'This Field Is Required'
               })
            } else {
               await this.setState({
                  group_name_valid: true,
                  group_name_error: ''
               })
            }
            break;
         default:
            break;
      }
   }
   Group_Submit = async () => {
      if (this.state.group_name_valid === false) {
         await this.setState({
            group_name_error: 'This Field Is Required'
         })
         this.Group_Form_check();
      }
      if (this.state.group_form_valid === true) {
         this.setState({
            isLoading: true
         })
         await API.post("createuser", "/createuser", {
            body: {
               "Guid": '',
               "CompanyID": [],
               "UserType": this.state.User_UserFormuserTypeValue,
               "GroupName": this.state.Group_Name,
               "ApproverGroup": [{
                  "FirstName": this.state.Group_UName !== '' ? this.state.Group_UName : null,
                  "Initials": this.state.Group_Initials !== '' ? this.state.Group_Initials : null,
                  "Department": this.state.Group_Department !== '' ? this.state.Group_Department : null,
               }],
            }
         }).then(data => {
            toast.success('Group created in dynamo');
            this.get_Approvergroups();

         }).catch(err => {
            console.log(err)

            toast.error('Group not created in dynamo');
         });
         this.setState({
            isLoading: false
         })
      }
      $('#add_user_close').click();
   }
   clearstates = async () => {
      document.getElementById("aprover_details").style.display = 'none';
      this.after_issueScrollHandler();
      await this.setState({
         clone_locked: '',
         lastactivedate: 0,
         companyids: [],
         alreadySignature: '',
         tmp_Guid: '',
         approver_setup: [],
         lastlogin: '',
         UPassword: '',
         logincount: '',
         badpwCount: '',
         pwlastchange: '',
         lastlogout: '',
         IsUser_UserFormValid: 'false',
         IsUser_UserFormuserLoginValid: false,
         User_UserFormuserLogin: '',
         IsUser_UserFormuserNameValid: false,
         User_UserFormuserName: '',
         IsUser_UserFormuserInitialsValid: false,
         User_UserFormuserInitials: '',
         User_UserFormuserEmail: '',
         IsUser_UserFormuserEmailValid: false,
         IsUser_UserFormCountryCodeValid: false,
         User_UserFormCountryCode: '',
         User_UserFormuserMobNo: '',
         IsUser_UserFormuserMobNoValid: false,
         IsUser_UserFormuserDepartmentValid: false,
         User_UserFormuserDepartment: '',
         User_UserFormuserTeamgroup: '',
         User_UserFormuserTeamGroupToView: '',
         IsUser_UserFormuserTimecardChkboxChecked: false,
         IsUser_UserFormuserPayrollBatchesChkboxChecked: false,
         IsUser_UserFormuserUserChkboxChecked: false,
         IsUser_UserFormuserEmployeeListChkboxChecked: false,
         IsUser_UserFormuserApprovalSetupChkboxChecked: false,
         IsUser_UserFormuserEmailTemplateChkboxChecked: false,
         IsUserFormUserSecurityAccountFactorAuthenticateChecked: false,
         AccessibleTemplates: [],
         AccessibleCompanies: [],
         IsUser_UserFormSendReminderEveryDayValid: false,
         User_UserFormSendReminderEveryDay: '0 Hr',
         IsUser_UserFormPasswordStrengthValid: false,
         User_UserFormPasswordStrength: '',
         User_UserFormuserPWDExpireDays: '',
         User_UserFormPassword: '',
         IsUserFormUserSecurityAccountDisabledChecked: false,
         IsUserFormUserSecurityAccountLockedOutChecked: false,
         IsUserFormUserSecurityAccountFactorAuthenticateCheck: false,
         User_UserFormuserInActivityLockDays: '',
         User_UserFormuserTypeValue: '',
         IsUser_UserFormuserTypeValid: false,
         group_name_valid: false,
         group_form_valid: false,
         Group_Name: '',
         Group_UName: '',
         Group_Initials: '',
         Group_Department: '',
         tmp_check: '',
      });
      var other_user = document.getElementById("other_user");
      var group = document.getElementById("approver_group");
      if (this.state.User_UserFormuserTypeValue === 'Approver Group') {

         group.style.display = "block";
         other_user.style.display = "none";

      }
      else {
         group.style.display = "none";
         other_user.style.display = "block";

      }
   }
   getUsertoedit = (guid) => async event => {
      await this.setState({
         isLoading: true
      });
      await API.post("getuserbyguid", "/getuserbyguid", {
         body: {
            user_id: guid
         }
      }).then(data => {
         this.setState({
            lastactivedate: data.lastactivedate,
            companyids: data.CompanyID,
            tmp_Guid: data.Guid,
            tmp_check: 'update',
            IsUser_UserFormValid: true,
            IsUser_UserFormuserLoginValid: true,
            User_UserFormuserLogin: data.UserLogin,
            IsUser_UserFormuserNameValid: true,
            User_UserFormuserName: data.FirstName,
            User_UserFormPassword: data.PasswordChange,
            IsUser_UserFormuserInitialsValid: true,
            User_UserFormuserInitials: data.Initials,
            User_UserFormuserEmail: data.EmailAddress,
            IsUser_UserFormuserEmailValid: true,
            IsUser_UserFormCountryCodeValid: true,
            User_UserFormCountryCode: data.countryCode,
            User_UserFormuserMobNo: data.MobileNumber,
            IsUser_UserFormuserMobNoValid: true,
            IsUser_UserFormuserDepartmentValid: true,
            User_UserFormuserDepartment: data.Department,
            UPassword: data.Password,
            User_UserFormuserTeamgroup: data.TeamGroup,
            User_UserFormuserTeamGroupToView: data.TeamList === null ? [] : data.TeamList,
            IsUser_UserFormuserTimecardChkboxChecked: data.access ? data.access.Timecards : false,
            IsUser_UserFormuserPayrollBatchesChkboxChecked: data.access ? data.access.PayrollBatch : false,
            IsUser_UserFormuserUserChkboxChecked: data.access ? data.access.Users : false,
            IsUser_UserFormuserEmployeeListChkboxChecked: data.access ? data.access.EmployeeList : false,
            IsUser_UserFormuserApprovalSetupChkboxChecked: data.access ? data.access.ApprovalSetup : false,
            IsUser_UserFormuserEmailTemplateChkboxChecked: data.access ? data.access.EmailTamplate : false,
            IsUserFormUserSecurityAccountFactorAuthenticateChecked: data.FactorAuthenticate,
            AccessibleTemplates: data.access ? data.access.AccessibleTemplates : [],
            IsUser_UserFormSendReminderEveryDayValid: true,
            User_UserFormSendReminderEveryDay: data.ApproverDetails.UserFormSendReminderEveryDay,
            IsUser_UserFormPasswordStrengthValid: true,
            User_UserFormPasswordStrength: data.PWStrength,
            User_UserFormuserPWDExpireDays: data.PWExpireDays,
            IsUserFormUserSecurityAccountDisabledChecked: data.AcctDisabled,
            IsUserFormUserSecurityAccountLockedOutChecked: data.AcctLockedout,
            clone_locked: data.AcctLockedout,
            IsUserFormUserSecurityAccountFactorAuthenticateCheck: data.FactorAuthenticate,
            User_UserFormuserInActivityLockDays: data.InactivityLockoutDays,
            lastlogin: data.LastLogin,
            User_UserFormuserTypeValue: data.UserType,
            IsUser_UserFormuserTypeValid: true,
            logincount: data.LoginCount,
            badpwCount: data.WrongPWCount,
            pwlastchange: data.PWLastChanged,
            lastlogout: data.LastLogout,
            AccessibleCompanies: data.TenantGuid

         });

      }).catch(err => {
         console.log('error in fetching user')
      });

      await this.add_team();
      await this.CheckImage();
      if (this.state.User_UserFormuserTypeValue === 'Approver') {
         document.getElementById("aprover_details").style.display = 'block';
      } else {
         document.getElementById("aprover_details").style.display = 'none';
      }
      await this.setState({
         isLoading: false
      });
   }
   getGrouptoedit = (guid) => async event => {
      await this.setState({
         User_UserFormuserTypeValue: 'Approver Group',
         IsUser_UserFormuserTypeValid: true,
         isLoading: true
      });
      var other_user = document.getElementById("other_user");
      var group = document.getElementById("approver_group");
      if (this.state.User_UserFormuserTypeValue === 'Approver Group') {

         group.style.display = "block";
         other_user.style.display = "none";

      }
      else {
         group.style.display = "none";
         other_user.style.display = "block";

      }

      await API.post("getuserbyguid", "/getuserbyguid", {
         body: {
            user_id: guid
         }
      }).then(data => {
         this.setState({

            group_name_valid: true,
            group_form_valid: true,
            Group_Name: data.GroupName ? data.GroupName : '',
            Group_UName: data.ApproverGroup[0].FirstName,
            Group_Initials: data.ApproverGroup[0].Initials,
            Group_Department: data.ApproverGroup[0].Department,
            tmp_check: 'update',
            tmp_Guid: data.Guid,




         });

      }).catch(err => {
         console.log('error in fetching user')
      });
      await this.setState({

         isLoading: false
      });
   }
   usergroup_update = async () => {
      this.Group_Form_check();
      if (this.state.tmp_Guid !== '' && this.state.group_form_valid === true) {

         this.setState({
            isLoading: true
         })
         await API.post("updateapprovergroup", "/updateapprovergroup", {
            body: {
               "Guid": this.state.tmp_Guid,
               "UserType": this.state.User_UserFormuserTypeValue,
               "GroupName": this.state.Group_Name,
               "ApproverGroup": [{
                  "FirstName": this.state.Group_UName !== '' ? this.state.Group_UName : null,
                  "Initials": this.state.Group_Initials !== '' ? this.state.Group_Initials : null,
                  "Department": this.state.Group_Department !== '' ? this.state.Group_Department : null,
               }],
            }
         }).then(data => {
            this.get_Approvergroups();
            this.get_users();
            toast.success(' Group Updated Successfully')
         }).catch(err => {
            console.log(err);
            toast.error('Group Not Updated Successfully')
         });
         $('#add_user_close').click();
         this.setState({
            isLoading: false
         })
      } else {
         toast.error('Data Incomplete');
      }


   }
   getSignatures = async (data) => {
      const base64 = data;

      const base64Data = new Buffer(base64.replace(/^data:image\/\w+;base64,/, ""), 'base64');
      console.log('bbbbbbbbbbb',base64Data);
      await this.setState({
         signature: base64Data
      });



   }
   save_signature = async () => {


      if (this.state.SignatureText !== "") {

         let Signaturetype = this.state.SignatureText;



         var c = document.getElementById("myCanvas");
         var ctx = c.getContext("2d");
         const text = document.getElementById('SignatureText').value;
         var width = ctx.measureText(text).width * 3;
         ctx.canvas.width = width;
         //ctx.canvas.width = ctx.measureText(text).width;
         ctx.fillStyle = "#FFFFFF";
         ctx.fillRect(0, 0, width, 100);

         var imageElem = document.getElementById("image");
         var c = document.getElementById("myCanvas");
         var ctx = c.getContext("2d");

         ctx.font = "25px arial";

         // Create gradient
         var gradient = ctx.createLinearGradient(0, 0, c.width, 0);
         gradient.addColorStop("0", " black");
         gradient.addColorStop("0.5", "black");
         gradient.addColorStop("1.0", "black");
         // Fill with gradient
         ctx.fillStyle = gradient;

         ctx.fillText(text, 0, 20);
         ctx.textBaseline = "bottom";

         const base64 = ctx.canvas.toDataURL("image/jpeg");
         const base64Data = new Buffer(base64.replace(/^data:image\/\w+;base64,/, ""), 'base64');
         await this.setState({ Signa: base64Data })
         // let filenameTyped = localStorage.getItem('useableGuid')+"/"+"/SignatureFileTyped.jpg";

         this.setState({
            isLoading: true
         });
         this.issue_ScrollHandler();


         var filename = this.state.User_UserFormuserEmail + '_signature.jpg';
         await Storage.put(filename, this.state.Signa, { contentType: 'image/jpeg' })
            .then(result => {
               console.log(result);
               this.CheckImage();
            }).catch(error => {
               console.log(error);
            });
         document.getElementById("close_signature").click();
         this.setState({
            isLoading: false
         });

      }
      else {
         this.setState({
            isLoading: true
         });
         this.issue_ScrollHandler();


         var filename = this.state.User_UserFormuserEmail + '_signature.jpg';
         await Storage.put(filename, this.state.signature,{ contentType: 'image/jpeg' })
            .then(result => {
               console.log(result);
               this.CheckImage();
            }).catch(error => {
               console.log(error);
            });
         document.getElementById("close_signature").click();
         this.setState({
            isLoading: false
         });
     
      }



   }
   clear_sign = async () => {
      await this.setState({

         signclear: Date.now(),
         SignatureText: ""
      })
   }
   opensignature_popup = async () => {
      if (this.state.IsUser_UserFormuserEmailValid && this.state.User_UserFormuserEmail !== '') {
         document.getElementById("open_edit_signature").click();
      } else {
         toast.error('Provide Email Before Edit Your Signature');
      }
   }
   CheckImage = async () => {
      await this.setState({
         isLoading: true
      });
      var filename = this.state.User_UserFormuserEmail + '_signature.jpg';
      if (this.state.IsUser_UserFormuserEmailValid && this.state.User_UserFormuserEmail !== '' && this.state.User_UserFormuserTypeValue === 'Approver') {

         await Storage.get(filename).then(result => {
            this.setState({
               alreadySignature: result
            });
         }).catch(error => {
            console.log(error);
            this.setState({
               alreadySignature: ''
            });
         });

      } else {
         this.setState({
            alreadySignature: ''
         });
      }
      await this.setState({
         isLoading: false
      });
   }
   sort_UserTableData = async (UserTab, name) => {



      await this.setState({
         toggleCaretState: !this.state.toggleCaretState
      })
      if (this.state.toggleCaretState === false) {
         $("#" + name).removeAttr("style")
      }
      localStorage.setItem('userUserTableSortTab', UserTab);
      if (UserTab === 'UserName') {

         $("#UserUnCaret").show()
         $("#UserTypeCaret").hide()
         $("#UserDepartmentCaret").hide()
         $("#UserEmailCaret").hide()
         $("#UserStatusCaret").hide()



         this.setState({
            User_UserSelTab: UserTab,
            User_UserType_Toggle: false,
            User_UserDepartment_Toggle: false,
            User_UserEmail_Toggle: false,
            User_UserStatus_Toggle: false,
         })

         if (this.state.User_UserUserName_Toggle) {
            await this.setState({
               User_UserUserName_Toggle: false
            })
         } else {
            await this.setState({
               User_UserUserName_Toggle: true
            })
         }
         if (this.state.User_UserUserName_Toggle) {
            await this.setState({
               UserTableData: this.state.UserTableData.sort(function (a, b) {
                  var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                     return -1;
                  }
                  if (nameA > nameB) {
                     return 1;
                  }
                  // names must be equal
                  return 0;
               })
            })
         } else {
            await this.setState({
               UserTableData: this.state.UserTableData.sort(function (a, b) {
                  var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
                  if (nameA > nameB) {
                     return -1;
                  }
                  if (nameA < nameB) {
                     return 1;
                  }
                  // names must be equal
                  return 0;
               })
            })
         }

      }

      if (UserTab === 'Type') {

         $("#UserUnCaret").hide()
         $("#UserTypeCaret").show()
         $("#UserDepartmentCaret").hide()
         $("#UserEmailCaret").hide()
         $("#UserStatusCaret").hide()



         this.setState({
            User_UserSelTab: UserTab,
            User_UserUserName_Toggle: false,
            User_UserDepartment_Toggle: false,
            User_UserEmail_Toggle: false,
            User_UserStatus_Toggle: false,
         })

         if (this.state.User_UserType_Toggle) {
            await this.setState({
               User_UserType_Toggle: false
            })
         } else {
            await this.setState({
               User_UserType_Toggle: true
            })
         }
         if (this.state.User_UserType_Toggle) {
            await this.setState({
               UserTableData: this.state.UserTableData.sort(function (a, b) {
                  var nameA = a.UserType.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.UserType.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                     return -1;
                  }
                  if (nameA > nameB) {
                     return 1;
                  }
                  // names must be equal
                  return 0;
               })
            })
         } else {
            await this.setState({
               UserTableData: this.state.UserTableData.sort(function (a, b) {
                  var nameA = a.UserType.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.UserType.toUpperCase(); // ignore upper and lowercase
                  if (nameA > nameB) {
                     return -1;
                  }
                  if (nameA < nameB) {
                     return 1;
                  }
                  // names must be equal
                  return 0;
               })
            })
         }

      }


      if (UserTab === 'Department') {

         $("#UserUnCaret").hide()
         $("#UserTypeCaret").hide()
         $("#UserDepartmentCaret").show()
         $("#UserEmailCaret").hide()
         $("#UserStatusCaret").hide()



         this.setState({
            User_UserSelTab: UserTab,
            User_UserUserName_Toggle: false,
            User_UserType_Toggle: false,
            User_UserEmail_Toggle: false,
            User_UserStatus_Toggle: false,
         })

         if (this.state.User_UserDepartment_Toggle) {
            await this.setState({
               User_UserDepartment_Toggle: false
            })
         } else {
            await this.setState({
               User_UserDepartment_Toggle: true
            })
         }
         if (this.state.User_UserDepartment_Toggle) {
            await this.setState({
               UserTableData: this.state.UserTableData.sort(function (a, b) {
                  var nameA = a.Department.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.Department.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                     return -1;
                  }
                  if (nameA > nameB) {
                     return 1;
                  }
                  // names must be equal
                  return 0;
               })
            })
         } else {
            await this.setState({
               UserTableData: this.state.UserTableData.sort(function (a, b) {
                  var nameA = a.Department.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.Department.toUpperCase(); // ignore upper and lowercase
                  if (nameA > nameB) {
                     return -1;
                  }
                  if (nameA < nameB) {
                     return 1;
                  }
                  // names must be equal
                  return 0;
               })
            })
         }

      }

      if (UserTab === 'Email') {

         $("#UserUnCaret").hide()
         $("#UserTypeCaret").hide()
         $("#UserDepartmentCaret").hide()
         $("#UserEmailCaret").show()
         $("#UserStatusCaret").hide()



         this.setState({
            User_UserSelTab: UserTab,
            User_UserUserName_Toggle: false,
            User_UserType_Toggle: false,
            User_UserDepartment_Toggle: false,
            User_UserStatus_Toggle: false,
         })

         if (this.state.User_UserEmail_Toggle) {
            await this.setState({
               User_UserEmail_Toggle: false
            })
         } else {
            await this.setState({
               User_UserEmail_Toggle: true
            })
         }
         if (this.state.User_UserEmail_Toggle) {
            await this.setState({
               UserTableData: this.state.UserTableData.sort(function (a, b) {
                  var nameA = a.EmailAddress.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.EmailAddress.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                     return -1;
                  }
                  if (nameA > nameB) {
                     return 1;
                  }
                  // names must be equal
                  return 0;
               })
            })
         } else {
            await this.setState({
               UserTableData: this.state.UserTableData.sort(function (a, b) {
                  var nameA = a.EmailAddress.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.EmailAddress.toUpperCase(); // ignore upper and lowercase
                  if (nameA > nameB) {
                     return -1;
                  }
                  if (nameA < nameB) {
                     return 1;
                  }
                  // names must be equal
                  return 0;
               })
            })
         }

      }

      if (UserTab === 'Status') {

         $("#UserUnCaret").hide()
         $("#UserTypeCaret").hide()
         $("#UserDepartmentCaret").hide()
         $("#UserEmailCaret").hide()
         $("#UserStatusCaret").show()



         this.setState({
            User_UserSelTab: UserTab,
            User_UserUserName_Toggle: false,
            User_UserType_Toggle: false,
            User_UserDepartment_Toggle: false,
            User_UserEmail_Toggle: false,

         })

         if (this.state.User_UserStatus_Toggle) {
            await this.setState({
               User_UserStatus_Toggle: false
            })
         } else {
            await this.setState({
               User_UserStatus_Toggle: true
            })
         }

         if (this.state.User_UserStatus_Toggle) {
            await this.setState({
               UserTableData: this.state.UserTableData.sort(function (a, b) {
                  var nameA = a.UserActive !== 1 ? 'Locked Out' : a.AcctDisabled ? 'Account Disabled' : a.LoginCount === 0 ? 'Pending' : a.LoginCount > 0 ? 'Accepted' : 'Pending'.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.UserActive !== 1 ? 'Locked Out' : b.AcctDisabled ? 'Account Disabled' : b.LoginCount === 0 ? 'Pending' : b.LoginCount > 0 ? 'Accepted' : 'Pending'.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                     return -1;
                  }
                  if (nameA > nameB) {
                     return 1;
                  }
                  // names must be equal
                  return 0;
               })
            })
         } else {
            await this.setState({
               UserTableData: this.state.UserTableData.sort(function (a, b) {
                  var nameA = a.UserActive !== 1 ? 'Locked Out' : a.AcctDisabled ? 'Account Disabled' : a.LoginCount === 0 ? 'Pending' : a.LoginCount > 0 ? 'Accepted' : 'Pending'.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.UserActive !== 1 ? 'Locked Out' : b.AcctDisabled ? 'Account Disabled' : b.LoginCount === 0 ? 'Pending' : b.LoginCount > 0 ? 'Accepted' : 'Pending'.toUpperCase(); // ignore upper and lowercase
                  if (nameA > nameB) {
                     return -1;
                  }
                  if (nameA < nameB) {
                     return 1;
                  }
                  // names must be equal
                  return 0;
               })
            })
         }
      }




   }

   sort_GroupTableData = async (tab, name) => {


      localStorage.setItem('AGTableSelTab', tab)


      if (tab === 'UserName') {



         $("#AGUserNameCaret").show()
         $("#AGGroupCaret").hide()
         $("#AGInitialsCaret").hide()
         $("#AGDeptCaret").hide()

         this.setState({
            User_AGSelTab: tab,
            User_AGGroupName_Toggle: false,
            User_AGInitials_Toggle: false,
            User_AGUserDepartment_Toggle: false,
         })


         if (this.state.User_AGUserName_Toggle) {
            await this.setState({
               User_AGUserName_Toggle: false
            })
         } else {
            await this.setState({
               User_AGUserName_Toggle: true
            })
         }
         if (this.state.User_AGUserName_Toggle) {
            await this.setState({
               GroupTableData: this.state.GroupTableData.sort(function (a, b) {
                  var nameA = a.ApproverGroup[0].FirstName.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.ApproverGroup[0].FirstName.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                     return -1;
                  }
                  if (nameA > nameB) {
                     return 1;
                  }
                  // names must be equal
                  return 0;
               })
            })
         } else {
            await this.setState({
               GroupTableData: this.state.GroupTableData.sort(function (a, b) {
                  var nameA = a.ApproverGroup[0].FirstName.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.ApproverGroup[0].FirstName.toUpperCase(); // ignore upper and lowercase
                  if (nameA > nameB) {
                     return -1;
                  }
                  if (nameA < nameB) {
                     return 1;
                  }
                  // names must be equal
                  return 0;
               })
            })
         }



      }

      if (tab === 'GroupName') {



         $("#AGUserNameCaret").hide()
         $("#AGGroupCaret").show()
         $("#AGInitialsCaret").hide()
         $("#AGDeptCaret").hide()

         this.setState({
            User_AGSelTab: tab,
            User_AGUserName_Toggle: false,
            User_AGInitials_Toggle: false,
            User_AGUserDepartment_Toggle: false,
         })


         if (this.state.User_AGGroupName_Toggle) {
            await this.setState({
               User_AGGroupName_Toggle: false
            })
         } else {
            await this.setState({
               User_AGGroupName_Toggle: true
            })
         }
         if (this.state.User_AGGroupName_Toggle) {

            await this.setState({
               GroupTableData: this.state.GroupTableData.sort(function (a, b) {
                  var nameA = a.GroupName.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.GroupName.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                     return -1;
                  }
                  if (nameA > nameB) {
                     return 1;
                  }
                  // names must be equal
                  return 0;
               })
            })
         } else {
            await this.setState({
               GroupTableData: this.state.GroupTableData.sort(function (a, b) {
                  var nameA = a.GroupName.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.GroupName.toUpperCase(); // ignore upper and lowercase
                  if (nameA > nameB) {
                     return -1;
                  }
                  if (nameA < nameB) {
                     return 1;
                  }
                  // names must be equal
                  return 0;
               })
            })
         }



      }

      if (tab === 'Initials') {



         $("#AGUserNameCaret").hide()
         $("#AGGroupCaret").hide()
         $("#AGInitialsCaret").show()
         $("#AGDeptCaret").hide()

         this.setState({
            User_AGSelTab: tab,
            User_AGUserName_Toggle: false,
            User_AGGroupName_Toggle: false,
            User_AGUserDepartment_Toggle: false,
         })


         if (this.state.User_AGInitials_Toggle) {
            await this.setState({
               User_AGInitials_Toggle: false
            })
         } else {
            await this.setState({
               User_AGInitials_Toggle: true
            })
         }
         if (this.state.User_AGInitials_Toggle) {
            await this.setState({
               GroupTableData: this.state.GroupTableData.sort(function (a, b) {
                  var nameA = a.ApproverGroup[0].Initials.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.ApproverGroup[0].Initials.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                     return -1;
                  }
                  if (nameA > nameB) {
                     return 1;
                  }
                  // names must be equal
                  return 0;
               })
            })
         } else {
            await this.setState({
               GroupTableData: this.state.GroupTableData.sort(function (a, b) {
                  var nameA = a.ApproverGroup[0].Initials.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.ApproverGroup[0].Initials.toUpperCase(); // ignore upper and lowercase
                  if (nameA > nameB) {
                     return -1;
                  }
                  if (nameA < nameB) {
                     return 1;
                  }
                  // names must be equal
                  return 0;
               })
            })
         }



      }

      if (tab === 'Department') {



         $("#AGUserNameCaret").hide()
         $("#AGGroupCaret").hide()
         $("#AGInitialsCaret").hide()
         $("#AGDeptCaret").show()

         this.setState({
            User_AGSelTab: tab,
            User_AGUserName_Toggle: false,
            User_AGGroupName_Toggle: false,
            User_AGInitials_Toggle: false,

         })


         if (this.state.User_AGUserDepartment_Toggle) {
            await this.setState({
               User_AGUserDepartment_Toggle: false
            })
         } else {
            await this.setState({
               User_AGUserDepartment_Toggle: true
            })
         }
         if (this.state.User_AGUserDepartment_Toggle) {

            await this.setState({
               GroupTableData: this.state.GroupTableData.sort(function (a, b) {
                  var nameA = a.ApproverGroup[0].Department.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.ApproverGroup[0].Department.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                     return -1;
                  }
                  if (nameA > nameB) {
                     return 1;
                  }
                  // names must be equal
                  return 0;
               })
            })
         } else {
            await this.setState({
               GroupTableData: this.state.GroupTableData.sort(function (a, b) {
                  var nameA = a.ApproverGroup[0].Department.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.ApproverGroup[0].Department.toUpperCase(); // ignore upper and lowercase
                  if (nameA > nameB) {
                     return -1;
                  }
                  if (nameA < nameB) {
                     return 1;
                  }
                  // names must be equal
                  return 0;
               })
            })
         }



      }


   }
   add_team = async (e) => {
      var team_values = [];
      var team_names = [];
      $("input[name='guid[]']").each(function () {
         if (this.checked === true) {
            team_values.push($(this).val());
            team_names.push($(this).attr("data-list-name"));
         }
      });
      await this.setState({
         team_values: team_names,
         User_UserFormuserTeamGroupToView: team_values
      });
   }
   signaturetext = async (e) => {
      await this.setState({ [e.target.name]: e.target.value })

   }
   render() {
      const that = this;
      const style = { width: 410, height: 107 };
      let role = localStorage.getItem('role');
      return (
         <div>
            {this.state.isLoading ?
               <div className="se-pre-con"></div>
               : ''}
            <Header1 childProps={this.props.childProps} pageHeader="User" />
            <div className="clearfix"></div>
            <div className="col-lg-10 col-lg-offset-1 mrg_top_user">
               <div className="col-sm-6 p0">
                  <div className="search_area_user">
                     <input name="UserTableDataSearch"
                        type="text" onChange={this.UserInputsChngHandlers}
                        placeholder={`Search by ${this.state.User_UserSelTab}`}
                        className="search_user" value={this.state.UserTableDataSearch}
                     />
                     <img src={searchSvg} width="20" height="20" alt="" />
                  </div>
               </div>
               <div className="col-sm-6 mrg_topright2">
                  <a href={null} className="plus_icon_user cursorPointer" onClick={this.onUserFormOpen} data-toggle="modal" data-target="#user_form">
                     <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                        <path id="ic_add_24px" d="M20,13.571H13.571V20H11.429V13.571H5V11.429h6.429V5h2.143v6.429H20Z" transform="translate(-5 -5)" fill="#7B7B7B"></path>
                     </svg>
                  </a>
               </div>
               <div className="clear10"></div>
               <div className="col-sm-12 p0">
                  <div className="overflow_scroll_sm">
                     <table width="100%" className="table table-bordered timecard_table user_list_table">
                        <thead>
                           <tr className="table_header_user">
                              <th width="5%" align="center">
                                 <input name="isAllUserTableChkbxSelected" id="isAllUserTableChkbxSelected"
                                    checked={this.state.isAllUserTableChkbxSelected}
                                    type="checkbox"
                                    onChange={(e) => this.UserpAllTableChkBoxHandler('isAllUserTableChkbxSelected', e.target.checked)} />
                              </th>
                              <th width="10%" align="center">
                                 <div className="cursorPointer" onClick={() => this.sort_UserTableData('UserName', 'UserUnCaret')}>
                                    <div className="pull-left" > User Name</div>
                                    <div className="user_downarrow usr_dwn_arrow">
                                       <svg
                                          id="UserUnCaret" className={!this.state.User_UserUserName_Toggle ? "toggleCaretClass" : ''}
                                          xmlns="http://www.w3.org/2000/svg" width="12.337" height="6.887" viewBox="0 0 12.337 6.887">
                                          <path id="ic_arrow_drop_down_24px" d="M7,10l6.169,6.887L19.337,10Z" transform="translate(-7 -10)" fill="#30679a" />
                                       </svg>
                                    </div>
                                 </div>
                              </th>
                              <th width="20%" align="center">

                                 <div className="cursorPointer" onClick={() => this.sort_UserTableData('Type', 'UserTypeCaret')}>
                                    <div className="pull-left mrg_table_th_userType">Type</div>
                                    <div className="user_downarrow usr_dwn_arrow">
                                       <svg
                                          id="UserTypeCaret" className={!this.state.User_UserType_Toggle ? "toggleCaretClass" : ''}
                                          xmlns="http://www.w3.org/2000/svg" width="12.337" height="6.887" viewBox="0 0 12.337 6.887">
                                          <path id="ic_arrow_drop_down_24px" d="M7,10l6.169,6.887L19.337,10Z" transform="translate(-7 -10)" fill="#30679a" />
                                       </svg>
                                    </div>
                                 </div>



                              </th>
                              <th width="20%" align="left" className="dep_td" >
                                 <div className="cursorPointer" onClick={() => this.sort_UserTableData('Department', 'UserDepartmentCaret')}>
                                    <div className="pull-left mrg_table_th_userDep" > Department</div>
                                    <div className="user_downarrow usr_dwn_arrow">
                                       <svg
                                          id="UserDepartmentCaret" className={!this.state.User_UserDepartment_Toggle ? "toggleCaretClass" : ''}
                                          xmlns="http://www.w3.org/2000/svg" width="12.337" height="6.887" viewBox="0 0 12.337 6.887">
                                          <path id="ic_arrow_drop_down_24px" d="M7,10l6.169,6.887L19.337,10Z" transform="translate(-7 -10)" fill="#30679a" />
                                       </svg>
                                    </div>
                                 </div>

                              </th>
                              <th width="15%" align="center" className="dep_td">
                                 <div className="cursorPointer" onClick={() => this.sort_UserTableData('Email', 'UserEmailCaret')}>
                                    <div className="pull-left" >Email Address </div>
                                    <div className="user_downarrow usr_dwn_arrow">
                                       <svg
                                          id="UserEmailCaret" className={!this.state.User_UserEmail_Toggle ? "toggleCaretClass" : ''}
                                          xmlns="http://www.w3.org/2000/svg" width="12.337" height="6.887" viewBox="0 0 12.337 6.887">
                                          <path id="ic_arrow_drop_down_24px" d="M7,10l6.169,6.887L19.337,10Z" transform="translate(-7 -10)" fill="#30679a" />
                                       </svg>
                                    </div>
                                 </div>
                              </th>

                              <th width="10%" align="center" className="dep_td">
                                 <div className="cursorPointer" onClick={() => this.sort_UserTableData('Status', 'UserStatusCaret')}>
                                    <div className="pull-left" > Status</div>
                                    <div className="user_downarrow usr_dwn_arrow">
                                       <svg
                                          id="UserStatusCaret" className={!this.state.User_UserStatus_Toggle ? "toggleCaretClass" : ''}
                                          xmlns="http://www.w3.org/2000/svg" width="12.337" height="6.887" viewBox="0 0 12.337 6.887">
                                          <path id="ic_arrow_drop_down_24px" d="M7,10l6.169,6.887L19.337,10Z" transform="translate(-7 -10)" fill="#30679a" />
                                       </svg>
                                    </div>
                                 </div></th>
                              <th width="10%" align="center">&nbsp;</th>
                           </tr>
                        </thead>
                        <tbody>
                           {this.state.UserTableData.map(items => {
                              return (
                                 this.state.UserTableData.length > 0 ?
                                    <tr key={items.Guid}>
                                       <td align="center">

                                          <input
                                             type="checkbox"
                                             name={items.Guid}
                                             checked={items.checked}
                                             onChange={(e) => this.UserpAllTableChkBoxHandler(items.Guid, e.target.checked)} />
                                       </td>
                                       <td align="center">{items.FirstName}</td>
                                       <td align="center">{items.UserType}
                                       </td>
                                       <td align="center" className="dep_td">{items.Department}
                                       </td>
                                       <td align="center" className="dep_td">{items.EmailAddress}</td>
                                       <td align="center" className="dep_td">{items.UserActive !== 1 ? 'Locked Out' : items.AcctDisabled ? 'Account Disabled' : items.LoginCount === 0 ? 'Pending' : items.LoginCount > 0 ? 'Accepted' : 'Pending'}</td>
                                       <td align="center">
                                          <div className="dropdown menu_icon_user2">
                                             <button className="dropdown-toggle btn_user_menu" type="button" data-toggle="dropdown">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="4" viewBox="0 0 16 4">
                                                   <path id="ic_more_horiz_24px" d="M6,10a2,2,0,1,0,2,2A2.006,2.006,0,0,0,6,10Zm12,0a2,2,0,1,0,2,2A2.006,2.006,0,0,0,18,10Zm-6,0a2,2,0,1,0,2,2A2.006,2.006,0,0,0,12,10Z" transform="translate(-4 -10)" />
                                                </svg>
                                             </button>
                                             <ul className="dropdown-menu dropleft dropdown_menu_users_down dropdown_menu_user_1 dropdown_user_droppp">
                                                <li>
                                                   <a href={null} data-toggle="modal" data-target="#invite_user_email" className="svg_delete_suer_drop cursorPointer" onClick={this.setEmailAddressToSendMail.bind(this, items.EmailAddress)}>
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
                                                         <path id="ic_mail_outline_24px" d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,14H4V8l8,5,8-5Zm-8-7L4,6H20Z" transform="translate(-2 -4)" fill="#7b7b7b" />
                                                      </svg>
                                                      Send Invite
                                 </a>
                                                </li>
                                                <li>
                                                   <a className="cursorPointer" href={null} onClick={this.handleDeleteUser(items.Guid, items.EmailAddress)}>
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="10.111" height="13" viewBox="0 0 10.111 13">
                                                         <path id="ic_delete_24px" d="M5.722,14.556A1.449,1.449,0,0,0,7.167,16h5.778a1.449,1.449,0,0,0,1.444-1.444V5.889H5.722ZM15.111,3.722H12.583L11.861,3H8.25l-.722.722H5V5.167H15.111Z" transform="translate(-5 -3)" fill="#7b7b7b" />
                                                      </svg>
                                                      Delete
                                 </a>
                                                </li>
                                             </ul>
                                          </div>
                                          <a href={null} className="user_pullleft_edit usr_edt cursorPointer" onClick={this.getUsertoedit(items.Guid)} data-toggle="modal" data-target="#user_form">
                                             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                <path id="ic_create_24px" className="cls-1" d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z" transform="translate(-3 -2.998)"></path>
                                             </svg>
                                          </a>
                                       </td>
                                    </tr>
                                    :
                                    <tr colspan="7">Loading .... </tr>
                              )
                           })}
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>

            <div className="col-lg-10 col-lg-offset-1 mrg_top_user_second">
               <div className="col-sm-6 p0">
                  <div className="search_area_user">
                     <input name="UserTableDataSearch" type="text"
                        className="search_user" onChange={this.GroupInputsChngHandlers}
                        placeholder={`Search by ${this.state.User_AGSelTab}`}
                        value={this.groupsearch}
                     />
                     <img src={searchSvg} width="20" height="20" alt="" />
                  </div>
               </div>
               <div className="col-sm-6 mrg_topright2">
               </div>
               <div className="clear10"></div>
               <div className="col-sm-12 p0">
                  <div className="overflow_scroll_sm">
                     <table width="100%" className="table table-bordered timecard_table user_list_table">
                        <thead>
                           <tr className="table_header_user">

                              <th width="10%" align="center">
                                 <div className="cursorPointer " onClick={() => this.sort_GroupTableData('UserName', 'AGUserNameCaret')}>
                                    <div className="pull-left mrg_table_th_userName1"> User Name</div>
                                    <div className="user_downarrow usr_dwn_arrow">
                                       <svg
                                          id="AGUserNameCaret" className={!this.state.User_AGUserName_Toggle ? "toggleCaretClass" : ''}
                                          xmlns="http://www.w3.org/2000/svg" width="12.337" height="6.887" viewBox="0 0 12.337 6.887">
                                          <path id="ic_arrow_drop_down_24px" d="M7,10l6.169,6.887L19.337,10Z" transform="translate(-7 -10)" fill="#30679a" />
                                       </svg>
                                    </div>
                                 </div>
                              </th>
                              <th width="20%" align="left" className="dep_td" >

                                 <div className="cursorPointer " onClick={() => this.sort_GroupTableData('GroupName', 'AGGroupCaret')}>
                                    <div className="pull-left mrg_table_th_userGroupName"> Group Name</div>
                                    <div className="user_downarrow usr_dwn_arrow">
                                       <svg
                                          id="AGGroupCaret" className={!this.state.User_AGGroupName_Toggle ? "toggleCaretClass" : ''}
                                          xmlns="http://www.w3.org/2000/svg" width="12.337" height="6.887" viewBox="0 0 12.337 6.887">
                                          <path id="ic_arrow_drop_down_24px" d="M7,10l6.169,6.887L19.337,10Z" transform="translate(-7 -10)" fill="#30679a" />
                                       </svg>
                                    </div>
                                 </div>

                              </th>
                              <th width="20%" align="left" className="dep_td" >


                                 <div className="cursorPointer" onClick={() => this.sort_GroupTableData('Initials', 'AGInitialsCaret')}>
                                    <div className="pull-left"> Initials</div>
                                    <div className="user_downarrow usr_dwn_arrow">
                                       <svg
                                          id="AGInitialsCaret" className={!this.state.User_AGInitials_Toggle ? "toggleCaretClass" : ''}
                                          xmlns="http://www.w3.org/2000/svg" width="12.337" height="6.887" viewBox="0 0 12.337 6.887">
                                          <path id="ic_arrow_drop_down_24px" d="M7,10l6.169,6.887L19.337,10Z" transform="translate(-7 -10)" fill="#30679a" />
                                       </svg>
                                    </div>
                                 </div>
                              </th>
                              <th width="20%" align="left" className="dep_td" >


                                 <div className="cursorPointer" onClick={() => this.sort_GroupTableData('Department', 'AGDeptCaret')}>
                                    <div className="pull-left "> Department</div>
                                    <div className="user_downarrow usr_dwn_arrow">
                                       <svg
                                          id="AGDeptCaret" className={!this.state.User_AGUserDepartment_Toggle ? "toggleCaretClass" : ''}
                                          xmlns="http://www.w3.org/2000/svg" width="12.337" height="6.887" viewBox="0 0 12.337 6.887">
                                          <path id="ic_arrow_drop_down_24px" d="M7,10l6.169,6.887L19.337,10Z" transform="translate(-7 -10)" fill="#30679a" />
                                       </svg>
                                    </div>
                                 </div>
                              </th>


                              <th width="10%" align="center">&nbsp;</th>
                           </tr>
                        </thead>
                        <tbody>
                           {this.state.GroupTableData.map(items => {
                              return (
                                 this.state.GroupTableData.length > 0 ?
                                    <tr key={items.Guid}>

                                       <td align="center">{items.ApproverGroup[0].FirstName}</td>
                                       <td align="center">{items.GroupName}
                                       </td>
                                       <td align="center" className="dep_td">{items.ApproverGroup[0].Initials}
                                       </td>
                                       <td align="center" className="dep_td">{items.ApproverGroup[0].Department}</td>

                                       <td align="center">
                                          <div className="dropdown menu_icon_user2">
                                             <button className="dropdown-toggle btn_user_menu" type="button" data-toggle="dropdown">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="4" viewBox="0 0 16 4">
                                                   <path id="ic_more_horiz_24px" d="M6,10a2,2,0,1,0,2,2A2.006,2.006,0,0,0,6,10Zm12,0a2,2,0,1,0,2,2A2.006,2.006,0,0,0,18,10Zm-6,0a2,2,0,1,0,2,2A2.006,2.006,0,0,0,12,10Z" transform="translate(-4 -10)" />
                                                </svg>
                                             </button>
                                             <ul className="dropdown-menu dropleft dropdown_menu_users_down dropdown_menu_user_1 dropdown_user_droppp">
                                                <li>
                                                   <a href={null} data-toggle="modal" data-target="#invite_user_email" className="svg_delete_suer_drop cursorPointer">
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
                                                         <path id="ic_mail_outline_24px" d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,14H4V8l8,5,8-5Zm-8-7L4,6H20Z" transform="translate(-2 -4)" fill="#7b7b7b" />
                                                      </svg>
                                                      Send Invite
                                 </a>
                                                </li>
                                                <li>
                                                   <a href={null} onClick={this.handleDeleteGroup(items.Guid)} >
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="10.111" height="13" viewBox="0 0 10.111 13">
                                                         <path id="ic_delete_24px" d="M5.722,14.556A1.449,1.449,0,0,0,7.167,16h5.778a1.449,1.449,0,0,0,1.444-1.444V5.889H5.722ZM15.111,3.722H12.583L11.861,3H8.25l-.722.722H5V5.167H15.111Z" transform="translate(-5 -3)" fill="#7b7b7b" />
                                                      </svg>
                                                      Delete
                                 </a>
                                                </li>
                                             </ul>
                                          </div>
                                          <a href={null} className="user_pullleft_edit usr_edt cursorPointer" onClick={this.getGrouptoedit(items.Guid)} data-toggle="modal" data-target="#user_form">
                                             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                <path id="ic_create_24px" className="cls-1" d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z" transform="translate(-3 -2.998)"></path>
                                             </svg>
                                          </a>
                                       </td>
                                    </tr>
                                    :
                                    <tr colspan="7">Loading .... </tr>
                              )
                           })}
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
            <div id="invite_user_email" className="modal fade" role="dialog">
               <div className="modal-dialog modal_middle_user modal-dialog-centered modal_no_scrol_employeee">
                  <div className="modal-content modal_middle_h_user2">
                     <div className="modal-header">
                        <button type="button" className="close close_modal_user" data-dismiss="modal">
                           <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                              <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B" />
                           </svg>
                        </button>
                        <h4 className="modal-title userl_modal_header">Invite User Email</h4>
                     </div>
                     <div className="modal-body user_pop_inviteemail">
                        <div className="col-xs-4 col-sm-2">Templates:</div>
                        <div className="col-xs-8 col-sm-4">
                           <select name="email_template_name" value={this.state.email_template_name} onChange={this.email_template_name_change}>
                              <option value=''>Select Template</option>
                              ({this.state.emailtemplates.map(item => {
                                 return (
                                    <option key={item.Name} value={item.Name}>{item.Name}</option>
                                 )
                              })})
</select>
                        </div>
                        <div className="clear20"></div>
                        <div className="col-sm-12">
                           Please enter any CC Emails and Notes to the user below. When you press send, an email will be sent to the user containing their login details, instructions on how to login, and help documentation.
               </div>
                        <div className="clear20"></div>
                        <div className="col-xs-4 col-sm-2 user_input_pop1">
                           CC:
               </div>
                        <div className="col-xs-8 col-sm-10 user_input_pop1">
                           <input name="User_InviteUserEmail_Cc"
                              type="text" value={this.state.User_InviteUserEmail_Cc}
                              onChange={this.UserInputsChngHandler}
                           />
                           <p className={"error validation " + (this.state.User_InviteUSerEmailErros.User_InviteUserEmail_Cc ? '' : 'displayNone')}>{this.state.User_InviteUSerEmailErros.User_InviteUserEmail_Cc}</p>
                        </div>
                        <div className="clear20"></div>
                        <div className="col-xs-4 col-sm-2 user_input_pop1">
                           Notes:
               </div>
                        <div className="col-xs-8 col-sm-10 user_input_pop1">
                           <textarea name="User_InviteUserEmail_Notes" value={this.state.User_InviteUserEmail_Notes} cols="" rows="" onChange={this.UserInputsChngHandler}></textarea>
                           <p className={"error validation " + (this.state.User_InviteUSerEmailErros.User_InviteUserEmail_Notes ? '' : 'displayNone')}>{this.state.User_InviteUSerEmailErros.User_InviteUserEmail_Notes}</p>
                        </div>
                        <div className="col-sm-12">
                           <div className="user_btn_save">
                              <a href={null} className="btn_save_user cursorPointer" onClick={this.UserInviteUserSendHandler}>Send</a>
                              <a href={null} className="btn_cancel_user cursorPointer" data-dismiss="modal">Cancel</a>
                           </div>
                           <div className="col-md-3"> </div>
                        </div>
                        <div className="clear10"></div>
                     </div>
                  </div>
               </div>
            </div>
            <div id="user_form" className="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
               <div className="modal-dialog pop_w_user">
                  <div className="modal-content pop_w_user">
                     <div className="modal-header user_hdr_pop2">
                        <button type="button" id="add_user_close" className="close close_modal_user" data-dismiss="modal" onClick={this.clearstates}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                              <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B" />
                           </svg>
                        </button>
                        <h4 className="modal-title">User Form
               </h4>
                     </div>




                     <div className="modal-body">

                        <div id="approver_group">
                           <div className="col-sm-12 table_attach_empl">
                              <div className="clear20"></div>
                              <div className="col-xs-12 p0 hdg_edit_detail_1">
                                 <div className="col-xs-6 p0">
                                    <h4>User Group</h4>
                                 </div>
                              </div>
                              <div className="clear10"></div>

                              <div className="col-md-12 col-lg-12 p0 user_edit_pop_input">
                                 <div className="col-xs-5 col-sm-6 col-md-4 label_dataentry"> User Type:</div>
                                 <div className="col-xs-7 col-sm-4 col-md-4">
                                    <select name="User_UserFormuserType" onChange={this.UserUserFormSelectOptHandlers} value={this.state.User_UserFormuserTypeValue}>
                                       <option value="">Select</option>
                                       <option value="Admin">Admin</option>
                                       <option value="SysAdmin">SysAdmin</option>
                                       <option value="Approver">Approver</option>
                                       <option value="Accounts">Accounts</option>
                                       <option value="Approver Group">Approver Group</option>
                                    </select>
                                    <p className={"error validation " + (this.state.User_UserFormErrors.User_UserFormuserType ? '' : 'displayNone')}>{this.state.User_UserFormErrors.User_UserFormuserType}</p>
                                 </div>
                                 <div className="clearfix"></div>

                                 <div className="col-xs-5 col-sm-6 col-md-4  label_dataentry">Group Name:</div>
                                 <div className="col-xs-7 col-sm-6 col-md-8">
                                    <input name="Group_Name" id="Group_Name"
                                       type="text" value={this.state.Group_Name}
                                       onChange={this.Groupchnghandler} />
                                 </div>
                                 <p className={"error validation " + (this.state.group_name_error !== '' ? '' : 'displayNone')}>{this.state.group_name_error}</p>
                                 <div className="clearfix"></div>

                                 <div className="col-xs-5 col-sm-6 col-md-4  label_dataentry">User Name:</div>
                                 <div className="col-xs-7 col-sm-6 col-md-8">
                                    <input name="Group_UName" id="Group_UName"
                                       type="text" value={this.state.Group_UName}
                                       onChange={this.Groupchnghandler} />
                                 </div>

                                 <div className="clearfix"></div>

                                 <div className="col-xs-5 col-sm-6 col-md-4  label_dataentry">Initials:</div>
                                 <div className="col-xs-7 col-sm-6 col-md-8">
                                    <input name="Group_Initials" id="Group_Initials"
                                       type="text" value={this.state.Group_Initials}
                                       onChange={this.Groupchnghandler} />
                                 </div>

                                 <div className="clearfix"></div>

                                 <div className="col-xs-5 col-sm-6 col-md-4  label_dataentry">Department:</div>
                                 <div className="col-xs-7 col-sm-6 col-md-8">
                                    <input name="Group_Department" id="Group_Department"
                                       type="text" value={this.state.Group_Department}
                                       onChange={this.Groupchnghandler} />
                                 </div>

                                 <div className="clearfix"></div>
                                 <div className="col-sm-12">
                                    <div className="user_btn_save">
                                       {that.state.tmp_check === 'update' ?
                                          <a href={null} className="btn_save_user cursorPointer" onClick={this.usergroup_update}>Update</a>
                                          :
                                          <a href={null} className="btn_save_user cursorPointer" onClick={this.Group_Submit}>save</a>
                                       }
                                       <a href={null} className="btn_cancel_user cursorPointer" data-dismiss="modal" onClick={this.clearstates}>Cancel</a>
                                    </div>
                                    <div className="col-md-3"> </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div id='other_user'>


                           <div className="col-sm-12 table_attach_empl">
                              <div className="clear20"></div>
                              <div className="col-xs-12 p0 hdg_edit_detail_1">
                                 <div className="col-xs-6 p0">
                                    <h4>User Details</h4>
                                 </div>
                                 <div className="col-xs-6 p0 text-right svg_icon_lsit_pop">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8.027" viewBox="0 0 13 8.027">
                                       <path id="ic_expand_more_24px" d="M17.473,8.59,12.5,13.552,7.528,8.59,6,10.118l6.5,6.5,6.5-6.5Z" transform="translate(-6 -8.59)" fill="#86878A" />
                                    </svg>
                                 </div>
                              </div>
                              <div className="clear10"></div>
                              <div className="col-md-12 col-lg-12 p0 user_edit_pop_input">
                                 <div className="col-xs-5 col-sm-6 col-md-4 label_dataentry"> User Type:</div>
                                 <div className="col-xs-7 col-sm-4 col-md-4">
                                    <select name="User_UserFormuserType" onChange={this.UserUserFormSelectOptHandlers} value={this.state.User_UserFormuserTypeValue}>
                                       <option value="">Select</option>
                                       <option value="Admin">Admin</option>
                                       <option value="SysAdmin">SysAdmin</option>
                                       <option value="Approver">Approver</option>
                                       <option value="Accounts">Accounts</option>
                                       <option value="Approver Group">Approver Group</option>

                                    </select>
                                    <p className={"error validation " + (this.state.User_UserFormErrors.User_UserFormuserType ? '' : 'displayNone')}>{this.state.User_UserFormErrors.User_UserFormuserType}</p>
                                 </div>
                                 <div className="clearfix"></div>



                                 <div className="col-xs-5 col-sm-6 col-md-4 label_dataentry"> User Login:</div>
                                 <div className="col-xs-7 col-sm-6 col-md-8">
                                    <input name="User_UserFormuserLogin"
                                       type="text"
                                       value={this.state.User_UserFormuserLogin} onChange={this.UserInputsChngHandler} />
                                 </div>
                                 <p className={"error validation " + (this.state.User_UserFormErrors.User_UserFormuserLogin ? '' : 'displayNone')}>{this.state.User_UserFormErrors.User_UserFormuserLogin}</p>
                                 <div className="clearfix"></div>
                                 <div className="col-xs-5 col-sm-6 col-md-4  label_dataentry">User Name:</div>
                                 <div className="col-xs-7 col-sm-6 col-md-8">
                                    <input name="User_UserFormuserName" id="User_UserFormuserName"
                                       type="text" value={this.state.User_UserFormuserName}
                                       onChange={this.UserInputsChngHandler} />
                                 </div>
                                 <p className={"error validation " + (this.state.User_UserFormErrors.User_UserFormuserName ? '' : 'displayNone')}>{this.state.User_UserFormErrors.User_UserFormuserName}</p>
                                 <div className="clearfix"></div>
                                 <div className="col-xs-5 col-sm-6 col-md-4 label_dataentry">Initials:</div>
                                 <div className="col-xs-7 col-sm-6 col-md-8">
                                    <input name="User_UserFormuserInitials" id="User_UserFormuserInitials"
                                       value={this.state.User_UserFormuserInitials}
                                       onChange={this.UserInputsChngHandler} type="text" />
                                 </div>
                                 <p className={"error validation " + (this.state.User_UserFormErrors.User_UserFormuserInitials ? '' : 'displayNone')}>{this.state.User_UserFormErrors.User_UserFormuserInitials}</p>
                                 <div className="clearfix"></div>
                                 <div className="col-xs-5 col-sm-6 col-md-4  label_dataentry">Email Address:</div>
                                 <div className="col-xs-7 col-sm-6 col-md-8">
                                    <input onBlur={this.CheckImage} name="User_UserFormuserEmail" id="User_UserFormuserEmail"
                                       value={this.state.User_UserFormuserEmail}
                                       readOnly={this.state.tmp_Guid !== "" ? true : false}
                                       onChange={this.UserInputsChngHandler} type="text" />
                                 </div>
                                 <p className={"error validation " + (this.state.User_UserFormErrors.User_UserFormuserEmail ? '' : 'displayNone')}>{this.state.User_UserFormErrors.User_UserFormuserEmail}</p>
                                 <div className="clearfix"></div>
                                 <div className="col-xs-5 col-sm-6 col-md-4  label_dataentry">Country Code:</div>
                                 <div className="col-xs-7 col-sm-4 col-md-4">
                                    <select name="User_UserFormCountryCode" value={this.state.User_UserFormCountryCode} onChange={(event) => this.handleCustomFieldsModalInputsCountry(event)}>
                                       <option>Please Select Country</option>
                                       <option value="61">Australia (+61)</option>
                                       <option value="44">UK (+44)</option>
                                       <option value="1">USA (+1)</option>
                                       <option value="213">Algeria (+213)</option>
                                       <option value="376">Andorra (+376)</option>
                                       <option value="244">Angola (+244)</option>
                                       <option value="1264">Anguilla (+1264)</option>
                                       <option value="1268">Antigua &amp; Barbuda (+1268)</option>
                                       <option value="54">Argentina (+54)</option>
                                       <option value="374">Armenia (+374)</option>
                                       <option value="297">Aruba (+297)</option>
                                       <option value="43">Austria (+43)</option>
                                       <option value="994">Azerbaijan (+994)</option>
                                       <option value="1242">Bahamas (+1242)</option>
                                       <option value="973">Bahrain (+973)</option>
                                       <option value="880">Bangladesh (+880)</option>
                                       <option value="1246">Barbados (+1246)</option>
                                       <option value="375">Belarus (+375)</option>
                                       <option value="32">Belgium (+32)</option>
                                       <option value="501">Belize (+501)</option>
                                       <option value="229">Benin (+229)</option>
                                       <option value="1441">Bermuda (+1441)</option>
                                       <option value="975">Bhutan (+975)</option>
                                       <option value="591">Bolivia (+591)</option>
                                       <option value="387">Bosnia Herzegovina (+387)</option>
                                       <option value="267">Botswana (+267)</option>
                                       <option value="55">Brazil (+55)</option>
                                       <option value="673">Brunei (+673)</option>
                                       <option value="359">Bulgaria (+359)</option>
                                       <option value="226">Burkina Faso (+226)</option>
                                       <option value="257">Burundi (+257)</option>
                                       <option value="855">Cambodia (+855)</option>
                                       <option value="237">Cameroon (+237)</option>
                                       <option value="1">Canada (+1)</option>
                                       <option value="238">Cape Verde Islands (+238)</option>
                                       <option value="1345">Cayman Islands (+1345)</option>
                                       <option value="236">Central African Republic (+236)</option>
                                       <option value="56">Chile (+56)</option>
                                       <option value="86">China (+86)</option>
                                       <option value="57">Colombia (+57)</option>
                                       <option value="269">Comoros (+269)</option>
                                       <option value="242">Congo (+242)</option>
                                       <option value="682">Cook Islands (+682)</option>
                                       <option value="506">Costa Rica (+506)</option>
                                       <option value="385">Croatia (+385)</option>
                                       <option value="53">Cuba (+53)</option>
                                       <option value="90392">Cyprus North (+90392)</option>
                                       <option value="357">Cyprus South (+357)</option>
                                       <option value="42">Czech Republic (+42)</option>
                                       <option value="45">Denmark (+45)</option>
                                       <option value="253">Djibouti (+253)</option>
                                       <option value="1809">Dominica (+1809)</option>
                                       <option value="1809">Dominican Republic (+1809)</option>
                                       <option value="593">Ecuador (+593)</option>
                                       <option value="20">Egypt (+20)</option>
                                       <option value="503">El Salvador (+503)</option>
                                       <option value="240">Equatorial Guinea (+240)</option>
                                       <option value="291">Eritrea (+291)</option>
                                       <option value="372">Estonia (+372)</option>
                                       <option value="251">Ethiopia (+251)</option>
                                       <option value="500">Falkland Islands (+500)</option>
                                       <option value="298">Faroe Islands (+298)</option>
                                       <option value="679">Fiji (+679)</option>
                                       <option value="358">Finland (+358)</option>
                                       <option value="33">France (+33)</option>
                                       <option value="594">French Guiana (+594)</option>
                                       <option value="689">French Polynesia (+689)</option>
                                       <option value="241">Gabon (+241)</option>
                                       <option value="220">Gambia (+220)</option>
                                       <option value="7880">Georgia (+7880)</option>
                                       <option value="49">Germany (+49)</option>
                                       <option value="233">Ghana (+233)</option>
                                       <option value="350">Gibraltar (+350)</option>
                                       <option value="30">Greece (+30)</option>
                                       <option value="299">Greenland (+299)</option>
                                       <option value="1473">Grenada (+1473)</option>
                                       <option value="590">Guadeloupe (+590)</option>
                                       <option value="671">Guam (+671)</option>
                                       <option value="502">Guatemala (+502)</option>
                                       <option value="224">Guinea (+224)</option>
                                       <option value="245">Guinea - Bissau (+245)</option>
                                       <option value="592">Guyana (+592)</option>
                                       <option value="509">Haiti (+509)</option>
                                       <option value="504">Honduras (+504)</option>
                                       <option value="852">Hong Kong (+852)</option>
                                       <option value="36">Hungary (+36)</option>
                                       <option value="354">Iceland (+354)</option>
                                       <option value="91">India (+91)</option>
                                       <option value="62">Indonesia (+62)</option>
                                       <option value="98">Iran (+98)</option>
                                       <option value="964">Iraq (+964)</option>
                                       <option value="353">Ireland (+353)</option>
                                       <option value="972">Israel (+972)</option>
                                       <option value="39">Italy (+39)</option>
                                       <option value="1876">Jamaica (+1876)</option>
                                       <option value="81">Japan (+81)</option>
                                       <option value="962">Jordan (+962)</option>
                                       <option value="7">Kazakhstan (+7)</option>
                                       <option value="254">Kenya (+254)</option>
                                       <option value="686">Kiribati (+686)</option>
                                       <option value="850">Korea North (+850)</option>
                                       <option value="82">Korea South (+82)</option>
                                       <option value="965">Kuwait (+965)</option>
                                       <option value="996">Kyrgyzstan (+996)</option>
                                       <option value="856">Laos (+856)</option>
                                       <option value="371">Latvia (+371)</option>
                                       <option value="961">Lebanon (+961)</option>
                                       <option value="266">Lesotho (+266)</option>
                                       <option value="231">Liberia (+231)</option>
                                       <option value="218">Libya (+218)</option>
                                       <option value="417">Liechtenstein (+417)</option>
                                       <option value="370">Lithuania (+370)</option>
                                       <option value="352">Luxembourg (+352)</option>
                                       <option value="853">Macao (+853)</option>
                                       <option value="389">Macedonia (+389)</option>
                                       <option value="261">Madagascar (+261)</option>
                                       <option value="265">Malawi (+265)</option>
                                       <option value="60">Malaysia (+60)</option>
                                       <option value="960">Maldives (+960)</option>
                                       <option value="223">Mali (+223)</option>
                                       <option value="356">Malta (+356)</option>
                                       <option value="692">Marshall Islands (+692)</option>
                                       <option value="596">Martinique (+596)</option>
                                       <option value="222">Mauritania (+222)</option>
                                       <option value="269">Mayotte (+269)</option>
                                       <option value="52">Mexico (+52)</option>
                                       <option value="691">Micronesia (+691)</option>
                                       <option value="373">Moldova (+373)</option>
                                       <option value="377">Monaco (+377)</option>
                                       <option value="976">Mongolia (+976)</option>
                                       <option value="1664">Montserrat (+1664)</option>
                                       <option value="212">Morocco (+212)</option>
                                       <option value="258">Mozambique (+258)</option>
                                       <option value="95">Myanmar (+95)</option>
                                       <option value="264">Namibia (+264)</option>
                                       <option value="674">Nauru (+674)</option>
                                       <option value="977">Nepal (+977)</option>
                                       <option value="31">Netherlands (+31)</option>
                                       <option value="687">New Caledonia (+687)</option>
                                       <option value="64">New Zealand (+64)</option>
                                       <option value="505">Nicaragua (+505)</option>
                                       <option value="227">Niger (+227)</option>
                                       <option value="234">Nigeria (+234)</option>
                                       <option value="683">Niue (+683)</option>
                                       <option value="672">Norfolk Islands (+672)</option>
                                       <option value="670">Northern Marianas (+670)</option>
                                       <option value="47">Norway (+47)</option>
                                       <option value="968">Oman (+968)</option>
                                       <option value="92">Pakistan (+92)</option>
                                       <option value="680">Palau (+680)</option>
                                       <option value="507">Panama (+507)</option>
                                       <option value="675">Papua New Guinea (+675)</option>
                                       <option value="595">Paraguay (+595)</option>
                                       <option value="51">Peru (+51)</option>
                                       <option value="63">Philippines (+63)</option>
                                       <option value="48">Poland (+48)</option>
                                       <option value="351">Portugal (+351)</option>
                                       <option value="1787">Puerto Rico (+1787)</option>
                                       <option value="974">Qatar (+974)</option>
                                       <option value="262">Reunion (+262)</option>
                                       <option value="40">Romania (+40)</option>
                                       <option value="7">Russia (+7)</option>
                                       <option value="250">Rwanda (+250)</option>
                                       <option value="378">San Marino (+378)</option>
                                       <option value="239">Sao Tome &amp; Principe (+239)</option>
                                       <option value="966">Saudi Arabia (+966)</option>
                                       <option value="221">Senegal (+221)</option>
                                       <option value="381">Serbia (+381)</option>
                                       <option value="248">Seychelles (+248)</option>
                                       <option value="232">Sierra Leone (+232)</option>
                                       <option value="65">Singapore (+65)</option>
                                       <option value="421">Slovak Republic (+421)</option>
                                       <option value="386">Slovenia (+386)</option>
                                       <option value="677">Solomon Islands (+677)</option>
                                       <option value="252">Somalia (+252)</option>
                                       <option value="27">South Africa (+27)</option>
                                       <option value="34">Spain (+34)</option>
                                       <option value="94">Sri Lanka (+94)</option>
                                       <option value="290">St. Helena (+290)</option>
                                       <option value="1869">St. Kitts (+1869)</option>
                                       <option value="1758">St. Lucia (+1758)</option>
                                       <option value="249">Sudan (+249)</option>
                                       <option value="597">Suriname (+597)</option>
                                       <option value="268">Swaziland (+268)</option>
                                       <option value="46">Sweden (+46)</option>
                                       <option value="41">Switzerland (+41)</option>
                                       <option value="963">Syria (+963)</option>
                                       <option value="886">Taiwan (+886)</option>
                                       <option value="7">Tajikstan (+7)</option>
                                       <option value="66">Thailand (+66)</option>
                                       <option value="228">Togo (+228)</option>
                                       <option value="676">Tonga (+676)</option>
                                       <option value="1868">Trinidad &amp; Tobago (+1868)</option>
                                       <option value="216">Tunisia (+216)</option>
                                       <option value="90">Turkey (+90)</option>
                                       <option value="7">Turkmenistan (+7)</option>
                                       <option value="993">Turkmenistan (+993)</option>
                                       <option value="1649">Turks & Caicos Islands (+1649)</option>
                                       <option value="688">Tuvalu (+688)</option>
                                       <option value="256">Uganda (+256)</option>
                                       <option value="44">UK (+44)</option>
                                       <option value="380">Ukraine (+380)</option>
                                       <option value="971">United Arab Emirates (+971)</option>
                                       <option value="598">Uruguay (+598)</option>
                                       <option value="1">USA (+1)</option>
                                       <option value="7">Uzbekistan (+7)</option>
                                       <option value="678">Vanuatu (+678)</option>
                                       <option value="379">Vatican City (+379)</option>
                                       <option value="58">Venezuela (+58)</option>
                                       <option value="84">Vietnam (+84)</option>
                                       <option value="84">Virgin Islands - British (+1284)</option>
                                       <option value="84">Virgin Islands - US (+1340)</option>
                                       <option value="681">Wallis &amp; Futuna (+681)</option>
                                       <option value="969">Yemen (North)(+969)</option>
                                       <option value="967">Yemen (South)(+967)</option>
                                       <option value="260">Zmbaia (+260)</option>
                                       <option value="263">Zimbabwe (+263)</option>
                                    </select>
                                    <p className={"error validation " + (this.state.User_UserFormErrors.User_UserFormCountryCode ? '' : 'displayNone')}>{this.state.User_UserFormErrors.User_UserFormCountryCode}</p>
                                 </div>
                                 <div className="clearfix"></div>
                                 <div className="col-xs-5 col-sm-6 col-md-4  label_dataentry">Mobile Number:</div>
                                 <div className="col-xs-7 col-sm-6 col-md-8">
                                    <input name="User_UserFormuserMobNo" value={this.state.User_UserFormuserMobNo}
                                       onChange={this.UserInputsChngHandler}
                                       type="text" />
                                    <p className={"error validation " + (this.state.User_UserFormErrors.User_UserFormuserMobNo ? '' : 'displayNone')}>{this.state.User_UserFormErrors.User_UserFormuserMobNo}</p>
                                 </div>
                                 <div className="clearfix"></div>
                                 <div className="col-xs-5 col-sm-6 col-md-4  label_dataentry">Department:</div>
                                 <div className="col-xs-7 col-sm-6 col-md-8">
                                    <input name="User_UserFormuserDepartment" value={this.state.User_UserFormuserDepartment}
                                       onChange={this.UserInputsChngHandler} type="text" />
                                 </div>
                                 <p className={"error validation " + (this.state.User_UserFormErrors.User_UserFormuserDepartment ? '' : 'displayNone')}>{this.state.User_UserFormErrors.User_UserFormuserDepartment}</p>
                                 <div className="clearfix"></div>
                                 <div className="col-xs-5 col-sm-6 col-md-4  label_dataentry">Team Group:</div>
                                 <div className="col-xs-7 col-sm-6 col-md-8">
                                    <input name="User_UserFormuserTeamgroup"
                                       value={this.state.User_UserFormuserTeamgroup}
                                       onChange={this.UserInputsChngHandler} type="text" />
                                 </div>
                                 <p className={"error validation " + (this.state.User_UserFormErrors.User_UserFormuserTeamgroup ? '' : 'displayNone')}>{this.state.User_UserFormErrors.User_UserFormuserTeamgroup}</p>
                                 <div className="clearfix"></div>
                                 <div className="col-xs-5 col-sm-6 col-md-4  label_dataentry">Team Groups to View:</div>
                                 <div className="col-xs-7 col-sm-6 col-md-8">
                                    <input name="User_UserFormuserTeamGroupToView"
                                       value={this.state.team_values.toString()}
                                       type="text"
                                       readOnly />
                                    <p className={"error validation " + (this.state.User_UserFormErrors.User_UserFormuserTeamGroupToView ? '' : 'displayNone')}>{this.state.User_UserFormErrors.User_UserFormuserTeamGroupToView}</p>
                                    <a href={null} className="search_user_pop2 cursorPointer" data-toggle="modal" data-target="#user_search_team">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                          <path id="ic_search_24px" d="M17.294,15.579h-.9l-.32-.309a7.444,7.444,0,1,0-.8.8l.309.32v.9L21.3,23,23,21.3Zm-6.861,0a5.146,5.146,0,1,1,5.146-5.146A5.139,5.139,0,0,1,10.433,15.579Z" transform="translate(-3 -3)" fill="#c4c4c4" />
                                       </svg>
                                    </a>
                                 </div>
                                 <div className="clearfix"></div>
                                 <div className="clear40"></div>
                              </div>
                              <div className="clearfix"></div>
                              <div id="aprover_details">
                                 <div className="col-xs-12 p0 hdg_edit_detail_1">
                                    <div className="col-xs-6 p0">
                                       <h4>Approver Details</h4>
                                    </div>
                                    <div className="col-xs-6 p0 text-right svg_icon_lsit_pop">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8.027" viewBox="0 0 13 8.027">
                                          <path id="ic_expand_more_24px" d="M17.473,8.59,12.5,13.552,7.528,8.59,6,10.118l6.5,6.5,6.5-6.5Z" transform="translate(-6 -8.59)" fill="#86878A" />
                                       </svg>
                                    </div>
                                 </div>
                                 <div className="clear10"></div>
                                 <div className="col-md-12 col-lg-12 p0 user_edit_pop_input">
                                    <div className="col-xs-5 col-sm-6 col-md-4 label_dataentry"> Send Reminder every:</div>
                                    <div className="col-xs-7 col-sm-6 col-md-5">
                                       <select name="User_UserFormSendReminderEveryDay" onChange={this.UserUserFormSelectOptHandlers}>
                                          <option value="0 Hr">0 Hr</option>
                                          <option value="1 Hr">1 Hr</option>
                                          <option value="4 Hr">4 Hr</option>
                                          <option value="8 Hr">8 Hr</option>
                                          <option value="24 Hr">24 Hr</option>
                                       </select>
                                       <p className={"error validation " + (this.state.User_UserFormErrors.User_UserFormSendReminderEveryDay ? '' : 'displayNone')}>{this.state.User_UserFormErrors.User_UserFormSendReminderEveryDay}</p>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="col-xs-5 col-sm-6 col-md-4 label_dataentry"> Signature File:</div>
                                    <div className="col-xs-7 col-sm-6 col-md-8">
                                       {this.state.alreadySignature === '' ?
                                          <div className="col-sm-3 user_signture_img"><img src={this.state.signature} className="img-responsive" alt="" /></div>
                                          :
                                          <div className="col-sm-3 user_signture_img"><img src={this.state.alreadySignature} className="img-responsive" alt="" /></div>
                                       }
                                       <div className="col-sm-3">
                                          <a href={null} id='open_edit_signature' className="btn_cancel_user mrg_top10_sign cursorPointer displayNone" data-toggle="modal" data-target="#user_edit_signpop">Edit</a>
                                          <a href={null} onClick={this.opensignature_popup} className="btn_cancel_user mrg_top10_sign cursorPointer">Edit</a>
                                       </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="clear40"></div>
                                 </div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="col-xs-12 p0 hdg_edit_detail_1">
                                 <div className="col-xs-6 p0">
                                    <h4>Access</h4>
                                 </div>
                                 <div className="col-xs-6 p0 text-right svg_icon_lsit_pop">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8.027" viewBox="0 0 13 8.027">
                                       <path id="ic_expand_more_24px" d="M17.473,8.59,12.5,13.552,7.528,8.59,6,10.118l6.5,6.5,6.5-6.5Z" transform="translate(-6 -8.59)" fill="#86878A" />
                                    </svg>
                                 </div>
                              </div>
                              <div className="clear10"></div>
                              <div className="col-md-12 col-lg-12 p0 user_edit_pop_input">
                                 <div className="col-xs-8 col-sm-3 label_user_access">Timecards:</div>
                                 <div className="col-xs-2 col-sm-1 checkbox_user_access">
                                    <div className="checkbox">
                                       <input id="IsUser_UserFormuserTimecardChkboxChecked" name="IsUser_UserFormuserTimecardChkboxChecked" type="checkbox"
                                          checked={this.state.IsUser_UserFormuserTimecardChkboxChecked}
                                          onChange={this.UserInputsChngHandler} />
                                       <label htmlFor="IsUser_UserFormuserTimecardChkboxChecked">
                                       </label>
                                    </div>
                                 </div>
                                 <div className="col-xs-8 col-sm-3 label_user_access">Employee List:</div>
                                 <div className="col-xs-2 col-sm-1 checkbox_user_access">
                                    <div className="checkbox">
                                       <input id="IsUser_UserFormuserEmployeeListChkboxChecked"
                                          name="IsUser_UserFormuserEmployeeListChkboxChecked" type="checkbox"
                                          checked={this.state.IsUser_UserFormuserEmployeeListChkboxChecked}
                                          onChange={this.UserInputsChngHandler} />
                                       <label htmlFor="IsUser_UserFormuserEmployeeListChkboxChecked">
                                       </label>
                                    </div>
                                 </div>
                                 <div className="col-xs-8 col-sm-3 label_user_access">Approval Setup:</div>
                                 <div className="col-xs-2 col-sm-1 checkbox_user_access">
                                    <div className="checkbox">
                                       <input id="IsUser_UserFormuserApprovalSetupChkboxChecked" type="checkbox" name="IsUser_UserFormuserApprovalSetupChkboxChecked"
                                          checked={this.state.IsUser_UserFormuserApprovalSetupChkboxChecked}
                                          onChange={this.UserInputsChngHandler} />
                                       <label htmlFor="IsUser_UserFormuserApprovalSetupChkboxChecked">
                                       </label>
                                    </div>
                                 </div>
                                 <div className="col-xs-8 col-sm-3 label_user_access">Payroll Batch:</div>
                                 <div className="col-xs-2 col-sm-1 checkbox_user_access">
                                    <div className="checkbox">
                                       <input id="IsUser_UserFormuserPayrollBatchesChkboxChecked" name="IsUser_UserFormuserPayrollBatchesChkboxChecked" type="checkbox"
                                          checked={this.state.IsUser_UserFormuserPayrollBatchesChkboxChecked}
                                          onChange={this.UserInputsChngHandler} />
                                       <label htmlFor="IsUser_UserFormuserPayrollBatchesChkboxChecked">
                                       </label>
                                    </div>
                                 </div>
                                 <div className="col-xs-8 col-sm-3 label_user_access">Users:</div>
                                 <div className="col-xs-2 col-sm-1 checkbox_user_access">
                                    <div className="checkbox">
                                       <input id="IsUser_UserFormuserUserChkboxChecked" name="IsUser_UserFormuserUserChkboxChecked" type="checkbox"
                                          checked={this.state.IsUser_UserFormuserUserChkboxChecked}
                                          onChange={this.UserInputsChngHandler} />
                                       <label htmlFor="IsUser_UserFormuserUserChkboxChecked">
                                       </label>
                                    </div>
                                 </div>
                                 <div className="col-xs-8 col-sm-3 label_user_access">Email Template:</div>
                                 <div className="col-xs-3 col-sm-1 checkbox_user_access">
                                    <div className="checkbox">
                                       <input id="IsUser_UserFormuserEmailTemplateChkboxChecked" name="IsUser_UserFormuserEmailTemplateChkboxChecked" type="checkbox"
                                          checked={this.state.IsUser_UserFormuserEmailTemplateChkboxChecked}
                                          onChange={this.UserInputsChngHandler} />
                                       <label htmlFor="IsUser_UserFormuserEmailTemplateChkboxChecked">
                                       </label>
                                    </div>
                                 </div>
                                 <div className="clear30"></div>

                                 {localStorage.getItem('role') !== 'Admin' ?
                                    <div> <div className="col-xs-5 col-sm-6 col-md-4 label_dataentry"> Tenant:</div>
                                       <div className="col-xs-7 col-sm-4 col-md-4">
                                          <select onChange={this.refreshtenant} disabled={role == 'SysAdmin' ? false : true} value={this.state.cId} name="Tenant_get" >
                                             <option value="">Select</option>
                                             {this.state.dropdown_tenant_CoyID.map(value => {
                                                return (<option key={value} value={value}>{value}</option>);
                                             })
                                             }
                                          </select>
                                       </div>
                                       <div className="clearfix"></div></div> : ''
                                 }
                                 <div id="template_div">
                                    <div className="col-sm-10 col-sm-offset-1 user_accress_box">
                                       {this.state.all_Tenants.map(value => {
                                          var chk;
                                          if (that.state.AccessibleCompanies.indexOf(value.TenantGuid) > -1) {
                                             chk = true;
                                          } else {
                                             chk = false;
                                          }
                                          return (
                                             <div className="col-sm-12 p0 accecss_box_check_bdr" key={value.Guid}>
                                                <div className="col-xs-3 col-sm-2 checkbox_user_access">
                                                   <div className="" >
                                                      {localStorage.getItem('tenantid').indexOf(value.TenantGuid) > -1 ?
                                                         <input value={value.CoyID} id={value.TenantGuid} checked={chk}
                                                            name="IsUser_UserFromAccessChkBoxChecked" type="checkbox"
                                                            onChange={this.accessibleCompanies}
                                                            disabled={role == 'SysAdmin' ? false : true}
                                                         /> :
                                                         <input value={value.CoyID} id={value.TenantGuid} checked={chk}
                                                            name="IsUser_UserFromAccessChkBoxChecked" type="checkbox"
                                                            //onChange={this.accessibleCompanies}
                                                            disabled />
                                                      }
                                                      <label htmlFor="IsUser_UserFromAccessChkBoxChecked">
                                                      </label>
                                                   </div>
                                                </div>
                                                <div className="col-xs-9 col-sm-9 label_user_access">{value.CompanyName}</div>
                                             </div>
                                          )
                                       })
                                       }



                                       <div className="clearfix"></div>






                                       <div className="clear20"></div>
                                    </div>
                                 </div>
                                 <div className="clear40"></div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="col-xs-12 p0 hdg_edit_detail_1">
                                 <div className="col-xs-6 p0">
                                    <h4>Security</h4>
                                 </div>
                                 <div className="col-xs-6 p0 text-right svg_icon_lsit_pop">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8.027" viewBox="0 0 13 8.027">
                                       <path id="ic_expand_more_24px" d="M17.473,8.59,12.5,13.552,7.528,8.59,6,10.118l6.5,6.5,6.5-6.5Z" transform="translate(-6 -8.59)" fill="#86878A" />
                                    </svg>
                                 </div>
                              </div>
                              <div className="clear10"></div>
                              <div className="col-md-12 col-lg-10 p0 user_edit_pop_input">
                                 <div className="clearfix"></div>
                                 <div className="col-xs-5 col-sm-6 col-md-4 label_dataentry"> PW Expire Days:</div>
                                 <div className="col-xs-7 col-sm-6 col-md-8">
                                    <input name="User_UserFormuserPWDExpireDays"
                                       value={this.state.User_UserFormuserPWDExpireDays}
                                       type="Number"
                                       onChange={this.UserInputsChngHandler}
                                    />
                                    <p className={"error validation " + (this.state.User_UserFormErrors.User_UserFormuserPWDExpireDays ? '' : 'displayNone')}>{this.state.User_UserFormErrors.User_UserFormuserPWDExpireDays}</p>
                                 </div>
                                 <div className="clearfix"></div>
                                 <div className="col-xs-5 col-sm-6 col-md-4 label_dataentry">Password:</div>
                                 <div className="col-xs-7 col-sm-6 col-md-8">
                                    <select name="User_UserFormPassword" value={this.state.User_UserFormPassword} onChange={this.UserUserFormSelectOptHandlers}>
                                       <option value="">Select</option>
                                       <option value="Must Change">Must Change</option>
                                       <option value="Can't Change">Can't Change</option>
                                       <option value="Never Expires">Never Expires</option>
                                    </select>
                                 </div>
                                 <div className="clearfix"></div>
                                 <div className="col-xs-5 col-sm-6 col-md-4 label_dataentry">Account Disabled:</div>
                                 <div className="col-xs-7 col-sm-6 col-md-8">
                                    <div className="checkbox">
                                       <input id="IsUserFormUserSecurityAccountDisabledChecked"
                                          name="IsUserFormUserSecurityAccountDisabledChecked"
                                          type="checkbox"
                                          checked={this.state.IsUserFormUserSecurityAccountDisabledChecked}
                                          onChange={this.UserInputsChngHandler}
                                       />
                                       <label htmlFor="IsUserFormUserSecurityAccountDisabledChecked">
                                       </label>
                                    </div>
                                 </div>
                                 <div className="clear10"></div>
                                 <div className="col-xs-5 col-sm-6 col-md-4 label_dataentry">Locked Out:</div>
                                 <div className="col-xs-7 col-sm-6 col-md-8">
                                    <div className="checkbox">
                                       <input id="IsUserFormUserSecurityAccountLockedOutChecked"
                                          name="IsUserFormUserSecurityAccountLockedOutChecked"
                                          checked={this.state.IsUserFormUserSecurityAccountLockedOutChecked}
                                          onChange={this.UserInputsChngHandler}
                                          type="checkbox" />
                                       <label htmlFor="IsUserFormUserSecurityAccountLockedOutChecked">
                                       </label>
                                    </div>
                                 </div>
                                 <div className="clear10"></div>
                                 <div className="col-xs-5 col-sm-6 col-md-4 label_dataentry">Factor Authentication:</div>
                                 <div className="col-xs-7 col-sm-6 col-md-8">
                                    <div className="checkbox">
                                       <input id="IsUserFormUserSecurityAccountFactorAuthenticateChecked"
                                          name="IsUserFormUserSecurityAccountFactorAuthenticateChecked"
                                          checked={this.state.IsUserFormUserSecurityAccountFactorAuthenticateChecked}
                                          onChange={this.UserInputsChngHandler}
                                          type="checkbox" />
                                       <label htmlFor="IsUserFormUserSecurityAccountFactorAuthenticateChecked">
                                       </label>
                                    </div>
                                 </div>
                                 <div className="clear10"></div>
                                 <div className="col-xs-5 col-sm-6 col-md-4 label_dataentry">Inactivity Lockout Days:</div>
                                 <div className="col-xs-7 col-sm-6 col-md-8">
                                    <input name="User_UserFormuserInActivityLockDays"
                                       value={this.state.User_UserFormuserInActivityLockDays}
                                       type="number"
                                       onChange={this.UserInputsChngHandler}
                                    />
                                    <p className={"error validation " + (this.state.User_UserFormErrors.User_UserFormuserInActivityLockDays ? '' : 'displayNone')}>{this.state.User_UserFormErrors.User_UserFormuserInActivityLockDays}</p>
                                 </div>
                                 <div className="clearfix"></div>
                                 <div className="col-xs-5 col-sm-6 col-md-4 label_dataentry">Access Date:</div>
                                 <div className="col-xs-6 col-sm-6 col-md-4">
                                    <input name="User_UserFormuserPWDAccessDate"
                                       value={this.state.User_UserFormuserPWDAccessDate} type="text"
                                       onChange={this.UserInputsChngHandler}
                                       readOnly />
                                    <p className={"error validation " + (this.state.User_UserFormErrors.User_UserFormuserPWDAccessDate ? '' : 'displayNone')}>{this.state.User_UserFormErrors.User_UserFormuserPWDAccessDate}</p>
                                 </div>
                                 <div className="col-xs-1 col-sm-6 col-md-1 calendar_user_pop2">
                                    <a href={null} className="cursorPointer">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
                                          <path id="ic_date_range_24px" d="M9,11H7v2H9Zm4,0H11v2h2Zm4,0H15v2h2Zm2-7H18V2H16V4H8V2H6V4H5A1.991,1.991,0,0,0,3.01,6L3,20a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,19,4Zm0,16H5V9H19Z" transform="translate(-3 -2)" fill="#2376D4" />
                                       </svg>
                                    </a>
                                 </div>
                                 <div className="clearfix"></div>
                                 <div className="col-xs-5 col-sm-6 col-md-4 label_dataentry">Lockout Date:</div>
                                 <div className="col-xs-6 col-sm-6 col-md-4">
                                    <input name="User_UserFormuserPWDLockOutDate"
                                       value={this.state.User_UserFormuserPWDLockOutDate} type="text"
                                       onChange={this.UserInputsChngHandler}
                                       readOnly />
                                    <p className={"error validation " + (this.state.User_UserFormErrors.User_UserFormuserPWDLockOutDate ? '' : 'displayNone')}>{this.state.User_UserFormErrors.User_UserFormuserPWDLockOutDate}</p>
                                 </div>
                                 <div className="col-xs-1 col-sm-6 col-md-1 calendar_user_pop2">
                                    <a href={null} className="cursorPointer">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
                                          <path id="ic_date_range_24px" d="M9,11H7v2H9Zm4,0H11v2h2Zm4,0H15v2h2Zm2-7H18V2H16V4H8V2H6V4H5A1.991,1.991,0,0,0,3.01,6L3,20a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,19,4Zm0,16H5V9H19Z" transform="translate(-3 -2)" fill="#2376D4" />
                                       </svg>
                                    </a>
                                 </div>
                                 <div className="clearfix"></div>
                                 <div className="col-xs-6 col-sm-6 col-md-4 label_dataentry">Last Login:</div>
                                 <div className="col-xs-6 col-sm-6 col-md-8 label_user_pop2_date">  {(this.state.lastlogin !== null && this.state.lastlogin !== '' && this.state.lastlogin !== 0) ?
                                    new Date(this.state.lastlogin).getDate() + '/' + (new Date(this.state.lastlogin).getMonth() + 1) + '/' + new Date(this.state.lastlogin).getFullYear() : 'NA'} </div>
                                 <div className="clearfix"></div>
                                 <div className="col-xs-6 col-sm-6 col-md-4 label_dataentry">Last Log Off:</div>
                                 <div className="col-xs-6 col-sm-6 col-md-8 label_user_pop2_date">  {
                                    (this.state.lastlogout !== null && this.state.lastlogout !== '' && this.state.lastlogout !== 0) ?
                                       new Date(this.state.lastlogout).getDate() + '/' + (new Date(this.state.lastlogout).getMonth() + 1) + '/' + new Date(this.state.lastlogout).getFullYear() : 'NA'} </div>
                                 <div className="clearfix"></div>
                                 <div className="col-xs-6 col-sm-6 col-md-4 label_dataentry">PW Last Changed:</div>
                                 <div className="col-xs-6 col-sm-6 col-md-8 label_user_pop2_date">{
                                    (this.state.pwlastchange !== null && this.state.pwlastchange !== '' && this.state.pwlastchange !== 0) ?
                                       new Date(this.state.pwlastchange).getDate() + '/' + (new Date(this.state.pwlastchange).getMonth() + 1) + '/' + new Date(this.state.pwlastchange).getFullYear() : 'NA'
                                 }</div>
                                 <div className="clearfix"></div>
                                 <div className="col-xs-6 col-sm-6 col-md-4 label_dataentry">Bad PW Count:</div>
                                 <div className="col-xs-6 col-sm-6 col-md-8 label_user_pop2_date"> {this.state.badpwCount}</div>
                                 <div className="clearfix"></div>
                                 <div className="col-xs-6 col-sm-6 col-md-4 label_dataentry">Login Count:</div>
                                 <div className="col-xs-6 col-sm-6 col-md-8 label_user_pop2_date"> {this.state.logincount} </div>
                                 <div className="clearfix"></div>
                                 <div className="clear40"></div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="clear10"></div>
                              <div className="col-md-12 user_edit_pop_input">
                                 <div className="col-sm-6 pull-right plus_pop3_user displayNone">
                                    <a href={null} onClick={this.setSequenceValid} className="cursorPointer" data-toggle="modal" data-target="#approval_setup">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                                          <path id="ic_add_24px" d="M20,13.571H13.571V20H11.429V13.571H5V11.429h6.429V5h2.143v6.429H20Z" transform="translate(-5 -5)" fill="#7b7b7b" />
                                       </svg>
                                    </a>
                                 </div>
                                 <div className="clear10"></div>
                                 <div className="col-sm-12">
                                    <div className="user_btn_save">
                                       <a href={null} className="btn_save_user cursorPointer" onClick={this.UserUserFormSaveHandler}>Save</a>
                                       <a href={null} className="btn_cancel_user cursorPointer" data-dismiss="modal" onClick={this.clearstates}>Cancel</a>
                                    </div>
                                    <div className="col-md-3"> </div>
                                 </div>
                                 <div className="clear10"></div>
                              </div>
                           </div>

                        </div>


                        <div className="clear10"></div>

                     </div>
                  </div>
               </div>
            </div>
            <div id="user_search_team" className="modal fade" role="dialog">
               <div className="modal-dialog modal_middle_user">
                  <div className="modal-content modal_middle_user_pop3">
                     <div className="modal-header">
                        <button type="button" className="close close_modal_user" data-dismiss="modal" onClick={this.issue_ScrollHandler}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                              <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B" />
                           </svg>
                        </button>
                        <h4 className="modal-title userl_modal_header">Teams</h4>
                     </div>
                     <div className="modal-body user_pop_inviteemail">
                        <div className="user_team_pop3">
                           <table width="100%" className="table table-bordered">
                              <thead>
                                 <tr className="table_header_adtimes bdr_chng">
                                    <th colSpan="3" align="left">
                                       <div className="header_user_team_pop3">Teams</div>
                                    </th>
                                 </tr>
                              </thead>
                              <tbody>

                                 {that.state.listTeamGroupsToView.map((items, index) => {
                                    return (
                                       <tr key={index}>
                                          <td width="5%" align="center">
                                             <div className="mt50">
                                                <input type="checkbox" className="w15" value={items.guid}
                                                   checked={(this.state.User_UserFormuserTeamGroupToView.indexOf(items.guid) > -1 ? true : false)}
                                                   onChange={that.add_team} data-list-name={items.name} name='guid[]' />
                                             </div>
                                          </td>
                                          <td width="75%" align="left">{items.name}</td>
                                          <td width="20%" align="center">
                                             <a href="ad_times_2.html" className="user_team_pop3_svg" data-toggle="modal" data-target="#adtimes2_edit_pop">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                   <path id="ic_create_24px" className="cls-1" d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z" transform="translate(-3 -2.998)"></path>
                                                </svg>
                                             </a>
                                             <a href={null} onClick={this.handleDeletelistTeam(items.guid)} className="user_team_pop3_svg cursorPointer">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10.111" height="13" viewBox="0 0 10.111 13">
                                                   <path id="ic_delete_24px" d="M5.722,14.556A1.449,1.449,0,0,0,7.167,16h5.778a1.449,1.449,0,0,0,1.444-1.444V5.889H5.722ZM15.111,3.722H12.583L11.861,3H8.25l-.722.722H5V5.167H15.111Z" transform="translate(-5 -3)" fill="#7b7b7b"></path>
                                                </svg>
                                             </a>
                                          </td>
                                       </tr>
                                    )
                                 })}
                                 <tr>
                                    <td align="center" colSpan="3">
                                       <a href={null} data-toggle="modal" data-target="#user_search_team" className="pull-right user_plus_pop4_right cursorPointer">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                                             <path id="ic_add_24px" d="M20,13.571H13.571V20H11.429V13.571H5V11.429h6.429V5h2.143v6.429H20Z" transform="translate(-5 -5)" fill="#7b7b7b"></path>
                                          </svg>
                                       </a>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                        <div className="col-sm-12">
                           <div className="user_btn_save">
                              <a href={null} className="btn_save_user cursorPointer" data-dismiss="modal" onClick={this.issue_ScrollHandler}>Save</a>
                              <a href={null} className="btn_cancel_user cursorPointer" data-dismiss="modal" onClick={this.issue_ScrollHandler}>Cancel</a>
                           </div>
                           <div className="col-md-3"> </div>
                        </div>
                        <div className="clear10"></div>
                     </div>
                  </div>
               </div>
            </div>
            <div id="user_edit_signpop" className="modal fade" role="dialog">
               <div className="modal-dialog modal_middle_user">
                  <div className="modal-content modal_middle_user_pop4">
                     <div className="modal-header">
                        <button type="button" className="close close_modal_user" data-dismiss="modal" onClick={this.issue_ScrollHandler} id='close_signature'>
                           <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                              <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B" />
                           </svg>
                        </button>
                        <h4 className="modal-title userl_modal_header">Signature</h4>

                     </div>
                     <div className="modal-body user_pop_inviteemail">
                        <div className="col-sm-12 col-md-12 general_setting_hd">
                           <div className=" mrg_dashboard_right">
                              <div className="">
                                 <div className="col-xs-12 col-sm-10 col-sm-offset-1 mrg_left_sign">
                                    <div className="tabbable-panel">
                                       <div className="tabbable-line">
                                          <ul className="nav nav-tabs ">
                                             <li>
                                                <a href="#tab_default_1" data-toggle="tab" className="text_right_tab1">
                                                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2112 7612 20 14">
                                                      <path id="ic_keyboard_24px" className="cls-1" d="M20,5H4A2,2,0,0,0,2.01,7L2,17a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,20,5ZM11,8h2v2H11Zm0,3h2v2H11ZM8,8h2v2H8Zm0,3h2v2H8ZM7,13H5V11H7Zm0-3H5V8H7Zm9,7H8V15h8Zm0-4H14V11h2Zm0-3H14V8h2Zm3,3H17V11h2Zm0-3H17V8h2Z" transform="translate(-2114 7607)" />
                                                   </svg>
                                                   <div className="clearfix"></div>
                                                   Type
                                       </a>
                                             </li>
                                             <li className="svg_down_pencil active">
                                                <a href="#tab_default_2" data-toggle="tab" className="text_left_tab2">
                                                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3800 7611 15 15">
                                                      <path id="ic_mode_edit_24px" className="cls-1" d="M3,14.873V18H6.125L15.34,8.782,12.215,5.658ZM17.756,6.366a.83.83,0,0,0,0-1.175l-1.95-1.95a.83.83,0,0,0-1.175,0L13.107,4.766l3.125,3.125Z" transform="translate(-3803 7608.002)" />
                                                   </svg>
                                                   <div className="clearfix"></div>
                                                   Signature
                                       </a>
                                             </li>
                                          </ul>
                                          <div className="tab-content">
                                             <div className="tab-pane" id="tab_default_1">
                                                <input name="SignatureText" id="SignatureText" className="" value={this.state.SignatureText} onChange={this.signaturetext} type="text" placeholder="Type Here" defaultValue="" />

                                             </div>


                                             <canvas id="myCanvas" height="40" style={{ border: '1px solid #d3d3d3', display: 'none' }}>
                                                <img id='image' alt="Signature File" style={{ display: 'none' }} src={this.state.Signa} />

                                             </canvas>



                                             <div className="tab-pane pdg_drag_drop_user active" id="tab_default_2">




                                                <div className="col-md-12 p0" id='tttt'>
                                                   <Signature style={style} onEnd={this.getSignatures} clear={this.state.signclear} />
                                                </div>
                                             </div>
                                             <div className="clear20"></div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="clear20"></div>
                           </div>
                        </div>
                        <div className="col-sm-12">
                           <div className="user_btn_save">
                              <a href={null} className="btn_save_user cursorPointer" data-dismiss="modal" onClick={this.save_signature}>Save</a>
                              <a href={null} className="btn_cancel_user cursorPointer" data-dismiss="modal" onClick={this.issue_ScrollHandler}>Cancel</a>
                              <a href={null} className="btn_cancel_user cursorPointer" onClick={this.clear_sign}>Clear</a>
                           </div>
                           <div className="col-md-3"> </div>
                        </div>
                        <div className="clear10"></div>
                     </div>
                  </div>
               </div>
            </div>
            <div id="approval_setup" className="modal fade" role="dialog">
               <div className="modal-dialog pop_w_approval">
                  <div className="modal-content pop_w_approval">
                     <div className="modal-header approval_hdr_pop2">
                        <button type="button" className="close close_modal_approval" data-dismiss="modal" onClick={this.issue_ScrollHandler}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                              <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B" />
                           </svg>
                        </button>
                        <h4 className="modal-title">Approver Setup</h4>
                     </div>
                     <div className="modal-body">
                        <div className="col-sm-12 table_attach_empl">
                           <div className="clear10"></div>
                           <div className="col-md-12 col-lg-12 p0 approval_edit_pop_input">
                              <div className="col-xs-5 col-sm-4 col-md-4 label_dataentry_approv"> Approver:</div>
                              <div className="col-xs-7 col-sm-6 col-md-6">
                                 <select name="User_ApproverSetup_Apporver" onChange={this.UserUserFormSelectOptHandlers}>
                                    <option>Select from given options</option>
                                    {this.state.User_ApproverSetup_ApproverData.map(items => {
                                       return (
                                          <option key={items.Guid} value={items.Guid}>{items.FirstName}</option>
                                       )
                                    })}
                                 </select>
                                 <p className={"error validation " + (this.state.User_ApproverSetupErrors.User_ApproverSetup_Apporver ? '' : 'displayNone')}>{this.state.User_ApproverSetupErrors.User_ApproverSetup_Apporver}</p>
                              </div>
                              <div className="clearfix"></div>
                              <div className="col-xs-5 col-sm-4 col-md-4 label_dataentry_approv">Sequence:</div>
                              <div className="col-xs-7 col-sm-6 col-md-8">
                                 <input name="User_ApproverSetup_Sequence" type="text"
                                    value={this.state.User_ApproverSetup_Sequence}
                                    onChange={this.UserInputsChngHandler} />
                                 {(this.state.present_before) ? <p style={{ color: 'red' }}>Sequence already Taken, Try another</p> : ''}
                                 <p className={"error validation " + (this.state.User_ApproverSetupErrors.User_ApproverSetup_Sequence ? '' : 'displayNone')}>{this.state.User_ApproverSetupErrors.User_ApproverSetup_Sequence}</p>
                              </div>
                              <div className="clearfix"></div>
                              <div className="col-xs-5 col-sm-4 col-md-4 label_dataentry_approv">Signature Position:</div>
                              <div className="col-xs-7 col-sm-6 col-md-8">
                                 <input name="User_ApproverSetup_SignaturePosition"
                                    value={this.state.User_ApproverSetup_SignaturePosition}
                                    type="text"
                                    onChange={this.UserInputsChngHandler} />
                                 {(this.state.signature_present_before) ? <p style={{ color: 'red' }}>Signature already taken, Try another</p> : ''}
                                 <p className={"error validation " + (this.state.User_ApproverSetupErrors.User_ApproverSetup_SignaturePosition ? '' : 'displayNone')}>{this.state.User_ApproverSetupErrors.User_ApproverSetup_SignaturePosition}</p>
                              </div>
                              <div className="clearfix"></div>
                              <div className="col-xs-5 col-sm-4 col-md-4 label_dataentry_approv">Edit Time Cards:</div>
                              <div className="col-xs-7 col-sm-4 col-md-8 checkbox_approval_access">
                                 <div className="checkbox usr_chkbx">
                                    <input id="User_ApproverSetup_EditTimeCard"
                                       name="User_ApproverSetup_EditTimeCard" type="checkbox"
                                       checked={this.state.User_ApproverSetup_EditTimeCard}
                                       onChange={this.UserInputsChngHandler}
                                    />
                                    <label htmlFor="User_ApproverSetup_EditTimeCard">
                                    </label>
                                 </div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="clear40"></div>
                           </div>
                           <div className="col-xs-12 p0 hdg_edit_detail_1">
                              <div className="col-xs-6 p0">
                                 <h4>View</h4>
                              </div>
                           </div>
                           <div className="clear10"></div>
                           <div className="col-md-12 col-lg-12 p0 approval_edit_pop_input">
                              <div className="col-xs-5 col-sm-4 label_approval_access">Payslips:</div>
                              <div className="col-xs-2 col-sm-1 checkbox_approval_access">
                                 <div className="checkbox usr_chkbx">
                                    <input id="User_ApproverSetup_Payslips"
                                       type="checkbox"
                                       name="User_ApproverSetup_Payslips"
                                       checked={this.state.User_ApproverSetup_Payslips}
                                       onChange={this.UserInputsChngHandler} />
                                    <label htmlFor="User_ApproverSetup_Payslips">
                                    </label>
                                 </div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="col-xs-5 col-sm-4 label_approval_access">Comments:</div>
                              <div className="col-xs-2 col-sm-1 checkbox_approval_access">
                                 <div className="checkbox usr_chkbx">
                                    <input id="User_ApproverSetup_Comments"
                                       type="checkbox"
                                       name="User_ApproverSetup_Comments"
                                       checked={this.state.User_ApproverSetup_Comments}
                                       onChange={this.UserInputsChngHandler}
                                    />
                                    <label htmlFor="User_ApproverSetup_Comments">
                                    </label>
                                 </div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="col-xs-5 col-sm-4 label_approval_access">Exceptions:</div>
                              <div className="col-xs-2 col-sm-1 checkbox_approval_access">
                                 <div className="checkbox usr_chkbx">
                                    <input id="User_ApproverSetup_Exceptions" type="checkbox"
                                       name="User_ApproverSetup_Exceptions"
                                       checked={this.state.User_ApproverSetup_Exceptions}
                                       onChange={this.UserInputsChngHandler}
                                    />
                                    <label htmlFor="User_ApproverSetup_Exceptions">
                                    </label>
                                 </div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="col-xs-5 col-sm-4 label_approval_access">Attachments:</div>
                              <div className="col-xs-2 col-sm-1 checkbox_approval_access">
                                 <div className="checkbox usr_chkbx">
                                    <input id="User_ApproverSetup_Attachments" type="checkbox"
                                       name="User_ApproverSetup_Attachments"
                                       checked={this.state.User_ApproverSetup_Attachments}
                                       onChange={this.UserInputsChngHandler} />
                                    <label htmlFor="User_ApproverSetup_Attachments">
                                    </label>
                                 </div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="col-xs-5 col-sm-4 label_approval_access">Activity:</div>
                              <div className="col-xs-2 col-sm-1 checkbox_approval_access">
                                 <div className="checkbox usr_chkbx">
                                    <input id="User_ApproverSetup_Activity" type="checkbox"
                                       name="User_ApproverSetup_Activity"
                                       checked={this.state.User_ApproverSetup_Activity}
                                       onChange={this.UserInputsChngHandler} />
                                    <label htmlFor="User_ApproverSetup_Activity">
                                    </label>
                                 </div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="clear30"></div>
                           </div>
                           <div className="approval_btn_save">
                              <a href={null} className="btn_save_approval cursorPointer" onClick={(this.state.editApproverSetup) ? this.UserApproverlSetupUpdate : this.UserApprovalSetupSave}>{(this.state.editApproverSetup ? 'Update' : 'Save')}</a>
                              <a href={null} className="btn_cancel_approval cursorPointer" data-dismiss="modal" onClick={this.issue_ScrollHandler}>Cancel</a>
                           </div>
                        </div>
                        <div className="clear10"></div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      )
   }
}
