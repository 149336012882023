import React, { Component } from 'react';
import GenSidebar from '../Gen_Sidebar/Gen_Sidebar';
import ic_dehaz from '../../../Assets/images/ic_dehaze_-19@2x.png';
import login_logo from '../../../Assets/images/login_logo.svg';
import forgot_logo from '../../../Assets/images/forgot_logo.svg';
import logout from '../../../Assets/images/logout.svg';
import notifactio from '../../../Assets/images/notifactio.svg';
import './TimeCard_Header.css';
import $ from "jquery";
import { Link } from 'react-router-dom';
import { API, Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
var ip = require('ip');

class TimeCard_Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoadingHeader: false,
      unreadMsgCount: 0,
      companies: [],
    }
  }

  async  componentDidMount() {
    let that = this;
    await API.post("getcompaniesbycoyid", "/getcompaniesbycoyid", {
      body: {
        "cID": localStorage.getItem('companyId')
      }
    }).then(result => {
      console.log(result);
      this.setState({
        companies: result
      })
    }).catch(err => {
      console.log(err);
    });
    setInterval(function () {
      if (that.state.unreadMsgCount !== 0) {
        if ($(".notifactio").hasClass('shake')) {
          $(".notifactio").removeClass('shake');
        }
        else {
          $(".notifactio").addClass('shake');
        }
      }
    }, 3000);

    await this.myunreadmsgcount(localStorage.getItem('userGuid'));

    $(document).ready(function () {
      $(".slide-toggle").click(function () {

        if ($(".sidebar_main").toggle().is(":visible")) {
          $(this).find('.fa-caret-left').css("transform", "rotate(0deg)")
          $('.tab_content_data').removeClass('collapse-in');
          $('.tab_content_data .timecard_8_colum,.tab_content_data .sidebar_main_right').removeClass('collapse-in-data');
          //$('.timecard_8_colum').css('width', '97%')
        } else {
          $(this).find('.fa-caret-left').css("transform", "rotate(180deg)")
          $('.tab_content_data').addClass('collapse-in');
          $('.tab_content_data .timecard_8_colum,.tab_content_data .sidebar_main_right').addClass('collapse-in-data');
          //$('.timecard_8_colum').css('width', '76.6%')
        }
        /*$(".sidebar_main").animate({
          width: "toggle"
        });*/
      });
    });
    $("#menu-toggle").click(function (e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
      $(".mrg_dashboard_right").css("width", "92%");
    });

    var toggle = true;
    $("#menu-toggle").click(function () {
      if (toggle) {
        $(".mrg_dashboard_right").css("width", "92%");
        $("#Group_123")
          .find("image")
          .first()
          .hide(300);
        $("#wrapper span").hide(300);
        $(".sidebar-nav_all li a span").css("display", "none");
      } else {
        //console.log('ssssssssssss');
        $("#Group_123")
          .find("image")
          .first()
          .show(300);
        $(".mrg_dashboard_right").css("width", "83.33%");
        $(".sidebar-nav_all li a span").css("display", "contents");

        $("#wrapper span").show(300);
      }
      toggle = !toggle;
    });

    $(document).unbind('click').bind('click', function (e) {

      if (e.target.id === 'toggle_image') {
        $("#mobile_menu_sm").slideToggle();

      }
      else {
        var sidebar = $("#mobile_menu_sm");
        if (!sidebar.is(e.target) && sidebar.has(e.target).length === 0) {


          hideDiv();

        }
      }

    });




    //the function to hide the div
    function hideDiv() {

      if (window.matchMedia('(max-width: 767px)').matches) {
        //alert($(window).width());

        $(".display_none_full_menu").hide();
        $(".dashboard_new").css('width', '100%');


      }
      else {
        $("#mobile_menu_sm").hide();
        $(".mrg_dashboard_right").css('width', '83.33%');
        $(".display_none_full_menu").show();
      }

    }
    //the function to hide the div
  }

  myunreadmsgcount = async (userGuid) => {
    await API.post('myunreadmsgcount', '/myunreadmsgcount', {
      body: {
        "myguid": userGuid
      }
    }).then(data => {
      this.setState({
        unreadMsgCount: data
      });
    }).catch(err => {
      toast.error('Unable To Fetch Unread MessageCounts');
    });
  }

  logout = async () => {
    this.setState({ isLoadingHeader: true });
    if(localStorage.getItem('userGuid')!==undefined&&localStorage.getItem('userGuid')!==""&&localStorage.getItem('EmailAddress')&&localStorage.getItem('EmailAddress')!==undefined&&localStorage.getItem('EmailAddress')!==""){
    await API.post("recordlastlogin", "/recordlastlogin", {
      body:
      {
        "Guid": localStorage.getItem('userGuid')
      }
    }).then(data => {
     // toast.success('Last Logout Recorded Successfully.');
    }
    ).catch(err => { toast.error(err.message); });
    await API.post("recordactivity", "/recordactivity", {
      body:
      {
        "Description": "logout",
        "UserName": localStorage.getItem('UserLogin'),
        "IPAddress": ip.address(),
        "TenantGuid": "UYGVUYGBIUYGHBIUYHGB",
        "Type": "Logout",
        "UserGuid": localStorage.getItem('userGuid')
      }
    }).then(data => {
       //toast.success('Logout Activity Recorded Successfully.'); 
      }).catch(err => { toast.error(err.message); });

    await API.post("updatelastlogout", "/updatelastlogout", {
      body:
      {
        "Guid": localStorage.getItem('userGuid')
      }
    }).then(data => {
     // toast.success('User last logout updated successfully.');
    }
    ).catch(err => { toast.error(err.message); });
    await API.post("updatelastlogoutforuser", "/updatelastlogoutforuser", {
      body:
      {
        "userEmail": localStorage.getItem('EmailAddress'),
        "LastLogout": Date.now()
      }
    }).then(data => {
     // toast.success('last logout updated successfully for user.');
    }
    ).catch(err => { toast.error(err.message); });
  }
    localStorage.removeItem('EmailAddress');
    localStorage.removeItem('userGuid');
    localStorage.removeItem('UserLogin');
    await Auth.signOut();
    this.props.history.push('/login');
  }
  checkCompanyWithUser = async (event) => {
    var userTenant = localStorage.getItem('tenantid');
    if (userTenant.indexOf(event.target.id) > -1) {
      $("#companiesModelclose").click();
      this.setState({ isLoading: true });
      await localStorage.setItem('useableGuid', event.target.id);
      await this.forceUpdate();
      //toast.success("Production switched Successfully.");

    } else {
      toast.error("You don't belong with selected company");
    }
    if (window.location.pathname !== '/dashboard') {
      this.props.history.push('/dashboard');
    }
    else {
      await API.post('gettenantbytenantid', '/gettenantbytenantid', {
        body: {
          id: localStorage.getItem('useableGuid')
        }
      }).then(data => {
        localStorage.setItem('completetenant', JSON.stringify(data[0]));
      }).catch(err => {
        toast.error('Unable To Fetch Users Activities');
      });
    }
  }

  render() {
    return (
      <div>
        {(this.state.isLoadingHeader || this.props.isLoading) ? <div className="se-pre-con"></div> : ''}
        <button data-toggle="modal" data-target="#companiesModel" id="companiesModelbtn" className="displayNone"></button>
        <div id="companiesModel" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" id="companiesModelclose" data-dismiss="modal">&times;</button>
                <h3 className="modal-title">Productions</h3>
              </div>
              <div className="modal-body">
                <div className="verif_table_box">

                  <table width="99%" className="table zeroMarginBottom">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Production</th>
                        <th align="center" scope="col">Approve</th>
                        <th align="center" scope="col">Pending</th>
                        <th align="center" scope="col">Approved</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.companies.map((value, key) => {
                        return (<tr key={key} onClick={this.checkCompanyWithUser} className="cursorPointer companyList">
                          <td id={value.TenantGuid}>{value.CompanyName}</td>
                          <td align="center" id={value.TenantGuid}>0</td>
                          <td align="center" id={value.TenantGuid}>0</td>
                          <td align="center" id={value.TenantGuid}>5</td>
                        </tr>);
                      })}


                    </tbody>
                  </table>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 header_shadow_all">

          <a hreaf="javascript:void(0);" className="full_menu_toggle" id="menu-toggle_mobile" style={{ float: "left", lineHeight: "normal" }}>

            <img src={ic_dehaz} style={{ width: "25px !important", height: "18px" }} alt="some" id="toggle_image" />
          </a>

          <div className="logo_header_all">
            <Link to="/dashboard" className="pull-left logo_header_all logo_headerall_none1">

              <img src={login_logo} alt="" /> </Link>

            <Link to="/dashboard" className="pull-left logo_headerall_none2">

              <img src={forgot_logo} alt="" /> </Link>

          </div>
          <div className="ibes">
            <label> {'"' + JSON.parse(localStorage.getItem('completetenant')).CompanyName + '"'}</label>
          </div>

          <div className="header_all_center_label header_timecard">
            <div className="tab_table" id="digital_form_page">
              <div className="horizontal_list_tab">
                <ul className="nav nav-tabs ">
                  {/* <!-- <li className="active">
<a href="#tab_default_1" data-toggle="tab">
Draft <span className="badge">30</span>
</a>
</li> --> */}
                  <li className="active" id="appr_ove" onClick={() => this.props.onTabClick('approve')}>
                    <a href="#tab_approve" data-toggle="tab">
                      Approve <span className="badge">{this.props.counts.approveCount}</span>
                    </a>
                  </li>
                  <li id='pending' onClick={() => this.props.onTabClick('pending')}>
                    <a href="#tab_pending" data-toggle="tab">
                      Pending <span className="badge ">{this.props.counts.pendingCount}</span>
                    </a>
                  </li>
                  {/* <!-- <li>
<a href="#tab_default_2" data-toggle="tab">
Declined <span className="badge">28</span>
</a>
</li> --> */}
                  <li id="apprvd" onClick={() => this.props.onTabClick('approved')}>
                    <a href="#tab_approved" data-toggle="tab">
                      Approved <span className="badge">{this.props.counts.approvedCount}</span>
                    </a>
                  </li>
                  <li id='all' onClick={() => this.props.onTabClick('all')}>
                    <a href="#tab_all" data-toggle="tab">
                      All <span className="badge">{this.props.counts.allCount}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

          </div>

          <div className="exit_top_right pull-right">
            <a href={null} onClick={this.logout}>

              <div className="right_logout_label">Logout</div>
              <img src={logout} alt="" />

            </a>
          </div>
          {
            this.props.childProps.setting_SNCheck &&

            <div className="notifactio">
              <Link to="/messages">
                <img src={notifactio} alt="" />
              </Link>
              {this.state.unreadMsgCount > 0 ? <span className="notif_label">{this.state.unreadMsgCount}</span> : ''}
            </div>
          }
        </div>
        <GenSidebar childProps={this.props.childProps} />





        <div className="header_payroll_block">
          <div className="col-sm-12 col-xs-12 ">
            <a
              onClick={this.props.onClickBackArrow}
              className="arrow_back_headerall arrow_paryroll_back arow_digital_timeee"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="21.053"
                viewBox="0 0 13 21.053"
              >
                <g
                  id="Group_1096"
                  data-name="Group 1096"
                  transform="translate(1480 539.053) rotate(180)"
                >
                  <path
                    id="ic_chevron_right_24px"
                    d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                    transform="translate(1458.41 512)"
                  />
                </g>
              </svg>
            </a>

            <div className=" text-center analytics_digital_text payroll_sm_text_none">
              Digital Times
    </div>
            <div className=" timecard_mbl_menu timecard_mobile_top_mrggg">
              <a
                hreaf="javascript:void(0);"
                data-toggle="modal"
                data-target="#timecard_sidee_modal_mobile"
                className="cursorPointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="4"
                  viewBox="0 0 16 4"
                >
                  <path
                    id="ic_more_horiz_24px"
                    d="M6,10a2,2,0,1,0,2,2A2.006,2.006,0,0,0,6,10Zm12,0a2,2,0,1,0,2,2A2.006,2.006,0,0,0,18,10Zm-6,0a2,2,0,1,0,2,2A2.006,2.006,0,0,0,12,10Z"
                    transform="translate(-4 -10)"
                    fill="#fff"
                  />
                </svg>
              </a>
            </div>
          </div>

          <div className="clearfix"> </div>
        </div>



      </div>


    )
  }
}
export default withRouter(TimeCard_Header);
