import React, { Component } from "react";
import { Link } from "react-router-dom";
import forgot_logo from "./../../Assets/images/forgot_logo.svg";
import "./Forgot_3.css";
class Forgot_3 extends Component {
  componentDidMount() {
    document.body.classList.add("forgot_bg");
  }
  componentWillUnmount() {
    document.body.classList.remove("forgot_bg");
  }
  render() {
    return (
      <div className="col-sm-12">
        <div className="container p0">
          <div className="forgot_box1">
            <div className="forgot_box">
              <div className="col-sm-12 reset_password p0">
                {" "}
                <img src={forgot_logo} alt="" /> Reset Password
              </div>

              <div className="reset_under_box2">
                Enter the PIN Code
                <div className="clear20" />
                <input
                  name=""
                  className="frogot_input"
                  type="text"
                  placeholder="123456"
                />
                <div className="clearfix" />
                <div className="col-sm-12 p0 text-right resend_code_label">
                  <a hreaf="javascript:void(0);">Resend code</a>
                </div>
                <div className="clearfix" />
                <Link to="/forgot_2" className="btn_frogot_back">
                  Back
                </Link>
                <Link to="/forgot_4" className="btn_frogot_blue">
                  Proceed
                </Link>
                <div className="clearfix" />
              </div>
              <div className="clearfix" />
            </div>
          </div>
          <div className="clearfix" />
        </div>
        <div className="clearfix" />
      </div>
    );
  }
}
export default Forgot_3;
