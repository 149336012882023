import React, { Component } from 'react'
import Header1 from '../Common/Header1/Header1';
import './SendMessage.css';
import MagglassSvg from "../../Assets/images/magnifying-glass.svg";
import msgOpenPng from "../../Assets/images/msg_open.png";
import sendImagPng from "../../Assets/images/send_imggg.png";
import attachUserIcPng from "../../Assets/images/attach_icon_user.png";
import timecard_2Png from "../../Assets/images/timecard_2.png";
import setting_3Png from "../../Assets/images/setting_3.png";
import CKEditor from "react-ckeditor-component";
import { Link } from 'react-router-dom';

export default class SendMessage extends Component {
    constructor(props) {
        super(props);
        this.updateContent = this.updateContent.bind(this);
        this.state = {
            sendMessageContent:
                '	<p>Hi, Im completig your contract but I need you to fill in your details online.</p><br /><br />          <div class="clear40"></div><div class="clear40"></div><div class="clear40"></div><p>Please click {{ContactLink}}to login and add your details.<br /><br />NOTE: This link will expire in 7days. Please fill in your details as soon as possible!<br /><br />If you need any help with filling your details check out this link:</p>',


            sendMessage_SeacrchSidebar: '',


            sendMessageBoxName: '',
            sendMessageBoxSubject: '',
            sendMessageAttachment: '',


            IssendMessageBoxNameValid: false,
            IssendMessageBoxSubjectValid: false,
            IssendMessageAttachmentValid: false,
            IssendMessageContentValid: false,

            sendMessageErrors: {
                sendMessageBoxName: '',
                sendMessageBoxSubject: '',
                sendMessageAttachment: '',
                sendMessageContent: ''
            },

            IsSendMessageValid: false,
        };
    }




    SendMessageFileUploadHandler = (e) => {

        this.setState({
            sendMessageAttachment: e.target.files[0]
        })
    }



    SenMessageInputChangeHandlers = (e) => {
        let name = e.target.name;
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({
            [name]: value
        }, this.validatFields(name, value));

    }

    validatFields = (fieldName, value) => {
        let sendMessageErrors = this.state.sendMessageErrors;
        let IssendMessageBoxNameValid = this.state.IssendMessageBoxNameValid;
        let IssendMessageBoxSubjectValid = this.state.IssendMessageBoxSubjectValid;
        let IssendMessageContentValid = this.state.IssendMessageContentValid;
        switch (fieldName) {
            case 'sendMessageBoxName':
                IssendMessageBoxNameValid = value.length > 0;
                sendMessageErrors.sendMessageBoxName = IssendMessageBoxNameValid ? '' : "This field is required";
                break;
            case 'sendMessageBoxSubject':
                IssendMessageBoxSubjectValid = value.length > 0;
                sendMessageErrors.sendMessageBoxSubject = IssendMessageBoxSubjectValid ? '' : "This field is required";
                break;
            case 'sendMessageContent':
                IssendMessageContentValid = value.length > 0;
                sendMessageErrors.sendMessageContent = IssendMessageContentValid ? '' : "This field is required";
                break;
            default:
                break;
        }

        this.setState({
            sendMessageErrors: sendMessageErrors,
            IssendMessageBoxNameValid: IssendMessageBoxNameValid,
            IssendMessageBoxSubjectValid: IssendMessageBoxSubjectValid,
            IssendMessageContentValid: IssendMessageContentValid
        })

    }







    updateContent(newContent) {
        this.setState({
            sendMessageContent: newContent
        });
    }

    onChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            sendMessageContent: newContent
        });
    }

    onBlur(evt) {

    }

    afterPaste(evt) {

    }


    onSendMessageSendHandler = () => {

        let sendMessageErrors = this.state.sendMessageErrors;
        let IssendMessageBoxNameValid = this.state.IssendMessageBoxNameValid;
        let IssendMessageBoxSubjectValid = this.state.IssendMessageBoxSubjectValid;
        let IssendMessageContentValid = this.state.IssendMessageContentValid;

        if (!IssendMessageBoxNameValid) {
            sendMessageErrors.sendMessageBoxName = 'This field is required';
        }
        if (!IssendMessageBoxSubjectValid) {
            sendMessageErrors.sendMessageBoxSubject = 'This field is required';
        }
        if (!IssendMessageContentValid) {
            sendMessageErrors.sendMessageContent = 'This field is required';
        }
        this.setState({
            sendMessageErrors: sendMessageErrors
        })


    }





    render() {
        return (
            <div>

                <Header1
                    pageHeader="Send Message"
                    childProps={this.props.childProps}

                />

                <div className="clearfix"></div>



                <div className="col-sm-12 msg_head_top sendddd_msg_head_top p0 msg_display_no_mobile">
                    <div className="msg_inbox_badge">
                        <div className="col-sm-12 p0">
                            <Link to="/messages">Inbox <span className="badge">1</span></Link>
                        </div>
                        <div className="clear10"> </div>
                        <div className="col-sm-12 p0">
                            < a href="# " className="cursorPointer">Drafts </a>
                        </div>
                        <div className="clear10"> </div>
                        <div className="col-sm-12 p0">
                            < a href="# " className="cursorPointer">Sent items </a>
                        </div>
                        <div className="clear10"> </div>
                        <div className="col-sm-12 p0">
                            < a href="# " className="cursorPointer">Deleted Items </a>
                        </div>
                        <div className="clear10"> </div>
                        <div className="col-sm-12 p0">
                            < a href="# " className="cursorPointer">Archives </a>
                        </div>
                    </div>
                    <div className="col-sm-4  p0 msg_boxx_scroll2 msg_boxx_width">
                        <div id="message_box">

                            <div className="grid_layout_message">
                                <div className="grid_layout_inner">
                                    <select name="" className="form_detail_select grid_layout_select_ie">
                                        <option value=""></option>
                                        <option value="1">0093</option>
                                        <option value="1">0094</option>
                                        <option value="1">0095</option>
                                        <option value="1">0096</option>
                                        <option value="1">0097</option>
                                    </select>
                                </div>
                                <div className="grid_layout_inner grid_layout_search_ie">
                                    <form className="form searchInput_form">
                                        <div className="form-group">
                                            <button type="button" className="search_button" value="">
                                                <img src={MagglassSvg} width="20" height="20" alt="send_msg" />
                                            </button>
                                            <input type="text" className="form-control search_input"
                                                name="sendMessage_SeacrchSidebar"
                                                value={this.state.sendMessage_SeacrchSidebar}
                                                onChange={this.SenMessageInputChangeHandlers}
                                                placeholder="What are you looking for" />
                                        </div>
                                    </form>
                                </div>

                            </div>
                            <div className="clearfix"></div>

                            <Link to="/messages"><div className="grid_layout_content active">
                                <div className="grid_content">
                                    <div className=" msg_box_1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
                                            <path id="ic_mail_outline_24px" d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,14H4V8l8,5,8-5Zm-8-7L4,6H20Z" transform="translate(-2 -4)" fill="#7b7b7b" />
                                        </svg>

                                        <label>Patrick Plummer</label>
                                    </div>

                                    <p>I've attached document</p>
                                    <p>Hi Patrick, I've attached ...</p>

                                </div>
                                <div className="grid_content">
                                    <h3> &nbsp; &nbsp; &nbsp;</h3>
                                    <p>Wed 31/10, 14:43.</p>
                                    <p> &nbsp; &nbsp; &nbsp;</p>

                                </div>
                                <div className="clearfix"></div>
                            </div>

                            </Link>
                            <div className="grid_layout_content">
                                <div className="grid_content">
                                    <div className=" msg_box_1">
                                        <img alt="send_msg" src={msgOpenPng} />
                                        <label>Patrick Plummer</label>
                                    </div>
                                    <p>I've attached document</p>
                                    <p>Hi Patrick, I've attached ...</p>

                                </div>
                                <div className="grid_content">
                                    <h3> &nbsp; &nbsp; &nbsp;</h3>
                                    <p>Wed 31/10, 14:43.</p>
                                    <p> &nbsp; &nbsp; &nbsp;</p>

                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="grid_layout_content">
                                <div className="grid_content">
                                    <div className=" msg_box_1">
                                        <img alt="send_msg" src={msgOpenPng} />
                                        <label>Patrick Plummer</label>
                                    </div>
                                    <p>I've attached document</p>
                                    <p>Hi Patrick, I've attached ...</p>

                                </div>
                                <div className="grid_content">
                                    <h3> &nbsp; &nbsp; &nbsp;</h3>
                                    <p>Wed 31/10, 14:43.</p>
                                    <p> &nbsp; &nbsp; &nbsp;</p>

                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="grid_layout_content">
                                <div className="grid_content">
                                    <div className=" msg_box_1">
                                        <img alt="send_msg" src={msgOpenPng} />
                                        <label>Patrick Plummer</label>
                                    </div>
                                    <p>I've attached document</p>
                                    <p>Hi Patrick, I've attached ...</p>

                                </div>
                                <div className="grid_content">
                                    <h3> &nbsp; &nbsp; &nbsp;</h3>
                                    <p>Wed 31/10, 14:43.</p>
                                    <p> &nbsp; &nbsp; &nbsp;</p>

                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="grid_layout_content">
                                <div className="grid_content">
                                    <div className=" msg_box_1">
                                        <img alt="send_msg" src={msgOpenPng} />
                                        <label>Patrick Plummer</label>
                                    </div>
                                    <p>I've attached document</p>
                                    <p>Hi Patrick, I've attached ...</p>

                                </div>
                                <div className="grid_content">
                                    <h3> &nbsp; &nbsp; &nbsp;</h3>
                                    <p>Wed 31/10, 14:43.</p>
                                    <p> &nbsp; &nbsp; &nbsp;</p>

                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="grid_layout_content">
                                <div className="grid_content">
                                    <div className=" msg_box_1">
                                        <img alt="send_msg" src={msgOpenPng} />
                                        <label>Patrick Plummer</label>
                                    </div>
                                    <p>I've attached document</p>
                                    <p>Hi Patrick, I've attached ...</p>

                                </div>
                                <div className="grid_content">
                                    <h3> &nbsp; &nbsp; &nbsp;</h3>
                                    <p>Wed 31/10, 14:43.</p>
                                    <p> &nbsp; &nbsp; &nbsp;</p>

                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="grid_layout_content">
                                <div className="grid_content">
                                    <div className=" msg_box_1">
                                        <img alt="send_msg" src={msgOpenPng} />
                                        <label>Patrick Plummer</label>
                                    </div>
                                    <p>I've attached document</p>
                                    <p>Hi Patrick, I've attached ...</p>

                                </div>
                                <div className="grid_content">
                                    <h3> &nbsp; &nbsp; &nbsp;</h3>
                                    <p>Wed 31/10, 14:43.</p>
                                    <p> &nbsp; &nbsp; &nbsp;</p>

                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="grid_layout_content">
                                <div className="grid_content">
                                    <div className=" msg_box_1">
                                        <img alt="send_msg" src={msgOpenPng} />
                                        <label>Patrick Plummer</label>
                                    </div>
                                    <p>I've attached document</p>
                                    <p>Hi Patrick, I've attached ...</p>

                                </div>
                                <div className="grid_content">
                                    <h3> &nbsp; &nbsp; &nbsp;</h3>
                                    <p>Wed 31/10, 14:43.</p>
                                    <p> &nbsp; &nbsp; &nbsp;</p>

                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="grid_layout_content">
                                <div className="grid_content">
                                    <div className=" msg_box_1">
                                        <img alt="send_msg" src={msgOpenPng} />
                                        <label>Patrick Plummer</label>
                                    </div>
                                    <p>I've attached document</p>
                                    <p>Hi Patrick, I've attached ...</p>

                                </div>
                                <div className="grid_content">
                                    <h3> &nbsp; &nbsp; &nbsp;</h3>
                                    <p>Wed 31/10, 14:43.</p>
                                    <p> &nbsp; &nbsp; &nbsp;</p>

                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="grid_layout_content">
                                <div className="grid_content">
                                    <div className=" msg_box_1">
                                        <img alt="send_msg" src={msgOpenPng} />
                                        <label>Patrick Plummer</label>
                                    </div>
                                    <p>I've attached document</p>
                                    <p>Hi Patrick, I've attached ...</p>

                                </div>
                                <div className="grid_content">
                                    <h3> &nbsp; &nbsp; &nbsp;</h3>
                                    <p>Wed 31/10, 14:43.</p>
                                    <p> &nbsp; &nbsp; &nbsp;</p>

                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="grid_layout_content">
                                <div className="grid_content">
                                    <div className=" msg_box_1">
                                        <img alt="send_msg" src={msgOpenPng} />
                                        <label>Patrick Plummer</label>
                                    </div>
                                    <p>I've attached document</p>
                                    <p>Hi Patrick, I've attached ...</p>

                                </div>
                                <div className="grid_content">
                                    <h3> &nbsp; &nbsp; &nbsp;</h3>
                                    <p>Wed 31/10, 14:43.</p>
                                    <p> &nbsp; &nbsp; &nbsp;</p>

                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="grid_layout_content">
                                <div className="grid_content">
                                    <div className=" msg_box_1">
                                        <img alt="send_msg" src={msgOpenPng} />
                                        <label>Patrick Plummer</label>
                                    </div>
                                    <p>I've attached document</p>
                                    <p>Hi Patrick, I've attached ...</p>

                                </div>
                                <div className="grid_content">
                                    <h3> &nbsp; &nbsp; &nbsp;</h3>
                                    <p>Wed 31/10, 14:43.</p>
                                    <p> &nbsp; &nbsp; &nbsp;</p>

                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>

                    </div>

                    <div className="col-sm-6 p0 msg_box_width_6">
                        <div className="msg_box_right_section msg_right_reply_pd_left">
                            <div className=" msg_right_reply msg_send_className msg_send_img">
                                < a href="# " className="cursorPointer" onClick={this.onSendMessageSendHandler}><img alt="send_msg" src={sendImagPng} />Send </a>

                            </div>

                            <div className=" msg_right_reply ">
                                < a href="# " className="cursorPointer"> <svg xmlns="http://www.w3.org/2000/svg" width="10.111" height="13" viewBox="0 0 10.111 13">
                                    <path id="ic_delete_24px" d="M5.722,14.556A1.449,1.449,0,0,0,7.167,16h5.778a1.449,1.449,0,0,0,1.444-1.444V5.889H5.722ZM15.111,3.722H12.583L11.861,3H8.25l-.722.722H5V5.167H15.111Z" transform="translate(-5 -3)" />
                                </svg>
                                    Delete </a>

                            </div>
                            <div className="clearfix"> </div>
                        </div>


                    </div>

                    <div className="col-sm-6">

                        <div className="msg_box_attached_document">
                            <div className="clear30"> </div>


                            <div className="form-group form_message_send">
                                <label htmlFor="email">Name:</label>
                                <input type="text"
                                    className="form-control"
                                    id="sendMessageBoxName" name="sendMessageBoxName"
                                    value={this.state.sendMessageBoxName} onChange={this.SenMessageInputChangeHandlers} />
                                <p className={"error validation" +
                                    (this.state.sendMessageErrors.sendMessageBoxName
                                        ? "" : "displayNone")} >{this.state.sendMessageErrors.sendMessageBoxName}
                                </p>

                            </div>

                            <div className="form-group form_message_send">
                                <label htmlFor="email">Subject:</label>
                                <input type="text"
                                    name="sendMessageBoxSubject" className="form-control" id="sendMessageBoxSubject"
                                    value={this.state.sendMessageBoxSubject}
                                    onChange={this.SenMessageInputChangeHandlers}
                                /><p className={"error validation" +
                                    (this.state.sendMessageErrors.sendMessageBoxSubject ? "" : 'displayNone')}>{this.state.sendMessageErrors.sendMessageBoxSubject}</p>
                            </div>


                            <div className="form-group form_message_send">
                                <div className="col-sm-12 p0">
                                    <label htmlFor="email">Attachment:</label>
                                    <form className="form_upload_message" action="upload.php" method="POST">
                                        <input type="file" multiple="" value={this.state.sendMessageAttachment} onChange={this.SendMessageFileUploadHandler} />

                                        <img alt="send_msg" src={attachUserIcPng} width="23" height="43" />
                                        <p>Drag and drop files.</p>

                                    </form>  </div>
                            </div>
                            <div className="clear10"></div>
                            <div className="form-group form_message_send">
                                <label htmlFor="email">Message:</label>
                                <CKEditor
                                    activeClass="p10"
                                    content={this.state.sendMessageContent}
                                    events={{
                                        blur: this.onBlur,
                                        afterPaste: this.afterPaste,
                                        change: this.onChange
                                    }}
                                />
                            </div>











                        </div>

                    </div>
                </div>


                <div id="mobile_msg_screeen">
                    <div className="col-sm-12  p0  msg_boxx_width msg_mobile_brder_btm">
                        <div className="col-xs-10 col-xs-offset-2 msg_mobile_text">
                            <h5>Patrick Plummer </h5>

                        </div>
                        <div className="col-xs-12 msg_mobile_text">
                            <div className="col-xs-2 p0 msg_close_svg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
                                    <path id="ic_mail_outline_24px" d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,14H4V8l8,5,8-5Zm-8-7L4,6H20Z" transform="translate(-2 -4)" fill="#7b7b7b"></path>
                                </svg>
                            </div>
                            <div className="col-xs-10 p0">
                                <label> I've attached document</label>
                            </div>
                        </div>
                        <div className="col-xs-10 col-xs-offset-2 msg_mobile_text">
                            <p>5/07/2018 1:10pm </p>
                        </div>
                        <div className="clearfix"> </div>
                    </div>

                    <div className="col-sm-12  p0 msg_boxx_width msg_mobile_brder_btm">
                        <div className="col-xs-10 col-xs-offset-2 msg_mobile_text">
                            <h5>Patrick Plummer </h5>

                        </div>
                        <div className="col-xs-12 msg_mobile_text">
                            <div className="col-xs-2 p0">
                                <img alt="send_msg" src={msgOpenPng} />
                            </div>
                            <div className="col-xs-10 p0">
                                <label> I've attached document</label>
                            </div>
                        </div>
                        <div className="col-xs-10 col-xs-offset-2 msg_mobile_text">
                            <p>5/07/2018 1:10pm </p>
                        </div>
                        <div className="clearfix"> </div>
                    </div>
                    <div className="col-sm-12  p0 msg_boxx_width msg_mobile_brder_btm">
                        <div className="col-xs-10 col-xs-offset-2 msg_mobile_text">
                            <h5>Patrick Plummer </h5>

                        </div>
                        <div className="col-xs-12 msg_mobile_text">
                            <div className="col-xs-2 p0">
                                <img alt="send_msg" src={msgOpenPng} />
                            </div>
                            <div className="col-xs-10 p0">
                                <label> I've attached document</label>
                            </div>
                        </div>
                        <div className="col-xs-10 col-xs-offset-2 msg_mobile_text">
                            <p>5/07/2018 1:10pm </p>
                        </div>
                        <div className="clearfix"> </div>
                    </div>

                    <div className="col-sm-12  p0 msg_boxx_width msg_mobile_brder_btm">
                        <div className="col-xs-10 col-xs-offset-2 msg_mobile_text">
                            <h5>Patrick Plummer </h5>

                        </div>
                        <div className="col-xs-12 msg_mobile_text">
                            <div className="col-xs-2 p0">
                                <img alt="send_msg" src={msgOpenPng} />
                            </div>
                            <div className="col-xs-10 p0">
                                <label> I've attached document</label>
                            </div>
                        </div>
                        <div className="col-xs-10 col-xs-offset-2 msg_mobile_text">
                            <p>5/07/2018 1:10pm </p>
                        </div>
                        <div className="clearfix"> </div>
                    </div>

                    <div className="col-sm-12  p0 msg_boxx_width msg_mobile_brder_btm">
                        <div className="col-xs-10 col-xs-offset-2 msg_mobile_text">
                            <h5>Patrick Plummer </h5>

                        </div>
                        <div className="col-xs-12 msg_mobile_text">
                            <div className="col-xs-2 p0">
                                <img alt="send_msg" src={msgOpenPng} />
                            </div>
                            <div className="col-xs-10 p0">
                                <label> I've attached document</label>
                            </div>
                        </div>
                        <div className="col-xs-10 col-xs-offset-2 msg_mobile_text">
                            <p>5/07/2018 1:10pm </p>
                        </div>
                        <div className="clearfix"> </div>
                    </div>

                    <div className="col-sm-12  p0 msg_boxx_width msg_mobile_brder_btm">
                        <div className="col-xs-10 col-xs-offset-2 msg_mobile_text">
                            <h5>Patrick Plummer </h5>

                        </div>
                        <div className="col-xs-12 msg_mobile_text">
                            <div className="col-xs-2 p0">
                                <img alt="send_msg" src={msgOpenPng} />
                            </div>
                            <div className="col-xs-10 p0">
                                <label> I've attached document</label>
                            </div>
                        </div>
                        <div className="col-xs-10 col-xs-offset-2 msg_mobile_text">
                            <p>5/07/2018 1:10pm </p>
                        </div>
                        <div className="clearfix"> </div>
                    </div>

                    <div className="col-sm-12  p0 msg_boxx_width msg_mobile_brder_btm">
                        <div className="col-xs-10 col-xs-offset-2 msg_mobile_text">
                            <h5>Patrick Plummer </h5>

                        </div>
                        <div className="col-xs-12 msg_mobile_text">
                            <div className="col-xs-2 p0">
                                <img alt="send_msg" src={msgOpenPng} />
                            </div>
                            <div className="col-xs-10 p0">
                                <label> I've attached document</label>
                            </div>
                        </div>
                        <div className="col-xs-10 col-xs-offset-2 msg_mobile_text">
                            <p>5/07/2018 1:10pm </p>
                        </div>
                        <div className="clearfix"> </div>
                    </div>
                    <div className="col-sm-12  p0 msg_boxx_width msg_mobile_brder_btm">
                        <div className="col-xs-10 col-xs-offset-2 msg_mobile_text">
                            <h5>Patrick Plummer </h5>

                        </div>
                        <div className="col-xs-12 msg_mobile_text">
                            <div className="col-xs-2 p0">
                                <img alt="send_msg" src={msgOpenPng} />
                            </div>
                            <div className="col-xs-10 p0">
                                <label> I've attached document</label>
                            </div>
                        </div>
                        <div className="col-xs-10 col-xs-offset-2 msg_mobile_text">
                            <p>5/07/2018 1:10pm </p>
                        </div>
                        <div className="clearfix"> </div>
                    </div>
                    <div className="col-sm-12  p0 msg_boxx_width msg_mobile_brder_btm">
                        <div className="col-xs-10 col-xs-offset-2 msg_mobile_text">
                            <h5>Patrick Plummer </h5>

                        </div>
                        <div className="col-xs-12 msg_mobile_text">
                            <div className="col-xs-2 p0">
                                <img alt="send_msg" src={msgOpenPng} />
                            </div>
                            <div className="col-xs-10 p0">
                                <label> I've attached document</label>
                            </div>
                        </div>
                        <div className="col-xs-10 col-xs-offset-2 msg_mobile_text">
                            <p>5/07/2018 1:10pm </p>
                        </div>
                        <div className="clearfix"> </div>
                    </div>
                    <div className="col-sm-12  p0 msg_boxx_width msg_mobile_brder_btm">
                        <div className="col-xs-10 col-xs-offset-2 msg_mobile_text">
                            <h5>Patrick Plummer </h5>

                        </div>
                        <div className="col-xs-12 msg_mobile_text">
                            <div className="col-xs-2 p0">
                                <img alt="send_msg" src={msgOpenPng} />
                            </div>
                            <div className="col-xs-10 p0">
                                <label> I've attached document</label>
                            </div>
                        </div>
                        <div className="col-xs-10 col-xs-offset-2 msg_mobile_text">
                            <p>5/07/2018 1:10pm </p>
                        </div>
                        <div className="clearfix"> </div>
                    </div>
                </div>

                <div className="timecard_btm_menu">
                    <div className="col-sm-12 col-xs-12">
                        <div className="col-xs-3 col-sm-3 timecard_mbl_tick_icon timecard_menu_mobilee text-center">
                            < a href="# " className="cursorPointer">

                                <img alt="send_msg" src={timecard_2Png} />

                                <p>Timecard </p>
                            </a>

                        </div>
                        <div className="col-xs-3 col-sm-3 timecard_mbl_edit_icon text-center timecard_menu_mobilee">
                            < a href="# " className="cursorPointer">

                                <img alt="send_msg" src={setting_3Png} className="setting_timecard_img" />

                                <p>Setting </p>
                            </a>

                        </div>
                        <div className="col-xs-3 col-sm-3 timecard_mbl_msg_icon timecard_mbl_decline_icon text-center timecard_menu_mobilee">
                            < a href="# " className="cursorPointer">

                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
                                    <path id="ic_mail_outline_24px" d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,14H4V8l8,5,8-5Zm-8-7L4,6H20Z" transform="translate(-2 -4)" fill="#7b7b7b"></path>
                                </svg>

                                <p>Message </p>
                            </a>

                        </div>
                        <div className="col-xs-3 col-sm-3 timecard_mbl_next_icon timecard_logout_icon text-center timecard_menu_mobilee">
                            < a href="# " className="cursorPointer">

                                <svg xmlns="http://www.w3.org/2000/svg" width="23.814" height="20.094" viewBox="0 0 23.814 20.094">
                                    <g id="logout" transform="translate(-0.002 -25)">
                                        <path id="XMLID_7_" d="M3.812,105.582H16.746a1.116,1.116,0,0,0,0-2.233H3.812l1.443-1.443a1.116,1.116,0,0,0-1.579-1.579L.328,103.676q-.039.039-.074.082c-.01.012-.019.026-.029.038s-.025.032-.036.049-.019.032-.029.048-.019.03-.027.046-.016.033-.024.05-.016.032-.023.049-.012.033-.018.049-.013.035-.019.054-.009.033-.013.05-.01.037-.014.056-.006.039-.009.058-.006.033-.007.05c0,.036-.005.072-.006.108v0q0,.054.006.109c0,.017,0,.033.007.049s.005.039.009.059.009.037.014.055.008.034.013.051.012.035.018.053.012.034.018.05.015.032.022.048.016.034.025.051.017.03.026.045.019.033.03.049.023.032.035.047.019.027.03.04.046.053.07.078l0,0L3.677,108.6a1.116,1.116,0,0,0,1.579-1.579Z" transform="translate(0 -69.419)" fill="#BCBCCB" />
                                        <path id="XMLID_8_" d="M115.258,25h-14.14A1.116,1.116,0,0,0,100,26.116v4.465a1.116,1.116,0,0,0,2.233,0V27.233h11.907V42.861H102.235V39.512a1.116,1.116,0,1,0-2.233,0v4.465a1.116,1.116,0,0,0,1.116,1.116h14.14a1.116,1.116,0,0,0,1.116-1.116V26.116A1.116,1.116,0,0,0,115.258,25Z" transform="translate(-92.559 0)" fill="#BCBCCB" />
                                    </g>
                                </svg>

                                <p>logout </p>
                            </a>

                        </div>

                        <div className="clearfix"> </div>
                    </div>
                    <div className="clearfix"> </div>
                </div>











            </div>
        )
    }
}
