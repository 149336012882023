import React, { Component } from "react";
import Header1 from "../Common/Header1/Header1";
import "./Settings.css";
import { Link } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import { toast } from "react-toastify";
var ip = require('ip');

export default class Setting extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      setting_dataUploadOptions: [],
      masterSettings: "",
      setting_selectDataUploadValue: "",
      setting_selectDataUpload: false,
      setting_SATCheck: "",
      setting_SCTCheck: "",
      setting_SNCheck: "",

      setting_selectDataUploadValid: false,
      setting_formValid: false,

      settingErrors: {
        setting_selectDataUploadErr: ''
      },
      previousSettings: false,
      previousGuid: ''
    };
  }
  componentDidMount = async () => {

    await this.props.childProps.isAuthentication();
    if (!this.props.childProps.isAuthenticated) {
      this.props.history.push('/login');
    } else {

      document.body.classList.add("setting_body");
      var tenantguid = localStorage.getItem('tenantid');
      var userguid = localStorage.getItem('userGuid');
      await API.post('mastersettings', '/mastersettings').then(data => {
        this.setState({ setting_dataUploadOptions: data.Setting_dataUploadOptions });
      }).catch(error => {
        toast.error('Settings Not Found In Master Record');
      });
      await API.post("getsettings", "/getsettings", {
        body: {
          "TenantGuid": tenantguid,
          "UserGuid": userguid
        }
      }).then(data => {
        if (data.length > 0) {
          console.log(data[0].Settings);
          this.setState({
            previousSettings: true,
            previousGuid: data[0].Guid,
            setting_SATCheck: data[0].Settings[0].setting_SATCheck,
            setting_SCTCheck: data[0].Settings[1].setting_SCTCheck,
            setting_SNCheck: data[0].Settings[2].setting_SNCheck,
            setting_selectDataUploadValue: data[0].Settings[3].setting_selectDataUploadValue,
          });
        } else {
          this.setState({
            previousGuid: '',
            previousSettings: false,
            setting_SATCheck: false,
            setting_SCTCheck: false,
            setting_SNCheck: false,
            setting_selectDataUploadValue: 0,
          });
        }
      })
        .catch(err => console.log(err));
      this.setState({
        isLoading: false
      });
    }
  }
  componentWillUnmount() {
    document.body.classList.remove("setting_body");
  }

  setting_selectHandler = e => {
    let name = e.target.name;
    let value = e.target.value;

    if (e.target.selectedIndex > 0) {
      this.setState(
        {
          setting_selectDataUploadValue: e.target.value,
          setting_selectDataUpload: true,
          setting_selectDataUploadValid: true
        },
        () => {
          this.setting_validateField(name, value);
        }
      );
    } else if (e.target.selectedIndex === 0) {
      this.setState(
        {
          setting_selectDataUploadValue: "",
          setting_selectDataUpload: false,
          setting_selectDataUploadValid: false
        },
        () => {
          this.setting_validateField(name, value);
        }
      );
    }
  };
  setting_inputHandler = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({
      [name]: value
    },
      () => {
        this.setting_validateField(name, value)
      }
    );
  }
  setting_validateField(fieldName, value) {
    let settingErrors = this.state.settingErrors;
    let setting_selectDataUploadValid = this.state.setting_selectDataUploadValid;



    switch (fieldName) {
      case 'setting_selectDataUpload':
        setting_selectDataUploadValid = this.state.setting_selectDataUploadValid;
        settingErrors.setting_selectDataUploadErr = setting_selectDataUploadValid ? '' : "Please Select Option";
        break;

      default:
        break;
    }

    this.setState({
      settingErrors: settingErrors,
      setting_selectDataUploadValid: setting_selectDataUploadValid,

    }, this.setting_validateForm);
  }
  setting_validateForm = () => {
    this.setState({
      setting_formValid: this.state.setting_selectDataUploadValid
    });
  }
  setting_submitHandler = async () => {

    if (this.state.setting_selectDataUploadValue.length > 0) {
      this.setState({
        isLoading: true
      });
      var tenantguid = localStorage.getItem('tenantid');
      var userguid = localStorage.getItem('userGuid');
      var settingsArray = [
        {
          setting_SATCheck: this.state.setting_SATCheck,
        },
        {
          setting_SCTCheck: this.state.setting_SCTCheck,
        },
        {
          setting_SNCheck: this.state.setting_SNCheck,
        },
        {
          setting_selectDataUploadValue: this.state.setting_selectDataUploadValue
        }
      ];
      if (!this.state.previousSettings) {
        await API.post("createsettings", "/createsettings", {
          body: {
            "TenantGuid": tenantguid,
            "UserGuid": userguid,
            "Settings": settingsArray
          }
        }).then(data => {
          API.post("recordactivity", "/recordactivity", {
            body: {
              "Description": "Settings Saved Activity",
              "UserName": localStorage.getItem('UserLogin'),
              "IPAddress": ip.address(),
              "TenantGuid": localStorage.getItem('useableGuid'),
              "Type": "Settings Saved",
              "UserGuid": localStorage.getItem('userGuid')
            }
          }).then(data => {
           // toast.success('Settings Saved Activity Note');
          }).catch(err => {
            toast.error(err.message);
          });
          // console.log(data);
         // toast.success('Setting Saved Successfully');
        })
          .catch(err => toast.error(err))
      } else {
        await API.post("createsettings", "/createsettings", {
          body: {
            "Guid": this.state.previousGuid,
            "TenantGuid": tenantguid,
            "UserGuid": userguid,
            "Settings": settingsArray
          }
        }).then(data => {
          API.post("recordactivity", "/recordactivity", {
            body: {
              "Description": "Settings Update Activity",
              "UserName": localStorage.getItem('UserLogin'),
              "IPAddress": ip.address(),
              "TenantGuid": localStorage.getItem('useableGuid'),
              "Type": "Settings Update",
              "UserGuid": localStorage.getItem('userGuid')
            }
          }).then(data => {
           // toast.success('Settings Update Activity Note');
          }).catch(err => {
            toast.error(err.message);
          });
          // console.log(data);
         // toast.success("Settings Updated Successfully");
          this.props.history.push('/dashboard')
        })
          .catch(err => toast.error(err))
      }
    }
    else {
      let settingErrors = this.state.settingErrors;

      if (!this.state.setting_selectDataUploadValid) {
        settingErrors.setting_selectDataUploadErr = "Please Select Option"
      }
      this.setState({
        settingErrors: settingErrors
      })
    }
    this.setState({
      isLoading: false
    });
  }

  logout = async () => {
    this.setState({ isLoading: true });
    if(localStorage.getItem('userGuid')!==undefined&&localStorage.getItem('userGuid')!==""&&localStorage.getItem('EmailAddress')&&localStorage.getItem('EmailAddress')!==undefined&&localStorage.getItem('EmailAddress')!==""){
    await API.post("recordlastlogin", "/recordlastlogin", {
      body:
      {
        "Guid": localStorage.getItem('userGuid')
      }
    }).then(data => {
    //  toast.success('Last Logout Recorded Successfully.');
    }
    ).catch(err => { toast.error(err.message); });
    await API.post("recordactivity", "/recordactivity", {
      body:
      {
        "Description": "logout",
        "UserName": localStorage.getItem('UserLogin'),
        "IPAddress": ip.address(),
        "TenantGuid": "UYGVUYGBIUYGHBIUYHGB",
        "Type": "Logout",
        "UserGuid": localStorage.getItem('userGuid')
      }
    }).then(data => { 
     // toast.success('Logout Activity Recorded Successfully.');
     }).catch(err => { toast.error(err.message); });

    await API.post("updatelastlogout", "/updatelastlogout", {
      body:
      {
        "Guid": localStorage.getItem('userGuid')
      }
    }).then(data => {
     // toast.success('User last logout updated successfully.');
    }
    ).catch(err => { toast.error(err.message); });

    await API.post("updatelastlogoutforuser", "/updatelastlogoutforuser", {
      body:
      {
        "userEmail": localStorage.getItem('EmailAddress'),
        "LastLogout": Date.now()
      }
    }).then(data => {
     // toast.success('last logout updated successfully for user.');
    }
    ).catch(err => { toast.error(err.message); });
  }
    localStorage.removeItem('EmailAddress');
    localStorage.removeItem('userGuid');
    localStorage.removeItem('UserLogin');
    localStorage.removeItem('UserFullName');
    await Auth.signOut();
    this.props.history.push('/login');
  }


  render() {
    return (
      <div>
        {this.state.isLoading ?
          <div className="se-pre-con"></div>
          : ''}
        <Header1
          pageHeader="Setting"
          childProps={this.props.childProps}

        />

        <div className="clearfix" />



        <div className="col-sm-12 p0 setting_mainarea setting_web_view">
          <div className="setting_box">
            <div className="col-sm-4 col-xs-4 label_dropdown_setting">
              Data Upload:
            </div>
            <div className="col-xs-7 col-sm-7 dropdown_setting">
              <select id="setting_selectDataUpload"
                name="setting_selectDataUpload"
                value={this.state.setting_selectDataUploadValue}
                onChange={this.setting_selectHandler} className="form-control payending_control_dropdown">
                <option>Please Select One Option</option>
                {this.state.setting_dataUploadOptions.map(function (item, key) {
                  return (
                    <option value={item.id} key={key}>
                      {item.Name}
                    </option>
                  );
                })}
              </select>

              <p className={"error validation " + (this.state.settingErrors.setting_selectDataUploadErr ? '' : 'displayNone')}>{this.state.settingErrors.setting_selectDataUploadErr}</p>
            </div>
            <div className="clear20" />
            <div className="col-sm-8 col-xs-8 label_dropdown_setting">
              Show AD times on timecard:
            </div>
            <div className="col-sm-2 col-xs-2 checkbox_setting_access">
              <div className="checkbox">
                <input id="checkbox1" name="setting_SATCheck" checked={this.state.setting_SATCheck} onChange={this.setting_inputHandler} type="checkbox" />
                <label htmlFor="checkbox1" />
              </div>
            </div>

            <div className="clear20" />
            <div className="col-sm-8 col-xs-8 label_dropdown_setting">
              Show changed times on timecard:
            </div>
            <div className="col-sm-2 col-xs-2 checkbox_setting_access">
              <div className="checkbox">
                <input id="checkbox2" name="setting_SCTCheck" checked={this.state.setting_SCTCheck} onChange={this.setting_inputHandler} type="checkbox" />
                <label htmlFor="checkbox2" />
              </div>
            </div>

            <div className="clear10" />
            <div className="col-sm-8 col-xs-8 label_dropdown_setting">
              Show Notifications:
            </div>
            <div className="col-sm-2 col-xs-2 checkbox_setting_access">
              <div className="checkbox">
                <input id="checkbox3" name="setting_SNCheck" checked={this.state.setting_SNCheck} onChange={this.setting_inputHandler} type="checkbox" />
                <label htmlFor="checkbox3" />
              </div>
            </div>

            <div className="col-sm-12">
              <div className="user_btn_save">
                <button
                  className="btn_save_setting cursorPointer"

                  onClick={this.setting_submitHandler}
                >
                  Save
                </button>
                <Link
                  to="/dashboard"
                  className="btn_cancel_setting"
                  data-dismiss="modal"
                >
                  Cancel
                </Link>
              </div>
              <div className="col-md-3"> </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 col-xs-12 analytics_wrapper timecrad_coments_display setting setting_mobile_view">


          <div className="col-sm-12 col-xs-12 p0 ">
            <div className="col-xs-5 p0">
              <label> Data Upload</label>
            </div>
            <div className="col-xs-7 col-sm-6 p0">
              <select id="setting_selectDataUpload"
                name="setting_selectDataUpload"
                onChange={this.setting_selectHandler} className="form-control payending_control_dropdown">
                <option>Please Select One Option</option>
                {this.state.setting_dataUploadOptions.map(function (item, key) {
                  return (
                    <option value={item.id} key={key}>
                      {item.Name}
                    </option>
                  );
                })}
              </select>
              <p className={"error validation " + (this.state.settingErrors.setting_selectDataUploadErr ? '' : 'displayNone')}>{this.state.settingErrors.setting_selectDataUploadErr}</p>
            </div>
          </div>
          <div className="clear30"> </div>
          <div className="col-xs-12 col-sm-12 p0" id="mb_chkbox_sting">
            <div className="col-xs-10 p0">
              <label>Show AD times on timecard: </label>
            </div>
            <div className="col-xs-2 col-sm-2 p0 text-right">
              <div className="checkbox timecard_chkbox_1 timecards_chk_pos_intitil radio_btm_marg_2">
                <input id="checkbox112" name="setting_SATCheck" checked={this.state.setting_SATCheck} onChange={this.setting_inputHandler} type="checkbox" />
                <label htmlFor="checkbox112" />
              </div>
            </div>
          </div>

          <div className="clear30"> </div>
          <div className="col-xs-12 col-sm-12 p0" id="mb_chkbox_sting">
            <div className="col-xs-10 p0">
              <label>Show Changed times on timecard: </label>
            </div>
            <div className="col-xs-2 col-sm-2 p0 text-right">
              <div className="checkbox timecard_chkbox_1 timecards_chk_pos_intitil radio_btm_marg_">
                <input id="checkbox111" name="setting_SCTCheck" checked={this.state.setting_SCTCheck} onChange={this.setting_inputHandler} type="checkbox" />
                <label htmlFor="checkbox111" />
              </div>
            </div>
          </div>

          <div className="clear50" />
          <div className="clear50" />
          <div className="clear50" />
          <div className="col-md-12 col-xs-12 col-sm-12 p0">
            <div className="col-md-4 col-lg-12 col-sm-6 p0">
              <div className="analytics_btn_save_save btn_save_settingsss">
                < a hreaf="javascript:void(0);" onClick={this.setting_submitHandler} className="btn_cancel_analytics_timecard cursorPointer">
                  Save
                </a>
              </div>
              <div className="col-md-3"> </div>
            </div>
          </div>
        </div>


        <div className="timecard_btm_menu payending_bdr_top">
          <div className="col-sm-12 col-xs-12">
            <div className="col-xs-3 col-sm-3 timecard_mbl_tick_icon timecard_menu_mobilee text-center ">
              <Link to="/timecards">

                <svg
                  className="timecard_svg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="20"
                  viewBox="0 0 20 22.223"
                >
                  <defs />
                  <path
                    id="ic_receipt_24px"
                    className="cls-1"
                    d="M19.667,18.667H6.333V16.444H19.667Zm0-4.444H6.333V12H19.667Zm0-4.444H6.333V7.556H19.667ZM3,24.222l1.667-1.667,1.667,1.667L8,22.556l1.667,1.667,1.667-1.667L13,24.222l1.667-1.667,1.667,1.667L18,22.556l1.667,1.667,1.667-1.667L23,24.222V2L21.333,3.667,19.667,2,18,3.667,16.333,2,14.667,3.667,13,2,11.333,3.667,9.667,2,8,3.667,6.333,2,4.667,3.667,3,2Z"
                    transform="translate(-3 -2)"
                    fill=""
                  />
                </svg>

                <p>Timecard </p>
              </Link>
            </div>
            <div className="col-xs-3 col-sm-3 timecard_mbl_edit_icon text-center timecard_menu_mobilee timecard_active">
              <Link to="/settings">

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="20"
                  viewBox="0 0 20 20.561"
                >
                  <defs />
                  <path
                    id="ic_settings_24px"
                    className="cls-1"
                    d="M19.911,13.288a8.012,8.012,0,0,0,.072-1.007,8.012,8.012,0,0,0-.072-1.007l2.169-1.7a.519.519,0,0,0,.123-.658L20.148,5.362a.517.517,0,0,0-.627-.226l-2.56,1.028a7.511,7.511,0,0,0-1.737-1.007l-.391-2.724a.5.5,0,0,0-.5-.432H10.217a.5.5,0,0,0-.5.432L9.322,5.156A7.9,7.9,0,0,0,7.585,6.164L5.025,5.136a.5.5,0,0,0-.627.226L2.342,8.919a.507.507,0,0,0,.123.658l2.169,1.7a8.153,8.153,0,0,0-.072,1.007,8.153,8.153,0,0,0,.072,1.007l-2.169,1.7a.519.519,0,0,0-.123.658L4.4,19.2a.517.517,0,0,0,.627.226L7.585,18.4A7.511,7.511,0,0,0,9.322,19.4l.391,2.724a.5.5,0,0,0,.5.432h4.112a.5.5,0,0,0,.5-.432l.391-2.724A7.9,7.9,0,0,0,16.961,18.4l2.56,1.028a.5.5,0,0,0,.627-.226L22.2,15.642a.519.519,0,0,0-.123-.658Zm-7.638,2.591a3.6,3.6,0,1,1,3.6-3.6A3.6,3.6,0,0,1,12.273,15.879Z"
                    transform="translate(-2.271 -2)"
                  />
                </svg>
                <p>Setting </p>
              </Link>
            </div>
            <div className="col-xs-3 col-sm-3 timecard_mbl_msg_icon timecard_mbl_decline_icon text-center timecard_menu_mobilee">
              <Link to="/messages">

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="20"
                  viewBox="0 0 20 16"
                >
                  <path
                    id="ic_mail_outline_24px"
                    d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,14H4V8l8,5,8-5Zm-8-7L4,6H20Z"
                    transform="translate(-2 -4)"
                  />
                </svg>

                <p>Message </p>
              </Link>
            </div>
            <div className="col-xs-3 col-sm-3 timecard_mbl_next_icon timecard_logout_icon text-center timecard_menu_mobilee">

              <div onClick={this.logout}>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="20"
                  viewBox="0 0 23.814 20.094"
                >
                  <g id="logout" transform="translate(-0.002 -25)">
                    <path
                      id="XMLID_7_"
                      d="M3.812,105.582H16.746a1.116,1.116,0,0,0,0-2.233H3.812l1.443-1.443a1.116,1.116,0,0,0-1.579-1.579L.328,103.676q-.039.039-.074.082c-.01.012-.019.026-.029.038s-.025.032-.036.049-.019.032-.029.048-.019.03-.027.046-.016.033-.024.05-.016.032-.023.049-.012.033-.018.049-.013.035-.019.054-.009.033-.013.05-.01.037-.014.056-.006.039-.009.058-.006.033-.007.05c0,.036-.005.072-.006.108v0q0,.054.006.109c0,.017,0,.033.007.049s.005.039.009.059.009.037.014.055.008.034.013.051.012.035.018.053.012.034.018.05.015.032.022.048.016.034.025.051.017.03.026.045.019.033.03.049.023.032.035.047.019.027.03.04.046.053.07.078l0,0L3.677,108.6a1.116,1.116,0,0,0,1.579-1.579Z"
                      transform="translate(0 -69.419)"
                    />
                    <path
                      id="XMLID_8_"
                      d="M115.258,25h-14.14A1.116,1.116,0,0,0,100,26.116v4.465a1.116,1.116,0,0,0,2.233,0V27.233h11.907V42.861H102.235V39.512a1.116,1.116,0,1,0-2.233,0v4.465a1.116,1.116,0,0,0,1.116,1.116h14.14a1.116,1.116,0,0,0,1.116-1.116V26.116A1.116,1.116,0,0,0,115.258,25Z"
                      transform="translate(-92.559 0)"
                    />
                  </g>
                </svg>
                <p>Logout </p>
              </div>
            </div>

            <div className="clearfix"> </div>
          </div>
          <div className="clearfix"> </div>
        </div>

      </div>
    );
  }
}
