import React, { Component } from "react";
import ic_search from "./../../Assets/images/ic_search_-1.svg";
import excel from "./../../Assets/images/excel.png";
import select_paste from "./../../Assets/images/select_paste.png";
import "./Employee_List.css";
import Header1 from "../Common/Header1/Header1";
import { toast } from 'react-toastify';
import Amplify, { Storage } from 'aws-amplify';
import ReactExport from "react-data-export";
import DatePicker from "react-datepicker";
import moment from 'moment';
import 'moment-timezone';
import { API } from "aws-amplify";
import {getKeys} from './../tempkeys.js'
import $ from "jquery";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class Employee_List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEmployeeList: true,
      stdtimes: "",
      GroupallData: [],
      employee_UserGuid: '',
      userGuid_tmp: [{ Guid: 'ss', UserGuid: 'hhh' }],
      EmployeeList_SelectedTab: 'FirstName',
      EmployeeList_FirstNameToggle: false,
      EmployeeList_LastNameToggle: false,
      EmployeeList_StartToggle: false,
      EmployeeList_EndToggle: false,
      EmployeeList_TerminatedToggle: false,
      EmployeeList_GroupToggle: false,
      EmployeeList_BasisToggle: false,
      EmployeeList_DeptToggle: false,
      EmployeeList_PositionToggle: false,
      EmployeeList_ApproverGroupToggle: false,
      toggleCaretState: false,
      isLoading: true,
      importdata: "",
      storedtimestamp: 946684800000,
      stampguid: "",
      EmployeeListTableSearchBox: '',
      attachments_array: [],
      Guid_forUpdate: '',
      attachment_forupdate: [],
      Employee_EmpDetailsForm_Agent: '',
      Employee_EmpDetailsForm_FirstName: '',
      Employee_EmpDetailsForm_LastName: '',
      Employee_EmpDetailsForm_Email: '',
      Employee_EmpDetailsForm_Mobile: '',
      Employee_EmpDetailsForm_Dept: '',
      Employee_EmpDetailsForm_Position: '',
      Employee_EmpDetailsForm_Approver: '',
      Employee_EmpDetailsApproverData: [
        { _id: 1, data: 'Approver 1' },
        { _id: 2, data: 'Approver 2' },
      ],
      Employee_EmpDetailsForm_HourlyRate: '',
      Employee_EmpDetailsForm_Template: '',
      Employee_EmpDetailsForm_Global: '',
      Employee_EmpDetailsForm_StartDate: new Date(),
      Employee_EmpDetailsForm_EndDate: new Date(),
      Employee_EmpDetailsForm_EmpBasis: '',
      Employee_EmpDetailsForm_StateTax: '',
      Employee_EmpDetailsForm_Group: '',



      Employee_EmpDetailsForm_Address1: '',
      Employee_EmpDetailsForm_Address2: '',
      Employee_EmpDetailsForm_City: '',
      Employee_EmpDetailsForm_State: '',
      Employee_EmpDetailsForm_PostalCode: '',

      Employee_EmpDetailsForm_Postal_Address1: '',
      Employee_EmpDetailsForm_Postal_Address2: '',
      Employee_EmpDetailsForm_Postal_City: '',
      Employee_EmpDetailsForm_Postal_State: '',
      Employee_EmpDetailsForm_Postal_PostalCode: '',


      Employee_EmpDetailsForm_Flags_Mon: '',
      Employee_EmpDetailsForm_Flags_Tue: '',
      Employee_EmpDetailsForm_Flags_Wed: '',
      Employee_EmpDetailsForm_Flags_Thur: '',
      Employee_EmpDetailsForm_Flags_Fri: '',
      Employee_EmpDetailsForm_Flags_Sat: '',
      Employee_EmpDetailsForm_Flags_Sun: '',

      Employee_EmpDetailsForm_Alias_FirstName: '',
      Employee_EmpDetailsForm_Alias_LastName: '',

      IsEmployee_EmpDetails_TerimatedChkbox_Checked: false,



      IsEmployee_EmpDetailsForm_FirstNameValid: '',
      IsEmployee_EmpDetailsForm_LastNameValid: '',
      IsEmployee_EmpDetailsForm_EmailValid: '',
      IsEmployee_EmpDetailsForm_MobileValid: '',
      IsEmployee_EmpDetailsForm_DeptValid: '',
      IsEmployee_EmpDetailsForm_PositionValid: '',
      IsEmployee_EmpDetailsForm_ApproverValid: '',
      IsEmployee_EmpDetailsForm_HourlyRateValid: '',
      IsEmployee_EmpDetailsForm_TemplateValid: '',
      IsEmployee_EmpDetailsForm_GlobalValid: '',
      IsEmployee_EmpDetailsForm_StartDateValid: '',
      IsEmployee_EmpDetailsForm_EndDateValid: '',
      IsEmployee_EmpDetailsForm_EmpBasisValid: '',
      IsEmployee_EmpDetailsForm_StateTaxValid: '',
      IsEmployee_EmpDetailsForm_GroupValid: '',



      IsEmployee_EmpDetailsForm_Address1Valid: '',
      IsEmployee_EmpDetailsForm_Address2Valid: '',
      IsEmployee_EmpDetailsForm_CityValid: '',
      IsEmployee_EmpDetailsForm_StateValid: '',
      IsEmployee_EmpDetailsForm_PostalCodeValid: '',

      IsEmployee_EmpDetailsForm_Postal_Address1Valid: '',
      IsEmployee_EmpDetailsForm_Postal_Address2Valid: '',
      IsEmployee_EmpDetailsForm_Postal_CityValid: '',
      IsEmployee_EmpDetailsForm_Postal_StateValid: '',
      IsEmployee_EmpDetailsForm_Postal_PostalCodeValid: '',


      IsEmployee_EmpDetailsForm_Flags_MonValid: '',
      IsEmployee_EmpDetailsForm_Flags_TueValid: '',
      IsEmployee_EmpDetailsForm_Flags_WedValid: '',
      IsEmployee_EmpDetailsForm_Flags_ThurValid: '',
      IsEmployee_EmpDetailsForm_Flags_FriValid: '',
      IsEmployee_EmpDetailsForm_Flags_SatValid: '',
      IsEmployee_EmpDetailsForm_Flags_SunValid: '',

      IsEmployee_EmpDetailsForm_Alias_FirstNameValid: '',
      IsEmployee_EmpDetailsForm_Alias_LastNameValid: '',







      Emp_EmpDetailsFormError: {
        Employee_EmpDetailsForm_FirstName: '',
        Employee_EmpDetailsForm_LastName: '',
        Employee_EmpDetailsForm_Email: '',
        Employee_EmpDetailsForm_Mobile: '',
        Employee_EmpDetailsForm_Dept: '',
        Employee_EmpDetailsForm_Position: '',
        Employee_EmpDetailsForm_Approver: '',
        Employee_EmpDetailsForm_HourlyRate: '',
        Employee_EmpDetailsForm_Template: '',
        Employee_EmpDetailsForm_Global: '',
        Employee_EmpDetailsForm_StartDate: '',
        Employee_EmpDetailsForm_EndDate: '',
        Employee_EmpDetailsForm_EmpBasis: '',
        Employee_EmpDetailsForm_StateTax: '',
        Employee_EmpDetailsForm_Group: '',



        Employee_EmpDetailsForm_Address1: '',
        Employee_EmpDetailsForm_Address2: '',
        Employee_EmpDetailsForm_City: '',
        Employee_EmpDetailsForm_State: '',
        Employee_EmpDetailsForm_PostalCode: '',

        Employee_EmpDetailsForm_Postal_Address1: '',
        Employee_EmpDetailsForm_Postal_Address2: '',
        Employee_EmpDetailsForm_Postal_City: '',
        Employee_EmpDetailsForm_Postal_State: '',
        Employee_EmpDetailsForm_Postal_PostalCode: '',


        Employee_EmpDetailsForm_Flags_Mon: '',
        Employee_EmpDetailsForm_Flags_Tue: '',
        Employee_EmpDetailsForm_Flags_Wed: '',
        Employee_EmpDetailsForm_Flags_Thur: '',
        Employee_EmpDetailsForm_Flags_Fri: '',
        Employee_EmpDetailsForm_Flags_Sat: '',
        Employee_EmpDetailsForm_Flags_Sun: '',

        Employee_EmpDetailsForm_Alias_FirstName: '',
        Employee_EmpDetailsForm_Alias_LastName: '',




      },


      EmployeeList_ExcelSelect: '',
      IsEmployeeList_ExcelSelectValid: false,



      EmployeeList_SelectPaste_SelectType: '',
      IsEmployeeList_SelectPaste_SelectTypeValid: false,
      EmployeeList_SelectPaste_PastHere: '',


      EmployeeList_Reports_Dropdown: '',
      IsEmployeeList_Reports_DropdownValid: false,




      EmployeeList_ExcelSelectModalErrors: {
        EmployeeList_ExcelSelect: '',

      },

      EmployeeList_SelectPastModalErrors: {
        EmployeeList_SelectPaste_SelectType: '',

      },
      EmployeeList_ReportsModalErrors: {
        EmployeeList_Reports_Dropdown: '',



      },














      isAllEmployeeListTableChkbxSelected: false,
      EmployeeListSearchData: [],
      EmployeeListTableData: [],
      SelectedEmployeeAttachment: '',





      IsEmployee_EmpDetailFormValid: false

    }
  }
  componentWillMount = async () => {
    await this.getUserSettings();
    if (this.state.showEmployeeList === false) {
      this.props.history.push('/dashboard');
    }
  }
  getUserSettings = async () => {
    let userGuid = localStorage.getItem("userGuid");
    await API.post("getuserbyguid", "/getuserbyguid", {
      body: {
        user_id: userGuid
      }
    }).then(data => {
      this.setState({
        showEmployeeList: data.access.EmployeeList,
      });
    }).catch(err => {
      console.log('error in fetching user')
    });


  }
  toggleCaret = async (name) => {

    await this.setState({
      toggleCaretState: !this.state.toggleCaretState
    })
    if (this.state.toggleCaretState === false) {
      $("#" + name).removeAttr("style")
    }
  }
  get_Approvergroups = async () => {

    this.setState({
      isLoading: true,
    });
   // toast.success('Fetching Groups list...');
    await API.post('getallusersag', '/getallusersag').then(data => { console.log(data); this.setState({ GroupallData: data, }); });
    this.setState({
      isLoading: false,
    });
  }


  componentDidMount = async () => {
    await this.props.childProps.isAuthentication();
    if (!this.props.childProps.isAuthenticated) {
      this.props.history.push('/login');
    } else {


      $(":contains('Download')").closest('button').hide();
      $("#employeeDetails").slideDown();
      $(".EmployeeDetailsTabsvg").removeClass("toggleEmployeeTabs");
      $("#employeeOtherDetailsData").slideUp();
      $(".employeeOtherDetailssvg").addClass("toggleEmployeeTabs");
      $("#EmployeesAddressData").slideUp();
      $(".EmployeesAddressSectionsvg").addClass("toggleEmployeeTabs");
      $("#EmployeepostalAddressData").slideUp();
      $(".EmployeepostalAddressHeadersvg").addClass("toggleEmployeeTabs");
      $("#EmployeeFlagsData").slideUp();
      $(".EmployeeFlagsHeaderSvg").addClass("toggleEmployeeTabs");
      $("#EmployeeAliasData").slideUp();
      $(".EmployeeAliasHeaderSvg").addClass("toggleEmployeeTabs");
      $(".EmployeeDetailsTab").click(function () {
        if ($(".EmployeeDetailsTabsvg").hasClass("toggleEmployeeTabs") === false) {
          $("#employeeDetails").slideUp();
          $(".EmployeeDetailsTabsvg").addClass("toggleEmployeeTabs");
        }
        else {
          $("#employeeDetails").slideDown();
          $(".EmployeeDetailsTabsvg").removeClass("toggleEmployeeTabs");
          $("#employeeOtherDetailsData").slideUp();
          $(".employeeOtherDetailssvg").addClass("toggleEmployeeTabs");
          $("#EmployeesAddressData").slideUp();
          $(".EmployeesAddressSectionsvg").addClass("toggleEmployeeTabs");
          $("#EmployeepostalAddressData").slideUp();
          $(".EmployeepostalAddressHeadersvg").addClass("toggleEmployeeTabs");
          $("#EmployeeFlagsData").slideUp();
          $(".EmployeeFlagsHeaderSvg").addClass("toggleEmployeeTabs");
          $("#EmployeeAliasData").slideUp();
          $(".EmployeeAliasHeaderSvg").addClass("toggleEmployeeTabs");
        }
      });
      $(".employeeOtherDetails").click(function () {
        if ($(".employeeOtherDetailssvg").hasClass("toggleEmployeeTabs") === false) {
          $("#employeeOtherDetailsData").slideUp();
          $(".employeeOtherDetailssvg").addClass("toggleEmployeeTabs");
        }
        else {
          $("#employeeOtherDetailsData").slideDown();
          $(".employeeOtherDetailssvg").removeClass("toggleEmployeeTabs");
          $("#employeeDetails").slideUp();
          $(".EmployeeDetailsTabsvg").addClass("toggleEmployeeTabs");
          $("#EmployeesAddressData").slideUp();
          $(".EmployeesAddressSectionsvg").addClass("toggleEmployeeTabs");
          $("#EmployeepostalAddressData").slideUp();
          $(".EmployeepostalAddressHeadersvg").addClass("toggleEmployeeTabs");
          $("#EmployeeFlagsData").slideUp();
          $(".EmployeeFlagsHeaderSvg").addClass("toggleEmployeeTabs");
          $("#EmployeeAliasData").slideUp();
          $(".EmployeeAliasHeaderSvg").addClass("toggleEmployeeTabs");
        }
      });
      $(".EmployeesAddressSection").click(function () {
        if ($(".EmployeesAddressSectionsvg").hasClass("toggleEmployeeTabs") === false) {
          $("#EmployeesAddressData").slideUp();
          $(".EmployeesAddressSectionsvg").addClass("toggleEmployeeTabs");
        }
        else {
          $("#EmployeesAddressData").slideDown();
          $(".EmployeesAddressSectionsvg").removeClass("toggleEmployeeTabs");
          $("#employeeOtherDetailsData").slideUp();
          $(".employeeOtherDetailssvg").addClass("toggleEmployeeTabs");
          $("#employeeDetails").slideUp();
          $(".EmployeeDetailsTabsvg").addClass("toggleEmployeeTabs");
          $("#EmployeepostalAddressData").slideUp();
          $(".EmployeepostalAddressHeadersvg").addClass("toggleEmployeeTabs");
          $("#EmployeeFlagsData").slideUp();
          $(".EmployeeFlagsHeaderSvg").addClass("toggleEmployeeTabs");
          $("#EmployeeAliasData").slideUp();
          $(".EmployeeAliasHeaderSvg").addClass("toggleEmployeeTabs");
        }
      });
      $(".EmployeepostalAddressHeader").click(function () {
        if ($(".EmployeepostalAddressHeadersvg").hasClass("toggleEmployeeTabs") === false) {
          $("#EmployeepostalAddressData").slideUp();
          $(".EmployeepostalAddressHeadersvg").addClass("toggleEmployeeTabs");
        }
        else {
          $("#EmployeepostalAddressData").slideDown();
          $(".EmployeepostalAddressHeadersvg").removeClass("toggleEmployeeTabs");
          $("#employeeOtherDetailsData").slideUp();
          $(".employeeOtherDetailssvg").addClass("toggleEmployeeTabs");
          $("#employeeDetails").slideUp();
          $(".EmployeeDetailsTabsvg").addClass("toggleEmployeeTabs");
          $("#EmployeesAddressData").slideUp();
          $(".EmployeesAddressSectionsvg").addClass("toggleEmployeeTabs");
          $("#EmployeeFlagsData").slideUp();
          $(".EmployeeFlagsHeaderSvg").addClass("toggleEmployeeTabs");
          $("#EmployeeAliasData").slideUp();
          $(".EmployeeAliasHeaderSvg").addClass("toggleEmployeeTabs");
        }
      });
      // EmployeeFlagsHeaderSvg 
      $(".EmployeeFlagsHeader").click(function () {
        if ($(".EmployeeFlagsHeaderSvg").hasClass("toggleEmployeeTabs") === false) {
          $("#EmployeeFlagsData").slideUp();
          $(".EmployeeFlagsHeaderSvg").addClass("toggleEmployeeTabs");
        }
        else {
          $("#EmployeeFlagsData").slideDown();
          $(".EmployeeFlagsHeaderSvg").removeClass("toggleEmployeeTabs");
          $("#EmployeepostalAddressData").slideUp();
          $(".EmployeepostalAddressHeadersvg").addClass("toggleEmployeeTabs");
          $("#employeeOtherDetailsData").slideUp();
          $(".employeeOtherDetailssvg").addClass("toggleEmployeeTabs");
          $("#employeeDetails").slideUp();
          $(".EmployeeDetailsTabsvg").addClass("toggleEmployeeTabs");
          $("#EmployeesAddressData").slideUp();
          $(".EmployeesAddressSectionsvg").addClass("toggleEmployeeTabs");
          $("#EmployeeAliasData").slideUp();
          $(".EmployeeAliasHeaderSvg").addClass("toggleEmployeeTabs");
        }
      });
      //EmployeeAliasHeaderSvg
      $(".EmployeeAliasHeader").click(function () {
        if ($(".EmployeeAliasHeaderSvg").hasClass("toggleEmployeeTabs") === false) {
          $("#EmployeeAliasData").slideUp();
          $(".EmployeeAliasHeaderSvg").addClass("toggleEmployeeTabs");
        }
        else {
          $("#EmployeeAliasData").slideDown();
          $(".EmployeeAliasHeaderSvg").removeClass("toggleEmployeeTabs");
          $("#EmployeeFlagsData").slideUp();
          $(".EmployeeFlagsHeaderSvg").addClass("toggleEmployeeTabs");
          $("#EmployeepostalAddressData").slideUp();
          $(".EmployeepostalAddressHeadersvg").addClass("toggleEmployeeTabs");
          $("#employeeOtherDetailsData").slideUp();
          $(".employeeOtherDetailssvg").addClass("toggleEmployeeTabs");
          $("#employeeDetails").slideUp();
          $(".EmployeeDetailsTabsvg").addClass("toggleEmployeeTabs");
          $("#EmployeesAddressData").slideUp();
          $(".EmployeesAddressSectionsvg").addClass("toggleEmployeeTabs");
        }
      });
      await this.get_Approvergroups();
      await this.gettimestamp();

      await this.syncgetapidata(new Date(this.state.storedtimestamp).toJSON());
    }
  }

  gettimestamp = async () => {
    await API.post("getstamp", "/getstamp", {
      body: {
        tenant: localStorage.getItem('useableGuid')
      }
    })
      .then(result => {
        if (result.length > 0) {
          this.setState({
            storedtimestamp: result[0].timest,
            stampguid: result[0].Guid
          })
          //toast.success("got time stamp successfully , Refreshing Employee List....")

        } else {
          this.setState({
            storedtimestamp: 946684800000,
            stampguid: ""
          })
          //toast.success("time stamp not found setting default please refresh again to fetch results");
        }
      })
      .catch(err => {
        toast.error("error, time stamp not working")
      })
  }
  updatetimestamp = async () => {
    await API.post('timestampsync', '/timestampsync', {
      body: {
        tenant: localStorage.getItem('useableGuid'),
        Guid: this.state.stampguid
      }
    })
      .then(resu => {
        //toast.success("time stamp synchronized successfully")
      })
      .catch(err => {
        toast.error("error, time stamp not synchronized");
      })
  }
  upload_Attachments = async (attachments) => {
    this.setState({
      isLoading: true
    })
    attachments.map((attachments_data, key) => {
      const base64Data = new Buffer(attachments_data.encodedfile, 'base64');
      Storage.put('employee_attachments/' + localStorage.getItem('useableGuid') + '/attachments/' + attachments_data.filename, base64Data, { contentType: 'application/pdf' })
        .then(result => { console.log(result) })
    });
    this.setState({
      isLoading: false
    })
  }
  syncgetapidata = async (storedtime) => {
    this.setState({
      isLoading: true
    });
    var completetenant = localStorage.getItem('completetenant');
    completetenant = JSON.parse(completetenant);
    var TphPayrollApi = completetenant.TphPayrollApi;

    var keys=localStorage.getItem('tempkeys');
    var finalkeys = (keys&&keys!==null&&keys!==undefined)?JSON.parse(keys):undefined;
    if(finalkeys!==undefined&&finalkeys.tempUsername&&
    finalkeys.tempPassword){

    await fetch(TphPayrollApi.url + '/api/apiemployee?timestamp=' + storedtime + '&username=' + finalkeys.tempUsername + '&password=' +   finalkeys.tempPassword+ '')
      .then(response => response.json())
      .then(async data => {


if(!data.apiEmployee_response.ServiceErrors){



        let emp = data.apiEmployee_response.employee_list;
        emp.map(item => {
          item.checked = false
        })
        //toast.success('Api working fine. Start Syncing...')
        this.updatetimestamp();





        if (emp.length > 0) {
          this.setState({
            EmployeeListTableData: emp,
            EmployeeListSearchData: emp
          }, this.updateDatainDynamo)
        } else {
         // toast.success('No data to sync.');
          this.fetchemployeefromdynamo();
        }

      }else if(data.apiEmployee_response.ServiceErrors[0].ERRORDESCRIPTION==="Userkey or Password invalid"||data.apiEmployee_response.ServiceErrors[0].ERRORDESCRIPTION==="temp key expired"){
        var confirm=await getKeys();
        if(confirm===true){
        this.syncgetapidata(storedtime);
        //toast.error('Keys Updated Please Try Again');
      }

      }




      })
      .catch(error => {

        toast.error("Could not fetch records and sync data successfully");
        toast.error("Api not responding.fetching old synced data.");
        this.fetchemployeefromdynamo();
      })

    }else{
      var confirm=await getKeys();
      if(confirm===true){
      this.syncgetapidata(storedtime);
     // toast.error('Keys Updated Please Try Again');
    }
  }



    this.setState({
      isLoading: false
    })
  }
  updateDatainDynamo = async () => {
    this.setState({
      isLoading: true
    })
    var tmp_items = [];
    await this.state.EmployeeListTableData.map(item => {
      var temp_attachments = [];
      if (item.attachments.length > 0) {

        item.attachments.map((items, index) => {
          temp_attachments.push({ "filename": items.filename });
        })
      }
      var userguid = [];
      userguid = this.state.userGuid_tmp.filter((it) => {

        return it.Guid === item.guid;
      })

      //this.state.userGuid_tmp[item.Guid];
      var obj_userGuid = '';
      var stdtimes = '';
      var appgroup = '';
      if (userguid.length === 0) {
        obj_userGuid = null;
        stdtimes = null;
        appgroup = null;
      } else {
        obj_userGuid = userguid[0].UserGuid;
        stdtimes = userguid[0].stdtimes;
        appgroup = userguid[0].appgroup
      }

      var obj = {
        "firstname": item.firstname,
        "lastname": item.lastname,
        "email": item.email,
        "mobile": item.mobile,
        "department": item.department,
        "position": item.position,
        "approvergroup": appgroup,
        "hourlyrate": item.hourlyrate,
        "template": item.template,
        "globalRecod": item.global,
        "startdate": item.startdate,
        "terminationdate": item.terminationdate,
        "terminated": item.terminated,
        "agent": item.agent,
        "empbasis": item.empbasis,
        "statetax": item.statetax,
        "group": item.group,
        "address1": item.address1,
        "address2": item.address2,
        "city": item.city,
        "state": item.state,
        "postcode": item.postcode,
        "postaladdress1": item.postaladdress2,
        "postaladdress2": item.postaladdress2,
        "postalcity": item.postalcity,
        "postalstate": item.postalstate,
        "postalpostcode": item.postalpostcode,
        "emp_flagsun": item.emp_flagsun,
        "flagmon": item.flagmon,
        "flagtue": item.flagtue,
        "flagwed": item.flagwed,
        "flagthu": item.flagthu,
        "flagfri": item.flagfri,
        "flagsat": item.flagsat,
        "firstnamealias": item.firstnamealias,
        "lastnamealias": item.lastnamealias,
        "guid": item.guid,
        "attachments": temp_attachments,
        "TenantGuid": localStorage.getItem('useableGuid'),
        "UserGuid": obj_userGuid,
        "stdtimes": stdtimes
      };
      tmp_items.push(obj);
      this.upload_Attachments(item.attachments);
    })
    await API.post("createemployees", "/createemployees", {
      body: {
        finaldata: tmp_items
      }
    }).then(data => {
     // toast.success('Data Sync Successfully');
      this.fetchemployeefromdynamo();
    }).catch(err => {
     // toast.success('Data not synced in dynamo. fetching old data');
      toast.error(err.message);
      this.fetchemployeefromdynamo();
    });
    this.setState({
      isLoading: false
    })

  }
  fetchemployeefromdynamo = async () => {
    this.setState({
      isLoading: true
    })
    var tmp_array_attachments = [];
    await API.post('getallemployees', '/getallemployees', {
      body: {
        "tenant": localStorage.getItem('useableGuid')
      }
    }).then(data => {
      data.map(item => {

        item.checked = false;
        item.EmpBasis =
          item.EmpBasis === null || item.EmpBasis === undefined
            ? "-"
            : item.EmpBasis;
        item.Department =
          item.Department === null || item.Department === undefined
            ? "-"
            : item.Department;

        item.Position =
          item.Position === null || item.Position === undefined
            ? "-"
            : item.Position;
        item.NewTerminated = (item.Terminated === null || item.Terminated === undefined) ? '1' : item.Terminated;


        item.NewApproverGroup =
          item.ApproverGroup === null || item.ApproverGroup === undefined
            ? "1"
            : item.ApproverGroup
              ? this.state.GroupallData.find(g => g.Guid == item.ApproverGroup)
                ?
                this.state.GroupallData.find(g => g.Guid == item.ApproverGroup).GroupName
                :
                '-'
              :
              '-';


        item.ApproverGroup = item.ApproverGroup
          ? this.state.GroupallData.find(g => g.Guid == item.ApproverGroup)
            ?
            this.state.GroupallData.find(g => g.Guid == item.ApproverGroup).GroupName
            :
            '-'
          :
          '-'

          ;
        item.NewStartDate = (item.StartDate === null || item.StartDate === undefined || !(item.StartDate)) ? "01/01/1970" : item.StartDate;
        item.Group = (item.TimecardGroup === null || item.TimecardGroup === undefined || !(item.TimecardGroup)) ? "01/01/1970" : item.TimecardGroup;
        tmp_array_attachments[item.Guid] = item.attachments;
        item.newDate = (item.TerminationDate === null || item.TerminationDate === undefined || (item.TerminationDate).trim() === "-  -") ? "01/01/1970" : item.TerminationDate;

      })


      let tab = localStorage.getItem('EmployeeListSelTab');


      if (tab === 'FirstName') {


        $("#FirstNameEmpCaret").show();
        $("#LastNameEmpCaret").hide();
        $("#StartCaret").hide();
        $("#EndCaret").hide();
        $("#TerminatedEmpCaret").hide();
        $("#GroupEmpCaret").hide();
        $("#BasisCaret").hide();
        $("#DeptCaret").hide();
        $("#PositionEmpCaret").hide();
        $("#ApproverCaret").hide();





        this.setState({
          EmployeeList_SelectedTab: tab,
          EmployeeList_FirstNameToggle: true,
          EmployeeList_LastNameToggle: false,
          EmployeeList_StartToggle: false,
          EmployeeList_EndToggle: false,
          EmployeeList_TerminatedToggle: false,
          EmployeeList_GroupToggle: false,
          EmployeeList_BasisToggle: false,
          EmployeeList_DeptToggle: false,
          EmployeeList_PositionToggle: false,
          EmployeeList_ApproverGroupToggle: false,
          attachments_array: tmp_array_attachments,
          EmployeeListTableData: data.sort(function (a, b) {
            var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),
          EmployeeListSearchData: data.sort(function (a, b) {
            var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          })
        })


      }

      else if (tab === 'LastName') {

        $("#FirstNameEmpCaret").hide();
        $("#LastNameEmpCaret").show();
        $("#StartCaret").hide();
        $("#EndCaret").hide();
        $("#TerminatedEmpCaret").hide();
        $("#GroupEmpCaret").hide();
        $("#BasisCaret").hide();
        $("#DeptCaret").hide();
        $("#PositionEmpCaret").hide();
        $("#ApproverCaret").hide();

        this.setState({
          EmployeeList_SelectedTab: tab,
          EmployeeList_FirstNameToggle: false,
          EmployeeList_LastNameToggle: true,
          EmployeeList_StartToggle: false,
          EmployeeList_EndToggle: false,
          EmployeeList_TerminatedToggle: false,
          EmployeeList_GroupToggle: false,
          EmployeeList_BasisToggle: false,
          EmployeeList_DeptToggle: false,
          EmployeeList_PositionToggle: false,
          EmployeeList_ApproverGroupToggle: false,
          attachments_array: tmp_array_attachments,
          EmployeeListTableData: data.sort(function (a, b) {
            var nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),
          EmployeeListSearchData: data.sort(function (a, b) {
            var nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          })
        })
      }
      else if (tab === 'Start') {


        $("#FirstNameEmpCaret").hide();
        $("#LastNameEmpCaret").hide();
        $("#StartCaret").show();
        $("#EndCaret").hide();
        $("#TerminatedEmpCaret").hide();
        $("#GroupEmpCaret").hide();
        $("#BasisCaret").hide();
        $("#DeptCaret").hide();
        $("#PositionEmpCaret").hide();
        $("#ApproverCaret").hide();
        this.setState({
          EmployeeList_SelectedTab: tab,
          EmployeeList_FirstNameToggle: false,
          EmployeeList_LastNameToggle: false,
          EmployeeList_StartToggle: true,
          EmployeeList_EndToggle: false,
          EmployeeList_TerminatedToggle: false,
          EmployeeList_GroupToggle: false,
          EmployeeList_BasisToggle: false,
          EmployeeList_DeptToggle: false,
          EmployeeList_PositionToggle: false,
          EmployeeList_ApproverGroupToggle: false,
          attachments_array: tmp_array_attachments,
          EmployeeListTableData: data.sort(function (a, b) {
            var nameA = a.StartDate.toUpperCase(); // ignore upper and lowercase
            var nameB = b.StartDate.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),
          EmployeeListSearchData: data.sort(function (a, b) {
            var nameA = a.StartDate.toUpperCase(); // ignore upper and lowercase
            var nameB = b.StartDate.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          })
        })

      }

      else if (tab === 'End') {


        $("#FirstNameEmpCaret").hide();
        $("#LastNameEmpCaret").hide();
        $("#StartCaret").hide();
        $("#EndCaret").show();
        $("#TerminatedEmpCaret").hide();
        $("#GroupEmpCaret").hide();
        $("#BasisCaret").hide();
        $("#DeptCaret").hide();
        $("#PositionEmpCaret").hide();
        $("#ApproverCaret").hide();

        this.setState({
          EmployeeList_SelectedTab: tab,
          EmployeeList_FirstNameToggle: false,
          EmployeeList_LastNameToggle: false,
          EmployeeList_StartToggle: false,
          EmployeeList_EndToggle: true,
          EmployeeList_TerminatedToggle: false,
          EmployeeList_GroupToggle: false,
          EmployeeList_BasisToggle: false,
          EmployeeList_DeptToggle: false,
          EmployeeList_PositionToggle: false,
          EmployeeList_ApproverGroupToggle: false,
          attachments_array: tmp_array_attachments,
          EmployeeListTableData: data.sort(function (a, b) {
            var nameA = a.newDate.toUpperCase(); // ignore upper and lowercase
            var nameB = b.newDate.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),
          EmployeeListSearchData: data.sort(function (a, b) {
            var nameA = a.newDate.toUpperCase(); // ignore upper and lowercase
            var nameB = b.newDate.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          })
        })

      }
      else if (tab === 'Terminated') {

        $("#FirstNameEmpCaret").hide();
        $("#LastNameEmpCaret").hide();
        $("#StartCaret").hide();
        $("#EndCaret").hide();
        $("#TerminatedEmpCaret").show();
        $("#GroupEmpCaret").hide();
        $("#BasisCaret").hide();
        $("#DeptCaret").hide();
        $("#PositionEmpCaret").hide();
        $("#ApproverCaret").hide();



        this.setState({
          EmployeeList_SelectedTab: tab,
          EmployeeList_FirstNameToggle: false,
          EmployeeList_LastNameToggle: false,
          EmployeeList_StartToggle: false,
          EmployeeList_EndToggle: false,
          EmployeeList_TerminatedToggle: true,
          EmployeeList_GroupToggle: false,
          EmployeeList_BasisToggle: false,
          EmployeeList_DeptToggle: false,
          EmployeeList_PositionToggle: false,
          EmployeeList_ApproverGroupToggle: false,
          attachments_array: tmp_array_attachments,
          EmployeeListTableData: data.sort(function (a, b) {
            var nameA = a.NewTerminated.toUpperCase(); // ignore upper and lowercase
            var nameB = b.NewTerminated.toUpperCase(); // ignore upper and lowercase

            if (nameA < nameB) {
              return -1;
            }
          }),
          EmployeeListSearchData: data.sort(function (a, b) {
            var nameA = a.NewTerminated.toUpperCase(); // ignore upper and lowercase
            var nameB = b.NewTerminated.toUpperCase(); // ignore upper and lowercase

            if (nameA < nameB) {
              return -1;
            }
          })
        })

      }
      else if (tab === 'Group') {

        $("#FirstNameEmpCaret").hide();
        $("#LastNameEmpCaret").hide();
        $("#StartCaret").hide();
        $("#EndCaret").hide();
        $("#TerminatedEmpCaret").hide();
        $("#GroupEmpCaret").show();
        $("#BasisCaret").hide();
        $("#DeptCaret").hide();
        $("#PositionEmpCaret").hide();
        $("#ApproverCaret").hide();
        this.setState({
          EmployeeList_SelectedTab: tab,
          EmployeeList_FirstNameToggle: false,
          EmployeeList_LastNameToggle: false,
          EmployeeList_StartToggle: false,
          EmployeeList_EndToggle: false,
          EmployeeList_TerminatedToggle: false,
          EmployeeList_GroupToggle: true,
          EmployeeList_BasisToggle: false,
          EmployeeList_DeptToggle: false,
          EmployeeList_PositionToggle: false,
          EmployeeList_ApproverGroupToggle: false,
          attachments_array: tmp_array_attachments,
          EmployeeListTableData: data.sort(function (a, b) {
            var nameA = a.Group.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Group.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),
          EmployeeListSearchData: data.sort(function (a, b) {

            var nameA = a.Group.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Group.toUpperCase();  // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          })
        })

      }
      else if (tab === 'Basis') {

        $("#FirstNameEmpCaret").hide();
        $("#LastNameEmpCaret").hide();
        $("#StartCaret").hide();
        $("#EndCaret").hide();
        $("#TerminatedEmpCaret").hide();
        $("#GroupEmpCaret").hide();
        $("#BasisCaret").show();
        $("#DeptCaret").hide();
        $("#PositionEmpCaret").hide();
        $("#ApproverCaret").hide();

        this.setState({
          EmployeeList_SelectedTab: tab,
          EmployeeList_FirstNameToggle: false,
          EmployeeList_LastNameToggle: false,
          EmployeeList_StartToggle: false,
          EmployeeList_EndToggle: false,
          EmployeeList_TerminatedToggle: false,
          EmployeeList_GroupToggle: false,
          EmployeeList_BasisToggle: true,
          EmployeeList_DeptToggle: false,
          EmployeeList_PositionToggle: false,
          EmployeeList_ApproverGroupToggle: false,
          attachments_array: tmp_array_attachments,
          EmployeeListTableData: data.sort(function (a, b) {
            var nameA = a.EmpBasis.toUpperCase(); // ignore upper and lowercase
            var nameB = b.EmpBasis.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),
          EmployeeListSearchData: data.sort(function (a, b) {
            var nameA = a.EmpBasis.toUpperCase(); // ignore upper and lowercase
            var nameB = b.EmpBasis.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          })
        })

      }
      else if (tab === 'Dept') {

        $("#FirstNameEmpCaret").hide();
        $("#LastNameEmpCaret").hide();
        $("#StartCaret").hide();
        $("#EndCaret").hide();
        $("#TerminatedEmpCaret").hide();
        $("#GroupEmpCaret").hide();
        $("#BasisCaret").hide();
        $("#DeptCaret").show();
        $("#PositionEmpCaret").hide();
        $("#ApproverCaret").hide();


        this.setState({
          EmployeeList_SelectedTab: tab,
          EmployeeList_FirstNameToggle: false,
          EmployeeList_LastNameToggle: false,
          EmployeeList_StartToggle: false,
          EmployeeList_EndToggle: false,
          EmployeeList_TerminatedToggle: false,
          EmployeeList_GroupToggle: false,
          EmployeeList_BasisToggle: false,
          EmployeeList_DeptToggle: true,
          EmployeeList_PositionToggle: false,
          EmployeeList_ApproverGroupToggle: false,
          attachments_array: tmp_array_attachments,
          EmployeeListTableData: data.sort(function (a, b) {
            var nameA = a.Department.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Department.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),
          EmployeeListSearchData: data.sort(function (a, b) {
            var nameA = a.Department.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Department.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          })
        })

      }
      else if (tab === 'Position') {

        $("#FirstNameEmpCaret").hide();
        $("#LastNameEmpCaret").hide();
        $("#StartCaret").hide();
        $("#EndCaret").hide();
        $("#TerminatedEmpCaret").hide();
        $("#GroupEmpCaret").hide();
        $("#BasisCaret").hide();
        $("#DeptCaret").hide();
        $("#PositionEmpCaret").show();
        $("#ApproverCaret").hide();

        this.setState({
          EmployeeList_SelectedTab: tab,
          EmployeeList_FirstNameToggle: false,
          EmployeeList_LastNameToggle: false,
          EmployeeList_StartToggle: false,
          EmployeeList_EndToggle: false,
          EmployeeList_TerminatedToggle: false,
          EmployeeList_GroupToggle: false,
          EmployeeList_BasisToggle: false,
          EmployeeList_DeptToggle: false,
          EmployeeList_PositionToggle: true,
          EmployeeList_ApproverGroupToggle: false,
          attachments_array: tmp_array_attachments,
          EmployeeListTableData: data.sort(function (a, b) {
            var nameA = a.Position.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Position.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),
          EmployeeListSearchData: data.sort(function (a, b) {
            var nameA = a.Position.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Position.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          })
        })

      }
      else if (tab === 'ApproverGroup') {

        $("#FirstNameEmpCaret").hide();
        $("#LastNameEmpCaret").hide();
        $("#StartCaret").hide();
        $("#EndCaret").hide();
        $("#TerminatedEmpCaret").hide();
        $("#GroupEmpCaret").hide();
        $("#BasisCaret").hide();
        $("#DeptCaret").hide();
        $("#PositionEmpCaret").hide();
        $("#ApproverCaret").show();


        this.setState({
          EmployeeList_FirstNameToggle: false,
          EmployeeList_LastNameToggle: false,
          EmployeeList_StartToggle: false,
          EmployeeList_EndToggle: false,
          EmployeeList_TerminatedToggle: false,
          EmployeeList_GroupToggle: false,
          EmployeeList_BasisToggle: false,
          EmployeeList_DeptToggle: false,
          EmployeeList_PositionToggle: false,
          EmployeeList_ApproverGroupToggle: true,
          attachments_array: tmp_array_attachments,
          EmployeeListTableData: data.sort(function (a, b) {
            var nameA = a.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
            var nameB = b.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),
          EmployeeListSearchData: data.sort(function (a, b) {
            var nameA = a.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
            var nameB = b.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          })
        })



      }
      else {

        $("#FirstNameEmpCaret").show();
        $("#LastNameEmpCaret").hide();
        $("#StartCaret").hide();
        $("#EndCaret").hide();
        $("#TerminatedEmpCaret").hide();
        $("#GroupEmpCaret").hide();
        $("#BasisCaret").hide();
        $("#DeptCaret").hide();
        $("#PositionEmpCaret").hide();
        $("#ApproverCaret").hide();





        this.setState({
          EmployeeList_SelectedTab: 'FirstName',
          EmployeeList_FirstNameToggle: true,
          EmployeeList_LastNameToggle: false,
          EmployeeList_StartToggle: false,
          EmployeeList_EndToggle: false,
          EmployeeList_TerminatedToggle: false,
          EmployeeList_GroupToggle: false,
          EmployeeList_BasisToggle: false,
          EmployeeList_DeptToggle: false,
          EmployeeList_PositionToggle: false,
          EmployeeList_ApproverGroupToggle: false,
          attachments_array: tmp_array_attachments,
          EmployeeListTableData: data.sort(function (a, b) {
            var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          }),
          EmployeeListSearchData: data.sort(function (a, b) {
            var nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
          })
        })


      }




      //toast.success('Fetching data from dynamo');

    }).catch(err => { toast.error(err.message) });

    this.setState({
      isLoading: false
    })
  }
  EmployeeListSearchHandler = (event) => {

    let SearchTab = this.state.EmployeeList_SelectedTab;

    var text = event.target.value === null ? '' : event.target.value;
    if (SearchTab === 'FirstName') {

      this.setState({
        EmployeeListTableSearchBox: event.target.value
      });
      this.setState({
        EmployeeListTableData: this.state.EmployeeListSearchData.filter(function (setup) {
          return setup.FirstName.toUpperCase().includes(text.toUpperCase());
        })
      });

    }
    if (SearchTab === 'LastName') {

      this.setState({
        EmployeeListTableSearchBox: event.target.value
      });
      this.setState({
        EmployeeListTableData: this.state.EmployeeListSearchData.filter(function (setup) {
          return setup.LastName.toUpperCase().includes(text.toUpperCase());
        })
      });

    }
    if (SearchTab === 'Start') {

      this.setState({
        EmployeeListTableSearchBox: event.target.value
      });
      this.setState({
        EmployeeListTableData: this.state.EmployeeListSearchData.filter(function (setup) {
          return setup.StartDate.toUpperCase().includes(text.toUpperCase());
        })
      });

    }
    if (SearchTab === 'End') {

      this.setState({
        EmployeeListTableSearchBox: event.target.value
      });
      this.setState({
        EmployeeListTableData: this.state.EmployeeListSearchData.filter(function (setup) {
          return setup.newDate.toUpperCase().includes(text.toUpperCase());
        })
      });

    }
    if (SearchTab === 'Terminated') {

      this.setState({
        EmployeeListTableSearchBox: event.target.value
      });
      this.setState({
        EmployeeListTableData: this.state.EmployeeListSearchData.filter(function (setup) {
          return setup.NewTerminated.toUpperCase().includes(text.toUpperCase());
        })
      });

    }
    if (SearchTab === 'Group') {

      this.setState({
        EmployeeListTableSearchBox: event.target.value
      });
      this.setState({
        EmployeeListTableData: this.state.EmployeeListSearchData.filter(function (setup) {

          return setup.Group.toUpperCase().includes(text.toUpperCase());
        })
      });

    }
    if (SearchTab === 'Basis') {

      this.setState({
        EmployeeListTableSearchBox: event.target.value
      });
      this.setState({
        EmployeeListTableData: this.state.EmployeeListSearchData.filter(function (setup) {
          return setup.EmpBasis.toUpperCase().includes(text.toUpperCase());
        })
      });

    }
    if (SearchTab === 'Dept') {

      this.setState({
        EmployeeListTableSearchBox: event.target.value
      });
      this.setState({
        EmployeeListTableData: this.state.EmployeeListSearchData.filter(function (setup) {
          return setup.Department.toUpperCase().includes(text.toUpperCase());
        })
      });

    }
    if (SearchTab === 'Position') {

      this.setState({
        EmployeeListTableSearchBox: event.target.value
      });
      this.setState({
        EmployeeListTableData: this.state.EmployeeListSearchData.filter(function (setup) {
          return setup.Position.toUpperCase().includes(text.toUpperCase());
        })
      });

    }
    if (SearchTab === 'ApproverGroup') {

      this.setState({
        EmployeeListTableSearchBox: event.target.value
      });
      this.setState({
        EmployeeListTableData: this.state.EmployeeListSearchData.filter(function (setup) {
          return setup.NewApproverGroup.toUpperCase().includes(text.toUpperCase());
        })
      });

    }


  }

  dowonloadEmpAttach = (name) => {

    window.open('https://'+process.env.REACT_APP_S3_BUCKET+'.s3-'+process.env.REACT_APP_S3_REGION+'.amazonaws.com/public/employee_attachments/' + localStorage.getItem('useableGuid') + '/attachments/' + name);
    // let pdfWindow = window.open("")
    // pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + 
    // encodeURI(encodedfile)+"'></iframe>")
    // let decodedBase64 = base64.base64Decode(encodedfile, name);

  }

  editCurrentEmployee = async (index) => {
    let data = [...this.state.EmployeeListTableData];
    if (data[index].Terminated === 'T') {
      this.setState({
        IsEmployee_EmpDetails_TerimatedChkbox_Checked: true
      })
    }
    else {
      this.setState({
        IsEmployee_EmpDetails_TerimatedChkbox_Checked: false
      })
    }

    await this.setState({
      Guid_forUpdate: data[index].Guid,
      Employee_EmpDetailsForm_FirstName: (data[index].FirstName === null) ? '' : data[index].FirstName,
      Employee_EmpDetailsForm_LastName: (data[index].LastName === null) ? '' : data[index].LastName,
      Employee_EmpDetailsForm_Email: (data[index].Email === null) ? '' : data[index].Email,
      Employee_EmpDetailsForm_Mobile: (data[index].Mobile === null) ? '' : data[index].Mobile,
      Employee_EmpDetailsForm_Dept: (data[index].Department === null) ? '' : data[index].Department,
      Employee_EmpDetailsForm_Position: (data[index].Position === null) ? '' : data[index].Position,
      Employee_EmpDetailsForm_Approver: (data[index].ApproverGroup === null) ? '' : data[index].ApproverGroup,
      Employee_EmpDetailsForm_HourlyRate: (data[index].HourlyRate === null) ? '' : data[index].HourlyRate,
      Employee_EmpDetailsForm_Template: (data[index].TemplateCode === null) ? '' : data[index].TemplateCode,
      Employee_EmpDetailsForm_Global: (data[index].GlobalCode === null) ? '' : data[index].GlobalCode,
      Employee_EmpDetailsForm_StartDate: (data[index].StartDate === null || data[index].StartDate === '    -  -') ? '' : data[index].StartDate,
      Employee_EmpDetailsForm_EndDate: (!data[index].TerminationDate) ? data[index].FinishDate : (data[index].TerminationDate === null || data[index].TerminationDate === '    -  -') ? '' : data[index].TerminationDate,
      Employee_EmpDetailsForm_EmpBasis: (data[index].EmpBasis === null) ? '' : data[index].EmpBasis,
      Employee_EmpDetailsForm_StateTax: (data[index].StateTax === null) ? '' : data[index].StateTax,
      Employee_EmpDetailsForm_Group: (data[index].TimecardGroup === null) ? '' : data[index].TimecardGroup,

      Employee_EmpDetailsForm_Agent: (data[index].AgentCode === null) ? '' : data[index].AgentCode,

      Employee_EmpDetailsForm_Address1: (data[index].Address[0].Address1 === null) ? '' : data[index].Address[0].Address1,
      Employee_EmpDetailsForm_Address2: (data[index].Address[0].Address2 === null) ? '' : data[index].Address[0].Address2,
      Employee_EmpDetailsForm_City: (data[index].Address[0].City === null) ? '' : data[index].Address[0].City,
      Employee_EmpDetailsForm_State: (data[index].Address[0].State === null) ? '' : data[index].Address[0].State,
      Employee_EmpDetailsForm_PostalCode: (data[index].Address[0].PostCode === null) ? '' : data[index].Address[0].PostCode,

      Employee_EmpDetailsForm_Postal_Address1: (data[index].Address[1].Address1 === null) ? '' : data[index].Address[1].Address1,
      Employee_EmpDetailsForm_Postal_Address2: (data[index].Address[1].Address2 === null) ? '' : data[index].Address[1].Address2,
      Employee_EmpDetailsForm_Postal_City: (data[index].Address[1].City === null) ? '' : data[index].Address[1].City,
      Employee_EmpDetailsForm_Postal_State: (data[index].Address[1].State === null) ? '' : data[index].Address[1].State,
      Employee_EmpDetailsForm_Postal_PostalCode: (data[index].Address[1].PostCode === null) ? '' : data[index].Address[1].PostCode,


      Employee_EmpDetailsForm_Flags_Mon: (data[index].PaymentFlags[0].Mon === null) ? '' : data[index].PaymentFlags[0].Mon,
      Employee_EmpDetailsForm_Flags_Tue: (data[index].PaymentFlags[0].Tue === null) ? '' : data[index].PaymentFlags[0].Tue,
      Employee_EmpDetailsForm_Flags_Wed: (data[index].PaymentFlags[0].Wed === null) ? '' : data[index].PaymentFlags[0].Wed,
      Employee_EmpDetailsForm_Flags_Thur: (data[index].PaymentFlags[0].Thu === null) ? '' : data[index].PaymentFlags[0].Thu,
      Employee_EmpDetailsForm_Flags_Fri: (data[index].PaymentFlags[0].Fri === null) ? '' : data[index].PaymentFlags[0].Fri,
      Employee_EmpDetailsForm_Flags_Sat: (data[index].PaymentFlags[0].Sat === null) ? '' : data[index].PaymentFlags[0].Sat,
      Employee_EmpDetailsForm_Flags_Sun: (data[index].PaymentFlags[0].Sun === null) ? '' : data[index].PaymentFlags[0].Sun,

      Employee_EmpDetailsForm_Alias_FirstName: (data[index].AliasFirstName === null) ? '' : data[index].AliasFirstName,
      Employee_EmpDetailsForm_Alias_LastName: (data[index].AliasLastName === null) ? '' : data[index].AliasLastName,
      employee_UserGuid: (!data[index].UserGuid) ? null : data[index].UserGuid,
      attachment_forupdate: data[index].attachments,
      stdtimes: (!data[index].stdtimes) ? null : data[index].stdtimes
    })
  }
  currentSelectedEmployee = async (index) => {

    const data = this.state.EmployeeListTableData;

    await this.setState({
      SelectedEmployeeAttachment: data[index]
    })


  }
  EmployeeListSortHandler = async (tab, name) => {


    localStorage.setItem('EmployeeListSelTab', tab)

    await this.setState({
      toggleCaretState: !this.state.toggleCaretState
    })
    if (this.state.toggleCaretState === false) {
      $("#" + name).removeAttr("style")
    }

    if (tab === 'FirstName') {

      $("#FirstNameEmpCaret").show();
      $("#LastNameEmpCaret").hide();
      $("#StartCaret").hide();
      $("#EndCaret").hide();
      $("#TerminatedEmpCaret").hide();
      $("#GroupEmpCaret").hide();
      $("#BasisCaret").hide();
      $("#DeptCaret").hide();
      $("#PositionEmpCaret").hide();
      $("#ApproverCaret").hide();







      this.setState({
        EmployeeList_SelectedTab: tab,
        EmployeeList_LastNameToggle: false,
        EmployeeList_StartToggle: false,
        EmployeeList_EndToggle: false,
        EmployeeList_TerminatedToggle: false,
        EmployeeList_GroupToggle: false,
        EmployeeList_BasisToggle: false,
        EmployeeList_DeptToggle: false,
        EmployeeList_PositionToggle: false,
        EmployeeList_ApproverGroupToggle: false,
      })



      if (this.state.EmployeeList_FirstNameToggle) {
        await this.setState({
          EmployeeList_FirstNameToggle: false
        })
      } else {
        await this.setState({
          EmployeeList_FirstNameToggle: true
        })
      }
      if (this.state.EmployeeList_FirstNameToggle) {
        await this.setState({
          EmployeeListTableData: this.state.EmployeeListTableData.sort(function (a, b) {
            let nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
            let nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0

            // names must be equal
          })
        })
      } else {
        await this.setState({
          EmployeeListTableData: this.state.EmployeeListTableData.sort(function (a, b) {
            let nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
            let nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return 1
            }
            if (nameA > nameB) {
              return -1;
            }
            return 0
            // names must be equal

          })
        })
      }







    }

    if (tab === 'LastName') {

      $("#FirstNameEmpCaret").hide();
      $("#LastNameEmpCaret").show();
      $("#StartCaret").hide();
      $("#EndCaret").hide();
      $("#TerminatedEmpCaret").hide();
      $("#GroupEmpCaret").hide();
      $("#BasisCaret").hide();
      $("#DeptCaret").hide();
      $("#PositionEmpCaret").hide();
      $("#ApproverCaret").hide();



      this.setState({
        EmployeeList_SelectedTab: tab,
        EmployeeList_FirstNameToggle: false,
        EmployeeList_StartToggle: false,
        EmployeeList_EndToggle: false,
        EmployeeList_TerminatedToggle: false,
        EmployeeList_GroupToggle: false,
        EmployeeList_BasisToggle: false,
        EmployeeList_DeptToggle: false,
        EmployeeList_PositionToggle: false,
        EmployeeList_ApproverGroupToggle: false,
      })



      if (this.state.EmployeeList_LastNameToggle) {
        await this.setState({
          EmployeeList_LastNameToggle: false
        })
      } else {
        await this.setState({
          EmployeeList_LastNameToggle: true
        })
      }
      if (this.state.EmployeeList_LastNameToggle) {
        await this.setState({
          EmployeeListTableData: this.state.EmployeeListTableData.sort(function (a, b) {
            let nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
            let nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0

            // names must be equal
          })
        })
      } else {
        await this.setState({
          EmployeeListTableData: this.state.EmployeeListTableData.sort(function (a, b) {
            let nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
            let nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return 1
            }
            if (nameA > nameB) {
              return -1;
            }
            return 0
            // names must be equal

          })
        })
      }





    }
    if (tab === 'Start') {

      $("#FirstNameEmpCaret").hide();
      $("#LastNameEmpCaret").hide();
      $("#StartCaret").show();
      $("#EndCaret").hide();
      $("#TerminatedEmpCaret").hide();
      $("#GroupEmpCaret").hide();
      $("#BasisCaret").hide();
      $("#DeptCaret").hide();
      $("#PositionEmpCaret").hide();
      $("#ApproverCaret").hide();

      this.setState({
        EmployeeList_SelectedTab: tab,
        EmployeeList_FirstNameToggle: false,
        EmployeeList_LastNameToggle: false,
        EmployeeList_EndToggle: false,
        EmployeeList_TerminatedToggle: false,
        EmployeeList_GroupToggle: false,
        EmployeeList_BasisToggle: false,
        EmployeeList_DeptToggle: false,
        EmployeeList_PositionToggle: false,
        EmployeeList_ApproverGroupToggle: false,
      })



      if (this.state.EmployeeList_StartToggle) {
        await this.setState({
          EmployeeList_StartToggle: false
        })
      } else {
        await this.setState({
          EmployeeList_StartToggle: true
        })
      }
      if (this.state.EmployeeList_StartToggle) {
        await this.setState({
          EmployeeListTableData: this.state.EmployeeListTableData.sort(function (a, b) {
            var nameA = a.StartDate.toUpperCase(); // ignore upper and lowercase
            var nameB = b.StartDate.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          EmployeeListTableData: this.state.EmployeeListTableData.sort(function (a, b) {
            var nameA = a.StartDate.toUpperCase(); // ignore upper and lowercase
            var nameB = b.StartDate.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        })
      }





    }

    if (tab === 'End') {


      $("#FirstNameEmpCaret").hide();
      $("#LastNameEmpCaret").hide();
      $("#StartCaret").hide();
      $("#EndCaret").show();
      $("#TerminatedEmpCaret").hide();
      $("#GroupEmpCaret").hide();
      $("#BasisCaret").hide();
      $("#DeptCaret").hide();
      $("#PositionEmpCaret").hide();
      $("#ApproverCaret").hide();

      this.setState({
        EmployeeList_SelectedTab: tab,
        EmployeeList_FirstNameToggle: false,
        EmployeeList_LastNameToggle: false,
        EmployeeList_StartToggle: false,
        EmployeeList_TerminatedToggle: false,
        EmployeeList_GroupToggle: false,
        EmployeeList_BasisToggle: false,
        EmployeeList_DeptToggle: false,
        EmployeeList_PositionToggle: false,
        EmployeeList_ApproverGroupToggle: false,
      })



      if (this.state.EmployeeList_EndToggle) {
        await this.setState({
          EmployeeList_EndToggle: false
        })
      } else {
        await this.setState({
          EmployeeList_EndToggle: true
        })
      }
      if (this.state.EmployeeList_EndToggle) {
        await this.setState({
          EmployeeListTableData: this.state.EmployeeListTableData.sort(function (a, b) {
            var nameA = a.newDate.toUpperCase(); // ignore upper and lowercase
            var nameB = b.newDate.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          EmployeeListTableData: this.state.EmployeeListTableData.sort(function (a, b) {
            var nameA = a.newDate.toUpperCase(); // ignore upper and lowercase
            var nameB = b.newDate.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        })
      }





    }
    if (tab === 'Terminated') {

      $("#FirstNameEmpCaret").hide();
      $("#LastNameEmpCaret").hide();
      $("#StartCaret").hide();
      $("#EndCaret").hide();
      $("#TerminatedEmpCaret").show();
      $("#GroupEmpCaret").hide();
      $("#BasisCaret").hide();
      $("#DeptCaret").hide();
      $("#PositionEmpCaret").hide();
      $("#ApproverCaret").hide();
      this.setState({
        EmployeeList_SelectedTab: tab,
        EmployeeList_FirstNameToggle: false,
        EmployeeList_LastNameToggle: false,
        EmployeeList_StartToggle: false,
        EmployeeList_EndToggle: false,
        EmployeeList_GroupToggle: false,
        EmployeeList_BasisToggle: false,
        EmployeeList_DeptToggle: false,
        EmployeeList_PositionToggle: false,
        EmployeeList_ApproverGroupToggle: false,
      })



      if (this.state.EmployeeList_TerminatedToggle) {
        await this.setState({
          EmployeeList_TerminatedToggle: false
        })
      } else {
        await this.setState({
          EmployeeList_TerminatedToggle: true
        })
      }
      if (this.state.EmployeeList_TerminatedToggle) {
        await this.setState({
          EmployeeListTableData: this.state.EmployeeListTableData.sort(function (a, b) {
            var nameA = a.NewTerminated.toUpperCase(); // ignore upper and lowercase
            var nameB = b.NewTerminated.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          EmployeeListTableData: this.state.EmployeeListTableData.sort(function (a, b) {
            var nameA = a.NewTerminated.toUpperCase(); // ignore upper and lowercase
            var nameB = b.NewTerminated.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        })
      }





    }
    if (tab === 'Group') {



      $("#FirstNameEmpCaret").hide();
      $("#LastNameEmpCaret").hide();
      $("#StartCaret").hide();
      $("#EndCaret").hide();
      $("#TerminatedEmpCaret").hide();
      $("#GroupEmpCaret").show();
      $("#BasisCaret").hide();
      $("#DeptCaret").hide();
      $("#PositionEmpCaret").hide();
      $("#ApproverCaret").hide();



      this.setState({
        EmployeeList_SelectedTab: tab,
        EmployeeList_FirstNameToggle: false,
        EmployeeList_LastNameToggle: false,
        EmployeeList_StartToggle: false,
        EmployeeList_EndToggle: false,
        EmployeeList_TerminatedToggle: false,
        EmployeeList_BasisToggle: false,
        EmployeeList_DeptToggle: false,
        EmployeeList_PositionToggle: false,
        EmployeeList_ApproverGroupToggle: false,
      })



      if (this.state.EmployeeList_GroupToggle) {
        await this.setState({
          EmployeeList_GroupToggle: false
        })
      } else {
        await this.setState({
          EmployeeList_GroupToggle: true
        })
      }
      if (this.state.EmployeeList_GroupToggle) {
        await this.setState({
          EmployeeListTableData: this.state.EmployeeListTableData.sort(function (a, b) {
            var nameA = a.Group.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Group.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          EmployeeListTableData: this.state.EmployeeListTableData.sort(function (a, b) {
            var nameA = a.Group.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Group.toUpperCase(); // ignore upper and lowercase

            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        })
      }





    }
    if (tab === 'Basis') {


      $("#FirstNameEmpCaret").hide();
      $("#LastNameEmpCaret").hide();
      $("#StartCaret").hide();
      $("#EndCaret").hide();
      $("#TerminatedEmpCaret").hide();
      $("#GroupEmpCaret").hide();
      $("#BasisCaret").show();
      $("#DeptCaret").hide();
      $("#PositionEmpCaret").hide();
      $("#ApproverCaret").hide();
      this.setState({
        EmployeeList_SelectedTab: tab,
        EmployeeList_FirstNameToggle: false,
        EmployeeList_LastNameToggle: false,
        EmployeeList_StartToggle: false,
        EmployeeList_EndToggle: false,
        EmployeeList_TerminatedToggle: false,
        EmployeeList_GroupToggle: false,
        EmployeeList_DeptToggle: false,
        EmployeeList_PositionToggle: false,
        EmployeeList_ApproverGroupToggle: false,
      })



      if (this.state.EmployeeList_BasisToggle) {
        await this.setState({
          EmployeeList_BasisToggle: false
        })
      } else {
        await this.setState({
          EmployeeList_BasisToggle: true
        })
      }
      if (this.state.EmployeeList_BasisToggle) {
        await this.setState({
          EmployeeListTableData: this.state.EmployeeListTableData.sort(function (a, b) {
            var nameA = a.EmpBasis.toUpperCase(); // ignore upper and lowercase
            var nameB = b.EmpBasis.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          EmployeeListTableData: this.state.EmployeeListTableData.sort(function (a, b) {
            var nameA = a.EmpBasis.toUpperCase(); // ignore upper and lowercase
            var nameB = b.EmpBasis.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        })
      }





    }
    if (tab === 'Dept') {

      $("#FirstNameEmpCaret").hide();
      $("#LastNameEmpCaret").hide();
      $("#StartCaret").hide();
      $("#EndCaret").hide();
      $("#TerminatedEmpCaret").hide();
      $("#GroupEmpCaret").hide();
      $("#BasisCaret").hide();
      $("#DeptCaret").show();
      $("#PositionEmpCaret").hide();
      $("#ApproverCaret").hide();


      this.setState({
        EmployeeList_SelectedTab: tab,
        EmployeeList_FirstNameToggle: false,
        EmployeeList_LastNameToggle: false,
        EmployeeList_StartToggle: false,
        EmployeeList_EndToggle: false,
        EmployeeList_TerminatedToggle: false,
        EmployeeList_GroupToggle: false,
        EmployeeList_BasisToggle: false,
        EmployeeList_PositionToggle: false,
        EmployeeList_ApproverGroupToggle: false,
      })



      if (this.state.EmployeeList_DeptToggle) {
        await this.setState({
          EmployeeList_DeptToggle: false
        })
      } else {
        await this.setState({
          EmployeeList_DeptToggle: true
        })
      }
      if (this.state.EmployeeList_DeptToggle) {
        await this.setState({
          EmployeeListTableData: this.state.EmployeeListTableData.sort(function (a, b) {
            var nameA = a.Department.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Department.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {

              return -1;

            }
            if (nameA > nameB) {
              return 1;

            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          EmployeeListTableData: this.state.EmployeeListTableData.sort(function (a, b) {
            var nameA = a.Department.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Department.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        })
      }





    }
    if (tab === 'Position') {

      $("#FirstNameEmpCaret").hide();
      $("#LastNameEmpCaret").hide();
      $("#StartCaret").hide();
      $("#EndCaret").hide();
      $("#TerminatedEmpCaret").hide();
      $("#GroupEmpCaret").hide();
      $("#BasisCaret").hide();
      $("#DeptCaret").hide();
      $("#PositionEmpCaret").show();
      $("#ApproverCaret").hide();
      this.setState({
        EmployeeList_SelectedTab: tab,
        EmployeeList_FirstNameToggle: false,
        EmployeeList_LastNameToggle: false,
        EmployeeList_StartToggle: false,
        EmployeeList_EndToggle: false,
        EmployeeList_TerminatedToggle: false,
        EmployeeList_GroupToggle: false,
        EmployeeList_BasisToggle: false,
        EmployeeList_DeptToggle: false,
        EmployeeList_ApproverGroupToggle: false,
      })



      if (this.state.EmployeeList_PositionToggle) {
        await this.setState({
          EmployeeList_PositionToggle: false
        })
      } else {
        await this.setState({
          EmployeeList_PositionToggle: true
        })
      }
      if (this.state.EmployeeList_PositionToggle) {
        await this.setState({
          EmployeeListTableData: this.state.EmployeeListTableData.sort(function (a, b) {
            var nameA = a.Position.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Position.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          EmployeeListTableData: this.state.EmployeeListTableData.sort(function (a, b) {
            var nameA = a.Position.toUpperCase(); // ignore upper and lowercase
            var nameB = b.Position.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        })
      }





    }
    if (tab === 'ApproverGroup') {
      $("#FirstNameEmpCaret").hide();
      $("#LastNameEmpCaret").hide();
      $("#StartCaret").hide();
      $("#EndCaret").hide();
      $("#TerminatedEmpCaret").hide();
      $("#GroupEmpCaret").hide();
      $("#BasisCaret").hide();
      $("#DeptCaret").hide();
      $("#PositionEmpCaret").hide();
      $("#ApproverCaret").show();

      this.setState({
        EmployeeList_SelectedTab: tab,
        EmployeeList_FirstNameToggle: false,
        EmployeeList_LastNameToggle: false,
        EmployeeList_StartToggle: false,
        EmployeeList_EndToggle: false,
        EmployeeList_TerminatedToggle: false,
        EmployeeList_GroupToggle: false,
        EmployeeList_BasisToggle: false,
        EmployeeList_DeptToggle: false,
        EmployeeList_PositionToggle: false,
      })



      if (this.state.EmployeeList_ApproverGroupToggle) {
        await this.setState({
          EmployeeList_ApproverGroupToggle: false
        })
      } else {
        await this.setState({
          EmployeeList_ApproverGroupToggle: true
        })
      }
      if (this.state.EmployeeList_ApproverGroupToggle) {
        await this.setState({
          EmployeeListTableData: this.state.EmployeeListTableData.sort(function (a, b) {
            var nameA = a.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
            var nameB = b.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
        })
      } else {
        await this.setState({
          EmployeeListTableData: this.state.EmployeeListTableData.sort(function (a, b) {
            var nameA = a.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
            var nameB = b.NewApproverGroup.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            // names must be equal
            return 0;
          })
        })
      }





    }



  }

  EmployeeListInputsHandler = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    this.setState({
      [name]: value
    }, this.validateFields(name, value))
  }

  validateFields(fieldName, value) {
    let Emp_EmpDetailsFormError = this.state.Emp_EmpDetailsFormError;
    let IsEmployee_EmpDetailsForm_FirstNameValid = this.state.IsEmployee_EmpDetailsForm_FirstNameValid;
    let IsEmployee_EmpDetailsForm_LastNameValid = this.state.IsEmployee_EmpDetailsForm_LastNameValid;
    let IsEmployee_EmpDetailsForm_EmailValid = this.state.IsEmployee_EmpDetailsForm_EmailValid;
    let IsEmployee_EmpDetailsForm_MobileValid = this.state.IsEmployee_EmpDetailsForm_MobileValid;
    let IsEmployee_EmpDetailsForm_DeptValid = this.state.IsEmployee_EmpDetailsForm_DeptValid;
    let IsEmployee_EmpDetailsForm_PositionValid = this.state.IsEmployee_EmpDetailsForm_PositionValid;
    let IsEmployee_EmpDetailsForm_ApproverValid = this.state.IsEmployee_EmpDetailsForm_ApproverValid;
    let IsEmployee_EmpDetailsForm_HourlyRateValid = this.state.IsEmployee_EmpDetailsForm_HourlyRateValid;
    let IsEmployee_EmpDetailsForm_TemplateValid = this.state.IsEmployee_EmpDetailsForm_TemplateValid;
    let IsEmployee_EmpDetailsForm_GlobalValid = this.state.IsEmployee_EmpDetailsForm_GlobalValid;
    let IsEmployee_EmpDetailsForm_StartDateValid = this.state.IsEmployee_EmpDetailsForm_StartDateValid;
    let IsEmployee_EmpDetailsForm_EndDateValid = this.state.IsEmployee_EmpDetailsForm_EndDateValid;
    let IsEmployee_EmpDetailsForm_EmpBasisValid = this.state.IsEmployee_EmpDetailsForm_EmpBasisValid;
    let IsEmployee_EmpDetailsForm_StateTaxValid = this.state.IsEmployee_EmpDetailsForm_StateTaxValid;
    let IsEmployee_EmpDetailsForm_GroupValid = this.state.IsEmployee_EmpDetailsForm_GroupValid;



    let IsEmployee_EmpDetailsForm_Address1Valid = this.state.IsEmployee_EmpDetailsForm_Address1Valid;
    let IsEmployee_EmpDetailsForm_Address2Valid = this.state.IsEmployee_EmpDetailsForm_Address2Valid;
    let IsEmployee_EmpDetailsForm_CityValid = this.state.IsEmployee_EmpDetailsForm_CityValid;
    let IsEmployee_EmpDetailsForm_StateValid = this.state.IsEmployee_EmpDetailsForm_StateValid;
    let IsEmployee_EmpDetailsForm_PostalCodeValid = this.state.IsEmployee_EmpDetailsForm_PostalCodeValid;

    let IsEmployee_EmpDetailsForm_Postal_Address1Valid = this.state.IsEmployee_EmpDetailsForm_Postal_Address1Valid;
    let IsEmployee_EmpDetailsForm_Postal_Address2Valid = this.state.IsEmployee_EmpDetailsForm_Postal_Address2Valid;
    let IsEmployee_EmpDetailsForm_Postal_CityValid = this.state.IsEmployee_EmpDetailsForm_Postal_CityValid;
    let IsEmployee_EmpDetailsForm_Postal_StateValid = this.state.IsEmployee_EmpDetailsForm_Postal_StateValid;
    let IsEmployee_EmpDetailsForm_Postal_PostalCodeValid = this.state.IsEmployee_EmpDetailsForm_Postal_PostalCodeValid;


    let IsEmployee_EmpDetailsForm_Flags_MonValid = this.state.IsEmployee_EmpDetailsForm_Flags_MonValid;
    let IsEmployee_EmpDetailsForm_Flags_TueValid = this.state.IsEmployee_EmpDetailsForm_Flags_TueValid;
    let IsEmployee_EmpDetailsForm_Flags_WedValid = this.state.IsEmployee_EmpDetailsForm_Flags_WedValid;
    let IsEmployee_EmpDetailsForm_Flags_ThurValid = this.state.IsEmployee_EmpDetailsForm_Flags_ThurValid;
    let IsEmployee_EmpDetailsForm_Flags_FriValid = this.state.IsEmployee_EmpDetailsForm_Flags_FriValid;
    let IsEmployee_EmpDetailsForm_Flags_SatValid = this.state.IsEmployee_EmpDetailsForm_Flags_SatValid;
    let IsEmployee_EmpDetailsForm_Flags_SunValid = this.state.IsEmployee_EmpDetailsForm_Flags_SunValid;

    let IsEmployee_EmpDetailsForm_Alias_FirstNameValid = this.state.IsEmployee_EmpDetailsForm_Alias_FirstNameValid;
    let IsEmployee_EmpDetailsForm_Alias_LastNameValid = this.state.IsEmployee_EmpDetailsForm_Alias_LastNameValid;


    /*
      switch (fieldName) {
          case 'Employee_EmpDetailsForm_FirstName':
          IsEmployee_EmpDetailsForm_FirstNameValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_FirstName = IsEmployee_EmpDetailsForm_FirstNameValid ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_LastName':
          IsEmployee_EmpDetailsForm_LastNameValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_LastName = IsEmployee_EmpDetailsForm_LastNameValid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_Email':
          IsEmployee_EmpDetailsForm_EmailValid      = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Email = IsEmployee_EmpDetailsForm_EmailValid      ? '' : "Email is not valid should be like user@gmail.com";
          break;
          case 'Employee_EmpDetailsForm_Mobile':
          IsEmployee_EmpDetailsForm_MobileValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Mobile = IsEmployee_EmpDetailsForm_MobileValid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_Dept':
          IsEmployee_EmpDetailsForm_DeptValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Dept = IsEmployee_EmpDetailsForm_DeptValid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_Position':
          IsEmployee_EmpDetailsForm_PositionValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Position = IsEmployee_EmpDetailsForm_PositionValid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_Approver':
          IsEmployee_EmpDetailsForm_ApproverValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Approver = IsEmployee_EmpDetailsForm_ApproverValid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_HourlyRate':
          IsEmployee_EmpDetailsForm_HourlyRateValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_HourlyRate = IsEmployee_EmpDetailsForm_HourlyRateValid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_Template':
          IsEmployee_EmpDetailsForm_TemplateValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Template = IsEmployee_EmpDetailsForm_TemplateValid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_Global':
          IsEmployee_EmpDetailsForm_GlobalValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Global  = IsEmployee_EmpDetailsForm_GlobalValid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_StartDate':
          IsEmployee_EmpDetailsForm_StartDateValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_StartDate = IsEmployee_EmpDetailsForm_StartDateValid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_EndDate':
          IsEmployee_EmpDetailsForm_EndDateValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_EndDate = IsEmployee_EmpDetailsForm_EndDateValid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_EmpBasis':
          IsEmployee_EmpDetailsForm_EmpBasisValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_EmpBasis = IsEmployee_EmpDetailsForm_EmpBasisValid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_StateTax':
          IsEmployee_EmpDetailsForm_StateTaxValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_StateTax = IsEmployee_EmpDetailsForm_StateTaxValid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_Group':
          IsEmployee_EmpDetailsForm_GroupValid  = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Group = IsEmployee_EmpDetailsForm_GroupValid   ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_Address1':
          IsEmployee_EmpDetailsForm_Address1Valid       = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Address1 = IsEmployee_EmpDetailsForm_Address1Valid    ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_Address2':
          IsEmployee_EmpDetailsForm_Address2Valid  = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Address2 = IsEmployee_EmpDetailsForm_Address2Valid  ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_City':
          IsEmployee_EmpDetailsForm_CityValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_City= IsEmployee_EmpDetailsForm_CityValid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_State':
          IsEmployee_EmpDetailsForm_StateValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_State = IsEmployee_EmpDetailsForm_StateValid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_PostalCode':
          IsEmployee_EmpDetailsForm_PostalCodeValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_PostalCode = IsEmployee_EmpDetailsForm_PostalCodeValid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_Postal_Address1':
          IsEmployee_EmpDetailsForm_Postal_Address1Valid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Postal_Address1  = IsEmployee_EmpDetailsForm_Postal_Address1Valid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_Postal_Address2':
          IsEmployee_EmpDetailsForm_Postal_Address2Valid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Postal_Address2 = IsEmployee_EmpDetailsForm_Postal_Address2Valid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_Postal_City':
          IsEmployee_EmpDetailsForm_Postal_CityValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Postal_City = IsEmployee_EmpDetailsForm_Postal_CityValid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_Postal_State':
          IsEmployee_EmpDetailsForm_Postal_StateValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Postal_State = IsEmployee_EmpDetailsForm_Postal_StateValid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_Postal_PostalCode':
          IsEmployee_EmpDetailsForm_Postal_PostalCodeValid     = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Postal_PostalCode = IsEmployee_EmpDetailsForm_Postal_PostalCodeValid     ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_Flags_Mon':
          IsEmployee_EmpDetailsForm_Flags_MonValid       = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Flags_Mon   = IsEmployee_EmpDetailsForm_Flags_MonValid       ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_Flags_Tue':
          IsEmployee_EmpDetailsForm_Flags_TueValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Flags_Tue  = IsEmployee_EmpDetailsForm_Flags_TueValid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_Flags_Wed':
          IsEmployee_EmpDetailsForm_Flags_WedValid       = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Flags_Wed  = IsEmployee_EmpDetailsForm_Flags_WedValid       ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_Flags_Thur':
          IsEmployee_EmpDetailsForm_Flags_ThurValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Flags_Thur  = IsEmployee_EmpDetailsForm_Flags_ThurValid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_Flags_Fri':
          IsEmployee_EmpDetailsForm_Flags_FriValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Flags_Fri  = IsEmployee_EmpDetailsForm_Flags_FriValid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_Flags_Sat':
          IsEmployee_EmpDetailsForm_Flags_SatValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Flags_Sat  = IsEmployee_EmpDetailsForm_Flags_SatValid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_Flags_Sun':
          IsEmployee_EmpDetailsForm_Flags_SunValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_State = IsEmployee_EmpDetailsForm_Flags_SunValid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_Alias_FirstName':
          IsEmployee_EmpDetailsForm_Alias_FirstNameValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Alias_FirstName   = IsEmployee_EmpDetailsForm_Alias_FirstNameValid      ? '' : "This field is required";
          break;
          case 'Employee_EmpDetailsForm_Alias_LastName':
          IsEmployee_EmpDetailsForm_Alias_LastNameValid      = value.length > 0;
          Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Alias_LastName = IsEmployee_EmpDetailsForm_Alias_LastNameValid      ? '' : "This field is required";
          break;
       
  
  
       default:
          break;
      }
      this.setState({
        Emp_EmpDetailsFormError : Emp_EmpDetailsFormError,
        IsEmployee_EmpDetailsForm_FirstNameValid :IsEmployee_EmpDetailsForm_FirstNameValid,
        IsEmployee_EmpDetailsForm_LastNameValid :IsEmployee_EmpDetailsForm_LastNameValid,
        IsEmployee_EmpDetailsForm_EmailValid :IsEmployee_EmpDetailsForm_EmailValid,
        IsEmployee_EmpDetailsForm_MobileValid :IsEmployee_EmpDetailsForm_MobileValid,
        IsEmployee_EmpDetailsForm_DeptValid :IsEmployee_EmpDetailsForm_DeptValid,
        IsEmployee_EmpDetailsForm_PositionValid :IsEmployee_EmpDetailsForm_PositionValid,
        IsEmployee_EmpDetailsForm_ApproverValid :IsEmployee_EmpDetailsForm_ApproverValid,
        IsEmployee_EmpDetailsForm_HourlyRateValid :IsEmployee_EmpDetailsForm_HourlyRateValid,
        IsEmployee_EmpDetailsForm_TemplateValid :IsEmployee_EmpDetailsForm_TemplateValid,
        IsEmployee_EmpDetailsForm_GlobalValid :IsEmployee_EmpDetailsForm_GlobalValid,
        IsEmployee_EmpDetailsForm_StartDateValid :IsEmployee_EmpDetailsForm_StartDateValid,
        IsEmployee_EmpDetailsForm_EndDateValid :IsEmployee_EmpDetailsForm_EndDateValid,
        IsEmployee_EmpDetailsForm_EmpBasisValid:IsEmployee_EmpDetailsForm_EmpBasisValid,
        IsEmployee_EmpDetailsForm_StateTaxValid :IsEmployee_EmpDetailsForm_StateTaxValid,
        IsEmployee_EmpDetailsForm_GroupValid :IsEmployee_EmpDetailsForm_GroupValid,
     
     
     
        IsEmployee_EmpDetailsForm_Address1Valid :IsEmployee_EmpDetailsForm_Address1Valid,
        IsEmployee_EmpDetailsForm_Address2Valid:IsEmployee_EmpDetailsForm_Address2Valid,
        IsEmployee_EmpDetailsForm_CityValid:IsEmployee_EmpDetailsForm_CityValid,
        IsEmployee_EmpDetailsForm_StateValid:IsEmployee_EmpDetailsForm_StateValid,
        IsEmployee_EmpDetailsForm_PostalCodeValid:IsEmployee_EmpDetailsForm_PostalCodeValid,
     
        IsEmployee_EmpDetailsForm_Postal_Address1Valid :IsEmployee_EmpDetailsForm_Postal_Address1Valid,
        IsEmployee_EmpDetailsForm_Postal_Address2Valid:IsEmployee_EmpDetailsForm_Postal_Address2Valid,
        IsEmployee_EmpDetailsForm_Postal_CityValid : IsEmployee_EmpDetailsForm_Postal_CityValid,
        IsEmployee_EmpDetailsForm_Postal_StateValid :IsEmployee_EmpDetailsForm_Postal_StateValid,
        IsEmployee_EmpDetailsForm_Postal_PostalCodeValid : IsEmployee_EmpDetailsForm_Postal_PostalCodeValid,
     
     
        IsEmployee_EmpDetailsForm_Flags_MonValid : IsEmployee_EmpDetailsForm_Flags_MonValid,
        IsEmployee_EmpDetailsForm_Flags_TueValid : IsEmployee_EmpDetailsForm_Flags_TueValid,
        IsEmployee_EmpDetailsForm_Flags_WedValid : IsEmployee_EmpDetailsForm_Flags_WedValid,
        IsEmployee_EmpDetailsForm_Flags_ThurValid : IsEmployee_EmpDetailsForm_Flags_ThurValid,
        IsEmployee_EmpDetailsForm_Flags_FriValid : IsEmployee_EmpDetailsForm_Flags_FriValid,
        IsEmployee_EmpDetailsForm_Flags_SatValid : IsEmployee_EmpDetailsForm_Flags_SatValid,
        IsEmployee_EmpDetailsForm_Flags_SunValid : IsEmployee_EmpDetailsForm_Flags_SunValid,
     
        IsEmployee_EmpDetailsForm_Alias_FirstNameValid : IsEmployee_EmpDetailsForm_Alias_FirstNameValid,
        IsEmployee_EmpDetailsForm_Alias_LastNameValid: IsEmployee_EmpDetailsForm_Alias_LastNameValid ,
    	
  
      })*/

  }
  EmployeeListSelectHandler = (e) => {


    if (e.target.name === 'EmployeeList_ExcelSelect') {


      if (e.target.selectedIndex > 0) {

        this.setState({
          EmployeeList_ExcelSelect: e.target.value,
          IsEmployeeList_ExcelSelectValid: true,
        })


      }
      else if (e.target.selectedIndex === 0) {

        this.setState({

          EmployeeList_ExcelSelect: '',
          IsEmployeeList_ExcelSelectValid: false,


        })
      }

    }

    if (e.target.name === 'EmployeeList_SelectPaste_SelectType') {

      if (e.target.selectedIndex > 0) {

        this.setState({
          EmployeeList_SelectPaste_SelectType: e.target.value,
          IsEmployeeList_SelectPaste_SelectTypeValid: true,
        })


      }
      else if (e.target.selectedIndex === 0) {

        this.setState({

          EmployeeList_SelectPaste_SelectType: '',
          IsEmployeeList_SelectPaste_SelectTypeValid: false,
        })
      }



    }
    if (e.target.name === 'EmployeeList_Reports_Dropdown') {


      if (e.target.selectedIndex > 0) {

        this.setState({
          EmployeeList_Reports_Dropdown: e.target.value,
          IsEmployeeList_Reports_DropdownValid: true,
        })
      }
      else if (e.target.selectedIndex === 0) {

        this.setState({

          EmployeeList_Reports_Dropdown: '',
          IsEmployeeList_Reports_DropdownValid: false,
        })
      }


    }
    if (e.target.name === 'Employee_EmpDetailsForm_EmpBasis') {


      if (e.target.selectedIndex > 0) {

        this.setState({
          Employee_EmpDetailsForm_EmpBasis: e.target.value,
          IsEmployee_EmpDetailsForm_EmpBasisValid: true,
        })
      }
      else if (e.target.selectedIndex === 0) {

        this.setState({

          Employee_EmpDetailsForm_EmpBasis: '',
          IsEmployee_EmpDetailsForm_EmpBasisValid: false,
        })
      }


    }
    if (e.target.name === 'Employee_EmpDetailsForm_StateTax') {


      if (e.target.selectedIndex > 0) {

        this.setState({
          Employee_EmpDetailsForm_StateTax: e.target.value,
          IsEmployee_EmpDetailsForm_StateTaxValid: true,
        })
      }
      else if (e.target.selectedIndex === 0) {

        this.setState({

          Employee_EmpDetailsForm_StateTax: '',
          IsEmployee_EmpDetailsForm_StateTaxValid: false,
        })
      }


    }

  }

  EmployeeListExcelModalStartHandler = () => {

    if (this.state.EmployeesExcelData.length > 0) {
      $(":contains('Download')").closest('button').click();
     // toast.success('Data Exported Successfully');
      document.getElementById('export_excel_cancel_btn').click();

    } else {
      toast.error('Please select data to export');
      document.getElementById('export_excel_cancel_btn').click();
    }

  }
  exportEmployees = async () => {
    const excelData = [];
    excelData.push({
      columns: [
        { title: "FirstName", width: { wpx: 130 } },//pixels width 
        { title: "LastName", width: { wpx: 130 } },//char width 
        { title: "Email", width: { wpx: 130 } },
        { title: "Mobile", width: { wpx: 130 } },//pixels width 
        { title: "Department", width: { wpx: 130 } },//char width 
        { title: "Position", width: { wpx: 130 } },
        { title: "ApproverGroup", width: { wpx: 130 } },//pixels width 
        { title: "HourlyRate", width: { wpx: 130 } },//char width 
        { title: "Template", width: { wpx: 130 } },
        { title: "Global", width: { wpx: 130 } },//pixels width 
        { title: "Start Date", width: { wpx: 130 } },//char width 
        { title: "End Date", width: { wpx: 130 } },
        { title: "Terminated?", width: { wpx: 130 } },//pixels width 
        { title: "Agent", width: { wpx: 130 } },//char width 
        { title: "Emp Basis", width: { wpx: 130 } },//pixels width 
        { title: "State Tax", width: { wpx: 130 } },//char width 
        { title: "Group", width: { wpx: 130 } },//pixels width 
        { title: "Address 1", width: { wpx: 130 } },//char width 
        { title: "Address 2", width: { wpx: 130 } },//pixels width 
        { title: "City", width: { wpx: 130 } },//char width 
        { title: "State", width: { wpx: 130 } },//pixels width 
        { title: "Post Code", width: { wpx: 130 } },//char width 
        { title: "Postal Address 1", width: { wpx: 130 } },
        { title: "Postal Address 2", width: { wpx: 130 } },//pixels width 
        { title: "Postal City", width: { wpx: 130 } },//char width 
        { title: "Postal State", width: { wpx: 130 } },
        { title: "Postal Post Code", width: { wpx: 130 } },//pixels width 
        { title: "Monday Flag", width: { wpx: 130 } },//char width 
        { title: "Tuesday Flag", width: { wpx: 130 } },
        { title: "Wednesday Flag", width: { wpx: 130 } },//pixels width 
        { title: "Thursday Flag", width: { wpx: 130 } },//char width 
        { title: "Friday Flag", width: { wpx: 130 } },
        { title: "Saturday Flag", width: { wpx: 130 } },//pixels width 
        { title: "Sunday Flag", width: { wpx: 130 } },//char width
        { title: "First Name Alias", width: { wpx: 130 } },
        { title: "EmpGuid", width: { wpx: 210 } },
        { title: "UserGuid", width: { wpx: 210 } }
      ]
    });
    // console.log(this.state.EmployeeListTableData);
    excelData[0].data = [];
    // console.log(this.state.EmployeeListTableData);
    this.state.EmployeeListTableData.map(item => {
      if (item.UserGuid === undefined) {
        item.UserGuid = 'NA';
      }
      if (item.checked === true) {

        var Address1 = '';
        var Address12 = '';
        var City1 = '';
        var PostCode1 = '';
        var State1 = '';
        var Type1 = '';
        var Address2 = '';
        var Address22 = '';
        var City2 = '';
        var PostCode2 = '';
        var State2 = '';
        var Type2 = '';

        item.Address.map((address, index) => {
          if (index === 0) {
            Address1 = address.Address1;
            Address12 = address.Address2;
            City1 = address.City;
            PostCode1 = address.PostCode;
            State1 = address.State;
            Type1 = address.Type;
          } else
            if (index === 1) {
              Address2 = address.Address1;
              Address22 = address.Address2;
              City2 = address.City;
              PostCode2 = address.PostCode;
              State2 = address.State;
              Type2 = address.Type;
            }
        });
        excelData[0].data.push([
          { value: item.FirstName === null || '' ? 'NA' : item.FirstName, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.LastName === null || '' ? 'NA' : item.LastName, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.Email === null || '' ? 'NA' : item.Email, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.Mobile === null || '' ? 'NA' : item.Mobile, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.Department === null || '' ? 'NA' : item.Department, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.Position === null || '' ? 'NA' : item.Position, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.ApproverGroup === null || '' ? 'NA' : item.ApproverGroup, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.HourlyRate === null || '' ? 'NA' : item.HourlyRate, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.TemplateCode === null || '' ? 'NA' : item.TemplateCode, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.GlobalCode === null || '' ? 'NA' : item.GlobalCode },
          { value: item.StartDate === null || '' ? 'NA' : item.StartDate, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.TerminationDate === null || '' ? 'NA' : item.TerminationDate, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.Terminated === null || '' ? 'NA' : item.Terminated, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.AgentCode === null || '' ? 'NA' : item.AgentCode, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.EmpBasis === null || '' ? 'NA' : item.EmpBasis, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.StateTax === null || '' ? 'NA' : item.StateTax, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.TimecardGroup === null || '' ? 'NA' : item.TimecardGroup },
          { value: Address1 === null || '' ? 'NA' : Address1, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: Address12 === null || '' ? 'NA' : Address12, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: City1 === null || '' ? 'NA' : City1, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: State1 === null || '' ? 'NA' : State1, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: PostCode1 === null || '' ? 'NA' : PostCode1, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: Address2 === null || '' ? 'NA' : Address2, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: Address22 === null || '' ? 'NA' : Address22, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: City2 === null || '' ? 'NA' : City2, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: State2 === null || '' ? 'NA' : State2, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: PostCode2 === null || '' ? 'NA' : PostCode2, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.PaymentFlags[0].Mon === null || '' ? 'NA' : item.PaymentFlags[0].Mon, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.PaymentFlags[0].Tue === null || '' ? 'NA' : item.PaymentFlags[0].Tue, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.PaymentFlags[0].Wed === null || '' ? 'NA' : item.PaymentFlags[0].Wed, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.PaymentFlags[0].Thu === null || '' ? 'NA' : item.PaymentFlags[0].Thu, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.PaymentFlags[0].Fri === null || '' ? 'NA' : item.PaymentFlags[0].Fri, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.PaymentFlags[0].Sat === null || '' ? 'NA' : item.PaymentFlags[0].Sat, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.PaymentFlags[0].Sun === null || '' ? 'NA' : item.PaymentFlags[0].Sun, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.AliasFirstName === null || '' ? 'NA' : item.AliasFirstName, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.EmpGuid === null || '' ? 'NA' : item.EmpGuid, style: { alignment: { horizontal: "center", vertical: 'center' } } },
          { value: item.UserGuid === null || '' ? 'NA' : item.UserGuid, style: { alignment: { horizontal: "center", vertical: 'center' } } }
        ])
      }
    })
    // console.log(excelData);
    await this.setState({
      EmployeesExcelData: excelData
    })
  }
  selectPaste_submitHandler = async () => {

    if (this.state.importdata !== '') {

      const pastedExcelData = this.state.importdata;
      var res = pastedExcelData.split(/[\n\t]/);

      const newExcelData = [];

      var excel_columns = [];
      var excel_data = [];
      for (var i = 0; i < Math.round(res.length / 37); i++) {
        for (var j = i * 37; j < (1 + i) * 37; j++) {
          if (i === 0) {
            excel_columns.push(res[j]);
          } else {
            excel_data.push(res[j]);
          }
        }
      }
      var final_excel_data = [];
      for (var i = 0; i < excel_data.length / 37; i++) {
        var temp = [];
        for (var j = i * 37; j < (1 + i) * 37; j++) {
          temp.push(excel_data[j]);
        }
        final_excel_data.push(temp);
      }

      const newExcelDataToImport = [];
      for (var i = 0; i < final_excel_data.length; i++) {
        const newImportedObject = {};




        newImportedObject.address1 = final_excel_data[i][17];
        newImportedObject.address2 = final_excel_data[i][18];
        newImportedObject.city = final_excel_data[i][19];
        newImportedObject.state = final_excel_data[i][20];
        newImportedObject.postcode = final_excel_data[i][21];

        newImportedObject.postaladdress1 = final_excel_data[i][22];
        newImportedObject.postaladdress2 = final_excel_data[i][23];
        newImportedObject.postalcity = final_excel_data[i][24];
        newImportedObject.postalstate = final_excel_data[i][25];
        newImportedObject.postalpostcode = final_excel_data[i][26];

        newImportedObject.emp_flagsun = final_excel_data[i][27];
        newImportedObject.flagmon = final_excel_data[i][28];
        newImportedObject.flagtue = final_excel_data[i][29];
        newImportedObject.flagwed = final_excel_data[i][30];
        newImportedObject.flagthu = final_excel_data[i][31];
        newImportedObject.flagfri = final_excel_data[i][32];
        newImportedObject.flagsat = final_excel_data[i][33];

        newImportedObject.agent = final_excel_data[i][13];
        newImportedObject.firstnamealias = final_excel_data[i][34];
        newImportedObject.approvergroup = final_excel_data[i][6];
        newImportedObject.department = final_excel_data[i][4];
        newImportedObject.email = final_excel_data[i][2];
        newImportedObject.empbasis = final_excel_data[i][14];
        newImportedObject.guid = final_excel_data[i][35];
        newImportedObject.firstname = final_excel_data[i][0];
        newImportedObject.hourlyrate = final_excel_data[i][7];
        newImportedObject.lastname = final_excel_data[i][1];
        newImportedObject.mobile = final_excel_data[i][3];
        newImportedObject.position = final_excel_data[i][5];
        newImportedObject.startdate = final_excel_data[i][10];
        newImportedObject.statetax = final_excel_data[i][15];
        newImportedObject.template = final_excel_data[i][8];
        newImportedObject.terminated = final_excel_data[i][12];
        newImportedObject.terminationdate = final_excel_data[i][11];
        newImportedObject.UserGuid = final_excel_data[i][36] === "NA" ? null : final_excel_data[i][36];
        newImportedObject.TenantGuid = localStorage.getItem('useableGuid');
        newImportedObject.attachments = this.state.attachments_array[final_excel_data[i][35]] ? this.state.attachments_array[final_excel_data[i][35]] : [];

        newExcelDataToImport.push(newImportedObject);
      }
      const newJSONObj = newExcelDataToImport;

      await API.post("createemployees", "/createemployees", {
        body: {
          finaldata: newJSONObj
        }
      }).then(data => {
        //toast.success('Data Sync Successfully');
        this.fetchemployeefromdynamo();
      }).catch(err => {
        //toast.success('Data not synced in dynamo. fetching old data');
        toast.error(err.message);
        this.fetchemployeefromdynamo();
      });
      document.getElementById('close_paste').click();
      this.setState({
        isLoading: false
      })
    } else {
      toast.error("Please Paste Data To Import");
    }
  };

  onCancelPaste = () => {
    this.setState({
      importdata: ''
    })
  }




  EmployeeDetailsFormSaveHandler = async () => {
    var completetenant = localStorage.getItem('completetenant');
    completetenant = JSON.parse(completetenant);
    var TphPayrollApi = completetenant.TphPayrollApi;
    this.setState({
      isLoading: true
    })
    var userGuid_array = [];
    var tmp_obj = { Guid: this.state.Guid_forUpdate, UserGuid: this.state.employee_UserGuid, stdtimes: this.state.stdtimes, appgroup: this.state.Employee_EmpDetailsForm_Approver };
    await userGuid_array.push(tmp_obj);
    await this.setState({
      userGuid_tmp: userGuid_array
    })


    var that = this;
    var dat = {
      "firstname": this.state.Employee_EmpDetailsForm_FirstName,
      "lastname": this.state.Employee_EmpDetailsForm_LastName,
      "email": this.state.Employee_EmpDetailsForm_Email,
      "mobile": this.state.Employee_EmpDetailsForm_Mobile,
      "department": this.state.Employee_EmpDetailsForm_Dept,
      "position": this.state.Employee_EmpDetailsForm_Position,

      "hourlyrate": this.state.Employee_EmpDetailsForm_HourlyRate,
      "template": this.state.Employee_EmpDetailsForm_Template,
      "global": this.state.Employee_EmpDetailsForm_Global,
      "startdate": moment(this.state.Employee_EmpDetailsForm_StartDate).format('YYYY-MM-DD'),
      "terminationdate": moment(this.state.Employee_EmpDetailsForm_EndDate).format('YYYY-MM-DD'),
      "terminated": this.state.IsEmployee_EmpDetails_TerimatedChkbox_Checked ? "T" : "F",
      "agent": this.state.Employee_EmpDetailsForm_Agent,
      "empbasis": this.state.Employee_EmpDetailsForm_EmpBasis,
      "statetax": this.state.Employee_EmpDetailsForm_StateTax,
      "group": this.state.Employee_EmpDetailsForm_Group,
      "address1": this.state.Employee_EmpDetailsForm_Address1,
      "address2": this.state.Employee_EmpDetailsForm_Address2,
      "city": this.state.Employee_EmpDetailsForm_City,
      "state": this.state.Employee_EmpDetailsForm_State,
      "postcode": this.state.Employee_EmpDetailsForm_PostalCode,
      "postaladdress1": this.state.Employee_EmpDetailsForm_Postal_Address1,
      "postaladdress2": this.state.Employee_EmpDetailsForm_Postal_Address2,
      "postalcity": this.state.Employee_EmpDetailsForm_Postal_City,
      "postalstate": this.state.Employee_EmpDetailsForm_Postal_State,
      "postalpostcode": this.state.Employee_EmpDetailsForm_Postal_PostalCode,
      "emp_flagsun": this.state.Employee_EmpDetailsForm_Flags_Sun,
      "flagmon": this.state.Employee_EmpDetailsForm_Flags_Mon,
      "flagtue": this.state.Employee_EmpDetailsForm_Flags_Tue,
      "flagwed": this.state.Employee_EmpDetailsForm_Flags_Wed,
      "flagthu": this.state.Employee_EmpDetailsForm_Flags_Thur,
      "flagfri": this.state.Employee_EmpDetailsForm_Flags_Fri,
      "flagsat": this.state.Employee_EmpDetailsForm_Flags_Sat,
      "firstnamealias": this.state.Employee_EmpDetailsForm_Alias_FirstName,
      "lastnamealias": this.state.Employee_EmpDetailsForm_Alias_LastName,
      "guid": this.state.Guid_forUpdate,
      "attachments": this.state.attachment_forupdate,

    }

    await this.gettimestamp();
    var date_latest = new Date(this.state.storedtimestamp).toJSON();
    $.ajax({
      url: TphPayrollApi.url + '/api/apiemployee?timestamp=' + date_latest + '&username=' + TphPayrollApi.key + '&password=' + TphPayrollApi.password,
      type: "post",
      dataType: "json",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify({
        "guid": this.state.Guid_forUpdate,
        "username": "5187739f6d9ecee2167bd2011df69787",
        "password": "4142ae33f921e0401df6b844ef81c910",
        "QEmployeeUpdate": [{
          "employee_list": {
            "firstname": this.state.Employee_EmpDetailsForm_FirstName,
            "lastname": this.state.Employee_EmpDetailsForm_LastName,
            "email": this.state.Employee_EmpDetailsForm_Email,
            "mobile": this.state.Employee_EmpDetailsForm_Mobile,
            "department": this.state.Employee_EmpDetailsForm_Dept,
            "position": this.state.Employee_EmpDetailsForm_Position,
            "approvergroup": this.state.Employee_EmpDetailsForm_Approver,
            "hourlyrate": this.state.Employee_EmpDetailsForm_HourlyRate,
            "template": this.state.Employee_EmpDetailsForm_Template,
            "global": this.state.Employee_EmpDetailsForm_Global,
            "startdate": this.state.Employee_EmpDetailsForm_StartDate,
            "terminationdate": this.state.Employee_EmpDetailsForm_EndDate,
            "terminated": this.state.IsEmployee_EmpDetails_TerimatedChkbox_Checked ? "T" : "F",
            "agent": this.state.Employee_EmpDetailsForm_Agent,
            "empbasis": this.state.Employee_EmpDetailsForm_EmpBasis,
            "statetax": this.state.Employee_EmpDetailsForm_StateTax,
            "group": this.state.Employee_EmpDetailsForm_Group,
            "address1": this.state.Employee_EmpDetailsForm_Address1,
            "address2": this.state.Employee_EmpDetailsForm_Address2,
            "city": this.state.Employee_EmpDetailsForm_City,
            "state": this.state.Employee_EmpDetailsForm_State,
            "postcode": this.state.Employee_EmpDetailsForm_PostalCode,
            "postaladdress1": this.state.Employee_EmpDetailsForm_Postal_Address1,
            "postaladdress2": this.state.Employee_EmpDetailsForm_Postal_Address2,
            "postalcity": this.state.Employee_EmpDetailsForm_Postal_City,
            "postalstate": this.state.Employee_EmpDetailsForm_Postal_State,
            "postalpostcode": this.state.Employee_EmpDetailsForm_Postal_PostalCode,
            "emp_flagsun": this.state.Employee_EmpDetailsForm_Flags_Sun,
            "flagmon": this.state.Employee_EmpDetailsForm_Flags_Mon,
            "flagtue": this.state.Employee_EmpDetailsForm_Flags_Tue,
            "flagwed": this.state.Employee_EmpDetailsForm_Flags_Wed,
            "flagthu": this.state.Employee_EmpDetailsForm_Flags_Thur,
            "flagfri": this.state.Employee_EmpDetailsForm_Flags_Fri,
            "flagsat": this.state.Employee_EmpDetailsForm_Flags_Sat,
            "firstnamealias": this.state.Employee_EmpDetailsForm_Alias_FirstName,
            "lastnamealias": this.state.Employee_EmpDetailsForm_Alias_LastName,

          }
        }]
      }),
      success: function (res) {

        $('#close_modal').click();
        API.post('timestampsync', '/timestampsync', {
          body: {
            tenant: localStorage.getItem('useableGuid'),
            Guid: that.state.stampguid
          }
        })
          .then(resu => {
            //toast.success("time stamp synchronized successfully")
          }, that.refresh_list())
          .catch(err => {
            toast.error("error, time stamp not synchronized");
          })
        //toast.success('Data Updated SuccessFully');




      }


    });

    this.setState({
      isLoading: false
    })

  }

  refresh_list = async () => {
    $('#freshbtn').addClass('moving');
    await this.gettimestamp();

    await this.syncgetapidata(new Date(this.state.storedtimestamp).toJSON());
    $('#freshbtn').removeClass('moving');
  }


  EmployeeListpAllTableChkBoxHandler = (chkboxName, isChecked) => {


    let isAllChecked = (chkboxName === 'isAllEmployeeListTableChkbxSelected' && isChecked);
    let isAllUnchecked = (chkboxName === 'isAllEmployeeListTableChkbxSelected' && !isChecked);
    const checked = isChecked;
    const checkList = this.state.EmployeeListTableData.map(item => {

      if (isAllChecked || item.Guid === chkboxName) {

        return {
          ...item,
          checked,
        }
      }
      else if (isAllUnchecked) {
        return {
          ...item,
          checked: false,
        }
      }

      return item;


    });

    let isAllSelected = (checkList.findIndex(item => item.checked === false) === -1) || isAllChecked;

    this.setState({
      EmployeeListTableData: checkList,
      isAllEmployeeListTableChkbxSelected: isAllSelected
    })



  }

  hanldeCopyPasteState = () => {
    this.setState({
      importdata: ''
    })
  }
  generateReport = async () => {
    var array_temp = [];
    this.state.EmployeeListTableData.map(abc => {
      if (abc.checked === true) {
        array_temp.push(abc);
      }
    })
    if (array_temp.length > 0) {
      let data = {
        "Employees": array_temp
      };
      data = JSON.stringify(data)
      localStorage.setItem('getreport', data);
      var path = window.location.protocol + '//' + window.location.host + '/EmployeeReport';
      window.open(path, '_blank');
    } else {
      toast.error("Please Select at Least One Record");
      $('.close').click();
      // $('#employee_reports').modal('hide');
      // $('body').removeClass('modal-open'); 
      // $('.modal-backdrop').remove();
    }

  }


  EmpDateChangeHandler = (name, date) => {

    this.setState({
      [name]: date
    })
  }

  render() {
    const attachments = this.state.SelectedEmployeeAttachment.attachments
    return (

      <div>
        <div id="viewer"></div>
        {this.state.isLoading ?
          <div className="se-pre-con"></div>
          : ''}
        <Header1
          pageHeader="Employee List"
          childProps={this.props.childProps}

        />

        <div className="col-lg-10 col-lg-offset-1 mrg_top_employee">
          <div className="col-sm-6 p0">
            <div className="search_area_employee">
              <input
                placeholder={`Employee search by ${this.state.EmployeeList_SelectedTab}`}
                name="EmployeeListTableSearchBox"
                type="text"
                id="EmployeeListTableSearchBox"
                className="search_employee"
                value={this.state.EmployeeListTableSearchBox}
                onChange={this.EmployeeListSearchHandler}
              />
              <img
                src={ic_search}
                width="20"
                height="20"
                alt=""
              />
            </div>
          </div>

          <div className="col-sm-6 p0 mrg_topright2">
            <div className="dropdown menu_icon_employee">
              <button
                className="dropdown-toggle btn_employee_menu"
                type="button"
                data-toggle="dropdown"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="5.223"
                  height="20.893"
                  viewBox="0 0 5.223 20.893"
                >
                  <path
                    id="ic_more_horiz_24px"
                    d="M6.612,10a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,6.612,10Zm15.669,0a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,22.281,10Zm-7.835,0a2.612,2.612,0,1,0,2.612,2.612A2.619,2.619,0,0,0,14.446,10Z"
                    transform="translate(15.223 -4) rotate(90)"
                    fill="#7b7b7b"
                  />
                </svg>
              </button>
              <ul className="dropdown-menu dropleft dropdown_menu_users_down dropdown_menu_employee">
                <li>
                  <a
                    href={null}
                    data-toggle="modal"
                    data-target="#excel_pop_employee"
                    className="cursorPointer"
                    onClick={this.exportEmployees}
                  >
                    <img
                      src={excel}
                      className="mrg_sendicon"
                      width="11"
                      height="14"
                      alt=""
                    />
                    Excel
                  </a>
                </li>
                <li>
                  <a
                    href={null}
                    data-toggle="modal"
                    data-target="#select_paste_pop"
                    className="cursorPointer"
                    onClick={this.hanldeCopyPasteState}
                  >
                    <img
                      src={select_paste}
                      className="mrg_sendicon"
                      width="12"
                      height="15"
                      alt=""
                    />
                    Select Paste
                  </a>
                </li>

                <li>
                  <a
                    href={null}
                    data-toggle="modal"
                    data-target="#employee_reports"
                    className="cursorPointer"
                  >
                    <img
                      src={excel}
                      className="mrg_sendicon"
                      width="11"
                      height="14"
                      alt=""
                    />
                    Reports
                  </a>
                </li>
              </ul>
            </div>

            < a href={null} onClick={this.refresh_list} id="freshbtn" className="refresh_icon_employee cursorPointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24.224"
                height="17.617"
                viewBox="0 0 24.224 17.617"
              >
                <path
                  id="ic_cached_24px"
                  d="M20.819,8.4l-4.4,4.4h3.3a6.612,6.612,0,0,1-6.606,6.606,6.464,6.464,0,0,1-3.083-.771L8.421,20.252a8.793,8.793,0,0,0,13.5-7.443h3.3Zm-14.314,4.4A6.612,6.612,0,0,1,13.112,6.2a6.464,6.464,0,0,1,3.083.771L17.8,5.365A8.793,8.793,0,0,0,4.3,12.809H1l4.4,4.4,4.4-4.4Z"
                  transform="translate(-1 -4)"
                  fill="#7B7B7B"
                />
              </svg>
            </a>
          </div>
          <ExcelFile element={<button>Download Data With Styles</button>} filename={"Employees_List_" + new Date()}>
            <ExcelSheet dataSet={this.state.EmployeesExcelData} name="Employees List" />
          </ExcelFile>
          <div className="clear10" />

          <div className="col-sm-12 p0">
            <div className="overflow_scroll_sm">
              <table
                width="100%"
                className="table table-bordered timecard_table employee_list_table"
              >


                <thead>
                  <tr className="table_header_employee">
                    <th width="3%" align="center">
                      <input name="isAllEmployeeListTableChkbxSelected"
                        type="checkbox" checked={this.state.isAllEmployeeListTableChkbxSelected}
                        onChange={(e) => this.EmployeeListpAllTableChkBoxHandler('isAllEmployeeListTableChkbxSelected', e.target.checked)} />
                    </th>
                    <th nowrap width="10%" align="center">
                      <div className="cursorPointer" onClick={() => this.EmployeeListSortHandler('FirstName', 'FirstNameEmpCaret')}>
                        <div className="pull-left"> First Name</div>
                        <div className="employee_downarrow">
                          <svg id="FirstNameEmpCaret" className={!this.state.EmployeeList_FirstNameToggle ? "toggleCaretClass" : ''}
                            xmlns="http://www.w3.org/2000/svg"
                            width="12.337"
                            height="6.887"
                            viewBox="0 0 12.337 6.887"
                          >
                            <path
                              id="ic_arrow_drop_down_24px"
                              d="M7,10l6.169,6.887L19.337,10Z"
                              transform="translate(-7 -10)"
                              fill="#30679a"
                            />
                          </svg>
                        </div>
                      </div>
                    </th>
                    <th nowrap width="8%" align="center">
                      <div className="cursorPointer" onClick={() => this.EmployeeListSortHandler('LastName', 'LastNameEmpCaret')}>
                        <div className="pull-left">Last Name</div>
                        <div className="employee_downarrow" >
                          <svg id="LastNameEmpCaret"
                            className={!this.state.EmployeeList_LastNameToggle ? "toggleCaretClass" : ''}
                            xmlns="http://www.w3.org/2000/svg"
                            width="12.337"
                            height="6.887"
                            viewBox="0 0 12.337 6.887"
                          >
                            <path
                              id="ic_arrow_drop_down_24px"
                              d="M7,10l6.169,6.887L19.337,10Z"
                              transform="translate(-7 -10)"
                              fill="#30679a"
                            />
                          </svg>
                        </div>
                      </div>
                    </th>
                    <th nowrap width="8%" align="center">
                      <div className="cursorPointer" onClick={() => this.EmployeeListSortHandler('Start', 'StartCaret')}>
                        <div className="p-new"> Start <div className="employee_downarrow new1" >
                          <svg id="StartCaret" className={!this.state.EmployeeList_StartToggle ? "toggleCaretClass" : ''}
                            xmlns="http://www.w3.org/2000/svg"
                            width="12.337"
                            height="6.887"
                            viewBox="0 0 12.337 6.887"
                          >
                            <path
                              id="ic_arrow_drop_down_24px"
                              d="M7,10l6.169,6.887L19.337,10Z"
                              transform="translate(-7 -10)"
                              fill="#30679a"
                            />
                          </svg>
                        </div></div>

                      </div>
                    </th>
                    <th nowrap width="8%" align="center">
                      <div className="cursorPointer" onClick={() => this.EmployeeListSortHandler('End', 'EndCaret')}>
                        <div className=" p-new">End <div className="employee_downarrow new1" >
                          <svg id="EndCaret"
                            className={!this.state.EmployeeList_EndToggle ? "toggleCaretClass" : ''}
                            xmlns="http://www.w3.org/2000/svg"
                            width="12.337"
                            height="6.887"
                            viewBox="0 0 12.337 6.887"
                          >
                            <path
                              id="ic_arrow_drop_down_24px"
                              d="M7,10l6.169,6.887L19.337,10Z"
                              transform="translate(-7 -10)"
                              fill="#30679a"
                            />
                          </svg>
                        </div></div>

                      </div>
                    </th>
                    <th nowrap width="12%" align="center">
                      <div className="cursorPointer" onClick={() => this.EmployeeListSortHandler('Terminated', 'TerminatedEmpCaret')}>
                        <div className="pull-left">Terminated</div>
                        <div className="employee_downarrow">
                          <svg id="TerminatedEmpCaret" className={!this.state.EmployeeList_TerminatedToggle ? "toggleCaretClass" : ''}
                            xmlns="http://www.w3.org/2000/svg"
                            width="12.337"
                            height="6.887"
                            viewBox="0 0 12.337 6.887"
                          >
                            <path
                              id="ic_arrow_drop_down_24px"
                              d="M7,10l6.169,6.887L19.337,10Z"
                              transform="translate(-7 -10)"
                              fill="#30679a"
                            />
                          </svg>
                        </div>
                      </div>
                    </th>
                    <th nowrap width="8%" align="center">
                      <div className="cursorPointer" onClick={() => this.EmployeeListSortHandler('Group', 'GroupEmpCaret')}>
                        <div className="pull-left">Group</div>
                        <div className="employee_downarrow">
                          <svg id="GroupEmpCaret" className={!this.state.EmployeeList_GroupToggle ? "toggleCaretClass" : ''}
                            xmlns="http://www.w3.org/2000/svg"
                            width="12.337"
                            height="6.887"
                            viewBox="0 0 12.337 6.887"
                          >
                            <path
                              id="ic_arrow_drop_down_24px"
                              d="M7,10l6.169,6.887L19.337,10Z"
                              transform="translate(-7 -10)"
                              fill="#30679a"
                            />
                          </svg>
                        </div>
                      </div>
                    </th>
                    <th nowrap width="8%" align="center">
                      <div className="cursorPointer" onClick={() => this.EmployeeListSortHandler('Basis', 'BasisCaret')}>
                        <div className="p-new">Basis <div className="employee_downarrow new2">
                          <svg id="BasisCaret" className={!this.state.EmployeeList_BasisToggle ? "toggleCaretClass" : ''}
                            xmlns="http://www.w3.org/2000/svg"
                            width="12.337"
                            height="6.887"
                            viewBox="0 0 12.337 6.887"
                          >
                            <path
                              id="ic_arrow_drop_down_24px"
                              d="M7,10l6.169,6.887L19.337,10Z"
                              transform="translate(-7 -10)"
                              fill="#30679a"
                            />
                          </svg>
                        </div></div>

                      </div>
                    </th>
                    <th nowrap width="6%" align="center">
                      <div className="cursorPointer" onClick={() => this.EmployeeListSortHandler('Dept', 'DeptCaret')}>
                        <div className="p-new"> Dept <div className="employee_downarrow new1">
                          <svg id="DeptCaret" className={!this.state.EmployeeList_DeptToggle ? "toggleCaretClass" : ''}
                            xmlns="http://www.w3.org/2000/svg"
                            width="12.337"
                            height="6.887"
                            viewBox="0 0 12.337 6.887"
                          >
                            <path
                              id="ic_arrow_drop_down_24px"
                              d="M7,10l6.169,6.887L19.337,10Z"
                              transform="translate(-7 -10)"
                              fill="#30679a"
                            />
                          </svg>
                        </div></div>

                      </div>
                    </th>
                    <th nowrap width="5%" align="center">


                      <div className="cursorPointer" onClick={() => this.EmployeeListSortHandler('Position', 'PositionEmpCaret')}>
                        <div className="p-new">Position{" "}<div className="employee_downarrow new1" >
                          <svg id="PositionEmpCaret" className={!this.state.EmployeeList_PositionToggle ? "toggleCaretClass" : ''}
                            xmlns="http://www.w3.org/2000/svg"
                            width="12.337"
                            height="6.887"
                            viewBox="0 0 12.337 6.887"
                          >
                            <path
                              id="ic_arrow_drop_down_24px"
                              d="M7,10l6.169,6.887L19.337,10Z"
                              transform="translate(-7 -10)"
                              fill="#30679a"
                            />
                          </svg>
                        </div></div>

                      </div>
                    </th>
                    <th nowrap width="17%" align="center">


                      <div className="cursorPointer" onClick={() => this.EmployeeListSortHandler('ApproverGroup', 'ApproverCaret')}>
                        <div className="pull-left"> Approver group</div>
                        <div className="employee_downarrow">
                          <svg id="ApproverCaret" className={!this.state.EmployeeList_ApproverGroupToggle ? "toggleCaretClass" : ''}
                            xmlns="http://www.w3.org/2000/svg"
                            width="12.337"
                            height="6.887"
                            viewBox="0 0 12.337 6.887"
                          >
                            <path
                              id="ic_arrow_drop_down_24px"
                              d="M7,10l6.169,6.887L19.337,10Z"
                              transform="translate(-7 -10)"
                              fill="#30679a"
                            />
                          </svg>
                        </div>
                      </div>
                    </th>
                    <th width="8%" align="center">
                      &nbsp;
</th>
                  </tr>
                </thead>
                <tbody>


                  {this.state.EmployeeListTableData.length > 0 &&
                    this.state.EmployeeListTableData.map((items, index) => {
                      return (
                        <tr key={items.Guid}>
                          <td align="center">
                            <input name={items.Guid} id={items.Guid} type="checkbox"
                              checked={items.checked}
                              onChange={(e) => this.EmployeeListpAllTableChkBoxHandler(items.Guid, e.target.checked)}
                            />
                          </td>
                          <td align="center">{items.FirstName}</td>
                          <td align="center">{items.LastName}</td>
                          <td align="center">{moment(items.StartDate).format('DD/MM/YYYY')}</td>
                          <td align="center">{moment(items.TerminationDate ? items.TerminationDate : items.FinishDate).format('DD/MM/YYYY')}</td>
                          <td align="center">{items.Terminated}</td>
                          <td align="center">{items.TimecardGroup}</td>
                          <td align="center">{(items.EmpBasis === "F") ? "Full Time" : (items.EmpBasis === "P") ? "Part-Time" : (items.EmpBasis === "C") ? "Casual" : "Other"}</td>
                          <td align="center">{items.Department}</td>
                          <td align="center">{items.Position}</td>
                          <td align="center">{items.ApproverGroup}</td>
                          <td align="center">
                            <a
                              href={null}
                              className="employee_attachment cursorPointer"
                              data-toggle="modal"
                              data-target="#employee_attachment" onClick={() => this.currentSelectedEmployee(index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11.766"
                                height="21.511"
                                viewBox="0 0 11.766 21.511"
                              >
                                <path
                                  id="ic_attach_file_24px"
                                  d="M17.162,5.889V17.134a4.106,4.106,0,0,1-4.279,3.911A4.106,4.106,0,0,1,8.6,17.134V4.911a2.568,2.568,0,0,1,2.674-2.444,2.568,2.568,0,0,1,2.674,2.444V15.178a1.074,1.074,0,0,1-2.139,0V5.889h-1.6v9.289a2.568,2.568,0,0,0,2.674,2.444,2.568,2.568,0,0,0,2.674-2.444V4.911A4.106,4.106,0,0,0,11.279,1,4.106,4.106,0,0,0,7,4.911V17.134a5.644,5.644,0,0,0,5.883,5.378,5.644,5.644,0,0,0,5.883-5.378V5.889Z"
                                  transform="translate(-7 -1)"
                                  fill="#7B7B7B"
                                />
                              </svg>
                            </a>

                            <a
                              href={null}
                              className="employee_pullleft_edit employe_pul_left_edit_iconnn cursorPointer"
                              data-toggle="modal"
                              data-target="#employee_detail" onClick={() => this.editCurrentEmployee(index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  id="ic_create_24px"
                                  className="cls-1"
                                  d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z"
                                  transform="translate(-3 -2.998)"
                                />
                              </svg>
                            </a>
                          </td>
                        </tr>
                      )


                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div id="excel_pop_employee" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle_employee modal-dialog-centered modal_no_scrol_employeee">


            <div className="modal-content modal_middle_h_employee">
              <div className="modal-header">
                <button type="button" className="close close_modal_employee" data-dismiss="modal">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B" />
                  </svg>

                </button>
                <h4 className="modal-title employeel_modal_header">Excel</h4>
              </div>
              <div className="modal-body">

                <div className="col-sm-8 employee_input_pop1">

                  <select name="EmployeeList_ExcelSelect" id="EmployeeList_ExcelSelect" onChange={this.EmployeeListSelectHandler}>
                    <option>Employee List</option>
                  </select>

                </div>



                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    < a href={null} className="btn_save_analytics cursorPointer" onClick={this.EmployeeListExcelModalStartHandler}>Start</a>
                    < a href={null} id="export_excel_cancel_btn" className="btn_cancel_analytics cursorPointer" data-dismiss="modal">Cancel</a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10"></div>

              </div>

            </div>

          </div>
        </div>
        <div id="select_paste_pop" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle_employee modal_no_scrol_employeee modal-dialog-centered">


            <div className="modal-content modal_middle_h_employee2">
              <div className="modal-header">
                <button type="button" className="close close_modal_employee" data-dismiss="modal">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B" />
                  </svg>

                </button>
                <h4 className="modal-title employeel_modal_header">Select Paste</h4>
              </div>
              <div className="modal-body">

                <div className="col-sm-8 employee_input_pop1">
                  <select name="EmployeeList_SelectPaste_SelectType" id="EmployeeList_SelectPaste_SelectType"
                    onChange={this.EmployeeListSelectHandler}>
                    <option>Select type</option>
                    <option>Employee List</option>
                  </select>
                </div>
                <div className="col-sm-12 employee_input_pop1">
                  <textarea name="importdata" cols="" rows="" value={this.state.importdata} onChange={this.EmployeeListInputsHandler}></textarea>
                </div>



                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    <a hreaf={null} className="btn_save_analytics cursorPointer"
                      onClick={this.selectPaste_submitHandler}>Paste</a>
                    <a href={null} id="close_paste" onClick={this.onCancelPaste} className="btn_cancel_analytics cursorPointer" data-dismiss="modal">Cancel</a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10"></div>

              </div>

            </div>

          </div>
        </div>
        <div id="employee_reports" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle_employee modal-dialog-centered modal_no_scrol_employeee">


            <div className="modal-content modal_middle_h_employee">
              <div className="modal-header">
                <button type="button" className="close close_modal_employee" data-dismiss="modal">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B" />
                  </svg>

                </button>
                <h4 className="modal-title employeel_modal_header">Reports</h4>
              </div>
              <div className="modal-body">

                <div className="col-sm-8 employee_input_pop1">

                  <select name="EmployeeList_Reports_Dropdown" id="EmployeeList_Reports_Dropdown" onChange={this.EmployeeListSelectHandler}>
                    <option>Employee List</option>
                  </select>

                </div>



                <div className="col-sm-12">
                  <div className="analytics_btn_save">
                    < a href={null} className="btn_save_analytics  cursorPointer" onClick={this.generateReport} >Start</a>
                    < a href={null} className="btn_cancel_analytics cursorPointer" data-dismiss="modal">Cancel</a>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
                <div className="clear10"></div>

              </div>

            </div>

          </div>
        </div>
        <div id="employee_attachment" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle_employee modal_no_scrol_employeee modal-dialog-centered">


            <div className="modal-content modal_middle_emp_attach">
              <div className="modal-header attach_header_pop">
                <button type="button" className="close close_modal_employee" data-dismiss="modal">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B" />
                  </svg>

                </button>
                <h4 className="modal-title employeel_modal_header">Attachments

</h4>
              </div>
              <div className="modal-body dynamicAttach">

                <div className="col-sm-12 table_attach_empl">

                  <table width="100%" className="table table-condensed">
                    <thead>
                      <tr>
                        <th colSpan="3" align="left">Description</th>
                      </tr>
                    </thead>


                    <tbody>

                      {this.state.SelectedEmployeeAttachment && this.state.SelectedEmployeeAttachment.attachments.map((attach, index) => {
                        return (
                          <tr key={index}>
                            <td width="90%">{attach.filename}

                            </td>
                            <td colSpan="2" align="center" width="10%">
                              < a href={null} className="doc_img_emp cursorPointer" onClick={() => this.dowonloadEmpAttach(attach.filename)}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 22.5">
                                  <path id="ic_description_24px" className="cls-1" d="M15.25,2h-9A2.247,2.247,0,0,0,4.011,4.25L4,22.25A2.247,2.247,0,0,0,6.239,24.5H19.75A2.257,2.257,0,0,0,22,22.25V8.75ZM17.5,20h-9V17.75h9Zm0-4.5h-9V13.25h9ZM14.125,9.875V3.688l6.187,6.188Z" transform="translate(-4 -2)" />
                                </svg>
                              </a>
                            </td>
                          </tr>
                        )
                      })}



                    </tbody>
                  </table>



                </div>




                <div className="clear10"></div>

              </div>

            </div>

          </div>
        </div>
        <div id="employee_detail" className="modal fade" role="dialog">
          <div className="modal-dialog">


            <div className="modal-content">
              <div className="modal-header edit_detail_hdr">
                <button type="button" id="close_modal" className="close close_modal_employee" data-dismiss="modal">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B" />
                  </svg>

                </button>
                <h4 className="modal-title">Employee Details

</h4>
              </div>



              <div className="modal-body">
                <div className="col-sm-12 table_attach_empl">
                  <div className="clear20"></div>
                  <div className="col-xs-12 p0 hdg_edit_detail_1 EmployeeDetailsTab cursorPointer">
                    <div className="col-xs-6 p0">
                      <h4>Details</h4>
                    </div>
                    <div className="col-xs-6 p0 text-right svg_icon_lsit_pop">
                      <svg className="EmployeeDetailsTabsvg" xmlns="http://www.w3.org/2000/svg" width="13" height="8.027" viewBox="0 0 13 8.027">
                        <path id="ic_expand_more_24px" d="M17.473,8.59,12.5,13.552,7.528,8.59,6,10.118l6.5,6.5,6.5-6.5Z" transform="translate(-6 -8.59)" fill="#86878A" />
                      </svg>
                    </div>
                  </div>
                  <div className="clear10"></div>
                  <div className="col-md-12 col-lg-10 p0 employe_edit_pop_input collapse in" id="employeeDetails">
                    <div className="col-sm-6 col-md-4 label_dataentry"> First Name:</div>
                    <div className="col-sm-6 col-md-8">
                      <input name="Employee_EmpDetailsForm_FirstName" id="Employee_EmpDetailsForm_FirstName " type="text" placeholder="Kevin"
                        value={this.state.Employee_EmpDetailsForm_FirstName}
                        onChange={this.EmployeeListInputsHandler}
                      />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_FirstName ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_FirstName}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry"> Last Name:</div>
                    <div className="col-sm-6 col-md-8">
                      <input name="Employee_EmpDetailsForm_LastName" type="text" placeholder="Plummer"
                        id="Employee_EmpDetailsForm_LastName" value={this.state.Employee_EmpDetailsForm_LastName}
                        onChange={this.EmployeeListInputsHandler}
                      />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_LastName ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_LastName}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry">Email:</div>
                    <div className="col-sm-6 col-md-8">  <input name="Employee_EmpDetailsForm_Email" type="text" placeholder="exemple@tphglobal.com"
                      id="Employee_EmpDetailsForm_Email" value={this.state.Employee_EmpDetailsForm_Email}
                      onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Email ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Email}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry">Mobile:
         </div>
                    <div className="col-sm-6 col-md-8">  <input name="Employee_EmpDetailsForm_Mobile" placeholder="123456789" type="text"
                      id="Employee_EmpDetailsForm_Mobile" value={this.state.Employee_EmpDetailsForm_Mobile}
                      onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Mobile ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Mobile}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry">Dept:
         </div>
                    <div className="col-sm-6 col-md-8">
                      <input name="Employee_EmpDetailsForm_Dept" placeholder="Accounting" type="text" id="Employee_EmpDetailsForm_Dept"
                        value={this.state.Employee_EmpDetailsForm_Dept}
                        onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Dept ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Dept}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry">Position:</div>
                    <div className="col-sm-6 col-md-8">  <input name="Employee_EmpDetailsForm_Position" placeholder="Accountant"
                      type="text" id="Employee_EmpDetailsForm_Position" value={this.state.Employee_EmpDetailsForm_Position}
                      onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Position ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Position}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry">Approver:</div>
                    <div className="col-sm-6 col-md-8">
                      <select name="Employee_EmpDetailsForm_Approver" id="Employee_EmpDetailsForm_Approver" value={this.state.Employee_EmpDetailsForm_Approver} onChange={this.EmployeeListInputsHandler}>
                        <option value="">Select</option>
                        {this.state.GroupallData.map(value => {
                          return (
                            <option value={value.Guid}>{value.GroupName}</option>
                          )
                        })}
                      </select>
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Approver ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Approver}</p>
                    </div>
                    <div className="clear40"></div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="col-xs-12 p0 hdg_edit_detail_1 cursorPointer employeeOtherDetails">
                    <div className="col-xs-6 p0">
                      <h4>Other Details</h4>
                    </div>
                    <div className="col-xs-6 p0 text-right svg_icon_lsit_pop">
                      <svg className="employeeOtherDetailssvg" xmlns="http://www.w3.org/2000/svg" width="13" height="8.027" viewBox="0 0 13 8.027">
                        <path id="ic_expand_more_24px" d="M17.473,8.59,12.5,13.552,7.528,8.59,6,10.118l6.5,6.5,6.5-6.5Z" transform="translate(-6 -8.59)" fill="#86878A" />
                      </svg>
                    </div>
                  </div>
                  <div className="clear10"></div>
                  <div className="col-md-12 col-lg-10 p0 employe_edit_pop_input" id="employeeOtherDetailsData">
                    <div className="col-sm-6 col-md-4 label_dataentry"> Hourly Rate:</div>
                    <div className="col-sm-6 col-md-8">  <input name="Employee_EmpDetailsForm_HourlyRate" type="text" placeholder="25.00000"
                      id="Employee_EmpDetailsForm_HourlyRate" value={this.state.Employee_EmpDetailsForm_HourlyRate}
                      onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_HourlyRate ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_HourlyRate}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry"> Template:</div>
                    <div className="col-sm-6 col-md-8">
                      <input name="Employee_EmpDetailsForm_Template" value={this.state.Employee_EmpDetailsForm_Template} id="Employee_EmpDetailsForm_Template"
                        onChange={this.EmployeeListSelectHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Template ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Template}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry">Global:</div>
                    <div className="col-sm-6 col-md-8">
                      <input name="Employee_EmpDetailsForm_Global" onChange={this.EmployeeListSelectHandler} value={this.state.Employee_EmpDetailsForm_Global} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Global ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Global}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry">Start Date:
         </div>
                    <div className="col-sm-6 col-md-8">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        name="Employee_EmpDetailsForm_StartDate"
                        id="Employee_EmpDetailsForm_StartDate" selected={new Date(this.state.Employee_EmpDetailsForm_StartDate)}
                        onChange={this.EmpDateChangeHandler.bind(this, 'Employee_EmpDetailsForm_StartDate')}
                      />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_StartDate ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_StartDate}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry">End Date:</div>
                    <div className="col-sm-6 col-md-8">
                      {console.log(this.state.Employee_EmpDetailsForm_EndDate,'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',this.state.Employee_EmpDetailsForm_StartDate)}
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        name="Employee_EmpDetailsForm_EndDate"
                        id="Employee_EmpDetailsForm_EndDate" selected={new Date(this.state.Employee_EmpDetailsForm_EndDate)}
                        onChange={this.EmpDateChangeHandler.bind(this, 'Employee_EmpDetailsForm_EndDate')}
                      />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_EndDate ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_EndDate}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-xs-6 col-md-4 label_dataentry">Terminated:</div>
                    <div className="col-xs-6 col-md-8">
                      <input name="IsEmployee_EmpDetails_TerimatedChkbox_Checked"
                        className="checkbox_termi chkboxx_mrg_topp" type="checkbox"
                        id="IsEmployee_EmpDetails_TerimatedChkbox_Checked"
                        checked={this.state.IsEmployee_EmpDetails_TerimatedChkbox_Checked}
                        onChange={this.EmployeeListInputsHandler} />
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-xs-6 col-md-4 label_dataentry">Agent:</div>
                    <div className="col-xs-6 col-md-8 label_dataentry_right2"> {this.state.Employee_EmpDetailsForm_Agent}</div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry">Emp basis:</div>
                    <div className="col-sm-6 col-md-8">
                      <select name="Employee_EmpDetailsForm_EmpBasis"
                        id="Employee_EmpDetailsForm_EmpBasis"
                        onChange={this.EmployeeListSelectHandler} value={this.state.Employee_EmpDetailsForm_EmpBasis}>
                        <option value="">select</option>
                        <option value="F">Full Time</option>
                        <option value="P">Part-Time</option>
                        <option value="C">Casual</option>
                        <option value="O">Other</option>
                      </select>
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_EmpBasis ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_EmpBasis}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry">State Tax:</div>
                    <div className="col-sm-6 col-md-8">
                      <select name="Employee_EmpDetailsForm_StateTax" id="Employee_EmpDetailsForm_StateTax" value={this.state.Employee_EmpDetailsForm_StateTax} onChange={this.EmployeeListSelectHandler}>
                        <option value="" >Select</option>
                        <option value="ACT" >ACT</option>
                        <option value="NSW" >NSW</option>
                        <option value="NT" >NT</option>
                        <option value="QLD" >QLD</option>
                        <option value="SA" >SA</option>
                        <option value="TAS" >TAS</option>
                        <option value="VIC" >VIC</option>
                        <option value="WA" >WA</option>
                        <option value="OTHER" >OTHER</option>
                      </select>
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_StateTax ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_StateTax}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry">Group:</div>
                    <div className="col-sm-6 col-md-8">  <input name="Employee_EmpDetailsForm_Group"
                      type="text" id="Employee_EmpDetailsForm_Group"
                      value={this.state.Employee_EmpDetailsForm_Group}
                      onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Group ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Group}</p>
                    </div>
                    <div className="clear40"></div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="col-xs-12 p0 hdg_edit_detail_1 cursorPointer EmployeesAddressSection">
                    <div className="col-xs-6 p0">
                      <h4>Address</h4>
                    </div>
                    <div className="col-xs-6 p0 text-right svg_icon_lsit_pop">
                      <svg className="EmployeesAddressSectionsvg" xmlns="http://www.w3.org/2000/svg" width="13" height="8.027" viewBox="0 0 13 8.027">
                        <path id="ic_expand_more_24px" d="M17.473,8.59,12.5,13.552,7.528,8.59,6,10.118l6.5,6.5,6.5-6.5Z" transform="translate(-6 -8.59)" fill="#86878A" />
                      </svg>
                    </div>
                  </div>
                  <div className="clear10"></div>
                  <div className="col-md-12 col-lg-10 p0 employe_edit_pop_input" id="EmployeesAddressData">
                    <div className="col-sm-6 col-md-4 label_dataentry"> Address 1:</div>
                    <div className="col-sm-6 col-md-8">  <input name="Employee_EmpDetailsForm_Address1" type="text" placeholder="" id="Employee_EmpDetailsForm_Address1" value={this.state.Employee_EmpDetailsForm_Address1}
                      onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Address1 ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Address1}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry"> Address 2:</div>
                    <div className="col-sm-6 col-md-8">
                      <input name="Employee_EmpDetailsForm_Address2" placeholder="" type="text" id="Employee_EmpDetailsForm_Address2" value={this.state.Employee_EmpDetailsForm_Address2}
                        onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Address2 ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Address2}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry">City:</div>
                    <div className="col-sm-6 col-md-8">
                      <input name="Employee_EmpDetailsForm_City" placeholder="" type="text" id="Employee_EmpDetailsForm_City" value={this.state.Employee_EmpDetailsForm_City}
                        onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_City ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_City}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry">State:
         </div>
                    <div className="col-sm-6 col-md-8">  <input name="Employee_EmpDetailsForm_State" placeholder="" type="text" id="Employee_EmpDetailsForm_State" value={this.state.Employee_EmpDetailsForm_State}
                      onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_State ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_State}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry">Post Code:</div>
                    <div className="col-sm-6 col-md-8">  <input name="Employee_EmpDetailsForm_PostalCode" placeholder="" type="text"
                      id="Employee_EmpDetailsForm_PostalCode" value={this.state.Employee_EmpDetailsForm_PostalCode}
                      onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_PostalCode ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_PostalCode}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="clear40"></div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="col-xs-12 p0 hdg_edit_detail_1 EmployeepostalAddressHeader cursorPointer">
                    <div className="col-xs-6 p0">
                      <h4>Postal Address<small>(If does not match above)</small></h4>
                    </div>
                    <div className="col-xs-6 p0 text-right svg_icon_lsit_pop">
                      <svg className="EmployeepostalAddressHeadersvg" xmlns="http://www.w3.org/2000/svg" width="13" height="8.027" viewBox="0 0 13 8.027">
                        <path id="ic_expand_more_24px" d="M17.473,8.59,12.5,13.552,7.528,8.59,6,10.118l6.5,6.5,6.5-6.5Z" transform="translate(-6 -8.59)" fill="#86878A" />
                      </svg>
                    </div>
                  </div>
                  <div className="clear10"></div>
                  <div className="col-md-12 col-lg-10 p0 employe_edit_pop_input" id="EmployeepostalAddressData">
                    <div className="col-sm-6 col-md-4 label_dataentry"> Address 1:</div>
                    <div className="col-sm-6 col-md-8">  <input name="Employee_EmpDetailsForm_Postal_Address1" type="text" placeholder=""
                      id="Employee_EmpDetailsForm_Postal_Address1" value={this.state.Employee_EmpDetailsForm_Postal_Address1}
                      onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Postal_Address1 ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Postal_Address1}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry"> Address 2:</div>
                    <div className="col-sm-6 col-md-8">
                      <input name="Employee_EmpDetailsForm_Postal_Address2" placeholder="" type="text"
                        id="Employee_EmpDetailsForm_Postal_Address2" value={this.state.Employee_EmpDetailsForm_Postal_Address2}
                        onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Postal_Address2 ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Postal_Address2}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry">City:</div>
                    <div className="col-sm-6 col-md-8">
                      <input name="Employee_EmpDetailsForm_Postal_City" placeholder="" type="text" id="Employee_EmpDetailsForm_Postal_City"
                        value={this.state.Employee_EmpDetailsForm_Postal_City}
                        onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Postal_City ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Postal_City}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry">State:
         </div>
                    <div className="col-sm-6 col-md-8">  <input name="Employee_EmpDetailsForm_Postal_State" placeholder="" type="text"
                      id="Employee_EmpDetailsForm_Postal_State" value={this.state.Employee_EmpDetailsForm_Postal_State}
                      onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Postal_State ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Postal_State}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry">Post Code:</div>
                    <div className="col-sm-6 col-md-8">  <input name="Employee_EmpDetailsForm_Postal_PostalCode"
                      placeholder="" type="text" id="Employee_EmpDetailsForm_Postal_PostalCode" value={this.state.Employee_EmpDetailsForm_Postal_PostalCode}
                      onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Postal_PostalCode ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Postal_PostalCode}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="clear40"></div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="col-xs-12 p0 hdg_edit_detail_1 EmployeeFlagsHeader cursorPointer">
                    <div className="col-xs-6 p0">
                      <h4>Flags</h4>
                    </div>
                    <div className="col-xs-6 p0 text-right svg_icon_lsit_pop">
                      <svg xmlns="http://www.w3.org/2000/svg" className="EmployeeFlagsHeaderSvg" width="13" height="8.027" viewBox="0 0 13 8.027">
                        <path id="ic_expand_more_24px" d="M17.473,8.59,12.5,13.552,7.528,8.59,6,10.118l6.5,6.5,6.5-6.5Z" transform="translate(-6 -8.59)" fill="#86878A" />
                      </svg>
                    </div>
                  </div>
                  <div className="clear10"></div>
                  <div className="col-md-12 col-lg-10 p0 employe_edit_pop_input" id="EmployeeFlagsData">
                    <div className="col-sm-6 col-md-4 label_dataentry"> Mon:</div>
                    <div className="col-sm-6 col-md-8">  <input name="Employee_EmpDetailsForm_Flags_Mon"
                      type="text" placeholder="" id="Employee_EmpDetailsForm_Flags_Mon" value={this.state.Employee_EmpDetailsForm_Flags_Mon}
                      onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Flags_Mon ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Flags_Mon}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry"> Tue:</div>
                    <div className="col-sm-6 col-md-8">
                      <input name="Employee_EmpDetailsForm_Flags_Tue" placeholder="" type="text" id="Employee_EmpDetailsForm_Flags_Tue" value={this.state.Employee_EmpDetailsForm_Flags_Tue}
                        onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Flags_Tue ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Flags_Tue}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry">Wed:</div>
                    <div className="col-sm-6 col-md-8">
                      <input name="Employee_EmpDetailsForm_Flags_Wed" placeholder="" type="text" id="Employee_EmpDetailsForm_Flags_Wed" value={this.state.Employee_EmpDetailsForm_Flags_Wed}
                        onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Flags_Wed ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Flags_Wed}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry">Thur:
         </div>
                    <div className="col-sm-6 col-md-8">  <input name="Employee_EmpDetailsForm_Flags_Thur"
                      placeholder="" type="text" id="" value={this.state.Employee_EmpDetailsForm_Flags_Thur}
                      onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Flags_Thur ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Flags_Thur}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry">Fri:</div>
                    <div className="col-sm-6 col-md-8">  <input name="Employee_EmpDetailsForm_Flags_Fri"
                      placeholder="" type="text" id="Employee_EmpDetailsForm_Flags_Fri" value={this.state.Employee_EmpDetailsForm_Flags_Fri}
                      onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Flags_Fri ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Flags_Fri}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry">Sat:</div>
                    <div className="col-sm-6 col-md-8">  <input name="Employee_EmpDetailsForm_Flags_Sat" placeholder="" type="text"
                      id="Employee_EmpDetailsForm_Flags_Sat" value={this.state.Employee_EmpDetailsForm_Flags_Sat}
                      onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Flags_Sat ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Flags_Sat}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry">Sun:</div>
                    <div className="col-sm-6 col-md-8">  <input name="Employee_EmpDetailsForm_Flags_Sun" placeholder="" type="text" id="Employee_EmpDetailsForm_Flags_Sun"
                      value={this.state.Employee_EmpDetailsForm_Flags_Sun}
                      onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Flags_Sun ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Flags_Sun}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="clear40"></div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="col-xs-12 p0 hdg_edit_detail_1 EmployeeAliasHeader cursorPointer">
                    <div className="col-xs-6 p0">
                      <h4>Alias</h4>
                    </div>
                    <div className="col-xs-6 p0 text-right svg_icon_lsit_pop">
                      <svg className="EmployeeAliasHeaderSvg" xmlns="http://www.w3.org/2000/svg" width="13" height="8.027" viewBox="0 0 13 8.027">
                        <path id="ic_expand_more_24px" d="M17.473,8.59,12.5,13.552,7.528,8.59,6,10.118l6.5,6.5,6.5-6.5Z" transform="translate(-6 -8.59)" fill="#86878A" />
                      </svg>
                    </div>
                  </div>
                  <div className="clear10"></div>
                  <div className="col-md-12 col-lg-10 p0 employe_edit_pop_input" id="EmployeeAliasData">
                    <div className="col-sm-6 col-md-4 label_dataentry"> First Name:</div>
                    <div className="col-sm-6 col-md-8">  <input name="Employee_EmpDetailsForm_Alias_FirstName" type="text" placeholder=""
                      id="Employee_EmpDetailsForm_Alias_FirstName" value={this.state.Employee_EmpDetailsForm_Alias_FirstName}
                      onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Alias_FirstName ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Alias_FirstName}</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6 col-md-4 label_dataentry"> Last Name:</div>
                    <div className="col-sm-6 col-md-8">
                      <input name="Employee_EmpDetailsForm_Alias_LastName" placeholder="" type="text" id="Employee_EmpDetailsForm_Alias_LastName"
                        value={this.state.Employee_EmpDetailsForm_Alias_LastName}
                        onChange={this.EmployeeListInputsHandler} />
                      <p className={"error validation " + (this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Alias_LastName ? '' : 'displayNone')}>{this.state.Emp_EmpDetailsFormError.Employee_EmpDetailsForm_Alias_LastName}</p>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="col-sm-12">
                    <div className="analytics_btn_save">
                      < a href={null} className="btn_save_analytics cursorPointer" onClick={this.EmployeeDetailsFormSaveHandler}>Save</a>
                      < a href={null} className="btn_cancel_analytics cursorPointer" data-dismiss="modal">Cancel</a>
                    </div>
                    <div className="col-md-3"> </div>
                  </div>
                  <div className="clear10"></div>
                </div>
                <div className="clear10"></div>
              </div>





            </div>

          </div>
        </div>



      </div>
    );
  }
}
export default Employee_List;
