import React from "react";
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { browserHistory } from 'react-router';
import Login from "../Containers/Login/Login";
import Verifaction_Code from "../Containers/Verifaction_Code/Verifaction_Code";
import Verifaction_Code2 from "../Containers/Verifaction_Code2/Verifaction_Code2";
import Dashboard from "../Containers/Dashboard/Dashboard";
import { AppliedRoute } from '../Components/AppliedRoute/AppliedRoute';
// import {AppliedRoute} from './';
import Payroll_Batches from "../Containers/Payroll_Batches/Payroll_Batches";
import PayrollBatches_Edit from "../Containers/PayrollBatches_Edit/PayrollBatches_Edit";
// import Add_Times from "../Containers/Add_Times/Add_Times";

import Employee_List from "../Containers/Employee_List/Employee_List";
import Ad_Times from "../Containers/Ad_Times/Ad_Times";
import Add_Times_2 from "../Containers/Add_Times_2/Add_Times_2";
import User from "../Containers/User/User";
import ApprovalSetup from "../Containers/ApprovalSetup/ApprovalSetup";
import EmailTemplate from "./../Containers/EmailTemplate/EmailTemplate";
import Email_Templates_Add from "./../Containers/Email_Templates_Add/Email_Templates_Add";
import Setup from "./../Containers/Setup/Setup";
import Message from "./../Containers/Message/Message";
import SendMessage from "./../Containers/SendMessage/SendMessage";
import Analytics from "./../Containers/Analytics/Analytics";
import Forgot from "./../Containers/Forgot/Forgot";
import Forgot_2 from "./../Containers/Forgot_2/Forgot_2";
import Forgot_3 from "./../Containers/Forgot_3/Forgot_3";
import Forgot_4 from "./../Containers/Forgot_4/Forgot_4";
import Settings from "./../Containers/Settings/Settings";
import TimeCards from "./../Containers/TimeCards/TimeCards";
import Aviva_Full_Page from "./../Containers/Aviva_Full_Page/Aviva_Full_Page";
import TimeCardReport from "../Containers/Reports/TimeCardReport";


import PayrollBatchReport from '../Containers/Reports/PayrollBatchReport';
// mobile views

import Exception_Mob from "../MobContainers/Exception_Mob/Exception_Mob";
import Decline_Mob from "../MobContainers/Decline_Mob/Decline_Mob";
import DeclineTimeCard from "../MobContainers/DeclineTimeCard_Mob/DeclineTimeCard_Mob";
import KevinPlummer_Mob from "../MobContainers/KevinPlummer_Mob/KevinPlummer_Mob";
import PayEndingTable_Mob from "../MobContainers/PayEndingTable_Mob/PayEndingTable_Mob";
import PayEndingFinal_Mob from "../MobContainers/PayEndingFinal_Mob/PayEndingFinal_Mob";
import TimeCardList_Mob from "../MobContainers/TimeCardList_Mob/TimeCardList_Mob";
import TimeCardList2_Mob from "../MobContainers/TimeCardList2_Mob/TimeCardList2_Mob";
import AttachTimeCard from "../MobContainers/AttachTimeCard/AttachTimeCard";
import AttachTimeCardAdd_Mob from "../MobContainers/AttachTimeCardAdd_Mob/AttachTimeCardAdd_Mob";
import Attachment_Mobile from "../MobContainers/Attachment_Mobile/Attachment_Mobile";
import Attach_Document from "../MobContainers/Attach_Document/Attach_Document";
import Comments from "../MobContainers/Comments/Comments";
import Aviva from "../MobContainers/Aviva/Aviva";
import TimeCardListNoSave from "../MobContainers/TimeCardListNoSave/TimeCardListNoSave";
import EmployeeReport from "../Containers/Reports/EmployeeReport";
import AdlistReport from "../Containers/Reports/AdlistReport";
import Two_Factor_Verify from "../Containers/Two_Factor_Verify/Two_Factor_Verify";

export default (childProps) => (
  <Router>
    <Switch>
      <AppliedRoute path="/" exact component={Login} props={childProps} />
      <AppliedRoute path="/Login" exact component={Login} props={childProps} />
      <AppliedRoute path="/verification_code" exact component={Verifaction_Code} props={childProps} />
      <AppliedRoute path="/company-select" exact component={Verifaction_Code2} props={childProps} />
      <AppliedRoute path="/dashboard" exact component={Dashboard} props={childProps} />
      <AppliedRoute path="/payroll_batches" exact component={Payroll_Batches} props={childProps} />
      <AppliedRoute
        path="/payroll_batches_edit"
        exact
        component={PayrollBatches_Edit}
        props={childProps} />
      <AppliedRoute path="/employee_list" exact component={Employee_List} props={childProps} />
      <AppliedRoute path="/ad_times" exact component={Ad_Times} props={childProps} />
      <AppliedRoute path="/ad_times_2" exact component={Add_Times_2} props={childProps} />
      <AppliedRoute path="/users" exact component={User} props={childProps} />
      <AppliedRoute path="/approval_setup" exact component={ApprovalSetup} props={childProps} />
      <AppliedRoute path="/email_template" exact component={EmailTemplate} props={childProps} />
      <AppliedRoute
        path="/email_templates_add"
        exact
        component={Email_Templates_Add}
        props={childProps} />
      <AppliedRoute path="/setup" exact component={Setup} props={childProps} />
      <AppliedRoute path="/messages" exact component={Message} props={childProps} />
      <AppliedRoute path="/send_message" exact component={SendMessage} props={childProps} />
      <AppliedRoute path="/analytics" exact component={Analytics} props={childProps} />
      <AppliedRoute path="/forgot" exact component={Forgot} props={childProps} />
      <AppliedRoute path="/forgot_2" exact component={Forgot_2} props={childProps} />
      <AppliedRoute path="/forgot_3" exact component={Forgot_3} props={childProps} />
      <AppliedRoute path="/forgot_4" exact component={Forgot_4} props={childProps} />
      <AppliedRoute path="/settings" exact component={Settings} props={childProps} />
      <AppliedRoute path="/timecards" exact component={TimeCards} props={childProps} />
      <AppliedRoute path="/aviva_full_page" exact component={Aviva_Full_Page} props={childProps} />
      {/* mobile AppliedRoutes extras */}

      <AppliedRoute path="/exceptions_mobile" exact component={Exception_Mob} props={childProps} />
      <AppliedRoute path="/decline" exact component={Decline_Mob} props={childProps} />
      <AppliedRoute path="/decline_timecard" exact component={DeclineTimeCard} props={childProps} />
      <AppliedRoute path="/kevin_plummer" exact component={KevinPlummer_Mob} props={childProps} />
      <AppliedRoute path="/pay_ending_table" exact component={PayEndingTable_Mob} props={childProps} />
      <AppliedRoute path="/payending_final" exact component={PayEndingFinal_Mob} props={childProps} />
      <AppliedRoute path="/timecard_list" exact component={TimeCardList_Mob} props={childProps} />
      <AppliedRoute path="/timecard_list_2" exact component={TimeCardList2_Mob} props={childProps} />
      <AppliedRoute path="/attach_timecard" exact component={AttachTimeCard} props={childProps} />
      <AppliedRoute
        path="/attach_timecard_add"
        exact
        component={AttachTimeCardAdd_Mob}
        props={childProps} />
      <AppliedRoute path="/attachment_mobile" exact component={Attachment_Mobile} props={childProps} />
      <AppliedRoute path="/attach_document" exact component={Attach_Document} props={childProps} />
      <AppliedRoute path="/comments" exact component={Comments} props={childProps} />
      <AppliedRoute path="/aviva" exact component={Aviva} props={childProps} />
      <AppliedRoute path="/EmployeeReport" exact component={EmployeeReport} props={childProps} />
      <AppliedRoute path="/AdlistReport" exact component={AdlistReport} props={childProps} />
      <AppliedRoute
        path="/timecard_list_no_save"
        exact
        component={TimeCardListNoSave}
        props={childProps} />

      <AppliedRoute path="/PayrollBatchReport" exact component={PayrollBatchReport} props={childProps} />

      <AppliedRoute path="/TimeCardReport" exact component={TimeCardReport} props={childProps} />
      <AppliedRoute path="/Two_Factor_Verify" exact component={Two_Factor_Verify} props={childProps} />


    </Switch>
  </Router>
);
