import React, { Component } from "react";
import "./Header1.css";
import { withRouter } from "react-router-dom";
import ic_dehaze_ from "./../../../Assets/images/ic_dehaze_-19@2x.png";
import login_logo from "./../../../Assets/images/login_logo.svg";
import forgot_logo from "./../../../Assets/images/forgot_logo.svg";
import logout from "./../../../Assets/images/logout.svg";
import notification from "./../../../Assets/images/notifactio.svg";
import { Link } from "react-router-dom";
import $ from "jquery";
import Signature from 'react-another-signature-pad';

import { API, Auth ,Storage} from "aws-amplify";
import { toast } from 'react-toastify';
var ip = require('ip');

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingHeader: false,
      unreadMsgCount: 0,
      signclear:false,
			isLoading:false,
			signature: '',
			Signa: "",
			loggedInUserEmail:localStorage.getItem('EmailAddress'),
			SignatureText: "",
			alreadySignature: '',
      companies: []
    }
  }

  async componentDidMount() {
    let that = this;
    await API.post("getcompaniesbycoyid", "/getcompaniesbycoyid", {
      body: {
        "cID": localStorage.getItem('companyId')
      }
    }).then(result => {

      this.setState({
        companies: result
      })

    }).catch(err => { console.log(err); });
    setInterval(function () {
      if (that.state.unreadMsgCount !== 0) {
        if ($(".notifactio").hasClass('shake')) {
          $(".notifactio").removeClass('shake');
        }
        else {
          $(".notifactio").addClass('shake');
        }
      }
    }, 3000);
    $("#menu-toggle").click(function (e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
      $(".mrg_dashboard_right").css('width', '92%');

    });
    await this.myunreadmsgcount(localStorage.getItem('userGuid'));


    //run on document load and on window resize
    $(document).ready(function () {


      $("#mobile_menu_sm").css("display", "block");
      //on load
      hideDiv();

      //on resize
      $(window).resize(function () {
        hideDiv();
      });

    });
    $("#menu-toggle").click(function (e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
      $(".mrg_dashboard_right").css('width', '92%');

    });




    var toggle = true;
    $("#menu-toggle").click(function () {
      if (toggle) {
        // $(".mrg_dashboard_right").css('width','92%');
        // $('#Group_123').find('image').first().hide(300);
        // $('#wrapper span').hide(300);
        // // $(".sidebar-nav_all li a span").css('display', 'none');

      }

      else {
        //console.log('ssssssssssss');
        $('#Group_123').find('image').first().show(300);
        $(".mrg_dashboard_right").css('width', '83.33%');
        $(".sidebar-nav_all li a span").css('display', 'contents');


        $('#wrapper span').show(300);
      }
      toggle = !toggle;
    });


    $(document).unbind('click').bind('click', function (e) {

      if (e.target.id === 'toggle_image') {
        $("#mobile_menu_sm").slideToggle();

      }
      else {
        var sidebar = $("#mobile_menu_sm");
        if (!sidebar.is(e.target) && sidebar.has(e.target).length === 0) {


          hideDiv();

        }
      }







    });




    //the function to hide the div
    function hideDiv() {

      if (window.matchMedia('(max-width: 767px)').matches) {
        //alert($(window).width());

        $(".display_none_full_menu").hide();
        $(".dashboard_new").css('width', '100%');


      }
      else {
        $("#mobile_menu_sm").hide();
        $(".mrg_dashboard_right").css('width', '83.33%');
        $(".display_none_full_menu").show();
      }

    }

    //code for checking
    $(document).ready(function () {
      $('.form input').change(function () {
        $('.form p').text(this.files.length + " file(s) selected");
      });
    });

    $(document).ready(
      function () {
        $("#music").click(function () {
          $("#musicinfo").toggle();
        });
      });
      $("#tttt canvas").attr("width", "410");
      $("#tttt canvas").attr("height", "107");
  }
  onHref = e => {
    e.preventDefault();
  };
  myunreadmsgcount = async (userGuid) => {
    await API.post('myunreadmsgcount', '/myunreadmsgcount', {
      body: {
        "myguid": userGuid
      }
    }).then(data => {
      this.setState({
        unreadMsgCount: data
      });
    }).catch(err => {
      toast.error('Unable To Fetch Unread MessageCounts');
    });
  }
  logout = async () => {
    this.setState({ isLoadingHeader: true });
    if(localStorage.getItem('userGuid')!==undefined&&localStorage.getItem('userGuid')!==""&&localStorage.getItem('EmailAddress')&&localStorage.getItem('EmailAddress')!==undefined&&localStorage.getItem('EmailAddress')!==""){
    await API.post("recordlastlogin", "/recordlastlogin", {
      body:
      {
        "Guid": localStorage.getItem('userGuid')
      }
    }).then(data => {
     // toast.success('Last Logout Recorded Successfully.');
    }
    ).catch(err => { toast.error(err.message); });
    await API.post("recordactivity", "/recordactivity", {
      body:
      {
        "Description": "logout",
        "UserName": localStorage.getItem('UserLogin'),
        "IPAddress": ip.address(),
        "TenantGuid": "UYGVUYGBIUYGHBIUYHGB",
        "Type": "Logout",
        "UserGuid": localStorage.getItem('userGuid')
      }
    }).then(data => { 
     // toast.success('Logout Activity Recorded Successfully.');
     }).catch(err => { toast.error(err.message); });

    await API.post("updatelastlogout", "/updatelastlogout", {
      body:
      {
        "Guid": localStorage.getItem('userGuid')
      }
    }).then(data => {
     // toast.success('User last logout updated successfully.');
    }
    ).catch(err => { toast.error(err.message); });
    await API.post("updatelastlogoutforuser", "/updatelastlogoutforuser", {
      body:
      {
        "userEmail": localStorage.getItem('EmailAddress'),
        "LastLogout": Date.now()
      }
    }).then(data => {
      //toast.success('last logout updated successfully for user.');
    }
    ).catch(err => { toast.error(err.message); });
  }
    localStorage.removeItem('EmailAddress');
    localStorage.removeItem('userGuid');
    localStorage.removeItem('UserLogin');
    localStorage.removeItem('UserFullName');
    await Auth.signOut();
    this.props.history.push('/login');
  }

  msgNavMob = () => {

    const Uri = window.location.pathname;
    if (Uri === '/messages') {
      return (
        <React.Fragment>

          <a
            onClick={() => this.props.history.goBack()}
            className="arrow_back_headerall arrow_paryroll_back arrow_mobile_headerrr"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="21.053"
              viewBox="0 0 13 21.053"
            >
              <g
                id="Group_1096"
                data-name="Group 1096"
                transform="translate(1480 539.053) rotate(180)"
              >
                <path
                  id="ic_chevron_right_24px"
                  d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                  transform="translate(1458.41 512)"
                />
              </g>
            </svg>
          </a>
          <div className=" text-center  payroll_sm_text_none timecard_search_img_width">
            Digital Times
            </div>

          <div className=" timecard_mbl_menu timecard_search_icon ">
            <a hreaf="javascript:void(0);" data-toggle="modal" data-target="#timecard_sidee_modal"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <path id="ic_search_24px" d="M17.294,15.579h-.9l-.32-.309a7.444,7.444,0,1,0-.8.8l.309.32v.9L21.3,23,23,21.3Zm-6.861,0a5.146,5.146,0,1,1,5.146-5.146A5.139,5.139,0,0,1,10.433,15.579Z" transform="translate(-3 -3)" fill="#fff" />
            </svg>
            </a>

          </div>
        </React.Fragment>
      )


    }
    if (Uri === '/analytics') {
      return (
        <React.Fragment>


          <Link
            to="/timecards"
            className="arrow_back_headerall arrow_paryroll_back arrow_mobile_headerrr"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="21.053"
              viewBox="0 0 13 21.053"
            >
              <g
                id="Group_1096"
                data-name="Group 1096"
                transform="translate(1480 539.053) rotate(180)"
              >
                <path
                  id="ic_chevron_right_24px"
                  d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                  transform="translate(1458.41 512)"
                />
              </g>
            </svg>
          </Link>
          <div className=" text-center  payroll_sm_text_none timecard_search_img_width">
            Digital Times
              </div>
        </React.Fragment>
      )


    }
    if (Uri === '/settings') {
      return (
        <React.Fragment>


          <a
            onClick={() => this.props.history.goBack()}
            className="arrow_back_headerall arrow_paryroll_back arrow_mobile_headerrr"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="21.053"
              viewBox="0 0 13 21.053"
            >
              <g
                id="Group_1096"
                data-name="Group 1096"
                transform="translate(1480 539.053) rotate(180)"
              >
                <path
                  id="ic_chevron_right_24px"
                  d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                  transform="translate(1458.41 512)"
                />
              </g>
            </svg>
          </a>
          <div className=" text-center  payroll_sm_text_none timecard_search_img_width">
            Digital Times
              </div>
        </React.Fragment>
      )


    }


    else {
      return (
        <div className=" text-center analytics_digital_text payroll_sm_text_none">
          Digital Times
            </div>
      )
    }


  }
  componentWillMount = async () => {
    await API.post("getcompaniesbycoyid", "/getcompaniesbycoyid", {
      body: {
        "cID": localStorage.getItem('companyId')
      }
    }).then(result => {

      this.setState({
        companies: result
      })

    }).catch(err => { console.log(err); });
  }
  checkCompanyWithUser = async (event) => {
    var userTenant = localStorage.getItem('tenantid');
    if (userTenant.indexOf(event.target.id) > -1) {
      $("#companiesModelclose").click();
      this.setState({ isLoading: true });
      await localStorage.setItem('useableGuid', event.target.id);
      await this.forceUpdate();
      //toast.success("Production switched Successfully.");

    } else {
      toast.error("You don't belong with selected company");
    }
    if (window.location.pathname !== '/dashboard') {
      this.props.history.push('/dashboard');
    }
    else {
      await API.post('gettenantbytenantid', '/gettenantbytenantid', {
        body: {
          id: localStorage.getItem('useableGuid')
        }
      }).then(data => {
        localStorage.setItem('completetenant', JSON.stringify(data[0]));
      }).catch(err => {
        toast.error('Unable To Fetch Users Activities');
      });
    }
  }
  openProductionModal = async () => {
    document.getElementById("companiesModelbtn").click();
    $("#mobile_menu_sm").css("display", "none");
    // $("#menu-toggle_mobile").css("display", "none");
    $("#menu-toggle_mobile_close").css("display", "none");
  }
  	// signaturepopupworking
	issue_ScrollHandler = (e) => {
		$('body').css("overflow", "hidden");
		$('#user_form').css('overflow-y', 'auto');
	 }

	getSignatures = async (data) => {
		const base64 = data;
  
		const base64Data = new Buffer(base64.replace(/^data:image\/\w+;base64,/, ""), 'base64');
		await this.setState({
		   signature: base64Data
		});
  
  
  
	 }
	 save_signature = async () => {
  
  
		if (this.state.SignatureText !== "") {
  
		   let Signaturetype = this.state.SignatureText;
  
  
  
		   var c = document.getElementById("myCanvas");
		   var ctx = c.getContext("2d");
		   const text = document.getElementById('SignatureText').value;
		   var width = ctx.measureText(text).width * 3;
		   ctx.canvas.width = width;
		   //ctx.canvas.width = ctx.measureText(text).width;
		   ctx.fillStyle = "#FFFFFF";
		   ctx.fillRect(0, 0, width, 100);
  
		   var imageElem = document.getElementById("image");
		   var c = document.getElementById("myCanvas");
		   var ctx = c.getContext("2d");
  
		   ctx.font = "25px arial";
  
		   // Create gradient
		   var gradient = ctx.createLinearGradient(0, 0, c.width, 0);
		   gradient.addColorStop("0", " black");
		   gradient.addColorStop("0.5", "black");
		   gradient.addColorStop("1.0", "black");
		   // Fill with gradient
		   ctx.fillStyle = gradient;
  
		   ctx.fillText(text, 0, 20);
		   ctx.textBaseline = "bottom";
  
		   const base64 = ctx.canvas.toDataURL("image/jpeg");
		   const base64Data = new Buffer(base64.replace(/^data:image\/\w+;base64,/, ""), 'base64');
		   await this.setState({ Signa: base64Data })
		   // let filenameTyped = localStorage.getItem('useableGuid')+"/"+"/SignatureFileTyped.jpg";
  
		   this.setState({
			  isLoading: true
		   });
		   this.issue_ScrollHandler();
  
  
		   var filename = this.state.loggedInUserEmail + '_signature.jpg';
		   await Storage.put(filename, this.state.Signa, { contentType: 'image/jpeg' })
			  .then(result => {
				 console.log(result);
				 this.CheckImage();
			  }).catch(error => {
				 console.log(error);
			  });
		   document.getElementById("close_signature").click();
		   this.setState({
			  isLoading: false
		   });
  
		}
		else {
		   this.setState({
			  isLoading: true
		   });
		   this.issue_ScrollHandler();
  
  
		   var filename = this.state.loggedInUserEmail + '_signature.jpg';
		   await Storage.put(filename, this.state.signature,{ contentType: 'image/jpeg' })
			  .then(result => {
				 console.log(result);
				 this.CheckImage();
			  }).catch(error => {
				 console.log(error);
			  });
		   document.getElementById("close_signature").click();
		   this.setState({
			  isLoading: false
		   });
	   
		}
  
  
  
	 }
	 CheckImage = async () => {
		await this.setState({
		   isLoading: true
		});
		var filename = this.state.loggedInUserEmail + '_signature.jpg';
		if ( this.state.loggedInUserEmail !== '') {
  
		   await Storage.get(filename).then(result => {
			  this.setState({
				 alreadySignature: result
			  });
		   }).catch(error => {
			  console.log(error);
			  this.setState({
				 alreadySignature: ''
			  });
		   });
  
		} else {
		   this.setState({
			  alreadySignature: ''
		   });
		}
		await this.setState({
		   isLoading: false
		});
	 }
	 clear_sign = async () => {
		await this.setState({
  
		   signclear: Date.now(),
		   SignatureText: ""
		})
	 }
	 signaturetext = async (e) => {
		await this.setState({ [e.target.name]: e.target.value })
  
	 }
	 // signaturepopupworking end
  render() {
    const style = { width: 410, height: 107 };
    return (
     <> <div>
        {(this.state.isLoadingHeader || this.props.isLoading) ? <div className="se-pre-con"></div> : ''}
        <button data-toggle="modal" data-target="#companiesModel" id="companiesModelbtn" className="displayNone"></button>
        <div id="companiesModel" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" id="companiesModelclose" data-dismiss="modal">&times;</button>
                <h3 className="modal-title">Productions</h3>
              </div>
              <div className="modal-body">
                <div className="verif_table_box">

                  <table width="99%" className="table zeroMarginBottom">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Production</th>
                        <th align="center" scope="col">Approve</th>
                        <th align="center" scope="col">Pending</th>
                        <th align="center" scope="col">Approved</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.companies.map((value, key) => {
                        return (<tr key={key} onClick={this.checkCompanyWithUser} className="cursorPointer companyList">
                          <td id={value.TenantGuid}>{value.CompanyName}</td>
                          <td align="center" id={value.TenantGuid}>0</td>
                          <td align="center" id={value.TenantGuid}>0</td>
                          <td align="center" id={value.TenantGuid}>5</td>
                        </tr>);
                      })}


                    </tbody>
                  </table>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 header_shadow_all">
          <Link
            to=""
            onClick={this.onHref}
            className="full_menu_toggle"
            id="menu-toggle_mobile"
            style={{ float: "left", lineHeight: "normal" }}
          >
            <img
              src={ic_dehaze_}
              style={{ width: "25px !important", height: "18px" }}
              alt=""
              id="toggle_image"
            />
          </Link>
          <div className="logo_header_all">
            <Link
              to="/dashboard"
              className="pull-left logo_header_all logo_headerall_none1"
            >
              <img src={login_logo} alt="" />{" "}
            </Link>

            <Link to="/dashboard" className="pull-left logo_headerall_none2">
              <img src={forgot_logo} alt="" />{" "}
            </Link>
          </div>

          <div className="header_all_center_label">
            <Link to={(window.location.pathname === '/email_templates_add' || window.location.pathname === '/ad_times_2' || window.location.pathname === '/analytics' || window.location.pathname === '/payroll_batches_edit') ? this.props.link : '/dashboard'} className="arrow_back_headerall">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="21.053"
                viewBox="0 0 13 21.053"
              >
                <g
                  id="Group_1096"
                  data-name="Group 1096"
                  transform="translate(1480 539.053) rotate(180)"
                >
                  <path
                    id="ic_chevron_right_24px"
                    d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                    transform="translate(1458.41 512)"
                  />
                </g>
              </svg>
            </Link>
            {(window.location.pathname === '/payroll_batches_edit') ? <div className="header_paches_label3 lb3_eee">{this.props.batchEditHeader}</div> : this.props.pageHeader}

          </div>

          <div className="exit_top_right pull-right">
            <a hreaf="javascript:void(0);" onClick={this.logout}>
              <div className="right_logout_label">Logout</div>
              <img src={logout} alt="" />
            </a>
          </div>


          {/* conditional rendering for  message icons */}


          {(window.location.pathname === '/messages') || (window.location.pathname === '/send_message') ? '' :
            this.props.childProps.setting_SNCheck &&
            <div className="notifactio">
              <Link to="/messages">
                <img src={notification} alt="" />
              </Link>
              {this.state.unreadMsgCount > 0 ? <span className="notif_label">{this.state.unreadMsgCount}</span> : ''}
            </div>
          }



          <div className="clearfix" />
        </div>

        <div className="header_payroll_block">
          <div className="col-sm-12 col-xs-12 p0 ">




            {/* small screen nav link */}
            {this.msgNavMob()}




          </div>
          <div className="clearfix"> </div>
        </div>

        <div className="col-sm-12 p0 text-center payroll_mbl_heading">
          {this.props.pageHeader}
        </div>
        <div className="clearfix" />

        <div className="col-sm-12 p0 top_mrg_form_res">
          <div className="">
            <div id="mobile_menu_sm" style={{ display: "none" }}>
              {/* <!-- Sidebar --> */}
              <div id="mobile_menu_all" className="sidebar_ipad">
                <ul className="sidebar-nav_all" style={{ marginLeft: "0" }}>
                  <li>
                    <Link to="/dashboard">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-5378 759 13.333 11.333"
                      >
                        <path
                          id="ic_home_24px"
                          className="cls-1"
                          d="M7.333,14.333v-4H10v4h3.333V9h2L8.667,3,2,9H4v5.333Z"
                          transform="translate(-5380 756)"
                        />
                      </svg>
                      <span> Dashboard </span>
                    </Link>
                  </li>
                  {
                    this.props.childProps.showPayrollBatch &&
                    <li>
                      <Link to="/payroll_batches">
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 1000 1000"
                          enableBackground=""
                          xmlSpace="preserve"
                        >
                          <g>
                            <path d="M755.1,658c0,55.8-18.1,103.8-54.4,144.1c-36.3,40.3-83.4,65.2-141.4,74.7v95.7c0,5.1-1.6,9.3-4.9,12.6c-3.3,3.3-7.5,4.9-12.6,4.9H468c-4.7,0-8.8-1.7-12.3-5.2c-3.5-3.5-5.2-7.6-5.2-12.3v-95.7c-24.1-3.3-47.3-8.9-69.7-16.9c-22.4-8-40.9-16.1-55.5-24.3c-14.6-8.2-28.1-17-40.5-26.3c-12.4-9.3-20.9-16.1-25.4-20.5c-4.6-4.4-7.8-7.7-9.6-9.8c-6.2-7.7-6.6-15.1-1.1-22.4l56.3-73.8c2.5-3.6,6.7-5.8,12.6-6.5c5.5-0.7,9.8,0.9,13.1,4.9l1.1,1.1c41.2,36.1,85.5,58.9,132.9,68.4c13.5,2.9,27,4.4,40.5,4.4c29.5,0,55.5-7.8,78-23.5c22.4-15.7,33.6-37.9,33.6-66.7c0-10.2-2.7-19.9-8.2-29c-5.5-9.1-11.6-16.8-18.3-23c-6.7-6.2-17.4-13-32-20.5c-14.6-7.5-26.6-13.3-36.1-17.5c-9.5-4.2-24.1-10.1-43.8-17.8c-14.2-5.8-25.4-10.4-33.6-13.7c-8.2-3.3-19.4-8.1-33.6-14.5c-14.2-6.4-25.6-12-34.2-16.9c-8.6-4.9-18.9-11.4-30.9-19.4c-12-8-21.8-15.8-29.3-23.2c-7.5-7.5-15.4-16.4-23.8-26.8c-8.4-10.4-14.9-21-19.4-31.7c-4.6-10.7-8.4-22.9-11.5-36.4c-3.1-13.5-4.6-27.7-4.7-42.7c0-50.3,17.9-94.4,53.6-132.3c35.7-37.9,82.2-62.4,139.5-73.3V27.5c0-4.7,1.7-8.8,5.2-12.3c3.5-3.5,7.6-5.2,12.3-5.2h73.8c5.1,0,9.3,1.6,12.6,4.9c3.3,3.3,4.9,7.5,4.9,12.6v96.3c20.8,2.2,40.9,6.4,60.5,12.6c19.5,6.2,35.4,12.3,47.6,18.3c12.2,6,23.8,12.9,34.7,20.5c10.9,7.7,18,12.9,21.3,15.9c3.3,2.9,6,5.5,8.2,7.7c6.2,6.6,7.1,13.5,2.7,20.8L690,299.3c-2.9,5.5-7.1,8.4-12.6,8.8c-5.1,1.1-10-0.2-14.8-3.8c-1.1-1.1-3.7-3.3-7.9-6.5c-4.2-3.3-11.3-8.1-21.3-14.5c-10-6.4-20.7-12.2-32-17.5s-24.9-10-40.7-14.2c-15.8-4.2-31.4-6.3-46.8-6.3c-34.6,0-62.9,7.8-84.8,23.5c-21.9,15.7-32.8,35.9-32.8,60.7c0,9.5,1.6,18.2,4.7,26.3c3.1,8,8.5,15.6,16.1,22.7c7.7,7.1,14.9,13.1,21.6,18.1s17,10.6,30.6,16.9c13.7,6.3,24.7,11.3,33.1,14.8c8.4,3.5,21.1,8.5,38.3,15.1c19.3,7.3,34.1,13,44.3,17.2c10.2,4.2,24.1,10.6,41.6,19.1s31.3,16.3,41.3,23.2c10,6.9,21.3,16,33.9,27.3c12.6,11.3,22.2,22.9,29,34.7c6.7,11.8,12.5,25.8,17.2,41.8C752.7,622.7,755.1,639.8,755.1,658L755.1,658z" />
                          </g>
                        </svg>
                        <span> Payroll Batches</span>
                      </Link>
                    </li>
                  }
                  {
                    this.props.childProps.showTimecards &&
                    <li>
                      <Link to="/timecards">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 23.158"
                        >
                          <path
                            id="ic_content_copy_24px"
                            className="cls-1"
                            d="M16.737,1H4.105A2.111,2.111,0,0,0,2,3.105V17.842H4.105V3.105H16.737Zm3.158,4.211H8.316A2.111,2.111,0,0,0,6.211,7.316V22.053a2.111,2.111,0,0,0,2.105,2.105H19.895A2.111,2.111,0,0,0,22,22.053V7.316A2.111,2.111,0,0,0,19.895,5.211Zm0,16.842H8.316V7.316H19.895Z"
                            transform="translate(-2 -1)"
                          />
                        </svg>
                        <span> Timecards</span>{" "}
                      </Link>
                    </li>
                  }

                  <li>
                    <a href={null} className="cursorPointer" onClick={this.openProductionModal}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="18px"
                        y="12px"
                        viewBox="0 0 1000 1000"
                        enableBackground="new 0 0 1000 1000"
                        xmlSpace="preserve"
                      >
                        <g>
                          <path d="M620.6,214c-88.3,0-176.5,0-264.8,0c-87.9,0-175.7,0-263.6,0.1c-6.2,0-12.5,0.5-18.6,1.6c-35.9,6.3-63.5,38.2-63.6,73.7C10,429.7,10,570,10,710.3c0,12.6,3,24.6,9.5,35.4C35.9,773,60.5,785.9,91.9,786c176.3,0.1,352.5,0,528.8,0c45.9,0,83.6-37.7,83.6-83.5c0-135,0-270,0-405C704.3,251.7,666.5,214,620.6,214z" />
                          <path d="M985.6,295.6c-10.6,0-21.3-0.1-31.9,0.1c-2.5,0.1-5.3,0.8-7.4,2c-66,39.5-131.9,79-197.8,118.5c-2.5,1.5-3.3,3.1-3.3,6c0.1,51.8,0.1,103.6,0,155.3c0,3.1,1.1,4.7,3.6,6.2c64,38.3,128,76.7,191.9,115.2c6.2,3.7,12.2,5.7,19.4,5.4c9.9-0.5,19.9-0.1,30.1-0.1c0-136.4,0-272.3,0-408.7C988.4,295.6,987,295.6,985.6,295.6z" />
                        </g>
                      </svg>
                      <span> Productions</span>{" "}
                    </a>
                  </li>
                  <li>
                    <a hreaf="javascript:void(0);">
                      <strong> Admin</strong>{" "}
                    </a>
                  </li>
                  {this.props.childProps.showEmployeeList &&
                    <li>
                      <Link to="/employee_list">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path
                            id="ic_description_24px"
                            className="cls-1"
                            d="M16.5,2H6.5A2.287,2.287,0,0,0,4.013,4L4,20a2.287,2.287,0,0,0,2.487,2H21.5A2.3,2.3,0,0,0,24,20V8ZM19,18H9V16H19Zm0-4H9V12H19ZM15.25,9V3.5L22.125,9Z"
                            transform="translate(-4 -2)"
                          />
                        </svg>
                        <span> Employee List</span>{" "}
                      </Link>
                    </li>
                  }
                  <li>
                    <Link to="/ad_times">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path
                          id="ic_create_24px"
                          className="cls-1"
                          d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z"
                          transform="translate(-3 -2.998)"
                        />
                      </svg>
                      <span> AD Times</span>{" "}
                    </Link>
                  </li>
                  {
                    this.props.childProps.showUsers &&
                    <li>
                      <Link to="/users">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path
                            id="ic_person_24px"
                            className="cls-1"
                            d="M14,14A5,5,0,1,0,9,9,5,5,0,0,0,14,14Zm0,2.5c-3.337,0-10,1.675-10,5V24H24V21.5C24,18.175,17.337,16.5,14,16.5Z"
                            transform="translate(-4 -4)"
                          />
                        </svg>
                        <span> Users</span>{" "}
                      </Link>
                    </li>
                  }
                  {
                    this.props.childProps.showApprovalSetup &&
                    <li>
                      <Link to="/approval_setup">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 14"
                        >
                          <path
                            id="ic_supervisor_account_24px"
                            className="cls-1"
                            d="M16.5,12A2.5,2.5,0,1,0,14,9.5,2.492,2.492,0,0,0,16.5,12ZM9,11A3,3,0,1,0,6,8,2.987,2.987,0,0,0,9,11Zm7.5,3c-1.83,0-5.5.92-5.5,2.75V19H22V16.75C22,14.92,18.33,14,16.5,14ZM9,13c-2.33,0-7,1.17-7,3.5V19H9V16.75a3.941,3.941,0,0,1,2.37-3.47A12.283,12.283,0,0,0,9,13Z"
                            transform="translate(-2 -5)"
                          />
                        </svg>
                        <span> Approval Setup</span>{" "}
                      </Link>
                    </li>
                  }
                  {
                    this.props.childProps.showEmailTamplate &&

                    <li>
                      <Link to="/email_template">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 16"
                        >
                          <path
                            id="ic_markunread_24px"
                            className="cls-1"
                            d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,4-8,5L4,8V6l8,5,8-5Z"
                            transform="translate(-2 -4)"
                          />
                        </svg>
                        <span> Email Template</span>{" "}
                      </Link>
                    </li>
                  }

                  <li>
                    <Link to="/settings">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20.561"
                      >
                        <path
                          id="ic_settings_24px"
                          className="cls-1"
                          d="M19.911,13.288a8.012,8.012,0,0,0,.072-1.007,8.012,8.012,0,0,0-.072-1.007l2.169-1.7a.519.519,0,0,0,.123-.658L20.148,5.362a.517.517,0,0,0-.627-.226l-2.56,1.028a7.511,7.511,0,0,0-1.737-1.007l-.391-2.724a.5.5,0,0,0-.5-.432H10.217a.5.5,0,0,0-.5.432L9.322,5.156A7.9,7.9,0,0,0,7.585,6.164L5.025,5.136a.5.5,0,0,0-.627.226L2.342,8.919a.507.507,0,0,0,.123.658l2.169,1.7a8.153,8.153,0,0,0-.072,1.007,8.153,8.153,0,0,0,.072,1.007l-2.169,1.7a.519.519,0,0,0-.123.658L4.4,19.2a.517.517,0,0,0,.627.226L7.585,18.4A7.511,7.511,0,0,0,9.322,19.4l.391,2.724a.5.5,0,0,0,.5.432h4.112a.5.5,0,0,0,.5-.432l.391-2.724A7.9,7.9,0,0,0,16.961,18.4l2.56,1.028a.5.5,0,0,0,.627-.226L22.2,15.642a.519.519,0,0,0-.123-.658Zm-7.638,2.591a3.6,3.6,0,1,1,3.6-3.6A3.6,3.6,0,0,1,12.273,15.879Z"
                          transform="translate(-2.271 -2)"
                        />
                      </svg>
                      <span> Settings</span>{" "}
                    </Link>
                  </li>
                  <li style={{cursor:'pointer'}}>
							
									
              <a href={null} data-toggle="modal" data-target="#signpop">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                  id="ic_create_24px"
                  className="cls-1"
                  d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z"
                  transform="translate(-3 -2.998)"
                />
              </svg>
              <span> Signature</span>
              </a>
          </li>
                  <li>
                    <Link to="/setup">
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="17px"
                        y="17px"
                        viewBox="0 0 1000 1000"
                        enableBackground="new 0 0 1000 1000"
                        xmlSpace="preserve"
                      >
                        <g>
                          <path d="M968.4,789.2L561.9,451.7c23.2-42.1,37.6-89.8,37.6-141.2c0-162.8-132-294.8-294.8-294.8c-34.8,0-67.8,7.1-98.8,18.2l99.1,94.9c49.1,47,50.8,125,3.8,174.1c-47,49.1-125,50.8-174.1,3.7L29.9,206.3C17.6,238.8,10,273.6,10,310.4c0,162.8,132,294.8,294.8,294.8c54.2,0,104.3-15.7,148-41.2l344.5,399.6c22.9,26.5,61.7,27.8,86.3,2.8l89.3-91C997.4,850.4,995.4,811.6,968.4,789.2z" />
                        </g>
                      </svg>
                      <span> Setup</span>{" "}
                    </Link>
                  </li>

                  <div className="clearfix" />
                </ul>
                <div className="clear20" />
              </div>
              <div className="clearfix" />
            </div>

            <div className="clearfix" />
          </div>

          <div className="clearfix" />
        </div>

        <div className="clearfix" />
      </div><div id="signpop" className="modal fade" role="dialog">
               <div className="modal-dialog modal_middle_user">
                  <div className="modal-content modal_middle_user_pop4">
                     <div className="modal-header">
                        <button type="button" className="close close_modal_user" data-dismiss="modal" onClick={this.issue_ScrollHandler} id='close_signature'>
                           <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                              <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B" />
                           </svg>
                        </button>
                        <h4 className="modal-title userl_modal_header">Signature</h4>

                     </div>
                     <div className="modal-body user_pop_inviteemail">
                        <div className="col-sm-12 col-md-12 general_setting_hd">
                           <div className=" mrg_dashboard_right">
                              <div className="">
                                 <div className="col-xs-12 col-sm-10 col-sm-offset-1 mrg_left_sign">
                                    <div className="tabbable-panel">
                                       <div className="tabbable-line">
                                          <ul className="nav nav-tabs ">
                                             <li>
                                                <a href="#tab_default_1" data-toggle="tab" className="text_right_tab1">
                                                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2112 7612 20 14">
                                                      <path id="ic_keyboard_24px" className="cls-1" d="M20,5H4A2,2,0,0,0,2.01,7L2,17a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,20,5ZM11,8h2v2H11Zm0,3h2v2H11ZM8,8h2v2H8Zm0,3h2v2H8ZM7,13H5V11H7Zm0-3H5V8H7Zm9,7H8V15h8Zm0-4H14V11h2Zm0-3H14V8h2Zm3,3H17V11h2Zm0-3H17V8h2Z" transform="translate(-2114 7607)" />
                                                   </svg>
                                                   <div className="clearfix"></div>
                                                   Type
                                       </a>
                                             </li>
                                             <li className="svg_down_pencil active">
                                                <a href="#tab_default_2" data-toggle="tab" className="text_left_tab2">
                                                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3800 7611 15 15">
                                                      <path id="ic_mode_edit_24px" className="cls-1" d="M3,14.873V18H6.125L15.34,8.782,12.215,5.658ZM17.756,6.366a.83.83,0,0,0,0-1.175l-1.95-1.95a.83.83,0,0,0-1.175,0L13.107,4.766l3.125,3.125Z" transform="translate(-3803 7608.002)" />
                                                   </svg>
                                                   <div className="clearfix"></div>
                                                   Signature
                                       </a>
                                             </li>
                                          </ul>
                                          <div className="tab-content">
                                             <div className="tab-pane" id="tab_default_1">
                                                <input name="SignatureText" id="SignatureText" className="" value={this.state.SignatureText} onChange={this.signaturetext} type="text" placeholder="Type Here" defaultValue="" />

                                             </div>


                                             <canvas id="myCanvas" height="40" style={{ border: '1px solid #d3d3d3', display: 'none' }}>
                                                <img id='image' alt="Signature File" style={{ display: 'none' }} src={this.state.Signa} />

                                             </canvas>



                                             <div className="tab-pane pdg_drag_drop_user active" id="tab_default_2">
								
                                                <div className="col-md-12 p0" id='tttt'>
                                                   <Signature  style={style} onEnd={this.getSignatures} clear={this.state.signclear} />
                                                </div>
                                             </div>
                                             <div className="clear20"></div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="clear20"></div>
                           </div>
                        </div>
                        <div className="col-sm-12">
                           <div className="user_btn_save">
                              <a href={null} className="btn_save_user cursorPointer" data-dismiss="modal" onClick={this.save_signature}>Save</a>
                              <a href={null} className="btn_cancel_user cursorPointer" data-dismiss="modal" onClick={this.issue_ScrollHandler}>Cancel</a>
                              <a href={null} className="btn_cancel_user cursorPointer" onClick={this.clear_sign}>Clear</a>
                           </div>
                           <div className="col-md-3"> </div>
                        </div>
                        <div className="clear10"></div>
                     </div>
                  </div>
               </div>
            </div></>
    );
  }
}

export default withRouter(Header);

