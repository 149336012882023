import React, { Component } from 'react';
import HeaderMob from '../Common/HeaderMob/HeaderMob';
import sign1 from './../../Assets/images//sign1.jpg';
import './AttachTimeCardAdd_Mob.css';

export default class AttachTimeCardAdd_Mob extends Component {
  render() {
    return (
      <div>







        <HeaderMob />
        <div className="col-sm-12 attach_timecard_margin_top">
          <select className="form-control" id="sel1">
            <option>Send To</option>

          </select>
        </div>
        <div className="clear5"></div>
        <div className="col-sm-12 timecard_subject">
          <input type="text" className="form-control" name="" defaultValue="Subject" />
        </div>
        <div className="clear5"></div>
        <div className="col-sm-12">
          <textarea className="add_timecar_textarea form-control" defaultValue="Message" />
        </div>
        <div className="clear5"> </div>
        <div className="tab-content tab-content_timecard">
          <div className="tab-pane" id="tab_default_1_timecard">


            <p>

            </p>
            <p>
              <img src={sign1} alt="" /> </p>
            <p>

            </p>
          </div>
          {/* <!--                         <div className="tab-pane pdg_drag_drop_user_tmecard active" id="tab_default_2_timecard">
                             
                             <div className="col-md-12 p0">
          <form className="form form_timecard" action="upload.php" method="POST">
  <input type="file" multiple=""/>
  
  <img src="images/attach_icon_user.png" width="23" height="43" alt=""/>
  <p>Drag and drop
  <br>
   signature file.</p>

</form>
          
          
      </div>
                            
                            
                            
                        </div>
                     --> */}


          <div className="clearfix"></div>
        </div>
        <div className="clear20"> </div>
      </div>
    )
  }
}
