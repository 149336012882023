import React, { Component } from "react";
import forgot_logo from "./../../Assets/images/forgot_logo.svg";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import { toast } from 'react-toastify';
import { Auth } from "aws-amplify";
import "./Forgot.css";
class Forgot extends Component {
  state = {
    isLoading: false,
    email_fp: '',
    cognitoid: '',
    guid_user: ''
  }
  componentDidMount() {
    document.body.classList.add('forgot_bg')
  }
  componentWillUnmount() {
    document.body.classList.remove('forgot_bg');
  }

  inputHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  sendsmsverification = async () => {
    await Auth.forgotPassword(this.state.email_fp)
      .then(data => {
        this.setState({
          codeStatus: true
        });
        // toast.success(
        //   "Sms Sended Successfully to " + data.CodeDeliveryDetails.Destination,
        //   {
        //     autoClose: 5000
        //   }
        // );
      })
      .catch(err => {
        this.setState({
          codeStatus: false
        });
        toast.error(err.message);
      });
  };

  checkAccount = async () => {
    this.setState({
      isLoading: true
    });
    if (this.state.email_fp === '') {
      toast.error("Enter your valid Email to proceed")
      return false;
    }
    await API.post("checkemailuser", "/checkemailuser", {
      body: {
        "user_email": this.state.email_fp
      }
    }).then(data => {
      if (data.length > 0) {
        if (data[0].PasswordChange === "Can't Change") {
          this.props.history.push({
            pathname: "/login"
          });
          toast.error("You can't change your password")
          return false;
        }

        this.setState({
          guid_user: data[0].Guid,
          cognitoid: data[0].Poolid
        });
        this.sendsmsverification();
        this.props.history.push({
          pathname: "/forgot_2",
          state: { authenticateacc: true, email: this.state.email_fp, user_guid: this.state.guid_user },
          codeStatus: this.state.codeStatus
        });

      } else {
        toast.error('User Not Found');
      }

    })
      .catch(err => console.log(err));
    this.setState({
      isLoading: false
    })
  }

  render() {
    return (

      <div className="col-sm-12">
        {this.state.isLoading ?
          <div className="se-pre-con"></div>
          : ''}
        <div className="container p0">
          <div className="forgot_box1">
            <div className="forgot_box">
              <div className="col-sm-12 reset_password p0">
                {" "}
                <img src={forgot_logo} alt="" /> Reset Password
                </div>

              <div className="reset_under_box">
                Enter your email below to reset your password
                  <div className="clear20" />
                <input
                  name="email_fp"
                  className="frogot_input"
                  type="text"
                  placeholder="Email Address"
                  onChange={this.inputHandler}
                />
                <div className="clearfix" />
                <Link to="/login" className="btn_frogot_back">
                  Back
                  </Link>
                <a href={null} className="btn_frogot_blue cursorPointer" onClick={this.checkAccount}>
                  Proceed
                  </a>
                <div className="clearfix" />
              </div>
              <div className="clearfix" />
            </div>
          </div>
          <div className="clearfix" />
        </div>
        <div className="clearfix" />
      </div>

    );
  }
}
export default Forgot;
