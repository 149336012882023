import React, { Component } from 'react';
import HeaderMob from '../Common/HeaderMob/HeaderMob';
import './PayEndingFinal_Mob.css';
import MagniSvg from '../../Assets/images/magnifying-glass.svg';
import { Link } from 'react-router-dom';


export default class PayEndingFinal_Mob extends Component {
  render() {
    return (
      <div>
        <HeaderMob />

        <div className="col-sm-12 p0 text-center payending_mbl_mrg analytics_mbl_heading">
          {/* <!-- Decline --> */}
        </div>

        <div className="col-sm-12 p0 top_mrg_form_res">
          <div className="">

            <div id="mobile_menu_sm">

              {/* <!-- Sidebar --> */}
              <div id="sidebar-wrapper " className="analytics_sidebar">

                <ul className="sidebar-nav" >
                  <li className="sidebar_active">
                    <Link to="/dashboard">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 759 13.333 11.333">
                        <path id="ic_home_24px" className="cls-1" d="M7.333,14.333v-4H10v4h3.333V9h2L8.667,3,2,9H4v5.333Z" transform="translate(-5380 756)" />
                      </svg>
                      <span> Dashboard </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/payroll_batches">
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="" xmlSpace="preserve">
                        <g>
                          <path d="M755.1,658c0,55.8-18.1,103.8-54.4,144.1c-36.3,40.3-83.4,65.2-141.4,74.7v95.7c0,5.1-1.6,9.3-4.9,12.6c-3.3,3.3-7.5,4.9-12.6,4.9H468c-4.7,0-8.8-1.7-12.3-5.2c-3.5-3.5-5.2-7.6-5.2-12.3v-95.7c-24.1-3.3-47.3-8.9-69.7-16.9c-22.4-8-40.9-16.1-55.5-24.3c-14.6-8.2-28.1-17-40.5-26.3c-12.4-9.3-20.9-16.1-25.4-20.5c-4.6-4.4-7.8-7.7-9.6-9.8c-6.2-7.7-6.6-15.1-1.1-22.4l56.3-73.8c2.5-3.6,6.7-5.8,12.6-6.5c5.5-0.7,9.8,0.9,13.1,4.9l1.1,1.1c41.2,36.1,85.5,58.9,132.9,68.4c13.5,2.9,27,4.4,40.5,4.4c29.5,0,55.5-7.8,78-23.5c22.4-15.7,33.6-37.9,33.6-66.7c0-10.2-2.7-19.9-8.2-29c-5.5-9.1-11.6-16.8-18.3-23c-6.7-6.2-17.4-13-32-20.5c-14.6-7.5-26.6-13.3-36.1-17.5c-9.5-4.2-24.1-10.1-43.8-17.8c-14.2-5.8-25.4-10.4-33.6-13.7c-8.2-3.3-19.4-8.1-33.6-14.5c-14.2-6.4-25.6-12-34.2-16.9c-8.6-4.9-18.9-11.4-30.9-19.4c-12-8-21.8-15.8-29.3-23.2c-7.5-7.5-15.4-16.4-23.8-26.8c-8.4-10.4-14.9-21-19.4-31.7c-4.6-10.7-8.4-22.9-11.5-36.4c-3.1-13.5-4.6-27.7-4.7-42.7c0-50.3,17.9-94.4,53.6-132.3c35.7-37.9,82.2-62.4,139.5-73.3V27.5c0-4.7,1.7-8.8,5.2-12.3c3.5-3.5,7.6-5.2,12.3-5.2h73.8c5.1,0,9.3,1.6,12.6,4.9c3.3,3.3,4.9,7.5,4.9,12.6v96.3c20.8,2.2,40.9,6.4,60.5,12.6c19.5,6.2,35.4,12.3,47.6,18.3c12.2,6,23.8,12.9,34.7,20.5c10.9,7.7,18,12.9,21.3,15.9c3.3,2.9,6,5.5,8.2,7.7c6.2,6.6,7.1,13.5,2.7,20.8L690,299.3c-2.9,5.5-7.1,8.4-12.6,8.8c-5.1,1.1-10-0.2-14.8-3.8c-1.1-1.1-3.7-3.3-7.9-6.5c-4.2-3.3-11.3-8.1-21.3-14.5c-10-6.4-20.7-12.2-32-17.5s-24.9-10-40.7-14.2c-15.8-4.2-31.4-6.3-46.8-6.3c-34.6,0-62.9,7.8-84.8,23.5c-21.9,15.7-32.8,35.9-32.8,60.7c0,9.5,1.6,18.2,4.7,26.3c3.1,8,8.5,15.6,16.1,22.7c7.7,7.1,14.9,13.1,21.6,18.1s17,10.6,30.6,16.9c13.7,6.3,24.7,11.3,33.1,14.8c8.4,3.5,21.1,8.5,38.3,15.1c19.3,7.3,34.1,13,44.3,17.2c10.2,4.2,24.1,10.6,41.6,19.1s31.3,16.3,41.3,23.2c10,6.9,21.3,16,33.9,27.3c12.6,11.3,22.2,22.9,29,34.7c6.7,11.8,12.5,25.8,17.2,41.8C752.7,622.7,755.1,639.8,755.1,658L755.1,658z" />
                        </g>
                      </svg>
                      <span> Payroll Batches</span> </Link>
                  </li>
                  <li>
                    <Link to="/timecards">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 23.158">
                        <path id="ic_content_copy_24px" className="cls-1" d="M16.737,1H4.105A2.111,2.111,0,0,0,2,3.105V17.842H4.105V3.105H16.737Zm3.158,4.211H8.316A2.111,2.111,0,0,0,6.211,7.316V22.053a2.111,2.111,0,0,0,2.105,2.105H19.895A2.111,2.111,0,0,0,22,22.053V7.316A2.111,2.111,0,0,0,19.895,5.211Zm0,16.842H8.316V7.316H19.895Z" transform="translate(-2 -1)" />
                      </svg>

                      <span> Timecards

</span> </Link>
                  </li>
                  {/* <li> */}

                  <li>
                    <Link to="/timecards">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="18px" y="12px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000" xmlSpace="preserve">

                        <g>
                          <path d="M620.6,214c-88.3,0-176.5,0-264.8,0c-87.9,0-175.7,0-263.6,0.1c-6.2,0-12.5,0.5-18.6,1.6c-35.9,6.3-63.5,38.2-63.6,73.7C10,429.7,10,570,10,710.3c0,12.6,3,24.6,9.5,35.4C35.9,773,60.5,785.9,91.9,786c176.3,0.1,352.5,0,528.8,0c45.9,0,83.6-37.7,83.6-83.5c0-135,0-270,0-405C704.3,251.7,666.5,214,620.6,214z" />
                          <path d="M985.6,295.6c-10.6,0-21.3-0.1-31.9,0.1c-2.5,0.1-5.3,0.8-7.4,2c-66,39.5-131.9,79-197.8,118.5c-2.5,1.5-3.3,3.1-3.3,6c0.1,51.8,0.1,103.6,0,155.3c0,3.1,1.1,4.7,3.6,6.2c64,38.3,128,76.7,191.9,115.2c6.2,3.7,12.2,5.7,19.4,5.4c9.9-0.5,19.9-0.1,30.1-0.1c0-136.4,0-272.3,0-408.7C988.4,295.6,987,295.6,985.6,295.6z" />
                        </g>
                      </svg>

                      <span> Productions

</span> </Link>
                  </li>
                  <li>

                    < a hreaf="javascript:void(0);">
                      <strong> Admin

 </strong> </a>
                  </li>

                  <li>
                    <Link to="/custom_field">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path id="ic_description_24px" className="cls-1" d="M16.5,2H6.5A2.287,2.287,0,0,0,4.013,4L4,20a2.287,2.287,0,0,0,2.487,2H21.5A2.3,2.3,0,0,0,24,20V8ZM19,18H9V16H19Zm0-4H9V12H19ZM15.25,9V3.5L22.125,9Z" transform="translate(-4 -2)" />
                      </svg>

                      <span> Employee List</span> </Link>
                  </li>
                  <li>
                    <Link to="/custom_list_field">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path id="ic_create_24px" className="cls-1" d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z" transform="translate(-3 -2.998)" />
                      </svg>

                      <span> AD Times

</span> </Link>
                  </li>

                  <li>
                    <Link to="/custom_list_field">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path id="ic_person_24px" className="cls-1" d="M14,14A5,5,0,1,0,9,9,5,5,0,0,0,14,14Zm0,2.5c-3.337,0-10,1.675-10,5V24H24V21.5C24,18.175,17.337,16.5,14,16.5Z" transform="translate(-4 -4)" />
                      </svg>

                      <span> Users

</span> </Link>
                  </li>

                  <li>
                    <Link to="/custom_list_field">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 14">
                        <path id="ic_supervisor_account_24px" className="cls-1" d="M16.5,12A2.5,2.5,0,1,0,14,9.5,2.492,2.492,0,0,0,16.5,12ZM9,11A3,3,0,1,0,6,8,2.987,2.987,0,0,0,9,11Zm7.5,3c-1.83,0-5.5.92-5.5,2.75V19H22V16.75C22,14.92,18.33,14,16.5,14ZM9,13c-2.33,0-7,1.17-7,3.5V19H9V16.75a3.941,3.941,0,0,1,2.37-3.47A12.283,12.283,0,0,0,9,13Z" transform="translate(-2 -5)" />
                      </svg>

                      <span> Approval Setup

</span> </Link>
                  </li>

                  <li>
                    <Link to="/custom_list_field">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 16">
                        <path id="ic_markunread_24px" className="cls-1" d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,4-8,5L4,8V6l8,5,8-5Z" transform="translate(-2 -4)" />
                      </svg>

                      <span> Email Template

</span> </Link>
                  </li>

                  <li>
                    <Link to="/custom_list_field">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20.561">
                        <path id="ic_settings_24px" className="cls-1" d="M19.911,13.288a8.012,8.012,0,0,0,.072-1.007,8.012,8.012,0,0,0-.072-1.007l2.169-1.7a.519.519,0,0,0,.123-.658L20.148,5.362a.517.517,0,0,0-.627-.226l-2.56,1.028a7.511,7.511,0,0,0-1.737-1.007l-.391-2.724a.5.5,0,0,0-.5-.432H10.217a.5.5,0,0,0-.5.432L9.322,5.156A7.9,7.9,0,0,0,7.585,6.164L5.025,5.136a.5.5,0,0,0-.627.226L2.342,8.919a.507.507,0,0,0,.123.658l2.169,1.7a8.153,8.153,0,0,0-.072,1.007,8.153,8.153,0,0,0,.072,1.007l-2.169,1.7a.519.519,0,0,0-.123.658L4.4,19.2a.517.517,0,0,0,.627.226L7.585,18.4A7.511,7.511,0,0,0,9.322,19.4l.391,2.724a.5.5,0,0,0,.5.432h4.112a.5.5,0,0,0,.5-.432l.391-2.724A7.9,7.9,0,0,0,16.961,18.4l2.56,1.028a.5.5,0,0,0,.627-.226L22.2,15.642a.519.519,0,0,0-.123-.658Zm-7.638,2.591a3.6,3.6,0,1,1,3.6-3.6A3.6,3.6,0,0,1,12.273,15.879Z" transform="translate(-2.271 -2)" />
                      </svg>

                      <span> Settings

</span> </Link>
                  </li>

                  <li>
                    <Link to="/custom_list_field">

                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="17px" y="17px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000" xmlSpace="preserve">
                        <g>
                          <path d="M968.4,789.2L561.9,451.7c23.2-42.1,37.6-89.8,37.6-141.2c0-162.8-132-294.8-294.8-294.8c-34.8,0-67.8,7.1-98.8,18.2l99.1,94.9c49.1,47,50.8,125,3.8,174.1c-47,49.1-125,50.8-174.1,3.7L29.9,206.3C17.6,238.8,10,273.6,10,310.4c0,162.8,132,294.8,294.8,294.8c54.2,0,104.3-15.7,148-41.2l344.5,399.6c22.9,26.5,61.7,27.8,86.3,2.8l89.3-91C997.4,850.4,995.4,811.6,968.4,789.2z" />
                        </g>
                      </svg>

                      <span> Setup</span> </Link>
                  </li>

                </ul>

              </div>

            </div>

            <div className="clearfix"></div>

          </div>

          <div className="clearfix"></div>

        </div>

        <div className="clearfix"></div>

        <div className="col-md-12 col-xs-12 analytics_wrapper timecrad_coments_display payend_final" >




          <div className="col-sm-12 col-xs-12 p0 payending_table">
            <div className="col-xs-2 p0"><label> Type: </label></div>
            <div className="col-xs-8 p0">
              <select className="form-control payending_control_dropdown">
                <option>Approve</option>
                <option>Pending</option>
                <option>Declined</option>
                <option>Approved</option>
                <option>All</option>
              </select>

            </div>

          </div>
          <div className="clear10"></div>
          <div className="col-xs-12 p0">
            <div className="col-xs-3 p0">
              <select className="form-control payending_control_dropdown" >
                <option>Sort</option>
                <option>Decline</option>
              </select>
            </div>
            <div className="col-xs-7 pd_payend_final_right">
              <div className="grid_layout_inner">
                <form className="form searchInput_form">
                  <div className="form-group">
                    <button type="button" className="search_button searc_btn_ico_index" value="">
                      <img src={MagniSvg} width="20" height="20" alt="payending-mob" />
                    </button>
                    <input type="text" className="form-control search_input search_btn_top_index" placeholder="What are you looking for" />
                  </div>
                </form>
              </div>
            </div>

            <div className="col-xs-2 col-sm-2 copy_payending_top p0 text-right">
              <Link to="/pay_ending_table"> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17.984" viewBox="0 0 20 17.984">
                <g id="list_4_" data-name="list (4)" transform="translate(0 -24.45)">
                  <g id="Group_871" data-name="Group 871" transform="translate(0 24.45)">
                    <path id="Path_105" data-name="Path 105" d="M192.911,46.25H180.449a.05.05,0,0,0-.049.049v1.167a.05.05,0,0,0,.049.049h12.462a.05.05,0,0,0,.049-.049V46.3A.048.048,0,0,0,192.911,46.25Z" transform="translate(-172.961 -45.351)" fill="#acacac" />
                    <ellipse id="Ellipse_46" data-name="Ellipse 46" cx="1.563" cy="1.563" rx="1.563" ry="1.563" transform="translate(1.386 7.876)" fill="#acacac" />
                    <ellipse id="Ellipse_47" data-name="Ellipse 47" cx="1.571" cy="1.571" rx="1.571" ry="1.571" transform="translate(1.377 14.722)" fill="#acacac" />
                    <path id="Path_106" data-name="Path 106" d="M180.449,98.716h5.889a.05.05,0,0,0,.049-.049V97.5a.05.05,0,0,0-.049-.049h-5.889a.05.05,0,0,0-.049.049v1.167A.053.053,0,0,0,180.449,98.716Z" transform="translate(-172.961 -94.44)" fill="#acacac" />
                    <path id="Path_107" data-name="Path 107" d="M192.911,212.45H180.449a.05.05,0,0,0-.049.049v1.167a.05.05,0,0,0,.049.049h12.462a.05.05,0,0,0,.049-.049V212.5A.048.048,0,0,0,192.911,212.45Z" transform="translate(-172.961 -204.697)" fill="#acacac" />
                    <path id="Path_108" data-name="Path 108" d="M180.449,264.916h5.889a.05.05,0,0,0,.049-.049V263.7a.05.05,0,0,0-.049-.049h-5.889a.05.05,0,0,0-.049.049v1.167A.057.057,0,0,0,180.449,264.916Z" transform="translate(-172.961 -253.786)" fill="#acacac" />
                    <path id="Path_109" data-name="Path 109" d="M192.911,378.55H180.449a.05.05,0,0,0-.049.049v1.167a.05.05,0,0,0,.049.049h12.462a.05.05,0,0,0,.049-.049V378.6A.048.048,0,0,0,192.911,378.55Z" transform="translate(-172.961 -363.948)" fill="#acacac" />
                    <path id="Path_110" data-name="Path 110" d="M186.338,429.85h-5.889a.05.05,0,0,0-.049.049v1.167a.05.05,0,0,0,.049.049h5.889a.05.05,0,0,0,.049-.049V429.9A.048.048,0,0,0,186.338,429.85Z" transform="translate(-172.961 -413.132)" fill="#acacac" />
                    <path id="Path_111" data-name="Path 111" d="M2.2,27.011,1.126,25.926,0,27.048l1.08,1.08L2.2,29.254,3.32,28.137,5.9,25.576,4.779,24.45Z" transform="translate(0 -24.45)" fill="#acacac" />
                  </g>
                </g>
              </svg> </Link>

            </div>
          </div>
          <div className="clearfix"> </div>

          <div className="col-sm-12 table_attach_empl p0 attach_mrg_btm_10">
            <div className="table-responsive">

              <table width="100%" className="table table-condensed table_attach_bdr_pay_ending">
                <thead>
                  <tr>
                    {/* <!-- <th width="10%" align="left"><div className="radio radio_btm_marg">
                        <input id="checkbox02" type="checkbox">
                        <label for="checkbox02">
                          
                        </label>
                    </div></th> --> */}
                    <th width="30%" align="left">Pay Ending</th>
                    <th width="30%" align="left">Name</th>
                    <th width="30%" align="left">Hours</th>
                    <th width="30%" align="left">&nbsp;</th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/* <!--   <td><div className="radio">
                        <input id="checkbox01" type="checkbox">
                        <label for="checkbox01">
                          
                        </label>
                    </div> </td> --> */}
                    <td >16/12/2017</td>
                    <td >
                      Kevin Plummer
        </td>
                    <td> 40</td>
                    <td className="right_icon_payending">
                      <Link to="/timecards"> <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13.053" viewBox="0 0 13 21.053">
                        <path id="ic_chevron_right_24px" d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z" transform="translate(-8.59 -6)" fill="#30679A" />
                      </svg> </Link>
                    </td>
                  </tr>

                  <tr>
                    {/* <!--  <td> <div className="radio">
                        <input id="checkbox02" type="checkbox">
                        <label for="checkbox02">
                          
                        </label>
                    </div></td> --> */}
                    <td >07/01/2018</td>
                    <td >
                      Jessie He
        </td>
                    <td> 32.5</td>
                    <td className="right_icon_payending"> <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13.053" viewBox="0 0 13 21.053">
                      <path id="ic_chevron_right_24px" d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z" transform="translate(-8.59 -6)" fill="#30679A" />
                    </svg>
                    </td>
                  </tr>

                  <tr>
                    {/* <!--  <td> <div className="radio">
                        <input id="checkbox03" type="checkbox">
                        <label for="checkbox03">
                          
                        </label>
                    </div></td> --> */}
                    <td >14/01/2018</td>
                    <td >
                      Luke Faccin
        </td>
                    <td> 55</td>
                    <td className="right_icon_payending"> <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13.053" viewBox="0 0 13 21.053">
                      <path id="ic_chevron_right_24px" d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z" transform="translate(-8.59 -6)" fill="#30679A" />
                    </svg>
                    </td>
                  </tr>
                  <tr>
                    {/* <!--   <td> <div className="radio">
                        <input id="checkbox04" type="checkbox">
                        <label for="checkbox04">
                          
                        </label>
                    </div></td> --> */}
                    <td >19/02/2018</td>
                    <td >
                      Joshua Fermaner
        </td>
                    <td> 55</td>
                    <td className="right_icon_payending"> <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13.053" viewBox="0 0 13 21.053">
                      <path id="ic_chevron_right_24px" d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z" transform="translate(-8.59 -6)" fill="#30679A" />
                    </svg>
                    </td>
                  </tr>
                  <tr>
                    {/* <!--    <td> <div className="radio">
                        <input id="checkbox05" type="checkbox">
                        <label for="checkbox05">
                          
                        </label>
                    </div></td> --> */}
                    <td >14/01/2018</td>
                    <td >
                      Luke Faccin
        </td>
                    <td> 40</td>
                    <td className="right_icon_payending"> <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13.053" viewBox="0 0 13 21.053">
                      <path id="ic_chevron_right_24px" d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z" transform="translate(-8.59 -6)" fill="#30679A" />
                    </svg>
                    </td>
                  </tr>
                  <tr>
                    {/* <!--  <td> <div className="radio">
                        <input id="checkbox06" type="checkbox">
                        <label for="checkbox06">
                          
                        </label>
                    </div></td> --> */}
                    <td >16/03/2018</td>
                    <td >
                      Kerry SNG
        </td>
                    <td> 40</td>
                    <td className="right_icon_payending"> <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13.053" viewBox="0 0 13 21.053">
                      <path id="ic_chevron_right_24px" d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z" transform="translate(-8.59 -6)" fill="#30679A" />
                    </svg>
                    </td>
                  </tr>
                  <tr>
                    {/* <!--  <td> <div className="radio">
                        <input id="checkbox07" type="checkbox">
                        <label for="checkbox07">
                          
                        </label>
                    </div> </td> --> */}
                    <td >16/04/2018</td>
                    <td >
                      16/04/2018
        </td>
                    <td> 40</td>
                    <td className="right_icon_payending"> <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13.053" viewBox="0 0 13 21.053">
                      <path id="ic_chevron_right_24px" d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z" transform="translate(-8.59 -6)" fill="#30679A" />
                    </svg>
                    </td>
                  </tr>
                  <tr>
                    {/* <!-- <td> <div className="radio">
                        <input id="checkbox08" type="checkbox">
                        <label for="checkbox08">
                          
                        </label>
                    </div></td> --> */}
                    <td >16/04/2018</td>
                    <td >
                      Test1
        </td>
                    <td> 40</td>
                    <td className="right_icon_payending"> <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13.053" viewBox="0 0 13 21.053">
                      <path id="ic_chevron_right_24px" d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z" transform="translate(-8.59 -6)" fill="#30679A" />
                    </svg>
                    </td>
                  </tr>
                  <tr>
                    {/* <!--  <td> <div className="radio">
                        <input id="checkbox09" type="checkbox">
                        <label for="checkbox09">
                          
                        </label>
                    </div> </td> --> */}
                    <td >16/04/2018</td>
                    <td >
                      Test1
        </td>
                    <td> 40</td>
                    <td className="right_icon_payending"> <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13.053" viewBox="0 0 13 21.053">
                      <path id="ic_chevron_right_24px" d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z" transform="translate(-8.59 -6)" fill="#30679A" />
                    </svg>
                    </td>
                  </tr>
                  <tr>
                    {/* <!-- <td> <div className="radio">
                        <input id="checkbox010" type="checkbox">
                        <label for="checkbox010">
                          
                        </label>
                    </div></td> --> */}
                    <td >16/04/2018</td>
                    <td >
                      Test1
        </td>
                    <td> 40</td>
                    <td className="right_icon_payending"> <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13.053" viewBox="0 0 13 21.053">
                      <path id="ic_chevron_right_24px" d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z" transform="translate(-8.59 -6)" fill="#30679A" />
                    </svg>
                    </td>
                  </tr>
                  <tr>
                    {/* <!--  <td> <div className="radio">
                        <input id="checkbox011" type="checkbox">
                        <label for="checkbox011">
                          
                        </label>
                    </div> </td> --> */}
                    <td >16/04/2018</td>
                    <td >
                      Test1
        </td>
                    <td> 40</td>
                    <td className="right_icon_payending"> <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13.053" viewBox="0 0 13 21.053">
                      <path id="ic_chevron_right_24px" d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z" transform="translate(-8.59 -6)" fill="#30679A" />
                    </svg>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>

          </div>


          <div className="clear30"></div>

        </div>
        <div className="clearfix"> </div>

        {/* <!-- Mobile Menu timecard --> */}
        <div className="timecard_btm_menu payending_bdr_top">
          <div className="col-sm-12 col-xs-12">
            <div className="col-xs-3 col-sm-3 timecard_mbl_tick_icon timecard_menu_mobilee text-center timecard_active">
              <Link to="payending_final">



                {/* <!-- <svg xmlns="http://www.w3.org/2000/svg" width="17.6" height="13.4" viewBox="0 0 17.6 13.4">
                                                <path id="ic_done_24px" d="M9,16.2,4.8,12,3.4,13.4,9,19,21,7,19.6,5.6Z" transform="translate(-3.4 -5.6)" fill="#4ED94E" />
                                            </svg> --> */}


                {/* <!--  <img src="images/timecard_2.png"> --> */}
                <svg className="timecard_svg" xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 20 22.223">
                  <defs>

                  </defs>
                  <path id="ic_receipt_24px" className="cls-1" d="M19.667,18.667H6.333V16.444H19.667Zm0-4.444H6.333V12H19.667Zm0-4.444H6.333V7.556H19.667ZM3,24.222l1.667-1.667,1.667,1.667L8,22.556l1.667,1.667,1.667-1.667L13,24.222l1.667-1.667,1.667,1.667L18,22.556l1.667,1.667,1.667-1.667L23,24.222V2L21.333,3.667,19.667,2,18,3.667,16.333,2,14.667,3.667,13,2,11.333,3.667,9.667,2,8,3.667,6.333,2,4.667,3.667,3,2Z" transform="translate(-3 -2)" />
                </svg>

                <p>Timecard </p>
              </Link>

            </div>
            <div className="col-xs-3 col-sm-3 timecard_mbl_edit_icon text-center timecard_menu_mobilee">
              <Link to="/settings">

                {/* <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="15.77" height="15.77">
                                                <path id="ic_create_24px" className="cls-1" d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166,2.033-2.033Z" transform="translate(-3 -2.998)" fill="#FFA200" />
                                            </svg> --> */}

                {/* <!--  <img src="images/setting_3.png" className="setting_timecard_img"> --> */}



                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 20 20.561">
                  <defs>

                  </defs>
                  <path id="ic_settings_24px" className="cls-1" d="M19.911,13.288a8.012,8.012,0,0,0,.072-1.007,8.012,8.012,0,0,0-.072-1.007l2.169-1.7a.519.519,0,0,0,.123-.658L20.148,5.362a.517.517,0,0,0-.627-.226l-2.56,1.028a7.511,7.511,0,0,0-1.737-1.007l-.391-2.724a.5.5,0,0,0-.5-.432H10.217a.5.5,0,0,0-.5.432L9.322,5.156A7.9,7.9,0,0,0,7.585,6.164L5.025,5.136a.5.5,0,0,0-.627.226L2.342,8.919a.507.507,0,0,0,.123.658l2.169,1.7a8.153,8.153,0,0,0-.072,1.007,8.153,8.153,0,0,0,.072,1.007l-2.169,1.7a.519.519,0,0,0-.123.658L4.4,19.2a.517.517,0,0,0,.627.226L7.585,18.4A7.511,7.511,0,0,0,9.322,19.4l.391,2.724a.5.5,0,0,0,.5.432h4.112a.5.5,0,0,0,.5-.432l.391-2.724A7.9,7.9,0,0,0,16.961,18.4l2.56,1.028a.5.5,0,0,0,.627-.226L22.2,15.642a.519.519,0,0,0-.123-.658Zm-7.638,2.591a3.6,3.6,0,1,1,3.6-3.6A3.6,3.6,0,0,1,12.273,15.879Z" transform="translate(-2.271 -2)" fill="#707070" />
                </svg>
                <p>Settings </p>
              </Link>

            </div>
            <div className="col-xs-3 col-sm-3 timecard_mbl_msg_icon timecard_mbl_decline_icon text-center timecard_menu_mobilee">
              <Link to="/messages">

                {/* <!-- <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#FF1E1E" />
                                            </svg> --> */}


                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 20 16">
                  <path id="ic_mail_outline_24px" d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,14H4V8l8,5,8-5Zm-8-7L4,6H20Z" transform="translate(-2 -4)" fill="#7b7b7b"></path>
                </svg>

                <p>Messages </p>
              </Link>

            </div>
            <div className="col-xs-3 col-sm-3 timecard_mbl_next_icon timecard_logout_icon text-center timecard_menu_mobilee">
              {/* <!--  <svg xmlns="http://www.w3.org/2000/svg"width="20.46" height="9" viewBox="0 0 20.646 9">
  <path id="ic_redo_24px" d="M5.173,10.6a10.612,10.612,0,0,1,17.014,4.62L19.8,16A8.078,8.078,0,0,0,6.969,12.38L10.622,16H1.54V7Z" transform="translate(-1.54 -7)" fill="#4093AB"/>
</svg>
                                      </svg> --> */}


              <Link to="/login"><svg xmlns="http://www.w3.org/2000/svg" width="18.814" height="20.094" viewBox="0 0 23.814 20.094">
                <g id="logout" transform="translate(-0.002 -25)">
                  <path id="XMLID_7_" d="M3.812,105.582H16.746a1.116,1.116,0,0,0,0-2.233H3.812l1.443-1.443a1.116,1.116,0,0,0-1.579-1.579L.328,103.676q-.039.039-.074.082c-.01.012-.019.026-.029.038s-.025.032-.036.049-.019.032-.029.048-.019.03-.027.046-.016.033-.024.05-.016.032-.023.049-.012.033-.018.049-.013.035-.019.054-.009.033-.013.05-.01.037-.014.056-.006.039-.009.058-.006.033-.007.05c0,.036-.005.072-.006.108v0q0,.054.006.109c0,.017,0,.033.007.049s.005.039.009.059.009.037.014.055.008.034.013.051.012.035.018.053.012.034.018.05.015.032.022.048.016.034.025.051.017.03.026.045.019.033.03.049.023.032.035.047.019.027.03.04.046.053.07.078l0,0L3.677,108.6a1.116,1.116,0,0,0,1.579-1.579Z" transform="translate(0 -69.419)" fill="#7b7b7b" />
                  <path id="XMLID_8_" d="M115.258,25h-14.14A1.116,1.116,0,0,0,100,26.116v4.465a1.116,1.116,0,0,0,2.233,0V27.233h11.907V42.861H102.235V39.512a1.116,1.116,0,1,0-2.233,0v4.465a1.116,1.116,0,0,0,1.116,1.116h14.14a1.116,1.116,0,0,0,1.116-1.116V26.116A1.116,1.116,0,0,0,115.258,25Z" transform="translate(-92.559 0)" fill="#7b7b7b" />
                </g>
              </svg>

                <p>Logout </p>
              </Link>

            </div>

            <div className="clearfix"> </div>
          </div>
          <div className="clearfix"> </div>
        </div>

        {/* <!-- End Mobile Menu timecard   -->


    <!-- Attachment Modal --> */}

        {/* <!-- <div id="approve_modal" className="modal fade" role="dialog">
  <div className="modal-dialog modal_middle_employee modal_attachment_dialog">

   
    <div className="modal-content modal_middle_emp_attach">
      <div className="modal-header">
        <button type="button" className="close  close_modal_employee" data-dismiss="modal">
       Done
        </button>
        <h4 className="modal-title employeel_modal_header modal_attachmnet_heading">&nbsp;

</h4>
      </div>
      <div className="modal-body">
       
       <div className="col-sm-12 table_attach_empl payending_hover text-center">
       Approve
        </div>
          <div className="col-sm-12 payending_hover table_attach_empl text-center">
       Pending
        </div>
          <div className="col-sm-12 payending_hover table_attach_empl text-center">
       Declined
        </div>
         <div className="col-sm-12 payending_hover table_attach_empl text-center">
       Approved
        </div>
         <div className="col-sm-12 payending_hover table_attach_empl text-center">
       All
        </div>
       
      
       
       
  <div className="clear10"></div>
       
      </div>
      
    </div>

  </div>
</div> -->                           */}


        {/* <!-- save page modal -->
 <!-- Attachment Modal --> */}

        <div id="save_modalll" className="modal fade" role="dialog">
          <div className="modal-dialog modal_middle_employee modal_attachment_dialog">

            {/* <!-- Modal content--> */}
            <div className="modal-content modal_middle_emp_attach">
              <div className="modal-header">
                <button type="button" className="close  close_modal_employee" data-dismiss="modal">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path id="ic_clear_24px" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#7B7B7B" />
                  </svg>

                </button>
                <h4 className="modal-title employeel_modal_header modal_attachmnet_heading">

                </h4>
              </div>
              <div className="modal-body">

                <div className="col-xs-12 text-center p0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 20 22.223">
                    <defs>

                    </defs>
                    <path id="ic_receipt_24px" className="cls-1" d="M19.667,18.667H6.333V16.444H19.667Zm0-4.444H6.333V12H19.667Zm0-4.444H6.333V7.556H19.667ZM3,24.222l1.667-1.667,1.667,1.667L8,22.556l1.667,1.667,1.667-1.667L13,24.222l1.667-1.667,1.667,1.667L18,22.556l1.667,1.667,1.667-1.667L23,24.222V2L21.333,3.667,19.667,2,18,3.667,16.333,2,14.667,3.667,13,2,11.333,3.667,9.667,2,8,3.667,6.333,2,4.667,3.667,3,2Z" transform="translate(-3 -2)" fill="#30679A" />
                  </svg>

                </div>
                <div className="clear20"> </div>
                <div className="col-xs-12 p0 ">
                  <label>You are about to leave this form without saving, do you wish to save?</label>

                </div>
                <div className="clear20"> </div>
                <div className="col-xs-12">
                  < a hreaf="javascript:void(0);" className="pull-left Save_timecard">Save</a>
                  < a hreaf="javascript:void(0);" className="pull-right dont_save_timecard">Don't Save</a>
                </div>




                <div className="clear10"></div>

              </div>

            </div>

          </div>
        </div>









      </div>
    )
  }
}
