import React, { Component } from 'react';
import HeaderMob from '../Common/HeaderMob/HeaderMob';
import './AttachTimeCard.css';
import sign1 from '../../Assets/images/sign1.jpg';

export default class AttachTimeCard extends Component {
  render() {
    return (
      <div>







        <HeaderMob />
        <div className="col-sm-12 attach_timecard_margin_top">
          <select className="form-control" id="sel1">
            <option>Send To</option>

          </select>
        </div>
        <div className="clear5"></div>
        <div className="col-sm-12 timecard_subject">
          <input type="text" className="form-control" name="" defaultValue="Subject" />
        </div>
        <div className="clear5"></div>
        <div className="col-sm-12">
          <textarea className="add_timecar_textarea form-control" defaultValue="Message" />
        </div>
        <div className="clear5"> </div>
        <div className="tab-content tab-content_timecard">
          <div className="tab-pane" id="tab_default_1_timecard">


            <p>

            </p>
            <p>
              <img src={sign1} alt="attach_timecardpic" /> </p>
            <p>

            </p>
          </div>



          <div className="clearfix"></div>
        </div>
        <div className="clear20"> </div>
      </div>
    )
  }
}
