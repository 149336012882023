import React, { Component } from 'react';
import "../../Assets/stimulsoft/stimulsoft.viewer.office2013.whiteblue.css";
class EmployeeReport extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount = async () => {
    await this.props.childProps.isAuthentication();
    if (!this.props.childProps.isAuthenticated) {
      this.props.history.push('/login');
    } else {

      var previewData = localStorage.getItem('getreport');

      var viewer = new window.Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);


      var report = new window.Stimulsoft.Report.StiReport();


      report.loadFile('/reports/EmployeeReport.mrt');

      report.dictionary.databases.clear();
      report.regData("Employee", "Employee", previewData);


      viewer.report = report;


      viewer.renderHtml('viewer');

    }
  }
  render() {

    return (
      <div id="viewer"></div>
    );
  }
}

export default EmployeeReport;
