import React, { Component } from "react";
import ic_dehaz from "../../../Assets/images/ic_dehaze_-19@2x.png";
import loginLogo from "../../../Assets/images/login_logo.svg";
import forgotLogoSvg from "../../../Assets/images/forgot_logo.svg";
import logoutSvg from "../../../Assets/images/logout.svg";
import notifactioSvg from "../../../Assets/images/notifactio.svg";
import "./HeaderMob.css";
import { Link } from "react-router-dom";
import SendImgPng from "../../../Assets/images/send_img.png";
import customPng from "../../../Assets/images/custom.png";
import replyPng from "../../../Assets/images/reply.png";

export default class HeaderMob extends Component {
  componentDidMount() { }

  navOpts = () => {
    const Uri = window.location.pathname;
    if (Uri === "/exceptions_mobile") {
      return (
        <React.Fragment>
          <Link
            to="/timecards"
            className="arrow_back_headerall arrow_back_header_white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="17.92"
              viewBox="0 0 13 21.053"
            >
              <g
                id="Group_1096"
                data-name="Group 1096"
                transform="translate(1480 539.053) rotate(180)"
              >
                <path
                  id="ic_chevron_right_24px"
                  d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                  transform="translate(1458.41 512)"
                />
              </g>
            </svg>
          </Link>
          <div className=" text-center analytics_digital_text digital_text_none  attach_timecard_headerrrrrr">
            Digital Times
          </div>
        </React.Fragment>
      );
    }
    if (Uri === "/comments") {
      return (
        <React.Fragment>
          <Link
            to="/timecards"
            className="arrow_back_headerall arrow_back_header_white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="17.92"
              viewBox="0 0 13 21.053"
            >
              <g
                id="Group_1096"
                data-name="Group 1096"
                transform="translate(1480 539.053) rotate(180)"
              >
                <path
                  id="ic_chevron_right_24px"
                  d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                  transform="translate(1458.41 512)"
                />
              </g>
            </svg>
          </Link>
          <div className=" text-center analytics_digital_text digital_text_none  attach_timecard_headerrrrrr">
            Digital Times
          </div>
        </React.Fragment>
      );
    }
    if (Uri === "/decline") {
      return (
        <React.Fragment>
          <Link
            to="/pay_ending_table"
            className="arrow_back_headerall arrow_back_header_white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="17.92"
              viewBox="0 0 13 21.053"
            >
              <g
                id="Group_1096"
                data-name="Group 1096"
                transform="translate(1480 539.053) rotate(180)"
              >
                <path
                  id="ic_chevron_right_24px"
                  d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                  transform="translate(1458.41 512)"
                />
              </g>
            </svg>
          </Link>
          <div className=" text-center analytics_digital_text digital_text_none  attach_timecard_headerrrrrr">
            Digital Times
          </div>
        </React.Fragment>
      );
    }
    if (Uri === "/decline_timecard") {
      return (
        <React.Fragment>
          <Link
            to="/timecards"
            className="arrow_back_headerall arrow_back_header_white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="17.92"
              viewBox="0 0 13 21.053"
            >
              <g
                id="Group_1096"
                data-name="Group 1096"
                transform="translate(1480 539.053) rotate(180)"
              >
                <path
                  id="ic_chevron_right_24px"
                  d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                  transform="translate(1458.41 512)"
                />
              </g>
            </svg>
          </Link>
          <div className=" text-center analytics_digital_text digital_text_none  attach_timecard_headerrrrrr">
            Digital Times
          </div>
        </React.Fragment>
      );
    }
    if (Uri === "/kevin_plummer") {
      return (
        <React.Fragment>
          <Link
            to="/timecards"
            className="arrow_back_headerall arrow_back_header_white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="17.92"
              viewBox="0 0 13 21.053"
            >
              <g
                id="Group_1096"
                data-name="Group 1096"
                transform="translate(1480 539.053) rotate(180)"
              >
                <path
                  id="ic_chevron_right_24px"
                  d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                  transform="translate(1458.41 512)"
                />
              </g>
            </svg>
          </Link>
          <div className=" text-center analytics_digital_text digital_text_none  attach_timecard_headerrrrrr">
            Digital Times
          </div>
        </React.Fragment>
      );
    }
    if (Uri === "/pay_ending_table") {
      return (
        <React.Fragment>
          <Link
            to="/payending_final"
            className="arrow_back_headerall arrow_back_header_white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="17.92"
              viewBox="0 0 13 21.053"
            >
              <g
                id="Group_1096"
                data-name="Group 1096"
                transform="translate(1480 539.053) rotate(180)"
              >
                <path
                  id="ic_chevron_right_24px"
                  d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                  transform="translate(1458.41 512)"
                />
              </g>
            </svg>
          </Link>
          <div className=" text-center analytics_digital_text digital_text_none  attach_timecard_headerrrrrr">
            Digital Times
          </div>
        </React.Fragment>
      );
    }
    if (Uri === "/payending_final") {
      return (
        <React.Fragment>

          <div className=" text-center analytics_digital_text digital_text_none  attach_timecard_headerrrrrr">
            Digital Times
          </div>
        </React.Fragment>
      );
    }
    if (Uri === "/timecard_list") {
      return (
        <React.Fragment>
          <Link
            to="/timecard_list_2"
            className="arrow_back_headerall arrow_back_header_white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="17.92"
              viewBox="0 0 13 21.053"
            >
              <g
                id="Group_1096"
                data-name="Group 1096"
                transform="translate(1480 539.053) rotate(180)"
              >
                <path
                  id="ic_chevron_right_24px"
                  d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                  transform="translate(1458.41 512)"
                />
              </g>
            </svg>
          </Link>
          <div className=" text-center analytics_digital_text digital_text_none  attach_timecard_headerrrrrr">
            Digital Times
          </div>
        </React.Fragment>
      );
    }
    if (Uri === "/attachment_mobile") {
      return (
        <React.Fragment>
          <Link
            to="/timecards"
            className="arrow_back_headerall arrow_back_header_white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="17.92"
              viewBox="0 0 13 21.053"
            >
              <g
                id="Group_1096"
                data-name="Group 1096"
                transform="translate(1480 539.053) rotate(180)"
              >
                <path
                  id="ic_chevron_right_24px"
                  d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                  transform="translate(1458.41 512)"
                />
              </g>
            </svg>
          </Link>
          <div className=" text-center analytics_digital_text digital_text_none  attach_timecard_headerrrrrr">
            Digital Times
          </div>
        </React.Fragment>
      );
    }
    if (Uri === "/aviva") {
      return (
        <React.Fragment>
          <Link
            to="/attachment_mobile"
            className="arrow_back_headerall arrow_back_header_white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="17.92"
              viewBox="0 0 13 21.053"
            >
              <g
                id="Group_1096"
                data-name="Group 1096"
                transform="translate(1480 539.053) rotate(180)"
              >
                <path
                  id="ic_chevron_right_24px"
                  d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                  transform="translate(1458.41 512)"
                />
              </g>
            </svg>
          </Link>
          <div className=" text-center analytics_digital_text digital_text_none  attach_timecard_headerrrrrr">
            Digital Times
          </div>
        </React.Fragment>
      );
    }
    if (Uri === "/timecard_list_no_save") {
      return (
        <React.Fragment>
          <Link
            to="/timecard_list_2"
            className="arrow_back_headerall arrow_back_header_white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="17.92"
              viewBox="0 0 13 21.053"
            >
              <g
                id="Group_1096"
                data-name="Group 1096"
                transform="translate(1480 539.053) rotate(180)"
              >
                <path
                  id="ic_chevron_right_24px"
                  d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                  transform="translate(1458.41 512)"
                />
              </g>
            </svg>
          </Link>
          <div className=" text-center analytics_digital_text digital_text_none  attach_timecard_headerrrrrr">
            Digital Times
          </div>
        </React.Fragment>
      );
    }
    if (Uri === "/attach_timecard") {
      return (
        <React.Fragment>
          <Link
            to="/timecards"
            className="arrow_back_headerall arrow_back_header_white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="17.92"
              viewBox="0 0 13 21.053"
            >
              <g
                id="Group_1096"
                data-name="Group 1096"
                transform="translate(1480 539.053) rotate(180)"
              >
                <path
                  id="ic_chevron_right_24px"
                  d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                  transform="translate(1458.41 512)"
                />
              </g>
            </svg>
          </Link>
          <div className=" text-center analytics_digital_text digital_text_none  attach_timecard_headerrrrrr">
            Digital Times
          </div>

          <div className="col-xs-1 p0 decline_gallery_icon">
            <Link to="/comments">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11.766"
                height="21.511"
                viewBox="0 0 11.766 21.511"
              >
                <path
                  id="ic_attach_file_24px"
                  d="M17.162,5.889V17.134a4.106,4.106,0,0,1-4.279,3.911A4.106,4.106,0,0,1,8.6,17.134V4.911a2.568,2.568,0,0,1,2.674-2.444,2.568,2.568,0,0,1,2.674,2.444V15.178a1.074,1.074,0,0,1-2.139,0V5.889h-1.6v9.289a2.568,2.568,0,0,0,2.674,2.444,2.568,2.568,0,0,0,2.674-2.444V4.911A4.106,4.106,0,0,0,11.279,1,4.106,4.106,0,0,0,7,4.911V17.134a5.644,5.644,0,0,0,5.883,5.378,5.644,5.644,0,0,0,5.883-5.378V5.889Z"
                  transform="translate(-7 -1)"
                  fill="#fff"
                />
                <Link to="/comments">
                  <img alt="headermob_pic" src={SendImgPng} />{" "}
                </Link>
              </svg>
            </Link>
          </div>
          <div className="col-xs-1 p0 decline_send_icon">
            <Link to="/comments">
              <img alt="headermob_pic" src={SendImgPng} />{" "}
            </Link>
          </div>
        </React.Fragment>
      );
    } else if (Uri === "/attach_timecard_add") {
      return (
        <React.Fragment>
          <Link
            to="/timecards"
            className="arrow_back_headerall arrow_back_header_white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="17.92"
              viewBox="0 0 13 21.053"
            >
              <g
                id="Group_1096"
                data-name="Group 1096"
                transform="translate(1480 539.053) rotate(180)"
              >
                <path
                  id="ic_chevron_right_24px"
                  d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                  transform="translate(1458.41 512)"
                />
              </g>
            </svg>
          </Link>
          <div className=" text-center analytics_digital_text digital_text_none  attach_timecard_headerrrrrr">
            Digital Times
          </div>
          <div className="col-xs-1 p0 decline_gallery_icon">
            <Link to="/messages">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11.766"
                height="21.511"
                viewBox="0 0 11.766 21.511"
              >
                <path
                  id="ic_attach_file_24px"
                  d="M17.162,5.889V17.134a4.106,4.106,0,0,1-4.279,3.911A4.106,4.106,0,0,1,8.6,17.134V4.911a2.568,2.568,0,0,1,2.674-2.444,2.568,2.568,0,0,1,2.674,2.444V15.178a1.074,1.074,0,0,1-2.139,0V5.889h-1.6v9.289a2.568,2.568,0,0,0,2.674,2.444,2.568,2.568,0,0,0,2.674-2.444V4.911A4.106,4.106,0,0,0,11.279,1,4.106,4.106,0,0,0,7,4.911V17.134a5.644,5.644,0,0,0,5.883,5.378,5.644,5.644,0,0,0,5.883-5.378V5.889Z"
                  transform="translate(-7 -1)"
                  fill="#fff"
                />
                <a href="/messages">
                  <img alt="headermob_pic" src={SendImgPng} />{" "}
                </a>
              </svg>
            </Link>
          </div>
          <div className="col-xs-1 p0 decline_send_icon">
            <Link to="/messages">
              <img alt="headermob_pic" src={SendImgPng} />{" "}
            </Link>
          </div>
        </React.Fragment>
      );
    } else if (Uri === "/timecard_list_2") {
      return (
        <React.Fragment>
          <a
            hreaf="javascript:void(0);"
            className="arrow_back_headerall arrow_paryroll_back arow_logic_btm_mrg"
            data-toggle="modal"
            data-target="#save_modalll"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="21.053"
              viewBox="0 0 13 21.053"
            >
              <g
                id="Group_1096"
                data-name="Group 1096"
                transform="translate(1480 539.053) rotate(180)"
              >
                <path
                  id="ic_chevron_right_24px"
                  d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                  transform="translate(1458.41 512)"
                />
              </g>
            </svg>
          </a>
          <div className=" text-center analytics_digital_text digital_text_none  attach_timecard_headerrrrrr attach_timecard_list_mobileee">
            Digital Times
          </div>
          <div className=" timecard_mbl_menu timecard_search_icon man_icon_dropppp ">
            <a
              hreaf="javascript:void(0);"
              data-toggle="modal"
              data-target="#timecard_sidee_modal"
            >
              {/* <!-- svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <path id="ic_search_24px" d="M17.294,15.579h-.9l-.32-.309a7.444,7.444,0,1,0-.8.8l.309.32v.9L21.3,23,23,21.3Zm-6.861,0a5.146,5.146,0,1,1,5.146-5.146A5.139,5.139,0,0,1,10.433,15.579Z" transform="translate(-3 -3)" fill="#fff"/>
</svg> --> */}

              <a
                hreaf="javascript:void(0);"
                data-toggle="modal"
                data-target="#orignial_adtimes"
              >
                <img alt="headermob_pic" src={customPng} width="26px;" />
              </a>
            </a>
            <div className="clearfix" />
          </div>
        </React.Fragment>
      );
    } else if (Uri === "/attach_document") {
      return (
        <React.Fragment>
          <Link
            to="/messages"
            className="arrow_back_headerall arrow_back_header_white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="17.92"
              viewBox="0 0 13 21.053"
            >
              <g
                id="Group_1096"
                data-name="Group 1096"
                transform="translate(1480 539.053) rotate(180)"
              >
                <path
                  id="ic_chevron_right_24px"
                  d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                  transform="translate(1458.41 512)"
                />
              </g>
            </svg>
          </Link>
          <div className=" text-center analytics_digital_text digital_text_none  attach_timecard_headerrrrrr">
            Digital Times
          </div>
          <div className="col-xs-1 p0 decline_gallery_icon gallery_icon_img">
            < a hreaf="javascript:void(0);">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10.111"
                height="13"
                viewBox="0 0 10.111 13"
              >
                <path
                  id="ic_delete_24px"
                  d="M5.722,14.556A1.449,1.449,0,0,0,7.167,16h5.778a1.449,1.449,0,0,0,1.444-1.444V5.889H5.722ZM15.111,3.722H12.583L11.861,3H8.25l-.722.722H5V5.167H15.111Z"
                  transform="translate(-5 -3)"
                  fill="#fff"
                />
              </svg>
            </a>
          </div>
          <div className="col-xs-1 p0 decline_send_icon send_img_timecard_file">
            < a hreaf="javascript:void(0);">
              <img alt="headermob_pic" src={replyPng} />{" "}
            </a>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {
            this.props.show &&
            <div
              onClick={this.props.onClickBackArrowHeaderMob}
              className="arrow_back_headerall arrow_paryroll_back arow_digital_timeee"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="21.053"
                viewBox="0 0 13 21.053"
              >
                <g
                  id="Group_1096"
                  data-name="Group 1096"
                  transform="translate(1480 539.053) rotate(180)"
                >
                  <path
                    id="ic_chevron_right_24px"
                    d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                    transform="translate(1458.41 512)"
                  />
                </g>
              </svg>
            </div>
          }
          <div className=" text-center analytics_digital_text digital_text_none  attach_timecard_headerrrrrr analytics_digital_text_left_margin">
            Digital Times
          </div>
        </React.Fragment>
      );
    }
  };

  render() {
    return (
      <div>
        <div className="col-sm-12 header_shadow_all">
          <a
            href="#menu-toggle"
            className="full_menu_toggle menu_clsssss"
            id="menu-toggle_mobile"
          >
            <img alt="headermob_pic" src={ic_dehaz} className="side_imggggggg" />
          </a>

          <div className="logo_header_all">
            <Link
              to="/dashboard"
              className="pull-left logo_header_all logo_headerall_none1"
            >
              <img alt="headermob_pic" src={loginLogo} />{" "}
            </Link>

            <Link to="/dashboard" className="pull-left logo_headerall_none2">
              <img alt="headermob_pic" src={forgotLogoSvg} />{" "}
            </Link>
          </div>

          <div className="header_all_center_label">
            <a href="/dashboard" className="arrow_back_headerall">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="21.053"
                viewBox="0 0 13 21.053"
              >
                <g
                  id="Group_1096"
                  data-name="Group 1096"
                  transform="translate(1480 539.053) rotate(180)"
                >
                  <path
                    id="ic_chevron_right_24px"
                    d="M11.064,6,8.59,8.474l8.035,8.053L8.59,24.579l2.474,2.474L21.59,16.526Z"
                    transform="translate(1458.41 512)"
                  />
                </g>
              </svg>
            </a>
            Analitics
          </div>
          <div className="exit_top_right pull-right">
            < a hreaf="javascript:void(0);">
              <div className="right_logout_label">Logout</div>
              <img alt="headermob_pic" src={logoutSvg} />
            </a>
          </div>

          <div className="notifactio">
            < a hreaf="javascript:void(0);">
              <img alt="headermob_pic" src={notifactioSvg} />
            </a>
            <span className="notif_label">12</span>
          </div>

          <div className="clearfix" />
        </div>

        <div className="clearfix" />
        <div className="header_analytics_block">
          <div className="col-sm-12 col-xs-12 p0 ">
            {/* conditon rendering for modal to save or don't save */}

            {this.navOpts()}
          </div>
          <div className="clearfix"> </div>
        </div>
        <div className="col-sm-12 p0 text-center payrol_timecard_mbl attach_timecard_none payroll_mbl_heading">
          &nbsp;
        </div>
      </div>
    );
  }
}
