import React, { Component } from "react";
import "./Analytics.css";
import { Link } from 'react-router-dom';
import Header1 from '../Common/Header1/Header1';
import DatePicker from "react-datepicker";
import moment from "moment";
import "moment-timezone";
import { API } from "aws-amplify";

import Amplify, { Storage } from "aws-amplify";
class Analytics extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      getallemployes: [],
      select_emp: [],
      select_dept: '',
      select_report: '',
      departments: [],
      getanalemp: [],
      reports: {},
      period_from_calender: new Date(),
      period_to_calender: new Date()
    }
  }

  componentDidMount = async () => {
    await this.props.childProps.isAuthentication();
    if (!this.props.childProps.isAuthenticated) {
      this.props.history.push('/login');
    } else {
      await this.getallemployes();
      await API.post('getdeclinetemplate', '/getdeclinetemplate', { body: { Guid: "ReportList" } }).then(data => {
        console.log(data)
        this.setState({ reports: data })
      })

    }
  }

  periodFromDateChange = async (e) => {
    await this.setState({
      period_from_calender: e
    })
    // console.log(moment(this.state.period_from_calender).format())
  }

  periodToDateChange = async (e) => {
    await this.setState({
      period_to_calender: e
    })
    // console.log(moment(this.state.period_to_calender).format())
  }
  openDatepickerFrom = () => this._calendarfrom.setOpen(true);
  openDatepickerTo = () => this._calendarto.setOpen(true);

  analytics_inputHandler = e => {
    let name = e.target.name;
    this.setState({
      [name]: e.target.value
    });
  };


  getallemployes = async () => {
    var arr = [];
    await this.setState({ isLoading: true });
    await API.post('getallemployees', '/getallemployees', {
      body: {
        "tenant": localStorage.getItem('useableGuid')
      }
    }).then(data => {
      console.log(data);

      data.map(val => {
        var x = arr.filter(vals => { return val.Department === vals })
        if (x.length === 0 && val.Department !== null) {
          arr.push(val.Department)
        }
      })
      this.setState({ getallemployes: data, departments: arr })
    }
    )
    await this.setState({ isLoading: false })
  }

  stateClears = async () => {
    await this.setState({
      select_emp: [],
      select_dept: '',
      select_report: '',
    })
  }
  onSaveAnalytics = async () => {
    var res = [];
    var filters = [];
    await this.setState({ isLoading: true })
    await API.post("getprlempanalytics", "/getprlempanalytics", {
      body: {
        tenant: localStorage.getItem("useableGuid")
      }
    }).then(data => {
      res = data;
      res.map(value => {
        if (value.Employee.length === 0) {
          if (((moment(this.state.period_to_calender).format('YYYY-MM-DD')
            >= moment(value.PeriodEndingDate).format('YYYY-MM-DD'))
            && (moment(this.state.period_from_calender).format('YYYY-MM-DD')
              <= moment(value.PeriodEndingDate).format('YYYY-MM-DD')))) {
            filters.push(value)
          }
        }
        else {
          if (((moment(this.state.period_to_calender).format('YYYY-MM-DD')
            >= moment(value.PeriodEndingDate).format('YYYY-MM-DD'))
            && (moment(this.state.period_from_calender).format('YYYY-MM-DD')
              <= moment(value.PeriodEndingDate).format('YYYY-MM-DD')))
          ) {
            filters.push(value)
            if (this.state.select_emp.length > 0) {
              filters = filters.filter(cal => { return (this.state.select_emp.indexOf(cal.Employee[0].Guid) > -1); });
            }
            if (this.state.select_dept !== "") {
              filters = filters.filter(cals => { return (this.state.select_dept === cals.Employee[0].Department); })
            }

          }
        }
      })
      this.setState({ isLoading: false })

    })
    await this.stateClears();
  }
  selectedemployee = e => {
    var name = [];

    for (var i = 0; i < e.target.options.length; i++) {


      if (e.target.options[i].selected) {
        name.push(e.target.options[i].value)
      }
    }


    this.setState({ select_emp: name })


  }
  selecteddepartment = e => {

    this.setState({ select_dept: e.target.value })
  }
  render() {
    return (

      <div>
        <Header1
          pageHeader="Analytics"
          link='/timecards'
          childProps={this.props.childProps}

        />
        {this.state.isLoading === false ?
          <React.Fragment>

            <div>
              <div className="col-md-12 col-xs-12 col-md-offset-3 col-sm-offset-4 analytics_wrapper">
                <div className="col-sm-12 col-md-12 col-xs-12 analytics_inner p0">
                  <div className="col-sm-2 col-md-2 p0">
                    {" "}
                    <label>Selection:</label>
                  </div>
                  <div className="col-sm-3 col-md-3 p0">
                    <select
                      className="form-control"
                      id="select_emp"
                      name="select_emp[]"
                      onChange={this.selectedemployee}
                      multiple={true}
                    >
                      <option value=''>Selected employees</option>
                      {this.state.getallemployes.length > 0 ?
                        (this.state.getallemployes.map((val, key) => (
                          <option key={key} value={val.Guid}>{val.FirstName}</option>
                        )))

                        :
                        <option>No employees</option>
                      }


                    </select>
                  </div>
                </div>
                <div className="clear10"> </div>
                <div className="col-sm-12 analytics_inner p0">
                  <div className="col-sm-2 col-md-2 p0">
                    {" "}
                    <label>Group By:</label>
                  </div>
                  <div className="col-sm-3 col-md-3 p0">
                    <select
                      className="form-control"
                      id="select_dept"
                      name="select_dept"
                      onChange={this.selecteddepartment}
                    >
                      <option value=''>Department</option>
                      {this.state.departments.length ?
                        this.state.departments.map((val, key) => (
                          <option value={val} key={key}>{val}</option>

                        ))
                        :
                        <option value=''>No Departments</option>}


                    </select>
                  </div>
                </div>
                <div className="clear10"> </div>
                <div className="col-sm-12 analytics_inner p0">
                  <div className="col-sm-2 col-md-2 p0">
                    {" "}
                    <label>Report On:</label>
                  </div>
                  <div className="col-sm-3 col-md-3 p0">
                    <select
                      className="form-control"
                      id="select_report"
                      name="select_report"
                      onChange={this.analytics_inputHandler}
                    >
                      <option value="">Select days</option>
                      {this.state.reports.ReportList ? this.state.reports.ReportList.map((val, key) => (
                        <option value={val.ReportName} key={key}>{val.ReportName}</option>
                      )) : <option>No Reports</option>}


                    </select>
                  </div>
                </div>
                <div className="clear10"> </div>
                <div className="col-sm-12 analytics_inner p0">
                  <div className="col-sm-2 col-md-2 p0">
                    {" "}
                    <label>Period From:</label>
                  </div>
                  <div className="col-sm-3 col-md-3 p0">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      className='form-control'
                      id="period_from_calender"
                      value={this.state.period_from_calender}
                      selected={this.state.period_from_calender}
                      onChange={this.periodFromDateChange}
                      ref={(c) => this._calendarfrom = c}
                    />
                    <a onClick={this.openDatepickerFrom} href={null}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                        className="analytics_date_iconn"
                      >
                        <path
                          id="ic_date_range_24px"
                          d="M9,11H7v2H9Zm4,0H11v2h2Zm4,0H15v2h2Zm2-7H18V2H16V4H8V2H6V4H5A1.991,1.991,0,0,0,3.01,6L3,20a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,19,4Zm0,16H5V9H19Z"
                          transform="translate(-3 -2)"
                          fill="#30679a"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
                <div className="clear10"> </div>
                <div className="col-sm-12 analytics_inner p0">
                  <div className="col-sm-2 col-md-2 p0">
                    {" "}
                    <label>Period To:</label>
                  </div>
                  <div className="col-sm-3 col-md-3 p0">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      className='form-control'
                      id="period_to_calender"
                      value={this.state.period_to_calender}
                      selected={this.state.period_to_calender}
                      onChange={this.periodToDateChange}
                      ref={(t) => this._calendarto = t}
                    />
                    <a onClick={this.openDatepickerTo} href={null}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                        className="analytics_date_iconn"
                      >
                        <path
                          id="ic_date_range_24px"
                          d="M9,11H7v2H9Zm4,0H11v2h2Zm4,0H15v2h2Zm2-7H18V2H16V4H8V2H6V4H5A1.991,1.991,0,0,0,3.01,6L3,20a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,19,4Zm0,16H5V9H19Z"
                          transform="translate(-3 -2)"
                          fill="#30679a"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
                <div className="clear10"> </div>
              </div>
              <div className="col-md-12 col-xs-12 col-sm-12 analytics_footer_btn_mbl_none">
                <div className="col-md-5 col-lg-5 col-sm-6"> </div>
                <div className="col-md-4 col-lg-3 col-sm-6 p0">
                  <div className="analytics_btn_save">
                    <a onClick={this.onSaveAnalytics} href={null} className="btn_save_analytics">
                      Save
              </a>
                    <Link to="/timecards" className="btn_cancel_analytics">
                      Cancel
              </Link>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
              </div>
              <div className="col-md-12 col-xs-12 col-sm-12 footer_btn_mbl_show">
                <div className="col-md-5 col-lg-5 col-sm-6"> </div>
                <div className="col-md-4 col-lg-3 col-sm-6 p0">
                  <div className="analytics_btn_save">
                    <Link to="/timecards" className="btn_save_analytics_footer">
                      Save
              </Link>
                  </div>
                  <div className="col-md-3"> </div>
                </div>
              </div>

            </div> </React.Fragment> : <div className="se-pre-con"></div>}
      </div>



    );
  }
}
export default Analytics;
